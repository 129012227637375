import {Datagrid, DateField, ReferenceField, TextField} from 'react-admin';
import {UserField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';

const PostsToApproveFields = () => (
    <Datagrid bulkActionButtons={false} rowClick="edit">
        <LinkToNewTab propertyName="id" resource="v1_Posts" />
        <LinkToRecordField source="id" label="Post ID" redirectToShow={false} />
        <TextField label="Source URL" source="new_name" />
        <DateField source="CreatedOn" label="Ordered On" locales="en-GB" />
        <DateField source="new_SenttoApproval" label="Sent to Approval" locales="en-GB" />
        <TextField source="new_Title" label="H1 Title" />
        <ReferenceField
            label="Campaign"
            source="campaign_id"
            reference="v1_Campaign"
            link="edit"
            sortBy="Campaign.new_name"
        >
            <TextField source="new_name" />
        </ReferenceField>
        <ReferenceField
            label="Project"
            source="campaign_id"
            reference="v1_Campaign"
            link={false}
            sortBy="Campaign.Project.new_name"
        >
            <ReferenceField reference="v1_Project" source="project_id" link="edit">
                <TextField source="new_name" />
            </ReferenceField>
        </ReferenceField>
        <UserField label="To Be Approved By" source="approver_id" sortBy="Approver.name" />
        <ReferenceField
            source="writer_id"
            link="edit"
            label="Writer Assigned"
            reference="v1_Writers"
            sortBy="Writer.FullName"
        >
            <TextField source="FullName" />
        </ReferenceField>
    </Datagrid>
);

export const postsToApproveSearchFields =
    'new_Title,' +
    'new_PostID,' +
    'Writer#FullName@_ilike,' +
    'Campaign#new_name@_ilike,' +
    'Campaign#Project#new_name@_ilike,' +
    'Approver#name@_ilike';
export default PostsToApproveFields;
