import Model from '../models/db/models';

type AuditedField = {
    field: string;
    resource?: string;
    refField?: string;
    hasShowingValue?: boolean;
};

type AuditedFieldsType = {
    [key: string]: AuditedField[];
};

export const AUDITED_FIELDS: AuditedFieldsType = {
    [Model.Post.res]: [
        {
            field: Model.Post.statecode,
        },
        {
            field: Model.Post.new_name,
        },
        {
            field: Model.Post.new_Supplierprice,
        },
        {
            field: Model.Post.new_ContentCost,
        },
        {
            field: Model.Post.new_UploadPrice,
        },
        {
            field: Model.Post.new_TargetURL1,
        },
        {
            field: Model.Post.new_TargetURL2,
        },
        {
            field: Model.Post.new_TargetURL3,
        },
        {
            field: Model.Post.new_Anchortext1,
        },
        {
            field: Model.Post.new_Anchortext2,
        },
        {
            field: Model.Post.new_Anchortext3,
        },
        {
            field: Model.Post.new_TextHTMLbody,
            hasShowingValue: false,
        },
        {
            field: Model.Post.approver_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.Post.writer_id,
            resource: Model.Writer.res,
            refField: Model.Writer.FullName,
        },
        {
            field: Model.Post.supplier_id,
            resource: Model.Supplier.res,
            refField: Model.Supplier.FullName,
        },
        {
            field: Model.Post.new_PostStatus,
            resource: Model.PostStatus.res,
            refField: Model.PostStatus.value,
        },
        {
            field: Model.Post.new_OutreachStatus,
            resource: Model.PostOutreachStatus.res,
            refField: Model.PostOutreachStatus.value,
        },
        {
            field: Model.Post.PublisherStatus,
            resource: Model.PublisherPostStatus.res,
            refField: Model.PublisherPostStatus.Status,
        },
        {
            field: Model.Post.requestedfrom_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
    ],
    [Model.Project.res]: [
        {
            field: Model.Project.statecode,
        },
        {
            field: Model.Project.new_Importance,
            resource: Model.ProjectImportance.res,
            refField: Model.ProjectImportance.value,
        },
        {
            field: Model.Project.owner_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
    ],
    [Model.Campaign.res]: [
        {
            field: Model.Campaign.statecode,
        },
        {
            field: Model.Campaign.approver_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.Campaign.new_MonthlyTarget,
        },
        {
            field: Model.Campaign.owner_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.Campaign.new_MinWordsperpost,
        },
        {
            field: Model.Campaign.new_Priority,
            resource: Model.CampaignPriority.res,
            refField: Model.CampaignPriority.value,
        },
        {
            field: Model.Campaign.project_id,
            resource: Model.Project.res,
            refField: Model.Project.new_name,
        },
        {
            field: Model.Campaign.strategy_id,
            resource: Model.CampaignStrategy.res,
            refField: Model.CampaignStrategy.new_name,
        },
        {
            field: Model.Campaign.allow_upload_content_portal,
        },
    ],
    [Model.Contractor.res]: [
        {
            field: Model.Contractor.StateCode,
        },
        {
            field: Model.Contractor.owner_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.Contractor.EMailAddress1,
        },
        {
            field: Model.Contractor.new_WebsitePassword,
        },
        {
            field: Model.Contractor.monthly_quota,
        },
        {
            field: Model.Contractor.new_FullSeoPage,
        },
        {
            field: Model.Contractor.new_fullseopagebig,
        },
        {
            field: Model.Contractor.new_fullseopagelarge,
        },
        {
            field: Model.Contractor.new_fullseopagejambo,
        },
        {
            field: Model.Contractor.new_shortsocialpost,
        },
        {
            field: Model.Contractor.new_longsocialpost,
        },
        {
            field: Model.Contractor.new_SeoShortPost,
        },
        {
            field: Model.Contractor.new_Translation,
        },
    ],
    [Model.Company.res]: [
        {
            field: Model.Company.owner_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.Company.EMailAddress1,
        },
    ],
    [Model.Receipt.res]: [
        {
            field: Model.Receipt.statuscode,
            resource: Model.ReceiptStatus.res,
            refField: Model.ReceiptStatus.value,
        },
        {
            field: Model.Receipt.CancelledBy,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.Receipt.company_id,
            resource: Model.Company.res,
            refField: Model.Company.Name,
        },
        {
            field: Model.Receipt.currency_id,
            resource: Model.Currency.res,
            refField: Model.Currency.CurrencySymbol,
        },
        {
            field: Model.Receipt.new_FromCompany,
            resource: Model.PaymentCompany.res,
            refField: Model.PaymentCompany.value,
        },
        {
            field: Model.Receipt.new_Totalamount,
        },
        {
            field: Model.Receipt.CancelledOn,
        },
    ],
    [Model.AffiliateOrder.res]: [
        {
            field: Model.AffiliateOrder.StateCode,
        },
        {
            field: Model.AffiliateOrder.new_LastCheck,
        },
        {
            field: Model.AffiliateOrder.CreatedOn,
        },
        {
            field: Model.AffiliateOrder.createdby_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
    ],
    [Model.Invoice.res]: [
        {
            field: Model.Invoice.StatusCode,
            resource: Model.InvoiceStatus.res,
            refField: Model.InvoiceStatus.value,
        },
        {
            field: Model.Invoice.new_PayTo,
            resource: Model.PaymentCompany.res,
            refField: Model.PaymentCompany.value,
        },
        {
            field: Model.Invoice.currency_id,
            resource: Model.Currency.res,
            refField: Model.Currency.CurrencySymbol,
        },
        {
            field: Model.Invoice.new_TotalVATIncluded,
        },
        {
            field: Model.Invoice.new_producedon,
        },
        {
            field: Model.Invoice.CancelledOn,
        },
        {
            field: Model.Invoice.CancelledBy,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.Invoice.CreatedOn,
        },
    ],
    [Model.PurchaseOrder.res]: [
        {
            field: Model.PurchaseOrder.statuscode,
            resource: Model.PurchaseOrderStatus.res,
            refField: Model.PurchaseOrderStatus.value,
        },
        {
            field: Model.PurchaseOrder.currency_id,
            resource: Model.Currency.res,
            refField: Model.Currency.CurrencySymbol,
        },
        {
            field: Model.PurchaseOrder.CancelledBy,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.PurchaseOrder.approvedby_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.PurchaseOrder.DeclinedBy,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.PurchaseOrder.new_Sum,
        },
        {
            field: Model.PurchaseOrder.CancelledOn,
        },
        {
            field: Model.PurchaseOrder.CreatedOn,
        },
        {
            field: Model.PurchaseOrder.new_PaidOn,
        },
        {
            field: Model.PurchaseOrder.new_ApprovedOn,
        },
        {
            field: Model.PurchaseOrder.DeclinedOn,
        },
        {
            field: Model.PurchaseOrder.new_name,
        },
        {
            field: Model.PurchaseOrder.new_Description,
            hasShowingValue: false,
        },
        {
            field: Model.PurchaseOrder.new_PaymentAddress,
        },
        {
            field: Model.PurchaseOrder.paymentmethod_id,
            resource: Model.PaymentMethod.res,
            refField: Model.PaymentMethod.new_name,
        },
        {
            field: Model.PurchaseOrder.new_PaymentType,
            resource: Model.PaymentCategory.res,
            refField: Model.PaymentCategory.name,
        },
        {
            field: Model.PurchaseOrder.recurringPayment_id,
            resource: Model.RecurringPayment.res,
            refField: Model.RecurringPayment.name,
        },
        {
            field: Model.PurchaseOrder.currency_id,
            resource: Model.Currency.res,
            refField: Model.Currency.CurrencySymbol,
        },
        {
            field: Model.PurchaseOrder.new_Sum,
        },
        {
            field: Model.PurchaseOrder.new_DueDate,
        },
        {
            field: Model.PurchaseOrder.paidby_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
    ],
    [Model.Offer.res]: [
        {
            field: Model.Offer.statecode,
        },
        {
            field: Model.Offer.createdby_id,
            resource: Model.User.res,
            refField: Model.User.name,
        },
        {
            field: Model.Offer.currency_id,
            resource: Model.Currency.res,
            refField: Model.Currency.CurrencySymbol,
        },
        {
            field: Model.Offer.created_on,
        },
        {
            field: Model.Offer.link_price,
        },
        {
            field: Model.Offer.name,
        },
    ],
    [Model.Brand.res]: [
        {
            field: Model.Brand.statecode,
        },
    ],
    [Model.Website.res]: [
        {
            field: Model.Website.statecode,
        },
    ],
    [Model.Tracker.res]: [
        {
            field: Model.Tracker.statecode,
        },
    ],
    [Model.LandingPage.res]: [
        {
            field: Model.LandingPage.statecode,
        },
        {
            field: Model.LandingPage.priority,
            resource: Model.LandingPagePriority.res,
            refField: Model.LandingPagePriority.value,
        },
    ],
    [Model.Bonus.res]: [
        {
            field: Model.Bonus.statecode,
        },
    ],
};
