import _ from 'lodash';
import {RaRecord, RaThemeOptions, ThemeType} from 'react-admin';
import {ROUTES} from '../config/statuses';
import {Maybe, v1_TransactionCurrencyBase} from '../generated/types';

export const buildPath = (compName: string, type: string) => {
    return `${compName}/${compName}${type}`;
};

export const getLastMonthDate = () => `${new Date().getMonth()}/01/${new Date().getFullYear()}`;

export const getThisMonthDate = () => `${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`;

export const getNextMonthDate = () => `${new Date().getMonth() + 2}/01/${new Date().getFullYear()}`;

export const parseDate = (dateString: string) => {
    if (!dateString) {
        return '';
    }
    return Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(new Date(dateString));
};

export const parsePrice = (
    currencies: v1_TransactionCurrencyBase[],
    currencyId: Maybe<number> | undefined,
    amount: Maybe<number>
) => {
    return `${currencies?.find((c) => c.id === currencyId)?.CurrencySymbol}${amount}`;
};

export const completionRatePercentage = (currentVal: number = 0, monthlyTarget: number = 0): number => {
    const safeCurrentVal: number = currentVal ?? 0;
    let safeMonthlyTarget: number = monthlyTarget ?? 0;

    if (safeMonthlyTarget <= 0) {
        return 0;
    }

    return (safeCurrentVal / safeMonthlyTarget) * 100 || 0;
};

export const completionRateText = (
    lastMonthPosts: number,
    newMonthlyTarget: number,
    completionRatePerMonth?: number
) => {
    return `${completionRatePerMonth?.toFixed(0)}% (${lastMonthPosts}/${newMonthlyTarget})`;
};

export const completionRateTextWithoutFraction = (currentVal: number, monthlyTarget: number) => {
    const percentage = completionRatePercentage(currentVal, monthlyTarget).toFixed(0);
    return `${percentage}%`;
};

export function getFirstMonthDay(monthDelta: number = 0) {
    const now = new Date();
    const firstDay = 1;
    const firstDayPreviousMonth = new Date(now.getFullYear(), now.getMonth() + monthDelta, firstDay);

    // 'fr-CA' => YYYY-MM-DD
    return new Intl.DateTimeFormat('fr-CA').format(firstDayPreviousMonth);
}

export function getLastMonthDay(monthDelta: number = 0) {
    const now = new Date();
    const lastDay = 0;
    const lastDayPreviousMonth = new Date(now.getFullYear(), now.getMonth() + monthDelta, lastDay);

    // 'fr-CA' => YYYY-MM-DD
    return new Intl.DateTimeFormat('fr-CA').format(lastDayPreviousMonth);
}

export const calcTotalSupplierPrices = (posts: RaRecord[]): number => {
    const initialValue = 0;

    if (!posts || posts.length < 1) return initialValue;

    return posts
        ?.map((post: RaRecord) => post.new_Supplierprice ?? 0)
        ?.reduce((acc: number, curr: number) => {
            return acc + curr;
        }, initialValue);
};

export const calcTotalWriterFee = (posts: RaRecord[]): number => {
    const initialValue = 0;

    if (!posts || posts.length < 1) return initialValue;

    return posts
        ?.map((post: RaRecord) => post.comp_total_price_writer)
        ?.reduce((acc: number, curr: number) => {
            return acc + curr;
        }, initialValue);
};

export const getThemeMode = (theme: ThemeType | RaThemeOptions): string | undefined => {
    if (typeof theme === 'string') return theme;
    return theme?.palette?.mode;
};

export const getRecordAccess = (userRolesIds: number[], recordRolesIds: number[]): boolean => {
    return _.intersection(userRolesIds, recordRolesIds).length > 0;
};

export const hasChangedField = (field: string, data: any, previousData: any): boolean => {
    if (!data[field] && !previousData[field]) return false;
    return data[field] !== previousData[field];
};

export const getApiHref = (path: string): string => {
    const apiBaseUrl = process.env.REACT_APP_EXPRESS_API_V1 || '';
    const endpoint = new URL(path, apiBaseUrl);
    return endpoint.href;
};

export const getNotificationApiHref = (path: string): string => {
    const apiBaseUrl = process.env.REACT_APP_NOTIFICATIONS_API_V1 || '';
    const endpoint = new URL(path, apiBaseUrl);
    return endpoint.href;
};

export const updateOfferRestrictedNiche = ({id}: {id: number}) =>
    ROUTES.UPDATE_OFFER_RESTRICTED_NICHE.replace('%id', id.toString());

export const getCheckDuplicateRoute = ({resource, field}: {resource: string; field: string}) =>
    `crm/${resource}/${field}/${ROUTES.CHECK_DUPLICATES}`;
