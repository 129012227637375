import {v1_OfferToTags_select_column} from '../../generated/types';

const OffersToTags = {
    ...v1_OfferToTags_select_column,
    res: 'v1_OfferToTags',
    comp: {},
    rel: {
        OfferTag: 'OfferTag',
    },
};

export default OffersToTags;
