import {v1_PostsOutreachStatus_select_column} from '../../generated/types';

const PostOutreachStatus = {
    ...v1_PostsOutreachStatus_select_column,
    res: 'v1_PostsOutreachStatus',
    comp: {},
    rel: {},
};

export default PostOutreachStatus;
