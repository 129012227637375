import {v1_WebsiteQualityScore_select_column} from '../../generated/types';

const WebsiteQualityScore = {
    ...v1_WebsiteQualityScore_select_column,
    res: 'v1_WebsiteQualityScore',
    comp: {},
    rel: {},
};

export default WebsiteQualityScore;
