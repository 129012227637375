import {v1_new_brandBase_select_column} from '../../generated/types';

const Brand = {
    ...v1_new_brandBase_select_column,
    res: 'v1_new_brandBase',
    comp: {
        trackers_30_day_clicks: 'comp_trackers_30_day_clicks',
        related_trackers: 'comp_related_trackers',
    },
    rel: {
        Company: 'Company',
        BrandPriority: 'BrandPriority',
        BrandsToCountries: 'new_new_brand_new_countryBases',
        Tracker: 'Trackers',
    },
    label: 'Brands',
    entity: 'Brand',
    listParams: 'v1_new_brandBase.listParams',
};

export default Brand;
