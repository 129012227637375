import {v1_MarketPlaceCategories_Offers_select_column} from '../../generated/types';

const MarketplaceCategoriesToOffers = {
    ...v1_MarketPlaceCategories_Offers_select_column,
    res: 'v1_MarketPlaceCategories_Offers',
    comp: {},
    rel: {},
};

export default MarketplaceCategoriesToOffers;
