import {v1_new_countryBase_select_column} from '../../generated/types';

const Country = {
    ...v1_new_countryBase_select_column,
    res: 'v1_new_countryBase',
    comp: {},
    rel: {},
    label: 'Countries',
    entity: 'Country',
};

export default Country;
