import {v1_Writers_select_column} from '../../generated/types';

const Writer = {
    ...v1_Writers_select_column,
    res: 'v1_Writers',
    comp: {
        InProgressPosts: 'inprogress_posts',
        LeftQuota: 'left_quota',
    },
    rel: {
        User: 'Owner',
    },
    label: 'Writers',
    entity: 'Writer',
};

export default Writer;
