import {v1_AffDealType_select_column} from '../../generated/types';

const AffiliateDealType = {
    ...v1_AffDealType_select_column,
    res: 'v1_AffDealType',
    comp: {},
    rel: {},
};

export default AffiliateDealType;
