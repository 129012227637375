import LanguageIcon from '@mui/icons-material/Language';
import {Button, Typography} from '@mui/material';
import {ReactNode, useState} from 'react';
import LanguageCodeModal from '../offer/createBulk/LanguageCodeModal';

const LanguageNotificationBox = ({defaultValuesNotification}: {defaultValuesNotification?: ReactNode}) => {
    const [languageModalOpen, setLanguageModalOpen] = useState(false);
    const handleLanguageModalClose = () => setLanguageModalOpen(false);
    const handleLanguageModalOpen = () => setLanguageModalOpen(true);

    return (
        <>
            <Button
                sx={{
                    lineHeight: 1.5,
                    padding: '4px 5px',
                    boxShadow: 2,
                    gap: 0.5,
                }}
                onClick={handleLanguageModalOpen}
            >
                <LanguageIcon />
                <Typography sx={{fontSize: 13}}>Language Index</Typography>
            </Button>
            {!!defaultValuesNotification && defaultValuesNotification}
            <LanguageCodeModal open={languageModalOpen} onClose={handleLanguageModalClose} />
        </>
    );
};

export default LanguageNotificationBox;
