import {Autocomplete, createFilterOptions, TextField} from '@mui/material';

const filterOptions = createFilterOptions({
    limit: 10,
});

type AutocompleteWrapperProps = {
    options: any;
    selectedOption?: any;
    label: string;
    value?: any;
    styles?: any;
    labelProp: string;
    onOptionSelected: any;
    isRequired: boolean;
    disableClear: boolean;
    disabled?: boolean;
    isLoading?: boolean;
    multiple?: boolean;
};
const AutocompleteWrapper = (props: AutocompleteWrapperProps) => {
    const {
        options,
        selectedOption,
        onOptionSelected,
        label,
        labelProp,
        styles,
        disableClear,
        isRequired,
        disabled,
        isLoading,
        multiple = false,
    } = props;
    return Array.isArray(options) ? (
        <Autocomplete
            multiple={multiple}
            disabled={disabled}
            key={`${selectedOption}-${label}`}
            disableClearable={disableClear}
            sx={styles}
            options={options}
            getOptionLabel={(option) => (option ? option[labelProp] : '')}
            isOptionEqualToValue={(option, value) => {
                return selectedOption ? option.id === value.id : true;
            }}
            clearOnBlur
            renderInput={(params) => <TextField {...params} label={label} required={isRequired} variant="outlined" />}
            defaultValue={multiple ? [] : ''}
            value={
                multiple && selectedOption
                    ? options.filter((o: any) => {
                          return selectedOption.includes(o.id);
                      })
                    : selectedOption
                      ? options.find((o: any) => o.id === selectedOption)
                      : ''
            }
            onChange={(event, value, reason) => onOptionSelected(value ? (multiple ? value : value.id) : null, reason)}
            loading={!!isLoading}
            filterOptions={filterOptions}
        ></Autocomplete>
    ) : (
        <>Loading...</>
    );
};
export default AutocompleteWrapper;
