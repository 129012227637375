import {v1_TrackerTags_select_column} from '../../generated/types';

const TrackerTag = {
    ...v1_TrackerTags_select_column,
    res: 'v1_TrackerTags',
    comp: {},
    rel: {},
    label: 'Tracker Tags',
};

export default TrackerTag;
