import LaunchIcon from '@mui/icons-material/Launch';
import {Box, Tooltip} from '@mui/material';
import Link from '@mui/material/Link/Link';
import get from 'lodash/get';
import React from 'react';
import {RaRecord, useRecordContext, WrapperField} from 'react-admin';
import {MAX_STRING_LENGTH} from './textFieldTruncate';

export const TruncatedLinkField = (props: TruncatedLinkFieldProps) => {
    const record = useRecordContext<RaRecord>();
    const {label, source, showPropertyName, limit = props.limit || MAX_STRING_LENGTH, tooltip = true} = props;

    const value = get(record, source) || '';
    if (!value) return null;

    const linkUrl = value.startsWith('http') ? value : `https://${value}`;
    let hideRef: string;
    try {
        hideRef = 'https://dereferer.me/?' + decodeURI(linkUrl);
    } catch (e: any) {
        console.log(e);
        hideRef = 'https://dereferer.me/?' + linkUrl;
    }

    const shouldTruncate = value.length > limit;

    const truncateStyle = {
        backgroundImage: 'linear-gradient(to right, #0085E2 75%, transparent)',
        backgroundClip: 'text',
        color: 'transparent',
        WebkitBackgroundClip: 'text',
        maxWidth: '550px',
    };

    const renderLinkComponent = (tooltipValue: boolean, childElement: JSX.Element) =>
        tooltipValue ? (
            <Tooltip title={value} placement="top-start">
                {childElement}
            </Tooltip>
        ) : (
            childElement
        );

    return (
        <WrapperField label={label}>
            {renderLinkComponent(
                tooltip,
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Link
                        rel="noreferrer"
                        target="_blank"
                        href={hideRef}
                        onClick={stopPropagation}
                        sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                    >
                        <Box
                            style={shouldTruncate ? truncateStyle : {}}
                            sx={{
                                overflow: 'hidden',
                            }}
                        >
                            {showPropertyName && value}
                        </Box>
                        {showPropertyName && <LaunchIcon style={{marginLeft: '0.2rem'}} />}
                    </Link>
                </Box>
            )}
        </WrapperField>
    );
};

type TruncatedLinkFieldProps = {
    label: string;
    source: string;
    showPropertyName?: boolean;
    limit?: number;
    tooltip?: boolean;
};

const stopPropagation = (e: any) => e.stopPropagation();
