import {v1_MarketPlaceCategories_select_column} from '../../generated/types';

const MarketplaceCategories = {
    ...v1_MarketPlaceCategories_select_column,
    res: 'v1_MarketPlaceCategories',
    comp: {},
    rel: {},
};

export default MarketplaceCategories;
