import {v1_new_new_brand_new_countryBase_select_column} from '../../generated/types';

const BrandsToCountries = {
    ...v1_new_new_brand_new_countryBase_select_column,
    res: 'v1_new_new_brand_new_countryBase',
    comp: {},
    rel: {
        Country: 'Countries',
    },
};

export default BrandsToCountries;
