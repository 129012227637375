import {v1_OrderPriority_select_column} from '../../generated/types';

const AffiliateOrderPriority = {
    ...v1_OrderPriority_select_column,
    res: 'v1_OrderPriority',
    comp: {},
    rel: {},
    label: 'AO Categories',
};

export default AffiliateOrderPriority;
