import React from 'react';
import {Datagrid, FunctionField, NumberField, ReferenceField, TextField} from 'react-admin';
import {STATE_CODE} from '../../config/statuses';
import {v1_Campaign} from '../../generated/types';
import {DashboardCampaignProps} from '../../types/dashboard-campaign-props.type';
import {completionRateText} from '../../utility/helpers';
import DashboardListWrapper from '../common/dashboardListWrapper';
import {TruncatedTooltipField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';
import ProgressWithLabel from '../common/progressBarWithLabel';

const DashboardCampaigns = (props: DashboardCampaignProps) => {
    const {strategyType, userId, cardTitle} = props;

    return (
        <DashboardListWrapper
            cardTitle={cardTitle}
            title=" "
            resource="v1_Campaign"
            filter={{
                owner_id: userId,
                'Strategy#CampaignType#id': strategyType,
                statecode: STATE_CODE.ENABLED,
            }}
            searchFields={'new_name,Project#new_name@_ilike'}
        >
            <Datagrid bulkActionButtons={false} rowClick="edit">
                <LinkToNewTab propertyName="id" resource="v1_Campaign" />
                <LinkToRecordField source="id" label="Campaign ID" redirectToShow={false} />
                <TruncatedTooltipField source="new_name" label="Name" />
                <ReferenceField label="Project" source="project_id" reference="v1_Project" link="edit">
                    <TruncatedTooltipField source="new_name" />
                </ReferenceField>
                <NumberField source="new_MonthlyTarget" label="Monthly Target" />
                <FunctionField
                    label="Completion Rate Last Month"
                    sortBy="CompletionRateLastMonth"
                    render={(record: v1_Campaign) => {
                        const completionRateLastMonth = Number(record.CompletionRateLastMonth);
                        const lastMonthPosts = Number(record.LastMonthPosts);
                        const newMonthlyTarget = Number(record.new_MonthlyTarget);

                        return (
                            <ProgressWithLabel
                                value={completionRateLastMonth}
                                displayValue={completionRateText(
                                    lastMonthPosts,
                                    newMonthlyTarget,
                                    completionRateLastMonth
                                )}
                            />
                        );
                    }}
                />
                <FunctionField
                    label="Completion Rate This Month"
                    sortBy="CompletionRateThisMonth"
                    source="CompletionRateThisMonth"
                    render={(record: v1_Campaign) => {
                        const completionRateThisMonth = Number(record.CompletionRateThisMonth);
                        const thisMonthPosts = Number(record.ThisMonthPosts);
                        const newMonthlyTarget = Number(record.new_MonthlyTarget);

                        return (
                            <ProgressWithLabel
                                value={completionRateThisMonth}
                                displayValue={completionRateText(
                                    thisMonthPosts,
                                    newMonthlyTarget,
                                    completionRateThisMonth
                                )}
                            />
                        );
                    }}
                />
                <ReferenceField label="Importance" source="new_Priority" reference="v1_CampaignPriority" link={false}>
                    <TextField source="value" />
                </ReferenceField>
                <NumberField source="PlannedPosts" label="Planned" />
                <NumberField source="RequestedPosts" label="Requested" />
                <NumberField source="OrderedPosts" label="Ordered" />
                <NumberField source="ApprovedPosts" label="Approved" />
                <NumberField source="WaitingApprovalPosts" label="Waiting Approval" />
                <NumberField source="SentToPublisherPosts" label="Sent To Publisher" />
                <NumberField source="DeclinedPosts" label="Declined" />
                <NumberField source="LivePosts" label="Live Posts" />
                <NumberField source="PoolPosts" label="Pool" />
            </Datagrid>
        </DashboardListWrapper>
    );
};
export default DashboardCampaigns;
