import {DataProvider, localStorageStore, Store} from 'react-admin';
import {IEvent} from './../models/event.model';

const localStore = localStorageStore();

export let store: Store;

export const initStore = (dataProvider: DataProvider): void => {
    store = {
        ...localStore,
        setItem: (key: string, value: any) => {
            if (isSavedSetting(key)) {
                const userId = localStore.getItem('userId');
                dataProvider
                    .getList<IEvent>('v1_Preferences', {
                        filter: {created_by: userId, path: key},
                        pagination: {page: 1, perPage: 10},
                        sort: {field: 'path', order: 'ASC'},
                    })
                    .then((result) => {
                        const {data} = result;
                        if (data.length === 0) {
                            dataProvider.create<IEvent>('v1_Preferences', {
                                data: {
                                    path: key,
                                    created_by: userId,
                                    preference: value,
                                },
                            });
                        } else {
                            dataProvider.update<IEvent>('v1_Preferences', {
                                id: data[0].id,
                                data: {
                                    path: key,
                                    created_by: userId,
                                    preference: value,
                                },
                                previousData: data[0],
                            });
                        }
                    });
            }
            localStore.setItem(key, value);
        },
    };
};

const isSavedColumnsBySavedQuery = (key: string): boolean => /datagrid\..+\.columns/i.test(key);

const isSavedSetting = (key: string): boolean =>
    key.toLowerCase().includes('savedqueries') ||
    key.toLowerCase().includes('datagrid.columns') ||
    isSavedColumnsBySavedQuery(key);
