import Advertiser from './advertiser.model';
import AffiliateDealType from './affiliateDealType.model';
import AffiliateOrder from './affiliateOrder.model';
import AffiliateOrderCategoryPriority from './affiliateOrderCategoryPriority.model';
import AffiliateOrderPriority from './affiliateOrderPriority.model';
import AffiliateOrderType from './affiliateOrderType.model';
import AffiliateProgramType from './affiliateProgramType.model';
import Bonus from './bonus.model';
import BonusesToCountries from './bonusesToCountries.model';
import BonusesToTypes from './bonusesToTypes.model';
import BonusStatus from './bonusStatus.model';
import BonusType from './bonusType.model';
import Brand from './brand.model';
import BrandAlias from './brandAlias.model';
import BrandPriority from './brandPriority.model';
import BrandsToCountries from './brandsToCountries.model';
import Campaign from './campaign.model';
import CampaignPriority from './campaignPriority.model';
import CampaignStrategy from './campaignStrategy.model';
import CampaignType from './campaignType.model';
import Categories from './categories.model';
import Company from './company.model';
import Contact from './contact.model';
import ContactRole from './contactRole.model';
import ContentLink from './contentLink.model';
import ContentType from './contentType.model';
import Contractor from './contractor.model';
import Country from './country.model';
import Credential from './credential.model';
import CredentialsToRoles from './credentialsToRoles.model';
import Currency from './currency.model';
import Invoice from './invoice.model';
import InvoiceStatus from './invoiceStatus.model';
import LandingPage from './landingPage.model';
import LandingPagePriority from './landingPagePriority.model';
import Language from './language.model';
import LanguagePriceStep from './languagePriceStep.model';
import MarketplaceCategories from './marketplaceCategories.model';
import MarketplaceCategoriesToOffers from './marketplaceCategoriesToOffers.model';
import MarketplaceCategoryTypes from './marketplaceCategoryTypes.model';
import marketplaceCategoryTypes from './marketplaceCategoryTypes.model';
import MarketplaceProject from './marketplaceProject.model';
import MarketplaceStatus from './marketplaceStatus.model';
import Month from './month.model';
import Offer from './offer.model';
import OffersToTags from './offersToTags';
import OfferTag from './offerTag';
import Order from './order.model';
import OrderPriority from './orderPriority.model';
import OrderType from './orderType.model';
import Page from './page.model';
import PaymentCategory from './paymentCategory.model';
import PaymentCompanies from './paymentCompanies';
import PaymentCompany from './paymentCompany.model';
import PaymentMethod from './paymentMethod.model';
import Post from './post.model';
import PostContentStatus from './postContentStatus.model';
import PostCopyScape from './postCopyScape.model';
import PostDuration from './postDuration.model';
import PostLinkStatus from './postLinkStatus.model';
import PostNiche from './postNiche.model';
import PostOutreachStatus from './postOutreachStatus.model';
import PostsContentStatus from './postsContentStatus.model';
import PostsLinkStatus from './postsLinkStatus.model';
import PostOnlineStatus from './postsOnlineStatus.model';
import PostsStatus from './postsStatus.model';
import PostStatus from './postStatus.model';
import Process from './process.model';
import ProcessRate from './processRate.model';
import Project from './project.model';
import ProjectCountry from './projectCountry.model';
import ProjectImportance from './projectImportance.model';
import PublisherPostStatus from './publisherPostStatus.model';
import PublisherStatus from './publisherStatus.model';
import PurchaseOrder from './purchaseOrder.model';
import PurchaseOrderStatus from './purchaseOrderStatus.model';
import Receipt from './receipt.model';
import ReceiptStatus from './receiptStatus.model';
import RecurringPayment from './recurringPayment.model';
import RegistrationEmail from './registratonEmail.model';
import Report from './report.model';
import ReportsToRoles from './reportsToRoles.model';
import RestrictedNiche from './restrictedNiche.model';
import RestrictedNichesToOffers from './restrictedNichesToOffers.model';
import Role from './role.model';
import StrategyFieldsSet from './strategyFieldSet.model';
import Supplier from './supplier.model';
import Team from './team.model';
import Tracker from './tracker.model';
import TrackerDeal from './trackerDeal.model';
import TrackersToTags from './trackersToTags.model';
import TrackerTag from './trackerTag.model';
import User from './user.model';
import UserStatus from './userStatus.model';
import UsersToRoles from './usersToRoles.model';
import UsersToTeams from './usersToTeams.model';
import Website from './website.model';
import WebsiteDtoxRisk from './websiteDtoxRisk.model';
import WebsitePlatform from './websitePlatform.model';
import WebsiteQualityScore from './websiteQualityScore.model';
import WebsitesToTopics from './websitesToTopics.model';
import WebsiteTopic from './websiteTopic.model';
import Writer from './writer.model';
import CompanyType from './сompanyType.model';

const Model = {
    Advertiser: Advertiser,
    AffiliateDealType: AffiliateDealType,
    AffiliateOrder: AffiliateOrder,
    AffiliateOrderCategoryPriority: AffiliateOrderCategoryPriority,
    AffiliateOrderPriority: AffiliateOrderPriority,
    AffiliateOrderType: AffiliateOrderType,
    AffiliateProgramType: AffiliateProgramType,
    Bonus: Bonus,
    BonusStatus: BonusStatus,
    BonusType: BonusType,
    BonusesToCountries: BonusesToCountries,
    BonusesToTypes: BonusesToTypes,
    Brand: Brand,
    BrandAlias: BrandAlias,
    BrandPriority: BrandPriority,
    BrandsToCountries: BrandsToCountries,
    Campaign: Campaign,
    CampaignPriority: CampaignPriority,
    CampaignStrategy: CampaignStrategy,
    CampaignType: CampaignType,
    Categories: Categories,
    Company: Company,
    CompanyType: CompanyType,
    Contact: Contact,
    ContactRole: ContactRole,
    ContentLink: ContentLink,
    ContentType: ContentType,
    Contractor: Contractor,
    Country: Country,
    Credential: Credential,
    CredentialsToRoles: CredentialsToRoles,
    Currency: Currency,
    Invoice: Invoice,
    InvoiceStatus: InvoiceStatus,
    LandingPage: LandingPage,
    LandingPagePriority: LandingPagePriority,
    Language: Language,
    LanguagePriceStep: LanguagePriceStep,
    MarketplaceCategories: MarketplaceCategories,
    MarketplaceCategoryTypes: MarketplaceCategoryTypes,
    MarketplaceCategoriesToOffers: MarketplaceCategoriesToOffers,
    MarketplaceProject: MarketplaceProject,
    MarketplaceStatus: MarketplaceStatus,
    Month: Month,
    Offer: Offer,
    OfferTag: OfferTag,
    OfferToTags: OffersToTags,
    Order: Order,
    OrderPriority: OrderPriority,
    OrderType: OrderType,
    Page: Page,
    PaymentCategory: PaymentCategory,
    PaymentCompanies: PaymentCompanies,
    PaymentCompany: PaymentCompany,
    PaymentMethod: PaymentMethod,
    Post: Post,
    PostContentStatus: PostContentStatus,
    PostCopyScape: PostCopyScape,
    PostDuration: PostDuration,
    PostLinkStatus: PostLinkStatus,
    PostNiche: PostNiche,
    PostOnlineStatus: PostOnlineStatus,
    PostOutreachStatus: PostOutreachStatus,
    PostStatus: PostStatus,
    PostsContentStatus: PostsContentStatus,
    PostsLinkStatus: PostsLinkStatus,
    PostsStatus: PostsStatus,
    Process: Process,
    ProcessRate: ProcessRate,
    Project: Project,
    ProjectCountry: ProjectCountry,
    ProjectImportance: ProjectImportance,
    PublisherPostStatus: PublisherPostStatus,
    PublisherStatus: PublisherStatus,
    PurchaseOrder: PurchaseOrder,
    PurchaseOrderStatus: PurchaseOrderStatus,
    Receipt: Receipt,
    ReceiptStatus: ReceiptStatus,
    RecurringPayment: RecurringPayment,
    RegistrationEmail: RegistrationEmail,
    Report: Report,
    ReportsToRoles: ReportsToRoles,
    RestrictedNiche: RestrictedNiche,
    RestrictedNichesToOffers: RestrictedNichesToOffers,
    Role: Role,
    StrategyFieldsSet: StrategyFieldsSet,
    Supplier: Supplier,
    Team: Team,
    Tracker: Tracker,
    TrackerDeal: TrackerDeal,
    TrackerTag: TrackerTag,
    TrackersToTag: TrackersToTags,
    TrackersToTags: TrackersToTags,
    User: User,
    UserStatus: UserStatus,
    UsersToRoles: UsersToRoles,
    UsersToTeams: UsersToTeams,
    Website: Website,
    WebsiteDtoxRisk: WebsiteDtoxRisk,
    WebsitePlatform: WebsitePlatform,
    WebsiteQualityScore: WebsiteQualityScore,
    WebsiteTopic: WebsiteTopic,
    WebsitesToTopics: WebsitesToTopics,
    Writer: Writer,
};

export default Model;
