import {v1_Project_Countries_select_column} from '../../generated/types';

const ProjectCountry = {
    ...v1_Project_Countries_select_column,
    res: 'v1_Project_Countries',
    comp: {},
    rel: {},
};

export default ProjectCountry;
