import {createHttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {nhost} from '../authorization/nhost';
import {CRM_USER} from './statuses';

let nhostToken: string;

nhost.auth.onTokenChanged(() => {
    nhostToken = nhost.auth.getAccessToken() as string;
});

export const authLink = setContext((_, {headers}) => {
    return {
        headers: {
            ...headers,
            authorization: nhostToken ? `Bearer ${nhostToken}` : '',
            'x-hasura-role': CRM_USER,
        },
    };
});

export const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_PROXY_ENDPOINT,
});
