import {Card, Stack, Typography} from '@mui/material';

interface ErrorsProps {
    errors: string[];
}

export function Errors({errors}: ErrorsProps) {
    if (!errors.length) return null;

    return (
        <Card
            sx={{
                mb: 3,
                padding: '15px',
            }}
        >
            <Typography variant="h6" color="red">
                Errors (live posts was not saved):
            </Typography>
            <Stack>
                {errors.map((error: string, idx: number) => {
                    return (
                        <Typography variant="body1" key={idx}>
                            Post {idx + 1}: Incorrect Source URL '{error}'
                        </Typography>
                    );
                })}
            </Stack>
        </Card>
    );
}
