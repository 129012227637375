import {CellChange, Column, Id, NumberCell, ReactGrid, Row, TextCell} from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import {useState} from 'react';
import type {OffersData} from './types';

interface OffersSpreadsheetProps {
    offers: OffersData[];
    handleTableDataChanges: any;
}

const formatNumbers = Intl.NumberFormat('en-US', {
    useGrouping: false,
});

const headerRow: Row = {
    rowId: 'header',
    cells: [
        {
            type: 'header',
            text: 'Name',
        },
        {
            type: 'header',
            text: 'Domain*',
        },
        {
            type: 'header',
            text: 'Description',
        },
        {
            type: 'header',
            text: 'Language*',
        },
        {
            type: 'header',
            text: 'Offer Currency',
        },
        {
            type: 'header',
            text: 'Amount',
        },
    ],
};

const getRows = (offers: OffersData[]): Row[] => [
    headerRow,
    ...offers.map<Row>((offer, idx) => ({
        rowId: idx,
        cells: [
            {
                type: 'text',
                text: offer.name,
            },
            {
                type: 'text',
                text: offer._domain,
            },
            {
                type: 'text',
                text: offer.notes,
            },
            {
                type: 'text',
                text: offer._language,
            },
            {
                type: 'text',
                text: offer._currency_symbol,
            },
            {
                type: 'number',
                value: offer.link_price,
                hideZero: true,
                format: formatNumbers,
            },
        ],
    })),
];

const getColumns = (): Column[] => [
    {
        columnId: 'name',
        resizable: true,
    },
    {
        columnId: '_domain',
        resizable: true,
    },
    {
        columnId: 'notes',
        resizable: true,
    },
    {
        columnId: '_language',
        resizable: true,
    },
    {
        columnId: '_currency_symbol',
        resizable: true,
    },
    {
        columnId: 'link_price',
        resizable: true,
    },
];

export const getOffers = (): OffersData[] => {
    const maxRows = 50;
    const result = [];
    for (let r = 1; r <= maxRows; r++) {
        result.push({
            name: '',
            _domain: '',
            notes: '',
            _language: '',
            _currency_symbol: '',
            link_price: 0,
            IsHomepageLink: true,
            do_follow: true,
        });
    }
    return result;
};

export const applyChangesToOffers = (changes: CellChange<TextCell | NumberCell>[], prevOffers: any[]): OffersData[] => {
    changes.forEach((change) => {
        const offerIndex = change.rowId as number;
        const fieldId = change.columnId;

        if (change.type === 'text') {
            prevOffers[offerIndex][fieldId] = change.newCell.text;
        }

        if (change.type === 'number') {
            prevOffers[offerIndex][fieldId] = change.newCell.value;
        }
    });
    return [...prevOffers];
};

export const OffersSpreadsheet = ({offers, handleTableDataChanges}: OffersSpreadsheetProps) => {
    const rows = getRows(offers);
    const [columns, setColumns] = useState<Column[]>(getColumns());

    const handleColumnResize = (ci: Id, width: number) => {
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = {
                ...resizedColumn,
                width,
            };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    };

    return (
        <ReactGrid
            rows={rows}
            columns={columns}
            enableColumnSelection
            enableRowSelection
            enableRangeSelection
            onColumnResized={handleColumnResize}
            onCellsChanged={handleTableDataChanges}
        />
    );
};
