import {v1_AffiliateProgramType_select_column} from '../../generated/types';

const AffiliateProgramType = {
    ...v1_AffiliateProgramType_select_column,
    res: 'v1_AffiliateProgramType',
    comp: {},
    rel: {},
};

export default AffiliateProgramType;
