import {v1_process_rate_select_column} from '../../generated/types';

const ProcessRate = {
    ...v1_process_rate_select_column,
    res: 'v1_process_rate',
    comp: {},
    rel: {},
    label: 'Processes',
};

export default ProcessRate;
