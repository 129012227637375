import {useEffect} from 'react';
import {useGetIdentity, useGetList, useStoreContext} from 'react-admin';
import {IEvent} from '../models/event.model';

const MAX_PREFERENCES = 1000;

const useGetPreferences = () => {
    const store = useStoreContext();
    const {data: identity} = useGetIdentity();
    const userId = identity?.id;
    const {data, isLoading} = useGetList<IEvent>('v1_Preferences', {
        filter: {created_by: userId},
        pagination: {page: 1, perPage: MAX_PREFERENCES},
    });

    useEffect(() => {
        if (isLoading || !userId) {
            return;
        }
        data?.forEach((p) => {
            store.setItem(p.path, p.preference);
        });
    }, [isLoading, data, store, userId]);
};
export default useGetPreferences;
