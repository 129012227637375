import {v1_Posts_select_column} from '../../generated/types';

const Post = {
    ...v1_Posts_select_column,
    res: 'v1_Posts',
    comp: {
        Project: '_Project',
        TotalPriceManager: 'comp_total_price_manager',
        TotalPriceWriter: 'comp_total_price_writer',
    },
    rel: {
        Approver: 'Approver',
        ApprovedBy: 'ApprovedBy',
        Advertiser: 'Advertiser',
        DeclinedBy: 'DeclinedBy',
        Campaign: 'Campaign',
        ContentLinks: 'ContentLinks',
        MarketplaceProject: 'MarketplaceProject',
        Offer: 'Offer',
        Supplier: 'Supplier',
        PostContentStatus: 'ContentStatus',
        PostLinkStatus: 'PostLinksStatus',
        PostOnlineStatus: 'OnlineStatus',
        PostOutreachStatus: 'OutreachStatus',
        PostStatus: 'PostStatus',
        Writer: 'Writer',
        Website: 'Website',
        RequestedBy: 'Requested_By',
        RequestedFrom: 'RequestedFrom',
    },
    label: 'Posts',
    entity: 'Post',
    listParams: 'v1_Posts.listParams',
};

export default Post;
