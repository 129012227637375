import {gql, useMutation} from '@apollo/client';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {CellChange, TextCell} from '@silevis/reactgrid';
import {useEffect, useState} from 'react';
import {Button, Form, useNotify} from 'react-admin';
import {ROUTES} from '../../../config/statuses';
import {ImportCsv} from '../../common/importScv/importCsv';
import LanguageNotificationBox from '../../common/languageNotificationBox';
import {Errors} from './errors';
import {ImportCSVSummary} from './ImportCSVSummary';
import {
    applyChangesToSpreadsheet,
    getWebsitesStatistics,
    StatisticsSpreadsheet,
    WebsiteStatisticsData,
} from './statisticsSpreadsheet';

export function ImportStatistics() {
    const [websitesStatistics, setWebsitesStatistics] = useState<WebsiteStatisticsData[]>(getWebsitesStatistics());
    const [tableErrors, setTableErrors] = useState([]);
    const handleTableDataChanges = (changes: CellChange<TextCell>[]) => {
        setWebsitesStatistics((prevWebsitesStatistics) => applyChangesToSpreadsheet(changes, prevWebsitesStatistics));
    };

    const notify = useNotify();

    const IMPORT_WEBSITE_STATISTICS_MUTATION = gql`
        mutation importWebsitesStatisticsMutation($websites: [WebsiteStatisticsInput]!) {
            importWebsitesStatistics(websites: $websites) {
                affected_rows
                returning {
                    id
                }
                errors {
                    message
                    cause {
                        id
                        new_name
                        language_id
                        new_dtoxrisk
                    }
                }
            }
        }
    `;
    const [importWebsitesStatisticsMutation, {data: dataApollo, loading: loadingApollo, error: errorApollo}] =
        useMutation(IMPORT_WEBSITE_STATISTICS_MUTATION, {ignoreResults: false});

    const handleSubmit = async () => {
        setTableErrors([]);
        const statsToImport = websitesStatistics.filter((stat) => stat.new_name.length > 0);

        if (statsToImport.length > 0) {
            const variables = {
                variables: {
                    websites: statsToImport,
                },
            };
            await importWebsitesStatisticsMutation(variables);
        }
    };

    useEffect(() => {
        if (dataApollo) {
            const {affected_rows, returning, errors} = dataApollo.importWebsitesStatistics;

            if (errors) {
                setTableErrors(errors.cause);

                notify('adzz.importWebsitesStatistics.errorApollo', {
                    messageArgs: {err: errors.message},
                    type: 'error',
                });
                return;
            }

            const ids = returning.map((website: any) => website.id);

            notify('adzz.importWebsitesStatistics.created', {
                messageArgs: {
                    affected_rows,
                    ids,
                },
                type: 'success',
            });
        }
    }, [dataApollo, notify]);

    useEffect(() => {
        if (errorApollo) {
            notify('adzz.importWebsitesStatistics.errorApollo', {
                messageArgs: {err: errorApollo.message},
                type: 'error',
            });
        }
    }, [errorApollo, notify]);

    return (
        <Card>
            <CardContent sx={{display: 'flex'}}>
                <Grid container direction="column" sx={{mt: 3}}>
                    <Typography variant="h3" sx={{mb: 3}}>
                        Import Statistics
                    </Typography>
                    <Grid>
                        <Errors errors={tableErrors} />
                    </Grid>
                    <Form onSubmit={handleSubmit} id="importStatisticsForm">
                        <Grid sx={{mb: 3}}>
                            <StatisticsSpreadsheet
                                websitesStatistics={websitesStatistics}
                                handleTableDataChanges={handleTableDataChanges}
                            />
                        </Grid>
                        <Grid>
                            <Button
                                type="submit"
                                disabled={loadingApollo ? true : false}
                                variant="contained"
                                label={loadingApollo ? 'Importing...' : 'Import statistics'}
                                form="importStatisticsForm"
                                sx={{mr: 3}}
                            />
                        </Grid>
                    </Form>
                </Grid>
                <Box display={'flex'} flexDirection={'column'} width={180} pt={3} gap={2}>
                    <ImportCsv
                        Summary={({result, setResult, toggleDialog}) => {
                            return (
                                <ImportCSVSummary result={result} setResult={setResult} toggleDialog={toggleDialog} />
                            );
                        }}
                        title="Import website statistics csv"
                        buttonLabel="Import statistics"
                        apiRoute={ROUTES.WEBSITE_STATISTICS_IMPORT_CSV}
                    />
                    <LanguageNotificationBox />
                </Box>
            </CardContent>
        </Card>
    );
}
