import React from 'react';
import {NumberField, ReferenceField, TextField, WrapperField} from 'react-admin';
import {COL, RES} from '../../config/statuses';

interface ICurrencyAmountProps {
    label: string;
    sourceCurrency: string;
    sourceAmount: string;
    sortBy?: string;
    reference?: string;
    sourceSymbol?: string;
}

const CurrencyAmountWrapper = ({
    label,
    sourceCurrency,
    sourceAmount,
    sortBy,
    reference = RES.CURRENCY,
    sourceSymbol = COL[RES.CURRENCY].CurrencySymbol,
}: ICurrencyAmountProps) => {
    return (
        <WrapperField label={label} sortBy={sortBy}>
            <ReferenceField link={false} source={sourceCurrency} reference={reference}>
                <TextField source={sourceSymbol} />
            </ReferenceField>
            <NumberField source={sourceAmount} />
        </WrapperField>
    );
};

export default CurrencyAmountWrapper;
