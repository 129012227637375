import {Button} from '@mui/material';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material/';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {OFFER_CSV_TEMPLATE_LINK, ROUTES} from '../../../config/statuses';
import {ImportCsv} from '../../common/importScv/importCsv';
import LanguageNotificationBox from '../../common/languageNotificationBox';
import {BulkCreateFormFields} from './bulkCreateFormFields';
import {CheckDuplicatesForm} from './checkDuplicatesForm';
import {CreateBulkForm} from './createBulkForm';
import {DefaultValuesNotification} from './DefaultValuesNotification';
import {ImportCsvSummary} from './importCsvSummary';
import {getOffers} from './spreadsheet';
import type {CheckedOffersData, OffersData} from './types';

export function OfferCreateBulk() {
    const [offers, setOffers] = useState<OffersData[]>(getOffers());
    const [checkedOffers, setCheckedOffers] = useState<CheckedOffersData[]>([]);
    const [displaySections, setDisplaySections] = useState({
        checking: true,
        submit: false,
    });

    return (
        <Card>
            <CardContent sx={{display: 'flex'}}>
                <Grid container direction="column" sx={{mt: 3}}>
                    <Grid container direction="row" gap={10}>
                        <Typography variant="h3">Bulk Create Offers</Typography>
                    </Grid>
                    {displaySections.checking && (
                        <CheckDuplicatesForm
                            setDisplaySections={setDisplaySections}
                            offers={offers}
                            setOffers={setOffers}
                            setCheckedOffers={setCheckedOffers}
                        />
                    )}
                    {displaySections.submit && (
                        <CreateBulkForm
                            setDisplaySections={setDisplaySections}
                            checkedOffers={checkedOffers}
                            setOffers={setOffers}
                            setCheckedOffers={setCheckedOffers}
                        />
                    )}
                </Grid>
                <Box display={'flex'} flexDirection={'column'} width={180} pt={3} gap={2}>
                    <ImportCsv
                        buttonLabel="Import offers"
                        Summary={ImportCsvSummary}
                        apiRoute={ROUTES.OFFER_IMPORT_CSV}
                    >
                        {<BulkCreateFormFields />}
                    </ImportCsv>
                    <Button
                        sx={{
                            lineHeight: 1.5,
                            padding: '4px 5px',
                            boxShadow: 2,
                            gap: 0.5,
                            textAlign: 'center',
                        }}
                        component={Link}
                        to={OFFER_CSV_TEMPLATE_LINK}
                        target="_blank"
                    >
                        <Typography sx={{fontSize: 13}}> Download CSV Template</Typography>
                    </Button>
                    <LanguageNotificationBox defaultValuesNotification={<DefaultValuesNotification />} />
                </Box>
            </CardContent>
        </Card>
    );
}
