export const COLORS = {
    ORANGE: '#ff9800',
    RED: '#b71c1c',
    DARK_RED: '#8b0000',
    WHITE: '#ffffff',
    BLACK: '#000000',
    GREEN: '#4caf50',
    DEEP_GREEN: '#328c36',
    LIGHT_BLUE: '#03a9f4',
    BLUE: '#0276ab',
    LIGHT_GREY: '#f7f9fC',
};
