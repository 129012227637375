import React from 'react';
import {Datagrid, DateField, FunctionField, NumberField, ReferenceField, TextField} from 'react-admin';
import {v1_Campaign} from '../../generated/types';
import {completionRateText} from '../../utility/helpers';
import {TruncatedTooltipField, UserField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';
import ProgressWithLabel from '../common/progressBarWithLabel';

const RequestedContentFields = () => {
    return (
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <LinkToNewTab propertyName="id" resource="v1_Posts" />
            <LinkToRecordField source="id" label="Post ID" redirectToShow={false} />
            <TextField label="Source URL" showPropertyName={true} source="new_name" />
            <DateField source="new_RequestedOn" label="Requested On" locales="en-GB" />
            <ReferenceField
                label="Campaign"
                source="campaign_id"
                reference="v1_Campaign"
                link="edit"
                sortBy="Campaign.new_name"
            >
                <TextField source="new_name" />
            </ReferenceField>
            <ReferenceField
                label="Project"
                source="campaign_id"
                reference="v1_Campaign"
                link={false}
                sortBy="Campaign.Project.new_name"
            >
                <ReferenceField reference="v1_Project" source="project_id" link="edit">
                    <TextField source="new_name" />
                </ReferenceField>
            </ReferenceField>
            <ReferenceField
                source="website_id"
                link="edit"
                label="Website"
                reference="v1_Website"
                sortBy="Website.new_name"
            >
                <TextField source="new_name" />
            </ReferenceField>
            <ReferenceField label="Completion Rate This Month" source="campaign_id" reference="v1_Campaign" link="edit">
                <FunctionField
                    label="Completion Rate This Month"
                    sortBy="CompletionRateThisMonth"
                    render={(record: v1_Campaign) => {
                        const completionRateThisMonth = Number(record.CompletionRateThisMonth);
                        const thisMonthPosts = Number(record.ThisMonthPosts);
                        const newMonthlyTarget = Number(record.new_MonthlyTarget);

                        return (
                            <ProgressWithLabel
                                value={completionRateThisMonth}
                                displayValue={completionRateText(
                                    thisMonthPosts,
                                    newMonthlyTarget,
                                    completionRateThisMonth
                                )}
                            />
                        );
                    }}
                />
            </ReferenceField>
            <UserField label="To Be Approved By" source="approver_id" sortBy="Approver.name" />
            <UserField label="Request Sent By" source="requestedby_id" sortBy="Requested_By.name" />
            <TruncatedTooltipField limit={100} source="requestDetails" label="Request Details" />
            <NumberField source="new_RequestedMinWords" label="Words Requested" />
            <DateField source="new_Contentduedate" label="Due Date" locales="en-GB" />
        </Datagrid>
    );
};
export const requestedContentSearchFields =
    'requestDetails,' +
    'Campaign#new_name@_ilike,' +
    'Campaign#Project#new_name@_ilike,' +
    'Approver#name@_ilike,' +
    'RequestedFrom#name@_ilike,' +
    'Website#new_name@_ilike';
export default RequestedContentFields;
