import {v1_BonusesToTypes_select_column} from '../../generated/types';

const BonusesToTypes = {
    ...v1_BonusesToTypes_select_column,
    res: 'v1_BonusesToTypes',
    comp: {},
    rel: {
        BonusType: 'BonusTypes',
    },
};

export default BonusesToTypes;
