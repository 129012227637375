import {v1_ContentLink_select_column} from '../../generated/types';

const ContentLink = {
    ...v1_ContentLink_select_column,
    res: 'v1_ContentLink',
    comp: {},
    rel: {},
};

export default ContentLink;
