import {v1_Page_select_column} from '../../generated/types';

const Page = {
    ...v1_Page_select_column,
    res: 'v1_Page',
    comp: {},
    rel: {},
    label: 'Pages',
};

export default Page;
