import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useEffect, useState} from 'react';
import {FileField, FileInput, Form, useNotify} from 'react-admin';
import {COL, RES} from '../../../config/statuses';
import {sendPostToApi} from '../../../services/api';
import type {CsvImportResult, ImportCsvDialogContentProps} from './types';

export const ImportCsvDialogContent = ({
    setResult,
    children,
    title,
    buttonLabel,
    apiRoute,
    getIsInProcess,
}: ImportCsvDialogContentProps) => {
    const notify = useNotify();
    const [isRequestProcessing, setIsRequestProcessing] = useState(false);
    useEffect(() => {
        getIsInProcess(isRequestProcessing);
        return () => {
            getIsInProcess(false);
        };
    }, [isRequestProcessing, getIsInProcess]);
    const importCsvHandler = async ({file, supplier_id}: any) => {
        try {
            setIsRequestProcessing(true);
            const formData = new FormData();
            const TMP_FILE_FIELD = 'csv_file';
            formData.append(TMP_FILE_FIELD, file.rawFile);
            formData.append(COL[RES.OFFER].supplier_id, supplier_id);
            const result: CsvImportResult = await sendPostToApi(apiRoute, formData);
            setResult(result);
        } catch (error: any) {
            notify(error?.message, {type: 'error'});
        } finally {
            setIsRequestProcessing(false);
        }
    };
    return (
        <>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Form onSubmit={importCsvHandler} id="importCsvForm">
                    {children}
                    <FileInput source="file" accept=".csv">
                        <FileField source="src" title="title" />
                    </FileInput>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type="submit" form="importCsvForm" disabled={isRequestProcessing}>
                    {buttonLabel}
                </Button>
            </DialogActions>
        </>
    );
};
