import {Box, ClickAwayListener, Typography} from '@mui/material';
import {THEME_COLORS} from '../theme';
import {useContext} from 'react';
import {NotificationContext, NotificationMessage} from '../../contexts/notificationContext';
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationCard} from './notificationCard';
import {nhost} from '../../authorization/nhost';

export const NotificationModal = () => {
    const {notificationList, handleCloseNotificationModal} = useContext(NotificationContext);
    const nhostUser = nhost.auth.getUser();

    if (!nhostUser) return <CircularProgress />;

    return (
        <ClickAwayListener onClickAway={handleCloseNotificationModal}>
            <Box>
                <Box sx={{height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography sx={{color: THEME_COLORS.WHITE, textAlign: 'center', fontSize: '16px', padding: 3}}>
                        Notifications
                    </Typography>
                </Box>
                <Box
                    sx={{
                        bgcolor: THEME_COLORS.MATE_GRAY,
                        borderRadius: '0 0 8px 8px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        maxHeight: '300px',
                    }}
                >
                    {notificationList.length ? (
                        notificationList.map((notification: NotificationMessage) => (
                            <NotificationCard key={notification._id} notification={notification} userId={nhostUser.id} />
                        ))
                    ) : (
                        <EmptyNotificationList />
                    )}
                </Box>
            </Box>
        </ClickAwayListener>
    );
};

const EmptyNotificationList = () => {
    return (
        <Box
            sx={{
                padding: '10px',
                display: 'flex',
                backgroundColor: THEME_COLORS.WHITE,
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out',
                gap: 1,
                ':hover': {transform: 'scale(1.01)'},
            }}
        >
            <Typography
                sx={{
                    color: THEME_COLORS.BLACK,
                    fontSize: '14px',
                    lineHeight: '14px',
                    paddingX: 5,
                }}
            >
                No notifications
            </Typography>
        </Box>
    );
};
