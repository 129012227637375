import axios from 'axios';
import {nhost} from '../authorization/nhost';
import {CRM_USER, HttpMethod} from '../config/statuses';
import {getApiHref, getNotificationApiHref} from '../utility/helpers';

export const sendPostToApi = async (endpoint: string, data: any, method = HttpMethod.POST): Promise<any> => {
    const config = createHttpClientConfig();
    try {
        const response = await axios({
            method,
            url: getApiHref(endpoint),
            data,
            headers: config.headers,
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const sendToNotificationApi = async ({
    endpoint,
    data,
    method,
}: {
    endpoint: string;
    data?: any;
    method: HttpMethod;
}): Promise<any> => {
    const config = createHttpClientConfig();
    try {
        const response = await axios({
            method,
            url: getNotificationApiHref(endpoint),
            data,
            headers: config.headers,
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const createHttpClientConfig = () => {
    const bearer = nhost.auth.getAccessToken() as string;
    return {
        headers: {
            authorization: `Bearer ${bearer}`,
            'x-hasura-role': CRM_USER,
        },
    };
};
