import {v1_Contacts_Roles_select_column} from '../../generated/types';

const ContactRole = {
    ...v1_Contacts_Roles_select_column,
    res: 'v1_Contacts_Roles',
    comp: {},
    rel: {},
};

export default ContactRole;
