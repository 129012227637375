import {Card, Grid} from '@mui/material';
import {IfCanAccess} from '@react-admin/ra-rbac/esm/src/IfCanAccess';
import React from 'react';
import {Loading, useGetIdentity, useStore} from 'react-admin';
import {CONTENT_STATUS, STATE_CODE} from '../../config/statuses';
import {useGetActiveUsers} from '../../hooks/useGetActiveUsers';
import AutocompleteWrapper from '../common/autocompleteWrapper';
import DashboardListWrapper from '../common/dashboardListWrapper';
import PostsToApproveFields, {postsToApproveSearchFields} from './contentManagerApprovedPosts';
import RequestedContentFields, {requestedContentSearchFields} from './contentManagerRequestedContentFields';

const ContentManagerList = (props: any) => {
    const {data: identity} = useGetIdentity();
    const userId = identity?.id;

    const [selectedUserId, setSelectedUserId] = useStore('pmDashboardSelectedUser', userId);
    const {data: activeUsers, isLoading} = useGetActiveUsers();

    if (!userId) return <Loading />;

    return (
        <Grid container display="flex" flexDirection="column" sx={{mt: '20px'}}>
            <Grid item alignSelf="flex-end">
                {isLoading ? (
                    <Loading />
                ) : (
                    <IfCanAccess resource={'pm-dashboard'} action="selectUser">
                        <AutocompleteWrapper
                            disableClear={true}
                            styles={{width: 300}}
                            selectedOption={selectedUserId}
                            label="User"
                            options={activeUsers}
                            labelProp="name"
                            onOptionSelected={setSelectedUserId}
                            isRequired={false}
                        />
                    </IfCanAccess>
                )}
            </Grid>
            <Grid item>
                <Card {...props}>
                    <DashboardListWrapper
                        cardTitle="Posts to Approve"
                        title=" "
                        resource="v1_Posts"
                        filter={{
                            approver_id: selectedUserId,
                            new_ContentOrderStatus: CONTENT_STATUS.WAITING_APPROVAL,
                            statecode: STATE_CODE.ENABLED,
                        }}
                        searchFields={postsToApproveSearchFields}
                        sort={{
                            field: 'new_SenttoApproval',
                            order: 'DESC',
                        }}
                    >
                        <PostsToApproveFields />
                    </DashboardListWrapper>
                    <DashboardListWrapper
                        cardTitle="Requested Content"
                        title="Contact Manager Dashboard"
                        resource="v1_Posts"
                        filter={{
                            requestedfrom_id: selectedUserId,
                            new_ContentOrderStatus: CONTENT_STATUS.CONTENT_REQUEST,
                            statecode: STATE_CODE.ENABLED,
                        }}
                        searchFields={requestedContentSearchFields}
                        sort={{
                            field: 'new_RequestedOn',
                            order: 'DESC',
                        }}
                    >
                        <RequestedContentFields />
                    </DashboardListWrapper>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ContentManagerList;
