import {v1_ReceiptStatus_select_column} from '../../generated/types';

const ReceiptStatus = {
    ...v1_ReceiptStatus_select_column,
    res: 'v1_ReceiptStatus',
    comp: {},
    rel: {},
};

export default ReceiptStatus;
