import {v1_LandingPagePriority_select_column} from '../../generated/types';

const LandingPagePriority = {
    ...v1_LandingPagePriority_select_column,
    res: 'v1_LandingPagePriority',
    comp: {},
    rel: {},
};

export default LandingPagePriority;
