import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {useBasename} from 'ra-core/src/routing/useBasename';
import React from 'react';
import {Link} from 'react-admin';
import {RES} from '../../../config/statuses';
import {v1_Offer} from '../../../generated/types';
import {CsvOfferDuplicate, ImportCsvSummaryProps} from '../../common/importScv/types';

export function ImportCsvSummary({result, setResult, toggleDialog}: ImportCsvSummaryProps) {
    const {createdOffers, offersDuplicates} = result;
    const closeModal = () => {
        toggleDialog();
        setResult(null);
    };
    const basename = useBasename();
    return (
        <>
            <DialogTitle>Import CSV Summary</DialogTitle>
            <DialogContent>
                {createdOffers.length > 0 && (
                    <Box>
                        <Typography>Created offers</Typography>
                        <Table
                            sx={{
                                mb: 2,
                                '& .MuiTableCell-root': {
                                    padding: '6px',
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow sx={{}}>
                                    <TableCell>Offer ID</TableCell>
                                    <TableCell>Link to Offer</TableCell>
                                    <TableCell>Website ID</TableCell>
                                    <TableCell>Link to Website Record</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody
                                sx={{
                                    overflowY: 'scroll',
                                    maxHeight: '500px',
                                }}
                            >
                                {createdOffers.map((offer: v1_Offer) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{offer.id}</TableCell>
                                            <TableCell>
                                                {'Click '}
                                                <Link to={`${basename}/${RES.OFFER}/${offer.id}`} target="_blank">
                                                    here
                                                </Link>
                                            </TableCell>
                                            <TableCell>{offer.website_id}</TableCell>
                                            <TableCell>
                                                {'Click '}
                                                <Link
                                                    to={`${basename}/${RES.WEBSITE}/${offer.website_id}`}
                                                    target="_blank"
                                                >
                                                    here
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                )}
                {offersDuplicates.length > 0 && (
                    <>
                        <Typography>Duplicated offers (not created in system):</Typography>
                        {offersDuplicates.map((item: CsvOfferDuplicate) => {
                            return (
                                <Typography key={item.line}>
                                    Line of CSV {item.line}:{' '}
                                    <Link to={`/${RES.OFFER}/${item.data.id}`} target="_blank">
                                        {item.data.name}
                                    </Link>
                                </Typography>
                            );
                        })}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={closeModal}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
}
