import {CellChange, Column, Id, NumberCell, ReactGrid, Row, TextCell} from '@silevis/reactgrid';
import './statisticsSpreadsheet.css';
import {useState} from 'react';
import '@silevis/reactgrid/styles.css';

const formatNumbers = Intl.NumberFormat('en-US', {
    useGrouping: false,
});

export interface WebsiteStatisticsData {
    new_name: string;
    language_id: string;
    linked_domains: number;

    ip_adress: string;
    keywords: number;
    traffic: number;
    url_rating: number;
    new_DomainRatingMOZ: number; // float

    referring_domains_total: number;
    new_dtoxrisk: string;
    powerTrustDom: number;
    new_Powerdomain: number; // float
    new_trustdom: number;
    new_TitleHome: string;
    // website_topic_id: number; // m2m
}

interface StatisticsSpreadsheetProps {
    websitesStatistics: WebsiteStatisticsData[];
    handleTableDataChanges: any;
}

const headerRow: Row[] = [
    {
        rowId: 'subheader',
        cells: [
            {
                type: 'header',
                text: 'Domain',
            },
            {
                type: 'header',
                text: 'Language',
            },
            {
                type: 'header',
                text: 'Linked Domains',
            },

            {
                type: 'header',
                text: 'IP',
            },
            {
                type: 'header',
                text: 'Keywords',
            },
            {
                type: 'header',
                text: 'Traffic',
            },
            {
                type: 'header',
                text: 'URL Rating',
            },
            {
                type: 'header',
                text: 'Domain Rating',
            },

            {
                type: 'header',
                text: 'Referring Domains Total',
            },
            {
                type: 'header',
                text: 'DTOX Risk',
            },
            {
                type: 'header',
                text: 'Power trust Dom',
            },
            {
                type: 'header',
                text: 'Power-dom',
            },
            {
                type: 'header',
                text: 'Trust-dom',
            },
            {
                type: 'header',
                text: 'Title-home',
            },
            // {type: 'header', text: 'Website Topic'},
        ],
    },
];

const getRows = (statistics: WebsiteStatisticsData[]): Row[] => [
    ...headerRow,
    ...statistics.map<Row>((stat, idx) => ({
        rowId: idx,
        cells: [
            {
                type: 'text',
                text: stat.new_name,
            },
            {
                type: 'text',
                text: stat.language_id,
            },
            {
                type: 'number',
                value: stat.linked_domains,
                format: formatNumbers,
            },
            {
                type: 'text',
                text: stat.ip_adress,
            },
            {
                type: 'number',
                value: stat.keywords,
                format: formatNumbers,
            },
            {
                type: 'number',
                value: stat.traffic,
                format: formatNumbers,
            },
            {
                type: 'number',
                value: stat.url_rating,
                format: formatNumbers,
            },
            {
                type: 'number',
                value: stat.new_DomainRatingMOZ,
                format: formatNumbers,
            },
            {
                type: 'number',
                value: stat.referring_domains_total,
                format: formatNumbers,
            },
            {
                type: 'text',
                text: stat.new_dtoxrisk,
            },
            {
                type: 'number',
                value: stat.powerTrustDom,
                format: formatNumbers,
            },
            {
                type: 'number',
                value: stat.new_Powerdomain,
                format: formatNumbers,
            },
            {
                type: 'number',
                value: stat.new_trustdom,
                format: formatNumbers,
            },
            {
                type: 'text',
                text: stat.new_TitleHome,
            },
            // {type: 'number', value: stat.website_topic_id, hideZero: true, format: formatNumbers},
        ],
    })),
];

const getColumns = (): Column[] => [
    {
        columnId: 'new_name',
        resizable: true,
    },
    {
        columnId: 'language_id',
        resizable: true,
    },
    {
        columnId: 'linked_domains',
        resizable: true,
    },
    {
        columnId: 'ip_adress',
        resizable: true,
    },
    {
        columnId: 'keywords',
        resizable: true,
    },
    {
        columnId: 'traffic',
        resizable: true,
    },
    {
        columnId: 'url_rating',
        resizable: true,
    },
    {
        columnId: 'new_DomainRatingMOZ',
        resizable: true,
    },
    {
        columnId: 'referring_domains_total',
        resizable: true,
        width: 210,
    },
    {
        columnId: 'new_dtoxrisk',
        resizable: true,
    },
    {
        columnId: 'powerTrustDom',
        resizable: true,
    },
    {
        columnId: 'new_Powerdomain',
        resizable: true,
    },
    {
        columnId: 'new_trustdom',
        resizable: true,
    },
    {
        columnId: 'new_TitleHome',
        resizable: true,
    },
    // {columnId: 'website_topic_id', resizable: true},
];

export const getWebsitesStatistics = (): WebsiteStatisticsData[] => {
    const maxRows = 50;
    const result = [];
    for (let r = 1; r <= maxRows; r++) {
        result.push({
            new_name: '',
            language_id: '',
            linked_domains: NaN,
            ip_adress: '',
            keywords: NaN,
            traffic: NaN,
            url_rating: NaN,
            new_DomainRatingMOZ: NaN,
            referring_domains_total: NaN,
            new_dtoxrisk: '',
            powerTrustDom: NaN,
            new_Powerdomain: NaN,
            new_trustdom: NaN,
            new_TitleHome: '', // website_topic_id: 0,
        });
    }
    return result;
};

export const applyChangesToSpreadsheet = (
    changes: CellChange<TextCell | NumberCell>[],
    prevWebsitesStatistics: any[]
): WebsiteStatisticsData[] => {
    changes.forEach((change) => {
        const rowIndex = change.rowId as number;
        const fieldName = change.columnId;

        if (change.type === 'number') {
            prevWebsitesStatistics[rowIndex][fieldName] = change.newCell.value;
        }
        if (change.type === 'text') {
            prevWebsitesStatistics[rowIndex][fieldName] = change.newCell.text;
        }
    });
    return [...prevWebsitesStatistics];
};

export const StatisticsSpreadsheet = ({websitesStatistics, handleTableDataChanges}: StatisticsSpreadsheetProps) => {
    const rows = getRows(websitesStatistics);
    const [columns, setColumns] = useState<Column[]>(getColumns());

    const handleColumnResize = (ci: Id, width: number) => {
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = {
                ...resizedColumn,
                width,
            };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    };

    return (
        <ReactGrid
            rows={rows}
            columns={columns}
            enableColumnSelection
            enableRowSelection
            enableRangeSelection
            onColumnResized={handleColumnResize}
            onCellsChanged={handleTableDataChanges}
        />
    );
};
