import { v1_PurchaseOrderStatus_select_column } from '../../generated/types';

const PurchaseOrderStatus = {
    ...v1_PurchaseOrderStatus_select_column,
    res: 'v1_PurchaseOrderStatus',
    comp: {},
    rel: {},
};

export default PurchaseOrderStatus;
