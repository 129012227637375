import {v1_new_paymentmethodBase_select_column} from '../../generated/types';

const PaymentMethod = {
    ...v1_new_paymentmethodBase_select_column,
    res: 'v1_new_paymentmethodBase',
    comp: {},
    rel: {},
    label: 'Payment Methods',
    entity: 'Payment Method',
};

export default PaymentMethod;
