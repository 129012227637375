import {v1_PostsLinkStatus_select_column} from '../../generated/types';

const PostLinkStatus = {
    ...v1_PostsLinkStatus_select_column,
    res: 'v1_PostsLinkStatus',
    comp: {},
    rel: {},
};

export default PostLinkStatus;
