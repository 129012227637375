import {v1_AccountBase_select_column} from '../../generated/types';

const Company = {
    ...v1_AccountBase_select_column,
    res: 'v1_AccountBase',
    comp: {},
    rel: {
        AffiliateOrder: 'AffOrders',
        Brand: 'new_brandBases',
    },
    label: 'Company',
};

export default Company;
