import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const useScrollToTop = () => {
    const location = useLocation();
    useEffect(() => {
        window.focus();
        window.scrollTo(0, 0);
    }, [location]);
};
