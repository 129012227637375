import {v1_Website_select_column} from '../../generated/types';

const Website = {
    ...v1_Website_select_column,
    res: 'v1_Website',
    comp: {
        AllSitePosts: 'AllSitePosts',
        ApprovedPosts: 'ApprovedPosts',
        InProgressPosts: 'InProgressPosts',
        LivePosts: 'LivePosts',
        LostPosts: 'LostPosts',
        PostedPosts: 'PostedPosts',
        DomainAge: 'comp_domain_age',
        LdRdRatio: 'comp_ld_rd_ratio',
        RelatedOffers: 'comp_related_offers',
    },
    rel: {
        User: 'Creator',
        WebsiteDtoxRisk: 'WebsiteDToxRisk',
        PostNiche: 'PostNiche',
        WebsitePlatform: 'Website_Platform',
        WebsiteQualityScore: 'WebsiteQualityScore',
        Language: 'Language',
    },
    label: 'Websites',
    entity: 'Website',
};

export default Website;
