import GoogleIcon from '@mui/icons-material/Google';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {Box} from '@mui/material';
import {Button, Login, useAuthProvider} from 'react-admin';
import logo from '../../layout/images/adzz-logo.png';

const NhostLogin = (props: {isClicked: any; setIsClicked: any}) => {
    const {nhostLogin} = useAuthProvider();

    const handleLogin = async () => {
        props.setIsClicked(true);
        nhostLogin();
    };

    const GoogleLoginButton = () => {
        return (
            <Button
                style={{marginTop: 20}}
                size="large"
                label={props.isClicked ? 'Loading...' : 'Click to Login'}
                variant="contained"
                onClick={handleLogin}
                startIcon={props?.isClicked ? <HourglassEmptyIcon /> : <GoogleIcon />}
                disabled={props.isClicked}
            />
        );
    };

    return (
        <Login>
            <Box display="flex" alignItems="center" flexDirection="column" paddingBottom="30px">
                <img src={logo} alt="logo" style={{maxWidth: 150}} />
                <GoogleLoginButton />
            </Box>
        </Login>
    );
};

export default NhostLogin;
