import ContentCreate from '@mui/icons-material/Create';
import {Grid, IconButton} from '@mui/material';
import {ReferenceInput, ReferenceInputProps, useCreatePath} from 'react-admin';
import {useWatch} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {DisplayProps} from '../../models/input-field.models';

export const ReferenceInputWithLink = (props: ReferenceInputProps & DisplayProps) => {
    const createPath = useCreatePath();
    const referenceId = useWatch({
        name: props.source,
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <ReferenceInput {...props} />
            </Grid>
            {!props?.hideLink && (
                <Grid item xs="auto">
                    <IconButton
                        aria-label="Edit reference record"
                        component={Link}
                        to={createPath({
                            type: 'edit',
                            resource: props.reference,
                            id: referenceId,
                        })}
                        target="_blank"
                        onClick={stopPropagation}
                        disabled={!referenceId}
                        sx={{mt: 1}}
                    >
                        <ContentCreate />
                    </IconButton>
                    {props?.urlLinkIcon}
                </Grid>
            )}
        </Grid>
    );
};

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();
