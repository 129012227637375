import {v1_Teams_select_column} from '../../generated/types';

const Team = {
    ...v1_Teams_select_column,
    res: 'v1_Teams',
    comp: {},
    rel: {},
    label: 'Teams',
};

export default Team;
