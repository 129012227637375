import {v1_TransactionCurrencyBase_select_column} from '../../generated/types';

const Currency = {
    ...v1_TransactionCurrencyBase_select_column,
    res: 'v1_TransactionCurrencyBase',
    comp: {},
    rel: {},
    label: 'Currency',
};

export default Currency;
