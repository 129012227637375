import {v1_TrackerDeal_select_column} from '../../generated/types';

const TrackerDeal = {
    ...v1_TrackerDeal_select_column,
    res: 'v1_TrackerDeal',
    comp: {},
    rel: {},
    label: 'Deal',
};

export default TrackerDeal;
