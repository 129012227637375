import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import {IfCanAccess} from '@react-admin/ra-rbac/esm/src/IfCanAccess';
import React from 'react';
import {Loading, SORT_DESC, useGetIdentity, useStore} from 'react-admin';
import {ACTIONS, CONTENT_STATUS, OUTREACH_STATUS, POST_STATUS, STATE_CODE, STRATEGY_TYPE} from '../../config/statuses';
import {useGetActiveUsers} from '../../hooks/useGetActiveUsers';
import AutocompleteWrapper from '../common/autocompleteWrapper';
import DashboardListWrapper from '../common/dashboardListWrapper';
import DashboardCampaigns from '../generic/dashboardCampaigns';
import ContentWritingFields, {contentWritingSearchFields} from './outreachDashboardContentWritingFields';
import ReadyToPublishFields, {readyToPublishSearchFields} from './outreachDashboardReadyToPublishFields';
import SentToPublisherFields, {sentToPublisherSearchFields} from './outreachDashboardSentToPublisherFields';
import Model from '../../models/db/models';
import OPERATOR from '../../models/db/operators';

const OutreachDashboardList = (props: any) => {
    const {data: identity, isLoading: identityLoading} = useGetIdentity();
    const userId = identity?.id;

    const [selectedUserId, setSelectedUserId] = useStore('outreachDashboardSelectedUser', userId);
    const {data: activeUsers, isLoading: activeUsersLoading} = useGetActiveUsers();

    if (!userId || identityLoading) return <Loading />;

    return (
        <Grid container display="flex" flexDirection="column" sx={{mt: '20px'}}>
            <Grid item alignSelf="flex-end">
                {activeUsersLoading ? (
                    <Loading />
                ) : (
                    <IfCanAccess resource={'outreach-dashboard'} action={ACTIONS.SELECT_USER}>
                        <AutocompleteWrapper
                            disableClear={true}
                            styles={{width: 300}}
                            selectedOption={selectedUserId}
                            label="User"
                            options={activeUsers}
                            labelProp="name"
                            onOptionSelected={setSelectedUserId}
                            isRequired={false}
                        />
                    </IfCanAccess>
                )}
            </Grid>
            <Grid item>
                <Card {...props}>
                    <DashboardListWrapper
                        cardTitle="Sent To Publisher"
                        title="Outreach Dashboard"
                        resource={Model.Post.res}
                        filter={{
                            statecode: STATE_CODE.ENABLED,
                            new_OutreachStatus: OUTREACH_STATUS.SENT_TO_PUBLISHER,
                            [[Model.Post.rel.Campaign, Model.Campaign.rel.Owner, Model.User.id].join('#')]:
                                selectedUserId,
                            [`${Model.Post.rel.Campaign}#${Model.Campaign.statecode}`]: STATE_CODE.ENABLED,
                            [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [POST_STATUS.POSTED],
                        }}
                        searchFields={sentToPublisherSearchFields}
                        sort={{
                            field: Model.Post.new_SentToPublisherOn,
                            order: SORT_DESC,
                        }}
                    >
                        <SentToPublisherFields />
                    </DashboardListWrapper>
                    <DashboardListWrapper
                        cardTitle="Ready To Publish"
                        title=" "
                        resource={Model.Post.res}
                        filter={{
                            statecode: STATE_CODE.ENABLED,
                            new_OutreachStatus: [OUTREACH_STATUS.READY_TO_PUBLISH],
                            [[Model.Post.rel.Campaign, Model.Campaign.rel.Owner, Model.User.id].join('#')]:
                                selectedUserId,
                            new_ContentOrderStatus: CONTENT_STATUS.APPROVED,
                            [`${Model.Post.rel.Campaign}#${Model.Campaign.statecode}`]: STATE_CODE.ENABLED,
                            [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [POST_STATUS.POSTED],
                        }}
                        searchFields={readyToPublishSearchFields}
                        sort={{
                            field: Model.Post.CreatedOn,
                            order: SORT_DESC,
                        }}
                    >
                        <ReadyToPublishFields />
                    </DashboardListWrapper>
                    <DashboardListWrapper
                        cardTitle="Content Writing"
                        title=" "
                        resource={Model.Post.res}
                        filter={{
                            statecode: STATE_CODE.ENABLED,
                            new_ContentOrderStatus: [
                                CONTENT_STATUS.ORDERED,
                                CONTENT_STATUS.CONTENT_REQUEST,
                                CONTENT_STATUS.DECLINED,
                                CONTENT_STATUS.WAITING_APPROVAL,
                            ],
                            new_OutreachStatus: [OUTREACH_STATUS.CONTENT_WRITING],
                            [[Model.Post.rel.Campaign, Model.Campaign.rel.Owner, Model.User.id].join('#')]:
                                selectedUserId,
                            [`${Model.Post.rel.Campaign}#${Model.Campaign.statecode}`]: STATE_CODE.ENABLED,
                            [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [
                                POST_STATUS.POOL,
                                POST_STATUS.POSTED,
                                POST_STATUS.PLANNED,
                            ],
                        }}
                        searchFields={contentWritingSearchFields}
                        sort={{
                            field: Model.Post.new_RequestedOn,
                            order: SORT_DESC,
                        }}
                    >
                        <ContentWritingFields />
                    </DashboardListWrapper>
                    <DashboardCampaigns
                        cardTitle="Link Building Campaigns"
                        userId={Number(selectedUserId)}
                        strategyType={STRATEGY_TYPE.LINK_BUILDING}
                    />
                    <DashboardCampaigns
                        cardTitle="Social Campaigns"
                        userId={Number(selectedUserId)}
                        strategyType={STRATEGY_TYPE.SOCIAL}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
export default OutreachDashboardList;
