import {v1_Roles_select_column} from '../../generated/types';

const Role = {
    ...v1_Roles_select_column,
    res: 'v1_Roles',
    comp: {},
    rel: {},
    label: 'Roles',
};

export default Role;
