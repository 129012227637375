import {v1_MarketplaceStatus_select_column} from '../../generated/types';

const MarketplaceStatus = {
    ...v1_MarketplaceStatus_select_column,
    res: 'v1_MarketplaceStatus',
    comp: {},
    rel: {},
};

export default MarketplaceStatus;
