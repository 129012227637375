import {v1_CampaignPriority_select_column} from '../../generated/types';

const CampaignPriority = {
    ...v1_CampaignPriority_select_column,
    res: 'v1_CampaignPriority',
    comp: {},
    rel: {},
};

export default CampaignPriority;
