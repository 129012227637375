import {v1_Report_select_column} from '../../generated/types';

const Report = {
    ...v1_Report_select_column,
    res: 'v1_Report',
    comp: {},
    rel: {},
    label: 'Reports',
};

export default Report;
