import {COLORS} from './colors';
import {OFFER_STATUS} from './statuses';

export const VARIANT = {
    OUTLINED: 'outlined',
    FILLED: 'filled',
} as const;

export const OFFER_STATUS_CHIP_MAP = {
    [OFFER_STATUS.DECLINED]: {color: COLORS.RED, variant: VARIANT.FILLED},
    [OFFER_STATUS.PUBLISHED]: {color: COLORS.GREEN, variant: VARIANT.FILLED},
    [OFFER_STATUS.IN_PROGRESS]: {
        color: COLORS.LIGHT_BLUE,
        variant: VARIANT.FILLED,
    },
    [OFFER_STATUS.WAITING_APPROVAL]: {
        color: COLORS.DARK_RED,
        variant: VARIANT.FILLED,
    },
    [OFFER_STATUS.UNPUBLISHED]: {
        color: COLORS.DARK_RED,
        variant: VARIANT.FILLED,
    },
};

export const USER_TYPE = {
    PUBLISHER: 'publisher',
    WRITER: 'writer',
    ADVERTISER: 'advertiser',
    ADMIN: 'admin',
};

export const SOCKET_EVENT = {
    READ: 'read_notification',
    DISABLE: 'disable_notification',
}
