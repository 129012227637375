import {v1_BonusesToCountries_select_column} from '../../generated/types';

const BonusesToCountries = {
    ...v1_BonusesToCountries_select_column,
    res: 'v1_BonusesToCountries',
    comp: {},
    rel: {
        Countries: 'Countries',
        Bonuses: 'Bonuses',
    },
};

export default BonusesToCountries;
