import {v1_PostDuration_select_column} from '../../generated/types';

const PostDuration = {
    ...v1_PostDuration_select_column,
    res: 'v1_PostDuration',
    comp: {},
    rel: {},
};

export default PostDuration;
