import {Button, Dialog, DialogActions, DialogContent, TextField} from '@mui/material';
import {useState} from 'react';
import {SimpleForm, useCreate, useCreateSuggestionContext} from 'react-admin';

const CreateWebsiteFromDropdown = () => {
    const {filter, onCancel, onCreate} = useCreateSuggestionContext();
    const [baseUrl, setBaseUrl] = useState(filter || '');
    const [create] = useCreate();

    const handleSubmit = (event: any) => {
        create(
            'v1_Website',
            {
                data: {
                    new_name: baseUrl,
                },
            },
            {
                onSuccess: (data) => {
                    setBaseUrl('');
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <SimpleForm onSubmit={handleSubmit} toolbar={false}>
                <DialogContent>
                    <TextField
                        label="New website"
                        value={baseUrl}
                        onChange={(event) => setBaseUrl(event.target.value)}
                        fullWidth
                        variant="outlined"
                        name="new_name"
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </SimpleForm>
        </Dialog>
    );
};

export default CreateWebsiteFromDropdown;
