import React from 'react';
import {Datagrid, DateField, ReferenceField, TextField} from 'react-admin';
import {TruncatedTooltipField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';
import {TruncatedLinkField} from '../common/truncatedLinkField';
import {LOCALE, PAGE} from '../../config/statuses';
import Model from '../../models/db/models';
import OPERATOR from '../../models/db/operators';

const PublishedPostsFields = () => (
    <Datagrid bulkActionButtons={false} rowClick={PAGE.EDIT}>
        <LinkToNewTab propertyName={Model.Post.id} resource={Model.Post.res} />
        <LinkToRecordField source={Model.Post.id} label="Post ID" redirectToShow={false} />
        <TruncatedLinkField source={Model.Post.new_name} label="Source URL" showPropertyName={true} />
        <DateField source={Model.Post.published_on} label="Published On" locales={LOCALE.EN_GB} />
        <TruncatedTooltipField source={Model.Post.new_Title} label="H1 Title" limit={80} />
        <ReferenceField
            source={Model.Post.MarketplaceProjectId}
            reference={Model.MarketplaceProject.res}
            label="Advertiser Project"
            link={false}
            sortBy={`${Model.Post.rel.MarketplaceProject}.${Model.MarketplaceProject.url}`}
        >
            <TruncatedTooltipField source={Model.MarketplaceProject.url} limit={50} />
        </ReferenceField>

        <ReferenceField
            source={Model.Post.AdvertiserId}
            reference={Model.Advertiser.res}
            label="Advertiser"
            link={PAGE.EDIT}
            sortBy={`${Model.Post.rel.Advertiser}.${Model.Advertiser.FullName}`}
        >
            <TextField source={Model.Advertiser.FullName} />
        </ReferenceField>

        <ReferenceField
            source={Model.Post.writer_id}
            reference={Model.Writer.res}
            label="Writer"
            link={PAGE.EDIT}
            sortBy={`${Model.Post.rel.Writer}.${Model.Writer.FullName}`}
        >
            <TextField source={Model.Writer.FullName} />
        </ReferenceField>

        <ReferenceField
            source={Model.Post.supplier_id}
            reference={Model.Supplier.res}
            label="Publisher"
            link={PAGE.EDIT}
            sortBy={`${Model.Post.rel.Supplier}.${Model.Supplier.FullName}`}
        >
            <TextField source={Model.Supplier.FullName} />
        </ReferenceField>
    </Datagrid>
);

export const publishedPostsSearchFields =
    `${Model.Post.new_name}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.Advertiser}#${Model.Advertiser.FullName}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.Supplier}#${Model.Supplier.FullName}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.Writer}#${Model.Writer.FullName}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.MarketplaceProject}#${Model.MarketplaceProject.url}${OPERATOR.ILIKE}`;

export default PublishedPostsFields;
