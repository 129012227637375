import {v1_StrategyFieldsSet_select_column} from '../../generated/types';

const StrategyFieldsSet = {
    ...v1_StrategyFieldsSet_select_column,
    res: 'v1_StrategyFieldsSet',
    comp: {},
    rel: {},
};

export default StrategyFieldsSet;
