import LaunchIcon from '@mui/icons-material/Launch';
import Link from '@mui/material/Link/Link';
import {FunctionField, RaRecord} from 'react-admin';

type LinkInNewTabFieldProps = {
    propertyName: string;
    resource: string;
    tab?: number;
    showPropertyName?: boolean;
    label?: string;
    redirectToShow?: boolean;
};

const LinkToNewTab = (props: LinkInNewTabFieldProps) => {
    const {propertyName, showPropertyName, resource, tab, redirectToShow} = props;
    const locationPath = window.location.origin;

    return (
        <FunctionField
            onClick={(e: {stopPropagation: () => void}) => e.stopPropagation()}
            render={function (record?: RaRecord) {
                if (!record || !record[propertyName]) return null;

                const showPath = `${locationPath}/#/${resource}/${record.id}`;
                const tabPath = tab ? `/${tab}` : '';

                const linkTab =
                    tab !== undefined || redirectToShow !== true ? `${showPath}${tabPath}` : `${showPath}/show`;

                return (
                    <Link
                        underline="none"
                        rel="noreferrer"
                        target="_blank"
                        href={linkTab}
                        title="Open link in a new tab"
                    >
                        {showPropertyName && record[propertyName]}
                        <LaunchIcon />
                    </Link>
                );
            }}
        />
    );
};
export default LinkToNewTab;
