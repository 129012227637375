import {useState} from 'react';
import {Admin, CustomRoutes, Loading} from 'react-admin';
import {QueryClientProvider} from 'react-query';
import {Route} from 'react-router-dom';
import {authProvider} from './authorization/authProvider';
import {AddLivePosts} from './components/campaign/AddLivePosts/AddLivePosts';
import ContentManagerList from './components/contentManager/contentManagerList';
import NhostLogin from './components/login/nhostLogin';
import {OfferCreateBulk} from './components/offer/createBulk/offerCreateBulk';
import OutreachDashboardList from './components/outreachDashboard/outreachDashboardList';
import PmDashboardList from './components/pmDashboard/pmDashboardList';
import {ImportStatistics} from './components/website/importStatistics/importStatistics';
import {resourceList} from './config/resourceList';
import {appQueryClient} from './data/appQueryClient';
import {initStore, store} from './data/storeWrapper';
import {useAppDataProvider} from './data/useAppDataProvider';
import i18nProvider from './i18n/i18nProvider';
import Dashboard from './layout/dashboard';
import Layout from './layout/layout';
import Notification from './layout/Notification';
import {customTheme, darkTheme} from './layout/theme';
import {ResourceGenerator} from './utility/resourceGenerator';
import MarketplaceDashboardList from "./components/marketplaceDashboard/marketplaceDashboardList";

const getAuthProvider = () => {
    // return isDevEnvironment === 'true' ? devAuthProvider : authProvider;
    return authProvider;
};

const appAuthProvider = getAuthProvider();

const App = () => {
    const CRMAppDataProvider = useAppDataProvider();

    const [isClicked, setIsClicked] = useState(false);

    if (!CRMAppDataProvider || !appAuthProvider) return <Loading />;

    initStore(CRMAppDataProvider);

    return (
        <QueryClientProvider client={appQueryClient}>
            <Admin
                requireAuth={true}
                queryClient={appQueryClient}
                i18nProvider={i18nProvider}
                store={store}
                authProvider={appAuthProvider}
                dataProvider={CRMAppDataProvider}
                loginPage={<NhostLogin isClicked={isClicked} setIsClicked={setIsClicked} />}
                layout={Layout}
                dashboard={Dashboard}
                notification={Notification}
                defaultTheme="light"
                theme={customTheme}
                lightTheme={customTheme}
                darkTheme={darkTheme}
            >
                {resourceList.map((r) => {
                    return ResourceGenerator(r);
                })}
                <CustomRoutes>
                    <Route path="/pm-dashboard" element={<PmDashboardList />} />
                    <Route path="/outreach-dashboard" element={<OutreachDashboardList />} />
                    <Route path="/content-manager" element={<ContentManagerList />} />
                    <Route path="/marketplace-dashboard" element={<MarketplaceDashboardList />} />
                    <Route path="/add-live-posts" element={<AddLivePosts />} />
                    <Route path="/import-statistics" element={<ImportStatistics />} />
                    <Route path="/add-bulk-offers" element={<OfferCreateBulk />} />
                </CustomRoutes>
            </Admin>
        </QueryClientProvider>
    );
};

export default App;
