import {Launch} from '@mui/icons-material/';
import {Box} from '@mui/material/';
import {FunctionField, Link} from 'react-admin';
import type {FieldValues, UseFormRegister} from 'react-hook-form';
import type {CheckedOffersData, OfferDuplicate} from './types';

type Id = number;
type TmpId = number;
type Register = UseFormRegister<FieldValues>;

interface DuplicatesFieldProps {
    register: Register;
}

export function DuplicatesField({register}: DuplicatesFieldProps) {
    return (
        <FunctionField
            label="Duplicates"
            render={(record: CheckedOffersData) => {
                return (
                    <Box flexDirection="row" display="flex" alignItems="center" justifyContent="space-between">
                        <Box>
                            {record._duplicates ? (
                                record._duplicates.map((duplicate: OfferDuplicate) => {
                                    return (
                                        <Box key={duplicate.id} display="flex" justifyContent="space-between">
                                            <LinkToOffer id={duplicate.id} name={duplicate.name} />
                                            <UpdateOfferInput
                                                tmp_id={record._tmp_id}
                                                id={duplicate.id}
                                                register={register}
                                            />
                                        </Box>
                                    );
                                })
                            ) : (
                                <NoDuplicatesCreateInput tmp_id={record._tmp_id} register={register} />
                            )}
                        </Box>
                        {record._duplicates && (
                            <Box>
                                <CreateOfferInput tmp_id={record._tmp_id} register={register} />
                            </Box>
                        )}
                    </Box>
                );
            }}
        />
    );
}

function LinkToOffer({id, name}: OfferDuplicate) {
    return (
        <Link
            to={`/v1_Offer/${id}`}
            target="_blank"
            rel="noreferrer noopener"
            sx={{
                display: 'flex',
                mr: 1,
            }}
        >
            {`${name}, id: ${id}`}
            <Launch fontSize="small" />
        </Link>
    );
}

function UpdateOfferInput({tmp_id, id, register}: {tmp_id: TmpId; id: Id; register: Register}) {
    return (
        <label>
            <input type="radio" {...register(`offerTmpId_${tmp_id}`)} value={`${id}`} required />
            Update
        </label>
    );
}

function CreateOfferInput({tmp_id, register}: {tmp_id: TmpId; register: Register}) {
    return (
        <label>
            <input type="radio" {...register(`offerTmpId_${tmp_id}`)} value="create" required />
            Create
        </label>
    );
}

function NoDuplicatesCreateInput({tmp_id, register}: {tmp_id: TmpId; register: Register}) {
    return (
        <>
            <input type="hidden" {...register(`offerTmpId_${tmp_id}`)} value="create" />
            No duplicates (offer will be created)
        </>
    );
}
