export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Datetime: { input: any; output: any; }
  _Any: { input: any; output: any; }
  _int4: { input: any; output: any; }
  bpchar: { input: any; output: any; }
  bytea: { input: any; output: any; }
  date: { input: any; output: any; }
  float8: { input: any; output: any; }
  json: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

export type AddLivePostsOutput = {
  __typename?: 'AddLivePostsOutput';
  affected_rows: Scalars['Int']['output'];
  returning: Array<LivePostOutput>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_BigQuery_comparison_exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_comparison_exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type BulkPLCOutput = {
  __typename?: 'BulkPLCOutput';
  posts_ids: Array<Scalars['Int']['output']>;
};

/** columns and relationships of "Clicks.OrphanTrackers" */
export type Clicks_OrphanTrackers = {
  __typename?: 'Clicks_OrphanTrackers';
  AffiliateOrderName?: Maybe<Scalars['String']['output']>;
  ClientTimeStr?: Maybe<Scalars['String']['output']>;
  Domain?: Maybe<Scalars['String']['output']>;
  LandingPageType?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
  ProjectId?: Maybe<Scalars['String']['output']>;
  Zone?: Maybe<Scalars['String']['output']>;
  brandname?: Maybe<Scalars['String']['output']>;
  clientdatetime?: Maybe<Scalars['String']['output']>;
  clientime?: Maybe<Scalars['Datetime']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  destinationurl?: Maybe<Scalars['String']['output']>;
  exitpage?: Maybe<Scalars['String']['output']>;
  externalurl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  internalurl?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  isUnique?: Maybe<Scalars['Boolean']['output']>;
  landingpage?: Maybe<Scalars['String']['output']>;
  orderid?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
  sourceurl?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "Clicks.OrphanTrackers" */
export type Clicks_OrphanTrackers_aggregate = {
  __typename?: 'Clicks_OrphanTrackers_aggregate';
  aggregate?: Maybe<Clicks_OrphanTrackers_aggregate_fields>;
  nodes: Array<Clicks_OrphanTrackers>;
};

/** aggregate fields of "Clicks.OrphanTrackers" */
export type Clicks_OrphanTrackers_aggregate_fields = {
  __typename?: 'Clicks_OrphanTrackers_aggregate_fields';
  avg?: Maybe<Clicks_OrphanTrackers_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Clicks_OrphanTrackers_max_fields>;
  min?: Maybe<Clicks_OrphanTrackers_min_fields>;
  stddev?: Maybe<Clicks_OrphanTrackers_stddev_fields>;
  stddev_pop?: Maybe<Clicks_OrphanTrackers_stddev_pop_fields>;
  stddev_samp?: Maybe<Clicks_OrphanTrackers_stddev_samp_fields>;
  sum?: Maybe<Clicks_OrphanTrackers_sum_fields>;
  var_pop?: Maybe<Clicks_OrphanTrackers_var_pop_fields>;
  var_samp?: Maybe<Clicks_OrphanTrackers_var_samp_fields>;
  variance?: Maybe<Clicks_OrphanTrackers_variance_fields>;
};


/** aggregate fields of "Clicks.OrphanTrackers" */
export type Clicks_OrphanTrackers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<Clicks_OrphanTrackers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Clicks_OrphanTrackers_avg_fields = {
  __typename?: 'Clicks_OrphanTrackers_avg_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "Clicks.OrphanTrackers". All fields are combined with a logical 'AND'. */
export type Clicks_OrphanTrackers_bool_exp = {
  AffiliateOrderName?: InputMaybe<String_BigQuery_comparison_exp>;
  ClientTimeStr?: InputMaybe<String_BigQuery_comparison_exp>;
  Domain?: InputMaybe<String_BigQuery_comparison_exp>;
  LandingPageType?: InputMaybe<String_BigQuery_comparison_exp>;
  Position?: InputMaybe<Int_BigQuery_comparison_exp>;
  ProjectId?: InputMaybe<String_BigQuery_comparison_exp>;
  Zone?: InputMaybe<String_BigQuery_comparison_exp>;
  _and?: InputMaybe<Array<Clicks_OrphanTrackers_bool_exp>>;
  _not?: InputMaybe<Clicks_OrphanTrackers_bool_exp>;
  _or?: InputMaybe<Array<Clicks_OrphanTrackers_bool_exp>>;
  brandname?: InputMaybe<String_BigQuery_comparison_exp>;
  clientdatetime?: InputMaybe<String_BigQuery_comparison_exp>;
  clientime?: InputMaybe<Datetime_BigQuery_comparison_exp>;
  country?: InputMaybe<String_BigQuery_comparison_exp>;
  destinationurl?: InputMaybe<String_BigQuery_comparison_exp>;
  exitpage?: InputMaybe<String_BigQuery_comparison_exp>;
  externalurl?: InputMaybe<String_BigQuery_comparison_exp>;
  id?: InputMaybe<String_BigQuery_comparison_exp>;
  internalurl?: InputMaybe<String_BigQuery_comparison_exp>;
  ip?: InputMaybe<String_BigQuery_comparison_exp>;
  isUnique?: InputMaybe<Boolean_BigQuery_comparison_exp>;
  landingpage?: InputMaybe<String_BigQuery_comparison_exp>;
  orderid?: InputMaybe<String_BigQuery_comparison_exp>;
  referrer?: InputMaybe<String_BigQuery_comparison_exp>;
  sourceurl?: InputMaybe<String_BigQuery_comparison_exp>;
};

/** aggregate max on columns */
export type Clicks_OrphanTrackers_max_fields = {
  __typename?: 'Clicks_OrphanTrackers_max_fields';
  AffiliateOrderName?: Maybe<Scalars['String']['output']>;
  ClientTimeStr?: Maybe<Scalars['String']['output']>;
  Domain?: Maybe<Scalars['String']['output']>;
  LandingPageType?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
  ProjectId?: Maybe<Scalars['String']['output']>;
  Zone?: Maybe<Scalars['String']['output']>;
  brandname?: Maybe<Scalars['String']['output']>;
  clientdatetime?: Maybe<Scalars['String']['output']>;
  clientime?: Maybe<Scalars['Datetime']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  destinationurl?: Maybe<Scalars['String']['output']>;
  exitpage?: Maybe<Scalars['String']['output']>;
  externalurl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  internalurl?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  isUnique?: Maybe<Scalars['Boolean']['output']>;
  landingpage?: Maybe<Scalars['String']['output']>;
  orderid?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
  sourceurl?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Clicks_OrphanTrackers_min_fields = {
  __typename?: 'Clicks_OrphanTrackers_min_fields';
  AffiliateOrderName?: Maybe<Scalars['String']['output']>;
  ClientTimeStr?: Maybe<Scalars['String']['output']>;
  Domain?: Maybe<Scalars['String']['output']>;
  LandingPageType?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
  ProjectId?: Maybe<Scalars['String']['output']>;
  Zone?: Maybe<Scalars['String']['output']>;
  brandname?: Maybe<Scalars['String']['output']>;
  clientdatetime?: Maybe<Scalars['String']['output']>;
  clientime?: Maybe<Scalars['Datetime']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  destinationurl?: Maybe<Scalars['String']['output']>;
  exitpage?: Maybe<Scalars['String']['output']>;
  externalurl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  internalurl?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  isUnique?: Maybe<Scalars['Boolean']['output']>;
  landingpage?: Maybe<Scalars['String']['output']>;
  orderid?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
  sourceurl?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "Clicks.OrphanTrackers". */
export type Clicks_OrphanTrackers_order_by = {
  AffiliateOrderName?: InputMaybe<order_by>;
  ClientTimeStr?: InputMaybe<order_by>;
  Domain?: InputMaybe<order_by>;
  LandingPageType?: InputMaybe<order_by>;
  Position?: InputMaybe<order_by>;
  ProjectId?: InputMaybe<order_by>;
  Zone?: InputMaybe<order_by>;
  brandname?: InputMaybe<order_by>;
  clientdatetime?: InputMaybe<order_by>;
  clientime?: InputMaybe<order_by>;
  country?: InputMaybe<order_by>;
  destinationurl?: InputMaybe<order_by>;
  exitpage?: InputMaybe<order_by>;
  externalurl?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internalurl?: InputMaybe<order_by>;
  ip?: InputMaybe<order_by>;
  isUnique?: InputMaybe<order_by>;
  landingpage?: InputMaybe<order_by>;
  orderid?: InputMaybe<order_by>;
  referrer?: InputMaybe<order_by>;
  sourceurl?: InputMaybe<order_by>;
};

/** select columns of table "Clicks.OrphanTrackers" */
export enum Clicks_OrphanTrackers_select_column {
  /** column name */
  AffiliateOrderName = 'AffiliateOrderName',
  /** column name */
  ClientTimeStr = 'ClientTimeStr',
  /** column name */
  Domain = 'Domain',
  /** column name */
  LandingPageType = 'LandingPageType',
  /** column name */
  Position = 'Position',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  Zone = 'Zone',
  /** column name */
  brandname = 'brandname',
  /** column name */
  clientdatetime = 'clientdatetime',
  /** column name */
  clientime = 'clientime',
  /** column name */
  country = 'country',
  /** column name */
  destinationurl = 'destinationurl',
  /** column name */
  exitpage = 'exitpage',
  /** column name */
  externalurl = 'externalurl',
  /** column name */
  id = 'id',
  /** column name */
  internalurl = 'internalurl',
  /** column name */
  ip = 'ip',
  /** column name */
  isUnique = 'isUnique',
  /** column name */
  landingpage = 'landingpage',
  /** column name */
  orderid = 'orderid',
  /** column name */
  referrer = 'referrer',
  /** column name */
  sourceurl = 'sourceurl'
}

/** aggregate stddev on columns */
export type Clicks_OrphanTrackers_stddev_fields = {
  __typename?: 'Clicks_OrphanTrackers_stddev_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Clicks_OrphanTrackers_stddev_pop_fields = {
  __typename?: 'Clicks_OrphanTrackers_stddev_pop_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Clicks_OrphanTrackers_stddev_samp_fields = {
  __typename?: 'Clicks_OrphanTrackers_stddev_samp_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate sum on columns */
export type Clicks_OrphanTrackers_sum_fields = {
  __typename?: 'Clicks_OrphanTrackers_sum_fields';
  Position?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Clicks_OrphanTrackers_var_pop_fields = {
  __typename?: 'Clicks_OrphanTrackers_var_pop_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Clicks_OrphanTrackers_var_samp_fields = {
  __typename?: 'Clicks_OrphanTrackers_var_samp_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Clicks_OrphanTrackers_variance_fields = {
  __typename?: 'Clicks_OrphanTrackers_variance_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "Clicks.lines" */
export type Clicks_lines = {
  __typename?: 'Clicks_lines';
  AffiliateOrderName?: Maybe<Scalars['String']['output']>;
  ClientTimeStr?: Maybe<Scalars['String']['output']>;
  Domain?: Maybe<Scalars['String']['output']>;
  LandingPageType?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
  ProjectId?: Maybe<Scalars['String']['output']>;
  Zone?: Maybe<Scalars['String']['output']>;
  brandname?: Maybe<Scalars['String']['output']>;
  clientdatetime?: Maybe<Scalars['String']['output']>;
  clientime?: Maybe<Scalars['Datetime']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  destinationurl?: Maybe<Scalars['String']['output']>;
  exitpage?: Maybe<Scalars['String']['output']>;
  externalurl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  internalurl?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  isUnique?: Maybe<Scalars['Boolean']['output']>;
  landingpage?: Maybe<Scalars['String']['output']>;
  orderid?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
  sourceurl?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "Clicks.lines" */
export type Clicks_lines_aggregate = {
  __typename?: 'Clicks_lines_aggregate';
  aggregate?: Maybe<Clicks_lines_aggregate_fields>;
  nodes: Array<Clicks_lines>;
};

/** aggregate fields of "Clicks.lines" */
export type Clicks_lines_aggregate_fields = {
  __typename?: 'Clicks_lines_aggregate_fields';
  avg?: Maybe<Clicks_lines_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Clicks_lines_max_fields>;
  min?: Maybe<Clicks_lines_min_fields>;
  stddev?: Maybe<Clicks_lines_stddev_fields>;
  stddev_pop?: Maybe<Clicks_lines_stddev_pop_fields>;
  stddev_samp?: Maybe<Clicks_lines_stddev_samp_fields>;
  sum?: Maybe<Clicks_lines_sum_fields>;
  var_pop?: Maybe<Clicks_lines_var_pop_fields>;
  var_samp?: Maybe<Clicks_lines_var_samp_fields>;
  variance?: Maybe<Clicks_lines_variance_fields>;
};


/** aggregate fields of "Clicks.lines" */
export type Clicks_lines_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<Clicks_lines_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Clicks_lines_avg_fields = {
  __typename?: 'Clicks_lines_avg_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "Clicks.lines". All fields are combined with a logical 'AND'. */
export type Clicks_lines_bool_exp = {
  AffiliateOrderName?: InputMaybe<String_BigQuery_comparison_exp>;
  ClientTimeStr?: InputMaybe<String_BigQuery_comparison_exp>;
  Domain?: InputMaybe<String_BigQuery_comparison_exp>;
  LandingPageType?: InputMaybe<String_BigQuery_comparison_exp>;
  Position?: InputMaybe<Int_BigQuery_comparison_exp>;
  ProjectId?: InputMaybe<String_BigQuery_comparison_exp>;
  Zone?: InputMaybe<String_BigQuery_comparison_exp>;
  _and?: InputMaybe<Array<Clicks_lines_bool_exp>>;
  _not?: InputMaybe<Clicks_lines_bool_exp>;
  _or?: InputMaybe<Array<Clicks_lines_bool_exp>>;
  brandname?: InputMaybe<String_BigQuery_comparison_exp>;
  clientdatetime?: InputMaybe<String_BigQuery_comparison_exp>;
  clientime?: InputMaybe<Datetime_BigQuery_comparison_exp>;
  country?: InputMaybe<String_BigQuery_comparison_exp>;
  destinationurl?: InputMaybe<String_BigQuery_comparison_exp>;
  exitpage?: InputMaybe<String_BigQuery_comparison_exp>;
  externalurl?: InputMaybe<String_BigQuery_comparison_exp>;
  id?: InputMaybe<String_BigQuery_comparison_exp>;
  internalurl?: InputMaybe<String_BigQuery_comparison_exp>;
  ip?: InputMaybe<String_BigQuery_comparison_exp>;
  isUnique?: InputMaybe<Boolean_BigQuery_comparison_exp>;
  landingpage?: InputMaybe<String_BigQuery_comparison_exp>;
  orderid?: InputMaybe<String_BigQuery_comparison_exp>;
  referrer?: InputMaybe<String_BigQuery_comparison_exp>;
  sourceurl?: InputMaybe<String_BigQuery_comparison_exp>;
};

/** aggregate max on columns */
export type Clicks_lines_max_fields = {
  __typename?: 'Clicks_lines_max_fields';
  AffiliateOrderName?: Maybe<Scalars['String']['output']>;
  ClientTimeStr?: Maybe<Scalars['String']['output']>;
  Domain?: Maybe<Scalars['String']['output']>;
  LandingPageType?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
  ProjectId?: Maybe<Scalars['String']['output']>;
  Zone?: Maybe<Scalars['String']['output']>;
  brandname?: Maybe<Scalars['String']['output']>;
  clientdatetime?: Maybe<Scalars['String']['output']>;
  clientime?: Maybe<Scalars['Datetime']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  destinationurl?: Maybe<Scalars['String']['output']>;
  exitpage?: Maybe<Scalars['String']['output']>;
  externalurl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  internalurl?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  isUnique?: Maybe<Scalars['Boolean']['output']>;
  landingpage?: Maybe<Scalars['String']['output']>;
  orderid?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
  sourceurl?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Clicks_lines_min_fields = {
  __typename?: 'Clicks_lines_min_fields';
  AffiliateOrderName?: Maybe<Scalars['String']['output']>;
  ClientTimeStr?: Maybe<Scalars['String']['output']>;
  Domain?: Maybe<Scalars['String']['output']>;
  LandingPageType?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
  ProjectId?: Maybe<Scalars['String']['output']>;
  Zone?: Maybe<Scalars['String']['output']>;
  brandname?: Maybe<Scalars['String']['output']>;
  clientdatetime?: Maybe<Scalars['String']['output']>;
  clientime?: Maybe<Scalars['Datetime']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  destinationurl?: Maybe<Scalars['String']['output']>;
  exitpage?: Maybe<Scalars['String']['output']>;
  externalurl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  internalurl?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  isUnique?: Maybe<Scalars['Boolean']['output']>;
  landingpage?: Maybe<Scalars['String']['output']>;
  orderid?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
  sourceurl?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "Clicks.lines". */
export type Clicks_lines_order_by = {
  AffiliateOrderName?: InputMaybe<order_by>;
  ClientTimeStr?: InputMaybe<order_by>;
  Domain?: InputMaybe<order_by>;
  LandingPageType?: InputMaybe<order_by>;
  Position?: InputMaybe<order_by>;
  ProjectId?: InputMaybe<order_by>;
  Zone?: InputMaybe<order_by>;
  brandname?: InputMaybe<order_by>;
  clientdatetime?: InputMaybe<order_by>;
  clientime?: InputMaybe<order_by>;
  country?: InputMaybe<order_by>;
  destinationurl?: InputMaybe<order_by>;
  exitpage?: InputMaybe<order_by>;
  externalurl?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  internalurl?: InputMaybe<order_by>;
  ip?: InputMaybe<order_by>;
  isUnique?: InputMaybe<order_by>;
  landingpage?: InputMaybe<order_by>;
  orderid?: InputMaybe<order_by>;
  referrer?: InputMaybe<order_by>;
  sourceurl?: InputMaybe<order_by>;
};

/** select columns of table "Clicks.lines" */
export enum Clicks_lines_select_column {
  /** column name */
  AffiliateOrderName = 'AffiliateOrderName',
  /** column name */
  ClientTimeStr = 'ClientTimeStr',
  /** column name */
  Domain = 'Domain',
  /** column name */
  LandingPageType = 'LandingPageType',
  /** column name */
  Position = 'Position',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  Zone = 'Zone',
  /** column name */
  brandname = 'brandname',
  /** column name */
  clientdatetime = 'clientdatetime',
  /** column name */
  clientime = 'clientime',
  /** column name */
  country = 'country',
  /** column name */
  destinationurl = 'destinationurl',
  /** column name */
  exitpage = 'exitpage',
  /** column name */
  externalurl = 'externalurl',
  /** column name */
  id = 'id',
  /** column name */
  internalurl = 'internalurl',
  /** column name */
  ip = 'ip',
  /** column name */
  isUnique = 'isUnique',
  /** column name */
  landingpage = 'landingpage',
  /** column name */
  orderid = 'orderid',
  /** column name */
  referrer = 'referrer',
  /** column name */
  sourceurl = 'sourceurl'
}

/** aggregate stddev on columns */
export type Clicks_lines_stddev_fields = {
  __typename?: 'Clicks_lines_stddev_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Clicks_lines_stddev_pop_fields = {
  __typename?: 'Clicks_lines_stddev_pop_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Clicks_lines_stddev_samp_fields = {
  __typename?: 'Clicks_lines_stddev_samp_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate sum on columns */
export type Clicks_lines_sum_fields = {
  __typename?: 'Clicks_lines_sum_fields';
  Position?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Clicks_lines_var_pop_fields = {
  __typename?: 'Clicks_lines_var_pop_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Clicks_lines_var_samp_fields = {
  __typename?: 'Clicks_lines_var_samp_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Clicks_lines_variance_fields = {
  __typename?: 'Clicks_lines_variance_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "Datetime". All fields are combined with logical 'AND'. */
export type Datetime_BigQuery_comparison_exp = {
  _eq?: InputMaybe<Scalars['Datetime']['input']>;
  _gt?: InputMaybe<Scalars['Datetime']['input']>;
  _gte?: InputMaybe<Scalars['Datetime']['input']>;
  _in?: InputMaybe<Array<Scalars['Datetime']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Datetime']['input']>;
  _lte?: InputMaybe<Scalars['Datetime']['input']>;
  _neq?: InputMaybe<Scalars['Datetime']['input']>;
  _nin?: InputMaybe<Array<Scalars['Datetime']['input']>>;
};

export type Error = {
  __typename?: 'Error';
  cause?: Maybe<ErrorCause>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ErrorCause = {
  __typename?: 'ErrorCause';
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
};

export type InsertPostOutput = {
  __typename?: 'InsertPostOutput';
  id: Scalars['Int']['output'];
};

export type InsertPostsOutput = {
  __typename?: 'InsertPostsOutput';
  affected_rows: Scalars['Int']['output'];
  returning: Array<InsertPostOutput>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_BigQuery_comparison_exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_comparison_exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LivePostInput = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  new_Anchortext1?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext2?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext3?: InputMaybe<Scalars['String']['input']>;
  new_ContentCost?: InputMaybe<Scalars['Float']['input']>;
  new_ContentOrderStatus: Scalars['Int']['input'];
  new_Supplierprice?: InputMaybe<Scalars['Float']['input']>;
  new_TargetURL1?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL2?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL3?: InputMaybe<Scalars['String']['input']>;
  new_Title?: InputMaybe<Scalars['String']['input']>;
  new_UploadPrice?: InputMaybe<Scalars['Float']['input']>;
  new_name: Scalars['String']['input'];
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_currency_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

export type LivePostOutput = {
  __typename?: 'LivePostOutput';
  id: Scalars['Int']['output'];
  new_Anchortext1: Scalars['String']['output'];
  new_Anchortext2: Scalars['String']['output'];
  new_Anchortext3: Scalars['String']['output'];
  new_TargetURL1: Scalars['String']['output'];
  new_TargetURL2: Scalars['String']['output'];
  new_TargetURL3: Scalars['String']['output'];
  new_name: Scalars['String']['output'];
};

export type PLCPostInput = {
  id: Scalars['Int']['input'];
  new_Anchortext1?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext2?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext3?: InputMaybe<Scalars['String']['input']>;
  new_ContentOrderStatus?: InputMaybe<Scalars['Int']['input']>;
  new_OutreachStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostLinksStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostOnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostStatus?: InputMaybe<Scalars['Int']['input']>;
  new_TargetURL1?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL2?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL3?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  plc_failed_attempts?: InputMaybe<Scalars['Int']['input']>;
};

export type PLCPostsInput = {
  post: Array<InputMaybe<PLCPostInput>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_BigQuery_comparison_exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_comparison_exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type UserCheckInput = {
  emailaddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UserCheckOutput = {
  __typename?: 'UserCheckOutput';
  UserId: Scalars['Int']['output'];
  UserRole: Scalars['Int']['output'];
};

export type UserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

export type UserInputLivePosts = {
  id: Scalars['Int']['input'];
};

export type WebsiteStatisticsInput = {
  MOZSpamScore?: InputMaybe<Scalars['Int']['input']>;
  OrganicTraffic?: InputMaybe<Scalars['Int']['input']>;
  OutgoingDomains?: InputMaybe<Scalars['Int']['input']>;
  SemrushAS?: InputMaybe<Scalars['Int']['input']>;
  ip_adress?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['String']['input']>;
  linked_domains?: InputMaybe<Scalars['Int']['input']>;
  new_DomainRatingMOZ?: InputMaybe<Scalars['Float']['input']>;
  new_MozDomainAuthority?: InputMaybe<Scalars['Int']['input']>;
  new_Powerdomain?: InputMaybe<Scalars['Float']['input']>;
  new_TitleHome?: InputMaybe<Scalars['String']['input']>;
  new_dtoxrisk?: InputMaybe<Scalars['String']['input']>;
  new_name: Scalars['String']['input'];
  new_trustdom?: InputMaybe<Scalars['Int']['input']>;
  powerTrustDom?: InputMaybe<Scalars['Int']['input']>;
  referring_domains_total?: InputMaybe<Scalars['Int']['input']>;
  traffic?: InputMaybe<Scalars['Int']['input']>;
  url_rating?: InputMaybe<Scalars['Int']['input']>;
};

export type WebsiteStatisticsOutput = {
  __typename?: 'WebsiteStatisticsOutput';
  id: Scalars['Int']['output'];
};

export type WebsitesStatisticsOutput = {
  __typename?: 'WebsitesStatisticsOutput';
  affected_rows?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  returning?: Maybe<Array<Maybe<WebsiteStatisticsOutput>>>;
};

/** A union of all types that use the @key directive */
export type _Entity = v1_Campaign | v1_Posts;

export type _Service = {
  __typename?: '_Service';
  /** SDL representation of schema */
  sdl: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type _int4_comparison_exp = {
  _eq?: InputMaybe<Scalars['_int4']['input']>;
  _gt?: InputMaybe<Scalars['_int4']['input']>;
  _gte?: InputMaybe<Scalars['_int4']['input']>;
  _in?: InputMaybe<Array<Scalars['_int4']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['_int4']['input']>;
  _lte?: InputMaybe<Scalars['_int4']['input']>;
  _neq?: InputMaybe<Scalars['_int4']['input']>;
  _nin?: InputMaybe<Array<Scalars['_int4']['input']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type bpchar_comparison_exp = {
  _eq?: InputMaybe<Scalars['bpchar']['input']>;
  _gt?: InputMaybe<Scalars['bpchar']['input']>;
  _gte?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']['input']>;
  _in?: InputMaybe<Array<Scalars['bpchar']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']['input']>;
  _lt?: InputMaybe<Scalars['bpchar']['input']>;
  _lte?: InputMaybe<Scalars['bpchar']['input']>;
  _neq?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']['input']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']['input']>;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type bytea_comparison_exp = {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
};

/** ordering argument of a cursor */
export enum cursor_ordering {
  /** ascending ordering of the cursor */
  ASC = 'ASC',
  /** descending ordering of the cursor */
  DESC = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type date_comparison_exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type float8_comparison_exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

export type insertPostInput = {
  OfferId?: InputMaybe<Scalars['Int']['input']>;
  approver_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  contenttype_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  new_Anchortext1?: InputMaybe<Scalars['String']['input']>;
  new_ContentOrderStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Contentduedate?: InputMaybe<Scalars['String']['input']>;
  new_Keywords?: InputMaybe<Scalars['String']['input']>;
  new_OutreachStatus?: InputMaybe<Scalars['Int']['input']>;
  new_RequestedMinWords?: InputMaybe<Scalars['Int']['input']>;
  new_Supplierprice?: InputMaybe<Scalars['Float']['input']>;
  new_TargetURL1?: InputMaybe<Scalars['String']['input']>;
  new_Title?: InputMaybe<Scalars['String']['input']>;
  new_WritingInstructions?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  numberOfPosts?: InputMaybe<Scalars['Int']['input']>;
  orderedby_id?: InputMaybe<Scalars['Int']['input']>;
  requestDetails?: InputMaybe<Scalars['String']['input']>;
  requestedby_id?: InputMaybe<Scalars['Int']['input']>;
  requestedfrom_id?: InputMaybe<Scalars['Int']['input']>;
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_currency_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  target_publish_date?: InputMaybe<Scalars['String']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type json_comparison_exp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

export type jsonb_cast_exp = {
  String?: InputMaybe<String_comparison_exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type jsonb_comparison_exp = {
  _cast?: InputMaybe<jsonb_cast_exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type mutation_root = {
  __typename?: 'mutation_root';
  /** addLivePosts */
  addLivePosts?: Maybe<AddLivePostsOutput>;
  /** Bulk post link check */
  bulkPostLinkCheck?: Maybe<BulkPLCOutput>;
  /** Check CopyScape */
  checkCopyScape: Scalars['Boolean']['output'];
  /** delete data from the table: "v1.AccountBase" */
  delete_v1_AccountBase?: Maybe<v1_AccountBase_mutation_response>;
  /** delete single row from the table: "v1.AccountBase" */
  delete_v1_AccountBase_by_pk?: Maybe<v1_AccountBase>;
  /** delete data from the table: "v1.AffDealType" */
  delete_v1_AffDealType?: Maybe<v1_AffDealType_mutation_response>;
  /** delete single row from the table: "v1.AffDealType" */
  delete_v1_AffDealType_by_pk?: Maybe<v1_AffDealType>;
  /** delete data from the table: "v1.AffiliateOrderCategoriesPriority" */
  delete_v1_AffiliateOrderCategoriesPriority?: Maybe<v1_AffiliateOrderCategoriesPriority_mutation_response>;
  /** delete single row from the table: "v1.AffiliateOrderCategoriesPriority" */
  delete_v1_AffiliateOrderCategoriesPriority_by_pk?: Maybe<v1_AffiliateOrderCategoriesPriority>;
  /** delete data from the table: "v1.AffiliateProgramType" */
  delete_v1_AffiliateProgramType?: Maybe<v1_AffiliateProgramType_mutation_response>;
  /** delete single row from the table: "v1.AffiliateProgramType" */
  delete_v1_AffiliateProgramType_by_pk?: Maybe<v1_AffiliateProgramType>;
  /** delete data from the table: "v1.AllowedPlayers" */
  delete_v1_AllowedPlayers?: Maybe<v1_AllowedPlayers_mutation_response>;
  /** delete single row from the table: "v1.AllowedPlayers" */
  delete_v1_AllowedPlayers_by_pk?: Maybe<v1_AllowedPlayers>;
  /** delete data from the table: "v1.ArticleTones" */
  delete_v1_ArticleTones?: Maybe<v1_ArticleTones_mutation_response>;
  /** delete single row from the table: "v1.ArticleTones" */
  delete_v1_ArticleTones_by_pk?: Maybe<v1_ArticleTones>;
  /** delete data from the table: "v1.Balance" */
  delete_v1_Balance?: Maybe<v1_Balance_mutation_response>;
  /** delete data from the table: "v1.BalanceActivityType" */
  delete_v1_BalanceActivityType?: Maybe<v1_BalanceActivityType_mutation_response>;
  /** delete single row from the table: "v1.BalanceActivityType" */
  delete_v1_BalanceActivityType_by_pk?: Maybe<v1_BalanceActivityType>;
  /** delete single row from the table: "v1.Balance" */
  delete_v1_Balance_by_pk?: Maybe<v1_Balance>;
  /** delete data from the table: "v1.BillingInformation" */
  delete_v1_BillingInformation?: Maybe<v1_BillingInformation_mutation_response>;
  /** delete single row from the table: "v1.BillingInformation" */
  delete_v1_BillingInformation_by_pk?: Maybe<v1_BillingInformation>;
  /** delete data from the table: "v1.BillingMethod" */
  delete_v1_BillingMethod?: Maybe<v1_BillingMethod_mutation_response>;
  /** delete single row from the table: "v1.BillingMethod" */
  delete_v1_BillingMethod_by_pk?: Maybe<v1_BillingMethod>;
  /** delete data from the table: "v1.BonusSource" */
  delete_v1_BonusSource?: Maybe<v1_BonusSource_mutation_response>;
  /** delete single row from the table: "v1.BonusSource" */
  delete_v1_BonusSource_by_pk?: Maybe<v1_BonusSource>;
  /** delete data from the table: "v1.BonusStatus" */
  delete_v1_BonusStatus?: Maybe<v1_BonusStatus_mutation_response>;
  /** delete single row from the table: "v1.BonusStatus" */
  delete_v1_BonusStatus_by_pk?: Maybe<v1_BonusStatus>;
  /** delete data from the table: "v1.BonusSubmitter" */
  delete_v1_BonusSubmitter?: Maybe<v1_BonusSubmitter_mutation_response>;
  /** delete single row from the table: "v1.BonusSubmitter" */
  delete_v1_BonusSubmitter_by_pk?: Maybe<v1_BonusSubmitter>;
  /** delete data from the table: "v1.BonusTypes" */
  delete_v1_BonusTypes?: Maybe<v1_BonusTypes_mutation_response>;
  /** delete single row from the table: "v1.BonusTypes" */
  delete_v1_BonusTypes_by_pk?: Maybe<v1_BonusTypes>;
  /** delete data from the table: "v1.Bonuses" */
  delete_v1_Bonuses?: Maybe<v1_Bonuses_mutation_response>;
  /** delete data from the table: "v1.BonusesToCountries" */
  delete_v1_BonusesToCountries?: Maybe<v1_BonusesToCountries_mutation_response>;
  /** delete single row from the table: "v1.BonusesToCountries" */
  delete_v1_BonusesToCountries_by_pk?: Maybe<v1_BonusesToCountries>;
  /** delete data from the table: "v1.BonusesToCurrencies" */
  delete_v1_BonusesToCurrencies?: Maybe<v1_BonusesToCurrencies_mutation_response>;
  /** delete single row from the table: "v1.BonusesToCurrencies" */
  delete_v1_BonusesToCurrencies_by_pk?: Maybe<v1_BonusesToCurrencies>;
  /** delete data from the table: "v1.BonusesToTypes" */
  delete_v1_BonusesToTypes?: Maybe<v1_BonusesToTypes_mutation_response>;
  /** delete single row from the table: "v1.BonusesToTypes" */
  delete_v1_BonusesToTypes_by_pk?: Maybe<v1_BonusesToTypes>;
  /** delete single row from the table: "v1.Bonuses" */
  delete_v1_Bonuses_by_pk?: Maybe<v1_Bonuses>;
  /** delete data from the table: "v1.BonusestoGames" */
  delete_v1_BonusestoGames?: Maybe<v1_BonusestoGames_mutation_response>;
  /** delete single row from the table: "v1.BonusestoGames" */
  delete_v1_BonusestoGames_by_pk?: Maybe<v1_BonusestoGames>;
  /** delete data from the table: "v1.BrandAlias" */
  delete_v1_BrandAlias?: Maybe<v1_BrandAlias_mutation_response>;
  /** delete single row from the table: "v1.BrandAlias" */
  delete_v1_BrandAlias_by_pk?: Maybe<v1_BrandAlias>;
  /** delete data from the table: "v1.BrandPriority" */
  delete_v1_BrandPriority?: Maybe<v1_BrandPriority_mutation_response>;
  /** delete single row from the table: "v1.BrandPriority" */
  delete_v1_BrandPriority_by_pk?: Maybe<v1_BrandPriority>;
  /** delete data from the table: "v1.Campaign" */
  delete_v1_Campaign?: Maybe<v1_Campaign_mutation_response>;
  /** delete data from the table: "v1.CampaignPriority" */
  delete_v1_CampaignPriority?: Maybe<v1_CampaignPriority_mutation_response>;
  /** delete single row from the table: "v1.CampaignPriority" */
  delete_v1_CampaignPriority_by_pk?: Maybe<v1_CampaignPriority>;
  /** delete data from the table: "v1.CampaignStatus" */
  delete_v1_CampaignStatus?: Maybe<v1_CampaignStatus_mutation_response>;
  /** delete single row from the table: "v1.CampaignStatus" */
  delete_v1_CampaignStatus_by_pk?: Maybe<v1_CampaignStatus>;
  /** delete data from the table: "v1.CampaignStrategy" */
  delete_v1_CampaignStrategy?: Maybe<v1_CampaignStrategy_mutation_response>;
  /** delete single row from the table: "v1.CampaignStrategy" */
  delete_v1_CampaignStrategy_by_pk?: Maybe<v1_CampaignStrategy>;
  /** delete data from the table: "v1.CampaignType" */
  delete_v1_CampaignType?: Maybe<v1_CampaignType_mutation_response>;
  /** delete single row from the table: "v1.CampaignType" */
  delete_v1_CampaignType_by_pk?: Maybe<v1_CampaignType>;
  /** delete single row from the table: "v1.Campaign" */
  delete_v1_Campaign_by_pk?: Maybe<v1_Campaign>;
  /** delete data from the table: "v1.Categories" */
  delete_v1_Categories?: Maybe<v1_Categories_mutation_response>;
  /** delete single row from the table: "v1.Categories" */
  delete_v1_Categories_by_pk?: Maybe<v1_Categories>;
  /** delete data from the table: "v1.Checkout" */
  delete_v1_Checkout?: Maybe<v1_Checkout_mutation_response>;
  /** delete data from the table: "v1.CheckoutDetail" */
  delete_v1_CheckoutDetail?: Maybe<v1_CheckoutDetail_mutation_response>;
  /** delete single row from the table: "v1.CheckoutDetail" */
  delete_v1_CheckoutDetail_by_pk?: Maybe<v1_CheckoutDetail>;
  /** delete data from the table: "v1.CheckoutStatus" */
  delete_v1_CheckoutStatus?: Maybe<v1_CheckoutStatus_mutation_response>;
  /** delete single row from the table: "v1.CheckoutStatus" */
  delete_v1_CheckoutStatus_by_pk?: Maybe<v1_CheckoutStatus>;
  /** delete single row from the table: "v1.Checkout" */
  delete_v1_Checkout_by_pk?: Maybe<v1_Checkout>;
  /** delete data from the table: "v1.Cities" */
  delete_v1_Cities?: Maybe<v1_Cities_mutation_response>;
  /** delete single row from the table: "v1.Cities" */
  delete_v1_Cities_by_pk?: Maybe<v1_Cities>;
  /** delete data from the table: "v1.ClicksByTrackers" */
  delete_v1_ClicksByTrackers?: Maybe<v1_ClicksByTrackers_mutation_response>;
  /** delete data from the table: "v1.ClicksByTrackersNew" */
  delete_v1_ClicksByTrackersNew?: Maybe<v1_ClicksByTrackersNew_mutation_response>;
  /** delete single row from the table: "v1.ClicksByTrackersNew" */
  delete_v1_ClicksByTrackersNew_by_pk?: Maybe<v1_ClicksByTrackersNew>;
  /** delete single row from the table: "v1.ClicksByTrackers" */
  delete_v1_ClicksByTrackers_by_pk?: Maybe<v1_ClicksByTrackers>;
  /** delete data from the table: "v1.CompanyType" */
  delete_v1_CompanyType?: Maybe<v1_CompanyType_mutation_response>;
  /** delete single row from the table: "v1.CompanyType" */
  delete_v1_CompanyType_by_pk?: Maybe<v1_CompanyType>;
  /** delete data from the table: "v1.Configurations" */
  delete_v1_Configurations?: Maybe<v1_Configurations_mutation_response>;
  /** delete single row from the table: "v1.Configurations" */
  delete_v1_Configurations_by_pk?: Maybe<v1_Configurations>;
  /** delete data from the table: "v1.ContactBase" */
  delete_v1_ContactBase?: Maybe<v1_ContactBase_mutation_response>;
  /** delete data from the table: "v1.ContactBasePaymentOption" */
  delete_v1_ContactBasePaymentOption?: Maybe<v1_ContactBasePaymentOption_mutation_response>;
  /** delete single row from the table: "v1.ContactBasePaymentOption" */
  delete_v1_ContactBasePaymentOption_by_pk?: Maybe<v1_ContactBasePaymentOption>;
  /** delete single row from the table: "v1.ContactBase" */
  delete_v1_ContactBase_by_pk?: Maybe<v1_ContactBase>;
  /** delete data from the table: "v1.ContactType" */
  delete_v1_ContactType?: Maybe<v1_ContactType_mutation_response>;
  /** delete single row from the table: "v1.ContactType" */
  delete_v1_ContactType_by_pk?: Maybe<v1_ContactType>;
  /** delete data from the table: "v1.Contacts_Roles" */
  delete_v1_Contacts_Roles?: Maybe<v1_Contacts_Roles_mutation_response>;
  /** delete single row from the table: "v1.Contacts_Roles" */
  delete_v1_Contacts_Roles_by_pk?: Maybe<v1_Contacts_Roles>;
  /** delete data from the table: "v1.ContentLink" */
  delete_v1_ContentLink?: Maybe<v1_ContentLink_mutation_response>;
  /** delete single row from the table: "v1.ContentLink" */
  delete_v1_ContentLink_by_pk?: Maybe<v1_ContentLink>;
  /** delete data from the table: "v1.ContentTypes" */
  delete_v1_ContentTypes?: Maybe<v1_ContentTypes_mutation_response>;
  /** delete single row from the table: "v1.ContentTypes" */
  delete_v1_ContentTypes_by_pk?: Maybe<v1_ContentTypes>;
  /** delete data from the table: "v1.Credential_Roles" */
  delete_v1_Credential_Roles?: Maybe<v1_Credential_Roles_mutation_response>;
  /** delete single row from the table: "v1.Credential_Roles" */
  delete_v1_Credential_Roles_by_pk?: Maybe<v1_Credential_Roles>;
  /** delete data from the table: "v1.Credentials" */
  delete_v1_Credentials?: Maybe<v1_Credentials_mutation_response>;
  /** delete single row from the table: "v1.Credentials" */
  delete_v1_Credentials_by_pk?: Maybe<v1_Credentials>;
  /** delete data from the table: "v1.DomainExtensions" */
  delete_v1_DomainExtensions?: Maybe<v1_DomainExtensions_mutation_response>;
  /** delete single row from the table: "v1.DomainExtensions" */
  delete_v1_DomainExtensions_by_pk?: Maybe<v1_DomainExtensions>;
  /** delete data from the table: "v1.Events" */
  delete_v1_Events?: Maybe<v1_Events_mutation_response>;
  /** delete single row from the table: "v1.Events" */
  delete_v1_Events_by_pk?: Maybe<v1_Events>;
  /** delete data from the table: "v1.ExpiringRecPayments" */
  delete_v1_ExpiringRecPayments?: Maybe<v1_ExpiringRecPayments_mutation_response>;
  /** delete data from the table: "v1.Files" */
  delete_v1_Files?: Maybe<v1_Files_mutation_response>;
  /** delete single row from the table: "v1.Files" */
  delete_v1_Files_by_pk?: Maybe<v1_Files>;
  /** delete data from the table: "v1.GameType" */
  delete_v1_GameType?: Maybe<v1_GameType_mutation_response>;
  /** delete single row from the table: "v1.GameType" */
  delete_v1_GameType_by_pk?: Maybe<v1_GameType>;
  /** delete data from the table: "v1.InvoiceBase" */
  delete_v1_InvoiceBase?: Maybe<v1_InvoiceBase_mutation_response>;
  /** delete single row from the table: "v1.InvoiceBase" */
  delete_v1_InvoiceBase_by_pk?: Maybe<v1_InvoiceBase>;
  /** delete data from the table: "v1.InvoiceDetailBase" */
  delete_v1_InvoiceDetailBase?: Maybe<v1_InvoiceDetailBase_mutation_response>;
  /** delete single row from the table: "v1.InvoiceDetailBase" */
  delete_v1_InvoiceDetailBase_by_pk?: Maybe<v1_InvoiceDetailBase>;
  /** delete data from the table: "v1.InvoiceStatus" */
  delete_v1_InvoiceStatus?: Maybe<v1_InvoiceStatus_mutation_response>;
  /** delete single row from the table: "v1.InvoiceStatus" */
  delete_v1_InvoiceStatus_by_pk?: Maybe<v1_InvoiceStatus>;
  /** delete data from the table: "v1.LandingPagePriority" */
  delete_v1_LandingPagePriority?: Maybe<v1_LandingPagePriority_mutation_response>;
  /** delete single row from the table: "v1.LandingPagePriority" */
  delete_v1_LandingPagePriority_by_pk?: Maybe<v1_LandingPagePriority>;
  /** delete data from the table: "v1.LandingPages" */
  delete_v1_LandingPages?: Maybe<v1_LandingPages_mutation_response>;
  /** delete single row from the table: "v1.LandingPages" */
  delete_v1_LandingPages_by_pk?: Maybe<v1_LandingPages>;
  /** delete data from the table: "v1.LanguagePriceStep" */
  delete_v1_LanguagePriceStep?: Maybe<v1_LanguagePriceStep_mutation_response>;
  /** delete single row from the table: "v1.LanguagePriceStep" */
  delete_v1_LanguagePriceStep_by_pk?: Maybe<v1_LanguagePriceStep>;
  /** delete data from the table: "v1.MarketPlaceCategories" */
  delete_v1_MarketPlaceCategories?: Maybe<v1_MarketPlaceCategories_mutation_response>;
  /** delete data from the table: "v1.MarketPlaceCategoriesTypes" */
  delete_v1_MarketPlaceCategoriesTypes?: Maybe<v1_MarketPlaceCategoriesTypes_mutation_response>;
  /** delete single row from the table: "v1.MarketPlaceCategoriesTypes" */
  delete_v1_MarketPlaceCategoriesTypes_by_pk?: Maybe<v1_MarketPlaceCategoriesTypes>;
  /** delete data from the table: "v1.MarketPlaceCategories_Offers" */
  delete_v1_MarketPlaceCategories_Offers?: Maybe<v1_MarketPlaceCategories_Offers_mutation_response>;
  /** delete single row from the table: "v1.MarketPlaceCategories_Offers" */
  delete_v1_MarketPlaceCategories_Offers_by_pk?: Maybe<v1_MarketPlaceCategories_Offers>;
  /** delete single row from the table: "v1.MarketPlaceCategories" */
  delete_v1_MarketPlaceCategories_by_pk?: Maybe<v1_MarketPlaceCategories>;
  /** delete data from the table: "v1.MarketPlaceOfferNiche" */
  delete_v1_MarketPlaceOfferNiche?: Maybe<v1_MarketPlaceOfferNiche_mutation_response>;
  /** delete single row from the table: "v1.MarketPlaceOfferNiche" */
  delete_v1_MarketPlaceOfferNiche_by_pk?: Maybe<v1_MarketPlaceOfferNiche>;
  /** delete data from the table: "v1.MarketPlaceOrder" */
  delete_v1_MarketPlaceOrder?: Maybe<v1_MarketPlaceOrder_mutation_response>;
  /** delete data from the table: "v1.MarketPlaceOrderRequest" */
  delete_v1_MarketPlaceOrderRequest?: Maybe<v1_MarketPlaceOrderRequest_mutation_response>;
  /** delete single row from the table: "v1.MarketPlaceOrderRequest" */
  delete_v1_MarketPlaceOrderRequest_by_pk?: Maybe<v1_MarketPlaceOrderRequest>;
  /** delete data from the table: "v1.MarketPlaceOrderStatus" */
  delete_v1_MarketPlaceOrderStatus?: Maybe<v1_MarketPlaceOrderStatus_mutation_response>;
  /** delete single row from the table: "v1.MarketPlaceOrderStatus" */
  delete_v1_MarketPlaceOrderStatus_by_pk?: Maybe<v1_MarketPlaceOrderStatus>;
  /** delete single row from the table: "v1.MarketPlaceOrder" */
  delete_v1_MarketPlaceOrder_by_pk?: Maybe<v1_MarketPlaceOrder>;
  /** delete data from the table: "v1.MarketPlaceRegisterStatus" */
  delete_v1_MarketPlaceRegisterStatus?: Maybe<v1_MarketPlaceRegisterStatus_mutation_response>;
  /** delete single row from the table: "v1.MarketPlaceRegisterStatus" */
  delete_v1_MarketPlaceRegisterStatus_by_pk?: Maybe<v1_MarketPlaceRegisterStatus>;
  /** delete data from the table: "v1.MarketType" */
  delete_v1_MarketType?: Maybe<v1_MarketType_mutation_response>;
  /** delete single row from the table: "v1.MarketType" */
  delete_v1_MarketType_by_pk?: Maybe<v1_MarketType>;
  /** delete data from the table: "v1.MarketplaceProjects" */
  delete_v1_MarketplaceProjects?: Maybe<v1_MarketplaceProjects_mutation_response>;
  /** delete single row from the table: "v1.MarketplaceProjects" */
  delete_v1_MarketplaceProjects_by_pk?: Maybe<v1_MarketplaceProjects>;
  /** delete data from the table: "v1.MarketplaceStatus" */
  delete_v1_MarketplaceStatus?: Maybe<v1_MarketplaceStatus_mutation_response>;
  /** delete single row from the table: "v1.MarketplaceStatus" */
  delete_v1_MarketplaceStatus_by_pk?: Maybe<v1_MarketplaceStatus>;
  /** delete data from the table: "v1.Months" */
  delete_v1_Months?: Maybe<v1_Months_mutation_response>;
  /** delete single row from the table: "v1.Months" */
  delete_v1_Months_by_pk?: Maybe<v1_Months>;
  /** delete data from the table: "v1.Offer" */
  delete_v1_Offer?: Maybe<v1_Offer_mutation_response>;
  /** delete data from the table: "v1.OfferStatus" */
  delete_v1_OfferStatus?: Maybe<v1_OfferStatus_mutation_response>;
  /** delete single row from the table: "v1.OfferStatus" */
  delete_v1_OfferStatus_by_pk?: Maybe<v1_OfferStatus>;
  /** delete data from the table: "v1.OfferTags" */
  delete_v1_OfferTags?: Maybe<v1_OfferTags_mutation_response>;
  /** delete single row from the table: "v1.OfferTags" */
  delete_v1_OfferTags_by_pk?: Maybe<v1_OfferTags>;
  /** delete data from the table: "v1.OfferToTags" */
  delete_v1_OfferToTags?: Maybe<v1_OfferToTags_mutation_response>;
  /** delete single row from the table: "v1.OfferToTags" */
  delete_v1_OfferToTags_by_pk?: Maybe<v1_OfferToTags>;
  /** delete single row from the table: "v1.Offer" */
  delete_v1_Offer_by_pk?: Maybe<v1_Offer>;
  /** delete data from the table: "v1.OrderFor" */
  delete_v1_OrderFor?: Maybe<v1_OrderFor_mutation_response>;
  /** delete single row from the table: "v1.OrderFor" */
  delete_v1_OrderFor_by_pk?: Maybe<v1_OrderFor>;
  /** delete data from the table: "v1.OrderPayTo" */
  delete_v1_OrderPayTo?: Maybe<v1_OrderPayTo_mutation_response>;
  /** delete single row from the table: "v1.OrderPayTo" */
  delete_v1_OrderPayTo_by_pk?: Maybe<v1_OrderPayTo>;
  /** delete data from the table: "v1.OrderPriority" */
  delete_v1_OrderPriority?: Maybe<v1_OrderPriority_mutation_response>;
  /** delete single row from the table: "v1.OrderPriority" */
  delete_v1_OrderPriority_by_pk?: Maybe<v1_OrderPriority>;
  /** delete data from the table: "v1.OrderType" */
  delete_v1_OrderType?: Maybe<v1_OrderType_mutation_response>;
  /** delete single row from the table: "v1.OrderType" */
  delete_v1_OrderType_by_pk?: Maybe<v1_OrderType>;
  /** delete data from the table: "v1.Page" */
  delete_v1_Page?: Maybe<v1_Page_mutation_response>;
  /** delete single row from the table: "v1.Page" */
  delete_v1_Page_by_pk?: Maybe<v1_Page>;
  /** delete data from the table: "v1.PaymentCompanies" */
  delete_v1_PaymentCompanies?: Maybe<v1_PaymentCompanies_mutation_response>;
  /** delete single row from the table: "v1.PaymentCompanies" */
  delete_v1_PaymentCompanies_by_pk?: Maybe<v1_PaymentCompanies>;
  /** delete data from the table: "v1.PaymentFrequency" */
  delete_v1_PaymentFrequency?: Maybe<v1_PaymentFrequency_mutation_response>;
  /** delete single row from the table: "v1.PaymentFrequency" */
  delete_v1_PaymentFrequency_by_pk?: Maybe<v1_PaymentFrequency>;
  /** delete data from the table: "v1.PoToPosts" */
  delete_v1_PoToPosts?: Maybe<v1_PoToPosts_mutation_response>;
  /** delete single row from the table: "v1.PoToPosts" */
  delete_v1_PoToPosts_by_pk?: Maybe<v1_PoToPosts>;
  /** delete data from the table: "v1.PostDuration" */
  delete_v1_PostDuration?: Maybe<v1_PostDuration_mutation_response>;
  /** delete single row from the table: "v1.PostDuration" */
  delete_v1_PostDuration_by_pk?: Maybe<v1_PostDuration>;
  /** delete data from the table: "v1.PostNiche" */
  delete_v1_PostNiche?: Maybe<v1_PostNiche_mutation_response>;
  /** delete single row from the table: "v1.PostNiche" */
  delete_v1_PostNiche_by_pk?: Maybe<v1_PostNiche>;
  /** delete data from the table: "v1.Posts" */
  delete_v1_Posts?: Maybe<v1_Posts_mutation_response>;
  /** delete data from the table: "v1.PostsAnchorTextType" */
  delete_v1_PostsAnchorTextType?: Maybe<v1_PostsAnchorTextType_mutation_response>;
  /** delete single row from the table: "v1.PostsAnchorTextType" */
  delete_v1_PostsAnchorTextType_by_pk?: Maybe<v1_PostsAnchorTextType>;
  /** delete data from the table: "v1.PostsContentStatus" */
  delete_v1_PostsContentStatus?: Maybe<v1_PostsContentStatus_mutation_response>;
  /** delete single row from the table: "v1.PostsContentStatus" */
  delete_v1_PostsContentStatus_by_pk?: Maybe<v1_PostsContentStatus>;
  /** delete data from the table: "v1.PostsCopyScape" */
  delete_v1_PostsCopyScape?: Maybe<v1_PostsCopyScape_mutation_response>;
  /** delete single row from the table: "v1.PostsCopyScape" */
  delete_v1_PostsCopyScape_by_pk?: Maybe<v1_PostsCopyScape>;
  /** delete data from the table: "v1.PostsLinkStatus" */
  delete_v1_PostsLinkStatus?: Maybe<v1_PostsLinkStatus_mutation_response>;
  /** delete single row from the table: "v1.PostsLinkStatus" */
  delete_v1_PostsLinkStatus_by_pk?: Maybe<v1_PostsLinkStatus>;
  /** delete data from the table: "v1.PostsOnlineStatus" */
  delete_v1_PostsOnlineStatus?: Maybe<v1_PostsOnlineStatus_mutation_response>;
  /** delete single row from the table: "v1.PostsOnlineStatus" */
  delete_v1_PostsOnlineStatus_by_pk?: Maybe<v1_PostsOnlineStatus>;
  /** delete data from the table: "v1.PostsOutreachStatus" */
  delete_v1_PostsOutreachStatus?: Maybe<v1_PostsOutreachStatus_mutation_response>;
  /** delete single row from the table: "v1.PostsOutreachStatus" */
  delete_v1_PostsOutreachStatus_by_pk?: Maybe<v1_PostsOutreachStatus>;
  /** delete data from the table: "v1.PostsPageTypeDeprecated" */
  delete_v1_PostsPageTypeDeprecated?: Maybe<v1_PostsPageTypeDeprecated_mutation_response>;
  /** delete single row from the table: "v1.PostsPageTypeDeprecated" */
  delete_v1_PostsPageTypeDeprecated_by_pk?: Maybe<v1_PostsPageTypeDeprecated>;
  /** delete data from the table: "v1.PostsRemovalStatus" */
  delete_v1_PostsRemovalStatus?: Maybe<v1_PostsRemovalStatus_mutation_response>;
  /** delete single row from the table: "v1.PostsRemovalStatus" */
  delete_v1_PostsRemovalStatus_by_pk?: Maybe<v1_PostsRemovalStatus>;
  /** delete data from the table: "v1.PostsSEOApproval" */
  delete_v1_PostsSEOApproval?: Maybe<v1_PostsSEOApproval_mutation_response>;
  /** delete single row from the table: "v1.PostsSEOApproval" */
  delete_v1_PostsSEOApproval_by_pk?: Maybe<v1_PostsSEOApproval>;
  /** delete data from the table: "v1.PostsSEODeclineReason" */
  delete_v1_PostsSEODeclineReason?: Maybe<v1_PostsSEODeclineReason_mutation_response>;
  /** delete single row from the table: "v1.PostsSEODeclineReason" */
  delete_v1_PostsSEODeclineReason_by_pk?: Maybe<v1_PostsSEODeclineReason>;
  /** delete data from the table: "v1.PostsStatus" */
  delete_v1_PostsStatus?: Maybe<v1_PostsStatus_mutation_response>;
  /** delete data from the table: "v1.PostsStatusCode" */
  delete_v1_PostsStatusCode?: Maybe<v1_PostsStatusCode_mutation_response>;
  /** delete single row from the table: "v1.PostsStatusCode" */
  delete_v1_PostsStatusCode_by_pk?: Maybe<v1_PostsStatusCode>;
  /** delete single row from the table: "v1.PostsStatus" */
  delete_v1_PostsStatus_by_pk?: Maybe<v1_PostsStatus>;
  /** delete single row from the table: "v1.Posts" */
  delete_v1_Posts_by_pk?: Maybe<v1_Posts>;
  /** delete data from the table: "v1.Preferences" */
  delete_v1_Preferences?: Maybe<v1_Preferences_mutation_response>;
  /** delete data from the table: "v1.PreferencesMarketplace" */
  delete_v1_PreferencesMarketplace?: Maybe<v1_PreferencesMarketplace_mutation_response>;
  /** delete single row from the table: "v1.PreferencesMarketplace" */
  delete_v1_PreferencesMarketplace_by_pk?: Maybe<v1_PreferencesMarketplace>;
  /** delete single row from the table: "v1.Preferences" */
  delete_v1_Preferences_by_pk?: Maybe<v1_Preferences>;
  /** delete data from the table: "v1.Project" */
  delete_v1_Project?: Maybe<v1_Project_mutation_response>;
  /** delete data from the table: "v1.ProjectImportance" */
  delete_v1_ProjectImportance?: Maybe<v1_ProjectImportance_mutation_response>;
  /** delete single row from the table: "v1.ProjectImportance" */
  delete_v1_ProjectImportance_by_pk?: Maybe<v1_ProjectImportance>;
  /** delete data from the table: "v1.ProjectStatus" */
  delete_v1_ProjectStatus?: Maybe<v1_ProjectStatus_mutation_response>;
  /** delete single row from the table: "v1.ProjectStatus" */
  delete_v1_ProjectStatus_by_pk?: Maybe<v1_ProjectStatus>;
  /** delete data from the table: "v1.ProjectTopic" */
  delete_v1_ProjectTopic?: Maybe<v1_ProjectTopic_mutation_response>;
  /** delete single row from the table: "v1.ProjectTopic" */
  delete_v1_ProjectTopic_by_pk?: Maybe<v1_ProjectTopic>;
  /** delete data from the table: "v1.Project_Countries" */
  delete_v1_Project_Countries?: Maybe<v1_Project_Countries_mutation_response>;
  /** delete single row from the table: "v1.Project_Countries" */
  delete_v1_Project_Countries_by_pk?: Maybe<v1_Project_Countries>;
  /** delete single row from the table: "v1.Project" */
  delete_v1_Project_by_pk?: Maybe<v1_Project>;
  /** delete data from the table: "v1.PublisherPostStatus" */
  delete_v1_PublisherPostStatus?: Maybe<v1_PublisherPostStatus_mutation_response>;
  /** delete single row from the table: "v1.PublisherPostStatus" */
  delete_v1_PublisherPostStatus_by_pk?: Maybe<v1_PublisherPostStatus>;
  /** delete data from the table: "v1.PurchaseOrderStatus" */
  delete_v1_PurchaseOrderStatus?: Maybe<v1_PurchaseOrderStatus_mutation_response>;
  /** delete single row from the table: "v1.PurchaseOrderStatus" */
  delete_v1_PurchaseOrderStatus_by_pk?: Maybe<v1_PurchaseOrderStatus>;
  /** delete data from the table: "v1.ReceiptStatus" */
  delete_v1_ReceiptStatus?: Maybe<v1_ReceiptStatus_mutation_response>;
  /** delete single row from the table: "v1.ReceiptStatus" */
  delete_v1_ReceiptStatus_by_pk?: Maybe<v1_ReceiptStatus>;
  /** delete data from the table: "v1.RecurringPaymentStatus" */
  delete_v1_RecurringPaymentStatus?: Maybe<v1_RecurringPaymentStatus_mutation_response>;
  /** delete single row from the table: "v1.RecurringPaymentStatus" */
  delete_v1_RecurringPaymentStatus_by_pk?: Maybe<v1_RecurringPaymentStatus>;
  /** delete data from the table: "v1.RecurringPayments" */
  delete_v1_RecurringPayments?: Maybe<v1_RecurringPayments_mutation_response>;
  /** delete data from the table: "v1.RecurringPayments_Roles" */
  delete_v1_RecurringPayments_Roles?: Maybe<v1_RecurringPayments_Roles_mutation_response>;
  /** delete single row from the table: "v1.RecurringPayments_Roles" */
  delete_v1_RecurringPayments_Roles_by_pk?: Maybe<v1_RecurringPayments_Roles>;
  /** delete single row from the table: "v1.RecurringPayments" */
  delete_v1_RecurringPayments_by_pk?: Maybe<v1_RecurringPayments>;
  /** delete data from the table: "v1.Report" */
  delete_v1_Report?: Maybe<v1_Report_mutation_response>;
  /** delete data from the table: "v1.Report_Roles" */
  delete_v1_Report_Roles?: Maybe<v1_Report_Roles_mutation_response>;
  /** delete single row from the table: "v1.Report_Roles" */
  delete_v1_Report_Roles_by_pk?: Maybe<v1_Report_Roles>;
  /** delete single row from the table: "v1.Report" */
  delete_v1_Report_by_pk?: Maybe<v1_Report>;
  /** delete data from the table: "v1.RestrictedNichesToOffer" */
  delete_v1_RestrictedNichesToOffer?: Maybe<v1_RestrictedNichesToOffer_mutation_response>;
  /** delete single row from the table: "v1.RestrictedNichesToOffer" */
  delete_v1_RestrictedNichesToOffer_by_pk?: Maybe<v1_RestrictedNichesToOffer>;
  /** delete data from the table: "v1.Roles" */
  delete_v1_Roles?: Maybe<v1_Roles_mutation_response>;
  /** delete single row from the table: "v1.Roles" */
  delete_v1_Roles_by_pk?: Maybe<v1_Roles>;
  /** delete data from the table: "v1.SalesOrderBase" */
  delete_v1_SalesOrderBase?: Maybe<v1_SalesOrderBase_mutation_response>;
  /** delete single row from the table: "v1.SalesOrderBase" */
  delete_v1_SalesOrderBase_by_pk?: Maybe<v1_SalesOrderBase>;
  /** delete data from the table: "v1.ServiceTypes" */
  delete_v1_ServiceTypes?: Maybe<v1_ServiceTypes_mutation_response>;
  /** delete single row from the table: "v1.ServiceTypes" */
  delete_v1_ServiceTypes_by_pk?: Maybe<v1_ServiceTypes>;
  /** delete data from the table: "v1.SoftwareProviders" */
  delete_v1_SoftwareProviders?: Maybe<v1_SoftwareProviders_mutation_response>;
  /** delete single row from the table: "v1.SoftwareProviders" */
  delete_v1_SoftwareProviders_by_pk?: Maybe<v1_SoftwareProviders>;
  /** delete data from the table: "v1.StrategyFieldsSet" */
  delete_v1_StrategyFieldsSet?: Maybe<v1_StrategyFieldsSet_mutation_response>;
  /** delete single row from the table: "v1.StrategyFieldsSet" */
  delete_v1_StrategyFieldsSet_by_pk?: Maybe<v1_StrategyFieldsSet>;
  /** delete data from the table: "v1.Supplier_Tag_rel" */
  delete_v1_Supplier_Tag_rel?: Maybe<v1_Supplier_Tag_rel_mutation_response>;
  /** delete single row from the table: "v1.Supplier_Tag_rel" */
  delete_v1_Supplier_Tag_rel_by_pk?: Maybe<v1_Supplier_Tag_rel>;
  /** delete data from the table: "v1.SystemUserBase" */
  delete_v1_SystemUserBase?: Maybe<v1_SystemUserBase_mutation_response>;
  /** delete single row from the table: "v1.SystemUserBase" */
  delete_v1_SystemUserBase_by_pk?: Maybe<v1_SystemUserBase>;
  /** delete data from the table: "v1.Tags" */
  delete_v1_Tags?: Maybe<v1_Tags_mutation_response>;
  /** delete single row from the table: "v1.Tags" */
  delete_v1_Tags_by_pk?: Maybe<v1_Tags>;
  /** delete data from the table: "v1.Teams" */
  delete_v1_Teams?: Maybe<v1_Teams_mutation_response>;
  /** delete single row from the table: "v1.Teams" */
  delete_v1_Teams_by_pk?: Maybe<v1_Teams>;
  /** delete data from the table: "v1.TrackerDeal" */
  delete_v1_TrackerDeal?: Maybe<v1_TrackerDeal_mutation_response>;
  /** delete single row from the table: "v1.TrackerDeal" */
  delete_v1_TrackerDeal_by_pk?: Maybe<v1_TrackerDeal>;
  /** delete data from the table: "v1.TrackerTags" */
  delete_v1_TrackerTags?: Maybe<v1_TrackerTags_mutation_response>;
  /** delete single row from the table: "v1.TrackerTags" */
  delete_v1_TrackerTags_by_pk?: Maybe<v1_TrackerTags>;
  /** delete data from the table: "v1.TrackerToTags" */
  delete_v1_TrackerToTags?: Maybe<v1_TrackerToTags_mutation_response>;
  /** delete single row from the table: "v1.TrackerToTags" */
  delete_v1_TrackerToTags_by_pk?: Maybe<v1_TrackerToTags>;
  /** delete data from the table: "v1.TransactionCurrencyBase" */
  delete_v1_TransactionCurrencyBase?: Maybe<v1_TransactionCurrencyBase_mutation_response>;
  /** delete single row from the table: "v1.TransactionCurrencyBase" */
  delete_v1_TransactionCurrencyBase_by_pk?: Maybe<v1_TransactionCurrencyBase>;
  /** delete data from the table: "v1.UsersStatus" */
  delete_v1_UsersStatus?: Maybe<v1_UsersStatus_mutation_response>;
  /** delete single row from the table: "v1.UsersStatus" */
  delete_v1_UsersStatus_by_pk?: Maybe<v1_UsersStatus>;
  /** delete data from the table: "v1.UsersToOffers" */
  delete_v1_UsersToOffers?: Maybe<v1_UsersToOffers_mutation_response>;
  /** delete single row from the table: "v1.UsersToOffers" */
  delete_v1_UsersToOffers_by_pk?: Maybe<v1_UsersToOffers>;
  /** delete data from the table: "v1.Users_Roles" */
  delete_v1_Users_Roles?: Maybe<v1_Users_Roles_mutation_response>;
  /** delete single row from the table: "v1.Users_Roles" */
  delete_v1_Users_Roles_by_pk?: Maybe<v1_Users_Roles>;
  /** delete data from the table: "v1.Users_Teams" */
  delete_v1_Users_Teams?: Maybe<v1_Users_Teams_mutation_response>;
  /** delete single row from the table: "v1.Users_Teams" */
  delete_v1_Users_Teams_by_pk?: Maybe<v1_Users_Teams>;
  /** delete data from the table: "v1.WageringRequirementsLocation" */
  delete_v1_WageringRequirementsLocation?: Maybe<v1_WageringRequirementsLocation_mutation_response>;
  /** delete single row from the table: "v1.WageringRequirementsLocation" */
  delete_v1_WageringRequirementsLocation_by_pk?: Maybe<v1_WageringRequirementsLocation>;
  /** delete data from the table: "v1.Website" */
  delete_v1_Website?: Maybe<v1_Website_mutation_response>;
  /** delete data from the table: "v1.WebsiteBlogProbability" */
  delete_v1_WebsiteBlogProbability?: Maybe<v1_WebsiteBlogProbability_mutation_response>;
  /** delete single row from the table: "v1.WebsiteBlogProbability" */
  delete_v1_WebsiteBlogProbability_by_pk?: Maybe<v1_WebsiteBlogProbability>;
  /** delete data from the table: "v1.WebsiteContentIndicator" */
  delete_v1_WebsiteContentIndicator?: Maybe<v1_WebsiteContentIndicator_mutation_response>;
  /** delete single row from the table: "v1.WebsiteContentIndicator" */
  delete_v1_WebsiteContentIndicator_by_pk?: Maybe<v1_WebsiteContentIndicator>;
  /** delete data from the table: "v1.WebsiteDToxRisk" */
  delete_v1_WebsiteDToxRisk?: Maybe<v1_WebsiteDToxRisk_mutation_response>;
  /** delete single row from the table: "v1.WebsiteDToxRisk" */
  delete_v1_WebsiteDToxRisk_by_pk?: Maybe<v1_WebsiteDToxRisk>;
  /** delete data from the table: "v1.WebsiteQualityScore" */
  delete_v1_WebsiteQualityScore?: Maybe<v1_WebsiteQualityScore_mutation_response>;
  /** delete single row from the table: "v1.WebsiteQualityScore" */
  delete_v1_WebsiteQualityScore_by_pk?: Maybe<v1_WebsiteQualityScore>;
  /** delete data from the table: "v1.WebsiteStatus" */
  delete_v1_WebsiteStatus?: Maybe<v1_WebsiteStatus_mutation_response>;
  /** delete single row from the table: "v1.WebsiteStatus" */
  delete_v1_WebsiteStatus_by_pk?: Maybe<v1_WebsiteStatus>;
  /** delete data from the table: "v1.WebsiteTopics" */
  delete_v1_WebsiteTopics?: Maybe<v1_WebsiteTopics_mutation_response>;
  /** delete single row from the table: "v1.WebsiteTopics" */
  delete_v1_WebsiteTopics_by_pk?: Maybe<v1_WebsiteTopics>;
  /** delete data from the table: "v1.WebsiteUptimeStatus" */
  delete_v1_WebsiteUptimeStatus?: Maybe<v1_WebsiteUptimeStatus_mutation_response>;
  /** delete single row from the table: "v1.WebsiteUptimeStatus" */
  delete_v1_WebsiteUptimeStatus_by_pk?: Maybe<v1_WebsiteUptimeStatus>;
  /** delete data from the table: "v1.Website_Platform" */
  delete_v1_Website_Platform?: Maybe<v1_Website_Platform_mutation_response>;
  /** delete single row from the table: "v1.Website_Platform" */
  delete_v1_Website_Platform_by_pk?: Maybe<v1_Website_Platform>;
  /** delete single row from the table: "v1.Website" */
  delete_v1_Website_by_pk?: Maybe<v1_Website>;
  /** delete data from the table: "v1.WebsitesToTopics" */
  delete_v1_WebsitesToTopics?: Maybe<v1_WebsitesToTopics_mutation_response>;
  /** delete single row from the table: "v1.WebsitesToTopics" */
  delete_v1_WebsitesToTopics_by_pk?: Maybe<v1_WebsitesToTopics>;
  /** delete data from the table: "v1.new_POBase" */
  delete_v1_new_POBase?: Maybe<v1_new_POBase_mutation_response>;
  /** delete single row from the table: "v1.new_POBase" */
  delete_v1_new_POBase_by_pk?: Maybe<v1_new_POBase>;
  /** delete data from the table: "v1.new_affiliateincomereportBase" */
  delete_v1_new_affiliateincomereportBase?: Maybe<v1_new_affiliateincomereportBase_mutation_response>;
  /** delete single row from the table: "v1.new_affiliateincomereportBase" */
  delete_v1_new_affiliateincomereportBase_by_pk?: Maybe<v1_new_affiliateincomereportBase>;
  /** delete data from the table: "v1.new_affordertypeBase" */
  delete_v1_new_affordertypeBase?: Maybe<v1_new_affordertypeBase_mutation_response>;
  /** delete single row from the table: "v1.new_affordertypeBase" */
  delete_v1_new_affordertypeBase_by_pk?: Maybe<v1_new_affordertypeBase>;
  /** delete data from the table: "v1.new_brandBase" */
  delete_v1_new_brandBase?: Maybe<v1_new_brandBase_mutation_response>;
  /** delete single row from the table: "v1.new_brandBase" */
  delete_v1_new_brandBase_by_pk?: Maybe<v1_new_brandBase>;
  /** delete data from the table: "v1.new_countryBase" */
  delete_v1_new_countryBase?: Maybe<v1_new_countryBase_mutation_response>;
  /** delete data from the table: "v1.new_languagesBase" */
  delete_v1_new_languagesBase?: Maybe<v1_new_languagesBase_mutation_response>;
  /** delete single row from the table: "v1.new_languagesBase" */
  delete_v1_new_languagesBase_by_pk?: Maybe<v1_new_languagesBase>;
  /** delete data from the table: "v1.new_new_brand_new_countryBase" */
  delete_v1_new_new_brand_new_countryBase?: Maybe<v1_new_new_brand_new_countryBase_mutation_response>;
  /** delete single row from the table: "v1.new_new_brand_new_countryBase" */
  delete_v1_new_new_brand_new_countryBase_by_pk?: Maybe<v1_new_new_brand_new_countryBase>;
  /** delete data from the table: "v1.new_paymentmethodBase" */
  delete_v1_new_paymentmethodBase?: Maybe<v1_new_paymentmethodBase_mutation_response>;
  /** delete single row from the table: "v1.new_paymentmethodBase" */
  delete_v1_new_paymentmethodBase_by_pk?: Maybe<v1_new_paymentmethodBase>;
  /** delete data from the table: "v1.new_receiptBase" */
  delete_v1_new_receiptBase?: Maybe<v1_new_receiptBase_mutation_response>;
  /** delete single row from the table: "v1.new_receiptBase" */
  delete_v1_new_receiptBase_by_pk?: Maybe<v1_new_receiptBase>;
  /** delete data from the table: "v1.new_regemailBase" */
  delete_v1_new_regemailBase?: Maybe<v1_new_regemailBase_mutation_response>;
  /** delete single row from the table: "v1.new_regemailBase" */
  delete_v1_new_regemailBase_by_pk?: Maybe<v1_new_regemailBase>;
  /** delete data from the table: "v1.new_trackerBase" */
  delete_v1_new_trackerBase?: Maybe<v1_new_trackerBase_mutation_response>;
  /** delete single row from the table: "v1.new_trackerBase" */
  delete_v1_new_trackerBase_by_pk?: Maybe<v1_new_trackerBase>;
  /** delete data from the table: "v1.process" */
  delete_v1_process?: Maybe<v1_process_mutation_response>;
  /** delete single row from the table: "v1.process" */
  delete_v1_process_by_pk?: Maybe<v1_process>;
  /** delete data from the table: "v1.process_rate" */
  delete_v1_process_rate?: Maybe<v1_process_rate_mutation_response>;
  /** delete single row from the table: "v1.process_rate" */
  delete_v1_process_rate_by_pk?: Maybe<v1_process_rate>;
  /** delete data from the table: "v1.project_topic" */
  delete_v1_project_topic?: Maybe<v1_project_topic_mutation_response>;
  /** delete single row from the table: "v1.project_topic" */
  delete_v1_project_topic_by_pk?: Maybe<v1_project_topic>;
  /** ImportWebsitesStatistics */
  importWebsitesStatistics?: Maybe<WebsitesStatisticsOutput>;
  /** insertPosts */
  insertPosts?: Maybe<InsertPostsOutput>;
  /** insert data into the table: "v1.AccountBase" */
  insert_v1_AccountBase?: Maybe<v1_AccountBase_mutation_response>;
  /** insert a single row into the table: "v1.AccountBase" */
  insert_v1_AccountBase_one?: Maybe<v1_AccountBase>;
  /** insert data into the table: "v1.AffDealType" */
  insert_v1_AffDealType?: Maybe<v1_AffDealType_mutation_response>;
  /** insert a single row into the table: "v1.AffDealType" */
  insert_v1_AffDealType_one?: Maybe<v1_AffDealType>;
  /** insert data into the table: "v1.AffiliateOrderCategoriesPriority" */
  insert_v1_AffiliateOrderCategoriesPriority?: Maybe<v1_AffiliateOrderCategoriesPriority_mutation_response>;
  /** insert a single row into the table: "v1.AffiliateOrderCategoriesPriority" */
  insert_v1_AffiliateOrderCategoriesPriority_one?: Maybe<v1_AffiliateOrderCategoriesPriority>;
  /** insert data into the table: "v1.AffiliateProgramType" */
  insert_v1_AffiliateProgramType?: Maybe<v1_AffiliateProgramType_mutation_response>;
  /** insert a single row into the table: "v1.AffiliateProgramType" */
  insert_v1_AffiliateProgramType_one?: Maybe<v1_AffiliateProgramType>;
  /** insert data into the table: "v1.AllowedPlayers" */
  insert_v1_AllowedPlayers?: Maybe<v1_AllowedPlayers_mutation_response>;
  /** insert a single row into the table: "v1.AllowedPlayers" */
  insert_v1_AllowedPlayers_one?: Maybe<v1_AllowedPlayers>;
  /** insert data into the table: "v1.ArticleTones" */
  insert_v1_ArticleTones?: Maybe<v1_ArticleTones_mutation_response>;
  /** insert a single row into the table: "v1.ArticleTones" */
  insert_v1_ArticleTones_one?: Maybe<v1_ArticleTones>;
  /** insert data into the table: "v1.Balance" */
  insert_v1_Balance?: Maybe<v1_Balance_mutation_response>;
  /** insert data into the table: "v1.BalanceActivityType" */
  insert_v1_BalanceActivityType?: Maybe<v1_BalanceActivityType_mutation_response>;
  /** insert a single row into the table: "v1.BalanceActivityType" */
  insert_v1_BalanceActivityType_one?: Maybe<v1_BalanceActivityType>;
  /** insert a single row into the table: "v1.Balance" */
  insert_v1_Balance_one?: Maybe<v1_Balance>;
  /** insert data into the table: "v1.BillingInformation" */
  insert_v1_BillingInformation?: Maybe<v1_BillingInformation_mutation_response>;
  /** insert a single row into the table: "v1.BillingInformation" */
  insert_v1_BillingInformation_one?: Maybe<v1_BillingInformation>;
  /** insert data into the table: "v1.BillingMethod" */
  insert_v1_BillingMethod?: Maybe<v1_BillingMethod_mutation_response>;
  /** insert a single row into the table: "v1.BillingMethod" */
  insert_v1_BillingMethod_one?: Maybe<v1_BillingMethod>;
  /** insert data into the table: "v1.BonusSource" */
  insert_v1_BonusSource?: Maybe<v1_BonusSource_mutation_response>;
  /** insert a single row into the table: "v1.BonusSource" */
  insert_v1_BonusSource_one?: Maybe<v1_BonusSource>;
  /** insert data into the table: "v1.BonusStatus" */
  insert_v1_BonusStatus?: Maybe<v1_BonusStatus_mutation_response>;
  /** insert a single row into the table: "v1.BonusStatus" */
  insert_v1_BonusStatus_one?: Maybe<v1_BonusStatus>;
  /** insert data into the table: "v1.BonusSubmitter" */
  insert_v1_BonusSubmitter?: Maybe<v1_BonusSubmitter_mutation_response>;
  /** insert a single row into the table: "v1.BonusSubmitter" */
  insert_v1_BonusSubmitter_one?: Maybe<v1_BonusSubmitter>;
  /** insert data into the table: "v1.BonusTypes" */
  insert_v1_BonusTypes?: Maybe<v1_BonusTypes_mutation_response>;
  /** insert a single row into the table: "v1.BonusTypes" */
  insert_v1_BonusTypes_one?: Maybe<v1_BonusTypes>;
  /** insert data into the table: "v1.Bonuses" */
  insert_v1_Bonuses?: Maybe<v1_Bonuses_mutation_response>;
  /** insert data into the table: "v1.BonusesToCountries" */
  insert_v1_BonusesToCountries?: Maybe<v1_BonusesToCountries_mutation_response>;
  /** insert a single row into the table: "v1.BonusesToCountries" */
  insert_v1_BonusesToCountries_one?: Maybe<v1_BonusesToCountries>;
  /** insert data into the table: "v1.BonusesToCurrencies" */
  insert_v1_BonusesToCurrencies?: Maybe<v1_BonusesToCurrencies_mutation_response>;
  /** insert a single row into the table: "v1.BonusesToCurrencies" */
  insert_v1_BonusesToCurrencies_one?: Maybe<v1_BonusesToCurrencies>;
  /** insert data into the table: "v1.BonusesToTypes" */
  insert_v1_BonusesToTypes?: Maybe<v1_BonusesToTypes_mutation_response>;
  /** insert a single row into the table: "v1.BonusesToTypes" */
  insert_v1_BonusesToTypes_one?: Maybe<v1_BonusesToTypes>;
  /** insert a single row into the table: "v1.Bonuses" */
  insert_v1_Bonuses_one?: Maybe<v1_Bonuses>;
  /** insert data into the table: "v1.BonusestoGames" */
  insert_v1_BonusestoGames?: Maybe<v1_BonusestoGames_mutation_response>;
  /** insert a single row into the table: "v1.BonusestoGames" */
  insert_v1_BonusestoGames_one?: Maybe<v1_BonusestoGames>;
  /** insert data into the table: "v1.BrandAlias" */
  insert_v1_BrandAlias?: Maybe<v1_BrandAlias_mutation_response>;
  /** insert a single row into the table: "v1.BrandAlias" */
  insert_v1_BrandAlias_one?: Maybe<v1_BrandAlias>;
  /** insert data into the table: "v1.BrandPriority" */
  insert_v1_BrandPriority?: Maybe<v1_BrandPriority_mutation_response>;
  /** insert a single row into the table: "v1.BrandPriority" */
  insert_v1_BrandPriority_one?: Maybe<v1_BrandPriority>;
  /** insert data into the table: "v1.Campaign" */
  insert_v1_Campaign?: Maybe<v1_Campaign_mutation_response>;
  /** insert data into the table: "v1.CampaignPriority" */
  insert_v1_CampaignPriority?: Maybe<v1_CampaignPriority_mutation_response>;
  /** insert a single row into the table: "v1.CampaignPriority" */
  insert_v1_CampaignPriority_one?: Maybe<v1_CampaignPriority>;
  /** insert data into the table: "v1.CampaignStatus" */
  insert_v1_CampaignStatus?: Maybe<v1_CampaignStatus_mutation_response>;
  /** insert a single row into the table: "v1.CampaignStatus" */
  insert_v1_CampaignStatus_one?: Maybe<v1_CampaignStatus>;
  /** insert data into the table: "v1.CampaignStrategy" */
  insert_v1_CampaignStrategy?: Maybe<v1_CampaignStrategy_mutation_response>;
  /** insert a single row into the table: "v1.CampaignStrategy" */
  insert_v1_CampaignStrategy_one?: Maybe<v1_CampaignStrategy>;
  /** insert data into the table: "v1.CampaignType" */
  insert_v1_CampaignType?: Maybe<v1_CampaignType_mutation_response>;
  /** insert a single row into the table: "v1.CampaignType" */
  insert_v1_CampaignType_one?: Maybe<v1_CampaignType>;
  /** insert a single row into the table: "v1.Campaign" */
  insert_v1_Campaign_one?: Maybe<v1_Campaign>;
  /** insert data into the table: "v1.Categories" */
  insert_v1_Categories?: Maybe<v1_Categories_mutation_response>;
  /** insert a single row into the table: "v1.Categories" */
  insert_v1_Categories_one?: Maybe<v1_Categories>;
  /** insert data into the table: "v1.Checkout" */
  insert_v1_Checkout?: Maybe<v1_Checkout_mutation_response>;
  /** insert data into the table: "v1.CheckoutDetail" */
  insert_v1_CheckoutDetail?: Maybe<v1_CheckoutDetail_mutation_response>;
  /** insert a single row into the table: "v1.CheckoutDetail" */
  insert_v1_CheckoutDetail_one?: Maybe<v1_CheckoutDetail>;
  /** insert data into the table: "v1.CheckoutStatus" */
  insert_v1_CheckoutStatus?: Maybe<v1_CheckoutStatus_mutation_response>;
  /** insert a single row into the table: "v1.CheckoutStatus" */
  insert_v1_CheckoutStatus_one?: Maybe<v1_CheckoutStatus>;
  /** insert a single row into the table: "v1.Checkout" */
  insert_v1_Checkout_one?: Maybe<v1_Checkout>;
  /** insert data into the table: "v1.Cities" */
  insert_v1_Cities?: Maybe<v1_Cities_mutation_response>;
  /** insert a single row into the table: "v1.Cities" */
  insert_v1_Cities_one?: Maybe<v1_Cities>;
  /** insert data into the table: "v1.ClicksByTrackers" */
  insert_v1_ClicksByTrackers?: Maybe<v1_ClicksByTrackers_mutation_response>;
  /** insert data into the table: "v1.ClicksByTrackersNew" */
  insert_v1_ClicksByTrackersNew?: Maybe<v1_ClicksByTrackersNew_mutation_response>;
  /** insert a single row into the table: "v1.ClicksByTrackersNew" */
  insert_v1_ClicksByTrackersNew_one?: Maybe<v1_ClicksByTrackersNew>;
  /** insert a single row into the table: "v1.ClicksByTrackers" */
  insert_v1_ClicksByTrackers_one?: Maybe<v1_ClicksByTrackers>;
  /** insert data into the table: "v1.CompanyType" */
  insert_v1_CompanyType?: Maybe<v1_CompanyType_mutation_response>;
  /** insert a single row into the table: "v1.CompanyType" */
  insert_v1_CompanyType_one?: Maybe<v1_CompanyType>;
  /** insert data into the table: "v1.Configurations" */
  insert_v1_Configurations?: Maybe<v1_Configurations_mutation_response>;
  /** insert a single row into the table: "v1.Configurations" */
  insert_v1_Configurations_one?: Maybe<v1_Configurations>;
  /** insert data into the table: "v1.ContactBase" */
  insert_v1_ContactBase?: Maybe<v1_ContactBase_mutation_response>;
  /** insert data into the table: "v1.ContactBasePaymentOption" */
  insert_v1_ContactBasePaymentOption?: Maybe<v1_ContactBasePaymentOption_mutation_response>;
  /** insert a single row into the table: "v1.ContactBasePaymentOption" */
  insert_v1_ContactBasePaymentOption_one?: Maybe<v1_ContactBasePaymentOption>;
  /** insert a single row into the table: "v1.ContactBase" */
  insert_v1_ContactBase_one?: Maybe<v1_ContactBase>;
  /** insert data into the table: "v1.ContactType" */
  insert_v1_ContactType?: Maybe<v1_ContactType_mutation_response>;
  /** insert a single row into the table: "v1.ContactType" */
  insert_v1_ContactType_one?: Maybe<v1_ContactType>;
  /** insert data into the table: "v1.Contacts_Roles" */
  insert_v1_Contacts_Roles?: Maybe<v1_Contacts_Roles_mutation_response>;
  /** insert a single row into the table: "v1.Contacts_Roles" */
  insert_v1_Contacts_Roles_one?: Maybe<v1_Contacts_Roles>;
  /** insert data into the table: "v1.ContentLink" */
  insert_v1_ContentLink?: Maybe<v1_ContentLink_mutation_response>;
  /** insert a single row into the table: "v1.ContentLink" */
  insert_v1_ContentLink_one?: Maybe<v1_ContentLink>;
  /** insert data into the table: "v1.ContentTypes" */
  insert_v1_ContentTypes?: Maybe<v1_ContentTypes_mutation_response>;
  /** insert a single row into the table: "v1.ContentTypes" */
  insert_v1_ContentTypes_one?: Maybe<v1_ContentTypes>;
  /** insert data into the table: "v1.Credential_Roles" */
  insert_v1_Credential_Roles?: Maybe<v1_Credential_Roles_mutation_response>;
  /** insert a single row into the table: "v1.Credential_Roles" */
  insert_v1_Credential_Roles_one?: Maybe<v1_Credential_Roles>;
  /** insert data into the table: "v1.Credentials" */
  insert_v1_Credentials?: Maybe<v1_Credentials_mutation_response>;
  /** insert a single row into the table: "v1.Credentials" */
  insert_v1_Credentials_one?: Maybe<v1_Credentials>;
  /** insert data into the table: "v1.DomainExtensions" */
  insert_v1_DomainExtensions?: Maybe<v1_DomainExtensions_mutation_response>;
  /** insert a single row into the table: "v1.DomainExtensions" */
  insert_v1_DomainExtensions_one?: Maybe<v1_DomainExtensions>;
  /** insert data into the table: "v1.Events" */
  insert_v1_Events?: Maybe<v1_Events_mutation_response>;
  /** insert a single row into the table: "v1.Events" */
  insert_v1_Events_one?: Maybe<v1_Events>;
  /** insert data into the table: "v1.ExpiringRecPayments" */
  insert_v1_ExpiringRecPayments?: Maybe<v1_ExpiringRecPayments_mutation_response>;
  /** insert a single row into the table: "v1.ExpiringRecPayments" */
  insert_v1_ExpiringRecPayments_one?: Maybe<v1_ExpiringRecPayments>;
  /** insert data into the table: "v1.Files" */
  insert_v1_Files?: Maybe<v1_Files_mutation_response>;
  /** insert a single row into the table: "v1.Files" */
  insert_v1_Files_one?: Maybe<v1_Files>;
  /** insert data into the table: "v1.GameType" */
  insert_v1_GameType?: Maybe<v1_GameType_mutation_response>;
  /** insert a single row into the table: "v1.GameType" */
  insert_v1_GameType_one?: Maybe<v1_GameType>;
  /** insert data into the table: "v1.InvoiceBase" */
  insert_v1_InvoiceBase?: Maybe<v1_InvoiceBase_mutation_response>;
  /** insert a single row into the table: "v1.InvoiceBase" */
  insert_v1_InvoiceBase_one?: Maybe<v1_InvoiceBase>;
  /** insert data into the table: "v1.InvoiceDetailBase" */
  insert_v1_InvoiceDetailBase?: Maybe<v1_InvoiceDetailBase_mutation_response>;
  /** insert a single row into the table: "v1.InvoiceDetailBase" */
  insert_v1_InvoiceDetailBase_one?: Maybe<v1_InvoiceDetailBase>;
  /** insert data into the table: "v1.InvoiceStatus" */
  insert_v1_InvoiceStatus?: Maybe<v1_InvoiceStatus_mutation_response>;
  /** insert a single row into the table: "v1.InvoiceStatus" */
  insert_v1_InvoiceStatus_one?: Maybe<v1_InvoiceStatus>;
  /** insert data into the table: "v1.LandingPagePriority" */
  insert_v1_LandingPagePriority?: Maybe<v1_LandingPagePriority_mutation_response>;
  /** insert a single row into the table: "v1.LandingPagePriority" */
  insert_v1_LandingPagePriority_one?: Maybe<v1_LandingPagePriority>;
  /** insert data into the table: "v1.LandingPages" */
  insert_v1_LandingPages?: Maybe<v1_LandingPages_mutation_response>;
  /** insert a single row into the table: "v1.LandingPages" */
  insert_v1_LandingPages_one?: Maybe<v1_LandingPages>;
  /** insert data into the table: "v1.LanguagePriceStep" */
  insert_v1_LanguagePriceStep?: Maybe<v1_LanguagePriceStep_mutation_response>;
  /** insert a single row into the table: "v1.LanguagePriceStep" */
  insert_v1_LanguagePriceStep_one?: Maybe<v1_LanguagePriceStep>;
  /** insert data into the table: "v1.MarketPlaceCategories" */
  insert_v1_MarketPlaceCategories?: Maybe<v1_MarketPlaceCategories_mutation_response>;
  /** insert data into the table: "v1.MarketPlaceCategoriesTypes" */
  insert_v1_MarketPlaceCategoriesTypes?: Maybe<v1_MarketPlaceCategoriesTypes_mutation_response>;
  /** insert a single row into the table: "v1.MarketPlaceCategoriesTypes" */
  insert_v1_MarketPlaceCategoriesTypes_one?: Maybe<v1_MarketPlaceCategoriesTypes>;
  /** insert data into the table: "v1.MarketPlaceCategories_Offers" */
  insert_v1_MarketPlaceCategories_Offers?: Maybe<v1_MarketPlaceCategories_Offers_mutation_response>;
  /** insert a single row into the table: "v1.MarketPlaceCategories_Offers" */
  insert_v1_MarketPlaceCategories_Offers_one?: Maybe<v1_MarketPlaceCategories_Offers>;
  /** insert a single row into the table: "v1.MarketPlaceCategories" */
  insert_v1_MarketPlaceCategories_one?: Maybe<v1_MarketPlaceCategories>;
  /** insert data into the table: "v1.MarketPlaceOfferNiche" */
  insert_v1_MarketPlaceOfferNiche?: Maybe<v1_MarketPlaceOfferNiche_mutation_response>;
  /** insert a single row into the table: "v1.MarketPlaceOfferNiche" */
  insert_v1_MarketPlaceOfferNiche_one?: Maybe<v1_MarketPlaceOfferNiche>;
  /** insert data into the table: "v1.MarketPlaceOrder" */
  insert_v1_MarketPlaceOrder?: Maybe<v1_MarketPlaceOrder_mutation_response>;
  /** insert data into the table: "v1.MarketPlaceOrderRequest" */
  insert_v1_MarketPlaceOrderRequest?: Maybe<v1_MarketPlaceOrderRequest_mutation_response>;
  /** insert a single row into the table: "v1.MarketPlaceOrderRequest" */
  insert_v1_MarketPlaceOrderRequest_one?: Maybe<v1_MarketPlaceOrderRequest>;
  /** insert data into the table: "v1.MarketPlaceOrderStatus" */
  insert_v1_MarketPlaceOrderStatus?: Maybe<v1_MarketPlaceOrderStatus_mutation_response>;
  /** insert a single row into the table: "v1.MarketPlaceOrderStatus" */
  insert_v1_MarketPlaceOrderStatus_one?: Maybe<v1_MarketPlaceOrderStatus>;
  /** insert a single row into the table: "v1.MarketPlaceOrder" */
  insert_v1_MarketPlaceOrder_one?: Maybe<v1_MarketPlaceOrder>;
  /** insert data into the table: "v1.MarketPlaceRegisterStatus" */
  insert_v1_MarketPlaceRegisterStatus?: Maybe<v1_MarketPlaceRegisterStatus_mutation_response>;
  /** insert a single row into the table: "v1.MarketPlaceRegisterStatus" */
  insert_v1_MarketPlaceRegisterStatus_one?: Maybe<v1_MarketPlaceRegisterStatus>;
  /** insert data into the table: "v1.MarketType" */
  insert_v1_MarketType?: Maybe<v1_MarketType_mutation_response>;
  /** insert a single row into the table: "v1.MarketType" */
  insert_v1_MarketType_one?: Maybe<v1_MarketType>;
  /** insert data into the table: "v1.MarketplaceProjects" */
  insert_v1_MarketplaceProjects?: Maybe<v1_MarketplaceProjects_mutation_response>;
  /** insert a single row into the table: "v1.MarketplaceProjects" */
  insert_v1_MarketplaceProjects_one?: Maybe<v1_MarketplaceProjects>;
  /** insert data into the table: "v1.MarketplaceStatus" */
  insert_v1_MarketplaceStatus?: Maybe<v1_MarketplaceStatus_mutation_response>;
  /** insert a single row into the table: "v1.MarketplaceStatus" */
  insert_v1_MarketplaceStatus_one?: Maybe<v1_MarketplaceStatus>;
  /** insert data into the table: "v1.Months" */
  insert_v1_Months?: Maybe<v1_Months_mutation_response>;
  /** insert a single row into the table: "v1.Months" */
  insert_v1_Months_one?: Maybe<v1_Months>;
  /** insert data into the table: "v1.Offer" */
  insert_v1_Offer?: Maybe<v1_Offer_mutation_response>;
  /** insert data into the table: "v1.OfferStatus" */
  insert_v1_OfferStatus?: Maybe<v1_OfferStatus_mutation_response>;
  /** insert a single row into the table: "v1.OfferStatus" */
  insert_v1_OfferStatus_one?: Maybe<v1_OfferStatus>;
  /** insert data into the table: "v1.OfferTags" */
  insert_v1_OfferTags?: Maybe<v1_OfferTags_mutation_response>;
  /** insert a single row into the table: "v1.OfferTags" */
  insert_v1_OfferTags_one?: Maybe<v1_OfferTags>;
  /** insert data into the table: "v1.OfferToTags" */
  insert_v1_OfferToTags?: Maybe<v1_OfferToTags_mutation_response>;
  /** insert a single row into the table: "v1.OfferToTags" */
  insert_v1_OfferToTags_one?: Maybe<v1_OfferToTags>;
  /** insert a single row into the table: "v1.Offer" */
  insert_v1_Offer_one?: Maybe<v1_Offer>;
  /** insert data into the table: "v1.OrderFor" */
  insert_v1_OrderFor?: Maybe<v1_OrderFor_mutation_response>;
  /** insert a single row into the table: "v1.OrderFor" */
  insert_v1_OrderFor_one?: Maybe<v1_OrderFor>;
  /** insert data into the table: "v1.OrderPayTo" */
  insert_v1_OrderPayTo?: Maybe<v1_OrderPayTo_mutation_response>;
  /** insert a single row into the table: "v1.OrderPayTo" */
  insert_v1_OrderPayTo_one?: Maybe<v1_OrderPayTo>;
  /** insert data into the table: "v1.OrderPriority" */
  insert_v1_OrderPriority?: Maybe<v1_OrderPriority_mutation_response>;
  /** insert a single row into the table: "v1.OrderPriority" */
  insert_v1_OrderPriority_one?: Maybe<v1_OrderPriority>;
  /** insert data into the table: "v1.OrderType" */
  insert_v1_OrderType?: Maybe<v1_OrderType_mutation_response>;
  /** insert a single row into the table: "v1.OrderType" */
  insert_v1_OrderType_one?: Maybe<v1_OrderType>;
  /** insert data into the table: "v1.Page" */
  insert_v1_Page?: Maybe<v1_Page_mutation_response>;
  /** insert a single row into the table: "v1.Page" */
  insert_v1_Page_one?: Maybe<v1_Page>;
  /** insert data into the table: "v1.PaymentCompanies" */
  insert_v1_PaymentCompanies?: Maybe<v1_PaymentCompanies_mutation_response>;
  /** insert a single row into the table: "v1.PaymentCompanies" */
  insert_v1_PaymentCompanies_one?: Maybe<v1_PaymentCompanies>;
  /** insert data into the table: "v1.PaymentFrequency" */
  insert_v1_PaymentFrequency?: Maybe<v1_PaymentFrequency_mutation_response>;
  /** insert a single row into the table: "v1.PaymentFrequency" */
  insert_v1_PaymentFrequency_one?: Maybe<v1_PaymentFrequency>;
  /** insert data into the table: "v1.PoToPosts" */
  insert_v1_PoToPosts?: Maybe<v1_PoToPosts_mutation_response>;
  /** insert a single row into the table: "v1.PoToPosts" */
  insert_v1_PoToPosts_one?: Maybe<v1_PoToPosts>;
  /** insert data into the table: "v1.PostDuration" */
  insert_v1_PostDuration?: Maybe<v1_PostDuration_mutation_response>;
  /** insert a single row into the table: "v1.PostDuration" */
  insert_v1_PostDuration_one?: Maybe<v1_PostDuration>;
  /** insert data into the table: "v1.PostNiche" */
  insert_v1_PostNiche?: Maybe<v1_PostNiche_mutation_response>;
  /** insert a single row into the table: "v1.PostNiche" */
  insert_v1_PostNiche_one?: Maybe<v1_PostNiche>;
  /** insert data into the table: "v1.Posts" */
  insert_v1_Posts?: Maybe<v1_Posts_mutation_response>;
  /** insert data into the table: "v1.PostsAnchorTextType" */
  insert_v1_PostsAnchorTextType?: Maybe<v1_PostsAnchorTextType_mutation_response>;
  /** insert a single row into the table: "v1.PostsAnchorTextType" */
  insert_v1_PostsAnchorTextType_one?: Maybe<v1_PostsAnchorTextType>;
  /** insert data into the table: "v1.PostsContentStatus" */
  insert_v1_PostsContentStatus?: Maybe<v1_PostsContentStatus_mutation_response>;
  /** insert a single row into the table: "v1.PostsContentStatus" */
  insert_v1_PostsContentStatus_one?: Maybe<v1_PostsContentStatus>;
  /** insert data into the table: "v1.PostsCopyScape" */
  insert_v1_PostsCopyScape?: Maybe<v1_PostsCopyScape_mutation_response>;
  /** insert a single row into the table: "v1.PostsCopyScape" */
  insert_v1_PostsCopyScape_one?: Maybe<v1_PostsCopyScape>;
  /** insert data into the table: "v1.PostsLinkStatus" */
  insert_v1_PostsLinkStatus?: Maybe<v1_PostsLinkStatus_mutation_response>;
  /** insert a single row into the table: "v1.PostsLinkStatus" */
  insert_v1_PostsLinkStatus_one?: Maybe<v1_PostsLinkStatus>;
  /** insert data into the table: "v1.PostsOnlineStatus" */
  insert_v1_PostsOnlineStatus?: Maybe<v1_PostsOnlineStatus_mutation_response>;
  /** insert a single row into the table: "v1.PostsOnlineStatus" */
  insert_v1_PostsOnlineStatus_one?: Maybe<v1_PostsOnlineStatus>;
  /** insert data into the table: "v1.PostsOutreachStatus" */
  insert_v1_PostsOutreachStatus?: Maybe<v1_PostsOutreachStatus_mutation_response>;
  /** insert a single row into the table: "v1.PostsOutreachStatus" */
  insert_v1_PostsOutreachStatus_one?: Maybe<v1_PostsOutreachStatus>;
  /** insert data into the table: "v1.PostsPageTypeDeprecated" */
  insert_v1_PostsPageTypeDeprecated?: Maybe<v1_PostsPageTypeDeprecated_mutation_response>;
  /** insert a single row into the table: "v1.PostsPageTypeDeprecated" */
  insert_v1_PostsPageTypeDeprecated_one?: Maybe<v1_PostsPageTypeDeprecated>;
  /** insert data into the table: "v1.PostsRemovalStatus" */
  insert_v1_PostsRemovalStatus?: Maybe<v1_PostsRemovalStatus_mutation_response>;
  /** insert a single row into the table: "v1.PostsRemovalStatus" */
  insert_v1_PostsRemovalStatus_one?: Maybe<v1_PostsRemovalStatus>;
  /** insert data into the table: "v1.PostsSEOApproval" */
  insert_v1_PostsSEOApproval?: Maybe<v1_PostsSEOApproval_mutation_response>;
  /** insert a single row into the table: "v1.PostsSEOApproval" */
  insert_v1_PostsSEOApproval_one?: Maybe<v1_PostsSEOApproval>;
  /** insert data into the table: "v1.PostsSEODeclineReason" */
  insert_v1_PostsSEODeclineReason?: Maybe<v1_PostsSEODeclineReason_mutation_response>;
  /** insert a single row into the table: "v1.PostsSEODeclineReason" */
  insert_v1_PostsSEODeclineReason_one?: Maybe<v1_PostsSEODeclineReason>;
  /** insert data into the table: "v1.PostsStatus" */
  insert_v1_PostsStatus?: Maybe<v1_PostsStatus_mutation_response>;
  /** insert data into the table: "v1.PostsStatusCode" */
  insert_v1_PostsStatusCode?: Maybe<v1_PostsStatusCode_mutation_response>;
  /** insert a single row into the table: "v1.PostsStatusCode" */
  insert_v1_PostsStatusCode_one?: Maybe<v1_PostsStatusCode>;
  /** insert a single row into the table: "v1.PostsStatus" */
  insert_v1_PostsStatus_one?: Maybe<v1_PostsStatus>;
  /** insert a single row into the table: "v1.Posts" */
  insert_v1_Posts_one?: Maybe<v1_Posts>;
  /** insert data into the table: "v1.Preferences" */
  insert_v1_Preferences?: Maybe<v1_Preferences_mutation_response>;
  /** insert data into the table: "v1.PreferencesMarketplace" */
  insert_v1_PreferencesMarketplace?: Maybe<v1_PreferencesMarketplace_mutation_response>;
  /** insert a single row into the table: "v1.PreferencesMarketplace" */
  insert_v1_PreferencesMarketplace_one?: Maybe<v1_PreferencesMarketplace>;
  /** insert a single row into the table: "v1.Preferences" */
  insert_v1_Preferences_one?: Maybe<v1_Preferences>;
  /** insert data into the table: "v1.Project" */
  insert_v1_Project?: Maybe<v1_Project_mutation_response>;
  /** insert data into the table: "v1.ProjectImportance" */
  insert_v1_ProjectImportance?: Maybe<v1_ProjectImportance_mutation_response>;
  /** insert a single row into the table: "v1.ProjectImportance" */
  insert_v1_ProjectImportance_one?: Maybe<v1_ProjectImportance>;
  /** insert data into the table: "v1.ProjectStatus" */
  insert_v1_ProjectStatus?: Maybe<v1_ProjectStatus_mutation_response>;
  /** insert a single row into the table: "v1.ProjectStatus" */
  insert_v1_ProjectStatus_one?: Maybe<v1_ProjectStatus>;
  /** insert data into the table: "v1.ProjectTopic" */
  insert_v1_ProjectTopic?: Maybe<v1_ProjectTopic_mutation_response>;
  /** insert a single row into the table: "v1.ProjectTopic" */
  insert_v1_ProjectTopic_one?: Maybe<v1_ProjectTopic>;
  /** insert data into the table: "v1.Project_Countries" */
  insert_v1_Project_Countries?: Maybe<v1_Project_Countries_mutation_response>;
  /** insert a single row into the table: "v1.Project_Countries" */
  insert_v1_Project_Countries_one?: Maybe<v1_Project_Countries>;
  /** insert a single row into the table: "v1.Project" */
  insert_v1_Project_one?: Maybe<v1_Project>;
  /** insert data into the table: "v1.PublisherPostStatus" */
  insert_v1_PublisherPostStatus?: Maybe<v1_PublisherPostStatus_mutation_response>;
  /** insert a single row into the table: "v1.PublisherPostStatus" */
  insert_v1_PublisherPostStatus_one?: Maybe<v1_PublisherPostStatus>;
  /** insert data into the table: "v1.PurchaseOrderStatus" */
  insert_v1_PurchaseOrderStatus?: Maybe<v1_PurchaseOrderStatus_mutation_response>;
  /** insert a single row into the table: "v1.PurchaseOrderStatus" */
  insert_v1_PurchaseOrderStatus_one?: Maybe<v1_PurchaseOrderStatus>;
  /** insert data into the table: "v1.ReceiptStatus" */
  insert_v1_ReceiptStatus?: Maybe<v1_ReceiptStatus_mutation_response>;
  /** insert a single row into the table: "v1.ReceiptStatus" */
  insert_v1_ReceiptStatus_one?: Maybe<v1_ReceiptStatus>;
  /** insert data into the table: "v1.RecurringPaymentStatus" */
  insert_v1_RecurringPaymentStatus?: Maybe<v1_RecurringPaymentStatus_mutation_response>;
  /** insert a single row into the table: "v1.RecurringPaymentStatus" */
  insert_v1_RecurringPaymentStatus_one?: Maybe<v1_RecurringPaymentStatus>;
  /** insert data into the table: "v1.RecurringPayments" */
  insert_v1_RecurringPayments?: Maybe<v1_RecurringPayments_mutation_response>;
  /** insert data into the table: "v1.RecurringPayments_Roles" */
  insert_v1_RecurringPayments_Roles?: Maybe<v1_RecurringPayments_Roles_mutation_response>;
  /** insert a single row into the table: "v1.RecurringPayments_Roles" */
  insert_v1_RecurringPayments_Roles_one?: Maybe<v1_RecurringPayments_Roles>;
  /** insert a single row into the table: "v1.RecurringPayments" */
  insert_v1_RecurringPayments_one?: Maybe<v1_RecurringPayments>;
  /** insert data into the table: "v1.Report" */
  insert_v1_Report?: Maybe<v1_Report_mutation_response>;
  /** insert data into the table: "v1.Report_Roles" */
  insert_v1_Report_Roles?: Maybe<v1_Report_Roles_mutation_response>;
  /** insert a single row into the table: "v1.Report_Roles" */
  insert_v1_Report_Roles_one?: Maybe<v1_Report_Roles>;
  /** insert a single row into the table: "v1.Report" */
  insert_v1_Report_one?: Maybe<v1_Report>;
  /** insert data into the table: "v1.RestrictedNichesToOffer" */
  insert_v1_RestrictedNichesToOffer?: Maybe<v1_RestrictedNichesToOffer_mutation_response>;
  /** insert a single row into the table: "v1.RestrictedNichesToOffer" */
  insert_v1_RestrictedNichesToOffer_one?: Maybe<v1_RestrictedNichesToOffer>;
  /** insert data into the table: "v1.Roles" */
  insert_v1_Roles?: Maybe<v1_Roles_mutation_response>;
  /** insert a single row into the table: "v1.Roles" */
  insert_v1_Roles_one?: Maybe<v1_Roles>;
  /** insert data into the table: "v1.SalesOrderBase" */
  insert_v1_SalesOrderBase?: Maybe<v1_SalesOrderBase_mutation_response>;
  /** insert a single row into the table: "v1.SalesOrderBase" */
  insert_v1_SalesOrderBase_one?: Maybe<v1_SalesOrderBase>;
  /** insert data into the table: "v1.ServiceTypes" */
  insert_v1_ServiceTypes?: Maybe<v1_ServiceTypes_mutation_response>;
  /** insert a single row into the table: "v1.ServiceTypes" */
  insert_v1_ServiceTypes_one?: Maybe<v1_ServiceTypes>;
  /** insert data into the table: "v1.SoftwareProviders" */
  insert_v1_SoftwareProviders?: Maybe<v1_SoftwareProviders_mutation_response>;
  /** insert a single row into the table: "v1.SoftwareProviders" */
  insert_v1_SoftwareProviders_one?: Maybe<v1_SoftwareProviders>;
  /** insert data into the table: "v1.StrategyFieldsSet" */
  insert_v1_StrategyFieldsSet?: Maybe<v1_StrategyFieldsSet_mutation_response>;
  /** insert a single row into the table: "v1.StrategyFieldsSet" */
  insert_v1_StrategyFieldsSet_one?: Maybe<v1_StrategyFieldsSet>;
  /** insert data into the table: "v1.Supplier_Tag_rel" */
  insert_v1_Supplier_Tag_rel?: Maybe<v1_Supplier_Tag_rel_mutation_response>;
  /** insert a single row into the table: "v1.Supplier_Tag_rel" */
  insert_v1_Supplier_Tag_rel_one?: Maybe<v1_Supplier_Tag_rel>;
  /** insert data into the table: "v1.SystemUserBase" */
  insert_v1_SystemUserBase?: Maybe<v1_SystemUserBase_mutation_response>;
  /** insert a single row into the table: "v1.SystemUserBase" */
  insert_v1_SystemUserBase_one?: Maybe<v1_SystemUserBase>;
  /** insert data into the table: "v1.Tags" */
  insert_v1_Tags?: Maybe<v1_Tags_mutation_response>;
  /** insert a single row into the table: "v1.Tags" */
  insert_v1_Tags_one?: Maybe<v1_Tags>;
  /** insert data into the table: "v1.Teams" */
  insert_v1_Teams?: Maybe<v1_Teams_mutation_response>;
  /** insert a single row into the table: "v1.Teams" */
  insert_v1_Teams_one?: Maybe<v1_Teams>;
  /** insert data into the table: "v1.TrackerDeal" */
  insert_v1_TrackerDeal?: Maybe<v1_TrackerDeal_mutation_response>;
  /** insert a single row into the table: "v1.TrackerDeal" */
  insert_v1_TrackerDeal_one?: Maybe<v1_TrackerDeal>;
  /** insert data into the table: "v1.TrackerTags" */
  insert_v1_TrackerTags?: Maybe<v1_TrackerTags_mutation_response>;
  /** insert a single row into the table: "v1.TrackerTags" */
  insert_v1_TrackerTags_one?: Maybe<v1_TrackerTags>;
  /** insert data into the table: "v1.TrackerToTags" */
  insert_v1_TrackerToTags?: Maybe<v1_TrackerToTags_mutation_response>;
  /** insert a single row into the table: "v1.TrackerToTags" */
  insert_v1_TrackerToTags_one?: Maybe<v1_TrackerToTags>;
  /** insert data into the table: "v1.TransactionCurrencyBase" */
  insert_v1_TransactionCurrencyBase?: Maybe<v1_TransactionCurrencyBase_mutation_response>;
  /** insert a single row into the table: "v1.TransactionCurrencyBase" */
  insert_v1_TransactionCurrencyBase_one?: Maybe<v1_TransactionCurrencyBase>;
  /** insert data into the table: "v1.UsersStatus" */
  insert_v1_UsersStatus?: Maybe<v1_UsersStatus_mutation_response>;
  /** insert a single row into the table: "v1.UsersStatus" */
  insert_v1_UsersStatus_one?: Maybe<v1_UsersStatus>;
  /** insert data into the table: "v1.UsersToOffers" */
  insert_v1_UsersToOffers?: Maybe<v1_UsersToOffers_mutation_response>;
  /** insert a single row into the table: "v1.UsersToOffers" */
  insert_v1_UsersToOffers_one?: Maybe<v1_UsersToOffers>;
  /** insert data into the table: "v1.Users_Roles" */
  insert_v1_Users_Roles?: Maybe<v1_Users_Roles_mutation_response>;
  /** insert a single row into the table: "v1.Users_Roles" */
  insert_v1_Users_Roles_one?: Maybe<v1_Users_Roles>;
  /** insert data into the table: "v1.Users_Teams" */
  insert_v1_Users_Teams?: Maybe<v1_Users_Teams_mutation_response>;
  /** insert a single row into the table: "v1.Users_Teams" */
  insert_v1_Users_Teams_one?: Maybe<v1_Users_Teams>;
  /** insert data into the table: "v1.WageringRequirementsLocation" */
  insert_v1_WageringRequirementsLocation?: Maybe<v1_WageringRequirementsLocation_mutation_response>;
  /** insert a single row into the table: "v1.WageringRequirementsLocation" */
  insert_v1_WageringRequirementsLocation_one?: Maybe<v1_WageringRequirementsLocation>;
  /** insert data into the table: "v1.Website" */
  insert_v1_Website?: Maybe<v1_Website_mutation_response>;
  /** insert data into the table: "v1.WebsiteBlogProbability" */
  insert_v1_WebsiteBlogProbability?: Maybe<v1_WebsiteBlogProbability_mutation_response>;
  /** insert a single row into the table: "v1.WebsiteBlogProbability" */
  insert_v1_WebsiteBlogProbability_one?: Maybe<v1_WebsiteBlogProbability>;
  /** insert data into the table: "v1.WebsiteContentIndicator" */
  insert_v1_WebsiteContentIndicator?: Maybe<v1_WebsiteContentIndicator_mutation_response>;
  /** insert a single row into the table: "v1.WebsiteContentIndicator" */
  insert_v1_WebsiteContentIndicator_one?: Maybe<v1_WebsiteContentIndicator>;
  /** insert data into the table: "v1.WebsiteDToxRisk" */
  insert_v1_WebsiteDToxRisk?: Maybe<v1_WebsiteDToxRisk_mutation_response>;
  /** insert a single row into the table: "v1.WebsiteDToxRisk" */
  insert_v1_WebsiteDToxRisk_one?: Maybe<v1_WebsiteDToxRisk>;
  /** insert data into the table: "v1.WebsiteQualityScore" */
  insert_v1_WebsiteQualityScore?: Maybe<v1_WebsiteQualityScore_mutation_response>;
  /** insert a single row into the table: "v1.WebsiteQualityScore" */
  insert_v1_WebsiteQualityScore_one?: Maybe<v1_WebsiteQualityScore>;
  /** insert data into the table: "v1.WebsiteStatus" */
  insert_v1_WebsiteStatus?: Maybe<v1_WebsiteStatus_mutation_response>;
  /** insert a single row into the table: "v1.WebsiteStatus" */
  insert_v1_WebsiteStatus_one?: Maybe<v1_WebsiteStatus>;
  /** insert data into the table: "v1.WebsiteTopics" */
  insert_v1_WebsiteTopics?: Maybe<v1_WebsiteTopics_mutation_response>;
  /** insert a single row into the table: "v1.WebsiteTopics" */
  insert_v1_WebsiteTopics_one?: Maybe<v1_WebsiteTopics>;
  /** insert data into the table: "v1.WebsiteUptimeStatus" */
  insert_v1_WebsiteUptimeStatus?: Maybe<v1_WebsiteUptimeStatus_mutation_response>;
  /** insert a single row into the table: "v1.WebsiteUptimeStatus" */
  insert_v1_WebsiteUptimeStatus_one?: Maybe<v1_WebsiteUptimeStatus>;
  /** insert data into the table: "v1.Website_Platform" */
  insert_v1_Website_Platform?: Maybe<v1_Website_Platform_mutation_response>;
  /** insert a single row into the table: "v1.Website_Platform" */
  insert_v1_Website_Platform_one?: Maybe<v1_Website_Platform>;
  /** insert a single row into the table: "v1.Website" */
  insert_v1_Website_one?: Maybe<v1_Website>;
  /** insert data into the table: "v1.WebsitesToTopics" */
  insert_v1_WebsitesToTopics?: Maybe<v1_WebsitesToTopics_mutation_response>;
  /** insert a single row into the table: "v1.WebsitesToTopics" */
  insert_v1_WebsitesToTopics_one?: Maybe<v1_WebsitesToTopics>;
  /** insert data into the table: "v1.new_POBase" */
  insert_v1_new_POBase?: Maybe<v1_new_POBase_mutation_response>;
  /** insert a single row into the table: "v1.new_POBase" */
  insert_v1_new_POBase_one?: Maybe<v1_new_POBase>;
  /** insert data into the table: "v1.new_affiliateincomereportBase" */
  insert_v1_new_affiliateincomereportBase?: Maybe<v1_new_affiliateincomereportBase_mutation_response>;
  /** insert a single row into the table: "v1.new_affiliateincomereportBase" */
  insert_v1_new_affiliateincomereportBase_one?: Maybe<v1_new_affiliateincomereportBase>;
  /** insert data into the table: "v1.new_affordertypeBase" */
  insert_v1_new_affordertypeBase?: Maybe<v1_new_affordertypeBase_mutation_response>;
  /** insert a single row into the table: "v1.new_affordertypeBase" */
  insert_v1_new_affordertypeBase_one?: Maybe<v1_new_affordertypeBase>;
  /** insert data into the table: "v1.new_brandBase" */
  insert_v1_new_brandBase?: Maybe<v1_new_brandBase_mutation_response>;
  /** insert a single row into the table: "v1.new_brandBase" */
  insert_v1_new_brandBase_one?: Maybe<v1_new_brandBase>;
  /** insert data into the table: "v1.new_countryBase" */
  insert_v1_new_countryBase?: Maybe<v1_new_countryBase_mutation_response>;
  /** insert a single row into the table: "v1.new_countryBase" */
  insert_v1_new_countryBase_one?: Maybe<v1_new_countryBase>;
  /** insert data into the table: "v1.new_languagesBase" */
  insert_v1_new_languagesBase?: Maybe<v1_new_languagesBase_mutation_response>;
  /** insert a single row into the table: "v1.new_languagesBase" */
  insert_v1_new_languagesBase_one?: Maybe<v1_new_languagesBase>;
  /** insert data into the table: "v1.new_new_brand_new_countryBase" */
  insert_v1_new_new_brand_new_countryBase?: Maybe<v1_new_new_brand_new_countryBase_mutation_response>;
  /** insert a single row into the table: "v1.new_new_brand_new_countryBase" */
  insert_v1_new_new_brand_new_countryBase_one?: Maybe<v1_new_new_brand_new_countryBase>;
  /** insert data into the table: "v1.new_paymentmethodBase" */
  insert_v1_new_paymentmethodBase?: Maybe<v1_new_paymentmethodBase_mutation_response>;
  /** insert a single row into the table: "v1.new_paymentmethodBase" */
  insert_v1_new_paymentmethodBase_one?: Maybe<v1_new_paymentmethodBase>;
  /** insert data into the table: "v1.new_receiptBase" */
  insert_v1_new_receiptBase?: Maybe<v1_new_receiptBase_mutation_response>;
  /** insert a single row into the table: "v1.new_receiptBase" */
  insert_v1_new_receiptBase_one?: Maybe<v1_new_receiptBase>;
  /** insert data into the table: "v1.new_regemailBase" */
  insert_v1_new_regemailBase?: Maybe<v1_new_regemailBase_mutation_response>;
  /** insert a single row into the table: "v1.new_regemailBase" */
  insert_v1_new_regemailBase_one?: Maybe<v1_new_regemailBase>;
  /** insert data into the table: "v1.new_trackerBase" */
  insert_v1_new_trackerBase?: Maybe<v1_new_trackerBase_mutation_response>;
  /** insert a single row into the table: "v1.new_trackerBase" */
  insert_v1_new_trackerBase_one?: Maybe<v1_new_trackerBase>;
  /** insert data into the table: "v1.process" */
  insert_v1_process?: Maybe<v1_process_mutation_response>;
  /** insert a single row into the table: "v1.process" */
  insert_v1_process_one?: Maybe<v1_process>;
  /** insert data into the table: "v1.process_rate" */
  insert_v1_process_rate?: Maybe<v1_process_rate_mutation_response>;
  /** insert a single row into the table: "v1.process_rate" */
  insert_v1_process_rate_one?: Maybe<v1_process_rate>;
  /** insert data into the table: "v1.project_topic" */
  insert_v1_project_topic?: Maybe<v1_project_topic_mutation_response>;
  /** insert a single row into the table: "v1.project_topic" */
  insert_v1_project_topic_one?: Maybe<v1_project_topic>;
  /** update data of the table: "v1.AccountBase" */
  update_v1_AccountBase?: Maybe<v1_AccountBase_mutation_response>;
  /** update single row of the table: "v1.AccountBase" */
  update_v1_AccountBase_by_pk?: Maybe<v1_AccountBase>;
  /** update multiples rows of table: "v1.AccountBase" */
  update_v1_AccountBase_many?: Maybe<Array<Maybe<v1_AccountBase_mutation_response>>>;
  /** update data of the table: "v1.AffDealType" */
  update_v1_AffDealType?: Maybe<v1_AffDealType_mutation_response>;
  /** update single row of the table: "v1.AffDealType" */
  update_v1_AffDealType_by_pk?: Maybe<v1_AffDealType>;
  /** update multiples rows of table: "v1.AffDealType" */
  update_v1_AffDealType_many?: Maybe<Array<Maybe<v1_AffDealType_mutation_response>>>;
  /** update data of the table: "v1.AffiliateOrderCategoriesPriority" */
  update_v1_AffiliateOrderCategoriesPriority?: Maybe<v1_AffiliateOrderCategoriesPriority_mutation_response>;
  /** update single row of the table: "v1.AffiliateOrderCategoriesPriority" */
  update_v1_AffiliateOrderCategoriesPriority_by_pk?: Maybe<v1_AffiliateOrderCategoriesPriority>;
  /** update multiples rows of table: "v1.AffiliateOrderCategoriesPriority" */
  update_v1_AffiliateOrderCategoriesPriority_many?: Maybe<Array<Maybe<v1_AffiliateOrderCategoriesPriority_mutation_response>>>;
  /** update data of the table: "v1.AffiliateProgramType" */
  update_v1_AffiliateProgramType?: Maybe<v1_AffiliateProgramType_mutation_response>;
  /** update single row of the table: "v1.AffiliateProgramType" */
  update_v1_AffiliateProgramType_by_pk?: Maybe<v1_AffiliateProgramType>;
  /** update multiples rows of table: "v1.AffiliateProgramType" */
  update_v1_AffiliateProgramType_many?: Maybe<Array<Maybe<v1_AffiliateProgramType_mutation_response>>>;
  /** update data of the table: "v1.AllowedPlayers" */
  update_v1_AllowedPlayers?: Maybe<v1_AllowedPlayers_mutation_response>;
  /** update single row of the table: "v1.AllowedPlayers" */
  update_v1_AllowedPlayers_by_pk?: Maybe<v1_AllowedPlayers>;
  /** update multiples rows of table: "v1.AllowedPlayers" */
  update_v1_AllowedPlayers_many?: Maybe<Array<Maybe<v1_AllowedPlayers_mutation_response>>>;
  /** update data of the table: "v1.ArticleTones" */
  update_v1_ArticleTones?: Maybe<v1_ArticleTones_mutation_response>;
  /** update single row of the table: "v1.ArticleTones" */
  update_v1_ArticleTones_by_pk?: Maybe<v1_ArticleTones>;
  /** update multiples rows of table: "v1.ArticleTones" */
  update_v1_ArticleTones_many?: Maybe<Array<Maybe<v1_ArticleTones_mutation_response>>>;
  /** update data of the table: "v1.Balance" */
  update_v1_Balance?: Maybe<v1_Balance_mutation_response>;
  /** update data of the table: "v1.BalanceActivityType" */
  update_v1_BalanceActivityType?: Maybe<v1_BalanceActivityType_mutation_response>;
  /** update single row of the table: "v1.BalanceActivityType" */
  update_v1_BalanceActivityType_by_pk?: Maybe<v1_BalanceActivityType>;
  /** update multiples rows of table: "v1.BalanceActivityType" */
  update_v1_BalanceActivityType_many?: Maybe<Array<Maybe<v1_BalanceActivityType_mutation_response>>>;
  /** update single row of the table: "v1.Balance" */
  update_v1_Balance_by_pk?: Maybe<v1_Balance>;
  /** update multiples rows of table: "v1.Balance" */
  update_v1_Balance_many?: Maybe<Array<Maybe<v1_Balance_mutation_response>>>;
  /** update data of the table: "v1.BillingInformation" */
  update_v1_BillingInformation?: Maybe<v1_BillingInformation_mutation_response>;
  /** update single row of the table: "v1.BillingInformation" */
  update_v1_BillingInformation_by_pk?: Maybe<v1_BillingInformation>;
  /** update multiples rows of table: "v1.BillingInformation" */
  update_v1_BillingInformation_many?: Maybe<Array<Maybe<v1_BillingInformation_mutation_response>>>;
  /** update data of the table: "v1.BillingMethod" */
  update_v1_BillingMethod?: Maybe<v1_BillingMethod_mutation_response>;
  /** update single row of the table: "v1.BillingMethod" */
  update_v1_BillingMethod_by_pk?: Maybe<v1_BillingMethod>;
  /** update multiples rows of table: "v1.BillingMethod" */
  update_v1_BillingMethod_many?: Maybe<Array<Maybe<v1_BillingMethod_mutation_response>>>;
  /** update data of the table: "v1.BonusSource" */
  update_v1_BonusSource?: Maybe<v1_BonusSource_mutation_response>;
  /** update single row of the table: "v1.BonusSource" */
  update_v1_BonusSource_by_pk?: Maybe<v1_BonusSource>;
  /** update multiples rows of table: "v1.BonusSource" */
  update_v1_BonusSource_many?: Maybe<Array<Maybe<v1_BonusSource_mutation_response>>>;
  /** update data of the table: "v1.BonusStatus" */
  update_v1_BonusStatus?: Maybe<v1_BonusStatus_mutation_response>;
  /** update single row of the table: "v1.BonusStatus" */
  update_v1_BonusStatus_by_pk?: Maybe<v1_BonusStatus>;
  /** update multiples rows of table: "v1.BonusStatus" */
  update_v1_BonusStatus_many?: Maybe<Array<Maybe<v1_BonusStatus_mutation_response>>>;
  /** update data of the table: "v1.BonusSubmitter" */
  update_v1_BonusSubmitter?: Maybe<v1_BonusSubmitter_mutation_response>;
  /** update single row of the table: "v1.BonusSubmitter" */
  update_v1_BonusSubmitter_by_pk?: Maybe<v1_BonusSubmitter>;
  /** update multiples rows of table: "v1.BonusSubmitter" */
  update_v1_BonusSubmitter_many?: Maybe<Array<Maybe<v1_BonusSubmitter_mutation_response>>>;
  /** update data of the table: "v1.BonusTypes" */
  update_v1_BonusTypes?: Maybe<v1_BonusTypes_mutation_response>;
  /** update single row of the table: "v1.BonusTypes" */
  update_v1_BonusTypes_by_pk?: Maybe<v1_BonusTypes>;
  /** update multiples rows of table: "v1.BonusTypes" */
  update_v1_BonusTypes_many?: Maybe<Array<Maybe<v1_BonusTypes_mutation_response>>>;
  /** update data of the table: "v1.Bonuses" */
  update_v1_Bonuses?: Maybe<v1_Bonuses_mutation_response>;
  /** update data of the table: "v1.BonusesToCountries" */
  update_v1_BonusesToCountries?: Maybe<v1_BonusesToCountries_mutation_response>;
  /** update single row of the table: "v1.BonusesToCountries" */
  update_v1_BonusesToCountries_by_pk?: Maybe<v1_BonusesToCountries>;
  /** update multiples rows of table: "v1.BonusesToCountries" */
  update_v1_BonusesToCountries_many?: Maybe<Array<Maybe<v1_BonusesToCountries_mutation_response>>>;
  /** update data of the table: "v1.BonusesToCurrencies" */
  update_v1_BonusesToCurrencies?: Maybe<v1_BonusesToCurrencies_mutation_response>;
  /** update single row of the table: "v1.BonusesToCurrencies" */
  update_v1_BonusesToCurrencies_by_pk?: Maybe<v1_BonusesToCurrencies>;
  /** update multiples rows of table: "v1.BonusesToCurrencies" */
  update_v1_BonusesToCurrencies_many?: Maybe<Array<Maybe<v1_BonusesToCurrencies_mutation_response>>>;
  /** update data of the table: "v1.BonusesToTypes" */
  update_v1_BonusesToTypes?: Maybe<v1_BonusesToTypes_mutation_response>;
  /** update single row of the table: "v1.BonusesToTypes" */
  update_v1_BonusesToTypes_by_pk?: Maybe<v1_BonusesToTypes>;
  /** update multiples rows of table: "v1.BonusesToTypes" */
  update_v1_BonusesToTypes_many?: Maybe<Array<Maybe<v1_BonusesToTypes_mutation_response>>>;
  /** update single row of the table: "v1.Bonuses" */
  update_v1_Bonuses_by_pk?: Maybe<v1_Bonuses>;
  /** update multiples rows of table: "v1.Bonuses" */
  update_v1_Bonuses_many?: Maybe<Array<Maybe<v1_Bonuses_mutation_response>>>;
  /** update data of the table: "v1.BonusestoGames" */
  update_v1_BonusestoGames?: Maybe<v1_BonusestoGames_mutation_response>;
  /** update single row of the table: "v1.BonusestoGames" */
  update_v1_BonusestoGames_by_pk?: Maybe<v1_BonusestoGames>;
  /** update multiples rows of table: "v1.BonusestoGames" */
  update_v1_BonusestoGames_many?: Maybe<Array<Maybe<v1_BonusestoGames_mutation_response>>>;
  /** update data of the table: "v1.BrandAlias" */
  update_v1_BrandAlias?: Maybe<v1_BrandAlias_mutation_response>;
  /** update single row of the table: "v1.BrandAlias" */
  update_v1_BrandAlias_by_pk?: Maybe<v1_BrandAlias>;
  /** update multiples rows of table: "v1.BrandAlias" */
  update_v1_BrandAlias_many?: Maybe<Array<Maybe<v1_BrandAlias_mutation_response>>>;
  /** update data of the table: "v1.BrandPriority" */
  update_v1_BrandPriority?: Maybe<v1_BrandPriority_mutation_response>;
  /** update single row of the table: "v1.BrandPriority" */
  update_v1_BrandPriority_by_pk?: Maybe<v1_BrandPriority>;
  /** update multiples rows of table: "v1.BrandPriority" */
  update_v1_BrandPriority_many?: Maybe<Array<Maybe<v1_BrandPriority_mutation_response>>>;
  /** update data of the table: "v1.Campaign" */
  update_v1_Campaign?: Maybe<v1_Campaign_mutation_response>;
  /** update data of the table: "v1.CampaignPriority" */
  update_v1_CampaignPriority?: Maybe<v1_CampaignPriority_mutation_response>;
  /** update single row of the table: "v1.CampaignPriority" */
  update_v1_CampaignPriority_by_pk?: Maybe<v1_CampaignPriority>;
  /** update multiples rows of table: "v1.CampaignPriority" */
  update_v1_CampaignPriority_many?: Maybe<Array<Maybe<v1_CampaignPriority_mutation_response>>>;
  /** update data of the table: "v1.CampaignStatus" */
  update_v1_CampaignStatus?: Maybe<v1_CampaignStatus_mutation_response>;
  /** update single row of the table: "v1.CampaignStatus" */
  update_v1_CampaignStatus_by_pk?: Maybe<v1_CampaignStatus>;
  /** update multiples rows of table: "v1.CampaignStatus" */
  update_v1_CampaignStatus_many?: Maybe<Array<Maybe<v1_CampaignStatus_mutation_response>>>;
  /** update data of the table: "v1.CampaignStrategy" */
  update_v1_CampaignStrategy?: Maybe<v1_CampaignStrategy_mutation_response>;
  /** update single row of the table: "v1.CampaignStrategy" */
  update_v1_CampaignStrategy_by_pk?: Maybe<v1_CampaignStrategy>;
  /** update multiples rows of table: "v1.CampaignStrategy" */
  update_v1_CampaignStrategy_many?: Maybe<Array<Maybe<v1_CampaignStrategy_mutation_response>>>;
  /** update data of the table: "v1.CampaignType" */
  update_v1_CampaignType?: Maybe<v1_CampaignType_mutation_response>;
  /** update single row of the table: "v1.CampaignType" */
  update_v1_CampaignType_by_pk?: Maybe<v1_CampaignType>;
  /** update multiples rows of table: "v1.CampaignType" */
  update_v1_CampaignType_many?: Maybe<Array<Maybe<v1_CampaignType_mutation_response>>>;
  /** update single row of the table: "v1.Campaign" */
  update_v1_Campaign_by_pk?: Maybe<v1_Campaign>;
  /** update multiples rows of table: "v1.Campaign" */
  update_v1_Campaign_many?: Maybe<Array<Maybe<v1_Campaign_mutation_response>>>;
  /** update data of the table: "v1.Categories" */
  update_v1_Categories?: Maybe<v1_Categories_mutation_response>;
  /** update single row of the table: "v1.Categories" */
  update_v1_Categories_by_pk?: Maybe<v1_Categories>;
  /** update multiples rows of table: "v1.Categories" */
  update_v1_Categories_many?: Maybe<Array<Maybe<v1_Categories_mutation_response>>>;
  /** update data of the table: "v1.Checkout" */
  update_v1_Checkout?: Maybe<v1_Checkout_mutation_response>;
  /** update data of the table: "v1.CheckoutDetail" */
  update_v1_CheckoutDetail?: Maybe<v1_CheckoutDetail_mutation_response>;
  /** update single row of the table: "v1.CheckoutDetail" */
  update_v1_CheckoutDetail_by_pk?: Maybe<v1_CheckoutDetail>;
  /** update multiples rows of table: "v1.CheckoutDetail" */
  update_v1_CheckoutDetail_many?: Maybe<Array<Maybe<v1_CheckoutDetail_mutation_response>>>;
  /** update data of the table: "v1.CheckoutStatus" */
  update_v1_CheckoutStatus?: Maybe<v1_CheckoutStatus_mutation_response>;
  /** update single row of the table: "v1.CheckoutStatus" */
  update_v1_CheckoutStatus_by_pk?: Maybe<v1_CheckoutStatus>;
  /** update multiples rows of table: "v1.CheckoutStatus" */
  update_v1_CheckoutStatus_many?: Maybe<Array<Maybe<v1_CheckoutStatus_mutation_response>>>;
  /** update single row of the table: "v1.Checkout" */
  update_v1_Checkout_by_pk?: Maybe<v1_Checkout>;
  /** update multiples rows of table: "v1.Checkout" */
  update_v1_Checkout_many?: Maybe<Array<Maybe<v1_Checkout_mutation_response>>>;
  /** update data of the table: "v1.Cities" */
  update_v1_Cities?: Maybe<v1_Cities_mutation_response>;
  /** update single row of the table: "v1.Cities" */
  update_v1_Cities_by_pk?: Maybe<v1_Cities>;
  /** update multiples rows of table: "v1.Cities" */
  update_v1_Cities_many?: Maybe<Array<Maybe<v1_Cities_mutation_response>>>;
  /** update data of the table: "v1.ClicksByTrackers" */
  update_v1_ClicksByTrackers?: Maybe<v1_ClicksByTrackers_mutation_response>;
  /** update data of the table: "v1.ClicksByTrackersNew" */
  update_v1_ClicksByTrackersNew?: Maybe<v1_ClicksByTrackersNew_mutation_response>;
  /** update single row of the table: "v1.ClicksByTrackersNew" */
  update_v1_ClicksByTrackersNew_by_pk?: Maybe<v1_ClicksByTrackersNew>;
  /** update multiples rows of table: "v1.ClicksByTrackersNew" */
  update_v1_ClicksByTrackersNew_many?: Maybe<Array<Maybe<v1_ClicksByTrackersNew_mutation_response>>>;
  /** update single row of the table: "v1.ClicksByTrackers" */
  update_v1_ClicksByTrackers_by_pk?: Maybe<v1_ClicksByTrackers>;
  /** update multiples rows of table: "v1.ClicksByTrackers" */
  update_v1_ClicksByTrackers_many?: Maybe<Array<Maybe<v1_ClicksByTrackers_mutation_response>>>;
  /** update data of the table: "v1.CompanyType" */
  update_v1_CompanyType?: Maybe<v1_CompanyType_mutation_response>;
  /** update single row of the table: "v1.CompanyType" */
  update_v1_CompanyType_by_pk?: Maybe<v1_CompanyType>;
  /** update multiples rows of table: "v1.CompanyType" */
  update_v1_CompanyType_many?: Maybe<Array<Maybe<v1_CompanyType_mutation_response>>>;
  /** update data of the table: "v1.Configurations" */
  update_v1_Configurations?: Maybe<v1_Configurations_mutation_response>;
  /** update single row of the table: "v1.Configurations" */
  update_v1_Configurations_by_pk?: Maybe<v1_Configurations>;
  /** update multiples rows of table: "v1.Configurations" */
  update_v1_Configurations_many?: Maybe<Array<Maybe<v1_Configurations_mutation_response>>>;
  /** update data of the table: "v1.ContactBase" */
  update_v1_ContactBase?: Maybe<v1_ContactBase_mutation_response>;
  /** update data of the table: "v1.ContactBasePaymentOption" */
  update_v1_ContactBasePaymentOption?: Maybe<v1_ContactBasePaymentOption_mutation_response>;
  /** update single row of the table: "v1.ContactBasePaymentOption" */
  update_v1_ContactBasePaymentOption_by_pk?: Maybe<v1_ContactBasePaymentOption>;
  /** update multiples rows of table: "v1.ContactBasePaymentOption" */
  update_v1_ContactBasePaymentOption_many?: Maybe<Array<Maybe<v1_ContactBasePaymentOption_mutation_response>>>;
  /** update single row of the table: "v1.ContactBase" */
  update_v1_ContactBase_by_pk?: Maybe<v1_ContactBase>;
  /** update multiples rows of table: "v1.ContactBase" */
  update_v1_ContactBase_many?: Maybe<Array<Maybe<v1_ContactBase_mutation_response>>>;
  /** update data of the table: "v1.ContactType" */
  update_v1_ContactType?: Maybe<v1_ContactType_mutation_response>;
  /** update single row of the table: "v1.ContactType" */
  update_v1_ContactType_by_pk?: Maybe<v1_ContactType>;
  /** update multiples rows of table: "v1.ContactType" */
  update_v1_ContactType_many?: Maybe<Array<Maybe<v1_ContactType_mutation_response>>>;
  /** update data of the table: "v1.Contacts_Roles" */
  update_v1_Contacts_Roles?: Maybe<v1_Contacts_Roles_mutation_response>;
  /** update single row of the table: "v1.Contacts_Roles" */
  update_v1_Contacts_Roles_by_pk?: Maybe<v1_Contacts_Roles>;
  /** update multiples rows of table: "v1.Contacts_Roles" */
  update_v1_Contacts_Roles_many?: Maybe<Array<Maybe<v1_Contacts_Roles_mutation_response>>>;
  /** update data of the table: "v1.ContentLink" */
  update_v1_ContentLink?: Maybe<v1_ContentLink_mutation_response>;
  /** update single row of the table: "v1.ContentLink" */
  update_v1_ContentLink_by_pk?: Maybe<v1_ContentLink>;
  /** update multiples rows of table: "v1.ContentLink" */
  update_v1_ContentLink_many?: Maybe<Array<Maybe<v1_ContentLink_mutation_response>>>;
  /** update data of the table: "v1.ContentTypes" */
  update_v1_ContentTypes?: Maybe<v1_ContentTypes_mutation_response>;
  /** update single row of the table: "v1.ContentTypes" */
  update_v1_ContentTypes_by_pk?: Maybe<v1_ContentTypes>;
  /** update multiples rows of table: "v1.ContentTypes" */
  update_v1_ContentTypes_many?: Maybe<Array<Maybe<v1_ContentTypes_mutation_response>>>;
  /** update data of the table: "v1.Credential_Roles" */
  update_v1_Credential_Roles?: Maybe<v1_Credential_Roles_mutation_response>;
  /** update single row of the table: "v1.Credential_Roles" */
  update_v1_Credential_Roles_by_pk?: Maybe<v1_Credential_Roles>;
  /** update multiples rows of table: "v1.Credential_Roles" */
  update_v1_Credential_Roles_many?: Maybe<Array<Maybe<v1_Credential_Roles_mutation_response>>>;
  /** update data of the table: "v1.Credentials" */
  update_v1_Credentials?: Maybe<v1_Credentials_mutation_response>;
  /** update single row of the table: "v1.Credentials" */
  update_v1_Credentials_by_pk?: Maybe<v1_Credentials>;
  /** update multiples rows of table: "v1.Credentials" */
  update_v1_Credentials_many?: Maybe<Array<Maybe<v1_Credentials_mutation_response>>>;
  /** update data of the table: "v1.DomainExtensions" */
  update_v1_DomainExtensions?: Maybe<v1_DomainExtensions_mutation_response>;
  /** update single row of the table: "v1.DomainExtensions" */
  update_v1_DomainExtensions_by_pk?: Maybe<v1_DomainExtensions>;
  /** update multiples rows of table: "v1.DomainExtensions" */
  update_v1_DomainExtensions_many?: Maybe<Array<Maybe<v1_DomainExtensions_mutation_response>>>;
  /** update data of the table: "v1.Events" */
  update_v1_Events?: Maybe<v1_Events_mutation_response>;
  /** update single row of the table: "v1.Events" */
  update_v1_Events_by_pk?: Maybe<v1_Events>;
  /** update multiples rows of table: "v1.Events" */
  update_v1_Events_many?: Maybe<Array<Maybe<v1_Events_mutation_response>>>;
  /** update data of the table: "v1.ExpiringRecPayments" */
  update_v1_ExpiringRecPayments?: Maybe<v1_ExpiringRecPayments_mutation_response>;
  /** update multiples rows of table: "v1.ExpiringRecPayments" */
  update_v1_ExpiringRecPayments_many?: Maybe<Array<Maybe<v1_ExpiringRecPayments_mutation_response>>>;
  /** update data of the table: "v1.Files" */
  update_v1_Files?: Maybe<v1_Files_mutation_response>;
  /** update single row of the table: "v1.Files" */
  update_v1_Files_by_pk?: Maybe<v1_Files>;
  /** update multiples rows of table: "v1.Files" */
  update_v1_Files_many?: Maybe<Array<Maybe<v1_Files_mutation_response>>>;
  /** update data of the table: "v1.GameType" */
  update_v1_GameType?: Maybe<v1_GameType_mutation_response>;
  /** update single row of the table: "v1.GameType" */
  update_v1_GameType_by_pk?: Maybe<v1_GameType>;
  /** update multiples rows of table: "v1.GameType" */
  update_v1_GameType_many?: Maybe<Array<Maybe<v1_GameType_mutation_response>>>;
  /** update data of the table: "v1.InvoiceBase" */
  update_v1_InvoiceBase?: Maybe<v1_InvoiceBase_mutation_response>;
  /** update single row of the table: "v1.InvoiceBase" */
  update_v1_InvoiceBase_by_pk?: Maybe<v1_InvoiceBase>;
  /** update multiples rows of table: "v1.InvoiceBase" */
  update_v1_InvoiceBase_many?: Maybe<Array<Maybe<v1_InvoiceBase_mutation_response>>>;
  /** update data of the table: "v1.InvoiceDetailBase" */
  update_v1_InvoiceDetailBase?: Maybe<v1_InvoiceDetailBase_mutation_response>;
  /** update single row of the table: "v1.InvoiceDetailBase" */
  update_v1_InvoiceDetailBase_by_pk?: Maybe<v1_InvoiceDetailBase>;
  /** update multiples rows of table: "v1.InvoiceDetailBase" */
  update_v1_InvoiceDetailBase_many?: Maybe<Array<Maybe<v1_InvoiceDetailBase_mutation_response>>>;
  /** update data of the table: "v1.InvoiceStatus" */
  update_v1_InvoiceStatus?: Maybe<v1_InvoiceStatus_mutation_response>;
  /** update single row of the table: "v1.InvoiceStatus" */
  update_v1_InvoiceStatus_by_pk?: Maybe<v1_InvoiceStatus>;
  /** update multiples rows of table: "v1.InvoiceStatus" */
  update_v1_InvoiceStatus_many?: Maybe<Array<Maybe<v1_InvoiceStatus_mutation_response>>>;
  /** update data of the table: "v1.LandingPagePriority" */
  update_v1_LandingPagePriority?: Maybe<v1_LandingPagePriority_mutation_response>;
  /** update single row of the table: "v1.LandingPagePriority" */
  update_v1_LandingPagePriority_by_pk?: Maybe<v1_LandingPagePriority>;
  /** update multiples rows of table: "v1.LandingPagePriority" */
  update_v1_LandingPagePriority_many?: Maybe<Array<Maybe<v1_LandingPagePriority_mutation_response>>>;
  /** update data of the table: "v1.LandingPages" */
  update_v1_LandingPages?: Maybe<v1_LandingPages_mutation_response>;
  /** update single row of the table: "v1.LandingPages" */
  update_v1_LandingPages_by_pk?: Maybe<v1_LandingPages>;
  /** update multiples rows of table: "v1.LandingPages" */
  update_v1_LandingPages_many?: Maybe<Array<Maybe<v1_LandingPages_mutation_response>>>;
  /** update data of the table: "v1.LanguagePriceStep" */
  update_v1_LanguagePriceStep?: Maybe<v1_LanguagePriceStep_mutation_response>;
  /** update single row of the table: "v1.LanguagePriceStep" */
  update_v1_LanguagePriceStep_by_pk?: Maybe<v1_LanguagePriceStep>;
  /** update multiples rows of table: "v1.LanguagePriceStep" */
  update_v1_LanguagePriceStep_many?: Maybe<Array<Maybe<v1_LanguagePriceStep_mutation_response>>>;
  /** update data of the table: "v1.MarketPlaceCategories" */
  update_v1_MarketPlaceCategories?: Maybe<v1_MarketPlaceCategories_mutation_response>;
  /** update data of the table: "v1.MarketPlaceCategoriesTypes" */
  update_v1_MarketPlaceCategoriesTypes?: Maybe<v1_MarketPlaceCategoriesTypes_mutation_response>;
  /** update single row of the table: "v1.MarketPlaceCategoriesTypes" */
  update_v1_MarketPlaceCategoriesTypes_by_pk?: Maybe<v1_MarketPlaceCategoriesTypes>;
  /** update multiples rows of table: "v1.MarketPlaceCategoriesTypes" */
  update_v1_MarketPlaceCategoriesTypes_many?: Maybe<Array<Maybe<v1_MarketPlaceCategoriesTypes_mutation_response>>>;
  /** update data of the table: "v1.MarketPlaceCategories_Offers" */
  update_v1_MarketPlaceCategories_Offers?: Maybe<v1_MarketPlaceCategories_Offers_mutation_response>;
  /** update single row of the table: "v1.MarketPlaceCategories_Offers" */
  update_v1_MarketPlaceCategories_Offers_by_pk?: Maybe<v1_MarketPlaceCategories_Offers>;
  /** update multiples rows of table: "v1.MarketPlaceCategories_Offers" */
  update_v1_MarketPlaceCategories_Offers_many?: Maybe<Array<Maybe<v1_MarketPlaceCategories_Offers_mutation_response>>>;
  /** update single row of the table: "v1.MarketPlaceCategories" */
  update_v1_MarketPlaceCategories_by_pk?: Maybe<v1_MarketPlaceCategories>;
  /** update multiples rows of table: "v1.MarketPlaceCategories" */
  update_v1_MarketPlaceCategories_many?: Maybe<Array<Maybe<v1_MarketPlaceCategories_mutation_response>>>;
  /** update data of the table: "v1.MarketPlaceOfferNiche" */
  update_v1_MarketPlaceOfferNiche?: Maybe<v1_MarketPlaceOfferNiche_mutation_response>;
  /** update single row of the table: "v1.MarketPlaceOfferNiche" */
  update_v1_MarketPlaceOfferNiche_by_pk?: Maybe<v1_MarketPlaceOfferNiche>;
  /** update multiples rows of table: "v1.MarketPlaceOfferNiche" */
  update_v1_MarketPlaceOfferNiche_many?: Maybe<Array<Maybe<v1_MarketPlaceOfferNiche_mutation_response>>>;
  /** update data of the table: "v1.MarketPlaceOrder" */
  update_v1_MarketPlaceOrder?: Maybe<v1_MarketPlaceOrder_mutation_response>;
  /** update data of the table: "v1.MarketPlaceOrderRequest" */
  update_v1_MarketPlaceOrderRequest?: Maybe<v1_MarketPlaceOrderRequest_mutation_response>;
  /** update single row of the table: "v1.MarketPlaceOrderRequest" */
  update_v1_MarketPlaceOrderRequest_by_pk?: Maybe<v1_MarketPlaceOrderRequest>;
  /** update multiples rows of table: "v1.MarketPlaceOrderRequest" */
  update_v1_MarketPlaceOrderRequest_many?: Maybe<Array<Maybe<v1_MarketPlaceOrderRequest_mutation_response>>>;
  /** update data of the table: "v1.MarketPlaceOrderStatus" */
  update_v1_MarketPlaceOrderStatus?: Maybe<v1_MarketPlaceOrderStatus_mutation_response>;
  /** update single row of the table: "v1.MarketPlaceOrderStatus" */
  update_v1_MarketPlaceOrderStatus_by_pk?: Maybe<v1_MarketPlaceOrderStatus>;
  /** update multiples rows of table: "v1.MarketPlaceOrderStatus" */
  update_v1_MarketPlaceOrderStatus_many?: Maybe<Array<Maybe<v1_MarketPlaceOrderStatus_mutation_response>>>;
  /** update single row of the table: "v1.MarketPlaceOrder" */
  update_v1_MarketPlaceOrder_by_pk?: Maybe<v1_MarketPlaceOrder>;
  /** update multiples rows of table: "v1.MarketPlaceOrder" */
  update_v1_MarketPlaceOrder_many?: Maybe<Array<Maybe<v1_MarketPlaceOrder_mutation_response>>>;
  /** update data of the table: "v1.MarketPlaceRegisterStatus" */
  update_v1_MarketPlaceRegisterStatus?: Maybe<v1_MarketPlaceRegisterStatus_mutation_response>;
  /** update single row of the table: "v1.MarketPlaceRegisterStatus" */
  update_v1_MarketPlaceRegisterStatus_by_pk?: Maybe<v1_MarketPlaceRegisterStatus>;
  /** update multiples rows of table: "v1.MarketPlaceRegisterStatus" */
  update_v1_MarketPlaceRegisterStatus_many?: Maybe<Array<Maybe<v1_MarketPlaceRegisterStatus_mutation_response>>>;
  /** update data of the table: "v1.MarketType" */
  update_v1_MarketType?: Maybe<v1_MarketType_mutation_response>;
  /** update single row of the table: "v1.MarketType" */
  update_v1_MarketType_by_pk?: Maybe<v1_MarketType>;
  /** update multiples rows of table: "v1.MarketType" */
  update_v1_MarketType_many?: Maybe<Array<Maybe<v1_MarketType_mutation_response>>>;
  /** update data of the table: "v1.MarketplaceProjects" */
  update_v1_MarketplaceProjects?: Maybe<v1_MarketplaceProjects_mutation_response>;
  /** update single row of the table: "v1.MarketplaceProjects" */
  update_v1_MarketplaceProjects_by_pk?: Maybe<v1_MarketplaceProjects>;
  /** update multiples rows of table: "v1.MarketplaceProjects" */
  update_v1_MarketplaceProjects_many?: Maybe<Array<Maybe<v1_MarketplaceProjects_mutation_response>>>;
  /** update data of the table: "v1.MarketplaceStatus" */
  update_v1_MarketplaceStatus?: Maybe<v1_MarketplaceStatus_mutation_response>;
  /** update single row of the table: "v1.MarketplaceStatus" */
  update_v1_MarketplaceStatus_by_pk?: Maybe<v1_MarketplaceStatus>;
  /** update multiples rows of table: "v1.MarketplaceStatus" */
  update_v1_MarketplaceStatus_many?: Maybe<Array<Maybe<v1_MarketplaceStatus_mutation_response>>>;
  /** update data of the table: "v1.Months" */
  update_v1_Months?: Maybe<v1_Months_mutation_response>;
  /** update single row of the table: "v1.Months" */
  update_v1_Months_by_pk?: Maybe<v1_Months>;
  /** update multiples rows of table: "v1.Months" */
  update_v1_Months_many?: Maybe<Array<Maybe<v1_Months_mutation_response>>>;
  /** update data of the table: "v1.Offer" */
  update_v1_Offer?: Maybe<v1_Offer_mutation_response>;
  /** update data of the table: "v1.OfferStatus" */
  update_v1_OfferStatus?: Maybe<v1_OfferStatus_mutation_response>;
  /** update single row of the table: "v1.OfferStatus" */
  update_v1_OfferStatus_by_pk?: Maybe<v1_OfferStatus>;
  /** update multiples rows of table: "v1.OfferStatus" */
  update_v1_OfferStatus_many?: Maybe<Array<Maybe<v1_OfferStatus_mutation_response>>>;
  /** update data of the table: "v1.OfferTags" */
  update_v1_OfferTags?: Maybe<v1_OfferTags_mutation_response>;
  /** update single row of the table: "v1.OfferTags" */
  update_v1_OfferTags_by_pk?: Maybe<v1_OfferTags>;
  /** update multiples rows of table: "v1.OfferTags" */
  update_v1_OfferTags_many?: Maybe<Array<Maybe<v1_OfferTags_mutation_response>>>;
  /** update data of the table: "v1.OfferToTags" */
  update_v1_OfferToTags?: Maybe<v1_OfferToTags_mutation_response>;
  /** update single row of the table: "v1.OfferToTags" */
  update_v1_OfferToTags_by_pk?: Maybe<v1_OfferToTags>;
  /** update multiples rows of table: "v1.OfferToTags" */
  update_v1_OfferToTags_many?: Maybe<Array<Maybe<v1_OfferToTags_mutation_response>>>;
  /** update single row of the table: "v1.Offer" */
  update_v1_Offer_by_pk?: Maybe<v1_Offer>;
  /** update multiples rows of table: "v1.Offer" */
  update_v1_Offer_many?: Maybe<Array<Maybe<v1_Offer_mutation_response>>>;
  /** update data of the table: "v1.OrderFor" */
  update_v1_OrderFor?: Maybe<v1_OrderFor_mutation_response>;
  /** update single row of the table: "v1.OrderFor" */
  update_v1_OrderFor_by_pk?: Maybe<v1_OrderFor>;
  /** update multiples rows of table: "v1.OrderFor" */
  update_v1_OrderFor_many?: Maybe<Array<Maybe<v1_OrderFor_mutation_response>>>;
  /** update data of the table: "v1.OrderPayTo" */
  update_v1_OrderPayTo?: Maybe<v1_OrderPayTo_mutation_response>;
  /** update single row of the table: "v1.OrderPayTo" */
  update_v1_OrderPayTo_by_pk?: Maybe<v1_OrderPayTo>;
  /** update multiples rows of table: "v1.OrderPayTo" */
  update_v1_OrderPayTo_many?: Maybe<Array<Maybe<v1_OrderPayTo_mutation_response>>>;
  /** update data of the table: "v1.OrderPriority" */
  update_v1_OrderPriority?: Maybe<v1_OrderPriority_mutation_response>;
  /** update single row of the table: "v1.OrderPriority" */
  update_v1_OrderPriority_by_pk?: Maybe<v1_OrderPriority>;
  /** update multiples rows of table: "v1.OrderPriority" */
  update_v1_OrderPriority_many?: Maybe<Array<Maybe<v1_OrderPriority_mutation_response>>>;
  /** update data of the table: "v1.OrderType" */
  update_v1_OrderType?: Maybe<v1_OrderType_mutation_response>;
  /** update single row of the table: "v1.OrderType" */
  update_v1_OrderType_by_pk?: Maybe<v1_OrderType>;
  /** update multiples rows of table: "v1.OrderType" */
  update_v1_OrderType_many?: Maybe<Array<Maybe<v1_OrderType_mutation_response>>>;
  /** update data of the table: "v1.Page" */
  update_v1_Page?: Maybe<v1_Page_mutation_response>;
  /** update single row of the table: "v1.Page" */
  update_v1_Page_by_pk?: Maybe<v1_Page>;
  /** update multiples rows of table: "v1.Page" */
  update_v1_Page_many?: Maybe<Array<Maybe<v1_Page_mutation_response>>>;
  /** update data of the table: "v1.PaymentCompanies" */
  update_v1_PaymentCompanies?: Maybe<v1_PaymentCompanies_mutation_response>;
  /** update single row of the table: "v1.PaymentCompanies" */
  update_v1_PaymentCompanies_by_pk?: Maybe<v1_PaymentCompanies>;
  /** update multiples rows of table: "v1.PaymentCompanies" */
  update_v1_PaymentCompanies_many?: Maybe<Array<Maybe<v1_PaymentCompanies_mutation_response>>>;
  /** update data of the table: "v1.PaymentFrequency" */
  update_v1_PaymentFrequency?: Maybe<v1_PaymentFrequency_mutation_response>;
  /** update single row of the table: "v1.PaymentFrequency" */
  update_v1_PaymentFrequency_by_pk?: Maybe<v1_PaymentFrequency>;
  /** update multiples rows of table: "v1.PaymentFrequency" */
  update_v1_PaymentFrequency_many?: Maybe<Array<Maybe<v1_PaymentFrequency_mutation_response>>>;
  /** update data of the table: "v1.PoToPosts" */
  update_v1_PoToPosts?: Maybe<v1_PoToPosts_mutation_response>;
  /** update single row of the table: "v1.PoToPosts" */
  update_v1_PoToPosts_by_pk?: Maybe<v1_PoToPosts>;
  /** update multiples rows of table: "v1.PoToPosts" */
  update_v1_PoToPosts_many?: Maybe<Array<Maybe<v1_PoToPosts_mutation_response>>>;
  /** update data of the table: "v1.PostDuration" */
  update_v1_PostDuration?: Maybe<v1_PostDuration_mutation_response>;
  /** update single row of the table: "v1.PostDuration" */
  update_v1_PostDuration_by_pk?: Maybe<v1_PostDuration>;
  /** update multiples rows of table: "v1.PostDuration" */
  update_v1_PostDuration_many?: Maybe<Array<Maybe<v1_PostDuration_mutation_response>>>;
  /** update data of the table: "v1.PostNiche" */
  update_v1_PostNiche?: Maybe<v1_PostNiche_mutation_response>;
  /** update single row of the table: "v1.PostNiche" */
  update_v1_PostNiche_by_pk?: Maybe<v1_PostNiche>;
  /** update multiples rows of table: "v1.PostNiche" */
  update_v1_PostNiche_many?: Maybe<Array<Maybe<v1_PostNiche_mutation_response>>>;
  /** update data of the table: "v1.Posts" */
  update_v1_Posts?: Maybe<v1_Posts_mutation_response>;
  /** update data of the table: "v1.PostsAnchorTextType" */
  update_v1_PostsAnchorTextType?: Maybe<v1_PostsAnchorTextType_mutation_response>;
  /** update single row of the table: "v1.PostsAnchorTextType" */
  update_v1_PostsAnchorTextType_by_pk?: Maybe<v1_PostsAnchorTextType>;
  /** update multiples rows of table: "v1.PostsAnchorTextType" */
  update_v1_PostsAnchorTextType_many?: Maybe<Array<Maybe<v1_PostsAnchorTextType_mutation_response>>>;
  /** update data of the table: "v1.PostsContentStatus" */
  update_v1_PostsContentStatus?: Maybe<v1_PostsContentStatus_mutation_response>;
  /** update single row of the table: "v1.PostsContentStatus" */
  update_v1_PostsContentStatus_by_pk?: Maybe<v1_PostsContentStatus>;
  /** update multiples rows of table: "v1.PostsContentStatus" */
  update_v1_PostsContentStatus_many?: Maybe<Array<Maybe<v1_PostsContentStatus_mutation_response>>>;
  /** update data of the table: "v1.PostsCopyScape" */
  update_v1_PostsCopyScape?: Maybe<v1_PostsCopyScape_mutation_response>;
  /** update single row of the table: "v1.PostsCopyScape" */
  update_v1_PostsCopyScape_by_pk?: Maybe<v1_PostsCopyScape>;
  /** update multiples rows of table: "v1.PostsCopyScape" */
  update_v1_PostsCopyScape_many?: Maybe<Array<Maybe<v1_PostsCopyScape_mutation_response>>>;
  /** update data of the table: "v1.PostsLinkStatus" */
  update_v1_PostsLinkStatus?: Maybe<v1_PostsLinkStatus_mutation_response>;
  /** update single row of the table: "v1.PostsLinkStatus" */
  update_v1_PostsLinkStatus_by_pk?: Maybe<v1_PostsLinkStatus>;
  /** update multiples rows of table: "v1.PostsLinkStatus" */
  update_v1_PostsLinkStatus_many?: Maybe<Array<Maybe<v1_PostsLinkStatus_mutation_response>>>;
  /** update data of the table: "v1.PostsOnlineStatus" */
  update_v1_PostsOnlineStatus?: Maybe<v1_PostsOnlineStatus_mutation_response>;
  /** update single row of the table: "v1.PostsOnlineStatus" */
  update_v1_PostsOnlineStatus_by_pk?: Maybe<v1_PostsOnlineStatus>;
  /** update multiples rows of table: "v1.PostsOnlineStatus" */
  update_v1_PostsOnlineStatus_many?: Maybe<Array<Maybe<v1_PostsOnlineStatus_mutation_response>>>;
  /** update data of the table: "v1.PostsOutreachStatus" */
  update_v1_PostsOutreachStatus?: Maybe<v1_PostsOutreachStatus_mutation_response>;
  /** update single row of the table: "v1.PostsOutreachStatus" */
  update_v1_PostsOutreachStatus_by_pk?: Maybe<v1_PostsOutreachStatus>;
  /** update multiples rows of table: "v1.PostsOutreachStatus" */
  update_v1_PostsOutreachStatus_many?: Maybe<Array<Maybe<v1_PostsOutreachStatus_mutation_response>>>;
  /** update data of the table: "v1.PostsPageTypeDeprecated" */
  update_v1_PostsPageTypeDeprecated?: Maybe<v1_PostsPageTypeDeprecated_mutation_response>;
  /** update single row of the table: "v1.PostsPageTypeDeprecated" */
  update_v1_PostsPageTypeDeprecated_by_pk?: Maybe<v1_PostsPageTypeDeprecated>;
  /** update multiples rows of table: "v1.PostsPageTypeDeprecated" */
  update_v1_PostsPageTypeDeprecated_many?: Maybe<Array<Maybe<v1_PostsPageTypeDeprecated_mutation_response>>>;
  /** update data of the table: "v1.PostsRemovalStatus" */
  update_v1_PostsRemovalStatus?: Maybe<v1_PostsRemovalStatus_mutation_response>;
  /** update single row of the table: "v1.PostsRemovalStatus" */
  update_v1_PostsRemovalStatus_by_pk?: Maybe<v1_PostsRemovalStatus>;
  /** update multiples rows of table: "v1.PostsRemovalStatus" */
  update_v1_PostsRemovalStatus_many?: Maybe<Array<Maybe<v1_PostsRemovalStatus_mutation_response>>>;
  /** update data of the table: "v1.PostsSEOApproval" */
  update_v1_PostsSEOApproval?: Maybe<v1_PostsSEOApproval_mutation_response>;
  /** update single row of the table: "v1.PostsSEOApproval" */
  update_v1_PostsSEOApproval_by_pk?: Maybe<v1_PostsSEOApproval>;
  /** update multiples rows of table: "v1.PostsSEOApproval" */
  update_v1_PostsSEOApproval_many?: Maybe<Array<Maybe<v1_PostsSEOApproval_mutation_response>>>;
  /** update data of the table: "v1.PostsSEODeclineReason" */
  update_v1_PostsSEODeclineReason?: Maybe<v1_PostsSEODeclineReason_mutation_response>;
  /** update single row of the table: "v1.PostsSEODeclineReason" */
  update_v1_PostsSEODeclineReason_by_pk?: Maybe<v1_PostsSEODeclineReason>;
  /** update multiples rows of table: "v1.PostsSEODeclineReason" */
  update_v1_PostsSEODeclineReason_many?: Maybe<Array<Maybe<v1_PostsSEODeclineReason_mutation_response>>>;
  /** update data of the table: "v1.PostsStatus" */
  update_v1_PostsStatus?: Maybe<v1_PostsStatus_mutation_response>;
  /** update data of the table: "v1.PostsStatusCode" */
  update_v1_PostsStatusCode?: Maybe<v1_PostsStatusCode_mutation_response>;
  /** update single row of the table: "v1.PostsStatusCode" */
  update_v1_PostsStatusCode_by_pk?: Maybe<v1_PostsStatusCode>;
  /** update multiples rows of table: "v1.PostsStatusCode" */
  update_v1_PostsStatusCode_many?: Maybe<Array<Maybe<v1_PostsStatusCode_mutation_response>>>;
  /** update single row of the table: "v1.PostsStatus" */
  update_v1_PostsStatus_by_pk?: Maybe<v1_PostsStatus>;
  /** update multiples rows of table: "v1.PostsStatus" */
  update_v1_PostsStatus_many?: Maybe<Array<Maybe<v1_PostsStatus_mutation_response>>>;
  /** update single row of the table: "v1.Posts" */
  update_v1_Posts_by_pk?: Maybe<v1_Posts>;
  /** update multiples rows of table: "v1.Posts" */
  update_v1_Posts_many?: Maybe<Array<Maybe<v1_Posts_mutation_response>>>;
  /** update data of the table: "v1.Preferences" */
  update_v1_Preferences?: Maybe<v1_Preferences_mutation_response>;
  /** update data of the table: "v1.PreferencesMarketplace" */
  update_v1_PreferencesMarketplace?: Maybe<v1_PreferencesMarketplace_mutation_response>;
  /** update single row of the table: "v1.PreferencesMarketplace" */
  update_v1_PreferencesMarketplace_by_pk?: Maybe<v1_PreferencesMarketplace>;
  /** update multiples rows of table: "v1.PreferencesMarketplace" */
  update_v1_PreferencesMarketplace_many?: Maybe<Array<Maybe<v1_PreferencesMarketplace_mutation_response>>>;
  /** update single row of the table: "v1.Preferences" */
  update_v1_Preferences_by_pk?: Maybe<v1_Preferences>;
  /** update multiples rows of table: "v1.Preferences" */
  update_v1_Preferences_many?: Maybe<Array<Maybe<v1_Preferences_mutation_response>>>;
  /** update data of the table: "v1.Project" */
  update_v1_Project?: Maybe<v1_Project_mutation_response>;
  /** update data of the table: "v1.ProjectImportance" */
  update_v1_ProjectImportance?: Maybe<v1_ProjectImportance_mutation_response>;
  /** update single row of the table: "v1.ProjectImportance" */
  update_v1_ProjectImportance_by_pk?: Maybe<v1_ProjectImportance>;
  /** update multiples rows of table: "v1.ProjectImportance" */
  update_v1_ProjectImportance_many?: Maybe<Array<Maybe<v1_ProjectImportance_mutation_response>>>;
  /** update data of the table: "v1.ProjectStatus" */
  update_v1_ProjectStatus?: Maybe<v1_ProjectStatus_mutation_response>;
  /** update single row of the table: "v1.ProjectStatus" */
  update_v1_ProjectStatus_by_pk?: Maybe<v1_ProjectStatus>;
  /** update multiples rows of table: "v1.ProjectStatus" */
  update_v1_ProjectStatus_many?: Maybe<Array<Maybe<v1_ProjectStatus_mutation_response>>>;
  /** update data of the table: "v1.ProjectTopic" */
  update_v1_ProjectTopic?: Maybe<v1_ProjectTopic_mutation_response>;
  /** update single row of the table: "v1.ProjectTopic" */
  update_v1_ProjectTopic_by_pk?: Maybe<v1_ProjectTopic>;
  /** update multiples rows of table: "v1.ProjectTopic" */
  update_v1_ProjectTopic_many?: Maybe<Array<Maybe<v1_ProjectTopic_mutation_response>>>;
  /** update data of the table: "v1.Project_Countries" */
  update_v1_Project_Countries?: Maybe<v1_Project_Countries_mutation_response>;
  /** update single row of the table: "v1.Project_Countries" */
  update_v1_Project_Countries_by_pk?: Maybe<v1_Project_Countries>;
  /** update multiples rows of table: "v1.Project_Countries" */
  update_v1_Project_Countries_many?: Maybe<Array<Maybe<v1_Project_Countries_mutation_response>>>;
  /** update single row of the table: "v1.Project" */
  update_v1_Project_by_pk?: Maybe<v1_Project>;
  /** update multiples rows of table: "v1.Project" */
  update_v1_Project_many?: Maybe<Array<Maybe<v1_Project_mutation_response>>>;
  /** update data of the table: "v1.PublisherPostStatus" */
  update_v1_PublisherPostStatus?: Maybe<v1_PublisherPostStatus_mutation_response>;
  /** update single row of the table: "v1.PublisherPostStatus" */
  update_v1_PublisherPostStatus_by_pk?: Maybe<v1_PublisherPostStatus>;
  /** update multiples rows of table: "v1.PublisherPostStatus" */
  update_v1_PublisherPostStatus_many?: Maybe<Array<Maybe<v1_PublisherPostStatus_mutation_response>>>;
  /** update data of the table: "v1.PurchaseOrderStatus" */
  update_v1_PurchaseOrderStatus?: Maybe<v1_PurchaseOrderStatus_mutation_response>;
  /** update single row of the table: "v1.PurchaseOrderStatus" */
  update_v1_PurchaseOrderStatus_by_pk?: Maybe<v1_PurchaseOrderStatus>;
  /** update multiples rows of table: "v1.PurchaseOrderStatus" */
  update_v1_PurchaseOrderStatus_many?: Maybe<Array<Maybe<v1_PurchaseOrderStatus_mutation_response>>>;
  /** update data of the table: "v1.ReceiptStatus" */
  update_v1_ReceiptStatus?: Maybe<v1_ReceiptStatus_mutation_response>;
  /** update single row of the table: "v1.ReceiptStatus" */
  update_v1_ReceiptStatus_by_pk?: Maybe<v1_ReceiptStatus>;
  /** update multiples rows of table: "v1.ReceiptStatus" */
  update_v1_ReceiptStatus_many?: Maybe<Array<Maybe<v1_ReceiptStatus_mutation_response>>>;
  /** update data of the table: "v1.RecurringPaymentStatus" */
  update_v1_RecurringPaymentStatus?: Maybe<v1_RecurringPaymentStatus_mutation_response>;
  /** update single row of the table: "v1.RecurringPaymentStatus" */
  update_v1_RecurringPaymentStatus_by_pk?: Maybe<v1_RecurringPaymentStatus>;
  /** update multiples rows of table: "v1.RecurringPaymentStatus" */
  update_v1_RecurringPaymentStatus_many?: Maybe<Array<Maybe<v1_RecurringPaymentStatus_mutation_response>>>;
  /** update data of the table: "v1.RecurringPayments" */
  update_v1_RecurringPayments?: Maybe<v1_RecurringPayments_mutation_response>;
  /** update data of the table: "v1.RecurringPayments_Roles" */
  update_v1_RecurringPayments_Roles?: Maybe<v1_RecurringPayments_Roles_mutation_response>;
  /** update single row of the table: "v1.RecurringPayments_Roles" */
  update_v1_RecurringPayments_Roles_by_pk?: Maybe<v1_RecurringPayments_Roles>;
  /** update multiples rows of table: "v1.RecurringPayments_Roles" */
  update_v1_RecurringPayments_Roles_many?: Maybe<Array<Maybe<v1_RecurringPayments_Roles_mutation_response>>>;
  /** update single row of the table: "v1.RecurringPayments" */
  update_v1_RecurringPayments_by_pk?: Maybe<v1_RecurringPayments>;
  /** update multiples rows of table: "v1.RecurringPayments" */
  update_v1_RecurringPayments_many?: Maybe<Array<Maybe<v1_RecurringPayments_mutation_response>>>;
  /** update data of the table: "v1.Report" */
  update_v1_Report?: Maybe<v1_Report_mutation_response>;
  /** update data of the table: "v1.Report_Roles" */
  update_v1_Report_Roles?: Maybe<v1_Report_Roles_mutation_response>;
  /** update single row of the table: "v1.Report_Roles" */
  update_v1_Report_Roles_by_pk?: Maybe<v1_Report_Roles>;
  /** update multiples rows of table: "v1.Report_Roles" */
  update_v1_Report_Roles_many?: Maybe<Array<Maybe<v1_Report_Roles_mutation_response>>>;
  /** update single row of the table: "v1.Report" */
  update_v1_Report_by_pk?: Maybe<v1_Report>;
  /** update multiples rows of table: "v1.Report" */
  update_v1_Report_many?: Maybe<Array<Maybe<v1_Report_mutation_response>>>;
  /** update data of the table: "v1.RestrictedNichesToOffer" */
  update_v1_RestrictedNichesToOffer?: Maybe<v1_RestrictedNichesToOffer_mutation_response>;
  /** update single row of the table: "v1.RestrictedNichesToOffer" */
  update_v1_RestrictedNichesToOffer_by_pk?: Maybe<v1_RestrictedNichesToOffer>;
  /** update multiples rows of table: "v1.RestrictedNichesToOffer" */
  update_v1_RestrictedNichesToOffer_many?: Maybe<Array<Maybe<v1_RestrictedNichesToOffer_mutation_response>>>;
  /** update data of the table: "v1.Roles" */
  update_v1_Roles?: Maybe<v1_Roles_mutation_response>;
  /** update single row of the table: "v1.Roles" */
  update_v1_Roles_by_pk?: Maybe<v1_Roles>;
  /** update multiples rows of table: "v1.Roles" */
  update_v1_Roles_many?: Maybe<Array<Maybe<v1_Roles_mutation_response>>>;
  /** update data of the table: "v1.SalesOrderBase" */
  update_v1_SalesOrderBase?: Maybe<v1_SalesOrderBase_mutation_response>;
  /** update single row of the table: "v1.SalesOrderBase" */
  update_v1_SalesOrderBase_by_pk?: Maybe<v1_SalesOrderBase>;
  /** update multiples rows of table: "v1.SalesOrderBase" */
  update_v1_SalesOrderBase_many?: Maybe<Array<Maybe<v1_SalesOrderBase_mutation_response>>>;
  /** update data of the table: "v1.ServiceTypes" */
  update_v1_ServiceTypes?: Maybe<v1_ServiceTypes_mutation_response>;
  /** update single row of the table: "v1.ServiceTypes" */
  update_v1_ServiceTypes_by_pk?: Maybe<v1_ServiceTypes>;
  /** update multiples rows of table: "v1.ServiceTypes" */
  update_v1_ServiceTypes_many?: Maybe<Array<Maybe<v1_ServiceTypes_mutation_response>>>;
  /** update data of the table: "v1.SoftwareProviders" */
  update_v1_SoftwareProviders?: Maybe<v1_SoftwareProviders_mutation_response>;
  /** update single row of the table: "v1.SoftwareProviders" */
  update_v1_SoftwareProviders_by_pk?: Maybe<v1_SoftwareProviders>;
  /** update multiples rows of table: "v1.SoftwareProviders" */
  update_v1_SoftwareProviders_many?: Maybe<Array<Maybe<v1_SoftwareProviders_mutation_response>>>;
  /** update data of the table: "v1.StrategyFieldsSet" */
  update_v1_StrategyFieldsSet?: Maybe<v1_StrategyFieldsSet_mutation_response>;
  /** update single row of the table: "v1.StrategyFieldsSet" */
  update_v1_StrategyFieldsSet_by_pk?: Maybe<v1_StrategyFieldsSet>;
  /** update multiples rows of table: "v1.StrategyFieldsSet" */
  update_v1_StrategyFieldsSet_many?: Maybe<Array<Maybe<v1_StrategyFieldsSet_mutation_response>>>;
  /** update data of the table: "v1.Supplier_Tag_rel" */
  update_v1_Supplier_Tag_rel?: Maybe<v1_Supplier_Tag_rel_mutation_response>;
  /** update single row of the table: "v1.Supplier_Tag_rel" */
  update_v1_Supplier_Tag_rel_by_pk?: Maybe<v1_Supplier_Tag_rel>;
  /** update multiples rows of table: "v1.Supplier_Tag_rel" */
  update_v1_Supplier_Tag_rel_many?: Maybe<Array<Maybe<v1_Supplier_Tag_rel_mutation_response>>>;
  /** update data of the table: "v1.SystemUserBase" */
  update_v1_SystemUserBase?: Maybe<v1_SystemUserBase_mutation_response>;
  /** update single row of the table: "v1.SystemUserBase" */
  update_v1_SystemUserBase_by_pk?: Maybe<v1_SystemUserBase>;
  /** update multiples rows of table: "v1.SystemUserBase" */
  update_v1_SystemUserBase_many?: Maybe<Array<Maybe<v1_SystemUserBase_mutation_response>>>;
  /** update data of the table: "v1.Tags" */
  update_v1_Tags?: Maybe<v1_Tags_mutation_response>;
  /** update single row of the table: "v1.Tags" */
  update_v1_Tags_by_pk?: Maybe<v1_Tags>;
  /** update multiples rows of table: "v1.Tags" */
  update_v1_Tags_many?: Maybe<Array<Maybe<v1_Tags_mutation_response>>>;
  /** update data of the table: "v1.Teams" */
  update_v1_Teams?: Maybe<v1_Teams_mutation_response>;
  /** update single row of the table: "v1.Teams" */
  update_v1_Teams_by_pk?: Maybe<v1_Teams>;
  /** update multiples rows of table: "v1.Teams" */
  update_v1_Teams_many?: Maybe<Array<Maybe<v1_Teams_mutation_response>>>;
  /** update data of the table: "v1.TrackerDeal" */
  update_v1_TrackerDeal?: Maybe<v1_TrackerDeal_mutation_response>;
  /** update single row of the table: "v1.TrackerDeal" */
  update_v1_TrackerDeal_by_pk?: Maybe<v1_TrackerDeal>;
  /** update multiples rows of table: "v1.TrackerDeal" */
  update_v1_TrackerDeal_many?: Maybe<Array<Maybe<v1_TrackerDeal_mutation_response>>>;
  /** update data of the table: "v1.TrackerTags" */
  update_v1_TrackerTags?: Maybe<v1_TrackerTags_mutation_response>;
  /** update single row of the table: "v1.TrackerTags" */
  update_v1_TrackerTags_by_pk?: Maybe<v1_TrackerTags>;
  /** update multiples rows of table: "v1.TrackerTags" */
  update_v1_TrackerTags_many?: Maybe<Array<Maybe<v1_TrackerTags_mutation_response>>>;
  /** update data of the table: "v1.TrackerToTags" */
  update_v1_TrackerToTags?: Maybe<v1_TrackerToTags_mutation_response>;
  /** update single row of the table: "v1.TrackerToTags" */
  update_v1_TrackerToTags_by_pk?: Maybe<v1_TrackerToTags>;
  /** update multiples rows of table: "v1.TrackerToTags" */
  update_v1_TrackerToTags_many?: Maybe<Array<Maybe<v1_TrackerToTags_mutation_response>>>;
  /** update data of the table: "v1.TransactionCurrencyBase" */
  update_v1_TransactionCurrencyBase?: Maybe<v1_TransactionCurrencyBase_mutation_response>;
  /** update single row of the table: "v1.TransactionCurrencyBase" */
  update_v1_TransactionCurrencyBase_by_pk?: Maybe<v1_TransactionCurrencyBase>;
  /** update multiples rows of table: "v1.TransactionCurrencyBase" */
  update_v1_TransactionCurrencyBase_many?: Maybe<Array<Maybe<v1_TransactionCurrencyBase_mutation_response>>>;
  /** update data of the table: "v1.UsersStatus" */
  update_v1_UsersStatus?: Maybe<v1_UsersStatus_mutation_response>;
  /** update single row of the table: "v1.UsersStatus" */
  update_v1_UsersStatus_by_pk?: Maybe<v1_UsersStatus>;
  /** update multiples rows of table: "v1.UsersStatus" */
  update_v1_UsersStatus_many?: Maybe<Array<Maybe<v1_UsersStatus_mutation_response>>>;
  /** update data of the table: "v1.UsersToOffers" */
  update_v1_UsersToOffers?: Maybe<v1_UsersToOffers_mutation_response>;
  /** update single row of the table: "v1.UsersToOffers" */
  update_v1_UsersToOffers_by_pk?: Maybe<v1_UsersToOffers>;
  /** update multiples rows of table: "v1.UsersToOffers" */
  update_v1_UsersToOffers_many?: Maybe<Array<Maybe<v1_UsersToOffers_mutation_response>>>;
  /** update data of the table: "v1.Users_Roles" */
  update_v1_Users_Roles?: Maybe<v1_Users_Roles_mutation_response>;
  /** update single row of the table: "v1.Users_Roles" */
  update_v1_Users_Roles_by_pk?: Maybe<v1_Users_Roles>;
  /** update multiples rows of table: "v1.Users_Roles" */
  update_v1_Users_Roles_many?: Maybe<Array<Maybe<v1_Users_Roles_mutation_response>>>;
  /** update data of the table: "v1.Users_Teams" */
  update_v1_Users_Teams?: Maybe<v1_Users_Teams_mutation_response>;
  /** update single row of the table: "v1.Users_Teams" */
  update_v1_Users_Teams_by_pk?: Maybe<v1_Users_Teams>;
  /** update multiples rows of table: "v1.Users_Teams" */
  update_v1_Users_Teams_many?: Maybe<Array<Maybe<v1_Users_Teams_mutation_response>>>;
  /** update data of the table: "v1.WageringRequirementsLocation" */
  update_v1_WageringRequirementsLocation?: Maybe<v1_WageringRequirementsLocation_mutation_response>;
  /** update single row of the table: "v1.WageringRequirementsLocation" */
  update_v1_WageringRequirementsLocation_by_pk?: Maybe<v1_WageringRequirementsLocation>;
  /** update multiples rows of table: "v1.WageringRequirementsLocation" */
  update_v1_WageringRequirementsLocation_many?: Maybe<Array<Maybe<v1_WageringRequirementsLocation_mutation_response>>>;
  /** update data of the table: "v1.Website" */
  update_v1_Website?: Maybe<v1_Website_mutation_response>;
  /** update data of the table: "v1.WebsiteBlogProbability" */
  update_v1_WebsiteBlogProbability?: Maybe<v1_WebsiteBlogProbability_mutation_response>;
  /** update single row of the table: "v1.WebsiteBlogProbability" */
  update_v1_WebsiteBlogProbability_by_pk?: Maybe<v1_WebsiteBlogProbability>;
  /** update multiples rows of table: "v1.WebsiteBlogProbability" */
  update_v1_WebsiteBlogProbability_many?: Maybe<Array<Maybe<v1_WebsiteBlogProbability_mutation_response>>>;
  /** update data of the table: "v1.WebsiteContentIndicator" */
  update_v1_WebsiteContentIndicator?: Maybe<v1_WebsiteContentIndicator_mutation_response>;
  /** update single row of the table: "v1.WebsiteContentIndicator" */
  update_v1_WebsiteContentIndicator_by_pk?: Maybe<v1_WebsiteContentIndicator>;
  /** update multiples rows of table: "v1.WebsiteContentIndicator" */
  update_v1_WebsiteContentIndicator_many?: Maybe<Array<Maybe<v1_WebsiteContentIndicator_mutation_response>>>;
  /** update data of the table: "v1.WebsiteDToxRisk" */
  update_v1_WebsiteDToxRisk?: Maybe<v1_WebsiteDToxRisk_mutation_response>;
  /** update single row of the table: "v1.WebsiteDToxRisk" */
  update_v1_WebsiteDToxRisk_by_pk?: Maybe<v1_WebsiteDToxRisk>;
  /** update multiples rows of table: "v1.WebsiteDToxRisk" */
  update_v1_WebsiteDToxRisk_many?: Maybe<Array<Maybe<v1_WebsiteDToxRisk_mutation_response>>>;
  /** update data of the table: "v1.WebsiteQualityScore" */
  update_v1_WebsiteQualityScore?: Maybe<v1_WebsiteQualityScore_mutation_response>;
  /** update single row of the table: "v1.WebsiteQualityScore" */
  update_v1_WebsiteQualityScore_by_pk?: Maybe<v1_WebsiteQualityScore>;
  /** update multiples rows of table: "v1.WebsiteQualityScore" */
  update_v1_WebsiteQualityScore_many?: Maybe<Array<Maybe<v1_WebsiteQualityScore_mutation_response>>>;
  /** update data of the table: "v1.WebsiteStatus" */
  update_v1_WebsiteStatus?: Maybe<v1_WebsiteStatus_mutation_response>;
  /** update single row of the table: "v1.WebsiteStatus" */
  update_v1_WebsiteStatus_by_pk?: Maybe<v1_WebsiteStatus>;
  /** update multiples rows of table: "v1.WebsiteStatus" */
  update_v1_WebsiteStatus_many?: Maybe<Array<Maybe<v1_WebsiteStatus_mutation_response>>>;
  /** update data of the table: "v1.WebsiteTopics" */
  update_v1_WebsiteTopics?: Maybe<v1_WebsiteTopics_mutation_response>;
  /** update single row of the table: "v1.WebsiteTopics" */
  update_v1_WebsiteTopics_by_pk?: Maybe<v1_WebsiteTopics>;
  /** update multiples rows of table: "v1.WebsiteTopics" */
  update_v1_WebsiteTopics_many?: Maybe<Array<Maybe<v1_WebsiteTopics_mutation_response>>>;
  /** update data of the table: "v1.WebsiteUptimeStatus" */
  update_v1_WebsiteUptimeStatus?: Maybe<v1_WebsiteUptimeStatus_mutation_response>;
  /** update single row of the table: "v1.WebsiteUptimeStatus" */
  update_v1_WebsiteUptimeStatus_by_pk?: Maybe<v1_WebsiteUptimeStatus>;
  /** update multiples rows of table: "v1.WebsiteUptimeStatus" */
  update_v1_WebsiteUptimeStatus_many?: Maybe<Array<Maybe<v1_WebsiteUptimeStatus_mutation_response>>>;
  /** update data of the table: "v1.Website_Platform" */
  update_v1_Website_Platform?: Maybe<v1_Website_Platform_mutation_response>;
  /** update single row of the table: "v1.Website_Platform" */
  update_v1_Website_Platform_by_pk?: Maybe<v1_Website_Platform>;
  /** update multiples rows of table: "v1.Website_Platform" */
  update_v1_Website_Platform_many?: Maybe<Array<Maybe<v1_Website_Platform_mutation_response>>>;
  /** update single row of the table: "v1.Website" */
  update_v1_Website_by_pk?: Maybe<v1_Website>;
  /** update multiples rows of table: "v1.Website" */
  update_v1_Website_many?: Maybe<Array<Maybe<v1_Website_mutation_response>>>;
  /** update data of the table: "v1.WebsitesToTopics" */
  update_v1_WebsitesToTopics?: Maybe<v1_WebsitesToTopics_mutation_response>;
  /** update single row of the table: "v1.WebsitesToTopics" */
  update_v1_WebsitesToTopics_by_pk?: Maybe<v1_WebsitesToTopics>;
  /** update multiples rows of table: "v1.WebsitesToTopics" */
  update_v1_WebsitesToTopics_many?: Maybe<Array<Maybe<v1_WebsitesToTopics_mutation_response>>>;
  /** update data of the table: "v1.new_POBase" */
  update_v1_new_POBase?: Maybe<v1_new_POBase_mutation_response>;
  /** update single row of the table: "v1.new_POBase" */
  update_v1_new_POBase_by_pk?: Maybe<v1_new_POBase>;
  /** update multiples rows of table: "v1.new_POBase" */
  update_v1_new_POBase_many?: Maybe<Array<Maybe<v1_new_POBase_mutation_response>>>;
  /** update data of the table: "v1.new_affiliateincomereportBase" */
  update_v1_new_affiliateincomereportBase?: Maybe<v1_new_affiliateincomereportBase_mutation_response>;
  /** update single row of the table: "v1.new_affiliateincomereportBase" */
  update_v1_new_affiliateincomereportBase_by_pk?: Maybe<v1_new_affiliateincomereportBase>;
  /** update multiples rows of table: "v1.new_affiliateincomereportBase" */
  update_v1_new_affiliateincomereportBase_many?: Maybe<Array<Maybe<v1_new_affiliateincomereportBase_mutation_response>>>;
  /** update data of the table: "v1.new_affordertypeBase" */
  update_v1_new_affordertypeBase?: Maybe<v1_new_affordertypeBase_mutation_response>;
  /** update single row of the table: "v1.new_affordertypeBase" */
  update_v1_new_affordertypeBase_by_pk?: Maybe<v1_new_affordertypeBase>;
  /** update multiples rows of table: "v1.new_affordertypeBase" */
  update_v1_new_affordertypeBase_many?: Maybe<Array<Maybe<v1_new_affordertypeBase_mutation_response>>>;
  /** update data of the table: "v1.new_brandBase" */
  update_v1_new_brandBase?: Maybe<v1_new_brandBase_mutation_response>;
  /** update single row of the table: "v1.new_brandBase" */
  update_v1_new_brandBase_by_pk?: Maybe<v1_new_brandBase>;
  /** update multiples rows of table: "v1.new_brandBase" */
  update_v1_new_brandBase_many?: Maybe<Array<Maybe<v1_new_brandBase_mutation_response>>>;
  /** update data of the table: "v1.new_countryBase" */
  update_v1_new_countryBase?: Maybe<v1_new_countryBase_mutation_response>;
  /** update multiples rows of table: "v1.new_countryBase" */
  update_v1_new_countryBase_many?: Maybe<Array<Maybe<v1_new_countryBase_mutation_response>>>;
  /** update data of the table: "v1.new_languagesBase" */
  update_v1_new_languagesBase?: Maybe<v1_new_languagesBase_mutation_response>;
  /** update single row of the table: "v1.new_languagesBase" */
  update_v1_new_languagesBase_by_pk?: Maybe<v1_new_languagesBase>;
  /** update multiples rows of table: "v1.new_languagesBase" */
  update_v1_new_languagesBase_many?: Maybe<Array<Maybe<v1_new_languagesBase_mutation_response>>>;
  /** update data of the table: "v1.new_new_brand_new_countryBase" */
  update_v1_new_new_brand_new_countryBase?: Maybe<v1_new_new_brand_new_countryBase_mutation_response>;
  /** update single row of the table: "v1.new_new_brand_new_countryBase" */
  update_v1_new_new_brand_new_countryBase_by_pk?: Maybe<v1_new_new_brand_new_countryBase>;
  /** update multiples rows of table: "v1.new_new_brand_new_countryBase" */
  update_v1_new_new_brand_new_countryBase_many?: Maybe<Array<Maybe<v1_new_new_brand_new_countryBase_mutation_response>>>;
  /** update data of the table: "v1.new_paymentmethodBase" */
  update_v1_new_paymentmethodBase?: Maybe<v1_new_paymentmethodBase_mutation_response>;
  /** update single row of the table: "v1.new_paymentmethodBase" */
  update_v1_new_paymentmethodBase_by_pk?: Maybe<v1_new_paymentmethodBase>;
  /** update multiples rows of table: "v1.new_paymentmethodBase" */
  update_v1_new_paymentmethodBase_many?: Maybe<Array<Maybe<v1_new_paymentmethodBase_mutation_response>>>;
  /** update data of the table: "v1.new_receiptBase" */
  update_v1_new_receiptBase?: Maybe<v1_new_receiptBase_mutation_response>;
  /** update single row of the table: "v1.new_receiptBase" */
  update_v1_new_receiptBase_by_pk?: Maybe<v1_new_receiptBase>;
  /** update multiples rows of table: "v1.new_receiptBase" */
  update_v1_new_receiptBase_many?: Maybe<Array<Maybe<v1_new_receiptBase_mutation_response>>>;
  /** update data of the table: "v1.new_regemailBase" */
  update_v1_new_regemailBase?: Maybe<v1_new_regemailBase_mutation_response>;
  /** update single row of the table: "v1.new_regemailBase" */
  update_v1_new_regemailBase_by_pk?: Maybe<v1_new_regemailBase>;
  /** update multiples rows of table: "v1.new_regemailBase" */
  update_v1_new_regemailBase_many?: Maybe<Array<Maybe<v1_new_regemailBase_mutation_response>>>;
  /** update data of the table: "v1.new_trackerBase" */
  update_v1_new_trackerBase?: Maybe<v1_new_trackerBase_mutation_response>;
  /** update single row of the table: "v1.new_trackerBase" */
  update_v1_new_trackerBase_by_pk?: Maybe<v1_new_trackerBase>;
  /** update multiples rows of table: "v1.new_trackerBase" */
  update_v1_new_trackerBase_many?: Maybe<Array<Maybe<v1_new_trackerBase_mutation_response>>>;
  /** update data of the table: "v1.process" */
  update_v1_process?: Maybe<v1_process_mutation_response>;
  /** update single row of the table: "v1.process" */
  update_v1_process_by_pk?: Maybe<v1_process>;
  /** update multiples rows of table: "v1.process" */
  update_v1_process_many?: Maybe<Array<Maybe<v1_process_mutation_response>>>;
  /** update data of the table: "v1.process_rate" */
  update_v1_process_rate?: Maybe<v1_process_rate_mutation_response>;
  /** update single row of the table: "v1.process_rate" */
  update_v1_process_rate_by_pk?: Maybe<v1_process_rate>;
  /** update multiples rows of table: "v1.process_rate" */
  update_v1_process_rate_many?: Maybe<Array<Maybe<v1_process_rate_mutation_response>>>;
  /** update data of the table: "v1.project_topic" */
  update_v1_project_topic?: Maybe<v1_project_topic_mutation_response>;
  /** update single row of the table: "v1.project_topic" */
  update_v1_project_topic_by_pk?: Maybe<v1_project_topic>;
  /** update multiples rows of table: "v1.project_topic" */
  update_v1_project_topic_many?: Maybe<Array<Maybe<v1_project_topic_mutation_response>>>;
};


/** mutation root */
export type mutation_rootaddLivePostsArgs = {
  posts: Array<InputMaybe<LivePostInput>>;
  user: UserInputLivePosts;
};


/** mutation root */
export type mutation_rootbulkPostLinkCheckArgs = {
  posts: Array<InputMaybe<PLCPostsInput>>;
  user: UserInput;
};


/** mutation root */
export type mutation_rootcheckCopyScapeArgs = {
  postId: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_AccountBaseArgs = {
  where: v1_AccountBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_AccountBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_AffDealTypeArgs = {
  where: v1_AffDealType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_AffDealType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_AffiliateOrderCategoriesPriorityArgs = {
  where: v1_AffiliateOrderCategoriesPriority_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_AffiliateOrderCategoriesPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_AffiliateProgramTypeArgs = {
  where: v1_AffiliateProgramType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_AffiliateProgramType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_AllowedPlayersArgs = {
  where: v1_AllowedPlayers_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_AllowedPlayers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ArticleTonesArgs = {
  where: v1_ArticleTones_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ArticleTones_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BalanceArgs = {
  where: v1_Balance_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BalanceActivityTypeArgs = {
  where: v1_BalanceActivityType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BalanceActivityType_by_pkArgs = {
  Name: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Balance_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BillingInformationArgs = {
  where: v1_BillingInformation_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BillingInformation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BillingMethodArgs = {
  where: v1_BillingMethod_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BillingMethod_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BonusSourceArgs = {
  where: v1_BonusSource_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BonusSource_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BonusStatusArgs = {
  where: v1_BonusStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BonusStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BonusSubmitterArgs = {
  where: v1_BonusSubmitter_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BonusSubmitter_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BonusTypesArgs = {
  where: v1_BonusTypes_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BonusTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BonusesArgs = {
  where: v1_Bonuses_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BonusesToCountriesArgs = {
  where: v1_BonusesToCountries_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BonusesToCountries_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BonusesToCurrenciesArgs = {
  where: v1_BonusesToCurrencies_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BonusesToCurrencies_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BonusesToTypesArgs = {
  where: v1_BonusesToTypes_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BonusesToTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Bonuses_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BonusestoGamesArgs = {
  where: v1_BonusestoGames_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BonusestoGames_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BrandAliasArgs = {
  where: v1_BrandAlias_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BrandAlias_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_BrandPriorityArgs = {
  where: v1_BrandPriority_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_BrandPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CampaignArgs = {
  where: v1_Campaign_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_CampaignPriorityArgs = {
  where: v1_CampaignPriority_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_CampaignPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CampaignStatusArgs = {
  where: v1_CampaignStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_CampaignStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CampaignStrategyArgs = {
  where: v1_CampaignStrategy_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_CampaignStrategy_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CampaignTypeArgs = {
  where: v1_CampaignType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_CampaignType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Campaign_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CategoriesArgs = {
  where: v1_Categories_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Categories_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CheckoutArgs = {
  where: v1_Checkout_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_CheckoutDetailArgs = {
  where: v1_CheckoutDetail_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_CheckoutDetail_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CheckoutStatusArgs = {
  where: v1_CheckoutStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_CheckoutStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Checkout_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CitiesArgs = {
  where: v1_Cities_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Cities_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ClicksByTrackersArgs = {
  where: v1_ClicksByTrackers_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ClicksByTrackersNewArgs = {
  where: v1_ClicksByTrackersNew_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ClicksByTrackersNew_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ClicksByTrackers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CompanyTypeArgs = {
  where: v1_CompanyType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_CompanyType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ConfigurationsArgs = {
  where: v1_Configurations_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Configurations_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ContactBaseArgs = {
  where: v1_ContactBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ContactBasePaymentOptionArgs = {
  where: v1_ContactBasePaymentOption_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ContactBasePaymentOption_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ContactBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ContactTypeArgs = {
  where: v1_ContactType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ContactType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Contacts_RolesArgs = {
  where: v1_Contacts_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Contacts_Roles_by_pkArgs = {
  contact_id: Scalars['Int']['input'];
  role_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ContentLinkArgs = {
  where: v1_ContentLink_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ContentLink_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ContentTypesArgs = {
  where: v1_ContentTypes_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ContentTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Credential_RolesArgs = {
  where: v1_Credential_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Credential_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_CredentialsArgs = {
  where: v1_Credentials_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Credentials_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_DomainExtensionsArgs = {
  where: v1_DomainExtensions_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_DomainExtensions_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_EventsArgs = {
  where: v1_Events_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Events_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ExpiringRecPaymentsArgs = {
  where: v1_ExpiringRecPayments_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_FilesArgs = {
  where: v1_Files_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Files_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_GameTypeArgs = {
  where: v1_GameType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_GameType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_InvoiceBaseArgs = {
  where: v1_InvoiceBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_InvoiceBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_InvoiceDetailBaseArgs = {
  where: v1_InvoiceDetailBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_InvoiceDetailBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_InvoiceStatusArgs = {
  where: v1_InvoiceStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_InvoiceStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_LandingPagePriorityArgs = {
  where: v1_LandingPagePriority_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_LandingPagePriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_LandingPagesArgs = {
  where: v1_LandingPages_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_LandingPages_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_LanguagePriceStepArgs = {
  where: v1_LanguagePriceStep_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_LanguagePriceStep_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceCategoriesArgs = {
  where: v1_MarketPlaceCategories_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceCategoriesTypesArgs = {
  where: v1_MarketPlaceCategoriesTypes_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceCategoriesTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceCategories_OffersArgs = {
  where: v1_MarketPlaceCategories_Offers_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceCategories_Offers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceCategories_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceOfferNicheArgs = {
  where: v1_MarketPlaceOfferNiche_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceOfferNiche_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceOrderArgs = {
  where: v1_MarketPlaceOrder_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceOrderRequestArgs = {
  where: v1_MarketPlaceOrderRequest_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceOrderRequest_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceOrderStatusArgs = {
  where: v1_MarketPlaceOrderStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceOrderStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceOrder_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceRegisterStatusArgs = {
  where: v1_MarketPlaceRegisterStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketPlaceRegisterStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketTypeArgs = {
  where: v1_MarketType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketplaceProjectsArgs = {
  where: v1_MarketplaceProjects_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketplaceProjects_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MarketplaceStatusArgs = {
  where: v1_MarketplaceStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_MarketplaceStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_MonthsArgs = {
  where: v1_Months_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Months_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_OfferArgs = {
  where: v1_Offer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_OfferStatusArgs = {
  where: v1_OfferStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_OfferStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_OfferTagsArgs = {
  where: v1_OfferTags_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_OfferTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_OfferToTagsArgs = {
  where: v1_OfferToTags_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_OfferToTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Offer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_OrderForArgs = {
  where: v1_OrderFor_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_OrderFor_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_OrderPayToArgs = {
  where: v1_OrderPayTo_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_OrderPayTo_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_OrderPriorityArgs = {
  where: v1_OrderPriority_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_OrderPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_OrderTypeArgs = {
  where: v1_OrderType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_OrderType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PageArgs = {
  where: v1_Page_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Page_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PaymentCompaniesArgs = {
  where: v1_PaymentCompanies_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PaymentCompanies_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PaymentFrequencyArgs = {
  where: v1_PaymentFrequency_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PaymentFrequency_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PoToPostsArgs = {
  where: v1_PoToPosts_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PoToPosts_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostDurationArgs = {
  where: v1_PostDuration_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostDuration_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostNicheArgs = {
  where: v1_PostNiche_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostNiche_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsArgs = {
  where: v1_Posts_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsAnchorTextTypeArgs = {
  where: v1_PostsAnchorTextType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsAnchorTextType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsContentStatusArgs = {
  where: v1_PostsContentStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsContentStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsCopyScapeArgs = {
  where: v1_PostsCopyScape_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsCopyScape_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsLinkStatusArgs = {
  where: v1_PostsLinkStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsLinkStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsOnlineStatusArgs = {
  where: v1_PostsOnlineStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsOnlineStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsOutreachStatusArgs = {
  where: v1_PostsOutreachStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsOutreachStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsPageTypeDeprecatedArgs = {
  where: v1_PostsPageTypeDeprecated_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsPageTypeDeprecated_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsRemovalStatusArgs = {
  where: v1_PostsRemovalStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsRemovalStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsSEOApprovalArgs = {
  where: v1_PostsSEOApproval_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsSEOApproval_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsSEODeclineReasonArgs = {
  where: v1_PostsSEODeclineReason_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsSEODeclineReason_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsStatusArgs = {
  where: v1_PostsStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsStatusCodeArgs = {
  where: v1_PostsStatusCode_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PostsStatusCode_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PostsStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Posts_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PreferencesArgs = {
  where: v1_Preferences_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PreferencesMarketplaceArgs = {
  where: v1_PreferencesMarketplace_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PreferencesMarketplace_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Preferences_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ProjectArgs = {
  where: v1_Project_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ProjectImportanceArgs = {
  where: v1_ProjectImportance_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ProjectImportance_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ProjectStatusArgs = {
  where: v1_ProjectStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ProjectStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ProjectTopicArgs = {
  where: v1_ProjectTopic_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ProjectTopic_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Project_CountriesArgs = {
  where: v1_Project_Countries_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Project_Countries_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Project_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PublisherPostStatusArgs = {
  where: v1_PublisherPostStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PublisherPostStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_PurchaseOrderStatusArgs = {
  where: v1_PurchaseOrderStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_PurchaseOrderStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ReceiptStatusArgs = {
  where: v1_ReceiptStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ReceiptStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_RecurringPaymentStatusArgs = {
  where: v1_RecurringPaymentStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_RecurringPaymentStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_RecurringPaymentsArgs = {
  where: v1_RecurringPayments_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_RecurringPayments_RolesArgs = {
  where: v1_RecurringPayments_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_RecurringPayments_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_RecurringPayments_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ReportArgs = {
  where: v1_Report_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Report_RolesArgs = {
  where: v1_Report_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Report_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Report_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_RestrictedNichesToOfferArgs = {
  where: v1_RestrictedNichesToOffer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_RestrictedNichesToOffer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_RolesArgs = {
  where: v1_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_SalesOrderBaseArgs = {
  where: v1_SalesOrderBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_SalesOrderBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_ServiceTypesArgs = {
  where: v1_ServiceTypes_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_ServiceTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_SoftwareProvidersArgs = {
  where: v1_SoftwareProviders_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_SoftwareProviders_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_StrategyFieldsSetArgs = {
  where: v1_StrategyFieldsSet_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_StrategyFieldsSet_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Supplier_Tag_relArgs = {
  where: v1_Supplier_Tag_rel_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Supplier_Tag_rel_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_SystemUserBaseArgs = {
  where: v1_SystemUserBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_SystemUserBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_TagsArgs = {
  where: v1_Tags_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Tags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_TeamsArgs = {
  where: v1_Teams_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Teams_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_TrackerDealArgs = {
  where: v1_TrackerDeal_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_TrackerDeal_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_TrackerTagsArgs = {
  where: v1_TrackerTags_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_TrackerTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_TrackerToTagsArgs = {
  where: v1_TrackerToTags_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_TrackerToTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_TransactionCurrencyBaseArgs = {
  where: v1_TransactionCurrencyBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_TransactionCurrencyBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_UsersStatusArgs = {
  where: v1_UsersStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_UsersStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_UsersToOffersArgs = {
  where: v1_UsersToOffers_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_UsersToOffers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Users_RolesArgs = {
  where: v1_Users_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Users_Roles_by_pkArgs = {
  role_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Users_TeamsArgs = {
  where: v1_Users_Teams_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Users_Teams_by_pkArgs = {
  team_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_WageringRequirementsLocationArgs = {
  where: v1_WageringRequirementsLocation_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WageringRequirementsLocation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteArgs = {
  where: v1_Website_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteBlogProbabilityArgs = {
  where: v1_WebsiteBlogProbability_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteBlogProbability_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteContentIndicatorArgs = {
  where: v1_WebsiteContentIndicator_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteContentIndicator_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteDToxRiskArgs = {
  where: v1_WebsiteDToxRisk_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteDToxRisk_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteQualityScoreArgs = {
  where: v1_WebsiteQualityScore_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteQualityScore_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteStatusArgs = {
  where: v1_WebsiteStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteTopicsArgs = {
  where: v1_WebsiteTopics_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteTopics_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteUptimeStatusArgs = {
  where: v1_WebsiteUptimeStatus_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WebsiteUptimeStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Website_PlatformArgs = {
  where: v1_Website_Platform_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_Website_Platform_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_Website_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_WebsitesToTopicsArgs = {
  where: v1_WebsitesToTopics_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_WebsitesToTopics_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_POBaseArgs = {
  where: v1_new_POBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_POBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_affiliateincomereportBaseArgs = {
  where: v1_new_affiliateincomereportBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_affiliateincomereportBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_affordertypeBaseArgs = {
  where: v1_new_affordertypeBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_affordertypeBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_brandBaseArgs = {
  where: v1_new_brandBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_brandBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_countryBaseArgs = {
  where: v1_new_countryBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_languagesBaseArgs = {
  where: v1_new_languagesBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_languagesBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_new_brand_new_countryBaseArgs = {
  where: v1_new_new_brand_new_countryBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_new_brand_new_countryBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_paymentmethodBaseArgs = {
  where: v1_new_paymentmethodBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_paymentmethodBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_receiptBaseArgs = {
  where: v1_new_receiptBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_receiptBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_regemailBaseArgs = {
  where: v1_new_regemailBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_regemailBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_new_trackerBaseArgs = {
  where: v1_new_trackerBase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_new_trackerBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_processArgs = {
  where: v1_process_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_process_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_process_rateArgs = {
  where: v1_process_rate_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_process_rate_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_v1_project_topicArgs = {
  where: v1_project_topic_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_v1_project_topic_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootimportWebsitesStatisticsArgs = {
  websites: Array<InputMaybe<WebsiteStatisticsInput>>;
};


/** mutation root */
export type mutation_rootinsertPostsArgs = {
  posts: Array<InputMaybe<insertPostInput>>;
};


/** mutation root */
export type mutation_rootinsert_v1_AccountBaseArgs = {
  objects: Array<v1_AccountBase_insert_input>;
  on_conflict?: InputMaybe<v1_AccountBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_AccountBase_oneArgs = {
  object: v1_AccountBase_insert_input;
  on_conflict?: InputMaybe<v1_AccountBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_AffDealTypeArgs = {
  objects: Array<v1_AffDealType_insert_input>;
  on_conflict?: InputMaybe<v1_AffDealType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_AffDealType_oneArgs = {
  object: v1_AffDealType_insert_input;
  on_conflict?: InputMaybe<v1_AffDealType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_AffiliateOrderCategoriesPriorityArgs = {
  objects: Array<v1_AffiliateOrderCategoriesPriority_insert_input>;
  on_conflict?: InputMaybe<v1_AffiliateOrderCategoriesPriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_AffiliateOrderCategoriesPriority_oneArgs = {
  object: v1_AffiliateOrderCategoriesPriority_insert_input;
  on_conflict?: InputMaybe<v1_AffiliateOrderCategoriesPriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_AffiliateProgramTypeArgs = {
  objects: Array<v1_AffiliateProgramType_insert_input>;
  on_conflict?: InputMaybe<v1_AffiliateProgramType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_AffiliateProgramType_oneArgs = {
  object: v1_AffiliateProgramType_insert_input;
  on_conflict?: InputMaybe<v1_AffiliateProgramType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_AllowedPlayersArgs = {
  objects: Array<v1_AllowedPlayers_insert_input>;
  on_conflict?: InputMaybe<v1_AllowedPlayers_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_AllowedPlayers_oneArgs = {
  object: v1_AllowedPlayers_insert_input;
  on_conflict?: InputMaybe<v1_AllowedPlayers_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ArticleTonesArgs = {
  objects: Array<v1_ArticleTones_insert_input>;
  on_conflict?: InputMaybe<v1_ArticleTones_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ArticleTones_oneArgs = {
  object: v1_ArticleTones_insert_input;
  on_conflict?: InputMaybe<v1_ArticleTones_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BalanceArgs = {
  objects: Array<v1_Balance_insert_input>;
  on_conflict?: InputMaybe<v1_Balance_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BalanceActivityTypeArgs = {
  objects: Array<v1_BalanceActivityType_insert_input>;
  on_conflict?: InputMaybe<v1_BalanceActivityType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BalanceActivityType_oneArgs = {
  object: v1_BalanceActivityType_insert_input;
  on_conflict?: InputMaybe<v1_BalanceActivityType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Balance_oneArgs = {
  object: v1_Balance_insert_input;
  on_conflict?: InputMaybe<v1_Balance_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BillingInformationArgs = {
  objects: Array<v1_BillingInformation_insert_input>;
  on_conflict?: InputMaybe<v1_BillingInformation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BillingInformation_oneArgs = {
  object: v1_BillingInformation_insert_input;
  on_conflict?: InputMaybe<v1_BillingInformation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BillingMethodArgs = {
  objects: Array<v1_BillingMethod_insert_input>;
  on_conflict?: InputMaybe<v1_BillingMethod_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BillingMethod_oneArgs = {
  object: v1_BillingMethod_insert_input;
  on_conflict?: InputMaybe<v1_BillingMethod_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusSourceArgs = {
  objects: Array<v1_BonusSource_insert_input>;
  on_conflict?: InputMaybe<v1_BonusSource_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusSource_oneArgs = {
  object: v1_BonusSource_insert_input;
  on_conflict?: InputMaybe<v1_BonusSource_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusStatusArgs = {
  objects: Array<v1_BonusStatus_insert_input>;
  on_conflict?: InputMaybe<v1_BonusStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusStatus_oneArgs = {
  object: v1_BonusStatus_insert_input;
  on_conflict?: InputMaybe<v1_BonusStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusSubmitterArgs = {
  objects: Array<v1_BonusSubmitter_insert_input>;
  on_conflict?: InputMaybe<v1_BonusSubmitter_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusSubmitter_oneArgs = {
  object: v1_BonusSubmitter_insert_input;
  on_conflict?: InputMaybe<v1_BonusSubmitter_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusTypesArgs = {
  objects: Array<v1_BonusTypes_insert_input>;
  on_conflict?: InputMaybe<v1_BonusTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusTypes_oneArgs = {
  object: v1_BonusTypes_insert_input;
  on_conflict?: InputMaybe<v1_BonusTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusesArgs = {
  objects: Array<v1_Bonuses_insert_input>;
  on_conflict?: InputMaybe<v1_Bonuses_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusesToCountriesArgs = {
  objects: Array<v1_BonusesToCountries_insert_input>;
  on_conflict?: InputMaybe<v1_BonusesToCountries_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusesToCountries_oneArgs = {
  object: v1_BonusesToCountries_insert_input;
  on_conflict?: InputMaybe<v1_BonusesToCountries_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusesToCurrenciesArgs = {
  objects: Array<v1_BonusesToCurrencies_insert_input>;
  on_conflict?: InputMaybe<v1_BonusesToCurrencies_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusesToCurrencies_oneArgs = {
  object: v1_BonusesToCurrencies_insert_input;
  on_conflict?: InputMaybe<v1_BonusesToCurrencies_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusesToTypesArgs = {
  objects: Array<v1_BonusesToTypes_insert_input>;
  on_conflict?: InputMaybe<v1_BonusesToTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusesToTypes_oneArgs = {
  object: v1_BonusesToTypes_insert_input;
  on_conflict?: InputMaybe<v1_BonusesToTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Bonuses_oneArgs = {
  object: v1_Bonuses_insert_input;
  on_conflict?: InputMaybe<v1_Bonuses_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusestoGamesArgs = {
  objects: Array<v1_BonusestoGames_insert_input>;
  on_conflict?: InputMaybe<v1_BonusestoGames_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BonusestoGames_oneArgs = {
  object: v1_BonusestoGames_insert_input;
  on_conflict?: InputMaybe<v1_BonusestoGames_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BrandAliasArgs = {
  objects: Array<v1_BrandAlias_insert_input>;
  on_conflict?: InputMaybe<v1_BrandAlias_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BrandAlias_oneArgs = {
  object: v1_BrandAlias_insert_input;
  on_conflict?: InputMaybe<v1_BrandAlias_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BrandPriorityArgs = {
  objects: Array<v1_BrandPriority_insert_input>;
  on_conflict?: InputMaybe<v1_BrandPriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_BrandPriority_oneArgs = {
  object: v1_BrandPriority_insert_input;
  on_conflict?: InputMaybe<v1_BrandPriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CampaignArgs = {
  objects: Array<v1_Campaign_insert_input>;
  on_conflict?: InputMaybe<v1_Campaign_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CampaignPriorityArgs = {
  objects: Array<v1_CampaignPriority_insert_input>;
  on_conflict?: InputMaybe<v1_CampaignPriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CampaignPriority_oneArgs = {
  object: v1_CampaignPriority_insert_input;
  on_conflict?: InputMaybe<v1_CampaignPriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CampaignStatusArgs = {
  objects: Array<v1_CampaignStatus_insert_input>;
  on_conflict?: InputMaybe<v1_CampaignStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CampaignStatus_oneArgs = {
  object: v1_CampaignStatus_insert_input;
  on_conflict?: InputMaybe<v1_CampaignStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CampaignStrategyArgs = {
  objects: Array<v1_CampaignStrategy_insert_input>;
  on_conflict?: InputMaybe<v1_CampaignStrategy_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CampaignStrategy_oneArgs = {
  object: v1_CampaignStrategy_insert_input;
  on_conflict?: InputMaybe<v1_CampaignStrategy_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CampaignTypeArgs = {
  objects: Array<v1_CampaignType_insert_input>;
  on_conflict?: InputMaybe<v1_CampaignType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CampaignType_oneArgs = {
  object: v1_CampaignType_insert_input;
  on_conflict?: InputMaybe<v1_CampaignType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Campaign_oneArgs = {
  object: v1_Campaign_insert_input;
  on_conflict?: InputMaybe<v1_Campaign_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CategoriesArgs = {
  objects: Array<v1_Categories_insert_input>;
  on_conflict?: InputMaybe<v1_Categories_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Categories_oneArgs = {
  object: v1_Categories_insert_input;
  on_conflict?: InputMaybe<v1_Categories_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CheckoutArgs = {
  objects: Array<v1_Checkout_insert_input>;
  on_conflict?: InputMaybe<v1_Checkout_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CheckoutDetailArgs = {
  objects: Array<v1_CheckoutDetail_insert_input>;
  on_conflict?: InputMaybe<v1_CheckoutDetail_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CheckoutDetail_oneArgs = {
  object: v1_CheckoutDetail_insert_input;
  on_conflict?: InputMaybe<v1_CheckoutDetail_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CheckoutStatusArgs = {
  objects: Array<v1_CheckoutStatus_insert_input>;
  on_conflict?: InputMaybe<v1_CheckoutStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CheckoutStatus_oneArgs = {
  object: v1_CheckoutStatus_insert_input;
  on_conflict?: InputMaybe<v1_CheckoutStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Checkout_oneArgs = {
  object: v1_Checkout_insert_input;
  on_conflict?: InputMaybe<v1_Checkout_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CitiesArgs = {
  objects: Array<v1_Cities_insert_input>;
  on_conflict?: InputMaybe<v1_Cities_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Cities_oneArgs = {
  object: v1_Cities_insert_input;
  on_conflict?: InputMaybe<v1_Cities_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ClicksByTrackersArgs = {
  objects: Array<v1_ClicksByTrackers_insert_input>;
  on_conflict?: InputMaybe<v1_ClicksByTrackers_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ClicksByTrackersNewArgs = {
  objects: Array<v1_ClicksByTrackersNew_insert_input>;
  on_conflict?: InputMaybe<v1_ClicksByTrackersNew_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ClicksByTrackersNew_oneArgs = {
  object: v1_ClicksByTrackersNew_insert_input;
  on_conflict?: InputMaybe<v1_ClicksByTrackersNew_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ClicksByTrackers_oneArgs = {
  object: v1_ClicksByTrackers_insert_input;
  on_conflict?: InputMaybe<v1_ClicksByTrackers_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CompanyTypeArgs = {
  objects: Array<v1_CompanyType_insert_input>;
  on_conflict?: InputMaybe<v1_CompanyType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CompanyType_oneArgs = {
  object: v1_CompanyType_insert_input;
  on_conflict?: InputMaybe<v1_CompanyType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ConfigurationsArgs = {
  objects: Array<v1_Configurations_insert_input>;
  on_conflict?: InputMaybe<v1_Configurations_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Configurations_oneArgs = {
  object: v1_Configurations_insert_input;
  on_conflict?: InputMaybe<v1_Configurations_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContactBaseArgs = {
  objects: Array<v1_ContactBase_insert_input>;
  on_conflict?: InputMaybe<v1_ContactBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContactBasePaymentOptionArgs = {
  objects: Array<v1_ContactBasePaymentOption_insert_input>;
  on_conflict?: InputMaybe<v1_ContactBasePaymentOption_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContactBasePaymentOption_oneArgs = {
  object: v1_ContactBasePaymentOption_insert_input;
  on_conflict?: InputMaybe<v1_ContactBasePaymentOption_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContactBase_oneArgs = {
  object: v1_ContactBase_insert_input;
  on_conflict?: InputMaybe<v1_ContactBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContactTypeArgs = {
  objects: Array<v1_ContactType_insert_input>;
  on_conflict?: InputMaybe<v1_ContactType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContactType_oneArgs = {
  object: v1_ContactType_insert_input;
  on_conflict?: InputMaybe<v1_ContactType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Contacts_RolesArgs = {
  objects: Array<v1_Contacts_Roles_insert_input>;
  on_conflict?: InputMaybe<v1_Contacts_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Contacts_Roles_oneArgs = {
  object: v1_Contacts_Roles_insert_input;
  on_conflict?: InputMaybe<v1_Contacts_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContentLinkArgs = {
  objects: Array<v1_ContentLink_insert_input>;
  on_conflict?: InputMaybe<v1_ContentLink_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContentLink_oneArgs = {
  object: v1_ContentLink_insert_input;
  on_conflict?: InputMaybe<v1_ContentLink_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContentTypesArgs = {
  objects: Array<v1_ContentTypes_insert_input>;
  on_conflict?: InputMaybe<v1_ContentTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ContentTypes_oneArgs = {
  object: v1_ContentTypes_insert_input;
  on_conflict?: InputMaybe<v1_ContentTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Credential_RolesArgs = {
  objects: Array<v1_Credential_Roles_insert_input>;
  on_conflict?: InputMaybe<v1_Credential_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Credential_Roles_oneArgs = {
  object: v1_Credential_Roles_insert_input;
  on_conflict?: InputMaybe<v1_Credential_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_CredentialsArgs = {
  objects: Array<v1_Credentials_insert_input>;
  on_conflict?: InputMaybe<v1_Credentials_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Credentials_oneArgs = {
  object: v1_Credentials_insert_input;
  on_conflict?: InputMaybe<v1_Credentials_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_DomainExtensionsArgs = {
  objects: Array<v1_DomainExtensions_insert_input>;
  on_conflict?: InputMaybe<v1_DomainExtensions_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_DomainExtensions_oneArgs = {
  object: v1_DomainExtensions_insert_input;
  on_conflict?: InputMaybe<v1_DomainExtensions_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_EventsArgs = {
  objects: Array<v1_Events_insert_input>;
  on_conflict?: InputMaybe<v1_Events_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Events_oneArgs = {
  object: v1_Events_insert_input;
  on_conflict?: InputMaybe<v1_Events_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ExpiringRecPaymentsArgs = {
  objects: Array<v1_ExpiringRecPayments_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_v1_ExpiringRecPayments_oneArgs = {
  object: v1_ExpiringRecPayments_insert_input;
};


/** mutation root */
export type mutation_rootinsert_v1_FilesArgs = {
  objects: Array<v1_Files_insert_input>;
  on_conflict?: InputMaybe<v1_Files_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Files_oneArgs = {
  object: v1_Files_insert_input;
  on_conflict?: InputMaybe<v1_Files_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_GameTypeArgs = {
  objects: Array<v1_GameType_insert_input>;
  on_conflict?: InputMaybe<v1_GameType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_GameType_oneArgs = {
  object: v1_GameType_insert_input;
  on_conflict?: InputMaybe<v1_GameType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_InvoiceBaseArgs = {
  objects: Array<v1_InvoiceBase_insert_input>;
  on_conflict?: InputMaybe<v1_InvoiceBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_InvoiceBase_oneArgs = {
  object: v1_InvoiceBase_insert_input;
  on_conflict?: InputMaybe<v1_InvoiceBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_InvoiceDetailBaseArgs = {
  objects: Array<v1_InvoiceDetailBase_insert_input>;
  on_conflict?: InputMaybe<v1_InvoiceDetailBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_InvoiceDetailBase_oneArgs = {
  object: v1_InvoiceDetailBase_insert_input;
  on_conflict?: InputMaybe<v1_InvoiceDetailBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_InvoiceStatusArgs = {
  objects: Array<v1_InvoiceStatus_insert_input>;
  on_conflict?: InputMaybe<v1_InvoiceStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_InvoiceStatus_oneArgs = {
  object: v1_InvoiceStatus_insert_input;
  on_conflict?: InputMaybe<v1_InvoiceStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_LandingPagePriorityArgs = {
  objects: Array<v1_LandingPagePriority_insert_input>;
  on_conflict?: InputMaybe<v1_LandingPagePriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_LandingPagePriority_oneArgs = {
  object: v1_LandingPagePriority_insert_input;
  on_conflict?: InputMaybe<v1_LandingPagePriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_LandingPagesArgs = {
  objects: Array<v1_LandingPages_insert_input>;
  on_conflict?: InputMaybe<v1_LandingPages_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_LandingPages_oneArgs = {
  object: v1_LandingPages_insert_input;
  on_conflict?: InputMaybe<v1_LandingPages_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_LanguagePriceStepArgs = {
  objects: Array<v1_LanguagePriceStep_insert_input>;
  on_conflict?: InputMaybe<v1_LanguagePriceStep_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_LanguagePriceStep_oneArgs = {
  object: v1_LanguagePriceStep_insert_input;
  on_conflict?: InputMaybe<v1_LanguagePriceStep_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceCategoriesArgs = {
  objects: Array<v1_MarketPlaceCategories_insert_input>;
  on_conflict?: InputMaybe<v1_MarketPlaceCategories_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceCategoriesTypesArgs = {
  objects: Array<v1_MarketPlaceCategoriesTypes_insert_input>;
  on_conflict?: InputMaybe<v1_MarketPlaceCategoriesTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceCategoriesTypes_oneArgs = {
  object: v1_MarketPlaceCategoriesTypes_insert_input;
  on_conflict?: InputMaybe<v1_MarketPlaceCategoriesTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceCategories_OffersArgs = {
  objects: Array<v1_MarketPlaceCategories_Offers_insert_input>;
  on_conflict?: InputMaybe<v1_MarketPlaceCategories_Offers_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceCategories_Offers_oneArgs = {
  object: v1_MarketPlaceCategories_Offers_insert_input;
  on_conflict?: InputMaybe<v1_MarketPlaceCategories_Offers_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceCategories_oneArgs = {
  object: v1_MarketPlaceCategories_insert_input;
  on_conflict?: InputMaybe<v1_MarketPlaceCategories_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceOfferNicheArgs = {
  objects: Array<v1_MarketPlaceOfferNiche_insert_input>;
  on_conflict?: InputMaybe<v1_MarketPlaceOfferNiche_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceOfferNiche_oneArgs = {
  object: v1_MarketPlaceOfferNiche_insert_input;
  on_conflict?: InputMaybe<v1_MarketPlaceOfferNiche_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceOrderArgs = {
  objects: Array<v1_MarketPlaceOrder_insert_input>;
  on_conflict?: InputMaybe<v1_MarketPlaceOrder_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceOrderRequestArgs = {
  objects: Array<v1_MarketPlaceOrderRequest_insert_input>;
  on_conflict?: InputMaybe<v1_MarketPlaceOrderRequest_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceOrderRequest_oneArgs = {
  object: v1_MarketPlaceOrderRequest_insert_input;
  on_conflict?: InputMaybe<v1_MarketPlaceOrderRequest_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceOrderStatusArgs = {
  objects: Array<v1_MarketPlaceOrderStatus_insert_input>;
  on_conflict?: InputMaybe<v1_MarketPlaceOrderStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceOrderStatus_oneArgs = {
  object: v1_MarketPlaceOrderStatus_insert_input;
  on_conflict?: InputMaybe<v1_MarketPlaceOrderStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceOrder_oneArgs = {
  object: v1_MarketPlaceOrder_insert_input;
  on_conflict?: InputMaybe<v1_MarketPlaceOrder_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceRegisterStatusArgs = {
  objects: Array<v1_MarketPlaceRegisterStatus_insert_input>;
  on_conflict?: InputMaybe<v1_MarketPlaceRegisterStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketPlaceRegisterStatus_oneArgs = {
  object: v1_MarketPlaceRegisterStatus_insert_input;
  on_conflict?: InputMaybe<v1_MarketPlaceRegisterStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketTypeArgs = {
  objects: Array<v1_MarketType_insert_input>;
  on_conflict?: InputMaybe<v1_MarketType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketType_oneArgs = {
  object: v1_MarketType_insert_input;
  on_conflict?: InputMaybe<v1_MarketType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketplaceProjectsArgs = {
  objects: Array<v1_MarketplaceProjects_insert_input>;
  on_conflict?: InputMaybe<v1_MarketplaceProjects_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketplaceProjects_oneArgs = {
  object: v1_MarketplaceProjects_insert_input;
  on_conflict?: InputMaybe<v1_MarketplaceProjects_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketplaceStatusArgs = {
  objects: Array<v1_MarketplaceStatus_insert_input>;
  on_conflict?: InputMaybe<v1_MarketplaceStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MarketplaceStatus_oneArgs = {
  object: v1_MarketplaceStatus_insert_input;
  on_conflict?: InputMaybe<v1_MarketplaceStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_MonthsArgs = {
  objects: Array<v1_Months_insert_input>;
  on_conflict?: InputMaybe<v1_Months_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Months_oneArgs = {
  object: v1_Months_insert_input;
  on_conflict?: InputMaybe<v1_Months_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OfferArgs = {
  objects: Array<v1_Offer_insert_input>;
  on_conflict?: InputMaybe<v1_Offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OfferStatusArgs = {
  objects: Array<v1_OfferStatus_insert_input>;
  on_conflict?: InputMaybe<v1_OfferStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OfferStatus_oneArgs = {
  object: v1_OfferStatus_insert_input;
  on_conflict?: InputMaybe<v1_OfferStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OfferTagsArgs = {
  objects: Array<v1_OfferTags_insert_input>;
  on_conflict?: InputMaybe<v1_OfferTags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OfferTags_oneArgs = {
  object: v1_OfferTags_insert_input;
  on_conflict?: InputMaybe<v1_OfferTags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OfferToTagsArgs = {
  objects: Array<v1_OfferToTags_insert_input>;
  on_conflict?: InputMaybe<v1_OfferToTags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OfferToTags_oneArgs = {
  object: v1_OfferToTags_insert_input;
  on_conflict?: InputMaybe<v1_OfferToTags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Offer_oneArgs = {
  object: v1_Offer_insert_input;
  on_conflict?: InputMaybe<v1_Offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OrderForArgs = {
  objects: Array<v1_OrderFor_insert_input>;
  on_conflict?: InputMaybe<v1_OrderFor_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OrderFor_oneArgs = {
  object: v1_OrderFor_insert_input;
  on_conflict?: InputMaybe<v1_OrderFor_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OrderPayToArgs = {
  objects: Array<v1_OrderPayTo_insert_input>;
  on_conflict?: InputMaybe<v1_OrderPayTo_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OrderPayTo_oneArgs = {
  object: v1_OrderPayTo_insert_input;
  on_conflict?: InputMaybe<v1_OrderPayTo_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OrderPriorityArgs = {
  objects: Array<v1_OrderPriority_insert_input>;
  on_conflict?: InputMaybe<v1_OrderPriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OrderPriority_oneArgs = {
  object: v1_OrderPriority_insert_input;
  on_conflict?: InputMaybe<v1_OrderPriority_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OrderTypeArgs = {
  objects: Array<v1_OrderType_insert_input>;
  on_conflict?: InputMaybe<v1_OrderType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_OrderType_oneArgs = {
  object: v1_OrderType_insert_input;
  on_conflict?: InputMaybe<v1_OrderType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PageArgs = {
  objects: Array<v1_Page_insert_input>;
  on_conflict?: InputMaybe<v1_Page_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Page_oneArgs = {
  object: v1_Page_insert_input;
  on_conflict?: InputMaybe<v1_Page_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PaymentCompaniesArgs = {
  objects: Array<v1_PaymentCompanies_insert_input>;
  on_conflict?: InputMaybe<v1_PaymentCompanies_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PaymentCompanies_oneArgs = {
  object: v1_PaymentCompanies_insert_input;
  on_conflict?: InputMaybe<v1_PaymentCompanies_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PaymentFrequencyArgs = {
  objects: Array<v1_PaymentFrequency_insert_input>;
  on_conflict?: InputMaybe<v1_PaymentFrequency_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PaymentFrequency_oneArgs = {
  object: v1_PaymentFrequency_insert_input;
  on_conflict?: InputMaybe<v1_PaymentFrequency_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PoToPostsArgs = {
  objects: Array<v1_PoToPosts_insert_input>;
  on_conflict?: InputMaybe<v1_PoToPosts_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PoToPosts_oneArgs = {
  object: v1_PoToPosts_insert_input;
  on_conflict?: InputMaybe<v1_PoToPosts_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostDurationArgs = {
  objects: Array<v1_PostDuration_insert_input>;
  on_conflict?: InputMaybe<v1_PostDuration_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostDuration_oneArgs = {
  object: v1_PostDuration_insert_input;
  on_conflict?: InputMaybe<v1_PostDuration_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostNicheArgs = {
  objects: Array<v1_PostNiche_insert_input>;
  on_conflict?: InputMaybe<v1_PostNiche_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostNiche_oneArgs = {
  object: v1_PostNiche_insert_input;
  on_conflict?: InputMaybe<v1_PostNiche_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsArgs = {
  objects: Array<v1_Posts_insert_input>;
  on_conflict?: InputMaybe<v1_Posts_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsAnchorTextTypeArgs = {
  objects: Array<v1_PostsAnchorTextType_insert_input>;
  on_conflict?: InputMaybe<v1_PostsAnchorTextType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsAnchorTextType_oneArgs = {
  object: v1_PostsAnchorTextType_insert_input;
  on_conflict?: InputMaybe<v1_PostsAnchorTextType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsContentStatusArgs = {
  objects: Array<v1_PostsContentStatus_insert_input>;
  on_conflict?: InputMaybe<v1_PostsContentStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsContentStatus_oneArgs = {
  object: v1_PostsContentStatus_insert_input;
  on_conflict?: InputMaybe<v1_PostsContentStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsCopyScapeArgs = {
  objects: Array<v1_PostsCopyScape_insert_input>;
  on_conflict?: InputMaybe<v1_PostsCopyScape_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsCopyScape_oneArgs = {
  object: v1_PostsCopyScape_insert_input;
  on_conflict?: InputMaybe<v1_PostsCopyScape_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsLinkStatusArgs = {
  objects: Array<v1_PostsLinkStatus_insert_input>;
  on_conflict?: InputMaybe<v1_PostsLinkStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsLinkStatus_oneArgs = {
  object: v1_PostsLinkStatus_insert_input;
  on_conflict?: InputMaybe<v1_PostsLinkStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsOnlineStatusArgs = {
  objects: Array<v1_PostsOnlineStatus_insert_input>;
  on_conflict?: InputMaybe<v1_PostsOnlineStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsOnlineStatus_oneArgs = {
  object: v1_PostsOnlineStatus_insert_input;
  on_conflict?: InputMaybe<v1_PostsOnlineStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsOutreachStatusArgs = {
  objects: Array<v1_PostsOutreachStatus_insert_input>;
  on_conflict?: InputMaybe<v1_PostsOutreachStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsOutreachStatus_oneArgs = {
  object: v1_PostsOutreachStatus_insert_input;
  on_conflict?: InputMaybe<v1_PostsOutreachStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsPageTypeDeprecatedArgs = {
  objects: Array<v1_PostsPageTypeDeprecated_insert_input>;
  on_conflict?: InputMaybe<v1_PostsPageTypeDeprecated_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsPageTypeDeprecated_oneArgs = {
  object: v1_PostsPageTypeDeprecated_insert_input;
  on_conflict?: InputMaybe<v1_PostsPageTypeDeprecated_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsRemovalStatusArgs = {
  objects: Array<v1_PostsRemovalStatus_insert_input>;
  on_conflict?: InputMaybe<v1_PostsRemovalStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsRemovalStatus_oneArgs = {
  object: v1_PostsRemovalStatus_insert_input;
  on_conflict?: InputMaybe<v1_PostsRemovalStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsSEOApprovalArgs = {
  objects: Array<v1_PostsSEOApproval_insert_input>;
  on_conflict?: InputMaybe<v1_PostsSEOApproval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsSEOApproval_oneArgs = {
  object: v1_PostsSEOApproval_insert_input;
  on_conflict?: InputMaybe<v1_PostsSEOApproval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsSEODeclineReasonArgs = {
  objects: Array<v1_PostsSEODeclineReason_insert_input>;
  on_conflict?: InputMaybe<v1_PostsSEODeclineReason_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsSEODeclineReason_oneArgs = {
  object: v1_PostsSEODeclineReason_insert_input;
  on_conflict?: InputMaybe<v1_PostsSEODeclineReason_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsStatusArgs = {
  objects: Array<v1_PostsStatus_insert_input>;
  on_conflict?: InputMaybe<v1_PostsStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsStatusCodeArgs = {
  objects: Array<v1_PostsStatusCode_insert_input>;
  on_conflict?: InputMaybe<v1_PostsStatusCode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsStatusCode_oneArgs = {
  object: v1_PostsStatusCode_insert_input;
  on_conflict?: InputMaybe<v1_PostsStatusCode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PostsStatus_oneArgs = {
  object: v1_PostsStatus_insert_input;
  on_conflict?: InputMaybe<v1_PostsStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Posts_oneArgs = {
  object: v1_Posts_insert_input;
  on_conflict?: InputMaybe<v1_Posts_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PreferencesArgs = {
  objects: Array<v1_Preferences_insert_input>;
  on_conflict?: InputMaybe<v1_Preferences_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PreferencesMarketplaceArgs = {
  objects: Array<v1_PreferencesMarketplace_insert_input>;
  on_conflict?: InputMaybe<v1_PreferencesMarketplace_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PreferencesMarketplace_oneArgs = {
  object: v1_PreferencesMarketplace_insert_input;
  on_conflict?: InputMaybe<v1_PreferencesMarketplace_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Preferences_oneArgs = {
  object: v1_Preferences_insert_input;
  on_conflict?: InputMaybe<v1_Preferences_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ProjectArgs = {
  objects: Array<v1_Project_insert_input>;
  on_conflict?: InputMaybe<v1_Project_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ProjectImportanceArgs = {
  objects: Array<v1_ProjectImportance_insert_input>;
  on_conflict?: InputMaybe<v1_ProjectImportance_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ProjectImportance_oneArgs = {
  object: v1_ProjectImportance_insert_input;
  on_conflict?: InputMaybe<v1_ProjectImportance_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ProjectStatusArgs = {
  objects: Array<v1_ProjectStatus_insert_input>;
  on_conflict?: InputMaybe<v1_ProjectStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ProjectStatus_oneArgs = {
  object: v1_ProjectStatus_insert_input;
  on_conflict?: InputMaybe<v1_ProjectStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ProjectTopicArgs = {
  objects: Array<v1_ProjectTopic_insert_input>;
  on_conflict?: InputMaybe<v1_ProjectTopic_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ProjectTopic_oneArgs = {
  object: v1_ProjectTopic_insert_input;
  on_conflict?: InputMaybe<v1_ProjectTopic_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Project_CountriesArgs = {
  objects: Array<v1_Project_Countries_insert_input>;
  on_conflict?: InputMaybe<v1_Project_Countries_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Project_Countries_oneArgs = {
  object: v1_Project_Countries_insert_input;
  on_conflict?: InputMaybe<v1_Project_Countries_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Project_oneArgs = {
  object: v1_Project_insert_input;
  on_conflict?: InputMaybe<v1_Project_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PublisherPostStatusArgs = {
  objects: Array<v1_PublisherPostStatus_insert_input>;
  on_conflict?: InputMaybe<v1_PublisherPostStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PublisherPostStatus_oneArgs = {
  object: v1_PublisherPostStatus_insert_input;
  on_conflict?: InputMaybe<v1_PublisherPostStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PurchaseOrderStatusArgs = {
  objects: Array<v1_PurchaseOrderStatus_insert_input>;
  on_conflict?: InputMaybe<v1_PurchaseOrderStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_PurchaseOrderStatus_oneArgs = {
  object: v1_PurchaseOrderStatus_insert_input;
  on_conflict?: InputMaybe<v1_PurchaseOrderStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ReceiptStatusArgs = {
  objects: Array<v1_ReceiptStatus_insert_input>;
  on_conflict?: InputMaybe<v1_ReceiptStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ReceiptStatus_oneArgs = {
  object: v1_ReceiptStatus_insert_input;
  on_conflict?: InputMaybe<v1_ReceiptStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_RecurringPaymentStatusArgs = {
  objects: Array<v1_RecurringPaymentStatus_insert_input>;
  on_conflict?: InputMaybe<v1_RecurringPaymentStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_RecurringPaymentStatus_oneArgs = {
  object: v1_RecurringPaymentStatus_insert_input;
  on_conflict?: InputMaybe<v1_RecurringPaymentStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_RecurringPaymentsArgs = {
  objects: Array<v1_RecurringPayments_insert_input>;
  on_conflict?: InputMaybe<v1_RecurringPayments_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_RecurringPayments_RolesArgs = {
  objects: Array<v1_RecurringPayments_Roles_insert_input>;
  on_conflict?: InputMaybe<v1_RecurringPayments_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_RecurringPayments_Roles_oneArgs = {
  object: v1_RecurringPayments_Roles_insert_input;
  on_conflict?: InputMaybe<v1_RecurringPayments_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_RecurringPayments_oneArgs = {
  object: v1_RecurringPayments_insert_input;
  on_conflict?: InputMaybe<v1_RecurringPayments_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ReportArgs = {
  objects: Array<v1_Report_insert_input>;
  on_conflict?: InputMaybe<v1_Report_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Report_RolesArgs = {
  objects: Array<v1_Report_Roles_insert_input>;
  on_conflict?: InputMaybe<v1_Report_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Report_Roles_oneArgs = {
  object: v1_Report_Roles_insert_input;
  on_conflict?: InputMaybe<v1_Report_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Report_oneArgs = {
  object: v1_Report_insert_input;
  on_conflict?: InputMaybe<v1_Report_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_RestrictedNichesToOfferArgs = {
  objects: Array<v1_RestrictedNichesToOffer_insert_input>;
  on_conflict?: InputMaybe<v1_RestrictedNichesToOffer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_RestrictedNichesToOffer_oneArgs = {
  object: v1_RestrictedNichesToOffer_insert_input;
  on_conflict?: InputMaybe<v1_RestrictedNichesToOffer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_RolesArgs = {
  objects: Array<v1_Roles_insert_input>;
  on_conflict?: InputMaybe<v1_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Roles_oneArgs = {
  object: v1_Roles_insert_input;
  on_conflict?: InputMaybe<v1_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_SalesOrderBaseArgs = {
  objects: Array<v1_SalesOrderBase_insert_input>;
  on_conflict?: InputMaybe<v1_SalesOrderBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_SalesOrderBase_oneArgs = {
  object: v1_SalesOrderBase_insert_input;
  on_conflict?: InputMaybe<v1_SalesOrderBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ServiceTypesArgs = {
  objects: Array<v1_ServiceTypes_insert_input>;
  on_conflict?: InputMaybe<v1_ServiceTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_ServiceTypes_oneArgs = {
  object: v1_ServiceTypes_insert_input;
  on_conflict?: InputMaybe<v1_ServiceTypes_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_SoftwareProvidersArgs = {
  objects: Array<v1_SoftwareProviders_insert_input>;
  on_conflict?: InputMaybe<v1_SoftwareProviders_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_SoftwareProviders_oneArgs = {
  object: v1_SoftwareProviders_insert_input;
  on_conflict?: InputMaybe<v1_SoftwareProviders_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_StrategyFieldsSetArgs = {
  objects: Array<v1_StrategyFieldsSet_insert_input>;
  on_conflict?: InputMaybe<v1_StrategyFieldsSet_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_StrategyFieldsSet_oneArgs = {
  object: v1_StrategyFieldsSet_insert_input;
  on_conflict?: InputMaybe<v1_StrategyFieldsSet_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Supplier_Tag_relArgs = {
  objects: Array<v1_Supplier_Tag_rel_insert_input>;
  on_conflict?: InputMaybe<v1_Supplier_Tag_rel_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Supplier_Tag_rel_oneArgs = {
  object: v1_Supplier_Tag_rel_insert_input;
  on_conflict?: InputMaybe<v1_Supplier_Tag_rel_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_SystemUserBaseArgs = {
  objects: Array<v1_SystemUserBase_insert_input>;
  on_conflict?: InputMaybe<v1_SystemUserBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_SystemUserBase_oneArgs = {
  object: v1_SystemUserBase_insert_input;
  on_conflict?: InputMaybe<v1_SystemUserBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TagsArgs = {
  objects: Array<v1_Tags_insert_input>;
  on_conflict?: InputMaybe<v1_Tags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Tags_oneArgs = {
  object: v1_Tags_insert_input;
  on_conflict?: InputMaybe<v1_Tags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TeamsArgs = {
  objects: Array<v1_Teams_insert_input>;
  on_conflict?: InputMaybe<v1_Teams_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Teams_oneArgs = {
  object: v1_Teams_insert_input;
  on_conflict?: InputMaybe<v1_Teams_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TrackerDealArgs = {
  objects: Array<v1_TrackerDeal_insert_input>;
  on_conflict?: InputMaybe<v1_TrackerDeal_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TrackerDeal_oneArgs = {
  object: v1_TrackerDeal_insert_input;
  on_conflict?: InputMaybe<v1_TrackerDeal_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TrackerTagsArgs = {
  objects: Array<v1_TrackerTags_insert_input>;
  on_conflict?: InputMaybe<v1_TrackerTags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TrackerTags_oneArgs = {
  object: v1_TrackerTags_insert_input;
  on_conflict?: InputMaybe<v1_TrackerTags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TrackerToTagsArgs = {
  objects: Array<v1_TrackerToTags_insert_input>;
  on_conflict?: InputMaybe<v1_TrackerToTags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TrackerToTags_oneArgs = {
  object: v1_TrackerToTags_insert_input;
  on_conflict?: InputMaybe<v1_TrackerToTags_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TransactionCurrencyBaseArgs = {
  objects: Array<v1_TransactionCurrencyBase_insert_input>;
  on_conflict?: InputMaybe<v1_TransactionCurrencyBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_TransactionCurrencyBase_oneArgs = {
  object: v1_TransactionCurrencyBase_insert_input;
  on_conflict?: InputMaybe<v1_TransactionCurrencyBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_UsersStatusArgs = {
  objects: Array<v1_UsersStatus_insert_input>;
  on_conflict?: InputMaybe<v1_UsersStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_UsersStatus_oneArgs = {
  object: v1_UsersStatus_insert_input;
  on_conflict?: InputMaybe<v1_UsersStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_UsersToOffersArgs = {
  objects: Array<v1_UsersToOffers_insert_input>;
  on_conflict?: InputMaybe<v1_UsersToOffers_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_UsersToOffers_oneArgs = {
  object: v1_UsersToOffers_insert_input;
  on_conflict?: InputMaybe<v1_UsersToOffers_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Users_RolesArgs = {
  objects: Array<v1_Users_Roles_insert_input>;
  on_conflict?: InputMaybe<v1_Users_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Users_Roles_oneArgs = {
  object: v1_Users_Roles_insert_input;
  on_conflict?: InputMaybe<v1_Users_Roles_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Users_TeamsArgs = {
  objects: Array<v1_Users_Teams_insert_input>;
  on_conflict?: InputMaybe<v1_Users_Teams_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Users_Teams_oneArgs = {
  object: v1_Users_Teams_insert_input;
  on_conflict?: InputMaybe<v1_Users_Teams_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WageringRequirementsLocationArgs = {
  objects: Array<v1_WageringRequirementsLocation_insert_input>;
  on_conflict?: InputMaybe<v1_WageringRequirementsLocation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WageringRequirementsLocation_oneArgs = {
  object: v1_WageringRequirementsLocation_insert_input;
  on_conflict?: InputMaybe<v1_WageringRequirementsLocation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteArgs = {
  objects: Array<v1_Website_insert_input>;
  on_conflict?: InputMaybe<v1_Website_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteBlogProbabilityArgs = {
  objects: Array<v1_WebsiteBlogProbability_insert_input>;
  on_conflict?: InputMaybe<v1_WebsiteBlogProbability_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteBlogProbability_oneArgs = {
  object: v1_WebsiteBlogProbability_insert_input;
  on_conflict?: InputMaybe<v1_WebsiteBlogProbability_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteContentIndicatorArgs = {
  objects: Array<v1_WebsiteContentIndicator_insert_input>;
  on_conflict?: InputMaybe<v1_WebsiteContentIndicator_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteContentIndicator_oneArgs = {
  object: v1_WebsiteContentIndicator_insert_input;
  on_conflict?: InputMaybe<v1_WebsiteContentIndicator_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteDToxRiskArgs = {
  objects: Array<v1_WebsiteDToxRisk_insert_input>;
  on_conflict?: InputMaybe<v1_WebsiteDToxRisk_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteDToxRisk_oneArgs = {
  object: v1_WebsiteDToxRisk_insert_input;
  on_conflict?: InputMaybe<v1_WebsiteDToxRisk_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteQualityScoreArgs = {
  objects: Array<v1_WebsiteQualityScore_insert_input>;
  on_conflict?: InputMaybe<v1_WebsiteQualityScore_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteQualityScore_oneArgs = {
  object: v1_WebsiteQualityScore_insert_input;
  on_conflict?: InputMaybe<v1_WebsiteQualityScore_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteStatusArgs = {
  objects: Array<v1_WebsiteStatus_insert_input>;
  on_conflict?: InputMaybe<v1_WebsiteStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteStatus_oneArgs = {
  object: v1_WebsiteStatus_insert_input;
  on_conflict?: InputMaybe<v1_WebsiteStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteTopicsArgs = {
  objects: Array<v1_WebsiteTopics_insert_input>;
  on_conflict?: InputMaybe<v1_WebsiteTopics_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteTopics_oneArgs = {
  object: v1_WebsiteTopics_insert_input;
  on_conflict?: InputMaybe<v1_WebsiteTopics_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteUptimeStatusArgs = {
  objects: Array<v1_WebsiteUptimeStatus_insert_input>;
  on_conflict?: InputMaybe<v1_WebsiteUptimeStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsiteUptimeStatus_oneArgs = {
  object: v1_WebsiteUptimeStatus_insert_input;
  on_conflict?: InputMaybe<v1_WebsiteUptimeStatus_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Website_PlatformArgs = {
  objects: Array<v1_Website_Platform_insert_input>;
  on_conflict?: InputMaybe<v1_Website_Platform_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Website_Platform_oneArgs = {
  object: v1_Website_Platform_insert_input;
  on_conflict?: InputMaybe<v1_Website_Platform_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_Website_oneArgs = {
  object: v1_Website_insert_input;
  on_conflict?: InputMaybe<v1_Website_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsitesToTopicsArgs = {
  objects: Array<v1_WebsitesToTopics_insert_input>;
  on_conflict?: InputMaybe<v1_WebsitesToTopics_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_WebsitesToTopics_oneArgs = {
  object: v1_WebsitesToTopics_insert_input;
  on_conflict?: InputMaybe<v1_WebsitesToTopics_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_POBaseArgs = {
  objects: Array<v1_new_POBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_POBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_POBase_oneArgs = {
  object: v1_new_POBase_insert_input;
  on_conflict?: InputMaybe<v1_new_POBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_affiliateincomereportBaseArgs = {
  objects: Array<v1_new_affiliateincomereportBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_affiliateincomereportBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_affiliateincomereportBase_oneArgs = {
  object: v1_new_affiliateincomereportBase_insert_input;
  on_conflict?: InputMaybe<v1_new_affiliateincomereportBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_affordertypeBaseArgs = {
  objects: Array<v1_new_affordertypeBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_affordertypeBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_affordertypeBase_oneArgs = {
  object: v1_new_affordertypeBase_insert_input;
  on_conflict?: InputMaybe<v1_new_affordertypeBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_brandBaseArgs = {
  objects: Array<v1_new_brandBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_brandBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_brandBase_oneArgs = {
  object: v1_new_brandBase_insert_input;
  on_conflict?: InputMaybe<v1_new_brandBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_countryBaseArgs = {
  objects: Array<v1_new_countryBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_countryBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_countryBase_oneArgs = {
  object: v1_new_countryBase_insert_input;
  on_conflict?: InputMaybe<v1_new_countryBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_languagesBaseArgs = {
  objects: Array<v1_new_languagesBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_languagesBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_languagesBase_oneArgs = {
  object: v1_new_languagesBase_insert_input;
  on_conflict?: InputMaybe<v1_new_languagesBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_new_brand_new_countryBaseArgs = {
  objects: Array<v1_new_new_brand_new_countryBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_new_brand_new_countryBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_new_brand_new_countryBase_oneArgs = {
  object: v1_new_new_brand_new_countryBase_insert_input;
  on_conflict?: InputMaybe<v1_new_new_brand_new_countryBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_paymentmethodBaseArgs = {
  objects: Array<v1_new_paymentmethodBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_paymentmethodBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_paymentmethodBase_oneArgs = {
  object: v1_new_paymentmethodBase_insert_input;
  on_conflict?: InputMaybe<v1_new_paymentmethodBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_receiptBaseArgs = {
  objects: Array<v1_new_receiptBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_receiptBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_receiptBase_oneArgs = {
  object: v1_new_receiptBase_insert_input;
  on_conflict?: InputMaybe<v1_new_receiptBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_regemailBaseArgs = {
  objects: Array<v1_new_regemailBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_regemailBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_regemailBase_oneArgs = {
  object: v1_new_regemailBase_insert_input;
  on_conflict?: InputMaybe<v1_new_regemailBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_trackerBaseArgs = {
  objects: Array<v1_new_trackerBase_insert_input>;
  on_conflict?: InputMaybe<v1_new_trackerBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_new_trackerBase_oneArgs = {
  object: v1_new_trackerBase_insert_input;
  on_conflict?: InputMaybe<v1_new_trackerBase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_processArgs = {
  objects: Array<v1_process_insert_input>;
  on_conflict?: InputMaybe<v1_process_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_process_oneArgs = {
  object: v1_process_insert_input;
  on_conflict?: InputMaybe<v1_process_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_process_rateArgs = {
  objects: Array<v1_process_rate_insert_input>;
  on_conflict?: InputMaybe<v1_process_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_process_rate_oneArgs = {
  object: v1_process_rate_insert_input;
  on_conflict?: InputMaybe<v1_process_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_project_topicArgs = {
  objects: Array<v1_project_topic_insert_input>;
  on_conflict?: InputMaybe<v1_project_topic_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_v1_project_topic_oneArgs = {
  object: v1_project_topic_insert_input;
  on_conflict?: InputMaybe<v1_project_topic_on_conflict>;
};


/** mutation root */
export type mutation_rootupdate_v1_AccountBaseArgs = {
  _inc?: InputMaybe<v1_AccountBase_inc_input>;
  _set?: InputMaybe<v1_AccountBase_set_input>;
  where: v1_AccountBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_AccountBase_by_pkArgs = {
  _inc?: InputMaybe<v1_AccountBase_inc_input>;
  _set?: InputMaybe<v1_AccountBase_set_input>;
  pk_columns: v1_AccountBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_AccountBase_manyArgs = {
  updates: Array<v1_AccountBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_AffDealTypeArgs = {
  _inc?: InputMaybe<v1_AffDealType_inc_input>;
  _set?: InputMaybe<v1_AffDealType_set_input>;
  where: v1_AffDealType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_AffDealType_by_pkArgs = {
  _inc?: InputMaybe<v1_AffDealType_inc_input>;
  _set?: InputMaybe<v1_AffDealType_set_input>;
  pk_columns: v1_AffDealType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_AffDealType_manyArgs = {
  updates: Array<v1_AffDealType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_AffiliateOrderCategoriesPriorityArgs = {
  _inc?: InputMaybe<v1_AffiliateOrderCategoriesPriority_inc_input>;
  _set?: InputMaybe<v1_AffiliateOrderCategoriesPriority_set_input>;
  where: v1_AffiliateOrderCategoriesPriority_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_AffiliateOrderCategoriesPriority_by_pkArgs = {
  _inc?: InputMaybe<v1_AffiliateOrderCategoriesPriority_inc_input>;
  _set?: InputMaybe<v1_AffiliateOrderCategoriesPriority_set_input>;
  pk_columns: v1_AffiliateOrderCategoriesPriority_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_AffiliateOrderCategoriesPriority_manyArgs = {
  updates: Array<v1_AffiliateOrderCategoriesPriority_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_AffiliateProgramTypeArgs = {
  _inc?: InputMaybe<v1_AffiliateProgramType_inc_input>;
  _set?: InputMaybe<v1_AffiliateProgramType_set_input>;
  where: v1_AffiliateProgramType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_AffiliateProgramType_by_pkArgs = {
  _inc?: InputMaybe<v1_AffiliateProgramType_inc_input>;
  _set?: InputMaybe<v1_AffiliateProgramType_set_input>;
  pk_columns: v1_AffiliateProgramType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_AffiliateProgramType_manyArgs = {
  updates: Array<v1_AffiliateProgramType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_AllowedPlayersArgs = {
  _inc?: InputMaybe<v1_AllowedPlayers_inc_input>;
  _set?: InputMaybe<v1_AllowedPlayers_set_input>;
  where: v1_AllowedPlayers_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_AllowedPlayers_by_pkArgs = {
  _inc?: InputMaybe<v1_AllowedPlayers_inc_input>;
  _set?: InputMaybe<v1_AllowedPlayers_set_input>;
  pk_columns: v1_AllowedPlayers_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_AllowedPlayers_manyArgs = {
  updates: Array<v1_AllowedPlayers_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ArticleTonesArgs = {
  _inc?: InputMaybe<v1_ArticleTones_inc_input>;
  _set?: InputMaybe<v1_ArticleTones_set_input>;
  where: v1_ArticleTones_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ArticleTones_by_pkArgs = {
  _inc?: InputMaybe<v1_ArticleTones_inc_input>;
  _set?: InputMaybe<v1_ArticleTones_set_input>;
  pk_columns: v1_ArticleTones_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ArticleTones_manyArgs = {
  updates: Array<v1_ArticleTones_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BalanceArgs = {
  _inc?: InputMaybe<v1_Balance_inc_input>;
  _set?: InputMaybe<v1_Balance_set_input>;
  where: v1_Balance_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BalanceActivityTypeArgs = {
  _inc?: InputMaybe<v1_BalanceActivityType_inc_input>;
  _set?: InputMaybe<v1_BalanceActivityType_set_input>;
  where: v1_BalanceActivityType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BalanceActivityType_by_pkArgs = {
  _inc?: InputMaybe<v1_BalanceActivityType_inc_input>;
  _set?: InputMaybe<v1_BalanceActivityType_set_input>;
  pk_columns: v1_BalanceActivityType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BalanceActivityType_manyArgs = {
  updates: Array<v1_BalanceActivityType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Balance_by_pkArgs = {
  _inc?: InputMaybe<v1_Balance_inc_input>;
  _set?: InputMaybe<v1_Balance_set_input>;
  pk_columns: v1_Balance_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Balance_manyArgs = {
  updates: Array<v1_Balance_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BillingInformationArgs = {
  _inc?: InputMaybe<v1_BillingInformation_inc_input>;
  _set?: InputMaybe<v1_BillingInformation_set_input>;
  where: v1_BillingInformation_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BillingInformation_by_pkArgs = {
  _inc?: InputMaybe<v1_BillingInformation_inc_input>;
  _set?: InputMaybe<v1_BillingInformation_set_input>;
  pk_columns: v1_BillingInformation_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BillingInformation_manyArgs = {
  updates: Array<v1_BillingInformation_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BillingMethodArgs = {
  _inc?: InputMaybe<v1_BillingMethod_inc_input>;
  _set?: InputMaybe<v1_BillingMethod_set_input>;
  where: v1_BillingMethod_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BillingMethod_by_pkArgs = {
  _inc?: InputMaybe<v1_BillingMethod_inc_input>;
  _set?: InputMaybe<v1_BillingMethod_set_input>;
  pk_columns: v1_BillingMethod_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BillingMethod_manyArgs = {
  updates: Array<v1_BillingMethod_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusSourceArgs = {
  _inc?: InputMaybe<v1_BonusSource_inc_input>;
  _set?: InputMaybe<v1_BonusSource_set_input>;
  where: v1_BonusSource_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusSource_by_pkArgs = {
  _inc?: InputMaybe<v1_BonusSource_inc_input>;
  _set?: InputMaybe<v1_BonusSource_set_input>;
  pk_columns: v1_BonusSource_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusSource_manyArgs = {
  updates: Array<v1_BonusSource_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusStatusArgs = {
  _inc?: InputMaybe<v1_BonusStatus_inc_input>;
  _set?: InputMaybe<v1_BonusStatus_set_input>;
  where: v1_BonusStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_BonusStatus_inc_input>;
  _set?: InputMaybe<v1_BonusStatus_set_input>;
  pk_columns: v1_BonusStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusStatus_manyArgs = {
  updates: Array<v1_BonusStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusSubmitterArgs = {
  _inc?: InputMaybe<v1_BonusSubmitter_inc_input>;
  _set?: InputMaybe<v1_BonusSubmitter_set_input>;
  where: v1_BonusSubmitter_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusSubmitter_by_pkArgs = {
  _inc?: InputMaybe<v1_BonusSubmitter_inc_input>;
  _set?: InputMaybe<v1_BonusSubmitter_set_input>;
  pk_columns: v1_BonusSubmitter_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusSubmitter_manyArgs = {
  updates: Array<v1_BonusSubmitter_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusTypesArgs = {
  _inc?: InputMaybe<v1_BonusTypes_inc_input>;
  _set?: InputMaybe<v1_BonusTypes_set_input>;
  where: v1_BonusTypes_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusTypes_by_pkArgs = {
  _inc?: InputMaybe<v1_BonusTypes_inc_input>;
  _set?: InputMaybe<v1_BonusTypes_set_input>;
  pk_columns: v1_BonusTypes_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusTypes_manyArgs = {
  updates: Array<v1_BonusTypes_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesArgs = {
  _inc?: InputMaybe<v1_Bonuses_inc_input>;
  _set?: InputMaybe<v1_Bonuses_set_input>;
  where: v1_Bonuses_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesToCountriesArgs = {
  _inc?: InputMaybe<v1_BonusesToCountries_inc_input>;
  _set?: InputMaybe<v1_BonusesToCountries_set_input>;
  where: v1_BonusesToCountries_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesToCountries_by_pkArgs = {
  _inc?: InputMaybe<v1_BonusesToCountries_inc_input>;
  _set?: InputMaybe<v1_BonusesToCountries_set_input>;
  pk_columns: v1_BonusesToCountries_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesToCountries_manyArgs = {
  updates: Array<v1_BonusesToCountries_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesToCurrenciesArgs = {
  _inc?: InputMaybe<v1_BonusesToCurrencies_inc_input>;
  _set?: InputMaybe<v1_BonusesToCurrencies_set_input>;
  where: v1_BonusesToCurrencies_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesToCurrencies_by_pkArgs = {
  _inc?: InputMaybe<v1_BonusesToCurrencies_inc_input>;
  _set?: InputMaybe<v1_BonusesToCurrencies_set_input>;
  pk_columns: v1_BonusesToCurrencies_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesToCurrencies_manyArgs = {
  updates: Array<v1_BonusesToCurrencies_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesToTypesArgs = {
  _inc?: InputMaybe<v1_BonusesToTypes_inc_input>;
  _set?: InputMaybe<v1_BonusesToTypes_set_input>;
  where: v1_BonusesToTypes_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesToTypes_by_pkArgs = {
  _inc?: InputMaybe<v1_BonusesToTypes_inc_input>;
  _set?: InputMaybe<v1_BonusesToTypes_set_input>;
  pk_columns: v1_BonusesToTypes_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusesToTypes_manyArgs = {
  updates: Array<v1_BonusesToTypes_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Bonuses_by_pkArgs = {
  _inc?: InputMaybe<v1_Bonuses_inc_input>;
  _set?: InputMaybe<v1_Bonuses_set_input>;
  pk_columns: v1_Bonuses_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Bonuses_manyArgs = {
  updates: Array<v1_Bonuses_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusestoGamesArgs = {
  _inc?: InputMaybe<v1_BonusestoGames_inc_input>;
  _set?: InputMaybe<v1_BonusestoGames_set_input>;
  where: v1_BonusestoGames_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusestoGames_by_pkArgs = {
  _inc?: InputMaybe<v1_BonusestoGames_inc_input>;
  _set?: InputMaybe<v1_BonusestoGames_set_input>;
  pk_columns: v1_BonusestoGames_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BonusestoGames_manyArgs = {
  updates: Array<v1_BonusestoGames_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BrandAliasArgs = {
  _inc?: InputMaybe<v1_BrandAlias_inc_input>;
  _set?: InputMaybe<v1_BrandAlias_set_input>;
  where: v1_BrandAlias_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BrandAlias_by_pkArgs = {
  _inc?: InputMaybe<v1_BrandAlias_inc_input>;
  _set?: InputMaybe<v1_BrandAlias_set_input>;
  pk_columns: v1_BrandAlias_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BrandAlias_manyArgs = {
  updates: Array<v1_BrandAlias_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_BrandPriorityArgs = {
  _inc?: InputMaybe<v1_BrandPriority_inc_input>;
  _set?: InputMaybe<v1_BrandPriority_set_input>;
  where: v1_BrandPriority_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_BrandPriority_by_pkArgs = {
  _inc?: InputMaybe<v1_BrandPriority_inc_input>;
  _set?: InputMaybe<v1_BrandPriority_set_input>;
  pk_columns: v1_BrandPriority_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_BrandPriority_manyArgs = {
  updates: Array<v1_BrandPriority_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignArgs = {
  _inc?: InputMaybe<v1_Campaign_inc_input>;
  _set?: InputMaybe<v1_Campaign_set_input>;
  where: v1_Campaign_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignPriorityArgs = {
  _inc?: InputMaybe<v1_CampaignPriority_inc_input>;
  _set?: InputMaybe<v1_CampaignPriority_set_input>;
  where: v1_CampaignPriority_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignPriority_by_pkArgs = {
  _inc?: InputMaybe<v1_CampaignPriority_inc_input>;
  _set?: InputMaybe<v1_CampaignPriority_set_input>;
  pk_columns: v1_CampaignPriority_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignPriority_manyArgs = {
  updates: Array<v1_CampaignPriority_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignStatusArgs = {
  _inc?: InputMaybe<v1_CampaignStatus_inc_input>;
  _set?: InputMaybe<v1_CampaignStatus_set_input>;
  where: v1_CampaignStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_CampaignStatus_inc_input>;
  _set?: InputMaybe<v1_CampaignStatus_set_input>;
  pk_columns: v1_CampaignStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignStatus_manyArgs = {
  updates: Array<v1_CampaignStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignStrategyArgs = {
  _inc?: InputMaybe<v1_CampaignStrategy_inc_input>;
  _set?: InputMaybe<v1_CampaignStrategy_set_input>;
  where: v1_CampaignStrategy_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignStrategy_by_pkArgs = {
  _inc?: InputMaybe<v1_CampaignStrategy_inc_input>;
  _set?: InputMaybe<v1_CampaignStrategy_set_input>;
  pk_columns: v1_CampaignStrategy_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignStrategy_manyArgs = {
  updates: Array<v1_CampaignStrategy_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignTypeArgs = {
  _inc?: InputMaybe<v1_CampaignType_inc_input>;
  _set?: InputMaybe<v1_CampaignType_set_input>;
  where: v1_CampaignType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignType_by_pkArgs = {
  _inc?: InputMaybe<v1_CampaignType_inc_input>;
  _set?: InputMaybe<v1_CampaignType_set_input>;
  pk_columns: v1_CampaignType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_CampaignType_manyArgs = {
  updates: Array<v1_CampaignType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Campaign_by_pkArgs = {
  _inc?: InputMaybe<v1_Campaign_inc_input>;
  _set?: InputMaybe<v1_Campaign_set_input>;
  pk_columns: v1_Campaign_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Campaign_manyArgs = {
  updates: Array<v1_Campaign_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CategoriesArgs = {
  _inc?: InputMaybe<v1_Categories_inc_input>;
  _set?: InputMaybe<v1_Categories_set_input>;
  where: v1_Categories_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Categories_by_pkArgs = {
  _inc?: InputMaybe<v1_Categories_inc_input>;
  _set?: InputMaybe<v1_Categories_set_input>;
  pk_columns: v1_Categories_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Categories_manyArgs = {
  updates: Array<v1_Categories_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CheckoutArgs = {
  _inc?: InputMaybe<v1_Checkout_inc_input>;
  _set?: InputMaybe<v1_Checkout_set_input>;
  where: v1_Checkout_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_CheckoutDetailArgs = {
  _inc?: InputMaybe<v1_CheckoutDetail_inc_input>;
  _set?: InputMaybe<v1_CheckoutDetail_set_input>;
  where: v1_CheckoutDetail_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_CheckoutDetail_by_pkArgs = {
  _inc?: InputMaybe<v1_CheckoutDetail_inc_input>;
  _set?: InputMaybe<v1_CheckoutDetail_set_input>;
  pk_columns: v1_CheckoutDetail_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_CheckoutDetail_manyArgs = {
  updates: Array<v1_CheckoutDetail_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CheckoutStatusArgs = {
  _inc?: InputMaybe<v1_CheckoutStatus_inc_input>;
  _set?: InputMaybe<v1_CheckoutStatus_set_input>;
  where: v1_CheckoutStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_CheckoutStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_CheckoutStatus_inc_input>;
  _set?: InputMaybe<v1_CheckoutStatus_set_input>;
  pk_columns: v1_CheckoutStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_CheckoutStatus_manyArgs = {
  updates: Array<v1_CheckoutStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Checkout_by_pkArgs = {
  _inc?: InputMaybe<v1_Checkout_inc_input>;
  _set?: InputMaybe<v1_Checkout_set_input>;
  pk_columns: v1_Checkout_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Checkout_manyArgs = {
  updates: Array<v1_Checkout_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CitiesArgs = {
  _inc?: InputMaybe<v1_Cities_inc_input>;
  _set?: InputMaybe<v1_Cities_set_input>;
  where: v1_Cities_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Cities_by_pkArgs = {
  _inc?: InputMaybe<v1_Cities_inc_input>;
  _set?: InputMaybe<v1_Cities_set_input>;
  pk_columns: v1_Cities_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Cities_manyArgs = {
  updates: Array<v1_Cities_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ClicksByTrackersArgs = {
  _inc?: InputMaybe<v1_ClicksByTrackers_inc_input>;
  _set?: InputMaybe<v1_ClicksByTrackers_set_input>;
  where: v1_ClicksByTrackers_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ClicksByTrackersNewArgs = {
  _inc?: InputMaybe<v1_ClicksByTrackersNew_inc_input>;
  _set?: InputMaybe<v1_ClicksByTrackersNew_set_input>;
  where: v1_ClicksByTrackersNew_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ClicksByTrackersNew_by_pkArgs = {
  _inc?: InputMaybe<v1_ClicksByTrackersNew_inc_input>;
  _set?: InputMaybe<v1_ClicksByTrackersNew_set_input>;
  pk_columns: v1_ClicksByTrackersNew_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ClicksByTrackersNew_manyArgs = {
  updates: Array<v1_ClicksByTrackersNew_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ClicksByTrackers_by_pkArgs = {
  _inc?: InputMaybe<v1_ClicksByTrackers_inc_input>;
  _set?: InputMaybe<v1_ClicksByTrackers_set_input>;
  pk_columns: v1_ClicksByTrackers_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ClicksByTrackers_manyArgs = {
  updates: Array<v1_ClicksByTrackers_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CompanyTypeArgs = {
  _inc?: InputMaybe<v1_CompanyType_inc_input>;
  _set?: InputMaybe<v1_CompanyType_set_input>;
  where: v1_CompanyType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_CompanyType_by_pkArgs = {
  _inc?: InputMaybe<v1_CompanyType_inc_input>;
  _set?: InputMaybe<v1_CompanyType_set_input>;
  pk_columns: v1_CompanyType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_CompanyType_manyArgs = {
  updates: Array<v1_CompanyType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ConfigurationsArgs = {
  _inc?: InputMaybe<v1_Configurations_inc_input>;
  _set?: InputMaybe<v1_Configurations_set_input>;
  where: v1_Configurations_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Configurations_by_pkArgs = {
  _inc?: InputMaybe<v1_Configurations_inc_input>;
  _set?: InputMaybe<v1_Configurations_set_input>;
  pk_columns: v1_Configurations_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Configurations_manyArgs = {
  updates: Array<v1_Configurations_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ContactBaseArgs = {
  _inc?: InputMaybe<v1_ContactBase_inc_input>;
  _set?: InputMaybe<v1_ContactBase_set_input>;
  where: v1_ContactBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ContactBasePaymentOptionArgs = {
  _inc?: InputMaybe<v1_ContactBasePaymentOption_inc_input>;
  _set?: InputMaybe<v1_ContactBasePaymentOption_set_input>;
  where: v1_ContactBasePaymentOption_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ContactBasePaymentOption_by_pkArgs = {
  _inc?: InputMaybe<v1_ContactBasePaymentOption_inc_input>;
  _set?: InputMaybe<v1_ContactBasePaymentOption_set_input>;
  pk_columns: v1_ContactBasePaymentOption_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ContactBasePaymentOption_manyArgs = {
  updates: Array<v1_ContactBasePaymentOption_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ContactBase_by_pkArgs = {
  _inc?: InputMaybe<v1_ContactBase_inc_input>;
  _set?: InputMaybe<v1_ContactBase_set_input>;
  pk_columns: v1_ContactBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ContactBase_manyArgs = {
  updates: Array<v1_ContactBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ContactTypeArgs = {
  _inc?: InputMaybe<v1_ContactType_inc_input>;
  _set?: InputMaybe<v1_ContactType_set_input>;
  where: v1_ContactType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ContactType_by_pkArgs = {
  _inc?: InputMaybe<v1_ContactType_inc_input>;
  _set?: InputMaybe<v1_ContactType_set_input>;
  pk_columns: v1_ContactType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ContactType_manyArgs = {
  updates: Array<v1_ContactType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Contacts_RolesArgs = {
  _inc?: InputMaybe<v1_Contacts_Roles_inc_input>;
  _set?: InputMaybe<v1_Contacts_Roles_set_input>;
  where: v1_Contacts_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Contacts_Roles_by_pkArgs = {
  _inc?: InputMaybe<v1_Contacts_Roles_inc_input>;
  _set?: InputMaybe<v1_Contacts_Roles_set_input>;
  pk_columns: v1_Contacts_Roles_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Contacts_Roles_manyArgs = {
  updates: Array<v1_Contacts_Roles_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ContentLinkArgs = {
  _inc?: InputMaybe<v1_ContentLink_inc_input>;
  _set?: InputMaybe<v1_ContentLink_set_input>;
  where: v1_ContentLink_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ContentLink_by_pkArgs = {
  _inc?: InputMaybe<v1_ContentLink_inc_input>;
  _set?: InputMaybe<v1_ContentLink_set_input>;
  pk_columns: v1_ContentLink_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ContentLink_manyArgs = {
  updates: Array<v1_ContentLink_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ContentTypesArgs = {
  _inc?: InputMaybe<v1_ContentTypes_inc_input>;
  _set?: InputMaybe<v1_ContentTypes_set_input>;
  where: v1_ContentTypes_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ContentTypes_by_pkArgs = {
  _inc?: InputMaybe<v1_ContentTypes_inc_input>;
  _set?: InputMaybe<v1_ContentTypes_set_input>;
  pk_columns: v1_ContentTypes_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ContentTypes_manyArgs = {
  updates: Array<v1_ContentTypes_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Credential_RolesArgs = {
  _inc?: InputMaybe<v1_Credential_Roles_inc_input>;
  _set?: InputMaybe<v1_Credential_Roles_set_input>;
  where: v1_Credential_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Credential_Roles_by_pkArgs = {
  _inc?: InputMaybe<v1_Credential_Roles_inc_input>;
  _set?: InputMaybe<v1_Credential_Roles_set_input>;
  pk_columns: v1_Credential_Roles_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Credential_Roles_manyArgs = {
  updates: Array<v1_Credential_Roles_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_CredentialsArgs = {
  _inc?: InputMaybe<v1_Credentials_inc_input>;
  _set?: InputMaybe<v1_Credentials_set_input>;
  where: v1_Credentials_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Credentials_by_pkArgs = {
  _inc?: InputMaybe<v1_Credentials_inc_input>;
  _set?: InputMaybe<v1_Credentials_set_input>;
  pk_columns: v1_Credentials_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Credentials_manyArgs = {
  updates: Array<v1_Credentials_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_DomainExtensionsArgs = {
  _inc?: InputMaybe<v1_DomainExtensions_inc_input>;
  _set?: InputMaybe<v1_DomainExtensions_set_input>;
  where: v1_DomainExtensions_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_DomainExtensions_by_pkArgs = {
  _inc?: InputMaybe<v1_DomainExtensions_inc_input>;
  _set?: InputMaybe<v1_DomainExtensions_set_input>;
  pk_columns: v1_DomainExtensions_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_DomainExtensions_manyArgs = {
  updates: Array<v1_DomainExtensions_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_EventsArgs = {
  _append?: InputMaybe<v1_Events_append_input>;
  _delete_at_path?: InputMaybe<v1_Events_delete_at_path_input>;
  _delete_elem?: InputMaybe<v1_Events_delete_elem_input>;
  _delete_key?: InputMaybe<v1_Events_delete_key_input>;
  _inc?: InputMaybe<v1_Events_inc_input>;
  _prepend?: InputMaybe<v1_Events_prepend_input>;
  _set?: InputMaybe<v1_Events_set_input>;
  where: v1_Events_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Events_by_pkArgs = {
  _append?: InputMaybe<v1_Events_append_input>;
  _delete_at_path?: InputMaybe<v1_Events_delete_at_path_input>;
  _delete_elem?: InputMaybe<v1_Events_delete_elem_input>;
  _delete_key?: InputMaybe<v1_Events_delete_key_input>;
  _inc?: InputMaybe<v1_Events_inc_input>;
  _prepend?: InputMaybe<v1_Events_prepend_input>;
  _set?: InputMaybe<v1_Events_set_input>;
  pk_columns: v1_Events_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Events_manyArgs = {
  updates: Array<v1_Events_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ExpiringRecPaymentsArgs = {
  _inc?: InputMaybe<v1_ExpiringRecPayments_inc_input>;
  _set?: InputMaybe<v1_ExpiringRecPayments_set_input>;
  where: v1_ExpiringRecPayments_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ExpiringRecPayments_manyArgs = {
  updates: Array<v1_ExpiringRecPayments_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_FilesArgs = {
  _inc?: InputMaybe<v1_Files_inc_input>;
  _set?: InputMaybe<v1_Files_set_input>;
  where: v1_Files_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Files_by_pkArgs = {
  _inc?: InputMaybe<v1_Files_inc_input>;
  _set?: InputMaybe<v1_Files_set_input>;
  pk_columns: v1_Files_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Files_manyArgs = {
  updates: Array<v1_Files_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_GameTypeArgs = {
  _inc?: InputMaybe<v1_GameType_inc_input>;
  _set?: InputMaybe<v1_GameType_set_input>;
  where: v1_GameType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_GameType_by_pkArgs = {
  _inc?: InputMaybe<v1_GameType_inc_input>;
  _set?: InputMaybe<v1_GameType_set_input>;
  pk_columns: v1_GameType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_GameType_manyArgs = {
  updates: Array<v1_GameType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_InvoiceBaseArgs = {
  _inc?: InputMaybe<v1_InvoiceBase_inc_input>;
  _set?: InputMaybe<v1_InvoiceBase_set_input>;
  where: v1_InvoiceBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_InvoiceBase_by_pkArgs = {
  _inc?: InputMaybe<v1_InvoiceBase_inc_input>;
  _set?: InputMaybe<v1_InvoiceBase_set_input>;
  pk_columns: v1_InvoiceBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_InvoiceBase_manyArgs = {
  updates: Array<v1_InvoiceBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_InvoiceDetailBaseArgs = {
  _inc?: InputMaybe<v1_InvoiceDetailBase_inc_input>;
  _set?: InputMaybe<v1_InvoiceDetailBase_set_input>;
  where: v1_InvoiceDetailBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_InvoiceDetailBase_by_pkArgs = {
  _inc?: InputMaybe<v1_InvoiceDetailBase_inc_input>;
  _set?: InputMaybe<v1_InvoiceDetailBase_set_input>;
  pk_columns: v1_InvoiceDetailBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_InvoiceDetailBase_manyArgs = {
  updates: Array<v1_InvoiceDetailBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_InvoiceStatusArgs = {
  _inc?: InputMaybe<v1_InvoiceStatus_inc_input>;
  _set?: InputMaybe<v1_InvoiceStatus_set_input>;
  where: v1_InvoiceStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_InvoiceStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_InvoiceStatus_inc_input>;
  _set?: InputMaybe<v1_InvoiceStatus_set_input>;
  pk_columns: v1_InvoiceStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_InvoiceStatus_manyArgs = {
  updates: Array<v1_InvoiceStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_LandingPagePriorityArgs = {
  _inc?: InputMaybe<v1_LandingPagePriority_inc_input>;
  _set?: InputMaybe<v1_LandingPagePriority_set_input>;
  where: v1_LandingPagePriority_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_LandingPagePriority_by_pkArgs = {
  _inc?: InputMaybe<v1_LandingPagePriority_inc_input>;
  _set?: InputMaybe<v1_LandingPagePriority_set_input>;
  pk_columns: v1_LandingPagePriority_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_LandingPagePriority_manyArgs = {
  updates: Array<v1_LandingPagePriority_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_LandingPagesArgs = {
  _inc?: InputMaybe<v1_LandingPages_inc_input>;
  _set?: InputMaybe<v1_LandingPages_set_input>;
  where: v1_LandingPages_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_LandingPages_by_pkArgs = {
  _inc?: InputMaybe<v1_LandingPages_inc_input>;
  _set?: InputMaybe<v1_LandingPages_set_input>;
  pk_columns: v1_LandingPages_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_LandingPages_manyArgs = {
  updates: Array<v1_LandingPages_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_LanguagePriceStepArgs = {
  _inc?: InputMaybe<v1_LanguagePriceStep_inc_input>;
  _set?: InputMaybe<v1_LanguagePriceStep_set_input>;
  where: v1_LanguagePriceStep_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_LanguagePriceStep_by_pkArgs = {
  _inc?: InputMaybe<v1_LanguagePriceStep_inc_input>;
  _set?: InputMaybe<v1_LanguagePriceStep_set_input>;
  pk_columns: v1_LanguagePriceStep_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_LanguagePriceStep_manyArgs = {
  updates: Array<v1_LanguagePriceStep_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceCategoriesArgs = {
  _inc?: InputMaybe<v1_MarketPlaceCategories_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceCategories_set_input>;
  where: v1_MarketPlaceCategories_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceCategoriesTypesArgs = {
  _inc?: InputMaybe<v1_MarketPlaceCategoriesTypes_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceCategoriesTypes_set_input>;
  where: v1_MarketPlaceCategoriesTypes_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceCategoriesTypes_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketPlaceCategoriesTypes_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceCategoriesTypes_set_input>;
  pk_columns: v1_MarketPlaceCategoriesTypes_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceCategoriesTypes_manyArgs = {
  updates: Array<v1_MarketPlaceCategoriesTypes_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceCategories_OffersArgs = {
  _inc?: InputMaybe<v1_MarketPlaceCategories_Offers_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceCategories_Offers_set_input>;
  where: v1_MarketPlaceCategories_Offers_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceCategories_Offers_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketPlaceCategories_Offers_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceCategories_Offers_set_input>;
  pk_columns: v1_MarketPlaceCategories_Offers_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceCategories_Offers_manyArgs = {
  updates: Array<v1_MarketPlaceCategories_Offers_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceCategories_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketPlaceCategories_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceCategories_set_input>;
  pk_columns: v1_MarketPlaceCategories_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceCategories_manyArgs = {
  updates: Array<v1_MarketPlaceCategories_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOfferNicheArgs = {
  _inc?: InputMaybe<v1_MarketPlaceOfferNiche_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceOfferNiche_set_input>;
  where: v1_MarketPlaceOfferNiche_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOfferNiche_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketPlaceOfferNiche_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceOfferNiche_set_input>;
  pk_columns: v1_MarketPlaceOfferNiche_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOfferNiche_manyArgs = {
  updates: Array<v1_MarketPlaceOfferNiche_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOrderArgs = {
  _inc?: InputMaybe<v1_MarketPlaceOrder_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceOrder_set_input>;
  where: v1_MarketPlaceOrder_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOrderRequestArgs = {
  _inc?: InputMaybe<v1_MarketPlaceOrderRequest_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceOrderRequest_set_input>;
  where: v1_MarketPlaceOrderRequest_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOrderRequest_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketPlaceOrderRequest_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceOrderRequest_set_input>;
  pk_columns: v1_MarketPlaceOrderRequest_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOrderRequest_manyArgs = {
  updates: Array<v1_MarketPlaceOrderRequest_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOrderStatusArgs = {
  _inc?: InputMaybe<v1_MarketPlaceOrderStatus_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceOrderStatus_set_input>;
  where: v1_MarketPlaceOrderStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOrderStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketPlaceOrderStatus_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceOrderStatus_set_input>;
  pk_columns: v1_MarketPlaceOrderStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOrderStatus_manyArgs = {
  updates: Array<v1_MarketPlaceOrderStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOrder_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketPlaceOrder_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceOrder_set_input>;
  pk_columns: v1_MarketPlaceOrder_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceOrder_manyArgs = {
  updates: Array<v1_MarketPlaceOrder_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceRegisterStatusArgs = {
  _inc?: InputMaybe<v1_MarketPlaceRegisterStatus_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceRegisterStatus_set_input>;
  where: v1_MarketPlaceRegisterStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceRegisterStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketPlaceRegisterStatus_inc_input>;
  _set?: InputMaybe<v1_MarketPlaceRegisterStatus_set_input>;
  pk_columns: v1_MarketPlaceRegisterStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketPlaceRegisterStatus_manyArgs = {
  updates: Array<v1_MarketPlaceRegisterStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketTypeArgs = {
  _inc?: InputMaybe<v1_MarketType_inc_input>;
  _set?: InputMaybe<v1_MarketType_set_input>;
  where: v1_MarketType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketType_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketType_inc_input>;
  _set?: InputMaybe<v1_MarketType_set_input>;
  pk_columns: v1_MarketType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketType_manyArgs = {
  updates: Array<v1_MarketType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketplaceProjectsArgs = {
  _inc?: InputMaybe<v1_MarketplaceProjects_inc_input>;
  _set?: InputMaybe<v1_MarketplaceProjects_set_input>;
  where: v1_MarketplaceProjects_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketplaceProjects_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketplaceProjects_inc_input>;
  _set?: InputMaybe<v1_MarketplaceProjects_set_input>;
  pk_columns: v1_MarketplaceProjects_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketplaceProjects_manyArgs = {
  updates: Array<v1_MarketplaceProjects_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketplaceStatusArgs = {
  _inc?: InputMaybe<v1_MarketplaceStatus_inc_input>;
  _set?: InputMaybe<v1_MarketplaceStatus_set_input>;
  where: v1_MarketplaceStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketplaceStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_MarketplaceStatus_inc_input>;
  _set?: InputMaybe<v1_MarketplaceStatus_set_input>;
  pk_columns: v1_MarketplaceStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_MarketplaceStatus_manyArgs = {
  updates: Array<v1_MarketplaceStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_MonthsArgs = {
  _inc?: InputMaybe<v1_Months_inc_input>;
  _set?: InputMaybe<v1_Months_set_input>;
  where: v1_Months_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Months_by_pkArgs = {
  _inc?: InputMaybe<v1_Months_inc_input>;
  _set?: InputMaybe<v1_Months_set_input>;
  pk_columns: v1_Months_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Months_manyArgs = {
  updates: Array<v1_Months_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferArgs = {
  _inc?: InputMaybe<v1_Offer_inc_input>;
  _set?: InputMaybe<v1_Offer_set_input>;
  where: v1_Offer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferStatusArgs = {
  _inc?: InputMaybe<v1_OfferStatus_inc_input>;
  _set?: InputMaybe<v1_OfferStatus_set_input>;
  where: v1_OfferStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_OfferStatus_inc_input>;
  _set?: InputMaybe<v1_OfferStatus_set_input>;
  pk_columns: v1_OfferStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferStatus_manyArgs = {
  updates: Array<v1_OfferStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferTagsArgs = {
  _inc?: InputMaybe<v1_OfferTags_inc_input>;
  _set?: InputMaybe<v1_OfferTags_set_input>;
  where: v1_OfferTags_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferTags_by_pkArgs = {
  _inc?: InputMaybe<v1_OfferTags_inc_input>;
  _set?: InputMaybe<v1_OfferTags_set_input>;
  pk_columns: v1_OfferTags_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferTags_manyArgs = {
  updates: Array<v1_OfferTags_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferToTagsArgs = {
  _inc?: InputMaybe<v1_OfferToTags_inc_input>;
  _set?: InputMaybe<v1_OfferToTags_set_input>;
  where: v1_OfferToTags_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferToTags_by_pkArgs = {
  _inc?: InputMaybe<v1_OfferToTags_inc_input>;
  _set?: InputMaybe<v1_OfferToTags_set_input>;
  pk_columns: v1_OfferToTags_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_OfferToTags_manyArgs = {
  updates: Array<v1_OfferToTags_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Offer_by_pkArgs = {
  _inc?: InputMaybe<v1_Offer_inc_input>;
  _set?: InputMaybe<v1_Offer_set_input>;
  pk_columns: v1_Offer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Offer_manyArgs = {
  updates: Array<v1_Offer_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderForArgs = {
  _inc?: InputMaybe<v1_OrderFor_inc_input>;
  _set?: InputMaybe<v1_OrderFor_set_input>;
  where: v1_OrderFor_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderFor_by_pkArgs = {
  _inc?: InputMaybe<v1_OrderFor_inc_input>;
  _set?: InputMaybe<v1_OrderFor_set_input>;
  pk_columns: v1_OrderFor_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderFor_manyArgs = {
  updates: Array<v1_OrderFor_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderPayToArgs = {
  _inc?: InputMaybe<v1_OrderPayTo_inc_input>;
  _set?: InputMaybe<v1_OrderPayTo_set_input>;
  where: v1_OrderPayTo_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderPayTo_by_pkArgs = {
  _inc?: InputMaybe<v1_OrderPayTo_inc_input>;
  _set?: InputMaybe<v1_OrderPayTo_set_input>;
  pk_columns: v1_OrderPayTo_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderPayTo_manyArgs = {
  updates: Array<v1_OrderPayTo_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderPriorityArgs = {
  _inc?: InputMaybe<v1_OrderPriority_inc_input>;
  _set?: InputMaybe<v1_OrderPriority_set_input>;
  where: v1_OrderPriority_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderPriority_by_pkArgs = {
  _inc?: InputMaybe<v1_OrderPriority_inc_input>;
  _set?: InputMaybe<v1_OrderPriority_set_input>;
  pk_columns: v1_OrderPriority_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderPriority_manyArgs = {
  updates: Array<v1_OrderPriority_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderTypeArgs = {
  _inc?: InputMaybe<v1_OrderType_inc_input>;
  _set?: InputMaybe<v1_OrderType_set_input>;
  where: v1_OrderType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderType_by_pkArgs = {
  _inc?: InputMaybe<v1_OrderType_inc_input>;
  _set?: InputMaybe<v1_OrderType_set_input>;
  pk_columns: v1_OrderType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_OrderType_manyArgs = {
  updates: Array<v1_OrderType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PageArgs = {
  _inc?: InputMaybe<v1_Page_inc_input>;
  _set?: InputMaybe<v1_Page_set_input>;
  where: v1_Page_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Page_by_pkArgs = {
  _inc?: InputMaybe<v1_Page_inc_input>;
  _set?: InputMaybe<v1_Page_set_input>;
  pk_columns: v1_Page_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Page_manyArgs = {
  updates: Array<v1_Page_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PaymentCompaniesArgs = {
  _inc?: InputMaybe<v1_PaymentCompanies_inc_input>;
  _set?: InputMaybe<v1_PaymentCompanies_set_input>;
  where: v1_PaymentCompanies_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PaymentCompanies_by_pkArgs = {
  _inc?: InputMaybe<v1_PaymentCompanies_inc_input>;
  _set?: InputMaybe<v1_PaymentCompanies_set_input>;
  pk_columns: v1_PaymentCompanies_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PaymentCompanies_manyArgs = {
  updates: Array<v1_PaymentCompanies_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PaymentFrequencyArgs = {
  _inc?: InputMaybe<v1_PaymentFrequency_inc_input>;
  _set?: InputMaybe<v1_PaymentFrequency_set_input>;
  where: v1_PaymentFrequency_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PaymentFrequency_by_pkArgs = {
  _inc?: InputMaybe<v1_PaymentFrequency_inc_input>;
  _set?: InputMaybe<v1_PaymentFrequency_set_input>;
  pk_columns: v1_PaymentFrequency_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PaymentFrequency_manyArgs = {
  updates: Array<v1_PaymentFrequency_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PoToPostsArgs = {
  _inc?: InputMaybe<v1_PoToPosts_inc_input>;
  _set?: InputMaybe<v1_PoToPosts_set_input>;
  where: v1_PoToPosts_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PoToPosts_by_pkArgs = {
  _inc?: InputMaybe<v1_PoToPosts_inc_input>;
  _set?: InputMaybe<v1_PoToPosts_set_input>;
  pk_columns: v1_PoToPosts_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PoToPosts_manyArgs = {
  updates: Array<v1_PoToPosts_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostDurationArgs = {
  _inc?: InputMaybe<v1_PostDuration_inc_input>;
  _set?: InputMaybe<v1_PostDuration_set_input>;
  where: v1_PostDuration_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostDuration_by_pkArgs = {
  _inc?: InputMaybe<v1_PostDuration_inc_input>;
  _set?: InputMaybe<v1_PostDuration_set_input>;
  pk_columns: v1_PostDuration_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostDuration_manyArgs = {
  updates: Array<v1_PostDuration_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostNicheArgs = {
  _inc?: InputMaybe<v1_PostNiche_inc_input>;
  _set?: InputMaybe<v1_PostNiche_set_input>;
  where: v1_PostNiche_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostNiche_by_pkArgs = {
  _inc?: InputMaybe<v1_PostNiche_inc_input>;
  _set?: InputMaybe<v1_PostNiche_set_input>;
  pk_columns: v1_PostNiche_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostNiche_manyArgs = {
  updates: Array<v1_PostNiche_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsArgs = {
  _inc?: InputMaybe<v1_Posts_inc_input>;
  _set?: InputMaybe<v1_Posts_set_input>;
  where: v1_Posts_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsAnchorTextTypeArgs = {
  _inc?: InputMaybe<v1_PostsAnchorTextType_inc_input>;
  _set?: InputMaybe<v1_PostsAnchorTextType_set_input>;
  where: v1_PostsAnchorTextType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsAnchorTextType_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsAnchorTextType_inc_input>;
  _set?: InputMaybe<v1_PostsAnchorTextType_set_input>;
  pk_columns: v1_PostsAnchorTextType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsAnchorTextType_manyArgs = {
  updates: Array<v1_PostsAnchorTextType_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsContentStatusArgs = {
  _inc?: InputMaybe<v1_PostsContentStatus_inc_input>;
  _set?: InputMaybe<v1_PostsContentStatus_set_input>;
  where: v1_PostsContentStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsContentStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsContentStatus_inc_input>;
  _set?: InputMaybe<v1_PostsContentStatus_set_input>;
  pk_columns: v1_PostsContentStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsContentStatus_manyArgs = {
  updates: Array<v1_PostsContentStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsCopyScapeArgs = {
  _inc?: InputMaybe<v1_PostsCopyScape_inc_input>;
  _set?: InputMaybe<v1_PostsCopyScape_set_input>;
  where: v1_PostsCopyScape_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsCopyScape_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsCopyScape_inc_input>;
  _set?: InputMaybe<v1_PostsCopyScape_set_input>;
  pk_columns: v1_PostsCopyScape_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsCopyScape_manyArgs = {
  updates: Array<v1_PostsCopyScape_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsLinkStatusArgs = {
  _inc?: InputMaybe<v1_PostsLinkStatus_inc_input>;
  _set?: InputMaybe<v1_PostsLinkStatus_set_input>;
  where: v1_PostsLinkStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsLinkStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsLinkStatus_inc_input>;
  _set?: InputMaybe<v1_PostsLinkStatus_set_input>;
  pk_columns: v1_PostsLinkStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsLinkStatus_manyArgs = {
  updates: Array<v1_PostsLinkStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsOnlineStatusArgs = {
  _inc?: InputMaybe<v1_PostsOnlineStatus_inc_input>;
  _set?: InputMaybe<v1_PostsOnlineStatus_set_input>;
  where: v1_PostsOnlineStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsOnlineStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsOnlineStatus_inc_input>;
  _set?: InputMaybe<v1_PostsOnlineStatus_set_input>;
  pk_columns: v1_PostsOnlineStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsOnlineStatus_manyArgs = {
  updates: Array<v1_PostsOnlineStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsOutreachStatusArgs = {
  _inc?: InputMaybe<v1_PostsOutreachStatus_inc_input>;
  _set?: InputMaybe<v1_PostsOutreachStatus_set_input>;
  where: v1_PostsOutreachStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsOutreachStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsOutreachStatus_inc_input>;
  _set?: InputMaybe<v1_PostsOutreachStatus_set_input>;
  pk_columns: v1_PostsOutreachStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsOutreachStatus_manyArgs = {
  updates: Array<v1_PostsOutreachStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsPageTypeDeprecatedArgs = {
  _inc?: InputMaybe<v1_PostsPageTypeDeprecated_inc_input>;
  _set?: InputMaybe<v1_PostsPageTypeDeprecated_set_input>;
  where: v1_PostsPageTypeDeprecated_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsPageTypeDeprecated_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsPageTypeDeprecated_inc_input>;
  _set?: InputMaybe<v1_PostsPageTypeDeprecated_set_input>;
  pk_columns: v1_PostsPageTypeDeprecated_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsPageTypeDeprecated_manyArgs = {
  updates: Array<v1_PostsPageTypeDeprecated_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsRemovalStatusArgs = {
  _inc?: InputMaybe<v1_PostsRemovalStatus_inc_input>;
  _set?: InputMaybe<v1_PostsRemovalStatus_set_input>;
  where: v1_PostsRemovalStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsRemovalStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsRemovalStatus_inc_input>;
  _set?: InputMaybe<v1_PostsRemovalStatus_set_input>;
  pk_columns: v1_PostsRemovalStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsRemovalStatus_manyArgs = {
  updates: Array<v1_PostsRemovalStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsSEOApprovalArgs = {
  _inc?: InputMaybe<v1_PostsSEOApproval_inc_input>;
  _set?: InputMaybe<v1_PostsSEOApproval_set_input>;
  where: v1_PostsSEOApproval_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsSEOApproval_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsSEOApproval_inc_input>;
  _set?: InputMaybe<v1_PostsSEOApproval_set_input>;
  pk_columns: v1_PostsSEOApproval_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsSEOApproval_manyArgs = {
  updates: Array<v1_PostsSEOApproval_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsSEODeclineReasonArgs = {
  _inc?: InputMaybe<v1_PostsSEODeclineReason_inc_input>;
  _set?: InputMaybe<v1_PostsSEODeclineReason_set_input>;
  where: v1_PostsSEODeclineReason_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsSEODeclineReason_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsSEODeclineReason_inc_input>;
  _set?: InputMaybe<v1_PostsSEODeclineReason_set_input>;
  pk_columns: v1_PostsSEODeclineReason_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsSEODeclineReason_manyArgs = {
  updates: Array<v1_PostsSEODeclineReason_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsStatusArgs = {
  _inc?: InputMaybe<v1_PostsStatus_inc_input>;
  _set?: InputMaybe<v1_PostsStatus_set_input>;
  where: v1_PostsStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsStatusCodeArgs = {
  _inc?: InputMaybe<v1_PostsStatusCode_inc_input>;
  _set?: InputMaybe<v1_PostsStatusCode_set_input>;
  where: v1_PostsStatusCode_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsStatusCode_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsStatusCode_inc_input>;
  _set?: InputMaybe<v1_PostsStatusCode_set_input>;
  pk_columns: v1_PostsStatusCode_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsStatusCode_manyArgs = {
  updates: Array<v1_PostsStatusCode_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_PostsStatus_inc_input>;
  _set?: InputMaybe<v1_PostsStatus_set_input>;
  pk_columns: v1_PostsStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PostsStatus_manyArgs = {
  updates: Array<v1_PostsStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Posts_by_pkArgs = {
  _inc?: InputMaybe<v1_Posts_inc_input>;
  _set?: InputMaybe<v1_Posts_set_input>;
  pk_columns: v1_Posts_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Posts_manyArgs = {
  updates: Array<v1_Posts_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PreferencesArgs = {
  _inc?: InputMaybe<v1_Preferences_inc_input>;
  _set?: InputMaybe<v1_Preferences_set_input>;
  where: v1_Preferences_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PreferencesMarketplaceArgs = {
  _inc?: InputMaybe<v1_PreferencesMarketplace_inc_input>;
  _set?: InputMaybe<v1_PreferencesMarketplace_set_input>;
  where: v1_PreferencesMarketplace_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PreferencesMarketplace_by_pkArgs = {
  _inc?: InputMaybe<v1_PreferencesMarketplace_inc_input>;
  _set?: InputMaybe<v1_PreferencesMarketplace_set_input>;
  pk_columns: v1_PreferencesMarketplace_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PreferencesMarketplace_manyArgs = {
  updates: Array<v1_PreferencesMarketplace_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Preferences_by_pkArgs = {
  _inc?: InputMaybe<v1_Preferences_inc_input>;
  _set?: InputMaybe<v1_Preferences_set_input>;
  pk_columns: v1_Preferences_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Preferences_manyArgs = {
  updates: Array<v1_Preferences_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectArgs = {
  _inc?: InputMaybe<v1_Project_inc_input>;
  _set?: InputMaybe<v1_Project_set_input>;
  where: v1_Project_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectImportanceArgs = {
  _inc?: InputMaybe<v1_ProjectImportance_inc_input>;
  _set?: InputMaybe<v1_ProjectImportance_set_input>;
  where: v1_ProjectImportance_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectImportance_by_pkArgs = {
  _inc?: InputMaybe<v1_ProjectImportance_inc_input>;
  _set?: InputMaybe<v1_ProjectImportance_set_input>;
  pk_columns: v1_ProjectImportance_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectImportance_manyArgs = {
  updates: Array<v1_ProjectImportance_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectStatusArgs = {
  _inc?: InputMaybe<v1_ProjectStatus_inc_input>;
  _set?: InputMaybe<v1_ProjectStatus_set_input>;
  where: v1_ProjectStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_ProjectStatus_inc_input>;
  _set?: InputMaybe<v1_ProjectStatus_set_input>;
  pk_columns: v1_ProjectStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectStatus_manyArgs = {
  updates: Array<v1_ProjectStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectTopicArgs = {
  _inc?: InputMaybe<v1_ProjectTopic_inc_input>;
  _set?: InputMaybe<v1_ProjectTopic_set_input>;
  where: v1_ProjectTopic_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectTopic_by_pkArgs = {
  _inc?: InputMaybe<v1_ProjectTopic_inc_input>;
  _set?: InputMaybe<v1_ProjectTopic_set_input>;
  pk_columns: v1_ProjectTopic_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ProjectTopic_manyArgs = {
  updates: Array<v1_ProjectTopic_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Project_CountriesArgs = {
  _inc?: InputMaybe<v1_Project_Countries_inc_input>;
  _set?: InputMaybe<v1_Project_Countries_set_input>;
  where: v1_Project_Countries_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Project_Countries_by_pkArgs = {
  _inc?: InputMaybe<v1_Project_Countries_inc_input>;
  _set?: InputMaybe<v1_Project_Countries_set_input>;
  pk_columns: v1_Project_Countries_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Project_Countries_manyArgs = {
  updates: Array<v1_Project_Countries_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Project_by_pkArgs = {
  _inc?: InputMaybe<v1_Project_inc_input>;
  _set?: InputMaybe<v1_Project_set_input>;
  pk_columns: v1_Project_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Project_manyArgs = {
  updates: Array<v1_Project_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PublisherPostStatusArgs = {
  _inc?: InputMaybe<v1_PublisherPostStatus_inc_input>;
  _set?: InputMaybe<v1_PublisherPostStatus_set_input>;
  where: v1_PublisherPostStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PublisherPostStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_PublisherPostStatus_inc_input>;
  _set?: InputMaybe<v1_PublisherPostStatus_set_input>;
  pk_columns: v1_PublisherPostStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PublisherPostStatus_manyArgs = {
  updates: Array<v1_PublisherPostStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_PurchaseOrderStatusArgs = {
  _inc?: InputMaybe<v1_PurchaseOrderStatus_inc_input>;
  _set?: InputMaybe<v1_PurchaseOrderStatus_set_input>;
  where: v1_PurchaseOrderStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_PurchaseOrderStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_PurchaseOrderStatus_inc_input>;
  _set?: InputMaybe<v1_PurchaseOrderStatus_set_input>;
  pk_columns: v1_PurchaseOrderStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_PurchaseOrderStatus_manyArgs = {
  updates: Array<v1_PurchaseOrderStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ReceiptStatusArgs = {
  _inc?: InputMaybe<v1_ReceiptStatus_inc_input>;
  _set?: InputMaybe<v1_ReceiptStatus_set_input>;
  where: v1_ReceiptStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ReceiptStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_ReceiptStatus_inc_input>;
  _set?: InputMaybe<v1_ReceiptStatus_set_input>;
  pk_columns: v1_ReceiptStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ReceiptStatus_manyArgs = {
  updates: Array<v1_ReceiptStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_RecurringPaymentStatusArgs = {
  _inc?: InputMaybe<v1_RecurringPaymentStatus_inc_input>;
  _set?: InputMaybe<v1_RecurringPaymentStatus_set_input>;
  where: v1_RecurringPaymentStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_RecurringPaymentStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_RecurringPaymentStatus_inc_input>;
  _set?: InputMaybe<v1_RecurringPaymentStatus_set_input>;
  pk_columns: v1_RecurringPaymentStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_RecurringPaymentStatus_manyArgs = {
  updates: Array<v1_RecurringPaymentStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_RecurringPaymentsArgs = {
  _inc?: InputMaybe<v1_RecurringPayments_inc_input>;
  _set?: InputMaybe<v1_RecurringPayments_set_input>;
  where: v1_RecurringPayments_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_RecurringPayments_RolesArgs = {
  _inc?: InputMaybe<v1_RecurringPayments_Roles_inc_input>;
  _set?: InputMaybe<v1_RecurringPayments_Roles_set_input>;
  where: v1_RecurringPayments_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_RecurringPayments_Roles_by_pkArgs = {
  _inc?: InputMaybe<v1_RecurringPayments_Roles_inc_input>;
  _set?: InputMaybe<v1_RecurringPayments_Roles_set_input>;
  pk_columns: v1_RecurringPayments_Roles_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_RecurringPayments_Roles_manyArgs = {
  updates: Array<v1_RecurringPayments_Roles_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_RecurringPayments_by_pkArgs = {
  _inc?: InputMaybe<v1_RecurringPayments_inc_input>;
  _set?: InputMaybe<v1_RecurringPayments_set_input>;
  pk_columns: v1_RecurringPayments_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_RecurringPayments_manyArgs = {
  updates: Array<v1_RecurringPayments_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ReportArgs = {
  _inc?: InputMaybe<v1_Report_inc_input>;
  _set?: InputMaybe<v1_Report_set_input>;
  where: v1_Report_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Report_RolesArgs = {
  _inc?: InputMaybe<v1_Report_Roles_inc_input>;
  _set?: InputMaybe<v1_Report_Roles_set_input>;
  where: v1_Report_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Report_Roles_by_pkArgs = {
  _inc?: InputMaybe<v1_Report_Roles_inc_input>;
  _set?: InputMaybe<v1_Report_Roles_set_input>;
  pk_columns: v1_Report_Roles_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Report_Roles_manyArgs = {
  updates: Array<v1_Report_Roles_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Report_by_pkArgs = {
  _inc?: InputMaybe<v1_Report_inc_input>;
  _set?: InputMaybe<v1_Report_set_input>;
  pk_columns: v1_Report_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Report_manyArgs = {
  updates: Array<v1_Report_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_RestrictedNichesToOfferArgs = {
  _inc?: InputMaybe<v1_RestrictedNichesToOffer_inc_input>;
  _set?: InputMaybe<v1_RestrictedNichesToOffer_set_input>;
  where: v1_RestrictedNichesToOffer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_RestrictedNichesToOffer_by_pkArgs = {
  _inc?: InputMaybe<v1_RestrictedNichesToOffer_inc_input>;
  _set?: InputMaybe<v1_RestrictedNichesToOffer_set_input>;
  pk_columns: v1_RestrictedNichesToOffer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_RestrictedNichesToOffer_manyArgs = {
  updates: Array<v1_RestrictedNichesToOffer_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_RolesArgs = {
  _inc?: InputMaybe<v1_Roles_inc_input>;
  _set?: InputMaybe<v1_Roles_set_input>;
  where: v1_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Roles_by_pkArgs = {
  _inc?: InputMaybe<v1_Roles_inc_input>;
  _set?: InputMaybe<v1_Roles_set_input>;
  pk_columns: v1_Roles_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Roles_manyArgs = {
  updates: Array<v1_Roles_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_SalesOrderBaseArgs = {
  _inc?: InputMaybe<v1_SalesOrderBase_inc_input>;
  _set?: InputMaybe<v1_SalesOrderBase_set_input>;
  where: v1_SalesOrderBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_SalesOrderBase_by_pkArgs = {
  _inc?: InputMaybe<v1_SalesOrderBase_inc_input>;
  _set?: InputMaybe<v1_SalesOrderBase_set_input>;
  pk_columns: v1_SalesOrderBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_SalesOrderBase_manyArgs = {
  updates: Array<v1_SalesOrderBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_ServiceTypesArgs = {
  _inc?: InputMaybe<v1_ServiceTypes_inc_input>;
  _set?: InputMaybe<v1_ServiceTypes_set_input>;
  where: v1_ServiceTypes_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_ServiceTypes_by_pkArgs = {
  _inc?: InputMaybe<v1_ServiceTypes_inc_input>;
  _set?: InputMaybe<v1_ServiceTypes_set_input>;
  pk_columns: v1_ServiceTypes_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_ServiceTypes_manyArgs = {
  updates: Array<v1_ServiceTypes_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_SoftwareProvidersArgs = {
  _inc?: InputMaybe<v1_SoftwareProviders_inc_input>;
  _set?: InputMaybe<v1_SoftwareProviders_set_input>;
  where: v1_SoftwareProviders_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_SoftwareProviders_by_pkArgs = {
  _inc?: InputMaybe<v1_SoftwareProviders_inc_input>;
  _set?: InputMaybe<v1_SoftwareProviders_set_input>;
  pk_columns: v1_SoftwareProviders_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_SoftwareProviders_manyArgs = {
  updates: Array<v1_SoftwareProviders_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_StrategyFieldsSetArgs = {
  _inc?: InputMaybe<v1_StrategyFieldsSet_inc_input>;
  _set?: InputMaybe<v1_StrategyFieldsSet_set_input>;
  where: v1_StrategyFieldsSet_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_StrategyFieldsSet_by_pkArgs = {
  _inc?: InputMaybe<v1_StrategyFieldsSet_inc_input>;
  _set?: InputMaybe<v1_StrategyFieldsSet_set_input>;
  pk_columns: v1_StrategyFieldsSet_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_StrategyFieldsSet_manyArgs = {
  updates: Array<v1_StrategyFieldsSet_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Supplier_Tag_relArgs = {
  _inc?: InputMaybe<v1_Supplier_Tag_rel_inc_input>;
  _set?: InputMaybe<v1_Supplier_Tag_rel_set_input>;
  where: v1_Supplier_Tag_rel_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Supplier_Tag_rel_by_pkArgs = {
  _inc?: InputMaybe<v1_Supplier_Tag_rel_inc_input>;
  _set?: InputMaybe<v1_Supplier_Tag_rel_set_input>;
  pk_columns: v1_Supplier_Tag_rel_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Supplier_Tag_rel_manyArgs = {
  updates: Array<v1_Supplier_Tag_rel_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_SystemUserBaseArgs = {
  _inc?: InputMaybe<v1_SystemUserBase_inc_input>;
  _set?: InputMaybe<v1_SystemUserBase_set_input>;
  where: v1_SystemUserBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_SystemUserBase_by_pkArgs = {
  _inc?: InputMaybe<v1_SystemUserBase_inc_input>;
  _set?: InputMaybe<v1_SystemUserBase_set_input>;
  pk_columns: v1_SystemUserBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_SystemUserBase_manyArgs = {
  updates: Array<v1_SystemUserBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_TagsArgs = {
  _inc?: InputMaybe<v1_Tags_inc_input>;
  _set?: InputMaybe<v1_Tags_set_input>;
  where: v1_Tags_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Tags_by_pkArgs = {
  _inc?: InputMaybe<v1_Tags_inc_input>;
  _set?: InputMaybe<v1_Tags_set_input>;
  pk_columns: v1_Tags_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Tags_manyArgs = {
  updates: Array<v1_Tags_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_TeamsArgs = {
  _inc?: InputMaybe<v1_Teams_inc_input>;
  _set?: InputMaybe<v1_Teams_set_input>;
  where: v1_Teams_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Teams_by_pkArgs = {
  _inc?: InputMaybe<v1_Teams_inc_input>;
  _set?: InputMaybe<v1_Teams_set_input>;
  pk_columns: v1_Teams_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Teams_manyArgs = {
  updates: Array<v1_Teams_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_TrackerDealArgs = {
  _inc?: InputMaybe<v1_TrackerDeal_inc_input>;
  _set?: InputMaybe<v1_TrackerDeal_set_input>;
  where: v1_TrackerDeal_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_TrackerDeal_by_pkArgs = {
  _inc?: InputMaybe<v1_TrackerDeal_inc_input>;
  _set?: InputMaybe<v1_TrackerDeal_set_input>;
  pk_columns: v1_TrackerDeal_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_TrackerDeal_manyArgs = {
  updates: Array<v1_TrackerDeal_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_TrackerTagsArgs = {
  _inc?: InputMaybe<v1_TrackerTags_inc_input>;
  _set?: InputMaybe<v1_TrackerTags_set_input>;
  where: v1_TrackerTags_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_TrackerTags_by_pkArgs = {
  _inc?: InputMaybe<v1_TrackerTags_inc_input>;
  _set?: InputMaybe<v1_TrackerTags_set_input>;
  pk_columns: v1_TrackerTags_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_TrackerTags_manyArgs = {
  updates: Array<v1_TrackerTags_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_TrackerToTagsArgs = {
  _inc?: InputMaybe<v1_TrackerToTags_inc_input>;
  _set?: InputMaybe<v1_TrackerToTags_set_input>;
  where: v1_TrackerToTags_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_TrackerToTags_by_pkArgs = {
  _inc?: InputMaybe<v1_TrackerToTags_inc_input>;
  _set?: InputMaybe<v1_TrackerToTags_set_input>;
  pk_columns: v1_TrackerToTags_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_TrackerToTags_manyArgs = {
  updates: Array<v1_TrackerToTags_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_TransactionCurrencyBaseArgs = {
  _inc?: InputMaybe<v1_TransactionCurrencyBase_inc_input>;
  _set?: InputMaybe<v1_TransactionCurrencyBase_set_input>;
  where: v1_TransactionCurrencyBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_TransactionCurrencyBase_by_pkArgs = {
  _inc?: InputMaybe<v1_TransactionCurrencyBase_inc_input>;
  _set?: InputMaybe<v1_TransactionCurrencyBase_set_input>;
  pk_columns: v1_TransactionCurrencyBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_TransactionCurrencyBase_manyArgs = {
  updates: Array<v1_TransactionCurrencyBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_UsersStatusArgs = {
  _inc?: InputMaybe<v1_UsersStatus_inc_input>;
  _set?: InputMaybe<v1_UsersStatus_set_input>;
  where: v1_UsersStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_UsersStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_UsersStatus_inc_input>;
  _set?: InputMaybe<v1_UsersStatus_set_input>;
  pk_columns: v1_UsersStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_UsersStatus_manyArgs = {
  updates: Array<v1_UsersStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_UsersToOffersArgs = {
  _inc?: InputMaybe<v1_UsersToOffers_inc_input>;
  _set?: InputMaybe<v1_UsersToOffers_set_input>;
  where: v1_UsersToOffers_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_UsersToOffers_by_pkArgs = {
  _inc?: InputMaybe<v1_UsersToOffers_inc_input>;
  _set?: InputMaybe<v1_UsersToOffers_set_input>;
  pk_columns: v1_UsersToOffers_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_UsersToOffers_manyArgs = {
  updates: Array<v1_UsersToOffers_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Users_RolesArgs = {
  _inc?: InputMaybe<v1_Users_Roles_inc_input>;
  _set?: InputMaybe<v1_Users_Roles_set_input>;
  where: v1_Users_Roles_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Users_Roles_by_pkArgs = {
  _inc?: InputMaybe<v1_Users_Roles_inc_input>;
  _set?: InputMaybe<v1_Users_Roles_set_input>;
  pk_columns: v1_Users_Roles_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Users_Roles_manyArgs = {
  updates: Array<v1_Users_Roles_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Users_TeamsArgs = {
  _inc?: InputMaybe<v1_Users_Teams_inc_input>;
  _set?: InputMaybe<v1_Users_Teams_set_input>;
  where: v1_Users_Teams_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Users_Teams_by_pkArgs = {
  _inc?: InputMaybe<v1_Users_Teams_inc_input>;
  _set?: InputMaybe<v1_Users_Teams_set_input>;
  pk_columns: v1_Users_Teams_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Users_Teams_manyArgs = {
  updates: Array<v1_Users_Teams_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_WageringRequirementsLocationArgs = {
  _inc?: InputMaybe<v1_WageringRequirementsLocation_inc_input>;
  _set?: InputMaybe<v1_WageringRequirementsLocation_set_input>;
  where: v1_WageringRequirementsLocation_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WageringRequirementsLocation_by_pkArgs = {
  _inc?: InputMaybe<v1_WageringRequirementsLocation_inc_input>;
  _set?: InputMaybe<v1_WageringRequirementsLocation_set_input>;
  pk_columns: v1_WageringRequirementsLocation_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_WageringRequirementsLocation_manyArgs = {
  updates: Array<v1_WageringRequirementsLocation_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteArgs = {
  _inc?: InputMaybe<v1_Website_inc_input>;
  _set?: InputMaybe<v1_Website_set_input>;
  where: v1_Website_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteBlogProbabilityArgs = {
  _inc?: InputMaybe<v1_WebsiteBlogProbability_inc_input>;
  _set?: InputMaybe<v1_WebsiteBlogProbability_set_input>;
  where: v1_WebsiteBlogProbability_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteBlogProbability_by_pkArgs = {
  _inc?: InputMaybe<v1_WebsiteBlogProbability_inc_input>;
  _set?: InputMaybe<v1_WebsiteBlogProbability_set_input>;
  pk_columns: v1_WebsiteBlogProbability_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteBlogProbability_manyArgs = {
  updates: Array<v1_WebsiteBlogProbability_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteContentIndicatorArgs = {
  _inc?: InputMaybe<v1_WebsiteContentIndicator_inc_input>;
  _set?: InputMaybe<v1_WebsiteContentIndicator_set_input>;
  where: v1_WebsiteContentIndicator_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteContentIndicator_by_pkArgs = {
  _inc?: InputMaybe<v1_WebsiteContentIndicator_inc_input>;
  _set?: InputMaybe<v1_WebsiteContentIndicator_set_input>;
  pk_columns: v1_WebsiteContentIndicator_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteContentIndicator_manyArgs = {
  updates: Array<v1_WebsiteContentIndicator_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteDToxRiskArgs = {
  _inc?: InputMaybe<v1_WebsiteDToxRisk_inc_input>;
  _set?: InputMaybe<v1_WebsiteDToxRisk_set_input>;
  where: v1_WebsiteDToxRisk_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteDToxRisk_by_pkArgs = {
  _inc?: InputMaybe<v1_WebsiteDToxRisk_inc_input>;
  _set?: InputMaybe<v1_WebsiteDToxRisk_set_input>;
  pk_columns: v1_WebsiteDToxRisk_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteDToxRisk_manyArgs = {
  updates: Array<v1_WebsiteDToxRisk_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteQualityScoreArgs = {
  _inc?: InputMaybe<v1_WebsiteQualityScore_inc_input>;
  _set?: InputMaybe<v1_WebsiteQualityScore_set_input>;
  where: v1_WebsiteQualityScore_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteQualityScore_by_pkArgs = {
  _inc?: InputMaybe<v1_WebsiteQualityScore_inc_input>;
  _set?: InputMaybe<v1_WebsiteQualityScore_set_input>;
  pk_columns: v1_WebsiteQualityScore_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteQualityScore_manyArgs = {
  updates: Array<v1_WebsiteQualityScore_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteStatusArgs = {
  _inc?: InputMaybe<v1_WebsiteStatus_inc_input>;
  _set?: InputMaybe<v1_WebsiteStatus_set_input>;
  where: v1_WebsiteStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_WebsiteStatus_inc_input>;
  _set?: InputMaybe<v1_WebsiteStatus_set_input>;
  pk_columns: v1_WebsiteStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteStatus_manyArgs = {
  updates: Array<v1_WebsiteStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteTopicsArgs = {
  _inc?: InputMaybe<v1_WebsiteTopics_inc_input>;
  _set?: InputMaybe<v1_WebsiteTopics_set_input>;
  where: v1_WebsiteTopics_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteTopics_by_pkArgs = {
  _inc?: InputMaybe<v1_WebsiteTopics_inc_input>;
  _set?: InputMaybe<v1_WebsiteTopics_set_input>;
  pk_columns: v1_WebsiteTopics_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteTopics_manyArgs = {
  updates: Array<v1_WebsiteTopics_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteUptimeStatusArgs = {
  _inc?: InputMaybe<v1_WebsiteUptimeStatus_inc_input>;
  _set?: InputMaybe<v1_WebsiteUptimeStatus_set_input>;
  where: v1_WebsiteUptimeStatus_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteUptimeStatus_by_pkArgs = {
  _inc?: InputMaybe<v1_WebsiteUptimeStatus_inc_input>;
  _set?: InputMaybe<v1_WebsiteUptimeStatus_set_input>;
  pk_columns: v1_WebsiteUptimeStatus_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsiteUptimeStatus_manyArgs = {
  updates: Array<v1_WebsiteUptimeStatus_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Website_PlatformArgs = {
  _inc?: InputMaybe<v1_Website_Platform_inc_input>;
  _set?: InputMaybe<v1_Website_Platform_set_input>;
  where: v1_Website_Platform_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_Website_Platform_by_pkArgs = {
  _inc?: InputMaybe<v1_Website_Platform_inc_input>;
  _set?: InputMaybe<v1_Website_Platform_set_input>;
  pk_columns: v1_Website_Platform_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Website_Platform_manyArgs = {
  updates: Array<v1_Website_Platform_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_Website_by_pkArgs = {
  _inc?: InputMaybe<v1_Website_inc_input>;
  _set?: InputMaybe<v1_Website_set_input>;
  pk_columns: v1_Website_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_Website_manyArgs = {
  updates: Array<v1_Website_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsitesToTopicsArgs = {
  _inc?: InputMaybe<v1_WebsitesToTopics_inc_input>;
  _set?: InputMaybe<v1_WebsitesToTopics_set_input>;
  where: v1_WebsitesToTopics_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsitesToTopics_by_pkArgs = {
  _inc?: InputMaybe<v1_WebsitesToTopics_inc_input>;
  _set?: InputMaybe<v1_WebsitesToTopics_set_input>;
  pk_columns: v1_WebsitesToTopics_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_WebsitesToTopics_manyArgs = {
  updates: Array<v1_WebsitesToTopics_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_POBaseArgs = {
  _inc?: InputMaybe<v1_new_POBase_inc_input>;
  _set?: InputMaybe<v1_new_POBase_set_input>;
  where: v1_new_POBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_POBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_POBase_inc_input>;
  _set?: InputMaybe<v1_new_POBase_set_input>;
  pk_columns: v1_new_POBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_POBase_manyArgs = {
  updates: Array<v1_new_POBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_affiliateincomereportBaseArgs = {
  _inc?: InputMaybe<v1_new_affiliateincomereportBase_inc_input>;
  _set?: InputMaybe<v1_new_affiliateincomereportBase_set_input>;
  where: v1_new_affiliateincomereportBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_affiliateincomereportBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_affiliateincomereportBase_inc_input>;
  _set?: InputMaybe<v1_new_affiliateincomereportBase_set_input>;
  pk_columns: v1_new_affiliateincomereportBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_affiliateincomereportBase_manyArgs = {
  updates: Array<v1_new_affiliateincomereportBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_affordertypeBaseArgs = {
  _inc?: InputMaybe<v1_new_affordertypeBase_inc_input>;
  _set?: InputMaybe<v1_new_affordertypeBase_set_input>;
  where: v1_new_affordertypeBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_affordertypeBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_affordertypeBase_inc_input>;
  _set?: InputMaybe<v1_new_affordertypeBase_set_input>;
  pk_columns: v1_new_affordertypeBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_affordertypeBase_manyArgs = {
  updates: Array<v1_new_affordertypeBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_brandBaseArgs = {
  _inc?: InputMaybe<v1_new_brandBase_inc_input>;
  _set?: InputMaybe<v1_new_brandBase_set_input>;
  where: v1_new_brandBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_brandBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_brandBase_inc_input>;
  _set?: InputMaybe<v1_new_brandBase_set_input>;
  pk_columns: v1_new_brandBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_brandBase_manyArgs = {
  updates: Array<v1_new_brandBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_countryBaseArgs = {
  _inc?: InputMaybe<v1_new_countryBase_inc_input>;
  _set?: InputMaybe<v1_new_countryBase_set_input>;
  where: v1_new_countryBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_countryBase_manyArgs = {
  updates: Array<v1_new_countryBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_languagesBaseArgs = {
  _inc?: InputMaybe<v1_new_languagesBase_inc_input>;
  _set?: InputMaybe<v1_new_languagesBase_set_input>;
  where: v1_new_languagesBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_languagesBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_languagesBase_inc_input>;
  _set?: InputMaybe<v1_new_languagesBase_set_input>;
  pk_columns: v1_new_languagesBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_languagesBase_manyArgs = {
  updates: Array<v1_new_languagesBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_new_brand_new_countryBaseArgs = {
  _inc?: InputMaybe<v1_new_new_brand_new_countryBase_inc_input>;
  _set?: InputMaybe<v1_new_new_brand_new_countryBase_set_input>;
  where: v1_new_new_brand_new_countryBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_new_brand_new_countryBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_new_brand_new_countryBase_inc_input>;
  _set?: InputMaybe<v1_new_new_brand_new_countryBase_set_input>;
  pk_columns: v1_new_new_brand_new_countryBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_new_brand_new_countryBase_manyArgs = {
  updates: Array<v1_new_new_brand_new_countryBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_paymentmethodBaseArgs = {
  _inc?: InputMaybe<v1_new_paymentmethodBase_inc_input>;
  _set?: InputMaybe<v1_new_paymentmethodBase_set_input>;
  where: v1_new_paymentmethodBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_paymentmethodBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_paymentmethodBase_inc_input>;
  _set?: InputMaybe<v1_new_paymentmethodBase_set_input>;
  pk_columns: v1_new_paymentmethodBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_paymentmethodBase_manyArgs = {
  updates: Array<v1_new_paymentmethodBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_receiptBaseArgs = {
  _inc?: InputMaybe<v1_new_receiptBase_inc_input>;
  _set?: InputMaybe<v1_new_receiptBase_set_input>;
  where: v1_new_receiptBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_receiptBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_receiptBase_inc_input>;
  _set?: InputMaybe<v1_new_receiptBase_set_input>;
  pk_columns: v1_new_receiptBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_receiptBase_manyArgs = {
  updates: Array<v1_new_receiptBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_regemailBaseArgs = {
  _inc?: InputMaybe<v1_new_regemailBase_inc_input>;
  _set?: InputMaybe<v1_new_regemailBase_set_input>;
  where: v1_new_regemailBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_regemailBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_regemailBase_inc_input>;
  _set?: InputMaybe<v1_new_regemailBase_set_input>;
  pk_columns: v1_new_regemailBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_regemailBase_manyArgs = {
  updates: Array<v1_new_regemailBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_new_trackerBaseArgs = {
  _inc?: InputMaybe<v1_new_trackerBase_inc_input>;
  _set?: InputMaybe<v1_new_trackerBase_set_input>;
  where: v1_new_trackerBase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_new_trackerBase_by_pkArgs = {
  _inc?: InputMaybe<v1_new_trackerBase_inc_input>;
  _set?: InputMaybe<v1_new_trackerBase_set_input>;
  pk_columns: v1_new_trackerBase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_new_trackerBase_manyArgs = {
  updates: Array<v1_new_trackerBase_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_processArgs = {
  _inc?: InputMaybe<v1_process_inc_input>;
  _set?: InputMaybe<v1_process_set_input>;
  where: v1_process_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_process_by_pkArgs = {
  _inc?: InputMaybe<v1_process_inc_input>;
  _set?: InputMaybe<v1_process_set_input>;
  pk_columns: v1_process_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_process_manyArgs = {
  updates: Array<v1_process_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_process_rateArgs = {
  _inc?: InputMaybe<v1_process_rate_inc_input>;
  _set?: InputMaybe<v1_process_rate_set_input>;
  where: v1_process_rate_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_process_rate_by_pkArgs = {
  _inc?: InputMaybe<v1_process_rate_inc_input>;
  _set?: InputMaybe<v1_process_rate_set_input>;
  pk_columns: v1_process_rate_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_process_rate_manyArgs = {
  updates: Array<v1_process_rate_updates>;
};


/** mutation root */
export type mutation_rootupdate_v1_project_topicArgs = {
  _inc?: InputMaybe<v1_project_topic_inc_input>;
  _set?: InputMaybe<v1_project_topic_set_input>;
  where: v1_project_topic_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_v1_project_topic_by_pkArgs = {
  _inc?: InputMaybe<v1_project_topic_inc_input>;
  _set?: InputMaybe<v1_project_topic_set_input>;
  pk_columns: v1_project_topic_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_v1_project_topic_manyArgs = {
  updates: Array<v1_project_topic_updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type numeric_comparison_exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum order_by {
  /** in ascending order, nulls first */
  asc = 'asc',
  /** in ascending order, nulls first */
  asc_nulls_first = 'asc_nulls_first',
  /** in ascending order, nulls last */
  asc_nulls_last = 'asc_nulls_last',
  /** in descending order, nulls last */
  desc = 'desc',
  /** in descending order, nulls first */
  desc_nulls_first = 'desc_nulls_first',
  /** in descending order, nulls last */
  desc_nulls_last = 'desc_nulls_last'
}

export type query_root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Clicks.OrphanTrackers" */
  Clicks_OrphanTrackers: Array<Clicks_OrphanTrackers>;
  /** fetch aggregated fields from the table: "Clicks.OrphanTrackers" */
  Clicks_OrphanTrackers_aggregate: Clicks_OrphanTrackers_aggregate;
  /** fetch data from the table: "Clicks.lines" */
  Clicks_lines: Array<Clicks_lines>;
  /** fetch aggregated fields from the table: "Clicks.lines" */
  Clicks_lines_aggregate: Clicks_lines_aggregate;
  /** query _Entity union */
  _entities?: Maybe<_Entity>;
  _service: _Service;
  /** fetch data from the table: "v1.AccountBase" */
  v1_AccountBase: Array<v1_AccountBase>;
  /** fetch aggregated fields from the table: "v1.AccountBase" */
  v1_AccountBase_aggregate: v1_AccountBase_aggregate;
  /** fetch data from the table: "v1.AccountBase" using primary key columns */
  v1_AccountBase_by_pk?: Maybe<v1_AccountBase>;
  /** fetch data from the table: "v1.Advertisers" */
  v1_Advertisers: Array<v1_Advertisers>;
  /** fetch aggregated fields from the table: "v1.Advertisers" */
  v1_Advertisers_aggregate: v1_Advertisers_aggregate;
  /** fetch data from the table: "v1.AffDealType" */
  v1_AffDealType: Array<v1_AffDealType>;
  /** fetch aggregated fields from the table: "v1.AffDealType" */
  v1_AffDealType_aggregate: v1_AffDealType_aggregate;
  /** fetch data from the table: "v1.AffDealType" using primary key columns */
  v1_AffDealType_by_pk?: Maybe<v1_AffDealType>;
  /** fetch data from the table: "v1.AffiliateOrderCategoriesPriority" */
  v1_AffiliateOrderCategoriesPriority: Array<v1_AffiliateOrderCategoriesPriority>;
  /** fetch aggregated fields from the table: "v1.AffiliateOrderCategoriesPriority" */
  v1_AffiliateOrderCategoriesPriority_aggregate: v1_AffiliateOrderCategoriesPriority_aggregate;
  /** fetch data from the table: "v1.AffiliateOrderCategoriesPriority" using primary key columns */
  v1_AffiliateOrderCategoriesPriority_by_pk?: Maybe<v1_AffiliateOrderCategoriesPriority>;
  /** fetch data from the table: "v1.AffiliateProgramType" */
  v1_AffiliateProgramType: Array<v1_AffiliateProgramType>;
  /** fetch aggregated fields from the table: "v1.AffiliateProgramType" */
  v1_AffiliateProgramType_aggregate: v1_AffiliateProgramType_aggregate;
  /** fetch data from the table: "v1.AffiliateProgramType" using primary key columns */
  v1_AffiliateProgramType_by_pk?: Maybe<v1_AffiliateProgramType>;
  /** fetch data from the table: "v1.AllowedPlayers" */
  v1_AllowedPlayers: Array<v1_AllowedPlayers>;
  /** fetch aggregated fields from the table: "v1.AllowedPlayers" */
  v1_AllowedPlayers_aggregate: v1_AllowedPlayers_aggregate;
  /** fetch data from the table: "v1.AllowedPlayers" using primary key columns */
  v1_AllowedPlayers_by_pk?: Maybe<v1_AllowedPlayers>;
  /** fetch data from the table: "v1.ArticleTones" */
  v1_ArticleTones: Array<v1_ArticleTones>;
  /** fetch aggregated fields from the table: "v1.ArticleTones" */
  v1_ArticleTones_aggregate: v1_ArticleTones_aggregate;
  /** fetch data from the table: "v1.ArticleTones" using primary key columns */
  v1_ArticleTones_by_pk?: Maybe<v1_ArticleTones>;
  /** fetch data from the table: "v1.Balance" */
  v1_Balance: Array<v1_Balance>;
  /** fetch data from the table: "v1.BalanceActivityType" */
  v1_BalanceActivityType: Array<v1_BalanceActivityType>;
  /** fetch aggregated fields from the table: "v1.BalanceActivityType" */
  v1_BalanceActivityType_aggregate: v1_BalanceActivityType_aggregate;
  /** fetch data from the table: "v1.BalanceActivityType" using primary key columns */
  v1_BalanceActivityType_by_pk?: Maybe<v1_BalanceActivityType>;
  /** fetch aggregated fields from the table: "v1.Balance" */
  v1_Balance_aggregate: v1_Balance_aggregate;
  /** fetch data from the table: "v1.Balance" using primary key columns */
  v1_Balance_by_pk?: Maybe<v1_Balance>;
  /** fetch data from the table: "v1.BillingInformation" */
  v1_BillingInformation: Array<v1_BillingInformation>;
  /** fetch aggregated fields from the table: "v1.BillingInformation" */
  v1_BillingInformation_aggregate: v1_BillingInformation_aggregate;
  /** fetch data from the table: "v1.BillingInformation" using primary key columns */
  v1_BillingInformation_by_pk?: Maybe<v1_BillingInformation>;
  /** fetch data from the table: "v1.BillingMethod" */
  v1_BillingMethod: Array<v1_BillingMethod>;
  /** fetch aggregated fields from the table: "v1.BillingMethod" */
  v1_BillingMethod_aggregate: v1_BillingMethod_aggregate;
  /** fetch data from the table: "v1.BillingMethod" using primary key columns */
  v1_BillingMethod_by_pk?: Maybe<v1_BillingMethod>;
  /** fetch data from the table: "v1.BonusSource" */
  v1_BonusSource: Array<v1_BonusSource>;
  /** fetch aggregated fields from the table: "v1.BonusSource" */
  v1_BonusSource_aggregate: v1_BonusSource_aggregate;
  /** fetch data from the table: "v1.BonusSource" using primary key columns */
  v1_BonusSource_by_pk?: Maybe<v1_BonusSource>;
  /** fetch data from the table: "v1.BonusStatus" */
  v1_BonusStatus: Array<v1_BonusStatus>;
  /** fetch aggregated fields from the table: "v1.BonusStatus" */
  v1_BonusStatus_aggregate: v1_BonusStatus_aggregate;
  /** fetch data from the table: "v1.BonusStatus" using primary key columns */
  v1_BonusStatus_by_pk?: Maybe<v1_BonusStatus>;
  /** fetch data from the table: "v1.BonusSubmitter" */
  v1_BonusSubmitter: Array<v1_BonusSubmitter>;
  /** fetch aggregated fields from the table: "v1.BonusSubmitter" */
  v1_BonusSubmitter_aggregate: v1_BonusSubmitter_aggregate;
  /** fetch data from the table: "v1.BonusSubmitter" using primary key columns */
  v1_BonusSubmitter_by_pk?: Maybe<v1_BonusSubmitter>;
  /** fetch data from the table: "v1.BonusTypes" */
  v1_BonusTypes: Array<v1_BonusTypes>;
  /** fetch aggregated fields from the table: "v1.BonusTypes" */
  v1_BonusTypes_aggregate: v1_BonusTypes_aggregate;
  /** fetch data from the table: "v1.BonusTypes" using primary key columns */
  v1_BonusTypes_by_pk?: Maybe<v1_BonusTypes>;
  /** fetch data from the table: "v1.Bonuses" */
  v1_Bonuses: Array<v1_Bonuses>;
  /** fetch data from the table: "v1.BonusesToCountries" */
  v1_BonusesToCountries: Array<v1_BonusesToCountries>;
  /** fetch aggregated fields from the table: "v1.BonusesToCountries" */
  v1_BonusesToCountries_aggregate: v1_BonusesToCountries_aggregate;
  /** fetch data from the table: "v1.BonusesToCountries" using primary key columns */
  v1_BonusesToCountries_by_pk?: Maybe<v1_BonusesToCountries>;
  /** fetch data from the table: "v1.BonusesToCurrencies" */
  v1_BonusesToCurrencies: Array<v1_BonusesToCurrencies>;
  /** fetch aggregated fields from the table: "v1.BonusesToCurrencies" */
  v1_BonusesToCurrencies_aggregate: v1_BonusesToCurrencies_aggregate;
  /** fetch data from the table: "v1.BonusesToCurrencies" using primary key columns */
  v1_BonusesToCurrencies_by_pk?: Maybe<v1_BonusesToCurrencies>;
  /** fetch data from the table: "v1.BonusesToTypes" */
  v1_BonusesToTypes: Array<v1_BonusesToTypes>;
  /** fetch aggregated fields from the table: "v1.BonusesToTypes" */
  v1_BonusesToTypes_aggregate: v1_BonusesToTypes_aggregate;
  /** fetch data from the table: "v1.BonusesToTypes" using primary key columns */
  v1_BonusesToTypes_by_pk?: Maybe<v1_BonusesToTypes>;
  /** fetch aggregated fields from the table: "v1.Bonuses" */
  v1_Bonuses_aggregate: v1_Bonuses_aggregate;
  /** fetch data from the table: "v1.Bonuses" using primary key columns */
  v1_Bonuses_by_pk?: Maybe<v1_Bonuses>;
  /** fetch data from the table: "v1.BonusestoGames" */
  v1_BonusestoGames: Array<v1_BonusestoGames>;
  /** fetch aggregated fields from the table: "v1.BonusestoGames" */
  v1_BonusestoGames_aggregate: v1_BonusestoGames_aggregate;
  /** fetch data from the table: "v1.BonusestoGames" using primary key columns */
  v1_BonusestoGames_by_pk?: Maybe<v1_BonusestoGames>;
  /** fetch data from the table: "v1.BrandAlias" */
  v1_BrandAlias: Array<v1_BrandAlias>;
  /** fetch aggregated fields from the table: "v1.BrandAlias" */
  v1_BrandAlias_aggregate: v1_BrandAlias_aggregate;
  /** fetch data from the table: "v1.BrandAlias" using primary key columns */
  v1_BrandAlias_by_pk?: Maybe<v1_BrandAlias>;
  /** fetch data from the table: "v1.BrandPriority" */
  v1_BrandPriority: Array<v1_BrandPriority>;
  /** fetch aggregated fields from the table: "v1.BrandPriority" */
  v1_BrandPriority_aggregate: v1_BrandPriority_aggregate;
  /** fetch data from the table: "v1.BrandPriority" using primary key columns */
  v1_BrandPriority_by_pk?: Maybe<v1_BrandPriority>;
  /** fetch data from the table: "v1.Campaign" */
  v1_Campaign: Array<v1_Campaign>;
  /** fetch data from the table: "v1.CampaignPriority" */
  v1_CampaignPriority: Array<v1_CampaignPriority>;
  /** fetch aggregated fields from the table: "v1.CampaignPriority" */
  v1_CampaignPriority_aggregate: v1_CampaignPriority_aggregate;
  /** fetch data from the table: "v1.CampaignPriority" using primary key columns */
  v1_CampaignPriority_by_pk?: Maybe<v1_CampaignPriority>;
  /** fetch data from the table: "v1.CampaignStatus" */
  v1_CampaignStatus: Array<v1_CampaignStatus>;
  /** fetch aggregated fields from the table: "v1.CampaignStatus" */
  v1_CampaignStatus_aggregate: v1_CampaignStatus_aggregate;
  /** fetch data from the table: "v1.CampaignStatus" using primary key columns */
  v1_CampaignStatus_by_pk?: Maybe<v1_CampaignStatus>;
  /** fetch data from the table: "v1.CampaignStrategy" */
  v1_CampaignStrategy: Array<v1_CampaignStrategy>;
  /** fetch aggregated fields from the table: "v1.CampaignStrategy" */
  v1_CampaignStrategy_aggregate: v1_CampaignStrategy_aggregate;
  /** fetch data from the table: "v1.CampaignStrategy" using primary key columns */
  v1_CampaignStrategy_by_pk?: Maybe<v1_CampaignStrategy>;
  /** fetch data from the table: "v1.CampaignType" */
  v1_CampaignType: Array<v1_CampaignType>;
  /** fetch aggregated fields from the table: "v1.CampaignType" */
  v1_CampaignType_aggregate: v1_CampaignType_aggregate;
  /** fetch data from the table: "v1.CampaignType" using primary key columns */
  v1_CampaignType_by_pk?: Maybe<v1_CampaignType>;
  /** fetch aggregated fields from the table: "v1.Campaign" */
  v1_Campaign_aggregate: v1_Campaign_aggregate;
  /** fetch data from the table: "v1.Campaign" using primary key columns */
  v1_Campaign_by_pk?: Maybe<v1_Campaign>;
  /** fetch data from the table: "v1.Categories" */
  v1_Categories: Array<v1_Categories>;
  /** fetch aggregated fields from the table: "v1.Categories" */
  v1_Categories_aggregate: v1_Categories_aggregate;
  /** fetch data from the table: "v1.Categories" using primary key columns */
  v1_Categories_by_pk?: Maybe<v1_Categories>;
  /** fetch data from the table: "v1.Checkout" */
  v1_Checkout: Array<v1_Checkout>;
  /** fetch data from the table: "v1.CheckoutDetail" */
  v1_CheckoutDetail: Array<v1_CheckoutDetail>;
  /** fetch aggregated fields from the table: "v1.CheckoutDetail" */
  v1_CheckoutDetail_aggregate: v1_CheckoutDetail_aggregate;
  /** fetch data from the table: "v1.CheckoutDetail" using primary key columns */
  v1_CheckoutDetail_by_pk?: Maybe<v1_CheckoutDetail>;
  /** fetch data from the table: "v1.CheckoutStatus" */
  v1_CheckoutStatus: Array<v1_CheckoutStatus>;
  /** fetch aggregated fields from the table: "v1.CheckoutStatus" */
  v1_CheckoutStatus_aggregate: v1_CheckoutStatus_aggregate;
  /** fetch data from the table: "v1.CheckoutStatus" using primary key columns */
  v1_CheckoutStatus_by_pk?: Maybe<v1_CheckoutStatus>;
  /** fetch aggregated fields from the table: "v1.Checkout" */
  v1_Checkout_aggregate: v1_Checkout_aggregate;
  /** fetch data from the table: "v1.Checkout" using primary key columns */
  v1_Checkout_by_pk?: Maybe<v1_Checkout>;
  /** fetch data from the table: "v1.Cities" */
  v1_Cities: Array<v1_Cities>;
  /** fetch aggregated fields from the table: "v1.Cities" */
  v1_Cities_aggregate: v1_Cities_aggregate;
  /** fetch data from the table: "v1.Cities" using primary key columns */
  v1_Cities_by_pk?: Maybe<v1_Cities>;
  /** fetch data from the table: "v1.ClicksByTrackers" */
  v1_ClicksByTrackers: Array<v1_ClicksByTrackers>;
  /** fetch data from the table: "v1.ClicksByTrackersNew" */
  v1_ClicksByTrackersNew: Array<v1_ClicksByTrackersNew>;
  /** fetch aggregated fields from the table: "v1.ClicksByTrackersNew" */
  v1_ClicksByTrackersNew_aggregate: v1_ClicksByTrackersNew_aggregate;
  /** fetch data from the table: "v1.ClicksByTrackersNew" using primary key columns */
  v1_ClicksByTrackersNew_by_pk?: Maybe<v1_ClicksByTrackersNew>;
  /** fetch aggregated fields from the table: "v1.ClicksByTrackers" */
  v1_ClicksByTrackers_aggregate: v1_ClicksByTrackers_aggregate;
  /** fetch data from the table: "v1.ClicksByTrackers" using primary key columns */
  v1_ClicksByTrackers_by_pk?: Maybe<v1_ClicksByTrackers>;
  /** fetch data from the table: "v1.CompanyType" */
  v1_CompanyType: Array<v1_CompanyType>;
  /** fetch aggregated fields from the table: "v1.CompanyType" */
  v1_CompanyType_aggregate: v1_CompanyType_aggregate;
  /** fetch data from the table: "v1.CompanyType" using primary key columns */
  v1_CompanyType_by_pk?: Maybe<v1_CompanyType>;
  /** fetch data from the table: "v1.Configurations" */
  v1_Configurations: Array<v1_Configurations>;
  /** fetch aggregated fields from the table: "v1.Configurations" */
  v1_Configurations_aggregate: v1_Configurations_aggregate;
  /** fetch data from the table: "v1.Configurations" using primary key columns */
  v1_Configurations_by_pk?: Maybe<v1_Configurations>;
  /** fetch data from the table: "v1.ContactBase" */
  v1_ContactBase: Array<v1_ContactBase>;
  /** fetch data from the table: "v1.ContactBasePaymentOption" */
  v1_ContactBasePaymentOption: Array<v1_ContactBasePaymentOption>;
  /** fetch aggregated fields from the table: "v1.ContactBasePaymentOption" */
  v1_ContactBasePaymentOption_aggregate: v1_ContactBasePaymentOption_aggregate;
  /** fetch data from the table: "v1.ContactBasePaymentOption" using primary key columns */
  v1_ContactBasePaymentOption_by_pk?: Maybe<v1_ContactBasePaymentOption>;
  /** fetch aggregated fields from the table: "v1.ContactBase" */
  v1_ContactBase_aggregate: v1_ContactBase_aggregate;
  /** fetch data from the table: "v1.ContactBase" using primary key columns */
  v1_ContactBase_by_pk?: Maybe<v1_ContactBase>;
  /** fetch data from the table: "v1.ContactType" */
  v1_ContactType: Array<v1_ContactType>;
  /** fetch aggregated fields from the table: "v1.ContactType" */
  v1_ContactType_aggregate: v1_ContactType_aggregate;
  /** fetch data from the table: "v1.ContactType" using primary key columns */
  v1_ContactType_by_pk?: Maybe<v1_ContactType>;
  /** fetch data from the table: "v1.Contacts_Roles" */
  v1_Contacts_Roles: Array<v1_Contacts_Roles>;
  /** fetch aggregated fields from the table: "v1.Contacts_Roles" */
  v1_Contacts_Roles_aggregate: v1_Contacts_Roles_aggregate;
  /** fetch data from the table: "v1.Contacts_Roles" using primary key columns */
  v1_Contacts_Roles_by_pk?: Maybe<v1_Contacts_Roles>;
  /** fetch data from the table: "v1.ContentLink" */
  v1_ContentLink: Array<v1_ContentLink>;
  /** fetch aggregated fields from the table: "v1.ContentLink" */
  v1_ContentLink_aggregate: v1_ContentLink_aggregate;
  /** fetch data from the table: "v1.ContentLink" using primary key columns */
  v1_ContentLink_by_pk?: Maybe<v1_ContentLink>;
  /** fetch data from the table: "v1.ContentTypes" */
  v1_ContentTypes: Array<v1_ContentTypes>;
  /** fetch aggregated fields from the table: "v1.ContentTypes" */
  v1_ContentTypes_aggregate: v1_ContentTypes_aggregate;
  /** fetch data from the table: "v1.ContentTypes" using primary key columns */
  v1_ContentTypes_by_pk?: Maybe<v1_ContentTypes>;
  /** fetch data from the table: "v1.Credential_Roles" */
  v1_Credential_Roles: Array<v1_Credential_Roles>;
  /** fetch aggregated fields from the table: "v1.Credential_Roles" */
  v1_Credential_Roles_aggregate: v1_Credential_Roles_aggregate;
  /** fetch data from the table: "v1.Credential_Roles" using primary key columns */
  v1_Credential_Roles_by_pk?: Maybe<v1_Credential_Roles>;
  /** fetch data from the table: "v1.Credentials" */
  v1_Credentials: Array<v1_Credentials>;
  /** fetch aggregated fields from the table: "v1.Credentials" */
  v1_Credentials_aggregate: v1_Credentials_aggregate;
  /** fetch data from the table: "v1.Credentials" using primary key columns */
  v1_Credentials_by_pk?: Maybe<v1_Credentials>;
  /** fetch data from the table: "v1.DomainExtensions" */
  v1_DomainExtensions: Array<v1_DomainExtensions>;
  /** fetch aggregated fields from the table: "v1.DomainExtensions" */
  v1_DomainExtensions_aggregate: v1_DomainExtensions_aggregate;
  /** fetch data from the table: "v1.DomainExtensions" using primary key columns */
  v1_DomainExtensions_by_pk?: Maybe<v1_DomainExtensions>;
  /** fetch data from the table: "v1.Events" */
  v1_Events: Array<v1_Events>;
  /** fetch aggregated fields from the table: "v1.Events" */
  v1_Events_aggregate: v1_Events_aggregate;
  /** fetch data from the table: "v1.Events" using primary key columns */
  v1_Events_by_pk?: Maybe<v1_Events>;
  /** fetch data from the table: "v1.ExpiringRecPayments" */
  v1_ExpiringRecPayments: Array<v1_ExpiringRecPayments>;
  /** fetch aggregated fields from the table: "v1.ExpiringRecPayments" */
  v1_ExpiringRecPayments_aggregate: v1_ExpiringRecPayments_aggregate;
  /** fetch data from the table: "v1.Files" */
  v1_Files: Array<v1_Files>;
  /** fetch aggregated fields from the table: "v1.Files" */
  v1_Files_aggregate: v1_Files_aggregate;
  /** fetch data from the table: "v1.Files" using primary key columns */
  v1_Files_by_pk?: Maybe<v1_Files>;
  /** fetch data from the table: "v1.GameType" */
  v1_GameType: Array<v1_GameType>;
  /** fetch aggregated fields from the table: "v1.GameType" */
  v1_GameType_aggregate: v1_GameType_aggregate;
  /** fetch data from the table: "v1.GameType" using primary key columns */
  v1_GameType_by_pk?: Maybe<v1_GameType>;
  /** fetch data from the table: "v1.InvoiceBase" */
  v1_InvoiceBase: Array<v1_InvoiceBase>;
  /** fetch aggregated fields from the table: "v1.InvoiceBase" */
  v1_InvoiceBase_aggregate: v1_InvoiceBase_aggregate;
  /** fetch data from the table: "v1.InvoiceBase" using primary key columns */
  v1_InvoiceBase_by_pk?: Maybe<v1_InvoiceBase>;
  /** fetch data from the table: "v1.InvoiceDetailBase" */
  v1_InvoiceDetailBase: Array<v1_InvoiceDetailBase>;
  /** fetch aggregated fields from the table: "v1.InvoiceDetailBase" */
  v1_InvoiceDetailBase_aggregate: v1_InvoiceDetailBase_aggregate;
  /** fetch data from the table: "v1.InvoiceDetailBase" using primary key columns */
  v1_InvoiceDetailBase_by_pk?: Maybe<v1_InvoiceDetailBase>;
  /** fetch data from the table: "v1.InvoiceStatus" */
  v1_InvoiceStatus: Array<v1_InvoiceStatus>;
  /** fetch aggregated fields from the table: "v1.InvoiceStatus" */
  v1_InvoiceStatus_aggregate: v1_InvoiceStatus_aggregate;
  /** fetch data from the table: "v1.InvoiceStatus" using primary key columns */
  v1_InvoiceStatus_by_pk?: Maybe<v1_InvoiceStatus>;
  /** fetch data from the table: "v1.LBInProgressPosts" */
  v1_LBInProgressPosts: Array<v1_LBInProgressPosts>;
  /** fetch aggregated fields from the table: "v1.LBInProgressPosts" */
  v1_LBInProgressPosts_aggregate: v1_LBInProgressPosts_aggregate;
  /** fetch data from the table: "v1.LBPublishedPosts" */
  v1_LBPublishedPosts: Array<v1_LBPublishedPosts>;
  /** fetch aggregated fields from the table: "v1.LBPublishedPosts" */
  v1_LBPublishedPosts_aggregate: v1_LBPublishedPosts_aggregate;
  /** fetch data from the table: "v1.LandingPagePriority" */
  v1_LandingPagePriority: Array<v1_LandingPagePriority>;
  /** fetch aggregated fields from the table: "v1.LandingPagePriority" */
  v1_LandingPagePriority_aggregate: v1_LandingPagePriority_aggregate;
  /** fetch data from the table: "v1.LandingPagePriority" using primary key columns */
  v1_LandingPagePriority_by_pk?: Maybe<v1_LandingPagePriority>;
  /** fetch data from the table: "v1.LandingPages" */
  v1_LandingPages: Array<v1_LandingPages>;
  /** fetch aggregated fields from the table: "v1.LandingPages" */
  v1_LandingPages_aggregate: v1_LandingPages_aggregate;
  /** fetch data from the table: "v1.LandingPages" using primary key columns */
  v1_LandingPages_by_pk?: Maybe<v1_LandingPages>;
  /** fetch data from the table: "v1.LanguagePriceStep" */
  v1_LanguagePriceStep: Array<v1_LanguagePriceStep>;
  /** fetch aggregated fields from the table: "v1.LanguagePriceStep" */
  v1_LanguagePriceStep_aggregate: v1_LanguagePriceStep_aggregate;
  /** fetch data from the table: "v1.LanguagePriceStep" using primary key columns */
  v1_LanguagePriceStep_by_pk?: Maybe<v1_LanguagePriceStep>;
  /** fetch data from the table: "v1.MarketPlaceCategories" */
  v1_MarketPlaceCategories: Array<v1_MarketPlaceCategories>;
  /** fetch data from the table: "v1.MarketPlaceCategoriesTypes" */
  v1_MarketPlaceCategoriesTypes: Array<v1_MarketPlaceCategoriesTypes>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceCategoriesTypes" */
  v1_MarketPlaceCategoriesTypes_aggregate: v1_MarketPlaceCategoriesTypes_aggregate;
  /** fetch data from the table: "v1.MarketPlaceCategoriesTypes" using primary key columns */
  v1_MarketPlaceCategoriesTypes_by_pk?: Maybe<v1_MarketPlaceCategoriesTypes>;
  /** fetch data from the table: "v1.MarketPlaceCategories_Offers" */
  v1_MarketPlaceCategories_Offers: Array<v1_MarketPlaceCategories_Offers>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceCategories_Offers" */
  v1_MarketPlaceCategories_Offers_aggregate: v1_MarketPlaceCategories_Offers_aggregate;
  /** fetch data from the table: "v1.MarketPlaceCategories_Offers" using primary key columns */
  v1_MarketPlaceCategories_Offers_by_pk?: Maybe<v1_MarketPlaceCategories_Offers>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceCategories" */
  v1_MarketPlaceCategories_aggregate: v1_MarketPlaceCategories_aggregate;
  /** fetch data from the table: "v1.MarketPlaceCategories" using primary key columns */
  v1_MarketPlaceCategories_by_pk?: Maybe<v1_MarketPlaceCategories>;
  /** fetch data from the table: "v1.MarketPlaceOfferNiche" */
  v1_MarketPlaceOfferNiche: Array<v1_MarketPlaceOfferNiche>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceOfferNiche" */
  v1_MarketPlaceOfferNiche_aggregate: v1_MarketPlaceOfferNiche_aggregate;
  /** fetch data from the table: "v1.MarketPlaceOfferNiche" using primary key columns */
  v1_MarketPlaceOfferNiche_by_pk?: Maybe<v1_MarketPlaceOfferNiche>;
  /** fetch data from the table: "v1.MarketPlaceOrder" */
  v1_MarketPlaceOrder: Array<v1_MarketPlaceOrder>;
  /** fetch data from the table: "v1.MarketPlaceOrderRequest" */
  v1_MarketPlaceOrderRequest: Array<v1_MarketPlaceOrderRequest>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceOrderRequest" */
  v1_MarketPlaceOrderRequest_aggregate: v1_MarketPlaceOrderRequest_aggregate;
  /** fetch data from the table: "v1.MarketPlaceOrderRequest" using primary key columns */
  v1_MarketPlaceOrderRequest_by_pk?: Maybe<v1_MarketPlaceOrderRequest>;
  /** fetch data from the table: "v1.MarketPlaceOrderStatus" */
  v1_MarketPlaceOrderStatus: Array<v1_MarketPlaceOrderStatus>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceOrderStatus" */
  v1_MarketPlaceOrderStatus_aggregate: v1_MarketPlaceOrderStatus_aggregate;
  /** fetch data from the table: "v1.MarketPlaceOrderStatus" using primary key columns */
  v1_MarketPlaceOrderStatus_by_pk?: Maybe<v1_MarketPlaceOrderStatus>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceOrder" */
  v1_MarketPlaceOrder_aggregate: v1_MarketPlaceOrder_aggregate;
  /** fetch data from the table: "v1.MarketPlaceOrder" using primary key columns */
  v1_MarketPlaceOrder_by_pk?: Maybe<v1_MarketPlaceOrder>;
  /** fetch data from the table: "v1.MarketPlaceRegisterStatus" */
  v1_MarketPlaceRegisterStatus: Array<v1_MarketPlaceRegisterStatus>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceRegisterStatus" */
  v1_MarketPlaceRegisterStatus_aggregate: v1_MarketPlaceRegisterStatus_aggregate;
  /** fetch data from the table: "v1.MarketPlaceRegisterStatus" using primary key columns */
  v1_MarketPlaceRegisterStatus_by_pk?: Maybe<v1_MarketPlaceRegisterStatus>;
  /** fetch data from the table: "v1.MarketType" */
  v1_MarketType: Array<v1_MarketType>;
  /** fetch aggregated fields from the table: "v1.MarketType" */
  v1_MarketType_aggregate: v1_MarketType_aggregate;
  /** fetch data from the table: "v1.MarketType" using primary key columns */
  v1_MarketType_by_pk?: Maybe<v1_MarketType>;
  /** fetch data from the table: "v1.MarketplaceProjects" */
  v1_MarketplaceProjects: Array<v1_MarketplaceProjects>;
  /** fetch aggregated fields from the table: "v1.MarketplaceProjects" */
  v1_MarketplaceProjects_aggregate: v1_MarketplaceProjects_aggregate;
  /** fetch data from the table: "v1.MarketplaceProjects" using primary key columns */
  v1_MarketplaceProjects_by_pk?: Maybe<v1_MarketplaceProjects>;
  /** fetch data from the table: "v1.MarketplaceStatus" */
  v1_MarketplaceStatus: Array<v1_MarketplaceStatus>;
  /** fetch aggregated fields from the table: "v1.MarketplaceStatus" */
  v1_MarketplaceStatus_aggregate: v1_MarketplaceStatus_aggregate;
  /** fetch data from the table: "v1.MarketplaceStatus" using primary key columns */
  v1_MarketplaceStatus_by_pk?: Maybe<v1_MarketplaceStatus>;
  /** fetch data from the table: "v1.Months" */
  v1_Months: Array<v1_Months>;
  /** fetch aggregated fields from the table: "v1.Months" */
  v1_Months_aggregate: v1_Months_aggregate;
  /** fetch data from the table: "v1.Months" using primary key columns */
  v1_Months_by_pk?: Maybe<v1_Months>;
  /** fetch data from the table: "v1.Offer" */
  v1_Offer: Array<v1_Offer>;
  /** fetch data from the table: "v1.OfferStatus" */
  v1_OfferStatus: Array<v1_OfferStatus>;
  /** fetch aggregated fields from the table: "v1.OfferStatus" */
  v1_OfferStatus_aggregate: v1_OfferStatus_aggregate;
  /** fetch data from the table: "v1.OfferStatus" using primary key columns */
  v1_OfferStatus_by_pk?: Maybe<v1_OfferStatus>;
  /** fetch data from the table: "v1.OfferTags" */
  v1_OfferTags: Array<v1_OfferTags>;
  /** fetch aggregated fields from the table: "v1.OfferTags" */
  v1_OfferTags_aggregate: v1_OfferTags_aggregate;
  /** fetch data from the table: "v1.OfferTags" using primary key columns */
  v1_OfferTags_by_pk?: Maybe<v1_OfferTags>;
  /** fetch data from the table: "v1.OfferToTags" */
  v1_OfferToTags: Array<v1_OfferToTags>;
  /** fetch aggregated fields from the table: "v1.OfferToTags" */
  v1_OfferToTags_aggregate: v1_OfferToTags_aggregate;
  /** fetch data from the table: "v1.OfferToTags" using primary key columns */
  v1_OfferToTags_by_pk?: Maybe<v1_OfferToTags>;
  /** fetch aggregated fields from the table: "v1.Offer" */
  v1_Offer_aggregate: v1_Offer_aggregate;
  /** fetch data from the table: "v1.Offer" using primary key columns */
  v1_Offer_by_pk?: Maybe<v1_Offer>;
  /** fetch data from the table: "v1.OrderFor" */
  v1_OrderFor: Array<v1_OrderFor>;
  /** fetch aggregated fields from the table: "v1.OrderFor" */
  v1_OrderFor_aggregate: v1_OrderFor_aggregate;
  /** fetch data from the table: "v1.OrderFor" using primary key columns */
  v1_OrderFor_by_pk?: Maybe<v1_OrderFor>;
  /** fetch data from the table: "v1.OrderPayTo" */
  v1_OrderPayTo: Array<v1_OrderPayTo>;
  /** fetch aggregated fields from the table: "v1.OrderPayTo" */
  v1_OrderPayTo_aggregate: v1_OrderPayTo_aggregate;
  /** fetch data from the table: "v1.OrderPayTo" using primary key columns */
  v1_OrderPayTo_by_pk?: Maybe<v1_OrderPayTo>;
  /** fetch data from the table: "v1.OrderPriority" */
  v1_OrderPriority: Array<v1_OrderPriority>;
  /** fetch aggregated fields from the table: "v1.OrderPriority" */
  v1_OrderPriority_aggregate: v1_OrderPriority_aggregate;
  /** fetch data from the table: "v1.OrderPriority" using primary key columns */
  v1_OrderPriority_by_pk?: Maybe<v1_OrderPriority>;
  /** fetch data from the table: "v1.OrderType" */
  v1_OrderType: Array<v1_OrderType>;
  /** fetch aggregated fields from the table: "v1.OrderType" */
  v1_OrderType_aggregate: v1_OrderType_aggregate;
  /** fetch data from the table: "v1.OrderType" using primary key columns */
  v1_OrderType_by_pk?: Maybe<v1_OrderType>;
  /** fetch data from the table: "v1.OutreachPosts" */
  v1_OutreachPosts: Array<v1_OutreachPosts>;
  /** fetch aggregated fields from the table: "v1.OutreachPosts" */
  v1_OutreachPosts_aggregate: v1_OutreachPosts_aggregate;
  /** fetch data from the table: "v1.Page" */
  v1_Page: Array<v1_Page>;
  /** fetch aggregated fields from the table: "v1.Page" */
  v1_Page_aggregate: v1_Page_aggregate;
  /** fetch data from the table: "v1.Page" using primary key columns */
  v1_Page_by_pk?: Maybe<v1_Page>;
  /** fetch data from the table: "v1.PaymentCompanies" */
  v1_PaymentCompanies: Array<v1_PaymentCompanies>;
  /** fetch aggregated fields from the table: "v1.PaymentCompanies" */
  v1_PaymentCompanies_aggregate: v1_PaymentCompanies_aggregate;
  /** fetch data from the table: "v1.PaymentCompanies" using primary key columns */
  v1_PaymentCompanies_by_pk?: Maybe<v1_PaymentCompanies>;
  /** fetch data from the table: "v1.PaymentFrequency" */
  v1_PaymentFrequency: Array<v1_PaymentFrequency>;
  /** fetch aggregated fields from the table: "v1.PaymentFrequency" */
  v1_PaymentFrequency_aggregate: v1_PaymentFrequency_aggregate;
  /** fetch data from the table: "v1.PaymentFrequency" using primary key columns */
  v1_PaymentFrequency_by_pk?: Maybe<v1_PaymentFrequency>;
  /** fetch data from the table: "v1.PoToPosts" */
  v1_PoToPosts: Array<v1_PoToPosts>;
  /** fetch aggregated fields from the table: "v1.PoToPosts" */
  v1_PoToPosts_aggregate: v1_PoToPosts_aggregate;
  /** fetch data from the table: "v1.PoToPosts" using primary key columns */
  v1_PoToPosts_by_pk?: Maybe<v1_PoToPosts>;
  /** fetch data from the table: "v1.PostDuration" */
  v1_PostDuration: Array<v1_PostDuration>;
  /** fetch aggregated fields from the table: "v1.PostDuration" */
  v1_PostDuration_aggregate: v1_PostDuration_aggregate;
  /** fetch data from the table: "v1.PostDuration" using primary key columns */
  v1_PostDuration_by_pk?: Maybe<v1_PostDuration>;
  /** fetch data from the table: "v1.PostNiche" */
  v1_PostNiche: Array<v1_PostNiche>;
  /** fetch aggregated fields from the table: "v1.PostNiche" */
  v1_PostNiche_aggregate: v1_PostNiche_aggregate;
  /** fetch data from the table: "v1.PostNiche" using primary key columns */
  v1_PostNiche_by_pk?: Maybe<v1_PostNiche>;
  /** fetch data from the table: "v1.Posts" */
  v1_Posts: Array<v1_Posts>;
  /** fetch data from the table: "v1.PostsAnchorTextType" */
  v1_PostsAnchorTextType: Array<v1_PostsAnchorTextType>;
  /** fetch aggregated fields from the table: "v1.PostsAnchorTextType" */
  v1_PostsAnchorTextType_aggregate: v1_PostsAnchorTextType_aggregate;
  /** fetch data from the table: "v1.PostsAnchorTextType" using primary key columns */
  v1_PostsAnchorTextType_by_pk?: Maybe<v1_PostsAnchorTextType>;
  /** fetch data from the table: "v1.PostsContentStatus" */
  v1_PostsContentStatus: Array<v1_PostsContentStatus>;
  /** fetch aggregated fields from the table: "v1.PostsContentStatus" */
  v1_PostsContentStatus_aggregate: v1_PostsContentStatus_aggregate;
  /** fetch data from the table: "v1.PostsContentStatus" using primary key columns */
  v1_PostsContentStatus_by_pk?: Maybe<v1_PostsContentStatus>;
  /** fetch data from the table: "v1.PostsCopyScape" */
  v1_PostsCopyScape: Array<v1_PostsCopyScape>;
  /** fetch aggregated fields from the table: "v1.PostsCopyScape" */
  v1_PostsCopyScape_aggregate: v1_PostsCopyScape_aggregate;
  /** fetch data from the table: "v1.PostsCopyScape" using primary key columns */
  v1_PostsCopyScape_by_pk?: Maybe<v1_PostsCopyScape>;
  /** fetch data from the table: "v1.PostsLinkStatus" */
  v1_PostsLinkStatus: Array<v1_PostsLinkStatus>;
  /** fetch aggregated fields from the table: "v1.PostsLinkStatus" */
  v1_PostsLinkStatus_aggregate: v1_PostsLinkStatus_aggregate;
  /** fetch data from the table: "v1.PostsLinkStatus" using primary key columns */
  v1_PostsLinkStatus_by_pk?: Maybe<v1_PostsLinkStatus>;
  /** fetch data from the table: "v1.PostsOnlineStatus" */
  v1_PostsOnlineStatus: Array<v1_PostsOnlineStatus>;
  /** fetch aggregated fields from the table: "v1.PostsOnlineStatus" */
  v1_PostsOnlineStatus_aggregate: v1_PostsOnlineStatus_aggregate;
  /** fetch data from the table: "v1.PostsOnlineStatus" using primary key columns */
  v1_PostsOnlineStatus_by_pk?: Maybe<v1_PostsOnlineStatus>;
  /** fetch data from the table: "v1.PostsOutreachStatus" */
  v1_PostsOutreachStatus: Array<v1_PostsOutreachStatus>;
  /** fetch aggregated fields from the table: "v1.PostsOutreachStatus" */
  v1_PostsOutreachStatus_aggregate: v1_PostsOutreachStatus_aggregate;
  /** fetch data from the table: "v1.PostsOutreachStatus" using primary key columns */
  v1_PostsOutreachStatus_by_pk?: Maybe<v1_PostsOutreachStatus>;
  /** fetch data from the table: "v1.PostsPageTypeDeprecated" */
  v1_PostsPageTypeDeprecated: Array<v1_PostsPageTypeDeprecated>;
  /** fetch aggregated fields from the table: "v1.PostsPageTypeDeprecated" */
  v1_PostsPageTypeDeprecated_aggregate: v1_PostsPageTypeDeprecated_aggregate;
  /** fetch data from the table: "v1.PostsPageTypeDeprecated" using primary key columns */
  v1_PostsPageTypeDeprecated_by_pk?: Maybe<v1_PostsPageTypeDeprecated>;
  /** fetch data from the table: "v1.PostsRemovalStatus" */
  v1_PostsRemovalStatus: Array<v1_PostsRemovalStatus>;
  /** fetch aggregated fields from the table: "v1.PostsRemovalStatus" */
  v1_PostsRemovalStatus_aggregate: v1_PostsRemovalStatus_aggregate;
  /** fetch data from the table: "v1.PostsRemovalStatus" using primary key columns */
  v1_PostsRemovalStatus_by_pk?: Maybe<v1_PostsRemovalStatus>;
  /** fetch data from the table: "v1.PostsSEOApproval" */
  v1_PostsSEOApproval: Array<v1_PostsSEOApproval>;
  /** fetch aggregated fields from the table: "v1.PostsSEOApproval" */
  v1_PostsSEOApproval_aggregate: v1_PostsSEOApproval_aggregate;
  /** fetch data from the table: "v1.PostsSEOApproval" using primary key columns */
  v1_PostsSEOApproval_by_pk?: Maybe<v1_PostsSEOApproval>;
  /** fetch data from the table: "v1.PostsSEODeclineReason" */
  v1_PostsSEODeclineReason: Array<v1_PostsSEODeclineReason>;
  /** fetch aggregated fields from the table: "v1.PostsSEODeclineReason" */
  v1_PostsSEODeclineReason_aggregate: v1_PostsSEODeclineReason_aggregate;
  /** fetch data from the table: "v1.PostsSEODeclineReason" using primary key columns */
  v1_PostsSEODeclineReason_by_pk?: Maybe<v1_PostsSEODeclineReason>;
  /** fetch data from the table: "v1.PostsStatus" */
  v1_PostsStatus: Array<v1_PostsStatus>;
  /** fetch data from the table: "v1.PostsStatusCode" */
  v1_PostsStatusCode: Array<v1_PostsStatusCode>;
  /** fetch aggregated fields from the table: "v1.PostsStatusCode" */
  v1_PostsStatusCode_aggregate: v1_PostsStatusCode_aggregate;
  /** fetch data from the table: "v1.PostsStatusCode" using primary key columns */
  v1_PostsStatusCode_by_pk?: Maybe<v1_PostsStatusCode>;
  /** fetch aggregated fields from the table: "v1.PostsStatus" */
  v1_PostsStatus_aggregate: v1_PostsStatus_aggregate;
  /** fetch data from the table: "v1.PostsStatus" using primary key columns */
  v1_PostsStatus_by_pk?: Maybe<v1_PostsStatus>;
  /** fetch data from the table: "v1.PostsToIndex" */
  v1_PostsToIndex: Array<v1_PostsToIndex>;
  /** fetch aggregated fields from the table: "v1.PostsToIndex" */
  v1_PostsToIndex_aggregate: v1_PostsToIndex_aggregate;
  /** fetch aggregated fields from the table: "v1.Posts" */
  v1_Posts_aggregate: v1_Posts_aggregate;
  /** fetch data from the table: "v1.Posts" using primary key columns */
  v1_Posts_by_pk?: Maybe<v1_Posts>;
  /** fetch data from the table: "v1.Preferences" */
  v1_Preferences: Array<v1_Preferences>;
  /** fetch data from the table: "v1.PreferencesMarketplace" */
  v1_PreferencesMarketplace: Array<v1_PreferencesMarketplace>;
  /** fetch aggregated fields from the table: "v1.PreferencesMarketplace" */
  v1_PreferencesMarketplace_aggregate: v1_PreferencesMarketplace_aggregate;
  /** fetch data from the table: "v1.PreferencesMarketplace" using primary key columns */
  v1_PreferencesMarketplace_by_pk?: Maybe<v1_PreferencesMarketplace>;
  /** fetch aggregated fields from the table: "v1.Preferences" */
  v1_Preferences_aggregate: v1_Preferences_aggregate;
  /** fetch data from the table: "v1.Preferences" using primary key columns */
  v1_Preferences_by_pk?: Maybe<v1_Preferences>;
  /** fetch data from the table: "v1.Project" */
  v1_Project: Array<v1_Project>;
  /** fetch data from the table: "v1.ProjectImportance" */
  v1_ProjectImportance: Array<v1_ProjectImportance>;
  /** fetch aggregated fields from the table: "v1.ProjectImportance" */
  v1_ProjectImportance_aggregate: v1_ProjectImportance_aggregate;
  /** fetch data from the table: "v1.ProjectImportance" using primary key columns */
  v1_ProjectImportance_by_pk?: Maybe<v1_ProjectImportance>;
  /** fetch data from the table: "v1.ProjectManagers" */
  v1_ProjectManagers: Array<v1_ProjectManagers>;
  /** fetch aggregated fields from the table: "v1.ProjectManagers" */
  v1_ProjectManagers_aggregate: v1_ProjectManagers_aggregate;
  /** fetch data from the table: "v1.ProjectStatus" */
  v1_ProjectStatus: Array<v1_ProjectStatus>;
  /** fetch aggregated fields from the table: "v1.ProjectStatus" */
  v1_ProjectStatus_aggregate: v1_ProjectStatus_aggregate;
  /** fetch data from the table: "v1.ProjectStatus" using primary key columns */
  v1_ProjectStatus_by_pk?: Maybe<v1_ProjectStatus>;
  /** fetch data from the table: "v1.ProjectTopic" */
  v1_ProjectTopic: Array<v1_ProjectTopic>;
  /** fetch aggregated fields from the table: "v1.ProjectTopic" */
  v1_ProjectTopic_aggregate: v1_ProjectTopic_aggregate;
  /** fetch data from the table: "v1.ProjectTopic" using primary key columns */
  v1_ProjectTopic_by_pk?: Maybe<v1_ProjectTopic>;
  /** fetch data from the table: "v1.Project_Countries" */
  v1_Project_Countries: Array<v1_Project_Countries>;
  /** fetch aggregated fields from the table: "v1.Project_Countries" */
  v1_Project_Countries_aggregate: v1_Project_Countries_aggregate;
  /** fetch data from the table: "v1.Project_Countries" using primary key columns */
  v1_Project_Countries_by_pk?: Maybe<v1_Project_Countries>;
  /** fetch aggregated fields from the table: "v1.Project" */
  v1_Project_aggregate: v1_Project_aggregate;
  /** fetch data from the table: "v1.Project" using primary key columns */
  v1_Project_by_pk?: Maybe<v1_Project>;
  /** fetch data from the table: "v1.PublisherPostStatus" */
  v1_PublisherPostStatus: Array<v1_PublisherPostStatus>;
  /** fetch aggregated fields from the table: "v1.PublisherPostStatus" */
  v1_PublisherPostStatus_aggregate: v1_PublisherPostStatus_aggregate;
  /** fetch data from the table: "v1.PublisherPostStatus" using primary key columns */
  v1_PublisherPostStatus_by_pk?: Maybe<v1_PublisherPostStatus>;
  /** fetch data from the table: "v1.PurchaseOrderStatus" */
  v1_PurchaseOrderStatus: Array<v1_PurchaseOrderStatus>;
  /** fetch aggregated fields from the table: "v1.PurchaseOrderStatus" */
  v1_PurchaseOrderStatus_aggregate: v1_PurchaseOrderStatus_aggregate;
  /** fetch data from the table: "v1.PurchaseOrderStatus" using primary key columns */
  v1_PurchaseOrderStatus_by_pk?: Maybe<v1_PurchaseOrderStatus>;
  /** fetch data from the table: "v1.ReceiptStatus" */
  v1_ReceiptStatus: Array<v1_ReceiptStatus>;
  /** fetch aggregated fields from the table: "v1.ReceiptStatus" */
  v1_ReceiptStatus_aggregate: v1_ReceiptStatus_aggregate;
  /** fetch data from the table: "v1.ReceiptStatus" using primary key columns */
  v1_ReceiptStatus_by_pk?: Maybe<v1_ReceiptStatus>;
  /** fetch data from the table: "v1.RecurringPaymentStatus" */
  v1_RecurringPaymentStatus: Array<v1_RecurringPaymentStatus>;
  /** fetch aggregated fields from the table: "v1.RecurringPaymentStatus" */
  v1_RecurringPaymentStatus_aggregate: v1_RecurringPaymentStatus_aggregate;
  /** fetch data from the table: "v1.RecurringPaymentStatus" using primary key columns */
  v1_RecurringPaymentStatus_by_pk?: Maybe<v1_RecurringPaymentStatus>;
  /** fetch data from the table: "v1.RecurringPayments" */
  v1_RecurringPayments: Array<v1_RecurringPayments>;
  /** fetch data from the table: "v1.RecurringPayments_Roles" */
  v1_RecurringPayments_Roles: Array<v1_RecurringPayments_Roles>;
  /** fetch aggregated fields from the table: "v1.RecurringPayments_Roles" */
  v1_RecurringPayments_Roles_aggregate: v1_RecurringPayments_Roles_aggregate;
  /** fetch data from the table: "v1.RecurringPayments_Roles" using primary key columns */
  v1_RecurringPayments_Roles_by_pk?: Maybe<v1_RecurringPayments_Roles>;
  /** fetch aggregated fields from the table: "v1.RecurringPayments" */
  v1_RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  /** fetch data from the table: "v1.RecurringPayments" using primary key columns */
  v1_RecurringPayments_by_pk?: Maybe<v1_RecurringPayments>;
  /** fetch data from the table: "v1.Report" */
  v1_Report: Array<v1_Report>;
  /** fetch data from the table: "v1.Report_Roles" */
  v1_Report_Roles: Array<v1_Report_Roles>;
  /** fetch aggregated fields from the table: "v1.Report_Roles" */
  v1_Report_Roles_aggregate: v1_Report_Roles_aggregate;
  /** fetch data from the table: "v1.Report_Roles" using primary key columns */
  v1_Report_Roles_by_pk?: Maybe<v1_Report_Roles>;
  /** fetch aggregated fields from the table: "v1.Report" */
  v1_Report_aggregate: v1_Report_aggregate;
  /** fetch data from the table: "v1.Report" using primary key columns */
  v1_Report_by_pk?: Maybe<v1_Report>;
  /** fetch data from the table: "v1.RestrictedNichesToOffer" */
  v1_RestrictedNichesToOffer: Array<v1_RestrictedNichesToOffer>;
  /** fetch aggregated fields from the table: "v1.RestrictedNichesToOffer" */
  v1_RestrictedNichesToOffer_aggregate: v1_RestrictedNichesToOffer_aggregate;
  /** fetch data from the table: "v1.RestrictedNichesToOffer" using primary key columns */
  v1_RestrictedNichesToOffer_by_pk?: Maybe<v1_RestrictedNichesToOffer>;
  /** fetch data from the table: "v1.Roles" */
  v1_Roles: Array<v1_Roles>;
  /** fetch aggregated fields from the table: "v1.Roles" */
  v1_Roles_aggregate: v1_Roles_aggregate;
  /** fetch data from the table: "v1.Roles" using primary key columns */
  v1_Roles_by_pk?: Maybe<v1_Roles>;
  /** fetch data from the table: "v1.SalesOrderBase" */
  v1_SalesOrderBase: Array<v1_SalesOrderBase>;
  /** fetch aggregated fields from the table: "v1.SalesOrderBase" */
  v1_SalesOrderBase_aggregate: v1_SalesOrderBase_aggregate;
  /** fetch data from the table: "v1.SalesOrderBase" using primary key columns */
  v1_SalesOrderBase_by_pk?: Maybe<v1_SalesOrderBase>;
  /** fetch data from the table: "v1.ServiceTypes" */
  v1_ServiceTypes: Array<v1_ServiceTypes>;
  /** fetch aggregated fields from the table: "v1.ServiceTypes" */
  v1_ServiceTypes_aggregate: v1_ServiceTypes_aggregate;
  /** fetch data from the table: "v1.ServiceTypes" using primary key columns */
  v1_ServiceTypes_by_pk?: Maybe<v1_ServiceTypes>;
  /** fetch data from the table: "v1.SoftwareProviders" */
  v1_SoftwareProviders: Array<v1_SoftwareProviders>;
  /** fetch aggregated fields from the table: "v1.SoftwareProviders" */
  v1_SoftwareProviders_aggregate: v1_SoftwareProviders_aggregate;
  /** fetch data from the table: "v1.SoftwareProviders" using primary key columns */
  v1_SoftwareProviders_by_pk?: Maybe<v1_SoftwareProviders>;
  /** fetch data from the table: "v1.StrategyFieldsSet" */
  v1_StrategyFieldsSet: Array<v1_StrategyFieldsSet>;
  /** fetch aggregated fields from the table: "v1.StrategyFieldsSet" */
  v1_StrategyFieldsSet_aggregate: v1_StrategyFieldsSet_aggregate;
  /** fetch data from the table: "v1.StrategyFieldsSet" using primary key columns */
  v1_StrategyFieldsSet_by_pk?: Maybe<v1_StrategyFieldsSet>;
  /** fetch data from the table: "v1.Supplier_Tag_rel" */
  v1_Supplier_Tag_rel: Array<v1_Supplier_Tag_rel>;
  /** fetch aggregated fields from the table: "v1.Supplier_Tag_rel" */
  v1_Supplier_Tag_rel_aggregate: v1_Supplier_Tag_rel_aggregate;
  /** fetch data from the table: "v1.Supplier_Tag_rel" using primary key columns */
  v1_Supplier_Tag_rel_by_pk?: Maybe<v1_Supplier_Tag_rel>;
  /** fetch data from the table: "v1.Suppliers" */
  v1_Suppliers: Array<v1_Suppliers>;
  /** fetch aggregated fields from the table: "v1.Suppliers" */
  v1_Suppliers_aggregate: v1_Suppliers_aggregate;
  /** fetch data from the table: "v1.SystemUserBase" */
  v1_SystemUserBase: Array<v1_SystemUserBase>;
  /** fetch aggregated fields from the table: "v1.SystemUserBase" */
  v1_SystemUserBase_aggregate: v1_SystemUserBase_aggregate;
  /** fetch data from the table: "v1.SystemUserBase" using primary key columns */
  v1_SystemUserBase_by_pk?: Maybe<v1_SystemUserBase>;
  /** fetch data from the table: "v1.Tags" */
  v1_Tags: Array<v1_Tags>;
  /** fetch aggregated fields from the table: "v1.Tags" */
  v1_Tags_aggregate: v1_Tags_aggregate;
  /** fetch data from the table: "v1.Tags" using primary key columns */
  v1_Tags_by_pk?: Maybe<v1_Tags>;
  /** fetch data from the table: "v1.Teams" */
  v1_Teams: Array<v1_Teams>;
  /** fetch aggregated fields from the table: "v1.Teams" */
  v1_Teams_aggregate: v1_Teams_aggregate;
  /** fetch data from the table: "v1.Teams" using primary key columns */
  v1_Teams_by_pk?: Maybe<v1_Teams>;
  /** fetch data from the table: "v1.TrackerDeal" */
  v1_TrackerDeal: Array<v1_TrackerDeal>;
  /** fetch aggregated fields from the table: "v1.TrackerDeal" */
  v1_TrackerDeal_aggregate: v1_TrackerDeal_aggregate;
  /** fetch data from the table: "v1.TrackerDeal" using primary key columns */
  v1_TrackerDeal_by_pk?: Maybe<v1_TrackerDeal>;
  /** fetch data from the table: "v1.TrackerTags" */
  v1_TrackerTags: Array<v1_TrackerTags>;
  /** fetch aggregated fields from the table: "v1.TrackerTags" */
  v1_TrackerTags_aggregate: v1_TrackerTags_aggregate;
  /** fetch data from the table: "v1.TrackerTags" using primary key columns */
  v1_TrackerTags_by_pk?: Maybe<v1_TrackerTags>;
  /** fetch data from the table: "v1.TrackerToTags" */
  v1_TrackerToTags: Array<v1_TrackerToTags>;
  /** fetch aggregated fields from the table: "v1.TrackerToTags" */
  v1_TrackerToTags_aggregate: v1_TrackerToTags_aggregate;
  /** fetch data from the table: "v1.TrackerToTags" using primary key columns */
  v1_TrackerToTags_by_pk?: Maybe<v1_TrackerToTags>;
  /** fetch data from the table: "v1.TransactionCurrencyBase" */
  v1_TransactionCurrencyBase: Array<v1_TransactionCurrencyBase>;
  /** fetch aggregated fields from the table: "v1.TransactionCurrencyBase" */
  v1_TransactionCurrencyBase_aggregate: v1_TransactionCurrencyBase_aggregate;
  /** fetch data from the table: "v1.TransactionCurrencyBase" using primary key columns */
  v1_TransactionCurrencyBase_by_pk?: Maybe<v1_TransactionCurrencyBase>;
  /** SimpleAction */
  v1_UserCheck?: Maybe<UserCheckOutput>;
  /** fetch data from the table: "v1.UsersStatus" */
  v1_UsersStatus: Array<v1_UsersStatus>;
  /** fetch aggregated fields from the table: "v1.UsersStatus" */
  v1_UsersStatus_aggregate: v1_UsersStatus_aggregate;
  /** fetch data from the table: "v1.UsersStatus" using primary key columns */
  v1_UsersStatus_by_pk?: Maybe<v1_UsersStatus>;
  /** fetch data from the table: "v1.UsersToOffers" */
  v1_UsersToOffers: Array<v1_UsersToOffers>;
  /** fetch aggregated fields from the table: "v1.UsersToOffers" */
  v1_UsersToOffers_aggregate: v1_UsersToOffers_aggregate;
  /** fetch data from the table: "v1.UsersToOffers" using primary key columns */
  v1_UsersToOffers_by_pk?: Maybe<v1_UsersToOffers>;
  /** fetch data from the table: "v1.Users_Roles" */
  v1_Users_Roles: Array<v1_Users_Roles>;
  /** fetch aggregated fields from the table: "v1.Users_Roles" */
  v1_Users_Roles_aggregate: v1_Users_Roles_aggregate;
  /** fetch data from the table: "v1.Users_Roles" using primary key columns */
  v1_Users_Roles_by_pk?: Maybe<v1_Users_Roles>;
  /** fetch data from the table: "v1.Users_Teams" */
  v1_Users_Teams: Array<v1_Users_Teams>;
  /** fetch aggregated fields from the table: "v1.Users_Teams" */
  v1_Users_Teams_aggregate: v1_Users_Teams_aggregate;
  /** fetch data from the table: "v1.Users_Teams" using primary key columns */
  v1_Users_Teams_by_pk?: Maybe<v1_Users_Teams>;
  /** fetch data from the table: "v1.WageringRequirementsLocation" */
  v1_WageringRequirementsLocation: Array<v1_WageringRequirementsLocation>;
  /** fetch aggregated fields from the table: "v1.WageringRequirementsLocation" */
  v1_WageringRequirementsLocation_aggregate: v1_WageringRequirementsLocation_aggregate;
  /** fetch data from the table: "v1.WageringRequirementsLocation" using primary key columns */
  v1_WageringRequirementsLocation_by_pk?: Maybe<v1_WageringRequirementsLocation>;
  /** fetch data from the table: "v1.Website" */
  v1_Website: Array<v1_Website>;
  /** fetch data from the table: "v1.WebsiteBlogProbability" */
  v1_WebsiteBlogProbability: Array<v1_WebsiteBlogProbability>;
  /** fetch aggregated fields from the table: "v1.WebsiteBlogProbability" */
  v1_WebsiteBlogProbability_aggregate: v1_WebsiteBlogProbability_aggregate;
  /** fetch data from the table: "v1.WebsiteBlogProbability" using primary key columns */
  v1_WebsiteBlogProbability_by_pk?: Maybe<v1_WebsiteBlogProbability>;
  /** fetch data from the table: "v1.WebsiteContentIndicator" */
  v1_WebsiteContentIndicator: Array<v1_WebsiteContentIndicator>;
  /** fetch aggregated fields from the table: "v1.WebsiteContentIndicator" */
  v1_WebsiteContentIndicator_aggregate: v1_WebsiteContentIndicator_aggregate;
  /** fetch data from the table: "v1.WebsiteContentIndicator" using primary key columns */
  v1_WebsiteContentIndicator_by_pk?: Maybe<v1_WebsiteContentIndicator>;
  /** fetch data from the table: "v1.WebsiteDToxRisk" */
  v1_WebsiteDToxRisk: Array<v1_WebsiteDToxRisk>;
  /** fetch aggregated fields from the table: "v1.WebsiteDToxRisk" */
  v1_WebsiteDToxRisk_aggregate: v1_WebsiteDToxRisk_aggregate;
  /** fetch data from the table: "v1.WebsiteDToxRisk" using primary key columns */
  v1_WebsiteDToxRisk_by_pk?: Maybe<v1_WebsiteDToxRisk>;
  /** fetch data from the table: "v1.WebsiteQualityScore" */
  v1_WebsiteQualityScore: Array<v1_WebsiteQualityScore>;
  /** fetch aggregated fields from the table: "v1.WebsiteQualityScore" */
  v1_WebsiteQualityScore_aggregate: v1_WebsiteQualityScore_aggregate;
  /** fetch data from the table: "v1.WebsiteQualityScore" using primary key columns */
  v1_WebsiteQualityScore_by_pk?: Maybe<v1_WebsiteQualityScore>;
  /** fetch data from the table: "v1.WebsiteStatus" */
  v1_WebsiteStatus: Array<v1_WebsiteStatus>;
  /** fetch aggregated fields from the table: "v1.WebsiteStatus" */
  v1_WebsiteStatus_aggregate: v1_WebsiteStatus_aggregate;
  /** fetch data from the table: "v1.WebsiteStatus" using primary key columns */
  v1_WebsiteStatus_by_pk?: Maybe<v1_WebsiteStatus>;
  /** fetch data from the table: "v1.WebsiteTopics" */
  v1_WebsiteTopics: Array<v1_WebsiteTopics>;
  /** fetch aggregated fields from the table: "v1.WebsiteTopics" */
  v1_WebsiteTopics_aggregate: v1_WebsiteTopics_aggregate;
  /** fetch data from the table: "v1.WebsiteTopics" using primary key columns */
  v1_WebsiteTopics_by_pk?: Maybe<v1_WebsiteTopics>;
  /** fetch data from the table: "v1.WebsiteUptimeStatus" */
  v1_WebsiteUptimeStatus: Array<v1_WebsiteUptimeStatus>;
  /** fetch aggregated fields from the table: "v1.WebsiteUptimeStatus" */
  v1_WebsiteUptimeStatus_aggregate: v1_WebsiteUptimeStatus_aggregate;
  /** fetch data from the table: "v1.WebsiteUptimeStatus" using primary key columns */
  v1_WebsiteUptimeStatus_by_pk?: Maybe<v1_WebsiteUptimeStatus>;
  /** fetch data from the table: "v1.Website_Platform" */
  v1_Website_Platform: Array<v1_Website_Platform>;
  /** fetch aggregated fields from the table: "v1.Website_Platform" */
  v1_Website_Platform_aggregate: v1_Website_Platform_aggregate;
  /** fetch data from the table: "v1.Website_Platform" using primary key columns */
  v1_Website_Platform_by_pk?: Maybe<v1_Website_Platform>;
  /** fetch aggregated fields from the table: "v1.Website" */
  v1_Website_aggregate: v1_Website_aggregate;
  /** fetch data from the table: "v1.Website" using primary key columns */
  v1_Website_by_pk?: Maybe<v1_Website>;
  /** fetch data from the table: "v1.WebsitesToTopics" */
  v1_WebsitesToTopics: Array<v1_WebsitesToTopics>;
  /** fetch aggregated fields from the table: "v1.WebsitesToTopics" */
  v1_WebsitesToTopics_aggregate: v1_WebsitesToTopics_aggregate;
  /** fetch data from the table: "v1.WebsitesToTopics" using primary key columns */
  v1_WebsitesToTopics_by_pk?: Maybe<v1_WebsitesToTopics>;
  /** fetch data from the table: "v1.Writers" */
  v1_Writers: Array<v1_Writers>;
  /** fetch aggregated fields from the table: "v1.Writers" */
  v1_Writers_aggregate: v1_Writers_aggregate;
  /** fetch data from the table: "v1.new_POBase" */
  v1_new_POBase: Array<v1_new_POBase>;
  /** fetch aggregated fields from the table: "v1.new_POBase" */
  v1_new_POBase_aggregate: v1_new_POBase_aggregate;
  /** fetch data from the table: "v1.new_POBase" using primary key columns */
  v1_new_POBase_by_pk?: Maybe<v1_new_POBase>;
  /** fetch data from the table: "v1.new_affiliateincomereportBase" */
  v1_new_affiliateincomereportBase: Array<v1_new_affiliateincomereportBase>;
  /** fetch aggregated fields from the table: "v1.new_affiliateincomereportBase" */
  v1_new_affiliateincomereportBase_aggregate: v1_new_affiliateincomereportBase_aggregate;
  /** fetch data from the table: "v1.new_affiliateincomereportBase" using primary key columns */
  v1_new_affiliateincomereportBase_by_pk?: Maybe<v1_new_affiliateincomereportBase>;
  /** fetch data from the table: "v1.new_affordertypeBase" */
  v1_new_affordertypeBase: Array<v1_new_affordertypeBase>;
  /** fetch aggregated fields from the table: "v1.new_affordertypeBase" */
  v1_new_affordertypeBase_aggregate: v1_new_affordertypeBase_aggregate;
  /** fetch data from the table: "v1.new_affordertypeBase" using primary key columns */
  v1_new_affordertypeBase_by_pk?: Maybe<v1_new_affordertypeBase>;
  /** fetch data from the table: "v1.new_brandBase" */
  v1_new_brandBase: Array<v1_new_brandBase>;
  /** fetch aggregated fields from the table: "v1.new_brandBase" */
  v1_new_brandBase_aggregate: v1_new_brandBase_aggregate;
  /** fetch data from the table: "v1.new_brandBase" using primary key columns */
  v1_new_brandBase_by_pk?: Maybe<v1_new_brandBase>;
  /** fetch data from the table: "v1.new_countryBase" */
  v1_new_countryBase: Array<v1_new_countryBase>;
  /** fetch aggregated fields from the table: "v1.new_countryBase" */
  v1_new_countryBase_aggregate: v1_new_countryBase_aggregate;
  /** fetch data from the table: "v1.new_languagesBase" */
  v1_new_languagesBase: Array<v1_new_languagesBase>;
  /** fetch aggregated fields from the table: "v1.new_languagesBase" */
  v1_new_languagesBase_aggregate: v1_new_languagesBase_aggregate;
  /** fetch data from the table: "v1.new_languagesBase" using primary key columns */
  v1_new_languagesBase_by_pk?: Maybe<v1_new_languagesBase>;
  /** fetch data from the table: "v1.new_new_brand_new_countryBase" */
  v1_new_new_brand_new_countryBase: Array<v1_new_new_brand_new_countryBase>;
  /** fetch aggregated fields from the table: "v1.new_new_brand_new_countryBase" */
  v1_new_new_brand_new_countryBase_aggregate: v1_new_new_brand_new_countryBase_aggregate;
  /** fetch data from the table: "v1.new_new_brand_new_countryBase" using primary key columns */
  v1_new_new_brand_new_countryBase_by_pk?: Maybe<v1_new_new_brand_new_countryBase>;
  /** fetch data from the table: "v1.new_paymentmethodBase" */
  v1_new_paymentmethodBase: Array<v1_new_paymentmethodBase>;
  /** fetch aggregated fields from the table: "v1.new_paymentmethodBase" */
  v1_new_paymentmethodBase_aggregate: v1_new_paymentmethodBase_aggregate;
  /** fetch data from the table: "v1.new_paymentmethodBase" using primary key columns */
  v1_new_paymentmethodBase_by_pk?: Maybe<v1_new_paymentmethodBase>;
  /** fetch data from the table: "v1.new_receiptBase" */
  v1_new_receiptBase: Array<v1_new_receiptBase>;
  /** fetch aggregated fields from the table: "v1.new_receiptBase" */
  v1_new_receiptBase_aggregate: v1_new_receiptBase_aggregate;
  /** fetch data from the table: "v1.new_receiptBase" using primary key columns */
  v1_new_receiptBase_by_pk?: Maybe<v1_new_receiptBase>;
  /** fetch data from the table: "v1.new_regemailBase" */
  v1_new_regemailBase: Array<v1_new_regemailBase>;
  /** fetch aggregated fields from the table: "v1.new_regemailBase" */
  v1_new_regemailBase_aggregate: v1_new_regemailBase_aggregate;
  /** fetch data from the table: "v1.new_regemailBase" using primary key columns */
  v1_new_regemailBase_by_pk?: Maybe<v1_new_regemailBase>;
  /** fetch data from the table: "v1.new_trackerBase" */
  v1_new_trackerBase: Array<v1_new_trackerBase>;
  /** fetch aggregated fields from the table: "v1.new_trackerBase" */
  v1_new_trackerBase_aggregate: v1_new_trackerBase_aggregate;
  /** fetch data from the table: "v1.new_trackerBase" using primary key columns */
  v1_new_trackerBase_by_pk?: Maybe<v1_new_trackerBase>;
  /** fetch data from the table: "v1.process" */
  v1_process: Array<v1_process>;
  /** fetch aggregated fields from the table: "v1.process" */
  v1_process_aggregate: v1_process_aggregate;
  /** fetch data from the table: "v1.process" using primary key columns */
  v1_process_by_pk?: Maybe<v1_process>;
  /** fetch data from the table: "v1.process_rate" */
  v1_process_rate: Array<v1_process_rate>;
  /** fetch aggregated fields from the table: "v1.process_rate" */
  v1_process_rate_aggregate: v1_process_rate_aggregate;
  /** fetch data from the table: "v1.process_rate" using primary key columns */
  v1_process_rate_by_pk?: Maybe<v1_process_rate>;
  /** fetch data from the table: "v1.project_topic" */
  v1_project_topic: Array<v1_project_topic>;
  /** fetch aggregated fields from the table: "v1.project_topic" */
  v1_project_topic_aggregate: v1_project_topic_aggregate;
  /** fetch data from the table: "v1.project_topic" using primary key columns */
  v1_project_topic_by_pk?: Maybe<v1_project_topic>;
};


export type query_rootClicks_OrphanTrackersArgs = {
  distinct_on?: InputMaybe<Array<Clicks_OrphanTrackers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clicks_OrphanTrackers_order_by>>;
  where?: InputMaybe<Clicks_OrphanTrackers_bool_exp>;
};


export type query_rootClicks_OrphanTrackers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<Clicks_OrphanTrackers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clicks_OrphanTrackers_order_by>>;
  where?: InputMaybe<Clicks_OrphanTrackers_bool_exp>;
};


export type query_rootClicks_linesArgs = {
  distinct_on?: InputMaybe<Array<Clicks_lines_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clicks_lines_order_by>>;
  where?: InputMaybe<Clicks_lines_bool_exp>;
};


export type query_rootClicks_lines_aggregateArgs = {
  distinct_on?: InputMaybe<Array<Clicks_lines_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clicks_lines_order_by>>;
  where?: InputMaybe<Clicks_lines_bool_exp>;
};


export type query_root_entitiesArgs = {
  representations: Array<Scalars['_Any']['input']>;
};


export type query_rootv1_AccountBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


export type query_rootv1_AccountBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


export type query_rootv1_AccountBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_AdvertisersArgs = {
  distinct_on?: InputMaybe<Array<v1_Advertisers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Advertisers_order_by>>;
  where?: InputMaybe<v1_Advertisers_bool_exp>;
};


export type query_rootv1_Advertisers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Advertisers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Advertisers_order_by>>;
  where?: InputMaybe<v1_Advertisers_bool_exp>;
};


export type query_rootv1_AffDealTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_AffDealType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffDealType_order_by>>;
  where?: InputMaybe<v1_AffDealType_bool_exp>;
};


export type query_rootv1_AffDealType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AffDealType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffDealType_order_by>>;
  where?: InputMaybe<v1_AffDealType_bool_exp>;
};


export type query_rootv1_AffDealType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_AffiliateOrderCategoriesPriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_order_by>>;
  where?: InputMaybe<v1_AffiliateOrderCategoriesPriority_bool_exp>;
};


export type query_rootv1_AffiliateOrderCategoriesPriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_order_by>>;
  where?: InputMaybe<v1_AffiliateOrderCategoriesPriority_bool_exp>;
};


export type query_rootv1_AffiliateOrderCategoriesPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_AffiliateProgramTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_AffiliateProgramType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffiliateProgramType_order_by>>;
  where?: InputMaybe<v1_AffiliateProgramType_bool_exp>;
};


export type query_rootv1_AffiliateProgramType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AffiliateProgramType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffiliateProgramType_order_by>>;
  where?: InputMaybe<v1_AffiliateProgramType_bool_exp>;
};


export type query_rootv1_AffiliateProgramType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_AllowedPlayersArgs = {
  distinct_on?: InputMaybe<Array<v1_AllowedPlayers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AllowedPlayers_order_by>>;
  where?: InputMaybe<v1_AllowedPlayers_bool_exp>;
};


export type query_rootv1_AllowedPlayers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AllowedPlayers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AllowedPlayers_order_by>>;
  where?: InputMaybe<v1_AllowedPlayers_bool_exp>;
};


export type query_rootv1_AllowedPlayers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ArticleTonesArgs = {
  distinct_on?: InputMaybe<Array<v1_ArticleTones_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ArticleTones_order_by>>;
  where?: InputMaybe<v1_ArticleTones_bool_exp>;
};


export type query_rootv1_ArticleTones_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ArticleTones_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ArticleTones_order_by>>;
  where?: InputMaybe<v1_ArticleTones_bool_exp>;
};


export type query_rootv1_ArticleTones_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BalanceArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


export type query_rootv1_BalanceActivityTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_BalanceActivityType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BalanceActivityType_order_by>>;
  where?: InputMaybe<v1_BalanceActivityType_bool_exp>;
};


export type query_rootv1_BalanceActivityType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BalanceActivityType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BalanceActivityType_order_by>>;
  where?: InputMaybe<v1_BalanceActivityType_bool_exp>;
};


export type query_rootv1_BalanceActivityType_by_pkArgs = {
  Name: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type query_rootv1_Balance_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


export type query_rootv1_Balance_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BillingInformationArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingInformation_order_by>>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};


export type query_rootv1_BillingInformation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingInformation_order_by>>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};


export type query_rootv1_BillingInformation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BillingMethodArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


export type query_rootv1_BillingMethod_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


export type query_rootv1_BillingMethod_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BonusSourceArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSource_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSource_order_by>>;
  where?: InputMaybe<v1_BonusSource_bool_exp>;
};


export type query_rootv1_BonusSource_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSource_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSource_order_by>>;
  where?: InputMaybe<v1_BonusSource_bool_exp>;
};


export type query_rootv1_BonusSource_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BonusStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusStatus_order_by>>;
  where?: InputMaybe<v1_BonusStatus_bool_exp>;
};


export type query_rootv1_BonusStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusStatus_order_by>>;
  where?: InputMaybe<v1_BonusStatus_bool_exp>;
};


export type query_rootv1_BonusStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BonusSubmitterArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSubmitter_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSubmitter_order_by>>;
  where?: InputMaybe<v1_BonusSubmitter_bool_exp>;
};


export type query_rootv1_BonusSubmitter_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSubmitter_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSubmitter_order_by>>;
  where?: InputMaybe<v1_BonusSubmitter_bool_exp>;
};


export type query_rootv1_BonusSubmitter_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BonusTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusTypes_order_by>>;
  where?: InputMaybe<v1_BonusTypes_bool_exp>;
};


export type query_rootv1_BonusTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusTypes_order_by>>;
  where?: InputMaybe<v1_BonusTypes_bool_exp>;
};


export type query_rootv1_BonusTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BonusesArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


export type query_rootv1_BonusesToCountriesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCountries_order_by>>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};


export type query_rootv1_BonusesToCountries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCountries_order_by>>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};


export type query_rootv1_BonusesToCountries_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BonusesToCurrenciesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCurrencies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCurrencies_order_by>>;
  where?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
};


export type query_rootv1_BonusesToCurrencies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCurrencies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCurrencies_order_by>>;
  where?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
};


export type query_rootv1_BonusesToCurrencies_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BonusesToTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToTypes_order_by>>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};


export type query_rootv1_BonusesToTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToTypes_order_by>>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};


export type query_rootv1_BonusesToTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Bonuses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


export type query_rootv1_Bonuses_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BonusestoGamesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusestoGames_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusestoGames_order_by>>;
  where?: InputMaybe<v1_BonusestoGames_bool_exp>;
};


export type query_rootv1_BonusestoGames_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusestoGames_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusestoGames_order_by>>;
  where?: InputMaybe<v1_BonusestoGames_bool_exp>;
};


export type query_rootv1_BonusestoGames_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BrandAliasArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandAlias_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandAlias_order_by>>;
  where?: InputMaybe<v1_BrandAlias_bool_exp>;
};


export type query_rootv1_BrandAlias_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandAlias_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandAlias_order_by>>;
  where?: InputMaybe<v1_BrandAlias_bool_exp>;
};


export type query_rootv1_BrandAlias_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_BrandPriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandPriority_order_by>>;
  where?: InputMaybe<v1_BrandPriority_bool_exp>;
};


export type query_rootv1_BrandPriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandPriority_order_by>>;
  where?: InputMaybe<v1_BrandPriority_bool_exp>;
};


export type query_rootv1_BrandPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CampaignArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


export type query_rootv1_CampaignPriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignPriority_order_by>>;
  where?: InputMaybe<v1_CampaignPriority_bool_exp>;
};


export type query_rootv1_CampaignPriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignPriority_order_by>>;
  where?: InputMaybe<v1_CampaignPriority_bool_exp>;
};


export type query_rootv1_CampaignPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CampaignStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStatus_order_by>>;
  where?: InputMaybe<v1_CampaignStatus_bool_exp>;
};


export type query_rootv1_CampaignStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStatus_order_by>>;
  where?: InputMaybe<v1_CampaignStatus_bool_exp>;
};


export type query_rootv1_CampaignStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CampaignStrategyArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


export type query_rootv1_CampaignStrategy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


export type query_rootv1_CampaignStrategy_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CampaignTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignType_order_by>>;
  where?: InputMaybe<v1_CampaignType_bool_exp>;
};


export type query_rootv1_CampaignType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignType_order_by>>;
  where?: InputMaybe<v1_CampaignType_bool_exp>;
};


export type query_rootv1_CampaignType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Campaign_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


export type query_rootv1_Campaign_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<v1_Categories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Categories_order_by>>;
  where?: InputMaybe<v1_Categories_bool_exp>;
};


export type query_rootv1_Categories_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Categories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Categories_order_by>>;
  where?: InputMaybe<v1_Categories_bool_exp>;
};


export type query_rootv1_Categories_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CheckoutArgs = {
  distinct_on?: InputMaybe<Array<v1_Checkout_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Checkout_order_by>>;
  where?: InputMaybe<v1_Checkout_bool_exp>;
};


export type query_rootv1_CheckoutDetailArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


export type query_rootv1_CheckoutDetail_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


export type query_rootv1_CheckoutDetail_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CheckoutStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutStatus_order_by>>;
  where?: InputMaybe<v1_CheckoutStatus_bool_exp>;
};


export type query_rootv1_CheckoutStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutStatus_order_by>>;
  where?: InputMaybe<v1_CheckoutStatus_bool_exp>;
};


export type query_rootv1_CheckoutStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Checkout_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Checkout_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Checkout_order_by>>;
  where?: InputMaybe<v1_Checkout_bool_exp>;
};


export type query_rootv1_Checkout_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CitiesArgs = {
  distinct_on?: InputMaybe<Array<v1_Cities_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Cities_order_by>>;
  where?: InputMaybe<v1_Cities_bool_exp>;
};


export type query_rootv1_Cities_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Cities_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Cities_order_by>>;
  where?: InputMaybe<v1_Cities_bool_exp>;
};


export type query_rootv1_Cities_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ClicksByTrackersArgs = {
  distinct_on?: InputMaybe<Array<v1_ClicksByTrackers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ClicksByTrackers_order_by>>;
  where?: InputMaybe<v1_ClicksByTrackers_bool_exp>;
};


export type query_rootv1_ClicksByTrackersNewArgs = {
  distinct_on?: InputMaybe<Array<v1_ClicksByTrackersNew_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ClicksByTrackersNew_order_by>>;
  where?: InputMaybe<v1_ClicksByTrackersNew_bool_exp>;
};


export type query_rootv1_ClicksByTrackersNew_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ClicksByTrackersNew_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ClicksByTrackersNew_order_by>>;
  where?: InputMaybe<v1_ClicksByTrackersNew_bool_exp>;
};


export type query_rootv1_ClicksByTrackersNew_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ClicksByTrackers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ClicksByTrackers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ClicksByTrackers_order_by>>;
  where?: InputMaybe<v1_ClicksByTrackers_bool_exp>;
};


export type query_rootv1_ClicksByTrackers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CompanyTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_CompanyType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CompanyType_order_by>>;
  where?: InputMaybe<v1_CompanyType_bool_exp>;
};


export type query_rootv1_CompanyType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CompanyType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CompanyType_order_by>>;
  where?: InputMaybe<v1_CompanyType_bool_exp>;
};


export type query_rootv1_CompanyType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<v1_Configurations_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Configurations_order_by>>;
  where?: InputMaybe<v1_Configurations_bool_exp>;
};


export type query_rootv1_Configurations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Configurations_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Configurations_order_by>>;
  where?: InputMaybe<v1_Configurations_bool_exp>;
};


export type query_rootv1_Configurations_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ContactBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


export type query_rootv1_ContactBasePaymentOptionArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBasePaymentOption_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBasePaymentOption_order_by>>;
  where?: InputMaybe<v1_ContactBasePaymentOption_bool_exp>;
};


export type query_rootv1_ContactBasePaymentOption_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBasePaymentOption_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBasePaymentOption_order_by>>;
  where?: InputMaybe<v1_ContactBasePaymentOption_bool_exp>;
};


export type query_rootv1_ContactBasePaymentOption_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ContactBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


export type query_rootv1_ContactBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ContactTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactType_order_by>>;
  where?: InputMaybe<v1_ContactType_bool_exp>;
};


export type query_rootv1_ContactType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactType_order_by>>;
  where?: InputMaybe<v1_ContactType_bool_exp>;
};


export type query_rootv1_ContactType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Contacts_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Contacts_Roles_order_by>>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};


export type query_rootv1_Contacts_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Contacts_Roles_order_by>>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};


export type query_rootv1_Contacts_Roles_by_pkArgs = {
  contact_id: Scalars['Int']['input'];
  role_id: Scalars['Int']['input'];
};


export type query_rootv1_ContentLinkArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentLink_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentLink_order_by>>;
  where?: InputMaybe<v1_ContentLink_bool_exp>;
};


export type query_rootv1_ContentLink_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentLink_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentLink_order_by>>;
  where?: InputMaybe<v1_ContentLink_bool_exp>;
};


export type query_rootv1_ContentLink_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ContentTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentTypes_order_by>>;
  where?: InputMaybe<v1_ContentTypes_bool_exp>;
};


export type query_rootv1_ContentTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentTypes_order_by>>;
  where?: InputMaybe<v1_ContentTypes_bool_exp>;
};


export type query_rootv1_ContentTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Credential_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credential_Roles_order_by>>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};


export type query_rootv1_Credential_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credential_Roles_order_by>>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};


export type query_rootv1_Credential_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_CredentialsArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


export type query_rootv1_Credentials_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


export type query_rootv1_Credentials_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_DomainExtensionsArgs = {
  distinct_on?: InputMaybe<Array<v1_DomainExtensions_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_DomainExtensions_order_by>>;
  where?: InputMaybe<v1_DomainExtensions_bool_exp>;
};


export type query_rootv1_DomainExtensions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_DomainExtensions_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_DomainExtensions_order_by>>;
  where?: InputMaybe<v1_DomainExtensions_bool_exp>;
};


export type query_rootv1_DomainExtensions_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_EventsArgs = {
  distinct_on?: InputMaybe<Array<v1_Events_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Events_order_by>>;
  where?: InputMaybe<v1_Events_bool_exp>;
};


export type query_rootv1_Events_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Events_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Events_order_by>>;
  where?: InputMaybe<v1_Events_bool_exp>;
};


export type query_rootv1_Events_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ExpiringRecPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_ExpiringRecPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ExpiringRecPayments_order_by>>;
  where?: InputMaybe<v1_ExpiringRecPayments_bool_exp>;
};


export type query_rootv1_ExpiringRecPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ExpiringRecPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ExpiringRecPayments_order_by>>;
  where?: InputMaybe<v1_ExpiringRecPayments_bool_exp>;
};


export type query_rootv1_FilesArgs = {
  distinct_on?: InputMaybe<Array<v1_Files_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Files_order_by>>;
  where?: InputMaybe<v1_Files_bool_exp>;
};


export type query_rootv1_Files_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Files_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Files_order_by>>;
  where?: InputMaybe<v1_Files_bool_exp>;
};


export type query_rootv1_Files_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_GameTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_GameType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_GameType_order_by>>;
  where?: InputMaybe<v1_GameType_bool_exp>;
};


export type query_rootv1_GameType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_GameType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_GameType_order_by>>;
  where?: InputMaybe<v1_GameType_bool_exp>;
};


export type query_rootv1_GameType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_InvoiceBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


export type query_rootv1_InvoiceBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


export type query_rootv1_InvoiceBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_InvoiceDetailBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


export type query_rootv1_InvoiceDetailBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


export type query_rootv1_InvoiceDetailBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_InvoiceStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceStatus_order_by>>;
  where?: InputMaybe<v1_InvoiceStatus_bool_exp>;
};


export type query_rootv1_InvoiceStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceStatus_order_by>>;
  where?: InputMaybe<v1_InvoiceStatus_bool_exp>;
};


export type query_rootv1_InvoiceStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_LBInProgressPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_LBInProgressPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LBInProgressPosts_order_by>>;
  where?: InputMaybe<v1_LBInProgressPosts_bool_exp>;
};


export type query_rootv1_LBInProgressPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LBInProgressPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LBInProgressPosts_order_by>>;
  where?: InputMaybe<v1_LBInProgressPosts_bool_exp>;
};


export type query_rootv1_LBPublishedPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_LBPublishedPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LBPublishedPosts_order_by>>;
  where?: InputMaybe<v1_LBPublishedPosts_bool_exp>;
};


export type query_rootv1_LBPublishedPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LBPublishedPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LBPublishedPosts_order_by>>;
  where?: InputMaybe<v1_LBPublishedPosts_bool_exp>;
};


export type query_rootv1_LandingPagePriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPagePriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPagePriority_order_by>>;
  where?: InputMaybe<v1_LandingPagePriority_bool_exp>;
};


export type query_rootv1_LandingPagePriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPagePriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPagePriority_order_by>>;
  where?: InputMaybe<v1_LandingPagePriority_bool_exp>;
};


export type query_rootv1_LandingPagePriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_LandingPagesArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


export type query_rootv1_LandingPages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


export type query_rootv1_LandingPages_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_LanguagePriceStepArgs = {
  distinct_on?: InputMaybe<Array<v1_LanguagePriceStep_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LanguagePriceStep_order_by>>;
  where?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
};


export type query_rootv1_LanguagePriceStep_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LanguagePriceStep_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LanguagePriceStep_order_by>>;
  where?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
};


export type query_rootv1_LanguagePriceStep_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketPlaceCategoriesArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
};


export type query_rootv1_MarketPlaceCategoriesTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategoriesTypes_bool_exp>;
};


export type query_rootv1_MarketPlaceCategoriesTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategoriesTypes_bool_exp>;
};


export type query_rootv1_MarketPlaceCategoriesTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketPlaceCategories_OffersArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};


export type query_rootv1_MarketPlaceCategories_Offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};


export type query_rootv1_MarketPlaceCategories_Offers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketPlaceCategories_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
};


export type query_rootv1_MarketPlaceCategories_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketPlaceOfferNicheArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOfferNiche_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOfferNiche_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOfferNiche_bool_exp>;
};


export type query_rootv1_MarketPlaceOfferNiche_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOfferNiche_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOfferNiche_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOfferNiche_bool_exp>;
};


export type query_rootv1_MarketPlaceOfferNiche_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketPlaceOrderArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrder_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};


export type query_rootv1_MarketPlaceOrderRequestArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderRequest_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};


export type query_rootv1_MarketPlaceOrderRequest_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderRequest_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};


export type query_rootv1_MarketPlaceOrderRequest_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketPlaceOrderStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderStatus_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderStatus_bool_exp>;
};


export type query_rootv1_MarketPlaceOrderStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderStatus_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderStatus_bool_exp>;
};


export type query_rootv1_MarketPlaceOrderStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketPlaceOrder_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrder_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};


export type query_rootv1_MarketPlaceOrder_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketPlaceRegisterStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_order_by>>;
  where?: InputMaybe<v1_MarketPlaceRegisterStatus_bool_exp>;
};


export type query_rootv1_MarketPlaceRegisterStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_order_by>>;
  where?: InputMaybe<v1_MarketPlaceRegisterStatus_bool_exp>;
};


export type query_rootv1_MarketPlaceRegisterStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketType_order_by>>;
  where?: InputMaybe<v1_MarketType_bool_exp>;
};


export type query_rootv1_MarketType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketType_order_by>>;
  where?: InputMaybe<v1_MarketType_bool_exp>;
};


export type query_rootv1_MarketType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketplaceProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceProjects_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceProjects_order_by>>;
  where?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
};


export type query_rootv1_MarketplaceProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceProjects_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceProjects_order_by>>;
  where?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
};


export type query_rootv1_MarketplaceProjects_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MarketplaceStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceStatus_order_by>>;
  where?: InputMaybe<v1_MarketplaceStatus_bool_exp>;
};


export type query_rootv1_MarketplaceStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceStatus_order_by>>;
  where?: InputMaybe<v1_MarketplaceStatus_bool_exp>;
};


export type query_rootv1_MarketplaceStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_MonthsArgs = {
  distinct_on?: InputMaybe<Array<v1_Months_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Months_order_by>>;
  where?: InputMaybe<v1_Months_bool_exp>;
};


export type query_rootv1_Months_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Months_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Months_order_by>>;
  where?: InputMaybe<v1_Months_bool_exp>;
};


export type query_rootv1_Months_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_OfferArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


export type query_rootv1_OfferStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferStatus_order_by>>;
  where?: InputMaybe<v1_OfferStatus_bool_exp>;
};


export type query_rootv1_OfferStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferStatus_order_by>>;
  where?: InputMaybe<v1_OfferStatus_bool_exp>;
};


export type query_rootv1_OfferStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_OfferTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferTags_order_by>>;
  where?: InputMaybe<v1_OfferTags_bool_exp>;
};


export type query_rootv1_OfferTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferTags_order_by>>;
  where?: InputMaybe<v1_OfferTags_bool_exp>;
};


export type query_rootv1_OfferTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_OfferToTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferToTags_order_by>>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};


export type query_rootv1_OfferToTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferToTags_order_by>>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};


export type query_rootv1_OfferToTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


export type query_rootv1_Offer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_OrderForArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderFor_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderFor_order_by>>;
  where?: InputMaybe<v1_OrderFor_bool_exp>;
};


export type query_rootv1_OrderFor_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderFor_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderFor_order_by>>;
  where?: InputMaybe<v1_OrderFor_bool_exp>;
};


export type query_rootv1_OrderFor_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_OrderPayToArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderPayTo_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderPayTo_order_by>>;
  where?: InputMaybe<v1_OrderPayTo_bool_exp>;
};


export type query_rootv1_OrderPayTo_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderPayTo_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderPayTo_order_by>>;
  where?: InputMaybe<v1_OrderPayTo_bool_exp>;
};


export type query_rootv1_OrderPayTo_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_OrderPriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderPriority_order_by>>;
  where?: InputMaybe<v1_OrderPriority_bool_exp>;
};


export type query_rootv1_OrderPriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderPriority_order_by>>;
  where?: InputMaybe<v1_OrderPriority_bool_exp>;
};


export type query_rootv1_OrderPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_OrderTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderType_order_by>>;
  where?: InputMaybe<v1_OrderType_bool_exp>;
};


export type query_rootv1_OrderType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderType_order_by>>;
  where?: InputMaybe<v1_OrderType_bool_exp>;
};


export type query_rootv1_OrderType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_OutreachPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_OutreachPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OutreachPosts_order_by>>;
  where?: InputMaybe<v1_OutreachPosts_bool_exp>;
};


export type query_rootv1_OutreachPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OutreachPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OutreachPosts_order_by>>;
  where?: InputMaybe<v1_OutreachPosts_bool_exp>;
};


export type query_rootv1_PageArgs = {
  distinct_on?: InputMaybe<Array<v1_Page_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Page_order_by>>;
  where?: InputMaybe<v1_Page_bool_exp>;
};


export type query_rootv1_Page_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Page_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Page_order_by>>;
  where?: InputMaybe<v1_Page_bool_exp>;
};


export type query_rootv1_Page_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PaymentCompaniesArgs = {
  distinct_on?: InputMaybe<Array<v1_PaymentCompanies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PaymentCompanies_order_by>>;
  where?: InputMaybe<v1_PaymentCompanies_bool_exp>;
};


export type query_rootv1_PaymentCompanies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PaymentCompanies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PaymentCompanies_order_by>>;
  where?: InputMaybe<v1_PaymentCompanies_bool_exp>;
};


export type query_rootv1_PaymentCompanies_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PaymentFrequencyArgs = {
  distinct_on?: InputMaybe<Array<v1_PaymentFrequency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PaymentFrequency_order_by>>;
  where?: InputMaybe<v1_PaymentFrequency_bool_exp>;
};


export type query_rootv1_PaymentFrequency_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PaymentFrequency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PaymentFrequency_order_by>>;
  where?: InputMaybe<v1_PaymentFrequency_bool_exp>;
};


export type query_rootv1_PaymentFrequency_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PoToPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PoToPosts_order_by>>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};


export type query_rootv1_PoToPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PoToPosts_order_by>>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};


export type query_rootv1_PoToPosts_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostDurationArgs = {
  distinct_on?: InputMaybe<Array<v1_PostDuration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostDuration_order_by>>;
  where?: InputMaybe<v1_PostDuration_bool_exp>;
};


export type query_rootv1_PostDuration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostDuration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostDuration_order_by>>;
  where?: InputMaybe<v1_PostDuration_bool_exp>;
};


export type query_rootv1_PostDuration_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostNicheArgs = {
  distinct_on?: InputMaybe<Array<v1_PostNiche_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostNiche_order_by>>;
  where?: InputMaybe<v1_PostNiche_bool_exp>;
};


export type query_rootv1_PostNiche_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostNiche_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostNiche_order_by>>;
  where?: InputMaybe<v1_PostNiche_bool_exp>;
};


export type query_rootv1_PostNiche_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


export type query_rootv1_PostsAnchorTextTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsAnchorTextType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsAnchorTextType_order_by>>;
  where?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
};


export type query_rootv1_PostsAnchorTextType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsAnchorTextType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsAnchorTextType_order_by>>;
  where?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
};


export type query_rootv1_PostsAnchorTextType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsContentStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsContentStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsContentStatus_order_by>>;
  where?: InputMaybe<v1_PostsContentStatus_bool_exp>;
};


export type query_rootv1_PostsContentStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsContentStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsContentStatus_order_by>>;
  where?: InputMaybe<v1_PostsContentStatus_bool_exp>;
};


export type query_rootv1_PostsContentStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsCopyScapeArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsCopyScape_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsCopyScape_order_by>>;
  where?: InputMaybe<v1_PostsCopyScape_bool_exp>;
};


export type query_rootv1_PostsCopyScape_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsCopyScape_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsCopyScape_order_by>>;
  where?: InputMaybe<v1_PostsCopyScape_bool_exp>;
};


export type query_rootv1_PostsCopyScape_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsLinkStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsLinkStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsLinkStatus_order_by>>;
  where?: InputMaybe<v1_PostsLinkStatus_bool_exp>;
};


export type query_rootv1_PostsLinkStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsLinkStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsLinkStatus_order_by>>;
  where?: InputMaybe<v1_PostsLinkStatus_bool_exp>;
};


export type query_rootv1_PostsLinkStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsOnlineStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsOnlineStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsOnlineStatus_order_by>>;
  where?: InputMaybe<v1_PostsOnlineStatus_bool_exp>;
};


export type query_rootv1_PostsOnlineStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsOnlineStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsOnlineStatus_order_by>>;
  where?: InputMaybe<v1_PostsOnlineStatus_bool_exp>;
};


export type query_rootv1_PostsOnlineStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsOutreachStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsOutreachStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsOutreachStatus_order_by>>;
  where?: InputMaybe<v1_PostsOutreachStatus_bool_exp>;
};


export type query_rootv1_PostsOutreachStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsOutreachStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsOutreachStatus_order_by>>;
  where?: InputMaybe<v1_PostsOutreachStatus_bool_exp>;
};


export type query_rootv1_PostsOutreachStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsPageTypeDeprecatedArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsPageTypeDeprecated_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsPageTypeDeprecated_order_by>>;
  where?: InputMaybe<v1_PostsPageTypeDeprecated_bool_exp>;
};


export type query_rootv1_PostsPageTypeDeprecated_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsPageTypeDeprecated_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsPageTypeDeprecated_order_by>>;
  where?: InputMaybe<v1_PostsPageTypeDeprecated_bool_exp>;
};


export type query_rootv1_PostsPageTypeDeprecated_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsRemovalStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsRemovalStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsRemovalStatus_order_by>>;
  where?: InputMaybe<v1_PostsRemovalStatus_bool_exp>;
};


export type query_rootv1_PostsRemovalStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsRemovalStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsRemovalStatus_order_by>>;
  where?: InputMaybe<v1_PostsRemovalStatus_bool_exp>;
};


export type query_rootv1_PostsRemovalStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsSEOApprovalArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsSEOApproval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsSEOApproval_order_by>>;
  where?: InputMaybe<v1_PostsSEOApproval_bool_exp>;
};


export type query_rootv1_PostsSEOApproval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsSEOApproval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsSEOApproval_order_by>>;
  where?: InputMaybe<v1_PostsSEOApproval_bool_exp>;
};


export type query_rootv1_PostsSEOApproval_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsSEODeclineReasonArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsSEODeclineReason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsSEODeclineReason_order_by>>;
  where?: InputMaybe<v1_PostsSEODeclineReason_bool_exp>;
};


export type query_rootv1_PostsSEODeclineReason_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsSEODeclineReason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsSEODeclineReason_order_by>>;
  where?: InputMaybe<v1_PostsSEODeclineReason_bool_exp>;
};


export type query_rootv1_PostsSEODeclineReason_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsStatus_order_by>>;
  where?: InputMaybe<v1_PostsStatus_bool_exp>;
};


export type query_rootv1_PostsStatusCodeArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsStatusCode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsStatusCode_order_by>>;
  where?: InputMaybe<v1_PostsStatusCode_bool_exp>;
};


export type query_rootv1_PostsStatusCode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsStatusCode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsStatusCode_order_by>>;
  where?: InputMaybe<v1_PostsStatusCode_bool_exp>;
};


export type query_rootv1_PostsStatusCode_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsStatus_order_by>>;
  where?: InputMaybe<v1_PostsStatus_bool_exp>;
};


export type query_rootv1_PostsStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PostsToIndexArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsToIndex_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsToIndex_order_by>>;
  where?: InputMaybe<v1_PostsToIndex_bool_exp>;
};


export type query_rootv1_PostsToIndex_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsToIndex_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsToIndex_order_by>>;
  where?: InputMaybe<v1_PostsToIndex_bool_exp>;
};


export type query_rootv1_Posts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


export type query_rootv1_Posts_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<v1_Preferences_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Preferences_order_by>>;
  where?: InputMaybe<v1_Preferences_bool_exp>;
};


export type query_rootv1_PreferencesMarketplaceArgs = {
  distinct_on?: InputMaybe<Array<v1_PreferencesMarketplace_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PreferencesMarketplace_order_by>>;
  where?: InputMaybe<v1_PreferencesMarketplace_bool_exp>;
};


export type query_rootv1_PreferencesMarketplace_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PreferencesMarketplace_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PreferencesMarketplace_order_by>>;
  where?: InputMaybe<v1_PreferencesMarketplace_bool_exp>;
};


export type query_rootv1_PreferencesMarketplace_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Preferences_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Preferences_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Preferences_order_by>>;
  where?: InputMaybe<v1_Preferences_bool_exp>;
};


export type query_rootv1_Preferences_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ProjectArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


export type query_rootv1_ProjectImportanceArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectImportance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectImportance_order_by>>;
  where?: InputMaybe<v1_ProjectImportance_bool_exp>;
};


export type query_rootv1_ProjectImportance_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectImportance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectImportance_order_by>>;
  where?: InputMaybe<v1_ProjectImportance_bool_exp>;
};


export type query_rootv1_ProjectImportance_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ProjectManagersArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectManagers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectManagers_order_by>>;
  where?: InputMaybe<v1_ProjectManagers_bool_exp>;
};


export type query_rootv1_ProjectManagers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectManagers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectManagers_order_by>>;
  where?: InputMaybe<v1_ProjectManagers_bool_exp>;
};


export type query_rootv1_ProjectStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectStatus_order_by>>;
  where?: InputMaybe<v1_ProjectStatus_bool_exp>;
};


export type query_rootv1_ProjectStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectStatus_order_by>>;
  where?: InputMaybe<v1_ProjectStatus_bool_exp>;
};


export type query_rootv1_ProjectStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ProjectTopicArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectTopic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectTopic_order_by>>;
  where?: InputMaybe<v1_ProjectTopic_bool_exp>;
};


export type query_rootv1_ProjectTopic_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectTopic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectTopic_order_by>>;
  where?: InputMaybe<v1_ProjectTopic_bool_exp>;
};


export type query_rootv1_ProjectTopic_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Project_CountriesArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_Countries_order_by>>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};


export type query_rootv1_Project_Countries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_Countries_order_by>>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};


export type query_rootv1_Project_Countries_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Project_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


export type query_rootv1_Project_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PublisherPostStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PublisherPostStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PublisherPostStatus_order_by>>;
  where?: InputMaybe<v1_PublisherPostStatus_bool_exp>;
};


export type query_rootv1_PublisherPostStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PublisherPostStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PublisherPostStatus_order_by>>;
  where?: InputMaybe<v1_PublisherPostStatus_bool_exp>;
};


export type query_rootv1_PublisherPostStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_PurchaseOrderStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PurchaseOrderStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PurchaseOrderStatus_order_by>>;
  where?: InputMaybe<v1_PurchaseOrderStatus_bool_exp>;
};


export type query_rootv1_PurchaseOrderStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PurchaseOrderStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PurchaseOrderStatus_order_by>>;
  where?: InputMaybe<v1_PurchaseOrderStatus_bool_exp>;
};


export type query_rootv1_PurchaseOrderStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ReceiptStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_ReceiptStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ReceiptStatus_order_by>>;
  where?: InputMaybe<v1_ReceiptStatus_bool_exp>;
};


export type query_rootv1_ReceiptStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ReceiptStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ReceiptStatus_order_by>>;
  where?: InputMaybe<v1_ReceiptStatus_bool_exp>;
};


export type query_rootv1_ReceiptStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_RecurringPaymentStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPaymentStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPaymentStatus_order_by>>;
  where?: InputMaybe<v1_RecurringPaymentStatus_bool_exp>;
};


export type query_rootv1_RecurringPaymentStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPaymentStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPaymentStatus_order_by>>;
  where?: InputMaybe<v1_RecurringPaymentStatus_bool_exp>;
};


export type query_rootv1_RecurringPaymentStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_RecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


export type query_rootv1_RecurringPayments_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_Roles_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};


export type query_rootv1_RecurringPayments_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_Roles_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};


export type query_rootv1_RecurringPayments_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_RecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


export type query_rootv1_RecurringPayments_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ReportArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_order_by>>;
  where?: InputMaybe<v1_Report_bool_exp>;
};


export type query_rootv1_Report_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_Roles_order_by>>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};


export type query_rootv1_Report_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_Roles_order_by>>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};


export type query_rootv1_Report_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Report_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_order_by>>;
  where?: InputMaybe<v1_Report_bool_exp>;
};


export type query_rootv1_Report_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_RestrictedNichesToOfferArgs = {
  distinct_on?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RestrictedNichesToOffer_order_by>>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};


export type query_rootv1_RestrictedNichesToOffer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RestrictedNichesToOffer_order_by>>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};


export type query_rootv1_RestrictedNichesToOffer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Roles_order_by>>;
  where?: InputMaybe<v1_Roles_bool_exp>;
};


export type query_rootv1_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Roles_order_by>>;
  where?: InputMaybe<v1_Roles_bool_exp>;
};


export type query_rootv1_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_SalesOrderBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


export type query_rootv1_SalesOrderBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


export type query_rootv1_SalesOrderBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_ServiceTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_ServiceTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ServiceTypes_order_by>>;
  where?: InputMaybe<v1_ServiceTypes_bool_exp>;
};


export type query_rootv1_ServiceTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ServiceTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ServiceTypes_order_by>>;
  where?: InputMaybe<v1_ServiceTypes_bool_exp>;
};


export type query_rootv1_ServiceTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_SoftwareProvidersArgs = {
  distinct_on?: InputMaybe<Array<v1_SoftwareProviders_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SoftwareProviders_order_by>>;
  where?: InputMaybe<v1_SoftwareProviders_bool_exp>;
};


export type query_rootv1_SoftwareProviders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SoftwareProviders_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SoftwareProviders_order_by>>;
  where?: InputMaybe<v1_SoftwareProviders_bool_exp>;
};


export type query_rootv1_SoftwareProviders_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_StrategyFieldsSetArgs = {
  distinct_on?: InputMaybe<Array<v1_StrategyFieldsSet_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_StrategyFieldsSet_order_by>>;
  where?: InputMaybe<v1_StrategyFieldsSet_bool_exp>;
};


export type query_rootv1_StrategyFieldsSet_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_StrategyFieldsSet_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_StrategyFieldsSet_order_by>>;
  where?: InputMaybe<v1_StrategyFieldsSet_bool_exp>;
};


export type query_rootv1_StrategyFieldsSet_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Supplier_Tag_relArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};


export type query_rootv1_Supplier_Tag_rel_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};


export type query_rootv1_Supplier_Tag_rel_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_SuppliersArgs = {
  distinct_on?: InputMaybe<Array<v1_Suppliers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Suppliers_order_by>>;
  where?: InputMaybe<v1_Suppliers_bool_exp>;
};


export type query_rootv1_Suppliers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Suppliers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Suppliers_order_by>>;
  where?: InputMaybe<v1_Suppliers_bool_exp>;
};


export type query_rootv1_SystemUserBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_SystemUserBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SystemUserBase_order_by>>;
  where?: InputMaybe<v1_SystemUserBase_bool_exp>;
};


export type query_rootv1_SystemUserBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SystemUserBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SystemUserBase_order_by>>;
  where?: InputMaybe<v1_SystemUserBase_bool_exp>;
};


export type query_rootv1_SystemUserBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_TagsArgs = {
  distinct_on?: InputMaybe<Array<v1_Tags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Tags_order_by>>;
  where?: InputMaybe<v1_Tags_bool_exp>;
};


export type query_rootv1_Tags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Tags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Tags_order_by>>;
  where?: InputMaybe<v1_Tags_bool_exp>;
};


export type query_rootv1_Tags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_TeamsArgs = {
  distinct_on?: InputMaybe<Array<v1_Teams_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Teams_order_by>>;
  where?: InputMaybe<v1_Teams_bool_exp>;
};


export type query_rootv1_Teams_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Teams_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Teams_order_by>>;
  where?: InputMaybe<v1_Teams_bool_exp>;
};


export type query_rootv1_Teams_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_TrackerDealArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerDeal_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerDeal_order_by>>;
  where?: InputMaybe<v1_TrackerDeal_bool_exp>;
};


export type query_rootv1_TrackerDeal_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerDeal_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerDeal_order_by>>;
  where?: InputMaybe<v1_TrackerDeal_bool_exp>;
};


export type query_rootv1_TrackerDeal_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_TrackerTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerTags_order_by>>;
  where?: InputMaybe<v1_TrackerTags_bool_exp>;
};


export type query_rootv1_TrackerTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerTags_order_by>>;
  where?: InputMaybe<v1_TrackerTags_bool_exp>;
};


export type query_rootv1_TrackerTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_TrackerToTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerToTags_order_by>>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};


export type query_rootv1_TrackerToTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerToTags_order_by>>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};


export type query_rootv1_TrackerToTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_TransactionCurrencyBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_TransactionCurrencyBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TransactionCurrencyBase_order_by>>;
  where?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
};


export type query_rootv1_TransactionCurrencyBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TransactionCurrencyBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TransactionCurrencyBase_order_by>>;
  where?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
};


export type query_rootv1_TransactionCurrencyBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_UserCheckArgs = {
  user: UserCheckInput;
};


export type query_rootv1_UsersStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersStatus_order_by>>;
  where?: InputMaybe<v1_UsersStatus_bool_exp>;
};


export type query_rootv1_UsersStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersStatus_order_by>>;
  where?: InputMaybe<v1_UsersStatus_bool_exp>;
};


export type query_rootv1_UsersStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_UsersToOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersToOffers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersToOffers_order_by>>;
  where?: InputMaybe<v1_UsersToOffers_bool_exp>;
};


export type query_rootv1_UsersToOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersToOffers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersToOffers_order_by>>;
  where?: InputMaybe<v1_UsersToOffers_bool_exp>;
};


export type query_rootv1_UsersToOffers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Users_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Roles_order_by>>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};


export type query_rootv1_Users_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Roles_order_by>>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};


export type query_rootv1_Users_Roles_by_pkArgs = {
  role_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type query_rootv1_Users_TeamsArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Teams_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Teams_order_by>>;
  where?: InputMaybe<v1_Users_Teams_bool_exp>;
};


export type query_rootv1_Users_Teams_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Teams_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Teams_order_by>>;
  where?: InputMaybe<v1_Users_Teams_bool_exp>;
};


export type query_rootv1_Users_Teams_by_pkArgs = {
  team_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type query_rootv1_WageringRequirementsLocationArgs = {
  distinct_on?: InputMaybe<Array<v1_WageringRequirementsLocation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WageringRequirementsLocation_order_by>>;
  where?: InputMaybe<v1_WageringRequirementsLocation_bool_exp>;
};


export type query_rootv1_WageringRequirementsLocation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WageringRequirementsLocation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WageringRequirementsLocation_order_by>>;
  where?: InputMaybe<v1_WageringRequirementsLocation_bool_exp>;
};


export type query_rootv1_WageringRequirementsLocation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_WebsiteArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


export type query_rootv1_WebsiteBlogProbabilityArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteBlogProbability_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteBlogProbability_order_by>>;
  where?: InputMaybe<v1_WebsiteBlogProbability_bool_exp>;
};


export type query_rootv1_WebsiteBlogProbability_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteBlogProbability_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteBlogProbability_order_by>>;
  where?: InputMaybe<v1_WebsiteBlogProbability_bool_exp>;
};


export type query_rootv1_WebsiteBlogProbability_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_WebsiteContentIndicatorArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteContentIndicator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteContentIndicator_order_by>>;
  where?: InputMaybe<v1_WebsiteContentIndicator_bool_exp>;
};


export type query_rootv1_WebsiteContentIndicator_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteContentIndicator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteContentIndicator_order_by>>;
  where?: InputMaybe<v1_WebsiteContentIndicator_bool_exp>;
};


export type query_rootv1_WebsiteContentIndicator_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_WebsiteDToxRiskArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteDToxRisk_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteDToxRisk_order_by>>;
  where?: InputMaybe<v1_WebsiteDToxRisk_bool_exp>;
};


export type query_rootv1_WebsiteDToxRisk_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteDToxRisk_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteDToxRisk_order_by>>;
  where?: InputMaybe<v1_WebsiteDToxRisk_bool_exp>;
};


export type query_rootv1_WebsiteDToxRisk_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_WebsiteQualityScoreArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteQualityScore_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteQualityScore_order_by>>;
  where?: InputMaybe<v1_WebsiteQualityScore_bool_exp>;
};


export type query_rootv1_WebsiteQualityScore_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteQualityScore_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteQualityScore_order_by>>;
  where?: InputMaybe<v1_WebsiteQualityScore_bool_exp>;
};


export type query_rootv1_WebsiteQualityScore_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_WebsiteStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteStatus_order_by>>;
  where?: InputMaybe<v1_WebsiteStatus_bool_exp>;
};


export type query_rootv1_WebsiteStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteStatus_order_by>>;
  where?: InputMaybe<v1_WebsiteStatus_bool_exp>;
};


export type query_rootv1_WebsiteStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_WebsiteTopicsArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteTopics_order_by>>;
  where?: InputMaybe<v1_WebsiteTopics_bool_exp>;
};


export type query_rootv1_WebsiteTopics_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteTopics_order_by>>;
  where?: InputMaybe<v1_WebsiteTopics_bool_exp>;
};


export type query_rootv1_WebsiteTopics_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_WebsiteUptimeStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteUptimeStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteUptimeStatus_order_by>>;
  where?: InputMaybe<v1_WebsiteUptimeStatus_bool_exp>;
};


export type query_rootv1_WebsiteUptimeStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteUptimeStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteUptimeStatus_order_by>>;
  where?: InputMaybe<v1_WebsiteUptimeStatus_bool_exp>;
};


export type query_rootv1_WebsiteUptimeStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Website_PlatformArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_Platform_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_Platform_order_by>>;
  where?: InputMaybe<v1_Website_Platform_bool_exp>;
};


export type query_rootv1_Website_Platform_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_Platform_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_Platform_order_by>>;
  where?: InputMaybe<v1_Website_Platform_bool_exp>;
};


export type query_rootv1_Website_Platform_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_Website_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


export type query_rootv1_Website_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_WebsitesToTopicsArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsitesToTopics_order_by>>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};


export type query_rootv1_WebsitesToTopics_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsitesToTopics_order_by>>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};


export type query_rootv1_WebsitesToTopics_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_WritersArgs = {
  distinct_on?: InputMaybe<Array<v1_Writers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Writers_order_by>>;
  where?: InputMaybe<v1_Writers_bool_exp>;
};


export type query_rootv1_Writers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Writers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Writers_order_by>>;
  where?: InputMaybe<v1_Writers_bool_exp>;
};


export type query_rootv1_new_POBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


export type query_rootv1_new_POBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


export type query_rootv1_new_POBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_new_affiliateincomereportBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affiliateincomereportBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affiliateincomereportBase_order_by>>;
  where?: InputMaybe<v1_new_affiliateincomereportBase_bool_exp>;
};


export type query_rootv1_new_affiliateincomereportBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affiliateincomereportBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affiliateincomereportBase_order_by>>;
  where?: InputMaybe<v1_new_affiliateincomereportBase_bool_exp>;
};


export type query_rootv1_new_affiliateincomereportBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_new_affordertypeBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affordertypeBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affordertypeBase_order_by>>;
  where?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
};


export type query_rootv1_new_affordertypeBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affordertypeBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affordertypeBase_order_by>>;
  where?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
};


export type query_rootv1_new_affordertypeBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_new_brandBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};


export type query_rootv1_new_brandBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};


export type query_rootv1_new_brandBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_new_countryBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_countryBase_bool_exp>;
};


export type query_rootv1_new_countryBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_countryBase_bool_exp>;
};


export type query_rootv1_new_languagesBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_languagesBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_languagesBase_order_by>>;
  where?: InputMaybe<v1_new_languagesBase_bool_exp>;
};


export type query_rootv1_new_languagesBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_languagesBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_languagesBase_order_by>>;
  where?: InputMaybe<v1_new_languagesBase_bool_exp>;
};


export type query_rootv1_new_languagesBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_new_new_brand_new_countryBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_new_brand_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};


export type query_rootv1_new_new_brand_new_countryBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_new_brand_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};


export type query_rootv1_new_new_brand_new_countryBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_new_paymentmethodBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_paymentmethodBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_paymentmethodBase_order_by>>;
  where?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
};


export type query_rootv1_new_paymentmethodBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_paymentmethodBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_paymentmethodBase_order_by>>;
  where?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
};


export type query_rootv1_new_paymentmethodBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_new_receiptBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


export type query_rootv1_new_receiptBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


export type query_rootv1_new_receiptBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_new_regemailBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_regemailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_regemailBase_order_by>>;
  where?: InputMaybe<v1_new_regemailBase_bool_exp>;
};


export type query_rootv1_new_regemailBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_regemailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_regemailBase_order_by>>;
  where?: InputMaybe<v1_new_regemailBase_bool_exp>;
};


export type query_rootv1_new_regemailBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_new_trackerBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


export type query_rootv1_new_trackerBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


export type query_rootv1_new_trackerBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_processArgs = {
  distinct_on?: InputMaybe<Array<v1_process_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_order_by>>;
  where?: InputMaybe<v1_process_bool_exp>;
};


export type query_rootv1_process_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_process_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_order_by>>;
  where?: InputMaybe<v1_process_bool_exp>;
};


export type query_rootv1_process_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_process_rateArgs = {
  distinct_on?: InputMaybe<Array<v1_process_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_rate_order_by>>;
  where?: InputMaybe<v1_process_rate_bool_exp>;
};


export type query_rootv1_process_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_process_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_rate_order_by>>;
  where?: InputMaybe<v1_process_rate_bool_exp>;
};


export type query_rootv1_process_rate_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootv1_project_topicArgs = {
  distinct_on?: InputMaybe<Array<v1_project_topic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_project_topic_order_by>>;
  where?: InputMaybe<v1_project_topic_bool_exp>;
};


export type query_rootv1_project_topic_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_project_topic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_project_topic_order_by>>;
  where?: InputMaybe<v1_project_topic_bool_exp>;
};


export type query_rootv1_project_topic_by_pkArgs = {
  id: Scalars['Int']['input'];
};

export type subscription_root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Clicks.OrphanTrackers" */
  Clicks_OrphanTrackers: Array<Clicks_OrphanTrackers>;
  /** fetch aggregated fields from the table: "Clicks.OrphanTrackers" */
  Clicks_OrphanTrackers_aggregate: Clicks_OrphanTrackers_aggregate;
  /** fetch data from the table: "Clicks.lines" */
  Clicks_lines: Array<Clicks_lines>;
  /** fetch aggregated fields from the table: "Clicks.lines" */
  Clicks_lines_aggregate: Clicks_lines_aggregate;
  /** fetch data from the table: "v1.AccountBase" */
  v1_AccountBase: Array<v1_AccountBase>;
  /** fetch aggregated fields from the table: "v1.AccountBase" */
  v1_AccountBase_aggregate: v1_AccountBase_aggregate;
  /** fetch data from the table: "v1.AccountBase" using primary key columns */
  v1_AccountBase_by_pk?: Maybe<v1_AccountBase>;
  /** fetch data from the table in a streaming manner: "v1.AccountBase" */
  v1_AccountBase_stream: Array<v1_AccountBase>;
  /** fetch data from the table: "v1.Advertisers" */
  v1_Advertisers: Array<v1_Advertisers>;
  /** fetch aggregated fields from the table: "v1.Advertisers" */
  v1_Advertisers_aggregate: v1_Advertisers_aggregate;
  /** fetch data from the table in a streaming manner: "v1.Advertisers" */
  v1_Advertisers_stream: Array<v1_Advertisers>;
  /** fetch data from the table: "v1.AffDealType" */
  v1_AffDealType: Array<v1_AffDealType>;
  /** fetch aggregated fields from the table: "v1.AffDealType" */
  v1_AffDealType_aggregate: v1_AffDealType_aggregate;
  /** fetch data from the table: "v1.AffDealType" using primary key columns */
  v1_AffDealType_by_pk?: Maybe<v1_AffDealType>;
  /** fetch data from the table in a streaming manner: "v1.AffDealType" */
  v1_AffDealType_stream: Array<v1_AffDealType>;
  /** fetch data from the table: "v1.AffiliateOrderCategoriesPriority" */
  v1_AffiliateOrderCategoriesPriority: Array<v1_AffiliateOrderCategoriesPriority>;
  /** fetch aggregated fields from the table: "v1.AffiliateOrderCategoriesPriority" */
  v1_AffiliateOrderCategoriesPriority_aggregate: v1_AffiliateOrderCategoriesPriority_aggregate;
  /** fetch data from the table: "v1.AffiliateOrderCategoriesPriority" using primary key columns */
  v1_AffiliateOrderCategoriesPriority_by_pk?: Maybe<v1_AffiliateOrderCategoriesPriority>;
  /** fetch data from the table in a streaming manner: "v1.AffiliateOrderCategoriesPriority" */
  v1_AffiliateOrderCategoriesPriority_stream: Array<v1_AffiliateOrderCategoriesPriority>;
  /** fetch data from the table: "v1.AffiliateProgramType" */
  v1_AffiliateProgramType: Array<v1_AffiliateProgramType>;
  /** fetch aggregated fields from the table: "v1.AffiliateProgramType" */
  v1_AffiliateProgramType_aggregate: v1_AffiliateProgramType_aggregate;
  /** fetch data from the table: "v1.AffiliateProgramType" using primary key columns */
  v1_AffiliateProgramType_by_pk?: Maybe<v1_AffiliateProgramType>;
  /** fetch data from the table in a streaming manner: "v1.AffiliateProgramType" */
  v1_AffiliateProgramType_stream: Array<v1_AffiliateProgramType>;
  /** fetch data from the table: "v1.AllowedPlayers" */
  v1_AllowedPlayers: Array<v1_AllowedPlayers>;
  /** fetch aggregated fields from the table: "v1.AllowedPlayers" */
  v1_AllowedPlayers_aggregate: v1_AllowedPlayers_aggregate;
  /** fetch data from the table: "v1.AllowedPlayers" using primary key columns */
  v1_AllowedPlayers_by_pk?: Maybe<v1_AllowedPlayers>;
  /** fetch data from the table in a streaming manner: "v1.AllowedPlayers" */
  v1_AllowedPlayers_stream: Array<v1_AllowedPlayers>;
  /** fetch data from the table: "v1.ArticleTones" */
  v1_ArticleTones: Array<v1_ArticleTones>;
  /** fetch aggregated fields from the table: "v1.ArticleTones" */
  v1_ArticleTones_aggregate: v1_ArticleTones_aggregate;
  /** fetch data from the table: "v1.ArticleTones" using primary key columns */
  v1_ArticleTones_by_pk?: Maybe<v1_ArticleTones>;
  /** fetch data from the table in a streaming manner: "v1.ArticleTones" */
  v1_ArticleTones_stream: Array<v1_ArticleTones>;
  /** fetch data from the table: "v1.Balance" */
  v1_Balance: Array<v1_Balance>;
  /** fetch data from the table: "v1.BalanceActivityType" */
  v1_BalanceActivityType: Array<v1_BalanceActivityType>;
  /** fetch aggregated fields from the table: "v1.BalanceActivityType" */
  v1_BalanceActivityType_aggregate: v1_BalanceActivityType_aggregate;
  /** fetch data from the table: "v1.BalanceActivityType" using primary key columns */
  v1_BalanceActivityType_by_pk?: Maybe<v1_BalanceActivityType>;
  /** fetch data from the table in a streaming manner: "v1.BalanceActivityType" */
  v1_BalanceActivityType_stream: Array<v1_BalanceActivityType>;
  /** fetch aggregated fields from the table: "v1.Balance" */
  v1_Balance_aggregate: v1_Balance_aggregate;
  /** fetch data from the table: "v1.Balance" using primary key columns */
  v1_Balance_by_pk?: Maybe<v1_Balance>;
  /** fetch data from the table in a streaming manner: "v1.Balance" */
  v1_Balance_stream: Array<v1_Balance>;
  /** fetch data from the table: "v1.BillingInformation" */
  v1_BillingInformation: Array<v1_BillingInformation>;
  /** fetch aggregated fields from the table: "v1.BillingInformation" */
  v1_BillingInformation_aggregate: v1_BillingInformation_aggregate;
  /** fetch data from the table: "v1.BillingInformation" using primary key columns */
  v1_BillingInformation_by_pk?: Maybe<v1_BillingInformation>;
  /** fetch data from the table in a streaming manner: "v1.BillingInformation" */
  v1_BillingInformation_stream: Array<v1_BillingInformation>;
  /** fetch data from the table: "v1.BillingMethod" */
  v1_BillingMethod: Array<v1_BillingMethod>;
  /** fetch aggregated fields from the table: "v1.BillingMethod" */
  v1_BillingMethod_aggregate: v1_BillingMethod_aggregate;
  /** fetch data from the table: "v1.BillingMethod" using primary key columns */
  v1_BillingMethod_by_pk?: Maybe<v1_BillingMethod>;
  /** fetch data from the table in a streaming manner: "v1.BillingMethod" */
  v1_BillingMethod_stream: Array<v1_BillingMethod>;
  /** fetch data from the table: "v1.BonusSource" */
  v1_BonusSource: Array<v1_BonusSource>;
  /** fetch aggregated fields from the table: "v1.BonusSource" */
  v1_BonusSource_aggregate: v1_BonusSource_aggregate;
  /** fetch data from the table: "v1.BonusSource" using primary key columns */
  v1_BonusSource_by_pk?: Maybe<v1_BonusSource>;
  /** fetch data from the table in a streaming manner: "v1.BonusSource" */
  v1_BonusSource_stream: Array<v1_BonusSource>;
  /** fetch data from the table: "v1.BonusStatus" */
  v1_BonusStatus: Array<v1_BonusStatus>;
  /** fetch aggregated fields from the table: "v1.BonusStatus" */
  v1_BonusStatus_aggregate: v1_BonusStatus_aggregate;
  /** fetch data from the table: "v1.BonusStatus" using primary key columns */
  v1_BonusStatus_by_pk?: Maybe<v1_BonusStatus>;
  /** fetch data from the table in a streaming manner: "v1.BonusStatus" */
  v1_BonusStatus_stream: Array<v1_BonusStatus>;
  /** fetch data from the table: "v1.BonusSubmitter" */
  v1_BonusSubmitter: Array<v1_BonusSubmitter>;
  /** fetch aggregated fields from the table: "v1.BonusSubmitter" */
  v1_BonusSubmitter_aggregate: v1_BonusSubmitter_aggregate;
  /** fetch data from the table: "v1.BonusSubmitter" using primary key columns */
  v1_BonusSubmitter_by_pk?: Maybe<v1_BonusSubmitter>;
  /** fetch data from the table in a streaming manner: "v1.BonusSubmitter" */
  v1_BonusSubmitter_stream: Array<v1_BonusSubmitter>;
  /** fetch data from the table: "v1.BonusTypes" */
  v1_BonusTypes: Array<v1_BonusTypes>;
  /** fetch aggregated fields from the table: "v1.BonusTypes" */
  v1_BonusTypes_aggregate: v1_BonusTypes_aggregate;
  /** fetch data from the table: "v1.BonusTypes" using primary key columns */
  v1_BonusTypes_by_pk?: Maybe<v1_BonusTypes>;
  /** fetch data from the table in a streaming manner: "v1.BonusTypes" */
  v1_BonusTypes_stream: Array<v1_BonusTypes>;
  /** fetch data from the table: "v1.Bonuses" */
  v1_Bonuses: Array<v1_Bonuses>;
  /** fetch data from the table: "v1.BonusesToCountries" */
  v1_BonusesToCountries: Array<v1_BonusesToCountries>;
  /** fetch aggregated fields from the table: "v1.BonusesToCountries" */
  v1_BonusesToCountries_aggregate: v1_BonusesToCountries_aggregate;
  /** fetch data from the table: "v1.BonusesToCountries" using primary key columns */
  v1_BonusesToCountries_by_pk?: Maybe<v1_BonusesToCountries>;
  /** fetch data from the table in a streaming manner: "v1.BonusesToCountries" */
  v1_BonusesToCountries_stream: Array<v1_BonusesToCountries>;
  /** fetch data from the table: "v1.BonusesToCurrencies" */
  v1_BonusesToCurrencies: Array<v1_BonusesToCurrencies>;
  /** fetch aggregated fields from the table: "v1.BonusesToCurrencies" */
  v1_BonusesToCurrencies_aggregate: v1_BonusesToCurrencies_aggregate;
  /** fetch data from the table: "v1.BonusesToCurrencies" using primary key columns */
  v1_BonusesToCurrencies_by_pk?: Maybe<v1_BonusesToCurrencies>;
  /** fetch data from the table in a streaming manner: "v1.BonusesToCurrencies" */
  v1_BonusesToCurrencies_stream: Array<v1_BonusesToCurrencies>;
  /** fetch data from the table: "v1.BonusesToTypes" */
  v1_BonusesToTypes: Array<v1_BonusesToTypes>;
  /** fetch aggregated fields from the table: "v1.BonusesToTypes" */
  v1_BonusesToTypes_aggregate: v1_BonusesToTypes_aggregate;
  /** fetch data from the table: "v1.BonusesToTypes" using primary key columns */
  v1_BonusesToTypes_by_pk?: Maybe<v1_BonusesToTypes>;
  /** fetch data from the table in a streaming manner: "v1.BonusesToTypes" */
  v1_BonusesToTypes_stream: Array<v1_BonusesToTypes>;
  /** fetch aggregated fields from the table: "v1.Bonuses" */
  v1_Bonuses_aggregate: v1_Bonuses_aggregate;
  /** fetch data from the table: "v1.Bonuses" using primary key columns */
  v1_Bonuses_by_pk?: Maybe<v1_Bonuses>;
  /** fetch data from the table in a streaming manner: "v1.Bonuses" */
  v1_Bonuses_stream: Array<v1_Bonuses>;
  /** fetch data from the table: "v1.BonusestoGames" */
  v1_BonusestoGames: Array<v1_BonusestoGames>;
  /** fetch aggregated fields from the table: "v1.BonusestoGames" */
  v1_BonusestoGames_aggregate: v1_BonusestoGames_aggregate;
  /** fetch data from the table: "v1.BonusestoGames" using primary key columns */
  v1_BonusestoGames_by_pk?: Maybe<v1_BonusestoGames>;
  /** fetch data from the table in a streaming manner: "v1.BonusestoGames" */
  v1_BonusestoGames_stream: Array<v1_BonusestoGames>;
  /** fetch data from the table: "v1.BrandAlias" */
  v1_BrandAlias: Array<v1_BrandAlias>;
  /** fetch aggregated fields from the table: "v1.BrandAlias" */
  v1_BrandAlias_aggregate: v1_BrandAlias_aggregate;
  /** fetch data from the table: "v1.BrandAlias" using primary key columns */
  v1_BrandAlias_by_pk?: Maybe<v1_BrandAlias>;
  /** fetch data from the table in a streaming manner: "v1.BrandAlias" */
  v1_BrandAlias_stream: Array<v1_BrandAlias>;
  /** fetch data from the table: "v1.BrandPriority" */
  v1_BrandPriority: Array<v1_BrandPriority>;
  /** fetch aggregated fields from the table: "v1.BrandPriority" */
  v1_BrandPriority_aggregate: v1_BrandPriority_aggregate;
  /** fetch data from the table: "v1.BrandPriority" using primary key columns */
  v1_BrandPriority_by_pk?: Maybe<v1_BrandPriority>;
  /** fetch data from the table in a streaming manner: "v1.BrandPriority" */
  v1_BrandPriority_stream: Array<v1_BrandPriority>;
  /** fetch data from the table: "v1.Campaign" */
  v1_Campaign: Array<v1_Campaign>;
  /** fetch data from the table: "v1.CampaignPriority" */
  v1_CampaignPriority: Array<v1_CampaignPriority>;
  /** fetch aggregated fields from the table: "v1.CampaignPriority" */
  v1_CampaignPriority_aggregate: v1_CampaignPriority_aggregate;
  /** fetch data from the table: "v1.CampaignPriority" using primary key columns */
  v1_CampaignPriority_by_pk?: Maybe<v1_CampaignPriority>;
  /** fetch data from the table in a streaming manner: "v1.CampaignPriority" */
  v1_CampaignPriority_stream: Array<v1_CampaignPriority>;
  /** fetch data from the table: "v1.CampaignStatus" */
  v1_CampaignStatus: Array<v1_CampaignStatus>;
  /** fetch aggregated fields from the table: "v1.CampaignStatus" */
  v1_CampaignStatus_aggregate: v1_CampaignStatus_aggregate;
  /** fetch data from the table: "v1.CampaignStatus" using primary key columns */
  v1_CampaignStatus_by_pk?: Maybe<v1_CampaignStatus>;
  /** fetch data from the table in a streaming manner: "v1.CampaignStatus" */
  v1_CampaignStatus_stream: Array<v1_CampaignStatus>;
  /** fetch data from the table: "v1.CampaignStrategy" */
  v1_CampaignStrategy: Array<v1_CampaignStrategy>;
  /** fetch aggregated fields from the table: "v1.CampaignStrategy" */
  v1_CampaignStrategy_aggregate: v1_CampaignStrategy_aggregate;
  /** fetch data from the table: "v1.CampaignStrategy" using primary key columns */
  v1_CampaignStrategy_by_pk?: Maybe<v1_CampaignStrategy>;
  /** fetch data from the table in a streaming manner: "v1.CampaignStrategy" */
  v1_CampaignStrategy_stream: Array<v1_CampaignStrategy>;
  /** fetch data from the table: "v1.CampaignType" */
  v1_CampaignType: Array<v1_CampaignType>;
  /** fetch aggregated fields from the table: "v1.CampaignType" */
  v1_CampaignType_aggregate: v1_CampaignType_aggregate;
  /** fetch data from the table: "v1.CampaignType" using primary key columns */
  v1_CampaignType_by_pk?: Maybe<v1_CampaignType>;
  /** fetch data from the table in a streaming manner: "v1.CampaignType" */
  v1_CampaignType_stream: Array<v1_CampaignType>;
  /** fetch aggregated fields from the table: "v1.Campaign" */
  v1_Campaign_aggregate: v1_Campaign_aggregate;
  /** fetch data from the table: "v1.Campaign" using primary key columns */
  v1_Campaign_by_pk?: Maybe<v1_Campaign>;
  /** fetch data from the table in a streaming manner: "v1.Campaign" */
  v1_Campaign_stream: Array<v1_Campaign>;
  /** fetch data from the table: "v1.Categories" */
  v1_Categories: Array<v1_Categories>;
  /** fetch aggregated fields from the table: "v1.Categories" */
  v1_Categories_aggregate: v1_Categories_aggregate;
  /** fetch data from the table: "v1.Categories" using primary key columns */
  v1_Categories_by_pk?: Maybe<v1_Categories>;
  /** fetch data from the table in a streaming manner: "v1.Categories" */
  v1_Categories_stream: Array<v1_Categories>;
  /** fetch data from the table: "v1.Checkout" */
  v1_Checkout: Array<v1_Checkout>;
  /** fetch data from the table: "v1.CheckoutDetail" */
  v1_CheckoutDetail: Array<v1_CheckoutDetail>;
  /** fetch aggregated fields from the table: "v1.CheckoutDetail" */
  v1_CheckoutDetail_aggregate: v1_CheckoutDetail_aggregate;
  /** fetch data from the table: "v1.CheckoutDetail" using primary key columns */
  v1_CheckoutDetail_by_pk?: Maybe<v1_CheckoutDetail>;
  /** fetch data from the table in a streaming manner: "v1.CheckoutDetail" */
  v1_CheckoutDetail_stream: Array<v1_CheckoutDetail>;
  /** fetch data from the table: "v1.CheckoutStatus" */
  v1_CheckoutStatus: Array<v1_CheckoutStatus>;
  /** fetch aggregated fields from the table: "v1.CheckoutStatus" */
  v1_CheckoutStatus_aggregate: v1_CheckoutStatus_aggregate;
  /** fetch data from the table: "v1.CheckoutStatus" using primary key columns */
  v1_CheckoutStatus_by_pk?: Maybe<v1_CheckoutStatus>;
  /** fetch data from the table in a streaming manner: "v1.CheckoutStatus" */
  v1_CheckoutStatus_stream: Array<v1_CheckoutStatus>;
  /** fetch aggregated fields from the table: "v1.Checkout" */
  v1_Checkout_aggregate: v1_Checkout_aggregate;
  /** fetch data from the table: "v1.Checkout" using primary key columns */
  v1_Checkout_by_pk?: Maybe<v1_Checkout>;
  /** fetch data from the table in a streaming manner: "v1.Checkout" */
  v1_Checkout_stream: Array<v1_Checkout>;
  /** fetch data from the table: "v1.Cities" */
  v1_Cities: Array<v1_Cities>;
  /** fetch aggregated fields from the table: "v1.Cities" */
  v1_Cities_aggregate: v1_Cities_aggregate;
  /** fetch data from the table: "v1.Cities" using primary key columns */
  v1_Cities_by_pk?: Maybe<v1_Cities>;
  /** fetch data from the table in a streaming manner: "v1.Cities" */
  v1_Cities_stream: Array<v1_Cities>;
  /** fetch data from the table: "v1.ClicksByTrackers" */
  v1_ClicksByTrackers: Array<v1_ClicksByTrackers>;
  /** fetch data from the table: "v1.ClicksByTrackersNew" */
  v1_ClicksByTrackersNew: Array<v1_ClicksByTrackersNew>;
  /** fetch aggregated fields from the table: "v1.ClicksByTrackersNew" */
  v1_ClicksByTrackersNew_aggregate: v1_ClicksByTrackersNew_aggregate;
  /** fetch data from the table: "v1.ClicksByTrackersNew" using primary key columns */
  v1_ClicksByTrackersNew_by_pk?: Maybe<v1_ClicksByTrackersNew>;
  /** fetch data from the table in a streaming manner: "v1.ClicksByTrackersNew" */
  v1_ClicksByTrackersNew_stream: Array<v1_ClicksByTrackersNew>;
  /** fetch aggregated fields from the table: "v1.ClicksByTrackers" */
  v1_ClicksByTrackers_aggregate: v1_ClicksByTrackers_aggregate;
  /** fetch data from the table: "v1.ClicksByTrackers" using primary key columns */
  v1_ClicksByTrackers_by_pk?: Maybe<v1_ClicksByTrackers>;
  /** fetch data from the table in a streaming manner: "v1.ClicksByTrackers" */
  v1_ClicksByTrackers_stream: Array<v1_ClicksByTrackers>;
  /** fetch data from the table: "v1.CompanyType" */
  v1_CompanyType: Array<v1_CompanyType>;
  /** fetch aggregated fields from the table: "v1.CompanyType" */
  v1_CompanyType_aggregate: v1_CompanyType_aggregate;
  /** fetch data from the table: "v1.CompanyType" using primary key columns */
  v1_CompanyType_by_pk?: Maybe<v1_CompanyType>;
  /** fetch data from the table in a streaming manner: "v1.CompanyType" */
  v1_CompanyType_stream: Array<v1_CompanyType>;
  /** fetch data from the table: "v1.Configurations" */
  v1_Configurations: Array<v1_Configurations>;
  /** fetch aggregated fields from the table: "v1.Configurations" */
  v1_Configurations_aggregate: v1_Configurations_aggregate;
  /** fetch data from the table: "v1.Configurations" using primary key columns */
  v1_Configurations_by_pk?: Maybe<v1_Configurations>;
  /** fetch data from the table in a streaming manner: "v1.Configurations" */
  v1_Configurations_stream: Array<v1_Configurations>;
  /** fetch data from the table: "v1.ContactBase" */
  v1_ContactBase: Array<v1_ContactBase>;
  /** fetch data from the table: "v1.ContactBasePaymentOption" */
  v1_ContactBasePaymentOption: Array<v1_ContactBasePaymentOption>;
  /** fetch aggregated fields from the table: "v1.ContactBasePaymentOption" */
  v1_ContactBasePaymentOption_aggregate: v1_ContactBasePaymentOption_aggregate;
  /** fetch data from the table: "v1.ContactBasePaymentOption" using primary key columns */
  v1_ContactBasePaymentOption_by_pk?: Maybe<v1_ContactBasePaymentOption>;
  /** fetch data from the table in a streaming manner: "v1.ContactBasePaymentOption" */
  v1_ContactBasePaymentOption_stream: Array<v1_ContactBasePaymentOption>;
  /** fetch aggregated fields from the table: "v1.ContactBase" */
  v1_ContactBase_aggregate: v1_ContactBase_aggregate;
  /** fetch data from the table: "v1.ContactBase" using primary key columns */
  v1_ContactBase_by_pk?: Maybe<v1_ContactBase>;
  /** fetch data from the table in a streaming manner: "v1.ContactBase" */
  v1_ContactBase_stream: Array<v1_ContactBase>;
  /** fetch data from the table: "v1.ContactType" */
  v1_ContactType: Array<v1_ContactType>;
  /** fetch aggregated fields from the table: "v1.ContactType" */
  v1_ContactType_aggregate: v1_ContactType_aggregate;
  /** fetch data from the table: "v1.ContactType" using primary key columns */
  v1_ContactType_by_pk?: Maybe<v1_ContactType>;
  /** fetch data from the table in a streaming manner: "v1.ContactType" */
  v1_ContactType_stream: Array<v1_ContactType>;
  /** fetch data from the table: "v1.Contacts_Roles" */
  v1_Contacts_Roles: Array<v1_Contacts_Roles>;
  /** fetch aggregated fields from the table: "v1.Contacts_Roles" */
  v1_Contacts_Roles_aggregate: v1_Contacts_Roles_aggregate;
  /** fetch data from the table: "v1.Contacts_Roles" using primary key columns */
  v1_Contacts_Roles_by_pk?: Maybe<v1_Contacts_Roles>;
  /** fetch data from the table in a streaming manner: "v1.Contacts_Roles" */
  v1_Contacts_Roles_stream: Array<v1_Contacts_Roles>;
  /** fetch data from the table: "v1.ContentLink" */
  v1_ContentLink: Array<v1_ContentLink>;
  /** fetch aggregated fields from the table: "v1.ContentLink" */
  v1_ContentLink_aggregate: v1_ContentLink_aggregate;
  /** fetch data from the table: "v1.ContentLink" using primary key columns */
  v1_ContentLink_by_pk?: Maybe<v1_ContentLink>;
  /** fetch data from the table in a streaming manner: "v1.ContentLink" */
  v1_ContentLink_stream: Array<v1_ContentLink>;
  /** fetch data from the table: "v1.ContentTypes" */
  v1_ContentTypes: Array<v1_ContentTypes>;
  /** fetch aggregated fields from the table: "v1.ContentTypes" */
  v1_ContentTypes_aggregate: v1_ContentTypes_aggregate;
  /** fetch data from the table: "v1.ContentTypes" using primary key columns */
  v1_ContentTypes_by_pk?: Maybe<v1_ContentTypes>;
  /** fetch data from the table in a streaming manner: "v1.ContentTypes" */
  v1_ContentTypes_stream: Array<v1_ContentTypes>;
  /** fetch data from the table: "v1.Credential_Roles" */
  v1_Credential_Roles: Array<v1_Credential_Roles>;
  /** fetch aggregated fields from the table: "v1.Credential_Roles" */
  v1_Credential_Roles_aggregate: v1_Credential_Roles_aggregate;
  /** fetch data from the table: "v1.Credential_Roles" using primary key columns */
  v1_Credential_Roles_by_pk?: Maybe<v1_Credential_Roles>;
  /** fetch data from the table in a streaming manner: "v1.Credential_Roles" */
  v1_Credential_Roles_stream: Array<v1_Credential_Roles>;
  /** fetch data from the table: "v1.Credentials" */
  v1_Credentials: Array<v1_Credentials>;
  /** fetch aggregated fields from the table: "v1.Credentials" */
  v1_Credentials_aggregate: v1_Credentials_aggregate;
  /** fetch data from the table: "v1.Credentials" using primary key columns */
  v1_Credentials_by_pk?: Maybe<v1_Credentials>;
  /** fetch data from the table in a streaming manner: "v1.Credentials" */
  v1_Credentials_stream: Array<v1_Credentials>;
  /** fetch data from the table: "v1.DomainExtensions" */
  v1_DomainExtensions: Array<v1_DomainExtensions>;
  /** fetch aggregated fields from the table: "v1.DomainExtensions" */
  v1_DomainExtensions_aggregate: v1_DomainExtensions_aggregate;
  /** fetch data from the table: "v1.DomainExtensions" using primary key columns */
  v1_DomainExtensions_by_pk?: Maybe<v1_DomainExtensions>;
  /** fetch data from the table in a streaming manner: "v1.DomainExtensions" */
  v1_DomainExtensions_stream: Array<v1_DomainExtensions>;
  /** fetch data from the table: "v1.Events" */
  v1_Events: Array<v1_Events>;
  /** fetch aggregated fields from the table: "v1.Events" */
  v1_Events_aggregate: v1_Events_aggregate;
  /** fetch data from the table: "v1.Events" using primary key columns */
  v1_Events_by_pk?: Maybe<v1_Events>;
  /** fetch data from the table in a streaming manner: "v1.Events" */
  v1_Events_stream: Array<v1_Events>;
  /** fetch data from the table: "v1.ExpiringRecPayments" */
  v1_ExpiringRecPayments: Array<v1_ExpiringRecPayments>;
  /** fetch aggregated fields from the table: "v1.ExpiringRecPayments" */
  v1_ExpiringRecPayments_aggregate: v1_ExpiringRecPayments_aggregate;
  /** fetch data from the table in a streaming manner: "v1.ExpiringRecPayments" */
  v1_ExpiringRecPayments_stream: Array<v1_ExpiringRecPayments>;
  /** fetch data from the table: "v1.Files" */
  v1_Files: Array<v1_Files>;
  /** fetch aggregated fields from the table: "v1.Files" */
  v1_Files_aggregate: v1_Files_aggregate;
  /** fetch data from the table: "v1.Files" using primary key columns */
  v1_Files_by_pk?: Maybe<v1_Files>;
  /** fetch data from the table in a streaming manner: "v1.Files" */
  v1_Files_stream: Array<v1_Files>;
  /** fetch data from the table: "v1.GameType" */
  v1_GameType: Array<v1_GameType>;
  /** fetch aggregated fields from the table: "v1.GameType" */
  v1_GameType_aggregate: v1_GameType_aggregate;
  /** fetch data from the table: "v1.GameType" using primary key columns */
  v1_GameType_by_pk?: Maybe<v1_GameType>;
  /** fetch data from the table in a streaming manner: "v1.GameType" */
  v1_GameType_stream: Array<v1_GameType>;
  /** fetch data from the table: "v1.InvoiceBase" */
  v1_InvoiceBase: Array<v1_InvoiceBase>;
  /** fetch aggregated fields from the table: "v1.InvoiceBase" */
  v1_InvoiceBase_aggregate: v1_InvoiceBase_aggregate;
  /** fetch data from the table: "v1.InvoiceBase" using primary key columns */
  v1_InvoiceBase_by_pk?: Maybe<v1_InvoiceBase>;
  /** fetch data from the table in a streaming manner: "v1.InvoiceBase" */
  v1_InvoiceBase_stream: Array<v1_InvoiceBase>;
  /** fetch data from the table: "v1.InvoiceDetailBase" */
  v1_InvoiceDetailBase: Array<v1_InvoiceDetailBase>;
  /** fetch aggregated fields from the table: "v1.InvoiceDetailBase" */
  v1_InvoiceDetailBase_aggregate: v1_InvoiceDetailBase_aggregate;
  /** fetch data from the table: "v1.InvoiceDetailBase" using primary key columns */
  v1_InvoiceDetailBase_by_pk?: Maybe<v1_InvoiceDetailBase>;
  /** fetch data from the table in a streaming manner: "v1.InvoiceDetailBase" */
  v1_InvoiceDetailBase_stream: Array<v1_InvoiceDetailBase>;
  /** fetch data from the table: "v1.InvoiceStatus" */
  v1_InvoiceStatus: Array<v1_InvoiceStatus>;
  /** fetch aggregated fields from the table: "v1.InvoiceStatus" */
  v1_InvoiceStatus_aggregate: v1_InvoiceStatus_aggregate;
  /** fetch data from the table: "v1.InvoiceStatus" using primary key columns */
  v1_InvoiceStatus_by_pk?: Maybe<v1_InvoiceStatus>;
  /** fetch data from the table in a streaming manner: "v1.InvoiceStatus" */
  v1_InvoiceStatus_stream: Array<v1_InvoiceStatus>;
  /** fetch data from the table: "v1.LBInProgressPosts" */
  v1_LBInProgressPosts: Array<v1_LBInProgressPosts>;
  /** fetch aggregated fields from the table: "v1.LBInProgressPosts" */
  v1_LBInProgressPosts_aggregate: v1_LBInProgressPosts_aggregate;
  /** fetch data from the table in a streaming manner: "v1.LBInProgressPosts" */
  v1_LBInProgressPosts_stream: Array<v1_LBInProgressPosts>;
  /** fetch data from the table: "v1.LBPublishedPosts" */
  v1_LBPublishedPosts: Array<v1_LBPublishedPosts>;
  /** fetch aggregated fields from the table: "v1.LBPublishedPosts" */
  v1_LBPublishedPosts_aggregate: v1_LBPublishedPosts_aggregate;
  /** fetch data from the table in a streaming manner: "v1.LBPublishedPosts" */
  v1_LBPublishedPosts_stream: Array<v1_LBPublishedPosts>;
  /** fetch data from the table: "v1.LandingPagePriority" */
  v1_LandingPagePriority: Array<v1_LandingPagePriority>;
  /** fetch aggregated fields from the table: "v1.LandingPagePriority" */
  v1_LandingPagePriority_aggregate: v1_LandingPagePriority_aggregate;
  /** fetch data from the table: "v1.LandingPagePriority" using primary key columns */
  v1_LandingPagePriority_by_pk?: Maybe<v1_LandingPagePriority>;
  /** fetch data from the table in a streaming manner: "v1.LandingPagePriority" */
  v1_LandingPagePriority_stream: Array<v1_LandingPagePriority>;
  /** fetch data from the table: "v1.LandingPages" */
  v1_LandingPages: Array<v1_LandingPages>;
  /** fetch aggregated fields from the table: "v1.LandingPages" */
  v1_LandingPages_aggregate: v1_LandingPages_aggregate;
  /** fetch data from the table: "v1.LandingPages" using primary key columns */
  v1_LandingPages_by_pk?: Maybe<v1_LandingPages>;
  /** fetch data from the table in a streaming manner: "v1.LandingPages" */
  v1_LandingPages_stream: Array<v1_LandingPages>;
  /** fetch data from the table: "v1.LanguagePriceStep" */
  v1_LanguagePriceStep: Array<v1_LanguagePriceStep>;
  /** fetch aggregated fields from the table: "v1.LanguagePriceStep" */
  v1_LanguagePriceStep_aggregate: v1_LanguagePriceStep_aggregate;
  /** fetch data from the table: "v1.LanguagePriceStep" using primary key columns */
  v1_LanguagePriceStep_by_pk?: Maybe<v1_LanguagePriceStep>;
  /** fetch data from the table in a streaming manner: "v1.LanguagePriceStep" */
  v1_LanguagePriceStep_stream: Array<v1_LanguagePriceStep>;
  /** fetch data from the table: "v1.MarketPlaceCategories" */
  v1_MarketPlaceCategories: Array<v1_MarketPlaceCategories>;
  /** fetch data from the table: "v1.MarketPlaceCategoriesTypes" */
  v1_MarketPlaceCategoriesTypes: Array<v1_MarketPlaceCategoriesTypes>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceCategoriesTypes" */
  v1_MarketPlaceCategoriesTypes_aggregate: v1_MarketPlaceCategoriesTypes_aggregate;
  /** fetch data from the table: "v1.MarketPlaceCategoriesTypes" using primary key columns */
  v1_MarketPlaceCategoriesTypes_by_pk?: Maybe<v1_MarketPlaceCategoriesTypes>;
  /** fetch data from the table in a streaming manner: "v1.MarketPlaceCategoriesTypes" */
  v1_MarketPlaceCategoriesTypes_stream: Array<v1_MarketPlaceCategoriesTypes>;
  /** fetch data from the table: "v1.MarketPlaceCategories_Offers" */
  v1_MarketPlaceCategories_Offers: Array<v1_MarketPlaceCategories_Offers>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceCategories_Offers" */
  v1_MarketPlaceCategories_Offers_aggregate: v1_MarketPlaceCategories_Offers_aggregate;
  /** fetch data from the table: "v1.MarketPlaceCategories_Offers" using primary key columns */
  v1_MarketPlaceCategories_Offers_by_pk?: Maybe<v1_MarketPlaceCategories_Offers>;
  /** fetch data from the table in a streaming manner: "v1.MarketPlaceCategories_Offers" */
  v1_MarketPlaceCategories_Offers_stream: Array<v1_MarketPlaceCategories_Offers>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceCategories" */
  v1_MarketPlaceCategories_aggregate: v1_MarketPlaceCategories_aggregate;
  /** fetch data from the table: "v1.MarketPlaceCategories" using primary key columns */
  v1_MarketPlaceCategories_by_pk?: Maybe<v1_MarketPlaceCategories>;
  /** fetch data from the table in a streaming manner: "v1.MarketPlaceCategories" */
  v1_MarketPlaceCategories_stream: Array<v1_MarketPlaceCategories>;
  /** fetch data from the table: "v1.MarketPlaceOfferNiche" */
  v1_MarketPlaceOfferNiche: Array<v1_MarketPlaceOfferNiche>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceOfferNiche" */
  v1_MarketPlaceOfferNiche_aggregate: v1_MarketPlaceOfferNiche_aggregate;
  /** fetch data from the table: "v1.MarketPlaceOfferNiche" using primary key columns */
  v1_MarketPlaceOfferNiche_by_pk?: Maybe<v1_MarketPlaceOfferNiche>;
  /** fetch data from the table in a streaming manner: "v1.MarketPlaceOfferNiche" */
  v1_MarketPlaceOfferNiche_stream: Array<v1_MarketPlaceOfferNiche>;
  /** fetch data from the table: "v1.MarketPlaceOrder" */
  v1_MarketPlaceOrder: Array<v1_MarketPlaceOrder>;
  /** fetch data from the table: "v1.MarketPlaceOrderRequest" */
  v1_MarketPlaceOrderRequest: Array<v1_MarketPlaceOrderRequest>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceOrderRequest" */
  v1_MarketPlaceOrderRequest_aggregate: v1_MarketPlaceOrderRequest_aggregate;
  /** fetch data from the table: "v1.MarketPlaceOrderRequest" using primary key columns */
  v1_MarketPlaceOrderRequest_by_pk?: Maybe<v1_MarketPlaceOrderRequest>;
  /** fetch data from the table in a streaming manner: "v1.MarketPlaceOrderRequest" */
  v1_MarketPlaceOrderRequest_stream: Array<v1_MarketPlaceOrderRequest>;
  /** fetch data from the table: "v1.MarketPlaceOrderStatus" */
  v1_MarketPlaceOrderStatus: Array<v1_MarketPlaceOrderStatus>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceOrderStatus" */
  v1_MarketPlaceOrderStatus_aggregate: v1_MarketPlaceOrderStatus_aggregate;
  /** fetch data from the table: "v1.MarketPlaceOrderStatus" using primary key columns */
  v1_MarketPlaceOrderStatus_by_pk?: Maybe<v1_MarketPlaceOrderStatus>;
  /** fetch data from the table in a streaming manner: "v1.MarketPlaceOrderStatus" */
  v1_MarketPlaceOrderStatus_stream: Array<v1_MarketPlaceOrderStatus>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceOrder" */
  v1_MarketPlaceOrder_aggregate: v1_MarketPlaceOrder_aggregate;
  /** fetch data from the table: "v1.MarketPlaceOrder" using primary key columns */
  v1_MarketPlaceOrder_by_pk?: Maybe<v1_MarketPlaceOrder>;
  /** fetch data from the table in a streaming manner: "v1.MarketPlaceOrder" */
  v1_MarketPlaceOrder_stream: Array<v1_MarketPlaceOrder>;
  /** fetch data from the table: "v1.MarketPlaceRegisterStatus" */
  v1_MarketPlaceRegisterStatus: Array<v1_MarketPlaceRegisterStatus>;
  /** fetch aggregated fields from the table: "v1.MarketPlaceRegisterStatus" */
  v1_MarketPlaceRegisterStatus_aggregate: v1_MarketPlaceRegisterStatus_aggregate;
  /** fetch data from the table: "v1.MarketPlaceRegisterStatus" using primary key columns */
  v1_MarketPlaceRegisterStatus_by_pk?: Maybe<v1_MarketPlaceRegisterStatus>;
  /** fetch data from the table in a streaming manner: "v1.MarketPlaceRegisterStatus" */
  v1_MarketPlaceRegisterStatus_stream: Array<v1_MarketPlaceRegisterStatus>;
  /** fetch data from the table: "v1.MarketType" */
  v1_MarketType: Array<v1_MarketType>;
  /** fetch aggregated fields from the table: "v1.MarketType" */
  v1_MarketType_aggregate: v1_MarketType_aggregate;
  /** fetch data from the table: "v1.MarketType" using primary key columns */
  v1_MarketType_by_pk?: Maybe<v1_MarketType>;
  /** fetch data from the table in a streaming manner: "v1.MarketType" */
  v1_MarketType_stream: Array<v1_MarketType>;
  /** fetch data from the table: "v1.MarketplaceProjects" */
  v1_MarketplaceProjects: Array<v1_MarketplaceProjects>;
  /** fetch aggregated fields from the table: "v1.MarketplaceProjects" */
  v1_MarketplaceProjects_aggregate: v1_MarketplaceProjects_aggregate;
  /** fetch data from the table: "v1.MarketplaceProjects" using primary key columns */
  v1_MarketplaceProjects_by_pk?: Maybe<v1_MarketplaceProjects>;
  /** fetch data from the table in a streaming manner: "v1.MarketplaceProjects" */
  v1_MarketplaceProjects_stream: Array<v1_MarketplaceProjects>;
  /** fetch data from the table: "v1.MarketplaceStatus" */
  v1_MarketplaceStatus: Array<v1_MarketplaceStatus>;
  /** fetch aggregated fields from the table: "v1.MarketplaceStatus" */
  v1_MarketplaceStatus_aggregate: v1_MarketplaceStatus_aggregate;
  /** fetch data from the table: "v1.MarketplaceStatus" using primary key columns */
  v1_MarketplaceStatus_by_pk?: Maybe<v1_MarketplaceStatus>;
  /** fetch data from the table in a streaming manner: "v1.MarketplaceStatus" */
  v1_MarketplaceStatus_stream: Array<v1_MarketplaceStatus>;
  /** fetch data from the table: "v1.Months" */
  v1_Months: Array<v1_Months>;
  /** fetch aggregated fields from the table: "v1.Months" */
  v1_Months_aggregate: v1_Months_aggregate;
  /** fetch data from the table: "v1.Months" using primary key columns */
  v1_Months_by_pk?: Maybe<v1_Months>;
  /** fetch data from the table in a streaming manner: "v1.Months" */
  v1_Months_stream: Array<v1_Months>;
  /** fetch data from the table: "v1.Offer" */
  v1_Offer: Array<v1_Offer>;
  /** fetch data from the table: "v1.OfferStatus" */
  v1_OfferStatus: Array<v1_OfferStatus>;
  /** fetch aggregated fields from the table: "v1.OfferStatus" */
  v1_OfferStatus_aggregate: v1_OfferStatus_aggregate;
  /** fetch data from the table: "v1.OfferStatus" using primary key columns */
  v1_OfferStatus_by_pk?: Maybe<v1_OfferStatus>;
  /** fetch data from the table in a streaming manner: "v1.OfferStatus" */
  v1_OfferStatus_stream: Array<v1_OfferStatus>;
  /** fetch data from the table: "v1.OfferTags" */
  v1_OfferTags: Array<v1_OfferTags>;
  /** fetch aggregated fields from the table: "v1.OfferTags" */
  v1_OfferTags_aggregate: v1_OfferTags_aggregate;
  /** fetch data from the table: "v1.OfferTags" using primary key columns */
  v1_OfferTags_by_pk?: Maybe<v1_OfferTags>;
  /** fetch data from the table in a streaming manner: "v1.OfferTags" */
  v1_OfferTags_stream: Array<v1_OfferTags>;
  /** fetch data from the table: "v1.OfferToTags" */
  v1_OfferToTags: Array<v1_OfferToTags>;
  /** fetch aggregated fields from the table: "v1.OfferToTags" */
  v1_OfferToTags_aggregate: v1_OfferToTags_aggregate;
  /** fetch data from the table: "v1.OfferToTags" using primary key columns */
  v1_OfferToTags_by_pk?: Maybe<v1_OfferToTags>;
  /** fetch data from the table in a streaming manner: "v1.OfferToTags" */
  v1_OfferToTags_stream: Array<v1_OfferToTags>;
  /** fetch aggregated fields from the table: "v1.Offer" */
  v1_Offer_aggregate: v1_Offer_aggregate;
  /** fetch data from the table: "v1.Offer" using primary key columns */
  v1_Offer_by_pk?: Maybe<v1_Offer>;
  /** fetch data from the table in a streaming manner: "v1.Offer" */
  v1_Offer_stream: Array<v1_Offer>;
  /** fetch data from the table: "v1.OrderFor" */
  v1_OrderFor: Array<v1_OrderFor>;
  /** fetch aggregated fields from the table: "v1.OrderFor" */
  v1_OrderFor_aggregate: v1_OrderFor_aggregate;
  /** fetch data from the table: "v1.OrderFor" using primary key columns */
  v1_OrderFor_by_pk?: Maybe<v1_OrderFor>;
  /** fetch data from the table in a streaming manner: "v1.OrderFor" */
  v1_OrderFor_stream: Array<v1_OrderFor>;
  /** fetch data from the table: "v1.OrderPayTo" */
  v1_OrderPayTo: Array<v1_OrderPayTo>;
  /** fetch aggregated fields from the table: "v1.OrderPayTo" */
  v1_OrderPayTo_aggregate: v1_OrderPayTo_aggregate;
  /** fetch data from the table: "v1.OrderPayTo" using primary key columns */
  v1_OrderPayTo_by_pk?: Maybe<v1_OrderPayTo>;
  /** fetch data from the table in a streaming manner: "v1.OrderPayTo" */
  v1_OrderPayTo_stream: Array<v1_OrderPayTo>;
  /** fetch data from the table: "v1.OrderPriority" */
  v1_OrderPriority: Array<v1_OrderPriority>;
  /** fetch aggregated fields from the table: "v1.OrderPriority" */
  v1_OrderPriority_aggregate: v1_OrderPriority_aggregate;
  /** fetch data from the table: "v1.OrderPriority" using primary key columns */
  v1_OrderPriority_by_pk?: Maybe<v1_OrderPriority>;
  /** fetch data from the table in a streaming manner: "v1.OrderPriority" */
  v1_OrderPriority_stream: Array<v1_OrderPriority>;
  /** fetch data from the table: "v1.OrderType" */
  v1_OrderType: Array<v1_OrderType>;
  /** fetch aggregated fields from the table: "v1.OrderType" */
  v1_OrderType_aggregate: v1_OrderType_aggregate;
  /** fetch data from the table: "v1.OrderType" using primary key columns */
  v1_OrderType_by_pk?: Maybe<v1_OrderType>;
  /** fetch data from the table in a streaming manner: "v1.OrderType" */
  v1_OrderType_stream: Array<v1_OrderType>;
  /** fetch data from the table: "v1.OutreachPosts" */
  v1_OutreachPosts: Array<v1_OutreachPosts>;
  /** fetch aggregated fields from the table: "v1.OutreachPosts" */
  v1_OutreachPosts_aggregate: v1_OutreachPosts_aggregate;
  /** fetch data from the table in a streaming manner: "v1.OutreachPosts" */
  v1_OutreachPosts_stream: Array<v1_OutreachPosts>;
  /** fetch data from the table: "v1.Page" */
  v1_Page: Array<v1_Page>;
  /** fetch aggregated fields from the table: "v1.Page" */
  v1_Page_aggregate: v1_Page_aggregate;
  /** fetch data from the table: "v1.Page" using primary key columns */
  v1_Page_by_pk?: Maybe<v1_Page>;
  /** fetch data from the table in a streaming manner: "v1.Page" */
  v1_Page_stream: Array<v1_Page>;
  /** fetch data from the table: "v1.PaymentCompanies" */
  v1_PaymentCompanies: Array<v1_PaymentCompanies>;
  /** fetch aggregated fields from the table: "v1.PaymentCompanies" */
  v1_PaymentCompanies_aggregate: v1_PaymentCompanies_aggregate;
  /** fetch data from the table: "v1.PaymentCompanies" using primary key columns */
  v1_PaymentCompanies_by_pk?: Maybe<v1_PaymentCompanies>;
  /** fetch data from the table in a streaming manner: "v1.PaymentCompanies" */
  v1_PaymentCompanies_stream: Array<v1_PaymentCompanies>;
  /** fetch data from the table: "v1.PaymentFrequency" */
  v1_PaymentFrequency: Array<v1_PaymentFrequency>;
  /** fetch aggregated fields from the table: "v1.PaymentFrequency" */
  v1_PaymentFrequency_aggregate: v1_PaymentFrequency_aggregate;
  /** fetch data from the table: "v1.PaymentFrequency" using primary key columns */
  v1_PaymentFrequency_by_pk?: Maybe<v1_PaymentFrequency>;
  /** fetch data from the table in a streaming manner: "v1.PaymentFrequency" */
  v1_PaymentFrequency_stream: Array<v1_PaymentFrequency>;
  /** fetch data from the table: "v1.PoToPosts" */
  v1_PoToPosts: Array<v1_PoToPosts>;
  /** fetch aggregated fields from the table: "v1.PoToPosts" */
  v1_PoToPosts_aggregate: v1_PoToPosts_aggregate;
  /** fetch data from the table: "v1.PoToPosts" using primary key columns */
  v1_PoToPosts_by_pk?: Maybe<v1_PoToPosts>;
  /** fetch data from the table in a streaming manner: "v1.PoToPosts" */
  v1_PoToPosts_stream: Array<v1_PoToPosts>;
  /** fetch data from the table: "v1.PostDuration" */
  v1_PostDuration: Array<v1_PostDuration>;
  /** fetch aggregated fields from the table: "v1.PostDuration" */
  v1_PostDuration_aggregate: v1_PostDuration_aggregate;
  /** fetch data from the table: "v1.PostDuration" using primary key columns */
  v1_PostDuration_by_pk?: Maybe<v1_PostDuration>;
  /** fetch data from the table in a streaming manner: "v1.PostDuration" */
  v1_PostDuration_stream: Array<v1_PostDuration>;
  /** fetch data from the table: "v1.PostNiche" */
  v1_PostNiche: Array<v1_PostNiche>;
  /** fetch aggregated fields from the table: "v1.PostNiche" */
  v1_PostNiche_aggregate: v1_PostNiche_aggregate;
  /** fetch data from the table: "v1.PostNiche" using primary key columns */
  v1_PostNiche_by_pk?: Maybe<v1_PostNiche>;
  /** fetch data from the table in a streaming manner: "v1.PostNiche" */
  v1_PostNiche_stream: Array<v1_PostNiche>;
  /** fetch data from the table: "v1.Posts" */
  v1_Posts: Array<v1_Posts>;
  /** fetch data from the table: "v1.PostsAnchorTextType" */
  v1_PostsAnchorTextType: Array<v1_PostsAnchorTextType>;
  /** fetch aggregated fields from the table: "v1.PostsAnchorTextType" */
  v1_PostsAnchorTextType_aggregate: v1_PostsAnchorTextType_aggregate;
  /** fetch data from the table: "v1.PostsAnchorTextType" using primary key columns */
  v1_PostsAnchorTextType_by_pk?: Maybe<v1_PostsAnchorTextType>;
  /** fetch data from the table in a streaming manner: "v1.PostsAnchorTextType" */
  v1_PostsAnchorTextType_stream: Array<v1_PostsAnchorTextType>;
  /** fetch data from the table: "v1.PostsContentStatus" */
  v1_PostsContentStatus: Array<v1_PostsContentStatus>;
  /** fetch aggregated fields from the table: "v1.PostsContentStatus" */
  v1_PostsContentStatus_aggregate: v1_PostsContentStatus_aggregate;
  /** fetch data from the table: "v1.PostsContentStatus" using primary key columns */
  v1_PostsContentStatus_by_pk?: Maybe<v1_PostsContentStatus>;
  /** fetch data from the table in a streaming manner: "v1.PostsContentStatus" */
  v1_PostsContentStatus_stream: Array<v1_PostsContentStatus>;
  /** fetch data from the table: "v1.PostsCopyScape" */
  v1_PostsCopyScape: Array<v1_PostsCopyScape>;
  /** fetch aggregated fields from the table: "v1.PostsCopyScape" */
  v1_PostsCopyScape_aggregate: v1_PostsCopyScape_aggregate;
  /** fetch data from the table: "v1.PostsCopyScape" using primary key columns */
  v1_PostsCopyScape_by_pk?: Maybe<v1_PostsCopyScape>;
  /** fetch data from the table in a streaming manner: "v1.PostsCopyScape" */
  v1_PostsCopyScape_stream: Array<v1_PostsCopyScape>;
  /** fetch data from the table: "v1.PostsLinkStatus" */
  v1_PostsLinkStatus: Array<v1_PostsLinkStatus>;
  /** fetch aggregated fields from the table: "v1.PostsLinkStatus" */
  v1_PostsLinkStatus_aggregate: v1_PostsLinkStatus_aggregate;
  /** fetch data from the table: "v1.PostsLinkStatus" using primary key columns */
  v1_PostsLinkStatus_by_pk?: Maybe<v1_PostsLinkStatus>;
  /** fetch data from the table in a streaming manner: "v1.PostsLinkStatus" */
  v1_PostsLinkStatus_stream: Array<v1_PostsLinkStatus>;
  /** fetch data from the table: "v1.PostsOnlineStatus" */
  v1_PostsOnlineStatus: Array<v1_PostsOnlineStatus>;
  /** fetch aggregated fields from the table: "v1.PostsOnlineStatus" */
  v1_PostsOnlineStatus_aggregate: v1_PostsOnlineStatus_aggregate;
  /** fetch data from the table: "v1.PostsOnlineStatus" using primary key columns */
  v1_PostsOnlineStatus_by_pk?: Maybe<v1_PostsOnlineStatus>;
  /** fetch data from the table in a streaming manner: "v1.PostsOnlineStatus" */
  v1_PostsOnlineStatus_stream: Array<v1_PostsOnlineStatus>;
  /** fetch data from the table: "v1.PostsOutreachStatus" */
  v1_PostsOutreachStatus: Array<v1_PostsOutreachStatus>;
  /** fetch aggregated fields from the table: "v1.PostsOutreachStatus" */
  v1_PostsOutreachStatus_aggregate: v1_PostsOutreachStatus_aggregate;
  /** fetch data from the table: "v1.PostsOutreachStatus" using primary key columns */
  v1_PostsOutreachStatus_by_pk?: Maybe<v1_PostsOutreachStatus>;
  /** fetch data from the table in a streaming manner: "v1.PostsOutreachStatus" */
  v1_PostsOutreachStatus_stream: Array<v1_PostsOutreachStatus>;
  /** fetch data from the table: "v1.PostsPageTypeDeprecated" */
  v1_PostsPageTypeDeprecated: Array<v1_PostsPageTypeDeprecated>;
  /** fetch aggregated fields from the table: "v1.PostsPageTypeDeprecated" */
  v1_PostsPageTypeDeprecated_aggregate: v1_PostsPageTypeDeprecated_aggregate;
  /** fetch data from the table: "v1.PostsPageTypeDeprecated" using primary key columns */
  v1_PostsPageTypeDeprecated_by_pk?: Maybe<v1_PostsPageTypeDeprecated>;
  /** fetch data from the table in a streaming manner: "v1.PostsPageTypeDeprecated" */
  v1_PostsPageTypeDeprecated_stream: Array<v1_PostsPageTypeDeprecated>;
  /** fetch data from the table: "v1.PostsRemovalStatus" */
  v1_PostsRemovalStatus: Array<v1_PostsRemovalStatus>;
  /** fetch aggregated fields from the table: "v1.PostsRemovalStatus" */
  v1_PostsRemovalStatus_aggregate: v1_PostsRemovalStatus_aggregate;
  /** fetch data from the table: "v1.PostsRemovalStatus" using primary key columns */
  v1_PostsRemovalStatus_by_pk?: Maybe<v1_PostsRemovalStatus>;
  /** fetch data from the table in a streaming manner: "v1.PostsRemovalStatus" */
  v1_PostsRemovalStatus_stream: Array<v1_PostsRemovalStatus>;
  /** fetch data from the table: "v1.PostsSEOApproval" */
  v1_PostsSEOApproval: Array<v1_PostsSEOApproval>;
  /** fetch aggregated fields from the table: "v1.PostsSEOApproval" */
  v1_PostsSEOApproval_aggregate: v1_PostsSEOApproval_aggregate;
  /** fetch data from the table: "v1.PostsSEOApproval" using primary key columns */
  v1_PostsSEOApproval_by_pk?: Maybe<v1_PostsSEOApproval>;
  /** fetch data from the table in a streaming manner: "v1.PostsSEOApproval" */
  v1_PostsSEOApproval_stream: Array<v1_PostsSEOApproval>;
  /** fetch data from the table: "v1.PostsSEODeclineReason" */
  v1_PostsSEODeclineReason: Array<v1_PostsSEODeclineReason>;
  /** fetch aggregated fields from the table: "v1.PostsSEODeclineReason" */
  v1_PostsSEODeclineReason_aggregate: v1_PostsSEODeclineReason_aggregate;
  /** fetch data from the table: "v1.PostsSEODeclineReason" using primary key columns */
  v1_PostsSEODeclineReason_by_pk?: Maybe<v1_PostsSEODeclineReason>;
  /** fetch data from the table in a streaming manner: "v1.PostsSEODeclineReason" */
  v1_PostsSEODeclineReason_stream: Array<v1_PostsSEODeclineReason>;
  /** fetch data from the table: "v1.PostsStatus" */
  v1_PostsStatus: Array<v1_PostsStatus>;
  /** fetch data from the table: "v1.PostsStatusCode" */
  v1_PostsStatusCode: Array<v1_PostsStatusCode>;
  /** fetch aggregated fields from the table: "v1.PostsStatusCode" */
  v1_PostsStatusCode_aggregate: v1_PostsStatusCode_aggregate;
  /** fetch data from the table: "v1.PostsStatusCode" using primary key columns */
  v1_PostsStatusCode_by_pk?: Maybe<v1_PostsStatusCode>;
  /** fetch data from the table in a streaming manner: "v1.PostsStatusCode" */
  v1_PostsStatusCode_stream: Array<v1_PostsStatusCode>;
  /** fetch aggregated fields from the table: "v1.PostsStatus" */
  v1_PostsStatus_aggregate: v1_PostsStatus_aggregate;
  /** fetch data from the table: "v1.PostsStatus" using primary key columns */
  v1_PostsStatus_by_pk?: Maybe<v1_PostsStatus>;
  /** fetch data from the table in a streaming manner: "v1.PostsStatus" */
  v1_PostsStatus_stream: Array<v1_PostsStatus>;
  /** fetch data from the table: "v1.PostsToIndex" */
  v1_PostsToIndex: Array<v1_PostsToIndex>;
  /** fetch aggregated fields from the table: "v1.PostsToIndex" */
  v1_PostsToIndex_aggregate: v1_PostsToIndex_aggregate;
  /** fetch data from the table in a streaming manner: "v1.PostsToIndex" */
  v1_PostsToIndex_stream: Array<v1_PostsToIndex>;
  /** fetch aggregated fields from the table: "v1.Posts" */
  v1_Posts_aggregate: v1_Posts_aggregate;
  /** fetch data from the table: "v1.Posts" using primary key columns */
  v1_Posts_by_pk?: Maybe<v1_Posts>;
  /** fetch data from the table in a streaming manner: "v1.Posts" */
  v1_Posts_stream: Array<v1_Posts>;
  /** fetch data from the table: "v1.Preferences" */
  v1_Preferences: Array<v1_Preferences>;
  /** fetch data from the table: "v1.PreferencesMarketplace" */
  v1_PreferencesMarketplace: Array<v1_PreferencesMarketplace>;
  /** fetch aggregated fields from the table: "v1.PreferencesMarketplace" */
  v1_PreferencesMarketplace_aggregate: v1_PreferencesMarketplace_aggregate;
  /** fetch data from the table: "v1.PreferencesMarketplace" using primary key columns */
  v1_PreferencesMarketplace_by_pk?: Maybe<v1_PreferencesMarketplace>;
  /** fetch data from the table in a streaming manner: "v1.PreferencesMarketplace" */
  v1_PreferencesMarketplace_stream: Array<v1_PreferencesMarketplace>;
  /** fetch aggregated fields from the table: "v1.Preferences" */
  v1_Preferences_aggregate: v1_Preferences_aggregate;
  /** fetch data from the table: "v1.Preferences" using primary key columns */
  v1_Preferences_by_pk?: Maybe<v1_Preferences>;
  /** fetch data from the table in a streaming manner: "v1.Preferences" */
  v1_Preferences_stream: Array<v1_Preferences>;
  /** fetch data from the table: "v1.Project" */
  v1_Project: Array<v1_Project>;
  /** fetch data from the table: "v1.ProjectImportance" */
  v1_ProjectImportance: Array<v1_ProjectImportance>;
  /** fetch aggregated fields from the table: "v1.ProjectImportance" */
  v1_ProjectImportance_aggregate: v1_ProjectImportance_aggregate;
  /** fetch data from the table: "v1.ProjectImportance" using primary key columns */
  v1_ProjectImportance_by_pk?: Maybe<v1_ProjectImportance>;
  /** fetch data from the table in a streaming manner: "v1.ProjectImportance" */
  v1_ProjectImportance_stream: Array<v1_ProjectImportance>;
  /** fetch data from the table: "v1.ProjectManagers" */
  v1_ProjectManagers: Array<v1_ProjectManagers>;
  /** fetch aggregated fields from the table: "v1.ProjectManagers" */
  v1_ProjectManagers_aggregate: v1_ProjectManagers_aggregate;
  /** fetch data from the table in a streaming manner: "v1.ProjectManagers" */
  v1_ProjectManagers_stream: Array<v1_ProjectManagers>;
  /** fetch data from the table: "v1.ProjectStatus" */
  v1_ProjectStatus: Array<v1_ProjectStatus>;
  /** fetch aggregated fields from the table: "v1.ProjectStatus" */
  v1_ProjectStatus_aggregate: v1_ProjectStatus_aggregate;
  /** fetch data from the table: "v1.ProjectStatus" using primary key columns */
  v1_ProjectStatus_by_pk?: Maybe<v1_ProjectStatus>;
  /** fetch data from the table in a streaming manner: "v1.ProjectStatus" */
  v1_ProjectStatus_stream: Array<v1_ProjectStatus>;
  /** fetch data from the table: "v1.ProjectTopic" */
  v1_ProjectTopic: Array<v1_ProjectTopic>;
  /** fetch aggregated fields from the table: "v1.ProjectTopic" */
  v1_ProjectTopic_aggregate: v1_ProjectTopic_aggregate;
  /** fetch data from the table: "v1.ProjectTopic" using primary key columns */
  v1_ProjectTopic_by_pk?: Maybe<v1_ProjectTopic>;
  /** fetch data from the table in a streaming manner: "v1.ProjectTopic" */
  v1_ProjectTopic_stream: Array<v1_ProjectTopic>;
  /** fetch data from the table: "v1.Project_Countries" */
  v1_Project_Countries: Array<v1_Project_Countries>;
  /** fetch aggregated fields from the table: "v1.Project_Countries" */
  v1_Project_Countries_aggregate: v1_Project_Countries_aggregate;
  /** fetch data from the table: "v1.Project_Countries" using primary key columns */
  v1_Project_Countries_by_pk?: Maybe<v1_Project_Countries>;
  /** fetch data from the table in a streaming manner: "v1.Project_Countries" */
  v1_Project_Countries_stream: Array<v1_Project_Countries>;
  /** fetch aggregated fields from the table: "v1.Project" */
  v1_Project_aggregate: v1_Project_aggregate;
  /** fetch data from the table: "v1.Project" using primary key columns */
  v1_Project_by_pk?: Maybe<v1_Project>;
  /** fetch data from the table in a streaming manner: "v1.Project" */
  v1_Project_stream: Array<v1_Project>;
  /** fetch data from the table: "v1.PublisherPostStatus" */
  v1_PublisherPostStatus: Array<v1_PublisherPostStatus>;
  /** fetch aggregated fields from the table: "v1.PublisherPostStatus" */
  v1_PublisherPostStatus_aggregate: v1_PublisherPostStatus_aggregate;
  /** fetch data from the table: "v1.PublisherPostStatus" using primary key columns */
  v1_PublisherPostStatus_by_pk?: Maybe<v1_PublisherPostStatus>;
  /** fetch data from the table in a streaming manner: "v1.PublisherPostStatus" */
  v1_PublisherPostStatus_stream: Array<v1_PublisherPostStatus>;
  /** fetch data from the table: "v1.PurchaseOrderStatus" */
  v1_PurchaseOrderStatus: Array<v1_PurchaseOrderStatus>;
  /** fetch aggregated fields from the table: "v1.PurchaseOrderStatus" */
  v1_PurchaseOrderStatus_aggregate: v1_PurchaseOrderStatus_aggregate;
  /** fetch data from the table: "v1.PurchaseOrderStatus" using primary key columns */
  v1_PurchaseOrderStatus_by_pk?: Maybe<v1_PurchaseOrderStatus>;
  /** fetch data from the table in a streaming manner: "v1.PurchaseOrderStatus" */
  v1_PurchaseOrderStatus_stream: Array<v1_PurchaseOrderStatus>;
  /** fetch data from the table: "v1.ReceiptStatus" */
  v1_ReceiptStatus: Array<v1_ReceiptStatus>;
  /** fetch aggregated fields from the table: "v1.ReceiptStatus" */
  v1_ReceiptStatus_aggregate: v1_ReceiptStatus_aggregate;
  /** fetch data from the table: "v1.ReceiptStatus" using primary key columns */
  v1_ReceiptStatus_by_pk?: Maybe<v1_ReceiptStatus>;
  /** fetch data from the table in a streaming manner: "v1.ReceiptStatus" */
  v1_ReceiptStatus_stream: Array<v1_ReceiptStatus>;
  /** fetch data from the table: "v1.RecurringPaymentStatus" */
  v1_RecurringPaymentStatus: Array<v1_RecurringPaymentStatus>;
  /** fetch aggregated fields from the table: "v1.RecurringPaymentStatus" */
  v1_RecurringPaymentStatus_aggregate: v1_RecurringPaymentStatus_aggregate;
  /** fetch data from the table: "v1.RecurringPaymentStatus" using primary key columns */
  v1_RecurringPaymentStatus_by_pk?: Maybe<v1_RecurringPaymentStatus>;
  /** fetch data from the table in a streaming manner: "v1.RecurringPaymentStatus" */
  v1_RecurringPaymentStatus_stream: Array<v1_RecurringPaymentStatus>;
  /** fetch data from the table: "v1.RecurringPayments" */
  v1_RecurringPayments: Array<v1_RecurringPayments>;
  /** fetch data from the table: "v1.RecurringPayments_Roles" */
  v1_RecurringPayments_Roles: Array<v1_RecurringPayments_Roles>;
  /** fetch aggregated fields from the table: "v1.RecurringPayments_Roles" */
  v1_RecurringPayments_Roles_aggregate: v1_RecurringPayments_Roles_aggregate;
  /** fetch data from the table: "v1.RecurringPayments_Roles" using primary key columns */
  v1_RecurringPayments_Roles_by_pk?: Maybe<v1_RecurringPayments_Roles>;
  /** fetch data from the table in a streaming manner: "v1.RecurringPayments_Roles" */
  v1_RecurringPayments_Roles_stream: Array<v1_RecurringPayments_Roles>;
  /** fetch aggregated fields from the table: "v1.RecurringPayments" */
  v1_RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  /** fetch data from the table: "v1.RecurringPayments" using primary key columns */
  v1_RecurringPayments_by_pk?: Maybe<v1_RecurringPayments>;
  /** fetch data from the table in a streaming manner: "v1.RecurringPayments" */
  v1_RecurringPayments_stream: Array<v1_RecurringPayments>;
  /** fetch data from the table: "v1.Report" */
  v1_Report: Array<v1_Report>;
  /** fetch data from the table: "v1.Report_Roles" */
  v1_Report_Roles: Array<v1_Report_Roles>;
  /** fetch aggregated fields from the table: "v1.Report_Roles" */
  v1_Report_Roles_aggregate: v1_Report_Roles_aggregate;
  /** fetch data from the table: "v1.Report_Roles" using primary key columns */
  v1_Report_Roles_by_pk?: Maybe<v1_Report_Roles>;
  /** fetch data from the table in a streaming manner: "v1.Report_Roles" */
  v1_Report_Roles_stream: Array<v1_Report_Roles>;
  /** fetch aggregated fields from the table: "v1.Report" */
  v1_Report_aggregate: v1_Report_aggregate;
  /** fetch data from the table: "v1.Report" using primary key columns */
  v1_Report_by_pk?: Maybe<v1_Report>;
  /** fetch data from the table in a streaming manner: "v1.Report" */
  v1_Report_stream: Array<v1_Report>;
  /** fetch data from the table: "v1.RestrictedNichesToOffer" */
  v1_RestrictedNichesToOffer: Array<v1_RestrictedNichesToOffer>;
  /** fetch aggregated fields from the table: "v1.RestrictedNichesToOffer" */
  v1_RestrictedNichesToOffer_aggregate: v1_RestrictedNichesToOffer_aggregate;
  /** fetch data from the table: "v1.RestrictedNichesToOffer" using primary key columns */
  v1_RestrictedNichesToOffer_by_pk?: Maybe<v1_RestrictedNichesToOffer>;
  /** fetch data from the table in a streaming manner: "v1.RestrictedNichesToOffer" */
  v1_RestrictedNichesToOffer_stream: Array<v1_RestrictedNichesToOffer>;
  /** fetch data from the table: "v1.Roles" */
  v1_Roles: Array<v1_Roles>;
  /** fetch aggregated fields from the table: "v1.Roles" */
  v1_Roles_aggregate: v1_Roles_aggregate;
  /** fetch data from the table: "v1.Roles" using primary key columns */
  v1_Roles_by_pk?: Maybe<v1_Roles>;
  /** fetch data from the table in a streaming manner: "v1.Roles" */
  v1_Roles_stream: Array<v1_Roles>;
  /** fetch data from the table: "v1.SalesOrderBase" */
  v1_SalesOrderBase: Array<v1_SalesOrderBase>;
  /** fetch aggregated fields from the table: "v1.SalesOrderBase" */
  v1_SalesOrderBase_aggregate: v1_SalesOrderBase_aggregate;
  /** fetch data from the table: "v1.SalesOrderBase" using primary key columns */
  v1_SalesOrderBase_by_pk?: Maybe<v1_SalesOrderBase>;
  /** fetch data from the table in a streaming manner: "v1.SalesOrderBase" */
  v1_SalesOrderBase_stream: Array<v1_SalesOrderBase>;
  /** fetch data from the table: "v1.ServiceTypes" */
  v1_ServiceTypes: Array<v1_ServiceTypes>;
  /** fetch aggregated fields from the table: "v1.ServiceTypes" */
  v1_ServiceTypes_aggregate: v1_ServiceTypes_aggregate;
  /** fetch data from the table: "v1.ServiceTypes" using primary key columns */
  v1_ServiceTypes_by_pk?: Maybe<v1_ServiceTypes>;
  /** fetch data from the table in a streaming manner: "v1.ServiceTypes" */
  v1_ServiceTypes_stream: Array<v1_ServiceTypes>;
  /** fetch data from the table: "v1.SoftwareProviders" */
  v1_SoftwareProviders: Array<v1_SoftwareProviders>;
  /** fetch aggregated fields from the table: "v1.SoftwareProviders" */
  v1_SoftwareProviders_aggregate: v1_SoftwareProviders_aggregate;
  /** fetch data from the table: "v1.SoftwareProviders" using primary key columns */
  v1_SoftwareProviders_by_pk?: Maybe<v1_SoftwareProviders>;
  /** fetch data from the table in a streaming manner: "v1.SoftwareProviders" */
  v1_SoftwareProviders_stream: Array<v1_SoftwareProviders>;
  /** fetch data from the table: "v1.StrategyFieldsSet" */
  v1_StrategyFieldsSet: Array<v1_StrategyFieldsSet>;
  /** fetch aggregated fields from the table: "v1.StrategyFieldsSet" */
  v1_StrategyFieldsSet_aggregate: v1_StrategyFieldsSet_aggregate;
  /** fetch data from the table: "v1.StrategyFieldsSet" using primary key columns */
  v1_StrategyFieldsSet_by_pk?: Maybe<v1_StrategyFieldsSet>;
  /** fetch data from the table in a streaming manner: "v1.StrategyFieldsSet" */
  v1_StrategyFieldsSet_stream: Array<v1_StrategyFieldsSet>;
  /** fetch data from the table: "v1.Supplier_Tag_rel" */
  v1_Supplier_Tag_rel: Array<v1_Supplier_Tag_rel>;
  /** fetch aggregated fields from the table: "v1.Supplier_Tag_rel" */
  v1_Supplier_Tag_rel_aggregate: v1_Supplier_Tag_rel_aggregate;
  /** fetch data from the table: "v1.Supplier_Tag_rel" using primary key columns */
  v1_Supplier_Tag_rel_by_pk?: Maybe<v1_Supplier_Tag_rel>;
  /** fetch data from the table in a streaming manner: "v1.Supplier_Tag_rel" */
  v1_Supplier_Tag_rel_stream: Array<v1_Supplier_Tag_rel>;
  /** fetch data from the table: "v1.Suppliers" */
  v1_Suppliers: Array<v1_Suppliers>;
  /** fetch aggregated fields from the table: "v1.Suppliers" */
  v1_Suppliers_aggregate: v1_Suppliers_aggregate;
  /** fetch data from the table in a streaming manner: "v1.Suppliers" */
  v1_Suppliers_stream: Array<v1_Suppliers>;
  /** fetch data from the table: "v1.SystemUserBase" */
  v1_SystemUserBase: Array<v1_SystemUserBase>;
  /** fetch aggregated fields from the table: "v1.SystemUserBase" */
  v1_SystemUserBase_aggregate: v1_SystemUserBase_aggregate;
  /** fetch data from the table: "v1.SystemUserBase" using primary key columns */
  v1_SystemUserBase_by_pk?: Maybe<v1_SystemUserBase>;
  /** fetch data from the table in a streaming manner: "v1.SystemUserBase" */
  v1_SystemUserBase_stream: Array<v1_SystemUserBase>;
  /** fetch data from the table: "v1.Tags" */
  v1_Tags: Array<v1_Tags>;
  /** fetch aggregated fields from the table: "v1.Tags" */
  v1_Tags_aggregate: v1_Tags_aggregate;
  /** fetch data from the table: "v1.Tags" using primary key columns */
  v1_Tags_by_pk?: Maybe<v1_Tags>;
  /** fetch data from the table in a streaming manner: "v1.Tags" */
  v1_Tags_stream: Array<v1_Tags>;
  /** fetch data from the table: "v1.Teams" */
  v1_Teams: Array<v1_Teams>;
  /** fetch aggregated fields from the table: "v1.Teams" */
  v1_Teams_aggregate: v1_Teams_aggregate;
  /** fetch data from the table: "v1.Teams" using primary key columns */
  v1_Teams_by_pk?: Maybe<v1_Teams>;
  /** fetch data from the table in a streaming manner: "v1.Teams" */
  v1_Teams_stream: Array<v1_Teams>;
  /** fetch data from the table: "v1.TrackerDeal" */
  v1_TrackerDeal: Array<v1_TrackerDeal>;
  /** fetch aggregated fields from the table: "v1.TrackerDeal" */
  v1_TrackerDeal_aggregate: v1_TrackerDeal_aggregate;
  /** fetch data from the table: "v1.TrackerDeal" using primary key columns */
  v1_TrackerDeal_by_pk?: Maybe<v1_TrackerDeal>;
  /** fetch data from the table in a streaming manner: "v1.TrackerDeal" */
  v1_TrackerDeal_stream: Array<v1_TrackerDeal>;
  /** fetch data from the table: "v1.TrackerTags" */
  v1_TrackerTags: Array<v1_TrackerTags>;
  /** fetch aggregated fields from the table: "v1.TrackerTags" */
  v1_TrackerTags_aggregate: v1_TrackerTags_aggregate;
  /** fetch data from the table: "v1.TrackerTags" using primary key columns */
  v1_TrackerTags_by_pk?: Maybe<v1_TrackerTags>;
  /** fetch data from the table in a streaming manner: "v1.TrackerTags" */
  v1_TrackerTags_stream: Array<v1_TrackerTags>;
  /** fetch data from the table: "v1.TrackerToTags" */
  v1_TrackerToTags: Array<v1_TrackerToTags>;
  /** fetch aggregated fields from the table: "v1.TrackerToTags" */
  v1_TrackerToTags_aggregate: v1_TrackerToTags_aggregate;
  /** fetch data from the table: "v1.TrackerToTags" using primary key columns */
  v1_TrackerToTags_by_pk?: Maybe<v1_TrackerToTags>;
  /** fetch data from the table in a streaming manner: "v1.TrackerToTags" */
  v1_TrackerToTags_stream: Array<v1_TrackerToTags>;
  /** fetch data from the table: "v1.TransactionCurrencyBase" */
  v1_TransactionCurrencyBase: Array<v1_TransactionCurrencyBase>;
  /** fetch aggregated fields from the table: "v1.TransactionCurrencyBase" */
  v1_TransactionCurrencyBase_aggregate: v1_TransactionCurrencyBase_aggregate;
  /** fetch data from the table: "v1.TransactionCurrencyBase" using primary key columns */
  v1_TransactionCurrencyBase_by_pk?: Maybe<v1_TransactionCurrencyBase>;
  /** fetch data from the table in a streaming manner: "v1.TransactionCurrencyBase" */
  v1_TransactionCurrencyBase_stream: Array<v1_TransactionCurrencyBase>;
  /** fetch data from the table: "v1.UsersStatus" */
  v1_UsersStatus: Array<v1_UsersStatus>;
  /** fetch aggregated fields from the table: "v1.UsersStatus" */
  v1_UsersStatus_aggregate: v1_UsersStatus_aggregate;
  /** fetch data from the table: "v1.UsersStatus" using primary key columns */
  v1_UsersStatus_by_pk?: Maybe<v1_UsersStatus>;
  /** fetch data from the table in a streaming manner: "v1.UsersStatus" */
  v1_UsersStatus_stream: Array<v1_UsersStatus>;
  /** fetch data from the table: "v1.UsersToOffers" */
  v1_UsersToOffers: Array<v1_UsersToOffers>;
  /** fetch aggregated fields from the table: "v1.UsersToOffers" */
  v1_UsersToOffers_aggregate: v1_UsersToOffers_aggregate;
  /** fetch data from the table: "v1.UsersToOffers" using primary key columns */
  v1_UsersToOffers_by_pk?: Maybe<v1_UsersToOffers>;
  /** fetch data from the table in a streaming manner: "v1.UsersToOffers" */
  v1_UsersToOffers_stream: Array<v1_UsersToOffers>;
  /** fetch data from the table: "v1.Users_Roles" */
  v1_Users_Roles: Array<v1_Users_Roles>;
  /** fetch aggregated fields from the table: "v1.Users_Roles" */
  v1_Users_Roles_aggregate: v1_Users_Roles_aggregate;
  /** fetch data from the table: "v1.Users_Roles" using primary key columns */
  v1_Users_Roles_by_pk?: Maybe<v1_Users_Roles>;
  /** fetch data from the table in a streaming manner: "v1.Users_Roles" */
  v1_Users_Roles_stream: Array<v1_Users_Roles>;
  /** fetch data from the table: "v1.Users_Teams" */
  v1_Users_Teams: Array<v1_Users_Teams>;
  /** fetch aggregated fields from the table: "v1.Users_Teams" */
  v1_Users_Teams_aggregate: v1_Users_Teams_aggregate;
  /** fetch data from the table: "v1.Users_Teams" using primary key columns */
  v1_Users_Teams_by_pk?: Maybe<v1_Users_Teams>;
  /** fetch data from the table in a streaming manner: "v1.Users_Teams" */
  v1_Users_Teams_stream: Array<v1_Users_Teams>;
  /** fetch data from the table: "v1.WageringRequirementsLocation" */
  v1_WageringRequirementsLocation: Array<v1_WageringRequirementsLocation>;
  /** fetch aggregated fields from the table: "v1.WageringRequirementsLocation" */
  v1_WageringRequirementsLocation_aggregate: v1_WageringRequirementsLocation_aggregate;
  /** fetch data from the table: "v1.WageringRequirementsLocation" using primary key columns */
  v1_WageringRequirementsLocation_by_pk?: Maybe<v1_WageringRequirementsLocation>;
  /** fetch data from the table in a streaming manner: "v1.WageringRequirementsLocation" */
  v1_WageringRequirementsLocation_stream: Array<v1_WageringRequirementsLocation>;
  /** fetch data from the table: "v1.Website" */
  v1_Website: Array<v1_Website>;
  /** fetch data from the table: "v1.WebsiteBlogProbability" */
  v1_WebsiteBlogProbability: Array<v1_WebsiteBlogProbability>;
  /** fetch aggregated fields from the table: "v1.WebsiteBlogProbability" */
  v1_WebsiteBlogProbability_aggregate: v1_WebsiteBlogProbability_aggregate;
  /** fetch data from the table: "v1.WebsiteBlogProbability" using primary key columns */
  v1_WebsiteBlogProbability_by_pk?: Maybe<v1_WebsiteBlogProbability>;
  /** fetch data from the table in a streaming manner: "v1.WebsiteBlogProbability" */
  v1_WebsiteBlogProbability_stream: Array<v1_WebsiteBlogProbability>;
  /** fetch data from the table: "v1.WebsiteContentIndicator" */
  v1_WebsiteContentIndicator: Array<v1_WebsiteContentIndicator>;
  /** fetch aggregated fields from the table: "v1.WebsiteContentIndicator" */
  v1_WebsiteContentIndicator_aggregate: v1_WebsiteContentIndicator_aggregate;
  /** fetch data from the table: "v1.WebsiteContentIndicator" using primary key columns */
  v1_WebsiteContentIndicator_by_pk?: Maybe<v1_WebsiteContentIndicator>;
  /** fetch data from the table in a streaming manner: "v1.WebsiteContentIndicator" */
  v1_WebsiteContentIndicator_stream: Array<v1_WebsiteContentIndicator>;
  /** fetch data from the table: "v1.WebsiteDToxRisk" */
  v1_WebsiteDToxRisk: Array<v1_WebsiteDToxRisk>;
  /** fetch aggregated fields from the table: "v1.WebsiteDToxRisk" */
  v1_WebsiteDToxRisk_aggregate: v1_WebsiteDToxRisk_aggregate;
  /** fetch data from the table: "v1.WebsiteDToxRisk" using primary key columns */
  v1_WebsiteDToxRisk_by_pk?: Maybe<v1_WebsiteDToxRisk>;
  /** fetch data from the table in a streaming manner: "v1.WebsiteDToxRisk" */
  v1_WebsiteDToxRisk_stream: Array<v1_WebsiteDToxRisk>;
  /** fetch data from the table: "v1.WebsiteQualityScore" */
  v1_WebsiteQualityScore: Array<v1_WebsiteQualityScore>;
  /** fetch aggregated fields from the table: "v1.WebsiteQualityScore" */
  v1_WebsiteQualityScore_aggregate: v1_WebsiteQualityScore_aggregate;
  /** fetch data from the table: "v1.WebsiteQualityScore" using primary key columns */
  v1_WebsiteQualityScore_by_pk?: Maybe<v1_WebsiteQualityScore>;
  /** fetch data from the table in a streaming manner: "v1.WebsiteQualityScore" */
  v1_WebsiteQualityScore_stream: Array<v1_WebsiteQualityScore>;
  /** fetch data from the table: "v1.WebsiteStatus" */
  v1_WebsiteStatus: Array<v1_WebsiteStatus>;
  /** fetch aggregated fields from the table: "v1.WebsiteStatus" */
  v1_WebsiteStatus_aggregate: v1_WebsiteStatus_aggregate;
  /** fetch data from the table: "v1.WebsiteStatus" using primary key columns */
  v1_WebsiteStatus_by_pk?: Maybe<v1_WebsiteStatus>;
  /** fetch data from the table in a streaming manner: "v1.WebsiteStatus" */
  v1_WebsiteStatus_stream: Array<v1_WebsiteStatus>;
  /** fetch data from the table: "v1.WebsiteTopics" */
  v1_WebsiteTopics: Array<v1_WebsiteTopics>;
  /** fetch aggregated fields from the table: "v1.WebsiteTopics" */
  v1_WebsiteTopics_aggregate: v1_WebsiteTopics_aggregate;
  /** fetch data from the table: "v1.WebsiteTopics" using primary key columns */
  v1_WebsiteTopics_by_pk?: Maybe<v1_WebsiteTopics>;
  /** fetch data from the table in a streaming manner: "v1.WebsiteTopics" */
  v1_WebsiteTopics_stream: Array<v1_WebsiteTopics>;
  /** fetch data from the table: "v1.WebsiteUptimeStatus" */
  v1_WebsiteUptimeStatus: Array<v1_WebsiteUptimeStatus>;
  /** fetch aggregated fields from the table: "v1.WebsiteUptimeStatus" */
  v1_WebsiteUptimeStatus_aggregate: v1_WebsiteUptimeStatus_aggregate;
  /** fetch data from the table: "v1.WebsiteUptimeStatus" using primary key columns */
  v1_WebsiteUptimeStatus_by_pk?: Maybe<v1_WebsiteUptimeStatus>;
  /** fetch data from the table in a streaming manner: "v1.WebsiteUptimeStatus" */
  v1_WebsiteUptimeStatus_stream: Array<v1_WebsiteUptimeStatus>;
  /** fetch data from the table: "v1.Website_Platform" */
  v1_Website_Platform: Array<v1_Website_Platform>;
  /** fetch aggregated fields from the table: "v1.Website_Platform" */
  v1_Website_Platform_aggregate: v1_Website_Platform_aggregate;
  /** fetch data from the table: "v1.Website_Platform" using primary key columns */
  v1_Website_Platform_by_pk?: Maybe<v1_Website_Platform>;
  /** fetch data from the table in a streaming manner: "v1.Website_Platform" */
  v1_Website_Platform_stream: Array<v1_Website_Platform>;
  /** fetch aggregated fields from the table: "v1.Website" */
  v1_Website_aggregate: v1_Website_aggregate;
  /** fetch data from the table: "v1.Website" using primary key columns */
  v1_Website_by_pk?: Maybe<v1_Website>;
  /** fetch data from the table in a streaming manner: "v1.Website" */
  v1_Website_stream: Array<v1_Website>;
  /** fetch data from the table: "v1.WebsitesToTopics" */
  v1_WebsitesToTopics: Array<v1_WebsitesToTopics>;
  /** fetch aggregated fields from the table: "v1.WebsitesToTopics" */
  v1_WebsitesToTopics_aggregate: v1_WebsitesToTopics_aggregate;
  /** fetch data from the table: "v1.WebsitesToTopics" using primary key columns */
  v1_WebsitesToTopics_by_pk?: Maybe<v1_WebsitesToTopics>;
  /** fetch data from the table in a streaming manner: "v1.WebsitesToTopics" */
  v1_WebsitesToTopics_stream: Array<v1_WebsitesToTopics>;
  /** fetch data from the table: "v1.Writers" */
  v1_Writers: Array<v1_Writers>;
  /** fetch aggregated fields from the table: "v1.Writers" */
  v1_Writers_aggregate: v1_Writers_aggregate;
  /** fetch data from the table in a streaming manner: "v1.Writers" */
  v1_Writers_stream: Array<v1_Writers>;
  /** fetch data from the table: "v1.new_POBase" */
  v1_new_POBase: Array<v1_new_POBase>;
  /** fetch aggregated fields from the table: "v1.new_POBase" */
  v1_new_POBase_aggregate: v1_new_POBase_aggregate;
  /** fetch data from the table: "v1.new_POBase" using primary key columns */
  v1_new_POBase_by_pk?: Maybe<v1_new_POBase>;
  /** fetch data from the table in a streaming manner: "v1.new_POBase" */
  v1_new_POBase_stream: Array<v1_new_POBase>;
  /** fetch data from the table: "v1.new_affiliateincomereportBase" */
  v1_new_affiliateincomereportBase: Array<v1_new_affiliateincomereportBase>;
  /** fetch aggregated fields from the table: "v1.new_affiliateincomereportBase" */
  v1_new_affiliateincomereportBase_aggregate: v1_new_affiliateincomereportBase_aggregate;
  /** fetch data from the table: "v1.new_affiliateincomereportBase" using primary key columns */
  v1_new_affiliateincomereportBase_by_pk?: Maybe<v1_new_affiliateincomereportBase>;
  /** fetch data from the table in a streaming manner: "v1.new_affiliateincomereportBase" */
  v1_new_affiliateincomereportBase_stream: Array<v1_new_affiliateincomereportBase>;
  /** fetch data from the table: "v1.new_affordertypeBase" */
  v1_new_affordertypeBase: Array<v1_new_affordertypeBase>;
  /** fetch aggregated fields from the table: "v1.new_affordertypeBase" */
  v1_new_affordertypeBase_aggregate: v1_new_affordertypeBase_aggregate;
  /** fetch data from the table: "v1.new_affordertypeBase" using primary key columns */
  v1_new_affordertypeBase_by_pk?: Maybe<v1_new_affordertypeBase>;
  /** fetch data from the table in a streaming manner: "v1.new_affordertypeBase" */
  v1_new_affordertypeBase_stream: Array<v1_new_affordertypeBase>;
  /** fetch data from the table: "v1.new_brandBase" */
  v1_new_brandBase: Array<v1_new_brandBase>;
  /** fetch aggregated fields from the table: "v1.new_brandBase" */
  v1_new_brandBase_aggregate: v1_new_brandBase_aggregate;
  /** fetch data from the table: "v1.new_brandBase" using primary key columns */
  v1_new_brandBase_by_pk?: Maybe<v1_new_brandBase>;
  /** fetch data from the table in a streaming manner: "v1.new_brandBase" */
  v1_new_brandBase_stream: Array<v1_new_brandBase>;
  /** fetch data from the table: "v1.new_countryBase" */
  v1_new_countryBase: Array<v1_new_countryBase>;
  /** fetch aggregated fields from the table: "v1.new_countryBase" */
  v1_new_countryBase_aggregate: v1_new_countryBase_aggregate;
  /** fetch data from the table in a streaming manner: "v1.new_countryBase" */
  v1_new_countryBase_stream: Array<v1_new_countryBase>;
  /** fetch data from the table: "v1.new_languagesBase" */
  v1_new_languagesBase: Array<v1_new_languagesBase>;
  /** fetch aggregated fields from the table: "v1.new_languagesBase" */
  v1_new_languagesBase_aggregate: v1_new_languagesBase_aggregate;
  /** fetch data from the table: "v1.new_languagesBase" using primary key columns */
  v1_new_languagesBase_by_pk?: Maybe<v1_new_languagesBase>;
  /** fetch data from the table in a streaming manner: "v1.new_languagesBase" */
  v1_new_languagesBase_stream: Array<v1_new_languagesBase>;
  /** fetch data from the table: "v1.new_new_brand_new_countryBase" */
  v1_new_new_brand_new_countryBase: Array<v1_new_new_brand_new_countryBase>;
  /** fetch aggregated fields from the table: "v1.new_new_brand_new_countryBase" */
  v1_new_new_brand_new_countryBase_aggregate: v1_new_new_brand_new_countryBase_aggregate;
  /** fetch data from the table: "v1.new_new_brand_new_countryBase" using primary key columns */
  v1_new_new_brand_new_countryBase_by_pk?: Maybe<v1_new_new_brand_new_countryBase>;
  /** fetch data from the table in a streaming manner: "v1.new_new_brand_new_countryBase" */
  v1_new_new_brand_new_countryBase_stream: Array<v1_new_new_brand_new_countryBase>;
  /** fetch data from the table: "v1.new_paymentmethodBase" */
  v1_new_paymentmethodBase: Array<v1_new_paymentmethodBase>;
  /** fetch aggregated fields from the table: "v1.new_paymentmethodBase" */
  v1_new_paymentmethodBase_aggregate: v1_new_paymentmethodBase_aggregate;
  /** fetch data from the table: "v1.new_paymentmethodBase" using primary key columns */
  v1_new_paymentmethodBase_by_pk?: Maybe<v1_new_paymentmethodBase>;
  /** fetch data from the table in a streaming manner: "v1.new_paymentmethodBase" */
  v1_new_paymentmethodBase_stream: Array<v1_new_paymentmethodBase>;
  /** fetch data from the table: "v1.new_receiptBase" */
  v1_new_receiptBase: Array<v1_new_receiptBase>;
  /** fetch aggregated fields from the table: "v1.new_receiptBase" */
  v1_new_receiptBase_aggregate: v1_new_receiptBase_aggregate;
  /** fetch data from the table: "v1.new_receiptBase" using primary key columns */
  v1_new_receiptBase_by_pk?: Maybe<v1_new_receiptBase>;
  /** fetch data from the table in a streaming manner: "v1.new_receiptBase" */
  v1_new_receiptBase_stream: Array<v1_new_receiptBase>;
  /** fetch data from the table: "v1.new_regemailBase" */
  v1_new_regemailBase: Array<v1_new_regemailBase>;
  /** fetch aggregated fields from the table: "v1.new_regemailBase" */
  v1_new_regemailBase_aggregate: v1_new_regemailBase_aggregate;
  /** fetch data from the table: "v1.new_regemailBase" using primary key columns */
  v1_new_regemailBase_by_pk?: Maybe<v1_new_regemailBase>;
  /** fetch data from the table in a streaming manner: "v1.new_regemailBase" */
  v1_new_regemailBase_stream: Array<v1_new_regemailBase>;
  /** fetch data from the table: "v1.new_trackerBase" */
  v1_new_trackerBase: Array<v1_new_trackerBase>;
  /** fetch aggregated fields from the table: "v1.new_trackerBase" */
  v1_new_trackerBase_aggregate: v1_new_trackerBase_aggregate;
  /** fetch data from the table: "v1.new_trackerBase" using primary key columns */
  v1_new_trackerBase_by_pk?: Maybe<v1_new_trackerBase>;
  /** fetch data from the table in a streaming manner: "v1.new_trackerBase" */
  v1_new_trackerBase_stream: Array<v1_new_trackerBase>;
  /** fetch data from the table: "v1.process" */
  v1_process: Array<v1_process>;
  /** fetch aggregated fields from the table: "v1.process" */
  v1_process_aggregate: v1_process_aggregate;
  /** fetch data from the table: "v1.process" using primary key columns */
  v1_process_by_pk?: Maybe<v1_process>;
  /** fetch data from the table: "v1.process_rate" */
  v1_process_rate: Array<v1_process_rate>;
  /** fetch aggregated fields from the table: "v1.process_rate" */
  v1_process_rate_aggregate: v1_process_rate_aggregate;
  /** fetch data from the table: "v1.process_rate" using primary key columns */
  v1_process_rate_by_pk?: Maybe<v1_process_rate>;
  /** fetch data from the table in a streaming manner: "v1.process_rate" */
  v1_process_rate_stream: Array<v1_process_rate>;
  /** fetch data from the table in a streaming manner: "v1.process" */
  v1_process_stream: Array<v1_process>;
  /** fetch data from the table: "v1.project_topic" */
  v1_project_topic: Array<v1_project_topic>;
  /** fetch aggregated fields from the table: "v1.project_topic" */
  v1_project_topic_aggregate: v1_project_topic_aggregate;
  /** fetch data from the table: "v1.project_topic" using primary key columns */
  v1_project_topic_by_pk?: Maybe<v1_project_topic>;
  /** fetch data from the table in a streaming manner: "v1.project_topic" */
  v1_project_topic_stream: Array<v1_project_topic>;
};


export type subscription_rootClicks_OrphanTrackersArgs = {
  distinct_on?: InputMaybe<Array<Clicks_OrphanTrackers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clicks_OrphanTrackers_order_by>>;
  where?: InputMaybe<Clicks_OrphanTrackers_bool_exp>;
};


export type subscription_rootClicks_OrphanTrackers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<Clicks_OrphanTrackers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clicks_OrphanTrackers_order_by>>;
  where?: InputMaybe<Clicks_OrphanTrackers_bool_exp>;
};


export type subscription_rootClicks_linesArgs = {
  distinct_on?: InputMaybe<Array<Clicks_lines_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clicks_lines_order_by>>;
  where?: InputMaybe<Clicks_lines_bool_exp>;
};


export type subscription_rootClicks_lines_aggregateArgs = {
  distinct_on?: InputMaybe<Array<Clicks_lines_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clicks_lines_order_by>>;
  where?: InputMaybe<Clicks_lines_bool_exp>;
};


export type subscription_rootv1_AccountBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


export type subscription_rootv1_AccountBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


export type subscription_rootv1_AccountBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_AccountBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_AccountBase_stream_cursor_input>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


export type subscription_rootv1_AdvertisersArgs = {
  distinct_on?: InputMaybe<Array<v1_Advertisers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Advertisers_order_by>>;
  where?: InputMaybe<v1_Advertisers_bool_exp>;
};


export type subscription_rootv1_Advertisers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Advertisers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Advertisers_order_by>>;
  where?: InputMaybe<v1_Advertisers_bool_exp>;
};


export type subscription_rootv1_Advertisers_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Advertisers_stream_cursor_input>>;
  where?: InputMaybe<v1_Advertisers_bool_exp>;
};


export type subscription_rootv1_AffDealTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_AffDealType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffDealType_order_by>>;
  where?: InputMaybe<v1_AffDealType_bool_exp>;
};


export type subscription_rootv1_AffDealType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AffDealType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffDealType_order_by>>;
  where?: InputMaybe<v1_AffDealType_bool_exp>;
};


export type subscription_rootv1_AffDealType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_AffDealType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_AffDealType_stream_cursor_input>>;
  where?: InputMaybe<v1_AffDealType_bool_exp>;
};


export type subscription_rootv1_AffiliateOrderCategoriesPriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_order_by>>;
  where?: InputMaybe<v1_AffiliateOrderCategoriesPriority_bool_exp>;
};


export type subscription_rootv1_AffiliateOrderCategoriesPriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_order_by>>;
  where?: InputMaybe<v1_AffiliateOrderCategoriesPriority_bool_exp>;
};


export type subscription_rootv1_AffiliateOrderCategoriesPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_AffiliateOrderCategoriesPriority_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_AffiliateOrderCategoriesPriority_stream_cursor_input>>;
  where?: InputMaybe<v1_AffiliateOrderCategoriesPriority_bool_exp>;
};


export type subscription_rootv1_AffiliateProgramTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_AffiliateProgramType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffiliateProgramType_order_by>>;
  where?: InputMaybe<v1_AffiliateProgramType_bool_exp>;
};


export type subscription_rootv1_AffiliateProgramType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AffiliateProgramType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AffiliateProgramType_order_by>>;
  where?: InputMaybe<v1_AffiliateProgramType_bool_exp>;
};


export type subscription_rootv1_AffiliateProgramType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_AffiliateProgramType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_AffiliateProgramType_stream_cursor_input>>;
  where?: InputMaybe<v1_AffiliateProgramType_bool_exp>;
};


export type subscription_rootv1_AllowedPlayersArgs = {
  distinct_on?: InputMaybe<Array<v1_AllowedPlayers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AllowedPlayers_order_by>>;
  where?: InputMaybe<v1_AllowedPlayers_bool_exp>;
};


export type subscription_rootv1_AllowedPlayers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AllowedPlayers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AllowedPlayers_order_by>>;
  where?: InputMaybe<v1_AllowedPlayers_bool_exp>;
};


export type subscription_rootv1_AllowedPlayers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_AllowedPlayers_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_AllowedPlayers_stream_cursor_input>>;
  where?: InputMaybe<v1_AllowedPlayers_bool_exp>;
};


export type subscription_rootv1_ArticleTonesArgs = {
  distinct_on?: InputMaybe<Array<v1_ArticleTones_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ArticleTones_order_by>>;
  where?: InputMaybe<v1_ArticleTones_bool_exp>;
};


export type subscription_rootv1_ArticleTones_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ArticleTones_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ArticleTones_order_by>>;
  where?: InputMaybe<v1_ArticleTones_bool_exp>;
};


export type subscription_rootv1_ArticleTones_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ArticleTones_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ArticleTones_stream_cursor_input>>;
  where?: InputMaybe<v1_ArticleTones_bool_exp>;
};


export type subscription_rootv1_BalanceArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


export type subscription_rootv1_BalanceActivityTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_BalanceActivityType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BalanceActivityType_order_by>>;
  where?: InputMaybe<v1_BalanceActivityType_bool_exp>;
};


export type subscription_rootv1_BalanceActivityType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BalanceActivityType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BalanceActivityType_order_by>>;
  where?: InputMaybe<v1_BalanceActivityType_bool_exp>;
};


export type subscription_rootv1_BalanceActivityType_by_pkArgs = {
  Name: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BalanceActivityType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BalanceActivityType_stream_cursor_input>>;
  where?: InputMaybe<v1_BalanceActivityType_bool_exp>;
};


export type subscription_rootv1_Balance_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


export type subscription_rootv1_Balance_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Balance_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Balance_stream_cursor_input>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


export type subscription_rootv1_BillingInformationArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingInformation_order_by>>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};


export type subscription_rootv1_BillingInformation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingInformation_order_by>>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};


export type subscription_rootv1_BillingInformation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BillingInformation_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BillingInformation_stream_cursor_input>>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};


export type subscription_rootv1_BillingMethodArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


export type subscription_rootv1_BillingMethod_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


export type subscription_rootv1_BillingMethod_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BillingMethod_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BillingMethod_stream_cursor_input>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


export type subscription_rootv1_BonusSourceArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSource_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSource_order_by>>;
  where?: InputMaybe<v1_BonusSource_bool_exp>;
};


export type subscription_rootv1_BonusSource_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSource_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSource_order_by>>;
  where?: InputMaybe<v1_BonusSource_bool_exp>;
};


export type subscription_rootv1_BonusSource_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BonusSource_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BonusSource_stream_cursor_input>>;
  where?: InputMaybe<v1_BonusSource_bool_exp>;
};


export type subscription_rootv1_BonusStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusStatus_order_by>>;
  where?: InputMaybe<v1_BonusStatus_bool_exp>;
};


export type subscription_rootv1_BonusStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusStatus_order_by>>;
  where?: InputMaybe<v1_BonusStatus_bool_exp>;
};


export type subscription_rootv1_BonusStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BonusStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BonusStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_BonusStatus_bool_exp>;
};


export type subscription_rootv1_BonusSubmitterArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSubmitter_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSubmitter_order_by>>;
  where?: InputMaybe<v1_BonusSubmitter_bool_exp>;
};


export type subscription_rootv1_BonusSubmitter_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSubmitter_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSubmitter_order_by>>;
  where?: InputMaybe<v1_BonusSubmitter_bool_exp>;
};


export type subscription_rootv1_BonusSubmitter_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BonusSubmitter_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BonusSubmitter_stream_cursor_input>>;
  where?: InputMaybe<v1_BonusSubmitter_bool_exp>;
};


export type subscription_rootv1_BonusTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusTypes_order_by>>;
  where?: InputMaybe<v1_BonusTypes_bool_exp>;
};


export type subscription_rootv1_BonusTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusTypes_order_by>>;
  where?: InputMaybe<v1_BonusTypes_bool_exp>;
};


export type subscription_rootv1_BonusTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BonusTypes_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BonusTypes_stream_cursor_input>>;
  where?: InputMaybe<v1_BonusTypes_bool_exp>;
};


export type subscription_rootv1_BonusesArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


export type subscription_rootv1_BonusesToCountriesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCountries_order_by>>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};


export type subscription_rootv1_BonusesToCountries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCountries_order_by>>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};


export type subscription_rootv1_BonusesToCountries_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BonusesToCountries_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BonusesToCountries_stream_cursor_input>>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};


export type subscription_rootv1_BonusesToCurrenciesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCurrencies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCurrencies_order_by>>;
  where?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
};


export type subscription_rootv1_BonusesToCurrencies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCurrencies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCurrencies_order_by>>;
  where?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
};


export type subscription_rootv1_BonusesToCurrencies_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BonusesToCurrencies_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BonusesToCurrencies_stream_cursor_input>>;
  where?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
};


export type subscription_rootv1_BonusesToTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToTypes_order_by>>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};


export type subscription_rootv1_BonusesToTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToTypes_order_by>>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};


export type subscription_rootv1_BonusesToTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BonusesToTypes_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BonusesToTypes_stream_cursor_input>>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};


export type subscription_rootv1_Bonuses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


export type subscription_rootv1_Bonuses_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Bonuses_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Bonuses_stream_cursor_input>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


export type subscription_rootv1_BonusestoGamesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusestoGames_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusestoGames_order_by>>;
  where?: InputMaybe<v1_BonusestoGames_bool_exp>;
};


export type subscription_rootv1_BonusestoGames_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusestoGames_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusestoGames_order_by>>;
  where?: InputMaybe<v1_BonusestoGames_bool_exp>;
};


export type subscription_rootv1_BonusestoGames_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BonusestoGames_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BonusestoGames_stream_cursor_input>>;
  where?: InputMaybe<v1_BonusestoGames_bool_exp>;
};


export type subscription_rootv1_BrandAliasArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandAlias_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandAlias_order_by>>;
  where?: InputMaybe<v1_BrandAlias_bool_exp>;
};


export type subscription_rootv1_BrandAlias_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandAlias_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandAlias_order_by>>;
  where?: InputMaybe<v1_BrandAlias_bool_exp>;
};


export type subscription_rootv1_BrandAlias_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BrandAlias_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BrandAlias_stream_cursor_input>>;
  where?: InputMaybe<v1_BrandAlias_bool_exp>;
};


export type subscription_rootv1_BrandPriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandPriority_order_by>>;
  where?: InputMaybe<v1_BrandPriority_bool_exp>;
};


export type subscription_rootv1_BrandPriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandPriority_order_by>>;
  where?: InputMaybe<v1_BrandPriority_bool_exp>;
};


export type subscription_rootv1_BrandPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_BrandPriority_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_BrandPriority_stream_cursor_input>>;
  where?: InputMaybe<v1_BrandPriority_bool_exp>;
};


export type subscription_rootv1_CampaignArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


export type subscription_rootv1_CampaignPriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignPriority_order_by>>;
  where?: InputMaybe<v1_CampaignPriority_bool_exp>;
};


export type subscription_rootv1_CampaignPriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignPriority_order_by>>;
  where?: InputMaybe<v1_CampaignPriority_bool_exp>;
};


export type subscription_rootv1_CampaignPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_CampaignPriority_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_CampaignPriority_stream_cursor_input>>;
  where?: InputMaybe<v1_CampaignPriority_bool_exp>;
};


export type subscription_rootv1_CampaignStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStatus_order_by>>;
  where?: InputMaybe<v1_CampaignStatus_bool_exp>;
};


export type subscription_rootv1_CampaignStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStatus_order_by>>;
  where?: InputMaybe<v1_CampaignStatus_bool_exp>;
};


export type subscription_rootv1_CampaignStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_CampaignStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_CampaignStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_CampaignStatus_bool_exp>;
};


export type subscription_rootv1_CampaignStrategyArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


export type subscription_rootv1_CampaignStrategy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


export type subscription_rootv1_CampaignStrategy_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_CampaignStrategy_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_CampaignStrategy_stream_cursor_input>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


export type subscription_rootv1_CampaignTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignType_order_by>>;
  where?: InputMaybe<v1_CampaignType_bool_exp>;
};


export type subscription_rootv1_CampaignType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignType_order_by>>;
  where?: InputMaybe<v1_CampaignType_bool_exp>;
};


export type subscription_rootv1_CampaignType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_CampaignType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_CampaignType_stream_cursor_input>>;
  where?: InputMaybe<v1_CampaignType_bool_exp>;
};


export type subscription_rootv1_Campaign_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


export type subscription_rootv1_Campaign_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Campaign_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Campaign_stream_cursor_input>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


export type subscription_rootv1_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<v1_Categories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Categories_order_by>>;
  where?: InputMaybe<v1_Categories_bool_exp>;
};


export type subscription_rootv1_Categories_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Categories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Categories_order_by>>;
  where?: InputMaybe<v1_Categories_bool_exp>;
};


export type subscription_rootv1_Categories_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Categories_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Categories_stream_cursor_input>>;
  where?: InputMaybe<v1_Categories_bool_exp>;
};


export type subscription_rootv1_CheckoutArgs = {
  distinct_on?: InputMaybe<Array<v1_Checkout_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Checkout_order_by>>;
  where?: InputMaybe<v1_Checkout_bool_exp>;
};


export type subscription_rootv1_CheckoutDetailArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


export type subscription_rootv1_CheckoutDetail_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


export type subscription_rootv1_CheckoutDetail_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_CheckoutDetail_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_CheckoutDetail_stream_cursor_input>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


export type subscription_rootv1_CheckoutStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutStatus_order_by>>;
  where?: InputMaybe<v1_CheckoutStatus_bool_exp>;
};


export type subscription_rootv1_CheckoutStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutStatus_order_by>>;
  where?: InputMaybe<v1_CheckoutStatus_bool_exp>;
};


export type subscription_rootv1_CheckoutStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_CheckoutStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_CheckoutStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_CheckoutStatus_bool_exp>;
};


export type subscription_rootv1_Checkout_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Checkout_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Checkout_order_by>>;
  where?: InputMaybe<v1_Checkout_bool_exp>;
};


export type subscription_rootv1_Checkout_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Checkout_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Checkout_stream_cursor_input>>;
  where?: InputMaybe<v1_Checkout_bool_exp>;
};


export type subscription_rootv1_CitiesArgs = {
  distinct_on?: InputMaybe<Array<v1_Cities_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Cities_order_by>>;
  where?: InputMaybe<v1_Cities_bool_exp>;
};


export type subscription_rootv1_Cities_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Cities_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Cities_order_by>>;
  where?: InputMaybe<v1_Cities_bool_exp>;
};


export type subscription_rootv1_Cities_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Cities_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Cities_stream_cursor_input>>;
  where?: InputMaybe<v1_Cities_bool_exp>;
};


export type subscription_rootv1_ClicksByTrackersArgs = {
  distinct_on?: InputMaybe<Array<v1_ClicksByTrackers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ClicksByTrackers_order_by>>;
  where?: InputMaybe<v1_ClicksByTrackers_bool_exp>;
};


export type subscription_rootv1_ClicksByTrackersNewArgs = {
  distinct_on?: InputMaybe<Array<v1_ClicksByTrackersNew_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ClicksByTrackersNew_order_by>>;
  where?: InputMaybe<v1_ClicksByTrackersNew_bool_exp>;
};


export type subscription_rootv1_ClicksByTrackersNew_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ClicksByTrackersNew_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ClicksByTrackersNew_order_by>>;
  where?: InputMaybe<v1_ClicksByTrackersNew_bool_exp>;
};


export type subscription_rootv1_ClicksByTrackersNew_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ClicksByTrackersNew_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ClicksByTrackersNew_stream_cursor_input>>;
  where?: InputMaybe<v1_ClicksByTrackersNew_bool_exp>;
};


export type subscription_rootv1_ClicksByTrackers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ClicksByTrackers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ClicksByTrackers_order_by>>;
  where?: InputMaybe<v1_ClicksByTrackers_bool_exp>;
};


export type subscription_rootv1_ClicksByTrackers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ClicksByTrackers_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ClicksByTrackers_stream_cursor_input>>;
  where?: InputMaybe<v1_ClicksByTrackers_bool_exp>;
};


export type subscription_rootv1_CompanyTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_CompanyType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CompanyType_order_by>>;
  where?: InputMaybe<v1_CompanyType_bool_exp>;
};


export type subscription_rootv1_CompanyType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CompanyType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CompanyType_order_by>>;
  where?: InputMaybe<v1_CompanyType_bool_exp>;
};


export type subscription_rootv1_CompanyType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_CompanyType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_CompanyType_stream_cursor_input>>;
  where?: InputMaybe<v1_CompanyType_bool_exp>;
};


export type subscription_rootv1_ConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<v1_Configurations_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Configurations_order_by>>;
  where?: InputMaybe<v1_Configurations_bool_exp>;
};


export type subscription_rootv1_Configurations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Configurations_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Configurations_order_by>>;
  where?: InputMaybe<v1_Configurations_bool_exp>;
};


export type subscription_rootv1_Configurations_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Configurations_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Configurations_stream_cursor_input>>;
  where?: InputMaybe<v1_Configurations_bool_exp>;
};


export type subscription_rootv1_ContactBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


export type subscription_rootv1_ContactBasePaymentOptionArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBasePaymentOption_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBasePaymentOption_order_by>>;
  where?: InputMaybe<v1_ContactBasePaymentOption_bool_exp>;
};


export type subscription_rootv1_ContactBasePaymentOption_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBasePaymentOption_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBasePaymentOption_order_by>>;
  where?: InputMaybe<v1_ContactBasePaymentOption_bool_exp>;
};


export type subscription_rootv1_ContactBasePaymentOption_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ContactBasePaymentOption_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ContactBasePaymentOption_stream_cursor_input>>;
  where?: InputMaybe<v1_ContactBasePaymentOption_bool_exp>;
};


export type subscription_rootv1_ContactBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


export type subscription_rootv1_ContactBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ContactBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ContactBase_stream_cursor_input>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


export type subscription_rootv1_ContactTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactType_order_by>>;
  where?: InputMaybe<v1_ContactType_bool_exp>;
};


export type subscription_rootv1_ContactType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactType_order_by>>;
  where?: InputMaybe<v1_ContactType_bool_exp>;
};


export type subscription_rootv1_ContactType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ContactType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ContactType_stream_cursor_input>>;
  where?: InputMaybe<v1_ContactType_bool_exp>;
};


export type subscription_rootv1_Contacts_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Contacts_Roles_order_by>>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};


export type subscription_rootv1_Contacts_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Contacts_Roles_order_by>>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};


export type subscription_rootv1_Contacts_Roles_by_pkArgs = {
  contact_id: Scalars['Int']['input'];
  role_id: Scalars['Int']['input'];
};


export type subscription_rootv1_Contacts_Roles_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Contacts_Roles_stream_cursor_input>>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};


export type subscription_rootv1_ContentLinkArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentLink_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentLink_order_by>>;
  where?: InputMaybe<v1_ContentLink_bool_exp>;
};


export type subscription_rootv1_ContentLink_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentLink_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentLink_order_by>>;
  where?: InputMaybe<v1_ContentLink_bool_exp>;
};


export type subscription_rootv1_ContentLink_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ContentLink_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ContentLink_stream_cursor_input>>;
  where?: InputMaybe<v1_ContentLink_bool_exp>;
};


export type subscription_rootv1_ContentTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentTypes_order_by>>;
  where?: InputMaybe<v1_ContentTypes_bool_exp>;
};


export type subscription_rootv1_ContentTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentTypes_order_by>>;
  where?: InputMaybe<v1_ContentTypes_bool_exp>;
};


export type subscription_rootv1_ContentTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ContentTypes_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ContentTypes_stream_cursor_input>>;
  where?: InputMaybe<v1_ContentTypes_bool_exp>;
};


export type subscription_rootv1_Credential_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credential_Roles_order_by>>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};


export type subscription_rootv1_Credential_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credential_Roles_order_by>>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};


export type subscription_rootv1_Credential_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Credential_Roles_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Credential_Roles_stream_cursor_input>>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};


export type subscription_rootv1_CredentialsArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


export type subscription_rootv1_Credentials_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


export type subscription_rootv1_Credentials_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Credentials_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Credentials_stream_cursor_input>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


export type subscription_rootv1_DomainExtensionsArgs = {
  distinct_on?: InputMaybe<Array<v1_DomainExtensions_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_DomainExtensions_order_by>>;
  where?: InputMaybe<v1_DomainExtensions_bool_exp>;
};


export type subscription_rootv1_DomainExtensions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_DomainExtensions_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_DomainExtensions_order_by>>;
  where?: InputMaybe<v1_DomainExtensions_bool_exp>;
};


export type subscription_rootv1_DomainExtensions_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_DomainExtensions_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_DomainExtensions_stream_cursor_input>>;
  where?: InputMaybe<v1_DomainExtensions_bool_exp>;
};


export type subscription_rootv1_EventsArgs = {
  distinct_on?: InputMaybe<Array<v1_Events_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Events_order_by>>;
  where?: InputMaybe<v1_Events_bool_exp>;
};


export type subscription_rootv1_Events_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Events_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Events_order_by>>;
  where?: InputMaybe<v1_Events_bool_exp>;
};


export type subscription_rootv1_Events_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Events_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Events_stream_cursor_input>>;
  where?: InputMaybe<v1_Events_bool_exp>;
};


export type subscription_rootv1_ExpiringRecPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_ExpiringRecPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ExpiringRecPayments_order_by>>;
  where?: InputMaybe<v1_ExpiringRecPayments_bool_exp>;
};


export type subscription_rootv1_ExpiringRecPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ExpiringRecPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ExpiringRecPayments_order_by>>;
  where?: InputMaybe<v1_ExpiringRecPayments_bool_exp>;
};


export type subscription_rootv1_ExpiringRecPayments_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ExpiringRecPayments_stream_cursor_input>>;
  where?: InputMaybe<v1_ExpiringRecPayments_bool_exp>;
};


export type subscription_rootv1_FilesArgs = {
  distinct_on?: InputMaybe<Array<v1_Files_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Files_order_by>>;
  where?: InputMaybe<v1_Files_bool_exp>;
};


export type subscription_rootv1_Files_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Files_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Files_order_by>>;
  where?: InputMaybe<v1_Files_bool_exp>;
};


export type subscription_rootv1_Files_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Files_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Files_stream_cursor_input>>;
  where?: InputMaybe<v1_Files_bool_exp>;
};


export type subscription_rootv1_GameTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_GameType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_GameType_order_by>>;
  where?: InputMaybe<v1_GameType_bool_exp>;
};


export type subscription_rootv1_GameType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_GameType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_GameType_order_by>>;
  where?: InputMaybe<v1_GameType_bool_exp>;
};


export type subscription_rootv1_GameType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_GameType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_GameType_stream_cursor_input>>;
  where?: InputMaybe<v1_GameType_bool_exp>;
};


export type subscription_rootv1_InvoiceBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


export type subscription_rootv1_InvoiceBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


export type subscription_rootv1_InvoiceBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_InvoiceBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_InvoiceBase_stream_cursor_input>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


export type subscription_rootv1_InvoiceDetailBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


export type subscription_rootv1_InvoiceDetailBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


export type subscription_rootv1_InvoiceDetailBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_InvoiceDetailBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_InvoiceDetailBase_stream_cursor_input>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


export type subscription_rootv1_InvoiceStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceStatus_order_by>>;
  where?: InputMaybe<v1_InvoiceStatus_bool_exp>;
};


export type subscription_rootv1_InvoiceStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceStatus_order_by>>;
  where?: InputMaybe<v1_InvoiceStatus_bool_exp>;
};


export type subscription_rootv1_InvoiceStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_InvoiceStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_InvoiceStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_InvoiceStatus_bool_exp>;
};


export type subscription_rootv1_LBInProgressPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_LBInProgressPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LBInProgressPosts_order_by>>;
  where?: InputMaybe<v1_LBInProgressPosts_bool_exp>;
};


export type subscription_rootv1_LBInProgressPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LBInProgressPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LBInProgressPosts_order_by>>;
  where?: InputMaybe<v1_LBInProgressPosts_bool_exp>;
};


export type subscription_rootv1_LBInProgressPosts_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_LBInProgressPosts_stream_cursor_input>>;
  where?: InputMaybe<v1_LBInProgressPosts_bool_exp>;
};


export type subscription_rootv1_LBPublishedPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_LBPublishedPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LBPublishedPosts_order_by>>;
  where?: InputMaybe<v1_LBPublishedPosts_bool_exp>;
};


export type subscription_rootv1_LBPublishedPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LBPublishedPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LBPublishedPosts_order_by>>;
  where?: InputMaybe<v1_LBPublishedPosts_bool_exp>;
};


export type subscription_rootv1_LBPublishedPosts_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_LBPublishedPosts_stream_cursor_input>>;
  where?: InputMaybe<v1_LBPublishedPosts_bool_exp>;
};


export type subscription_rootv1_LandingPagePriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPagePriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPagePriority_order_by>>;
  where?: InputMaybe<v1_LandingPagePriority_bool_exp>;
};


export type subscription_rootv1_LandingPagePriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPagePriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPagePriority_order_by>>;
  where?: InputMaybe<v1_LandingPagePriority_bool_exp>;
};


export type subscription_rootv1_LandingPagePriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_LandingPagePriority_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_LandingPagePriority_stream_cursor_input>>;
  where?: InputMaybe<v1_LandingPagePriority_bool_exp>;
};


export type subscription_rootv1_LandingPagesArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


export type subscription_rootv1_LandingPages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


export type subscription_rootv1_LandingPages_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_LandingPages_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_LandingPages_stream_cursor_input>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


export type subscription_rootv1_LanguagePriceStepArgs = {
  distinct_on?: InputMaybe<Array<v1_LanguagePriceStep_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LanguagePriceStep_order_by>>;
  where?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
};


export type subscription_rootv1_LanguagePriceStep_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LanguagePriceStep_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LanguagePriceStep_order_by>>;
  where?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
};


export type subscription_rootv1_LanguagePriceStep_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_LanguagePriceStep_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_LanguagePriceStep_stream_cursor_input>>;
  where?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
};


export type subscription_rootv1_MarketPlaceCategoriesArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
};


export type subscription_rootv1_MarketPlaceCategoriesTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategoriesTypes_bool_exp>;
};


export type subscription_rootv1_MarketPlaceCategoriesTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategoriesTypes_bool_exp>;
};


export type subscription_rootv1_MarketPlaceCategoriesTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketPlaceCategoriesTypes_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketPlaceCategoriesTypes_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketPlaceCategoriesTypes_bool_exp>;
};


export type subscription_rootv1_MarketPlaceCategories_OffersArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};


export type subscription_rootv1_MarketPlaceCategories_Offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};


export type subscription_rootv1_MarketPlaceCategories_Offers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketPlaceCategories_Offers_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketPlaceCategories_Offers_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};


export type subscription_rootv1_MarketPlaceCategories_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
};


export type subscription_rootv1_MarketPlaceCategories_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketPlaceCategories_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketPlaceCategories_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOfferNicheArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOfferNiche_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOfferNiche_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOfferNiche_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOfferNiche_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOfferNiche_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOfferNiche_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOfferNiche_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOfferNiche_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketPlaceOfferNiche_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketPlaceOfferNiche_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketPlaceOfferNiche_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOrderArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrder_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOrderRequestArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderRequest_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOrderRequest_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderRequest_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOrderRequest_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketPlaceOrderRequest_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketPlaceOrderRequest_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOrderStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderStatus_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderStatus_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOrderStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderStatus_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderStatus_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOrderStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketPlaceOrderStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketPlaceOrderStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketPlaceOrderStatus_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOrder_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrder_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};


export type subscription_rootv1_MarketPlaceOrder_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketPlaceOrder_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketPlaceOrder_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};


export type subscription_rootv1_MarketPlaceRegisterStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_order_by>>;
  where?: InputMaybe<v1_MarketPlaceRegisterStatus_bool_exp>;
};


export type subscription_rootv1_MarketPlaceRegisterStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_order_by>>;
  where?: InputMaybe<v1_MarketPlaceRegisterStatus_bool_exp>;
};


export type subscription_rootv1_MarketPlaceRegisterStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketPlaceRegisterStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketPlaceRegisterStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketPlaceRegisterStatus_bool_exp>;
};


export type subscription_rootv1_MarketTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketType_order_by>>;
  where?: InputMaybe<v1_MarketType_bool_exp>;
};


export type subscription_rootv1_MarketType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketType_order_by>>;
  where?: InputMaybe<v1_MarketType_bool_exp>;
};


export type subscription_rootv1_MarketType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketType_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketType_bool_exp>;
};


export type subscription_rootv1_MarketplaceProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceProjects_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceProjects_order_by>>;
  where?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
};


export type subscription_rootv1_MarketplaceProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceProjects_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceProjects_order_by>>;
  where?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
};


export type subscription_rootv1_MarketplaceProjects_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketplaceProjects_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketplaceProjects_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
};


export type subscription_rootv1_MarketplaceStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceStatus_order_by>>;
  where?: InputMaybe<v1_MarketplaceStatus_bool_exp>;
};


export type subscription_rootv1_MarketplaceStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceStatus_order_by>>;
  where?: InputMaybe<v1_MarketplaceStatus_bool_exp>;
};


export type subscription_rootv1_MarketplaceStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_MarketplaceStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_MarketplaceStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_MarketplaceStatus_bool_exp>;
};


export type subscription_rootv1_MonthsArgs = {
  distinct_on?: InputMaybe<Array<v1_Months_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Months_order_by>>;
  where?: InputMaybe<v1_Months_bool_exp>;
};


export type subscription_rootv1_Months_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Months_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Months_order_by>>;
  where?: InputMaybe<v1_Months_bool_exp>;
};


export type subscription_rootv1_Months_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Months_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Months_stream_cursor_input>>;
  where?: InputMaybe<v1_Months_bool_exp>;
};


export type subscription_rootv1_OfferArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


export type subscription_rootv1_OfferStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferStatus_order_by>>;
  where?: InputMaybe<v1_OfferStatus_bool_exp>;
};


export type subscription_rootv1_OfferStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferStatus_order_by>>;
  where?: InputMaybe<v1_OfferStatus_bool_exp>;
};


export type subscription_rootv1_OfferStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_OfferStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_OfferStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_OfferStatus_bool_exp>;
};


export type subscription_rootv1_OfferTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferTags_order_by>>;
  where?: InputMaybe<v1_OfferTags_bool_exp>;
};


export type subscription_rootv1_OfferTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferTags_order_by>>;
  where?: InputMaybe<v1_OfferTags_bool_exp>;
};


export type subscription_rootv1_OfferTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_OfferTags_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_OfferTags_stream_cursor_input>>;
  where?: InputMaybe<v1_OfferTags_bool_exp>;
};


export type subscription_rootv1_OfferToTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferToTags_order_by>>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};


export type subscription_rootv1_OfferToTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferToTags_order_by>>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};


export type subscription_rootv1_OfferToTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_OfferToTags_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_OfferToTags_stream_cursor_input>>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};


export type subscription_rootv1_Offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


export type subscription_rootv1_Offer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Offer_stream_cursor_input>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


export type subscription_rootv1_OrderForArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderFor_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderFor_order_by>>;
  where?: InputMaybe<v1_OrderFor_bool_exp>;
};


export type subscription_rootv1_OrderFor_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderFor_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderFor_order_by>>;
  where?: InputMaybe<v1_OrderFor_bool_exp>;
};


export type subscription_rootv1_OrderFor_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_OrderFor_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_OrderFor_stream_cursor_input>>;
  where?: InputMaybe<v1_OrderFor_bool_exp>;
};


export type subscription_rootv1_OrderPayToArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderPayTo_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderPayTo_order_by>>;
  where?: InputMaybe<v1_OrderPayTo_bool_exp>;
};


export type subscription_rootv1_OrderPayTo_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderPayTo_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderPayTo_order_by>>;
  where?: InputMaybe<v1_OrderPayTo_bool_exp>;
};


export type subscription_rootv1_OrderPayTo_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_OrderPayTo_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_OrderPayTo_stream_cursor_input>>;
  where?: InputMaybe<v1_OrderPayTo_bool_exp>;
};


export type subscription_rootv1_OrderPriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderPriority_order_by>>;
  where?: InputMaybe<v1_OrderPriority_bool_exp>;
};


export type subscription_rootv1_OrderPriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderPriority_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderPriority_order_by>>;
  where?: InputMaybe<v1_OrderPriority_bool_exp>;
};


export type subscription_rootv1_OrderPriority_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_OrderPriority_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_OrderPriority_stream_cursor_input>>;
  where?: InputMaybe<v1_OrderPriority_bool_exp>;
};


export type subscription_rootv1_OrderTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderType_order_by>>;
  where?: InputMaybe<v1_OrderType_bool_exp>;
};


export type subscription_rootv1_OrderType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OrderType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OrderType_order_by>>;
  where?: InputMaybe<v1_OrderType_bool_exp>;
};


export type subscription_rootv1_OrderType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_OrderType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_OrderType_stream_cursor_input>>;
  where?: InputMaybe<v1_OrderType_bool_exp>;
};


export type subscription_rootv1_OutreachPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_OutreachPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OutreachPosts_order_by>>;
  where?: InputMaybe<v1_OutreachPosts_bool_exp>;
};


export type subscription_rootv1_OutreachPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OutreachPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OutreachPosts_order_by>>;
  where?: InputMaybe<v1_OutreachPosts_bool_exp>;
};


export type subscription_rootv1_OutreachPosts_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_OutreachPosts_stream_cursor_input>>;
  where?: InputMaybe<v1_OutreachPosts_bool_exp>;
};


export type subscription_rootv1_PageArgs = {
  distinct_on?: InputMaybe<Array<v1_Page_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Page_order_by>>;
  where?: InputMaybe<v1_Page_bool_exp>;
};


export type subscription_rootv1_Page_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Page_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Page_order_by>>;
  where?: InputMaybe<v1_Page_bool_exp>;
};


export type subscription_rootv1_Page_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Page_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Page_stream_cursor_input>>;
  where?: InputMaybe<v1_Page_bool_exp>;
};


export type subscription_rootv1_PaymentCompaniesArgs = {
  distinct_on?: InputMaybe<Array<v1_PaymentCompanies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PaymentCompanies_order_by>>;
  where?: InputMaybe<v1_PaymentCompanies_bool_exp>;
};


export type subscription_rootv1_PaymentCompanies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PaymentCompanies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PaymentCompanies_order_by>>;
  where?: InputMaybe<v1_PaymentCompanies_bool_exp>;
};


export type subscription_rootv1_PaymentCompanies_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PaymentCompanies_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PaymentCompanies_stream_cursor_input>>;
  where?: InputMaybe<v1_PaymentCompanies_bool_exp>;
};


export type subscription_rootv1_PaymentFrequencyArgs = {
  distinct_on?: InputMaybe<Array<v1_PaymentFrequency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PaymentFrequency_order_by>>;
  where?: InputMaybe<v1_PaymentFrequency_bool_exp>;
};


export type subscription_rootv1_PaymentFrequency_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PaymentFrequency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PaymentFrequency_order_by>>;
  where?: InputMaybe<v1_PaymentFrequency_bool_exp>;
};


export type subscription_rootv1_PaymentFrequency_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PaymentFrequency_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PaymentFrequency_stream_cursor_input>>;
  where?: InputMaybe<v1_PaymentFrequency_bool_exp>;
};


export type subscription_rootv1_PoToPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PoToPosts_order_by>>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};


export type subscription_rootv1_PoToPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PoToPosts_order_by>>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};


export type subscription_rootv1_PoToPosts_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PoToPosts_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PoToPosts_stream_cursor_input>>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};


export type subscription_rootv1_PostDurationArgs = {
  distinct_on?: InputMaybe<Array<v1_PostDuration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostDuration_order_by>>;
  where?: InputMaybe<v1_PostDuration_bool_exp>;
};


export type subscription_rootv1_PostDuration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostDuration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostDuration_order_by>>;
  where?: InputMaybe<v1_PostDuration_bool_exp>;
};


export type subscription_rootv1_PostDuration_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostDuration_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostDuration_stream_cursor_input>>;
  where?: InputMaybe<v1_PostDuration_bool_exp>;
};


export type subscription_rootv1_PostNicheArgs = {
  distinct_on?: InputMaybe<Array<v1_PostNiche_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostNiche_order_by>>;
  where?: InputMaybe<v1_PostNiche_bool_exp>;
};


export type subscription_rootv1_PostNiche_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostNiche_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostNiche_order_by>>;
  where?: InputMaybe<v1_PostNiche_bool_exp>;
};


export type subscription_rootv1_PostNiche_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostNiche_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostNiche_stream_cursor_input>>;
  where?: InputMaybe<v1_PostNiche_bool_exp>;
};


export type subscription_rootv1_PostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


export type subscription_rootv1_PostsAnchorTextTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsAnchorTextType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsAnchorTextType_order_by>>;
  where?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
};


export type subscription_rootv1_PostsAnchorTextType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsAnchorTextType_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsAnchorTextType_order_by>>;
  where?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
};


export type subscription_rootv1_PostsAnchorTextType_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsAnchorTextType_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsAnchorTextType_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
};


export type subscription_rootv1_PostsContentStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsContentStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsContentStatus_order_by>>;
  where?: InputMaybe<v1_PostsContentStatus_bool_exp>;
};


export type subscription_rootv1_PostsContentStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsContentStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsContentStatus_order_by>>;
  where?: InputMaybe<v1_PostsContentStatus_bool_exp>;
};


export type subscription_rootv1_PostsContentStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsContentStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsContentStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsContentStatus_bool_exp>;
};


export type subscription_rootv1_PostsCopyScapeArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsCopyScape_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsCopyScape_order_by>>;
  where?: InputMaybe<v1_PostsCopyScape_bool_exp>;
};


export type subscription_rootv1_PostsCopyScape_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsCopyScape_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsCopyScape_order_by>>;
  where?: InputMaybe<v1_PostsCopyScape_bool_exp>;
};


export type subscription_rootv1_PostsCopyScape_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsCopyScape_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsCopyScape_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsCopyScape_bool_exp>;
};


export type subscription_rootv1_PostsLinkStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsLinkStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsLinkStatus_order_by>>;
  where?: InputMaybe<v1_PostsLinkStatus_bool_exp>;
};


export type subscription_rootv1_PostsLinkStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsLinkStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsLinkStatus_order_by>>;
  where?: InputMaybe<v1_PostsLinkStatus_bool_exp>;
};


export type subscription_rootv1_PostsLinkStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsLinkStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsLinkStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsLinkStatus_bool_exp>;
};


export type subscription_rootv1_PostsOnlineStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsOnlineStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsOnlineStatus_order_by>>;
  where?: InputMaybe<v1_PostsOnlineStatus_bool_exp>;
};


export type subscription_rootv1_PostsOnlineStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsOnlineStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsOnlineStatus_order_by>>;
  where?: InputMaybe<v1_PostsOnlineStatus_bool_exp>;
};


export type subscription_rootv1_PostsOnlineStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsOnlineStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsOnlineStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsOnlineStatus_bool_exp>;
};


export type subscription_rootv1_PostsOutreachStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsOutreachStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsOutreachStatus_order_by>>;
  where?: InputMaybe<v1_PostsOutreachStatus_bool_exp>;
};


export type subscription_rootv1_PostsOutreachStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsOutreachStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsOutreachStatus_order_by>>;
  where?: InputMaybe<v1_PostsOutreachStatus_bool_exp>;
};


export type subscription_rootv1_PostsOutreachStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsOutreachStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsOutreachStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsOutreachStatus_bool_exp>;
};


export type subscription_rootv1_PostsPageTypeDeprecatedArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsPageTypeDeprecated_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsPageTypeDeprecated_order_by>>;
  where?: InputMaybe<v1_PostsPageTypeDeprecated_bool_exp>;
};


export type subscription_rootv1_PostsPageTypeDeprecated_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsPageTypeDeprecated_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsPageTypeDeprecated_order_by>>;
  where?: InputMaybe<v1_PostsPageTypeDeprecated_bool_exp>;
};


export type subscription_rootv1_PostsPageTypeDeprecated_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsPageTypeDeprecated_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsPageTypeDeprecated_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsPageTypeDeprecated_bool_exp>;
};


export type subscription_rootv1_PostsRemovalStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsRemovalStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsRemovalStatus_order_by>>;
  where?: InputMaybe<v1_PostsRemovalStatus_bool_exp>;
};


export type subscription_rootv1_PostsRemovalStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsRemovalStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsRemovalStatus_order_by>>;
  where?: InputMaybe<v1_PostsRemovalStatus_bool_exp>;
};


export type subscription_rootv1_PostsRemovalStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsRemovalStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsRemovalStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsRemovalStatus_bool_exp>;
};


export type subscription_rootv1_PostsSEOApprovalArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsSEOApproval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsSEOApproval_order_by>>;
  where?: InputMaybe<v1_PostsSEOApproval_bool_exp>;
};


export type subscription_rootv1_PostsSEOApproval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsSEOApproval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsSEOApproval_order_by>>;
  where?: InputMaybe<v1_PostsSEOApproval_bool_exp>;
};


export type subscription_rootv1_PostsSEOApproval_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsSEOApproval_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsSEOApproval_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsSEOApproval_bool_exp>;
};


export type subscription_rootv1_PostsSEODeclineReasonArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsSEODeclineReason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsSEODeclineReason_order_by>>;
  where?: InputMaybe<v1_PostsSEODeclineReason_bool_exp>;
};


export type subscription_rootv1_PostsSEODeclineReason_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsSEODeclineReason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsSEODeclineReason_order_by>>;
  where?: InputMaybe<v1_PostsSEODeclineReason_bool_exp>;
};


export type subscription_rootv1_PostsSEODeclineReason_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsSEODeclineReason_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsSEODeclineReason_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsSEODeclineReason_bool_exp>;
};


export type subscription_rootv1_PostsStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsStatus_order_by>>;
  where?: InputMaybe<v1_PostsStatus_bool_exp>;
};


export type subscription_rootv1_PostsStatusCodeArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsStatusCode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsStatusCode_order_by>>;
  where?: InputMaybe<v1_PostsStatusCode_bool_exp>;
};


export type subscription_rootv1_PostsStatusCode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsStatusCode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsStatusCode_order_by>>;
  where?: InputMaybe<v1_PostsStatusCode_bool_exp>;
};


export type subscription_rootv1_PostsStatusCode_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsStatusCode_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsStatusCode_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsStatusCode_bool_exp>;
};


export type subscription_rootv1_PostsStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsStatus_order_by>>;
  where?: InputMaybe<v1_PostsStatus_bool_exp>;
};


export type subscription_rootv1_PostsStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PostsStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsStatus_bool_exp>;
};


export type subscription_rootv1_PostsToIndexArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsToIndex_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsToIndex_order_by>>;
  where?: InputMaybe<v1_PostsToIndex_bool_exp>;
};


export type subscription_rootv1_PostsToIndex_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PostsToIndex_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PostsToIndex_order_by>>;
  where?: InputMaybe<v1_PostsToIndex_bool_exp>;
};


export type subscription_rootv1_PostsToIndex_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PostsToIndex_stream_cursor_input>>;
  where?: InputMaybe<v1_PostsToIndex_bool_exp>;
};


export type subscription_rootv1_Posts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


export type subscription_rootv1_Posts_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Posts_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Posts_stream_cursor_input>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


export type subscription_rootv1_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<v1_Preferences_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Preferences_order_by>>;
  where?: InputMaybe<v1_Preferences_bool_exp>;
};


export type subscription_rootv1_PreferencesMarketplaceArgs = {
  distinct_on?: InputMaybe<Array<v1_PreferencesMarketplace_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PreferencesMarketplace_order_by>>;
  where?: InputMaybe<v1_PreferencesMarketplace_bool_exp>;
};


export type subscription_rootv1_PreferencesMarketplace_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PreferencesMarketplace_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PreferencesMarketplace_order_by>>;
  where?: InputMaybe<v1_PreferencesMarketplace_bool_exp>;
};


export type subscription_rootv1_PreferencesMarketplace_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PreferencesMarketplace_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PreferencesMarketplace_stream_cursor_input>>;
  where?: InputMaybe<v1_PreferencesMarketplace_bool_exp>;
};


export type subscription_rootv1_Preferences_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Preferences_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Preferences_order_by>>;
  where?: InputMaybe<v1_Preferences_bool_exp>;
};


export type subscription_rootv1_Preferences_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Preferences_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Preferences_stream_cursor_input>>;
  where?: InputMaybe<v1_Preferences_bool_exp>;
};


export type subscription_rootv1_ProjectArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


export type subscription_rootv1_ProjectImportanceArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectImportance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectImportance_order_by>>;
  where?: InputMaybe<v1_ProjectImportance_bool_exp>;
};


export type subscription_rootv1_ProjectImportance_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectImportance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectImportance_order_by>>;
  where?: InputMaybe<v1_ProjectImportance_bool_exp>;
};


export type subscription_rootv1_ProjectImportance_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ProjectImportance_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ProjectImportance_stream_cursor_input>>;
  where?: InputMaybe<v1_ProjectImportance_bool_exp>;
};


export type subscription_rootv1_ProjectManagersArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectManagers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectManagers_order_by>>;
  where?: InputMaybe<v1_ProjectManagers_bool_exp>;
};


export type subscription_rootv1_ProjectManagers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectManagers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectManagers_order_by>>;
  where?: InputMaybe<v1_ProjectManagers_bool_exp>;
};


export type subscription_rootv1_ProjectManagers_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ProjectManagers_stream_cursor_input>>;
  where?: InputMaybe<v1_ProjectManagers_bool_exp>;
};


export type subscription_rootv1_ProjectStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectStatus_order_by>>;
  where?: InputMaybe<v1_ProjectStatus_bool_exp>;
};


export type subscription_rootv1_ProjectStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectStatus_order_by>>;
  where?: InputMaybe<v1_ProjectStatus_bool_exp>;
};


export type subscription_rootv1_ProjectStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ProjectStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ProjectStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_ProjectStatus_bool_exp>;
};


export type subscription_rootv1_ProjectTopicArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectTopic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectTopic_order_by>>;
  where?: InputMaybe<v1_ProjectTopic_bool_exp>;
};


export type subscription_rootv1_ProjectTopic_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ProjectTopic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ProjectTopic_order_by>>;
  where?: InputMaybe<v1_ProjectTopic_bool_exp>;
};


export type subscription_rootv1_ProjectTopic_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ProjectTopic_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ProjectTopic_stream_cursor_input>>;
  where?: InputMaybe<v1_ProjectTopic_bool_exp>;
};


export type subscription_rootv1_Project_CountriesArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_Countries_order_by>>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};


export type subscription_rootv1_Project_Countries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_Countries_order_by>>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};


export type subscription_rootv1_Project_Countries_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Project_Countries_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Project_Countries_stream_cursor_input>>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};


export type subscription_rootv1_Project_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


export type subscription_rootv1_Project_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Project_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Project_stream_cursor_input>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


export type subscription_rootv1_PublisherPostStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PublisherPostStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PublisherPostStatus_order_by>>;
  where?: InputMaybe<v1_PublisherPostStatus_bool_exp>;
};


export type subscription_rootv1_PublisherPostStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PublisherPostStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PublisherPostStatus_order_by>>;
  where?: InputMaybe<v1_PublisherPostStatus_bool_exp>;
};


export type subscription_rootv1_PublisherPostStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PublisherPostStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PublisherPostStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_PublisherPostStatus_bool_exp>;
};


export type subscription_rootv1_PurchaseOrderStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_PurchaseOrderStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PurchaseOrderStatus_order_by>>;
  where?: InputMaybe<v1_PurchaseOrderStatus_bool_exp>;
};


export type subscription_rootv1_PurchaseOrderStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PurchaseOrderStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PurchaseOrderStatus_order_by>>;
  where?: InputMaybe<v1_PurchaseOrderStatus_bool_exp>;
};


export type subscription_rootv1_PurchaseOrderStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_PurchaseOrderStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_PurchaseOrderStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_PurchaseOrderStatus_bool_exp>;
};


export type subscription_rootv1_ReceiptStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_ReceiptStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ReceiptStatus_order_by>>;
  where?: InputMaybe<v1_ReceiptStatus_bool_exp>;
};


export type subscription_rootv1_ReceiptStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ReceiptStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ReceiptStatus_order_by>>;
  where?: InputMaybe<v1_ReceiptStatus_bool_exp>;
};


export type subscription_rootv1_ReceiptStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ReceiptStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ReceiptStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_ReceiptStatus_bool_exp>;
};


export type subscription_rootv1_RecurringPaymentStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPaymentStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPaymentStatus_order_by>>;
  where?: InputMaybe<v1_RecurringPaymentStatus_bool_exp>;
};


export type subscription_rootv1_RecurringPaymentStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPaymentStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPaymentStatus_order_by>>;
  where?: InputMaybe<v1_RecurringPaymentStatus_bool_exp>;
};


export type subscription_rootv1_RecurringPaymentStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_RecurringPaymentStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_RecurringPaymentStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_RecurringPaymentStatus_bool_exp>;
};


export type subscription_rootv1_RecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


export type subscription_rootv1_RecurringPayments_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_Roles_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};


export type subscription_rootv1_RecurringPayments_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_Roles_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};


export type subscription_rootv1_RecurringPayments_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_RecurringPayments_Roles_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_RecurringPayments_Roles_stream_cursor_input>>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};


export type subscription_rootv1_RecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


export type subscription_rootv1_RecurringPayments_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_RecurringPayments_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_RecurringPayments_stream_cursor_input>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


export type subscription_rootv1_ReportArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_order_by>>;
  where?: InputMaybe<v1_Report_bool_exp>;
};


export type subscription_rootv1_Report_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_Roles_order_by>>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};


export type subscription_rootv1_Report_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_Roles_order_by>>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};


export type subscription_rootv1_Report_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Report_Roles_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Report_Roles_stream_cursor_input>>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};


export type subscription_rootv1_Report_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_order_by>>;
  where?: InputMaybe<v1_Report_bool_exp>;
};


export type subscription_rootv1_Report_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Report_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Report_stream_cursor_input>>;
  where?: InputMaybe<v1_Report_bool_exp>;
};


export type subscription_rootv1_RestrictedNichesToOfferArgs = {
  distinct_on?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RestrictedNichesToOffer_order_by>>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};


export type subscription_rootv1_RestrictedNichesToOffer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RestrictedNichesToOffer_order_by>>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};


export type subscription_rootv1_RestrictedNichesToOffer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_RestrictedNichesToOffer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_RestrictedNichesToOffer_stream_cursor_input>>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};


export type subscription_rootv1_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Roles_order_by>>;
  where?: InputMaybe<v1_Roles_bool_exp>;
};


export type subscription_rootv1_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Roles_order_by>>;
  where?: InputMaybe<v1_Roles_bool_exp>;
};


export type subscription_rootv1_Roles_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Roles_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Roles_stream_cursor_input>>;
  where?: InputMaybe<v1_Roles_bool_exp>;
};


export type subscription_rootv1_SalesOrderBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


export type subscription_rootv1_SalesOrderBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


export type subscription_rootv1_SalesOrderBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_SalesOrderBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_SalesOrderBase_stream_cursor_input>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


export type subscription_rootv1_ServiceTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_ServiceTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ServiceTypes_order_by>>;
  where?: InputMaybe<v1_ServiceTypes_bool_exp>;
};


export type subscription_rootv1_ServiceTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ServiceTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ServiceTypes_order_by>>;
  where?: InputMaybe<v1_ServiceTypes_bool_exp>;
};


export type subscription_rootv1_ServiceTypes_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_ServiceTypes_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_ServiceTypes_stream_cursor_input>>;
  where?: InputMaybe<v1_ServiceTypes_bool_exp>;
};


export type subscription_rootv1_SoftwareProvidersArgs = {
  distinct_on?: InputMaybe<Array<v1_SoftwareProviders_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SoftwareProviders_order_by>>;
  where?: InputMaybe<v1_SoftwareProviders_bool_exp>;
};


export type subscription_rootv1_SoftwareProviders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SoftwareProviders_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SoftwareProviders_order_by>>;
  where?: InputMaybe<v1_SoftwareProviders_bool_exp>;
};


export type subscription_rootv1_SoftwareProviders_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_SoftwareProviders_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_SoftwareProviders_stream_cursor_input>>;
  where?: InputMaybe<v1_SoftwareProviders_bool_exp>;
};


export type subscription_rootv1_StrategyFieldsSetArgs = {
  distinct_on?: InputMaybe<Array<v1_StrategyFieldsSet_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_StrategyFieldsSet_order_by>>;
  where?: InputMaybe<v1_StrategyFieldsSet_bool_exp>;
};


export type subscription_rootv1_StrategyFieldsSet_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_StrategyFieldsSet_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_StrategyFieldsSet_order_by>>;
  where?: InputMaybe<v1_StrategyFieldsSet_bool_exp>;
};


export type subscription_rootv1_StrategyFieldsSet_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_StrategyFieldsSet_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_StrategyFieldsSet_stream_cursor_input>>;
  where?: InputMaybe<v1_StrategyFieldsSet_bool_exp>;
};


export type subscription_rootv1_Supplier_Tag_relArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};


export type subscription_rootv1_Supplier_Tag_rel_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};


export type subscription_rootv1_Supplier_Tag_rel_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Supplier_Tag_rel_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Supplier_Tag_rel_stream_cursor_input>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};


export type subscription_rootv1_SuppliersArgs = {
  distinct_on?: InputMaybe<Array<v1_Suppliers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Suppliers_order_by>>;
  where?: InputMaybe<v1_Suppliers_bool_exp>;
};


export type subscription_rootv1_Suppliers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Suppliers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Suppliers_order_by>>;
  where?: InputMaybe<v1_Suppliers_bool_exp>;
};


export type subscription_rootv1_Suppliers_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Suppliers_stream_cursor_input>>;
  where?: InputMaybe<v1_Suppliers_bool_exp>;
};


export type subscription_rootv1_SystemUserBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_SystemUserBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SystemUserBase_order_by>>;
  where?: InputMaybe<v1_SystemUserBase_bool_exp>;
};


export type subscription_rootv1_SystemUserBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SystemUserBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SystemUserBase_order_by>>;
  where?: InputMaybe<v1_SystemUserBase_bool_exp>;
};


export type subscription_rootv1_SystemUserBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_SystemUserBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_SystemUserBase_stream_cursor_input>>;
  where?: InputMaybe<v1_SystemUserBase_bool_exp>;
};


export type subscription_rootv1_TagsArgs = {
  distinct_on?: InputMaybe<Array<v1_Tags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Tags_order_by>>;
  where?: InputMaybe<v1_Tags_bool_exp>;
};


export type subscription_rootv1_Tags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Tags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Tags_order_by>>;
  where?: InputMaybe<v1_Tags_bool_exp>;
};


export type subscription_rootv1_Tags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Tags_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Tags_stream_cursor_input>>;
  where?: InputMaybe<v1_Tags_bool_exp>;
};


export type subscription_rootv1_TeamsArgs = {
  distinct_on?: InputMaybe<Array<v1_Teams_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Teams_order_by>>;
  where?: InputMaybe<v1_Teams_bool_exp>;
};


export type subscription_rootv1_Teams_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Teams_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Teams_order_by>>;
  where?: InputMaybe<v1_Teams_bool_exp>;
};


export type subscription_rootv1_Teams_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Teams_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Teams_stream_cursor_input>>;
  where?: InputMaybe<v1_Teams_bool_exp>;
};


export type subscription_rootv1_TrackerDealArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerDeal_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerDeal_order_by>>;
  where?: InputMaybe<v1_TrackerDeal_bool_exp>;
};


export type subscription_rootv1_TrackerDeal_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerDeal_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerDeal_order_by>>;
  where?: InputMaybe<v1_TrackerDeal_bool_exp>;
};


export type subscription_rootv1_TrackerDeal_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_TrackerDeal_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_TrackerDeal_stream_cursor_input>>;
  where?: InputMaybe<v1_TrackerDeal_bool_exp>;
};


export type subscription_rootv1_TrackerTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerTags_order_by>>;
  where?: InputMaybe<v1_TrackerTags_bool_exp>;
};


export type subscription_rootv1_TrackerTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerTags_order_by>>;
  where?: InputMaybe<v1_TrackerTags_bool_exp>;
};


export type subscription_rootv1_TrackerTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_TrackerTags_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_TrackerTags_stream_cursor_input>>;
  where?: InputMaybe<v1_TrackerTags_bool_exp>;
};


export type subscription_rootv1_TrackerToTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerToTags_order_by>>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};


export type subscription_rootv1_TrackerToTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerToTags_order_by>>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};


export type subscription_rootv1_TrackerToTags_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_TrackerToTags_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_TrackerToTags_stream_cursor_input>>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};


export type subscription_rootv1_TransactionCurrencyBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_TransactionCurrencyBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TransactionCurrencyBase_order_by>>;
  where?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
};


export type subscription_rootv1_TransactionCurrencyBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TransactionCurrencyBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TransactionCurrencyBase_order_by>>;
  where?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
};


export type subscription_rootv1_TransactionCurrencyBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_TransactionCurrencyBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_TransactionCurrencyBase_stream_cursor_input>>;
  where?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
};


export type subscription_rootv1_UsersStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersStatus_order_by>>;
  where?: InputMaybe<v1_UsersStatus_bool_exp>;
};


export type subscription_rootv1_UsersStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersStatus_order_by>>;
  where?: InputMaybe<v1_UsersStatus_bool_exp>;
};


export type subscription_rootv1_UsersStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_UsersStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_UsersStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_UsersStatus_bool_exp>;
};


export type subscription_rootv1_UsersToOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersToOffers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersToOffers_order_by>>;
  where?: InputMaybe<v1_UsersToOffers_bool_exp>;
};


export type subscription_rootv1_UsersToOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersToOffers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersToOffers_order_by>>;
  where?: InputMaybe<v1_UsersToOffers_bool_exp>;
};


export type subscription_rootv1_UsersToOffers_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_UsersToOffers_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_UsersToOffers_stream_cursor_input>>;
  where?: InputMaybe<v1_UsersToOffers_bool_exp>;
};


export type subscription_rootv1_Users_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Roles_order_by>>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};


export type subscription_rootv1_Users_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Roles_order_by>>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};


export type subscription_rootv1_Users_Roles_by_pkArgs = {
  role_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type subscription_rootv1_Users_Roles_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Users_Roles_stream_cursor_input>>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};


export type subscription_rootv1_Users_TeamsArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Teams_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Teams_order_by>>;
  where?: InputMaybe<v1_Users_Teams_bool_exp>;
};


export type subscription_rootv1_Users_Teams_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Teams_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Teams_order_by>>;
  where?: InputMaybe<v1_Users_Teams_bool_exp>;
};


export type subscription_rootv1_Users_Teams_by_pkArgs = {
  team_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type subscription_rootv1_Users_Teams_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Users_Teams_stream_cursor_input>>;
  where?: InputMaybe<v1_Users_Teams_bool_exp>;
};


export type subscription_rootv1_WageringRequirementsLocationArgs = {
  distinct_on?: InputMaybe<Array<v1_WageringRequirementsLocation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WageringRequirementsLocation_order_by>>;
  where?: InputMaybe<v1_WageringRequirementsLocation_bool_exp>;
};


export type subscription_rootv1_WageringRequirementsLocation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WageringRequirementsLocation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WageringRequirementsLocation_order_by>>;
  where?: InputMaybe<v1_WageringRequirementsLocation_bool_exp>;
};


export type subscription_rootv1_WageringRequirementsLocation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_WageringRequirementsLocation_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_WageringRequirementsLocation_stream_cursor_input>>;
  where?: InputMaybe<v1_WageringRequirementsLocation_bool_exp>;
};


export type subscription_rootv1_WebsiteArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


export type subscription_rootv1_WebsiteBlogProbabilityArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteBlogProbability_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteBlogProbability_order_by>>;
  where?: InputMaybe<v1_WebsiteBlogProbability_bool_exp>;
};


export type subscription_rootv1_WebsiteBlogProbability_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteBlogProbability_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteBlogProbability_order_by>>;
  where?: InputMaybe<v1_WebsiteBlogProbability_bool_exp>;
};


export type subscription_rootv1_WebsiteBlogProbability_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_WebsiteBlogProbability_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_WebsiteBlogProbability_stream_cursor_input>>;
  where?: InputMaybe<v1_WebsiteBlogProbability_bool_exp>;
};


export type subscription_rootv1_WebsiteContentIndicatorArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteContentIndicator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteContentIndicator_order_by>>;
  where?: InputMaybe<v1_WebsiteContentIndicator_bool_exp>;
};


export type subscription_rootv1_WebsiteContentIndicator_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteContentIndicator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteContentIndicator_order_by>>;
  where?: InputMaybe<v1_WebsiteContentIndicator_bool_exp>;
};


export type subscription_rootv1_WebsiteContentIndicator_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_WebsiteContentIndicator_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_WebsiteContentIndicator_stream_cursor_input>>;
  where?: InputMaybe<v1_WebsiteContentIndicator_bool_exp>;
};


export type subscription_rootv1_WebsiteDToxRiskArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteDToxRisk_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteDToxRisk_order_by>>;
  where?: InputMaybe<v1_WebsiteDToxRisk_bool_exp>;
};


export type subscription_rootv1_WebsiteDToxRisk_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteDToxRisk_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteDToxRisk_order_by>>;
  where?: InputMaybe<v1_WebsiteDToxRisk_bool_exp>;
};


export type subscription_rootv1_WebsiteDToxRisk_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_WebsiteDToxRisk_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_WebsiteDToxRisk_stream_cursor_input>>;
  where?: InputMaybe<v1_WebsiteDToxRisk_bool_exp>;
};


export type subscription_rootv1_WebsiteQualityScoreArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteQualityScore_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteQualityScore_order_by>>;
  where?: InputMaybe<v1_WebsiteQualityScore_bool_exp>;
};


export type subscription_rootv1_WebsiteQualityScore_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteQualityScore_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteQualityScore_order_by>>;
  where?: InputMaybe<v1_WebsiteQualityScore_bool_exp>;
};


export type subscription_rootv1_WebsiteQualityScore_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_WebsiteQualityScore_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_WebsiteQualityScore_stream_cursor_input>>;
  where?: InputMaybe<v1_WebsiteQualityScore_bool_exp>;
};


export type subscription_rootv1_WebsiteStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteStatus_order_by>>;
  where?: InputMaybe<v1_WebsiteStatus_bool_exp>;
};


export type subscription_rootv1_WebsiteStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteStatus_order_by>>;
  where?: InputMaybe<v1_WebsiteStatus_bool_exp>;
};


export type subscription_rootv1_WebsiteStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_WebsiteStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_WebsiteStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_WebsiteStatus_bool_exp>;
};


export type subscription_rootv1_WebsiteTopicsArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteTopics_order_by>>;
  where?: InputMaybe<v1_WebsiteTopics_bool_exp>;
};


export type subscription_rootv1_WebsiteTopics_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteTopics_order_by>>;
  where?: InputMaybe<v1_WebsiteTopics_bool_exp>;
};


export type subscription_rootv1_WebsiteTopics_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_WebsiteTopics_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_WebsiteTopics_stream_cursor_input>>;
  where?: InputMaybe<v1_WebsiteTopics_bool_exp>;
};


export type subscription_rootv1_WebsiteUptimeStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteUptimeStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteUptimeStatus_order_by>>;
  where?: InputMaybe<v1_WebsiteUptimeStatus_bool_exp>;
};


export type subscription_rootv1_WebsiteUptimeStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsiteUptimeStatus_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsiteUptimeStatus_order_by>>;
  where?: InputMaybe<v1_WebsiteUptimeStatus_bool_exp>;
};


export type subscription_rootv1_WebsiteUptimeStatus_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_WebsiteUptimeStatus_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_WebsiteUptimeStatus_stream_cursor_input>>;
  where?: InputMaybe<v1_WebsiteUptimeStatus_bool_exp>;
};


export type subscription_rootv1_Website_PlatformArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_Platform_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_Platform_order_by>>;
  where?: InputMaybe<v1_Website_Platform_bool_exp>;
};


export type subscription_rootv1_Website_Platform_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_Platform_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_Platform_order_by>>;
  where?: InputMaybe<v1_Website_Platform_bool_exp>;
};


export type subscription_rootv1_Website_Platform_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Website_Platform_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Website_Platform_stream_cursor_input>>;
  where?: InputMaybe<v1_Website_Platform_bool_exp>;
};


export type subscription_rootv1_Website_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


export type subscription_rootv1_Website_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_Website_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Website_stream_cursor_input>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


export type subscription_rootv1_WebsitesToTopicsArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsitesToTopics_order_by>>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};


export type subscription_rootv1_WebsitesToTopics_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsitesToTopics_order_by>>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};


export type subscription_rootv1_WebsitesToTopics_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_WebsitesToTopics_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_WebsitesToTopics_stream_cursor_input>>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};


export type subscription_rootv1_WritersArgs = {
  distinct_on?: InputMaybe<Array<v1_Writers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Writers_order_by>>;
  where?: InputMaybe<v1_Writers_bool_exp>;
};


export type subscription_rootv1_Writers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Writers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Writers_order_by>>;
  where?: InputMaybe<v1_Writers_bool_exp>;
};


export type subscription_rootv1_Writers_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_Writers_stream_cursor_input>>;
  where?: InputMaybe<v1_Writers_bool_exp>;
};


export type subscription_rootv1_new_POBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


export type subscription_rootv1_new_POBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


export type subscription_rootv1_new_POBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_POBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_POBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


export type subscription_rootv1_new_affiliateincomereportBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affiliateincomereportBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affiliateincomereportBase_order_by>>;
  where?: InputMaybe<v1_new_affiliateincomereportBase_bool_exp>;
};


export type subscription_rootv1_new_affiliateincomereportBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affiliateincomereportBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affiliateincomereportBase_order_by>>;
  where?: InputMaybe<v1_new_affiliateincomereportBase_bool_exp>;
};


export type subscription_rootv1_new_affiliateincomereportBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_affiliateincomereportBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_affiliateincomereportBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_affiliateincomereportBase_bool_exp>;
};


export type subscription_rootv1_new_affordertypeBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affordertypeBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affordertypeBase_order_by>>;
  where?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
};


export type subscription_rootv1_new_affordertypeBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affordertypeBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affordertypeBase_order_by>>;
  where?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
};


export type subscription_rootv1_new_affordertypeBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_affordertypeBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_affordertypeBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
};


export type subscription_rootv1_new_brandBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};


export type subscription_rootv1_new_brandBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};


export type subscription_rootv1_new_brandBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_brandBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_brandBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};


export type subscription_rootv1_new_countryBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_countryBase_bool_exp>;
};


export type subscription_rootv1_new_countryBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_countryBase_bool_exp>;
};


export type subscription_rootv1_new_countryBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_countryBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_countryBase_bool_exp>;
};


export type subscription_rootv1_new_languagesBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_languagesBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_languagesBase_order_by>>;
  where?: InputMaybe<v1_new_languagesBase_bool_exp>;
};


export type subscription_rootv1_new_languagesBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_languagesBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_languagesBase_order_by>>;
  where?: InputMaybe<v1_new_languagesBase_bool_exp>;
};


export type subscription_rootv1_new_languagesBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_languagesBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_languagesBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_languagesBase_bool_exp>;
};


export type subscription_rootv1_new_new_brand_new_countryBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_new_brand_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};


export type subscription_rootv1_new_new_brand_new_countryBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_new_brand_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};


export type subscription_rootv1_new_new_brand_new_countryBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_new_brand_new_countryBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_new_brand_new_countryBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};


export type subscription_rootv1_new_paymentmethodBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_paymentmethodBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_paymentmethodBase_order_by>>;
  where?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
};


export type subscription_rootv1_new_paymentmethodBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_paymentmethodBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_paymentmethodBase_order_by>>;
  where?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
};


export type subscription_rootv1_new_paymentmethodBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_paymentmethodBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_paymentmethodBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
};


export type subscription_rootv1_new_receiptBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


export type subscription_rootv1_new_receiptBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


export type subscription_rootv1_new_receiptBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_receiptBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_receiptBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


export type subscription_rootv1_new_regemailBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_regemailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_regemailBase_order_by>>;
  where?: InputMaybe<v1_new_regemailBase_bool_exp>;
};


export type subscription_rootv1_new_regemailBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_regemailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_regemailBase_order_by>>;
  where?: InputMaybe<v1_new_regemailBase_bool_exp>;
};


export type subscription_rootv1_new_regemailBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_regemailBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_regemailBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_regemailBase_bool_exp>;
};


export type subscription_rootv1_new_trackerBaseArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


export type subscription_rootv1_new_trackerBase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


export type subscription_rootv1_new_trackerBase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_new_trackerBase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_new_trackerBase_stream_cursor_input>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


export type subscription_rootv1_processArgs = {
  distinct_on?: InputMaybe<Array<v1_process_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_order_by>>;
  where?: InputMaybe<v1_process_bool_exp>;
};


export type subscription_rootv1_process_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_process_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_order_by>>;
  where?: InputMaybe<v1_process_bool_exp>;
};


export type subscription_rootv1_process_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_process_rateArgs = {
  distinct_on?: InputMaybe<Array<v1_process_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_rate_order_by>>;
  where?: InputMaybe<v1_process_rate_bool_exp>;
};


export type subscription_rootv1_process_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_process_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_rate_order_by>>;
  where?: InputMaybe<v1_process_rate_bool_exp>;
};


export type subscription_rootv1_process_rate_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_process_rate_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_process_rate_stream_cursor_input>>;
  where?: InputMaybe<v1_process_rate_bool_exp>;
};


export type subscription_rootv1_process_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_process_stream_cursor_input>>;
  where?: InputMaybe<v1_process_bool_exp>;
};


export type subscription_rootv1_project_topicArgs = {
  distinct_on?: InputMaybe<Array<v1_project_topic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_project_topic_order_by>>;
  where?: InputMaybe<v1_project_topic_bool_exp>;
};


export type subscription_rootv1_project_topic_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_project_topic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_project_topic_order_by>>;
  where?: InputMaybe<v1_project_topic_bool_exp>;
};


export type subscription_rootv1_project_topic_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootv1_project_topic_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<v1_project_topic_stream_cursor_input>>;
  where?: InputMaybe<v1_project_topic_bool_exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type timestamp_comparison_exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type timestamptz_comparison_exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type uuid_comparison_exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Companies table */
export type v1_AccountBase = {
  __typename?: 'v1_AccountBase';
  AccountId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  AffOrders: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  AffOrders_aggregate: v1_SalesOrderBase_aggregate;
  /** An object relationship */
  AffiliateProgramType?: Maybe<v1_AffiliateProgramType>;
  /** An object relationship */
  CompanyType?: Maybe<v1_CompanyType>;
  /** An object relationship */
  Country?: Maybe<v1_new_countryBase>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  Invoices: Array<v1_InvoiceBase>;
  /** An aggregate relationship */
  Invoices_aggregate: v1_InvoiceBase_aggregate;
  Name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  /** An array relationship */
  PurchaseOrders: Array<v1_new_POBase>;
  /** An aggregate relationship */
  PurchaseOrders_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  Receipts: Array<v1_new_receiptBase>;
  /** An aggregate relationship */
  Receipts_aggregate: v1_new_receiptBase_aggregate;
  /** An array relationship */
  RecurringPayments: Array<v1_RecurringPayments>;
  /** An aggregate relationship */
  RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  /** An object relationship */
  ServiceType?: Maybe<v1_ServiceTypes>;
  StateCode: Scalars['Int']['output'];
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_number_of_ao" */
  comp_number_of_ao?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_number_related_invoices" */
  comp_number_of_invoices?: Maybe<Scalars['Int']['output']>;
  company_contact_name?: Maybe<Scalars['String']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  new_Accounttype?: Maybe<Scalars['Int']['output']>;
  new_Balance?: Maybe<Scalars['numeric']['output']>;
  new_Country?: Maybe<Scalars['uuid']['output']>;
  new_Customer?: Maybe<Scalars['Boolean']['output']>;
  new_IsSupplier?: Maybe<Scalars['Boolean']['output']>;
  new_Manager?: Maybe<Scalars['String']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Payeeinformation?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  new_brandBases: Array<v1_new_brandBase>;
  /** An aggregate relationship */
  new_brandBases_aggregate: v1_new_brandBase_aggregate;
  new_companyaddress?: Maybe<Scalars['String']['output']>;
  new_companyid?: Maybe<Scalars['String']['output']>;
  new_companytype?: Maybe<Scalars['Boolean']['output']>;
  new_contact?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  payment_category_id?: Maybe<Scalars['Int']['output']>;
  registration_number?: Maybe<Scalars['Int']['output']>;
};


/** Companies table */
export type v1_AccountBaseAffOrdersArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** Companies table */
export type v1_AccountBaseAffOrders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** Companies table */
export type v1_AccountBaseInvoicesArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** Companies table */
export type v1_AccountBaseInvoices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** Companies table */
export type v1_AccountBaseOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** Companies table */
export type v1_AccountBaseOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** Companies table */
export type v1_AccountBasePurchaseOrdersArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Companies table */
export type v1_AccountBasePurchaseOrders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Companies table */
export type v1_AccountBaseReceiptsArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** Companies table */
export type v1_AccountBaseReceipts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** Companies table */
export type v1_AccountBaseRecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** Companies table */
export type v1_AccountBaseRecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** Companies table */
export type v1_AccountBasenew_brandBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};


/** Companies table */
export type v1_AccountBasenew_brandBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};

/** aggregated selection of "v1.AccountBase" */
export type v1_AccountBase_aggregate = {
  __typename?: 'v1_AccountBase_aggregate';
  aggregate?: Maybe<v1_AccountBase_aggregate_fields>;
  nodes: Array<v1_AccountBase>;
};

export type v1_AccountBase_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_AccountBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_AccountBase_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_AccountBase_aggregate_bool_exp_count>;
};

export type v1_AccountBase_aggregate_bool_exp_bool_and = {
  arguments: v1_AccountBase_select_column_v1_AccountBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_AccountBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_AccountBase_aggregate_bool_exp_bool_or = {
  arguments: v1_AccountBase_select_column_v1_AccountBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_AccountBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_AccountBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_AccountBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_AccountBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.AccountBase" */
export type v1_AccountBase_aggregate_fields = {
  __typename?: 'v1_AccountBase_aggregate_fields';
  avg?: Maybe<v1_AccountBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_AccountBase_max_fields>;
  min?: Maybe<v1_AccountBase_min_fields>;
  stddev?: Maybe<v1_AccountBase_stddev_fields>;
  stddev_pop?: Maybe<v1_AccountBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_AccountBase_stddev_samp_fields>;
  sum?: Maybe<v1_AccountBase_sum_fields>;
  var_pop?: Maybe<v1_AccountBase_var_pop_fields>;
  var_samp?: Maybe<v1_AccountBase_var_samp_fields>;
  variance?: Maybe<v1_AccountBase_variance_fields>;
};


/** aggregate fields of "v1.AccountBase" */
export type v1_AccountBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_AccountBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.AccountBase" */
export type v1_AccountBase_aggregate_order_by = {
  avg?: InputMaybe<v1_AccountBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_AccountBase_max_order_by>;
  min?: InputMaybe<v1_AccountBase_min_order_by>;
  stddev?: InputMaybe<v1_AccountBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_AccountBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_AccountBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_AccountBase_sum_order_by>;
  var_pop?: InputMaybe<v1_AccountBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_AccountBase_var_samp_order_by>;
  variance?: InputMaybe<v1_AccountBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.AccountBase" */
export type v1_AccountBase_arr_rel_insert_input = {
  data: Array<v1_AccountBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_AccountBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_AccountBase_avg_fields = {
  __typename?: 'v1_AccountBase_avg_fields';
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Accounttype?: Maybe<Scalars['Float']['output']>;
  new_Balance?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  payment_category_id?: Maybe<Scalars['Float']['output']>;
  registration_number?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.AccountBase" */
export type v1_AccountBase_avg_order_by = {
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.AccountBase". All fields are combined with a logical 'AND'. */
export type v1_AccountBase_bool_exp = {
  AccountId?: InputMaybe<uuid_comparison_exp>;
  AffOrders?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  AffOrders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  AffiliateProgramType?: InputMaybe<v1_AffiliateProgramType_bool_exp>;
  CompanyType?: InputMaybe<v1_CompanyType_bool_exp>;
  Country?: InputMaybe<v1_new_countryBase_bool_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  EMailAddress1?: InputMaybe<String_comparison_exp>;
  Fax?: InputMaybe<String_comparison_exp>;
  Invoices?: InputMaybe<v1_InvoiceBase_bool_exp>;
  Invoices_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  Name?: InputMaybe<String_comparison_exp>;
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  PurchaseOrders?: InputMaybe<v1_new_POBase_bool_exp>;
  PurchaseOrders_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  Receipts?: InputMaybe<v1_new_receiptBase_bool_exp>;
  Receipts_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_bool_exp>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_bool_exp>;
  ServiceType?: InputMaybe<v1_ServiceTypes_bool_exp>;
  StateCode?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<Int_comparison_exp>;
  Telephone1?: InputMaybe<String_comparison_exp>;
  _and?: InputMaybe<Array<v1_AccountBase_bool_exp>>;
  _not?: InputMaybe<v1_AccountBase_bool_exp>;
  _or?: InputMaybe<Array<v1_AccountBase_bool_exp>>;
  affiliate_program_type_id?: InputMaybe<Int_comparison_exp>;
  comp_number_of_ao?: InputMaybe<Int_comparison_exp>;
  comp_number_of_invoices?: InputMaybe<Int_comparison_exp>;
  company_contact_name?: InputMaybe<String_comparison_exp>;
  country_id?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_Accounttype?: InputMaybe<Int_comparison_exp>;
  new_Balance?: InputMaybe<numeric_comparison_exp>;
  new_Country?: InputMaybe<uuid_comparison_exp>;
  new_Customer?: InputMaybe<Boolean_comparison_exp>;
  new_IsSupplier?: InputMaybe<Boolean_comparison_exp>;
  new_Manager?: InputMaybe<String_comparison_exp>;
  new_Passportid?: InputMaybe<String_comparison_exp>;
  new_Payeeinformation?: InputMaybe<String_comparison_exp>;
  new_brandBases?: InputMaybe<v1_new_brandBase_bool_exp>;
  new_brandBases_aggregate?: InputMaybe<v1_new_brandBase_aggregate_bool_exp>;
  new_companyaddress?: InputMaybe<String_comparison_exp>;
  new_companyid?: InputMaybe<String_comparison_exp>;
  new_companytype?: InputMaybe<Boolean_comparison_exp>;
  new_contact?: InputMaybe<uuid_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  payment_category_id?: InputMaybe<Int_comparison_exp>;
  registration_number?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.AccountBase" */
export enum v1_AccountBase_constraint {
  /** unique or primary key constraint on columns "id" */
  AccountBase_id_key = 'AccountBase_id_key',
  /** unique or primary key constraint on columns "id" */
  cndx_PrimaryKey_Account = 'cndx_PrimaryKey_Account'
}

/** input type for incrementing numeric columns in table "v1.AccountBase" */
export type v1_AccountBase_inc_input = {
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  affiliate_program_type_id?: InputMaybe<Scalars['Int']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_Accounttype?: InputMaybe<Scalars['Int']['input']>;
  new_Balance?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  payment_category_id?: InputMaybe<Scalars['Int']['input']>;
  registration_number?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.AccountBase" */
export type v1_AccountBase_insert_input = {
  AccountId?: InputMaybe<Scalars['uuid']['input']>;
  AffOrders?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  AffiliateProgramType?: InputMaybe<v1_AffiliateProgramType_obj_rel_insert_input>;
  CompanyType?: InputMaybe<v1_CompanyType_obj_rel_insert_input>;
  Country?: InputMaybe<v1_new_countryBase_obj_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Description?: InputMaybe<Scalars['String']['input']>;
  EMailAddress1?: InputMaybe<Scalars['String']['input']>;
  Fax?: InputMaybe<Scalars['String']['input']>;
  Invoices?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  PurchaseOrders?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  Receipts?: InputMaybe<v1_new_receiptBase_arr_rel_insert_input>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_arr_rel_insert_input>;
  ServiceType?: InputMaybe<v1_ServiceTypes_obj_rel_insert_input>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  Telephone1?: InputMaybe<Scalars['String']['input']>;
  affiliate_program_type_id?: InputMaybe<Scalars['Int']['input']>;
  company_contact_name?: InputMaybe<Scalars['String']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_Accounttype?: InputMaybe<Scalars['Int']['input']>;
  new_Balance?: InputMaybe<Scalars['numeric']['input']>;
  new_Country?: InputMaybe<Scalars['uuid']['input']>;
  new_Customer?: InputMaybe<Scalars['Boolean']['input']>;
  new_IsSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  new_Manager?: InputMaybe<Scalars['String']['input']>;
  new_Passportid?: InputMaybe<Scalars['String']['input']>;
  new_Payeeinformation?: InputMaybe<Scalars['String']['input']>;
  new_brandBases?: InputMaybe<v1_new_brandBase_arr_rel_insert_input>;
  new_companyaddress?: InputMaybe<Scalars['String']['input']>;
  new_companyid?: InputMaybe<Scalars['String']['input']>;
  new_companytype?: InputMaybe<Scalars['Boolean']['input']>;
  new_contact?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  payment_category_id?: InputMaybe<Scalars['Int']['input']>;
  registration_number?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_AccountBase_max_fields = {
  __typename?: 'v1_AccountBase_max_fields';
  AccountId?: Maybe<Scalars['uuid']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Int']['output']>;
  company_contact_name?: Maybe<Scalars['String']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_Accounttype?: Maybe<Scalars['Int']['output']>;
  new_Balance?: Maybe<Scalars['numeric']['output']>;
  new_Country?: Maybe<Scalars['uuid']['output']>;
  new_Manager?: Maybe<Scalars['String']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Payeeinformation?: Maybe<Scalars['String']['output']>;
  new_companyaddress?: Maybe<Scalars['String']['output']>;
  new_companyid?: Maybe<Scalars['String']['output']>;
  new_contact?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  payment_category_id?: Maybe<Scalars['Int']['output']>;
  registration_number?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.AccountBase" */
export type v1_AccountBase_max_order_by = {
  AccountId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  EMailAddress1?: InputMaybe<order_by>;
  Fax?: InputMaybe<order_by>;
  Name?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  Telephone1?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  company_contact_name?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  new_Country?: InputMaybe<order_by>;
  new_Manager?: InputMaybe<order_by>;
  new_Passportid?: InputMaybe<order_by>;
  new_Payeeinformation?: InputMaybe<order_by>;
  new_companyaddress?: InputMaybe<order_by>;
  new_companyid?: InputMaybe<order_by>;
  new_contact?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_AccountBase_min_fields = {
  __typename?: 'v1_AccountBase_min_fields';
  AccountId?: Maybe<Scalars['uuid']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Int']['output']>;
  company_contact_name?: Maybe<Scalars['String']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_Accounttype?: Maybe<Scalars['Int']['output']>;
  new_Balance?: Maybe<Scalars['numeric']['output']>;
  new_Country?: Maybe<Scalars['uuid']['output']>;
  new_Manager?: Maybe<Scalars['String']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Payeeinformation?: Maybe<Scalars['String']['output']>;
  new_companyaddress?: Maybe<Scalars['String']['output']>;
  new_companyid?: Maybe<Scalars['String']['output']>;
  new_contact?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  payment_category_id?: Maybe<Scalars['Int']['output']>;
  registration_number?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.AccountBase" */
export type v1_AccountBase_min_order_by = {
  AccountId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  EMailAddress1?: InputMaybe<order_by>;
  Fax?: InputMaybe<order_by>;
  Name?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  Telephone1?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  company_contact_name?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  new_Country?: InputMaybe<order_by>;
  new_Manager?: InputMaybe<order_by>;
  new_Passportid?: InputMaybe<order_by>;
  new_Payeeinformation?: InputMaybe<order_by>;
  new_companyaddress?: InputMaybe<order_by>;
  new_companyid?: InputMaybe<order_by>;
  new_contact?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.AccountBase" */
export type v1_AccountBase_mutation_response = {
  __typename?: 'v1_AccountBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_AccountBase>;
};

/** input type for inserting object relation for remote table "v1.AccountBase" */
export type v1_AccountBase_obj_rel_insert_input = {
  data: v1_AccountBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_AccountBase_on_conflict>;
};

/** on_conflict condition type for table "v1.AccountBase" */
export type v1_AccountBase_on_conflict = {
  constraint: v1_AccountBase_constraint;
  update_columns?: Array<v1_AccountBase_update_column>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.AccountBase". */
export type v1_AccountBase_order_by = {
  AccountId?: InputMaybe<order_by>;
  AffOrders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  AffiliateProgramType?: InputMaybe<v1_AffiliateProgramType_order_by>;
  CompanyType?: InputMaybe<v1_CompanyType_order_by>;
  Country?: InputMaybe<v1_new_countryBase_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Description?: InputMaybe<order_by>;
  EMailAddress1?: InputMaybe<order_by>;
  Fax?: InputMaybe<order_by>;
  Invoices_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  Name?: InputMaybe<order_by>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  PurchaseOrders_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  Receipts_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_order_by>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_order_by>;
  ServiceType?: InputMaybe<v1_ServiceTypes_order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  Telephone1?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  comp_number_of_ao?: InputMaybe<order_by>;
  comp_number_of_invoices?: InputMaybe<order_by>;
  company_contact_name?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  new_Country?: InputMaybe<order_by>;
  new_Customer?: InputMaybe<order_by>;
  new_IsSupplier?: InputMaybe<order_by>;
  new_Manager?: InputMaybe<order_by>;
  new_Passportid?: InputMaybe<order_by>;
  new_Payeeinformation?: InputMaybe<order_by>;
  new_brandBases_aggregate?: InputMaybe<v1_new_brandBase_aggregate_order_by>;
  new_companyaddress?: InputMaybe<order_by>;
  new_companyid?: InputMaybe<order_by>;
  new_companytype?: InputMaybe<order_by>;
  new_contact?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.AccountBase */
export type v1_AccountBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.AccountBase" */
export enum v1_AccountBase_select_column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  EMailAddress1 = 'EMailAddress1',
  /** column name */
  Fax = 'Fax',
  /** column name */
  Name = 'Name',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  Telephone1 = 'Telephone1',
  /** column name */
  affiliate_program_type_id = 'affiliate_program_type_id',
  /** column name */
  company_contact_name = 'company_contact_name',
  /** column name */
  country_id = 'country_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  id = 'id',
  /** column name */
  new_Accounttype = 'new_Accounttype',
  /** column name */
  new_Balance = 'new_Balance',
  /** column name */
  new_Country = 'new_Country',
  /** column name */
  new_Customer = 'new_Customer',
  /** column name */
  new_IsSupplier = 'new_IsSupplier',
  /** column name */
  new_Manager = 'new_Manager',
  /** column name */
  new_Passportid = 'new_Passportid',
  /** column name */
  new_Payeeinformation = 'new_Payeeinformation',
  /** column name */
  new_companyaddress = 'new_companyaddress',
  /** column name */
  new_companyid = 'new_companyid',
  /** column name */
  new_companytype = 'new_companytype',
  /** column name */
  new_contact = 'new_contact',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  payment_category_id = 'payment_category_id',
  /** column name */
  registration_number = 'registration_number'
}

/** select "v1_AccountBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.AccountBase" */
export enum v1_AccountBase_select_column_v1_AccountBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  new_Customer = 'new_Customer',
  /** column name */
  new_IsSupplier = 'new_IsSupplier',
  /** column name */
  new_companytype = 'new_companytype'
}

/** select "v1_AccountBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.AccountBase" */
export enum v1_AccountBase_select_column_v1_AccountBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  new_Customer = 'new_Customer',
  /** column name */
  new_IsSupplier = 'new_IsSupplier',
  /** column name */
  new_companytype = 'new_companytype'
}

/** input type for updating data in table "v1.AccountBase" */
export type v1_AccountBase_set_input = {
  AccountId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  EMailAddress1?: InputMaybe<Scalars['String']['input']>;
  Fax?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  Telephone1?: InputMaybe<Scalars['String']['input']>;
  affiliate_program_type_id?: InputMaybe<Scalars['Int']['input']>;
  company_contact_name?: InputMaybe<Scalars['String']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_Accounttype?: InputMaybe<Scalars['Int']['input']>;
  new_Balance?: InputMaybe<Scalars['numeric']['input']>;
  new_Country?: InputMaybe<Scalars['uuid']['input']>;
  new_Customer?: InputMaybe<Scalars['Boolean']['input']>;
  new_IsSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  new_Manager?: InputMaybe<Scalars['String']['input']>;
  new_Passportid?: InputMaybe<Scalars['String']['input']>;
  new_Payeeinformation?: InputMaybe<Scalars['String']['input']>;
  new_companyaddress?: InputMaybe<Scalars['String']['input']>;
  new_companyid?: InputMaybe<Scalars['String']['input']>;
  new_companytype?: InputMaybe<Scalars['Boolean']['input']>;
  new_contact?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  payment_category_id?: InputMaybe<Scalars['Int']['input']>;
  registration_number?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_AccountBase_stddev_fields = {
  __typename?: 'v1_AccountBase_stddev_fields';
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Accounttype?: Maybe<Scalars['Float']['output']>;
  new_Balance?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  payment_category_id?: Maybe<Scalars['Float']['output']>;
  registration_number?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.AccountBase" */
export type v1_AccountBase_stddev_order_by = {
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_AccountBase_stddev_pop_fields = {
  __typename?: 'v1_AccountBase_stddev_pop_fields';
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Accounttype?: Maybe<Scalars['Float']['output']>;
  new_Balance?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  payment_category_id?: Maybe<Scalars['Float']['output']>;
  registration_number?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.AccountBase" */
export type v1_AccountBase_stddev_pop_order_by = {
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_AccountBase_stddev_samp_fields = {
  __typename?: 'v1_AccountBase_stddev_samp_fields';
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Accounttype?: Maybe<Scalars['Float']['output']>;
  new_Balance?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  payment_category_id?: Maybe<Scalars['Float']['output']>;
  registration_number?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.AccountBase" */
export type v1_AccountBase_stddev_samp_order_by = {
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_AccountBase" */
export type v1_AccountBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_AccountBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_AccountBase_stream_cursor_value_input = {
  AccountId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  EMailAddress1?: InputMaybe<Scalars['String']['input']>;
  Fax?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  Telephone1?: InputMaybe<Scalars['String']['input']>;
  affiliate_program_type_id?: InputMaybe<Scalars['Int']['input']>;
  company_contact_name?: InputMaybe<Scalars['String']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_Accounttype?: InputMaybe<Scalars['Int']['input']>;
  new_Balance?: InputMaybe<Scalars['numeric']['input']>;
  new_Country?: InputMaybe<Scalars['uuid']['input']>;
  new_Customer?: InputMaybe<Scalars['Boolean']['input']>;
  new_IsSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  new_Manager?: InputMaybe<Scalars['String']['input']>;
  new_Passportid?: InputMaybe<Scalars['String']['input']>;
  new_Payeeinformation?: InputMaybe<Scalars['String']['input']>;
  new_companyaddress?: InputMaybe<Scalars['String']['input']>;
  new_companyid?: InputMaybe<Scalars['String']['input']>;
  new_companytype?: InputMaybe<Scalars['Boolean']['input']>;
  new_contact?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  payment_category_id?: InputMaybe<Scalars['Int']['input']>;
  registration_number?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_AccountBase_sum_fields = {
  __typename?: 'v1_AccountBase_sum_fields';
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Int']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_Accounttype?: Maybe<Scalars['Int']['output']>;
  new_Balance?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  payment_category_id?: Maybe<Scalars['Int']['output']>;
  registration_number?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.AccountBase" */
export type v1_AccountBase_sum_order_by = {
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** update columns of table "v1.AccountBase" */
export enum v1_AccountBase_update_column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  EMailAddress1 = 'EMailAddress1',
  /** column name */
  Fax = 'Fax',
  /** column name */
  Name = 'Name',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  Telephone1 = 'Telephone1',
  /** column name */
  affiliate_program_type_id = 'affiliate_program_type_id',
  /** column name */
  company_contact_name = 'company_contact_name',
  /** column name */
  country_id = 'country_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  id = 'id',
  /** column name */
  new_Accounttype = 'new_Accounttype',
  /** column name */
  new_Balance = 'new_Balance',
  /** column name */
  new_Country = 'new_Country',
  /** column name */
  new_Customer = 'new_Customer',
  /** column name */
  new_IsSupplier = 'new_IsSupplier',
  /** column name */
  new_Manager = 'new_Manager',
  /** column name */
  new_Passportid = 'new_Passportid',
  /** column name */
  new_Payeeinformation = 'new_Payeeinformation',
  /** column name */
  new_companyaddress = 'new_companyaddress',
  /** column name */
  new_companyid = 'new_companyid',
  /** column name */
  new_companytype = 'new_companytype',
  /** column name */
  new_contact = 'new_contact',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  payment_category_id = 'payment_category_id',
  /** column name */
  registration_number = 'registration_number'
}

export type v1_AccountBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_AccountBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_AccountBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_AccountBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_AccountBase_var_pop_fields = {
  __typename?: 'v1_AccountBase_var_pop_fields';
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Accounttype?: Maybe<Scalars['Float']['output']>;
  new_Balance?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  payment_category_id?: Maybe<Scalars['Float']['output']>;
  registration_number?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.AccountBase" */
export type v1_AccountBase_var_pop_order_by = {
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_AccountBase_var_samp_fields = {
  __typename?: 'v1_AccountBase_var_samp_fields';
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Accounttype?: Maybe<Scalars['Float']['output']>;
  new_Balance?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  payment_category_id?: Maybe<Scalars['Float']['output']>;
  registration_number?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.AccountBase" */
export type v1_AccountBase_var_samp_order_by = {
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_AccountBase_variance_fields = {
  __typename?: 'v1_AccountBase_variance_fields';
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_program_type_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Accounttype?: Maybe<Scalars['Float']['output']>;
  new_Balance?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  payment_category_id?: Maybe<Scalars['Float']['output']>;
  registration_number?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.AccountBase" */
export type v1_AccountBase_variance_order_by = {
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_program_type_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Accounttype?: InputMaybe<order_by>;
  new_Balance?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  payment_category_id?: InputMaybe<order_by>;
  registration_number?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Advertisers" */
export type v1_Advertisers = {
  __typename?: 'v1_Advertisers';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  allow_upload_content_portal?: Maybe<Scalars['Boolean']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_AccountActive?: Maybe<Scalars['Boolean']['output']>;
  new_Blacklisted?: Maybe<Scalars['Boolean']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_IsCustomer?: Maybe<Scalars['Boolean']['output']>;
  new_Issupplier?: Maybe<Scalars['Boolean']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_Linksexchanger?: Maybe<Scalars['Boolean']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_Translator?: Maybe<Scalars['Boolean']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_Writer?: Maybe<Scalars['Boolean']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contacetype?: Maybe<Scalars['Boolean']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_inhouse?: Maybe<Scalars['Boolean']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  tag_ids?: Maybe<Scalars['_int4']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "v1.Advertisers" */
export type v1_Advertisers_aggregate = {
  __typename?: 'v1_Advertisers_aggregate';
  aggregate?: Maybe<v1_Advertisers_aggregate_fields>;
  nodes: Array<v1_Advertisers>;
};

/** aggregate fields of "v1.Advertisers" */
export type v1_Advertisers_aggregate_fields = {
  __typename?: 'v1_Advertisers_aggregate_fields';
  avg?: Maybe<v1_Advertisers_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Advertisers_max_fields>;
  min?: Maybe<v1_Advertisers_min_fields>;
  stddev?: Maybe<v1_Advertisers_stddev_fields>;
  stddev_pop?: Maybe<v1_Advertisers_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Advertisers_stddev_samp_fields>;
  sum?: Maybe<v1_Advertisers_sum_fields>;
  var_pop?: Maybe<v1_Advertisers_var_pop_fields>;
  var_samp?: Maybe<v1_Advertisers_var_samp_fields>;
  variance?: Maybe<v1_Advertisers_variance_fields>;
};


/** aggregate fields of "v1.Advertisers" */
export type v1_Advertisers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Advertisers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Advertisers_avg_fields = {
  __typename?: 'v1_Advertisers_avg_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Advertisers". All fields are combined with a logical 'AND'. */
export type v1_Advertisers_bool_exp = {
  ActiveOffersCount?: InputMaybe<Int_comparison_exp>;
  AllPosts?: InputMaybe<Int_comparison_exp>;
  AssistantID?: InputMaybe<Int_comparison_exp>;
  Balance?: InputMaybe<numeric_comparison_exp>;
  BirthDate?: InputMaybe<timestamp_comparison_exp>;
  ContactId?: InputMaybe<uuid_comparison_exp>;
  CountryID?: InputMaybe<Int_comparison_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  EMailAddress1?: InputMaybe<String_comparison_exp>;
  EMailAddress2?: InputMaybe<String_comparison_exp>;
  ExchangeRate?: InputMaybe<numeric_comparison_exp>;
  FinishedOrders?: InputMaybe<Int_comparison_exp>;
  FirstName?: InputMaybe<String_comparison_exp>;
  FullName?: InputMaybe<String_comparison_exp>;
  LastName?: InputMaybe<String_comparison_exp>;
  MobilePhone?: InputMaybe<String_comparison_exp>;
  ModifiedOn?: InputMaybe<timestamp_comparison_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  SoldOffersCount?: InputMaybe<Int_comparison_exp>;
  StateCode?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<Int_comparison_exp>;
  Telephone1?: InputMaybe<String_comparison_exp>;
  TotalOffersCount?: InputMaybe<Int_comparison_exp>;
  TotalOrdersSum?: InputMaybe<Int_comparison_exp>;
  TotalPayoutsSum?: InputMaybe<Int_comparison_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  _and?: InputMaybe<Array<v1_Advertisers_bool_exp>>;
  _not?: InputMaybe<v1_Advertisers_bool_exp>;
  _or?: InputMaybe<Array<v1_Advertisers_bool_exp>>;
  allow_upload_content_portal?: InputMaybe<Boolean_comparison_exp>;
  buzzstream_id?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  language1_id?: InputMaybe<Int_comparison_exp>;
  language_id?: InputMaybe<Int_comparison_exp>;
  monthly_quota?: InputMaybe<numeric_comparison_exp>;
  new_AccountActive?: InputMaybe<Boolean_comparison_exp>;
  new_Blacklisted?: InputMaybe<Boolean_comparison_exp>;
  new_Contacttype?: InputMaybe<Int_comparison_exp>;
  new_CreatedByUser?: InputMaybe<String_comparison_exp>;
  new_Facebook?: InputMaybe<String_comparison_exp>;
  new_FullSeoPage?: InputMaybe<numeric_comparison_exp>;
  new_IsCustomer?: InputMaybe<Boolean_comparison_exp>;
  new_Issupplier?: InputMaybe<Boolean_comparison_exp>;
  new_LastActivity?: InputMaybe<timestamp_comparison_exp>;
  new_LinkedIn?: InputMaybe<String_comparison_exp>;
  new_Linksexchanger?: InputMaybe<Boolean_comparison_exp>;
  new_MainLanguage?: InputMaybe<Int_comparison_exp>;
  new_Offers?: InputMaybe<Int_comparison_exp>;
  new_Passportid?: InputMaybe<String_comparison_exp>;
  new_Password?: InputMaybe<String_comparison_exp>;
  new_PaymentAddress?: InputMaybe<String_comparison_exp>;
  new_Paymentmethod?: InputMaybe<Int_comparison_exp>;
  new_Rating?: InputMaybe<Int_comparison_exp>;
  new_SecondaryLanguage?: InputMaybe<Int_comparison_exp>;
  new_SeoShortPost?: InputMaybe<numeric_comparison_exp>;
  new_Translation?: InputMaybe<numeric_comparison_exp>;
  new_Translator?: InputMaybe<Boolean_comparison_exp>;
  new_UserName?: InputMaybe<String_comparison_exp>;
  new_WebsitePassword?: InputMaybe<String_comparison_exp>;
  new_Writer?: InputMaybe<Boolean_comparison_exp>;
  new_bankaddress?: InputMaybe<String_comparison_exp>;
  new_bankibanaccount?: InputMaybe<String_comparison_exp>;
  new_bankname?: InputMaybe<String_comparison_exp>;
  new_bankswift?: InputMaybe<String_comparison_exp>;
  new_beneficiaryaddress?: InputMaybe<String_comparison_exp>;
  new_beneficiaryname?: InputMaybe<String_comparison_exp>;
  new_contacetype?: InputMaybe<Boolean_comparison_exp>;
  new_contactid?: InputMaybe<String_comparison_exp>;
  new_fullseopagebig?: InputMaybe<numeric_comparison_exp>;
  new_fullseopagejambo?: InputMaybe<numeric_comparison_exp>;
  new_fullseopagelarge?: InputMaybe<numeric_comparison_exp>;
  new_inhouse?: InputMaybe<Boolean_comparison_exp>;
  new_language?: InputMaybe<uuid_comparison_exp>;
  new_language1?: InputMaybe<uuid_comparison_exp>;
  new_longsocialpost?: InputMaybe<numeric_comparison_exp>;
  new_shortsocialpost?: InputMaybe<numeric_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paymentOption?: InputMaybe<Int_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  price_per_word?: InputMaybe<float8_comparison_exp>;
  skill_level?: InputMaybe<numeric_comparison_exp>;
  tag_ids?: InputMaybe<_int4_comparison_exp>;
  upload_price?: InputMaybe<numeric_comparison_exp>;
};

/** aggregate max on columns */
export type v1_Advertisers_max_fields = {
  __typename?: 'v1_Advertisers_max_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type v1_Advertisers_min_fields = {
  __typename?: 'v1_Advertisers_min_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** Ordering options when selecting data from "v1.Advertisers". */
export type v1_Advertisers_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  BirthDate?: InputMaybe<order_by>;
  ContactId?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  EMailAddress1?: InputMaybe<order_by>;
  EMailAddress2?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  FirstName?: InputMaybe<order_by>;
  FullName?: InputMaybe<order_by>;
  LastName?: InputMaybe<order_by>;
  MobilePhone?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  Telephone1?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  allow_upload_content_portal?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language1_id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_AccountActive?: InputMaybe<order_by>;
  new_Blacklisted?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_Facebook?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_IsCustomer?: InputMaybe<order_by>;
  new_Issupplier?: InputMaybe<order_by>;
  new_LastActivity?: InputMaybe<order_by>;
  new_LinkedIn?: InputMaybe<order_by>;
  new_Linksexchanger?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Passportid?: InputMaybe<order_by>;
  new_Password?: InputMaybe<order_by>;
  new_PaymentAddress?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_Translator?: InputMaybe<order_by>;
  new_UserName?: InputMaybe<order_by>;
  new_WebsitePassword?: InputMaybe<order_by>;
  new_Writer?: InputMaybe<order_by>;
  new_bankaddress?: InputMaybe<order_by>;
  new_bankibanaccount?: InputMaybe<order_by>;
  new_bankname?: InputMaybe<order_by>;
  new_bankswift?: InputMaybe<order_by>;
  new_beneficiaryaddress?: InputMaybe<order_by>;
  new_beneficiaryname?: InputMaybe<order_by>;
  new_contacetype?: InputMaybe<order_by>;
  new_contactid?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_inhouse?: InputMaybe<order_by>;
  new_language?: InputMaybe<order_by>;
  new_language1?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  tag_ids?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** select columns of table "v1.Advertisers" */
export enum v1_Advertisers_select_column {
  /** column name */
  ActiveOffersCount = 'ActiveOffersCount',
  /** column name */
  AllPosts = 'AllPosts',
  /** column name */
  AssistantID = 'AssistantID',
  /** column name */
  Balance = 'Balance',
  /** column name */
  BirthDate = 'BirthDate',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CountryID = 'CountryID',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  EMailAddress1 = 'EMailAddress1',
  /** column name */
  EMailAddress2 = 'EMailAddress2',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  FinishedOrders = 'FinishedOrders',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  FullName = 'FullName',
  /** column name */
  LastName = 'LastName',
  /** column name */
  MobilePhone = 'MobilePhone',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  SoldOffersCount = 'SoldOffersCount',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  Telephone1 = 'Telephone1',
  /** column name */
  TotalOffersCount = 'TotalOffersCount',
  /** column name */
  TotalOrdersSum = 'TotalOrdersSum',
  /** column name */
  TotalPayoutsSum = 'TotalPayoutsSum',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  buzzstream_id = 'buzzstream_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  language1_id = 'language1_id',
  /** column name */
  language_id = 'language_id',
  /** column name */
  monthly_quota = 'monthly_quota',
  /** column name */
  new_AccountActive = 'new_AccountActive',
  /** column name */
  new_Blacklisted = 'new_Blacklisted',
  /** column name */
  new_Contacttype = 'new_Contacttype',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_Facebook = 'new_Facebook',
  /** column name */
  new_FullSeoPage = 'new_FullSeoPage',
  /** column name */
  new_IsCustomer = 'new_IsCustomer',
  /** column name */
  new_Issupplier = 'new_Issupplier',
  /** column name */
  new_LastActivity = 'new_LastActivity',
  /** column name */
  new_LinkedIn = 'new_LinkedIn',
  /** column name */
  new_Linksexchanger = 'new_Linksexchanger',
  /** column name */
  new_MainLanguage = 'new_MainLanguage',
  /** column name */
  new_Offers = 'new_Offers',
  /** column name */
  new_Passportid = 'new_Passportid',
  /** column name */
  new_Password = 'new_Password',
  /** column name */
  new_PaymentAddress = 'new_PaymentAddress',
  /** column name */
  new_Paymentmethod = 'new_Paymentmethod',
  /** column name */
  new_Rating = 'new_Rating',
  /** column name */
  new_SecondaryLanguage = 'new_SecondaryLanguage',
  /** column name */
  new_SeoShortPost = 'new_SeoShortPost',
  /** column name */
  new_Translation = 'new_Translation',
  /** column name */
  new_Translator = 'new_Translator',
  /** column name */
  new_UserName = 'new_UserName',
  /** column name */
  new_WebsitePassword = 'new_WebsitePassword',
  /** column name */
  new_Writer = 'new_Writer',
  /** column name */
  new_bankaddress = 'new_bankaddress',
  /** column name */
  new_bankibanaccount = 'new_bankibanaccount',
  /** column name */
  new_bankname = 'new_bankname',
  /** column name */
  new_bankswift = 'new_bankswift',
  /** column name */
  new_beneficiaryaddress = 'new_beneficiaryaddress',
  /** column name */
  new_beneficiaryname = 'new_beneficiaryname',
  /** column name */
  new_contacetype = 'new_contacetype',
  /** column name */
  new_contactid = 'new_contactid',
  /** column name */
  new_fullseopagebig = 'new_fullseopagebig',
  /** column name */
  new_fullseopagejambo = 'new_fullseopagejambo',
  /** column name */
  new_fullseopagelarge = 'new_fullseopagelarge',
  /** column name */
  new_inhouse = 'new_inhouse',
  /** column name */
  new_language = 'new_language',
  /** column name */
  new_language1 = 'new_language1',
  /** column name */
  new_longsocialpost = 'new_longsocialpost',
  /** column name */
  new_shortsocialpost = 'new_shortsocialpost',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentOption = 'paymentOption',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  price_per_word = 'price_per_word',
  /** column name */
  skill_level = 'skill_level',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  upload_price = 'upload_price'
}

/** aggregate stddev on columns */
export type v1_Advertisers_stddev_fields = {
  __typename?: 'v1_Advertisers_stddev_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Advertisers_stddev_pop_fields = {
  __typename?: 'v1_Advertisers_stddev_pop_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Advertisers_stddev_samp_fields = {
  __typename?: 'v1_Advertisers_stddev_samp_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Advertisers" */
export type v1_Advertisers_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Advertisers_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Advertisers_stream_cursor_value_input = {
  ActiveOffersCount?: InputMaybe<Scalars['Int']['input']>;
  AllPosts?: InputMaybe<Scalars['Int']['input']>;
  AssistantID?: InputMaybe<Scalars['Int']['input']>;
  Balance?: InputMaybe<Scalars['numeric']['input']>;
  BirthDate?: InputMaybe<Scalars['timestamp']['input']>;
  ContactId?: InputMaybe<Scalars['uuid']['input']>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  EMailAddress1?: InputMaybe<Scalars['String']['input']>;
  EMailAddress2?: InputMaybe<Scalars['String']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  FinishedOrders?: InputMaybe<Scalars['Int']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FullName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  SoldOffersCount?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  Telephone1?: InputMaybe<Scalars['String']['input']>;
  TotalOffersCount?: InputMaybe<Scalars['Int']['input']>;
  TotalOrdersSum?: InputMaybe<Scalars['Int']['input']>;
  TotalPayoutsSum?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  allow_upload_content_portal?: InputMaybe<Scalars['Boolean']['input']>;
  buzzstream_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language1_id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  monthly_quota?: InputMaybe<Scalars['numeric']['input']>;
  new_AccountActive?: InputMaybe<Scalars['Boolean']['input']>;
  new_Blacklisted?: InputMaybe<Scalars['Boolean']['input']>;
  new_Contacttype?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_Facebook?: InputMaybe<Scalars['String']['input']>;
  new_FullSeoPage?: InputMaybe<Scalars['numeric']['input']>;
  new_IsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  new_Issupplier?: InputMaybe<Scalars['Boolean']['input']>;
  new_LastActivity?: InputMaybe<Scalars['timestamp']['input']>;
  new_LinkedIn?: InputMaybe<Scalars['String']['input']>;
  new_Linksexchanger?: InputMaybe<Scalars['Boolean']['input']>;
  new_MainLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_Offers?: InputMaybe<Scalars['Int']['input']>;
  new_Passportid?: InputMaybe<Scalars['String']['input']>;
  new_Password?: InputMaybe<Scalars['String']['input']>;
  new_PaymentAddress?: InputMaybe<Scalars['String']['input']>;
  new_Paymentmethod?: InputMaybe<Scalars['Int']['input']>;
  new_Rating?: InputMaybe<Scalars['Int']['input']>;
  new_SecondaryLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_SeoShortPost?: InputMaybe<Scalars['numeric']['input']>;
  new_Translation?: InputMaybe<Scalars['numeric']['input']>;
  new_Translator?: InputMaybe<Scalars['Boolean']['input']>;
  new_UserName?: InputMaybe<Scalars['String']['input']>;
  new_WebsitePassword?: InputMaybe<Scalars['String']['input']>;
  new_Writer?: InputMaybe<Scalars['Boolean']['input']>;
  new_bankaddress?: InputMaybe<Scalars['String']['input']>;
  new_bankibanaccount?: InputMaybe<Scalars['String']['input']>;
  new_bankname?: InputMaybe<Scalars['String']['input']>;
  new_bankswift?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryaddress?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryname?: InputMaybe<Scalars['String']['input']>;
  new_contacetype?: InputMaybe<Scalars['Boolean']['input']>;
  new_contactid?: InputMaybe<Scalars['String']['input']>;
  new_fullseopagebig?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagejambo?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagelarge?: InputMaybe<Scalars['numeric']['input']>;
  new_inhouse?: InputMaybe<Scalars['Boolean']['input']>;
  new_language?: InputMaybe<Scalars['uuid']['input']>;
  new_language1?: InputMaybe<Scalars['uuid']['input']>;
  new_longsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  new_shortsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentOption?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  price_per_word?: InputMaybe<Scalars['float8']['input']>;
  skill_level?: InputMaybe<Scalars['numeric']['input']>;
  tag_ids?: InputMaybe<Scalars['_int4']['input']>;
  upload_price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type v1_Advertisers_sum_fields = {
  __typename?: 'v1_Advertisers_sum_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type v1_Advertisers_var_pop_fields = {
  __typename?: 'v1_Advertisers_var_pop_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Advertisers_var_samp_fields = {
  __typename?: 'v1_Advertisers_var_samp_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Advertisers_variance_fields = {
  __typename?: 'v1_Advertisers_variance_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.AffDealType" */
export type v1_AffDealType = {
  __typename?: 'v1_AffDealType';
  /** An array relationship */
  SalesOrderBases: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  SalesOrderBases_aggregate: v1_SalesOrderBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.AffDealType" */
export type v1_AffDealTypeSalesOrderBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.AffDealType" */
export type v1_AffDealTypeSalesOrderBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};

/** aggregated selection of "v1.AffDealType" */
export type v1_AffDealType_aggregate = {
  __typename?: 'v1_AffDealType_aggregate';
  aggregate?: Maybe<v1_AffDealType_aggregate_fields>;
  nodes: Array<v1_AffDealType>;
};

/** aggregate fields of "v1.AffDealType" */
export type v1_AffDealType_aggregate_fields = {
  __typename?: 'v1_AffDealType_aggregate_fields';
  avg?: Maybe<v1_AffDealType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_AffDealType_max_fields>;
  min?: Maybe<v1_AffDealType_min_fields>;
  stddev?: Maybe<v1_AffDealType_stddev_fields>;
  stddev_pop?: Maybe<v1_AffDealType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_AffDealType_stddev_samp_fields>;
  sum?: Maybe<v1_AffDealType_sum_fields>;
  var_pop?: Maybe<v1_AffDealType_var_pop_fields>;
  var_samp?: Maybe<v1_AffDealType_var_samp_fields>;
  variance?: Maybe<v1_AffDealType_variance_fields>;
};


/** aggregate fields of "v1.AffDealType" */
export type v1_AffDealType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_AffDealType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_AffDealType_avg_fields = {
  __typename?: 'v1_AffDealType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.AffDealType". All fields are combined with a logical 'AND'. */
export type v1_AffDealType_bool_exp = {
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_AffDealType_bool_exp>>;
  _not?: InputMaybe<v1_AffDealType_bool_exp>;
  _or?: InputMaybe<Array<v1_AffDealType_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.AffDealType" */
export enum v1_AffDealType_constraint {
  /** unique or primary key constraint on columns "id" */
  AffDealType_pkey = 'AffDealType_pkey'
}

/** input type for incrementing numeric columns in table "v1.AffDealType" */
export type v1_AffDealType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.AffDealType" */
export type v1_AffDealType_insert_input = {
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_AffDealType_max_fields = {
  __typename?: 'v1_AffDealType_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_AffDealType_min_fields = {
  __typename?: 'v1_AffDealType_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.AffDealType" */
export type v1_AffDealType_mutation_response = {
  __typename?: 'v1_AffDealType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_AffDealType>;
};

/** input type for inserting object relation for remote table "v1.AffDealType" */
export type v1_AffDealType_obj_rel_insert_input = {
  data: v1_AffDealType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_AffDealType_on_conflict>;
};

/** on_conflict condition type for table "v1.AffDealType" */
export type v1_AffDealType_on_conflict = {
  constraint: v1_AffDealType_constraint;
  update_columns?: Array<v1_AffDealType_update_column>;
  where?: InputMaybe<v1_AffDealType_bool_exp>;
};

/** Ordering options when selecting data from "v1.AffDealType". */
export type v1_AffDealType_order_by = {
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.AffDealType */
export type v1_AffDealType_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.AffDealType" */
export enum v1_AffDealType_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.AffDealType" */
export type v1_AffDealType_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_AffDealType_stddev_fields = {
  __typename?: 'v1_AffDealType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_AffDealType_stddev_pop_fields = {
  __typename?: 'v1_AffDealType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_AffDealType_stddev_samp_fields = {
  __typename?: 'v1_AffDealType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_AffDealType" */
export type v1_AffDealType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_AffDealType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_AffDealType_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_AffDealType_sum_fields = {
  __typename?: 'v1_AffDealType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.AffDealType" */
export enum v1_AffDealType_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_AffDealType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_AffDealType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_AffDealType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_AffDealType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_AffDealType_var_pop_fields = {
  __typename?: 'v1_AffDealType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_AffDealType_var_samp_fields = {
  __typename?: 'v1_AffDealType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_AffDealType_variance_fields = {
  __typename?: 'v1_AffDealType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Affiliate Order Categories Priority */
export type v1_AffiliateOrderCategoriesPriority = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority';
  /** An array relationship */
  AffiliateOrderCategoriesPriority: Array<v1_new_affordertypeBase>;
  /** An aggregate relationship */
  AffiliateOrderCategoriesPriority_aggregate: v1_new_affordertypeBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** Affiliate Order Categories Priority */
export type v1_AffiliateOrderCategoriesPriorityAffiliateOrderCategoriesPriorityArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affordertypeBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affordertypeBase_order_by>>;
  where?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
};


/** Affiliate Order Categories Priority */
export type v1_AffiliateOrderCategoriesPriorityAffiliateOrderCategoriesPriority_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_affordertypeBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_affordertypeBase_order_by>>;
  where?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
};

/** aggregated selection of "v1.AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_aggregate = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_aggregate';
  aggregate?: Maybe<v1_AffiliateOrderCategoriesPriority_aggregate_fields>;
  nodes: Array<v1_AffiliateOrderCategoriesPriority>;
};

/** aggregate fields of "v1.AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_aggregate_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_aggregate_fields';
  avg?: Maybe<v1_AffiliateOrderCategoriesPriority_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_AffiliateOrderCategoriesPriority_max_fields>;
  min?: Maybe<v1_AffiliateOrderCategoriesPriority_min_fields>;
  stddev?: Maybe<v1_AffiliateOrderCategoriesPriority_stddev_fields>;
  stddev_pop?: Maybe<v1_AffiliateOrderCategoriesPriority_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_AffiliateOrderCategoriesPriority_stddev_samp_fields>;
  sum?: Maybe<v1_AffiliateOrderCategoriesPriority_sum_fields>;
  var_pop?: Maybe<v1_AffiliateOrderCategoriesPriority_var_pop_fields>;
  var_samp?: Maybe<v1_AffiliateOrderCategoriesPriority_var_samp_fields>;
  variance?: Maybe<v1_AffiliateOrderCategoriesPriority_variance_fields>;
};


/** aggregate fields of "v1.AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_AffiliateOrderCategoriesPriority_avg_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.AffiliateOrderCategoriesPriority". All fields are combined with a logical 'AND'. */
export type v1_AffiliateOrderCategoriesPriority_bool_exp = {
  AffiliateOrderCategoriesPriority?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
  AffiliateOrderCategoriesPriority_aggregate?: InputMaybe<v1_new_affordertypeBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_bool_exp>>;
  _not?: InputMaybe<v1_AffiliateOrderCategoriesPriority_bool_exp>;
  _or?: InputMaybe<Array<v1_AffiliateOrderCategoriesPriority_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.AffiliateOrderCategoriesPriority" */
export enum v1_AffiliateOrderCategoriesPriority_constraint {
  /** unique or primary key constraint on columns "id" */
  AffiliateOrderCategoriesPriority_pkey = 'AffiliateOrderCategoriesPriority_pkey',
  /** unique or primary key constraint on columns "value" */
  AffiliateOrderCategoriesPriority_value_key = 'AffiliateOrderCategoriesPriority_value_key'
}

/** input type for incrementing numeric columns in table "v1.AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_insert_input = {
  AffiliateOrderCategoriesPriority?: InputMaybe<v1_new_affordertypeBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_AffiliateOrderCategoriesPriority_max_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_AffiliateOrderCategoriesPriority_min_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_mutation_response = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_AffiliateOrderCategoriesPriority>;
};

/** input type for inserting object relation for remote table "v1.AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_obj_rel_insert_input = {
  data: v1_AffiliateOrderCategoriesPriority_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_AffiliateOrderCategoriesPriority_on_conflict>;
};

/** on_conflict condition type for table "v1.AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_on_conflict = {
  constraint: v1_AffiliateOrderCategoriesPriority_constraint;
  update_columns?: Array<v1_AffiliateOrderCategoriesPriority_update_column>;
  where?: InputMaybe<v1_AffiliateOrderCategoriesPriority_bool_exp>;
};

/** Ordering options when selecting data from "v1.AffiliateOrderCategoriesPriority". */
export type v1_AffiliateOrderCategoriesPriority_order_by = {
  AffiliateOrderCategoriesPriority_aggregate?: InputMaybe<v1_new_affordertypeBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.AffiliateOrderCategoriesPriority */
export type v1_AffiliateOrderCategoriesPriority_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.AffiliateOrderCategoriesPriority" */
export enum v1_AffiliateOrderCategoriesPriority_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_AffiliateOrderCategoriesPriority_stddev_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_AffiliateOrderCategoriesPriority_stddev_pop_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_AffiliateOrderCategoriesPriority_stddev_samp_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_AffiliateOrderCategoriesPriority" */
export type v1_AffiliateOrderCategoriesPriority_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_AffiliateOrderCategoriesPriority_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_AffiliateOrderCategoriesPriority_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_AffiliateOrderCategoriesPriority_sum_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.AffiliateOrderCategoriesPriority" */
export enum v1_AffiliateOrderCategoriesPriority_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_AffiliateOrderCategoriesPriority_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_AffiliateOrderCategoriesPriority_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_AffiliateOrderCategoriesPriority_set_input>;
  /** filter the rows which have to be updated */
  where: v1_AffiliateOrderCategoriesPriority_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_AffiliateOrderCategoriesPriority_var_pop_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_AffiliateOrderCategoriesPriority_var_samp_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_AffiliateOrderCategoriesPriority_variance_fields = {
  __typename?: 'v1_AffiliateOrderCategoriesPriority_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType = {
  __typename?: 'v1_AffiliateProgramType';
  /** An array relationship */
  AccountBases: Array<v1_AccountBase>;
  /** An aggregate relationship */
  AccountBases_aggregate: v1_AccountBase_aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "v1.AffiliateProgramType" */
export type v1_AffiliateProgramTypeAccountBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** columns and relationships of "v1.AffiliateProgramType" */
export type v1_AffiliateProgramTypeAccountBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};

/** aggregated selection of "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType_aggregate = {
  __typename?: 'v1_AffiliateProgramType_aggregate';
  aggregate?: Maybe<v1_AffiliateProgramType_aggregate_fields>;
  nodes: Array<v1_AffiliateProgramType>;
};

/** aggregate fields of "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType_aggregate_fields = {
  __typename?: 'v1_AffiliateProgramType_aggregate_fields';
  avg?: Maybe<v1_AffiliateProgramType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_AffiliateProgramType_max_fields>;
  min?: Maybe<v1_AffiliateProgramType_min_fields>;
  stddev?: Maybe<v1_AffiliateProgramType_stddev_fields>;
  stddev_pop?: Maybe<v1_AffiliateProgramType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_AffiliateProgramType_stddev_samp_fields>;
  sum?: Maybe<v1_AffiliateProgramType_sum_fields>;
  var_pop?: Maybe<v1_AffiliateProgramType_var_pop_fields>;
  var_samp?: Maybe<v1_AffiliateProgramType_var_samp_fields>;
  variance?: Maybe<v1_AffiliateProgramType_variance_fields>;
};


/** aggregate fields of "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_AffiliateProgramType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_AffiliateProgramType_avg_fields = {
  __typename?: 'v1_AffiliateProgramType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.AffiliateProgramType". All fields are combined with a logical 'AND'. */
export type v1_AffiliateProgramType_bool_exp = {
  AccountBases?: InputMaybe<v1_AccountBase_bool_exp>;
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_AffiliateProgramType_bool_exp>>;
  _not?: InputMaybe<v1_AffiliateProgramType_bool_exp>;
  _or?: InputMaybe<Array<v1_AffiliateProgramType_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "v1.AffiliateProgramType" */
export enum v1_AffiliateProgramType_constraint {
  /** unique or primary key constraint on columns "id" */
  AffiliateProgramType_pkey = 'AffiliateProgramType_pkey'
}

/** input type for incrementing numeric columns in table "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType_insert_input = {
  AccountBases?: InputMaybe<v1_AccountBase_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type v1_AffiliateProgramType_max_fields = {
  __typename?: 'v1_AffiliateProgramType_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type v1_AffiliateProgramType_min_fields = {
  __typename?: 'v1_AffiliateProgramType_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType_mutation_response = {
  __typename?: 'v1_AffiliateProgramType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_AffiliateProgramType>;
};

/** input type for inserting object relation for remote table "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType_obj_rel_insert_input = {
  data: v1_AffiliateProgramType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_AffiliateProgramType_on_conflict>;
};

/** on_conflict condition type for table "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType_on_conflict = {
  constraint: v1_AffiliateProgramType_constraint;
  update_columns?: Array<v1_AffiliateProgramType_update_column>;
  where?: InputMaybe<v1_AffiliateProgramType_bool_exp>;
};

/** Ordering options when selecting data from "v1.AffiliateProgramType". */
export type v1_AffiliateProgramType_order_by = {
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.AffiliateProgramType */
export type v1_AffiliateProgramType_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.AffiliateProgramType" */
export enum v1_AffiliateProgramType_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "v1.AffiliateProgramType" */
export type v1_AffiliateProgramType_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type v1_AffiliateProgramType_stddev_fields = {
  __typename?: 'v1_AffiliateProgramType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_AffiliateProgramType_stddev_pop_fields = {
  __typename?: 'v1_AffiliateProgramType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_AffiliateProgramType_stddev_samp_fields = {
  __typename?: 'v1_AffiliateProgramType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_AffiliateProgramType" */
export type v1_AffiliateProgramType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_AffiliateProgramType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_AffiliateProgramType_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type v1_AffiliateProgramType_sum_fields = {
  __typename?: 'v1_AffiliateProgramType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.AffiliateProgramType" */
export enum v1_AffiliateProgramType_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  updated_at = 'updated_at'
}

export type v1_AffiliateProgramType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_AffiliateProgramType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_AffiliateProgramType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_AffiliateProgramType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_AffiliateProgramType_var_pop_fields = {
  __typename?: 'v1_AffiliateProgramType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_AffiliateProgramType_var_samp_fields = {
  __typename?: 'v1_AffiliateProgramType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_AffiliateProgramType_variance_fields = {
  __typename?: 'v1_AffiliateProgramType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** This is for the BMS, for which players can accept the bonus. */
export type v1_AllowedPlayers = {
  __typename?: 'v1_AllowedPlayers';
  /** An array relationship */
  Bonuses: Array<v1_Bonuses>;
  /** An aggregate relationship */
  Bonuses_aggregate: v1_Bonuses_aggregate;
  id: Scalars['Int']['output'];
  new_name: Scalars['bpchar']['output'];
};


/** This is for the BMS, for which players can accept the bonus. */
export type v1_AllowedPlayersBonusesArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** This is for the BMS, for which players can accept the bonus. */
export type v1_AllowedPlayersBonuses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};

/** aggregated selection of "v1.AllowedPlayers" */
export type v1_AllowedPlayers_aggregate = {
  __typename?: 'v1_AllowedPlayers_aggregate';
  aggregate?: Maybe<v1_AllowedPlayers_aggregate_fields>;
  nodes: Array<v1_AllowedPlayers>;
};

/** aggregate fields of "v1.AllowedPlayers" */
export type v1_AllowedPlayers_aggregate_fields = {
  __typename?: 'v1_AllowedPlayers_aggregate_fields';
  avg?: Maybe<v1_AllowedPlayers_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_AllowedPlayers_max_fields>;
  min?: Maybe<v1_AllowedPlayers_min_fields>;
  stddev?: Maybe<v1_AllowedPlayers_stddev_fields>;
  stddev_pop?: Maybe<v1_AllowedPlayers_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_AllowedPlayers_stddev_samp_fields>;
  sum?: Maybe<v1_AllowedPlayers_sum_fields>;
  var_pop?: Maybe<v1_AllowedPlayers_var_pop_fields>;
  var_samp?: Maybe<v1_AllowedPlayers_var_samp_fields>;
  variance?: Maybe<v1_AllowedPlayers_variance_fields>;
};


/** aggregate fields of "v1.AllowedPlayers" */
export type v1_AllowedPlayers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_AllowedPlayers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_AllowedPlayers_avg_fields = {
  __typename?: 'v1_AllowedPlayers_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.AllowedPlayers". All fields are combined with a logical 'AND'. */
export type v1_AllowedPlayers_bool_exp = {
  Bonuses?: InputMaybe<v1_Bonuses_bool_exp>;
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_AllowedPlayers_bool_exp>>;
  _not?: InputMaybe<v1_AllowedPlayers_bool_exp>;
  _or?: InputMaybe<Array<v1_AllowedPlayers_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<bpchar_comparison_exp>;
};

/** unique or primary key constraints on table "v1.AllowedPlayers" */
export enum v1_AllowedPlayers_constraint {
  /** unique or primary key constraint on columns "new_name" */
  AllowedPlayers_new_name_key = 'AllowedPlayers_new_name_key',
  /** unique or primary key constraint on columns "id" */
  AllowedPlayers_pkey = 'AllowedPlayers_pkey'
}

/** input type for incrementing numeric columns in table "v1.AllowedPlayers" */
export type v1_AllowedPlayers_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.AllowedPlayers" */
export type v1_AllowedPlayers_insert_input = {
  Bonuses?: InputMaybe<v1_Bonuses_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type v1_AllowedPlayers_max_fields = {
  __typename?: 'v1_AllowedPlayers_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_AllowedPlayers_min_fields = {
  __typename?: 'v1_AllowedPlayers_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.AllowedPlayers" */
export type v1_AllowedPlayers_mutation_response = {
  __typename?: 'v1_AllowedPlayers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_AllowedPlayers>;
};

/** input type for inserting object relation for remote table "v1.AllowedPlayers" */
export type v1_AllowedPlayers_obj_rel_insert_input = {
  data: v1_AllowedPlayers_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_AllowedPlayers_on_conflict>;
};

/** on_conflict condition type for table "v1.AllowedPlayers" */
export type v1_AllowedPlayers_on_conflict = {
  constraint: v1_AllowedPlayers_constraint;
  update_columns?: Array<v1_AllowedPlayers_update_column>;
  where?: InputMaybe<v1_AllowedPlayers_bool_exp>;
};

/** Ordering options when selecting data from "v1.AllowedPlayers". */
export type v1_AllowedPlayers_order_by = {
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.AllowedPlayers */
export type v1_AllowedPlayers_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.AllowedPlayers" */
export enum v1_AllowedPlayers_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

/** input type for updating data in table "v1.AllowedPlayers" */
export type v1_AllowedPlayers_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type v1_AllowedPlayers_stddev_fields = {
  __typename?: 'v1_AllowedPlayers_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_AllowedPlayers_stddev_pop_fields = {
  __typename?: 'v1_AllowedPlayers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_AllowedPlayers_stddev_samp_fields = {
  __typename?: 'v1_AllowedPlayers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_AllowedPlayers" */
export type v1_AllowedPlayers_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_AllowedPlayers_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_AllowedPlayers_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type v1_AllowedPlayers_sum_fields = {
  __typename?: 'v1_AllowedPlayers_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.AllowedPlayers" */
export enum v1_AllowedPlayers_update_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

export type v1_AllowedPlayers_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_AllowedPlayers_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_AllowedPlayers_set_input>;
  /** filter the rows which have to be updated */
  where: v1_AllowedPlayers_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_AllowedPlayers_var_pop_fields = {
  __typename?: 'v1_AllowedPlayers_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_AllowedPlayers_var_samp_fields = {
  __typename?: 'v1_AllowedPlayers_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_AllowedPlayers_variance_fields = {
  __typename?: 'v1_AllowedPlayers_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.ArticleTones" */
export type v1_ArticleTones = {
  __typename?: 'v1_ArticleTones';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.ArticleTones" */
export type v1_ArticleTonesPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.ArticleTones" */
export type v1_ArticleTonesPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.ArticleTones" */
export type v1_ArticleTones_aggregate = {
  __typename?: 'v1_ArticleTones_aggregate';
  aggregate?: Maybe<v1_ArticleTones_aggregate_fields>;
  nodes: Array<v1_ArticleTones>;
};

/** aggregate fields of "v1.ArticleTones" */
export type v1_ArticleTones_aggregate_fields = {
  __typename?: 'v1_ArticleTones_aggregate_fields';
  avg?: Maybe<v1_ArticleTones_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ArticleTones_max_fields>;
  min?: Maybe<v1_ArticleTones_min_fields>;
  stddev?: Maybe<v1_ArticleTones_stddev_fields>;
  stddev_pop?: Maybe<v1_ArticleTones_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ArticleTones_stddev_samp_fields>;
  sum?: Maybe<v1_ArticleTones_sum_fields>;
  var_pop?: Maybe<v1_ArticleTones_var_pop_fields>;
  var_samp?: Maybe<v1_ArticleTones_var_samp_fields>;
  variance?: Maybe<v1_ArticleTones_variance_fields>;
};


/** aggregate fields of "v1.ArticleTones" */
export type v1_ArticleTones_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ArticleTones_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ArticleTones_avg_fields = {
  __typename?: 'v1_ArticleTones_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ArticleTones". All fields are combined with a logical 'AND'. */
export type v1_ArticleTones_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_ArticleTones_bool_exp>>;
  _not?: InputMaybe<v1_ArticleTones_bool_exp>;
  _or?: InputMaybe<Array<v1_ArticleTones_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ArticleTones" */
export enum v1_ArticleTones_constraint {
  /** unique or primary key constraint on columns "id" */
  ArticleTones_pkey = 'ArticleTones_pkey',
  /** unique or primary key constraint on columns "value" */
  ArticleTones_value_key = 'ArticleTones_value_key'
}

/** input type for incrementing numeric columns in table "v1.ArticleTones" */
export type v1_ArticleTones_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ArticleTones" */
export type v1_ArticleTones_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_ArticleTones_max_fields = {
  __typename?: 'v1_ArticleTones_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_ArticleTones_min_fields = {
  __typename?: 'v1_ArticleTones_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.ArticleTones" */
export type v1_ArticleTones_mutation_response = {
  __typename?: 'v1_ArticleTones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ArticleTones>;
};

/** input type for inserting object relation for remote table "v1.ArticleTones" */
export type v1_ArticleTones_obj_rel_insert_input = {
  data: v1_ArticleTones_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ArticleTones_on_conflict>;
};

/** on_conflict condition type for table "v1.ArticleTones" */
export type v1_ArticleTones_on_conflict = {
  constraint: v1_ArticleTones_constraint;
  update_columns?: Array<v1_ArticleTones_update_column>;
  where?: InputMaybe<v1_ArticleTones_bool_exp>;
};

/** Ordering options when selecting data from "v1.ArticleTones". */
export type v1_ArticleTones_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ArticleTones */
export type v1_ArticleTones_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ArticleTones" */
export enum v1_ArticleTones_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.ArticleTones" */
export type v1_ArticleTones_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_ArticleTones_stddev_fields = {
  __typename?: 'v1_ArticleTones_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ArticleTones_stddev_pop_fields = {
  __typename?: 'v1_ArticleTones_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ArticleTones_stddev_samp_fields = {
  __typename?: 'v1_ArticleTones_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ArticleTones" */
export type v1_ArticleTones_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ArticleTones_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ArticleTones_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ArticleTones_sum_fields = {
  __typename?: 'v1_ArticleTones_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ArticleTones" */
export enum v1_ArticleTones_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_ArticleTones_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ArticleTones_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ArticleTones_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ArticleTones_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ArticleTones_var_pop_fields = {
  __typename?: 'v1_ArticleTones_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ArticleTones_var_samp_fields = {
  __typename?: 'v1_ArticleTones_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ArticleTones_variance_fields = {
  __typename?: 'v1_ArticleTones_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Balance" */
export type v1_Balance = {
  __typename?: 'v1_Balance';
  Activity?: Maybe<Scalars['Int']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  Amount?: Maybe<Scalars['numeric']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  BalanceActivityType?: Maybe<v1_BalanceActivityType>;
  BusinessName: Scalars['String']['output'];
  CVC?: Maybe<Scalars['Int']['output']>;
  CardNumber: Scalars['Int']['output'];
  City?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  Country?: Maybe<v1_new_countryBase>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  CurrencyID?: Maybe<Scalars['Int']['output']>;
  Date: Scalars['timestamptz']['output'];
  ExpirationDate?: Maybe<Scalars['date']['output']>;
  InvoiceEmail: Scalars['String']['output'];
  /** An object relationship */
  Order: v1_MarketPlaceOrder;
  OrderID: Scalars['Int']['output'];
  /** An object relationship */
  Payment: v1_new_paymentmethodBase;
  PaymentMethod: Scalars['Int']['output'];
  /** An object relationship */
  User?: Maybe<v1_ContactBase>;
  UserId?: Maybe<Scalars['Int']['output']>;
  ZipCode?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
};

/** columns and relationships of "v1.BalanceActivityType" */
export type v1_BalanceActivityType = {
  __typename?: 'v1_BalanceActivityType';
  /** An array relationship */
  Balances: Array<v1_Balance>;
  /** An aggregate relationship */
  Balances_aggregate: v1_Balance_aggregate;
  Name: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.BalanceActivityType" */
export type v1_BalanceActivityTypeBalancesArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** columns and relationships of "v1.BalanceActivityType" */
export type v1_BalanceActivityTypeBalances_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};

/** aggregated selection of "v1.BalanceActivityType" */
export type v1_BalanceActivityType_aggregate = {
  __typename?: 'v1_BalanceActivityType_aggregate';
  aggregate?: Maybe<v1_BalanceActivityType_aggregate_fields>;
  nodes: Array<v1_BalanceActivityType>;
};

/** aggregate fields of "v1.BalanceActivityType" */
export type v1_BalanceActivityType_aggregate_fields = {
  __typename?: 'v1_BalanceActivityType_aggregate_fields';
  avg?: Maybe<v1_BalanceActivityType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BalanceActivityType_max_fields>;
  min?: Maybe<v1_BalanceActivityType_min_fields>;
  stddev?: Maybe<v1_BalanceActivityType_stddev_fields>;
  stddev_pop?: Maybe<v1_BalanceActivityType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BalanceActivityType_stddev_samp_fields>;
  sum?: Maybe<v1_BalanceActivityType_sum_fields>;
  var_pop?: Maybe<v1_BalanceActivityType_var_pop_fields>;
  var_samp?: Maybe<v1_BalanceActivityType_var_samp_fields>;
  variance?: Maybe<v1_BalanceActivityType_variance_fields>;
};


/** aggregate fields of "v1.BalanceActivityType" */
export type v1_BalanceActivityType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BalanceActivityType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_BalanceActivityType_avg_fields = {
  __typename?: 'v1_BalanceActivityType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.BalanceActivityType". All fields are combined with a logical 'AND'. */
export type v1_BalanceActivityType_bool_exp = {
  Balances?: InputMaybe<v1_Balance_bool_exp>;
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_bool_exp>;
  Name?: InputMaybe<String_comparison_exp>;
  _and?: InputMaybe<Array<v1_BalanceActivityType_bool_exp>>;
  _not?: InputMaybe<v1_BalanceActivityType_bool_exp>;
  _or?: InputMaybe<Array<v1_BalanceActivityType_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BalanceActivityType" */
export enum v1_BalanceActivityType_constraint {
  /** unique or primary key constraint on columns "Name", "id" */
  ActivityType_pkey = 'ActivityType_pkey',
  /** unique or primary key constraint on columns "id" */
  BalanceActivityType_ID_key = 'BalanceActivityType_ID_key'
}

/** input type for incrementing numeric columns in table "v1.BalanceActivityType" */
export type v1_BalanceActivityType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BalanceActivityType" */
export type v1_BalanceActivityType_insert_input = {
  Balances?: InputMaybe<v1_Balance_arr_rel_insert_input>;
  Name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_BalanceActivityType_max_fields = {
  __typename?: 'v1_BalanceActivityType_max_fields';
  Name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_BalanceActivityType_min_fields = {
  __typename?: 'v1_BalanceActivityType_min_fields';
  Name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.BalanceActivityType" */
export type v1_BalanceActivityType_mutation_response = {
  __typename?: 'v1_BalanceActivityType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BalanceActivityType>;
};

/** input type for inserting object relation for remote table "v1.BalanceActivityType" */
export type v1_BalanceActivityType_obj_rel_insert_input = {
  data: v1_BalanceActivityType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BalanceActivityType_on_conflict>;
};

/** on_conflict condition type for table "v1.BalanceActivityType" */
export type v1_BalanceActivityType_on_conflict = {
  constraint: v1_BalanceActivityType_constraint;
  update_columns?: Array<v1_BalanceActivityType_update_column>;
  where?: InputMaybe<v1_BalanceActivityType_bool_exp>;
};

/** Ordering options when selecting data from "v1.BalanceActivityType". */
export type v1_BalanceActivityType_order_by = {
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_order_by>;
  Name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BalanceActivityType */
export type v1_BalanceActivityType_pk_columns_input = {
  Name: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BalanceActivityType" */
export enum v1_BalanceActivityType_select_column {
  /** column name */
  Name = 'Name',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.BalanceActivityType" */
export type v1_BalanceActivityType_set_input = {
  Name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_BalanceActivityType_stddev_fields = {
  __typename?: 'v1_BalanceActivityType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_BalanceActivityType_stddev_pop_fields = {
  __typename?: 'v1_BalanceActivityType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_BalanceActivityType_stddev_samp_fields = {
  __typename?: 'v1_BalanceActivityType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_BalanceActivityType" */
export type v1_BalanceActivityType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BalanceActivityType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BalanceActivityType_stream_cursor_value_input = {
  Name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_BalanceActivityType_sum_fields = {
  __typename?: 'v1_BalanceActivityType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.BalanceActivityType" */
export enum v1_BalanceActivityType_update_column {
  /** column name */
  Name = 'Name',
  /** column name */
  id = 'id'
}

export type v1_BalanceActivityType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BalanceActivityType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BalanceActivityType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BalanceActivityType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BalanceActivityType_var_pop_fields = {
  __typename?: 'v1_BalanceActivityType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_BalanceActivityType_var_samp_fields = {
  __typename?: 'v1_BalanceActivityType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_BalanceActivityType_variance_fields = {
  __typename?: 'v1_BalanceActivityType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.Balance" */
export type v1_Balance_aggregate = {
  __typename?: 'v1_Balance_aggregate';
  aggregate?: Maybe<v1_Balance_aggregate_fields>;
  nodes: Array<v1_Balance>;
};

export type v1_Balance_aggregate_bool_exp = {
  count?: InputMaybe<v1_Balance_aggregate_bool_exp_count>;
};

export type v1_Balance_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Balance_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Balance_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Balance" */
export type v1_Balance_aggregate_fields = {
  __typename?: 'v1_Balance_aggregate_fields';
  avg?: Maybe<v1_Balance_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Balance_max_fields>;
  min?: Maybe<v1_Balance_min_fields>;
  stddev?: Maybe<v1_Balance_stddev_fields>;
  stddev_pop?: Maybe<v1_Balance_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Balance_stddev_samp_fields>;
  sum?: Maybe<v1_Balance_sum_fields>;
  var_pop?: Maybe<v1_Balance_var_pop_fields>;
  var_samp?: Maybe<v1_Balance_var_samp_fields>;
  variance?: Maybe<v1_Balance_variance_fields>;
};


/** aggregate fields of "v1.Balance" */
export type v1_Balance_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Balance_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Balance" */
export type v1_Balance_aggregate_order_by = {
  avg?: InputMaybe<v1_Balance_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Balance_max_order_by>;
  min?: InputMaybe<v1_Balance_min_order_by>;
  stddev?: InputMaybe<v1_Balance_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Balance_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Balance_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Balance_sum_order_by>;
  var_pop?: InputMaybe<v1_Balance_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Balance_var_samp_order_by>;
  variance?: InputMaybe<v1_Balance_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Balance" */
export type v1_Balance_arr_rel_insert_input = {
  data: Array<v1_Balance_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Balance_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Balance_avg_fields = {
  __typename?: 'v1_Balance_avg_fields';
  Activity?: Maybe<Scalars['Float']['output']>;
  Amount?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CVC?: Maybe<Scalars['Float']['output']>;
  CardNumber?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  CurrencyID?: Maybe<Scalars['Float']['output']>;
  OrderID?: Maybe<Scalars['Float']['output']>;
  PaymentMethod?: Maybe<Scalars['Float']['output']>;
  UserId?: Maybe<Scalars['Float']['output']>;
  ZipCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Balance" */
export type v1_Balance_avg_order_by = {
  Activity?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Balance". All fields are combined with a logical 'AND'. */
export type v1_Balance_bool_exp = {
  Activity?: InputMaybe<Int_comparison_exp>;
  Address?: InputMaybe<String_comparison_exp>;
  Amount?: InputMaybe<numeric_comparison_exp>;
  Balance?: InputMaybe<numeric_comparison_exp>;
  BalanceActivityType?: InputMaybe<v1_BalanceActivityType_bool_exp>;
  BusinessName?: InputMaybe<String_comparison_exp>;
  CVC?: InputMaybe<Int_comparison_exp>;
  CardNumber?: InputMaybe<Int_comparison_exp>;
  City?: InputMaybe<String_comparison_exp>;
  Country?: InputMaybe<v1_new_countryBase_bool_exp>;
  CountryID?: InputMaybe<Int_comparison_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  CurrencyID?: InputMaybe<Int_comparison_exp>;
  Date?: InputMaybe<timestamptz_comparison_exp>;
  ExpirationDate?: InputMaybe<date_comparison_exp>;
  InvoiceEmail?: InputMaybe<String_comparison_exp>;
  Order?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
  OrderID?: InputMaybe<Int_comparison_exp>;
  Payment?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  PaymentMethod?: InputMaybe<Int_comparison_exp>;
  User?: InputMaybe<v1_ContactBase_bool_exp>;
  UserId?: InputMaybe<Int_comparison_exp>;
  ZipCode?: InputMaybe<Int_comparison_exp>;
  _and?: InputMaybe<Array<v1_Balance_bool_exp>>;
  _not?: InputMaybe<v1_Balance_bool_exp>;
  _or?: InputMaybe<Array<v1_Balance_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Balance" */
export enum v1_Balance_constraint {
  /** unique or primary key constraint on columns "id" */
  Balance_pkey = 'Balance_pkey'
}

/** input type for incrementing numeric columns in table "v1.Balance" */
export type v1_Balance_inc_input = {
  Activity?: InputMaybe<Scalars['Int']['input']>;
  Amount?: InputMaybe<Scalars['numeric']['input']>;
  Balance?: InputMaybe<Scalars['numeric']['input']>;
  CVC?: InputMaybe<Scalars['Int']['input']>;
  CardNumber?: InputMaybe<Scalars['Int']['input']>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  CurrencyID?: InputMaybe<Scalars['Int']['input']>;
  OrderID?: InputMaybe<Scalars['Int']['input']>;
  PaymentMethod?: InputMaybe<Scalars['Int']['input']>;
  UserId?: InputMaybe<Scalars['Int']['input']>;
  ZipCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Balance" */
export type v1_Balance_insert_input = {
  Activity?: InputMaybe<Scalars['Int']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  Amount?: InputMaybe<Scalars['numeric']['input']>;
  Balance?: InputMaybe<Scalars['numeric']['input']>;
  BalanceActivityType?: InputMaybe<v1_BalanceActivityType_obj_rel_insert_input>;
  BusinessName?: InputMaybe<Scalars['String']['input']>;
  CVC?: InputMaybe<Scalars['Int']['input']>;
  CardNumber?: InputMaybe<Scalars['Int']['input']>;
  City?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<v1_new_countryBase_obj_rel_insert_input>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  CurrencyID?: InputMaybe<Scalars['Int']['input']>;
  Date?: InputMaybe<Scalars['timestamptz']['input']>;
  ExpirationDate?: InputMaybe<Scalars['date']['input']>;
  InvoiceEmail?: InputMaybe<Scalars['String']['input']>;
  Order?: InputMaybe<v1_MarketPlaceOrder_obj_rel_insert_input>;
  OrderID?: InputMaybe<Scalars['Int']['input']>;
  Payment?: InputMaybe<v1_new_paymentmethodBase_obj_rel_insert_input>;
  PaymentMethod?: InputMaybe<Scalars['Int']['input']>;
  User?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  UserId?: InputMaybe<Scalars['Int']['input']>;
  ZipCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Balance_max_fields = {
  __typename?: 'v1_Balance_max_fields';
  Activity?: Maybe<Scalars['Int']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  Amount?: Maybe<Scalars['numeric']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BusinessName?: Maybe<Scalars['String']['output']>;
  CVC?: Maybe<Scalars['Int']['output']>;
  CardNumber?: Maybe<Scalars['Int']['output']>;
  City?: Maybe<Scalars['String']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CurrencyID?: Maybe<Scalars['Int']['output']>;
  Date?: Maybe<Scalars['timestamptz']['output']>;
  ExpirationDate?: Maybe<Scalars['date']['output']>;
  InvoiceEmail?: Maybe<Scalars['String']['output']>;
  OrderID?: Maybe<Scalars['Int']['output']>;
  PaymentMethod?: Maybe<Scalars['Int']['output']>;
  UserId?: Maybe<Scalars['Int']['output']>;
  ZipCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Balance" */
export type v1_Balance_max_order_by = {
  Activity?: InputMaybe<order_by>;
  Address?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  BusinessName?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  City?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  Date?: InputMaybe<order_by>;
  ExpirationDate?: InputMaybe<order_by>;
  InvoiceEmail?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Balance_min_fields = {
  __typename?: 'v1_Balance_min_fields';
  Activity?: Maybe<Scalars['Int']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  Amount?: Maybe<Scalars['numeric']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BusinessName?: Maybe<Scalars['String']['output']>;
  CVC?: Maybe<Scalars['Int']['output']>;
  CardNumber?: Maybe<Scalars['Int']['output']>;
  City?: Maybe<Scalars['String']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CurrencyID?: Maybe<Scalars['Int']['output']>;
  Date?: Maybe<Scalars['timestamptz']['output']>;
  ExpirationDate?: Maybe<Scalars['date']['output']>;
  InvoiceEmail?: Maybe<Scalars['String']['output']>;
  OrderID?: Maybe<Scalars['Int']['output']>;
  PaymentMethod?: Maybe<Scalars['Int']['output']>;
  UserId?: Maybe<Scalars['Int']['output']>;
  ZipCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Balance" */
export type v1_Balance_min_order_by = {
  Activity?: InputMaybe<order_by>;
  Address?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  BusinessName?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  City?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  Date?: InputMaybe<order_by>;
  ExpirationDate?: InputMaybe<order_by>;
  InvoiceEmail?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Balance" */
export type v1_Balance_mutation_response = {
  __typename?: 'v1_Balance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Balance>;
};

/** on_conflict condition type for table "v1.Balance" */
export type v1_Balance_on_conflict = {
  constraint: v1_Balance_constraint;
  update_columns?: Array<v1_Balance_update_column>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};

/** Ordering options when selecting data from "v1.Balance". */
export type v1_Balance_order_by = {
  Activity?: InputMaybe<order_by>;
  Address?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  BalanceActivityType?: InputMaybe<v1_BalanceActivityType_order_by>;
  BusinessName?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  City?: InputMaybe<order_by>;
  Country?: InputMaybe<v1_new_countryBase_order_by>;
  CountryID?: InputMaybe<order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  CurrencyID?: InputMaybe<order_by>;
  Date?: InputMaybe<order_by>;
  ExpirationDate?: InputMaybe<order_by>;
  InvoiceEmail?: InputMaybe<order_by>;
  Order?: InputMaybe<v1_MarketPlaceOrder_order_by>;
  OrderID?: InputMaybe<order_by>;
  Payment?: InputMaybe<v1_new_paymentmethodBase_order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  User?: InputMaybe<v1_ContactBase_order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Balance */
export type v1_Balance_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Balance" */
export enum v1_Balance_select_column {
  /** column name */
  Activity = 'Activity',
  /** column name */
  Address = 'Address',
  /** column name */
  Amount = 'Amount',
  /** column name */
  Balance = 'Balance',
  /** column name */
  BusinessName = 'BusinessName',
  /** column name */
  CVC = 'CVC',
  /** column name */
  CardNumber = 'CardNumber',
  /** column name */
  City = 'City',
  /** column name */
  CountryID = 'CountryID',
  /** column name */
  CurrencyID = 'CurrencyID',
  /** column name */
  Date = 'Date',
  /** column name */
  ExpirationDate = 'ExpirationDate',
  /** column name */
  InvoiceEmail = 'InvoiceEmail',
  /** column name */
  OrderID = 'OrderID',
  /** column name */
  PaymentMethod = 'PaymentMethod',
  /** column name */
  UserId = 'UserId',
  /** column name */
  ZipCode = 'ZipCode',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.Balance" */
export type v1_Balance_set_input = {
  Activity?: InputMaybe<Scalars['Int']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  Amount?: InputMaybe<Scalars['numeric']['input']>;
  Balance?: InputMaybe<Scalars['numeric']['input']>;
  BusinessName?: InputMaybe<Scalars['String']['input']>;
  CVC?: InputMaybe<Scalars['Int']['input']>;
  CardNumber?: InputMaybe<Scalars['Int']['input']>;
  City?: InputMaybe<Scalars['String']['input']>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  CurrencyID?: InputMaybe<Scalars['Int']['input']>;
  Date?: InputMaybe<Scalars['timestamptz']['input']>;
  ExpirationDate?: InputMaybe<Scalars['date']['input']>;
  InvoiceEmail?: InputMaybe<Scalars['String']['input']>;
  OrderID?: InputMaybe<Scalars['Int']['input']>;
  PaymentMethod?: InputMaybe<Scalars['Int']['input']>;
  UserId?: InputMaybe<Scalars['Int']['input']>;
  ZipCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Balance_stddev_fields = {
  __typename?: 'v1_Balance_stddev_fields';
  Activity?: Maybe<Scalars['Float']['output']>;
  Amount?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CVC?: Maybe<Scalars['Float']['output']>;
  CardNumber?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  CurrencyID?: Maybe<Scalars['Float']['output']>;
  OrderID?: Maybe<Scalars['Float']['output']>;
  PaymentMethod?: Maybe<Scalars['Float']['output']>;
  UserId?: Maybe<Scalars['Float']['output']>;
  ZipCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Balance" */
export type v1_Balance_stddev_order_by = {
  Activity?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Balance_stddev_pop_fields = {
  __typename?: 'v1_Balance_stddev_pop_fields';
  Activity?: Maybe<Scalars['Float']['output']>;
  Amount?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CVC?: Maybe<Scalars['Float']['output']>;
  CardNumber?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  CurrencyID?: Maybe<Scalars['Float']['output']>;
  OrderID?: Maybe<Scalars['Float']['output']>;
  PaymentMethod?: Maybe<Scalars['Float']['output']>;
  UserId?: Maybe<Scalars['Float']['output']>;
  ZipCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Balance" */
export type v1_Balance_stddev_pop_order_by = {
  Activity?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Balance_stddev_samp_fields = {
  __typename?: 'v1_Balance_stddev_samp_fields';
  Activity?: Maybe<Scalars['Float']['output']>;
  Amount?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CVC?: Maybe<Scalars['Float']['output']>;
  CardNumber?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  CurrencyID?: Maybe<Scalars['Float']['output']>;
  OrderID?: Maybe<Scalars['Float']['output']>;
  PaymentMethod?: Maybe<Scalars['Float']['output']>;
  UserId?: Maybe<Scalars['Float']['output']>;
  ZipCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Balance" */
export type v1_Balance_stddev_samp_order_by = {
  Activity?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Balance" */
export type v1_Balance_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Balance_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Balance_stream_cursor_value_input = {
  Activity?: InputMaybe<Scalars['Int']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  Amount?: InputMaybe<Scalars['numeric']['input']>;
  Balance?: InputMaybe<Scalars['numeric']['input']>;
  BusinessName?: InputMaybe<Scalars['String']['input']>;
  CVC?: InputMaybe<Scalars['Int']['input']>;
  CardNumber?: InputMaybe<Scalars['Int']['input']>;
  City?: InputMaybe<Scalars['String']['input']>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  CurrencyID?: InputMaybe<Scalars['Int']['input']>;
  Date?: InputMaybe<Scalars['timestamptz']['input']>;
  ExpirationDate?: InputMaybe<Scalars['date']['input']>;
  InvoiceEmail?: InputMaybe<Scalars['String']['input']>;
  OrderID?: InputMaybe<Scalars['Int']['input']>;
  PaymentMethod?: InputMaybe<Scalars['Int']['input']>;
  UserId?: InputMaybe<Scalars['Int']['input']>;
  ZipCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Balance_sum_fields = {
  __typename?: 'v1_Balance_sum_fields';
  Activity?: Maybe<Scalars['Int']['output']>;
  Amount?: Maybe<Scalars['numeric']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  CVC?: Maybe<Scalars['Int']['output']>;
  CardNumber?: Maybe<Scalars['Int']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CurrencyID?: Maybe<Scalars['Int']['output']>;
  OrderID?: Maybe<Scalars['Int']['output']>;
  PaymentMethod?: Maybe<Scalars['Int']['output']>;
  UserId?: Maybe<Scalars['Int']['output']>;
  ZipCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Balance" */
export type v1_Balance_sum_order_by = {
  Activity?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Balance" */
export enum v1_Balance_update_column {
  /** column name */
  Activity = 'Activity',
  /** column name */
  Address = 'Address',
  /** column name */
  Amount = 'Amount',
  /** column name */
  Balance = 'Balance',
  /** column name */
  BusinessName = 'BusinessName',
  /** column name */
  CVC = 'CVC',
  /** column name */
  CardNumber = 'CardNumber',
  /** column name */
  City = 'City',
  /** column name */
  CountryID = 'CountryID',
  /** column name */
  CurrencyID = 'CurrencyID',
  /** column name */
  Date = 'Date',
  /** column name */
  ExpirationDate = 'ExpirationDate',
  /** column name */
  InvoiceEmail = 'InvoiceEmail',
  /** column name */
  OrderID = 'OrderID',
  /** column name */
  PaymentMethod = 'PaymentMethod',
  /** column name */
  UserId = 'UserId',
  /** column name */
  ZipCode = 'ZipCode',
  /** column name */
  id = 'id'
}

export type v1_Balance_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Balance_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Balance_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Balance_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Balance_var_pop_fields = {
  __typename?: 'v1_Balance_var_pop_fields';
  Activity?: Maybe<Scalars['Float']['output']>;
  Amount?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CVC?: Maybe<Scalars['Float']['output']>;
  CardNumber?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  CurrencyID?: Maybe<Scalars['Float']['output']>;
  OrderID?: Maybe<Scalars['Float']['output']>;
  PaymentMethod?: Maybe<Scalars['Float']['output']>;
  UserId?: Maybe<Scalars['Float']['output']>;
  ZipCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Balance" */
export type v1_Balance_var_pop_order_by = {
  Activity?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Balance_var_samp_fields = {
  __typename?: 'v1_Balance_var_samp_fields';
  Activity?: Maybe<Scalars['Float']['output']>;
  Amount?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CVC?: Maybe<Scalars['Float']['output']>;
  CardNumber?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  CurrencyID?: Maybe<Scalars['Float']['output']>;
  OrderID?: Maybe<Scalars['Float']['output']>;
  PaymentMethod?: Maybe<Scalars['Float']['output']>;
  UserId?: Maybe<Scalars['Float']['output']>;
  ZipCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Balance" */
export type v1_Balance_var_samp_order_by = {
  Activity?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Balance_variance_fields = {
  __typename?: 'v1_Balance_variance_fields';
  Activity?: Maybe<Scalars['Float']['output']>;
  Amount?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CVC?: Maybe<Scalars['Float']['output']>;
  CardNumber?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  CurrencyID?: Maybe<Scalars['Float']['output']>;
  OrderID?: Maybe<Scalars['Float']['output']>;
  PaymentMethod?: Maybe<Scalars['Float']['output']>;
  UserId?: Maybe<Scalars['Float']['output']>;
  ZipCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Balance" */
export type v1_Balance_variance_order_by = {
  Activity?: InputMaybe<order_by>;
  Amount?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CurrencyID?: InputMaybe<order_by>;
  OrderID?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<order_by>;
  UserId?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.BillingInformation" */
export type v1_BillingInformation = {
  __typename?: 'v1_BillingInformation';
  Address?: Maybe<Scalars['bpchar']['output']>;
  BusinessName?: Maybe<Scalars['bpchar']['output']>;
  /** An object relationship */
  City?: Maybe<v1_Cities>;
  CityId?: Maybe<Scalars['Int']['output']>;
  CountryId?: Maybe<Scalars['Int']['output']>;
  InvoiceEmail?: Maybe<Scalars['bpchar']['output']>;
  ZipCode?: Maybe<Scalars['bpchar']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  new_countryBase?: Maybe<v1_new_countryBase>;
};

/** aggregated selection of "v1.BillingInformation" */
export type v1_BillingInformation_aggregate = {
  __typename?: 'v1_BillingInformation_aggregate';
  aggregate?: Maybe<v1_BillingInformation_aggregate_fields>;
  nodes: Array<v1_BillingInformation>;
};

export type v1_BillingInformation_aggregate_bool_exp = {
  count?: InputMaybe<v1_BillingInformation_aggregate_bool_exp_count>;
};

export type v1_BillingInformation_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_BillingInformation_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.BillingInformation" */
export type v1_BillingInformation_aggregate_fields = {
  __typename?: 'v1_BillingInformation_aggregate_fields';
  avg?: Maybe<v1_BillingInformation_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BillingInformation_max_fields>;
  min?: Maybe<v1_BillingInformation_min_fields>;
  stddev?: Maybe<v1_BillingInformation_stddev_fields>;
  stddev_pop?: Maybe<v1_BillingInformation_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BillingInformation_stddev_samp_fields>;
  sum?: Maybe<v1_BillingInformation_sum_fields>;
  var_pop?: Maybe<v1_BillingInformation_var_pop_fields>;
  var_samp?: Maybe<v1_BillingInformation_var_samp_fields>;
  variance?: Maybe<v1_BillingInformation_variance_fields>;
};


/** aggregate fields of "v1.BillingInformation" */
export type v1_BillingInformation_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.BillingInformation" */
export type v1_BillingInformation_aggregate_order_by = {
  avg?: InputMaybe<v1_BillingInformation_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_BillingInformation_max_order_by>;
  min?: InputMaybe<v1_BillingInformation_min_order_by>;
  stddev?: InputMaybe<v1_BillingInformation_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_BillingInformation_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_BillingInformation_stddev_samp_order_by>;
  sum?: InputMaybe<v1_BillingInformation_sum_order_by>;
  var_pop?: InputMaybe<v1_BillingInformation_var_pop_order_by>;
  var_samp?: InputMaybe<v1_BillingInformation_var_samp_order_by>;
  variance?: InputMaybe<v1_BillingInformation_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.BillingInformation" */
export type v1_BillingInformation_arr_rel_insert_input = {
  data: Array<v1_BillingInformation_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BillingInformation_on_conflict>;
};

/** aggregate avg on columns */
export type v1_BillingInformation_avg_fields = {
  __typename?: 'v1_BillingInformation_avg_fields';
  CityId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_avg_order_by = {
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.BillingInformation". All fields are combined with a logical 'AND'. */
export type v1_BillingInformation_bool_exp = {
  Address?: InputMaybe<bpchar_comparison_exp>;
  BusinessName?: InputMaybe<bpchar_comparison_exp>;
  City?: InputMaybe<v1_Cities_bool_exp>;
  CityId?: InputMaybe<Int_comparison_exp>;
  CountryId?: InputMaybe<Int_comparison_exp>;
  InvoiceEmail?: InputMaybe<bpchar_comparison_exp>;
  ZipCode?: InputMaybe<bpchar_comparison_exp>;
  _and?: InputMaybe<Array<v1_BillingInformation_bool_exp>>;
  _not?: InputMaybe<v1_BillingInformation_bool_exp>;
  _or?: InputMaybe<Array<v1_BillingInformation_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_countryBase?: InputMaybe<v1_new_countryBase_bool_exp>;
};

/** unique or primary key constraints on table "v1.BillingInformation" */
export enum v1_BillingInformation_constraint {
  /** unique or primary key constraint on columns "id" */
  BillingInformation_pkey = 'BillingInformation_pkey'
}

/** input type for incrementing numeric columns in table "v1.BillingInformation" */
export type v1_BillingInformation_inc_input = {
  CityId?: InputMaybe<Scalars['Int']['input']>;
  CountryId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BillingInformation" */
export type v1_BillingInformation_insert_input = {
  Address?: InputMaybe<Scalars['bpchar']['input']>;
  BusinessName?: InputMaybe<Scalars['bpchar']['input']>;
  City?: InputMaybe<v1_Cities_obj_rel_insert_input>;
  CityId?: InputMaybe<Scalars['Int']['input']>;
  CountryId?: InputMaybe<Scalars['Int']['input']>;
  InvoiceEmail?: InputMaybe<Scalars['bpchar']['input']>;
  ZipCode?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_countryBase?: InputMaybe<v1_new_countryBase_obj_rel_insert_input>;
};

/** aggregate max on columns */
export type v1_BillingInformation_max_fields = {
  __typename?: 'v1_BillingInformation_max_fields';
  Address?: Maybe<Scalars['bpchar']['output']>;
  BusinessName?: Maybe<Scalars['bpchar']['output']>;
  CityId?: Maybe<Scalars['Int']['output']>;
  CountryId?: Maybe<Scalars['Int']['output']>;
  InvoiceEmail?: Maybe<Scalars['bpchar']['output']>;
  ZipCode?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_max_order_by = {
  Address?: InputMaybe<order_by>;
  BusinessName?: InputMaybe<order_by>;
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  InvoiceEmail?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_BillingInformation_min_fields = {
  __typename?: 'v1_BillingInformation_min_fields';
  Address?: Maybe<Scalars['bpchar']['output']>;
  BusinessName?: Maybe<Scalars['bpchar']['output']>;
  CityId?: Maybe<Scalars['Int']['output']>;
  CountryId?: Maybe<Scalars['Int']['output']>;
  InvoiceEmail?: Maybe<Scalars['bpchar']['output']>;
  ZipCode?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_min_order_by = {
  Address?: InputMaybe<order_by>;
  BusinessName?: InputMaybe<order_by>;
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  InvoiceEmail?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.BillingInformation" */
export type v1_BillingInformation_mutation_response = {
  __typename?: 'v1_BillingInformation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BillingInformation>;
};

/** on_conflict condition type for table "v1.BillingInformation" */
export type v1_BillingInformation_on_conflict = {
  constraint: v1_BillingInformation_constraint;
  update_columns?: Array<v1_BillingInformation_update_column>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};

/** Ordering options when selecting data from "v1.BillingInformation". */
export type v1_BillingInformation_order_by = {
  Address?: InputMaybe<order_by>;
  BusinessName?: InputMaybe<order_by>;
  City?: InputMaybe<v1_Cities_order_by>;
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  InvoiceEmail?: InputMaybe<order_by>;
  ZipCode?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_countryBase?: InputMaybe<v1_new_countryBase_order_by>;
};

/** primary key columns input for table: v1.BillingInformation */
export type v1_BillingInformation_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BillingInformation" */
export enum v1_BillingInformation_select_column {
  /** column name */
  Address = 'Address',
  /** column name */
  BusinessName = 'BusinessName',
  /** column name */
  CityId = 'CityId',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  InvoiceEmail = 'InvoiceEmail',
  /** column name */
  ZipCode = 'ZipCode',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.BillingInformation" */
export type v1_BillingInformation_set_input = {
  Address?: InputMaybe<Scalars['bpchar']['input']>;
  BusinessName?: InputMaybe<Scalars['bpchar']['input']>;
  CityId?: InputMaybe<Scalars['Int']['input']>;
  CountryId?: InputMaybe<Scalars['Int']['input']>;
  InvoiceEmail?: InputMaybe<Scalars['bpchar']['input']>;
  ZipCode?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_BillingInformation_stddev_fields = {
  __typename?: 'v1_BillingInformation_stddev_fields';
  CityId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_stddev_order_by = {
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_BillingInformation_stddev_pop_fields = {
  __typename?: 'v1_BillingInformation_stddev_pop_fields';
  CityId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_stddev_pop_order_by = {
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_BillingInformation_stddev_samp_fields = {
  __typename?: 'v1_BillingInformation_stddev_samp_fields';
  CityId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_stddev_samp_order_by = {
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_BillingInformation" */
export type v1_BillingInformation_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BillingInformation_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BillingInformation_stream_cursor_value_input = {
  Address?: InputMaybe<Scalars['bpchar']['input']>;
  BusinessName?: InputMaybe<Scalars['bpchar']['input']>;
  CityId?: InputMaybe<Scalars['Int']['input']>;
  CountryId?: InputMaybe<Scalars['Int']['input']>;
  InvoiceEmail?: InputMaybe<Scalars['bpchar']['input']>;
  ZipCode?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_BillingInformation_sum_fields = {
  __typename?: 'v1_BillingInformation_sum_fields';
  CityId?: Maybe<Scalars['Int']['output']>;
  CountryId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_sum_order_by = {
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.BillingInformation" */
export enum v1_BillingInformation_update_column {
  /** column name */
  Address = 'Address',
  /** column name */
  BusinessName = 'BusinessName',
  /** column name */
  CityId = 'CityId',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  InvoiceEmail = 'InvoiceEmail',
  /** column name */
  ZipCode = 'ZipCode',
  /** column name */
  id = 'id'
}

export type v1_BillingInformation_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BillingInformation_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BillingInformation_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BillingInformation_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BillingInformation_var_pop_fields = {
  __typename?: 'v1_BillingInformation_var_pop_fields';
  CityId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_var_pop_order_by = {
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_BillingInformation_var_samp_fields = {
  __typename?: 'v1_BillingInformation_var_samp_fields';
  CityId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_var_samp_order_by = {
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_BillingInformation_variance_fields = {
  __typename?: 'v1_BillingInformation_variance_fields';
  CityId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.BillingInformation" */
export type v1_BillingInformation_variance_order_by = {
  CityId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.BillingMethod" */
export type v1_BillingMethod = {
  __typename?: 'v1_BillingMethod';
  BankAccountName?: Maybe<Scalars['bpchar']['output']>;
  BankAccountNumber?: Maybe<Scalars['bpchar']['output']>;
  CVC?: Maybe<Scalars['Int']['output']>;
  CardNumber?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  ContactBase?: Maybe<v1_ContactBase>;
  ContactBaseId?: Maybe<Scalars['Int']['output']>;
  CountryId?: Maybe<Scalars['Int']['output']>;
  CurrencyId?: Maybe<Scalars['Int']['output']>;
  ExpirationDate?: Maybe<Scalars['date']['output']>;
  /** This is for the person's name. */
  FullName?: Maybe<Scalars['bpchar']['output']>;
  /** This is for the name of the billing method - example: VISA. */
  Name?: Maybe<Scalars['bpchar']['output']>;
  PayPalAccountName?: Maybe<Scalars['bpchar']['output']>;
  PayPalEmail?: Maybe<Scalars['String']['output']>;
  PhoneNumber?: Maybe<Scalars['String']['output']>;
  RoutingNumber?: Maybe<Scalars['bpchar']['output']>;
  /** An object relationship */
  TransactionCurrencyBase?: Maybe<v1_TransactionCurrencyBase>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  new_countryBase?: Maybe<v1_new_countryBase>;
};

/** aggregated selection of "v1.BillingMethod" */
export type v1_BillingMethod_aggregate = {
  __typename?: 'v1_BillingMethod_aggregate';
  aggregate?: Maybe<v1_BillingMethod_aggregate_fields>;
  nodes: Array<v1_BillingMethod>;
};

export type v1_BillingMethod_aggregate_bool_exp = {
  count?: InputMaybe<v1_BillingMethod_aggregate_bool_exp_count>;
};

export type v1_BillingMethod_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_BillingMethod_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.BillingMethod" */
export type v1_BillingMethod_aggregate_fields = {
  __typename?: 'v1_BillingMethod_aggregate_fields';
  avg?: Maybe<v1_BillingMethod_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BillingMethod_max_fields>;
  min?: Maybe<v1_BillingMethod_min_fields>;
  stddev?: Maybe<v1_BillingMethod_stddev_fields>;
  stddev_pop?: Maybe<v1_BillingMethod_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BillingMethod_stddev_samp_fields>;
  sum?: Maybe<v1_BillingMethod_sum_fields>;
  var_pop?: Maybe<v1_BillingMethod_var_pop_fields>;
  var_samp?: Maybe<v1_BillingMethod_var_samp_fields>;
  variance?: Maybe<v1_BillingMethod_variance_fields>;
};


/** aggregate fields of "v1.BillingMethod" */
export type v1_BillingMethod_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.BillingMethod" */
export type v1_BillingMethod_aggregate_order_by = {
  avg?: InputMaybe<v1_BillingMethod_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_BillingMethod_max_order_by>;
  min?: InputMaybe<v1_BillingMethod_min_order_by>;
  stddev?: InputMaybe<v1_BillingMethod_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_BillingMethod_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_BillingMethod_stddev_samp_order_by>;
  sum?: InputMaybe<v1_BillingMethod_sum_order_by>;
  var_pop?: InputMaybe<v1_BillingMethod_var_pop_order_by>;
  var_samp?: InputMaybe<v1_BillingMethod_var_samp_order_by>;
  variance?: InputMaybe<v1_BillingMethod_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.BillingMethod" */
export type v1_BillingMethod_arr_rel_insert_input = {
  data: Array<v1_BillingMethod_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BillingMethod_on_conflict>;
};

/** aggregate avg on columns */
export type v1_BillingMethod_avg_fields = {
  __typename?: 'v1_BillingMethod_avg_fields';
  CVC?: Maybe<Scalars['Float']['output']>;
  ContactBaseId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  CurrencyId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_avg_order_by = {
  CVC?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.BillingMethod". All fields are combined with a logical 'AND'. */
export type v1_BillingMethod_bool_exp = {
  BankAccountName?: InputMaybe<bpchar_comparison_exp>;
  BankAccountNumber?: InputMaybe<bpchar_comparison_exp>;
  CVC?: InputMaybe<Int_comparison_exp>;
  CardNumber?: InputMaybe<String_comparison_exp>;
  ContactBase?: InputMaybe<v1_ContactBase_bool_exp>;
  ContactBaseId?: InputMaybe<Int_comparison_exp>;
  CountryId?: InputMaybe<Int_comparison_exp>;
  CurrencyId?: InputMaybe<Int_comparison_exp>;
  ExpirationDate?: InputMaybe<date_comparison_exp>;
  FullName?: InputMaybe<bpchar_comparison_exp>;
  Name?: InputMaybe<bpchar_comparison_exp>;
  PayPalAccountName?: InputMaybe<bpchar_comparison_exp>;
  PayPalEmail?: InputMaybe<String_comparison_exp>;
  PhoneNumber?: InputMaybe<String_comparison_exp>;
  RoutingNumber?: InputMaybe<bpchar_comparison_exp>;
  TransactionCurrencyBase?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  _and?: InputMaybe<Array<v1_BillingMethod_bool_exp>>;
  _not?: InputMaybe<v1_BillingMethod_bool_exp>;
  _or?: InputMaybe<Array<v1_BillingMethod_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_countryBase?: InputMaybe<v1_new_countryBase_bool_exp>;
};

/** unique or primary key constraints on table "v1.BillingMethod" */
export enum v1_BillingMethod_constraint {
  /** unique or primary key constraint on columns "id" */
  BillingMethod_pkey = 'BillingMethod_pkey'
}

/** input type for incrementing numeric columns in table "v1.BillingMethod" */
export type v1_BillingMethod_inc_input = {
  CVC?: InputMaybe<Scalars['Int']['input']>;
  ContactBaseId?: InputMaybe<Scalars['Int']['input']>;
  CountryId?: InputMaybe<Scalars['Int']['input']>;
  CurrencyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BillingMethod" */
export type v1_BillingMethod_insert_input = {
  BankAccountName?: InputMaybe<Scalars['bpchar']['input']>;
  BankAccountNumber?: InputMaybe<Scalars['bpchar']['input']>;
  CVC?: InputMaybe<Scalars['Int']['input']>;
  CardNumber?: InputMaybe<Scalars['String']['input']>;
  ContactBase?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  ContactBaseId?: InputMaybe<Scalars['Int']['input']>;
  CountryId?: InputMaybe<Scalars['Int']['input']>;
  CurrencyId?: InputMaybe<Scalars['Int']['input']>;
  ExpirationDate?: InputMaybe<Scalars['date']['input']>;
  /** This is for the person's name. */
  FullName?: InputMaybe<Scalars['bpchar']['input']>;
  /** This is for the name of the billing method - example: VISA. */
  Name?: InputMaybe<Scalars['bpchar']['input']>;
  PayPalAccountName?: InputMaybe<Scalars['bpchar']['input']>;
  PayPalEmail?: InputMaybe<Scalars['String']['input']>;
  PhoneNumber?: InputMaybe<Scalars['String']['input']>;
  RoutingNumber?: InputMaybe<Scalars['bpchar']['input']>;
  TransactionCurrencyBase?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_countryBase?: InputMaybe<v1_new_countryBase_obj_rel_insert_input>;
};

/** aggregate max on columns */
export type v1_BillingMethod_max_fields = {
  __typename?: 'v1_BillingMethod_max_fields';
  BankAccountName?: Maybe<Scalars['bpchar']['output']>;
  BankAccountNumber?: Maybe<Scalars['bpchar']['output']>;
  CVC?: Maybe<Scalars['Int']['output']>;
  CardNumber?: Maybe<Scalars['String']['output']>;
  ContactBaseId?: Maybe<Scalars['Int']['output']>;
  CountryId?: Maybe<Scalars['Int']['output']>;
  CurrencyId?: Maybe<Scalars['Int']['output']>;
  ExpirationDate?: Maybe<Scalars['date']['output']>;
  /** This is for the person's name. */
  FullName?: Maybe<Scalars['bpchar']['output']>;
  /** This is for the name of the billing method - example: VISA. */
  Name?: Maybe<Scalars['bpchar']['output']>;
  PayPalAccountName?: Maybe<Scalars['bpchar']['output']>;
  PayPalEmail?: Maybe<Scalars['String']['output']>;
  PhoneNumber?: Maybe<Scalars['String']['output']>;
  RoutingNumber?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_max_order_by = {
  BankAccountName?: InputMaybe<order_by>;
  BankAccountNumber?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  ExpirationDate?: InputMaybe<order_by>;
  /** This is for the person's name. */
  FullName?: InputMaybe<order_by>;
  /** This is for the name of the billing method - example: VISA. */
  Name?: InputMaybe<order_by>;
  PayPalAccountName?: InputMaybe<order_by>;
  PayPalEmail?: InputMaybe<order_by>;
  PhoneNumber?: InputMaybe<order_by>;
  RoutingNumber?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_BillingMethod_min_fields = {
  __typename?: 'v1_BillingMethod_min_fields';
  BankAccountName?: Maybe<Scalars['bpchar']['output']>;
  BankAccountNumber?: Maybe<Scalars['bpchar']['output']>;
  CVC?: Maybe<Scalars['Int']['output']>;
  CardNumber?: Maybe<Scalars['String']['output']>;
  ContactBaseId?: Maybe<Scalars['Int']['output']>;
  CountryId?: Maybe<Scalars['Int']['output']>;
  CurrencyId?: Maybe<Scalars['Int']['output']>;
  ExpirationDate?: Maybe<Scalars['date']['output']>;
  /** This is for the person's name. */
  FullName?: Maybe<Scalars['bpchar']['output']>;
  /** This is for the name of the billing method - example: VISA. */
  Name?: Maybe<Scalars['bpchar']['output']>;
  PayPalAccountName?: Maybe<Scalars['bpchar']['output']>;
  PayPalEmail?: Maybe<Scalars['String']['output']>;
  PhoneNumber?: Maybe<Scalars['String']['output']>;
  RoutingNumber?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_min_order_by = {
  BankAccountName?: InputMaybe<order_by>;
  BankAccountNumber?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  ExpirationDate?: InputMaybe<order_by>;
  /** This is for the person's name. */
  FullName?: InputMaybe<order_by>;
  /** This is for the name of the billing method - example: VISA. */
  Name?: InputMaybe<order_by>;
  PayPalAccountName?: InputMaybe<order_by>;
  PayPalEmail?: InputMaybe<order_by>;
  PhoneNumber?: InputMaybe<order_by>;
  RoutingNumber?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.BillingMethod" */
export type v1_BillingMethod_mutation_response = {
  __typename?: 'v1_BillingMethod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BillingMethod>;
};

/** on_conflict condition type for table "v1.BillingMethod" */
export type v1_BillingMethod_on_conflict = {
  constraint: v1_BillingMethod_constraint;
  update_columns?: Array<v1_BillingMethod_update_column>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};

/** Ordering options when selecting data from "v1.BillingMethod". */
export type v1_BillingMethod_order_by = {
  BankAccountName?: InputMaybe<order_by>;
  BankAccountNumber?: InputMaybe<order_by>;
  CVC?: InputMaybe<order_by>;
  CardNumber?: InputMaybe<order_by>;
  ContactBase?: InputMaybe<v1_ContactBase_order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  ExpirationDate?: InputMaybe<order_by>;
  FullName?: InputMaybe<order_by>;
  Name?: InputMaybe<order_by>;
  PayPalAccountName?: InputMaybe<order_by>;
  PayPalEmail?: InputMaybe<order_by>;
  PhoneNumber?: InputMaybe<order_by>;
  RoutingNumber?: InputMaybe<order_by>;
  TransactionCurrencyBase?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  id?: InputMaybe<order_by>;
  new_countryBase?: InputMaybe<v1_new_countryBase_order_by>;
};

/** primary key columns input for table: v1.BillingMethod */
export type v1_BillingMethod_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BillingMethod" */
export enum v1_BillingMethod_select_column {
  /** column name */
  BankAccountName = 'BankAccountName',
  /** column name */
  BankAccountNumber = 'BankAccountNumber',
  /** column name */
  CVC = 'CVC',
  /** column name */
  CardNumber = 'CardNumber',
  /** column name */
  ContactBaseId = 'ContactBaseId',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  CurrencyId = 'CurrencyId',
  /** column name */
  ExpirationDate = 'ExpirationDate',
  /** column name */
  FullName = 'FullName',
  /** column name */
  Name = 'Name',
  /** column name */
  PayPalAccountName = 'PayPalAccountName',
  /** column name */
  PayPalEmail = 'PayPalEmail',
  /** column name */
  PhoneNumber = 'PhoneNumber',
  /** column name */
  RoutingNumber = 'RoutingNumber',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.BillingMethod" */
export type v1_BillingMethod_set_input = {
  BankAccountName?: InputMaybe<Scalars['bpchar']['input']>;
  BankAccountNumber?: InputMaybe<Scalars['bpchar']['input']>;
  CVC?: InputMaybe<Scalars['Int']['input']>;
  CardNumber?: InputMaybe<Scalars['String']['input']>;
  ContactBaseId?: InputMaybe<Scalars['Int']['input']>;
  CountryId?: InputMaybe<Scalars['Int']['input']>;
  CurrencyId?: InputMaybe<Scalars['Int']['input']>;
  ExpirationDate?: InputMaybe<Scalars['date']['input']>;
  /** This is for the person's name. */
  FullName?: InputMaybe<Scalars['bpchar']['input']>;
  /** This is for the name of the billing method - example: VISA. */
  Name?: InputMaybe<Scalars['bpchar']['input']>;
  PayPalAccountName?: InputMaybe<Scalars['bpchar']['input']>;
  PayPalEmail?: InputMaybe<Scalars['String']['input']>;
  PhoneNumber?: InputMaybe<Scalars['String']['input']>;
  RoutingNumber?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_BillingMethod_stddev_fields = {
  __typename?: 'v1_BillingMethod_stddev_fields';
  CVC?: Maybe<Scalars['Float']['output']>;
  ContactBaseId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  CurrencyId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_stddev_order_by = {
  CVC?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_BillingMethod_stddev_pop_fields = {
  __typename?: 'v1_BillingMethod_stddev_pop_fields';
  CVC?: Maybe<Scalars['Float']['output']>;
  ContactBaseId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  CurrencyId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_stddev_pop_order_by = {
  CVC?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_BillingMethod_stddev_samp_fields = {
  __typename?: 'v1_BillingMethod_stddev_samp_fields';
  CVC?: Maybe<Scalars['Float']['output']>;
  ContactBaseId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  CurrencyId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_stddev_samp_order_by = {
  CVC?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_BillingMethod" */
export type v1_BillingMethod_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BillingMethod_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BillingMethod_stream_cursor_value_input = {
  BankAccountName?: InputMaybe<Scalars['bpchar']['input']>;
  BankAccountNumber?: InputMaybe<Scalars['bpchar']['input']>;
  CVC?: InputMaybe<Scalars['Int']['input']>;
  CardNumber?: InputMaybe<Scalars['String']['input']>;
  ContactBaseId?: InputMaybe<Scalars['Int']['input']>;
  CountryId?: InputMaybe<Scalars['Int']['input']>;
  CurrencyId?: InputMaybe<Scalars['Int']['input']>;
  ExpirationDate?: InputMaybe<Scalars['date']['input']>;
  /** This is for the person's name. */
  FullName?: InputMaybe<Scalars['bpchar']['input']>;
  /** This is for the name of the billing method - example: VISA. */
  Name?: InputMaybe<Scalars['bpchar']['input']>;
  PayPalAccountName?: InputMaybe<Scalars['bpchar']['input']>;
  PayPalEmail?: InputMaybe<Scalars['String']['input']>;
  PhoneNumber?: InputMaybe<Scalars['String']['input']>;
  RoutingNumber?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_BillingMethod_sum_fields = {
  __typename?: 'v1_BillingMethod_sum_fields';
  CVC?: Maybe<Scalars['Int']['output']>;
  ContactBaseId?: Maybe<Scalars['Int']['output']>;
  CountryId?: Maybe<Scalars['Int']['output']>;
  CurrencyId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_sum_order_by = {
  CVC?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.BillingMethod" */
export enum v1_BillingMethod_update_column {
  /** column name */
  BankAccountName = 'BankAccountName',
  /** column name */
  BankAccountNumber = 'BankAccountNumber',
  /** column name */
  CVC = 'CVC',
  /** column name */
  CardNumber = 'CardNumber',
  /** column name */
  ContactBaseId = 'ContactBaseId',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  CurrencyId = 'CurrencyId',
  /** column name */
  ExpirationDate = 'ExpirationDate',
  /** column name */
  FullName = 'FullName',
  /** column name */
  Name = 'Name',
  /** column name */
  PayPalAccountName = 'PayPalAccountName',
  /** column name */
  PayPalEmail = 'PayPalEmail',
  /** column name */
  PhoneNumber = 'PhoneNumber',
  /** column name */
  RoutingNumber = 'RoutingNumber',
  /** column name */
  id = 'id'
}

export type v1_BillingMethod_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BillingMethod_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BillingMethod_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BillingMethod_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BillingMethod_var_pop_fields = {
  __typename?: 'v1_BillingMethod_var_pop_fields';
  CVC?: Maybe<Scalars['Float']['output']>;
  ContactBaseId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  CurrencyId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_var_pop_order_by = {
  CVC?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_BillingMethod_var_samp_fields = {
  __typename?: 'v1_BillingMethod_var_samp_fields';
  CVC?: Maybe<Scalars['Float']['output']>;
  ContactBaseId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  CurrencyId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_var_samp_order_by = {
  CVC?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_BillingMethod_variance_fields = {
  __typename?: 'v1_BillingMethod_variance_fields';
  CVC?: Maybe<Scalars['Float']['output']>;
  ContactBaseId?: Maybe<Scalars['Float']['output']>;
  CountryId?: Maybe<Scalars['Float']['output']>;
  CurrencyId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.BillingMethod" */
export type v1_BillingMethod_variance_order_by = {
  CVC?: InputMaybe<order_by>;
  ContactBaseId?: InputMaybe<order_by>;
  CountryId?: InputMaybe<order_by>;
  CurrencyId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** This is for the BMS, to know where the bonus came from. */
export type v1_BonusSource = {
  __typename?: 'v1_BonusSource';
  id: Scalars['Int']['output'];
  new_name: Scalars['bpchar']['output'];
};

/** aggregated selection of "v1.BonusSource" */
export type v1_BonusSource_aggregate = {
  __typename?: 'v1_BonusSource_aggregate';
  aggregate?: Maybe<v1_BonusSource_aggregate_fields>;
  nodes: Array<v1_BonusSource>;
};

/** aggregate fields of "v1.BonusSource" */
export type v1_BonusSource_aggregate_fields = {
  __typename?: 'v1_BonusSource_aggregate_fields';
  avg?: Maybe<v1_BonusSource_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BonusSource_max_fields>;
  min?: Maybe<v1_BonusSource_min_fields>;
  stddev?: Maybe<v1_BonusSource_stddev_fields>;
  stddev_pop?: Maybe<v1_BonusSource_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BonusSource_stddev_samp_fields>;
  sum?: Maybe<v1_BonusSource_sum_fields>;
  var_pop?: Maybe<v1_BonusSource_var_pop_fields>;
  var_samp?: Maybe<v1_BonusSource_var_samp_fields>;
  variance?: Maybe<v1_BonusSource_variance_fields>;
};


/** aggregate fields of "v1.BonusSource" */
export type v1_BonusSource_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BonusSource_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_BonusSource_avg_fields = {
  __typename?: 'v1_BonusSource_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.BonusSource". All fields are combined with a logical 'AND'. */
export type v1_BonusSource_bool_exp = {
  _and?: InputMaybe<Array<v1_BonusSource_bool_exp>>;
  _not?: InputMaybe<v1_BonusSource_bool_exp>;
  _or?: InputMaybe<Array<v1_BonusSource_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<bpchar_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BonusSource" */
export enum v1_BonusSource_constraint {
  /** unique or primary key constraint on columns "new_name" */
  BonusSource_new_name_key = 'BonusSource_new_name_key',
  /** unique or primary key constraint on columns "id" */
  BonusSource_pkey = 'BonusSource_pkey'
}

/** input type for incrementing numeric columns in table "v1.BonusSource" */
export type v1_BonusSource_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BonusSource" */
export type v1_BonusSource_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type v1_BonusSource_max_fields = {
  __typename?: 'v1_BonusSource_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_BonusSource_min_fields = {
  __typename?: 'v1_BonusSource_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.BonusSource" */
export type v1_BonusSource_mutation_response = {
  __typename?: 'v1_BonusSource_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BonusSource>;
};

/** on_conflict condition type for table "v1.BonusSource" */
export type v1_BonusSource_on_conflict = {
  constraint: v1_BonusSource_constraint;
  update_columns?: Array<v1_BonusSource_update_column>;
  where?: InputMaybe<v1_BonusSource_bool_exp>;
};

/** Ordering options when selecting data from "v1.BonusSource". */
export type v1_BonusSource_order_by = {
  id?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BonusSource */
export type v1_BonusSource_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BonusSource" */
export enum v1_BonusSource_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

/** input type for updating data in table "v1.BonusSource" */
export type v1_BonusSource_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type v1_BonusSource_stddev_fields = {
  __typename?: 'v1_BonusSource_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_BonusSource_stddev_pop_fields = {
  __typename?: 'v1_BonusSource_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_BonusSource_stddev_samp_fields = {
  __typename?: 'v1_BonusSource_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_BonusSource" */
export type v1_BonusSource_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BonusSource_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BonusSource_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type v1_BonusSource_sum_fields = {
  __typename?: 'v1_BonusSource_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.BonusSource" */
export enum v1_BonusSource_update_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

export type v1_BonusSource_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BonusSource_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BonusSource_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BonusSource_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BonusSource_var_pop_fields = {
  __typename?: 'v1_BonusSource_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_BonusSource_var_samp_fields = {
  __typename?: 'v1_BonusSource_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_BonusSource_variance_fields = {
  __typename?: 'v1_BonusSource_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.BonusStatus" */
export type v1_BonusStatus = {
  __typename?: 'v1_BonusStatus';
  /** An array relationship */
  Bonuses: Array<v1_Bonuses>;
  /** An aggregate relationship */
  Bonuses_aggregate: v1_Bonuses_aggregate;
  id: Scalars['Int']['output'];
  new_name?: Maybe<Scalars['bpchar']['output']>;
};


/** columns and relationships of "v1.BonusStatus" */
export type v1_BonusStatusBonusesArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** columns and relationships of "v1.BonusStatus" */
export type v1_BonusStatusBonuses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};

/** aggregated selection of "v1.BonusStatus" */
export type v1_BonusStatus_aggregate = {
  __typename?: 'v1_BonusStatus_aggregate';
  aggregate?: Maybe<v1_BonusStatus_aggregate_fields>;
  nodes: Array<v1_BonusStatus>;
};

/** aggregate fields of "v1.BonusStatus" */
export type v1_BonusStatus_aggregate_fields = {
  __typename?: 'v1_BonusStatus_aggregate_fields';
  avg?: Maybe<v1_BonusStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BonusStatus_max_fields>;
  min?: Maybe<v1_BonusStatus_min_fields>;
  stddev?: Maybe<v1_BonusStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_BonusStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BonusStatus_stddev_samp_fields>;
  sum?: Maybe<v1_BonusStatus_sum_fields>;
  var_pop?: Maybe<v1_BonusStatus_var_pop_fields>;
  var_samp?: Maybe<v1_BonusStatus_var_samp_fields>;
  variance?: Maybe<v1_BonusStatus_variance_fields>;
};


/** aggregate fields of "v1.BonusStatus" */
export type v1_BonusStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BonusStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_BonusStatus_avg_fields = {
  __typename?: 'v1_BonusStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.BonusStatus". All fields are combined with a logical 'AND'. */
export type v1_BonusStatus_bool_exp = {
  Bonuses?: InputMaybe<v1_Bonuses_bool_exp>;
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_BonusStatus_bool_exp>>;
  _not?: InputMaybe<v1_BonusStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_BonusStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<bpchar_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BonusStatus" */
export enum v1_BonusStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  BonusStatus_pkey = 'BonusStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.BonusStatus" */
export type v1_BonusStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BonusStatus" */
export type v1_BonusStatus_insert_input = {
  Bonuses?: InputMaybe<v1_Bonuses_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type v1_BonusStatus_max_fields = {
  __typename?: 'v1_BonusStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_BonusStatus_min_fields = {
  __typename?: 'v1_BonusStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.BonusStatus" */
export type v1_BonusStatus_mutation_response = {
  __typename?: 'v1_BonusStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BonusStatus>;
};

/** input type for inserting object relation for remote table "v1.BonusStatus" */
export type v1_BonusStatus_obj_rel_insert_input = {
  data: v1_BonusStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BonusStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.BonusStatus" */
export type v1_BonusStatus_on_conflict = {
  constraint: v1_BonusStatus_constraint;
  update_columns?: Array<v1_BonusStatus_update_column>;
  where?: InputMaybe<v1_BonusStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.BonusStatus". */
export type v1_BonusStatus_order_by = {
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BonusStatus */
export type v1_BonusStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BonusStatus" */
export enum v1_BonusStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

/** input type for updating data in table "v1.BonusStatus" */
export type v1_BonusStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type v1_BonusStatus_stddev_fields = {
  __typename?: 'v1_BonusStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_BonusStatus_stddev_pop_fields = {
  __typename?: 'v1_BonusStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_BonusStatus_stddev_samp_fields = {
  __typename?: 'v1_BonusStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_BonusStatus" */
export type v1_BonusStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BonusStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BonusStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type v1_BonusStatus_sum_fields = {
  __typename?: 'v1_BonusStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.BonusStatus" */
export enum v1_BonusStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

export type v1_BonusStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BonusStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BonusStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BonusStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BonusStatus_var_pop_fields = {
  __typename?: 'v1_BonusStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_BonusStatus_var_samp_fields = {
  __typename?: 'v1_BonusStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_BonusStatus_variance_fields = {
  __typename?: 'v1_BonusStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.BonusSubmitter" */
export type v1_BonusSubmitter = {
  __typename?: 'v1_BonusSubmitter';
  /** An array relationship */
  Bonuses: Array<v1_Bonuses>;
  /** An aggregate relationship */
  Bonuses_aggregate: v1_Bonuses_aggregate;
  /** An object relationship */
  Project?: Maybe<v1_Project>;
  /** An array relationship */
  PurchaseOrders: Array<v1_new_POBase>;
  /** An aggregate relationship */
  PurchaseOrders_aggregate: v1_new_POBase_aggregate;
  created_on?: Maybe<Scalars['timestamptz']['output']>;
  crypto_wallet?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_paid_on?: Maybe<Scalars['timestamptz']['output']>;
  nhost_id: Scalars['uuid']['output'];
  project_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.BonusSubmitter" */
export type v1_BonusSubmitterBonusesArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** columns and relationships of "v1.BonusSubmitter" */
export type v1_BonusSubmitterBonuses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** columns and relationships of "v1.BonusSubmitter" */
export type v1_BonusSubmitterPurchaseOrdersArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** columns and relationships of "v1.BonusSubmitter" */
export type v1_BonusSubmitterPurchaseOrders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};

/** aggregated selection of "v1.BonusSubmitter" */
export type v1_BonusSubmitter_aggregate = {
  __typename?: 'v1_BonusSubmitter_aggregate';
  aggregate?: Maybe<v1_BonusSubmitter_aggregate_fields>;
  nodes: Array<v1_BonusSubmitter>;
};

export type v1_BonusSubmitter_aggregate_bool_exp = {
  count?: InputMaybe<v1_BonusSubmitter_aggregate_bool_exp_count>;
};

export type v1_BonusSubmitter_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_BonusSubmitter_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_BonusSubmitter_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.BonusSubmitter" */
export type v1_BonusSubmitter_aggregate_fields = {
  __typename?: 'v1_BonusSubmitter_aggregate_fields';
  avg?: Maybe<v1_BonusSubmitter_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BonusSubmitter_max_fields>;
  min?: Maybe<v1_BonusSubmitter_min_fields>;
  stddev?: Maybe<v1_BonusSubmitter_stddev_fields>;
  stddev_pop?: Maybe<v1_BonusSubmitter_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BonusSubmitter_stddev_samp_fields>;
  sum?: Maybe<v1_BonusSubmitter_sum_fields>;
  var_pop?: Maybe<v1_BonusSubmitter_var_pop_fields>;
  var_samp?: Maybe<v1_BonusSubmitter_var_samp_fields>;
  variance?: Maybe<v1_BonusSubmitter_variance_fields>;
};


/** aggregate fields of "v1.BonusSubmitter" */
export type v1_BonusSubmitter_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BonusSubmitter_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_aggregate_order_by = {
  avg?: InputMaybe<v1_BonusSubmitter_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_BonusSubmitter_max_order_by>;
  min?: InputMaybe<v1_BonusSubmitter_min_order_by>;
  stddev?: InputMaybe<v1_BonusSubmitter_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_BonusSubmitter_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_BonusSubmitter_stddev_samp_order_by>;
  sum?: InputMaybe<v1_BonusSubmitter_sum_order_by>;
  var_pop?: InputMaybe<v1_BonusSubmitter_var_pop_order_by>;
  var_samp?: InputMaybe<v1_BonusSubmitter_var_samp_order_by>;
  variance?: InputMaybe<v1_BonusSubmitter_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_arr_rel_insert_input = {
  data: Array<v1_BonusSubmitter_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BonusSubmitter_on_conflict>;
};

/** aggregate avg on columns */
export type v1_BonusSubmitter_avg_fields = {
  __typename?: 'v1_BonusSubmitter_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_avg_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.BonusSubmitter". All fields are combined with a logical 'AND'. */
export type v1_BonusSubmitter_bool_exp = {
  Bonuses?: InputMaybe<v1_Bonuses_bool_exp>;
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_bool_exp>;
  Project?: InputMaybe<v1_Project_bool_exp>;
  PurchaseOrders?: InputMaybe<v1_new_POBase_bool_exp>;
  PurchaseOrders_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_BonusSubmitter_bool_exp>>;
  _not?: InputMaybe<v1_BonusSubmitter_bool_exp>;
  _or?: InputMaybe<Array<v1_BonusSubmitter_bool_exp>>;
  created_on?: InputMaybe<timestamptz_comparison_exp>;
  crypto_wallet?: InputMaybe<String_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  last_paid_on?: InputMaybe<timestamptz_comparison_exp>;
  nhost_id?: InputMaybe<uuid_comparison_exp>;
  project_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BonusSubmitter" */
export enum v1_BonusSubmitter_constraint {
  /** unique or primary key constraint on columns "nhost_id" */
  BonusSubmitter_nhost_id_key = 'BonusSubmitter_nhost_id_key',
  /** unique or primary key constraint on columns "id" */
  BonusSubmitter_pkey = 'BonusSubmitter_pkey'
}

/** input type for incrementing numeric columns in table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_insert_input = {
  Bonuses?: InputMaybe<v1_Bonuses_arr_rel_insert_input>;
  Project?: InputMaybe<v1_Project_obj_rel_insert_input>;
  PurchaseOrders?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  created_on?: InputMaybe<Scalars['timestamptz']['input']>;
  crypto_wallet?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_paid_on?: InputMaybe<Scalars['timestamptz']['input']>;
  nhost_id?: InputMaybe<Scalars['uuid']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_BonusSubmitter_max_fields = {
  __typename?: 'v1_BonusSubmitter_max_fields';
  created_on?: Maybe<Scalars['timestamptz']['output']>;
  crypto_wallet?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_paid_on?: Maybe<Scalars['timestamptz']['output']>;
  nhost_id?: Maybe<Scalars['uuid']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_max_order_by = {
  created_on?: InputMaybe<order_by>;
  crypto_wallet?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_paid_on?: InputMaybe<order_by>;
  nhost_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_BonusSubmitter_min_fields = {
  __typename?: 'v1_BonusSubmitter_min_fields';
  created_on?: Maybe<Scalars['timestamptz']['output']>;
  crypto_wallet?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_paid_on?: Maybe<Scalars['timestamptz']['output']>;
  nhost_id?: Maybe<Scalars['uuid']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_min_order_by = {
  created_on?: InputMaybe<order_by>;
  crypto_wallet?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_paid_on?: InputMaybe<order_by>;
  nhost_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_mutation_response = {
  __typename?: 'v1_BonusSubmitter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BonusSubmitter>;
};

/** input type for inserting object relation for remote table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_obj_rel_insert_input = {
  data: v1_BonusSubmitter_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BonusSubmitter_on_conflict>;
};

/** on_conflict condition type for table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_on_conflict = {
  constraint: v1_BonusSubmitter_constraint;
  update_columns?: Array<v1_BonusSubmitter_update_column>;
  where?: InputMaybe<v1_BonusSubmitter_bool_exp>;
};

/** Ordering options when selecting data from "v1.BonusSubmitter". */
export type v1_BonusSubmitter_order_by = {
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_order_by>;
  Project?: InputMaybe<v1_Project_order_by>;
  PurchaseOrders_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  created_on?: InputMaybe<order_by>;
  crypto_wallet?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_paid_on?: InputMaybe<order_by>;
  nhost_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BonusSubmitter */
export type v1_BonusSubmitter_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BonusSubmitter" */
export enum v1_BonusSubmitter_select_column {
  /** column name */
  created_on = 'created_on',
  /** column name */
  crypto_wallet = 'crypto_wallet',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id',
  /** column name */
  last_paid_on = 'last_paid_on',
  /** column name */
  nhost_id = 'nhost_id',
  /** column name */
  project_id = 'project_id'
}

/** input type for updating data in table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_set_input = {
  created_on?: InputMaybe<Scalars['timestamptz']['input']>;
  crypto_wallet?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_paid_on?: InputMaybe<Scalars['timestamptz']['input']>;
  nhost_id?: InputMaybe<Scalars['uuid']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_BonusSubmitter_stddev_fields = {
  __typename?: 'v1_BonusSubmitter_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_stddev_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_BonusSubmitter_stddev_pop_fields = {
  __typename?: 'v1_BonusSubmitter_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_BonusSubmitter_stddev_samp_fields = {
  __typename?: 'v1_BonusSubmitter_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_BonusSubmitter" */
export type v1_BonusSubmitter_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BonusSubmitter_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BonusSubmitter_stream_cursor_value_input = {
  created_on?: InputMaybe<Scalars['timestamptz']['input']>;
  crypto_wallet?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_paid_on?: InputMaybe<Scalars['timestamptz']['input']>;
  nhost_id?: InputMaybe<Scalars['uuid']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_BonusSubmitter_sum_fields = {
  __typename?: 'v1_BonusSubmitter_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_sum_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.BonusSubmitter" */
export enum v1_BonusSubmitter_update_column {
  /** column name */
  created_on = 'created_on',
  /** column name */
  crypto_wallet = 'crypto_wallet',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id',
  /** column name */
  last_paid_on = 'last_paid_on',
  /** column name */
  nhost_id = 'nhost_id',
  /** column name */
  project_id = 'project_id'
}

export type v1_BonusSubmitter_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BonusSubmitter_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BonusSubmitter_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BonusSubmitter_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BonusSubmitter_var_pop_fields = {
  __typename?: 'v1_BonusSubmitter_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_BonusSubmitter_var_samp_fields = {
  __typename?: 'v1_BonusSubmitter_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_BonusSubmitter_variance_fields = {
  __typename?: 'v1_BonusSubmitter_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.BonusSubmitter" */
export type v1_BonusSubmitter_variance_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.BonusTypes" */
export type v1_BonusTypes = {
  __typename?: 'v1_BonusTypes';
  /** An array relationship */
  BonusesToTypes: Array<v1_BonusesToTypes>;
  /** An aggregate relationship */
  BonusesToTypes_aggregate: v1_BonusesToTypes_aggregate;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "v1.BonusTypes" */
export type v1_BonusTypesBonusesToTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToTypes_order_by>>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};


/** columns and relationships of "v1.BonusTypes" */
export type v1_BonusTypesBonusesToTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToTypes_order_by>>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};

/** aggregated selection of "v1.BonusTypes" */
export type v1_BonusTypes_aggregate = {
  __typename?: 'v1_BonusTypes_aggregate';
  aggregate?: Maybe<v1_BonusTypes_aggregate_fields>;
  nodes: Array<v1_BonusTypes>;
};

/** aggregate fields of "v1.BonusTypes" */
export type v1_BonusTypes_aggregate_fields = {
  __typename?: 'v1_BonusTypes_aggregate_fields';
  avg?: Maybe<v1_BonusTypes_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BonusTypes_max_fields>;
  min?: Maybe<v1_BonusTypes_min_fields>;
  stddev?: Maybe<v1_BonusTypes_stddev_fields>;
  stddev_pop?: Maybe<v1_BonusTypes_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BonusTypes_stddev_samp_fields>;
  sum?: Maybe<v1_BonusTypes_sum_fields>;
  var_pop?: Maybe<v1_BonusTypes_var_pop_fields>;
  var_samp?: Maybe<v1_BonusTypes_var_samp_fields>;
  variance?: Maybe<v1_BonusTypes_variance_fields>;
};


/** aggregate fields of "v1.BonusTypes" */
export type v1_BonusTypes_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BonusTypes_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_BonusTypes_avg_fields = {
  __typename?: 'v1_BonusTypes_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.BonusTypes". All fields are combined with a logical 'AND'. */
export type v1_BonusTypes_bool_exp = {
  BonusesToTypes?: InputMaybe<v1_BonusesToTypes_bool_exp>;
  BonusesToTypes_aggregate?: InputMaybe<v1_BonusesToTypes_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_BonusTypes_bool_exp>>;
  _not?: InputMaybe<v1_BonusTypes_bool_exp>;
  _or?: InputMaybe<Array<v1_BonusTypes_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BonusTypes" */
export enum v1_BonusTypes_constraint {
  /** unique or primary key constraint on columns "id" */
  BonusesType_pkey = 'BonusesType_pkey'
}

/** input type for incrementing numeric columns in table "v1.BonusTypes" */
export type v1_BonusTypes_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BonusTypes" */
export type v1_BonusTypes_insert_input = {
  BonusesToTypes?: InputMaybe<v1_BonusesToTypes_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_BonusTypes_max_fields = {
  __typename?: 'v1_BonusTypes_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_BonusTypes_min_fields = {
  __typename?: 'v1_BonusTypes_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.BonusTypes" */
export type v1_BonusTypes_mutation_response = {
  __typename?: 'v1_BonusTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BonusTypes>;
};

/** input type for inserting object relation for remote table "v1.BonusTypes" */
export type v1_BonusTypes_obj_rel_insert_input = {
  data: v1_BonusTypes_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BonusTypes_on_conflict>;
};

/** on_conflict condition type for table "v1.BonusTypes" */
export type v1_BonusTypes_on_conflict = {
  constraint: v1_BonusTypes_constraint;
  update_columns?: Array<v1_BonusTypes_update_column>;
  where?: InputMaybe<v1_BonusTypes_bool_exp>;
};

/** Ordering options when selecting data from "v1.BonusTypes". */
export type v1_BonusTypes_order_by = {
  BonusesToTypes_aggregate?: InputMaybe<v1_BonusesToTypes_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BonusTypes */
export type v1_BonusTypes_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BonusTypes" */
export enum v1_BonusTypes_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.BonusTypes" */
export type v1_BonusTypes_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_BonusTypes_stddev_fields = {
  __typename?: 'v1_BonusTypes_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_BonusTypes_stddev_pop_fields = {
  __typename?: 'v1_BonusTypes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_BonusTypes_stddev_samp_fields = {
  __typename?: 'v1_BonusTypes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_BonusTypes" */
export type v1_BonusTypes_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BonusTypes_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BonusTypes_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_BonusTypes_sum_fields = {
  __typename?: 'v1_BonusTypes_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.BonusTypes" */
export enum v1_BonusTypes_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_BonusTypes_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BonusTypes_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BonusTypes_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BonusTypes_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BonusTypes_var_pop_fields = {
  __typename?: 'v1_BonusTypes_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_BonusTypes_var_samp_fields = {
  __typename?: 'v1_BonusTypes_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_BonusTypes_variance_fields = {
  __typename?: 'v1_BonusTypes_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** This is the bonus table for the bonus management system (BMS). */
export type v1_Bonuses = {
  __typename?: 'v1_Bonuses';
  /** An object relationship */
  AllowedPlayer?: Maybe<v1_AllowedPlayers>;
  /** An object relationship */
  BonusStatus: v1_BonusStatus;
  /** An object relationship */
  BonusSubmitter?: Maybe<v1_BonusSubmitter>;
  /** An array relationship */
  BonusesToCountries: Array<v1_BonusesToCountries>;
  /** An aggregate relationship */
  BonusesToCountries_aggregate: v1_BonusesToCountries_aggregate;
  /** An array relationship */
  BonusesToTypes: Array<v1_BonusesToTypes>;
  /** An aggregate relationship */
  BonusesToTypes_aggregate: v1_BonusesToTypes_aggregate;
  /** An object relationship */
  MarketType?: Maybe<v1_MarketType>;
  /** An object relationship */
  Project?: Maybe<v1_Project>;
  /** An object relationship */
  User?: Maybe<v1_SystemUserBase>;
  additional_info?: Maybe<Scalars['String']['output']>;
  allowed_player_id?: Maybe<Scalars['Int']['output']>;
  bonus_condition: Scalars['String']['output'];
  bonus_info: Scalars['String']['output'];
  bonusstatus_id: Scalars['Int']['output'];
  brand_id?: Maybe<Scalars['Int']['output']>;
  cms_brand_name?: Maybe<Scalars['String']['output']>;
  cms_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['date']['output']>;
  dep_req_text?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  expires_on?: Maybe<Scalars['date']['output']>;
  id: Scalars['Int']['output'];
  is_exclusive: Scalars['Boolean']['output'];
  launched_on?: Maybe<Scalars['date']['output']>;
  market_type_id?: Maybe<Scalars['Int']['output']>;
  max_cashout_new?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  new_brandBase?: Maybe<v1_new_brandBase>;
  parent_bonus_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  submitter_id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  uploaded_on?: Maybe<Scalars['date']['output']>;
  uploadedby_id?: Maybe<Scalars['Int']['output']>;
  wagering_requirements?: Maybe<Scalars['String']['output']>;
};


/** This is the bonus table for the bonus management system (BMS). */
export type v1_BonusesBonusesToCountriesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCountries_order_by>>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};


/** This is the bonus table for the bonus management system (BMS). */
export type v1_BonusesBonusesToCountries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCountries_order_by>>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};


/** This is the bonus table for the bonus management system (BMS). */
export type v1_BonusesBonusesToTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToTypes_order_by>>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};


/** This is the bonus table for the bonus management system (BMS). */
export type v1_BonusesBonusesToTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToTypes_order_by>>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};

/** This is a many-to-many table for the BMS, as one bonus might have many countries. */
export type v1_BonusesToCountries = {
  __typename?: 'v1_BonusesToCountries';
  /** An object relationship */
  Bonuses?: Maybe<v1_Bonuses>;
  /** An object relationship */
  Countries?: Maybe<v1_new_countryBase>;
  bonus_id?: Maybe<Scalars['Int']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.BonusesToCountries" */
export type v1_BonusesToCountries_aggregate = {
  __typename?: 'v1_BonusesToCountries_aggregate';
  aggregate?: Maybe<v1_BonusesToCountries_aggregate_fields>;
  nodes: Array<v1_BonusesToCountries>;
};

export type v1_BonusesToCountries_aggregate_bool_exp = {
  count?: InputMaybe<v1_BonusesToCountries_aggregate_bool_exp_count>;
};

export type v1_BonusesToCountries_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_BonusesToCountries_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.BonusesToCountries" */
export type v1_BonusesToCountries_aggregate_fields = {
  __typename?: 'v1_BonusesToCountries_aggregate_fields';
  avg?: Maybe<v1_BonusesToCountries_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BonusesToCountries_max_fields>;
  min?: Maybe<v1_BonusesToCountries_min_fields>;
  stddev?: Maybe<v1_BonusesToCountries_stddev_fields>;
  stddev_pop?: Maybe<v1_BonusesToCountries_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BonusesToCountries_stddev_samp_fields>;
  sum?: Maybe<v1_BonusesToCountries_sum_fields>;
  var_pop?: Maybe<v1_BonusesToCountries_var_pop_fields>;
  var_samp?: Maybe<v1_BonusesToCountries_var_samp_fields>;
  variance?: Maybe<v1_BonusesToCountries_variance_fields>;
};


/** aggregate fields of "v1.BonusesToCountries" */
export type v1_BonusesToCountries_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_aggregate_order_by = {
  avg?: InputMaybe<v1_BonusesToCountries_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_BonusesToCountries_max_order_by>;
  min?: InputMaybe<v1_BonusesToCountries_min_order_by>;
  stddev?: InputMaybe<v1_BonusesToCountries_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_BonusesToCountries_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_BonusesToCountries_stddev_samp_order_by>;
  sum?: InputMaybe<v1_BonusesToCountries_sum_order_by>;
  var_pop?: InputMaybe<v1_BonusesToCountries_var_pop_order_by>;
  var_samp?: InputMaybe<v1_BonusesToCountries_var_samp_order_by>;
  variance?: InputMaybe<v1_BonusesToCountries_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_arr_rel_insert_input = {
  data: Array<v1_BonusesToCountries_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BonusesToCountries_on_conflict>;
};

/** aggregate avg on columns */
export type v1_BonusesToCountries_avg_fields = {
  __typename?: 'v1_BonusesToCountries_avg_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_avg_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.BonusesToCountries". All fields are combined with a logical 'AND'. */
export type v1_BonusesToCountries_bool_exp = {
  Bonuses?: InputMaybe<v1_Bonuses_bool_exp>;
  Countries?: InputMaybe<v1_new_countryBase_bool_exp>;
  _and?: InputMaybe<Array<v1_BonusesToCountries_bool_exp>>;
  _not?: InputMaybe<v1_BonusesToCountries_bool_exp>;
  _or?: InputMaybe<Array<v1_BonusesToCountries_bool_exp>>;
  bonus_id?: InputMaybe<Int_comparison_exp>;
  country_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BonusesToCountries" */
export enum v1_BonusesToCountries_constraint {
  /** unique or primary key constraint on columns "id" */
  BonusToCountries_pkey = 'BonusToCountries_pkey'
}

/** input type for incrementing numeric columns in table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_inc_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_insert_input = {
  Bonuses?: InputMaybe<v1_Bonuses_obj_rel_insert_input>;
  Countries?: InputMaybe<v1_new_countryBase_obj_rel_insert_input>;
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_BonusesToCountries_max_fields = {
  __typename?: 'v1_BonusesToCountries_max_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_max_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_BonusesToCountries_min_fields = {
  __typename?: 'v1_BonusesToCountries_min_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_min_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_mutation_response = {
  __typename?: 'v1_BonusesToCountries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BonusesToCountries>;
};

/** on_conflict condition type for table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_on_conflict = {
  constraint: v1_BonusesToCountries_constraint;
  update_columns?: Array<v1_BonusesToCountries_update_column>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};

/** Ordering options when selecting data from "v1.BonusesToCountries". */
export type v1_BonusesToCountries_order_by = {
  Bonuses?: InputMaybe<v1_Bonuses_order_by>;
  Countries?: InputMaybe<v1_new_countryBase_order_by>;
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BonusesToCountries */
export type v1_BonusesToCountries_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BonusesToCountries" */
export enum v1_BonusesToCountries_select_column {
  /** column name */
  bonus_id = 'bonus_id',
  /** column name */
  country_id = 'country_id',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_set_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_BonusesToCountries_stddev_fields = {
  __typename?: 'v1_BonusesToCountries_stddev_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_stddev_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_BonusesToCountries_stddev_pop_fields = {
  __typename?: 'v1_BonusesToCountries_stddev_pop_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_stddev_pop_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_BonusesToCountries_stddev_samp_fields = {
  __typename?: 'v1_BonusesToCountries_stddev_samp_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_stddev_samp_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_BonusesToCountries" */
export type v1_BonusesToCountries_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BonusesToCountries_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BonusesToCountries_stream_cursor_value_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_BonusesToCountries_sum_fields = {
  __typename?: 'v1_BonusesToCountries_sum_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_sum_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.BonusesToCountries" */
export enum v1_BonusesToCountries_update_column {
  /** column name */
  bonus_id = 'bonus_id',
  /** column name */
  country_id = 'country_id',
  /** column name */
  id = 'id'
}

export type v1_BonusesToCountries_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BonusesToCountries_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BonusesToCountries_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BonusesToCountries_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BonusesToCountries_var_pop_fields = {
  __typename?: 'v1_BonusesToCountries_var_pop_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_var_pop_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_BonusesToCountries_var_samp_fields = {
  __typename?: 'v1_BonusesToCountries_var_samp_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_var_samp_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_BonusesToCountries_variance_fields = {
  __typename?: 'v1_BonusesToCountries_variance_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.BonusesToCountries" */
export type v1_BonusesToCountries_variance_order_by = {
  bonus_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** This is a many to many table for the currencies for the BMS system. */
export type v1_BonusesToCurrencies = {
  __typename?: 'v1_BonusesToCurrencies';
  bonus_id: Scalars['Int']['output'];
  currency_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_aggregate = {
  __typename?: 'v1_BonusesToCurrencies_aggregate';
  aggregate?: Maybe<v1_BonusesToCurrencies_aggregate_fields>;
  nodes: Array<v1_BonusesToCurrencies>;
};

export type v1_BonusesToCurrencies_aggregate_bool_exp = {
  count?: InputMaybe<v1_BonusesToCurrencies_aggregate_bool_exp_count>;
};

export type v1_BonusesToCurrencies_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_BonusesToCurrencies_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_aggregate_fields = {
  __typename?: 'v1_BonusesToCurrencies_aggregate_fields';
  avg?: Maybe<v1_BonusesToCurrencies_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BonusesToCurrencies_max_fields>;
  min?: Maybe<v1_BonusesToCurrencies_min_fields>;
  stddev?: Maybe<v1_BonusesToCurrencies_stddev_fields>;
  stddev_pop?: Maybe<v1_BonusesToCurrencies_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BonusesToCurrencies_stddev_samp_fields>;
  sum?: Maybe<v1_BonusesToCurrencies_sum_fields>;
  var_pop?: Maybe<v1_BonusesToCurrencies_var_pop_fields>;
  var_samp?: Maybe<v1_BonusesToCurrencies_var_samp_fields>;
  variance?: Maybe<v1_BonusesToCurrencies_variance_fields>;
};


/** aggregate fields of "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BonusesToCurrencies_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_aggregate_order_by = {
  avg?: InputMaybe<v1_BonusesToCurrencies_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_BonusesToCurrencies_max_order_by>;
  min?: InputMaybe<v1_BonusesToCurrencies_min_order_by>;
  stddev?: InputMaybe<v1_BonusesToCurrencies_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_BonusesToCurrencies_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_BonusesToCurrencies_stddev_samp_order_by>;
  sum?: InputMaybe<v1_BonusesToCurrencies_sum_order_by>;
  var_pop?: InputMaybe<v1_BonusesToCurrencies_var_pop_order_by>;
  var_samp?: InputMaybe<v1_BonusesToCurrencies_var_samp_order_by>;
  variance?: InputMaybe<v1_BonusesToCurrencies_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_arr_rel_insert_input = {
  data: Array<v1_BonusesToCurrencies_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BonusesToCurrencies_on_conflict>;
};

/** aggregate avg on columns */
export type v1_BonusesToCurrencies_avg_fields = {
  __typename?: 'v1_BonusesToCurrencies_avg_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_avg_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.BonusesToCurrencies". All fields are combined with a logical 'AND'. */
export type v1_BonusesToCurrencies_bool_exp = {
  _and?: InputMaybe<Array<v1_BonusesToCurrencies_bool_exp>>;
  _not?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
  _or?: InputMaybe<Array<v1_BonusesToCurrencies_bool_exp>>;
  bonus_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BonusesToCurrencies" */
export enum v1_BonusesToCurrencies_constraint {
  /** unique or primary key constraint on columns "id" */
  BonusesToCurrencies_pkey = 'BonusesToCurrencies_pkey'
}

/** input type for incrementing numeric columns in table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_inc_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_insert_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_BonusesToCurrencies_max_fields = {
  __typename?: 'v1_BonusesToCurrencies_max_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_max_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_BonusesToCurrencies_min_fields = {
  __typename?: 'v1_BonusesToCurrencies_min_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_min_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_mutation_response = {
  __typename?: 'v1_BonusesToCurrencies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BonusesToCurrencies>;
};

/** on_conflict condition type for table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_on_conflict = {
  constraint: v1_BonusesToCurrencies_constraint;
  update_columns?: Array<v1_BonusesToCurrencies_update_column>;
  where?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
};

/** Ordering options when selecting data from "v1.BonusesToCurrencies". */
export type v1_BonusesToCurrencies_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BonusesToCurrencies */
export type v1_BonusesToCurrencies_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BonusesToCurrencies" */
export enum v1_BonusesToCurrencies_select_column {
  /** column name */
  bonus_id = 'bonus_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_set_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_BonusesToCurrencies_stddev_fields = {
  __typename?: 'v1_BonusesToCurrencies_stddev_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_stddev_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_BonusesToCurrencies_stddev_pop_fields = {
  __typename?: 'v1_BonusesToCurrencies_stddev_pop_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_stddev_pop_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_BonusesToCurrencies_stddev_samp_fields = {
  __typename?: 'v1_BonusesToCurrencies_stddev_samp_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_stddev_samp_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_BonusesToCurrencies" */
export type v1_BonusesToCurrencies_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BonusesToCurrencies_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BonusesToCurrencies_stream_cursor_value_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_BonusesToCurrencies_sum_fields = {
  __typename?: 'v1_BonusesToCurrencies_sum_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_sum_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.BonusesToCurrencies" */
export enum v1_BonusesToCurrencies_update_column {
  /** column name */
  bonus_id = 'bonus_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id'
}

export type v1_BonusesToCurrencies_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BonusesToCurrencies_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BonusesToCurrencies_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BonusesToCurrencies_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BonusesToCurrencies_var_pop_fields = {
  __typename?: 'v1_BonusesToCurrencies_var_pop_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_var_pop_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_BonusesToCurrencies_var_samp_fields = {
  __typename?: 'v1_BonusesToCurrencies_var_samp_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_var_samp_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_BonusesToCurrencies_variance_fields = {
  __typename?: 'v1_BonusesToCurrencies_variance_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.BonusesToCurrencies" */
export type v1_BonusesToCurrencies_variance_order_by = {
  bonus_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.BonusesToTypes" */
export type v1_BonusesToTypes = {
  __typename?: 'v1_BonusesToTypes';
  /** An object relationship */
  BonusTypes?: Maybe<v1_BonusTypes>;
  /** An object relationship */
  Bonuses?: Maybe<v1_Bonuses>;
  bonus_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "v1.BonusesToTypes" */
export type v1_BonusesToTypes_aggregate = {
  __typename?: 'v1_BonusesToTypes_aggregate';
  aggregate?: Maybe<v1_BonusesToTypes_aggregate_fields>;
  nodes: Array<v1_BonusesToTypes>;
};

export type v1_BonusesToTypes_aggregate_bool_exp = {
  count?: InputMaybe<v1_BonusesToTypes_aggregate_bool_exp_count>;
};

export type v1_BonusesToTypes_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_BonusesToTypes_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.BonusesToTypes" */
export type v1_BonusesToTypes_aggregate_fields = {
  __typename?: 'v1_BonusesToTypes_aggregate_fields';
  avg?: Maybe<v1_BonusesToTypes_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BonusesToTypes_max_fields>;
  min?: Maybe<v1_BonusesToTypes_min_fields>;
  stddev?: Maybe<v1_BonusesToTypes_stddev_fields>;
  stddev_pop?: Maybe<v1_BonusesToTypes_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BonusesToTypes_stddev_samp_fields>;
  sum?: Maybe<v1_BonusesToTypes_sum_fields>;
  var_pop?: Maybe<v1_BonusesToTypes_var_pop_fields>;
  var_samp?: Maybe<v1_BonusesToTypes_var_samp_fields>;
  variance?: Maybe<v1_BonusesToTypes_variance_fields>;
};


/** aggregate fields of "v1.BonusesToTypes" */
export type v1_BonusesToTypes_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BonusesToTypes_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_aggregate_order_by = {
  avg?: InputMaybe<v1_BonusesToTypes_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_BonusesToTypes_max_order_by>;
  min?: InputMaybe<v1_BonusesToTypes_min_order_by>;
  stddev?: InputMaybe<v1_BonusesToTypes_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_BonusesToTypes_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_BonusesToTypes_stddev_samp_order_by>;
  sum?: InputMaybe<v1_BonusesToTypes_sum_order_by>;
  var_pop?: InputMaybe<v1_BonusesToTypes_var_pop_order_by>;
  var_samp?: InputMaybe<v1_BonusesToTypes_var_samp_order_by>;
  variance?: InputMaybe<v1_BonusesToTypes_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_arr_rel_insert_input = {
  data: Array<v1_BonusesToTypes_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BonusesToTypes_on_conflict>;
};

/** aggregate avg on columns */
export type v1_BonusesToTypes_avg_fields = {
  __typename?: 'v1_BonusesToTypes_avg_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_avg_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.BonusesToTypes". All fields are combined with a logical 'AND'. */
export type v1_BonusesToTypes_bool_exp = {
  BonusTypes?: InputMaybe<v1_BonusTypes_bool_exp>;
  Bonuses?: InputMaybe<v1_Bonuses_bool_exp>;
  _and?: InputMaybe<Array<v1_BonusesToTypes_bool_exp>>;
  _not?: InputMaybe<v1_BonusesToTypes_bool_exp>;
  _or?: InputMaybe<Array<v1_BonusesToTypes_bool_exp>>;
  bonus_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  type_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BonusesToTypes" */
export enum v1_BonusesToTypes_constraint {
  /** unique or primary key constraint on columns "id" */
  BonusesToTypes_pkey = 'BonusesToTypes_pkey'
}

/** input type for incrementing numeric columns in table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_inc_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_insert_input = {
  BonusTypes?: InputMaybe<v1_BonusTypes_obj_rel_insert_input>;
  Bonuses?: InputMaybe<v1_Bonuses_obj_rel_insert_input>;
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_BonusesToTypes_max_fields = {
  __typename?: 'v1_BonusesToTypes_max_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_max_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_BonusesToTypes_min_fields = {
  __typename?: 'v1_BonusesToTypes_min_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_min_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_mutation_response = {
  __typename?: 'v1_BonusesToTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BonusesToTypes>;
};

/** on_conflict condition type for table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_on_conflict = {
  constraint: v1_BonusesToTypes_constraint;
  update_columns?: Array<v1_BonusesToTypes_update_column>;
  where?: InputMaybe<v1_BonusesToTypes_bool_exp>;
};

/** Ordering options when selecting data from "v1.BonusesToTypes". */
export type v1_BonusesToTypes_order_by = {
  BonusTypes?: InputMaybe<v1_BonusTypes_order_by>;
  Bonuses?: InputMaybe<v1_Bonuses_order_by>;
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BonusesToTypes */
export type v1_BonusesToTypes_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BonusesToTypes" */
export enum v1_BonusesToTypes_select_column {
  /** column name */
  bonus_id = 'bonus_id',
  /** column name */
  id = 'id',
  /** column name */
  type_id = 'type_id'
}

/** input type for updating data in table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_set_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_BonusesToTypes_stddev_fields = {
  __typename?: 'v1_BonusesToTypes_stddev_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_stddev_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_BonusesToTypes_stddev_pop_fields = {
  __typename?: 'v1_BonusesToTypes_stddev_pop_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_stddev_pop_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_BonusesToTypes_stddev_samp_fields = {
  __typename?: 'v1_BonusesToTypes_stddev_samp_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_stddev_samp_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_BonusesToTypes" */
export type v1_BonusesToTypes_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BonusesToTypes_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BonusesToTypes_stream_cursor_value_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_BonusesToTypes_sum_fields = {
  __typename?: 'v1_BonusesToTypes_sum_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_sum_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.BonusesToTypes" */
export enum v1_BonusesToTypes_update_column {
  /** column name */
  bonus_id = 'bonus_id',
  /** column name */
  id = 'id',
  /** column name */
  type_id = 'type_id'
}

export type v1_BonusesToTypes_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BonusesToTypes_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BonusesToTypes_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BonusesToTypes_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BonusesToTypes_var_pop_fields = {
  __typename?: 'v1_BonusesToTypes_var_pop_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_var_pop_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_BonusesToTypes_var_samp_fields = {
  __typename?: 'v1_BonusesToTypes_var_samp_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_var_samp_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_BonusesToTypes_variance_fields = {
  __typename?: 'v1_BonusesToTypes_variance_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.BonusesToTypes" */
export type v1_BonusesToTypes_variance_order_by = {
  bonus_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type_id?: InputMaybe<order_by>;
};

/** aggregated selection of "v1.Bonuses" */
export type v1_Bonuses_aggregate = {
  __typename?: 'v1_Bonuses_aggregate';
  aggregate?: Maybe<v1_Bonuses_aggregate_fields>;
  nodes: Array<v1_Bonuses>;
};

export type v1_Bonuses_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_Bonuses_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_Bonuses_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_Bonuses_aggregate_bool_exp_count>;
};

export type v1_Bonuses_aggregate_bool_exp_bool_and = {
  arguments: v1_Bonuses_select_column_v1_Bonuses_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Bonuses_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Bonuses_aggregate_bool_exp_bool_or = {
  arguments: v1_Bonuses_select_column_v1_Bonuses_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Bonuses_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Bonuses_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Bonuses_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Bonuses_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Bonuses" */
export type v1_Bonuses_aggregate_fields = {
  __typename?: 'v1_Bonuses_aggregate_fields';
  avg?: Maybe<v1_Bonuses_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Bonuses_max_fields>;
  min?: Maybe<v1_Bonuses_min_fields>;
  stddev?: Maybe<v1_Bonuses_stddev_fields>;
  stddev_pop?: Maybe<v1_Bonuses_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Bonuses_stddev_samp_fields>;
  sum?: Maybe<v1_Bonuses_sum_fields>;
  var_pop?: Maybe<v1_Bonuses_var_pop_fields>;
  var_samp?: Maybe<v1_Bonuses_var_samp_fields>;
  variance?: Maybe<v1_Bonuses_variance_fields>;
};


/** aggregate fields of "v1.Bonuses" */
export type v1_Bonuses_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Bonuses_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Bonuses" */
export type v1_Bonuses_aggregate_order_by = {
  avg?: InputMaybe<v1_Bonuses_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Bonuses_max_order_by>;
  min?: InputMaybe<v1_Bonuses_min_order_by>;
  stddev?: InputMaybe<v1_Bonuses_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Bonuses_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Bonuses_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Bonuses_sum_order_by>;
  var_pop?: InputMaybe<v1_Bonuses_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Bonuses_var_samp_order_by>;
  variance?: InputMaybe<v1_Bonuses_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Bonuses" */
export type v1_Bonuses_arr_rel_insert_input = {
  data: Array<v1_Bonuses_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Bonuses_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Bonuses_avg_fields = {
  __typename?: 'v1_Bonuses_avg_fields';
  allowed_player_id?: Maybe<Scalars['Float']['output']>;
  bonusstatus_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  cms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  market_type_id?: Maybe<Scalars['Float']['output']>;
  parent_bonus_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  submitter_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Bonuses" */
export type v1_Bonuses_avg_order_by = {
  allowed_player_id?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Bonuses". All fields are combined with a logical 'AND'. */
export type v1_Bonuses_bool_exp = {
  AllowedPlayer?: InputMaybe<v1_AllowedPlayers_bool_exp>;
  BonusStatus?: InputMaybe<v1_BonusStatus_bool_exp>;
  BonusSubmitter?: InputMaybe<v1_BonusSubmitter_bool_exp>;
  BonusesToCountries?: InputMaybe<v1_BonusesToCountries_bool_exp>;
  BonusesToCountries_aggregate?: InputMaybe<v1_BonusesToCountries_aggregate_bool_exp>;
  BonusesToTypes?: InputMaybe<v1_BonusesToTypes_bool_exp>;
  BonusesToTypes_aggregate?: InputMaybe<v1_BonusesToTypes_aggregate_bool_exp>;
  MarketType?: InputMaybe<v1_MarketType_bool_exp>;
  Project?: InputMaybe<v1_Project_bool_exp>;
  User?: InputMaybe<v1_SystemUserBase_bool_exp>;
  _and?: InputMaybe<Array<v1_Bonuses_bool_exp>>;
  _not?: InputMaybe<v1_Bonuses_bool_exp>;
  _or?: InputMaybe<Array<v1_Bonuses_bool_exp>>;
  additional_info?: InputMaybe<String_comparison_exp>;
  allowed_player_id?: InputMaybe<Int_comparison_exp>;
  bonus_condition?: InputMaybe<String_comparison_exp>;
  bonus_info?: InputMaybe<String_comparison_exp>;
  bonusstatus_id?: InputMaybe<Int_comparison_exp>;
  brand_id?: InputMaybe<Int_comparison_exp>;
  cms_brand_name?: InputMaybe<String_comparison_exp>;
  cms_id?: InputMaybe<Int_comparison_exp>;
  created_on?: InputMaybe<date_comparison_exp>;
  dep_req_text?: InputMaybe<String_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  expires_on?: InputMaybe<date_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  is_exclusive?: InputMaybe<Boolean_comparison_exp>;
  launched_on?: InputMaybe<date_comparison_exp>;
  market_type_id?: InputMaybe<Int_comparison_exp>;
  max_cashout_new?: InputMaybe<String_comparison_exp>;
  new_brandBase?: InputMaybe<v1_new_brandBase_bool_exp>;
  parent_bonus_id?: InputMaybe<Int_comparison_exp>;
  project_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  submitter_id?: InputMaybe<Int_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  uploaded_on?: InputMaybe<date_comparison_exp>;
  uploadedby_id?: InputMaybe<Int_comparison_exp>;
  wagering_requirements?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Bonuses" */
export enum v1_Bonuses_constraint {
  /** unique or primary key constraint on columns "id" */
  Bonuses_pkey = 'Bonuses_pkey'
}

/** input type for incrementing numeric columns in table "v1.Bonuses" */
export type v1_Bonuses_inc_input = {
  allowed_player_id?: InputMaybe<Scalars['Int']['input']>;
  bonusstatus_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  cms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  market_type_id?: InputMaybe<Scalars['Int']['input']>;
  parent_bonus_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  submitter_id?: InputMaybe<Scalars['Int']['input']>;
  uploadedby_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Bonuses" */
export type v1_Bonuses_insert_input = {
  AllowedPlayer?: InputMaybe<v1_AllowedPlayers_obj_rel_insert_input>;
  BonusStatus?: InputMaybe<v1_BonusStatus_obj_rel_insert_input>;
  BonusSubmitter?: InputMaybe<v1_BonusSubmitter_obj_rel_insert_input>;
  BonusesToCountries?: InputMaybe<v1_BonusesToCountries_arr_rel_insert_input>;
  BonusesToTypes?: InputMaybe<v1_BonusesToTypes_arr_rel_insert_input>;
  MarketType?: InputMaybe<v1_MarketType_obj_rel_insert_input>;
  Project?: InputMaybe<v1_Project_obj_rel_insert_input>;
  User?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  additional_info?: InputMaybe<Scalars['String']['input']>;
  allowed_player_id?: InputMaybe<Scalars['Int']['input']>;
  bonus_condition?: InputMaybe<Scalars['String']['input']>;
  bonus_info?: InputMaybe<Scalars['String']['input']>;
  bonusstatus_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  cms_brand_name?: InputMaybe<Scalars['String']['input']>;
  cms_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  dep_req_text?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expires_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_exclusive?: InputMaybe<Scalars['Boolean']['input']>;
  launched_on?: InputMaybe<Scalars['date']['input']>;
  market_type_id?: InputMaybe<Scalars['Int']['input']>;
  max_cashout_new?: InputMaybe<Scalars['String']['input']>;
  new_brandBase?: InputMaybe<v1_new_brandBase_obj_rel_insert_input>;
  parent_bonus_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  submitter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  uploaded_on?: InputMaybe<Scalars['date']['input']>;
  uploadedby_id?: InputMaybe<Scalars['Int']['input']>;
  wagering_requirements?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Bonuses_max_fields = {
  __typename?: 'v1_Bonuses_max_fields';
  additional_info?: Maybe<Scalars['String']['output']>;
  allowed_player_id?: Maybe<Scalars['Int']['output']>;
  bonus_condition?: Maybe<Scalars['String']['output']>;
  bonus_info?: Maybe<Scalars['String']['output']>;
  bonusstatus_id?: Maybe<Scalars['Int']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  cms_brand_name?: Maybe<Scalars['String']['output']>;
  cms_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['date']['output']>;
  dep_req_text?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expires_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  launched_on?: Maybe<Scalars['date']['output']>;
  market_type_id?: Maybe<Scalars['Int']['output']>;
  max_cashout_new?: Maybe<Scalars['String']['output']>;
  parent_bonus_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  submitter_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uploaded_on?: Maybe<Scalars['date']['output']>;
  uploadedby_id?: Maybe<Scalars['Int']['output']>;
  wagering_requirements?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "v1.Bonuses" */
export type v1_Bonuses_max_order_by = {
  additional_info?: InputMaybe<order_by>;
  allowed_player_id?: InputMaybe<order_by>;
  bonus_condition?: InputMaybe<order_by>;
  bonus_info?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_brand_name?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  dep_req_text?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  expires_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  launched_on?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  max_cashout_new?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  uploaded_on?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  wagering_requirements?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Bonuses_min_fields = {
  __typename?: 'v1_Bonuses_min_fields';
  additional_info?: Maybe<Scalars['String']['output']>;
  allowed_player_id?: Maybe<Scalars['Int']['output']>;
  bonus_condition?: Maybe<Scalars['String']['output']>;
  bonus_info?: Maybe<Scalars['String']['output']>;
  bonusstatus_id?: Maybe<Scalars['Int']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  cms_brand_name?: Maybe<Scalars['String']['output']>;
  cms_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['date']['output']>;
  dep_req_text?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expires_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  launched_on?: Maybe<Scalars['date']['output']>;
  market_type_id?: Maybe<Scalars['Int']['output']>;
  max_cashout_new?: Maybe<Scalars['String']['output']>;
  parent_bonus_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  submitter_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uploaded_on?: Maybe<Scalars['date']['output']>;
  uploadedby_id?: Maybe<Scalars['Int']['output']>;
  wagering_requirements?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "v1.Bonuses" */
export type v1_Bonuses_min_order_by = {
  additional_info?: InputMaybe<order_by>;
  allowed_player_id?: InputMaybe<order_by>;
  bonus_condition?: InputMaybe<order_by>;
  bonus_info?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_brand_name?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  dep_req_text?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  expires_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  launched_on?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  max_cashout_new?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  uploaded_on?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  wagering_requirements?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Bonuses" */
export type v1_Bonuses_mutation_response = {
  __typename?: 'v1_Bonuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Bonuses>;
};

/** input type for inserting object relation for remote table "v1.Bonuses" */
export type v1_Bonuses_obj_rel_insert_input = {
  data: v1_Bonuses_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Bonuses_on_conflict>;
};

/** on_conflict condition type for table "v1.Bonuses" */
export type v1_Bonuses_on_conflict = {
  constraint: v1_Bonuses_constraint;
  update_columns?: Array<v1_Bonuses_update_column>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};

/** Ordering options when selecting data from "v1.Bonuses". */
export type v1_Bonuses_order_by = {
  AllowedPlayer?: InputMaybe<v1_AllowedPlayers_order_by>;
  BonusStatus?: InputMaybe<v1_BonusStatus_order_by>;
  BonusSubmitter?: InputMaybe<v1_BonusSubmitter_order_by>;
  BonusesToCountries_aggregate?: InputMaybe<v1_BonusesToCountries_aggregate_order_by>;
  BonusesToTypes_aggregate?: InputMaybe<v1_BonusesToTypes_aggregate_order_by>;
  MarketType?: InputMaybe<v1_MarketType_order_by>;
  Project?: InputMaybe<v1_Project_order_by>;
  User?: InputMaybe<v1_SystemUserBase_order_by>;
  additional_info?: InputMaybe<order_by>;
  allowed_player_id?: InputMaybe<order_by>;
  bonus_condition?: InputMaybe<order_by>;
  bonus_info?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_brand_name?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  dep_req_text?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  expires_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  is_exclusive?: InputMaybe<order_by>;
  launched_on?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  max_cashout_new?: InputMaybe<order_by>;
  new_brandBase?: InputMaybe<v1_new_brandBase_order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  uploaded_on?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  wagering_requirements?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Bonuses */
export type v1_Bonuses_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Bonuses" */
export enum v1_Bonuses_select_column {
  /** column name */
  additional_info = 'additional_info',
  /** column name */
  allowed_player_id = 'allowed_player_id',
  /** column name */
  bonus_condition = 'bonus_condition',
  /** column name */
  bonus_info = 'bonus_info',
  /** column name */
  bonusstatus_id = 'bonusstatus_id',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  cms_brand_name = 'cms_brand_name',
  /** column name */
  cms_id = 'cms_id',
  /** column name */
  created_on = 'created_on',
  /** column name */
  dep_req_text = 'dep_req_text',
  /** column name */
  description = 'description',
  /** column name */
  email = 'email',
  /** column name */
  expires_on = 'expires_on',
  /** column name */
  id = 'id',
  /** column name */
  is_exclusive = 'is_exclusive',
  /** column name */
  launched_on = 'launched_on',
  /** column name */
  market_type_id = 'market_type_id',
  /** column name */
  max_cashout_new = 'max_cashout_new',
  /** column name */
  parent_bonus_id = 'parent_bonus_id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  submitter_id = 'submitter_id',
  /** column name */
  title = 'title',
  /** column name */
  uploaded_on = 'uploaded_on',
  /** column name */
  uploadedby_id = 'uploadedby_id',
  /** column name */
  wagering_requirements = 'wagering_requirements'
}

/** select "v1_Bonuses_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.Bonuses" */
export enum v1_Bonuses_select_column_v1_Bonuses_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  is_exclusive = 'is_exclusive'
}

/** select "v1_Bonuses_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.Bonuses" */
export enum v1_Bonuses_select_column_v1_Bonuses_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  is_exclusive = 'is_exclusive'
}

/** input type for updating data in table "v1.Bonuses" */
export type v1_Bonuses_set_input = {
  additional_info?: InputMaybe<Scalars['String']['input']>;
  allowed_player_id?: InputMaybe<Scalars['Int']['input']>;
  bonus_condition?: InputMaybe<Scalars['String']['input']>;
  bonus_info?: InputMaybe<Scalars['String']['input']>;
  bonusstatus_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  cms_brand_name?: InputMaybe<Scalars['String']['input']>;
  cms_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  dep_req_text?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expires_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_exclusive?: InputMaybe<Scalars['Boolean']['input']>;
  launched_on?: InputMaybe<Scalars['date']['input']>;
  market_type_id?: InputMaybe<Scalars['Int']['input']>;
  max_cashout_new?: InputMaybe<Scalars['String']['input']>;
  parent_bonus_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  submitter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  uploaded_on?: InputMaybe<Scalars['date']['input']>;
  uploadedby_id?: InputMaybe<Scalars['Int']['input']>;
  wagering_requirements?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Bonuses_stddev_fields = {
  __typename?: 'v1_Bonuses_stddev_fields';
  allowed_player_id?: Maybe<Scalars['Float']['output']>;
  bonusstatus_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  cms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  market_type_id?: Maybe<Scalars['Float']['output']>;
  parent_bonus_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  submitter_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Bonuses" */
export type v1_Bonuses_stddev_order_by = {
  allowed_player_id?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Bonuses_stddev_pop_fields = {
  __typename?: 'v1_Bonuses_stddev_pop_fields';
  allowed_player_id?: Maybe<Scalars['Float']['output']>;
  bonusstatus_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  cms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  market_type_id?: Maybe<Scalars['Float']['output']>;
  parent_bonus_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  submitter_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Bonuses" */
export type v1_Bonuses_stddev_pop_order_by = {
  allowed_player_id?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Bonuses_stddev_samp_fields = {
  __typename?: 'v1_Bonuses_stddev_samp_fields';
  allowed_player_id?: Maybe<Scalars['Float']['output']>;
  bonusstatus_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  cms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  market_type_id?: Maybe<Scalars['Float']['output']>;
  parent_bonus_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  submitter_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Bonuses" */
export type v1_Bonuses_stddev_samp_order_by = {
  allowed_player_id?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Bonuses" */
export type v1_Bonuses_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Bonuses_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Bonuses_stream_cursor_value_input = {
  additional_info?: InputMaybe<Scalars['String']['input']>;
  allowed_player_id?: InputMaybe<Scalars['Int']['input']>;
  bonus_condition?: InputMaybe<Scalars['String']['input']>;
  bonus_info?: InputMaybe<Scalars['String']['input']>;
  bonusstatus_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  cms_brand_name?: InputMaybe<Scalars['String']['input']>;
  cms_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  dep_req_text?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expires_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_exclusive?: InputMaybe<Scalars['Boolean']['input']>;
  launched_on?: InputMaybe<Scalars['date']['input']>;
  market_type_id?: InputMaybe<Scalars['Int']['input']>;
  max_cashout_new?: InputMaybe<Scalars['String']['input']>;
  parent_bonus_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  submitter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  uploaded_on?: InputMaybe<Scalars['date']['input']>;
  uploadedby_id?: InputMaybe<Scalars['Int']['input']>;
  wagering_requirements?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Bonuses_sum_fields = {
  __typename?: 'v1_Bonuses_sum_fields';
  allowed_player_id?: Maybe<Scalars['Int']['output']>;
  bonusstatus_id?: Maybe<Scalars['Int']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  cms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  market_type_id?: Maybe<Scalars['Int']['output']>;
  parent_bonus_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  submitter_id?: Maybe<Scalars['Int']['output']>;
  uploadedby_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Bonuses" */
export type v1_Bonuses_sum_order_by = {
  allowed_player_id?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Bonuses" */
export enum v1_Bonuses_update_column {
  /** column name */
  additional_info = 'additional_info',
  /** column name */
  allowed_player_id = 'allowed_player_id',
  /** column name */
  bonus_condition = 'bonus_condition',
  /** column name */
  bonus_info = 'bonus_info',
  /** column name */
  bonusstatus_id = 'bonusstatus_id',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  cms_brand_name = 'cms_brand_name',
  /** column name */
  cms_id = 'cms_id',
  /** column name */
  created_on = 'created_on',
  /** column name */
  dep_req_text = 'dep_req_text',
  /** column name */
  description = 'description',
  /** column name */
  email = 'email',
  /** column name */
  expires_on = 'expires_on',
  /** column name */
  id = 'id',
  /** column name */
  is_exclusive = 'is_exclusive',
  /** column name */
  launched_on = 'launched_on',
  /** column name */
  market_type_id = 'market_type_id',
  /** column name */
  max_cashout_new = 'max_cashout_new',
  /** column name */
  parent_bonus_id = 'parent_bonus_id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  submitter_id = 'submitter_id',
  /** column name */
  title = 'title',
  /** column name */
  uploaded_on = 'uploaded_on',
  /** column name */
  uploadedby_id = 'uploadedby_id',
  /** column name */
  wagering_requirements = 'wagering_requirements'
}

export type v1_Bonuses_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Bonuses_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Bonuses_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Bonuses_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Bonuses_var_pop_fields = {
  __typename?: 'v1_Bonuses_var_pop_fields';
  allowed_player_id?: Maybe<Scalars['Float']['output']>;
  bonusstatus_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  cms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  market_type_id?: Maybe<Scalars['Float']['output']>;
  parent_bonus_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  submitter_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Bonuses" */
export type v1_Bonuses_var_pop_order_by = {
  allowed_player_id?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Bonuses_var_samp_fields = {
  __typename?: 'v1_Bonuses_var_samp_fields';
  allowed_player_id?: Maybe<Scalars['Float']['output']>;
  bonusstatus_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  cms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  market_type_id?: Maybe<Scalars['Float']['output']>;
  parent_bonus_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  submitter_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Bonuses" */
export type v1_Bonuses_var_samp_order_by = {
  allowed_player_id?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Bonuses_variance_fields = {
  __typename?: 'v1_Bonuses_variance_fields';
  allowed_player_id?: Maybe<Scalars['Float']['output']>;
  bonusstatus_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  cms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  market_type_id?: Maybe<Scalars['Float']['output']>;
  parent_bonus_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  submitter_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Bonuses" */
export type v1_Bonuses_variance_order_by = {
  allowed_player_id?: InputMaybe<order_by>;
  bonusstatus_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  cms_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  market_type_id?: InputMaybe<order_by>;
  parent_bonus_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  submitter_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.BonusestoGames" */
export type v1_BonusestoGames = {
  __typename?: 'v1_BonusestoGames';
  /** An object relationship */
  GameType: v1_GameType;
  bonus_id: Scalars['Int']['output'];
  game_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.BonusestoGames" */
export type v1_BonusestoGames_aggregate = {
  __typename?: 'v1_BonusestoGames_aggregate';
  aggregate?: Maybe<v1_BonusestoGames_aggregate_fields>;
  nodes: Array<v1_BonusestoGames>;
};

export type v1_BonusestoGames_aggregate_bool_exp = {
  count?: InputMaybe<v1_BonusestoGames_aggregate_bool_exp_count>;
};

export type v1_BonusestoGames_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_BonusestoGames_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_BonusestoGames_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.BonusestoGames" */
export type v1_BonusestoGames_aggregate_fields = {
  __typename?: 'v1_BonusestoGames_aggregate_fields';
  avg?: Maybe<v1_BonusestoGames_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BonusestoGames_max_fields>;
  min?: Maybe<v1_BonusestoGames_min_fields>;
  stddev?: Maybe<v1_BonusestoGames_stddev_fields>;
  stddev_pop?: Maybe<v1_BonusestoGames_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BonusestoGames_stddev_samp_fields>;
  sum?: Maybe<v1_BonusestoGames_sum_fields>;
  var_pop?: Maybe<v1_BonusestoGames_var_pop_fields>;
  var_samp?: Maybe<v1_BonusestoGames_var_samp_fields>;
  variance?: Maybe<v1_BonusestoGames_variance_fields>;
};


/** aggregate fields of "v1.BonusestoGames" */
export type v1_BonusestoGames_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BonusestoGames_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.BonusestoGames" */
export type v1_BonusestoGames_aggregate_order_by = {
  avg?: InputMaybe<v1_BonusestoGames_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_BonusestoGames_max_order_by>;
  min?: InputMaybe<v1_BonusestoGames_min_order_by>;
  stddev?: InputMaybe<v1_BonusestoGames_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_BonusestoGames_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_BonusestoGames_stddev_samp_order_by>;
  sum?: InputMaybe<v1_BonusestoGames_sum_order_by>;
  var_pop?: InputMaybe<v1_BonusestoGames_var_pop_order_by>;
  var_samp?: InputMaybe<v1_BonusestoGames_var_samp_order_by>;
  variance?: InputMaybe<v1_BonusestoGames_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.BonusestoGames" */
export type v1_BonusestoGames_arr_rel_insert_input = {
  data: Array<v1_BonusestoGames_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BonusestoGames_on_conflict>;
};

/** aggregate avg on columns */
export type v1_BonusestoGames_avg_fields = {
  __typename?: 'v1_BonusestoGames_avg_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  game_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_avg_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.BonusestoGames". All fields are combined with a logical 'AND'. */
export type v1_BonusestoGames_bool_exp = {
  GameType?: InputMaybe<v1_GameType_bool_exp>;
  _and?: InputMaybe<Array<v1_BonusestoGames_bool_exp>>;
  _not?: InputMaybe<v1_BonusestoGames_bool_exp>;
  _or?: InputMaybe<Array<v1_BonusestoGames_bool_exp>>;
  bonus_id?: InputMaybe<Int_comparison_exp>;
  game_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BonusestoGames" */
export enum v1_BonusestoGames_constraint {
  /** unique or primary key constraint on columns "id" */
  BonusestoGames_pkey = 'BonusestoGames_pkey'
}

/** input type for incrementing numeric columns in table "v1.BonusestoGames" */
export type v1_BonusestoGames_inc_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  game_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BonusestoGames" */
export type v1_BonusestoGames_insert_input = {
  GameType?: InputMaybe<v1_GameType_obj_rel_insert_input>;
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  game_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_BonusestoGames_max_fields = {
  __typename?: 'v1_BonusestoGames_max_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  game_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_max_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_BonusestoGames_min_fields = {
  __typename?: 'v1_BonusestoGames_min_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  game_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_min_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.BonusestoGames" */
export type v1_BonusestoGames_mutation_response = {
  __typename?: 'v1_BonusestoGames_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BonusestoGames>;
};

/** on_conflict condition type for table "v1.BonusestoGames" */
export type v1_BonusestoGames_on_conflict = {
  constraint: v1_BonusestoGames_constraint;
  update_columns?: Array<v1_BonusestoGames_update_column>;
  where?: InputMaybe<v1_BonusestoGames_bool_exp>;
};

/** Ordering options when selecting data from "v1.BonusestoGames". */
export type v1_BonusestoGames_order_by = {
  GameType?: InputMaybe<v1_GameType_order_by>;
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BonusestoGames */
export type v1_BonusestoGames_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BonusestoGames" */
export enum v1_BonusestoGames_select_column {
  /** column name */
  bonus_id = 'bonus_id',
  /** column name */
  game_id = 'game_id',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.BonusestoGames" */
export type v1_BonusestoGames_set_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  game_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_BonusestoGames_stddev_fields = {
  __typename?: 'v1_BonusestoGames_stddev_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  game_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_stddev_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_BonusestoGames_stddev_pop_fields = {
  __typename?: 'v1_BonusestoGames_stddev_pop_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  game_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_stddev_pop_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_BonusestoGames_stddev_samp_fields = {
  __typename?: 'v1_BonusestoGames_stddev_samp_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  game_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_stddev_samp_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_BonusestoGames" */
export type v1_BonusestoGames_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BonusestoGames_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BonusestoGames_stream_cursor_value_input = {
  bonus_id?: InputMaybe<Scalars['Int']['input']>;
  game_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_BonusestoGames_sum_fields = {
  __typename?: 'v1_BonusestoGames_sum_fields';
  bonus_id?: Maybe<Scalars['Int']['output']>;
  game_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_sum_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.BonusestoGames" */
export enum v1_BonusestoGames_update_column {
  /** column name */
  bonus_id = 'bonus_id',
  /** column name */
  game_id = 'game_id',
  /** column name */
  id = 'id'
}

export type v1_BonusestoGames_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BonusestoGames_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BonusestoGames_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BonusestoGames_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BonusestoGames_var_pop_fields = {
  __typename?: 'v1_BonusestoGames_var_pop_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  game_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_var_pop_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_BonusestoGames_var_samp_fields = {
  __typename?: 'v1_BonusestoGames_var_samp_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  game_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_var_samp_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_BonusestoGames_variance_fields = {
  __typename?: 'v1_BonusestoGames_variance_fields';
  bonus_id?: Maybe<Scalars['Float']['output']>;
  game_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.BonusestoGames" */
export type v1_BonusestoGames_variance_order_by = {
  bonus_id?: InputMaybe<order_by>;
  game_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.BrandAlias" */
export type v1_BrandAlias = {
  __typename?: 'v1_BrandAlias';
  brand_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  new_brandBase: v1_new_brandBase;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "v1.BrandAlias" */
export type v1_BrandAlias_aggregate = {
  __typename?: 'v1_BrandAlias_aggregate';
  aggregate?: Maybe<v1_BrandAlias_aggregate_fields>;
  nodes: Array<v1_BrandAlias>;
};

export type v1_BrandAlias_aggregate_bool_exp = {
  count?: InputMaybe<v1_BrandAlias_aggregate_bool_exp_count>;
};

export type v1_BrandAlias_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_BrandAlias_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_BrandAlias_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.BrandAlias" */
export type v1_BrandAlias_aggregate_fields = {
  __typename?: 'v1_BrandAlias_aggregate_fields';
  avg?: Maybe<v1_BrandAlias_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BrandAlias_max_fields>;
  min?: Maybe<v1_BrandAlias_min_fields>;
  stddev?: Maybe<v1_BrandAlias_stddev_fields>;
  stddev_pop?: Maybe<v1_BrandAlias_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BrandAlias_stddev_samp_fields>;
  sum?: Maybe<v1_BrandAlias_sum_fields>;
  var_pop?: Maybe<v1_BrandAlias_var_pop_fields>;
  var_samp?: Maybe<v1_BrandAlias_var_samp_fields>;
  variance?: Maybe<v1_BrandAlias_variance_fields>;
};


/** aggregate fields of "v1.BrandAlias" */
export type v1_BrandAlias_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BrandAlias_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.BrandAlias" */
export type v1_BrandAlias_aggregate_order_by = {
  avg?: InputMaybe<v1_BrandAlias_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_BrandAlias_max_order_by>;
  min?: InputMaybe<v1_BrandAlias_min_order_by>;
  stddev?: InputMaybe<v1_BrandAlias_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_BrandAlias_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_BrandAlias_stddev_samp_order_by>;
  sum?: InputMaybe<v1_BrandAlias_sum_order_by>;
  var_pop?: InputMaybe<v1_BrandAlias_var_pop_order_by>;
  var_samp?: InputMaybe<v1_BrandAlias_var_samp_order_by>;
  variance?: InputMaybe<v1_BrandAlias_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.BrandAlias" */
export type v1_BrandAlias_arr_rel_insert_input = {
  data: Array<v1_BrandAlias_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BrandAlias_on_conflict>;
};

/** aggregate avg on columns */
export type v1_BrandAlias_avg_fields = {
  __typename?: 'v1_BrandAlias_avg_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_avg_order_by = {
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.BrandAlias". All fields are combined with a logical 'AND'. */
export type v1_BrandAlias_bool_exp = {
  _and?: InputMaybe<Array<v1_BrandAlias_bool_exp>>;
  _not?: InputMaybe<v1_BrandAlias_bool_exp>;
  _or?: InputMaybe<Array<v1_BrandAlias_bool_exp>>;
  brand_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  new_brandBase?: InputMaybe<v1_new_brandBase_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BrandAlias" */
export enum v1_BrandAlias_constraint {
  /** unique or primary key constraint on columns "id" */
  BrandAlias_pkey = 'BrandAlias_pkey'
}

/** input type for incrementing numeric columns in table "v1.BrandAlias" */
export type v1_BrandAlias_inc_input = {
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BrandAlias" */
export type v1_BrandAlias_insert_input = {
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  new_brandBase?: InputMaybe<v1_new_brandBase_obj_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type v1_BrandAlias_max_fields = {
  __typename?: 'v1_BrandAlias_max_fields';
  brand_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_max_order_by = {
  brand_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_BrandAlias_min_fields = {
  __typename?: 'v1_BrandAlias_min_fields';
  brand_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_min_order_by = {
  brand_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.BrandAlias" */
export type v1_BrandAlias_mutation_response = {
  __typename?: 'v1_BrandAlias_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BrandAlias>;
};

/** on_conflict condition type for table "v1.BrandAlias" */
export type v1_BrandAlias_on_conflict = {
  constraint: v1_BrandAlias_constraint;
  update_columns?: Array<v1_BrandAlias_update_column>;
  where?: InputMaybe<v1_BrandAlias_bool_exp>;
};

/** Ordering options when selecting data from "v1.BrandAlias". */
export type v1_BrandAlias_order_by = {
  brand_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  new_brandBase?: InputMaybe<v1_new_brandBase_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BrandAlias */
export type v1_BrandAlias_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BrandAlias" */
export enum v1_BrandAlias_select_column {
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "v1.BrandAlias" */
export type v1_BrandAlias_set_input = {
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type v1_BrandAlias_stddev_fields = {
  __typename?: 'v1_BrandAlias_stddev_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_stddev_order_by = {
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_BrandAlias_stddev_pop_fields = {
  __typename?: 'v1_BrandAlias_stddev_pop_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_stddev_pop_order_by = {
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_BrandAlias_stddev_samp_fields = {
  __typename?: 'v1_BrandAlias_stddev_samp_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_stddev_samp_order_by = {
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_BrandAlias" */
export type v1_BrandAlias_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BrandAlias_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BrandAlias_stream_cursor_value_input = {
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type v1_BrandAlias_sum_fields = {
  __typename?: 'v1_BrandAlias_sum_fields';
  brand_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_sum_order_by = {
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.BrandAlias" */
export enum v1_BrandAlias_update_column {
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  updated_at = 'updated_at'
}

export type v1_BrandAlias_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BrandAlias_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BrandAlias_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BrandAlias_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BrandAlias_var_pop_fields = {
  __typename?: 'v1_BrandAlias_var_pop_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_var_pop_order_by = {
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_BrandAlias_var_samp_fields = {
  __typename?: 'v1_BrandAlias_var_samp_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_var_samp_order_by = {
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_BrandAlias_variance_fields = {
  __typename?: 'v1_BrandAlias_variance_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.BrandAlias" */
export type v1_BrandAlias_variance_order_by = {
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.BrandPriority" */
export type v1_BrandPriority = {
  __typename?: 'v1_BrandPriority';
  /** An array relationship */
  Brand: Array<v1_new_brandBase>;
  /** An aggregate relationship */
  Brand_aggregate: v1_new_brandBase_aggregate;
  id: Scalars['Int']['output'];
  value?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "v1.BrandPriority" */
export type v1_BrandPriorityBrandArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};


/** columns and relationships of "v1.BrandPriority" */
export type v1_BrandPriorityBrand_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};

/** aggregated selection of "v1.BrandPriority" */
export type v1_BrandPriority_aggregate = {
  __typename?: 'v1_BrandPriority_aggregate';
  aggregate?: Maybe<v1_BrandPriority_aggregate_fields>;
  nodes: Array<v1_BrandPriority>;
};

/** aggregate fields of "v1.BrandPriority" */
export type v1_BrandPriority_aggregate_fields = {
  __typename?: 'v1_BrandPriority_aggregate_fields';
  avg?: Maybe<v1_BrandPriority_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_BrandPriority_max_fields>;
  min?: Maybe<v1_BrandPriority_min_fields>;
  stddev?: Maybe<v1_BrandPriority_stddev_fields>;
  stddev_pop?: Maybe<v1_BrandPriority_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_BrandPriority_stddev_samp_fields>;
  sum?: Maybe<v1_BrandPriority_sum_fields>;
  var_pop?: Maybe<v1_BrandPriority_var_pop_fields>;
  var_samp?: Maybe<v1_BrandPriority_var_samp_fields>;
  variance?: Maybe<v1_BrandPriority_variance_fields>;
};


/** aggregate fields of "v1.BrandPriority" */
export type v1_BrandPriority_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_BrandPriority_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_BrandPriority_avg_fields = {
  __typename?: 'v1_BrandPriority_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.BrandPriority". All fields are combined with a logical 'AND'. */
export type v1_BrandPriority_bool_exp = {
  Brand?: InputMaybe<v1_new_brandBase_bool_exp>;
  Brand_aggregate?: InputMaybe<v1_new_brandBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_BrandPriority_bool_exp>>;
  _not?: InputMaybe<v1_BrandPriority_bool_exp>;
  _or?: InputMaybe<Array<v1_BrandPriority_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.BrandPriority" */
export enum v1_BrandPriority_constraint {
  /** unique or primary key constraint on columns "id" */
  BrandPriority_pkey = 'BrandPriority_pkey'
}

/** input type for incrementing numeric columns in table "v1.BrandPriority" */
export type v1_BrandPriority_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.BrandPriority" */
export type v1_BrandPriority_insert_input = {
  Brand?: InputMaybe<v1_new_brandBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_BrandPriority_max_fields = {
  __typename?: 'v1_BrandPriority_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_BrandPriority_min_fields = {
  __typename?: 'v1_BrandPriority_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.BrandPriority" */
export type v1_BrandPriority_mutation_response = {
  __typename?: 'v1_BrandPriority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_BrandPriority>;
};

/** input type for inserting object relation for remote table "v1.BrandPriority" */
export type v1_BrandPriority_obj_rel_insert_input = {
  data: v1_BrandPriority_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_BrandPriority_on_conflict>;
};

/** on_conflict condition type for table "v1.BrandPriority" */
export type v1_BrandPriority_on_conflict = {
  constraint: v1_BrandPriority_constraint;
  update_columns?: Array<v1_BrandPriority_update_column>;
  where?: InputMaybe<v1_BrandPriority_bool_exp>;
};

/** Ordering options when selecting data from "v1.BrandPriority". */
export type v1_BrandPriority_order_by = {
  Brand_aggregate?: InputMaybe<v1_new_brandBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.BrandPriority */
export type v1_BrandPriority_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.BrandPriority" */
export enum v1_BrandPriority_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.BrandPriority" */
export type v1_BrandPriority_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_BrandPriority_stddev_fields = {
  __typename?: 'v1_BrandPriority_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_BrandPriority_stddev_pop_fields = {
  __typename?: 'v1_BrandPriority_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_BrandPriority_stddev_samp_fields = {
  __typename?: 'v1_BrandPriority_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_BrandPriority" */
export type v1_BrandPriority_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_BrandPriority_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_BrandPriority_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_BrandPriority_sum_fields = {
  __typename?: 'v1_BrandPriority_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.BrandPriority" */
export enum v1_BrandPriority_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_BrandPriority_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_BrandPriority_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_BrandPriority_set_input>;
  /** filter the rows which have to be updated */
  where: v1_BrandPriority_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_BrandPriority_var_pop_fields = {
  __typename?: 'v1_BrandPriority_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_BrandPriority_var_samp_fields = {
  __typename?: 'v1_BrandPriority_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_BrandPriority_variance_fields = {
  __typename?: 'v1_BrandPriority_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Campaign" */
export type v1_Campaign = {
  __typename?: 'v1_Campaign';
  /** A computed field, executes function "v1.all_active_posts" */
  AllActivePosts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.approved_posts" */
  ApprovedPosts?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Approver?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  CampaignPriority?: Maybe<v1_CampaignPriority>;
  /** A computed field, executes function "v1.completed_rate_lm" */
  CompletionRateLastMonth?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.completed_rate" */
  CompletionRateThisMonth?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  ContentType?: Maybe<v1_ContentTypes>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  /** A computed field, executes function "v1.declined_posts" */
  DeclinedPosts?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Language?: Maybe<v1_new_languagesBase>;
  /** A computed field, executes function "v1.last_month_posts" */
  LastMonthPosts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.live_posts" */
  LivePosts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.onprogress_posts" */
  OnprogressPosts?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "v1.ordered_posts" */
  OrderedPosts?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "v1.planned_posts" */
  PlannedPosts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.pool_posts" */
  PoolPosts?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An object relationship */
  Project?: Maybe<v1_Project>;
  /** A computed field, executes function "v1.requested_posts" */
  RequestedPosts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.sent_to_publisher_posts" */
  SentToPublisherPosts?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  StatusCode?: Maybe<v1_CampaignStatus>;
  /** An object relationship */
  Strategy?: Maybe<v1_CampaignStrategy>;
  /** A computed field, executes function "v1.this_month_posts" */
  ThisMonthPosts?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Uploader?: Maybe<v1_SystemUserBase>;
  /** A computed field, executes function "v1.waiting_approval_posts" */
  WaitingApprovalPosts?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Writer?: Maybe<v1_ContactBase>;
  allow_upload_content_portal: Scalars['Boolean']['output'];
  approver_id?: Maybe<Scalars['Int']['output']>;
  campaign_crm_id?: Maybe<Scalars['uuid']['output']>;
  content_cost?: Maybe<Scalars['float8']['output']>;
  contenttype_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  free_selection?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  important_pages?: Maybe<Scalars['Int']['output']>;
  keyword_variation?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  new_AllowWriterToAddPosts?: Maybe<Scalars['Boolean']['output']>;
  new_Brand?: Maybe<Scalars['Int']['output']>;
  new_Compound?: Maybe<Scalars['Int']['output']>;
  new_ContactOwner?: Maybe<Scalars['uuid']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Int']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_DoShellyWebsitePlacements?: Maybe<Scalars['Boolean']['output']>;
  new_FollowUp?: Maybe<Scalars['timestamp']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Int']['output']>;
  new_IncludeAnchorTexts?: Maybe<Scalars['Boolean']['output']>;
  new_InstructionsForContentWriters?: Maybe<Scalars['String']['output']>;
  new_LastContentOrder?: Maybe<Scalars['timestamp']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Int']['output']>;
  new_MainPhrase?: Maybe<Scalars['Int']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Int']['output']>;
  new_Month?: Maybe<Scalars['Int']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Int']['output']>;
  new_ParticipatingContact1?: Maybe<Scalars['uuid']['output']>;
  new_ParticipatingContact2?: Maybe<Scalars['uuid']['output']>;
  new_PictureLink?: Maybe<Scalars['Int']['output']>;
  new_PostLength?: Maybe<Scalars['Int']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Int']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Int']['output']>;
  new_Priority?: Maybe<Scalars['Int']['output']>;
  new_ShellyRandom?: Maybe<Scalars['Boolean']['output']>;
  new_StatusChanged?: Maybe<Scalars['timestamp']['output']>;
  new_Tear?: Maybe<Scalars['Int']['output']>;
  new_UploadCost?: Maybe<Scalars['Int']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Int']['output']>;
  new_WriterUploadToSite?: Maybe<Scalars['Boolean']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_defaultcontenttype?: Maybe<Scalars['uuid']['output']>;
  new_includetargeturl?: Maybe<Scalars['Boolean']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  outreach_topics?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_id?: Maybe<Scalars['Int']['output']>;
  uploader_id?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.Campaign" */
export type v1_CampaignPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.Campaign" */
export type v1_CampaignPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** columns and relationships of "v1.CampaignPriority" */
export type v1_CampaignPriority = {
  __typename?: 'v1_CampaignPriority';
  /** An array relationship */
  Campaigns: Array<v1_Campaign>;
  /** An aggregate relationship */
  Campaigns_aggregate: v1_Campaign_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.CampaignPriority" */
export type v1_CampaignPriorityCampaignsArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** columns and relationships of "v1.CampaignPriority" */
export type v1_CampaignPriorityCampaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};

/** aggregated selection of "v1.CampaignPriority" */
export type v1_CampaignPriority_aggregate = {
  __typename?: 'v1_CampaignPriority_aggregate';
  aggregate?: Maybe<v1_CampaignPriority_aggregate_fields>;
  nodes: Array<v1_CampaignPriority>;
};

/** aggregate fields of "v1.CampaignPriority" */
export type v1_CampaignPriority_aggregate_fields = {
  __typename?: 'v1_CampaignPriority_aggregate_fields';
  avg?: Maybe<v1_CampaignPriority_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_CampaignPriority_max_fields>;
  min?: Maybe<v1_CampaignPriority_min_fields>;
  stddev?: Maybe<v1_CampaignPriority_stddev_fields>;
  stddev_pop?: Maybe<v1_CampaignPriority_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_CampaignPriority_stddev_samp_fields>;
  sum?: Maybe<v1_CampaignPriority_sum_fields>;
  var_pop?: Maybe<v1_CampaignPriority_var_pop_fields>;
  var_samp?: Maybe<v1_CampaignPriority_var_samp_fields>;
  variance?: Maybe<v1_CampaignPriority_variance_fields>;
};


/** aggregate fields of "v1.CampaignPriority" */
export type v1_CampaignPriority_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_CampaignPriority_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_CampaignPriority_avg_fields = {
  __typename?: 'v1_CampaignPriority_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.CampaignPriority". All fields are combined with a logical 'AND'. */
export type v1_CampaignPriority_bool_exp = {
  Campaigns?: InputMaybe<v1_Campaign_bool_exp>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_CampaignPriority_bool_exp>>;
  _not?: InputMaybe<v1_CampaignPriority_bool_exp>;
  _or?: InputMaybe<Array<v1_CampaignPriority_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.CampaignPriority" */
export enum v1_CampaignPriority_constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignPriotity_pkey = 'CampaignPriotity_pkey'
}

/** input type for incrementing numeric columns in table "v1.CampaignPriority" */
export type v1_CampaignPriority_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.CampaignPriority" */
export type v1_CampaignPriority_insert_input = {
  Campaigns?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_CampaignPriority_max_fields = {
  __typename?: 'v1_CampaignPriority_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_CampaignPriority_min_fields = {
  __typename?: 'v1_CampaignPriority_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.CampaignPriority" */
export type v1_CampaignPriority_mutation_response = {
  __typename?: 'v1_CampaignPriority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_CampaignPriority>;
};

/** input type for inserting object relation for remote table "v1.CampaignPriority" */
export type v1_CampaignPriority_obj_rel_insert_input = {
  data: v1_CampaignPriority_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_CampaignPriority_on_conflict>;
};

/** on_conflict condition type for table "v1.CampaignPriority" */
export type v1_CampaignPriority_on_conflict = {
  constraint: v1_CampaignPriority_constraint;
  update_columns?: Array<v1_CampaignPriority_update_column>;
  where?: InputMaybe<v1_CampaignPriority_bool_exp>;
};

/** Ordering options when selecting data from "v1.CampaignPriority". */
export type v1_CampaignPriority_order_by = {
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.CampaignPriority */
export type v1_CampaignPriority_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.CampaignPriority" */
export enum v1_CampaignPriority_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.CampaignPriority" */
export type v1_CampaignPriority_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_CampaignPriority_stddev_fields = {
  __typename?: 'v1_CampaignPriority_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_CampaignPriority_stddev_pop_fields = {
  __typename?: 'v1_CampaignPriority_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_CampaignPriority_stddev_samp_fields = {
  __typename?: 'v1_CampaignPriority_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_CampaignPriority" */
export type v1_CampaignPriority_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_CampaignPriority_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_CampaignPriority_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_CampaignPriority_sum_fields = {
  __typename?: 'v1_CampaignPriority_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.CampaignPriority" */
export enum v1_CampaignPriority_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_CampaignPriority_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_CampaignPriority_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_CampaignPriority_set_input>;
  /** filter the rows which have to be updated */
  where: v1_CampaignPriority_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_CampaignPriority_var_pop_fields = {
  __typename?: 'v1_CampaignPriority_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_CampaignPriority_var_samp_fields = {
  __typename?: 'v1_CampaignPriority_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_CampaignPriority_variance_fields = {
  __typename?: 'v1_CampaignPriority_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Campaigns status codes */
export type v1_CampaignStatus = {
  __typename?: 'v1_CampaignStatus';
  /** An array relationship */
  Campaigns: Array<v1_Campaign>;
  /** An aggregate relationship */
  Campaigns_aggregate: v1_Campaign_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** Campaigns status codes */
export type v1_CampaignStatusCampaignsArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** Campaigns status codes */
export type v1_CampaignStatusCampaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};

/** aggregated selection of "v1.CampaignStatus" */
export type v1_CampaignStatus_aggregate = {
  __typename?: 'v1_CampaignStatus_aggregate';
  aggregate?: Maybe<v1_CampaignStatus_aggregate_fields>;
  nodes: Array<v1_CampaignStatus>;
};

/** aggregate fields of "v1.CampaignStatus" */
export type v1_CampaignStatus_aggregate_fields = {
  __typename?: 'v1_CampaignStatus_aggregate_fields';
  avg?: Maybe<v1_CampaignStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_CampaignStatus_max_fields>;
  min?: Maybe<v1_CampaignStatus_min_fields>;
  stddev?: Maybe<v1_CampaignStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_CampaignStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_CampaignStatus_stddev_samp_fields>;
  sum?: Maybe<v1_CampaignStatus_sum_fields>;
  var_pop?: Maybe<v1_CampaignStatus_var_pop_fields>;
  var_samp?: Maybe<v1_CampaignStatus_var_samp_fields>;
  variance?: Maybe<v1_CampaignStatus_variance_fields>;
};


/** aggregate fields of "v1.CampaignStatus" */
export type v1_CampaignStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_CampaignStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_CampaignStatus_avg_fields = {
  __typename?: 'v1_CampaignStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.CampaignStatus". All fields are combined with a logical 'AND'. */
export type v1_CampaignStatus_bool_exp = {
  Campaigns?: InputMaybe<v1_Campaign_bool_exp>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_CampaignStatus_bool_exp>>;
  _not?: InputMaybe<v1_CampaignStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_CampaignStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.CampaignStatus" */
export enum v1_CampaignStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignStatus_pkey = 'CampaignStatus_pkey',
  /** unique or primary key constraint on columns "value" */
  CampaignStatus_value_key = 'CampaignStatus_value_key'
}

/** input type for incrementing numeric columns in table "v1.CampaignStatus" */
export type v1_CampaignStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.CampaignStatus" */
export type v1_CampaignStatus_insert_input = {
  Campaigns?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_CampaignStatus_max_fields = {
  __typename?: 'v1_CampaignStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_CampaignStatus_min_fields = {
  __typename?: 'v1_CampaignStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.CampaignStatus" */
export type v1_CampaignStatus_mutation_response = {
  __typename?: 'v1_CampaignStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_CampaignStatus>;
};

/** input type for inserting object relation for remote table "v1.CampaignStatus" */
export type v1_CampaignStatus_obj_rel_insert_input = {
  data: v1_CampaignStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_CampaignStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.CampaignStatus" */
export type v1_CampaignStatus_on_conflict = {
  constraint: v1_CampaignStatus_constraint;
  update_columns?: Array<v1_CampaignStatus_update_column>;
  where?: InputMaybe<v1_CampaignStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.CampaignStatus". */
export type v1_CampaignStatus_order_by = {
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.CampaignStatus */
export type v1_CampaignStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.CampaignStatus" */
export enum v1_CampaignStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.CampaignStatus" */
export type v1_CampaignStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_CampaignStatus_stddev_fields = {
  __typename?: 'v1_CampaignStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_CampaignStatus_stddev_pop_fields = {
  __typename?: 'v1_CampaignStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_CampaignStatus_stddev_samp_fields = {
  __typename?: 'v1_CampaignStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_CampaignStatus" */
export type v1_CampaignStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_CampaignStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_CampaignStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_CampaignStatus_sum_fields = {
  __typename?: 'v1_CampaignStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.CampaignStatus" */
export enum v1_CampaignStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_CampaignStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_CampaignStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_CampaignStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_CampaignStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_CampaignStatus_var_pop_fields = {
  __typename?: 'v1_CampaignStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_CampaignStatus_var_samp_fields = {
  __typename?: 'v1_CampaignStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_CampaignStatus_variance_fields = {
  __typename?: 'v1_CampaignStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** We use it as Post Strategy and as Campaign Strategy */
export type v1_CampaignStrategy = {
  __typename?: 'v1_CampaignStrategy';
  /** An object relationship */
  CampaignType?: Maybe<v1_CampaignType>;
  /** An array relationship */
  Campaigns: Array<v1_Campaign>;
  /** An aggregate relationship */
  Campaigns_aggregate: v1_Campaign_aggregate;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn: Scalars['timestamp']['output'];
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  Createdby?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  FieldsSet?: Maybe<v1_StrategyFieldsSet>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  Modifiedby?: Maybe<v1_SystemUserBase>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  OwnerIdType: Scalars['Int']['output'];
  OwningBusinessUnit?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  VersionNumber?: Maybe<Scalars['bytea']['output']>;
  campaign_type?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  fieldsset_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  modified_by?: Maybe<Scalars['Int']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['numeric']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_text?: Maybe<Scalars['String']['output']>;
  new_textmode?: Maybe<Scalars['Boolean']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_crm_id?: Maybe<Scalars['uuid']['output']>;
};


/** We use it as Post Strategy and as Campaign Strategy */
export type v1_CampaignStrategyCampaignsArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** We use it as Post Strategy and as Campaign Strategy */
export type v1_CampaignStrategyCampaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** We use it as Post Strategy and as Campaign Strategy */
export type v1_CampaignStrategyPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** We use it as Post Strategy and as Campaign Strategy */
export type v1_CampaignStrategyPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.CampaignStrategy" */
export type v1_CampaignStrategy_aggregate = {
  __typename?: 'v1_CampaignStrategy_aggregate';
  aggregate?: Maybe<v1_CampaignStrategy_aggregate_fields>;
  nodes: Array<v1_CampaignStrategy>;
};

export type v1_CampaignStrategy_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_CampaignStrategy_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_CampaignStrategy_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_CampaignStrategy_aggregate_bool_exp_count>;
};

export type v1_CampaignStrategy_aggregate_bool_exp_bool_and = {
  arguments: v1_CampaignStrategy_select_column_v1_CampaignStrategy_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_CampaignStrategy_aggregate_bool_exp_bool_or = {
  arguments: v1_CampaignStrategy_select_column_v1_CampaignStrategy_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_CampaignStrategy_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.CampaignStrategy" */
export type v1_CampaignStrategy_aggregate_fields = {
  __typename?: 'v1_CampaignStrategy_aggregate_fields';
  avg?: Maybe<v1_CampaignStrategy_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_CampaignStrategy_max_fields>;
  min?: Maybe<v1_CampaignStrategy_min_fields>;
  stddev?: Maybe<v1_CampaignStrategy_stddev_fields>;
  stddev_pop?: Maybe<v1_CampaignStrategy_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_CampaignStrategy_stddev_samp_fields>;
  sum?: Maybe<v1_CampaignStrategy_sum_fields>;
  var_pop?: Maybe<v1_CampaignStrategy_var_pop_fields>;
  var_samp?: Maybe<v1_CampaignStrategy_var_samp_fields>;
  variance?: Maybe<v1_CampaignStrategy_variance_fields>;
};


/** aggregate fields of "v1.CampaignStrategy" */
export type v1_CampaignStrategy_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_aggregate_order_by = {
  avg?: InputMaybe<v1_CampaignStrategy_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_CampaignStrategy_max_order_by>;
  min?: InputMaybe<v1_CampaignStrategy_min_order_by>;
  stddev?: InputMaybe<v1_CampaignStrategy_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_CampaignStrategy_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_CampaignStrategy_stddev_samp_order_by>;
  sum?: InputMaybe<v1_CampaignStrategy_sum_order_by>;
  var_pop?: InputMaybe<v1_CampaignStrategy_var_pop_order_by>;
  var_samp?: InputMaybe<v1_CampaignStrategy_var_samp_order_by>;
  variance?: InputMaybe<v1_CampaignStrategy_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_arr_rel_insert_input = {
  data: Array<v1_CampaignStrategy_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_CampaignStrategy_on_conflict>;
};

/** aggregate avg on columns */
export type v1_CampaignStrategy_avg_fields = {
  __typename?: 'v1_CampaignStrategy_avg_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  campaign_type?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  fieldsset_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  modified_by?: Maybe<Scalars['Float']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_avg_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.CampaignStrategy". All fields are combined with a logical 'AND'. */
export type v1_CampaignStrategy_bool_exp = {
  CampaignType?: InputMaybe<v1_CampaignType_bool_exp>;
  Campaigns?: InputMaybe<v1_Campaign_bool_exp>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  CreatedOnBehalfBy?: InputMaybe<uuid_comparison_exp>;
  Createdby?: InputMaybe<v1_SystemUserBase_bool_exp>;
  FieldsSet?: InputMaybe<v1_StrategyFieldsSet_bool_exp>;
  ImportSequenceNumber?: InputMaybe<Int_comparison_exp>;
  ModifiedBy?: InputMaybe<uuid_comparison_exp>;
  ModifiedOn?: InputMaybe<timestamp_comparison_exp>;
  ModifiedOnBehalfBy?: InputMaybe<uuid_comparison_exp>;
  Modifiedby?: InputMaybe<v1_SystemUserBase_bool_exp>;
  OverriddenCreatedOn?: InputMaybe<timestamp_comparison_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  OwnerIdType?: InputMaybe<Int_comparison_exp>;
  OwningBusinessUnit?: InputMaybe<uuid_comparison_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  TimeZoneRuleVersionNumber?: InputMaybe<Int_comparison_exp>;
  UTCConversionTimeZoneCode?: InputMaybe<Int_comparison_exp>;
  VersionNumber?: InputMaybe<bytea_comparison_exp>;
  _and?: InputMaybe<Array<v1_CampaignStrategy_bool_exp>>;
  _not?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  _or?: InputMaybe<Array<v1_CampaignStrategy_bool_exp>>;
  campaign_type?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  fieldsset_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  modified_by?: InputMaybe<Int_comparison_exp>;
  new_Description?: InputMaybe<String_comparison_exp>;
  new_EstimatedCostPerPost?: InputMaybe<numeric_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_text?: InputMaybe<String_comparison_exp>;
  new_textmode?: InputMaybe<Boolean_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
  strategy_crm_id?: InputMaybe<uuid_comparison_exp>;
};

/** unique or primary key constraints on table "v1.CampaignStrategy" */
export enum v1_CampaignStrategy_constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignStrategy_id_key = 'CampaignStrategy_id_key',
  /** unique or primary key constraint on columns "id" */
  CampaignStrategy_pkey = 'CampaignStrategy_pkey'
}

/** input type for incrementing numeric columns in table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_inc_input = {
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  campaign_type?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  fieldsset_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  modified_by?: InputMaybe<Scalars['Int']['input']>;
  new_EstimatedCostPerPost?: InputMaybe<Scalars['numeric']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_insert_input = {
  CampaignType?: InputMaybe<v1_CampaignType_obj_rel_insert_input>;
  Campaigns?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  Createdby?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  FieldsSet?: InputMaybe<v1_StrategyFieldsSet_obj_rel_insert_input>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  Modifiedby?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  OwningBusinessUnit?: InputMaybe<Scalars['uuid']['input']>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  campaign_type?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  fieldsset_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  modified_by?: InputMaybe<Scalars['Int']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_EstimatedCostPerPost?: InputMaybe<Scalars['numeric']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_text?: InputMaybe<Scalars['String']['input']>;
  new_textmode?: InputMaybe<Scalars['Boolean']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_crm_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type v1_CampaignStrategy_max_fields = {
  __typename?: 'v1_CampaignStrategy_max_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  OwningBusinessUnit?: Maybe<Scalars['uuid']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  campaign_type?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  fieldsset_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  modified_by?: Maybe<Scalars['Int']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['numeric']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_text?: Maybe<Scalars['String']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_crm_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_max_order_by = {
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CreatedOnBehalfBy?: InputMaybe<order_by>;
  ImportSequenceNumber?: InputMaybe<order_by>;
  ModifiedBy?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  ModifiedOnBehalfBy?: InputMaybe<order_by>;
  OverriddenCreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  OwningBusinessUnit?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_text?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_crm_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_CampaignStrategy_min_fields = {
  __typename?: 'v1_CampaignStrategy_min_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  OwningBusinessUnit?: Maybe<Scalars['uuid']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  campaign_type?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  fieldsset_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  modified_by?: Maybe<Scalars['Int']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['numeric']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_text?: Maybe<Scalars['String']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_crm_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_min_order_by = {
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CreatedOnBehalfBy?: InputMaybe<order_by>;
  ImportSequenceNumber?: InputMaybe<order_by>;
  ModifiedBy?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  ModifiedOnBehalfBy?: InputMaybe<order_by>;
  OverriddenCreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  OwningBusinessUnit?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_text?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_crm_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_mutation_response = {
  __typename?: 'v1_CampaignStrategy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_CampaignStrategy>;
};

/** input type for inserting object relation for remote table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_obj_rel_insert_input = {
  data: v1_CampaignStrategy_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_CampaignStrategy_on_conflict>;
};

/** on_conflict condition type for table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_on_conflict = {
  constraint: v1_CampaignStrategy_constraint;
  update_columns?: Array<v1_CampaignStrategy_update_column>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};

/** Ordering options when selecting data from "v1.CampaignStrategy". */
export type v1_CampaignStrategy_order_by = {
  CampaignType?: InputMaybe<v1_CampaignType_order_by>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CreatedOnBehalfBy?: InputMaybe<order_by>;
  Createdby?: InputMaybe<v1_SystemUserBase_order_by>;
  FieldsSet?: InputMaybe<v1_StrategyFieldsSet_order_by>;
  ImportSequenceNumber?: InputMaybe<order_by>;
  ModifiedBy?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  ModifiedOnBehalfBy?: InputMaybe<order_by>;
  Modifiedby?: InputMaybe<v1_SystemUserBase_order_by>;
  OverriddenCreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  OwningBusinessUnit?: InputMaybe<order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  VersionNumber?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_text?: InputMaybe<order_by>;
  new_textmode?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_crm_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.CampaignStrategy */
export type v1_CampaignStrategy_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.CampaignStrategy" */
export enum v1_CampaignStrategy_select_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CreatedOnBehalfBy = 'CreatedOnBehalfBy',
  /** column name */
  ImportSequenceNumber = 'ImportSequenceNumber',
  /** column name */
  ModifiedBy = 'ModifiedBy',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  ModifiedOnBehalfBy = 'ModifiedOnBehalfBy',
  /** column name */
  OverriddenCreatedOn = 'OverriddenCreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  OwnerIdType = 'OwnerIdType',
  /** column name */
  OwningBusinessUnit = 'OwningBusinessUnit',
  /** column name */
  TimeZoneRuleVersionNumber = 'TimeZoneRuleVersionNumber',
  /** column name */
  UTCConversionTimeZoneCode = 'UTCConversionTimeZoneCode',
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  campaign_type = 'campaign_type',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  fieldsset_id = 'fieldsset_id',
  /** column name */
  id = 'id',
  /** column name */
  modified_by = 'modified_by',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_EstimatedCostPerPost = 'new_EstimatedCostPerPost',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_text = 'new_text',
  /** column name */
  new_textmode = 'new_textmode',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  strategy_crm_id = 'strategy_crm_id'
}

/** select "v1_CampaignStrategy_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.CampaignStrategy" */
export enum v1_CampaignStrategy_select_column_v1_CampaignStrategy_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  new_textmode = 'new_textmode'
}

/** select "v1_CampaignStrategy_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.CampaignStrategy" */
export enum v1_CampaignStrategy_select_column_v1_CampaignStrategy_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  new_textmode = 'new_textmode'
}

/** input type for updating data in table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_set_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  OwningBusinessUnit?: InputMaybe<Scalars['uuid']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  campaign_type?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  fieldsset_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  modified_by?: InputMaybe<Scalars['Int']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_EstimatedCostPerPost?: InputMaybe<Scalars['numeric']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_text?: InputMaybe<Scalars['String']['input']>;
  new_textmode?: InputMaybe<Scalars['Boolean']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_crm_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type v1_CampaignStrategy_stddev_fields = {
  __typename?: 'v1_CampaignStrategy_stddev_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  campaign_type?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  fieldsset_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  modified_by?: Maybe<Scalars['Float']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_stddev_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_CampaignStrategy_stddev_pop_fields = {
  __typename?: 'v1_CampaignStrategy_stddev_pop_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  campaign_type?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  fieldsset_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  modified_by?: Maybe<Scalars['Float']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_stddev_pop_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_CampaignStrategy_stddev_samp_fields = {
  __typename?: 'v1_CampaignStrategy_stddev_samp_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  campaign_type?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  fieldsset_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  modified_by?: Maybe<Scalars['Float']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_stddev_samp_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_CampaignStrategy" */
export type v1_CampaignStrategy_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_CampaignStrategy_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_CampaignStrategy_stream_cursor_value_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  OwningBusinessUnit?: InputMaybe<Scalars['uuid']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  campaign_type?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  fieldsset_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  modified_by?: InputMaybe<Scalars['Int']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_EstimatedCostPerPost?: InputMaybe<Scalars['numeric']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_text?: InputMaybe<Scalars['String']['input']>;
  new_textmode?: InputMaybe<Scalars['Boolean']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_crm_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type v1_CampaignStrategy_sum_fields = {
  __typename?: 'v1_CampaignStrategy_sum_fields';
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  campaign_type?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  fieldsset_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  modified_by?: Maybe<Scalars['Int']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['numeric']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_sum_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** update columns of table "v1.CampaignStrategy" */
export enum v1_CampaignStrategy_update_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CreatedOnBehalfBy = 'CreatedOnBehalfBy',
  /** column name */
  ImportSequenceNumber = 'ImportSequenceNumber',
  /** column name */
  ModifiedBy = 'ModifiedBy',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  ModifiedOnBehalfBy = 'ModifiedOnBehalfBy',
  /** column name */
  OverriddenCreatedOn = 'OverriddenCreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  OwnerIdType = 'OwnerIdType',
  /** column name */
  OwningBusinessUnit = 'OwningBusinessUnit',
  /** column name */
  TimeZoneRuleVersionNumber = 'TimeZoneRuleVersionNumber',
  /** column name */
  UTCConversionTimeZoneCode = 'UTCConversionTimeZoneCode',
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  campaign_type = 'campaign_type',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  fieldsset_id = 'fieldsset_id',
  /** column name */
  id = 'id',
  /** column name */
  modified_by = 'modified_by',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_EstimatedCostPerPost = 'new_EstimatedCostPerPost',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_text = 'new_text',
  /** column name */
  new_textmode = 'new_textmode',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  strategy_crm_id = 'strategy_crm_id'
}

export type v1_CampaignStrategy_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_CampaignStrategy_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_CampaignStrategy_set_input>;
  /** filter the rows which have to be updated */
  where: v1_CampaignStrategy_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_CampaignStrategy_var_pop_fields = {
  __typename?: 'v1_CampaignStrategy_var_pop_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  campaign_type?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  fieldsset_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  modified_by?: Maybe<Scalars['Float']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_var_pop_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_CampaignStrategy_var_samp_fields = {
  __typename?: 'v1_CampaignStrategy_var_samp_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  campaign_type?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  fieldsset_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  modified_by?: Maybe<Scalars['Float']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_var_samp_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_CampaignStrategy_variance_fields = {
  __typename?: 'v1_CampaignStrategy_variance_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  campaign_type?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  fieldsset_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  modified_by?: Maybe<Scalars['Float']['output']>;
  new_EstimatedCostPerPost?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.CampaignStrategy" */
export type v1_CampaignStrategy_variance_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  campaign_type?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  fieldsset_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  modified_by?: InputMaybe<order_by>;
  new_EstimatedCostPerPost?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Tyes of campaign */
export type v1_CampaignType = {
  __typename?: 'v1_CampaignType';
  /** An array relationship */
  CampaignStrategies: Array<v1_CampaignStrategy>;
  /** An aggregate relationship */
  CampaignStrategies_aggregate: v1_CampaignStrategy_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** Tyes of campaign */
export type v1_CampaignTypeCampaignStrategiesArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


/** Tyes of campaign */
export type v1_CampaignTypeCampaignStrategies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};

/** aggregated selection of "v1.CampaignType" */
export type v1_CampaignType_aggregate = {
  __typename?: 'v1_CampaignType_aggregate';
  aggregate?: Maybe<v1_CampaignType_aggregate_fields>;
  nodes: Array<v1_CampaignType>;
};

/** aggregate fields of "v1.CampaignType" */
export type v1_CampaignType_aggregate_fields = {
  __typename?: 'v1_CampaignType_aggregate_fields';
  avg?: Maybe<v1_CampaignType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_CampaignType_max_fields>;
  min?: Maybe<v1_CampaignType_min_fields>;
  stddev?: Maybe<v1_CampaignType_stddev_fields>;
  stddev_pop?: Maybe<v1_CampaignType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_CampaignType_stddev_samp_fields>;
  sum?: Maybe<v1_CampaignType_sum_fields>;
  var_pop?: Maybe<v1_CampaignType_var_pop_fields>;
  var_samp?: Maybe<v1_CampaignType_var_samp_fields>;
  variance?: Maybe<v1_CampaignType_variance_fields>;
};


/** aggregate fields of "v1.CampaignType" */
export type v1_CampaignType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_CampaignType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_CampaignType_avg_fields = {
  __typename?: 'v1_CampaignType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.CampaignType". All fields are combined with a logical 'AND'. */
export type v1_CampaignType_bool_exp = {
  CampaignStrategies?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  CampaignStrategies_aggregate?: InputMaybe<v1_CampaignStrategy_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_CampaignType_bool_exp>>;
  _not?: InputMaybe<v1_CampaignType_bool_exp>;
  _or?: InputMaybe<Array<v1_CampaignType_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.CampaignType" */
export enum v1_CampaignType_constraint {
  /** unique or primary key constraint on columns "id" */
  CampaignType_pkey = 'CampaignType_pkey'
}

/** input type for incrementing numeric columns in table "v1.CampaignType" */
export type v1_CampaignType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.CampaignType" */
export type v1_CampaignType_insert_input = {
  CampaignStrategies?: InputMaybe<v1_CampaignStrategy_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_CampaignType_max_fields = {
  __typename?: 'v1_CampaignType_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_CampaignType_min_fields = {
  __typename?: 'v1_CampaignType_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.CampaignType" */
export type v1_CampaignType_mutation_response = {
  __typename?: 'v1_CampaignType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_CampaignType>;
};

/** input type for inserting object relation for remote table "v1.CampaignType" */
export type v1_CampaignType_obj_rel_insert_input = {
  data: v1_CampaignType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_CampaignType_on_conflict>;
};

/** on_conflict condition type for table "v1.CampaignType" */
export type v1_CampaignType_on_conflict = {
  constraint: v1_CampaignType_constraint;
  update_columns?: Array<v1_CampaignType_update_column>;
  where?: InputMaybe<v1_CampaignType_bool_exp>;
};

/** Ordering options when selecting data from "v1.CampaignType". */
export type v1_CampaignType_order_by = {
  CampaignStrategies_aggregate?: InputMaybe<v1_CampaignStrategy_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.CampaignType */
export type v1_CampaignType_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.CampaignType" */
export enum v1_CampaignType_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.CampaignType" */
export type v1_CampaignType_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_CampaignType_stddev_fields = {
  __typename?: 'v1_CampaignType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_CampaignType_stddev_pop_fields = {
  __typename?: 'v1_CampaignType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_CampaignType_stddev_samp_fields = {
  __typename?: 'v1_CampaignType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_CampaignType" */
export type v1_CampaignType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_CampaignType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_CampaignType_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_CampaignType_sum_fields = {
  __typename?: 'v1_CampaignType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.CampaignType" */
export enum v1_CampaignType_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_CampaignType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_CampaignType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_CampaignType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_CampaignType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_CampaignType_var_pop_fields = {
  __typename?: 'v1_CampaignType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_CampaignType_var_samp_fields = {
  __typename?: 'v1_CampaignType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_CampaignType_variance_fields = {
  __typename?: 'v1_CampaignType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.Campaign" */
export type v1_Campaign_aggregate = {
  __typename?: 'v1_Campaign_aggregate';
  aggregate?: Maybe<v1_Campaign_aggregate_fields>;
  nodes: Array<v1_Campaign>;
};

export type v1_Campaign_aggregate_bool_exp = {
  avg?: InputMaybe<v1_Campaign_aggregate_bool_exp_avg>;
  bool_and?: InputMaybe<v1_Campaign_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_Campaign_aggregate_bool_exp_bool_or>;
  corr?: InputMaybe<v1_Campaign_aggregate_bool_exp_corr>;
  count?: InputMaybe<v1_Campaign_aggregate_bool_exp_count>;
  covar_samp?: InputMaybe<v1_Campaign_aggregate_bool_exp_covar_samp>;
  max?: InputMaybe<v1_Campaign_aggregate_bool_exp_max>;
  min?: InputMaybe<v1_Campaign_aggregate_bool_exp_min>;
  stddev_samp?: InputMaybe<v1_Campaign_aggregate_bool_exp_stddev_samp>;
  sum?: InputMaybe<v1_Campaign_aggregate_bool_exp_sum>;
  var_samp?: InputMaybe<v1_Campaign_aggregate_bool_exp_var_samp>;
};

export type v1_Campaign_aggregate_bool_exp_avg = {
  arguments: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_avg_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_bool_and = {
  arguments: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_bool_or = {
  arguments: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_corr = {
  arguments: v1_Campaign_aggregate_bool_exp_corr_arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_corr_arguments = {
  X: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_corr_arguments_columns;
  Y: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_corr_arguments_columns;
};

export type v1_Campaign_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Campaign_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: Int_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_covar_samp = {
  arguments: v1_Campaign_aggregate_bool_exp_covar_samp_arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_covar_samp_arguments = {
  X: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_covar_samp_arguments_columns;
};

export type v1_Campaign_aggregate_bool_exp_max = {
  arguments: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_max_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_min = {
  arguments: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_min_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_stddev_samp = {
  arguments: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_sum = {
  arguments: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_sum_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Campaign_aggregate_bool_exp_var_samp = {
  arguments: v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Campaign_bool_exp>;
  predicate: float8_comparison_exp;
};

/** aggregate fields of "v1.Campaign" */
export type v1_Campaign_aggregate_fields = {
  __typename?: 'v1_Campaign_aggregate_fields';
  avg?: Maybe<v1_Campaign_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Campaign_max_fields>;
  min?: Maybe<v1_Campaign_min_fields>;
  stddev?: Maybe<v1_Campaign_stddev_fields>;
  stddev_pop?: Maybe<v1_Campaign_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Campaign_stddev_samp_fields>;
  sum?: Maybe<v1_Campaign_sum_fields>;
  var_pop?: Maybe<v1_Campaign_var_pop_fields>;
  var_samp?: Maybe<v1_Campaign_var_samp_fields>;
  variance?: Maybe<v1_Campaign_variance_fields>;
};


/** aggregate fields of "v1.Campaign" */
export type v1_Campaign_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Campaign_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Campaign" */
export type v1_Campaign_aggregate_order_by = {
  avg?: InputMaybe<v1_Campaign_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Campaign_max_order_by>;
  min?: InputMaybe<v1_Campaign_min_order_by>;
  stddev?: InputMaybe<v1_Campaign_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Campaign_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Campaign_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Campaign_sum_order_by>;
  var_pop?: InputMaybe<v1_Campaign_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Campaign_var_samp_order_by>;
  variance?: InputMaybe<v1_Campaign_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Campaign" */
export type v1_Campaign_arr_rel_insert_input = {
  data: Array<v1_Campaign_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Campaign_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Campaign_avg_fields = {
  __typename?: 'v1_Campaign_avg_fields';
  approver_id?: Maybe<Scalars['Float']['output']>;
  content_cost?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  free_selection?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  important_pages?: Maybe<Scalars['Float']['output']>;
  keyword_variation?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_Brand?: Maybe<Scalars['Float']['output']>;
  new_Compound?: Maybe<Scalars['Float']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Float']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Float']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Float']['output']>;
  new_MainPhrase?: Maybe<Scalars['Float']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Float']['output']>;
  new_Month?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PictureLink?: Maybe<Scalars['Float']['output']>;
  new_PostLength?: Maybe<Scalars['Float']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Float']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Float']['output']>;
  new_Priority?: Maybe<Scalars['Float']['output']>;
  new_Tear?: Maybe<Scalars['Float']['output']>;
  new_UploadCost?: Maybe<Scalars['Float']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  outreach_topics?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Campaign" */
export type v1_Campaign_avg_order_by = {
  approver_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Campaign". All fields are combined with a logical 'AND'. */
export type v1_Campaign_bool_exp = {
  AllActivePosts?: InputMaybe<Int_comparison_exp>;
  ApprovedPosts?: InputMaybe<Int_comparison_exp>;
  Approver?: InputMaybe<v1_SystemUserBase_bool_exp>;
  CampaignPriority?: InputMaybe<v1_CampaignPriority_bool_exp>;
  CompletionRateLastMonth?: InputMaybe<Int_comparison_exp>;
  CompletionRateThisMonth?: InputMaybe<Int_comparison_exp>;
  ContentType?: InputMaybe<v1_ContentTypes_bool_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  DeclinedPosts?: InputMaybe<Int_comparison_exp>;
  Language?: InputMaybe<v1_new_languagesBase_bool_exp>;
  LastMonthPosts?: InputMaybe<Int_comparison_exp>;
  LivePosts?: InputMaybe<Int_comparison_exp>;
  OnprogressPosts?: InputMaybe<String_comparison_exp>;
  OrderedPosts?: InputMaybe<Int_comparison_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  PlannedPosts?: InputMaybe<Int_comparison_exp>;
  PoolPosts?: InputMaybe<Int_comparison_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  Project?: InputMaybe<v1_Project_bool_exp>;
  RequestedPosts?: InputMaybe<Int_comparison_exp>;
  SentToPublisherPosts?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<v1_CampaignStatus_bool_exp>;
  Strategy?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  ThisMonthPosts?: InputMaybe<Int_comparison_exp>;
  Uploader?: InputMaybe<v1_SystemUserBase_bool_exp>;
  WaitingApprovalPosts?: InputMaybe<Int_comparison_exp>;
  Writer?: InputMaybe<v1_ContactBase_bool_exp>;
  _and?: InputMaybe<Array<v1_Campaign_bool_exp>>;
  _not?: InputMaybe<v1_Campaign_bool_exp>;
  _or?: InputMaybe<Array<v1_Campaign_bool_exp>>;
  allow_upload_content_portal?: InputMaybe<Boolean_comparison_exp>;
  approver_id?: InputMaybe<Int_comparison_exp>;
  campaign_crm_id?: InputMaybe<uuid_comparison_exp>;
  content_cost?: InputMaybe<float8_comparison_exp>;
  contenttype_id?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  free_selection?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  important_pages?: InputMaybe<Int_comparison_exp>;
  keyword_variation?: InputMaybe<Int_comparison_exp>;
  language_id?: InputMaybe<Int_comparison_exp>;
  new_AllowWriterToAddPosts?: InputMaybe<Boolean_comparison_exp>;
  new_Brand?: InputMaybe<Int_comparison_exp>;
  new_Compound?: InputMaybe<Int_comparison_exp>;
  new_ContactOwner?: InputMaybe<uuid_comparison_exp>;
  new_ContentTemplate?: InputMaybe<Int_comparison_exp>;
  new_Description?: InputMaybe<String_comparison_exp>;
  new_DoShellyWebsitePlacements?: InputMaybe<Boolean_comparison_exp>;
  new_FollowUp?: InputMaybe<timestamp_comparison_exp>;
  new_GeneralVariationNoise?: InputMaybe<Int_comparison_exp>;
  new_IncludeAnchorTexts?: InputMaybe<Boolean_comparison_exp>;
  new_InstructionsForContentWriters?: InputMaybe<String_comparison_exp>;
  new_LastContentOrder?: InputMaybe<timestamp_comparison_exp>;
  new_Latest10Articles?: InputMaybe<Int_comparison_exp>;
  new_MainPhrase?: InputMaybe<Int_comparison_exp>;
  new_MinWordsperpost?: InputMaybe<Int_comparison_exp>;
  new_Month?: InputMaybe<Int_comparison_exp>;
  new_MonthlyTarget?: InputMaybe<Int_comparison_exp>;
  new_ParticipatingContact1?: InputMaybe<uuid_comparison_exp>;
  new_ParticipatingContact2?: InputMaybe<uuid_comparison_exp>;
  new_PictureLink?: InputMaybe<Int_comparison_exp>;
  new_PostLength?: InputMaybe<Int_comparison_exp>;
  new_PostsindexedinGoogle?: InputMaybe<Int_comparison_exp>;
  new_PotentialWebsites?: InputMaybe<Int_comparison_exp>;
  new_Priority?: InputMaybe<Int_comparison_exp>;
  new_ShellyRandom?: InputMaybe<Boolean_comparison_exp>;
  new_StatusChanged?: InputMaybe<timestamp_comparison_exp>;
  new_Tear?: InputMaybe<Int_comparison_exp>;
  new_UploadCost?: InputMaybe<Int_comparison_exp>;
  new_UrlAnchors?: InputMaybe<Int_comparison_exp>;
  new_WriterUploadToSite?: InputMaybe<Boolean_comparison_exp>;
  new_Year?: InputMaybe<Int_comparison_exp>;
  new_defaultcontenttype?: InputMaybe<uuid_comparison_exp>;
  new_includetargeturl?: InputMaybe<Boolean_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  outreach_topics?: InputMaybe<Int_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  project_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
  strategy_id?: InputMaybe<Int_comparison_exp>;
  uploader_id?: InputMaybe<Int_comparison_exp>;
  writer_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Campaign" */
export enum v1_Campaign_constraint {
  /** unique or primary key constraint on columns "id" */
  Campaign_id_key = 'Campaign_id_key',
  /** unique or primary key constraint on columns "id" */
  Campaign_pkey = 'Campaign_pkey'
}

/** input type for incrementing numeric columns in table "v1.Campaign" */
export type v1_Campaign_inc_input = {
  approver_id?: InputMaybe<Scalars['Int']['input']>;
  content_cost?: InputMaybe<Scalars['float8']['input']>;
  contenttype_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  free_selection?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  important_pages?: InputMaybe<Scalars['Int']['input']>;
  keyword_variation?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  new_Brand?: InputMaybe<Scalars['Int']['input']>;
  new_Compound?: InputMaybe<Scalars['Int']['input']>;
  new_ContentTemplate?: InputMaybe<Scalars['Int']['input']>;
  new_GeneralVariationNoise?: InputMaybe<Scalars['Int']['input']>;
  new_Latest10Articles?: InputMaybe<Scalars['Int']['input']>;
  new_MainPhrase?: InputMaybe<Scalars['Int']['input']>;
  new_MinWordsperpost?: InputMaybe<Scalars['Int']['input']>;
  new_Month?: InputMaybe<Scalars['Int']['input']>;
  new_MonthlyTarget?: InputMaybe<Scalars['Int']['input']>;
  new_PictureLink?: InputMaybe<Scalars['Int']['input']>;
  new_PostLength?: InputMaybe<Scalars['Int']['input']>;
  new_PostsindexedinGoogle?: InputMaybe<Scalars['Int']['input']>;
  new_PotentialWebsites?: InputMaybe<Scalars['Int']['input']>;
  new_Priority?: InputMaybe<Scalars['Int']['input']>;
  new_Tear?: InputMaybe<Scalars['Int']['input']>;
  new_UploadCost?: InputMaybe<Scalars['Int']['input']>;
  new_UrlAnchors?: InputMaybe<Scalars['Int']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  outreach_topics?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  uploader_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Campaign" */
export type v1_Campaign_insert_input = {
  Approver?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  CampaignPriority?: InputMaybe<v1_CampaignPriority_obj_rel_insert_input>;
  ContentType?: InputMaybe<v1_ContentTypes_obj_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  Language?: InputMaybe<v1_new_languagesBase_obj_rel_insert_input>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  Project?: InputMaybe<v1_Project_obj_rel_insert_input>;
  StatusCode?: InputMaybe<v1_CampaignStatus_obj_rel_insert_input>;
  Strategy?: InputMaybe<v1_CampaignStrategy_obj_rel_insert_input>;
  Uploader?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Writer?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  allow_upload_content_portal?: InputMaybe<Scalars['Boolean']['input']>;
  approver_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  content_cost?: InputMaybe<Scalars['float8']['input']>;
  contenttype_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  free_selection?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  important_pages?: InputMaybe<Scalars['Int']['input']>;
  keyword_variation?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  new_AllowWriterToAddPosts?: InputMaybe<Scalars['Boolean']['input']>;
  new_Brand?: InputMaybe<Scalars['Int']['input']>;
  new_Compound?: InputMaybe<Scalars['Int']['input']>;
  new_ContactOwner?: InputMaybe<Scalars['uuid']['input']>;
  new_ContentTemplate?: InputMaybe<Scalars['Int']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DoShellyWebsitePlacements?: InputMaybe<Scalars['Boolean']['input']>;
  new_FollowUp?: InputMaybe<Scalars['timestamp']['input']>;
  new_GeneralVariationNoise?: InputMaybe<Scalars['Int']['input']>;
  new_IncludeAnchorTexts?: InputMaybe<Scalars['Boolean']['input']>;
  new_InstructionsForContentWriters?: InputMaybe<Scalars['String']['input']>;
  new_LastContentOrder?: InputMaybe<Scalars['timestamp']['input']>;
  new_Latest10Articles?: InputMaybe<Scalars['Int']['input']>;
  new_MainPhrase?: InputMaybe<Scalars['Int']['input']>;
  new_MinWordsperpost?: InputMaybe<Scalars['Int']['input']>;
  new_Month?: InputMaybe<Scalars['Int']['input']>;
  new_MonthlyTarget?: InputMaybe<Scalars['Int']['input']>;
  new_ParticipatingContact1?: InputMaybe<Scalars['uuid']['input']>;
  new_ParticipatingContact2?: InputMaybe<Scalars['uuid']['input']>;
  new_PictureLink?: InputMaybe<Scalars['Int']['input']>;
  new_PostLength?: InputMaybe<Scalars['Int']['input']>;
  new_PostsindexedinGoogle?: InputMaybe<Scalars['Int']['input']>;
  new_PotentialWebsites?: InputMaybe<Scalars['Int']['input']>;
  new_Priority?: InputMaybe<Scalars['Int']['input']>;
  new_ShellyRandom?: InputMaybe<Scalars['Boolean']['input']>;
  new_StatusChanged?: InputMaybe<Scalars['timestamp']['input']>;
  new_Tear?: InputMaybe<Scalars['Int']['input']>;
  new_UploadCost?: InputMaybe<Scalars['Int']['input']>;
  new_UrlAnchors?: InputMaybe<Scalars['Int']['input']>;
  new_WriterUploadToSite?: InputMaybe<Scalars['Boolean']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_defaultcontenttype?: InputMaybe<Scalars['uuid']['input']>;
  new_includetargeturl?: InputMaybe<Scalars['Boolean']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  outreach_topics?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  uploader_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Campaign_max_fields = {
  __typename?: 'v1_Campaign_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  approver_id?: Maybe<Scalars['Int']['output']>;
  campaign_crm_id?: Maybe<Scalars['uuid']['output']>;
  content_cost?: Maybe<Scalars['float8']['output']>;
  contenttype_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  free_selection?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  important_pages?: Maybe<Scalars['Int']['output']>;
  keyword_variation?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  new_Brand?: Maybe<Scalars['Int']['output']>;
  new_Compound?: Maybe<Scalars['Int']['output']>;
  new_ContactOwner?: Maybe<Scalars['uuid']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Int']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_FollowUp?: Maybe<Scalars['timestamp']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Int']['output']>;
  new_InstructionsForContentWriters?: Maybe<Scalars['String']['output']>;
  new_LastContentOrder?: Maybe<Scalars['timestamp']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Int']['output']>;
  new_MainPhrase?: Maybe<Scalars['Int']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Int']['output']>;
  new_Month?: Maybe<Scalars['Int']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Int']['output']>;
  new_ParticipatingContact1?: Maybe<Scalars['uuid']['output']>;
  new_ParticipatingContact2?: Maybe<Scalars['uuid']['output']>;
  new_PictureLink?: Maybe<Scalars['Int']['output']>;
  new_PostLength?: Maybe<Scalars['Int']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Int']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Int']['output']>;
  new_Priority?: Maybe<Scalars['Int']['output']>;
  new_StatusChanged?: Maybe<Scalars['timestamp']['output']>;
  new_Tear?: Maybe<Scalars['Int']['output']>;
  new_UploadCost?: Maybe<Scalars['Int']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_defaultcontenttype?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  outreach_topics?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_id?: Maybe<Scalars['Int']['output']>;
  uploader_id?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Campaign" */
export type v1_Campaign_max_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  campaign_crm_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContactOwner?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_FollowUp?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_InstructionsForContentWriters?: InputMaybe<order_by>;
  new_LastContentOrder?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_ParticipatingContact1?: InputMaybe<order_by>;
  new_ParticipatingContact2?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_StatusChanged?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_defaultcontenttype?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Campaign_min_fields = {
  __typename?: 'v1_Campaign_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  approver_id?: Maybe<Scalars['Int']['output']>;
  campaign_crm_id?: Maybe<Scalars['uuid']['output']>;
  content_cost?: Maybe<Scalars['float8']['output']>;
  contenttype_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  free_selection?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  important_pages?: Maybe<Scalars['Int']['output']>;
  keyword_variation?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  new_Brand?: Maybe<Scalars['Int']['output']>;
  new_Compound?: Maybe<Scalars['Int']['output']>;
  new_ContactOwner?: Maybe<Scalars['uuid']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Int']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_FollowUp?: Maybe<Scalars['timestamp']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Int']['output']>;
  new_InstructionsForContentWriters?: Maybe<Scalars['String']['output']>;
  new_LastContentOrder?: Maybe<Scalars['timestamp']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Int']['output']>;
  new_MainPhrase?: Maybe<Scalars['Int']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Int']['output']>;
  new_Month?: Maybe<Scalars['Int']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Int']['output']>;
  new_ParticipatingContact1?: Maybe<Scalars['uuid']['output']>;
  new_ParticipatingContact2?: Maybe<Scalars['uuid']['output']>;
  new_PictureLink?: Maybe<Scalars['Int']['output']>;
  new_PostLength?: Maybe<Scalars['Int']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Int']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Int']['output']>;
  new_Priority?: Maybe<Scalars['Int']['output']>;
  new_StatusChanged?: Maybe<Scalars['timestamp']['output']>;
  new_Tear?: Maybe<Scalars['Int']['output']>;
  new_UploadCost?: Maybe<Scalars['Int']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_defaultcontenttype?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  outreach_topics?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_id?: Maybe<Scalars['Int']['output']>;
  uploader_id?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Campaign" */
export type v1_Campaign_min_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  campaign_crm_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContactOwner?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_FollowUp?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_InstructionsForContentWriters?: InputMaybe<order_by>;
  new_LastContentOrder?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_ParticipatingContact1?: InputMaybe<order_by>;
  new_ParticipatingContact2?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_StatusChanged?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_defaultcontenttype?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Campaign" */
export type v1_Campaign_mutation_response = {
  __typename?: 'v1_Campaign_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Campaign>;
};

/** input type for inserting object relation for remote table "v1.Campaign" */
export type v1_Campaign_obj_rel_insert_input = {
  data: v1_Campaign_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Campaign_on_conflict>;
};

/** on_conflict condition type for table "v1.Campaign" */
export type v1_Campaign_on_conflict = {
  constraint: v1_Campaign_constraint;
  update_columns?: Array<v1_Campaign_update_column>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};

/** Ordering options when selecting data from "v1.Campaign". */
export type v1_Campaign_order_by = {
  AllActivePosts?: InputMaybe<order_by>;
  ApprovedPosts?: InputMaybe<order_by>;
  Approver?: InputMaybe<v1_SystemUserBase_order_by>;
  CampaignPriority?: InputMaybe<v1_CampaignPriority_order_by>;
  CompletionRateLastMonth?: InputMaybe<order_by>;
  CompletionRateThisMonth?: InputMaybe<order_by>;
  ContentType?: InputMaybe<v1_ContentTypes_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  DeclinedPosts?: InputMaybe<order_by>;
  Language?: InputMaybe<v1_new_languagesBase_order_by>;
  LastMonthPosts?: InputMaybe<order_by>;
  LivePosts?: InputMaybe<order_by>;
  OnprogressPosts?: InputMaybe<order_by>;
  OrderedPosts?: InputMaybe<order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  OwnerId?: InputMaybe<order_by>;
  PlannedPosts?: InputMaybe<order_by>;
  PoolPosts?: InputMaybe<order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  Project?: InputMaybe<v1_Project_order_by>;
  RequestedPosts?: InputMaybe<order_by>;
  SentToPublisherPosts?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<v1_CampaignStatus_order_by>;
  Strategy?: InputMaybe<v1_CampaignStrategy_order_by>;
  ThisMonthPosts?: InputMaybe<order_by>;
  Uploader?: InputMaybe<v1_SystemUserBase_order_by>;
  WaitingApprovalPosts?: InputMaybe<order_by>;
  Writer?: InputMaybe<v1_ContactBase_order_by>;
  allow_upload_content_portal?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  campaign_crm_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_AllowWriterToAddPosts?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContactOwner?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_DoShellyWebsitePlacements?: InputMaybe<order_by>;
  new_FollowUp?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_IncludeAnchorTexts?: InputMaybe<order_by>;
  new_InstructionsForContentWriters?: InputMaybe<order_by>;
  new_LastContentOrder?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_ParticipatingContact1?: InputMaybe<order_by>;
  new_ParticipatingContact2?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_ShellyRandom?: InputMaybe<order_by>;
  new_StatusChanged?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_WriterUploadToSite?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_defaultcontenttype?: InputMaybe<order_by>;
  new_includetargeturl?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Campaign */
export type v1_Campaign_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Campaign" */
export enum v1_Campaign_select_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  approver_id = 'approver_id',
  /** column name */
  campaign_crm_id = 'campaign_crm_id',
  /** column name */
  content_cost = 'content_cost',
  /** column name */
  contenttype_id = 'contenttype_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  free_selection = 'free_selection',
  /** column name */
  id = 'id',
  /** column name */
  important_pages = 'important_pages',
  /** column name */
  keyword_variation = 'keyword_variation',
  /** column name */
  language_id = 'language_id',
  /** column name */
  new_AllowWriterToAddPosts = 'new_AllowWriterToAddPosts',
  /** column name */
  new_Brand = 'new_Brand',
  /** column name */
  new_Compound = 'new_Compound',
  /** column name */
  new_ContactOwner = 'new_ContactOwner',
  /** column name */
  new_ContentTemplate = 'new_ContentTemplate',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_DoShellyWebsitePlacements = 'new_DoShellyWebsitePlacements',
  /** column name */
  new_FollowUp = 'new_FollowUp',
  /** column name */
  new_GeneralVariationNoise = 'new_GeneralVariationNoise',
  /** column name */
  new_IncludeAnchorTexts = 'new_IncludeAnchorTexts',
  /** column name */
  new_InstructionsForContentWriters = 'new_InstructionsForContentWriters',
  /** column name */
  new_LastContentOrder = 'new_LastContentOrder',
  /** column name */
  new_Latest10Articles = 'new_Latest10Articles',
  /** column name */
  new_MainPhrase = 'new_MainPhrase',
  /** column name */
  new_MinWordsperpost = 'new_MinWordsperpost',
  /** column name */
  new_Month = 'new_Month',
  /** column name */
  new_MonthlyTarget = 'new_MonthlyTarget',
  /** column name */
  new_ParticipatingContact1 = 'new_ParticipatingContact1',
  /** column name */
  new_ParticipatingContact2 = 'new_ParticipatingContact2',
  /** column name */
  new_PictureLink = 'new_PictureLink',
  /** column name */
  new_PostLength = 'new_PostLength',
  /** column name */
  new_PostsindexedinGoogle = 'new_PostsindexedinGoogle',
  /** column name */
  new_PotentialWebsites = 'new_PotentialWebsites',
  /** column name */
  new_Priority = 'new_Priority',
  /** column name */
  new_ShellyRandom = 'new_ShellyRandom',
  /** column name */
  new_StatusChanged = 'new_StatusChanged',
  /** column name */
  new_Tear = 'new_Tear',
  /** column name */
  new_UploadCost = 'new_UploadCost',
  /** column name */
  new_UrlAnchors = 'new_UrlAnchors',
  /** column name */
  new_WriterUploadToSite = 'new_WriterUploadToSite',
  /** column name */
  new_Year = 'new_Year',
  /** column name */
  new_defaultcontenttype = 'new_defaultcontenttype',
  /** column name */
  new_includetargeturl = 'new_includetargeturl',
  /** column name */
  new_name = 'new_name',
  /** column name */
  outreach_topics = 'outreach_topics',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  strategy_id = 'strategy_id',
  /** column name */
  uploader_id = 'uploader_id',
  /** column name */
  writer_id = 'writer_id'
}

/** select "v1_Campaign_aggregate_bool_exp_avg_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_avg_arguments_columns {
  /** column name */
  content_cost = 'content_cost'
}

/** select "v1_Campaign_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  new_AllowWriterToAddPosts = 'new_AllowWriterToAddPosts',
  /** column name */
  new_DoShellyWebsitePlacements = 'new_DoShellyWebsitePlacements',
  /** column name */
  new_IncludeAnchorTexts = 'new_IncludeAnchorTexts',
  /** column name */
  new_ShellyRandom = 'new_ShellyRandom',
  /** column name */
  new_WriterUploadToSite = 'new_WriterUploadToSite',
  /** column name */
  new_includetargeturl = 'new_includetargeturl'
}

/** select "v1_Campaign_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  new_AllowWriterToAddPosts = 'new_AllowWriterToAddPosts',
  /** column name */
  new_DoShellyWebsitePlacements = 'new_DoShellyWebsitePlacements',
  /** column name */
  new_IncludeAnchorTexts = 'new_IncludeAnchorTexts',
  /** column name */
  new_ShellyRandom = 'new_ShellyRandom',
  /** column name */
  new_WriterUploadToSite = 'new_WriterUploadToSite',
  /** column name */
  new_includetargeturl = 'new_includetargeturl'
}

/** select "v1_Campaign_aggregate_bool_exp_corr_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_corr_arguments_columns {
  /** column name */
  content_cost = 'content_cost'
}

/** select "v1_Campaign_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_covar_samp_arguments_columns {
  /** column name */
  content_cost = 'content_cost'
}

/** select "v1_Campaign_aggregate_bool_exp_max_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_max_arguments_columns {
  /** column name */
  content_cost = 'content_cost'
}

/** select "v1_Campaign_aggregate_bool_exp_min_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_min_arguments_columns {
  /** column name */
  content_cost = 'content_cost'
}

/** select "v1_Campaign_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_stddev_samp_arguments_columns {
  /** column name */
  content_cost = 'content_cost'
}

/** select "v1_Campaign_aggregate_bool_exp_sum_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_sum_arguments_columns {
  /** column name */
  content_cost = 'content_cost'
}

/** select "v1_Campaign_aggregate_bool_exp_var_samp_arguments_columns" columns of table "v1.Campaign" */
export enum v1_Campaign_select_column_v1_Campaign_aggregate_bool_exp_var_samp_arguments_columns {
  /** column name */
  content_cost = 'content_cost'
}

/** input type for updating data in table "v1.Campaign" */
export type v1_Campaign_set_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  allow_upload_content_portal?: InputMaybe<Scalars['Boolean']['input']>;
  approver_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  content_cost?: InputMaybe<Scalars['float8']['input']>;
  contenttype_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  free_selection?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  important_pages?: InputMaybe<Scalars['Int']['input']>;
  keyword_variation?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  new_AllowWriterToAddPosts?: InputMaybe<Scalars['Boolean']['input']>;
  new_Brand?: InputMaybe<Scalars['Int']['input']>;
  new_Compound?: InputMaybe<Scalars['Int']['input']>;
  new_ContactOwner?: InputMaybe<Scalars['uuid']['input']>;
  new_ContentTemplate?: InputMaybe<Scalars['Int']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DoShellyWebsitePlacements?: InputMaybe<Scalars['Boolean']['input']>;
  new_FollowUp?: InputMaybe<Scalars['timestamp']['input']>;
  new_GeneralVariationNoise?: InputMaybe<Scalars['Int']['input']>;
  new_IncludeAnchorTexts?: InputMaybe<Scalars['Boolean']['input']>;
  new_InstructionsForContentWriters?: InputMaybe<Scalars['String']['input']>;
  new_LastContentOrder?: InputMaybe<Scalars['timestamp']['input']>;
  new_Latest10Articles?: InputMaybe<Scalars['Int']['input']>;
  new_MainPhrase?: InputMaybe<Scalars['Int']['input']>;
  new_MinWordsperpost?: InputMaybe<Scalars['Int']['input']>;
  new_Month?: InputMaybe<Scalars['Int']['input']>;
  new_MonthlyTarget?: InputMaybe<Scalars['Int']['input']>;
  new_ParticipatingContact1?: InputMaybe<Scalars['uuid']['input']>;
  new_ParticipatingContact2?: InputMaybe<Scalars['uuid']['input']>;
  new_PictureLink?: InputMaybe<Scalars['Int']['input']>;
  new_PostLength?: InputMaybe<Scalars['Int']['input']>;
  new_PostsindexedinGoogle?: InputMaybe<Scalars['Int']['input']>;
  new_PotentialWebsites?: InputMaybe<Scalars['Int']['input']>;
  new_Priority?: InputMaybe<Scalars['Int']['input']>;
  new_ShellyRandom?: InputMaybe<Scalars['Boolean']['input']>;
  new_StatusChanged?: InputMaybe<Scalars['timestamp']['input']>;
  new_Tear?: InputMaybe<Scalars['Int']['input']>;
  new_UploadCost?: InputMaybe<Scalars['Int']['input']>;
  new_UrlAnchors?: InputMaybe<Scalars['Int']['input']>;
  new_WriterUploadToSite?: InputMaybe<Scalars['Boolean']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_defaultcontenttype?: InputMaybe<Scalars['uuid']['input']>;
  new_includetargeturl?: InputMaybe<Scalars['Boolean']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  outreach_topics?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  uploader_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Campaign_stddev_fields = {
  __typename?: 'v1_Campaign_stddev_fields';
  approver_id?: Maybe<Scalars['Float']['output']>;
  content_cost?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  free_selection?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  important_pages?: Maybe<Scalars['Float']['output']>;
  keyword_variation?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_Brand?: Maybe<Scalars['Float']['output']>;
  new_Compound?: Maybe<Scalars['Float']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Float']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Float']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Float']['output']>;
  new_MainPhrase?: Maybe<Scalars['Float']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Float']['output']>;
  new_Month?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PictureLink?: Maybe<Scalars['Float']['output']>;
  new_PostLength?: Maybe<Scalars['Float']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Float']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Float']['output']>;
  new_Priority?: Maybe<Scalars['Float']['output']>;
  new_Tear?: Maybe<Scalars['Float']['output']>;
  new_UploadCost?: Maybe<Scalars['Float']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  outreach_topics?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Campaign" */
export type v1_Campaign_stddev_order_by = {
  approver_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Campaign_stddev_pop_fields = {
  __typename?: 'v1_Campaign_stddev_pop_fields';
  approver_id?: Maybe<Scalars['Float']['output']>;
  content_cost?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  free_selection?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  important_pages?: Maybe<Scalars['Float']['output']>;
  keyword_variation?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_Brand?: Maybe<Scalars['Float']['output']>;
  new_Compound?: Maybe<Scalars['Float']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Float']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Float']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Float']['output']>;
  new_MainPhrase?: Maybe<Scalars['Float']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Float']['output']>;
  new_Month?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PictureLink?: Maybe<Scalars['Float']['output']>;
  new_PostLength?: Maybe<Scalars['Float']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Float']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Float']['output']>;
  new_Priority?: Maybe<Scalars['Float']['output']>;
  new_Tear?: Maybe<Scalars['Float']['output']>;
  new_UploadCost?: Maybe<Scalars['Float']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  outreach_topics?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Campaign" */
export type v1_Campaign_stddev_pop_order_by = {
  approver_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Campaign_stddev_samp_fields = {
  __typename?: 'v1_Campaign_stddev_samp_fields';
  approver_id?: Maybe<Scalars['Float']['output']>;
  content_cost?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  free_selection?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  important_pages?: Maybe<Scalars['Float']['output']>;
  keyword_variation?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_Brand?: Maybe<Scalars['Float']['output']>;
  new_Compound?: Maybe<Scalars['Float']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Float']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Float']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Float']['output']>;
  new_MainPhrase?: Maybe<Scalars['Float']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Float']['output']>;
  new_Month?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PictureLink?: Maybe<Scalars['Float']['output']>;
  new_PostLength?: Maybe<Scalars['Float']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Float']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Float']['output']>;
  new_Priority?: Maybe<Scalars['Float']['output']>;
  new_Tear?: Maybe<Scalars['Float']['output']>;
  new_UploadCost?: Maybe<Scalars['Float']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  outreach_topics?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Campaign" */
export type v1_Campaign_stddev_samp_order_by = {
  approver_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Campaign" */
export type v1_Campaign_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Campaign_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Campaign_stream_cursor_value_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  allow_upload_content_portal?: InputMaybe<Scalars['Boolean']['input']>;
  approver_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  content_cost?: InputMaybe<Scalars['float8']['input']>;
  contenttype_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  free_selection?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  important_pages?: InputMaybe<Scalars['Int']['input']>;
  keyword_variation?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  new_AllowWriterToAddPosts?: InputMaybe<Scalars['Boolean']['input']>;
  new_Brand?: InputMaybe<Scalars['Int']['input']>;
  new_Compound?: InputMaybe<Scalars['Int']['input']>;
  new_ContactOwner?: InputMaybe<Scalars['uuid']['input']>;
  new_ContentTemplate?: InputMaybe<Scalars['Int']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DoShellyWebsitePlacements?: InputMaybe<Scalars['Boolean']['input']>;
  new_FollowUp?: InputMaybe<Scalars['timestamp']['input']>;
  new_GeneralVariationNoise?: InputMaybe<Scalars['Int']['input']>;
  new_IncludeAnchorTexts?: InputMaybe<Scalars['Boolean']['input']>;
  new_InstructionsForContentWriters?: InputMaybe<Scalars['String']['input']>;
  new_LastContentOrder?: InputMaybe<Scalars['timestamp']['input']>;
  new_Latest10Articles?: InputMaybe<Scalars['Int']['input']>;
  new_MainPhrase?: InputMaybe<Scalars['Int']['input']>;
  new_MinWordsperpost?: InputMaybe<Scalars['Int']['input']>;
  new_Month?: InputMaybe<Scalars['Int']['input']>;
  new_MonthlyTarget?: InputMaybe<Scalars['Int']['input']>;
  new_ParticipatingContact1?: InputMaybe<Scalars['uuid']['input']>;
  new_ParticipatingContact2?: InputMaybe<Scalars['uuid']['input']>;
  new_PictureLink?: InputMaybe<Scalars['Int']['input']>;
  new_PostLength?: InputMaybe<Scalars['Int']['input']>;
  new_PostsindexedinGoogle?: InputMaybe<Scalars['Int']['input']>;
  new_PotentialWebsites?: InputMaybe<Scalars['Int']['input']>;
  new_Priority?: InputMaybe<Scalars['Int']['input']>;
  new_ShellyRandom?: InputMaybe<Scalars['Boolean']['input']>;
  new_StatusChanged?: InputMaybe<Scalars['timestamp']['input']>;
  new_Tear?: InputMaybe<Scalars['Int']['input']>;
  new_UploadCost?: InputMaybe<Scalars['Int']['input']>;
  new_UrlAnchors?: InputMaybe<Scalars['Int']['input']>;
  new_WriterUploadToSite?: InputMaybe<Scalars['Boolean']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_defaultcontenttype?: InputMaybe<Scalars['uuid']['input']>;
  new_includetargeturl?: InputMaybe<Scalars['Boolean']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  outreach_topics?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  uploader_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Campaign_sum_fields = {
  __typename?: 'v1_Campaign_sum_fields';
  approver_id?: Maybe<Scalars['Int']['output']>;
  content_cost?: Maybe<Scalars['float8']['output']>;
  contenttype_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  free_selection?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  important_pages?: Maybe<Scalars['Int']['output']>;
  keyword_variation?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  new_Brand?: Maybe<Scalars['Int']['output']>;
  new_Compound?: Maybe<Scalars['Int']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Int']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Int']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Int']['output']>;
  new_MainPhrase?: Maybe<Scalars['Int']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Int']['output']>;
  new_Month?: Maybe<Scalars['Int']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Int']['output']>;
  new_PictureLink?: Maybe<Scalars['Int']['output']>;
  new_PostLength?: Maybe<Scalars['Int']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Int']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Int']['output']>;
  new_Priority?: Maybe<Scalars['Int']['output']>;
  new_Tear?: Maybe<Scalars['Int']['output']>;
  new_UploadCost?: Maybe<Scalars['Int']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  outreach_topics?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_id?: Maybe<Scalars['Int']['output']>;
  uploader_id?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Campaign" */
export type v1_Campaign_sum_order_by = {
  approver_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Campaign" */
export enum v1_Campaign_update_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  approver_id = 'approver_id',
  /** column name */
  campaign_crm_id = 'campaign_crm_id',
  /** column name */
  content_cost = 'content_cost',
  /** column name */
  contenttype_id = 'contenttype_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  free_selection = 'free_selection',
  /** column name */
  id = 'id',
  /** column name */
  important_pages = 'important_pages',
  /** column name */
  keyword_variation = 'keyword_variation',
  /** column name */
  language_id = 'language_id',
  /** column name */
  new_AllowWriterToAddPosts = 'new_AllowWriterToAddPosts',
  /** column name */
  new_Brand = 'new_Brand',
  /** column name */
  new_Compound = 'new_Compound',
  /** column name */
  new_ContactOwner = 'new_ContactOwner',
  /** column name */
  new_ContentTemplate = 'new_ContentTemplate',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_DoShellyWebsitePlacements = 'new_DoShellyWebsitePlacements',
  /** column name */
  new_FollowUp = 'new_FollowUp',
  /** column name */
  new_GeneralVariationNoise = 'new_GeneralVariationNoise',
  /** column name */
  new_IncludeAnchorTexts = 'new_IncludeAnchorTexts',
  /** column name */
  new_InstructionsForContentWriters = 'new_InstructionsForContentWriters',
  /** column name */
  new_LastContentOrder = 'new_LastContentOrder',
  /** column name */
  new_Latest10Articles = 'new_Latest10Articles',
  /** column name */
  new_MainPhrase = 'new_MainPhrase',
  /** column name */
  new_MinWordsperpost = 'new_MinWordsperpost',
  /** column name */
  new_Month = 'new_Month',
  /** column name */
  new_MonthlyTarget = 'new_MonthlyTarget',
  /** column name */
  new_ParticipatingContact1 = 'new_ParticipatingContact1',
  /** column name */
  new_ParticipatingContact2 = 'new_ParticipatingContact2',
  /** column name */
  new_PictureLink = 'new_PictureLink',
  /** column name */
  new_PostLength = 'new_PostLength',
  /** column name */
  new_PostsindexedinGoogle = 'new_PostsindexedinGoogle',
  /** column name */
  new_PotentialWebsites = 'new_PotentialWebsites',
  /** column name */
  new_Priority = 'new_Priority',
  /** column name */
  new_ShellyRandom = 'new_ShellyRandom',
  /** column name */
  new_StatusChanged = 'new_StatusChanged',
  /** column name */
  new_Tear = 'new_Tear',
  /** column name */
  new_UploadCost = 'new_UploadCost',
  /** column name */
  new_UrlAnchors = 'new_UrlAnchors',
  /** column name */
  new_WriterUploadToSite = 'new_WriterUploadToSite',
  /** column name */
  new_Year = 'new_Year',
  /** column name */
  new_defaultcontenttype = 'new_defaultcontenttype',
  /** column name */
  new_includetargeturl = 'new_includetargeturl',
  /** column name */
  new_name = 'new_name',
  /** column name */
  outreach_topics = 'outreach_topics',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  strategy_id = 'strategy_id',
  /** column name */
  uploader_id = 'uploader_id',
  /** column name */
  writer_id = 'writer_id'
}

export type v1_Campaign_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Campaign_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Campaign_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Campaign_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Campaign_var_pop_fields = {
  __typename?: 'v1_Campaign_var_pop_fields';
  approver_id?: Maybe<Scalars['Float']['output']>;
  content_cost?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  free_selection?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  important_pages?: Maybe<Scalars['Float']['output']>;
  keyword_variation?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_Brand?: Maybe<Scalars['Float']['output']>;
  new_Compound?: Maybe<Scalars['Float']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Float']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Float']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Float']['output']>;
  new_MainPhrase?: Maybe<Scalars['Float']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Float']['output']>;
  new_Month?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PictureLink?: Maybe<Scalars['Float']['output']>;
  new_PostLength?: Maybe<Scalars['Float']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Float']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Float']['output']>;
  new_Priority?: Maybe<Scalars['Float']['output']>;
  new_Tear?: Maybe<Scalars['Float']['output']>;
  new_UploadCost?: Maybe<Scalars['Float']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  outreach_topics?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Campaign" */
export type v1_Campaign_var_pop_order_by = {
  approver_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Campaign_var_samp_fields = {
  __typename?: 'v1_Campaign_var_samp_fields';
  approver_id?: Maybe<Scalars['Float']['output']>;
  content_cost?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  free_selection?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  important_pages?: Maybe<Scalars['Float']['output']>;
  keyword_variation?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_Brand?: Maybe<Scalars['Float']['output']>;
  new_Compound?: Maybe<Scalars['Float']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Float']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Float']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Float']['output']>;
  new_MainPhrase?: Maybe<Scalars['Float']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Float']['output']>;
  new_Month?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PictureLink?: Maybe<Scalars['Float']['output']>;
  new_PostLength?: Maybe<Scalars['Float']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Float']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Float']['output']>;
  new_Priority?: Maybe<Scalars['Float']['output']>;
  new_Tear?: Maybe<Scalars['Float']['output']>;
  new_UploadCost?: Maybe<Scalars['Float']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  outreach_topics?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Campaign" */
export type v1_Campaign_var_samp_order_by = {
  approver_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Campaign_variance_fields = {
  __typename?: 'v1_Campaign_variance_fields';
  approver_id?: Maybe<Scalars['Float']['output']>;
  content_cost?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  free_selection?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  important_pages?: Maybe<Scalars['Float']['output']>;
  keyword_variation?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_Brand?: Maybe<Scalars['Float']['output']>;
  new_Compound?: Maybe<Scalars['Float']['output']>;
  new_ContentTemplate?: Maybe<Scalars['Float']['output']>;
  new_GeneralVariationNoise?: Maybe<Scalars['Float']['output']>;
  new_Latest10Articles?: Maybe<Scalars['Float']['output']>;
  new_MainPhrase?: Maybe<Scalars['Float']['output']>;
  new_MinWordsperpost?: Maybe<Scalars['Float']['output']>;
  new_Month?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PictureLink?: Maybe<Scalars['Float']['output']>;
  new_PostLength?: Maybe<Scalars['Float']['output']>;
  new_PostsindexedinGoogle?: Maybe<Scalars['Float']['output']>;
  new_PotentialWebsites?: Maybe<Scalars['Float']['output']>;
  new_Priority?: Maybe<Scalars['Float']['output']>;
  new_Tear?: Maybe<Scalars['Float']['output']>;
  new_UploadCost?: Maybe<Scalars['Float']['output']>;
  new_UrlAnchors?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  outreach_topics?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Campaign" */
export type v1_Campaign_variance_order_by = {
  approver_id?: InputMaybe<order_by>;
  content_cost?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  free_selection?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  important_pages?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_Compound?: InputMaybe<order_by>;
  new_ContentTemplate?: InputMaybe<order_by>;
  new_GeneralVariationNoise?: InputMaybe<order_by>;
  new_Latest10Articles?: InputMaybe<order_by>;
  new_MainPhrase?: InputMaybe<order_by>;
  new_MinWordsperpost?: InputMaybe<order_by>;
  new_Month?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PictureLink?: InputMaybe<order_by>;
  new_PostLength?: InputMaybe<order_by>;
  new_PostsindexedinGoogle?: InputMaybe<order_by>;
  new_PotentialWebsites?: InputMaybe<order_by>;
  new_Priority?: InputMaybe<order_by>;
  new_Tear?: InputMaybe<order_by>;
  new_UploadCost?: InputMaybe<order_by>;
  new_UrlAnchors?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  outreach_topics?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** Project categories */
export type v1_Categories = {
  __typename?: 'v1_Categories';
  /** An array relationship */
  Projects: Array<v1_Project>;
  /** An aggregate relationship */
  Projects_aggregate: v1_Project_aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


/** Project categories */
export type v1_CategoriesProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** Project categories */
export type v1_CategoriesProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};

/** aggregated selection of "v1.Categories" */
export type v1_Categories_aggregate = {
  __typename?: 'v1_Categories_aggregate';
  aggregate?: Maybe<v1_Categories_aggregate_fields>;
  nodes: Array<v1_Categories>;
};

/** aggregate fields of "v1.Categories" */
export type v1_Categories_aggregate_fields = {
  __typename?: 'v1_Categories_aggregate_fields';
  avg?: Maybe<v1_Categories_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Categories_max_fields>;
  min?: Maybe<v1_Categories_min_fields>;
  stddev?: Maybe<v1_Categories_stddev_fields>;
  stddev_pop?: Maybe<v1_Categories_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Categories_stddev_samp_fields>;
  sum?: Maybe<v1_Categories_sum_fields>;
  var_pop?: Maybe<v1_Categories_var_pop_fields>;
  var_samp?: Maybe<v1_Categories_var_samp_fields>;
  variance?: Maybe<v1_Categories_variance_fields>;
};


/** aggregate fields of "v1.Categories" */
export type v1_Categories_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Categories_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Categories_avg_fields = {
  __typename?: 'v1_Categories_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Categories". All fields are combined with a logical 'AND'. */
export type v1_Categories_bool_exp = {
  Projects?: InputMaybe<v1_Project_bool_exp>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_Categories_bool_exp>>;
  _not?: InputMaybe<v1_Categories_bool_exp>;
  _or?: InputMaybe<Array<v1_Categories_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Categories" */
export enum v1_Categories_constraint {
  /** unique or primary key constraint on columns "id" */
  PK_topic = 'PK_topic'
}

/** input type for incrementing numeric columns in table "v1.Categories" */
export type v1_Categories_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Categories" */
export type v1_Categories_insert_input = {
  Projects?: InputMaybe<v1_Project_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Categories_max_fields = {
  __typename?: 'v1_Categories_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_Categories_min_fields = {
  __typename?: 'v1_Categories_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.Categories" */
export type v1_Categories_mutation_response = {
  __typename?: 'v1_Categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Categories>;
};

/** input type for inserting object relation for remote table "v1.Categories" */
export type v1_Categories_obj_rel_insert_input = {
  data: v1_Categories_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Categories_on_conflict>;
};

/** on_conflict condition type for table "v1.Categories" */
export type v1_Categories_on_conflict = {
  constraint: v1_Categories_constraint;
  update_columns?: Array<v1_Categories_update_column>;
  where?: InputMaybe<v1_Categories_bool_exp>;
};

/** Ordering options when selecting data from "v1.Categories". */
export type v1_Categories_order_by = {
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Categories */
export type v1_Categories_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Categories" */
export enum v1_Categories_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.Categories" */
export type v1_Categories_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Categories_stddev_fields = {
  __typename?: 'v1_Categories_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Categories_stddev_pop_fields = {
  __typename?: 'v1_Categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Categories_stddev_samp_fields = {
  __typename?: 'v1_Categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Categories" */
export type v1_Categories_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Categories_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Categories_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Categories_sum_fields = {
  __typename?: 'v1_Categories_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Categories" */
export enum v1_Categories_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_Categories_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Categories_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Categories_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Categories_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Categories_var_pop_fields = {
  __typename?: 'v1_Categories_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Categories_var_samp_fields = {
  __typename?: 'v1_Categories_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Categories_variance_fields = {
  __typename?: 'v1_Categories_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Checkout" */
export type v1_Checkout = {
  __typename?: 'v1_Checkout';
  /** An array relationship */
  CheckoutDetails: Array<v1_CheckoutDetail>;
  /** An aggregate relationship */
  CheckoutDetails_aggregate: v1_CheckoutDetail_aggregate;
  /** An object relationship */
  Status: v1_CheckoutStatus;
  /** An object relationship */
  User: v1_ContactBase;
  id: Scalars['Int']['output'];
  statecode: Scalars['Int']['output'];
  status_id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.Checkout" */
export type v1_CheckoutCheckoutDetailsArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


/** columns and relationships of "v1.Checkout" */
export type v1_CheckoutCheckoutDetails_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};

/** columns and relationships of "v1.CheckoutDetail" */
export type v1_CheckoutDetail = {
  __typename?: 'v1_CheckoutDetail';
  /** An object relationship */
  Checkout: v1_Checkout;
  /** An object relationship */
  Niche?: Maybe<v1_PostNiche>;
  /** An object relationship */
  Offer?: Maybe<v1_Offer>;
  /** An object relationship */
  Post?: Maybe<v1_Posts>;
  /** An object relationship */
  PriceStep?: Maybe<v1_LanguagePriceStep>;
  base_price: Scalars['numeric']['output'];
  checkout_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  niche_fee: Scalars['numeric']['output'];
  niche_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  price: Scalars['numeric']['output'];
  step_id?: Maybe<Scalars['Int']['output']>;
  word_fee: Scalars['numeric']['output'];
};

/** aggregated selection of "v1.CheckoutDetail" */
export type v1_CheckoutDetail_aggregate = {
  __typename?: 'v1_CheckoutDetail_aggregate';
  aggregate?: Maybe<v1_CheckoutDetail_aggregate_fields>;
  nodes: Array<v1_CheckoutDetail>;
};

export type v1_CheckoutDetail_aggregate_bool_exp = {
  count?: InputMaybe<v1_CheckoutDetail_aggregate_bool_exp_count>;
};

export type v1_CheckoutDetail_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_CheckoutDetail_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.CheckoutDetail" */
export type v1_CheckoutDetail_aggregate_fields = {
  __typename?: 'v1_CheckoutDetail_aggregate_fields';
  avg?: Maybe<v1_CheckoutDetail_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_CheckoutDetail_max_fields>;
  min?: Maybe<v1_CheckoutDetail_min_fields>;
  stddev?: Maybe<v1_CheckoutDetail_stddev_fields>;
  stddev_pop?: Maybe<v1_CheckoutDetail_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_CheckoutDetail_stddev_samp_fields>;
  sum?: Maybe<v1_CheckoutDetail_sum_fields>;
  var_pop?: Maybe<v1_CheckoutDetail_var_pop_fields>;
  var_samp?: Maybe<v1_CheckoutDetail_var_samp_fields>;
  variance?: Maybe<v1_CheckoutDetail_variance_fields>;
};


/** aggregate fields of "v1.CheckoutDetail" */
export type v1_CheckoutDetail_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_aggregate_order_by = {
  avg?: InputMaybe<v1_CheckoutDetail_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_CheckoutDetail_max_order_by>;
  min?: InputMaybe<v1_CheckoutDetail_min_order_by>;
  stddev?: InputMaybe<v1_CheckoutDetail_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_CheckoutDetail_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_CheckoutDetail_stddev_samp_order_by>;
  sum?: InputMaybe<v1_CheckoutDetail_sum_order_by>;
  var_pop?: InputMaybe<v1_CheckoutDetail_var_pop_order_by>;
  var_samp?: InputMaybe<v1_CheckoutDetail_var_samp_order_by>;
  variance?: InputMaybe<v1_CheckoutDetail_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_arr_rel_insert_input = {
  data: Array<v1_CheckoutDetail_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_CheckoutDetail_on_conflict>;
};

/** aggregate avg on columns */
export type v1_CheckoutDetail_avg_fields = {
  __typename?: 'v1_CheckoutDetail_avg_fields';
  base_price?: Maybe<Scalars['Float']['output']>;
  checkout_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  niche_fee?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step_id?: Maybe<Scalars['Float']['output']>;
  word_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_avg_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.CheckoutDetail". All fields are combined with a logical 'AND'. */
export type v1_CheckoutDetail_bool_exp = {
  Checkout?: InputMaybe<v1_Checkout_bool_exp>;
  Niche?: InputMaybe<v1_PostNiche_bool_exp>;
  Offer?: InputMaybe<v1_Offer_bool_exp>;
  Post?: InputMaybe<v1_Posts_bool_exp>;
  PriceStep?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
  _and?: InputMaybe<Array<v1_CheckoutDetail_bool_exp>>;
  _not?: InputMaybe<v1_CheckoutDetail_bool_exp>;
  _or?: InputMaybe<Array<v1_CheckoutDetail_bool_exp>>;
  base_price?: InputMaybe<numeric_comparison_exp>;
  checkout_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  niche_fee?: InputMaybe<numeric_comparison_exp>;
  niche_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  post_id?: InputMaybe<Int_comparison_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  step_id?: InputMaybe<Int_comparison_exp>;
  word_fee?: InputMaybe<numeric_comparison_exp>;
};

/** unique or primary key constraints on table "v1.CheckoutDetail" */
export enum v1_CheckoutDetail_constraint {
  /** unique or primary key constraint on columns "id" */
  CheckoutDetail_pkey = 'CheckoutDetail_pkey'
}

/** input type for incrementing numeric columns in table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_inc_input = {
  base_price?: InputMaybe<Scalars['numeric']['input']>;
  checkout_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  niche_fee?: InputMaybe<Scalars['numeric']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  step_id?: InputMaybe<Scalars['Int']['input']>;
  word_fee?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_insert_input = {
  Checkout?: InputMaybe<v1_Checkout_obj_rel_insert_input>;
  Niche?: InputMaybe<v1_PostNiche_obj_rel_insert_input>;
  Offer?: InputMaybe<v1_Offer_obj_rel_insert_input>;
  Post?: InputMaybe<v1_Posts_obj_rel_insert_input>;
  PriceStep?: InputMaybe<v1_LanguagePriceStep_obj_rel_insert_input>;
  base_price?: InputMaybe<Scalars['numeric']['input']>;
  checkout_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  niche_fee?: InputMaybe<Scalars['numeric']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  step_id?: InputMaybe<Scalars['Int']['input']>;
  word_fee?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type v1_CheckoutDetail_max_fields = {
  __typename?: 'v1_CheckoutDetail_max_fields';
  base_price?: Maybe<Scalars['numeric']['output']>;
  checkout_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  niche_fee?: Maybe<Scalars['numeric']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  step_id?: Maybe<Scalars['Int']['output']>;
  word_fee?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_max_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_CheckoutDetail_min_fields = {
  __typename?: 'v1_CheckoutDetail_min_fields';
  base_price?: Maybe<Scalars['numeric']['output']>;
  checkout_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  niche_fee?: Maybe<Scalars['numeric']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  step_id?: Maybe<Scalars['Int']['output']>;
  word_fee?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_min_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_mutation_response = {
  __typename?: 'v1_CheckoutDetail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_CheckoutDetail>;
};

/** on_conflict condition type for table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_on_conflict = {
  constraint: v1_CheckoutDetail_constraint;
  update_columns?: Array<v1_CheckoutDetail_update_column>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};

/** Ordering options when selecting data from "v1.CheckoutDetail". */
export type v1_CheckoutDetail_order_by = {
  Checkout?: InputMaybe<v1_Checkout_order_by>;
  Niche?: InputMaybe<v1_PostNiche_order_by>;
  Offer?: InputMaybe<v1_Offer_order_by>;
  Post?: InputMaybe<v1_Posts_order_by>;
  PriceStep?: InputMaybe<v1_LanguagePriceStep_order_by>;
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.CheckoutDetail */
export type v1_CheckoutDetail_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.CheckoutDetail" */
export enum v1_CheckoutDetail_select_column {
  /** column name */
  base_price = 'base_price',
  /** column name */
  checkout_id = 'checkout_id',
  /** column name */
  id = 'id',
  /** column name */
  niche_fee = 'niche_fee',
  /** column name */
  niche_id = 'niche_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  post_id = 'post_id',
  /** column name */
  price = 'price',
  /** column name */
  step_id = 'step_id',
  /** column name */
  word_fee = 'word_fee'
}

/** input type for updating data in table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_set_input = {
  base_price?: InputMaybe<Scalars['numeric']['input']>;
  checkout_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  niche_fee?: InputMaybe<Scalars['numeric']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  step_id?: InputMaybe<Scalars['Int']['input']>;
  word_fee?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type v1_CheckoutDetail_stddev_fields = {
  __typename?: 'v1_CheckoutDetail_stddev_fields';
  base_price?: Maybe<Scalars['Float']['output']>;
  checkout_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  niche_fee?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step_id?: Maybe<Scalars['Float']['output']>;
  word_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_stddev_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_CheckoutDetail_stddev_pop_fields = {
  __typename?: 'v1_CheckoutDetail_stddev_pop_fields';
  base_price?: Maybe<Scalars['Float']['output']>;
  checkout_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  niche_fee?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step_id?: Maybe<Scalars['Float']['output']>;
  word_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_stddev_pop_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_CheckoutDetail_stddev_samp_fields = {
  __typename?: 'v1_CheckoutDetail_stddev_samp_fields';
  base_price?: Maybe<Scalars['Float']['output']>;
  checkout_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  niche_fee?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step_id?: Maybe<Scalars['Float']['output']>;
  word_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_stddev_samp_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_CheckoutDetail" */
export type v1_CheckoutDetail_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_CheckoutDetail_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_CheckoutDetail_stream_cursor_value_input = {
  base_price?: InputMaybe<Scalars['numeric']['input']>;
  checkout_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  niche_fee?: InputMaybe<Scalars['numeric']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  step_id?: InputMaybe<Scalars['Int']['input']>;
  word_fee?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type v1_CheckoutDetail_sum_fields = {
  __typename?: 'v1_CheckoutDetail_sum_fields';
  base_price?: Maybe<Scalars['numeric']['output']>;
  checkout_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  niche_fee?: Maybe<Scalars['numeric']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  step_id?: Maybe<Scalars['Int']['output']>;
  word_fee?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_sum_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** update columns of table "v1.CheckoutDetail" */
export enum v1_CheckoutDetail_update_column {
  /** column name */
  base_price = 'base_price',
  /** column name */
  checkout_id = 'checkout_id',
  /** column name */
  id = 'id',
  /** column name */
  niche_fee = 'niche_fee',
  /** column name */
  niche_id = 'niche_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  post_id = 'post_id',
  /** column name */
  price = 'price',
  /** column name */
  step_id = 'step_id',
  /** column name */
  word_fee = 'word_fee'
}

export type v1_CheckoutDetail_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_CheckoutDetail_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_CheckoutDetail_set_input>;
  /** filter the rows which have to be updated */
  where: v1_CheckoutDetail_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_CheckoutDetail_var_pop_fields = {
  __typename?: 'v1_CheckoutDetail_var_pop_fields';
  base_price?: Maybe<Scalars['Float']['output']>;
  checkout_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  niche_fee?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step_id?: Maybe<Scalars['Float']['output']>;
  word_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_var_pop_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_CheckoutDetail_var_samp_fields = {
  __typename?: 'v1_CheckoutDetail_var_samp_fields';
  base_price?: Maybe<Scalars['Float']['output']>;
  checkout_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  niche_fee?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step_id?: Maybe<Scalars['Float']['output']>;
  word_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_var_samp_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_CheckoutDetail_variance_fields = {
  __typename?: 'v1_CheckoutDetail_variance_fields';
  base_price?: Maybe<Scalars['Float']['output']>;
  checkout_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  niche_fee?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step_id?: Maybe<Scalars['Float']['output']>;
  word_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.CheckoutDetail" */
export type v1_CheckoutDetail_variance_order_by = {
  base_price?: InputMaybe<order_by>;
  checkout_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  niche_fee?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step_id?: InputMaybe<order_by>;
  word_fee?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.CheckoutStatus" */
export type v1_CheckoutStatus = {
  __typename?: 'v1_CheckoutStatus';
  /** An array relationship */
  Checkouts: Array<v1_Checkout>;
  /** An aggregate relationship */
  Checkouts_aggregate: v1_Checkout_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.CheckoutStatus" */
export type v1_CheckoutStatusCheckoutsArgs = {
  distinct_on?: InputMaybe<Array<v1_Checkout_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Checkout_order_by>>;
  where?: InputMaybe<v1_Checkout_bool_exp>;
};


/** columns and relationships of "v1.CheckoutStatus" */
export type v1_CheckoutStatusCheckouts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Checkout_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Checkout_order_by>>;
  where?: InputMaybe<v1_Checkout_bool_exp>;
};

/** aggregated selection of "v1.CheckoutStatus" */
export type v1_CheckoutStatus_aggregate = {
  __typename?: 'v1_CheckoutStatus_aggregate';
  aggregate?: Maybe<v1_CheckoutStatus_aggregate_fields>;
  nodes: Array<v1_CheckoutStatus>;
};

/** aggregate fields of "v1.CheckoutStatus" */
export type v1_CheckoutStatus_aggregate_fields = {
  __typename?: 'v1_CheckoutStatus_aggregate_fields';
  avg?: Maybe<v1_CheckoutStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_CheckoutStatus_max_fields>;
  min?: Maybe<v1_CheckoutStatus_min_fields>;
  stddev?: Maybe<v1_CheckoutStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_CheckoutStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_CheckoutStatus_stddev_samp_fields>;
  sum?: Maybe<v1_CheckoutStatus_sum_fields>;
  var_pop?: Maybe<v1_CheckoutStatus_var_pop_fields>;
  var_samp?: Maybe<v1_CheckoutStatus_var_samp_fields>;
  variance?: Maybe<v1_CheckoutStatus_variance_fields>;
};


/** aggregate fields of "v1.CheckoutStatus" */
export type v1_CheckoutStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_CheckoutStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_CheckoutStatus_avg_fields = {
  __typename?: 'v1_CheckoutStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.CheckoutStatus". All fields are combined with a logical 'AND'. */
export type v1_CheckoutStatus_bool_exp = {
  Checkouts?: InputMaybe<v1_Checkout_bool_exp>;
  Checkouts_aggregate?: InputMaybe<v1_Checkout_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_CheckoutStatus_bool_exp>>;
  _not?: InputMaybe<v1_CheckoutStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_CheckoutStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.CheckoutStatus" */
export enum v1_CheckoutStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  CheckoutStatus_pkey = 'CheckoutStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.CheckoutStatus" */
export type v1_CheckoutStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.CheckoutStatus" */
export type v1_CheckoutStatus_insert_input = {
  Checkouts?: InputMaybe<v1_Checkout_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_CheckoutStatus_max_fields = {
  __typename?: 'v1_CheckoutStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_CheckoutStatus_min_fields = {
  __typename?: 'v1_CheckoutStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.CheckoutStatus" */
export type v1_CheckoutStatus_mutation_response = {
  __typename?: 'v1_CheckoutStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_CheckoutStatus>;
};

/** input type for inserting object relation for remote table "v1.CheckoutStatus" */
export type v1_CheckoutStatus_obj_rel_insert_input = {
  data: v1_CheckoutStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_CheckoutStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.CheckoutStatus" */
export type v1_CheckoutStatus_on_conflict = {
  constraint: v1_CheckoutStatus_constraint;
  update_columns?: Array<v1_CheckoutStatus_update_column>;
  where?: InputMaybe<v1_CheckoutStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.CheckoutStatus". */
export type v1_CheckoutStatus_order_by = {
  Checkouts_aggregate?: InputMaybe<v1_Checkout_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.CheckoutStatus */
export type v1_CheckoutStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.CheckoutStatus" */
export enum v1_CheckoutStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.CheckoutStatus" */
export type v1_CheckoutStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_CheckoutStatus_stddev_fields = {
  __typename?: 'v1_CheckoutStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_CheckoutStatus_stddev_pop_fields = {
  __typename?: 'v1_CheckoutStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_CheckoutStatus_stddev_samp_fields = {
  __typename?: 'v1_CheckoutStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_CheckoutStatus" */
export type v1_CheckoutStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_CheckoutStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_CheckoutStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_CheckoutStatus_sum_fields = {
  __typename?: 'v1_CheckoutStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.CheckoutStatus" */
export enum v1_CheckoutStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_CheckoutStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_CheckoutStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_CheckoutStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_CheckoutStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_CheckoutStatus_var_pop_fields = {
  __typename?: 'v1_CheckoutStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_CheckoutStatus_var_samp_fields = {
  __typename?: 'v1_CheckoutStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_CheckoutStatus_variance_fields = {
  __typename?: 'v1_CheckoutStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.Checkout" */
export type v1_Checkout_aggregate = {
  __typename?: 'v1_Checkout_aggregate';
  aggregate?: Maybe<v1_Checkout_aggregate_fields>;
  nodes: Array<v1_Checkout>;
};

export type v1_Checkout_aggregate_bool_exp = {
  count?: InputMaybe<v1_Checkout_aggregate_bool_exp_count>;
};

export type v1_Checkout_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Checkout_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Checkout_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Checkout" */
export type v1_Checkout_aggregate_fields = {
  __typename?: 'v1_Checkout_aggregate_fields';
  avg?: Maybe<v1_Checkout_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Checkout_max_fields>;
  min?: Maybe<v1_Checkout_min_fields>;
  stddev?: Maybe<v1_Checkout_stddev_fields>;
  stddev_pop?: Maybe<v1_Checkout_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Checkout_stddev_samp_fields>;
  sum?: Maybe<v1_Checkout_sum_fields>;
  var_pop?: Maybe<v1_Checkout_var_pop_fields>;
  var_samp?: Maybe<v1_Checkout_var_samp_fields>;
  variance?: Maybe<v1_Checkout_variance_fields>;
};


/** aggregate fields of "v1.Checkout" */
export type v1_Checkout_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Checkout_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Checkout" */
export type v1_Checkout_aggregate_order_by = {
  avg?: InputMaybe<v1_Checkout_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Checkout_max_order_by>;
  min?: InputMaybe<v1_Checkout_min_order_by>;
  stddev?: InputMaybe<v1_Checkout_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Checkout_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Checkout_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Checkout_sum_order_by>;
  var_pop?: InputMaybe<v1_Checkout_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Checkout_var_samp_order_by>;
  variance?: InputMaybe<v1_Checkout_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Checkout" */
export type v1_Checkout_arr_rel_insert_input = {
  data: Array<v1_Checkout_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Checkout_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Checkout_avg_fields = {
  __typename?: 'v1_Checkout_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Checkout" */
export type v1_Checkout_avg_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Checkout". All fields are combined with a logical 'AND'. */
export type v1_Checkout_bool_exp = {
  CheckoutDetails?: InputMaybe<v1_CheckoutDetail_bool_exp>;
  CheckoutDetails_aggregate?: InputMaybe<v1_CheckoutDetail_aggregate_bool_exp>;
  Status?: InputMaybe<v1_CheckoutStatus_bool_exp>;
  User?: InputMaybe<v1_ContactBase_bool_exp>;
  _and?: InputMaybe<Array<v1_Checkout_bool_exp>>;
  _not?: InputMaybe<v1_Checkout_bool_exp>;
  _or?: InputMaybe<Array<v1_Checkout_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  status_id?: InputMaybe<Int_comparison_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Checkout" */
export enum v1_Checkout_constraint {
  /** unique or primary key constraint on columns "id" */
  Checkout_pkey = 'Checkout_pkey'
}

/** input type for incrementing numeric columns in table "v1.Checkout" */
export type v1_Checkout_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Checkout" */
export type v1_Checkout_insert_input = {
  CheckoutDetails?: InputMaybe<v1_CheckoutDetail_arr_rel_insert_input>;
  Status?: InputMaybe<v1_CheckoutStatus_obj_rel_insert_input>;
  User?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Checkout_max_fields = {
  __typename?: 'v1_Checkout_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Checkout" */
export type v1_Checkout_max_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Checkout_min_fields = {
  __typename?: 'v1_Checkout_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Checkout" */
export type v1_Checkout_min_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Checkout" */
export type v1_Checkout_mutation_response = {
  __typename?: 'v1_Checkout_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Checkout>;
};

/** input type for inserting object relation for remote table "v1.Checkout" */
export type v1_Checkout_obj_rel_insert_input = {
  data: v1_Checkout_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Checkout_on_conflict>;
};

/** on_conflict condition type for table "v1.Checkout" */
export type v1_Checkout_on_conflict = {
  constraint: v1_Checkout_constraint;
  update_columns?: Array<v1_Checkout_update_column>;
  where?: InputMaybe<v1_Checkout_bool_exp>;
};

/** Ordering options when selecting data from "v1.Checkout". */
export type v1_Checkout_order_by = {
  CheckoutDetails_aggregate?: InputMaybe<v1_CheckoutDetail_aggregate_order_by>;
  Status?: InputMaybe<v1_CheckoutStatus_order_by>;
  User?: InputMaybe<v1_ContactBase_order_by>;
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Checkout */
export type v1_Checkout_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Checkout" */
export enum v1_Checkout_select_column {
  /** column name */
  id = 'id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  status_id = 'status_id',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "v1.Checkout" */
export type v1_Checkout_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Checkout_stddev_fields = {
  __typename?: 'v1_Checkout_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Checkout" */
export type v1_Checkout_stddev_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Checkout_stddev_pop_fields = {
  __typename?: 'v1_Checkout_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Checkout" */
export type v1_Checkout_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Checkout_stddev_samp_fields = {
  __typename?: 'v1_Checkout_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Checkout" */
export type v1_Checkout_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Checkout" */
export type v1_Checkout_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Checkout_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Checkout_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Checkout_sum_fields = {
  __typename?: 'v1_Checkout_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Checkout" */
export type v1_Checkout_sum_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Checkout" */
export enum v1_Checkout_update_column {
  /** column name */
  id = 'id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  status_id = 'status_id',
  /** column name */
  user_id = 'user_id'
}

export type v1_Checkout_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Checkout_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Checkout_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Checkout_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Checkout_var_pop_fields = {
  __typename?: 'v1_Checkout_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Checkout" */
export type v1_Checkout_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Checkout_var_samp_fields = {
  __typename?: 'v1_Checkout_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Checkout" */
export type v1_Checkout_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Checkout_variance_fields = {
  __typename?: 'v1_Checkout_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Checkout" */
export type v1_Checkout_variance_order_by = {
  id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Cities" */
export type v1_Cities = {
  __typename?: 'v1_Cities';
  /** An array relationship */
  BillingInformation: Array<v1_BillingInformation>;
  /** An aggregate relationship */
  BillingInformation_aggregate: v1_BillingInformation_aggregate;
  /** An array relationship */
  ContactBases: Array<v1_ContactBase>;
  /** An aggregate relationship */
  ContactBases_aggregate: v1_ContactBase_aggregate;
  Name?: Maybe<Scalars['bpchar']['output']>;
  id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.Cities" */
export type v1_CitiesBillingInformationArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingInformation_order_by>>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};


/** columns and relationships of "v1.Cities" */
export type v1_CitiesBillingInformation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingInformation_order_by>>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};


/** columns and relationships of "v1.Cities" */
export type v1_CitiesContactBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** columns and relationships of "v1.Cities" */
export type v1_CitiesContactBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};

/** aggregated selection of "v1.Cities" */
export type v1_Cities_aggregate = {
  __typename?: 'v1_Cities_aggregate';
  aggregate?: Maybe<v1_Cities_aggregate_fields>;
  nodes: Array<v1_Cities>;
};

/** aggregate fields of "v1.Cities" */
export type v1_Cities_aggregate_fields = {
  __typename?: 'v1_Cities_aggregate_fields';
  avg?: Maybe<v1_Cities_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Cities_max_fields>;
  min?: Maybe<v1_Cities_min_fields>;
  stddev?: Maybe<v1_Cities_stddev_fields>;
  stddev_pop?: Maybe<v1_Cities_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Cities_stddev_samp_fields>;
  sum?: Maybe<v1_Cities_sum_fields>;
  var_pop?: Maybe<v1_Cities_var_pop_fields>;
  var_samp?: Maybe<v1_Cities_var_samp_fields>;
  variance?: Maybe<v1_Cities_variance_fields>;
};


/** aggregate fields of "v1.Cities" */
export type v1_Cities_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Cities_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Cities_avg_fields = {
  __typename?: 'v1_Cities_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Cities". All fields are combined with a logical 'AND'. */
export type v1_Cities_bool_exp = {
  BillingInformation?: InputMaybe<v1_BillingInformation_bool_exp>;
  BillingInformation_aggregate?: InputMaybe<v1_BillingInformation_aggregate_bool_exp>;
  ContactBases?: InputMaybe<v1_ContactBase_bool_exp>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  Name?: InputMaybe<bpchar_comparison_exp>;
  _and?: InputMaybe<Array<v1_Cities_bool_exp>>;
  _not?: InputMaybe<v1_Cities_bool_exp>;
  _or?: InputMaybe<Array<v1_Cities_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Cities" */
export enum v1_Cities_constraint {
  /** unique or primary key constraint on columns "id" */
  new_cityBase_pkey = 'new_cityBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.Cities" */
export type v1_Cities_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Cities" */
export type v1_Cities_insert_input = {
  BillingInformation?: InputMaybe<v1_BillingInformation_arr_rel_insert_input>;
  ContactBases?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  Name?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Cities_max_fields = {
  __typename?: 'v1_Cities_max_fields';
  Name?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_Cities_min_fields = {
  __typename?: 'v1_Cities_min_fields';
  Name?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.Cities" */
export type v1_Cities_mutation_response = {
  __typename?: 'v1_Cities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Cities>;
};

/** input type for inserting object relation for remote table "v1.Cities" */
export type v1_Cities_obj_rel_insert_input = {
  data: v1_Cities_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Cities_on_conflict>;
};

/** on_conflict condition type for table "v1.Cities" */
export type v1_Cities_on_conflict = {
  constraint: v1_Cities_constraint;
  update_columns?: Array<v1_Cities_update_column>;
  where?: InputMaybe<v1_Cities_bool_exp>;
};

/** Ordering options when selecting data from "v1.Cities". */
export type v1_Cities_order_by = {
  BillingInformation_aggregate?: InputMaybe<v1_BillingInformation_aggregate_order_by>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  Name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Cities */
export type v1_Cities_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Cities" */
export enum v1_Cities_select_column {
  /** column name */
  Name = 'Name',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.Cities" */
export type v1_Cities_set_input = {
  Name?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Cities_stddev_fields = {
  __typename?: 'v1_Cities_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Cities_stddev_pop_fields = {
  __typename?: 'v1_Cities_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Cities_stddev_samp_fields = {
  __typename?: 'v1_Cities_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Cities" */
export type v1_Cities_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Cities_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Cities_stream_cursor_value_input = {
  Name?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Cities_sum_fields = {
  __typename?: 'v1_Cities_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Cities" */
export enum v1_Cities_update_column {
  /** column name */
  Name = 'Name',
  /** column name */
  id = 'id'
}

export type v1_Cities_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Cities_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Cities_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Cities_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Cities_var_pop_fields = {
  __typename?: 'v1_Cities_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Cities_var_samp_fields = {
  __typename?: 'v1_Cities_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Cities_variance_fields = {
  __typename?: 'v1_Cities_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.ClicksByTrackers" */
export type v1_ClicksByTrackers = {
  __typename?: 'v1_ClicksByTrackers';
  clicks?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  extrernal_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

/** columns and relationships of "v1.ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew = {
  __typename?: 'v1_ClicksByTrackersNew';
  clicks?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  extrernal_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "v1.ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew_aggregate = {
  __typename?: 'v1_ClicksByTrackersNew_aggregate';
  aggregate?: Maybe<v1_ClicksByTrackersNew_aggregate_fields>;
  nodes: Array<v1_ClicksByTrackersNew>;
};

/** aggregate fields of "v1.ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew_aggregate_fields = {
  __typename?: 'v1_ClicksByTrackersNew_aggregate_fields';
  avg?: Maybe<v1_ClicksByTrackersNew_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ClicksByTrackersNew_max_fields>;
  min?: Maybe<v1_ClicksByTrackersNew_min_fields>;
  stddev?: Maybe<v1_ClicksByTrackersNew_stddev_fields>;
  stddev_pop?: Maybe<v1_ClicksByTrackersNew_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ClicksByTrackersNew_stddev_samp_fields>;
  sum?: Maybe<v1_ClicksByTrackersNew_sum_fields>;
  var_pop?: Maybe<v1_ClicksByTrackersNew_var_pop_fields>;
  var_samp?: Maybe<v1_ClicksByTrackersNew_var_samp_fields>;
  variance?: Maybe<v1_ClicksByTrackersNew_variance_fields>;
};


/** aggregate fields of "v1.ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ClicksByTrackersNew_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ClicksByTrackersNew_avg_fields = {
  __typename?: 'v1_ClicksByTrackersNew_avg_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ClicksByTrackersNew". All fields are combined with a logical 'AND'. */
export type v1_ClicksByTrackersNew_bool_exp = {
  _and?: InputMaybe<Array<v1_ClicksByTrackersNew_bool_exp>>;
  _not?: InputMaybe<v1_ClicksByTrackersNew_bool_exp>;
  _or?: InputMaybe<Array<v1_ClicksByTrackersNew_bool_exp>>;
  clicks?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<date_comparison_exp>;
  extrernal_url?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  project_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ClicksByTrackersNew" */
export enum v1_ClicksByTrackersNew_constraint {
  /** unique or primary key constraint on columns "id" */
  ClicksByTrackersNew_pkey = 'ClicksByTrackersNew_pkey'
}

/** input type for incrementing numeric columns in table "v1.ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew_inc_input = {
  clicks?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew_insert_input = {
  clicks?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  extrernal_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_ClicksByTrackersNew_max_fields = {
  __typename?: 'v1_ClicksByTrackersNew_max_fields';
  clicks?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  extrernal_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_ClicksByTrackersNew_min_fields = {
  __typename?: 'v1_ClicksByTrackersNew_min_fields';
  clicks?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  extrernal_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew_mutation_response = {
  __typename?: 'v1_ClicksByTrackersNew_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ClicksByTrackersNew>;
};

/** on_conflict condition type for table "v1.ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew_on_conflict = {
  constraint: v1_ClicksByTrackersNew_constraint;
  update_columns?: Array<v1_ClicksByTrackersNew_update_column>;
  where?: InputMaybe<v1_ClicksByTrackersNew_bool_exp>;
};

/** Ordering options when selecting data from "v1.ClicksByTrackersNew". */
export type v1_ClicksByTrackersNew_order_by = {
  clicks?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  extrernal_url?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ClicksByTrackersNew */
export type v1_ClicksByTrackersNew_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ClicksByTrackersNew" */
export enum v1_ClicksByTrackersNew_select_column {
  /** column name */
  clicks = 'clicks',
  /** column name */
  date = 'date',
  /** column name */
  extrernal_url = 'extrernal_url',
  /** column name */
  id = 'id',
  /** column name */
  project_id = 'project_id'
}

/** input type for updating data in table "v1.ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew_set_input = {
  clicks?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  extrernal_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_ClicksByTrackersNew_stddev_fields = {
  __typename?: 'v1_ClicksByTrackersNew_stddev_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ClicksByTrackersNew_stddev_pop_fields = {
  __typename?: 'v1_ClicksByTrackersNew_stddev_pop_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ClicksByTrackersNew_stddev_samp_fields = {
  __typename?: 'v1_ClicksByTrackersNew_stddev_samp_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ClicksByTrackersNew" */
export type v1_ClicksByTrackersNew_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ClicksByTrackersNew_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ClicksByTrackersNew_stream_cursor_value_input = {
  clicks?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  extrernal_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_ClicksByTrackersNew_sum_fields = {
  __typename?: 'v1_ClicksByTrackersNew_sum_fields';
  clicks?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ClicksByTrackersNew" */
export enum v1_ClicksByTrackersNew_update_column {
  /** column name */
  clicks = 'clicks',
  /** column name */
  date = 'date',
  /** column name */
  extrernal_url = 'extrernal_url',
  /** column name */
  id = 'id',
  /** column name */
  project_id = 'project_id'
}

export type v1_ClicksByTrackersNew_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ClicksByTrackersNew_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ClicksByTrackersNew_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ClicksByTrackersNew_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ClicksByTrackersNew_var_pop_fields = {
  __typename?: 'v1_ClicksByTrackersNew_var_pop_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ClicksByTrackersNew_var_samp_fields = {
  __typename?: 'v1_ClicksByTrackersNew_var_samp_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ClicksByTrackersNew_variance_fields = {
  __typename?: 'v1_ClicksByTrackersNew_variance_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.ClicksByTrackers" */
export type v1_ClicksByTrackers_aggregate = {
  __typename?: 'v1_ClicksByTrackers_aggregate';
  aggregate?: Maybe<v1_ClicksByTrackers_aggregate_fields>;
  nodes: Array<v1_ClicksByTrackers>;
};

/** aggregate fields of "v1.ClicksByTrackers" */
export type v1_ClicksByTrackers_aggregate_fields = {
  __typename?: 'v1_ClicksByTrackers_aggregate_fields';
  avg?: Maybe<v1_ClicksByTrackers_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ClicksByTrackers_max_fields>;
  min?: Maybe<v1_ClicksByTrackers_min_fields>;
  stddev?: Maybe<v1_ClicksByTrackers_stddev_fields>;
  stddev_pop?: Maybe<v1_ClicksByTrackers_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ClicksByTrackers_stddev_samp_fields>;
  sum?: Maybe<v1_ClicksByTrackers_sum_fields>;
  var_pop?: Maybe<v1_ClicksByTrackers_var_pop_fields>;
  var_samp?: Maybe<v1_ClicksByTrackers_var_samp_fields>;
  variance?: Maybe<v1_ClicksByTrackers_variance_fields>;
};


/** aggregate fields of "v1.ClicksByTrackers" */
export type v1_ClicksByTrackers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ClicksByTrackers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ClicksByTrackers_avg_fields = {
  __typename?: 'v1_ClicksByTrackers_avg_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ClicksByTrackers". All fields are combined with a logical 'AND'. */
export type v1_ClicksByTrackers_bool_exp = {
  _and?: InputMaybe<Array<v1_ClicksByTrackers_bool_exp>>;
  _not?: InputMaybe<v1_ClicksByTrackers_bool_exp>;
  _or?: InputMaybe<Array<v1_ClicksByTrackers_bool_exp>>;
  clicks?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<date_comparison_exp>;
  extrernal_url?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ClicksByTrackers" */
export enum v1_ClicksByTrackers_constraint {
  /** unique or primary key constraint on columns "id" */
  ClicksByTrackers_pkey = 'ClicksByTrackers_pkey'
}

/** input type for incrementing numeric columns in table "v1.ClicksByTrackers" */
export type v1_ClicksByTrackers_inc_input = {
  clicks?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ClicksByTrackers" */
export type v1_ClicksByTrackers_insert_input = {
  clicks?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  extrernal_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_ClicksByTrackers_max_fields = {
  __typename?: 'v1_ClicksByTrackers_max_fields';
  clicks?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  extrernal_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_ClicksByTrackers_min_fields = {
  __typename?: 'v1_ClicksByTrackers_min_fields';
  clicks?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  extrernal_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.ClicksByTrackers" */
export type v1_ClicksByTrackers_mutation_response = {
  __typename?: 'v1_ClicksByTrackers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ClicksByTrackers>;
};

/** on_conflict condition type for table "v1.ClicksByTrackers" */
export type v1_ClicksByTrackers_on_conflict = {
  constraint: v1_ClicksByTrackers_constraint;
  update_columns?: Array<v1_ClicksByTrackers_update_column>;
  where?: InputMaybe<v1_ClicksByTrackers_bool_exp>;
};

/** Ordering options when selecting data from "v1.ClicksByTrackers". */
export type v1_ClicksByTrackers_order_by = {
  clicks?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  extrernal_url?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ClicksByTrackers */
export type v1_ClicksByTrackers_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ClicksByTrackers" */
export enum v1_ClicksByTrackers_select_column {
  /** column name */
  clicks = 'clicks',
  /** column name */
  date = 'date',
  /** column name */
  extrernal_url = 'extrernal_url',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.ClicksByTrackers" */
export type v1_ClicksByTrackers_set_input = {
  clicks?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  extrernal_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_ClicksByTrackers_stddev_fields = {
  __typename?: 'v1_ClicksByTrackers_stddev_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ClicksByTrackers_stddev_pop_fields = {
  __typename?: 'v1_ClicksByTrackers_stddev_pop_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ClicksByTrackers_stddev_samp_fields = {
  __typename?: 'v1_ClicksByTrackers_stddev_samp_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ClicksByTrackers" */
export type v1_ClicksByTrackers_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ClicksByTrackers_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ClicksByTrackers_stream_cursor_value_input = {
  clicks?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  extrernal_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_ClicksByTrackers_sum_fields = {
  __typename?: 'v1_ClicksByTrackers_sum_fields';
  clicks?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ClicksByTrackers" */
export enum v1_ClicksByTrackers_update_column {
  /** column name */
  clicks = 'clicks',
  /** column name */
  date = 'date',
  /** column name */
  extrernal_url = 'extrernal_url',
  /** column name */
  id = 'id'
}

export type v1_ClicksByTrackers_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ClicksByTrackers_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ClicksByTrackers_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ClicksByTrackers_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ClicksByTrackers_var_pop_fields = {
  __typename?: 'v1_ClicksByTrackers_var_pop_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ClicksByTrackers_var_samp_fields = {
  __typename?: 'v1_ClicksByTrackers_var_samp_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ClicksByTrackers_variance_fields = {
  __typename?: 'v1_ClicksByTrackers_variance_fields';
  clicks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Company type option set */
export type v1_CompanyType = {
  __typename?: 'v1_CompanyType';
  /** An array relationship */
  AccountBases: Array<v1_AccountBase>;
  /** An aggregate relationship */
  AccountBases_aggregate: v1_AccountBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** Company type option set */
export type v1_CompanyTypeAccountBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** Company type option set */
export type v1_CompanyTypeAccountBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};

/** aggregated selection of "v1.CompanyType" */
export type v1_CompanyType_aggregate = {
  __typename?: 'v1_CompanyType_aggregate';
  aggregate?: Maybe<v1_CompanyType_aggregate_fields>;
  nodes: Array<v1_CompanyType>;
};

/** aggregate fields of "v1.CompanyType" */
export type v1_CompanyType_aggregate_fields = {
  __typename?: 'v1_CompanyType_aggregate_fields';
  avg?: Maybe<v1_CompanyType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_CompanyType_max_fields>;
  min?: Maybe<v1_CompanyType_min_fields>;
  stddev?: Maybe<v1_CompanyType_stddev_fields>;
  stddev_pop?: Maybe<v1_CompanyType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_CompanyType_stddev_samp_fields>;
  sum?: Maybe<v1_CompanyType_sum_fields>;
  var_pop?: Maybe<v1_CompanyType_var_pop_fields>;
  var_samp?: Maybe<v1_CompanyType_var_samp_fields>;
  variance?: Maybe<v1_CompanyType_variance_fields>;
};


/** aggregate fields of "v1.CompanyType" */
export type v1_CompanyType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_CompanyType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_CompanyType_avg_fields = {
  __typename?: 'v1_CompanyType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.CompanyType". All fields are combined with a logical 'AND'. */
export type v1_CompanyType_bool_exp = {
  AccountBases?: InputMaybe<v1_AccountBase_bool_exp>;
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_CompanyType_bool_exp>>;
  _not?: InputMaybe<v1_CompanyType_bool_exp>;
  _or?: InputMaybe<Array<v1_CompanyType_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.CompanyType" */
export enum v1_CompanyType_constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyType_pkey = 'CompanyType_pkey',
  /** unique or primary key constraint on columns "value" */
  CompanyType_value_key = 'CompanyType_value_key'
}

/** input type for incrementing numeric columns in table "v1.CompanyType" */
export type v1_CompanyType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.CompanyType" */
export type v1_CompanyType_insert_input = {
  AccountBases?: InputMaybe<v1_AccountBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_CompanyType_max_fields = {
  __typename?: 'v1_CompanyType_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_CompanyType_min_fields = {
  __typename?: 'v1_CompanyType_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.CompanyType" */
export type v1_CompanyType_mutation_response = {
  __typename?: 'v1_CompanyType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_CompanyType>;
};

/** input type for inserting object relation for remote table "v1.CompanyType" */
export type v1_CompanyType_obj_rel_insert_input = {
  data: v1_CompanyType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_CompanyType_on_conflict>;
};

/** on_conflict condition type for table "v1.CompanyType" */
export type v1_CompanyType_on_conflict = {
  constraint: v1_CompanyType_constraint;
  update_columns?: Array<v1_CompanyType_update_column>;
  where?: InputMaybe<v1_CompanyType_bool_exp>;
};

/** Ordering options when selecting data from "v1.CompanyType". */
export type v1_CompanyType_order_by = {
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.CompanyType */
export type v1_CompanyType_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.CompanyType" */
export enum v1_CompanyType_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.CompanyType" */
export type v1_CompanyType_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_CompanyType_stddev_fields = {
  __typename?: 'v1_CompanyType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_CompanyType_stddev_pop_fields = {
  __typename?: 'v1_CompanyType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_CompanyType_stddev_samp_fields = {
  __typename?: 'v1_CompanyType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_CompanyType" */
export type v1_CompanyType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_CompanyType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_CompanyType_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_CompanyType_sum_fields = {
  __typename?: 'v1_CompanyType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.CompanyType" */
export enum v1_CompanyType_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_CompanyType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_CompanyType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_CompanyType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_CompanyType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_CompanyType_var_pop_fields = {
  __typename?: 'v1_CompanyType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_CompanyType_var_samp_fields = {
  __typename?: 'v1_CompanyType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_CompanyType_variance_fields = {
  __typename?: 'v1_CompanyType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Configurations" */
export type v1_Configurations = {
  __typename?: 'v1_Configurations';
  config: Scalars['json']['output'];
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "v1.Configurations" */
export type v1_ConfigurationsconfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "v1.Configurations" */
export type v1_Configurations_aggregate = {
  __typename?: 'v1_Configurations_aggregate';
  aggregate?: Maybe<v1_Configurations_aggregate_fields>;
  nodes: Array<v1_Configurations>;
};

/** aggregate fields of "v1.Configurations" */
export type v1_Configurations_aggregate_fields = {
  __typename?: 'v1_Configurations_aggregate_fields';
  avg?: Maybe<v1_Configurations_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Configurations_max_fields>;
  min?: Maybe<v1_Configurations_min_fields>;
  stddev?: Maybe<v1_Configurations_stddev_fields>;
  stddev_pop?: Maybe<v1_Configurations_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Configurations_stddev_samp_fields>;
  sum?: Maybe<v1_Configurations_sum_fields>;
  var_pop?: Maybe<v1_Configurations_var_pop_fields>;
  var_samp?: Maybe<v1_Configurations_var_samp_fields>;
  variance?: Maybe<v1_Configurations_variance_fields>;
};


/** aggregate fields of "v1.Configurations" */
export type v1_Configurations_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Configurations_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Configurations_avg_fields = {
  __typename?: 'v1_Configurations_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Configurations". All fields are combined with a logical 'AND'. */
export type v1_Configurations_bool_exp = {
  _and?: InputMaybe<Array<v1_Configurations_bool_exp>>;
  _not?: InputMaybe<v1_Configurations_bool_exp>;
  _or?: InputMaybe<Array<v1_Configurations_bool_exp>>;
  config?: InputMaybe<json_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Configurations" */
export enum v1_Configurations_constraint {
  /** unique or primary key constraint on columns "name" */
  Configurations_name_key = 'Configurations_name_key',
  /** unique or primary key constraint on columns "id" */
  RolesConfigurations_id_key = 'RolesConfigurations_id_key',
  /** unique or primary key constraint on columns "id" */
  RolesConfigurations_pkey = 'RolesConfigurations_pkey'
}

/** input type for incrementing numeric columns in table "v1.Configurations" */
export type v1_Configurations_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Configurations" */
export type v1_Configurations_insert_input = {
  config?: InputMaybe<Scalars['json']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Configurations_max_fields = {
  __typename?: 'v1_Configurations_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_Configurations_min_fields = {
  __typename?: 'v1_Configurations_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.Configurations" */
export type v1_Configurations_mutation_response = {
  __typename?: 'v1_Configurations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Configurations>;
};

/** on_conflict condition type for table "v1.Configurations" */
export type v1_Configurations_on_conflict = {
  constraint: v1_Configurations_constraint;
  update_columns?: Array<v1_Configurations_update_column>;
  where?: InputMaybe<v1_Configurations_bool_exp>;
};

/** Ordering options when selecting data from "v1.Configurations". */
export type v1_Configurations_order_by = {
  config?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Configurations */
export type v1_Configurations_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Configurations" */
export enum v1_Configurations_select_column {
  /** column name */
  config = 'config',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.Configurations" */
export type v1_Configurations_set_input = {
  config?: InputMaybe<Scalars['json']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Configurations_stddev_fields = {
  __typename?: 'v1_Configurations_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Configurations_stddev_pop_fields = {
  __typename?: 'v1_Configurations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Configurations_stddev_samp_fields = {
  __typename?: 'v1_Configurations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Configurations" */
export type v1_Configurations_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Configurations_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Configurations_stream_cursor_value_input = {
  config?: InputMaybe<Scalars['json']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Configurations_sum_fields = {
  __typename?: 'v1_Configurations_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Configurations" */
export enum v1_Configurations_update_column {
  /** column name */
  config = 'config',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_Configurations_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Configurations_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Configurations_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Configurations_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Configurations_var_pop_fields = {
  __typename?: 'v1_Configurations_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Configurations_var_samp_fields = {
  __typename?: 'v1_Configurations_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Configurations_variance_fields = {
  __typename?: 'v1_Configurations_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Writers */
export type v1_ContactBase = {
  __typename?: 'v1_ContactBase';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Assistant?: Maybe<v1_SystemUserBase>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  Balances: Array<v1_Balance>;
  /** An aggregate relationship */
  Balances_aggregate: v1_Balance_aggregate;
  /** An array relationship */
  BillingMethods: Array<v1_BillingMethod>;
  /** An aggregate relationship */
  BillingMethods_aggregate: v1_BillingMethod_aggregate;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  Campaigns: Array<v1_Campaign>;
  /** An aggregate relationship */
  Campaigns_aggregate: v1_Campaign_aggregate;
  /** An object relationship */
  City?: Maybe<v1_Cities>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  ContactType?: Maybe<v1_ContactType>;
  /** An array relationship */
  Contacts_Roles: Array<v1_Contacts_Roles>;
  /** An aggregate relationship */
  Contacts_Roles_aggregate: v1_Contacts_Roles_aggregate;
  /** An object relationship */
  Country?: Maybe<v1_new_countryBase>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  MarketplaceProjects: Array<v1_MarketplaceProjects>;
  /** An aggregate relationship */
  MarketplaceProjects_aggregate: v1_MarketplaceProjects_aggregate;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  PaymentMethod?: Maybe<v1_new_paymentmethodBase>;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An object relationship */
  PrimaryLanguage?: Maybe<v1_new_languagesBase>;
  /** An object relationship */
  SecondaryLanguage?: Maybe<v1_new_languagesBase>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode: Scalars['Int']['output'];
  StatusCode?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  SupplierTags: Array<v1_Supplier_Tag_rel>;
  /** An aggregate relationship */
  SupplierTags_aggregate: v1_Supplier_Tag_rel_aggregate;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  allow_upload_content_portal: Scalars['Boolean']['output'];
  avatar_url: Scalars['String']['output'];
  balance?: Maybe<Scalars['numeric']['output']>;
  billing_address: Scalars['String']['output'];
  billing_zip_code: Scalars['String']['output'];
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  city_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_supplier_in_progress_posts" */
  comp_supplier_in_progress_posts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_supplier_offers" */
  comp_supplier_offers?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_supplier_posted_posts" */
  comp_supplier_posted_posts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_supplier_purchase_orders" */
  comp_supplier_purchase_orders?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_writer_approved_posts" */
  comp_writer_approved_posts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_writer_purchase_orders" */
  comp_writer_purchase_orders?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_writer_waiting_approval_posts" */
  comp_writer_waiting_approval_posts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_writers_ordered_posts" */
  comp_writers_ordered_posts?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** A computed field, executes function "v1.writers_post" */
  inprogress_posts?: Maybe<Scalars['Int']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Int']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.left_quota" */
  left_quota?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.count_advertiser_posts_last_month" */
  links_bought_last_month?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.count_advertiser_posts_this_month" */
  links_bought_this_month?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.count_advertiser_posts" */
  links_bought_total?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.count_advertiser_posts_in_progress" */
  links_status_in_progress?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.count_advertiser_posts_live" */
  links_status_live?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  newPobasesByAdvertiserid: Array<v1_new_POBase>;
  /** An aggregate relationship */
  newPobasesByAdvertiserid_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  newPobasesByWriter: Array<v1_new_POBase>;
  /** An aggregate relationship */
  newPobasesByWriter_aggregate: v1_new_POBase_aggregate;
  new_AccountActive?: Maybe<Scalars['Boolean']['output']>;
  new_Blacklisted?: Maybe<Scalars['Boolean']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_IsCustomer?: Maybe<Scalars['Boolean']['output']>;
  new_Issupplier?: Maybe<Scalars['Boolean']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_Linksexchanger?: Maybe<Scalars['Boolean']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  new_POBases: Array<v1_new_POBase>;
  /** An aggregate relationship */
  new_POBases_aggregate: v1_new_POBase_aggregate;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_Translator?: Maybe<Scalars['Boolean']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_Writer?: Maybe<Scalars['Boolean']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contacetype?: Maybe<Scalars['Boolean']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_inhouse?: Maybe<Scalars['Boolean']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  postsByAdvertiserid: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByAdvertiserid_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsBySupplierId: Array<v1_Posts>;
  /** An aggregate relationship */
  postsBySupplierId_aggregate: v1_Posts_aggregate;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level: Scalars['numeric']['output'];
  tag_ids?: Maybe<Scalars['_int4']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};


/** Writers */
export type v1_ContactBaseBalancesArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** Writers */
export type v1_ContactBaseBalances_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** Writers */
export type v1_ContactBaseBillingMethodsArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


/** Writers */
export type v1_ContactBaseBillingMethods_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


/** Writers */
export type v1_ContactBaseCampaignsArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** Writers */
export type v1_ContactBaseCampaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** Writers */
export type v1_ContactBaseContacts_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Contacts_Roles_order_by>>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};


/** Writers */
export type v1_ContactBaseContacts_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Contacts_Roles_order_by>>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};


/** Writers */
export type v1_ContactBaseMarketplaceProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceProjects_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceProjects_order_by>>;
  where?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
};


/** Writers */
export type v1_ContactBaseMarketplaceProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketplaceProjects_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketplaceProjects_order_by>>;
  where?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
};


/** Writers */
export type v1_ContactBaseOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** Writers */
export type v1_ContactBaseOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** Writers */
export type v1_ContactBasePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Writers */
export type v1_ContactBasePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Writers */
export type v1_ContactBaseSupplierTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};


/** Writers */
export type v1_ContactBaseSupplierTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};


/** Writers */
export type v1_ContactBaseWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** Writers */
export type v1_ContactBaseWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** Writers */
export type v1_ContactBasenewPobasesByAdvertiseridArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Writers */
export type v1_ContactBasenewPobasesByAdvertiserid_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Writers */
export type v1_ContactBasenewPobasesByWriterArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Writers */
export type v1_ContactBasenewPobasesByWriter_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Writers */
export type v1_ContactBasenew_POBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Writers */
export type v1_ContactBasenew_POBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Writers */
export type v1_ContactBasepostsByAdvertiseridArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Writers */
export type v1_ContactBasepostsByAdvertiserid_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Writers */
export type v1_ContactBasepostsBySupplierIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Writers */
export type v1_ContactBasepostsBySupplierId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** columns and relationships of "v1.ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption = {
  __typename?: 'v1_ContactBasePaymentOption';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "v1.ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption_aggregate = {
  __typename?: 'v1_ContactBasePaymentOption_aggregate';
  aggregate?: Maybe<v1_ContactBasePaymentOption_aggregate_fields>;
  nodes: Array<v1_ContactBasePaymentOption>;
};

/** aggregate fields of "v1.ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption_aggregate_fields = {
  __typename?: 'v1_ContactBasePaymentOption_aggregate_fields';
  avg?: Maybe<v1_ContactBasePaymentOption_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ContactBasePaymentOption_max_fields>;
  min?: Maybe<v1_ContactBasePaymentOption_min_fields>;
  stddev?: Maybe<v1_ContactBasePaymentOption_stddev_fields>;
  stddev_pop?: Maybe<v1_ContactBasePaymentOption_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ContactBasePaymentOption_stddev_samp_fields>;
  sum?: Maybe<v1_ContactBasePaymentOption_sum_fields>;
  var_pop?: Maybe<v1_ContactBasePaymentOption_var_pop_fields>;
  var_samp?: Maybe<v1_ContactBasePaymentOption_var_samp_fields>;
  variance?: Maybe<v1_ContactBasePaymentOption_variance_fields>;
};


/** aggregate fields of "v1.ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ContactBasePaymentOption_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ContactBasePaymentOption_avg_fields = {
  __typename?: 'v1_ContactBasePaymentOption_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ContactBasePaymentOption". All fields are combined with a logical 'AND'. */
export type v1_ContactBasePaymentOption_bool_exp = {
  _and?: InputMaybe<Array<v1_ContactBasePaymentOption_bool_exp>>;
  _not?: InputMaybe<v1_ContactBasePaymentOption_bool_exp>;
  _or?: InputMaybe<Array<v1_ContactBasePaymentOption_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ContactBasePaymentOption" */
export enum v1_ContactBasePaymentOption_constraint {
  /** unique or primary key constraint on columns "id" */
  ContactBasePaymentOption_pkey = 'ContactBasePaymentOption_pkey'
}

/** input type for incrementing numeric columns in table "v1.ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_ContactBasePaymentOption_max_fields = {
  __typename?: 'v1_ContactBasePaymentOption_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_ContactBasePaymentOption_min_fields = {
  __typename?: 'v1_ContactBasePaymentOption_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption_mutation_response = {
  __typename?: 'v1_ContactBasePaymentOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ContactBasePaymentOption>;
};

/** on_conflict condition type for table "v1.ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption_on_conflict = {
  constraint: v1_ContactBasePaymentOption_constraint;
  update_columns?: Array<v1_ContactBasePaymentOption_update_column>;
  where?: InputMaybe<v1_ContactBasePaymentOption_bool_exp>;
};

/** Ordering options when selecting data from "v1.ContactBasePaymentOption". */
export type v1_ContactBasePaymentOption_order_by = {
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ContactBasePaymentOption */
export type v1_ContactBasePaymentOption_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ContactBasePaymentOption" */
export enum v1_ContactBasePaymentOption_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_ContactBasePaymentOption_stddev_fields = {
  __typename?: 'v1_ContactBasePaymentOption_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ContactBasePaymentOption_stddev_pop_fields = {
  __typename?: 'v1_ContactBasePaymentOption_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ContactBasePaymentOption_stddev_samp_fields = {
  __typename?: 'v1_ContactBasePaymentOption_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ContactBasePaymentOption" */
export type v1_ContactBasePaymentOption_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ContactBasePaymentOption_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ContactBasePaymentOption_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ContactBasePaymentOption_sum_fields = {
  __typename?: 'v1_ContactBasePaymentOption_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ContactBasePaymentOption" */
export enum v1_ContactBasePaymentOption_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_ContactBasePaymentOption_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ContactBasePaymentOption_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ContactBasePaymentOption_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ContactBasePaymentOption_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ContactBasePaymentOption_var_pop_fields = {
  __typename?: 'v1_ContactBasePaymentOption_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ContactBasePaymentOption_var_samp_fields = {
  __typename?: 'v1_ContactBasePaymentOption_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ContactBasePaymentOption_variance_fields = {
  __typename?: 'v1_ContactBasePaymentOption_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.ContactBase" */
export type v1_ContactBase_aggregate = {
  __typename?: 'v1_ContactBase_aggregate';
  aggregate?: Maybe<v1_ContactBase_aggregate_fields>;
  nodes: Array<v1_ContactBase>;
};

export type v1_ContactBase_aggregate_bool_exp = {
  avg?: InputMaybe<v1_ContactBase_aggregate_bool_exp_avg>;
  bool_and?: InputMaybe<v1_ContactBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_ContactBase_aggregate_bool_exp_bool_or>;
  corr?: InputMaybe<v1_ContactBase_aggregate_bool_exp_corr>;
  count?: InputMaybe<v1_ContactBase_aggregate_bool_exp_count>;
  covar_samp?: InputMaybe<v1_ContactBase_aggregate_bool_exp_covar_samp>;
  max?: InputMaybe<v1_ContactBase_aggregate_bool_exp_max>;
  min?: InputMaybe<v1_ContactBase_aggregate_bool_exp_min>;
  stddev_samp?: InputMaybe<v1_ContactBase_aggregate_bool_exp_stddev_samp>;
  sum?: InputMaybe<v1_ContactBase_aggregate_bool_exp_sum>;
  var_samp?: InputMaybe<v1_ContactBase_aggregate_bool_exp_var_samp>;
};

export type v1_ContactBase_aggregate_bool_exp_avg = {
  arguments: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_avg_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_bool_and = {
  arguments: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_bool_or = {
  arguments: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_corr = {
  arguments: v1_ContactBase_aggregate_bool_exp_corr_arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_corr_arguments = {
  X: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_corr_arguments_columns;
  Y: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_corr_arguments_columns;
};

export type v1_ContactBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_ContactBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: Int_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_covar_samp = {
  arguments: v1_ContactBase_aggregate_bool_exp_covar_samp_arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_covar_samp_arguments = {
  X: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_covar_samp_arguments_columns;
};

export type v1_ContactBase_aggregate_bool_exp_max = {
  arguments: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_max_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_min = {
  arguments: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_min_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_stddev_samp = {
  arguments: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_sum = {
  arguments: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_sum_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_ContactBase_aggregate_bool_exp_var_samp = {
  arguments: v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContactBase_bool_exp>;
  predicate: float8_comparison_exp;
};

/** aggregate fields of "v1.ContactBase" */
export type v1_ContactBase_aggregate_fields = {
  __typename?: 'v1_ContactBase_aggregate_fields';
  avg?: Maybe<v1_ContactBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ContactBase_max_fields>;
  min?: Maybe<v1_ContactBase_min_fields>;
  stddev?: Maybe<v1_ContactBase_stddev_fields>;
  stddev_pop?: Maybe<v1_ContactBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ContactBase_stddev_samp_fields>;
  sum?: Maybe<v1_ContactBase_sum_fields>;
  var_pop?: Maybe<v1_ContactBase_var_pop_fields>;
  var_samp?: Maybe<v1_ContactBase_var_samp_fields>;
  variance?: Maybe<v1_ContactBase_variance_fields>;
};


/** aggregate fields of "v1.ContactBase" */
export type v1_ContactBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ContactBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.ContactBase" */
export type v1_ContactBase_aggregate_order_by = {
  avg?: InputMaybe<v1_ContactBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_ContactBase_max_order_by>;
  min?: InputMaybe<v1_ContactBase_min_order_by>;
  stddev?: InputMaybe<v1_ContactBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_ContactBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_ContactBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_ContactBase_sum_order_by>;
  var_pop?: InputMaybe<v1_ContactBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_ContactBase_var_samp_order_by>;
  variance?: InputMaybe<v1_ContactBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.ContactBase" */
export type v1_ContactBase_arr_rel_insert_input = {
  data: Array<v1_ContactBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ContactBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_ContactBase_avg_fields = {
  __typename?: 'v1_ContactBase_avg_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Float']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.ContactBase" */
export type v1_ContactBase_avg_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.ContactBase". All fields are combined with a logical 'AND'. */
export type v1_ContactBase_bool_exp = {
  ActiveOffersCount?: InputMaybe<Int_comparison_exp>;
  AllPosts?: InputMaybe<Int_comparison_exp>;
  Assistant?: InputMaybe<v1_SystemUserBase_bool_exp>;
  AssistantID?: InputMaybe<Int_comparison_exp>;
  Balances?: InputMaybe<v1_Balance_bool_exp>;
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_bool_exp>;
  BillingMethods?: InputMaybe<v1_BillingMethod_bool_exp>;
  BillingMethods_aggregate?: InputMaybe<v1_BillingMethod_aggregate_bool_exp>;
  BirthDate?: InputMaybe<timestamp_comparison_exp>;
  Campaigns?: InputMaybe<v1_Campaign_bool_exp>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  City?: InputMaybe<v1_Cities_bool_exp>;
  ContactId?: InputMaybe<uuid_comparison_exp>;
  ContactType?: InputMaybe<v1_ContactType_bool_exp>;
  Contacts_Roles?: InputMaybe<v1_Contacts_Roles_bool_exp>;
  Contacts_Roles_aggregate?: InputMaybe<v1_Contacts_Roles_aggregate_bool_exp>;
  Country?: InputMaybe<v1_new_countryBase_bool_exp>;
  CountryID?: InputMaybe<Int_comparison_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  EMailAddress1?: InputMaybe<String_comparison_exp>;
  EMailAddress2?: InputMaybe<String_comparison_exp>;
  ExchangeRate?: InputMaybe<numeric_comparison_exp>;
  FinishedOrders?: InputMaybe<Int_comparison_exp>;
  FirstName?: InputMaybe<String_comparison_exp>;
  FullName?: InputMaybe<String_comparison_exp>;
  LastName?: InputMaybe<String_comparison_exp>;
  MarketplaceProjects?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
  MarketplaceProjects_aggregate?: InputMaybe<v1_MarketplaceProjects_aggregate_bool_exp>;
  MobilePhone?: InputMaybe<String_comparison_exp>;
  ModifiedOn?: InputMaybe<timestamp_comparison_exp>;
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  PrimaryLanguage?: InputMaybe<v1_new_languagesBase_bool_exp>;
  SecondaryLanguage?: InputMaybe<v1_new_languagesBase_bool_exp>;
  SoldOffersCount?: InputMaybe<Int_comparison_exp>;
  StateCode?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<Int_comparison_exp>;
  SupplierTags?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
  SupplierTags_aggregate?: InputMaybe<v1_Supplier_Tag_rel_aggregate_bool_exp>;
  Telephone1?: InputMaybe<String_comparison_exp>;
  TotalOffersCount?: InputMaybe<Int_comparison_exp>;
  TotalOrdersSum?: InputMaybe<Int_comparison_exp>;
  TotalPayoutsSum?: InputMaybe<Int_comparison_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_ContactBase_bool_exp>>;
  _not?: InputMaybe<v1_ContactBase_bool_exp>;
  _or?: InputMaybe<Array<v1_ContactBase_bool_exp>>;
  allow_upload_content_portal?: InputMaybe<Boolean_comparison_exp>;
  avatar_url?: InputMaybe<String_comparison_exp>;
  balance?: InputMaybe<numeric_comparison_exp>;
  billing_address?: InputMaybe<String_comparison_exp>;
  billing_zip_code?: InputMaybe<String_comparison_exp>;
  buzzstream_id?: InputMaybe<Int_comparison_exp>;
  city_id?: InputMaybe<Int_comparison_exp>;
  comp_supplier_in_progress_posts?: InputMaybe<Int_comparison_exp>;
  comp_supplier_offers?: InputMaybe<Int_comparison_exp>;
  comp_supplier_posted_posts?: InputMaybe<Int_comparison_exp>;
  comp_supplier_purchase_orders?: InputMaybe<Int_comparison_exp>;
  comp_writer_approved_posts?: InputMaybe<Int_comparison_exp>;
  comp_writer_purchase_orders?: InputMaybe<Int_comparison_exp>;
  comp_writer_waiting_approval_posts?: InputMaybe<Int_comparison_exp>;
  comp_writers_ordered_posts?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  inprogress_posts?: InputMaybe<Int_comparison_exp>;
  language1_id?: InputMaybe<Int_comparison_exp>;
  language_id?: InputMaybe<Int_comparison_exp>;
  left_quota?: InputMaybe<Int_comparison_exp>;
  links_bought_last_month?: InputMaybe<Int_comparison_exp>;
  links_bought_this_month?: InputMaybe<Int_comparison_exp>;
  links_bought_total?: InputMaybe<Int_comparison_exp>;
  links_status_in_progress?: InputMaybe<Int_comparison_exp>;
  links_status_live?: InputMaybe<Int_comparison_exp>;
  monthly_quota?: InputMaybe<numeric_comparison_exp>;
  newPobasesByAdvertiserid?: InputMaybe<v1_new_POBase_bool_exp>;
  newPobasesByAdvertiserid_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  newPobasesByWriter?: InputMaybe<v1_new_POBase_bool_exp>;
  newPobasesByWriter_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  new_AccountActive?: InputMaybe<Boolean_comparison_exp>;
  new_Blacklisted?: InputMaybe<Boolean_comparison_exp>;
  new_Contacttype?: InputMaybe<Int_comparison_exp>;
  new_CreatedByUser?: InputMaybe<String_comparison_exp>;
  new_Facebook?: InputMaybe<String_comparison_exp>;
  new_FullSeoPage?: InputMaybe<numeric_comparison_exp>;
  new_IsCustomer?: InputMaybe<Boolean_comparison_exp>;
  new_Issupplier?: InputMaybe<Boolean_comparison_exp>;
  new_LastActivity?: InputMaybe<timestamp_comparison_exp>;
  new_LinkedIn?: InputMaybe<String_comparison_exp>;
  new_Linksexchanger?: InputMaybe<Boolean_comparison_exp>;
  new_MainLanguage?: InputMaybe<Int_comparison_exp>;
  new_Offers?: InputMaybe<Int_comparison_exp>;
  new_POBases?: InputMaybe<v1_new_POBase_bool_exp>;
  new_POBases_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  new_Passportid?: InputMaybe<String_comparison_exp>;
  new_Password?: InputMaybe<String_comparison_exp>;
  new_PaymentAddress?: InputMaybe<String_comparison_exp>;
  new_Paymentmethod?: InputMaybe<Int_comparison_exp>;
  new_Rating?: InputMaybe<Int_comparison_exp>;
  new_SecondaryLanguage?: InputMaybe<Int_comparison_exp>;
  new_SeoShortPost?: InputMaybe<numeric_comparison_exp>;
  new_Translation?: InputMaybe<numeric_comparison_exp>;
  new_Translator?: InputMaybe<Boolean_comparison_exp>;
  new_UserName?: InputMaybe<String_comparison_exp>;
  new_WebsitePassword?: InputMaybe<String_comparison_exp>;
  new_Writer?: InputMaybe<Boolean_comparison_exp>;
  new_bankaddress?: InputMaybe<String_comparison_exp>;
  new_bankibanaccount?: InputMaybe<String_comparison_exp>;
  new_bankname?: InputMaybe<String_comparison_exp>;
  new_bankswift?: InputMaybe<String_comparison_exp>;
  new_beneficiaryaddress?: InputMaybe<String_comparison_exp>;
  new_beneficiaryname?: InputMaybe<String_comparison_exp>;
  new_contacetype?: InputMaybe<Boolean_comparison_exp>;
  new_contactid?: InputMaybe<String_comparison_exp>;
  new_fullseopagebig?: InputMaybe<numeric_comparison_exp>;
  new_fullseopagejambo?: InputMaybe<numeric_comparison_exp>;
  new_fullseopagelarge?: InputMaybe<numeric_comparison_exp>;
  new_inhouse?: InputMaybe<Boolean_comparison_exp>;
  new_language?: InputMaybe<uuid_comparison_exp>;
  new_language1?: InputMaybe<uuid_comparison_exp>;
  new_longsocialpost?: InputMaybe<numeric_comparison_exp>;
  new_shortsocialpost?: InputMaybe<numeric_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paymentOption?: InputMaybe<Int_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  postsByAdvertiserid?: InputMaybe<v1_Posts_bool_exp>;
  postsByAdvertiserid_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsBySupplierId?: InputMaybe<v1_Posts_bool_exp>;
  postsBySupplierId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  price_per_word?: InputMaybe<float8_comparison_exp>;
  skill_level?: InputMaybe<numeric_comparison_exp>;
  tag_ids?: InputMaybe<_int4_comparison_exp>;
  upload_price?: InputMaybe<numeric_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ContactBase" */
export enum v1_ContactBase_constraint {
  /** unique or primary key constraint on columns "id" */
  ContactBase_id_key = 'ContactBase_id_key',
  /** unique or primary key constraint on columns "id" */
  ContactBase_pkey = 'ContactBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.ContactBase" */
export type v1_ContactBase_inc_input = {
  ActiveOffersCount?: InputMaybe<Scalars['Int']['input']>;
  AllPosts?: InputMaybe<Scalars['Int']['input']>;
  AssistantID?: InputMaybe<Scalars['Int']['input']>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  FinishedOrders?: InputMaybe<Scalars['Int']['input']>;
  SoldOffersCount?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  TotalOffersCount?: InputMaybe<Scalars['Int']['input']>;
  TotalOrdersSum?: InputMaybe<Scalars['Int']['input']>;
  TotalPayoutsSum?: InputMaybe<Scalars['Int']['input']>;
  balance?: InputMaybe<Scalars['numeric']['input']>;
  buzzstream_id?: InputMaybe<Scalars['Int']['input']>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Secondary language */
  language1_id?: InputMaybe<Scalars['Int']['input']>;
  /** Main language */
  language_id?: InputMaybe<Scalars['Int']['input']>;
  monthly_quota?: InputMaybe<Scalars['numeric']['input']>;
  new_Contacttype?: InputMaybe<Scalars['Int']['input']>;
  new_FullSeoPage?: InputMaybe<Scalars['numeric']['input']>;
  new_MainLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_Offers?: InputMaybe<Scalars['Int']['input']>;
  new_Paymentmethod?: InputMaybe<Scalars['Int']['input']>;
  new_Rating?: InputMaybe<Scalars['Int']['input']>;
  new_SecondaryLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_SeoShortPost?: InputMaybe<Scalars['numeric']['input']>;
  new_Translation?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagebig?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagejambo?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagelarge?: InputMaybe<Scalars['numeric']['input']>;
  new_longsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  new_shortsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentOption?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  price_per_word?: InputMaybe<Scalars['float8']['input']>;
  skill_level?: InputMaybe<Scalars['numeric']['input']>;
  upload_price?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "v1.ContactBase" */
export type v1_ContactBase_insert_input = {
  ActiveOffersCount?: InputMaybe<Scalars['Int']['input']>;
  AllPosts?: InputMaybe<Scalars['Int']['input']>;
  Assistant?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  AssistantID?: InputMaybe<Scalars['Int']['input']>;
  Balances?: InputMaybe<v1_Balance_arr_rel_insert_input>;
  BillingMethods?: InputMaybe<v1_BillingMethod_arr_rel_insert_input>;
  BirthDate?: InputMaybe<Scalars['timestamp']['input']>;
  Campaigns?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  City?: InputMaybe<v1_Cities_obj_rel_insert_input>;
  ContactId?: InputMaybe<Scalars['uuid']['input']>;
  ContactType?: InputMaybe<v1_ContactType_obj_rel_insert_input>;
  Contacts_Roles?: InputMaybe<v1_Contacts_Roles_arr_rel_insert_input>;
  Country?: InputMaybe<v1_new_countryBase_obj_rel_insert_input>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  Description?: InputMaybe<Scalars['String']['input']>;
  EMailAddress1?: InputMaybe<Scalars['String']['input']>;
  EMailAddress2?: InputMaybe<Scalars['String']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  FinishedOrders?: InputMaybe<Scalars['Int']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FullName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  MarketplaceProjects?: InputMaybe<v1_MarketplaceProjects_arr_rel_insert_input>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_obj_rel_insert_input>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  PrimaryLanguage?: InputMaybe<v1_new_languagesBase_obj_rel_insert_input>;
  SecondaryLanguage?: InputMaybe<v1_new_languagesBase_obj_rel_insert_input>;
  SoldOffersCount?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  SupplierTags?: InputMaybe<v1_Supplier_Tag_rel_arr_rel_insert_input>;
  Telephone1?: InputMaybe<Scalars['String']['input']>;
  TotalOffersCount?: InputMaybe<Scalars['Int']['input']>;
  TotalOrdersSum?: InputMaybe<Scalars['Int']['input']>;
  TotalPayoutsSum?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  allow_upload_content_portal?: InputMaybe<Scalars['Boolean']['input']>;
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  balance?: InputMaybe<Scalars['numeric']['input']>;
  billing_address?: InputMaybe<Scalars['String']['input']>;
  billing_zip_code?: InputMaybe<Scalars['String']['input']>;
  buzzstream_id?: InputMaybe<Scalars['Int']['input']>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Secondary language */
  language1_id?: InputMaybe<Scalars['Int']['input']>;
  /** Main language */
  language_id?: InputMaybe<Scalars['Int']['input']>;
  monthly_quota?: InputMaybe<Scalars['numeric']['input']>;
  newPobasesByAdvertiserid?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  newPobasesByWriter?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  new_AccountActive?: InputMaybe<Scalars['Boolean']['input']>;
  new_Blacklisted?: InputMaybe<Scalars['Boolean']['input']>;
  new_Contacttype?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_Facebook?: InputMaybe<Scalars['String']['input']>;
  new_FullSeoPage?: InputMaybe<Scalars['numeric']['input']>;
  new_IsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  new_Issupplier?: InputMaybe<Scalars['Boolean']['input']>;
  new_LastActivity?: InputMaybe<Scalars['timestamp']['input']>;
  new_LinkedIn?: InputMaybe<Scalars['String']['input']>;
  new_Linksexchanger?: InputMaybe<Scalars['Boolean']['input']>;
  new_MainLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_Offers?: InputMaybe<Scalars['Int']['input']>;
  new_POBases?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  new_Passportid?: InputMaybe<Scalars['String']['input']>;
  new_Password?: InputMaybe<Scalars['String']['input']>;
  new_PaymentAddress?: InputMaybe<Scalars['String']['input']>;
  new_Paymentmethod?: InputMaybe<Scalars['Int']['input']>;
  new_Rating?: InputMaybe<Scalars['Int']['input']>;
  new_SecondaryLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_SeoShortPost?: InputMaybe<Scalars['numeric']['input']>;
  new_Translation?: InputMaybe<Scalars['numeric']['input']>;
  new_Translator?: InputMaybe<Scalars['Boolean']['input']>;
  new_UserName?: InputMaybe<Scalars['String']['input']>;
  new_WebsitePassword?: InputMaybe<Scalars['String']['input']>;
  new_Writer?: InputMaybe<Scalars['Boolean']['input']>;
  new_bankaddress?: InputMaybe<Scalars['String']['input']>;
  new_bankibanaccount?: InputMaybe<Scalars['String']['input']>;
  new_bankname?: InputMaybe<Scalars['String']['input']>;
  new_bankswift?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryaddress?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryname?: InputMaybe<Scalars['String']['input']>;
  new_contacetype?: InputMaybe<Scalars['Boolean']['input']>;
  new_contactid?: InputMaybe<Scalars['String']['input']>;
  new_fullseopagebig?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagejambo?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagelarge?: InputMaybe<Scalars['numeric']['input']>;
  new_inhouse?: InputMaybe<Scalars['Boolean']['input']>;
  new_language?: InputMaybe<Scalars['uuid']['input']>;
  new_language1?: InputMaybe<Scalars['uuid']['input']>;
  new_longsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  new_shortsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentOption?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  postsByAdvertiserid?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsBySupplierId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  price_per_word?: InputMaybe<Scalars['float8']['input']>;
  skill_level?: InputMaybe<Scalars['numeric']['input']>;
  tag_ids?: InputMaybe<Scalars['_int4']['input']>;
  upload_price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type v1_ContactBase_max_fields = {
  __typename?: 'v1_ContactBase_max_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  avatar_url?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['numeric']['output']>;
  billing_address?: Maybe<Scalars['String']['output']>;
  billing_zip_code?: Maybe<Scalars['String']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  city_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Int']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "v1.ContactBase" */
export type v1_ContactBase_max_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  BirthDate?: InputMaybe<order_by>;
  ContactId?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  EMailAddress1?: InputMaybe<order_by>;
  EMailAddress2?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  FirstName?: InputMaybe<order_by>;
  FullName?: InputMaybe<order_by>;
  LastName?: InputMaybe<order_by>;
  MobilePhone?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  Telephone1?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  avatar_url?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  billing_address?: InputMaybe<order_by>;
  billing_zip_code?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_Facebook?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_LastActivity?: InputMaybe<order_by>;
  new_LinkedIn?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Passportid?: InputMaybe<order_by>;
  new_Password?: InputMaybe<order_by>;
  new_PaymentAddress?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_UserName?: InputMaybe<order_by>;
  new_WebsitePassword?: InputMaybe<order_by>;
  new_bankaddress?: InputMaybe<order_by>;
  new_bankibanaccount?: InputMaybe<order_by>;
  new_bankname?: InputMaybe<order_by>;
  new_bankswift?: InputMaybe<order_by>;
  new_beneficiaryaddress?: InputMaybe<order_by>;
  new_beneficiaryname?: InputMaybe<order_by>;
  new_contactid?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_language?: InputMaybe<order_by>;
  new_language1?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_ContactBase_min_fields = {
  __typename?: 'v1_ContactBase_min_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  avatar_url?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['numeric']['output']>;
  billing_address?: Maybe<Scalars['String']['output']>;
  billing_zip_code?: Maybe<Scalars['String']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  city_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Int']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "v1.ContactBase" */
export type v1_ContactBase_min_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  BirthDate?: InputMaybe<order_by>;
  ContactId?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  EMailAddress1?: InputMaybe<order_by>;
  EMailAddress2?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  FirstName?: InputMaybe<order_by>;
  FullName?: InputMaybe<order_by>;
  LastName?: InputMaybe<order_by>;
  MobilePhone?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  Telephone1?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  avatar_url?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  billing_address?: InputMaybe<order_by>;
  billing_zip_code?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_Facebook?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_LastActivity?: InputMaybe<order_by>;
  new_LinkedIn?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Passportid?: InputMaybe<order_by>;
  new_Password?: InputMaybe<order_by>;
  new_PaymentAddress?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_UserName?: InputMaybe<order_by>;
  new_WebsitePassword?: InputMaybe<order_by>;
  new_bankaddress?: InputMaybe<order_by>;
  new_bankibanaccount?: InputMaybe<order_by>;
  new_bankname?: InputMaybe<order_by>;
  new_bankswift?: InputMaybe<order_by>;
  new_beneficiaryaddress?: InputMaybe<order_by>;
  new_beneficiaryname?: InputMaybe<order_by>;
  new_contactid?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_language?: InputMaybe<order_by>;
  new_language1?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.ContactBase" */
export type v1_ContactBase_mutation_response = {
  __typename?: 'v1_ContactBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ContactBase>;
};

/** input type for inserting object relation for remote table "v1.ContactBase" */
export type v1_ContactBase_obj_rel_insert_input = {
  data: v1_ContactBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ContactBase_on_conflict>;
};

/** on_conflict condition type for table "v1.ContactBase" */
export type v1_ContactBase_on_conflict = {
  constraint: v1_ContactBase_constraint;
  update_columns?: Array<v1_ContactBase_update_column>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.ContactBase". */
export type v1_ContactBase_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  Assistant?: InputMaybe<v1_SystemUserBase_order_by>;
  AssistantID?: InputMaybe<order_by>;
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_order_by>;
  BillingMethods_aggregate?: InputMaybe<v1_BillingMethod_aggregate_order_by>;
  BirthDate?: InputMaybe<order_by>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  City?: InputMaybe<v1_Cities_order_by>;
  ContactId?: InputMaybe<order_by>;
  ContactType?: InputMaybe<v1_ContactType_order_by>;
  Contacts_Roles_aggregate?: InputMaybe<v1_Contacts_Roles_aggregate_order_by>;
  Country?: InputMaybe<v1_new_countryBase_order_by>;
  CountryID?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  Description?: InputMaybe<order_by>;
  EMailAddress1?: InputMaybe<order_by>;
  EMailAddress2?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  FirstName?: InputMaybe<order_by>;
  FullName?: InputMaybe<order_by>;
  LastName?: InputMaybe<order_by>;
  MarketplaceProjects_aggregate?: InputMaybe<v1_MarketplaceProjects_aggregate_order_by>;
  MobilePhone?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  OwnerId?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  PrimaryLanguage?: InputMaybe<v1_new_languagesBase_order_by>;
  SecondaryLanguage?: InputMaybe<v1_new_languagesBase_order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  SupplierTags_aggregate?: InputMaybe<v1_Supplier_Tag_rel_aggregate_order_by>;
  Telephone1?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  allow_upload_content_portal?: InputMaybe<order_by>;
  avatar_url?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  billing_address?: InputMaybe<order_by>;
  billing_zip_code?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  comp_supplier_in_progress_posts?: InputMaybe<order_by>;
  comp_supplier_offers?: InputMaybe<order_by>;
  comp_supplier_posted_posts?: InputMaybe<order_by>;
  comp_supplier_purchase_orders?: InputMaybe<order_by>;
  comp_writer_approved_posts?: InputMaybe<order_by>;
  comp_writer_purchase_orders?: InputMaybe<order_by>;
  comp_writer_waiting_approval_posts?: InputMaybe<order_by>;
  comp_writers_ordered_posts?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inprogress_posts?: InputMaybe<order_by>;
  language1_id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  left_quota?: InputMaybe<order_by>;
  links_bought_last_month?: InputMaybe<order_by>;
  links_bought_this_month?: InputMaybe<order_by>;
  links_bought_total?: InputMaybe<order_by>;
  links_status_in_progress?: InputMaybe<order_by>;
  links_status_live?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  newPobasesByAdvertiserid_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  newPobasesByWriter_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  new_AccountActive?: InputMaybe<order_by>;
  new_Blacklisted?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_Facebook?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_IsCustomer?: InputMaybe<order_by>;
  new_Issupplier?: InputMaybe<order_by>;
  new_LastActivity?: InputMaybe<order_by>;
  new_LinkedIn?: InputMaybe<order_by>;
  new_Linksexchanger?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_POBases_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  new_Passportid?: InputMaybe<order_by>;
  new_Password?: InputMaybe<order_by>;
  new_PaymentAddress?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_Translator?: InputMaybe<order_by>;
  new_UserName?: InputMaybe<order_by>;
  new_WebsitePassword?: InputMaybe<order_by>;
  new_Writer?: InputMaybe<order_by>;
  new_bankaddress?: InputMaybe<order_by>;
  new_bankibanaccount?: InputMaybe<order_by>;
  new_bankname?: InputMaybe<order_by>;
  new_bankswift?: InputMaybe<order_by>;
  new_beneficiaryaddress?: InputMaybe<order_by>;
  new_beneficiaryname?: InputMaybe<order_by>;
  new_contacetype?: InputMaybe<order_by>;
  new_contactid?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_inhouse?: InputMaybe<order_by>;
  new_language?: InputMaybe<order_by>;
  new_language1?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  postsByAdvertiserid_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsBySupplierId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  tag_ids?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ContactBase */
export type v1_ContactBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column {
  /** column name */
  ActiveOffersCount = 'ActiveOffersCount',
  /** column name */
  AllPosts = 'AllPosts',
  /** column name */
  AssistantID = 'AssistantID',
  /** column name */
  BirthDate = 'BirthDate',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CountryID = 'CountryID',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  EMailAddress1 = 'EMailAddress1',
  /** column name */
  EMailAddress2 = 'EMailAddress2',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  FinishedOrders = 'FinishedOrders',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  FullName = 'FullName',
  /** column name */
  LastName = 'LastName',
  /** column name */
  MobilePhone = 'MobilePhone',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  SoldOffersCount = 'SoldOffersCount',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  Telephone1 = 'Telephone1',
  /** column name */
  TotalOffersCount = 'TotalOffersCount',
  /** column name */
  TotalOrdersSum = 'TotalOrdersSum',
  /** column name */
  TotalPayoutsSum = 'TotalPayoutsSum',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  avatar_url = 'avatar_url',
  /** column name */
  balance = 'balance',
  /** column name */
  billing_address = 'billing_address',
  /** column name */
  billing_zip_code = 'billing_zip_code',
  /** column name */
  buzzstream_id = 'buzzstream_id',
  /** column name */
  city_id = 'city_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  language1_id = 'language1_id',
  /** column name */
  language_id = 'language_id',
  /** column name */
  monthly_quota = 'monthly_quota',
  /** column name */
  new_AccountActive = 'new_AccountActive',
  /** column name */
  new_Blacklisted = 'new_Blacklisted',
  /** column name */
  new_Contacttype = 'new_Contacttype',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_Facebook = 'new_Facebook',
  /** column name */
  new_FullSeoPage = 'new_FullSeoPage',
  /** column name */
  new_IsCustomer = 'new_IsCustomer',
  /** column name */
  new_Issupplier = 'new_Issupplier',
  /** column name */
  new_LastActivity = 'new_LastActivity',
  /** column name */
  new_LinkedIn = 'new_LinkedIn',
  /** column name */
  new_Linksexchanger = 'new_Linksexchanger',
  /** column name */
  new_MainLanguage = 'new_MainLanguage',
  /** column name */
  new_Offers = 'new_Offers',
  /** column name */
  new_Passportid = 'new_Passportid',
  /** column name */
  new_Password = 'new_Password',
  /** column name */
  new_PaymentAddress = 'new_PaymentAddress',
  /** column name */
  new_Paymentmethod = 'new_Paymentmethod',
  /** column name */
  new_Rating = 'new_Rating',
  /** column name */
  new_SecondaryLanguage = 'new_SecondaryLanguage',
  /** column name */
  new_SeoShortPost = 'new_SeoShortPost',
  /** column name */
  new_Translation = 'new_Translation',
  /** column name */
  new_Translator = 'new_Translator',
  /** column name */
  new_UserName = 'new_UserName',
  /** column name */
  new_WebsitePassword = 'new_WebsitePassword',
  /** column name */
  new_Writer = 'new_Writer',
  /** column name */
  new_bankaddress = 'new_bankaddress',
  /** column name */
  new_bankibanaccount = 'new_bankibanaccount',
  /** column name */
  new_bankname = 'new_bankname',
  /** column name */
  new_bankswift = 'new_bankswift',
  /** column name */
  new_beneficiaryaddress = 'new_beneficiaryaddress',
  /** column name */
  new_beneficiaryname = 'new_beneficiaryname',
  /** column name */
  new_contacetype = 'new_contacetype',
  /** column name */
  new_contactid = 'new_contactid',
  /** column name */
  new_fullseopagebig = 'new_fullseopagebig',
  /** column name */
  new_fullseopagejambo = 'new_fullseopagejambo',
  /** column name */
  new_fullseopagelarge = 'new_fullseopagelarge',
  /** column name */
  new_inhouse = 'new_inhouse',
  /** column name */
  new_language = 'new_language',
  /** column name */
  new_language1 = 'new_language1',
  /** column name */
  new_longsocialpost = 'new_longsocialpost',
  /** column name */
  new_shortsocialpost = 'new_shortsocialpost',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentOption = 'paymentOption',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  price_per_word = 'price_per_word',
  /** column name */
  skill_level = 'skill_level',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  upload_price = 'upload_price'
}

/** select "v1_ContactBase_aggregate_bool_exp_avg_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_avg_arguments_columns {
  /** column name */
  price_per_word = 'price_per_word'
}

/** select "v1_ContactBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  new_AccountActive = 'new_AccountActive',
  /** column name */
  new_Blacklisted = 'new_Blacklisted',
  /** column name */
  new_IsCustomer = 'new_IsCustomer',
  /** column name */
  new_Issupplier = 'new_Issupplier',
  /** column name */
  new_Linksexchanger = 'new_Linksexchanger',
  /** column name */
  new_Translator = 'new_Translator',
  /** column name */
  new_Writer = 'new_Writer',
  /** column name */
  new_contacetype = 'new_contacetype',
  /** column name */
  new_inhouse = 'new_inhouse'
}

/** select "v1_ContactBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  new_AccountActive = 'new_AccountActive',
  /** column name */
  new_Blacklisted = 'new_Blacklisted',
  /** column name */
  new_IsCustomer = 'new_IsCustomer',
  /** column name */
  new_Issupplier = 'new_Issupplier',
  /** column name */
  new_Linksexchanger = 'new_Linksexchanger',
  /** column name */
  new_Translator = 'new_Translator',
  /** column name */
  new_Writer = 'new_Writer',
  /** column name */
  new_contacetype = 'new_contacetype',
  /** column name */
  new_inhouse = 'new_inhouse'
}

/** select "v1_ContactBase_aggregate_bool_exp_corr_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_corr_arguments_columns {
  /** column name */
  price_per_word = 'price_per_word'
}

/** select "v1_ContactBase_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_covar_samp_arguments_columns {
  /** column name */
  price_per_word = 'price_per_word'
}

/** select "v1_ContactBase_aggregate_bool_exp_max_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_max_arguments_columns {
  /** column name */
  price_per_word = 'price_per_word'
}

/** select "v1_ContactBase_aggregate_bool_exp_min_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_min_arguments_columns {
  /** column name */
  price_per_word = 'price_per_word'
}

/** select "v1_ContactBase_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_stddev_samp_arguments_columns {
  /** column name */
  price_per_word = 'price_per_word'
}

/** select "v1_ContactBase_aggregate_bool_exp_sum_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_sum_arguments_columns {
  /** column name */
  price_per_word = 'price_per_word'
}

/** select "v1_ContactBase_aggregate_bool_exp_var_samp_arguments_columns" columns of table "v1.ContactBase" */
export enum v1_ContactBase_select_column_v1_ContactBase_aggregate_bool_exp_var_samp_arguments_columns {
  /** column name */
  price_per_word = 'price_per_word'
}

/** input type for updating data in table "v1.ContactBase" */
export type v1_ContactBase_set_input = {
  ActiveOffersCount?: InputMaybe<Scalars['Int']['input']>;
  AllPosts?: InputMaybe<Scalars['Int']['input']>;
  AssistantID?: InputMaybe<Scalars['Int']['input']>;
  BirthDate?: InputMaybe<Scalars['timestamp']['input']>;
  ContactId?: InputMaybe<Scalars['uuid']['input']>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  EMailAddress1?: InputMaybe<Scalars['String']['input']>;
  EMailAddress2?: InputMaybe<Scalars['String']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  FinishedOrders?: InputMaybe<Scalars['Int']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FullName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  SoldOffersCount?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  Telephone1?: InputMaybe<Scalars['String']['input']>;
  TotalOffersCount?: InputMaybe<Scalars['Int']['input']>;
  TotalOrdersSum?: InputMaybe<Scalars['Int']['input']>;
  TotalPayoutsSum?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  allow_upload_content_portal?: InputMaybe<Scalars['Boolean']['input']>;
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  balance?: InputMaybe<Scalars['numeric']['input']>;
  billing_address?: InputMaybe<Scalars['String']['input']>;
  billing_zip_code?: InputMaybe<Scalars['String']['input']>;
  buzzstream_id?: InputMaybe<Scalars['Int']['input']>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Secondary language */
  language1_id?: InputMaybe<Scalars['Int']['input']>;
  /** Main language */
  language_id?: InputMaybe<Scalars['Int']['input']>;
  monthly_quota?: InputMaybe<Scalars['numeric']['input']>;
  new_AccountActive?: InputMaybe<Scalars['Boolean']['input']>;
  new_Blacklisted?: InputMaybe<Scalars['Boolean']['input']>;
  new_Contacttype?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_Facebook?: InputMaybe<Scalars['String']['input']>;
  new_FullSeoPage?: InputMaybe<Scalars['numeric']['input']>;
  new_IsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  new_Issupplier?: InputMaybe<Scalars['Boolean']['input']>;
  new_LastActivity?: InputMaybe<Scalars['timestamp']['input']>;
  new_LinkedIn?: InputMaybe<Scalars['String']['input']>;
  new_Linksexchanger?: InputMaybe<Scalars['Boolean']['input']>;
  new_MainLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_Offers?: InputMaybe<Scalars['Int']['input']>;
  new_Passportid?: InputMaybe<Scalars['String']['input']>;
  new_Password?: InputMaybe<Scalars['String']['input']>;
  new_PaymentAddress?: InputMaybe<Scalars['String']['input']>;
  new_Paymentmethod?: InputMaybe<Scalars['Int']['input']>;
  new_Rating?: InputMaybe<Scalars['Int']['input']>;
  new_SecondaryLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_SeoShortPost?: InputMaybe<Scalars['numeric']['input']>;
  new_Translation?: InputMaybe<Scalars['numeric']['input']>;
  new_Translator?: InputMaybe<Scalars['Boolean']['input']>;
  new_UserName?: InputMaybe<Scalars['String']['input']>;
  new_WebsitePassword?: InputMaybe<Scalars['String']['input']>;
  new_Writer?: InputMaybe<Scalars['Boolean']['input']>;
  new_bankaddress?: InputMaybe<Scalars['String']['input']>;
  new_bankibanaccount?: InputMaybe<Scalars['String']['input']>;
  new_bankname?: InputMaybe<Scalars['String']['input']>;
  new_bankswift?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryaddress?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryname?: InputMaybe<Scalars['String']['input']>;
  new_contacetype?: InputMaybe<Scalars['Boolean']['input']>;
  new_contactid?: InputMaybe<Scalars['String']['input']>;
  new_fullseopagebig?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagejambo?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagelarge?: InputMaybe<Scalars['numeric']['input']>;
  new_inhouse?: InputMaybe<Scalars['Boolean']['input']>;
  new_language?: InputMaybe<Scalars['uuid']['input']>;
  new_language1?: InputMaybe<Scalars['uuid']['input']>;
  new_longsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  new_shortsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentOption?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  price_per_word?: InputMaybe<Scalars['float8']['input']>;
  skill_level?: InputMaybe<Scalars['numeric']['input']>;
  tag_ids?: InputMaybe<Scalars['_int4']['input']>;
  upload_price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type v1_ContactBase_stddev_fields = {
  __typename?: 'v1_ContactBase_stddev_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Float']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.ContactBase" */
export type v1_ContactBase_stddev_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_ContactBase_stddev_pop_fields = {
  __typename?: 'v1_ContactBase_stddev_pop_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Float']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.ContactBase" */
export type v1_ContactBase_stddev_pop_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_ContactBase_stddev_samp_fields = {
  __typename?: 'v1_ContactBase_stddev_samp_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Float']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.ContactBase" */
export type v1_ContactBase_stddev_samp_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_ContactBase" */
export type v1_ContactBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ContactBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ContactBase_stream_cursor_value_input = {
  ActiveOffersCount?: InputMaybe<Scalars['Int']['input']>;
  AllPosts?: InputMaybe<Scalars['Int']['input']>;
  AssistantID?: InputMaybe<Scalars['Int']['input']>;
  BirthDate?: InputMaybe<Scalars['timestamp']['input']>;
  ContactId?: InputMaybe<Scalars['uuid']['input']>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  EMailAddress1?: InputMaybe<Scalars['String']['input']>;
  EMailAddress2?: InputMaybe<Scalars['String']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  FinishedOrders?: InputMaybe<Scalars['Int']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FullName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  SoldOffersCount?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  Telephone1?: InputMaybe<Scalars['String']['input']>;
  TotalOffersCount?: InputMaybe<Scalars['Int']['input']>;
  TotalOrdersSum?: InputMaybe<Scalars['Int']['input']>;
  TotalPayoutsSum?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  allow_upload_content_portal?: InputMaybe<Scalars['Boolean']['input']>;
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  balance?: InputMaybe<Scalars['numeric']['input']>;
  billing_address?: InputMaybe<Scalars['String']['input']>;
  billing_zip_code?: InputMaybe<Scalars['String']['input']>;
  buzzstream_id?: InputMaybe<Scalars['Int']['input']>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Secondary language */
  language1_id?: InputMaybe<Scalars['Int']['input']>;
  /** Main language */
  language_id?: InputMaybe<Scalars['Int']['input']>;
  monthly_quota?: InputMaybe<Scalars['numeric']['input']>;
  new_AccountActive?: InputMaybe<Scalars['Boolean']['input']>;
  new_Blacklisted?: InputMaybe<Scalars['Boolean']['input']>;
  new_Contacttype?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_Facebook?: InputMaybe<Scalars['String']['input']>;
  new_FullSeoPage?: InputMaybe<Scalars['numeric']['input']>;
  new_IsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  new_Issupplier?: InputMaybe<Scalars['Boolean']['input']>;
  new_LastActivity?: InputMaybe<Scalars['timestamp']['input']>;
  new_LinkedIn?: InputMaybe<Scalars['String']['input']>;
  new_Linksexchanger?: InputMaybe<Scalars['Boolean']['input']>;
  new_MainLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_Offers?: InputMaybe<Scalars['Int']['input']>;
  new_Passportid?: InputMaybe<Scalars['String']['input']>;
  new_Password?: InputMaybe<Scalars['String']['input']>;
  new_PaymentAddress?: InputMaybe<Scalars['String']['input']>;
  new_Paymentmethod?: InputMaybe<Scalars['Int']['input']>;
  new_Rating?: InputMaybe<Scalars['Int']['input']>;
  new_SecondaryLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_SeoShortPost?: InputMaybe<Scalars['numeric']['input']>;
  new_Translation?: InputMaybe<Scalars['numeric']['input']>;
  new_Translator?: InputMaybe<Scalars['Boolean']['input']>;
  new_UserName?: InputMaybe<Scalars['String']['input']>;
  new_WebsitePassword?: InputMaybe<Scalars['String']['input']>;
  new_Writer?: InputMaybe<Scalars['Boolean']['input']>;
  new_bankaddress?: InputMaybe<Scalars['String']['input']>;
  new_bankibanaccount?: InputMaybe<Scalars['String']['input']>;
  new_bankname?: InputMaybe<Scalars['String']['input']>;
  new_bankswift?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryaddress?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryname?: InputMaybe<Scalars['String']['input']>;
  new_contacetype?: InputMaybe<Scalars['Boolean']['input']>;
  new_contactid?: InputMaybe<Scalars['String']['input']>;
  new_fullseopagebig?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagejambo?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagelarge?: InputMaybe<Scalars['numeric']['input']>;
  new_inhouse?: InputMaybe<Scalars['Boolean']['input']>;
  new_language?: InputMaybe<Scalars['uuid']['input']>;
  new_language1?: InputMaybe<Scalars['uuid']['input']>;
  new_longsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  new_shortsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentOption?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  price_per_word?: InputMaybe<Scalars['float8']['input']>;
  skill_level?: InputMaybe<Scalars['numeric']['input']>;
  tag_ids?: InputMaybe<Scalars['_int4']['input']>;
  upload_price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type v1_ContactBase_sum_fields = {
  __typename?: 'v1_ContactBase_sum_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  balance?: Maybe<Scalars['numeric']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  city_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Int']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "v1.ContactBase" */
export type v1_ContactBase_sum_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** update columns of table "v1.ContactBase" */
export enum v1_ContactBase_update_column {
  /** column name */
  ActiveOffersCount = 'ActiveOffersCount',
  /** column name */
  AllPosts = 'AllPosts',
  /** column name */
  AssistantID = 'AssistantID',
  /** column name */
  BirthDate = 'BirthDate',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CountryID = 'CountryID',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  EMailAddress1 = 'EMailAddress1',
  /** column name */
  EMailAddress2 = 'EMailAddress2',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  FinishedOrders = 'FinishedOrders',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  FullName = 'FullName',
  /** column name */
  LastName = 'LastName',
  /** column name */
  MobilePhone = 'MobilePhone',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  SoldOffersCount = 'SoldOffersCount',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  Telephone1 = 'Telephone1',
  /** column name */
  TotalOffersCount = 'TotalOffersCount',
  /** column name */
  TotalOrdersSum = 'TotalOrdersSum',
  /** column name */
  TotalPayoutsSum = 'TotalPayoutsSum',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  avatar_url = 'avatar_url',
  /** column name */
  balance = 'balance',
  /** column name */
  billing_address = 'billing_address',
  /** column name */
  billing_zip_code = 'billing_zip_code',
  /** column name */
  buzzstream_id = 'buzzstream_id',
  /** column name */
  city_id = 'city_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  language1_id = 'language1_id',
  /** column name */
  language_id = 'language_id',
  /** column name */
  monthly_quota = 'monthly_quota',
  /** column name */
  new_AccountActive = 'new_AccountActive',
  /** column name */
  new_Blacklisted = 'new_Blacklisted',
  /** column name */
  new_Contacttype = 'new_Contacttype',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_Facebook = 'new_Facebook',
  /** column name */
  new_FullSeoPage = 'new_FullSeoPage',
  /** column name */
  new_IsCustomer = 'new_IsCustomer',
  /** column name */
  new_Issupplier = 'new_Issupplier',
  /** column name */
  new_LastActivity = 'new_LastActivity',
  /** column name */
  new_LinkedIn = 'new_LinkedIn',
  /** column name */
  new_Linksexchanger = 'new_Linksexchanger',
  /** column name */
  new_MainLanguage = 'new_MainLanguage',
  /** column name */
  new_Offers = 'new_Offers',
  /** column name */
  new_Passportid = 'new_Passportid',
  /** column name */
  new_Password = 'new_Password',
  /** column name */
  new_PaymentAddress = 'new_PaymentAddress',
  /** column name */
  new_Paymentmethod = 'new_Paymentmethod',
  /** column name */
  new_Rating = 'new_Rating',
  /** column name */
  new_SecondaryLanguage = 'new_SecondaryLanguage',
  /** column name */
  new_SeoShortPost = 'new_SeoShortPost',
  /** column name */
  new_Translation = 'new_Translation',
  /** column name */
  new_Translator = 'new_Translator',
  /** column name */
  new_UserName = 'new_UserName',
  /** column name */
  new_WebsitePassword = 'new_WebsitePassword',
  /** column name */
  new_Writer = 'new_Writer',
  /** column name */
  new_bankaddress = 'new_bankaddress',
  /** column name */
  new_bankibanaccount = 'new_bankibanaccount',
  /** column name */
  new_bankname = 'new_bankname',
  /** column name */
  new_bankswift = 'new_bankswift',
  /** column name */
  new_beneficiaryaddress = 'new_beneficiaryaddress',
  /** column name */
  new_beneficiaryname = 'new_beneficiaryname',
  /** column name */
  new_contacetype = 'new_contacetype',
  /** column name */
  new_contactid = 'new_contactid',
  /** column name */
  new_fullseopagebig = 'new_fullseopagebig',
  /** column name */
  new_fullseopagejambo = 'new_fullseopagejambo',
  /** column name */
  new_fullseopagelarge = 'new_fullseopagelarge',
  /** column name */
  new_inhouse = 'new_inhouse',
  /** column name */
  new_language = 'new_language',
  /** column name */
  new_language1 = 'new_language1',
  /** column name */
  new_longsocialpost = 'new_longsocialpost',
  /** column name */
  new_shortsocialpost = 'new_shortsocialpost',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentOption = 'paymentOption',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  price_per_word = 'price_per_word',
  /** column name */
  skill_level = 'skill_level',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  upload_price = 'upload_price'
}

export type v1_ContactBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ContactBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ContactBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ContactBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ContactBase_var_pop_fields = {
  __typename?: 'v1_ContactBase_var_pop_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Float']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.ContactBase" */
export type v1_ContactBase_var_pop_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_ContactBase_var_samp_fields = {
  __typename?: 'v1_ContactBase_var_samp_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Float']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.ContactBase" */
export type v1_ContactBase_var_samp_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_ContactBase_variance_fields = {
  __typename?: 'v1_ContactBase_variance_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  /** Secondary language */
  language1_id?: Maybe<Scalars['Float']['output']>;
  /** Main language */
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.ContactBase" */
export type v1_ContactBase_variance_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  balance?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  city_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  /** Secondary language */
  language1_id?: InputMaybe<order_by>;
  /** Main language */
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** ContactType Option set */
export type v1_ContactType = {
  __typename?: 'v1_ContactType';
  /** An array relationship */
  Contacts: Array<v1_ContactBase>;
  /** An aggregate relationship */
  Contacts_aggregate: v1_ContactBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** ContactType Option set */
export type v1_ContactTypeContactsArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** ContactType Option set */
export type v1_ContactTypeContacts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};

/** aggregated selection of "v1.ContactType" */
export type v1_ContactType_aggregate = {
  __typename?: 'v1_ContactType_aggregate';
  aggregate?: Maybe<v1_ContactType_aggregate_fields>;
  nodes: Array<v1_ContactType>;
};

/** aggregate fields of "v1.ContactType" */
export type v1_ContactType_aggregate_fields = {
  __typename?: 'v1_ContactType_aggregate_fields';
  avg?: Maybe<v1_ContactType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ContactType_max_fields>;
  min?: Maybe<v1_ContactType_min_fields>;
  stddev?: Maybe<v1_ContactType_stddev_fields>;
  stddev_pop?: Maybe<v1_ContactType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ContactType_stddev_samp_fields>;
  sum?: Maybe<v1_ContactType_sum_fields>;
  var_pop?: Maybe<v1_ContactType_var_pop_fields>;
  var_samp?: Maybe<v1_ContactType_var_samp_fields>;
  variance?: Maybe<v1_ContactType_variance_fields>;
};


/** aggregate fields of "v1.ContactType" */
export type v1_ContactType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ContactType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ContactType_avg_fields = {
  __typename?: 'v1_ContactType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ContactType". All fields are combined with a logical 'AND'. */
export type v1_ContactType_bool_exp = {
  Contacts?: InputMaybe<v1_ContactBase_bool_exp>;
  Contacts_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_ContactType_bool_exp>>;
  _not?: InputMaybe<v1_ContactType_bool_exp>;
  _or?: InputMaybe<Array<v1_ContactType_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ContactType" */
export enum v1_ContactType_constraint {
  /** unique or primary key constraint on columns "id" */
  ContactType_pkey = 'ContactType_pkey'
}

/** input type for incrementing numeric columns in table "v1.ContactType" */
export type v1_ContactType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ContactType" */
export type v1_ContactType_insert_input = {
  Contacts?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_ContactType_max_fields = {
  __typename?: 'v1_ContactType_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_ContactType_min_fields = {
  __typename?: 'v1_ContactType_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.ContactType" */
export type v1_ContactType_mutation_response = {
  __typename?: 'v1_ContactType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ContactType>;
};

/** input type for inserting object relation for remote table "v1.ContactType" */
export type v1_ContactType_obj_rel_insert_input = {
  data: v1_ContactType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ContactType_on_conflict>;
};

/** on_conflict condition type for table "v1.ContactType" */
export type v1_ContactType_on_conflict = {
  constraint: v1_ContactType_constraint;
  update_columns?: Array<v1_ContactType_update_column>;
  where?: InputMaybe<v1_ContactType_bool_exp>;
};

/** Ordering options when selecting data from "v1.ContactType". */
export type v1_ContactType_order_by = {
  Contacts_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ContactType */
export type v1_ContactType_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ContactType" */
export enum v1_ContactType_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.ContactType" */
export type v1_ContactType_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_ContactType_stddev_fields = {
  __typename?: 'v1_ContactType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ContactType_stddev_pop_fields = {
  __typename?: 'v1_ContactType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ContactType_stddev_samp_fields = {
  __typename?: 'v1_ContactType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ContactType" */
export type v1_ContactType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ContactType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ContactType_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ContactType_sum_fields = {
  __typename?: 'v1_ContactType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ContactType" */
export enum v1_ContactType_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_ContactType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ContactType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ContactType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ContactType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ContactType_var_pop_fields = {
  __typename?: 'v1_ContactType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ContactType_var_samp_fields = {
  __typename?: 'v1_ContactType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ContactType_variance_fields = {
  __typename?: 'v1_ContactType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Content Portal */
export type v1_Contacts_Roles = {
  __typename?: 'v1_Contacts_Roles';
  /** An object relationship */
  Contacts: v1_ContactBase;
  /** An object relationship */
  Roles: v1_Roles;
  contact_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  role_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.Contacts_Roles" */
export type v1_Contacts_Roles_aggregate = {
  __typename?: 'v1_Contacts_Roles_aggregate';
  aggregate?: Maybe<v1_Contacts_Roles_aggregate_fields>;
  nodes: Array<v1_Contacts_Roles>;
};

export type v1_Contacts_Roles_aggregate_bool_exp = {
  count?: InputMaybe<v1_Contacts_Roles_aggregate_bool_exp_count>;
};

export type v1_Contacts_Roles_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Contacts_Roles_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Contacts_Roles" */
export type v1_Contacts_Roles_aggregate_fields = {
  __typename?: 'v1_Contacts_Roles_aggregate_fields';
  avg?: Maybe<v1_Contacts_Roles_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Contacts_Roles_max_fields>;
  min?: Maybe<v1_Contacts_Roles_min_fields>;
  stddev?: Maybe<v1_Contacts_Roles_stddev_fields>;
  stddev_pop?: Maybe<v1_Contacts_Roles_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Contacts_Roles_stddev_samp_fields>;
  sum?: Maybe<v1_Contacts_Roles_sum_fields>;
  var_pop?: Maybe<v1_Contacts_Roles_var_pop_fields>;
  var_samp?: Maybe<v1_Contacts_Roles_var_samp_fields>;
  variance?: Maybe<v1_Contacts_Roles_variance_fields>;
};


/** aggregate fields of "v1.Contacts_Roles" */
export type v1_Contacts_Roles_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_aggregate_order_by = {
  avg?: InputMaybe<v1_Contacts_Roles_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Contacts_Roles_max_order_by>;
  min?: InputMaybe<v1_Contacts_Roles_min_order_by>;
  stddev?: InputMaybe<v1_Contacts_Roles_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Contacts_Roles_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Contacts_Roles_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Contacts_Roles_sum_order_by>;
  var_pop?: InputMaybe<v1_Contacts_Roles_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Contacts_Roles_var_samp_order_by>;
  variance?: InputMaybe<v1_Contacts_Roles_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_arr_rel_insert_input = {
  data: Array<v1_Contacts_Roles_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Contacts_Roles_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Contacts_Roles_avg_fields = {
  __typename?: 'v1_Contacts_Roles_avg_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_avg_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Contacts_Roles". All fields are combined with a logical 'AND'. */
export type v1_Contacts_Roles_bool_exp = {
  Contacts?: InputMaybe<v1_ContactBase_bool_exp>;
  Roles?: InputMaybe<v1_Roles_bool_exp>;
  _and?: InputMaybe<Array<v1_Contacts_Roles_bool_exp>>;
  _not?: InputMaybe<v1_Contacts_Roles_bool_exp>;
  _or?: InputMaybe<Array<v1_Contacts_Roles_bool_exp>>;
  contact_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  role_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Contacts_Roles" */
export enum v1_Contacts_Roles_constraint {
  /** unique or primary key constraint on columns "id" */
  Contacts_Roles_id_key = 'Contacts_Roles_id_key',
  /** unique or primary key constraint on columns "role_id", "contact_id" */
  Contacts_Roles_pkey = 'Contacts_Roles_pkey'
}

/** input type for incrementing numeric columns in table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_inc_input = {
  contact_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_insert_input = {
  Contacts?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  Roles?: InputMaybe<v1_Roles_obj_rel_insert_input>;
  contact_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Contacts_Roles_max_fields = {
  __typename?: 'v1_Contacts_Roles_max_fields';
  contact_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_max_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Contacts_Roles_min_fields = {
  __typename?: 'v1_Contacts_Roles_min_fields';
  contact_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_min_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_mutation_response = {
  __typename?: 'v1_Contacts_Roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Contacts_Roles>;
};

/** on_conflict condition type for table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_on_conflict = {
  constraint: v1_Contacts_Roles_constraint;
  update_columns?: Array<v1_Contacts_Roles_update_column>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};

/** Ordering options when selecting data from "v1.Contacts_Roles". */
export type v1_Contacts_Roles_order_by = {
  Contacts?: InputMaybe<v1_ContactBase_order_by>;
  Roles?: InputMaybe<v1_Roles_order_by>;
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Contacts_Roles */
export type v1_Contacts_Roles_pk_columns_input = {
  contact_id: Scalars['Int']['input'];
  role_id: Scalars['Int']['input'];
};

/** select columns of table "v1.Contacts_Roles" */
export enum v1_Contacts_Roles_select_column {
  /** column name */
  contact_id = 'contact_id',
  /** column name */
  id = 'id',
  /** column name */
  role_id = 'role_id'
}

/** input type for updating data in table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_set_input = {
  contact_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Contacts_Roles_stddev_fields = {
  __typename?: 'v1_Contacts_Roles_stddev_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_stddev_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Contacts_Roles_stddev_pop_fields = {
  __typename?: 'v1_Contacts_Roles_stddev_pop_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_stddev_pop_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Contacts_Roles_stddev_samp_fields = {
  __typename?: 'v1_Contacts_Roles_stddev_samp_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_stddev_samp_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Contacts_Roles" */
export type v1_Contacts_Roles_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Contacts_Roles_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Contacts_Roles_stream_cursor_value_input = {
  contact_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Contacts_Roles_sum_fields = {
  __typename?: 'v1_Contacts_Roles_sum_fields';
  contact_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_sum_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Contacts_Roles" */
export enum v1_Contacts_Roles_update_column {
  /** column name */
  contact_id = 'contact_id',
  /** column name */
  id = 'id',
  /** column name */
  role_id = 'role_id'
}

export type v1_Contacts_Roles_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Contacts_Roles_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Contacts_Roles_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Contacts_Roles_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Contacts_Roles_var_pop_fields = {
  __typename?: 'v1_Contacts_Roles_var_pop_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_var_pop_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Contacts_Roles_var_samp_fields = {
  __typename?: 'v1_Contacts_Roles_var_samp_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_var_samp_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Contacts_Roles_variance_fields = {
  __typename?: 'v1_Contacts_Roles_variance_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Contacts_Roles" */
export type v1_Contacts_Roles_variance_order_by = {
  contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.ContentLink" */
export type v1_ContentLink = {
  __typename?: 'v1_ContentLink';
  /** An object relationship */
  Post?: Maybe<v1_Posts>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  post_id?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};

/** aggregated selection of "v1.ContentLink" */
export type v1_ContentLink_aggregate = {
  __typename?: 'v1_ContentLink_aggregate';
  aggregate?: Maybe<v1_ContentLink_aggregate_fields>;
  nodes: Array<v1_ContentLink>;
};

export type v1_ContentLink_aggregate_bool_exp = {
  count?: InputMaybe<v1_ContentLink_aggregate_bool_exp_count>;
};

export type v1_ContentLink_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_ContentLink_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContentLink_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.ContentLink" */
export type v1_ContentLink_aggregate_fields = {
  __typename?: 'v1_ContentLink_aggregate_fields';
  avg?: Maybe<v1_ContentLink_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ContentLink_max_fields>;
  min?: Maybe<v1_ContentLink_min_fields>;
  stddev?: Maybe<v1_ContentLink_stddev_fields>;
  stddev_pop?: Maybe<v1_ContentLink_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ContentLink_stddev_samp_fields>;
  sum?: Maybe<v1_ContentLink_sum_fields>;
  var_pop?: Maybe<v1_ContentLink_var_pop_fields>;
  var_samp?: Maybe<v1_ContentLink_var_samp_fields>;
  variance?: Maybe<v1_ContentLink_variance_fields>;
};


/** aggregate fields of "v1.ContentLink" */
export type v1_ContentLink_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ContentLink_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.ContentLink" */
export type v1_ContentLink_aggregate_order_by = {
  avg?: InputMaybe<v1_ContentLink_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_ContentLink_max_order_by>;
  min?: InputMaybe<v1_ContentLink_min_order_by>;
  stddev?: InputMaybe<v1_ContentLink_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_ContentLink_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_ContentLink_stddev_samp_order_by>;
  sum?: InputMaybe<v1_ContentLink_sum_order_by>;
  var_pop?: InputMaybe<v1_ContentLink_var_pop_order_by>;
  var_samp?: InputMaybe<v1_ContentLink_var_samp_order_by>;
  variance?: InputMaybe<v1_ContentLink_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.ContentLink" */
export type v1_ContentLink_arr_rel_insert_input = {
  data: Array<v1_ContentLink_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ContentLink_on_conflict>;
};

/** aggregate avg on columns */
export type v1_ContentLink_avg_fields = {
  __typename?: 'v1_ContentLink_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.ContentLink" */
export type v1_ContentLink_avg_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.ContentLink". All fields are combined with a logical 'AND'. */
export type v1_ContentLink_bool_exp = {
  Post?: InputMaybe<v1_Posts_bool_exp>;
  _and?: InputMaybe<Array<v1_ContentLink_bool_exp>>;
  _not?: InputMaybe<v1_ContentLink_bool_exp>;
  _or?: InputMaybe<Array<v1_ContentLink_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  post_id?: InputMaybe<Int_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ContentLink" */
export enum v1_ContentLink_constraint {
  /** unique or primary key constraint on columns "id" */
  ContentLink_pkey = 'ContentLink_pkey'
}

/** input type for incrementing numeric columns in table "v1.ContentLink" */
export type v1_ContentLink_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ContentLink" */
export type v1_ContentLink_insert_input = {
  Post?: InputMaybe<v1_Posts_obj_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_ContentLink_max_fields = {
  __typename?: 'v1_ContentLink_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "v1.ContentLink" */
export type v1_ContentLink_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_ContentLink_min_fields = {
  __typename?: 'v1_ContentLink_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "v1.ContentLink" */
export type v1_ContentLink_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.ContentLink" */
export type v1_ContentLink_mutation_response = {
  __typename?: 'v1_ContentLink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ContentLink>;
};

/** on_conflict condition type for table "v1.ContentLink" */
export type v1_ContentLink_on_conflict = {
  constraint: v1_ContentLink_constraint;
  update_columns?: Array<v1_ContentLink_update_column>;
  where?: InputMaybe<v1_ContentLink_bool_exp>;
};

/** Ordering options when selecting data from "v1.ContentLink". */
export type v1_ContentLink_order_by = {
  Post?: InputMaybe<v1_Posts_order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ContentLink */
export type v1_ContentLink_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ContentLink" */
export enum v1_ContentLink_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  post_id = 'post_id',
  /** column name */
  url = 'url'
}

/** input type for updating data in table "v1.ContentLink" */
export type v1_ContentLink_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_ContentLink_stddev_fields = {
  __typename?: 'v1_ContentLink_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.ContentLink" */
export type v1_ContentLink_stddev_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_ContentLink_stddev_pop_fields = {
  __typename?: 'v1_ContentLink_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.ContentLink" */
export type v1_ContentLink_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_ContentLink_stddev_samp_fields = {
  __typename?: 'v1_ContentLink_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.ContentLink" */
export type v1_ContentLink_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_ContentLink" */
export type v1_ContentLink_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ContentLink_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ContentLink_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ContentLink_sum_fields = {
  __typename?: 'v1_ContentLink_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.ContentLink" */
export type v1_ContentLink_sum_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.ContentLink" */
export enum v1_ContentLink_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  post_id = 'post_id',
  /** column name */
  url = 'url'
}

export type v1_ContentLink_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ContentLink_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ContentLink_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ContentLink_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ContentLink_var_pop_fields = {
  __typename?: 'v1_ContentLink_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.ContentLink" */
export type v1_ContentLink_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_ContentLink_var_samp_fields = {
  __typename?: 'v1_ContentLink_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.ContentLink" */
export type v1_ContentLink_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_ContentLink_variance_fields = {
  __typename?: 'v1_ContentLink_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.ContentLink" */
export type v1_ContentLink_variance_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.ContentTypes" */
export type v1_ContentTypes = {
  __typename?: 'v1_ContentTypes';
  /** An array relationship */
  Campaigns: Array<v1_Campaign>;
  /** An aggregate relationship */
  Campaigns_aggregate: v1_Campaign_aggregate;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An object relationship */
  Website?: Maybe<v1_Website>;
  id: Scalars['Int']['output'];
  new_HumanName?: Maybe<Scalars['String']['output']>;
  new_MachineName?: Maybe<Scalars['String']['output']>;
  new_contenttypesId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.ContentTypes" */
export type v1_ContentTypesCampaignsArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** columns and relationships of "v1.ContentTypes" */
export type v1_ContentTypesCampaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** columns and relationships of "v1.ContentTypes" */
export type v1_ContentTypesPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.ContentTypes" */
export type v1_ContentTypesPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.ContentTypes" */
export type v1_ContentTypes_aggregate = {
  __typename?: 'v1_ContentTypes_aggregate';
  aggregate?: Maybe<v1_ContentTypes_aggregate_fields>;
  nodes: Array<v1_ContentTypes>;
};

export type v1_ContentTypes_aggregate_bool_exp = {
  count?: InputMaybe<v1_ContentTypes_aggregate_bool_exp_count>;
};

export type v1_ContentTypes_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_ContentTypes_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_ContentTypes_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.ContentTypes" */
export type v1_ContentTypes_aggregate_fields = {
  __typename?: 'v1_ContentTypes_aggregate_fields';
  avg?: Maybe<v1_ContentTypes_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ContentTypes_max_fields>;
  min?: Maybe<v1_ContentTypes_min_fields>;
  stddev?: Maybe<v1_ContentTypes_stddev_fields>;
  stddev_pop?: Maybe<v1_ContentTypes_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ContentTypes_stddev_samp_fields>;
  sum?: Maybe<v1_ContentTypes_sum_fields>;
  var_pop?: Maybe<v1_ContentTypes_var_pop_fields>;
  var_samp?: Maybe<v1_ContentTypes_var_samp_fields>;
  variance?: Maybe<v1_ContentTypes_variance_fields>;
};


/** aggregate fields of "v1.ContentTypes" */
export type v1_ContentTypes_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ContentTypes_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.ContentTypes" */
export type v1_ContentTypes_aggregate_order_by = {
  avg?: InputMaybe<v1_ContentTypes_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_ContentTypes_max_order_by>;
  min?: InputMaybe<v1_ContentTypes_min_order_by>;
  stddev?: InputMaybe<v1_ContentTypes_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_ContentTypes_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_ContentTypes_stddev_samp_order_by>;
  sum?: InputMaybe<v1_ContentTypes_sum_order_by>;
  var_pop?: InputMaybe<v1_ContentTypes_var_pop_order_by>;
  var_samp?: InputMaybe<v1_ContentTypes_var_samp_order_by>;
  variance?: InputMaybe<v1_ContentTypes_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.ContentTypes" */
export type v1_ContentTypes_arr_rel_insert_input = {
  data: Array<v1_ContentTypes_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ContentTypes_on_conflict>;
};

/** aggregate avg on columns */
export type v1_ContentTypes_avg_fields = {
  __typename?: 'v1_ContentTypes_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_avg_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.ContentTypes". All fields are combined with a logical 'AND'. */
export type v1_ContentTypes_bool_exp = {
  Campaigns?: InputMaybe<v1_Campaign_bool_exp>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  Website?: InputMaybe<v1_Website_bool_exp>;
  _and?: InputMaybe<Array<v1_ContentTypes_bool_exp>>;
  _not?: InputMaybe<v1_ContentTypes_bool_exp>;
  _or?: InputMaybe<Array<v1_ContentTypes_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_HumanName?: InputMaybe<String_comparison_exp>;
  new_MachineName?: InputMaybe<String_comparison_exp>;
  new_contenttypesId?: InputMaybe<uuid_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  post_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
  website_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ContentTypes" */
export enum v1_ContentTypes_constraint {
  /** unique or primary key constraint on columns "id" */
  ContentTypes_id_key = 'ContentTypes_id_key',
  /** unique or primary key constraint on columns "id" */
  ContentTypes_pkey = 'ContentTypes_pkey',
  /** unique or primary key constraint on columns "new_MachineName", "website_id" */
  unique_machine_name_website_id = 'unique_machine_name_website_id'
}

/** input type for incrementing numeric columns in table "v1.ContentTypes" */
export type v1_ContentTypes_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ContentTypes" */
export type v1_ContentTypes_insert_input = {
  Campaigns?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  Website?: InputMaybe<v1_Website_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_HumanName?: InputMaybe<Scalars['String']['input']>;
  new_MachineName?: InputMaybe<Scalars['String']['input']>;
  new_contenttypesId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_ContentTypes_max_fields = {
  __typename?: 'v1_ContentTypes_max_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_HumanName?: Maybe<Scalars['String']['output']>;
  new_MachineName?: Maybe<Scalars['String']['output']>;
  new_contenttypesId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_max_order_by = {
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_HumanName?: InputMaybe<order_by>;
  new_MachineName?: InputMaybe<order_by>;
  new_contenttypesId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_ContentTypes_min_fields = {
  __typename?: 'v1_ContentTypes_min_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_HumanName?: Maybe<Scalars['String']['output']>;
  new_MachineName?: Maybe<Scalars['String']['output']>;
  new_contenttypesId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_min_order_by = {
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_HumanName?: InputMaybe<order_by>;
  new_MachineName?: InputMaybe<order_by>;
  new_contenttypesId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.ContentTypes" */
export type v1_ContentTypes_mutation_response = {
  __typename?: 'v1_ContentTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ContentTypes>;
};

/** input type for inserting object relation for remote table "v1.ContentTypes" */
export type v1_ContentTypes_obj_rel_insert_input = {
  data: v1_ContentTypes_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ContentTypes_on_conflict>;
};

/** on_conflict condition type for table "v1.ContentTypes" */
export type v1_ContentTypes_on_conflict = {
  constraint: v1_ContentTypes_constraint;
  update_columns?: Array<v1_ContentTypes_update_column>;
  where?: InputMaybe<v1_ContentTypes_bool_exp>;
};

/** Ordering options when selecting data from "v1.ContentTypes". */
export type v1_ContentTypes_order_by = {
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  Website?: InputMaybe<v1_Website_order_by>;
  id?: InputMaybe<order_by>;
  new_HumanName?: InputMaybe<order_by>;
  new_MachineName?: InputMaybe<order_by>;
  new_contenttypesId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ContentTypes */
export type v1_ContentTypes_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ContentTypes" */
export enum v1_ContentTypes_select_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_HumanName = 'new_HumanName',
  /** column name */
  new_MachineName = 'new_MachineName',
  /** column name */
  new_contenttypesId = 'new_contenttypesId',
  /** column name */
  new_name = 'new_name',
  /** column name */
  post_id = 'post_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  website_id = 'website_id'
}

/** input type for updating data in table "v1.ContentTypes" */
export type v1_ContentTypes_set_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_HumanName?: InputMaybe<Scalars['String']['input']>;
  new_MachineName?: InputMaybe<Scalars['String']['input']>;
  new_contenttypesId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_ContentTypes_stddev_fields = {
  __typename?: 'v1_ContentTypes_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_stddev_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_ContentTypes_stddev_pop_fields = {
  __typename?: 'v1_ContentTypes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_ContentTypes_stddev_samp_fields = {
  __typename?: 'v1_ContentTypes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_ContentTypes" */
export type v1_ContentTypes_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ContentTypes_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ContentTypes_stream_cursor_value_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_HumanName?: InputMaybe<Scalars['String']['input']>;
  new_MachineName?: InputMaybe<Scalars['String']['input']>;
  new_contenttypesId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_ContentTypes_sum_fields = {
  __typename?: 'v1_ContentTypes_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_sum_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.ContentTypes" */
export enum v1_ContentTypes_update_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_HumanName = 'new_HumanName',
  /** column name */
  new_MachineName = 'new_MachineName',
  /** column name */
  new_contenttypesId = 'new_contenttypesId',
  /** column name */
  new_name = 'new_name',
  /** column name */
  post_id = 'post_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  website_id = 'website_id'
}

export type v1_ContentTypes_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ContentTypes_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ContentTypes_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ContentTypes_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ContentTypes_var_pop_fields = {
  __typename?: 'v1_ContentTypes_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_ContentTypes_var_samp_fields = {
  __typename?: 'v1_ContentTypes_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_ContentTypes_variance_fields = {
  __typename?: 'v1_ContentTypes_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.ContentTypes" */
export type v1_ContentTypes_variance_order_by = {
  id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Credential_Roles" */
export type v1_Credential_Roles = {
  __typename?: 'v1_Credential_Roles';
  /** An object relationship */
  Credential?: Maybe<v1_Credentials>;
  /** An object relationship */
  Roles?: Maybe<v1_Roles>;
  credential_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "v1.Credential_Roles" */
export type v1_Credential_Roles_aggregate = {
  __typename?: 'v1_Credential_Roles_aggregate';
  aggregate?: Maybe<v1_Credential_Roles_aggregate_fields>;
  nodes: Array<v1_Credential_Roles>;
};

export type v1_Credential_Roles_aggregate_bool_exp = {
  count?: InputMaybe<v1_Credential_Roles_aggregate_bool_exp_count>;
};

export type v1_Credential_Roles_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Credential_Roles_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Credential_Roles" */
export type v1_Credential_Roles_aggregate_fields = {
  __typename?: 'v1_Credential_Roles_aggregate_fields';
  avg?: Maybe<v1_Credential_Roles_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Credential_Roles_max_fields>;
  min?: Maybe<v1_Credential_Roles_min_fields>;
  stddev?: Maybe<v1_Credential_Roles_stddev_fields>;
  stddev_pop?: Maybe<v1_Credential_Roles_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Credential_Roles_stddev_samp_fields>;
  sum?: Maybe<v1_Credential_Roles_sum_fields>;
  var_pop?: Maybe<v1_Credential_Roles_var_pop_fields>;
  var_samp?: Maybe<v1_Credential_Roles_var_samp_fields>;
  variance?: Maybe<v1_Credential_Roles_variance_fields>;
};


/** aggregate fields of "v1.Credential_Roles" */
export type v1_Credential_Roles_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Credential_Roles" */
export type v1_Credential_Roles_aggregate_order_by = {
  avg?: InputMaybe<v1_Credential_Roles_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Credential_Roles_max_order_by>;
  min?: InputMaybe<v1_Credential_Roles_min_order_by>;
  stddev?: InputMaybe<v1_Credential_Roles_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Credential_Roles_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Credential_Roles_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Credential_Roles_sum_order_by>;
  var_pop?: InputMaybe<v1_Credential_Roles_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Credential_Roles_var_samp_order_by>;
  variance?: InputMaybe<v1_Credential_Roles_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Credential_Roles" */
export type v1_Credential_Roles_arr_rel_insert_input = {
  data: Array<v1_Credential_Roles_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Credential_Roles_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Credential_Roles_avg_fields = {
  __typename?: 'v1_Credential_Roles_avg_fields';
  credential_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_avg_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Credential_Roles". All fields are combined with a logical 'AND'. */
export type v1_Credential_Roles_bool_exp = {
  Credential?: InputMaybe<v1_Credentials_bool_exp>;
  Roles?: InputMaybe<v1_Roles_bool_exp>;
  _and?: InputMaybe<Array<v1_Credential_Roles_bool_exp>>;
  _not?: InputMaybe<v1_Credential_Roles_bool_exp>;
  _or?: InputMaybe<Array<v1_Credential_Roles_bool_exp>>;
  credential_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  role_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Credential_Roles" */
export enum v1_Credential_Roles_constraint {
  /** unique or primary key constraint on columns "id" */
  Credential_Roles_pkey = 'Credential_Roles_pkey'
}

/** input type for incrementing numeric columns in table "v1.Credential_Roles" */
export type v1_Credential_Roles_inc_input = {
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Credential_Roles" */
export type v1_Credential_Roles_insert_input = {
  Credential?: InputMaybe<v1_Credentials_obj_rel_insert_input>;
  Roles?: InputMaybe<v1_Roles_obj_rel_insert_input>;
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Credential_Roles_max_fields = {
  __typename?: 'v1_Credential_Roles_max_fields';
  credential_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_max_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Credential_Roles_min_fields = {
  __typename?: 'v1_Credential_Roles_min_fields';
  credential_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_min_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Credential_Roles" */
export type v1_Credential_Roles_mutation_response = {
  __typename?: 'v1_Credential_Roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Credential_Roles>;
};

/** on_conflict condition type for table "v1.Credential_Roles" */
export type v1_Credential_Roles_on_conflict = {
  constraint: v1_Credential_Roles_constraint;
  update_columns?: Array<v1_Credential_Roles_update_column>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};

/** Ordering options when selecting data from "v1.Credential_Roles". */
export type v1_Credential_Roles_order_by = {
  Credential?: InputMaybe<v1_Credentials_order_by>;
  Roles?: InputMaybe<v1_Roles_order_by>;
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Credential_Roles */
export type v1_Credential_Roles_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Credential_Roles" */
export enum v1_Credential_Roles_select_column {
  /** column name */
  credential_id = 'credential_id',
  /** column name */
  id = 'id',
  /** column name */
  role_id = 'role_id'
}

/** input type for updating data in table "v1.Credential_Roles" */
export type v1_Credential_Roles_set_input = {
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Credential_Roles_stddev_fields = {
  __typename?: 'v1_Credential_Roles_stddev_fields';
  credential_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_stddev_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Credential_Roles_stddev_pop_fields = {
  __typename?: 'v1_Credential_Roles_stddev_pop_fields';
  credential_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_stddev_pop_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Credential_Roles_stddev_samp_fields = {
  __typename?: 'v1_Credential_Roles_stddev_samp_fields';
  credential_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_stddev_samp_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Credential_Roles" */
export type v1_Credential_Roles_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Credential_Roles_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Credential_Roles_stream_cursor_value_input = {
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Credential_Roles_sum_fields = {
  __typename?: 'v1_Credential_Roles_sum_fields';
  credential_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_sum_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Credential_Roles" */
export enum v1_Credential_Roles_update_column {
  /** column name */
  credential_id = 'credential_id',
  /** column name */
  id = 'id',
  /** column name */
  role_id = 'role_id'
}

export type v1_Credential_Roles_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Credential_Roles_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Credential_Roles_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Credential_Roles_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Credential_Roles_var_pop_fields = {
  __typename?: 'v1_Credential_Roles_var_pop_fields';
  credential_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_var_pop_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Credential_Roles_var_samp_fields = {
  __typename?: 'v1_Credential_Roles_var_samp_fields';
  credential_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_var_samp_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Credential_Roles_variance_fields = {
  __typename?: 'v1_Credential_Roles_variance_fields';
  credential_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Credential_Roles" */
export type v1_Credential_Roles_variance_order_by = {
  credential_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Credentials" */
export type v1_Credentials = {
  __typename?: 'v1_Credentials';
  /** An object relationship */
  Createdby?: Maybe<v1_SystemUserBase>;
  /** An array relationship */
  Credential_Roles: Array<v1_Credential_Roles>;
  /** An aggregate relationship */
  Credential_Roles_aggregate: v1_Credential_Roles_aggregate;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  PaymentCategories?: Maybe<v1_ServiceTypes>;
  /** An array relationship */
  RecurringPayments: Array<v1_RecurringPayments>;
  /** An aggregate relationship */
  RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  created_on?: Maybe<Scalars['date']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "v1.Credentials" */
export type v1_CredentialsCredential_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credential_Roles_order_by>>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};


/** columns and relationships of "v1.Credentials" */
export type v1_CredentialsCredential_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credential_Roles_order_by>>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};


/** columns and relationships of "v1.Credentials" */
export type v1_CredentialsRecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** columns and relationships of "v1.Credentials" */
export type v1_CredentialsRecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};

/** aggregated selection of "v1.Credentials" */
export type v1_Credentials_aggregate = {
  __typename?: 'v1_Credentials_aggregate';
  aggregate?: Maybe<v1_Credentials_aggregate_fields>;
  nodes: Array<v1_Credentials>;
};

export type v1_Credentials_aggregate_bool_exp = {
  count?: InputMaybe<v1_Credentials_aggregate_bool_exp_count>;
};

export type v1_Credentials_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Credentials_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Credentials_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Credentials" */
export type v1_Credentials_aggregate_fields = {
  __typename?: 'v1_Credentials_aggregate_fields';
  avg?: Maybe<v1_Credentials_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Credentials_max_fields>;
  min?: Maybe<v1_Credentials_min_fields>;
  stddev?: Maybe<v1_Credentials_stddev_fields>;
  stddev_pop?: Maybe<v1_Credentials_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Credentials_stddev_samp_fields>;
  sum?: Maybe<v1_Credentials_sum_fields>;
  var_pop?: Maybe<v1_Credentials_var_pop_fields>;
  var_samp?: Maybe<v1_Credentials_var_samp_fields>;
  variance?: Maybe<v1_Credentials_variance_fields>;
};


/** aggregate fields of "v1.Credentials" */
export type v1_Credentials_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Credentials_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Credentials" */
export type v1_Credentials_aggregate_order_by = {
  avg?: InputMaybe<v1_Credentials_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Credentials_max_order_by>;
  min?: InputMaybe<v1_Credentials_min_order_by>;
  stddev?: InputMaybe<v1_Credentials_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Credentials_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Credentials_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Credentials_sum_order_by>;
  var_pop?: InputMaybe<v1_Credentials_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Credentials_var_samp_order_by>;
  variance?: InputMaybe<v1_Credentials_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Credentials" */
export type v1_Credentials_arr_rel_insert_input = {
  data: Array<v1_Credentials_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Credentials_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Credentials_avg_fields = {
  __typename?: 'v1_Credentials_avg_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Credentials" */
export type v1_Credentials_avg_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Credentials". All fields are combined with a logical 'AND'. */
export type v1_Credentials_bool_exp = {
  Createdby?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Credential_Roles?: InputMaybe<v1_Credential_Roles_bool_exp>;
  Credential_Roles_aggregate?: InputMaybe<v1_Credential_Roles_aggregate_bool_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  PaymentCategories?: InputMaybe<v1_ServiceTypes_bool_exp>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_bool_exp>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_Credentials_bool_exp>>;
  _not?: InputMaybe<v1_Credentials_bool_exp>;
  _or?: InputMaybe<Array<v1_Credentials_bool_exp>>;
  created_on?: InputMaybe<date_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  password?: InputMaybe<String_comparison_exp>;
  paymentCategory_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
  username?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Credentials" */
export enum v1_Credentials_constraint {
  /** unique or primary key constraint on columns "id" */
  Credentials_pkey = 'Credentials_pkey'
}

/** input type for incrementing numeric columns in table "v1.Credentials" */
export type v1_Credentials_inc_input = {
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Credentials" */
export type v1_Credentials_insert_input = {
  Createdby?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Credential_Roles?: InputMaybe<v1_Credential_Roles_arr_rel_insert_input>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  PaymentCategories?: InputMaybe<v1_ServiceTypes_obj_rel_insert_input>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_arr_rel_insert_input>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Credentials_max_fields = {
  __typename?: 'v1_Credentials_max_fields';
  created_on?: Maybe<Scalars['date']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "v1.Credentials" */
export type v1_Credentials_max_order_by = {
  created_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  password?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  username?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Credentials_min_fields = {
  __typename?: 'v1_Credentials_min_fields';
  created_on?: Maybe<Scalars['date']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "v1.Credentials" */
export type v1_Credentials_min_order_by = {
  created_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  password?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  username?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Credentials" */
export type v1_Credentials_mutation_response = {
  __typename?: 'v1_Credentials_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Credentials>;
};

/** input type for inserting object relation for remote table "v1.Credentials" */
export type v1_Credentials_obj_rel_insert_input = {
  data: v1_Credentials_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Credentials_on_conflict>;
};

/** on_conflict condition type for table "v1.Credentials" */
export type v1_Credentials_on_conflict = {
  constraint: v1_Credentials_constraint;
  update_columns?: Array<v1_Credentials_update_column>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};

/** Ordering options when selecting data from "v1.Credentials". */
export type v1_Credentials_order_by = {
  Createdby?: InputMaybe<v1_SystemUserBase_order_by>;
  Credential_Roles_aggregate?: InputMaybe<v1_Credential_Roles_aggregate_order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  PaymentCategories?: InputMaybe<v1_ServiceTypes_order_by>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_order_by>;
  created_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  password?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  username?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Credentials */
export type v1_Credentials_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Credentials" */
export enum v1_Credentials_select_column {
  /** column name */
  created_on = 'created_on',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  description = 'description',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  password = 'password',
  /** column name */
  paymentCategory_id = 'paymentCategory_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  url = 'url',
  /** column name */
  username = 'username'
}

/** input type for updating data in table "v1.Credentials" */
export type v1_Credentials_set_input = {
  created_on?: InputMaybe<Scalars['date']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Credentials_stddev_fields = {
  __typename?: 'v1_Credentials_stddev_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Credentials" */
export type v1_Credentials_stddev_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Credentials_stddev_pop_fields = {
  __typename?: 'v1_Credentials_stddev_pop_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Credentials" */
export type v1_Credentials_stddev_pop_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Credentials_stddev_samp_fields = {
  __typename?: 'v1_Credentials_stddev_samp_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Credentials" */
export type v1_Credentials_stddev_samp_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Credentials" */
export type v1_Credentials_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Credentials_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Credentials_stream_cursor_value_input = {
  created_on?: InputMaybe<Scalars['date']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Credentials_sum_fields = {
  __typename?: 'v1_Credentials_sum_fields';
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Credentials" */
export type v1_Credentials_sum_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** update columns of table "v1.Credentials" */
export enum v1_Credentials_update_column {
  /** column name */
  created_on = 'created_on',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  description = 'description',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  password = 'password',
  /** column name */
  paymentCategory_id = 'paymentCategory_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  url = 'url',
  /** column name */
  username = 'username'
}

export type v1_Credentials_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Credentials_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Credentials_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Credentials_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Credentials_var_pop_fields = {
  __typename?: 'v1_Credentials_var_pop_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Credentials" */
export type v1_Credentials_var_pop_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Credentials_var_samp_fields = {
  __typename?: 'v1_Credentials_var_samp_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Credentials" */
export type v1_Credentials_var_samp_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Credentials_variance_fields = {
  __typename?: 'v1_Credentials_variance_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Credentials" */
export type v1_Credentials_variance_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.DomainExtensions" */
export type v1_DomainExtensions = {
  __typename?: 'v1_DomainExtensions';
  Name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.DomainExtensions" */
export type v1_DomainExtensionsOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.DomainExtensions" */
export type v1_DomainExtensionsOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};

/** aggregated selection of "v1.DomainExtensions" */
export type v1_DomainExtensions_aggregate = {
  __typename?: 'v1_DomainExtensions_aggregate';
  aggregate?: Maybe<v1_DomainExtensions_aggregate_fields>;
  nodes: Array<v1_DomainExtensions>;
};

/** aggregate fields of "v1.DomainExtensions" */
export type v1_DomainExtensions_aggregate_fields = {
  __typename?: 'v1_DomainExtensions_aggregate_fields';
  avg?: Maybe<v1_DomainExtensions_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_DomainExtensions_max_fields>;
  min?: Maybe<v1_DomainExtensions_min_fields>;
  stddev?: Maybe<v1_DomainExtensions_stddev_fields>;
  stddev_pop?: Maybe<v1_DomainExtensions_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_DomainExtensions_stddev_samp_fields>;
  sum?: Maybe<v1_DomainExtensions_sum_fields>;
  var_pop?: Maybe<v1_DomainExtensions_var_pop_fields>;
  var_samp?: Maybe<v1_DomainExtensions_var_samp_fields>;
  variance?: Maybe<v1_DomainExtensions_variance_fields>;
};


/** aggregate fields of "v1.DomainExtensions" */
export type v1_DomainExtensions_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_DomainExtensions_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_DomainExtensions_avg_fields = {
  __typename?: 'v1_DomainExtensions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.DomainExtensions". All fields are combined with a logical 'AND'. */
export type v1_DomainExtensions_bool_exp = {
  Name?: InputMaybe<String_comparison_exp>;
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_DomainExtensions_bool_exp>>;
  _not?: InputMaybe<v1_DomainExtensions_bool_exp>;
  _or?: InputMaybe<Array<v1_DomainExtensions_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.DomainExtensions" */
export enum v1_DomainExtensions_constraint {
  /** unique or primary key constraint on columns "id" */
  DomainExtensions_pkey = 'DomainExtensions_pkey'
}

/** input type for incrementing numeric columns in table "v1.DomainExtensions" */
export type v1_DomainExtensions_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.DomainExtensions" */
export type v1_DomainExtensions_insert_input = {
  Name?: InputMaybe<Scalars['String']['input']>;
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_DomainExtensions_max_fields = {
  __typename?: 'v1_DomainExtensions_max_fields';
  Name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_DomainExtensions_min_fields = {
  __typename?: 'v1_DomainExtensions_min_fields';
  Name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.DomainExtensions" */
export type v1_DomainExtensions_mutation_response = {
  __typename?: 'v1_DomainExtensions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_DomainExtensions>;
};

/** input type for inserting object relation for remote table "v1.DomainExtensions" */
export type v1_DomainExtensions_obj_rel_insert_input = {
  data: v1_DomainExtensions_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_DomainExtensions_on_conflict>;
};

/** on_conflict condition type for table "v1.DomainExtensions" */
export type v1_DomainExtensions_on_conflict = {
  constraint: v1_DomainExtensions_constraint;
  update_columns?: Array<v1_DomainExtensions_update_column>;
  where?: InputMaybe<v1_DomainExtensions_bool_exp>;
};

/** Ordering options when selecting data from "v1.DomainExtensions". */
export type v1_DomainExtensions_order_by = {
  Name?: InputMaybe<order_by>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.DomainExtensions */
export type v1_DomainExtensions_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.DomainExtensions" */
export enum v1_DomainExtensions_select_column {
  /** column name */
  Name = 'Name',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.DomainExtensions" */
export type v1_DomainExtensions_set_input = {
  Name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_DomainExtensions_stddev_fields = {
  __typename?: 'v1_DomainExtensions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_DomainExtensions_stddev_pop_fields = {
  __typename?: 'v1_DomainExtensions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_DomainExtensions_stddev_samp_fields = {
  __typename?: 'v1_DomainExtensions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_DomainExtensions" */
export type v1_DomainExtensions_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_DomainExtensions_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_DomainExtensions_stream_cursor_value_input = {
  Name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_DomainExtensions_sum_fields = {
  __typename?: 'v1_DomainExtensions_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.DomainExtensions" */
export enum v1_DomainExtensions_update_column {
  /** column name */
  Name = 'Name',
  /** column name */
  id = 'id'
}

export type v1_DomainExtensions_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_DomainExtensions_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_DomainExtensions_set_input>;
  /** filter the rows which have to be updated */
  where: v1_DomainExtensions_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_DomainExtensions_var_pop_fields = {
  __typename?: 'v1_DomainExtensions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_DomainExtensions_var_samp_fields = {
  __typename?: 'v1_DomainExtensions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_DomainExtensions_variance_fields = {
  __typename?: 'v1_DomainExtensions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Table to store information about resource events */
export type v1_Events = {
  __typename?: 'v1_Events';
  /** An object relationship */
  Author?: Maybe<v1_SystemUserBase>;
  action: Scalars['String']['output'];
  author: Scalars['jsonb']['output'];
  author_id?: Maybe<Scalars['Int']['output']>;
  date: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  payload: Scalars['jsonb']['output'];
  resource: Scalars['String']['output'];
};


/** Table to store information about resource events */
export type v1_EventsauthorArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** Table to store information about resource events */
export type v1_EventspayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "v1.Events" */
export type v1_Events_aggregate = {
  __typename?: 'v1_Events_aggregate';
  aggregate?: Maybe<v1_Events_aggregate_fields>;
  nodes: Array<v1_Events>;
};

export type v1_Events_aggregate_bool_exp = {
  count?: InputMaybe<v1_Events_aggregate_bool_exp_count>;
};

export type v1_Events_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Events_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Events_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Events" */
export type v1_Events_aggregate_fields = {
  __typename?: 'v1_Events_aggregate_fields';
  avg?: Maybe<v1_Events_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Events_max_fields>;
  min?: Maybe<v1_Events_min_fields>;
  stddev?: Maybe<v1_Events_stddev_fields>;
  stddev_pop?: Maybe<v1_Events_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Events_stddev_samp_fields>;
  sum?: Maybe<v1_Events_sum_fields>;
  var_pop?: Maybe<v1_Events_var_pop_fields>;
  var_samp?: Maybe<v1_Events_var_samp_fields>;
  variance?: Maybe<v1_Events_variance_fields>;
};


/** aggregate fields of "v1.Events" */
export type v1_Events_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Events_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Events" */
export type v1_Events_aggregate_order_by = {
  avg?: InputMaybe<v1_Events_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Events_max_order_by>;
  min?: InputMaybe<v1_Events_min_order_by>;
  stddev?: InputMaybe<v1_Events_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Events_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Events_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Events_sum_order_by>;
  var_pop?: InputMaybe<v1_Events_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Events_var_samp_order_by>;
  variance?: InputMaybe<v1_Events_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type v1_Events_append_input = {
  author?: InputMaybe<Scalars['jsonb']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "v1.Events" */
export type v1_Events_arr_rel_insert_input = {
  data: Array<v1_Events_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Events_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Events_avg_fields = {
  __typename?: 'v1_Events_avg_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Events" */
export type v1_Events_avg_order_by = {
  author_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Events". All fields are combined with a logical 'AND'. */
export type v1_Events_bool_exp = {
  Author?: InputMaybe<v1_SystemUserBase_bool_exp>;
  _and?: InputMaybe<Array<v1_Events_bool_exp>>;
  _not?: InputMaybe<v1_Events_bool_exp>;
  _or?: InputMaybe<Array<v1_Events_bool_exp>>;
  action?: InputMaybe<String_comparison_exp>;
  author?: InputMaybe<jsonb_comparison_exp>;
  author_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  payload?: InputMaybe<jsonb_comparison_exp>;
  resource?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Events" */
export enum v1_Events_constraint {
  /** unique or primary key constraint on columns "id" */
  Events_pkey = 'Events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type v1_Events_delete_at_path_input = {
  author?: InputMaybe<Array<Scalars['String']['input']>>;
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type v1_Events_delete_elem_input = {
  author?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type v1_Events_delete_key_input = {
  author?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "v1.Events" */
export type v1_Events_inc_input = {
  author_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Events" */
export type v1_Events_insert_input = {
  Author?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  action?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['jsonb']['input']>;
  author_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Events_max_fields = {
  __typename?: 'v1_Events_max_fields';
  action?: Maybe<Scalars['String']['output']>;
  author_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "v1.Events" */
export type v1_Events_max_order_by = {
  action?: InputMaybe<order_by>;
  author_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  resource?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Events_min_fields = {
  __typename?: 'v1_Events_min_fields';
  action?: Maybe<Scalars['String']['output']>;
  author_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "v1.Events" */
export type v1_Events_min_order_by = {
  action?: InputMaybe<order_by>;
  author_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  resource?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Events" */
export type v1_Events_mutation_response = {
  __typename?: 'v1_Events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Events>;
};

/** on_conflict condition type for table "v1.Events" */
export type v1_Events_on_conflict = {
  constraint: v1_Events_constraint;
  update_columns?: Array<v1_Events_update_column>;
  where?: InputMaybe<v1_Events_bool_exp>;
};

/** Ordering options when selecting data from "v1.Events". */
export type v1_Events_order_by = {
  Author?: InputMaybe<v1_SystemUserBase_order_by>;
  action?: InputMaybe<order_by>;
  author?: InputMaybe<order_by>;
  author_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  payload?: InputMaybe<order_by>;
  resource?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Events */
export type v1_Events_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type v1_Events_prepend_input = {
  author?: InputMaybe<Scalars['jsonb']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "v1.Events" */
export enum v1_Events_select_column {
  /** column name */
  action = 'action',
  /** column name */
  author = 'author',
  /** column name */
  author_id = 'author_id',
  /** column name */
  date = 'date',
  /** column name */
  id = 'id',
  /** column name */
  payload = 'payload',
  /** column name */
  resource = 'resource'
}

/** input type for updating data in table "v1.Events" */
export type v1_Events_set_input = {
  action?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['jsonb']['input']>;
  author_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Events_stddev_fields = {
  __typename?: 'v1_Events_stddev_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Events" */
export type v1_Events_stddev_order_by = {
  author_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Events_stddev_pop_fields = {
  __typename?: 'v1_Events_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Events" */
export type v1_Events_stddev_pop_order_by = {
  author_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Events_stddev_samp_fields = {
  __typename?: 'v1_Events_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Events" */
export type v1_Events_stddev_samp_order_by = {
  author_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Events" */
export type v1_Events_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Events_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Events_stream_cursor_value_input = {
  action?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['jsonb']['input']>;
  author_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Events_sum_fields = {
  __typename?: 'v1_Events_sum_fields';
  author_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Events" */
export type v1_Events_sum_order_by = {
  author_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Events" */
export enum v1_Events_update_column {
  /** column name */
  action = 'action',
  /** column name */
  author = 'author',
  /** column name */
  author_id = 'author_id',
  /** column name */
  date = 'date',
  /** column name */
  id = 'id',
  /** column name */
  payload = 'payload',
  /** column name */
  resource = 'resource'
}

export type v1_Events_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<v1_Events_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<v1_Events_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<v1_Events_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<v1_Events_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Events_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<v1_Events_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Events_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Events_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Events_var_pop_fields = {
  __typename?: 'v1_Events_var_pop_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Events" */
export type v1_Events_var_pop_order_by = {
  author_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Events_var_samp_fields = {
  __typename?: 'v1_Events_var_samp_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Events" */
export type v1_Events_var_samp_order_by = {
  author_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Events_variance_fields = {
  __typename?: 'v1_Events_variance_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Events" */
export type v1_Events_variance_order_by = {
  author_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.ExpiringRecPayments" */
export type v1_ExpiringRecPayments = {
  __typename?: 'v1_ExpiringRecPayments';
  amount?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['date']['output']>;
  credential_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Int']['output']>;
  payment_address?: Maybe<Scalars['String']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "v1.ExpiringRecPayments" */
export type v1_ExpiringRecPayments_aggregate = {
  __typename?: 'v1_ExpiringRecPayments_aggregate';
  aggregate?: Maybe<v1_ExpiringRecPayments_aggregate_fields>;
  nodes: Array<v1_ExpiringRecPayments>;
};

/** aggregate fields of "v1.ExpiringRecPayments" */
export type v1_ExpiringRecPayments_aggregate_fields = {
  __typename?: 'v1_ExpiringRecPayments_aggregate_fields';
  avg?: Maybe<v1_ExpiringRecPayments_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ExpiringRecPayments_max_fields>;
  min?: Maybe<v1_ExpiringRecPayments_min_fields>;
  stddev?: Maybe<v1_ExpiringRecPayments_stddev_fields>;
  stddev_pop?: Maybe<v1_ExpiringRecPayments_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ExpiringRecPayments_stddev_samp_fields>;
  sum?: Maybe<v1_ExpiringRecPayments_sum_fields>;
  var_pop?: Maybe<v1_ExpiringRecPayments_var_pop_fields>;
  var_samp?: Maybe<v1_ExpiringRecPayments_var_samp_fields>;
  variance?: Maybe<v1_ExpiringRecPayments_variance_fields>;
};


/** aggregate fields of "v1.ExpiringRecPayments" */
export type v1_ExpiringRecPayments_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ExpiringRecPayments_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ExpiringRecPayments_avg_fields = {
  __typename?: 'v1_ExpiringRecPayments_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ExpiringRecPayments". All fields are combined with a logical 'AND'. */
export type v1_ExpiringRecPayments_bool_exp = {
  _and?: InputMaybe<Array<v1_ExpiringRecPayments_bool_exp>>;
  _not?: InputMaybe<v1_ExpiringRecPayments_bool_exp>;
  _or?: InputMaybe<Array<v1_ExpiringRecPayments_bool_exp>>;
  amount?: InputMaybe<numeric_comparison_exp>;
  company_id?: InputMaybe<Int_comparison_exp>;
  created_on?: InputMaybe<date_comparison_exp>;
  credential_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  expires_on?: InputMaybe<date_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paymentCategory_id?: InputMaybe<Int_comparison_exp>;
  paymentFrequency_id?: InputMaybe<Int_comparison_exp>;
  payment_address?: InputMaybe<String_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  status_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for incrementing numeric columns in table "v1.ExpiringRecPayments" */
export type v1_ExpiringRecPayments_inc_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  paymentFrequency_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ExpiringRecPayments" */
export type v1_ExpiringRecPayments_insert_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  paymentFrequency_id?: InputMaybe<Scalars['Int']['input']>;
  payment_address?: InputMaybe<Scalars['String']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_ExpiringRecPayments_max_fields = {
  __typename?: 'v1_ExpiringRecPayments_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['date']['output']>;
  credential_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Int']['output']>;
  payment_address?: Maybe<Scalars['String']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_ExpiringRecPayments_min_fields = {
  __typename?: 'v1_ExpiringRecPayments_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['date']['output']>;
  credential_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Int']['output']>;
  payment_address?: Maybe<Scalars['String']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.ExpiringRecPayments" */
export type v1_ExpiringRecPayments_mutation_response = {
  __typename?: 'v1_ExpiringRecPayments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ExpiringRecPayments>;
};

/** Ordering options when selecting data from "v1.ExpiringRecPayments". */
export type v1_ExpiringRecPayments_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  expires_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  payment_address?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** select columns of table "v1.ExpiringRecPayments" */
export enum v1_ExpiringRecPayments_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  company_id = 'company_id',
  /** column name */
  created_on = 'created_on',
  /** column name */
  credential_id = 'credential_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  description = 'description',
  /** column name */
  expires_on = 'expires_on',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentCategory_id = 'paymentCategory_id',
  /** column name */
  paymentFrequency_id = 'paymentFrequency_id',
  /** column name */
  payment_address = 'payment_address',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  status_id = 'status_id'
}

/** input type for updating data in table "v1.ExpiringRecPayments" */
export type v1_ExpiringRecPayments_set_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  paymentFrequency_id?: InputMaybe<Scalars['Int']['input']>;
  payment_address?: InputMaybe<Scalars['String']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_ExpiringRecPayments_stddev_fields = {
  __typename?: 'v1_ExpiringRecPayments_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ExpiringRecPayments_stddev_pop_fields = {
  __typename?: 'v1_ExpiringRecPayments_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ExpiringRecPayments_stddev_samp_fields = {
  __typename?: 'v1_ExpiringRecPayments_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ExpiringRecPayments" */
export type v1_ExpiringRecPayments_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ExpiringRecPayments_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ExpiringRecPayments_stream_cursor_value_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  paymentFrequency_id?: InputMaybe<Scalars['Int']['input']>;
  payment_address?: InputMaybe<Scalars['String']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_ExpiringRecPayments_sum_fields = {
  __typename?: 'v1_ExpiringRecPayments_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  credential_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

export type v1_ExpiringRecPayments_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ExpiringRecPayments_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ExpiringRecPayments_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ExpiringRecPayments_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ExpiringRecPayments_var_pop_fields = {
  __typename?: 'v1_ExpiringRecPayments_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ExpiringRecPayments_var_samp_fields = {
  __typename?: 'v1_ExpiringRecPayments_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ExpiringRecPayments_variance_fields = {
  __typename?: 'v1_ExpiringRecPayments_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** Adzz CRM Files */
export type v1_Files = {
  __typename?: 'v1_Files';
  created_on: Scalars['date']['output'];
  file_name: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  record_id: Scalars['Int']['output'];
  resource: Scalars['String']['output'];
  user_id: Scalars['Int']['output'];
  uuid: Scalars['uuid']['output'];
};

/** aggregated selection of "v1.Files" */
export type v1_Files_aggregate = {
  __typename?: 'v1_Files_aggregate';
  aggregate?: Maybe<v1_Files_aggregate_fields>;
  nodes: Array<v1_Files>;
};

/** aggregate fields of "v1.Files" */
export type v1_Files_aggregate_fields = {
  __typename?: 'v1_Files_aggregate_fields';
  avg?: Maybe<v1_Files_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Files_max_fields>;
  min?: Maybe<v1_Files_min_fields>;
  stddev?: Maybe<v1_Files_stddev_fields>;
  stddev_pop?: Maybe<v1_Files_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Files_stddev_samp_fields>;
  sum?: Maybe<v1_Files_sum_fields>;
  var_pop?: Maybe<v1_Files_var_pop_fields>;
  var_samp?: Maybe<v1_Files_var_samp_fields>;
  variance?: Maybe<v1_Files_variance_fields>;
};


/** aggregate fields of "v1.Files" */
export type v1_Files_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Files_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Files_avg_fields = {
  __typename?: 'v1_Files_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  record_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Files". All fields are combined with a logical 'AND'. */
export type v1_Files_bool_exp = {
  _and?: InputMaybe<Array<v1_Files_bool_exp>>;
  _not?: InputMaybe<v1_Files_bool_exp>;
  _or?: InputMaybe<Array<v1_Files_bool_exp>>;
  created_on?: InputMaybe<date_comparison_exp>;
  file_name?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  key?: InputMaybe<String_comparison_exp>;
  record_id?: InputMaybe<Int_comparison_exp>;
  resource?: InputMaybe<String_comparison_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
  uuid?: InputMaybe<uuid_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Files" */
export enum v1_Files_constraint {
  /** unique or primary key constraint on columns "key" */
  Files_key_key = 'Files_key_key',
  /** unique or primary key constraint on columns "id" */
  Files_pkey = 'Files_pkey',
  /** unique or primary key constraint on columns "uuid" */
  Files_uuid_key = 'Files_uuid_key'
}

/** input type for incrementing numeric columns in table "v1.Files" */
export type v1_Files_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  record_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Files" */
export type v1_Files_insert_input = {
  created_on?: InputMaybe<Scalars['date']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  record_id?: InputMaybe<Scalars['Int']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type v1_Files_max_fields = {
  __typename?: 'v1_Files_max_fields';
  created_on?: Maybe<Scalars['date']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  record_id?: Maybe<Scalars['Int']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type v1_Files_min_fields = {
  __typename?: 'v1_Files_min_fields';
  created_on?: Maybe<Scalars['date']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  record_id?: Maybe<Scalars['Int']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "v1.Files" */
export type v1_Files_mutation_response = {
  __typename?: 'v1_Files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Files>;
};

/** on_conflict condition type for table "v1.Files" */
export type v1_Files_on_conflict = {
  constraint: v1_Files_constraint;
  update_columns?: Array<v1_Files_update_column>;
  where?: InputMaybe<v1_Files_bool_exp>;
};

/** Ordering options when selecting data from "v1.Files". */
export type v1_Files_order_by = {
  created_on?: InputMaybe<order_by>;
  file_name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  record_id?: InputMaybe<order_by>;
  resource?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
  uuid?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Files */
export type v1_Files_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Files" */
export enum v1_Files_select_column {
  /** column name */
  created_on = 'created_on',
  /** column name */
  file_name = 'file_name',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  record_id = 'record_id',
  /** column name */
  resource = 'resource',
  /** column name */
  user_id = 'user_id',
  /** column name */
  uuid = 'uuid'
}

/** input type for updating data in table "v1.Files" */
export type v1_Files_set_input = {
  created_on?: InputMaybe<Scalars['date']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  record_id?: InputMaybe<Scalars['Int']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type v1_Files_stddev_fields = {
  __typename?: 'v1_Files_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  record_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Files_stddev_pop_fields = {
  __typename?: 'v1_Files_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  record_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Files_stddev_samp_fields = {
  __typename?: 'v1_Files_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  record_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Files" */
export type v1_Files_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Files_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Files_stream_cursor_value_input = {
  created_on?: InputMaybe<Scalars['date']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  record_id?: InputMaybe<Scalars['Int']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type v1_Files_sum_fields = {
  __typename?: 'v1_Files_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  record_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Files" */
export enum v1_Files_update_column {
  /** column name */
  created_on = 'created_on',
  /** column name */
  file_name = 'file_name',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  record_id = 'record_id',
  /** column name */
  resource = 'resource',
  /** column name */
  user_id = 'user_id',
  /** column name */
  uuid = 'uuid'
}

export type v1_Files_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Files_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Files_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Files_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Files_var_pop_fields = {
  __typename?: 'v1_Files_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  record_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Files_var_samp_fields = {
  __typename?: 'v1_Files_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  record_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Files_variance_fields = {
  __typename?: 'v1_Files_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  record_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** This is about the BMS */
export type v1_GameType = {
  __typename?: 'v1_GameType';
  /** An array relationship */
  BonusestoGames: Array<v1_BonusestoGames>;
  /** An aggregate relationship */
  BonusestoGames_aggregate: v1_BonusestoGames_aggregate;
  id: Scalars['Int']['output'];
  new_name: Scalars['bpchar']['output'];
};


/** This is about the BMS */
export type v1_GameTypeBonusestoGamesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusestoGames_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusestoGames_order_by>>;
  where?: InputMaybe<v1_BonusestoGames_bool_exp>;
};


/** This is about the BMS */
export type v1_GameTypeBonusestoGames_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusestoGames_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusestoGames_order_by>>;
  where?: InputMaybe<v1_BonusestoGames_bool_exp>;
};

/** aggregated selection of "v1.GameType" */
export type v1_GameType_aggregate = {
  __typename?: 'v1_GameType_aggregate';
  aggregate?: Maybe<v1_GameType_aggregate_fields>;
  nodes: Array<v1_GameType>;
};

/** aggregate fields of "v1.GameType" */
export type v1_GameType_aggregate_fields = {
  __typename?: 'v1_GameType_aggregate_fields';
  avg?: Maybe<v1_GameType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_GameType_max_fields>;
  min?: Maybe<v1_GameType_min_fields>;
  stddev?: Maybe<v1_GameType_stddev_fields>;
  stddev_pop?: Maybe<v1_GameType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_GameType_stddev_samp_fields>;
  sum?: Maybe<v1_GameType_sum_fields>;
  var_pop?: Maybe<v1_GameType_var_pop_fields>;
  var_samp?: Maybe<v1_GameType_var_samp_fields>;
  variance?: Maybe<v1_GameType_variance_fields>;
};


/** aggregate fields of "v1.GameType" */
export type v1_GameType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_GameType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_GameType_avg_fields = {
  __typename?: 'v1_GameType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.GameType". All fields are combined with a logical 'AND'. */
export type v1_GameType_bool_exp = {
  BonusestoGames?: InputMaybe<v1_BonusestoGames_bool_exp>;
  BonusestoGames_aggregate?: InputMaybe<v1_BonusestoGames_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_GameType_bool_exp>>;
  _not?: InputMaybe<v1_GameType_bool_exp>;
  _or?: InputMaybe<Array<v1_GameType_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<bpchar_comparison_exp>;
};

/** unique or primary key constraints on table "v1.GameType" */
export enum v1_GameType_constraint {
  /** unique or primary key constraint on columns "new_name" */
  GameType_new_name_key = 'GameType_new_name_key',
  /** unique or primary key constraint on columns "id" */
  GameType_pkey = 'GameType_pkey'
}

/** input type for incrementing numeric columns in table "v1.GameType" */
export type v1_GameType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.GameType" */
export type v1_GameType_insert_input = {
  BonusestoGames?: InputMaybe<v1_BonusestoGames_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type v1_GameType_max_fields = {
  __typename?: 'v1_GameType_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_GameType_min_fields = {
  __typename?: 'v1_GameType_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.GameType" */
export type v1_GameType_mutation_response = {
  __typename?: 'v1_GameType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_GameType>;
};

/** input type for inserting object relation for remote table "v1.GameType" */
export type v1_GameType_obj_rel_insert_input = {
  data: v1_GameType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_GameType_on_conflict>;
};

/** on_conflict condition type for table "v1.GameType" */
export type v1_GameType_on_conflict = {
  constraint: v1_GameType_constraint;
  update_columns?: Array<v1_GameType_update_column>;
  where?: InputMaybe<v1_GameType_bool_exp>;
};

/** Ordering options when selecting data from "v1.GameType". */
export type v1_GameType_order_by = {
  BonusestoGames_aggregate?: InputMaybe<v1_BonusestoGames_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.GameType */
export type v1_GameType_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.GameType" */
export enum v1_GameType_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

/** input type for updating data in table "v1.GameType" */
export type v1_GameType_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type v1_GameType_stddev_fields = {
  __typename?: 'v1_GameType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_GameType_stddev_pop_fields = {
  __typename?: 'v1_GameType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_GameType_stddev_samp_fields = {
  __typename?: 'v1_GameType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_GameType" */
export type v1_GameType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_GameType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_GameType_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type v1_GameType_sum_fields = {
  __typename?: 'v1_GameType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.GameType" */
export enum v1_GameType_update_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

export type v1_GameType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_GameType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_GameType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_GameType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_GameType_var_pop_fields = {
  __typename?: 'v1_GameType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_GameType_var_samp_fields = {
  __typename?: 'v1_GameType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_GameType_variance_fields = {
  __typename?: 'v1_GameType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.InvoiceBase" */
export type v1_InvoiceBase = {
  __typename?: 'v1_InvoiceBase';
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  CancelledByUser?: Maybe<v1_SystemUserBase>;
  CancelledOn?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  Company?: Maybe<v1_AccountBase>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  CustomerId?: Maybe<Scalars['uuid']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  InvoiceDetail: Array<v1_InvoiceDetailBase>;
  /** An aggregate relationship */
  InvoiceDetail_aggregate: v1_InvoiceDetailBase_aggregate;
  /** An object relationship */
  InvoiceFrom?: Maybe<v1_PaymentCompanies>;
  InvoiceId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  InvoiceMonth?: Maybe<v1_Months>;
  InvoiceNumber?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  InvoiceStatus: v1_InvoiceStatus;
  LineDescription?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  Order?: Maybe<v1_SalesOrderBase>;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  PaidPeriod?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  PaymentMethod?: Maybe<v1_new_paymentmethodBase>;
  /** A computed field, executes function "v1.produced_on_formatted" */
  ProducedOnF?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  Receipt?: Maybe<v1_new_receiptBase>;
  StateCode: Scalars['Int']['output'];
  /** 1 is draft */
  StatusCode: Scalars['Int']['output'];
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  TraversedPath?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['numeric']['output']>;
  new_DueDate?: Maybe<Scalars['timestamp']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Int']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['numeric']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['numeric']['output']>;
  new_ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['numeric']['output']>;
  new_FromCompany?: Maybe<Scalars['Int']['output']>;
  new_InvoiceID?: Maybe<Scalars['String']['output']>;
  new_InvoiceRecalculated?: Maybe<Scalars['timestamp']['output']>;
  new_Invoicesent?: Maybe<Scalars['Boolean']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Int']['output']>;
  new_MarkedasPaidby?: Maybe<Scalars['uuid']['output']>;
  new_OrderType?: Maybe<Scalars['Int']['output']>;
  new_PaidOn?: Maybe<Scalars['timestamp']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Int']['output']>;
  new_PaidTo?: Maybe<Scalars['Int']['output']>;
  new_PayTo?: Maybe<Scalars['Int']['output']>;
  new_PaymentMethod?: Maybe<Scalars['uuid']['output']>;
  new_PaymentType?: Maybe<Scalars['Int']['output']>;
  new_Receipt?: Maybe<Scalars['uuid']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['numeric']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['numeric']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['numeric']['output']>;
  new_TotalvatIncludedinShekels?: Maybe<Scalars['String']['output']>;
  new_TransactionNumber?: Maybe<Scalars['String']['output']>;
  new_UseVAT?: Maybe<Scalars['Boolean']['output']>;
  new_VATAmount?: Maybe<Scalars['numeric']['output']>;
  new_VATpercentage?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_booltocancelandelete?: Maybe<Scalars['Boolean']['output']>;
  new_checkvalue?: Maybe<Scalars['String']['output']>;
  new_connectedorder?: Maybe<Scalars['uuid']['output']>;
  new_copyPrintUrl?: Maybe<Scalars['String']['output']>;
  new_documentlocation?: Maybe<Scalars['uuid']['output']>;
  new_fieldtodelete?: Maybe<Scalars['timestamp']['output']>;
  new_guid?: Maybe<Scalars['String']['output']>;
  new_invoicetype?: Maybe<Scalars['Boolean']['output']>;
  new_notes?: Maybe<Scalars['String']['output']>;
  new_paymentmethoddm?: Maybe<Scalars['uuid']['output']>;
  new_producedon?: Maybe<Scalars['timestamp']['output']>;
  new_senttoaccounting?: Maybe<Scalars['timestamp']['output']>;
  new_sourcePrintUrl?: Maybe<Scalars['String']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['numeric']['output']>;
  new_trigger?: Maybe<Scalars['Boolean']['output']>;
  new_vatamount_Base?: Maybe<Scalars['numeric']['output']>;
  order_id?: Maybe<Scalars['Int']['output']>;
  origin_invoice_id?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  receipt_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.InvoiceBase" */
export type v1_InvoiceBaseInvoiceDetailArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


/** columns and relationships of "v1.InvoiceBase" */
export type v1_InvoiceBaseInvoiceDetail_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};

/** aggregated selection of "v1.InvoiceBase" */
export type v1_InvoiceBase_aggregate = {
  __typename?: 'v1_InvoiceBase_aggregate';
  aggregate?: Maybe<v1_InvoiceBase_aggregate_fields>;
  nodes: Array<v1_InvoiceBase>;
};

export type v1_InvoiceBase_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp_count>;
};

export type v1_InvoiceBase_aggregate_bool_exp_bool_and = {
  arguments: v1_InvoiceBase_select_column_v1_InvoiceBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_InvoiceBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_InvoiceBase_aggregate_bool_exp_bool_or = {
  arguments: v1_InvoiceBase_select_column_v1_InvoiceBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_InvoiceBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_InvoiceBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_InvoiceBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.InvoiceBase" */
export type v1_InvoiceBase_aggregate_fields = {
  __typename?: 'v1_InvoiceBase_aggregate_fields';
  avg?: Maybe<v1_InvoiceBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_InvoiceBase_max_fields>;
  min?: Maybe<v1_InvoiceBase_min_fields>;
  stddev?: Maybe<v1_InvoiceBase_stddev_fields>;
  stddev_pop?: Maybe<v1_InvoiceBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_InvoiceBase_stddev_samp_fields>;
  sum?: Maybe<v1_InvoiceBase_sum_fields>;
  var_pop?: Maybe<v1_InvoiceBase_var_pop_fields>;
  var_samp?: Maybe<v1_InvoiceBase_var_samp_fields>;
  variance?: Maybe<v1_InvoiceBase_variance_fields>;
};


/** aggregate fields of "v1.InvoiceBase" */
export type v1_InvoiceBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.InvoiceBase" */
export type v1_InvoiceBase_aggregate_order_by = {
  avg?: InputMaybe<v1_InvoiceBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_InvoiceBase_max_order_by>;
  min?: InputMaybe<v1_InvoiceBase_min_order_by>;
  stddev?: InputMaybe<v1_InvoiceBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_InvoiceBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_InvoiceBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_InvoiceBase_sum_order_by>;
  var_pop?: InputMaybe<v1_InvoiceBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_InvoiceBase_var_samp_order_by>;
  variance?: InputMaybe<v1_InvoiceBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.InvoiceBase" */
export type v1_InvoiceBase_arr_rel_insert_input = {
  data: Array<v1_InvoiceBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_InvoiceBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_InvoiceBase_avg_fields = {
  __typename?: 'v1_InvoiceBase_avg_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['Float']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRate?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Float']['output']>;
  new_PaidTo?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['Float']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['Float']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['Float']['output']>;
  new_VATAmount?: Maybe<Scalars['Float']['output']>;
  new_VATpercentage?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['Float']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['Float']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['Float']['output']>;
  new_vatamount_Base?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  origin_invoice_id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  receipt_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_avg_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.InvoiceBase". All fields are combined with a logical 'AND'. */
export type v1_InvoiceBase_bool_exp = {
  CancelledBy?: InputMaybe<Int_comparison_exp>;
  CancelledByUser?: InputMaybe<v1_SystemUserBase_bool_exp>;
  CancelledOn?: InputMaybe<timestamptz_comparison_exp>;
  Company?: InputMaybe<v1_AccountBase_bool_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  CustomerId?: InputMaybe<uuid_comparison_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  InvoiceDetail?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
  InvoiceDetail_aggregate?: InputMaybe<v1_InvoiceDetailBase_aggregate_bool_exp>;
  InvoiceFrom?: InputMaybe<v1_PaymentCompanies_bool_exp>;
  InvoiceId?: InputMaybe<uuid_comparison_exp>;
  InvoiceMonth?: InputMaybe<v1_Months_bool_exp>;
  InvoiceNumber?: InputMaybe<String_comparison_exp>;
  InvoiceStatus?: InputMaybe<v1_InvoiceStatus_bool_exp>;
  LineDescription?: InputMaybe<String_comparison_exp>;
  Name?: InputMaybe<String_comparison_exp>;
  Order?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  PaidPeriod?: InputMaybe<date_comparison_exp>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  ProducedOnF?: InputMaybe<String_comparison_exp>;
  Receipt?: InputMaybe<v1_new_receiptBase_bool_exp>;
  StateCode?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<Int_comparison_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  TraversedPath?: InputMaybe<String_comparison_exp>;
  _and?: InputMaybe<Array<v1_InvoiceBase_bool_exp>>;
  _not?: InputMaybe<v1_InvoiceBase_bool_exp>;
  _or?: InputMaybe<Array<v1_InvoiceBase_bool_exp>>;
  company_id?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_CreatedByUser?: InputMaybe<String_comparison_exp>;
  new_DollarEquivalent?: InputMaybe<numeric_comparison_exp>;
  new_DueDate?: InputMaybe<timestamp_comparison_exp>;
  new_DueDateNotice?: InputMaybe<Int_comparison_exp>;
  new_EuroEquivalent?: InputMaybe<numeric_comparison_exp>;
  new_EuroEquivalentAuto?: InputMaybe<numeric_comparison_exp>;
  new_ExchangeRate?: InputMaybe<numeric_comparison_exp>;
  new_ExchangeRateEuro?: InputMaybe<numeric_comparison_exp>;
  new_FromCompany?: InputMaybe<Int_comparison_exp>;
  new_InvoiceID?: InputMaybe<String_comparison_exp>;
  new_InvoiceRecalculated?: InputMaybe<timestamp_comparison_exp>;
  new_Invoicesent?: InputMaybe<Boolean_comparison_exp>;
  new_Issueinvoice?: InputMaybe<Int_comparison_exp>;
  new_MarkedasPaidby?: InputMaybe<uuid_comparison_exp>;
  new_OrderType?: InputMaybe<Int_comparison_exp>;
  new_PaidOn?: InputMaybe<timestamp_comparison_exp>;
  new_PaidPeriodMonth?: InputMaybe<Int_comparison_exp>;
  new_PaidTo?: InputMaybe<Int_comparison_exp>;
  new_PayTo?: InputMaybe<Int_comparison_exp>;
  new_PaymentMethod?: InputMaybe<uuid_comparison_exp>;
  new_PaymentType?: InputMaybe<Int_comparison_exp>;
  new_Receipt?: InputMaybe<uuid_comparison_exp>;
  new_TotalAmountBeforeVAT?: InputMaybe<numeric_comparison_exp>;
  new_TotalOrderAmount?: InputMaybe<numeric_comparison_exp>;
  new_TotalVATIncluded?: InputMaybe<numeric_comparison_exp>;
  new_TotalvatIncludedinShekels?: InputMaybe<String_comparison_exp>;
  new_TransactionNumber?: InputMaybe<String_comparison_exp>;
  new_UseVAT?: InputMaybe<Boolean_comparison_exp>;
  new_VATAmount?: InputMaybe<numeric_comparison_exp>;
  new_VATpercentage?: InputMaybe<Int_comparison_exp>;
  new_Year?: InputMaybe<Int_comparison_exp>;
  new_booltocancelandelete?: InputMaybe<Boolean_comparison_exp>;
  new_checkvalue?: InputMaybe<String_comparison_exp>;
  new_connectedorder?: InputMaybe<uuid_comparison_exp>;
  new_copyPrintUrl?: InputMaybe<String_comparison_exp>;
  new_documentlocation?: InputMaybe<uuid_comparison_exp>;
  new_fieldtodelete?: InputMaybe<timestamp_comparison_exp>;
  new_guid?: InputMaybe<String_comparison_exp>;
  new_invoicetype?: InputMaybe<Boolean_comparison_exp>;
  new_notes?: InputMaybe<String_comparison_exp>;
  new_paymentmethoddm?: InputMaybe<uuid_comparison_exp>;
  new_producedon?: InputMaybe<timestamp_comparison_exp>;
  new_senttoaccounting?: InputMaybe<timestamp_comparison_exp>;
  new_sourcePrintUrl?: InputMaybe<String_comparison_exp>;
  new_totalamountbeforevat_Base?: InputMaybe<numeric_comparison_exp>;
  new_totalorderamount_Base?: InputMaybe<numeric_comparison_exp>;
  new_totalvatincluded_Base?: InputMaybe<numeric_comparison_exp>;
  new_trigger?: InputMaybe<Boolean_comparison_exp>;
  new_vatamount_Base?: InputMaybe<numeric_comparison_exp>;
  order_id?: InputMaybe<Int_comparison_exp>;
  origin_invoice_id?: InputMaybe<Int_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  receipt_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.InvoiceBase" */
export enum v1_InvoiceBase_constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceBase_id_key = 'InvoiceBase_id_key',
  /** unique or primary key constraint on columns "id" */
  cndx_PrimaryKey_Invoice = 'cndx_PrimaryKey_Invoice'
}

/** input type for incrementing numeric columns in table "v1.InvoiceBase" */
export type v1_InvoiceBase_inc_input = {
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  /** 1 is draft */
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_DollarEquivalent?: InputMaybe<Scalars['numeric']['input']>;
  new_DueDateNotice?: InputMaybe<Scalars['Int']['input']>;
  new_EuroEquivalent?: InputMaybe<Scalars['numeric']['input']>;
  new_EuroEquivalentAuto?: InputMaybe<Scalars['numeric']['input']>;
  new_ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  new_ExchangeRateEuro?: InputMaybe<Scalars['numeric']['input']>;
  new_FromCompany?: InputMaybe<Scalars['Int']['input']>;
  new_Issueinvoice?: InputMaybe<Scalars['Int']['input']>;
  new_OrderType?: InputMaybe<Scalars['Int']['input']>;
  new_PaidPeriodMonth?: InputMaybe<Scalars['Int']['input']>;
  new_PaidTo?: InputMaybe<Scalars['Int']['input']>;
  new_PayTo?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentType?: InputMaybe<Scalars['Int']['input']>;
  new_TotalAmountBeforeVAT?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalOrderAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalVATIncluded?: InputMaybe<Scalars['numeric']['input']>;
  new_VATAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_VATpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_totalamountbeforevat_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalorderamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalvatincluded_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_vatamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  order_id?: InputMaybe<Scalars['Int']['input']>;
  origin_invoice_id?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  receipt_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.InvoiceBase" */
export type v1_InvoiceBase_insert_input = {
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  CancelledByUser?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  CancelledOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Company?: InputMaybe<v1_AccountBase_obj_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  CustomerId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  InvoiceDetail?: InputMaybe<v1_InvoiceDetailBase_arr_rel_insert_input>;
  InvoiceFrom?: InputMaybe<v1_PaymentCompanies_obj_rel_insert_input>;
  InvoiceId?: InputMaybe<Scalars['uuid']['input']>;
  InvoiceMonth?: InputMaybe<v1_Months_obj_rel_insert_input>;
  InvoiceNumber?: InputMaybe<Scalars['String']['input']>;
  InvoiceStatus?: InputMaybe<v1_InvoiceStatus_obj_rel_insert_input>;
  LineDescription?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Order?: InputMaybe<v1_SalesOrderBase_obj_rel_insert_input>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  PaidPeriod?: InputMaybe<Scalars['date']['input']>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_obj_rel_insert_input>;
  Receipt?: InputMaybe<v1_new_receiptBase_obj_rel_insert_input>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  /** 1 is draft */
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  TraversedPath?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_DollarEquivalent?: InputMaybe<Scalars['numeric']['input']>;
  new_DueDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_DueDateNotice?: InputMaybe<Scalars['Int']['input']>;
  new_EuroEquivalent?: InputMaybe<Scalars['numeric']['input']>;
  new_EuroEquivalentAuto?: InputMaybe<Scalars['numeric']['input']>;
  new_ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  new_ExchangeRateEuro?: InputMaybe<Scalars['numeric']['input']>;
  new_FromCompany?: InputMaybe<Scalars['Int']['input']>;
  new_InvoiceID?: InputMaybe<Scalars['String']['input']>;
  new_InvoiceRecalculated?: InputMaybe<Scalars['timestamp']['input']>;
  new_Invoicesent?: InputMaybe<Scalars['Boolean']['input']>;
  new_Issueinvoice?: InputMaybe<Scalars['Int']['input']>;
  new_MarkedasPaidby?: InputMaybe<Scalars['uuid']['input']>;
  new_OrderType?: InputMaybe<Scalars['Int']['input']>;
  new_PaidOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_PaidPeriodMonth?: InputMaybe<Scalars['Int']['input']>;
  new_PaidTo?: InputMaybe<Scalars['Int']['input']>;
  new_PayTo?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['uuid']['input']>;
  new_PaymentType?: InputMaybe<Scalars['Int']['input']>;
  new_Receipt?: InputMaybe<Scalars['uuid']['input']>;
  new_TotalAmountBeforeVAT?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalOrderAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalVATIncluded?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalvatIncludedinShekels?: InputMaybe<Scalars['String']['input']>;
  new_TransactionNumber?: InputMaybe<Scalars['String']['input']>;
  new_UseVAT?: InputMaybe<Scalars['Boolean']['input']>;
  new_VATAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_VATpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_booltocancelandelete?: InputMaybe<Scalars['Boolean']['input']>;
  new_checkvalue?: InputMaybe<Scalars['String']['input']>;
  new_connectedorder?: InputMaybe<Scalars['uuid']['input']>;
  new_copyPrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_documentlocation?: InputMaybe<Scalars['uuid']['input']>;
  new_fieldtodelete?: InputMaybe<Scalars['timestamp']['input']>;
  new_guid?: InputMaybe<Scalars['String']['input']>;
  new_invoicetype?: InputMaybe<Scalars['Boolean']['input']>;
  new_notes?: InputMaybe<Scalars['String']['input']>;
  new_paymentmethoddm?: InputMaybe<Scalars['uuid']['input']>;
  new_producedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_senttoaccounting?: InputMaybe<Scalars['timestamp']['input']>;
  new_sourcePrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_totalamountbeforevat_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalorderamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalvatincluded_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_trigger?: InputMaybe<Scalars['Boolean']['input']>;
  new_vatamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  order_id?: InputMaybe<Scalars['Int']['input']>;
  origin_invoice_id?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  receipt_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_InvoiceBase_max_fields = {
  __typename?: 'v1_InvoiceBase_max_fields';
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  CancelledOn?: Maybe<Scalars['timestamptz']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CustomerId?: Maybe<Scalars['uuid']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  InvoiceId?: Maybe<Scalars['uuid']['output']>;
  InvoiceNumber?: Maybe<Scalars['String']['output']>;
  LineDescription?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  PaidPeriod?: Maybe<Scalars['date']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  TraversedPath?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['numeric']['output']>;
  new_DueDate?: Maybe<Scalars['timestamp']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Int']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['numeric']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['numeric']['output']>;
  new_ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['numeric']['output']>;
  new_FromCompany?: Maybe<Scalars['Int']['output']>;
  new_InvoiceID?: Maybe<Scalars['String']['output']>;
  new_InvoiceRecalculated?: Maybe<Scalars['timestamp']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Int']['output']>;
  new_MarkedasPaidby?: Maybe<Scalars['uuid']['output']>;
  new_OrderType?: Maybe<Scalars['Int']['output']>;
  new_PaidOn?: Maybe<Scalars['timestamp']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Int']['output']>;
  new_PaidTo?: Maybe<Scalars['Int']['output']>;
  new_PayTo?: Maybe<Scalars['Int']['output']>;
  new_PaymentMethod?: Maybe<Scalars['uuid']['output']>;
  new_PaymentType?: Maybe<Scalars['Int']['output']>;
  new_Receipt?: Maybe<Scalars['uuid']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['numeric']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['numeric']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['numeric']['output']>;
  new_TotalvatIncludedinShekels?: Maybe<Scalars['String']['output']>;
  new_TransactionNumber?: Maybe<Scalars['String']['output']>;
  new_VATAmount?: Maybe<Scalars['numeric']['output']>;
  new_VATpercentage?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_checkvalue?: Maybe<Scalars['String']['output']>;
  new_connectedorder?: Maybe<Scalars['uuid']['output']>;
  new_copyPrintUrl?: Maybe<Scalars['String']['output']>;
  new_documentlocation?: Maybe<Scalars['uuid']['output']>;
  new_fieldtodelete?: Maybe<Scalars['timestamp']['output']>;
  new_guid?: Maybe<Scalars['String']['output']>;
  new_notes?: Maybe<Scalars['String']['output']>;
  new_paymentmethoddm?: Maybe<Scalars['uuid']['output']>;
  new_producedon?: Maybe<Scalars['timestamp']['output']>;
  new_senttoaccounting?: Maybe<Scalars['timestamp']['output']>;
  new_sourcePrintUrl?: Maybe<Scalars['String']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['numeric']['output']>;
  new_vatamount_Base?: Maybe<Scalars['numeric']['output']>;
  order_id?: Maybe<Scalars['Int']['output']>;
  origin_invoice_id?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  receipt_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_max_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  CancelledOn?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CustomerId?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  InvoiceId?: InputMaybe<order_by>;
  InvoiceNumber?: InputMaybe<order_by>;
  LineDescription?: InputMaybe<order_by>;
  Name?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  PaidPeriod?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  TraversedPath?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDate?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_InvoiceID?: InputMaybe<order_by>;
  new_InvoiceRecalculated?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_MarkedasPaidby?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidOn?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Receipt?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_TotalvatIncludedinShekels?: InputMaybe<order_by>;
  new_TransactionNumber?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_checkvalue?: InputMaybe<order_by>;
  new_connectedorder?: InputMaybe<order_by>;
  new_copyPrintUrl?: InputMaybe<order_by>;
  new_documentlocation?: InputMaybe<order_by>;
  new_fieldtodelete?: InputMaybe<order_by>;
  new_guid?: InputMaybe<order_by>;
  new_notes?: InputMaybe<order_by>;
  new_paymentmethoddm?: InputMaybe<order_by>;
  new_producedon?: InputMaybe<order_by>;
  new_senttoaccounting?: InputMaybe<order_by>;
  new_sourcePrintUrl?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_InvoiceBase_min_fields = {
  __typename?: 'v1_InvoiceBase_min_fields';
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  CancelledOn?: Maybe<Scalars['timestamptz']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CustomerId?: Maybe<Scalars['uuid']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  InvoiceId?: Maybe<Scalars['uuid']['output']>;
  InvoiceNumber?: Maybe<Scalars['String']['output']>;
  LineDescription?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  PaidPeriod?: Maybe<Scalars['date']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  TraversedPath?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['numeric']['output']>;
  new_DueDate?: Maybe<Scalars['timestamp']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Int']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['numeric']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['numeric']['output']>;
  new_ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['numeric']['output']>;
  new_FromCompany?: Maybe<Scalars['Int']['output']>;
  new_InvoiceID?: Maybe<Scalars['String']['output']>;
  new_InvoiceRecalculated?: Maybe<Scalars['timestamp']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Int']['output']>;
  new_MarkedasPaidby?: Maybe<Scalars['uuid']['output']>;
  new_OrderType?: Maybe<Scalars['Int']['output']>;
  new_PaidOn?: Maybe<Scalars['timestamp']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Int']['output']>;
  new_PaidTo?: Maybe<Scalars['Int']['output']>;
  new_PayTo?: Maybe<Scalars['Int']['output']>;
  new_PaymentMethod?: Maybe<Scalars['uuid']['output']>;
  new_PaymentType?: Maybe<Scalars['Int']['output']>;
  new_Receipt?: Maybe<Scalars['uuid']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['numeric']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['numeric']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['numeric']['output']>;
  new_TotalvatIncludedinShekels?: Maybe<Scalars['String']['output']>;
  new_TransactionNumber?: Maybe<Scalars['String']['output']>;
  new_VATAmount?: Maybe<Scalars['numeric']['output']>;
  new_VATpercentage?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_checkvalue?: Maybe<Scalars['String']['output']>;
  new_connectedorder?: Maybe<Scalars['uuid']['output']>;
  new_copyPrintUrl?: Maybe<Scalars['String']['output']>;
  new_documentlocation?: Maybe<Scalars['uuid']['output']>;
  new_fieldtodelete?: Maybe<Scalars['timestamp']['output']>;
  new_guid?: Maybe<Scalars['String']['output']>;
  new_notes?: Maybe<Scalars['String']['output']>;
  new_paymentmethoddm?: Maybe<Scalars['uuid']['output']>;
  new_producedon?: Maybe<Scalars['timestamp']['output']>;
  new_senttoaccounting?: Maybe<Scalars['timestamp']['output']>;
  new_sourcePrintUrl?: Maybe<Scalars['String']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['numeric']['output']>;
  new_vatamount_Base?: Maybe<Scalars['numeric']['output']>;
  order_id?: Maybe<Scalars['Int']['output']>;
  origin_invoice_id?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  receipt_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_min_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  CancelledOn?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CustomerId?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  InvoiceId?: InputMaybe<order_by>;
  InvoiceNumber?: InputMaybe<order_by>;
  LineDescription?: InputMaybe<order_by>;
  Name?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  PaidPeriod?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  TraversedPath?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDate?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_InvoiceID?: InputMaybe<order_by>;
  new_InvoiceRecalculated?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_MarkedasPaidby?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidOn?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Receipt?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_TotalvatIncludedinShekels?: InputMaybe<order_by>;
  new_TransactionNumber?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_checkvalue?: InputMaybe<order_by>;
  new_connectedorder?: InputMaybe<order_by>;
  new_copyPrintUrl?: InputMaybe<order_by>;
  new_documentlocation?: InputMaybe<order_by>;
  new_fieldtodelete?: InputMaybe<order_by>;
  new_guid?: InputMaybe<order_by>;
  new_notes?: InputMaybe<order_by>;
  new_paymentmethoddm?: InputMaybe<order_by>;
  new_producedon?: InputMaybe<order_by>;
  new_senttoaccounting?: InputMaybe<order_by>;
  new_sourcePrintUrl?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.InvoiceBase" */
export type v1_InvoiceBase_mutation_response = {
  __typename?: 'v1_InvoiceBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_InvoiceBase>;
};

/** input type for inserting object relation for remote table "v1.InvoiceBase" */
export type v1_InvoiceBase_obj_rel_insert_input = {
  data: v1_InvoiceBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_InvoiceBase_on_conflict>;
};

/** on_conflict condition type for table "v1.InvoiceBase" */
export type v1_InvoiceBase_on_conflict = {
  constraint: v1_InvoiceBase_constraint;
  update_columns?: Array<v1_InvoiceBase_update_column>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.InvoiceBase". */
export type v1_InvoiceBase_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  CancelledByUser?: InputMaybe<v1_SystemUserBase_order_by>;
  CancelledOn?: InputMaybe<order_by>;
  Company?: InputMaybe<v1_AccountBase_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  CustomerId?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  InvoiceDetail_aggregate?: InputMaybe<v1_InvoiceDetailBase_aggregate_order_by>;
  InvoiceFrom?: InputMaybe<v1_PaymentCompanies_order_by>;
  InvoiceId?: InputMaybe<order_by>;
  InvoiceMonth?: InputMaybe<v1_Months_order_by>;
  InvoiceNumber?: InputMaybe<order_by>;
  InvoiceStatus?: InputMaybe<v1_InvoiceStatus_order_by>;
  LineDescription?: InputMaybe<order_by>;
  Name?: InputMaybe<order_by>;
  Order?: InputMaybe<v1_SalesOrderBase_order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  OwnerId?: InputMaybe<order_by>;
  PaidPeriod?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_order_by>;
  ProducedOnF?: InputMaybe<order_by>;
  Receipt?: InputMaybe<v1_new_receiptBase_order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  TraversedPath?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDate?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_InvoiceID?: InputMaybe<order_by>;
  new_InvoiceRecalculated?: InputMaybe<order_by>;
  new_Invoicesent?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_MarkedasPaidby?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidOn?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Receipt?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_TotalvatIncludedinShekels?: InputMaybe<order_by>;
  new_TransactionNumber?: InputMaybe<order_by>;
  new_UseVAT?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_booltocancelandelete?: InputMaybe<order_by>;
  new_checkvalue?: InputMaybe<order_by>;
  new_connectedorder?: InputMaybe<order_by>;
  new_copyPrintUrl?: InputMaybe<order_by>;
  new_documentlocation?: InputMaybe<order_by>;
  new_fieldtodelete?: InputMaybe<order_by>;
  new_guid?: InputMaybe<order_by>;
  new_invoicetype?: InputMaybe<order_by>;
  new_notes?: InputMaybe<order_by>;
  new_paymentmethoddm?: InputMaybe<order_by>;
  new_producedon?: InputMaybe<order_by>;
  new_senttoaccounting?: InputMaybe<order_by>;
  new_sourcePrintUrl?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_trigger?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.InvoiceBase */
export type v1_InvoiceBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.InvoiceBase" */
export enum v1_InvoiceBase_select_column {
  /** column name */
  CancelledBy = 'CancelledBy',
  /** column name */
  CancelledOn = 'CancelledOn',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CustomerId = 'CustomerId',
  /** column name */
  Description = 'Description',
  /** column name */
  InvoiceId = 'InvoiceId',
  /** column name */
  InvoiceNumber = 'InvoiceNumber',
  /** column name */
  LineDescription = 'LineDescription',
  /** column name */
  Name = 'Name',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  PaidPeriod = 'PaidPeriod',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  TraversedPath = 'TraversedPath',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_DollarEquivalent = 'new_DollarEquivalent',
  /** column name */
  new_DueDate = 'new_DueDate',
  /** column name */
  new_DueDateNotice = 'new_DueDateNotice',
  /** column name */
  new_EuroEquivalent = 'new_EuroEquivalent',
  /** column name */
  new_EuroEquivalentAuto = 'new_EuroEquivalentAuto',
  /** column name */
  new_ExchangeRate = 'new_ExchangeRate',
  /** column name */
  new_ExchangeRateEuro = 'new_ExchangeRateEuro',
  /** column name */
  new_FromCompany = 'new_FromCompany',
  /** column name */
  new_InvoiceID = 'new_InvoiceID',
  /** column name */
  new_InvoiceRecalculated = 'new_InvoiceRecalculated',
  /** column name */
  new_Invoicesent = 'new_Invoicesent',
  /** column name */
  new_Issueinvoice = 'new_Issueinvoice',
  /** column name */
  new_MarkedasPaidby = 'new_MarkedasPaidby',
  /** column name */
  new_OrderType = 'new_OrderType',
  /** column name */
  new_PaidOn = 'new_PaidOn',
  /** column name */
  new_PaidPeriodMonth = 'new_PaidPeriodMonth',
  /** column name */
  new_PaidTo = 'new_PaidTo',
  /** column name */
  new_PayTo = 'new_PayTo',
  /** column name */
  new_PaymentMethod = 'new_PaymentMethod',
  /** column name */
  new_PaymentType = 'new_PaymentType',
  /** column name */
  new_Receipt = 'new_Receipt',
  /** column name */
  new_TotalAmountBeforeVAT = 'new_TotalAmountBeforeVAT',
  /** column name */
  new_TotalOrderAmount = 'new_TotalOrderAmount',
  /** column name */
  new_TotalVATIncluded = 'new_TotalVATIncluded',
  /** column name */
  new_TotalvatIncludedinShekels = 'new_TotalvatIncludedinShekels',
  /** column name */
  new_TransactionNumber = 'new_TransactionNumber',
  /** column name */
  new_UseVAT = 'new_UseVAT',
  /** column name */
  new_VATAmount = 'new_VATAmount',
  /** column name */
  new_VATpercentage = 'new_VATpercentage',
  /** column name */
  new_Year = 'new_Year',
  /** column name */
  new_booltocancelandelete = 'new_booltocancelandelete',
  /** column name */
  new_checkvalue = 'new_checkvalue',
  /** column name */
  new_connectedorder = 'new_connectedorder',
  /** column name */
  new_copyPrintUrl = 'new_copyPrintUrl',
  /** column name */
  new_documentlocation = 'new_documentlocation',
  /** column name */
  new_fieldtodelete = 'new_fieldtodelete',
  /** column name */
  new_guid = 'new_guid',
  /** column name */
  new_invoicetype = 'new_invoicetype',
  /** column name */
  new_notes = 'new_notes',
  /** column name */
  new_paymentmethoddm = 'new_paymentmethoddm',
  /** column name */
  new_producedon = 'new_producedon',
  /** column name */
  new_senttoaccounting = 'new_senttoaccounting',
  /** column name */
  new_sourcePrintUrl = 'new_sourcePrintUrl',
  /** column name */
  new_totalamountbeforevat_Base = 'new_totalamountbeforevat_Base',
  /** column name */
  new_totalorderamount_Base = 'new_totalorderamount_Base',
  /** column name */
  new_totalvatincluded_Base = 'new_totalvatincluded_Base',
  /** column name */
  new_trigger = 'new_trigger',
  /** column name */
  new_vatamount_Base = 'new_vatamount_Base',
  /** column name */
  order_id = 'order_id',
  /** column name */
  origin_invoice_id = 'origin_invoice_id',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  receipt_id = 'receipt_id'
}

/** select "v1_InvoiceBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.InvoiceBase" */
export enum v1_InvoiceBase_select_column_v1_InvoiceBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  new_Invoicesent = 'new_Invoicesent',
  /** column name */
  new_UseVAT = 'new_UseVAT',
  /** column name */
  new_booltocancelandelete = 'new_booltocancelandelete',
  /** column name */
  new_invoicetype = 'new_invoicetype',
  /** column name */
  new_trigger = 'new_trigger'
}

/** select "v1_InvoiceBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.InvoiceBase" */
export enum v1_InvoiceBase_select_column_v1_InvoiceBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  new_Invoicesent = 'new_Invoicesent',
  /** column name */
  new_UseVAT = 'new_UseVAT',
  /** column name */
  new_booltocancelandelete = 'new_booltocancelandelete',
  /** column name */
  new_invoicetype = 'new_invoicetype',
  /** column name */
  new_trigger = 'new_trigger'
}

/** input type for updating data in table "v1.InvoiceBase" */
export type v1_InvoiceBase_set_input = {
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  CancelledOn?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CustomerId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  InvoiceId?: InputMaybe<Scalars['uuid']['input']>;
  InvoiceNumber?: InputMaybe<Scalars['String']['input']>;
  LineDescription?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  PaidPeriod?: InputMaybe<Scalars['date']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  /** 1 is draft */
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  TraversedPath?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_DollarEquivalent?: InputMaybe<Scalars['numeric']['input']>;
  new_DueDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_DueDateNotice?: InputMaybe<Scalars['Int']['input']>;
  new_EuroEquivalent?: InputMaybe<Scalars['numeric']['input']>;
  new_EuroEquivalentAuto?: InputMaybe<Scalars['numeric']['input']>;
  new_ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  new_ExchangeRateEuro?: InputMaybe<Scalars['numeric']['input']>;
  new_FromCompany?: InputMaybe<Scalars['Int']['input']>;
  new_InvoiceID?: InputMaybe<Scalars['String']['input']>;
  new_InvoiceRecalculated?: InputMaybe<Scalars['timestamp']['input']>;
  new_Invoicesent?: InputMaybe<Scalars['Boolean']['input']>;
  new_Issueinvoice?: InputMaybe<Scalars['Int']['input']>;
  new_MarkedasPaidby?: InputMaybe<Scalars['uuid']['input']>;
  new_OrderType?: InputMaybe<Scalars['Int']['input']>;
  new_PaidOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_PaidPeriodMonth?: InputMaybe<Scalars['Int']['input']>;
  new_PaidTo?: InputMaybe<Scalars['Int']['input']>;
  new_PayTo?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['uuid']['input']>;
  new_PaymentType?: InputMaybe<Scalars['Int']['input']>;
  new_Receipt?: InputMaybe<Scalars['uuid']['input']>;
  new_TotalAmountBeforeVAT?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalOrderAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalVATIncluded?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalvatIncludedinShekels?: InputMaybe<Scalars['String']['input']>;
  new_TransactionNumber?: InputMaybe<Scalars['String']['input']>;
  new_UseVAT?: InputMaybe<Scalars['Boolean']['input']>;
  new_VATAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_VATpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_booltocancelandelete?: InputMaybe<Scalars['Boolean']['input']>;
  new_checkvalue?: InputMaybe<Scalars['String']['input']>;
  new_connectedorder?: InputMaybe<Scalars['uuid']['input']>;
  new_copyPrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_documentlocation?: InputMaybe<Scalars['uuid']['input']>;
  new_fieldtodelete?: InputMaybe<Scalars['timestamp']['input']>;
  new_guid?: InputMaybe<Scalars['String']['input']>;
  new_invoicetype?: InputMaybe<Scalars['Boolean']['input']>;
  new_notes?: InputMaybe<Scalars['String']['input']>;
  new_paymentmethoddm?: InputMaybe<Scalars['uuid']['input']>;
  new_producedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_senttoaccounting?: InputMaybe<Scalars['timestamp']['input']>;
  new_sourcePrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_totalamountbeforevat_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalorderamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalvatincluded_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_trigger?: InputMaybe<Scalars['Boolean']['input']>;
  new_vatamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  order_id?: InputMaybe<Scalars['Int']['input']>;
  origin_invoice_id?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  receipt_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_InvoiceBase_stddev_fields = {
  __typename?: 'v1_InvoiceBase_stddev_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['Float']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRate?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Float']['output']>;
  new_PaidTo?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['Float']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['Float']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['Float']['output']>;
  new_VATAmount?: Maybe<Scalars['Float']['output']>;
  new_VATpercentage?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['Float']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['Float']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['Float']['output']>;
  new_vatamount_Base?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  origin_invoice_id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  receipt_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_stddev_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_InvoiceBase_stddev_pop_fields = {
  __typename?: 'v1_InvoiceBase_stddev_pop_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['Float']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRate?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Float']['output']>;
  new_PaidTo?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['Float']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['Float']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['Float']['output']>;
  new_VATAmount?: Maybe<Scalars['Float']['output']>;
  new_VATpercentage?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['Float']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['Float']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['Float']['output']>;
  new_vatamount_Base?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  origin_invoice_id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  receipt_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_stddev_pop_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_InvoiceBase_stddev_samp_fields = {
  __typename?: 'v1_InvoiceBase_stddev_samp_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['Float']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRate?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Float']['output']>;
  new_PaidTo?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['Float']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['Float']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['Float']['output']>;
  new_VATAmount?: Maybe<Scalars['Float']['output']>;
  new_VATpercentage?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['Float']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['Float']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['Float']['output']>;
  new_vatamount_Base?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  origin_invoice_id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  receipt_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_stddev_samp_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_InvoiceBase" */
export type v1_InvoiceBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_InvoiceBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_InvoiceBase_stream_cursor_value_input = {
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  CancelledOn?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CustomerId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  InvoiceId?: InputMaybe<Scalars['uuid']['input']>;
  InvoiceNumber?: InputMaybe<Scalars['String']['input']>;
  LineDescription?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  PaidPeriod?: InputMaybe<Scalars['date']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  /** 1 is draft */
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  TraversedPath?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_DollarEquivalent?: InputMaybe<Scalars['numeric']['input']>;
  new_DueDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_DueDateNotice?: InputMaybe<Scalars['Int']['input']>;
  new_EuroEquivalent?: InputMaybe<Scalars['numeric']['input']>;
  new_EuroEquivalentAuto?: InputMaybe<Scalars['numeric']['input']>;
  new_ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  new_ExchangeRateEuro?: InputMaybe<Scalars['numeric']['input']>;
  new_FromCompany?: InputMaybe<Scalars['Int']['input']>;
  new_InvoiceID?: InputMaybe<Scalars['String']['input']>;
  new_InvoiceRecalculated?: InputMaybe<Scalars['timestamp']['input']>;
  new_Invoicesent?: InputMaybe<Scalars['Boolean']['input']>;
  new_Issueinvoice?: InputMaybe<Scalars['Int']['input']>;
  new_MarkedasPaidby?: InputMaybe<Scalars['uuid']['input']>;
  new_OrderType?: InputMaybe<Scalars['Int']['input']>;
  new_PaidOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_PaidPeriodMonth?: InputMaybe<Scalars['Int']['input']>;
  new_PaidTo?: InputMaybe<Scalars['Int']['input']>;
  new_PayTo?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['uuid']['input']>;
  new_PaymentType?: InputMaybe<Scalars['Int']['input']>;
  new_Receipt?: InputMaybe<Scalars['uuid']['input']>;
  new_TotalAmountBeforeVAT?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalOrderAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalVATIncluded?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalvatIncludedinShekels?: InputMaybe<Scalars['String']['input']>;
  new_TransactionNumber?: InputMaybe<Scalars['String']['input']>;
  new_UseVAT?: InputMaybe<Scalars['Boolean']['input']>;
  new_VATAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_VATpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_booltocancelandelete?: InputMaybe<Scalars['Boolean']['input']>;
  new_checkvalue?: InputMaybe<Scalars['String']['input']>;
  new_connectedorder?: InputMaybe<Scalars['uuid']['input']>;
  new_copyPrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_documentlocation?: InputMaybe<Scalars['uuid']['input']>;
  new_fieldtodelete?: InputMaybe<Scalars['timestamp']['input']>;
  new_guid?: InputMaybe<Scalars['String']['input']>;
  new_invoicetype?: InputMaybe<Scalars['Boolean']['input']>;
  new_notes?: InputMaybe<Scalars['String']['input']>;
  new_paymentmethoddm?: InputMaybe<Scalars['uuid']['input']>;
  new_producedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_senttoaccounting?: InputMaybe<Scalars['timestamp']['input']>;
  new_sourcePrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_totalamountbeforevat_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalorderamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalvatincluded_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_trigger?: InputMaybe<Scalars['Boolean']['input']>;
  new_vatamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  order_id?: InputMaybe<Scalars['Int']['input']>;
  origin_invoice_id?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  receipt_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_InvoiceBase_sum_fields = {
  __typename?: 'v1_InvoiceBase_sum_fields';
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['numeric']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Int']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['numeric']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['numeric']['output']>;
  new_ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['numeric']['output']>;
  new_FromCompany?: Maybe<Scalars['Int']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Int']['output']>;
  new_OrderType?: Maybe<Scalars['Int']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Int']['output']>;
  new_PaidTo?: Maybe<Scalars['Int']['output']>;
  new_PayTo?: Maybe<Scalars['Int']['output']>;
  new_PaymentType?: Maybe<Scalars['Int']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['numeric']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['numeric']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['numeric']['output']>;
  new_VATAmount?: Maybe<Scalars['numeric']['output']>;
  new_VATpercentage?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['numeric']['output']>;
  new_vatamount_Base?: Maybe<Scalars['numeric']['output']>;
  order_id?: Maybe<Scalars['Int']['output']>;
  origin_invoice_id?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  receipt_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_sum_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.InvoiceBase" */
export enum v1_InvoiceBase_update_column {
  /** column name */
  CancelledBy = 'CancelledBy',
  /** column name */
  CancelledOn = 'CancelledOn',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CustomerId = 'CustomerId',
  /** column name */
  Description = 'Description',
  /** column name */
  InvoiceId = 'InvoiceId',
  /** column name */
  InvoiceNumber = 'InvoiceNumber',
  /** column name */
  LineDescription = 'LineDescription',
  /** column name */
  Name = 'Name',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  PaidPeriod = 'PaidPeriod',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  TraversedPath = 'TraversedPath',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_DollarEquivalent = 'new_DollarEquivalent',
  /** column name */
  new_DueDate = 'new_DueDate',
  /** column name */
  new_DueDateNotice = 'new_DueDateNotice',
  /** column name */
  new_EuroEquivalent = 'new_EuroEquivalent',
  /** column name */
  new_EuroEquivalentAuto = 'new_EuroEquivalentAuto',
  /** column name */
  new_ExchangeRate = 'new_ExchangeRate',
  /** column name */
  new_ExchangeRateEuro = 'new_ExchangeRateEuro',
  /** column name */
  new_FromCompany = 'new_FromCompany',
  /** column name */
  new_InvoiceID = 'new_InvoiceID',
  /** column name */
  new_InvoiceRecalculated = 'new_InvoiceRecalculated',
  /** column name */
  new_Invoicesent = 'new_Invoicesent',
  /** column name */
  new_Issueinvoice = 'new_Issueinvoice',
  /** column name */
  new_MarkedasPaidby = 'new_MarkedasPaidby',
  /** column name */
  new_OrderType = 'new_OrderType',
  /** column name */
  new_PaidOn = 'new_PaidOn',
  /** column name */
  new_PaidPeriodMonth = 'new_PaidPeriodMonth',
  /** column name */
  new_PaidTo = 'new_PaidTo',
  /** column name */
  new_PayTo = 'new_PayTo',
  /** column name */
  new_PaymentMethod = 'new_PaymentMethod',
  /** column name */
  new_PaymentType = 'new_PaymentType',
  /** column name */
  new_Receipt = 'new_Receipt',
  /** column name */
  new_TotalAmountBeforeVAT = 'new_TotalAmountBeforeVAT',
  /** column name */
  new_TotalOrderAmount = 'new_TotalOrderAmount',
  /** column name */
  new_TotalVATIncluded = 'new_TotalVATIncluded',
  /** column name */
  new_TotalvatIncludedinShekels = 'new_TotalvatIncludedinShekels',
  /** column name */
  new_TransactionNumber = 'new_TransactionNumber',
  /** column name */
  new_UseVAT = 'new_UseVAT',
  /** column name */
  new_VATAmount = 'new_VATAmount',
  /** column name */
  new_VATpercentage = 'new_VATpercentage',
  /** column name */
  new_Year = 'new_Year',
  /** column name */
  new_booltocancelandelete = 'new_booltocancelandelete',
  /** column name */
  new_checkvalue = 'new_checkvalue',
  /** column name */
  new_connectedorder = 'new_connectedorder',
  /** column name */
  new_copyPrintUrl = 'new_copyPrintUrl',
  /** column name */
  new_documentlocation = 'new_documentlocation',
  /** column name */
  new_fieldtodelete = 'new_fieldtodelete',
  /** column name */
  new_guid = 'new_guid',
  /** column name */
  new_invoicetype = 'new_invoicetype',
  /** column name */
  new_notes = 'new_notes',
  /** column name */
  new_paymentmethoddm = 'new_paymentmethoddm',
  /** column name */
  new_producedon = 'new_producedon',
  /** column name */
  new_senttoaccounting = 'new_senttoaccounting',
  /** column name */
  new_sourcePrintUrl = 'new_sourcePrintUrl',
  /** column name */
  new_totalamountbeforevat_Base = 'new_totalamountbeforevat_Base',
  /** column name */
  new_totalorderamount_Base = 'new_totalorderamount_Base',
  /** column name */
  new_totalvatincluded_Base = 'new_totalvatincluded_Base',
  /** column name */
  new_trigger = 'new_trigger',
  /** column name */
  new_vatamount_Base = 'new_vatamount_Base',
  /** column name */
  order_id = 'order_id',
  /** column name */
  origin_invoice_id = 'origin_invoice_id',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  receipt_id = 'receipt_id'
}

export type v1_InvoiceBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_InvoiceBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_InvoiceBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_InvoiceBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_InvoiceBase_var_pop_fields = {
  __typename?: 'v1_InvoiceBase_var_pop_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['Float']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRate?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Float']['output']>;
  new_PaidTo?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['Float']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['Float']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['Float']['output']>;
  new_VATAmount?: Maybe<Scalars['Float']['output']>;
  new_VATpercentage?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['Float']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['Float']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['Float']['output']>;
  new_vatamount_Base?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  origin_invoice_id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  receipt_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_var_pop_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_InvoiceBase_var_samp_fields = {
  __typename?: 'v1_InvoiceBase_var_samp_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['Float']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRate?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Float']['output']>;
  new_PaidTo?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['Float']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['Float']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['Float']['output']>;
  new_VATAmount?: Maybe<Scalars['Float']['output']>;
  new_VATpercentage?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['Float']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['Float']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['Float']['output']>;
  new_vatamount_Base?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  origin_invoice_id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  receipt_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_var_samp_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_InvoiceBase_variance_fields = {
  __typename?: 'v1_InvoiceBase_variance_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  /** 1 is draft */
  StatusCode?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_DollarEquivalent?: Maybe<Scalars['Float']['output']>;
  new_DueDateNotice?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalent?: Maybe<Scalars['Float']['output']>;
  new_EuroEquivalentAuto?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRate?: Maybe<Scalars['Float']['output']>;
  new_ExchangeRateEuro?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Issueinvoice?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PaidPeriodMonth?: Maybe<Scalars['Float']['output']>;
  new_PaidTo?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_TotalAmountBeforeVAT?: Maybe<Scalars['Float']['output']>;
  new_TotalOrderAmount?: Maybe<Scalars['Float']['output']>;
  new_TotalVATIncluded?: Maybe<Scalars['Float']['output']>;
  new_VATAmount?: Maybe<Scalars['Float']['output']>;
  new_VATpercentage?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_totalamountbeforevat_Base?: Maybe<Scalars['Float']['output']>;
  new_totalorderamount_Base?: Maybe<Scalars['Float']['output']>;
  new_totalvatincluded_Base?: Maybe<Scalars['Float']['output']>;
  new_vatamount_Base?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  origin_invoice_id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  receipt_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.InvoiceBase" */
export type v1_InvoiceBase_variance_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  /** 1 is draft */
  StatusCode?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_DollarEquivalent?: InputMaybe<order_by>;
  new_DueDateNotice?: InputMaybe<order_by>;
  new_EuroEquivalent?: InputMaybe<order_by>;
  new_EuroEquivalentAuto?: InputMaybe<order_by>;
  new_ExchangeRate?: InputMaybe<order_by>;
  new_ExchangeRateEuro?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Issueinvoice?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PaidPeriodMonth?: InputMaybe<order_by>;
  new_PaidTo?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_TotalAmountBeforeVAT?: InputMaybe<order_by>;
  new_TotalOrderAmount?: InputMaybe<order_by>;
  new_TotalVATIncluded?: InputMaybe<order_by>;
  new_VATAmount?: InputMaybe<order_by>;
  new_VATpercentage?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_totalamountbeforevat_Base?: InputMaybe<order_by>;
  new_totalorderamount_Base?: InputMaybe<order_by>;
  new_totalvatincluded_Base?: InputMaybe<order_by>;
  new_vatamount_Base?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  origin_invoice_id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  receipt_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase = {
  __typename?: 'v1_InvoiceDetailBase';
  BaseAmount?: Maybe<Scalars['numeric']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  Invoice?: Maybe<v1_InvoiceBase>;
  InvoiceDetailId?: Maybe<Scalars['uuid']['output']>;
  InvoiceId?: Maybe<Scalars['uuid']['output']>;
  LineItemNumber?: Maybe<Scalars['Int']['output']>;
  PricePerUnit?: Maybe<Scalars['numeric']['output']>;
  ProductDescription?: Maybe<Scalars['String']['output']>;
  Quantity?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  SystemUserBase?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  TransactionCurrencyBase?: Maybe<v1_TransactionCurrencyBase>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invoice_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_aggregate = {
  __typename?: 'v1_InvoiceDetailBase_aggregate';
  aggregate?: Maybe<v1_InvoiceDetailBase_aggregate_fields>;
  nodes: Array<v1_InvoiceDetailBase>;
};

export type v1_InvoiceDetailBase_aggregate_bool_exp = {
  count?: InputMaybe<v1_InvoiceDetailBase_aggregate_bool_exp_count>;
};

export type v1_InvoiceDetailBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_aggregate_fields = {
  __typename?: 'v1_InvoiceDetailBase_aggregate_fields';
  avg?: Maybe<v1_InvoiceDetailBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_InvoiceDetailBase_max_fields>;
  min?: Maybe<v1_InvoiceDetailBase_min_fields>;
  stddev?: Maybe<v1_InvoiceDetailBase_stddev_fields>;
  stddev_pop?: Maybe<v1_InvoiceDetailBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_InvoiceDetailBase_stddev_samp_fields>;
  sum?: Maybe<v1_InvoiceDetailBase_sum_fields>;
  var_pop?: Maybe<v1_InvoiceDetailBase_var_pop_fields>;
  var_samp?: Maybe<v1_InvoiceDetailBase_var_samp_fields>;
  variance?: Maybe<v1_InvoiceDetailBase_variance_fields>;
};


/** aggregate fields of "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_aggregate_order_by = {
  avg?: InputMaybe<v1_InvoiceDetailBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_InvoiceDetailBase_max_order_by>;
  min?: InputMaybe<v1_InvoiceDetailBase_min_order_by>;
  stddev?: InputMaybe<v1_InvoiceDetailBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_InvoiceDetailBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_InvoiceDetailBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_InvoiceDetailBase_sum_order_by>;
  var_pop?: InputMaybe<v1_InvoiceDetailBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_InvoiceDetailBase_var_samp_order_by>;
  variance?: InputMaybe<v1_InvoiceDetailBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_arr_rel_insert_input = {
  data: Array<v1_InvoiceDetailBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_InvoiceDetailBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_InvoiceDetailBase_avg_fields = {
  __typename?: 'v1_InvoiceDetailBase_avg_fields';
  BaseAmount?: Maybe<Scalars['Float']['output']>;
  LineItemNumber?: Maybe<Scalars['Float']['output']>;
  PricePerUnit?: Maybe<Scalars['Float']['output']>;
  Quantity?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invoice_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_avg_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.InvoiceDetailBase". All fields are combined with a logical 'AND'. */
export type v1_InvoiceDetailBase_bool_exp = {
  BaseAmount?: InputMaybe<numeric_comparison_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  Invoice?: InputMaybe<v1_InvoiceBase_bool_exp>;
  InvoiceDetailId?: InputMaybe<uuid_comparison_exp>;
  InvoiceId?: InputMaybe<uuid_comparison_exp>;
  LineItemNumber?: InputMaybe<Int_comparison_exp>;
  PricePerUnit?: InputMaybe<numeric_comparison_exp>;
  ProductDescription?: InputMaybe<String_comparison_exp>;
  Quantity?: InputMaybe<numeric_comparison_exp>;
  SystemUserBase?: InputMaybe<v1_SystemUserBase_bool_exp>;
  TransactionCurrencyBase?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  _and?: InputMaybe<Array<v1_InvoiceDetailBase_bool_exp>>;
  _not?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
  _or?: InputMaybe<Array<v1_InvoiceDetailBase_bool_exp>>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  invoice_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.InvoiceDetailBase" */
export enum v1_InvoiceDetailBase_constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceDetailBase_id_key = 'InvoiceDetailBase_id_key',
  /** unique or primary key constraint on columns "id" */
  InvoiceDetailBase_pkey = 'InvoiceDetailBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_inc_input = {
  BaseAmount?: InputMaybe<Scalars['numeric']['input']>;
  LineItemNumber?: InputMaybe<Scalars['Int']['input']>;
  PricePerUnit?: InputMaybe<Scalars['numeric']['input']>;
  Quantity?: InputMaybe<Scalars['numeric']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_insert_input = {
  BaseAmount?: InputMaybe<Scalars['numeric']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Invoice?: InputMaybe<v1_InvoiceBase_obj_rel_insert_input>;
  InvoiceDetailId?: InputMaybe<Scalars['uuid']['input']>;
  InvoiceId?: InputMaybe<Scalars['uuid']['input']>;
  LineItemNumber?: InputMaybe<Scalars['Int']['input']>;
  PricePerUnit?: InputMaybe<Scalars['numeric']['input']>;
  ProductDescription?: InputMaybe<Scalars['String']['input']>;
  Quantity?: InputMaybe<Scalars['numeric']['input']>;
  SystemUserBase?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  TransactionCurrencyBase?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_InvoiceDetailBase_max_fields = {
  __typename?: 'v1_InvoiceDetailBase_max_fields';
  BaseAmount?: Maybe<Scalars['numeric']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  InvoiceDetailId?: Maybe<Scalars['uuid']['output']>;
  InvoiceId?: Maybe<Scalars['uuid']['output']>;
  LineItemNumber?: Maybe<Scalars['Int']['output']>;
  PricePerUnit?: Maybe<Scalars['numeric']['output']>;
  ProductDescription?: Maybe<Scalars['String']['output']>;
  Quantity?: Maybe<Scalars['numeric']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  invoice_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_max_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  InvoiceDetailId?: InputMaybe<order_by>;
  InvoiceId?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  ProductDescription?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_InvoiceDetailBase_min_fields = {
  __typename?: 'v1_InvoiceDetailBase_min_fields';
  BaseAmount?: Maybe<Scalars['numeric']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  InvoiceDetailId?: Maybe<Scalars['uuid']['output']>;
  InvoiceId?: Maybe<Scalars['uuid']['output']>;
  LineItemNumber?: Maybe<Scalars['Int']['output']>;
  PricePerUnit?: Maybe<Scalars['numeric']['output']>;
  ProductDescription?: Maybe<Scalars['String']['output']>;
  Quantity?: Maybe<Scalars['numeric']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  invoice_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_min_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  InvoiceDetailId?: InputMaybe<order_by>;
  InvoiceId?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  ProductDescription?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_mutation_response = {
  __typename?: 'v1_InvoiceDetailBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_InvoiceDetailBase>;
};

/** on_conflict condition type for table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_on_conflict = {
  constraint: v1_InvoiceDetailBase_constraint;
  update_columns?: Array<v1_InvoiceDetailBase_update_column>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.InvoiceDetailBase". */
export type v1_InvoiceDetailBase_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  Invoice?: InputMaybe<v1_InvoiceBase_order_by>;
  InvoiceDetailId?: InputMaybe<order_by>;
  InvoiceId?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  ProductDescription?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  SystemUserBase?: InputMaybe<v1_SystemUserBase_order_by>;
  TransactionCurrencyBase?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.InvoiceDetailBase */
export type v1_InvoiceDetailBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.InvoiceDetailBase" */
export enum v1_InvoiceDetailBase_select_column {
  /** column name */
  BaseAmount = 'BaseAmount',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  InvoiceDetailId = 'InvoiceDetailId',
  /** column name */
  InvoiceId = 'InvoiceId',
  /** column name */
  LineItemNumber = 'LineItemNumber',
  /** column name */
  PricePerUnit = 'PricePerUnit',
  /** column name */
  ProductDescription = 'ProductDescription',
  /** column name */
  Quantity = 'Quantity',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  invoice_id = 'invoice_id'
}

/** input type for updating data in table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_set_input = {
  BaseAmount?: InputMaybe<Scalars['numeric']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  InvoiceDetailId?: InputMaybe<Scalars['uuid']['input']>;
  InvoiceId?: InputMaybe<Scalars['uuid']['input']>;
  LineItemNumber?: InputMaybe<Scalars['Int']['input']>;
  PricePerUnit?: InputMaybe<Scalars['numeric']['input']>;
  ProductDescription?: InputMaybe<Scalars['String']['input']>;
  Quantity?: InputMaybe<Scalars['numeric']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_InvoiceDetailBase_stddev_fields = {
  __typename?: 'v1_InvoiceDetailBase_stddev_fields';
  BaseAmount?: Maybe<Scalars['Float']['output']>;
  LineItemNumber?: Maybe<Scalars['Float']['output']>;
  PricePerUnit?: Maybe<Scalars['Float']['output']>;
  Quantity?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invoice_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_stddev_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_InvoiceDetailBase_stddev_pop_fields = {
  __typename?: 'v1_InvoiceDetailBase_stddev_pop_fields';
  BaseAmount?: Maybe<Scalars['Float']['output']>;
  LineItemNumber?: Maybe<Scalars['Float']['output']>;
  PricePerUnit?: Maybe<Scalars['Float']['output']>;
  Quantity?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invoice_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_stddev_pop_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_InvoiceDetailBase_stddev_samp_fields = {
  __typename?: 'v1_InvoiceDetailBase_stddev_samp_fields';
  BaseAmount?: Maybe<Scalars['Float']['output']>;
  LineItemNumber?: Maybe<Scalars['Float']['output']>;
  PricePerUnit?: Maybe<Scalars['Float']['output']>;
  Quantity?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invoice_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_stddev_samp_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_InvoiceDetailBase" */
export type v1_InvoiceDetailBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_InvoiceDetailBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_InvoiceDetailBase_stream_cursor_value_input = {
  BaseAmount?: InputMaybe<Scalars['numeric']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  InvoiceDetailId?: InputMaybe<Scalars['uuid']['input']>;
  InvoiceId?: InputMaybe<Scalars['uuid']['input']>;
  LineItemNumber?: InputMaybe<Scalars['Int']['input']>;
  PricePerUnit?: InputMaybe<Scalars['numeric']['input']>;
  ProductDescription?: InputMaybe<Scalars['String']['input']>;
  Quantity?: InputMaybe<Scalars['numeric']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_InvoiceDetailBase_sum_fields = {
  __typename?: 'v1_InvoiceDetailBase_sum_fields';
  BaseAmount?: Maybe<Scalars['numeric']['output']>;
  LineItemNumber?: Maybe<Scalars['Int']['output']>;
  PricePerUnit?: Maybe<Scalars['numeric']['output']>;
  Quantity?: Maybe<Scalars['numeric']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  invoice_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_sum_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.InvoiceDetailBase" */
export enum v1_InvoiceDetailBase_update_column {
  /** column name */
  BaseAmount = 'BaseAmount',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  InvoiceDetailId = 'InvoiceDetailId',
  /** column name */
  InvoiceId = 'InvoiceId',
  /** column name */
  LineItemNumber = 'LineItemNumber',
  /** column name */
  PricePerUnit = 'PricePerUnit',
  /** column name */
  ProductDescription = 'ProductDescription',
  /** column name */
  Quantity = 'Quantity',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  invoice_id = 'invoice_id'
}

export type v1_InvoiceDetailBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_InvoiceDetailBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_InvoiceDetailBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_InvoiceDetailBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_InvoiceDetailBase_var_pop_fields = {
  __typename?: 'v1_InvoiceDetailBase_var_pop_fields';
  BaseAmount?: Maybe<Scalars['Float']['output']>;
  LineItemNumber?: Maybe<Scalars['Float']['output']>;
  PricePerUnit?: Maybe<Scalars['Float']['output']>;
  Quantity?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invoice_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_var_pop_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_InvoiceDetailBase_var_samp_fields = {
  __typename?: 'v1_InvoiceDetailBase_var_samp_fields';
  BaseAmount?: Maybe<Scalars['Float']['output']>;
  LineItemNumber?: Maybe<Scalars['Float']['output']>;
  PricePerUnit?: Maybe<Scalars['Float']['output']>;
  Quantity?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invoice_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_var_samp_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_InvoiceDetailBase_variance_fields = {
  __typename?: 'v1_InvoiceDetailBase_variance_fields';
  BaseAmount?: Maybe<Scalars['Float']['output']>;
  LineItemNumber?: Maybe<Scalars['Float']['output']>;
  PricePerUnit?: Maybe<Scalars['Float']['output']>;
  Quantity?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invoice_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.InvoiceDetailBase" */
export type v1_InvoiceDetailBase_variance_order_by = {
  BaseAmount?: InputMaybe<order_by>;
  LineItemNumber?: InputMaybe<order_by>;
  PricePerUnit?: InputMaybe<order_by>;
  Quantity?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoice_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.InvoiceStatus" */
export type v1_InvoiceStatus = {
  __typename?: 'v1_InvoiceStatus';
  /** An array relationship */
  InvoiceBases: Array<v1_InvoiceBase>;
  /** An aggregate relationship */
  InvoiceBases_aggregate: v1_InvoiceBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.InvoiceStatus" */
export type v1_InvoiceStatusInvoiceBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** columns and relationships of "v1.InvoiceStatus" */
export type v1_InvoiceStatusInvoiceBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};

/** aggregated selection of "v1.InvoiceStatus" */
export type v1_InvoiceStatus_aggregate = {
  __typename?: 'v1_InvoiceStatus_aggregate';
  aggregate?: Maybe<v1_InvoiceStatus_aggregate_fields>;
  nodes: Array<v1_InvoiceStatus>;
};

/** aggregate fields of "v1.InvoiceStatus" */
export type v1_InvoiceStatus_aggregate_fields = {
  __typename?: 'v1_InvoiceStatus_aggregate_fields';
  avg?: Maybe<v1_InvoiceStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_InvoiceStatus_max_fields>;
  min?: Maybe<v1_InvoiceStatus_min_fields>;
  stddev?: Maybe<v1_InvoiceStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_InvoiceStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_InvoiceStatus_stddev_samp_fields>;
  sum?: Maybe<v1_InvoiceStatus_sum_fields>;
  var_pop?: Maybe<v1_InvoiceStatus_var_pop_fields>;
  var_samp?: Maybe<v1_InvoiceStatus_var_samp_fields>;
  variance?: Maybe<v1_InvoiceStatus_variance_fields>;
};


/** aggregate fields of "v1.InvoiceStatus" */
export type v1_InvoiceStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_InvoiceStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_InvoiceStatus_avg_fields = {
  __typename?: 'v1_InvoiceStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.InvoiceStatus". All fields are combined with a logical 'AND'. */
export type v1_InvoiceStatus_bool_exp = {
  InvoiceBases?: InputMaybe<v1_InvoiceBase_bool_exp>;
  InvoiceBases_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_InvoiceStatus_bool_exp>>;
  _not?: InputMaybe<v1_InvoiceStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_InvoiceStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.InvoiceStatus" */
export enum v1_InvoiceStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceStatus_pkey = 'InvoiceStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.InvoiceStatus" */
export type v1_InvoiceStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.InvoiceStatus" */
export type v1_InvoiceStatus_insert_input = {
  InvoiceBases?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_InvoiceStatus_max_fields = {
  __typename?: 'v1_InvoiceStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_InvoiceStatus_min_fields = {
  __typename?: 'v1_InvoiceStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.InvoiceStatus" */
export type v1_InvoiceStatus_mutation_response = {
  __typename?: 'v1_InvoiceStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_InvoiceStatus>;
};

/** input type for inserting object relation for remote table "v1.InvoiceStatus" */
export type v1_InvoiceStatus_obj_rel_insert_input = {
  data: v1_InvoiceStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_InvoiceStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.InvoiceStatus" */
export type v1_InvoiceStatus_on_conflict = {
  constraint: v1_InvoiceStatus_constraint;
  update_columns?: Array<v1_InvoiceStatus_update_column>;
  where?: InputMaybe<v1_InvoiceStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.InvoiceStatus". */
export type v1_InvoiceStatus_order_by = {
  InvoiceBases_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.InvoiceStatus */
export type v1_InvoiceStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.InvoiceStatus" */
export enum v1_InvoiceStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.InvoiceStatus" */
export type v1_InvoiceStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_InvoiceStatus_stddev_fields = {
  __typename?: 'v1_InvoiceStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_InvoiceStatus_stddev_pop_fields = {
  __typename?: 'v1_InvoiceStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_InvoiceStatus_stddev_samp_fields = {
  __typename?: 'v1_InvoiceStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_InvoiceStatus" */
export type v1_InvoiceStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_InvoiceStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_InvoiceStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_InvoiceStatus_sum_fields = {
  __typename?: 'v1_InvoiceStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.InvoiceStatus" */
export enum v1_InvoiceStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_InvoiceStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_InvoiceStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_InvoiceStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_InvoiceStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_InvoiceStatus_var_pop_fields = {
  __typename?: 'v1_InvoiceStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_InvoiceStatus_var_samp_fields = {
  __typename?: 'v1_InvoiceStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_InvoiceStatus_variance_fields = {
  __typename?: 'v1_InvoiceStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.LBInProgressPosts" */
export type v1_LBInProgressPosts = {
  __typename?: 'v1_LBInProgressPosts';
  id?: Maybe<Scalars['Int']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "v1.LBInProgressPosts" */
export type v1_LBInProgressPosts_aggregate = {
  __typename?: 'v1_LBInProgressPosts_aggregate';
  aggregate?: Maybe<v1_LBInProgressPosts_aggregate_fields>;
  nodes: Array<v1_LBInProgressPosts>;
};

/** aggregate fields of "v1.LBInProgressPosts" */
export type v1_LBInProgressPosts_aggregate_fields = {
  __typename?: 'v1_LBInProgressPosts_aggregate_fields';
  avg?: Maybe<v1_LBInProgressPosts_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_LBInProgressPosts_max_fields>;
  min?: Maybe<v1_LBInProgressPosts_min_fields>;
  stddev?: Maybe<v1_LBInProgressPosts_stddev_fields>;
  stddev_pop?: Maybe<v1_LBInProgressPosts_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_LBInProgressPosts_stddev_samp_fields>;
  sum?: Maybe<v1_LBInProgressPosts_sum_fields>;
  var_pop?: Maybe<v1_LBInProgressPosts_var_pop_fields>;
  var_samp?: Maybe<v1_LBInProgressPosts_var_samp_fields>;
  variance?: Maybe<v1_LBInProgressPosts_variance_fields>;
};


/** aggregate fields of "v1.LBInProgressPosts" */
export type v1_LBInProgressPosts_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_LBInProgressPosts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_LBInProgressPosts_avg_fields = {
  __typename?: 'v1_LBInProgressPosts_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.LBInProgressPosts". All fields are combined with a logical 'AND'. */
export type v1_LBInProgressPosts_bool_exp = {
  _and?: InputMaybe<Array<v1_LBInProgressPosts_bool_exp>>;
  _not?: InputMaybe<v1_LBInProgressPosts_bool_exp>;
  _or?: InputMaybe<Array<v1_LBInProgressPosts_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_TargetURL1?: InputMaybe<String_comparison_exp>;
  new_TargetURL2?: InputMaybe<String_comparison_exp>;
  new_TargetURL3?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type v1_LBInProgressPosts_max_fields = {
  __typename?: 'v1_LBInProgressPosts_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_LBInProgressPosts_min_fields = {
  __typename?: 'v1_LBInProgressPosts_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "v1.LBInProgressPosts". */
export type v1_LBInProgressPosts_order_by = {
  id?: InputMaybe<order_by>;
  new_TargetURL1?: InputMaybe<order_by>;
  new_TargetURL2?: InputMaybe<order_by>;
  new_TargetURL3?: InputMaybe<order_by>;
};

/** select columns of table "v1.LBInProgressPosts" */
export enum v1_LBInProgressPosts_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_TargetURL1 = 'new_TargetURL1',
  /** column name */
  new_TargetURL2 = 'new_TargetURL2',
  /** column name */
  new_TargetURL3 = 'new_TargetURL3'
}

/** aggregate stddev on columns */
export type v1_LBInProgressPosts_stddev_fields = {
  __typename?: 'v1_LBInProgressPosts_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_LBInProgressPosts_stddev_pop_fields = {
  __typename?: 'v1_LBInProgressPosts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_LBInProgressPosts_stddev_samp_fields = {
  __typename?: 'v1_LBInProgressPosts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_LBInProgressPosts" */
export type v1_LBInProgressPosts_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_LBInProgressPosts_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_LBInProgressPosts_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_TargetURL1?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL2?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL3?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_LBInProgressPosts_sum_fields = {
  __typename?: 'v1_LBInProgressPosts_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type v1_LBInProgressPosts_var_pop_fields = {
  __typename?: 'v1_LBInProgressPosts_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_LBInProgressPosts_var_samp_fields = {
  __typename?: 'v1_LBInProgressPosts_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_LBInProgressPosts_variance_fields = {
  __typename?: 'v1_LBInProgressPosts_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.LBPublishedPosts" */
export type v1_LBPublishedPosts = {
  __typename?: 'v1_LBPublishedPosts';
  id?: Maybe<Scalars['Int']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "v1.LBPublishedPosts" */
export type v1_LBPublishedPosts_aggregate = {
  __typename?: 'v1_LBPublishedPosts_aggregate';
  aggregate?: Maybe<v1_LBPublishedPosts_aggregate_fields>;
  nodes: Array<v1_LBPublishedPosts>;
};

/** aggregate fields of "v1.LBPublishedPosts" */
export type v1_LBPublishedPosts_aggregate_fields = {
  __typename?: 'v1_LBPublishedPosts_aggregate_fields';
  avg?: Maybe<v1_LBPublishedPosts_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_LBPublishedPosts_max_fields>;
  min?: Maybe<v1_LBPublishedPosts_min_fields>;
  stddev?: Maybe<v1_LBPublishedPosts_stddev_fields>;
  stddev_pop?: Maybe<v1_LBPublishedPosts_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_LBPublishedPosts_stddev_samp_fields>;
  sum?: Maybe<v1_LBPublishedPosts_sum_fields>;
  var_pop?: Maybe<v1_LBPublishedPosts_var_pop_fields>;
  var_samp?: Maybe<v1_LBPublishedPosts_var_samp_fields>;
  variance?: Maybe<v1_LBPublishedPosts_variance_fields>;
};


/** aggregate fields of "v1.LBPublishedPosts" */
export type v1_LBPublishedPosts_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_LBPublishedPosts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_LBPublishedPosts_avg_fields = {
  __typename?: 'v1_LBPublishedPosts_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.LBPublishedPosts". All fields are combined with a logical 'AND'. */
export type v1_LBPublishedPosts_bool_exp = {
  _and?: InputMaybe<Array<v1_LBPublishedPosts_bool_exp>>;
  _not?: InputMaybe<v1_LBPublishedPosts_bool_exp>;
  _or?: InputMaybe<Array<v1_LBPublishedPosts_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_TargetURL1?: InputMaybe<String_comparison_exp>;
  new_TargetURL2?: InputMaybe<String_comparison_exp>;
  new_TargetURL3?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type v1_LBPublishedPosts_max_fields = {
  __typename?: 'v1_LBPublishedPosts_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_LBPublishedPosts_min_fields = {
  __typename?: 'v1_LBPublishedPosts_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "v1.LBPublishedPosts". */
export type v1_LBPublishedPosts_order_by = {
  id?: InputMaybe<order_by>;
  new_TargetURL1?: InputMaybe<order_by>;
  new_TargetURL2?: InputMaybe<order_by>;
  new_TargetURL3?: InputMaybe<order_by>;
};

/** select columns of table "v1.LBPublishedPosts" */
export enum v1_LBPublishedPosts_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_TargetURL1 = 'new_TargetURL1',
  /** column name */
  new_TargetURL2 = 'new_TargetURL2',
  /** column name */
  new_TargetURL3 = 'new_TargetURL3'
}

/** aggregate stddev on columns */
export type v1_LBPublishedPosts_stddev_fields = {
  __typename?: 'v1_LBPublishedPosts_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_LBPublishedPosts_stddev_pop_fields = {
  __typename?: 'v1_LBPublishedPosts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_LBPublishedPosts_stddev_samp_fields = {
  __typename?: 'v1_LBPublishedPosts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_LBPublishedPosts" */
export type v1_LBPublishedPosts_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_LBPublishedPosts_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_LBPublishedPosts_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_TargetURL1?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL2?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL3?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_LBPublishedPosts_sum_fields = {
  __typename?: 'v1_LBPublishedPosts_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type v1_LBPublishedPosts_var_pop_fields = {
  __typename?: 'v1_LBPublishedPosts_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_LBPublishedPosts_var_samp_fields = {
  __typename?: 'v1_LBPublishedPosts_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_LBPublishedPosts_variance_fields = {
  __typename?: 'v1_LBPublishedPosts_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.LandingPagePriority" */
export type v1_LandingPagePriority = {
  __typename?: 'v1_LandingPagePriority';
  /** An array relationship */
  LandinPages: Array<v1_LandingPages>;
  /** An aggregate relationship */
  LandinPages_aggregate: v1_LandingPages_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['bpchar']['output'];
};


/** columns and relationships of "v1.LandingPagePriority" */
export type v1_LandingPagePriorityLandinPagesArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


/** columns and relationships of "v1.LandingPagePriority" */
export type v1_LandingPagePriorityLandinPages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};

/** aggregated selection of "v1.LandingPagePriority" */
export type v1_LandingPagePriority_aggregate = {
  __typename?: 'v1_LandingPagePriority_aggregate';
  aggregate?: Maybe<v1_LandingPagePriority_aggregate_fields>;
  nodes: Array<v1_LandingPagePriority>;
};

/** aggregate fields of "v1.LandingPagePriority" */
export type v1_LandingPagePriority_aggregate_fields = {
  __typename?: 'v1_LandingPagePriority_aggregate_fields';
  avg?: Maybe<v1_LandingPagePriority_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_LandingPagePriority_max_fields>;
  min?: Maybe<v1_LandingPagePriority_min_fields>;
  stddev?: Maybe<v1_LandingPagePriority_stddev_fields>;
  stddev_pop?: Maybe<v1_LandingPagePriority_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_LandingPagePriority_stddev_samp_fields>;
  sum?: Maybe<v1_LandingPagePriority_sum_fields>;
  var_pop?: Maybe<v1_LandingPagePriority_var_pop_fields>;
  var_samp?: Maybe<v1_LandingPagePriority_var_samp_fields>;
  variance?: Maybe<v1_LandingPagePriority_variance_fields>;
};


/** aggregate fields of "v1.LandingPagePriority" */
export type v1_LandingPagePriority_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_LandingPagePriority_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_LandingPagePriority_avg_fields = {
  __typename?: 'v1_LandingPagePriority_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.LandingPagePriority". All fields are combined with a logical 'AND'. */
export type v1_LandingPagePriority_bool_exp = {
  LandinPages?: InputMaybe<v1_LandingPages_bool_exp>;
  LandinPages_aggregate?: InputMaybe<v1_LandingPages_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_LandingPagePriority_bool_exp>>;
  _not?: InputMaybe<v1_LandingPagePriority_bool_exp>;
  _or?: InputMaybe<Array<v1_LandingPagePriority_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<bpchar_comparison_exp>;
};

/** unique or primary key constraints on table "v1.LandingPagePriority" */
export enum v1_LandingPagePriority_constraint {
  /** unique or primary key constraint on columns "id" */
  LandingPagePriority_pkey = 'LandingPagePriority_pkey',
  /** unique or primary key constraint on columns "value" */
  LandingPagePriority_value_key = 'LandingPagePriority_value_key'
}

/** input type for incrementing numeric columns in table "v1.LandingPagePriority" */
export type v1_LandingPagePriority_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.LandingPagePriority" */
export type v1_LandingPagePriority_insert_input = {
  LandinPages?: InputMaybe<v1_LandingPages_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type v1_LandingPagePriority_max_fields = {
  __typename?: 'v1_LandingPagePriority_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_LandingPagePriority_min_fields = {
  __typename?: 'v1_LandingPagePriority_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.LandingPagePriority" */
export type v1_LandingPagePriority_mutation_response = {
  __typename?: 'v1_LandingPagePriority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_LandingPagePriority>;
};

/** input type for inserting object relation for remote table "v1.LandingPagePriority" */
export type v1_LandingPagePriority_obj_rel_insert_input = {
  data: v1_LandingPagePriority_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_LandingPagePriority_on_conflict>;
};

/** on_conflict condition type for table "v1.LandingPagePriority" */
export type v1_LandingPagePriority_on_conflict = {
  constraint: v1_LandingPagePriority_constraint;
  update_columns?: Array<v1_LandingPagePriority_update_column>;
  where?: InputMaybe<v1_LandingPagePriority_bool_exp>;
};

/** Ordering options when selecting data from "v1.LandingPagePriority". */
export type v1_LandingPagePriority_order_by = {
  LandinPages_aggregate?: InputMaybe<v1_LandingPages_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.LandingPagePriority */
export type v1_LandingPagePriority_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.LandingPagePriority" */
export enum v1_LandingPagePriority_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.LandingPagePriority" */
export type v1_LandingPagePriority_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type v1_LandingPagePriority_stddev_fields = {
  __typename?: 'v1_LandingPagePriority_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_LandingPagePriority_stddev_pop_fields = {
  __typename?: 'v1_LandingPagePriority_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_LandingPagePriority_stddev_samp_fields = {
  __typename?: 'v1_LandingPagePriority_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_LandingPagePriority" */
export type v1_LandingPagePriority_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_LandingPagePriority_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_LandingPagePriority_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type v1_LandingPagePriority_sum_fields = {
  __typename?: 'v1_LandingPagePriority_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.LandingPagePriority" */
export enum v1_LandingPagePriority_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_LandingPagePriority_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_LandingPagePriority_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_LandingPagePriority_set_input>;
  /** filter the rows which have to be updated */
  where: v1_LandingPagePriority_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_LandingPagePriority_var_pop_fields = {
  __typename?: 'v1_LandingPagePriority_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_LandingPagePriority_var_samp_fields = {
  __typename?: 'v1_LandingPagePriority_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_LandingPagePriority_variance_fields = {
  __typename?: 'v1_LandingPagePriority_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.LandingPages" */
export type v1_LandingPages = {
  __typename?: 'v1_LandingPages';
  /** An object relationship */
  CreatedBy?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  LandingPagePriority?: Maybe<v1_LandingPagePriority>;
  /** An object relationship */
  Page?: Maybe<v1_Page>;
  /** An object relationship */
  Project?: Maybe<v1_Project>;
  created_on?: Maybe<Scalars['timestamptz']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  keyword_variation?: Maybe<Scalars['String']['output']>;
  main_phrases?: Maybe<Scalars['String']['output']>;
  page_id?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
};

/** aggregated selection of "v1.LandingPages" */
export type v1_LandingPages_aggregate = {
  __typename?: 'v1_LandingPages_aggregate';
  aggregate?: Maybe<v1_LandingPages_aggregate_fields>;
  nodes: Array<v1_LandingPages>;
};

export type v1_LandingPages_aggregate_bool_exp = {
  count?: InputMaybe<v1_LandingPages_aggregate_bool_exp_count>;
};

export type v1_LandingPages_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_LandingPages_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_LandingPages_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.LandingPages" */
export type v1_LandingPages_aggregate_fields = {
  __typename?: 'v1_LandingPages_aggregate_fields';
  avg?: Maybe<v1_LandingPages_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_LandingPages_max_fields>;
  min?: Maybe<v1_LandingPages_min_fields>;
  stddev?: Maybe<v1_LandingPages_stddev_fields>;
  stddev_pop?: Maybe<v1_LandingPages_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_LandingPages_stddev_samp_fields>;
  sum?: Maybe<v1_LandingPages_sum_fields>;
  var_pop?: Maybe<v1_LandingPages_var_pop_fields>;
  var_samp?: Maybe<v1_LandingPages_var_samp_fields>;
  variance?: Maybe<v1_LandingPages_variance_fields>;
};


/** aggregate fields of "v1.LandingPages" */
export type v1_LandingPages_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_LandingPages_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.LandingPages" */
export type v1_LandingPages_aggregate_order_by = {
  avg?: InputMaybe<v1_LandingPages_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_LandingPages_max_order_by>;
  min?: InputMaybe<v1_LandingPages_min_order_by>;
  stddev?: InputMaybe<v1_LandingPages_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_LandingPages_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_LandingPages_stddev_samp_order_by>;
  sum?: InputMaybe<v1_LandingPages_sum_order_by>;
  var_pop?: InputMaybe<v1_LandingPages_var_pop_order_by>;
  var_samp?: InputMaybe<v1_LandingPages_var_samp_order_by>;
  variance?: InputMaybe<v1_LandingPages_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.LandingPages" */
export type v1_LandingPages_arr_rel_insert_input = {
  data: Array<v1_LandingPages_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_LandingPages_on_conflict>;
};

/** aggregate avg on columns */
export type v1_LandingPages_avg_fields = {
  __typename?: 'v1_LandingPages_avg_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  page_id?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.LandingPages" */
export type v1_LandingPages_avg_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.LandingPages". All fields are combined with a logical 'AND'. */
export type v1_LandingPages_bool_exp = {
  CreatedBy?: InputMaybe<v1_SystemUserBase_bool_exp>;
  LandingPagePriority?: InputMaybe<v1_LandingPagePriority_bool_exp>;
  Page?: InputMaybe<v1_Page_bool_exp>;
  Project?: InputMaybe<v1_Project_bool_exp>;
  _and?: InputMaybe<Array<v1_LandingPages_bool_exp>>;
  _not?: InputMaybe<v1_LandingPages_bool_exp>;
  _or?: InputMaybe<Array<v1_LandingPages_bool_exp>>;
  created_on?: InputMaybe<timestamptz_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  keyword_variation?: InputMaybe<String_comparison_exp>;
  main_phrases?: InputMaybe<String_comparison_exp>;
  page_id?: InputMaybe<Int_comparison_exp>;
  priority?: InputMaybe<Int_comparison_exp>;
  project_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.LandingPages" */
export enum v1_LandingPages_constraint {
  /** unique or primary key constraint on columns "id" */
  LandinPages_pkey = 'LandinPages_pkey',
  /** unique or primary key constraint on columns "project_id", "page_id" */
  lp_page_id_project_id = 'lp_page_id_project_id'
}

/** input type for incrementing numeric columns in table "v1.LandingPages" */
export type v1_LandingPages_inc_input = {
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  page_id?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.LandingPages" */
export type v1_LandingPages_insert_input = {
  CreatedBy?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  LandingPagePriority?: InputMaybe<v1_LandingPagePriority_obj_rel_insert_input>;
  Page?: InputMaybe<v1_Page_obj_rel_insert_input>;
  Project?: InputMaybe<v1_Project_obj_rel_insert_input>;
  created_on?: InputMaybe<Scalars['timestamptz']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  keyword_variation?: InputMaybe<Scalars['String']['input']>;
  main_phrases?: InputMaybe<Scalars['String']['input']>;
  page_id?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_LandingPages_max_fields = {
  __typename?: 'v1_LandingPages_max_fields';
  created_on?: Maybe<Scalars['timestamptz']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  keyword_variation?: Maybe<Scalars['String']['output']>;
  main_phrases?: Maybe<Scalars['String']['output']>;
  page_id?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.LandingPages" */
export type v1_LandingPages_max_order_by = {
  created_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  main_phrases?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_LandingPages_min_fields = {
  __typename?: 'v1_LandingPages_min_fields';
  created_on?: Maybe<Scalars['timestamptz']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  keyword_variation?: Maybe<Scalars['String']['output']>;
  main_phrases?: Maybe<Scalars['String']['output']>;
  page_id?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.LandingPages" */
export type v1_LandingPages_min_order_by = {
  created_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  main_phrases?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.LandingPages" */
export type v1_LandingPages_mutation_response = {
  __typename?: 'v1_LandingPages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_LandingPages>;
};

/** on_conflict condition type for table "v1.LandingPages" */
export type v1_LandingPages_on_conflict = {
  constraint: v1_LandingPages_constraint;
  update_columns?: Array<v1_LandingPages_update_column>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};

/** Ordering options when selecting data from "v1.LandingPages". */
export type v1_LandingPages_order_by = {
  CreatedBy?: InputMaybe<v1_SystemUserBase_order_by>;
  LandingPagePriority?: InputMaybe<v1_LandingPagePriority_order_by>;
  Page?: InputMaybe<v1_Page_order_by>;
  Project?: InputMaybe<v1_Project_order_by>;
  created_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keyword_variation?: InputMaybe<order_by>;
  main_phrases?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.LandingPages */
export type v1_LandingPages_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.LandingPages" */
export enum v1_LandingPages_select_column {
  /** column name */
  created_on = 'created_on',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  keyword_variation = 'keyword_variation',
  /** column name */
  main_phrases = 'main_phrases',
  /** column name */
  page_id = 'page_id',
  /** column name */
  priority = 'priority',
  /** column name */
  project_id = 'project_id',
  /** column name */
  statecode = 'statecode'
}

/** input type for updating data in table "v1.LandingPages" */
export type v1_LandingPages_set_input = {
  created_on?: InputMaybe<Scalars['timestamptz']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  keyword_variation?: InputMaybe<Scalars['String']['input']>;
  main_phrases?: InputMaybe<Scalars['String']['input']>;
  page_id?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_LandingPages_stddev_fields = {
  __typename?: 'v1_LandingPages_stddev_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  page_id?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.LandingPages" */
export type v1_LandingPages_stddev_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_LandingPages_stddev_pop_fields = {
  __typename?: 'v1_LandingPages_stddev_pop_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  page_id?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.LandingPages" */
export type v1_LandingPages_stddev_pop_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_LandingPages_stddev_samp_fields = {
  __typename?: 'v1_LandingPages_stddev_samp_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  page_id?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.LandingPages" */
export type v1_LandingPages_stddev_samp_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_LandingPages" */
export type v1_LandingPages_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_LandingPages_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_LandingPages_stream_cursor_value_input = {
  created_on?: InputMaybe<Scalars['timestamptz']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  keyword_variation?: InputMaybe<Scalars['String']['input']>;
  main_phrases?: InputMaybe<Scalars['String']['input']>;
  page_id?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_LandingPages_sum_fields = {
  __typename?: 'v1_LandingPages_sum_fields';
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  page_id?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.LandingPages" */
export type v1_LandingPages_sum_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** update columns of table "v1.LandingPages" */
export enum v1_LandingPages_update_column {
  /** column name */
  created_on = 'created_on',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  keyword_variation = 'keyword_variation',
  /** column name */
  main_phrases = 'main_phrases',
  /** column name */
  page_id = 'page_id',
  /** column name */
  priority = 'priority',
  /** column name */
  project_id = 'project_id',
  /** column name */
  statecode = 'statecode'
}

export type v1_LandingPages_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_LandingPages_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_LandingPages_set_input>;
  /** filter the rows which have to be updated */
  where: v1_LandingPages_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_LandingPages_var_pop_fields = {
  __typename?: 'v1_LandingPages_var_pop_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  page_id?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.LandingPages" */
export type v1_LandingPages_var_pop_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_LandingPages_var_samp_fields = {
  __typename?: 'v1_LandingPages_var_samp_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  page_id?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.LandingPages" */
export type v1_LandingPages_var_samp_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_LandingPages_variance_fields = {
  __typename?: 'v1_LandingPages_variance_fields';
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  page_id?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.LandingPages" */
export type v1_LandingPages_variance_order_by = {
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  page_id?: InputMaybe<order_by>;
  priority?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
};

/** Using by MP Checkout */
export type v1_LanguagePriceStep = {
  __typename?: 'v1_LanguagePriceStep';
  id: Scalars['Int']['output'];
  language_id: Scalars['Int']['output'];
  price: Scalars['numeric']['output'];
  step: Scalars['numeric']['output'];
};

/** aggregated selection of "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_aggregate = {
  __typename?: 'v1_LanguagePriceStep_aggregate';
  aggregate?: Maybe<v1_LanguagePriceStep_aggregate_fields>;
  nodes: Array<v1_LanguagePriceStep>;
};

export type v1_LanguagePriceStep_aggregate_bool_exp = {
  count?: InputMaybe<v1_LanguagePriceStep_aggregate_bool_exp_count>;
};

export type v1_LanguagePriceStep_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_LanguagePriceStep_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_aggregate_fields = {
  __typename?: 'v1_LanguagePriceStep_aggregate_fields';
  avg?: Maybe<v1_LanguagePriceStep_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_LanguagePriceStep_max_fields>;
  min?: Maybe<v1_LanguagePriceStep_min_fields>;
  stddev?: Maybe<v1_LanguagePriceStep_stddev_fields>;
  stddev_pop?: Maybe<v1_LanguagePriceStep_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_LanguagePriceStep_stddev_samp_fields>;
  sum?: Maybe<v1_LanguagePriceStep_sum_fields>;
  var_pop?: Maybe<v1_LanguagePriceStep_var_pop_fields>;
  var_samp?: Maybe<v1_LanguagePriceStep_var_samp_fields>;
  variance?: Maybe<v1_LanguagePriceStep_variance_fields>;
};


/** aggregate fields of "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_LanguagePriceStep_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_aggregate_order_by = {
  avg?: InputMaybe<v1_LanguagePriceStep_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_LanguagePriceStep_max_order_by>;
  min?: InputMaybe<v1_LanguagePriceStep_min_order_by>;
  stddev?: InputMaybe<v1_LanguagePriceStep_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_LanguagePriceStep_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_LanguagePriceStep_stddev_samp_order_by>;
  sum?: InputMaybe<v1_LanguagePriceStep_sum_order_by>;
  var_pop?: InputMaybe<v1_LanguagePriceStep_var_pop_order_by>;
  var_samp?: InputMaybe<v1_LanguagePriceStep_var_samp_order_by>;
  variance?: InputMaybe<v1_LanguagePriceStep_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_arr_rel_insert_input = {
  data: Array<v1_LanguagePriceStep_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_LanguagePriceStep_on_conflict>;
};

/** aggregate avg on columns */
export type v1_LanguagePriceStep_avg_fields = {
  __typename?: 'v1_LanguagePriceStep_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_avg_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.LanguagePriceStep". All fields are combined with a logical 'AND'. */
export type v1_LanguagePriceStep_bool_exp = {
  _and?: InputMaybe<Array<v1_LanguagePriceStep_bool_exp>>;
  _not?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
  _or?: InputMaybe<Array<v1_LanguagePriceStep_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  language_id?: InputMaybe<Int_comparison_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  step?: InputMaybe<numeric_comparison_exp>;
};

/** unique or primary key constraints on table "v1.LanguagePriceStep" */
export enum v1_LanguagePriceStep_constraint {
  /** unique or primary key constraint on columns "id" */
  LanguagePriceStep_pkey = 'LanguagePriceStep_pkey'
}

/** input type for incrementing numeric columns in table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  step?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  step?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type v1_LanguagePriceStep_max_fields = {
  __typename?: 'v1_LanguagePriceStep_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  step?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_max_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_LanguagePriceStep_min_fields = {
  __typename?: 'v1_LanguagePriceStep_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  step?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_min_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_mutation_response = {
  __typename?: 'v1_LanguagePriceStep_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_LanguagePriceStep>;
};

/** input type for inserting object relation for remote table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_obj_rel_insert_input = {
  data: v1_LanguagePriceStep_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_LanguagePriceStep_on_conflict>;
};

/** on_conflict condition type for table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_on_conflict = {
  constraint: v1_LanguagePriceStep_constraint;
  update_columns?: Array<v1_LanguagePriceStep_update_column>;
  where?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
};

/** Ordering options when selecting data from "v1.LanguagePriceStep". */
export type v1_LanguagePriceStep_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.LanguagePriceStep */
export type v1_LanguagePriceStep_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.LanguagePriceStep" */
export enum v1_LanguagePriceStep_select_column {
  /** column name */
  id = 'id',
  /** column name */
  language_id = 'language_id',
  /** column name */
  price = 'price',
  /** column name */
  step = 'step'
}

/** input type for updating data in table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  step?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type v1_LanguagePriceStep_stddev_fields = {
  __typename?: 'v1_LanguagePriceStep_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_stddev_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_LanguagePriceStep_stddev_pop_fields = {
  __typename?: 'v1_LanguagePriceStep_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_LanguagePriceStep_stddev_samp_fields = {
  __typename?: 'v1_LanguagePriceStep_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_LanguagePriceStep" */
export type v1_LanguagePriceStep_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_LanguagePriceStep_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_LanguagePriceStep_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  step?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type v1_LanguagePriceStep_sum_fields = {
  __typename?: 'v1_LanguagePriceStep_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  step?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_sum_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** update columns of table "v1.LanguagePriceStep" */
export enum v1_LanguagePriceStep_update_column {
  /** column name */
  id = 'id',
  /** column name */
  language_id = 'language_id',
  /** column name */
  price = 'price',
  /** column name */
  step = 'step'
}

export type v1_LanguagePriceStep_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_LanguagePriceStep_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_LanguagePriceStep_set_input>;
  /** filter the rows which have to be updated */
  where: v1_LanguagePriceStep_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_LanguagePriceStep_var_pop_fields = {
  __typename?: 'v1_LanguagePriceStep_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_LanguagePriceStep_var_samp_fields = {
  __typename?: 'v1_LanguagePriceStep_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_LanguagePriceStep_variance_fields = {
  __typename?: 'v1_LanguagePriceStep_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  step?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.LanguagePriceStep" */
export type v1_LanguagePriceStep_variance_order_by = {
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  step?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories = {
  __typename?: 'v1_MarketPlaceCategories';
  CategoryName: Scalars['String']['output'];
  /** An object relationship */
  MarketPlaceCategoriesType: v1_MarketPlaceCategoriesTypes;
  /** An array relationship */
  MarketPlaceCategories_Offers: Array<v1_MarketPlaceCategories_Offers>;
  /** An aggregate relationship */
  MarketPlaceCategories_Offers_aggregate: v1_MarketPlaceCategories_Offers_aggregate;
  ParentId?: Maybe<Scalars['Int']['output']>;
  TypeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategoriesMarketPlaceCategories_OffersArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategoriesMarketPlaceCategories_Offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};

/** columns and relationships of "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes = {
  __typename?: 'v1_MarketPlaceCategoriesTypes';
  /** An array relationship */
  MarketPlaceCategories: Array<v1_MarketPlaceCategories>;
  /** An aggregate relationship */
  MarketPlaceCategories_aggregate: v1_MarketPlaceCategories_aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


/** columns and relationships of "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypesMarketPlaceCategoriesArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypesMarketPlaceCategories_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
};

/** aggregated selection of "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_aggregate = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_aggregate';
  aggregate?: Maybe<v1_MarketPlaceCategoriesTypes_aggregate_fields>;
  nodes: Array<v1_MarketPlaceCategoriesTypes>;
};

/** aggregate fields of "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_aggregate_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_aggregate_fields';
  avg?: Maybe<v1_MarketPlaceCategoriesTypes_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketPlaceCategoriesTypes_max_fields>;
  min?: Maybe<v1_MarketPlaceCategoriesTypes_min_fields>;
  stddev?: Maybe<v1_MarketPlaceCategoriesTypes_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketPlaceCategoriesTypes_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketPlaceCategoriesTypes_stddev_samp_fields>;
  sum?: Maybe<v1_MarketPlaceCategoriesTypes_sum_fields>;
  var_pop?: Maybe<v1_MarketPlaceCategoriesTypes_var_pop_fields>;
  var_samp?: Maybe<v1_MarketPlaceCategoriesTypes_var_samp_fields>;
  variance?: Maybe<v1_MarketPlaceCategoriesTypes_variance_fields>;
};


/** aggregate fields of "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_MarketPlaceCategoriesTypes_avg_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.MarketPlaceCategoriesTypes". All fields are combined with a logical 'AND'. */
export type v1_MarketPlaceCategoriesTypes_bool_exp = {
  MarketPlaceCategories?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
  MarketPlaceCategories_aggregate?: InputMaybe<v1_MarketPlaceCategories_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_bool_exp>>;
  _not?: InputMaybe<v1_MarketPlaceCategoriesTypes_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketPlaceCategoriesTypes_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketPlaceCategoriesTypes" */
export enum v1_MarketPlaceCategoriesTypes_constraint {
  /** unique or primary key constraint on columns "name" */
  MarketPlaceCategoriesTypes_name_key = 'MarketPlaceCategoriesTypes_name_key',
  /** unique or primary key constraint on columns "id" */
  MarketPlaceCategoriesTypes_pkey = 'MarketPlaceCategoriesTypes_pkey'
}

/** input type for incrementing numeric columns in table "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_insert_input = {
  MarketPlaceCategories?: InputMaybe<v1_MarketPlaceCategories_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_MarketPlaceCategoriesTypes_max_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_MarketPlaceCategoriesTypes_min_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_mutation_response = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketPlaceCategoriesTypes>;
};

/** input type for inserting object relation for remote table "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_obj_rel_insert_input = {
  data: v1_MarketPlaceCategoriesTypes_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceCategoriesTypes_on_conflict>;
};

/** on_conflict condition type for table "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_on_conflict = {
  constraint: v1_MarketPlaceCategoriesTypes_constraint;
  update_columns?: Array<v1_MarketPlaceCategoriesTypes_update_column>;
  where?: InputMaybe<v1_MarketPlaceCategoriesTypes_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketPlaceCategoriesTypes". */
export type v1_MarketPlaceCategoriesTypes_order_by = {
  MarketPlaceCategories_aggregate?: InputMaybe<v1_MarketPlaceCategories_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketPlaceCategoriesTypes */
export type v1_MarketPlaceCategoriesTypes_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketPlaceCategoriesTypes" */
export enum v1_MarketPlaceCategoriesTypes_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketPlaceCategoriesTypes_stddev_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketPlaceCategoriesTypes_stddev_pop_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketPlaceCategoriesTypes_stddev_samp_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_MarketPlaceCategoriesTypes" */
export type v1_MarketPlaceCategoriesTypes_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketPlaceCategoriesTypes_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketPlaceCategoriesTypes_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketPlaceCategoriesTypes_sum_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.MarketPlaceCategoriesTypes" */
export enum v1_MarketPlaceCategoriesTypes_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_MarketPlaceCategoriesTypes_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketPlaceCategoriesTypes_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketPlaceCategoriesTypes_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketPlaceCategoriesTypes_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketPlaceCategoriesTypes_var_pop_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_MarketPlaceCategoriesTypes_var_samp_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_MarketPlaceCategoriesTypes_variance_fields = {
  __typename?: 'v1_MarketPlaceCategoriesTypes_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers = {
  __typename?: 'v1_MarketPlaceCategories_Offers';
  /** An object relationship */
  MarketPlaceCategories: v1_MarketPlaceCategories;
  /** An object relationship */
  Offers: v1_Offer;
  id: Scalars['Int']['output'];
  marketplace_category_id: Scalars['Int']['output'];
  offer_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_aggregate = {
  __typename?: 'v1_MarketPlaceCategories_Offers_aggregate';
  aggregate?: Maybe<v1_MarketPlaceCategories_Offers_aggregate_fields>;
  nodes: Array<v1_MarketPlaceCategories_Offers>;
};

export type v1_MarketPlaceCategories_Offers_aggregate_bool_exp = {
  count?: InputMaybe<v1_MarketPlaceCategories_Offers_aggregate_bool_exp_count>;
};

export type v1_MarketPlaceCategories_Offers_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_aggregate_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_aggregate_fields';
  avg?: Maybe<v1_MarketPlaceCategories_Offers_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketPlaceCategories_Offers_max_fields>;
  min?: Maybe<v1_MarketPlaceCategories_Offers_min_fields>;
  stddev?: Maybe<v1_MarketPlaceCategories_Offers_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketPlaceCategories_Offers_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketPlaceCategories_Offers_stddev_samp_fields>;
  sum?: Maybe<v1_MarketPlaceCategories_Offers_sum_fields>;
  var_pop?: Maybe<v1_MarketPlaceCategories_Offers_var_pop_fields>;
  var_samp?: Maybe<v1_MarketPlaceCategories_Offers_var_samp_fields>;
  variance?: Maybe<v1_MarketPlaceCategories_Offers_variance_fields>;
};


/** aggregate fields of "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_aggregate_order_by = {
  avg?: InputMaybe<v1_MarketPlaceCategories_Offers_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_MarketPlaceCategories_Offers_max_order_by>;
  min?: InputMaybe<v1_MarketPlaceCategories_Offers_min_order_by>;
  stddev?: InputMaybe<v1_MarketPlaceCategories_Offers_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_MarketPlaceCategories_Offers_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_MarketPlaceCategories_Offers_stddev_samp_order_by>;
  sum?: InputMaybe<v1_MarketPlaceCategories_Offers_sum_order_by>;
  var_pop?: InputMaybe<v1_MarketPlaceCategories_Offers_var_pop_order_by>;
  var_samp?: InputMaybe<v1_MarketPlaceCategories_Offers_var_samp_order_by>;
  variance?: InputMaybe<v1_MarketPlaceCategories_Offers_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_arr_rel_insert_input = {
  data: Array<v1_MarketPlaceCategories_Offers_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceCategories_Offers_on_conflict>;
};

/** aggregate avg on columns */
export type v1_MarketPlaceCategories_Offers_avg_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marketplace_category_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_avg_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.MarketPlaceCategories_Offers". All fields are combined with a logical 'AND'. */
export type v1_MarketPlaceCategories_Offers_bool_exp = {
  MarketPlaceCategories?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  _and?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_bool_exp>>;
  _not?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  marketplace_category_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketPlaceCategories_Offers" */
export enum v1_MarketPlaceCategories_Offers_constraint {
  /** unique or primary key constraint on columns "id" */
  MarketPlaceCategories_Offers_pkey = 'MarketPlaceCategories_Offers_pkey'
}

/** input type for incrementing numeric columns in table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  marketplace_category_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_insert_input = {
  MarketPlaceCategories?: InputMaybe<v1_MarketPlaceCategories_obj_rel_insert_input>;
  Offers?: InputMaybe<v1_Offer_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  marketplace_category_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_MarketPlaceCategories_Offers_max_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  marketplace_category_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_max_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_MarketPlaceCategories_Offers_min_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  marketplace_category_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_min_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_mutation_response = {
  __typename?: 'v1_MarketPlaceCategories_Offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketPlaceCategories_Offers>;
};

/** on_conflict condition type for table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_on_conflict = {
  constraint: v1_MarketPlaceCategories_Offers_constraint;
  update_columns?: Array<v1_MarketPlaceCategories_Offers_update_column>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketPlaceCategories_Offers". */
export type v1_MarketPlaceCategories_Offers_order_by = {
  MarketPlaceCategories?: InputMaybe<v1_MarketPlaceCategories_order_by>;
  Offers?: InputMaybe<v1_Offer_order_by>;
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketPlaceCategories_Offers */
export type v1_MarketPlaceCategories_Offers_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketPlaceCategories_Offers" */
export enum v1_MarketPlaceCategories_Offers_select_column {
  /** column name */
  id = 'id',
  /** column name */
  marketplace_category_id = 'marketplace_category_id',
  /** column name */
  offer_id = 'offer_id'
}

/** input type for updating data in table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  marketplace_category_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketPlaceCategories_Offers_stddev_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marketplace_category_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_stddev_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketPlaceCategories_Offers_stddev_pop_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marketplace_category_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketPlaceCategories_Offers_stddev_samp_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marketplace_category_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketPlaceCategories_Offers_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketPlaceCategories_Offers_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  marketplace_category_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketPlaceCategories_Offers_sum_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  marketplace_category_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_sum_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.MarketPlaceCategories_Offers" */
export enum v1_MarketPlaceCategories_Offers_update_column {
  /** column name */
  id = 'id',
  /** column name */
  marketplace_category_id = 'marketplace_category_id',
  /** column name */
  offer_id = 'offer_id'
}

export type v1_MarketPlaceCategories_Offers_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketPlaceCategories_Offers_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketPlaceCategories_Offers_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketPlaceCategories_Offers_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketPlaceCategories_Offers_var_pop_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marketplace_category_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_MarketPlaceCategories_Offers_var_samp_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marketplace_category_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_MarketPlaceCategories_Offers_variance_fields = {
  __typename?: 'v1_MarketPlaceCategories_Offers_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  marketplace_category_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.MarketPlaceCategories_Offers" */
export type v1_MarketPlaceCategories_Offers_variance_order_by = {
  id?: InputMaybe<order_by>;
  marketplace_category_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregated selection of "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_aggregate = {
  __typename?: 'v1_MarketPlaceCategories_aggregate';
  aggregate?: Maybe<v1_MarketPlaceCategories_aggregate_fields>;
  nodes: Array<v1_MarketPlaceCategories>;
};

export type v1_MarketPlaceCategories_aggregate_bool_exp = {
  count?: InputMaybe<v1_MarketPlaceCategories_aggregate_bool_exp_count>;
};

export type v1_MarketPlaceCategories_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_MarketPlaceCategories_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_aggregate_fields = {
  __typename?: 'v1_MarketPlaceCategories_aggregate_fields';
  avg?: Maybe<v1_MarketPlaceCategories_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketPlaceCategories_max_fields>;
  min?: Maybe<v1_MarketPlaceCategories_min_fields>;
  stddev?: Maybe<v1_MarketPlaceCategories_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketPlaceCategories_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketPlaceCategories_stddev_samp_fields>;
  sum?: Maybe<v1_MarketPlaceCategories_sum_fields>;
  var_pop?: Maybe<v1_MarketPlaceCategories_var_pop_fields>;
  var_samp?: Maybe<v1_MarketPlaceCategories_var_samp_fields>;
  variance?: Maybe<v1_MarketPlaceCategories_variance_fields>;
};


/** aggregate fields of "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketPlaceCategories_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_aggregate_order_by = {
  avg?: InputMaybe<v1_MarketPlaceCategories_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_MarketPlaceCategories_max_order_by>;
  min?: InputMaybe<v1_MarketPlaceCategories_min_order_by>;
  stddev?: InputMaybe<v1_MarketPlaceCategories_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_MarketPlaceCategories_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_MarketPlaceCategories_stddev_samp_order_by>;
  sum?: InputMaybe<v1_MarketPlaceCategories_sum_order_by>;
  var_pop?: InputMaybe<v1_MarketPlaceCategories_var_pop_order_by>;
  var_samp?: InputMaybe<v1_MarketPlaceCategories_var_samp_order_by>;
  variance?: InputMaybe<v1_MarketPlaceCategories_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_arr_rel_insert_input = {
  data: Array<v1_MarketPlaceCategories_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceCategories_on_conflict>;
};

/** aggregate avg on columns */
export type v1_MarketPlaceCategories_avg_fields = {
  __typename?: 'v1_MarketPlaceCategories_avg_fields';
  ParentId?: Maybe<Scalars['Float']['output']>;
  TypeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_avg_order_by = {
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.MarketPlaceCategories". All fields are combined with a logical 'AND'. */
export type v1_MarketPlaceCategories_bool_exp = {
  CategoryName?: InputMaybe<String_comparison_exp>;
  MarketPlaceCategoriesType?: InputMaybe<v1_MarketPlaceCategoriesTypes_bool_exp>;
  MarketPlaceCategories_Offers?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
  MarketPlaceCategories_Offers_aggregate?: InputMaybe<v1_MarketPlaceCategories_Offers_aggregate_bool_exp>;
  ParentId?: InputMaybe<Int_comparison_exp>;
  TypeId?: InputMaybe<Int_comparison_exp>;
  _and?: InputMaybe<Array<v1_MarketPlaceCategories_bool_exp>>;
  _not?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketPlaceCategories_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketPlaceCategories" */
export enum v1_MarketPlaceCategories_constraint {
  /** unique or primary key constraint on columns "CategoryName" */
  MarketPlaceCategories_CategoryName_key = 'MarketPlaceCategories_CategoryName_key',
  /** unique or primary key constraint on columns "id" */
  MarketPlaceCategories_pkey = 'MarketPlaceCategories_pkey'
}

/** input type for incrementing numeric columns in table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_inc_input = {
  ParentId?: InputMaybe<Scalars['Int']['input']>;
  TypeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_insert_input = {
  CategoryName?: InputMaybe<Scalars['String']['input']>;
  MarketPlaceCategoriesType?: InputMaybe<v1_MarketPlaceCategoriesTypes_obj_rel_insert_input>;
  MarketPlaceCategories_Offers?: InputMaybe<v1_MarketPlaceCategories_Offers_arr_rel_insert_input>;
  ParentId?: InputMaybe<Scalars['Int']['input']>;
  TypeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_MarketPlaceCategories_max_fields = {
  __typename?: 'v1_MarketPlaceCategories_max_fields';
  CategoryName?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['Int']['output']>;
  TypeId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_max_order_by = {
  CategoryName?: InputMaybe<order_by>;
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_MarketPlaceCategories_min_fields = {
  __typename?: 'v1_MarketPlaceCategories_min_fields';
  CategoryName?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['Int']['output']>;
  TypeId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_min_order_by = {
  CategoryName?: InputMaybe<order_by>;
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_mutation_response = {
  __typename?: 'v1_MarketPlaceCategories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketPlaceCategories>;
};

/** input type for inserting object relation for remote table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_obj_rel_insert_input = {
  data: v1_MarketPlaceCategories_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceCategories_on_conflict>;
};

/** on_conflict condition type for table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_on_conflict = {
  constraint: v1_MarketPlaceCategories_constraint;
  update_columns?: Array<v1_MarketPlaceCategories_update_column>;
  where?: InputMaybe<v1_MarketPlaceCategories_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketPlaceCategories". */
export type v1_MarketPlaceCategories_order_by = {
  CategoryName?: InputMaybe<order_by>;
  MarketPlaceCategoriesType?: InputMaybe<v1_MarketPlaceCategoriesTypes_order_by>;
  MarketPlaceCategories_Offers_aggregate?: InputMaybe<v1_MarketPlaceCategories_Offers_aggregate_order_by>;
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketPlaceCategories */
export type v1_MarketPlaceCategories_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketPlaceCategories" */
export enum v1_MarketPlaceCategories_select_column {
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_set_input = {
  CategoryName?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['Int']['input']>;
  TypeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketPlaceCategories_stddev_fields = {
  __typename?: 'v1_MarketPlaceCategories_stddev_fields';
  ParentId?: Maybe<Scalars['Float']['output']>;
  TypeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_stddev_order_by = {
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketPlaceCategories_stddev_pop_fields = {
  __typename?: 'v1_MarketPlaceCategories_stddev_pop_fields';
  ParentId?: Maybe<Scalars['Float']['output']>;
  TypeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_stddev_pop_order_by = {
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketPlaceCategories_stddev_samp_fields = {
  __typename?: 'v1_MarketPlaceCategories_stddev_samp_fields';
  ParentId?: Maybe<Scalars['Float']['output']>;
  TypeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_stddev_samp_order_by = {
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_MarketPlaceCategories" */
export type v1_MarketPlaceCategories_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketPlaceCategories_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketPlaceCategories_stream_cursor_value_input = {
  CategoryName?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['Int']['input']>;
  TypeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketPlaceCategories_sum_fields = {
  __typename?: 'v1_MarketPlaceCategories_sum_fields';
  ParentId?: Maybe<Scalars['Int']['output']>;
  TypeId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_sum_order_by = {
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.MarketPlaceCategories" */
export enum v1_MarketPlaceCategories_update_column {
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  id = 'id'
}

export type v1_MarketPlaceCategories_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketPlaceCategories_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketPlaceCategories_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketPlaceCategories_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketPlaceCategories_var_pop_fields = {
  __typename?: 'v1_MarketPlaceCategories_var_pop_fields';
  ParentId?: Maybe<Scalars['Float']['output']>;
  TypeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_var_pop_order_by = {
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_MarketPlaceCategories_var_samp_fields = {
  __typename?: 'v1_MarketPlaceCategories_var_samp_fields';
  ParentId?: Maybe<Scalars['Float']['output']>;
  TypeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_var_samp_order_by = {
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_MarketPlaceCategories_variance_fields = {
  __typename?: 'v1_MarketPlaceCategories_variance_fields';
  ParentId?: Maybe<Scalars['Float']['output']>;
  TypeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.MarketPlaceCategories" */
export type v1_MarketPlaceCategories_variance_order_by = {
  ParentId?: InputMaybe<order_by>;
  TypeId?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche = {
  __typename?: 'v1_MarketPlaceOfferNiche';
  AdultMultiplier?: Maybe<Scalars['numeric']['output']>;
  CBDMultiply?: Maybe<Scalars['numeric']['output']>;
  CasinoMultiply?: Maybe<Scalars['numeric']['output']>;
  CryptoMultiply?: Maybe<Scalars['numeric']['output']>;
  DatingMultiply?: Maybe<Scalars['numeric']['output']>;
  FinanceMultiply?: Maybe<Scalars['numeric']['output']>;
  MedicalMultiply?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  id: Scalars['Int']['output'];
  isAdultAccepted?: Maybe<Scalars['Boolean']['output']>;
  isCBDAccepted?: Maybe<Scalars['Boolean']['output']>;
  isCasinoAccepted?: Maybe<Scalars['Boolean']['output']>;
  isCryptoAccepted?: Maybe<Scalars['Boolean']['output']>;
  isDatingAccepted?: Maybe<Scalars['Boolean']['output']>;
  isFinanceAccepted?: Maybe<Scalars['Boolean']['output']>;
  isMedicalAccepted?: Maybe<Scalars['Boolean']['output']>;
};


/** columns and relationships of "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNicheOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNicheOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};

/** aggregated selection of "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_aggregate = {
  __typename?: 'v1_MarketPlaceOfferNiche_aggregate';
  aggregate?: Maybe<v1_MarketPlaceOfferNiche_aggregate_fields>;
  nodes: Array<v1_MarketPlaceOfferNiche>;
};

/** aggregate fields of "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_aggregate_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_aggregate_fields';
  avg?: Maybe<v1_MarketPlaceOfferNiche_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketPlaceOfferNiche_max_fields>;
  min?: Maybe<v1_MarketPlaceOfferNiche_min_fields>;
  stddev?: Maybe<v1_MarketPlaceOfferNiche_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketPlaceOfferNiche_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketPlaceOfferNiche_stddev_samp_fields>;
  sum?: Maybe<v1_MarketPlaceOfferNiche_sum_fields>;
  var_pop?: Maybe<v1_MarketPlaceOfferNiche_var_pop_fields>;
  var_samp?: Maybe<v1_MarketPlaceOfferNiche_var_samp_fields>;
  variance?: Maybe<v1_MarketPlaceOfferNiche_variance_fields>;
};


/** aggregate fields of "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketPlaceOfferNiche_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_MarketPlaceOfferNiche_avg_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_avg_fields';
  AdultMultiplier?: Maybe<Scalars['Float']['output']>;
  CBDMultiply?: Maybe<Scalars['Float']['output']>;
  CasinoMultiply?: Maybe<Scalars['Float']['output']>;
  CryptoMultiply?: Maybe<Scalars['Float']['output']>;
  DatingMultiply?: Maybe<Scalars['Float']['output']>;
  FinanceMultiply?: Maybe<Scalars['Float']['output']>;
  MedicalMultiply?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.MarketPlaceOfferNiche". All fields are combined with a logical 'AND'. */
export type v1_MarketPlaceOfferNiche_bool_exp = {
  AdultMultiplier?: InputMaybe<numeric_comparison_exp>;
  CBDMultiply?: InputMaybe<numeric_comparison_exp>;
  CasinoMultiply?: InputMaybe<numeric_comparison_exp>;
  CryptoMultiply?: InputMaybe<numeric_comparison_exp>;
  DatingMultiply?: InputMaybe<numeric_comparison_exp>;
  FinanceMultiply?: InputMaybe<numeric_comparison_exp>;
  MedicalMultiply?: InputMaybe<numeric_comparison_exp>;
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_MarketPlaceOfferNiche_bool_exp>>;
  _not?: InputMaybe<v1_MarketPlaceOfferNiche_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketPlaceOfferNiche_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  isAdultAccepted?: InputMaybe<Boolean_comparison_exp>;
  isCBDAccepted?: InputMaybe<Boolean_comparison_exp>;
  isCasinoAccepted?: InputMaybe<Boolean_comparison_exp>;
  isCryptoAccepted?: InputMaybe<Boolean_comparison_exp>;
  isDatingAccepted?: InputMaybe<Boolean_comparison_exp>;
  isFinanceAccepted?: InputMaybe<Boolean_comparison_exp>;
  isMedicalAccepted?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketPlaceOfferNiche" */
export enum v1_MarketPlaceOfferNiche_constraint {
  /** unique or primary key constraint on columns "id" */
  MarketPlaceOrderNiche_pkey = 'MarketPlaceOrderNiche_pkey'
}

/** input type for incrementing numeric columns in table "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_inc_input = {
  AdultMultiplier?: InputMaybe<Scalars['numeric']['input']>;
  CBDMultiply?: InputMaybe<Scalars['numeric']['input']>;
  CasinoMultiply?: InputMaybe<Scalars['numeric']['input']>;
  CryptoMultiply?: InputMaybe<Scalars['numeric']['input']>;
  DatingMultiply?: InputMaybe<Scalars['numeric']['input']>;
  FinanceMultiply?: InputMaybe<Scalars['numeric']['input']>;
  MedicalMultiply?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_insert_input = {
  AdultMultiplier?: InputMaybe<Scalars['numeric']['input']>;
  CBDMultiply?: InputMaybe<Scalars['numeric']['input']>;
  CasinoMultiply?: InputMaybe<Scalars['numeric']['input']>;
  CryptoMultiply?: InputMaybe<Scalars['numeric']['input']>;
  DatingMultiply?: InputMaybe<Scalars['numeric']['input']>;
  FinanceMultiply?: InputMaybe<Scalars['numeric']['input']>;
  MedicalMultiply?: InputMaybe<Scalars['numeric']['input']>;
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isAdultAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isCBDAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isCasinoAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isCryptoAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isDatingAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isFinanceAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isMedicalAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type v1_MarketPlaceOfferNiche_max_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_max_fields';
  AdultMultiplier?: Maybe<Scalars['numeric']['output']>;
  CBDMultiply?: Maybe<Scalars['numeric']['output']>;
  CasinoMultiply?: Maybe<Scalars['numeric']['output']>;
  CryptoMultiply?: Maybe<Scalars['numeric']['output']>;
  DatingMultiply?: Maybe<Scalars['numeric']['output']>;
  FinanceMultiply?: Maybe<Scalars['numeric']['output']>;
  MedicalMultiply?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_MarketPlaceOfferNiche_min_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_min_fields';
  AdultMultiplier?: Maybe<Scalars['numeric']['output']>;
  CBDMultiply?: Maybe<Scalars['numeric']['output']>;
  CasinoMultiply?: Maybe<Scalars['numeric']['output']>;
  CryptoMultiply?: Maybe<Scalars['numeric']['output']>;
  DatingMultiply?: Maybe<Scalars['numeric']['output']>;
  FinanceMultiply?: Maybe<Scalars['numeric']['output']>;
  MedicalMultiply?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_mutation_response = {
  __typename?: 'v1_MarketPlaceOfferNiche_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketPlaceOfferNiche>;
};

/** input type for inserting object relation for remote table "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_obj_rel_insert_input = {
  data: v1_MarketPlaceOfferNiche_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceOfferNiche_on_conflict>;
};

/** on_conflict condition type for table "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_on_conflict = {
  constraint: v1_MarketPlaceOfferNiche_constraint;
  update_columns?: Array<v1_MarketPlaceOfferNiche_update_column>;
  where?: InputMaybe<v1_MarketPlaceOfferNiche_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketPlaceOfferNiche". */
export type v1_MarketPlaceOfferNiche_order_by = {
  AdultMultiplier?: InputMaybe<order_by>;
  CBDMultiply?: InputMaybe<order_by>;
  CasinoMultiply?: InputMaybe<order_by>;
  CryptoMultiply?: InputMaybe<order_by>;
  DatingMultiply?: InputMaybe<order_by>;
  FinanceMultiply?: InputMaybe<order_by>;
  MedicalMultiply?: InputMaybe<order_by>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  isAdultAccepted?: InputMaybe<order_by>;
  isCBDAccepted?: InputMaybe<order_by>;
  isCasinoAccepted?: InputMaybe<order_by>;
  isCryptoAccepted?: InputMaybe<order_by>;
  isDatingAccepted?: InputMaybe<order_by>;
  isFinanceAccepted?: InputMaybe<order_by>;
  isMedicalAccepted?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketPlaceOfferNiche */
export type v1_MarketPlaceOfferNiche_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketPlaceOfferNiche" */
export enum v1_MarketPlaceOfferNiche_select_column {
  /** column name */
  AdultMultiplier = 'AdultMultiplier',
  /** column name */
  CBDMultiply = 'CBDMultiply',
  /** column name */
  CasinoMultiply = 'CasinoMultiply',
  /** column name */
  CryptoMultiply = 'CryptoMultiply',
  /** column name */
  DatingMultiply = 'DatingMultiply',
  /** column name */
  FinanceMultiply = 'FinanceMultiply',
  /** column name */
  MedicalMultiply = 'MedicalMultiply',
  /** column name */
  id = 'id',
  /** column name */
  isAdultAccepted = 'isAdultAccepted',
  /** column name */
  isCBDAccepted = 'isCBDAccepted',
  /** column name */
  isCasinoAccepted = 'isCasinoAccepted',
  /** column name */
  isCryptoAccepted = 'isCryptoAccepted',
  /** column name */
  isDatingAccepted = 'isDatingAccepted',
  /** column name */
  isFinanceAccepted = 'isFinanceAccepted',
  /** column name */
  isMedicalAccepted = 'isMedicalAccepted'
}

/** input type for updating data in table "v1.MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_set_input = {
  AdultMultiplier?: InputMaybe<Scalars['numeric']['input']>;
  CBDMultiply?: InputMaybe<Scalars['numeric']['input']>;
  CasinoMultiply?: InputMaybe<Scalars['numeric']['input']>;
  CryptoMultiply?: InputMaybe<Scalars['numeric']['input']>;
  DatingMultiply?: InputMaybe<Scalars['numeric']['input']>;
  FinanceMultiply?: InputMaybe<Scalars['numeric']['input']>;
  MedicalMultiply?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isAdultAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isCBDAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isCasinoAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isCryptoAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isDatingAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isFinanceAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isMedicalAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketPlaceOfferNiche_stddev_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_stddev_fields';
  AdultMultiplier?: Maybe<Scalars['Float']['output']>;
  CBDMultiply?: Maybe<Scalars['Float']['output']>;
  CasinoMultiply?: Maybe<Scalars['Float']['output']>;
  CryptoMultiply?: Maybe<Scalars['Float']['output']>;
  DatingMultiply?: Maybe<Scalars['Float']['output']>;
  FinanceMultiply?: Maybe<Scalars['Float']['output']>;
  MedicalMultiply?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketPlaceOfferNiche_stddev_pop_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_stddev_pop_fields';
  AdultMultiplier?: Maybe<Scalars['Float']['output']>;
  CBDMultiply?: Maybe<Scalars['Float']['output']>;
  CasinoMultiply?: Maybe<Scalars['Float']['output']>;
  CryptoMultiply?: Maybe<Scalars['Float']['output']>;
  DatingMultiply?: Maybe<Scalars['Float']['output']>;
  FinanceMultiply?: Maybe<Scalars['Float']['output']>;
  MedicalMultiply?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketPlaceOfferNiche_stddev_samp_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_stddev_samp_fields';
  AdultMultiplier?: Maybe<Scalars['Float']['output']>;
  CBDMultiply?: Maybe<Scalars['Float']['output']>;
  CasinoMultiply?: Maybe<Scalars['Float']['output']>;
  CryptoMultiply?: Maybe<Scalars['Float']['output']>;
  DatingMultiply?: Maybe<Scalars['Float']['output']>;
  FinanceMultiply?: Maybe<Scalars['Float']['output']>;
  MedicalMultiply?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_MarketPlaceOfferNiche" */
export type v1_MarketPlaceOfferNiche_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketPlaceOfferNiche_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketPlaceOfferNiche_stream_cursor_value_input = {
  AdultMultiplier?: InputMaybe<Scalars['numeric']['input']>;
  CBDMultiply?: InputMaybe<Scalars['numeric']['input']>;
  CasinoMultiply?: InputMaybe<Scalars['numeric']['input']>;
  CryptoMultiply?: InputMaybe<Scalars['numeric']['input']>;
  DatingMultiply?: InputMaybe<Scalars['numeric']['input']>;
  FinanceMultiply?: InputMaybe<Scalars['numeric']['input']>;
  MedicalMultiply?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isAdultAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isCBDAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isCasinoAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isCryptoAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isDatingAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isFinanceAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  isMedicalAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketPlaceOfferNiche_sum_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_sum_fields';
  AdultMultiplier?: Maybe<Scalars['numeric']['output']>;
  CBDMultiply?: Maybe<Scalars['numeric']['output']>;
  CasinoMultiply?: Maybe<Scalars['numeric']['output']>;
  CryptoMultiply?: Maybe<Scalars['numeric']['output']>;
  DatingMultiply?: Maybe<Scalars['numeric']['output']>;
  FinanceMultiply?: Maybe<Scalars['numeric']['output']>;
  MedicalMultiply?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.MarketPlaceOfferNiche" */
export enum v1_MarketPlaceOfferNiche_update_column {
  /** column name */
  AdultMultiplier = 'AdultMultiplier',
  /** column name */
  CBDMultiply = 'CBDMultiply',
  /** column name */
  CasinoMultiply = 'CasinoMultiply',
  /** column name */
  CryptoMultiply = 'CryptoMultiply',
  /** column name */
  DatingMultiply = 'DatingMultiply',
  /** column name */
  FinanceMultiply = 'FinanceMultiply',
  /** column name */
  MedicalMultiply = 'MedicalMultiply',
  /** column name */
  id = 'id',
  /** column name */
  isAdultAccepted = 'isAdultAccepted',
  /** column name */
  isCBDAccepted = 'isCBDAccepted',
  /** column name */
  isCasinoAccepted = 'isCasinoAccepted',
  /** column name */
  isCryptoAccepted = 'isCryptoAccepted',
  /** column name */
  isDatingAccepted = 'isDatingAccepted',
  /** column name */
  isFinanceAccepted = 'isFinanceAccepted',
  /** column name */
  isMedicalAccepted = 'isMedicalAccepted'
}

export type v1_MarketPlaceOfferNiche_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketPlaceOfferNiche_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketPlaceOfferNiche_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketPlaceOfferNiche_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketPlaceOfferNiche_var_pop_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_var_pop_fields';
  AdultMultiplier?: Maybe<Scalars['Float']['output']>;
  CBDMultiply?: Maybe<Scalars['Float']['output']>;
  CasinoMultiply?: Maybe<Scalars['Float']['output']>;
  CryptoMultiply?: Maybe<Scalars['Float']['output']>;
  DatingMultiply?: Maybe<Scalars['Float']['output']>;
  FinanceMultiply?: Maybe<Scalars['Float']['output']>;
  MedicalMultiply?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_MarketPlaceOfferNiche_var_samp_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_var_samp_fields';
  AdultMultiplier?: Maybe<Scalars['Float']['output']>;
  CBDMultiply?: Maybe<Scalars['Float']['output']>;
  CasinoMultiply?: Maybe<Scalars['Float']['output']>;
  CryptoMultiply?: Maybe<Scalars['Float']['output']>;
  DatingMultiply?: Maybe<Scalars['Float']['output']>;
  FinanceMultiply?: Maybe<Scalars['Float']['output']>;
  MedicalMultiply?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_MarketPlaceOfferNiche_variance_fields = {
  __typename?: 'v1_MarketPlaceOfferNiche_variance_fields';
  AdultMultiplier?: Maybe<Scalars['Float']['output']>;
  CBDMultiply?: Maybe<Scalars['Float']['output']>;
  CasinoMultiply?: Maybe<Scalars['Float']['output']>;
  CryptoMultiply?: Maybe<Scalars['Float']['output']>;
  DatingMultiply?: Maybe<Scalars['Float']['output']>;
  FinanceMultiply?: Maybe<Scalars['Float']['output']>;
  MedicalMultiply?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder = {
  __typename?: 'v1_MarketPlaceOrder';
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  Balances: Array<v1_Balance>;
  /** An aggregate relationship */
  Balances_aggregate: v1_Balance_aggregate;
  CreatedBy: Scalars['Int']['output'];
  CreatedOn: Scalars['timestamptz']['output'];
  /** An object relationship */
  MarketPlaceOrderRequest?: Maybe<v1_MarketPlaceOrderRequest>;
  /** An array relationship */
  MarketPlaceOrderRequests: Array<v1_MarketPlaceOrderRequest>;
  /** An aggregate relationship */
  MarketPlaceOrderRequests_aggregate: v1_MarketPlaceOrderRequest_aggregate;
  /** An object relationship */
  MarketPlaceOrderStatus: v1_MarketPlaceOrderStatus;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  PublisherId?: Maybe<Scalars['Int']['output']>;
  RequestId?: Maybe<Scalars['Int']['output']>;
  SalesUserId?: Maybe<Scalars['Int']['output']>;
  Status: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrderBalancesArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrderBalances_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrderMarketPlaceOrderRequestsArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderRequest_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrderMarketPlaceOrderRequests_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderRequest_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrderPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrderPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** columns and relationships of "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest = {
  __typename?: 'v1_MarketPlaceOrderRequest';
  AnchorText?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Instructions?: Maybe<Scalars['String']['output']>;
  Keywords?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  Language?: Maybe<v1_new_languagesBase>;
  LanguageId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  MarketPlaceOrder: v1_MarketPlaceOrder;
  /** An array relationship */
  MarketPlaceOrders: Array<v1_MarketPlaceOrder>;
  /** An aggregate relationship */
  MarketPlaceOrders_aggregate: v1_MarketPlaceOrder_aggregate;
  Order: Scalars['Int']['output'];
  PostStyle?: Maybe<Scalars['String']['output']>;
  SEOInstructions?: Maybe<Scalars['String']['output']>;
  TargetUrl: Scalars['String']['output'];
  Title?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequestMarketPlaceOrdersArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrder_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequestMarketPlaceOrders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrder_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};

/** aggregated selection of "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_aggregate = {
  __typename?: 'v1_MarketPlaceOrderRequest_aggregate';
  aggregate?: Maybe<v1_MarketPlaceOrderRequest_aggregate_fields>;
  nodes: Array<v1_MarketPlaceOrderRequest>;
};

export type v1_MarketPlaceOrderRequest_aggregate_bool_exp = {
  count?: InputMaybe<v1_MarketPlaceOrderRequest_aggregate_bool_exp_count>;
};

export type v1_MarketPlaceOrderRequest_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_aggregate_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_aggregate_fields';
  avg?: Maybe<v1_MarketPlaceOrderRequest_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketPlaceOrderRequest_max_fields>;
  min?: Maybe<v1_MarketPlaceOrderRequest_min_fields>;
  stddev?: Maybe<v1_MarketPlaceOrderRequest_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketPlaceOrderRequest_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketPlaceOrderRequest_stddev_samp_fields>;
  sum?: Maybe<v1_MarketPlaceOrderRequest_sum_fields>;
  var_pop?: Maybe<v1_MarketPlaceOrderRequest_var_pop_fields>;
  var_samp?: Maybe<v1_MarketPlaceOrderRequest_var_samp_fields>;
  variance?: Maybe<v1_MarketPlaceOrderRequest_variance_fields>;
};


/** aggregate fields of "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_aggregate_order_by = {
  avg?: InputMaybe<v1_MarketPlaceOrderRequest_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_MarketPlaceOrderRequest_max_order_by>;
  min?: InputMaybe<v1_MarketPlaceOrderRequest_min_order_by>;
  stddev?: InputMaybe<v1_MarketPlaceOrderRequest_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_MarketPlaceOrderRequest_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_MarketPlaceOrderRequest_stddev_samp_order_by>;
  sum?: InputMaybe<v1_MarketPlaceOrderRequest_sum_order_by>;
  var_pop?: InputMaybe<v1_MarketPlaceOrderRequest_var_pop_order_by>;
  var_samp?: InputMaybe<v1_MarketPlaceOrderRequest_var_samp_order_by>;
  variance?: InputMaybe<v1_MarketPlaceOrderRequest_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_arr_rel_insert_input = {
  data: Array<v1_MarketPlaceOrderRequest_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceOrderRequest_on_conflict>;
};

/** aggregate avg on columns */
export type v1_MarketPlaceOrderRequest_avg_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_avg_fields';
  LanguageId?: Maybe<Scalars['Float']['output']>;
  Order?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_avg_order_by = {
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.MarketPlaceOrderRequest". All fields are combined with a logical 'AND'. */
export type v1_MarketPlaceOrderRequest_bool_exp = {
  AnchorText?: InputMaybe<String_comparison_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  Instructions?: InputMaybe<String_comparison_exp>;
  Keywords?: InputMaybe<String_comparison_exp>;
  Language?: InputMaybe<v1_new_languagesBase_bool_exp>;
  LanguageId?: InputMaybe<Int_comparison_exp>;
  MarketPlaceOrder?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
  MarketPlaceOrders?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
  MarketPlaceOrders_aggregate?: InputMaybe<v1_MarketPlaceOrder_aggregate_bool_exp>;
  Order?: InputMaybe<Int_comparison_exp>;
  PostStyle?: InputMaybe<String_comparison_exp>;
  SEOInstructions?: InputMaybe<String_comparison_exp>;
  TargetUrl?: InputMaybe<String_comparison_exp>;
  Title?: InputMaybe<String_comparison_exp>;
  _and?: InputMaybe<Array<v1_MarketPlaceOrderRequest_bool_exp>>;
  _not?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketPlaceOrderRequest_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketPlaceOrderRequest" */
export enum v1_MarketPlaceOrderRequest_constraint {
  /** unique or primary key constraint on columns "id" */
  MarketPlaceOrderRequest_pkey = 'MarketPlaceOrderRequest_pkey'
}

/** input type for incrementing numeric columns in table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_inc_input = {
  LanguageId?: InputMaybe<Scalars['Int']['input']>;
  Order?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_insert_input = {
  AnchorText?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Instructions?: InputMaybe<Scalars['String']['input']>;
  Keywords?: InputMaybe<Scalars['String']['input']>;
  Language?: InputMaybe<v1_new_languagesBase_obj_rel_insert_input>;
  LanguageId?: InputMaybe<Scalars['Int']['input']>;
  MarketPlaceOrder?: InputMaybe<v1_MarketPlaceOrder_obj_rel_insert_input>;
  MarketPlaceOrders?: InputMaybe<v1_MarketPlaceOrder_arr_rel_insert_input>;
  Order?: InputMaybe<Scalars['Int']['input']>;
  PostStyle?: InputMaybe<Scalars['String']['input']>;
  SEOInstructions?: InputMaybe<Scalars['String']['input']>;
  TargetUrl?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_MarketPlaceOrderRequest_max_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_max_fields';
  AnchorText?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Instructions?: Maybe<Scalars['String']['output']>;
  Keywords?: Maybe<Scalars['String']['output']>;
  LanguageId?: Maybe<Scalars['Int']['output']>;
  Order?: Maybe<Scalars['Int']['output']>;
  PostStyle?: Maybe<Scalars['String']['output']>;
  SEOInstructions?: Maybe<Scalars['String']['output']>;
  TargetUrl?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_max_order_by = {
  AnchorText?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  Instructions?: InputMaybe<order_by>;
  Keywords?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  PostStyle?: InputMaybe<order_by>;
  SEOInstructions?: InputMaybe<order_by>;
  TargetUrl?: InputMaybe<order_by>;
  Title?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_MarketPlaceOrderRequest_min_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_min_fields';
  AnchorText?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Instructions?: Maybe<Scalars['String']['output']>;
  Keywords?: Maybe<Scalars['String']['output']>;
  LanguageId?: Maybe<Scalars['Int']['output']>;
  Order?: Maybe<Scalars['Int']['output']>;
  PostStyle?: Maybe<Scalars['String']['output']>;
  SEOInstructions?: Maybe<Scalars['String']['output']>;
  TargetUrl?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_min_order_by = {
  AnchorText?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  Instructions?: InputMaybe<order_by>;
  Keywords?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  PostStyle?: InputMaybe<order_by>;
  SEOInstructions?: InputMaybe<order_by>;
  TargetUrl?: InputMaybe<order_by>;
  Title?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_mutation_response = {
  __typename?: 'v1_MarketPlaceOrderRequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketPlaceOrderRequest>;
};

/** input type for inserting object relation for remote table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_obj_rel_insert_input = {
  data: v1_MarketPlaceOrderRequest_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceOrderRequest_on_conflict>;
};

/** on_conflict condition type for table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_on_conflict = {
  constraint: v1_MarketPlaceOrderRequest_constraint;
  update_columns?: Array<v1_MarketPlaceOrderRequest_update_column>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketPlaceOrderRequest". */
export type v1_MarketPlaceOrderRequest_order_by = {
  AnchorText?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  Instructions?: InputMaybe<order_by>;
  Keywords?: InputMaybe<order_by>;
  Language?: InputMaybe<v1_new_languagesBase_order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceOrder?: InputMaybe<v1_MarketPlaceOrder_order_by>;
  MarketPlaceOrders_aggregate?: InputMaybe<v1_MarketPlaceOrder_aggregate_order_by>;
  Order?: InputMaybe<order_by>;
  PostStyle?: InputMaybe<order_by>;
  SEOInstructions?: InputMaybe<order_by>;
  TargetUrl?: InputMaybe<order_by>;
  Title?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketPlaceOrderRequest */
export type v1_MarketPlaceOrderRequest_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketPlaceOrderRequest" */
export enum v1_MarketPlaceOrderRequest_select_column {
  /** column name */
  AnchorText = 'AnchorText',
  /** column name */
  Description = 'Description',
  /** column name */
  Instructions = 'Instructions',
  /** column name */
  Keywords = 'Keywords',
  /** column name */
  LanguageId = 'LanguageId',
  /** column name */
  Order = 'Order',
  /** column name */
  PostStyle = 'PostStyle',
  /** column name */
  SEOInstructions = 'SEOInstructions',
  /** column name */
  TargetUrl = 'TargetUrl',
  /** column name */
  Title = 'Title',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_set_input = {
  AnchorText?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Instructions?: InputMaybe<Scalars['String']['input']>;
  Keywords?: InputMaybe<Scalars['String']['input']>;
  LanguageId?: InputMaybe<Scalars['Int']['input']>;
  Order?: InputMaybe<Scalars['Int']['input']>;
  PostStyle?: InputMaybe<Scalars['String']['input']>;
  SEOInstructions?: InputMaybe<Scalars['String']['input']>;
  TargetUrl?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketPlaceOrderRequest_stddev_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_stddev_fields';
  LanguageId?: Maybe<Scalars['Float']['output']>;
  Order?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_stddev_order_by = {
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketPlaceOrderRequest_stddev_pop_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_stddev_pop_fields';
  LanguageId?: Maybe<Scalars['Float']['output']>;
  Order?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_stddev_pop_order_by = {
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketPlaceOrderRequest_stddev_samp_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_stddev_samp_fields';
  LanguageId?: Maybe<Scalars['Float']['output']>;
  Order?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_stddev_samp_order_by = {
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketPlaceOrderRequest_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketPlaceOrderRequest_stream_cursor_value_input = {
  AnchorText?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Instructions?: InputMaybe<Scalars['String']['input']>;
  Keywords?: InputMaybe<Scalars['String']['input']>;
  LanguageId?: InputMaybe<Scalars['Int']['input']>;
  Order?: InputMaybe<Scalars['Int']['input']>;
  PostStyle?: InputMaybe<Scalars['String']['input']>;
  SEOInstructions?: InputMaybe<Scalars['String']['input']>;
  TargetUrl?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketPlaceOrderRequest_sum_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_sum_fields';
  LanguageId?: Maybe<Scalars['Int']['output']>;
  Order?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_sum_order_by = {
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.MarketPlaceOrderRequest" */
export enum v1_MarketPlaceOrderRequest_update_column {
  /** column name */
  AnchorText = 'AnchorText',
  /** column name */
  Description = 'Description',
  /** column name */
  Instructions = 'Instructions',
  /** column name */
  Keywords = 'Keywords',
  /** column name */
  LanguageId = 'LanguageId',
  /** column name */
  Order = 'Order',
  /** column name */
  PostStyle = 'PostStyle',
  /** column name */
  SEOInstructions = 'SEOInstructions',
  /** column name */
  TargetUrl = 'TargetUrl',
  /** column name */
  Title = 'Title',
  /** column name */
  id = 'id'
}

export type v1_MarketPlaceOrderRequest_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketPlaceOrderRequest_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketPlaceOrderRequest_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketPlaceOrderRequest_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketPlaceOrderRequest_var_pop_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_var_pop_fields';
  LanguageId?: Maybe<Scalars['Float']['output']>;
  Order?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_var_pop_order_by = {
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_MarketPlaceOrderRequest_var_samp_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_var_samp_fields';
  LanguageId?: Maybe<Scalars['Float']['output']>;
  Order?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_var_samp_order_by = {
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_MarketPlaceOrderRequest_variance_fields = {
  __typename?: 'v1_MarketPlaceOrderRequest_variance_fields';
  LanguageId?: Maybe<Scalars['Float']['output']>;
  Order?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.MarketPlaceOrderRequest" */
export type v1_MarketPlaceOrderRequest_variance_order_by = {
  LanguageId?: InputMaybe<order_by>;
  Order?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus = {
  __typename?: 'v1_MarketPlaceOrderStatus';
  /** An array relationship */
  MarketPlaceOrders: Array<v1_MarketPlaceOrder>;
  /** An aggregate relationship */
  MarketPlaceOrders_aggregate: v1_MarketPlaceOrder_aggregate;
  Status: Scalars['bpchar']['output'];
  id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatusMarketPlaceOrdersArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrder_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};


/** columns and relationships of "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatusMarketPlaceOrders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrder_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};

/** aggregated selection of "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_aggregate = {
  __typename?: 'v1_MarketPlaceOrderStatus_aggregate';
  aggregate?: Maybe<v1_MarketPlaceOrderStatus_aggregate_fields>;
  nodes: Array<v1_MarketPlaceOrderStatus>;
};

/** aggregate fields of "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_aggregate_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_aggregate_fields';
  avg?: Maybe<v1_MarketPlaceOrderStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketPlaceOrderStatus_max_fields>;
  min?: Maybe<v1_MarketPlaceOrderStatus_min_fields>;
  stddev?: Maybe<v1_MarketPlaceOrderStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketPlaceOrderStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketPlaceOrderStatus_stddev_samp_fields>;
  sum?: Maybe<v1_MarketPlaceOrderStatus_sum_fields>;
  var_pop?: Maybe<v1_MarketPlaceOrderStatus_var_pop_fields>;
  var_samp?: Maybe<v1_MarketPlaceOrderStatus_var_samp_fields>;
  variance?: Maybe<v1_MarketPlaceOrderStatus_variance_fields>;
};


/** aggregate fields of "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketPlaceOrderStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_MarketPlaceOrderStatus_avg_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.MarketPlaceOrderStatus". All fields are combined with a logical 'AND'. */
export type v1_MarketPlaceOrderStatus_bool_exp = {
  MarketPlaceOrders?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
  MarketPlaceOrders_aggregate?: InputMaybe<v1_MarketPlaceOrder_aggregate_bool_exp>;
  Status?: InputMaybe<bpchar_comparison_exp>;
  _and?: InputMaybe<Array<v1_MarketPlaceOrderStatus_bool_exp>>;
  _not?: InputMaybe<v1_MarketPlaceOrderStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketPlaceOrderStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketPlaceOrderStatus" */
export enum v1_MarketPlaceOrderStatus_constraint {
  /** unique or primary key constraint on columns "Status" */
  MarketPlaceOrderStatus_Status_key = 'MarketPlaceOrderStatus_Status_key',
  /** unique or primary key constraint on columns "id" */
  MarketPlaceOrderStatus_pkey = 'MarketPlaceOrderStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_insert_input = {
  MarketPlaceOrders?: InputMaybe<v1_MarketPlaceOrder_arr_rel_insert_input>;
  Status?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_MarketPlaceOrderStatus_max_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_max_fields';
  Status?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_MarketPlaceOrderStatus_min_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_min_fields';
  Status?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_mutation_response = {
  __typename?: 'v1_MarketPlaceOrderStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketPlaceOrderStatus>;
};

/** input type for inserting object relation for remote table "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_obj_rel_insert_input = {
  data: v1_MarketPlaceOrderStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceOrderStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_on_conflict = {
  constraint: v1_MarketPlaceOrderStatus_constraint;
  update_columns?: Array<v1_MarketPlaceOrderStatus_update_column>;
  where?: InputMaybe<v1_MarketPlaceOrderStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketPlaceOrderStatus". */
export type v1_MarketPlaceOrderStatus_order_by = {
  MarketPlaceOrders_aggregate?: InputMaybe<v1_MarketPlaceOrder_aggregate_order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketPlaceOrderStatus */
export type v1_MarketPlaceOrderStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketPlaceOrderStatus" */
export enum v1_MarketPlaceOrderStatus_select_column {
  /** column name */
  Status = 'Status',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_set_input = {
  Status?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketPlaceOrderStatus_stddev_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketPlaceOrderStatus_stddev_pop_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketPlaceOrderStatus_stddev_samp_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_MarketPlaceOrderStatus" */
export type v1_MarketPlaceOrderStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketPlaceOrderStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketPlaceOrderStatus_stream_cursor_value_input = {
  Status?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketPlaceOrderStatus_sum_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.MarketPlaceOrderStatus" */
export enum v1_MarketPlaceOrderStatus_update_column {
  /** column name */
  Status = 'Status',
  /** column name */
  id = 'id'
}

export type v1_MarketPlaceOrderStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketPlaceOrderStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketPlaceOrderStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketPlaceOrderStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketPlaceOrderStatus_var_pop_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_MarketPlaceOrderStatus_var_samp_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_MarketPlaceOrderStatus_variance_fields = {
  __typename?: 'v1_MarketPlaceOrderStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_aggregate = {
  __typename?: 'v1_MarketPlaceOrder_aggregate';
  aggregate?: Maybe<v1_MarketPlaceOrder_aggregate_fields>;
  nodes: Array<v1_MarketPlaceOrder>;
};

export type v1_MarketPlaceOrder_aggregate_bool_exp = {
  count?: InputMaybe<v1_MarketPlaceOrder_aggregate_bool_exp_count>;
};

export type v1_MarketPlaceOrder_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_aggregate_fields = {
  __typename?: 'v1_MarketPlaceOrder_aggregate_fields';
  avg?: Maybe<v1_MarketPlaceOrder_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketPlaceOrder_max_fields>;
  min?: Maybe<v1_MarketPlaceOrder_min_fields>;
  stddev?: Maybe<v1_MarketPlaceOrder_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketPlaceOrder_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketPlaceOrder_stddev_samp_fields>;
  sum?: Maybe<v1_MarketPlaceOrder_sum_fields>;
  var_pop?: Maybe<v1_MarketPlaceOrder_var_pop_fields>;
  var_samp?: Maybe<v1_MarketPlaceOrder_var_samp_fields>;
  variance?: Maybe<v1_MarketPlaceOrder_variance_fields>;
};


/** aggregate fields of "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketPlaceOrder_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_aggregate_order_by = {
  avg?: InputMaybe<v1_MarketPlaceOrder_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_MarketPlaceOrder_max_order_by>;
  min?: InputMaybe<v1_MarketPlaceOrder_min_order_by>;
  stddev?: InputMaybe<v1_MarketPlaceOrder_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_MarketPlaceOrder_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_MarketPlaceOrder_stddev_samp_order_by>;
  sum?: InputMaybe<v1_MarketPlaceOrder_sum_order_by>;
  var_pop?: InputMaybe<v1_MarketPlaceOrder_var_pop_order_by>;
  var_samp?: InputMaybe<v1_MarketPlaceOrder_var_samp_order_by>;
  variance?: InputMaybe<v1_MarketPlaceOrder_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_arr_rel_insert_input = {
  data: Array<v1_MarketPlaceOrder_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceOrder_on_conflict>;
};

/** aggregate avg on columns */
export type v1_MarketPlaceOrder_avg_fields = {
  __typename?: 'v1_MarketPlaceOrder_avg_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CreatedBy?: Maybe<Scalars['Float']['output']>;
  PublisherId?: Maybe<Scalars['Float']['output']>;
  RequestId?: Maybe<Scalars['Float']['output']>;
  SalesUserId?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_avg_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.MarketPlaceOrder". All fields are combined with a logical 'AND'. */
export type v1_MarketPlaceOrder_bool_exp = {
  AdvertiserId?: InputMaybe<Int_comparison_exp>;
  Balances?: InputMaybe<v1_Balance_bool_exp>;
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_bool_exp>;
  CreatedBy?: InputMaybe<Int_comparison_exp>;
  CreatedOn?: InputMaybe<timestamptz_comparison_exp>;
  MarketPlaceOrderRequest?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
  MarketPlaceOrderRequests?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
  MarketPlaceOrderRequests_aggregate?: InputMaybe<v1_MarketPlaceOrderRequest_aggregate_bool_exp>;
  MarketPlaceOrderStatus?: InputMaybe<v1_MarketPlaceOrderStatus_bool_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  PublisherId?: InputMaybe<Int_comparison_exp>;
  RequestId?: InputMaybe<Int_comparison_exp>;
  SalesUserId?: InputMaybe<Int_comparison_exp>;
  Status?: InputMaybe<Int_comparison_exp>;
  _and?: InputMaybe<Array<v1_MarketPlaceOrder_bool_exp>>;
  _not?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketPlaceOrder_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketPlaceOrder" */
export enum v1_MarketPlaceOrder_constraint {
  /** unique or primary key constraint on columns "id" */
  MarketPlaceOrder_pkey = 'MarketPlaceOrder_pkey'
}

/** input type for incrementing numeric columns in table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_inc_input = {
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['Int']['input']>;
  PublisherId?: InputMaybe<Scalars['Int']['input']>;
  RequestId?: InputMaybe<Scalars['Int']['input']>;
  SalesUserId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_insert_input = {
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  Balances?: InputMaybe<v1_Balance_arr_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['Int']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  MarketPlaceOrderRequest?: InputMaybe<v1_MarketPlaceOrderRequest_obj_rel_insert_input>;
  MarketPlaceOrderRequests?: InputMaybe<v1_MarketPlaceOrderRequest_arr_rel_insert_input>;
  MarketPlaceOrderStatus?: InputMaybe<v1_MarketPlaceOrderStatus_obj_rel_insert_input>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  PublisherId?: InputMaybe<Scalars['Int']['input']>;
  RequestId?: InputMaybe<Scalars['Int']['input']>;
  SalesUserId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_MarketPlaceOrder_max_fields = {
  __typename?: 'v1_MarketPlaceOrder_max_fields';
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['Int']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  PublisherId?: Maybe<Scalars['Int']['output']>;
  RequestId?: Maybe<Scalars['Int']['output']>;
  SalesUserId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_max_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_MarketPlaceOrder_min_fields = {
  __typename?: 'v1_MarketPlaceOrder_min_fields';
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['Int']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  PublisherId?: Maybe<Scalars['Int']['output']>;
  RequestId?: Maybe<Scalars['Int']['output']>;
  SalesUserId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_min_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_mutation_response = {
  __typename?: 'v1_MarketPlaceOrder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketPlaceOrder>;
};

/** input type for inserting object relation for remote table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_obj_rel_insert_input = {
  data: v1_MarketPlaceOrder_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketPlaceOrder_on_conflict>;
};

/** on_conflict condition type for table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_on_conflict = {
  constraint: v1_MarketPlaceOrder_constraint;
  update_columns?: Array<v1_MarketPlaceOrder_update_column>;
  where?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketPlaceOrder". */
export type v1_MarketPlaceOrder_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  MarketPlaceOrderRequest?: InputMaybe<v1_MarketPlaceOrderRequest_order_by>;
  MarketPlaceOrderRequests_aggregate?: InputMaybe<v1_MarketPlaceOrderRequest_aggregate_order_by>;
  MarketPlaceOrderStatus?: InputMaybe<v1_MarketPlaceOrderStatus_order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketPlaceOrder */
export type v1_MarketPlaceOrder_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketPlaceOrder" */
export enum v1_MarketPlaceOrder_select_column {
  /** column name */
  AdvertiserId = 'AdvertiserId',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  PublisherId = 'PublisherId',
  /** column name */
  RequestId = 'RequestId',
  /** column name */
  SalesUserId = 'SalesUserId',
  /** column name */
  Status = 'Status',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_set_input = {
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['Int']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  PublisherId?: InputMaybe<Scalars['Int']['input']>;
  RequestId?: InputMaybe<Scalars['Int']['input']>;
  SalesUserId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketPlaceOrder_stddev_fields = {
  __typename?: 'v1_MarketPlaceOrder_stddev_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CreatedBy?: Maybe<Scalars['Float']['output']>;
  PublisherId?: Maybe<Scalars['Float']['output']>;
  RequestId?: Maybe<Scalars['Float']['output']>;
  SalesUserId?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_stddev_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketPlaceOrder_stddev_pop_fields = {
  __typename?: 'v1_MarketPlaceOrder_stddev_pop_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CreatedBy?: Maybe<Scalars['Float']['output']>;
  PublisherId?: Maybe<Scalars['Float']['output']>;
  RequestId?: Maybe<Scalars['Float']['output']>;
  SalesUserId?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_stddev_pop_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketPlaceOrder_stddev_samp_fields = {
  __typename?: 'v1_MarketPlaceOrder_stddev_samp_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CreatedBy?: Maybe<Scalars['Float']['output']>;
  PublisherId?: Maybe<Scalars['Float']['output']>;
  RequestId?: Maybe<Scalars['Float']['output']>;
  SalesUserId?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_stddev_samp_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_MarketPlaceOrder" */
export type v1_MarketPlaceOrder_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketPlaceOrder_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketPlaceOrder_stream_cursor_value_input = {
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['Int']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  PublisherId?: InputMaybe<Scalars['Int']['input']>;
  RequestId?: InputMaybe<Scalars['Int']['input']>;
  SalesUserId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketPlaceOrder_sum_fields = {
  __typename?: 'v1_MarketPlaceOrder_sum_fields';
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['Int']['output']>;
  PublisherId?: Maybe<Scalars['Int']['output']>;
  RequestId?: Maybe<Scalars['Int']['output']>;
  SalesUserId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_sum_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.MarketPlaceOrder" */
export enum v1_MarketPlaceOrder_update_column {
  /** column name */
  AdvertiserId = 'AdvertiserId',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  PublisherId = 'PublisherId',
  /** column name */
  RequestId = 'RequestId',
  /** column name */
  SalesUserId = 'SalesUserId',
  /** column name */
  Status = 'Status',
  /** column name */
  id = 'id'
}

export type v1_MarketPlaceOrder_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketPlaceOrder_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketPlaceOrder_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketPlaceOrder_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketPlaceOrder_var_pop_fields = {
  __typename?: 'v1_MarketPlaceOrder_var_pop_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CreatedBy?: Maybe<Scalars['Float']['output']>;
  PublisherId?: Maybe<Scalars['Float']['output']>;
  RequestId?: Maybe<Scalars['Float']['output']>;
  SalesUserId?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_var_pop_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_MarketPlaceOrder_var_samp_fields = {
  __typename?: 'v1_MarketPlaceOrder_var_samp_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CreatedBy?: Maybe<Scalars['Float']['output']>;
  PublisherId?: Maybe<Scalars['Float']['output']>;
  RequestId?: Maybe<Scalars['Float']['output']>;
  SalesUserId?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_var_samp_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_MarketPlaceOrder_variance_fields = {
  __typename?: 'v1_MarketPlaceOrder_variance_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CreatedBy?: Maybe<Scalars['Float']['output']>;
  PublisherId?: Maybe<Scalars['Float']['output']>;
  RequestId?: Maybe<Scalars['Float']['output']>;
  SalesUserId?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.MarketPlaceOrder" */
export type v1_MarketPlaceOrder_variance_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  PublisherId?: InputMaybe<order_by>;
  RequestId?: InputMaybe<order_by>;
  SalesUserId?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** This will be used for writers/suppliers/advertisers */
export type v1_MarketPlaceRegisterStatus = {
  __typename?: 'v1_MarketPlaceRegisterStatus';
  id: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

/** aggregated selection of "v1.MarketPlaceRegisterStatus" */
export type v1_MarketPlaceRegisterStatus_aggregate = {
  __typename?: 'v1_MarketPlaceRegisterStatus_aggregate';
  aggregate?: Maybe<v1_MarketPlaceRegisterStatus_aggregate_fields>;
  nodes: Array<v1_MarketPlaceRegisterStatus>;
};

/** aggregate fields of "v1.MarketPlaceRegisterStatus" */
export type v1_MarketPlaceRegisterStatus_aggregate_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_aggregate_fields';
  avg?: Maybe<v1_MarketPlaceRegisterStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketPlaceRegisterStatus_max_fields>;
  min?: Maybe<v1_MarketPlaceRegisterStatus_min_fields>;
  stddev?: Maybe<v1_MarketPlaceRegisterStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketPlaceRegisterStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketPlaceRegisterStatus_stddev_samp_fields>;
  sum?: Maybe<v1_MarketPlaceRegisterStatus_sum_fields>;
  var_pop?: Maybe<v1_MarketPlaceRegisterStatus_var_pop_fields>;
  var_samp?: Maybe<v1_MarketPlaceRegisterStatus_var_samp_fields>;
  variance?: Maybe<v1_MarketPlaceRegisterStatus_variance_fields>;
};


/** aggregate fields of "v1.MarketPlaceRegisterStatus" */
export type v1_MarketPlaceRegisterStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_MarketPlaceRegisterStatus_avg_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.MarketPlaceRegisterStatus". All fields are combined with a logical 'AND'. */
export type v1_MarketPlaceRegisterStatus_bool_exp = {
  _and?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_bool_exp>>;
  _not?: InputMaybe<v1_MarketPlaceRegisterStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketPlaceRegisterStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  status?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketPlaceRegisterStatus" */
export enum v1_MarketPlaceRegisterStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  MarketPlaceRegisterStatus_pkey = 'MarketPlaceRegisterStatus_pkey',
  /** unique or primary key constraint on columns "status" */
  MarketPlaceRegisterStatus_status_key = 'MarketPlaceRegisterStatus_status_key'
}

/** input type for incrementing numeric columns in table "v1.MarketPlaceRegisterStatus" */
export type v1_MarketPlaceRegisterStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketPlaceRegisterStatus" */
export type v1_MarketPlaceRegisterStatus_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_MarketPlaceRegisterStatus_max_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_MarketPlaceRegisterStatus_min_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.MarketPlaceRegisterStatus" */
export type v1_MarketPlaceRegisterStatus_mutation_response = {
  __typename?: 'v1_MarketPlaceRegisterStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketPlaceRegisterStatus>;
};

/** on_conflict condition type for table "v1.MarketPlaceRegisterStatus" */
export type v1_MarketPlaceRegisterStatus_on_conflict = {
  constraint: v1_MarketPlaceRegisterStatus_constraint;
  update_columns?: Array<v1_MarketPlaceRegisterStatus_update_column>;
  where?: InputMaybe<v1_MarketPlaceRegisterStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketPlaceRegisterStatus". */
export type v1_MarketPlaceRegisterStatus_order_by = {
  id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketPlaceRegisterStatus */
export type v1_MarketPlaceRegisterStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketPlaceRegisterStatus" */
export enum v1_MarketPlaceRegisterStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  status = 'status'
}

/** input type for updating data in table "v1.MarketPlaceRegisterStatus" */
export type v1_MarketPlaceRegisterStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketPlaceRegisterStatus_stddev_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketPlaceRegisterStatus_stddev_pop_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketPlaceRegisterStatus_stddev_samp_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_MarketPlaceRegisterStatus" */
export type v1_MarketPlaceRegisterStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketPlaceRegisterStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketPlaceRegisterStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketPlaceRegisterStatus_sum_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.MarketPlaceRegisterStatus" */
export enum v1_MarketPlaceRegisterStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  status = 'status'
}

export type v1_MarketPlaceRegisterStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketPlaceRegisterStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketPlaceRegisterStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketPlaceRegisterStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketPlaceRegisterStatus_var_pop_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_MarketPlaceRegisterStatus_var_samp_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_MarketPlaceRegisterStatus_variance_fields = {
  __typename?: 'v1_MarketPlaceRegisterStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** This table is for the Bonus Management System, for sports or casino. */
export type v1_MarketType = {
  __typename?: 'v1_MarketType';
  /** An array relationship */
  Bonuses: Array<v1_Bonuses>;
  /** An aggregate relationship */
  Bonuses_aggregate: v1_Bonuses_aggregate;
  id: Scalars['Int']['output'];
  new_name: Scalars['bpchar']['output'];
};


/** This table is for the Bonus Management System, for sports or casino. */
export type v1_MarketTypeBonusesArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** This table is for the Bonus Management System, for sports or casino. */
export type v1_MarketTypeBonuses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};

/** aggregated selection of "v1.MarketType" */
export type v1_MarketType_aggregate = {
  __typename?: 'v1_MarketType_aggregate';
  aggregate?: Maybe<v1_MarketType_aggregate_fields>;
  nodes: Array<v1_MarketType>;
};

/** aggregate fields of "v1.MarketType" */
export type v1_MarketType_aggregate_fields = {
  __typename?: 'v1_MarketType_aggregate_fields';
  avg?: Maybe<v1_MarketType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketType_max_fields>;
  min?: Maybe<v1_MarketType_min_fields>;
  stddev?: Maybe<v1_MarketType_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketType_stddev_samp_fields>;
  sum?: Maybe<v1_MarketType_sum_fields>;
  var_pop?: Maybe<v1_MarketType_var_pop_fields>;
  var_samp?: Maybe<v1_MarketType_var_samp_fields>;
  variance?: Maybe<v1_MarketType_variance_fields>;
};


/** aggregate fields of "v1.MarketType" */
export type v1_MarketType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_MarketType_avg_fields = {
  __typename?: 'v1_MarketType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.MarketType". All fields are combined with a logical 'AND'. */
export type v1_MarketType_bool_exp = {
  Bonuses?: InputMaybe<v1_Bonuses_bool_exp>;
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_MarketType_bool_exp>>;
  _not?: InputMaybe<v1_MarketType_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketType_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<bpchar_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketType" */
export enum v1_MarketType_constraint {
  /** unique or primary key constraint on columns "new_name" */
  MarketType_new_name_key = 'MarketType_new_name_key',
  /** unique or primary key constraint on columns "id" */
  MarketType_pkey = 'MarketType_pkey'
}

/** input type for incrementing numeric columns in table "v1.MarketType" */
export type v1_MarketType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketType" */
export type v1_MarketType_insert_input = {
  Bonuses?: InputMaybe<v1_Bonuses_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type v1_MarketType_max_fields = {
  __typename?: 'v1_MarketType_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_MarketType_min_fields = {
  __typename?: 'v1_MarketType_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.MarketType" */
export type v1_MarketType_mutation_response = {
  __typename?: 'v1_MarketType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketType>;
};

/** input type for inserting object relation for remote table "v1.MarketType" */
export type v1_MarketType_obj_rel_insert_input = {
  data: v1_MarketType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketType_on_conflict>;
};

/** on_conflict condition type for table "v1.MarketType" */
export type v1_MarketType_on_conflict = {
  constraint: v1_MarketType_constraint;
  update_columns?: Array<v1_MarketType_update_column>;
  where?: InputMaybe<v1_MarketType_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketType". */
export type v1_MarketType_order_by = {
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketType */
export type v1_MarketType_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketType" */
export enum v1_MarketType_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

/** input type for updating data in table "v1.MarketType" */
export type v1_MarketType_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketType_stddev_fields = {
  __typename?: 'v1_MarketType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketType_stddev_pop_fields = {
  __typename?: 'v1_MarketType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketType_stddev_samp_fields = {
  __typename?: 'v1_MarketType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_MarketType" */
export type v1_MarketType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketType_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketType_sum_fields = {
  __typename?: 'v1_MarketType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.MarketType" */
export enum v1_MarketType_update_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

export type v1_MarketType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketType_var_pop_fields = {
  __typename?: 'v1_MarketType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_MarketType_var_samp_fields = {
  __typename?: 'v1_MarketType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_MarketType_variance_fields = {
  __typename?: 'v1_MarketType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects = {
  __typename?: 'v1_MarketplaceProjects';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An object relationship */
  User: v1_ContactBase;
  /** A computed field, executes function "v1.count_project_posts_in_progress" */
  comp_posts_in_progress?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.count_project_posts_published" */
  comp_posts_published?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  url: Scalars['String']['output'];
  user_id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.MarketplaceProjects" */
export type v1_MarketplaceProjectsPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.MarketplaceProjects" */
export type v1_MarketplaceProjectsPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_aggregate = {
  __typename?: 'v1_MarketplaceProjects_aggregate';
  aggregate?: Maybe<v1_MarketplaceProjects_aggregate_fields>;
  nodes: Array<v1_MarketplaceProjects>;
};

export type v1_MarketplaceProjects_aggregate_bool_exp = {
  count?: InputMaybe<v1_MarketplaceProjects_aggregate_bool_exp_count>;
};

export type v1_MarketplaceProjects_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_MarketplaceProjects_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_aggregate_fields = {
  __typename?: 'v1_MarketplaceProjects_aggregate_fields';
  avg?: Maybe<v1_MarketplaceProjects_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketplaceProjects_max_fields>;
  min?: Maybe<v1_MarketplaceProjects_min_fields>;
  stddev?: Maybe<v1_MarketplaceProjects_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketplaceProjects_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketplaceProjects_stddev_samp_fields>;
  sum?: Maybe<v1_MarketplaceProjects_sum_fields>;
  var_pop?: Maybe<v1_MarketplaceProjects_var_pop_fields>;
  var_samp?: Maybe<v1_MarketplaceProjects_var_samp_fields>;
  variance?: Maybe<v1_MarketplaceProjects_variance_fields>;
};


/** aggregate fields of "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketplaceProjects_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_aggregate_order_by = {
  avg?: InputMaybe<v1_MarketplaceProjects_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_MarketplaceProjects_max_order_by>;
  min?: InputMaybe<v1_MarketplaceProjects_min_order_by>;
  stddev?: InputMaybe<v1_MarketplaceProjects_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_MarketplaceProjects_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_MarketplaceProjects_stddev_samp_order_by>;
  sum?: InputMaybe<v1_MarketplaceProjects_sum_order_by>;
  var_pop?: InputMaybe<v1_MarketplaceProjects_var_pop_order_by>;
  var_samp?: InputMaybe<v1_MarketplaceProjects_var_samp_order_by>;
  variance?: InputMaybe<v1_MarketplaceProjects_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_arr_rel_insert_input = {
  data: Array<v1_MarketplaceProjects_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketplaceProjects_on_conflict>;
};

/** aggregate avg on columns */
export type v1_MarketplaceProjects_avg_fields = {
  __typename?: 'v1_MarketplaceProjects_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_avg_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.MarketplaceProjects". All fields are combined with a logical 'AND'. */
export type v1_MarketplaceProjects_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  User?: InputMaybe<v1_ContactBase_bool_exp>;
  _and?: InputMaybe<Array<v1_MarketplaceProjects_bool_exp>>;
  _not?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketplaceProjects_bool_exp>>;
  comp_posts_in_progress?: InputMaybe<Int_comparison_exp>;
  comp_posts_published?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketplaceProjects" */
export enum v1_MarketplaceProjects_constraint {
  /** unique or primary key constraint on columns "id" */
  MarketplaceProjects_pkey = 'MarketplaceProjects_pkey',
  /** unique or primary key constraint on columns "url", "user_id" */
  MarketplaceProjects_user_id_url_key = 'MarketplaceProjects_user_id_url_key'
}

/** input type for incrementing numeric columns in table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  User?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_MarketplaceProjects_max_fields = {
  __typename?: 'v1_MarketplaceProjects_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_MarketplaceProjects_min_fields = {
  __typename?: 'v1_MarketplaceProjects_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_mutation_response = {
  __typename?: 'v1_MarketplaceProjects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketplaceProjects>;
};

/** input type for inserting object relation for remote table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_obj_rel_insert_input = {
  data: v1_MarketplaceProjects_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketplaceProjects_on_conflict>;
};

/** on_conflict condition type for table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_on_conflict = {
  constraint: v1_MarketplaceProjects_constraint;
  update_columns?: Array<v1_MarketplaceProjects_update_column>;
  where?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketplaceProjects". */
export type v1_MarketplaceProjects_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  User?: InputMaybe<v1_ContactBase_order_by>;
  comp_posts_in_progress?: InputMaybe<order_by>;
  comp_posts_published?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketplaceProjects */
export type v1_MarketplaceProjects_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketplaceProjects" */
export enum v1_MarketplaceProjects_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  url = 'url',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketplaceProjects_stddev_fields = {
  __typename?: 'v1_MarketplaceProjects_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_stddev_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketplaceProjects_stddev_pop_fields = {
  __typename?: 'v1_MarketplaceProjects_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketplaceProjects_stddev_samp_fields = {
  __typename?: 'v1_MarketplaceProjects_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_MarketplaceProjects" */
export type v1_MarketplaceProjects_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketplaceProjects_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketplaceProjects_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketplaceProjects_sum_fields = {
  __typename?: 'v1_MarketplaceProjects_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_sum_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.MarketplaceProjects" */
export enum v1_MarketplaceProjects_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  url = 'url',
  /** column name */
  user_id = 'user_id'
}

export type v1_MarketplaceProjects_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketplaceProjects_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketplaceProjects_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketplaceProjects_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketplaceProjects_var_pop_fields = {
  __typename?: 'v1_MarketplaceProjects_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_MarketplaceProjects_var_samp_fields = {
  __typename?: 'v1_MarketplaceProjects_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_MarketplaceProjects_variance_fields = {
  __typename?: 'v1_MarketplaceProjects_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.MarketplaceProjects" */
export type v1_MarketplaceProjects_variance_order_by = {
  id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus = {
  __typename?: 'v1_MarketplaceStatus';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "v1.MarketplaceStatus" */
export type v1_MarketplaceStatusPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.MarketplaceStatus" */
export type v1_MarketplaceStatusPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus_aggregate = {
  __typename?: 'v1_MarketplaceStatus_aggregate';
  aggregate?: Maybe<v1_MarketplaceStatus_aggregate_fields>;
  nodes: Array<v1_MarketplaceStatus>;
};

/** aggregate fields of "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus_aggregate_fields = {
  __typename?: 'v1_MarketplaceStatus_aggregate_fields';
  avg?: Maybe<v1_MarketplaceStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_MarketplaceStatus_max_fields>;
  min?: Maybe<v1_MarketplaceStatus_min_fields>;
  stddev?: Maybe<v1_MarketplaceStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_MarketplaceStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_MarketplaceStatus_stddev_samp_fields>;
  sum?: Maybe<v1_MarketplaceStatus_sum_fields>;
  var_pop?: Maybe<v1_MarketplaceStatus_var_pop_fields>;
  var_samp?: Maybe<v1_MarketplaceStatus_var_samp_fields>;
  variance?: Maybe<v1_MarketplaceStatus_variance_fields>;
};


/** aggregate fields of "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_MarketplaceStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_MarketplaceStatus_avg_fields = {
  __typename?: 'v1_MarketplaceStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.MarketplaceStatus". All fields are combined with a logical 'AND'. */
export type v1_MarketplaceStatus_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_MarketplaceStatus_bool_exp>>;
  _not?: InputMaybe<v1_MarketplaceStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_MarketplaceStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.MarketplaceStatus" */
export enum v1_MarketplaceStatus_constraint {
  /** unique or primary key constraint on columns "name" */
  MarketplaceStatus_name_key = 'MarketplaceStatus_name_key',
  /** unique or primary key constraint on columns "id" */
  MarketplaceStatus_pkey = 'MarketplaceStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_MarketplaceStatus_max_fields = {
  __typename?: 'v1_MarketplaceStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_MarketplaceStatus_min_fields = {
  __typename?: 'v1_MarketplaceStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus_mutation_response = {
  __typename?: 'v1_MarketplaceStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_MarketplaceStatus>;
};

/** input type for inserting object relation for remote table "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus_obj_rel_insert_input = {
  data: v1_MarketplaceStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_MarketplaceStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus_on_conflict = {
  constraint: v1_MarketplaceStatus_constraint;
  update_columns?: Array<v1_MarketplaceStatus_update_column>;
  where?: InputMaybe<v1_MarketplaceStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.MarketplaceStatus". */
export type v1_MarketplaceStatus_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.MarketplaceStatus */
export type v1_MarketplaceStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.MarketplaceStatus" */
export enum v1_MarketplaceStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.MarketplaceStatus" */
export type v1_MarketplaceStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_MarketplaceStatus_stddev_fields = {
  __typename?: 'v1_MarketplaceStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_MarketplaceStatus_stddev_pop_fields = {
  __typename?: 'v1_MarketplaceStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_MarketplaceStatus_stddev_samp_fields = {
  __typename?: 'v1_MarketplaceStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_MarketplaceStatus" */
export type v1_MarketplaceStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_MarketplaceStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_MarketplaceStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_MarketplaceStatus_sum_fields = {
  __typename?: 'v1_MarketplaceStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.MarketplaceStatus" */
export enum v1_MarketplaceStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_MarketplaceStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_MarketplaceStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_MarketplaceStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_MarketplaceStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_MarketplaceStatus_var_pop_fields = {
  __typename?: 'v1_MarketplaceStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_MarketplaceStatus_var_samp_fields = {
  __typename?: 'v1_MarketplaceStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_MarketplaceStatus_variance_fields = {
  __typename?: 'v1_MarketplaceStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Months" */
export type v1_Months = {
  __typename?: 'v1_Months';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "v1.Months" */
export type v1_Months_aggregate = {
  __typename?: 'v1_Months_aggregate';
  aggregate?: Maybe<v1_Months_aggregate_fields>;
  nodes: Array<v1_Months>;
};

/** aggregate fields of "v1.Months" */
export type v1_Months_aggregate_fields = {
  __typename?: 'v1_Months_aggregate_fields';
  avg?: Maybe<v1_Months_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Months_max_fields>;
  min?: Maybe<v1_Months_min_fields>;
  stddev?: Maybe<v1_Months_stddev_fields>;
  stddev_pop?: Maybe<v1_Months_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Months_stddev_samp_fields>;
  sum?: Maybe<v1_Months_sum_fields>;
  var_pop?: Maybe<v1_Months_var_pop_fields>;
  var_samp?: Maybe<v1_Months_var_samp_fields>;
  variance?: Maybe<v1_Months_variance_fields>;
};


/** aggregate fields of "v1.Months" */
export type v1_Months_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Months_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Months_avg_fields = {
  __typename?: 'v1_Months_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Months". All fields are combined with a logical 'AND'. */
export type v1_Months_bool_exp = {
  _and?: InputMaybe<Array<v1_Months_bool_exp>>;
  _not?: InputMaybe<v1_Months_bool_exp>;
  _or?: InputMaybe<Array<v1_Months_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Months" */
export enum v1_Months_constraint {
  /** unique or primary key constraint on columns "id" */
  Months_pkey = 'Months_pkey'
}

/** input type for incrementing numeric columns in table "v1.Months" */
export type v1_Months_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Months" */
export type v1_Months_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Months_max_fields = {
  __typename?: 'v1_Months_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_Months_min_fields = {
  __typename?: 'v1_Months_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.Months" */
export type v1_Months_mutation_response = {
  __typename?: 'v1_Months_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Months>;
};

/** input type for inserting object relation for remote table "v1.Months" */
export type v1_Months_obj_rel_insert_input = {
  data: v1_Months_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Months_on_conflict>;
};

/** on_conflict condition type for table "v1.Months" */
export type v1_Months_on_conflict = {
  constraint: v1_Months_constraint;
  update_columns?: Array<v1_Months_update_column>;
  where?: InputMaybe<v1_Months_bool_exp>;
};

/** Ordering options when selecting data from "v1.Months". */
export type v1_Months_order_by = {
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Months */
export type v1_Months_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Months" */
export enum v1_Months_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.Months" */
export type v1_Months_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Months_stddev_fields = {
  __typename?: 'v1_Months_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Months_stddev_pop_fields = {
  __typename?: 'v1_Months_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Months_stddev_samp_fields = {
  __typename?: 'v1_Months_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Months" */
export type v1_Months_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Months_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Months_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Months_sum_fields = {
  __typename?: 'v1_Months_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Months" */
export enum v1_Months_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_Months_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Months_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Months_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Months_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Months_var_pop_fields = {
  __typename?: 'v1_Months_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Months_var_samp_fields = {
  __typename?: 'v1_Months_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Months_variance_fields = {
  __typename?: 'v1_Months_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Offer" */
export type v1_Offer = {
  __typename?: 'v1_Offer';
  AddedOn?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  CheckoutDetails: Array<v1_CheckoutDetail>;
  /** An aggregate relationship */
  CheckoutDetails_aggregate: v1_CheckoutDetail_aggregate;
  /** An object relationship */
  Company?: Maybe<v1_AccountBase>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  Description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  DomainExtension?: Maybe<v1_DomainExtensions>;
  DomainExtensionId?: Maybe<Scalars['Int']['output']>;
  ExpiresOn?: Maybe<Scalars['timestamptz']['output']>;
  /** notes */
  ExternalMarketplaceNote?: Maybe<Scalars['String']['output']>;
  ExternalPrice?: Maybe<Scalars['numeric']['output']>;
  IsHomepageLink?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  Language?: Maybe<v1_new_languagesBase>;
  LanguageId?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  LikedUsers: Array<v1_UsersToOffers>;
  /** An aggregate relationship */
  LikedUsers_aggregate: v1_UsersToOffers_aggregate;
  /** An array relationship */
  MarketPlaceCategories: Array<v1_MarketPlaceCategories_Offers>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Int']['output']>;
  /** An aggregate relationship */
  MarketPlaceCategories_aggregate: v1_MarketPlaceCategories_Offers_aggregate;
  /** An object relationship */
  MarketPlaceOfferNiche?: Maybe<v1_MarketPlaceOfferNiche>;
  ModifiedOn?: Maybe<Scalars['timestamptz']['output']>;
  NicheId?: Maybe<Scalars['Int']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  OfferStatus: v1_OfferStatus;
  /** An array relationship */
  OfferToTags: Array<v1_OfferToTags>;
  /** An aggregate relationship */
  OfferToTags_aggregate: v1_OfferToTags_aggregate;
  /** An object relationship */
  PostDuration?: Maybe<v1_PostDuration>;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** Text field,  link */
  PublicationSample?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  RestrictedNiches: Array<v1_RestrictedNichesToOffer>;
  /** An aggregate relationship */
  RestrictedNiches_aggregate: v1_RestrictedNichesToOffer_aggregate;
  ShowOnMarketplace?: Maybe<Scalars['Boolean']['output']>;
  Status: Scalars['Int']['output'];
  /** An object relationship */
  Supplier?: Maybe<v1_ContactBase>;
  /** An object relationship */
  Website?: Maybe<v1_Website>;
  /** An object relationship */
  WebsiteTopic?: Maybe<v1_WebsiteTopics>;
  additional_price_percentage: Scalars['numeric']['output'];
  casino_accepted?: Maybe<Scalars['Boolean']['output']>;
  /** A computed field, executes function "v1.comp_related_websites" */
  comp_related_websites?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['timestamptz']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  declined_on?: Maybe<Scalars['timestamptz']['output']>;
  do_follow: Scalars['Boolean']['output'];
  follow_link?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_homepage: Scalars['Boolean']['output'];
  link_price: Scalars['numeric']['output'];
  link_price_old?: Maybe<Scalars['String']['output']>;
  links_to_follow?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes: Scalars['String']['output'];
  outreach_one_id?: Maybe<Scalars['String']['output']>;
  postduration_id?: Maybe<Scalars['Int']['output']>;
  published_on?: Maybe<Scalars['timestamptz']['output']>;
  requested_word_count?: Maybe<Scalars['Int']['output']>;
  sent_to_approval_on?: Maybe<Scalars['timestamptz']['output']>;
  statecode: Scalars['Int']['output'];
  supplier?: Maybe<Scalars['String']['output']>;
  supplier_count?: Maybe<Scalars['Int']['output']>;
  supplier_crm_id?: Maybe<Scalars['uuid']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  supplier_name?: Maybe<Scalars['String']['output']>;
  unpublished_on?: Maybe<Scalars['timestamptz']['output']>;
  updated_on?: Maybe<Scalars['timestamptz']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
  website_name?: Maybe<Scalars['String']['output']>;
  website_topic_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferCheckoutDetailsArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferCheckoutDetails_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferLikedUsersArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersToOffers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersToOffers_order_by>>;
  where?: InputMaybe<v1_UsersToOffers_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferLikedUsers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_UsersToOffers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_UsersToOffers_order_by>>;
  where?: InputMaybe<v1_UsersToOffers_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferMarketPlaceCategoriesArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferMarketPlaceCategories_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceCategories_Offers_order_by>>;
  where?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferOfferToTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferToTags_order_by>>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferOfferToTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferToTags_order_by>>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferRestrictedNichesArgs = {
  distinct_on?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RestrictedNichesToOffer_order_by>>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};


/** columns and relationships of "v1.Offer" */
export type v1_OfferRestrictedNiches_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RestrictedNichesToOffer_order_by>>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};

/** columns and relationships of "v1.OfferStatus" */
export type v1_OfferStatus = {
  __typename?: 'v1_OfferStatus';
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.OfferStatus" */
export type v1_OfferStatusOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.OfferStatus" */
export type v1_OfferStatusOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};

/** aggregated selection of "v1.OfferStatus" */
export type v1_OfferStatus_aggregate = {
  __typename?: 'v1_OfferStatus_aggregate';
  aggregate?: Maybe<v1_OfferStatus_aggregate_fields>;
  nodes: Array<v1_OfferStatus>;
};

/** aggregate fields of "v1.OfferStatus" */
export type v1_OfferStatus_aggregate_fields = {
  __typename?: 'v1_OfferStatus_aggregate_fields';
  avg?: Maybe<v1_OfferStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_OfferStatus_max_fields>;
  min?: Maybe<v1_OfferStatus_min_fields>;
  stddev?: Maybe<v1_OfferStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_OfferStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_OfferStatus_stddev_samp_fields>;
  sum?: Maybe<v1_OfferStatus_sum_fields>;
  var_pop?: Maybe<v1_OfferStatus_var_pop_fields>;
  var_samp?: Maybe<v1_OfferStatus_var_samp_fields>;
  variance?: Maybe<v1_OfferStatus_variance_fields>;
};


/** aggregate fields of "v1.OfferStatus" */
export type v1_OfferStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_OfferStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_OfferStatus_avg_fields = {
  __typename?: 'v1_OfferStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.OfferStatus". All fields are combined with a logical 'AND'. */
export type v1_OfferStatus_bool_exp = {
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_OfferStatus_bool_exp>>;
  _not?: InputMaybe<v1_OfferStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_OfferStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.OfferStatus" */
export enum v1_OfferStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  OfferStatus_pkey = 'OfferStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.OfferStatus" */
export type v1_OfferStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.OfferStatus" */
export type v1_OfferStatus_insert_input = {
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_OfferStatus_max_fields = {
  __typename?: 'v1_OfferStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_OfferStatus_min_fields = {
  __typename?: 'v1_OfferStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.OfferStatus" */
export type v1_OfferStatus_mutation_response = {
  __typename?: 'v1_OfferStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_OfferStatus>;
};

/** input type for inserting object relation for remote table "v1.OfferStatus" */
export type v1_OfferStatus_obj_rel_insert_input = {
  data: v1_OfferStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_OfferStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.OfferStatus" */
export type v1_OfferStatus_on_conflict = {
  constraint: v1_OfferStatus_constraint;
  update_columns?: Array<v1_OfferStatus_update_column>;
  where?: InputMaybe<v1_OfferStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.OfferStatus". */
export type v1_OfferStatus_order_by = {
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.OfferStatus */
export type v1_OfferStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.OfferStatus" */
export enum v1_OfferStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.OfferStatus" */
export type v1_OfferStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_OfferStatus_stddev_fields = {
  __typename?: 'v1_OfferStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_OfferStatus_stddev_pop_fields = {
  __typename?: 'v1_OfferStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_OfferStatus_stddev_samp_fields = {
  __typename?: 'v1_OfferStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_OfferStatus" */
export type v1_OfferStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_OfferStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_OfferStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_OfferStatus_sum_fields = {
  __typename?: 'v1_OfferStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.OfferStatus" */
export enum v1_OfferStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_OfferStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_OfferStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_OfferStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_OfferStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_OfferStatus_var_pop_fields = {
  __typename?: 'v1_OfferStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_OfferStatus_var_samp_fields = {
  __typename?: 'v1_OfferStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_OfferStatus_variance_fields = {
  __typename?: 'v1_OfferStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.OfferTags" */
export type v1_OfferTags = {
  __typename?: 'v1_OfferTags';
  /** An array relationship */
  OfferToTags: Array<v1_OfferToTags>;
  /** An aggregate relationship */
  OfferToTags_aggregate: v1_OfferToTags_aggregate;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "v1.OfferTags" */
export type v1_OfferTagsOfferToTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferToTags_order_by>>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};


/** columns and relationships of "v1.OfferTags" */
export type v1_OfferTagsOfferToTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_OfferToTags_order_by>>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};

/** aggregated selection of "v1.OfferTags" */
export type v1_OfferTags_aggregate = {
  __typename?: 'v1_OfferTags_aggregate';
  aggregate?: Maybe<v1_OfferTags_aggregate_fields>;
  nodes: Array<v1_OfferTags>;
};

/** aggregate fields of "v1.OfferTags" */
export type v1_OfferTags_aggregate_fields = {
  __typename?: 'v1_OfferTags_aggregate_fields';
  avg?: Maybe<v1_OfferTags_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_OfferTags_max_fields>;
  min?: Maybe<v1_OfferTags_min_fields>;
  stddev?: Maybe<v1_OfferTags_stddev_fields>;
  stddev_pop?: Maybe<v1_OfferTags_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_OfferTags_stddev_samp_fields>;
  sum?: Maybe<v1_OfferTags_sum_fields>;
  var_pop?: Maybe<v1_OfferTags_var_pop_fields>;
  var_samp?: Maybe<v1_OfferTags_var_samp_fields>;
  variance?: Maybe<v1_OfferTags_variance_fields>;
};


/** aggregate fields of "v1.OfferTags" */
export type v1_OfferTags_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_OfferTags_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_OfferTags_avg_fields = {
  __typename?: 'v1_OfferTags_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.OfferTags". All fields are combined with a logical 'AND'. */
export type v1_OfferTags_bool_exp = {
  OfferToTags?: InputMaybe<v1_OfferToTags_bool_exp>;
  OfferToTags_aggregate?: InputMaybe<v1_OfferToTags_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_OfferTags_bool_exp>>;
  _not?: InputMaybe<v1_OfferTags_bool_exp>;
  _or?: InputMaybe<Array<v1_OfferTags_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.OfferTags" */
export enum v1_OfferTags_constraint {
  /** unique or primary key constraint on columns "id" */
  OfferTags_pkey = 'OfferTags_pkey'
}

/** input type for incrementing numeric columns in table "v1.OfferTags" */
export type v1_OfferTags_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.OfferTags" */
export type v1_OfferTags_insert_input = {
  OfferToTags?: InputMaybe<v1_OfferToTags_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_OfferTags_max_fields = {
  __typename?: 'v1_OfferTags_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_OfferTags_min_fields = {
  __typename?: 'v1_OfferTags_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.OfferTags" */
export type v1_OfferTags_mutation_response = {
  __typename?: 'v1_OfferTags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_OfferTags>;
};

/** input type for inserting object relation for remote table "v1.OfferTags" */
export type v1_OfferTags_obj_rel_insert_input = {
  data: v1_OfferTags_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_OfferTags_on_conflict>;
};

/** on_conflict condition type for table "v1.OfferTags" */
export type v1_OfferTags_on_conflict = {
  constraint: v1_OfferTags_constraint;
  update_columns?: Array<v1_OfferTags_update_column>;
  where?: InputMaybe<v1_OfferTags_bool_exp>;
};

/** Ordering options when selecting data from "v1.OfferTags". */
export type v1_OfferTags_order_by = {
  OfferToTags_aggregate?: InputMaybe<v1_OfferToTags_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.OfferTags */
export type v1_OfferTags_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.OfferTags" */
export enum v1_OfferTags_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.OfferTags" */
export type v1_OfferTags_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_OfferTags_stddev_fields = {
  __typename?: 'v1_OfferTags_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_OfferTags_stddev_pop_fields = {
  __typename?: 'v1_OfferTags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_OfferTags_stddev_samp_fields = {
  __typename?: 'v1_OfferTags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_OfferTags" */
export type v1_OfferTags_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_OfferTags_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_OfferTags_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_OfferTags_sum_fields = {
  __typename?: 'v1_OfferTags_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.OfferTags" */
export enum v1_OfferTags_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_OfferTags_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_OfferTags_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_OfferTags_set_input>;
  /** filter the rows which have to be updated */
  where: v1_OfferTags_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_OfferTags_var_pop_fields = {
  __typename?: 'v1_OfferTags_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_OfferTags_var_samp_fields = {
  __typename?: 'v1_OfferTags_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_OfferTags_variance_fields = {
  __typename?: 'v1_OfferTags_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.OfferToTags" */
export type v1_OfferToTags = {
  __typename?: 'v1_OfferToTags';
  /** An object relationship */
  Offer: v1_Offer;
  /** An object relationship */
  OfferTag: v1_OfferTags;
  id: Scalars['Int']['output'];
  offer_id: Scalars['Int']['output'];
  tag_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.OfferToTags" */
export type v1_OfferToTags_aggregate = {
  __typename?: 'v1_OfferToTags_aggregate';
  aggregate?: Maybe<v1_OfferToTags_aggregate_fields>;
  nodes: Array<v1_OfferToTags>;
};

export type v1_OfferToTags_aggregate_bool_exp = {
  count?: InputMaybe<v1_OfferToTags_aggregate_bool_exp_count>;
};

export type v1_OfferToTags_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_OfferToTags_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.OfferToTags" */
export type v1_OfferToTags_aggregate_fields = {
  __typename?: 'v1_OfferToTags_aggregate_fields';
  avg?: Maybe<v1_OfferToTags_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_OfferToTags_max_fields>;
  min?: Maybe<v1_OfferToTags_min_fields>;
  stddev?: Maybe<v1_OfferToTags_stddev_fields>;
  stddev_pop?: Maybe<v1_OfferToTags_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_OfferToTags_stddev_samp_fields>;
  sum?: Maybe<v1_OfferToTags_sum_fields>;
  var_pop?: Maybe<v1_OfferToTags_var_pop_fields>;
  var_samp?: Maybe<v1_OfferToTags_var_samp_fields>;
  variance?: Maybe<v1_OfferToTags_variance_fields>;
};


/** aggregate fields of "v1.OfferToTags" */
export type v1_OfferToTags_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_OfferToTags_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.OfferToTags" */
export type v1_OfferToTags_aggregate_order_by = {
  avg?: InputMaybe<v1_OfferToTags_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_OfferToTags_max_order_by>;
  min?: InputMaybe<v1_OfferToTags_min_order_by>;
  stddev?: InputMaybe<v1_OfferToTags_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_OfferToTags_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_OfferToTags_stddev_samp_order_by>;
  sum?: InputMaybe<v1_OfferToTags_sum_order_by>;
  var_pop?: InputMaybe<v1_OfferToTags_var_pop_order_by>;
  var_samp?: InputMaybe<v1_OfferToTags_var_samp_order_by>;
  variance?: InputMaybe<v1_OfferToTags_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.OfferToTags" */
export type v1_OfferToTags_arr_rel_insert_input = {
  data: Array<v1_OfferToTags_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_OfferToTags_on_conflict>;
};

/** aggregate avg on columns */
export type v1_OfferToTags_avg_fields = {
  __typename?: 'v1_OfferToTags_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_avg_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.OfferToTags". All fields are combined with a logical 'AND'. */
export type v1_OfferToTags_bool_exp = {
  Offer?: InputMaybe<v1_Offer_bool_exp>;
  OfferTag?: InputMaybe<v1_OfferTags_bool_exp>;
  _and?: InputMaybe<Array<v1_OfferToTags_bool_exp>>;
  _not?: InputMaybe<v1_OfferToTags_bool_exp>;
  _or?: InputMaybe<Array<v1_OfferToTags_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  tag_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.OfferToTags" */
export enum v1_OfferToTags_constraint {
  /** unique or primary key constraint on columns "id" */
  OfferToTags_pkey = 'OfferToTags_pkey'
}

/** input type for incrementing numeric columns in table "v1.OfferToTags" */
export type v1_OfferToTags_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.OfferToTags" */
export type v1_OfferToTags_insert_input = {
  Offer?: InputMaybe<v1_Offer_obj_rel_insert_input>;
  OfferTag?: InputMaybe<v1_OfferTags_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_OfferToTags_max_fields = {
  __typename?: 'v1_OfferToTags_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_max_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_OfferToTags_min_fields = {
  __typename?: 'v1_OfferToTags_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_min_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.OfferToTags" */
export type v1_OfferToTags_mutation_response = {
  __typename?: 'v1_OfferToTags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_OfferToTags>;
};

/** on_conflict condition type for table "v1.OfferToTags" */
export type v1_OfferToTags_on_conflict = {
  constraint: v1_OfferToTags_constraint;
  update_columns?: Array<v1_OfferToTags_update_column>;
  where?: InputMaybe<v1_OfferToTags_bool_exp>;
};

/** Ordering options when selecting data from "v1.OfferToTags". */
export type v1_OfferToTags_order_by = {
  Offer?: InputMaybe<v1_Offer_order_by>;
  OfferTag?: InputMaybe<v1_OfferTags_order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.OfferToTags */
export type v1_OfferToTags_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.OfferToTags" */
export enum v1_OfferToTags_select_column {
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  tag_id = 'tag_id'
}

/** input type for updating data in table "v1.OfferToTags" */
export type v1_OfferToTags_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_OfferToTags_stddev_fields = {
  __typename?: 'v1_OfferToTags_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_stddev_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_OfferToTags_stddev_pop_fields = {
  __typename?: 'v1_OfferToTags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_OfferToTags_stddev_samp_fields = {
  __typename?: 'v1_OfferToTags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_OfferToTags" */
export type v1_OfferToTags_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_OfferToTags_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_OfferToTags_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_OfferToTags_sum_fields = {
  __typename?: 'v1_OfferToTags_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_sum_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.OfferToTags" */
export enum v1_OfferToTags_update_column {
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  tag_id = 'tag_id'
}

export type v1_OfferToTags_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_OfferToTags_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_OfferToTags_set_input>;
  /** filter the rows which have to be updated */
  where: v1_OfferToTags_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_OfferToTags_var_pop_fields = {
  __typename?: 'v1_OfferToTags_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_OfferToTags_var_samp_fields = {
  __typename?: 'v1_OfferToTags_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_OfferToTags_variance_fields = {
  __typename?: 'v1_OfferToTags_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.OfferToTags" */
export type v1_OfferToTags_variance_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregated selection of "v1.Offer" */
export type v1_Offer_aggregate = {
  __typename?: 'v1_Offer_aggregate';
  aggregate?: Maybe<v1_Offer_aggregate_fields>;
  nodes: Array<v1_Offer>;
};

export type v1_Offer_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_Offer_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_Offer_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_Offer_aggregate_bool_exp_count>;
};

export type v1_Offer_aggregate_bool_exp_bool_and = {
  arguments: v1_Offer_select_column_v1_Offer_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Offer_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Offer_aggregate_bool_exp_bool_or = {
  arguments: v1_Offer_select_column_v1_Offer_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Offer_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Offer" */
export type v1_Offer_aggregate_fields = {
  __typename?: 'v1_Offer_aggregate_fields';
  avg?: Maybe<v1_Offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Offer_max_fields>;
  min?: Maybe<v1_Offer_min_fields>;
  stddev?: Maybe<v1_Offer_stddev_fields>;
  stddev_pop?: Maybe<v1_Offer_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Offer_stddev_samp_fields>;
  sum?: Maybe<v1_Offer_sum_fields>;
  var_pop?: Maybe<v1_Offer_var_pop_fields>;
  var_samp?: Maybe<v1_Offer_var_samp_fields>;
  variance?: Maybe<v1_Offer_variance_fields>;
};


/** aggregate fields of "v1.Offer" */
export type v1_Offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Offer" */
export type v1_Offer_aggregate_order_by = {
  avg?: InputMaybe<v1_Offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Offer_max_order_by>;
  min?: InputMaybe<v1_Offer_min_order_by>;
  stddev?: InputMaybe<v1_Offer_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Offer_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Offer_sum_order_by>;
  var_pop?: InputMaybe<v1_Offer_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Offer_var_samp_order_by>;
  variance?: InputMaybe<v1_Offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Offer" */
export type v1_Offer_arr_rel_insert_input = {
  data: Array<v1_Offer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Offer_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Offer_avg_fields = {
  __typename?: 'v1_Offer_avg_fields';
  DomainExtensionId?: Maybe<Scalars['Float']['output']>;
  ExternalPrice?: Maybe<Scalars['Float']['output']>;
  LanguageId?: Maybe<Scalars['Float']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  additional_price_percentage?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_price?: Maybe<Scalars['Float']['output']>;
  links_to_follow?: Maybe<Scalars['Float']['output']>;
  postduration_id?: Maybe<Scalars['Float']['output']>;
  requested_word_count?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  supplier_count?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Offer" */
export type v1_Offer_avg_order_by = {
  DomainExtensionId?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Offer". All fields are combined with a logical 'AND'. */
export type v1_Offer_bool_exp = {
  AddedOn?: InputMaybe<timestamptz_comparison_exp>;
  CheckoutDetails?: InputMaybe<v1_CheckoutDetail_bool_exp>;
  CheckoutDetails_aggregate?: InputMaybe<v1_CheckoutDetail_aggregate_bool_exp>;
  Company?: InputMaybe<v1_AccountBase_bool_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  DomainExtension?: InputMaybe<v1_DomainExtensions_bool_exp>;
  DomainExtensionId?: InputMaybe<Int_comparison_exp>;
  ExpiresOn?: InputMaybe<timestamptz_comparison_exp>;
  ExternalMarketplaceNote?: InputMaybe<String_comparison_exp>;
  ExternalPrice?: InputMaybe<numeric_comparison_exp>;
  IsHomepageLink?: InputMaybe<Boolean_comparison_exp>;
  Language?: InputMaybe<v1_new_languagesBase_bool_exp>;
  LanguageId?: InputMaybe<Int_comparison_exp>;
  LikedUsers?: InputMaybe<v1_UsersToOffers_bool_exp>;
  LikedUsers_aggregate?: InputMaybe<v1_UsersToOffers_aggregate_bool_exp>;
  MarketPlaceCategories?: InputMaybe<v1_MarketPlaceCategories_Offers_bool_exp>;
  MarketPlaceCategoriesId?: InputMaybe<Int_comparison_exp>;
  MarketPlaceCategories_aggregate?: InputMaybe<v1_MarketPlaceCategories_Offers_aggregate_bool_exp>;
  MarketPlaceOfferNiche?: InputMaybe<v1_MarketPlaceOfferNiche_bool_exp>;
  ModifiedOn?: InputMaybe<timestamptz_comparison_exp>;
  NicheId?: InputMaybe<Int_comparison_exp>;
  OfferPublisherPrice?: InputMaybe<Int_comparison_exp>;
  OfferStatus?: InputMaybe<v1_OfferStatus_bool_exp>;
  OfferToTags?: InputMaybe<v1_OfferToTags_bool_exp>;
  OfferToTags_aggregate?: InputMaybe<v1_OfferToTags_aggregate_bool_exp>;
  PostDuration?: InputMaybe<v1_PostDuration_bool_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  PublicationSample?: InputMaybe<String_comparison_exp>;
  RestrictedNiches?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
  RestrictedNiches_aggregate?: InputMaybe<v1_RestrictedNichesToOffer_aggregate_bool_exp>;
  ShowOnMarketplace?: InputMaybe<Boolean_comparison_exp>;
  Status?: InputMaybe<Int_comparison_exp>;
  Supplier?: InputMaybe<v1_ContactBase_bool_exp>;
  Website?: InputMaybe<v1_Website_bool_exp>;
  WebsiteTopic?: InputMaybe<v1_WebsiteTopics_bool_exp>;
  _and?: InputMaybe<Array<v1_Offer_bool_exp>>;
  _not?: InputMaybe<v1_Offer_bool_exp>;
  _or?: InputMaybe<Array<v1_Offer_bool_exp>>;
  additional_price_percentage?: InputMaybe<numeric_comparison_exp>;
  casino_accepted?: InputMaybe<Boolean_comparison_exp>;
  comp_related_websites?: InputMaybe<numeric_comparison_exp>;
  company_id?: InputMaybe<Int_comparison_exp>;
  created_on?: InputMaybe<timestamptz_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency?: InputMaybe<String_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  declined_on?: InputMaybe<timestamptz_comparison_exp>;
  do_follow?: InputMaybe<Boolean_comparison_exp>;
  follow_link?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  is_homepage?: InputMaybe<Boolean_comparison_exp>;
  link_price?: InputMaybe<numeric_comparison_exp>;
  link_price_old?: InputMaybe<String_comparison_exp>;
  links_to_follow?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  notes?: InputMaybe<String_comparison_exp>;
  outreach_one_id?: InputMaybe<String_comparison_exp>;
  postduration_id?: InputMaybe<Int_comparison_exp>;
  published_on?: InputMaybe<timestamptz_comparison_exp>;
  requested_word_count?: InputMaybe<Int_comparison_exp>;
  sent_to_approval_on?: InputMaybe<timestamptz_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  supplier?: InputMaybe<String_comparison_exp>;
  supplier_count?: InputMaybe<Int_comparison_exp>;
  supplier_crm_id?: InputMaybe<uuid_comparison_exp>;
  supplier_id?: InputMaybe<Int_comparison_exp>;
  supplier_name?: InputMaybe<String_comparison_exp>;
  unpublished_on?: InputMaybe<timestamptz_comparison_exp>;
  updated_on?: InputMaybe<timestamptz_comparison_exp>;
  website?: InputMaybe<String_comparison_exp>;
  website_id?: InputMaybe<Int_comparison_exp>;
  website_name?: InputMaybe<String_comparison_exp>;
  website_topic_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Offer" */
export enum v1_Offer_constraint {
  /** unique or primary key constraint on columns "id" */
  Offer_id_key = 'Offer_id_key',
  /** unique or primary key constraint on columns "id" */
  PK_offer = 'PK_offer'
}

/** input type for incrementing numeric columns in table "v1.Offer" */
export type v1_Offer_inc_input = {
  DomainExtensionId?: InputMaybe<Scalars['Int']['input']>;
  ExternalPrice?: InputMaybe<Scalars['numeric']['input']>;
  LanguageId?: InputMaybe<Scalars['Int']['input']>;
  MarketPlaceCategoriesId?: InputMaybe<Scalars['Int']['input']>;
  NicheId?: InputMaybe<Scalars['Int']['input']>;
  OfferPublisherPrice?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['Int']['input']>;
  additional_price_percentage?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  link_price?: InputMaybe<Scalars['numeric']['input']>;
  links_to_follow?: InputMaybe<Scalars['Int']['input']>;
  postduration_id?: InputMaybe<Scalars['Int']['input']>;
  requested_word_count?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  supplier_count?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
  website_topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Offer" */
export type v1_Offer_insert_input = {
  AddedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  CheckoutDetails?: InputMaybe<v1_CheckoutDetail_arr_rel_insert_input>;
  Company?: InputMaybe<v1_AccountBase_obj_rel_insert_input>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DomainExtension?: InputMaybe<v1_DomainExtensions_obj_rel_insert_input>;
  DomainExtensionId?: InputMaybe<Scalars['Int']['input']>;
  ExpiresOn?: InputMaybe<Scalars['timestamptz']['input']>;
  /** notes */
  ExternalMarketplaceNote?: InputMaybe<Scalars['String']['input']>;
  ExternalPrice?: InputMaybe<Scalars['numeric']['input']>;
  IsHomepageLink?: InputMaybe<Scalars['Boolean']['input']>;
  Language?: InputMaybe<v1_new_languagesBase_obj_rel_insert_input>;
  LanguageId?: InputMaybe<Scalars['Int']['input']>;
  LikedUsers?: InputMaybe<v1_UsersToOffers_arr_rel_insert_input>;
  MarketPlaceCategories?: InputMaybe<v1_MarketPlaceCategories_Offers_arr_rel_insert_input>;
  MarketPlaceCategoriesId?: InputMaybe<Scalars['Int']['input']>;
  MarketPlaceOfferNiche?: InputMaybe<v1_MarketPlaceOfferNiche_obj_rel_insert_input>;
  ModifiedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  NicheId?: InputMaybe<Scalars['Int']['input']>;
  OfferPublisherPrice?: InputMaybe<Scalars['Int']['input']>;
  OfferStatus?: InputMaybe<v1_OfferStatus_obj_rel_insert_input>;
  OfferToTags?: InputMaybe<v1_OfferToTags_arr_rel_insert_input>;
  PostDuration?: InputMaybe<v1_PostDuration_obj_rel_insert_input>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  /** Text field,  link */
  PublicationSample?: InputMaybe<Scalars['String']['input']>;
  RestrictedNiches?: InputMaybe<v1_RestrictedNichesToOffer_arr_rel_insert_input>;
  ShowOnMarketplace?: InputMaybe<Scalars['Boolean']['input']>;
  Status?: InputMaybe<Scalars['Int']['input']>;
  Supplier?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  Website?: InputMaybe<v1_Website_obj_rel_insert_input>;
  WebsiteTopic?: InputMaybe<v1_WebsiteTopics_obj_rel_insert_input>;
  additional_price_percentage?: InputMaybe<Scalars['numeric']['input']>;
  casino_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['timestamptz']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  declined_on?: InputMaybe<Scalars['timestamptz']['input']>;
  do_follow?: InputMaybe<Scalars['Boolean']['input']>;
  follow_link?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_homepage?: InputMaybe<Scalars['Boolean']['input']>;
  link_price?: InputMaybe<Scalars['numeric']['input']>;
  link_price_old?: InputMaybe<Scalars['String']['input']>;
  links_to_follow?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  outreach_one_id?: InputMaybe<Scalars['String']['input']>;
  postduration_id?: InputMaybe<Scalars['Int']['input']>;
  published_on?: InputMaybe<Scalars['timestamptz']['input']>;
  requested_word_count?: InputMaybe<Scalars['Int']['input']>;
  sent_to_approval_on?: InputMaybe<Scalars['timestamptz']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  supplier_count?: InputMaybe<Scalars['Int']['input']>;
  supplier_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_name?: InputMaybe<Scalars['String']['input']>;
  unpublished_on?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_on?: InputMaybe<Scalars['timestamptz']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
  website_name?: InputMaybe<Scalars['String']['input']>;
  website_topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Offer_max_fields = {
  __typename?: 'v1_Offer_max_fields';
  AddedOn?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DomainExtensionId?: Maybe<Scalars['Int']['output']>;
  ExpiresOn?: Maybe<Scalars['timestamptz']['output']>;
  /** notes */
  ExternalMarketplaceNote?: Maybe<Scalars['String']['output']>;
  ExternalPrice?: Maybe<Scalars['numeric']['output']>;
  LanguageId?: Maybe<Scalars['Int']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Int']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamptz']['output']>;
  NicheId?: Maybe<Scalars['Int']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Int']['output']>;
  /** Text field,  link */
  PublicationSample?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['Int']['output']>;
  additional_price_percentage?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['timestamptz']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  declined_on?: Maybe<Scalars['timestamptz']['output']>;
  follow_link?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  link_price?: Maybe<Scalars['numeric']['output']>;
  link_price_old?: Maybe<Scalars['String']['output']>;
  links_to_follow?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  outreach_one_id?: Maybe<Scalars['String']['output']>;
  postduration_id?: Maybe<Scalars['Int']['output']>;
  published_on?: Maybe<Scalars['timestamptz']['output']>;
  requested_word_count?: Maybe<Scalars['Int']['output']>;
  sent_to_approval_on?: Maybe<Scalars['timestamptz']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  supplier_count?: Maybe<Scalars['Int']['output']>;
  supplier_crm_id?: Maybe<Scalars['uuid']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  supplier_name?: Maybe<Scalars['String']['output']>;
  unpublished_on?: Maybe<Scalars['timestamptz']['output']>;
  updated_on?: Maybe<Scalars['timestamptz']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
  website_name?: Maybe<Scalars['String']['output']>;
  website_topic_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Offer" */
export type v1_Offer_max_order_by = {
  AddedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  DomainExtensionId?: InputMaybe<order_by>;
  ExpiresOn?: InputMaybe<order_by>;
  /** notes */
  ExternalMarketplaceNote?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  /** Text field,  link */
  PublicationSample?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declined_on?: InputMaybe<order_by>;
  follow_link?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  link_price_old?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  notes?: InputMaybe<order_by>;
  outreach_one_id?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  published_on?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  sent_to_approval_on?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_crm_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_name?: InputMaybe<order_by>;
  unpublished_on?: InputMaybe<order_by>;
  updated_on?: InputMaybe<order_by>;
  website?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_name?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Offer_min_fields = {
  __typename?: 'v1_Offer_min_fields';
  AddedOn?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DomainExtensionId?: Maybe<Scalars['Int']['output']>;
  ExpiresOn?: Maybe<Scalars['timestamptz']['output']>;
  /** notes */
  ExternalMarketplaceNote?: Maybe<Scalars['String']['output']>;
  ExternalPrice?: Maybe<Scalars['numeric']['output']>;
  LanguageId?: Maybe<Scalars['Int']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Int']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamptz']['output']>;
  NicheId?: Maybe<Scalars['Int']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Int']['output']>;
  /** Text field,  link */
  PublicationSample?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['Int']['output']>;
  additional_price_percentage?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['timestamptz']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  declined_on?: Maybe<Scalars['timestamptz']['output']>;
  follow_link?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  link_price?: Maybe<Scalars['numeric']['output']>;
  link_price_old?: Maybe<Scalars['String']['output']>;
  links_to_follow?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  outreach_one_id?: Maybe<Scalars['String']['output']>;
  postduration_id?: Maybe<Scalars['Int']['output']>;
  published_on?: Maybe<Scalars['timestamptz']['output']>;
  requested_word_count?: Maybe<Scalars['Int']['output']>;
  sent_to_approval_on?: Maybe<Scalars['timestamptz']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  supplier_count?: Maybe<Scalars['Int']['output']>;
  supplier_crm_id?: Maybe<Scalars['uuid']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  supplier_name?: Maybe<Scalars['String']['output']>;
  unpublished_on?: Maybe<Scalars['timestamptz']['output']>;
  updated_on?: Maybe<Scalars['timestamptz']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
  website_name?: Maybe<Scalars['String']['output']>;
  website_topic_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Offer" */
export type v1_Offer_min_order_by = {
  AddedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  DomainExtensionId?: InputMaybe<order_by>;
  ExpiresOn?: InputMaybe<order_by>;
  /** notes */
  ExternalMarketplaceNote?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  /** Text field,  link */
  PublicationSample?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declined_on?: InputMaybe<order_by>;
  follow_link?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  link_price_old?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  notes?: InputMaybe<order_by>;
  outreach_one_id?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  published_on?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  sent_to_approval_on?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_crm_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_name?: InputMaybe<order_by>;
  unpublished_on?: InputMaybe<order_by>;
  updated_on?: InputMaybe<order_by>;
  website?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_name?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Offer" */
export type v1_Offer_mutation_response = {
  __typename?: 'v1_Offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Offer>;
};

/** input type for inserting object relation for remote table "v1.Offer" */
export type v1_Offer_obj_rel_insert_input = {
  data: v1_Offer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Offer_on_conflict>;
};

/** on_conflict condition type for table "v1.Offer" */
export type v1_Offer_on_conflict = {
  constraint: v1_Offer_constraint;
  update_columns?: Array<v1_Offer_update_column>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};

/** Ordering options when selecting data from "v1.Offer". */
export type v1_Offer_order_by = {
  AddedOn?: InputMaybe<order_by>;
  CheckoutDetails_aggregate?: InputMaybe<v1_CheckoutDetail_aggregate_order_by>;
  Company?: InputMaybe<v1_AccountBase_order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  Description?: InputMaybe<order_by>;
  DomainExtension?: InputMaybe<v1_DomainExtensions_order_by>;
  DomainExtensionId?: InputMaybe<order_by>;
  ExpiresOn?: InputMaybe<order_by>;
  ExternalMarketplaceNote?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  IsHomepageLink?: InputMaybe<order_by>;
  Language?: InputMaybe<v1_new_languagesBase_order_by>;
  LanguageId?: InputMaybe<order_by>;
  LikedUsers_aggregate?: InputMaybe<v1_UsersToOffers_aggregate_order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  MarketPlaceCategories_aggregate?: InputMaybe<v1_MarketPlaceCategories_Offers_aggregate_order_by>;
  MarketPlaceOfferNiche?: InputMaybe<v1_MarketPlaceOfferNiche_order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  OfferStatus?: InputMaybe<v1_OfferStatus_order_by>;
  OfferToTags_aggregate?: InputMaybe<v1_OfferToTags_aggregate_order_by>;
  PostDuration?: InputMaybe<v1_PostDuration_order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  PublicationSample?: InputMaybe<order_by>;
  RestrictedNiches_aggregate?: InputMaybe<v1_RestrictedNichesToOffer_aggregate_order_by>;
  ShowOnMarketplace?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  Supplier?: InputMaybe<v1_ContactBase_order_by>;
  Website?: InputMaybe<v1_Website_order_by>;
  WebsiteTopic?: InputMaybe<v1_WebsiteTopics_order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  casino_accepted?: InputMaybe<order_by>;
  comp_related_websites?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declined_on?: InputMaybe<order_by>;
  do_follow?: InputMaybe<order_by>;
  follow_link?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  is_homepage?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  link_price_old?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  notes?: InputMaybe<order_by>;
  outreach_one_id?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  published_on?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  sent_to_approval_on?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_crm_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_name?: InputMaybe<order_by>;
  unpublished_on?: InputMaybe<order_by>;
  updated_on?: InputMaybe<order_by>;
  website?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_name?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Offer */
export type v1_Offer_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Offer" */
export enum v1_Offer_select_column {
  /** column name */
  AddedOn = 'AddedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  DomainExtensionId = 'DomainExtensionId',
  /** column name */
  ExpiresOn = 'ExpiresOn',
  /** column name */
  ExternalMarketplaceNote = 'ExternalMarketplaceNote',
  /** column name */
  ExternalPrice = 'ExternalPrice',
  /** column name */
  IsHomepageLink = 'IsHomepageLink',
  /** column name */
  LanguageId = 'LanguageId',
  /** column name */
  MarketPlaceCategoriesId = 'MarketPlaceCategoriesId',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  NicheId = 'NicheId',
  /** column name */
  OfferPublisherPrice = 'OfferPublisherPrice',
  /** column name */
  PublicationSample = 'PublicationSample',
  /** column name */
  ShowOnMarketplace = 'ShowOnMarketplace',
  /** column name */
  Status = 'Status',
  /** column name */
  additional_price_percentage = 'additional_price_percentage',
  /** column name */
  casino_accepted = 'casino_accepted',
  /** column name */
  company_id = 'company_id',
  /** column name */
  created_on = 'created_on',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency = 'currency',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  declined_on = 'declined_on',
  /** column name */
  do_follow = 'do_follow',
  /** column name */
  follow_link = 'follow_link',
  /** column name */
  id = 'id',
  /** column name */
  is_homepage = 'is_homepage',
  /** column name */
  link_price = 'link_price',
  /** column name */
  link_price_old = 'link_price_old',
  /** column name */
  links_to_follow = 'links_to_follow',
  /** column name */
  name = 'name',
  /** column name */
  notes = 'notes',
  /** column name */
  outreach_one_id = 'outreach_one_id',
  /** column name */
  postduration_id = 'postduration_id',
  /** column name */
  published_on = 'published_on',
  /** column name */
  requested_word_count = 'requested_word_count',
  /** column name */
  sent_to_approval_on = 'sent_to_approval_on',
  /** column name */
  statecode = 'statecode',
  /** column name */
  supplier = 'supplier',
  /** column name */
  supplier_count = 'supplier_count',
  /** column name */
  supplier_crm_id = 'supplier_crm_id',
  /** column name */
  supplier_id = 'supplier_id',
  /** column name */
  supplier_name = 'supplier_name',
  /** column name */
  unpublished_on = 'unpublished_on',
  /** column name */
  updated_on = 'updated_on',
  /** column name */
  website = 'website',
  /** column name */
  website_id = 'website_id',
  /** column name */
  website_name = 'website_name',
  /** column name */
  website_topic_id = 'website_topic_id'
}

/** select "v1_Offer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.Offer" */
export enum v1_Offer_select_column_v1_Offer_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  IsHomepageLink = 'IsHomepageLink',
  /** column name */
  ShowOnMarketplace = 'ShowOnMarketplace',
  /** column name */
  casino_accepted = 'casino_accepted',
  /** column name */
  do_follow = 'do_follow',
  /** column name */
  is_homepage = 'is_homepage'
}

/** select "v1_Offer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.Offer" */
export enum v1_Offer_select_column_v1_Offer_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  IsHomepageLink = 'IsHomepageLink',
  /** column name */
  ShowOnMarketplace = 'ShowOnMarketplace',
  /** column name */
  casino_accepted = 'casino_accepted',
  /** column name */
  do_follow = 'do_follow',
  /** column name */
  is_homepage = 'is_homepage'
}

/** input type for updating data in table "v1.Offer" */
export type v1_Offer_set_input = {
  AddedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DomainExtensionId?: InputMaybe<Scalars['Int']['input']>;
  ExpiresOn?: InputMaybe<Scalars['timestamptz']['input']>;
  /** notes */
  ExternalMarketplaceNote?: InputMaybe<Scalars['String']['input']>;
  ExternalPrice?: InputMaybe<Scalars['numeric']['input']>;
  IsHomepageLink?: InputMaybe<Scalars['Boolean']['input']>;
  LanguageId?: InputMaybe<Scalars['Int']['input']>;
  MarketPlaceCategoriesId?: InputMaybe<Scalars['Int']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  NicheId?: InputMaybe<Scalars['Int']['input']>;
  OfferPublisherPrice?: InputMaybe<Scalars['Int']['input']>;
  /** Text field,  link */
  PublicationSample?: InputMaybe<Scalars['String']['input']>;
  ShowOnMarketplace?: InputMaybe<Scalars['Boolean']['input']>;
  Status?: InputMaybe<Scalars['Int']['input']>;
  additional_price_percentage?: InputMaybe<Scalars['numeric']['input']>;
  casino_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['timestamptz']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  declined_on?: InputMaybe<Scalars['timestamptz']['input']>;
  do_follow?: InputMaybe<Scalars['Boolean']['input']>;
  follow_link?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_homepage?: InputMaybe<Scalars['Boolean']['input']>;
  link_price?: InputMaybe<Scalars['numeric']['input']>;
  link_price_old?: InputMaybe<Scalars['String']['input']>;
  links_to_follow?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  outreach_one_id?: InputMaybe<Scalars['String']['input']>;
  postduration_id?: InputMaybe<Scalars['Int']['input']>;
  published_on?: InputMaybe<Scalars['timestamptz']['input']>;
  requested_word_count?: InputMaybe<Scalars['Int']['input']>;
  sent_to_approval_on?: InputMaybe<Scalars['timestamptz']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  supplier_count?: InputMaybe<Scalars['Int']['input']>;
  supplier_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_name?: InputMaybe<Scalars['String']['input']>;
  unpublished_on?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_on?: InputMaybe<Scalars['timestamptz']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
  website_name?: InputMaybe<Scalars['String']['input']>;
  website_topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Offer_stddev_fields = {
  __typename?: 'v1_Offer_stddev_fields';
  DomainExtensionId?: Maybe<Scalars['Float']['output']>;
  ExternalPrice?: Maybe<Scalars['Float']['output']>;
  LanguageId?: Maybe<Scalars['Float']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  additional_price_percentage?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_price?: Maybe<Scalars['Float']['output']>;
  links_to_follow?: Maybe<Scalars['Float']['output']>;
  postduration_id?: Maybe<Scalars['Float']['output']>;
  requested_word_count?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  supplier_count?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Offer" */
export type v1_Offer_stddev_order_by = {
  DomainExtensionId?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Offer_stddev_pop_fields = {
  __typename?: 'v1_Offer_stddev_pop_fields';
  DomainExtensionId?: Maybe<Scalars['Float']['output']>;
  ExternalPrice?: Maybe<Scalars['Float']['output']>;
  LanguageId?: Maybe<Scalars['Float']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  additional_price_percentage?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_price?: Maybe<Scalars['Float']['output']>;
  links_to_follow?: Maybe<Scalars['Float']['output']>;
  postduration_id?: Maybe<Scalars['Float']['output']>;
  requested_word_count?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  supplier_count?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Offer" */
export type v1_Offer_stddev_pop_order_by = {
  DomainExtensionId?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Offer_stddev_samp_fields = {
  __typename?: 'v1_Offer_stddev_samp_fields';
  DomainExtensionId?: Maybe<Scalars['Float']['output']>;
  ExternalPrice?: Maybe<Scalars['Float']['output']>;
  LanguageId?: Maybe<Scalars['Float']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  additional_price_percentage?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_price?: Maybe<Scalars['Float']['output']>;
  links_to_follow?: Maybe<Scalars['Float']['output']>;
  postduration_id?: Maybe<Scalars['Float']['output']>;
  requested_word_count?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  supplier_count?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Offer" */
export type v1_Offer_stddev_samp_order_by = {
  DomainExtensionId?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Offer" */
export type v1_Offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Offer_stream_cursor_value_input = {
  AddedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DomainExtensionId?: InputMaybe<Scalars['Int']['input']>;
  ExpiresOn?: InputMaybe<Scalars['timestamptz']['input']>;
  /** notes */
  ExternalMarketplaceNote?: InputMaybe<Scalars['String']['input']>;
  ExternalPrice?: InputMaybe<Scalars['numeric']['input']>;
  IsHomepageLink?: InputMaybe<Scalars['Boolean']['input']>;
  LanguageId?: InputMaybe<Scalars['Int']['input']>;
  MarketPlaceCategoriesId?: InputMaybe<Scalars['Int']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  NicheId?: InputMaybe<Scalars['Int']['input']>;
  OfferPublisherPrice?: InputMaybe<Scalars['Int']['input']>;
  /** Text field,  link */
  PublicationSample?: InputMaybe<Scalars['String']['input']>;
  ShowOnMarketplace?: InputMaybe<Scalars['Boolean']['input']>;
  Status?: InputMaybe<Scalars['Int']['input']>;
  additional_price_percentage?: InputMaybe<Scalars['numeric']['input']>;
  casino_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['timestamptz']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  declined_on?: InputMaybe<Scalars['timestamptz']['input']>;
  do_follow?: InputMaybe<Scalars['Boolean']['input']>;
  follow_link?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_homepage?: InputMaybe<Scalars['Boolean']['input']>;
  link_price?: InputMaybe<Scalars['numeric']['input']>;
  link_price_old?: InputMaybe<Scalars['String']['input']>;
  links_to_follow?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  outreach_one_id?: InputMaybe<Scalars['String']['input']>;
  postduration_id?: InputMaybe<Scalars['Int']['input']>;
  published_on?: InputMaybe<Scalars['timestamptz']['input']>;
  requested_word_count?: InputMaybe<Scalars['Int']['input']>;
  sent_to_approval_on?: InputMaybe<Scalars['timestamptz']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  supplier_count?: InputMaybe<Scalars['Int']['input']>;
  supplier_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_name?: InputMaybe<Scalars['String']['input']>;
  unpublished_on?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_on?: InputMaybe<Scalars['timestamptz']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
  website_name?: InputMaybe<Scalars['String']['input']>;
  website_topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Offer_sum_fields = {
  __typename?: 'v1_Offer_sum_fields';
  DomainExtensionId?: Maybe<Scalars['Int']['output']>;
  ExternalPrice?: Maybe<Scalars['numeric']['output']>;
  LanguageId?: Maybe<Scalars['Int']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Int']['output']>;
  NicheId?: Maybe<Scalars['Int']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['Int']['output']>;
  additional_price_percentage?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  link_price?: Maybe<Scalars['numeric']['output']>;
  links_to_follow?: Maybe<Scalars['Int']['output']>;
  postduration_id?: Maybe<Scalars['Int']['output']>;
  requested_word_count?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  supplier_count?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
  website_topic_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Offer" */
export type v1_Offer_sum_order_by = {
  DomainExtensionId?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Offer" */
export enum v1_Offer_update_column {
  /** column name */
  AddedOn = 'AddedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  DomainExtensionId = 'DomainExtensionId',
  /** column name */
  ExpiresOn = 'ExpiresOn',
  /** column name */
  ExternalMarketplaceNote = 'ExternalMarketplaceNote',
  /** column name */
  ExternalPrice = 'ExternalPrice',
  /** column name */
  IsHomepageLink = 'IsHomepageLink',
  /** column name */
  LanguageId = 'LanguageId',
  /** column name */
  MarketPlaceCategoriesId = 'MarketPlaceCategoriesId',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  NicheId = 'NicheId',
  /** column name */
  OfferPublisherPrice = 'OfferPublisherPrice',
  /** column name */
  PublicationSample = 'PublicationSample',
  /** column name */
  ShowOnMarketplace = 'ShowOnMarketplace',
  /** column name */
  Status = 'Status',
  /** column name */
  additional_price_percentage = 'additional_price_percentage',
  /** column name */
  casino_accepted = 'casino_accepted',
  /** column name */
  company_id = 'company_id',
  /** column name */
  created_on = 'created_on',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency = 'currency',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  declined_on = 'declined_on',
  /** column name */
  do_follow = 'do_follow',
  /** column name */
  follow_link = 'follow_link',
  /** column name */
  id = 'id',
  /** column name */
  is_homepage = 'is_homepage',
  /** column name */
  link_price = 'link_price',
  /** column name */
  link_price_old = 'link_price_old',
  /** column name */
  links_to_follow = 'links_to_follow',
  /** column name */
  name = 'name',
  /** column name */
  notes = 'notes',
  /** column name */
  outreach_one_id = 'outreach_one_id',
  /** column name */
  postduration_id = 'postduration_id',
  /** column name */
  published_on = 'published_on',
  /** column name */
  requested_word_count = 'requested_word_count',
  /** column name */
  sent_to_approval_on = 'sent_to_approval_on',
  /** column name */
  statecode = 'statecode',
  /** column name */
  supplier = 'supplier',
  /** column name */
  supplier_count = 'supplier_count',
  /** column name */
  supplier_crm_id = 'supplier_crm_id',
  /** column name */
  supplier_id = 'supplier_id',
  /** column name */
  supplier_name = 'supplier_name',
  /** column name */
  unpublished_on = 'unpublished_on',
  /** column name */
  updated_on = 'updated_on',
  /** column name */
  website = 'website',
  /** column name */
  website_id = 'website_id',
  /** column name */
  website_name = 'website_name',
  /** column name */
  website_topic_id = 'website_topic_id'
}

export type v1_Offer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Offer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Offer_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Offer_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Offer_var_pop_fields = {
  __typename?: 'v1_Offer_var_pop_fields';
  DomainExtensionId?: Maybe<Scalars['Float']['output']>;
  ExternalPrice?: Maybe<Scalars['Float']['output']>;
  LanguageId?: Maybe<Scalars['Float']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  additional_price_percentage?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_price?: Maybe<Scalars['Float']['output']>;
  links_to_follow?: Maybe<Scalars['Float']['output']>;
  postduration_id?: Maybe<Scalars['Float']['output']>;
  requested_word_count?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  supplier_count?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Offer" */
export type v1_Offer_var_pop_order_by = {
  DomainExtensionId?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Offer_var_samp_fields = {
  __typename?: 'v1_Offer_var_samp_fields';
  DomainExtensionId?: Maybe<Scalars['Float']['output']>;
  ExternalPrice?: Maybe<Scalars['Float']['output']>;
  LanguageId?: Maybe<Scalars['Float']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  additional_price_percentage?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_price?: Maybe<Scalars['Float']['output']>;
  links_to_follow?: Maybe<Scalars['Float']['output']>;
  postduration_id?: Maybe<Scalars['Float']['output']>;
  requested_word_count?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  supplier_count?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Offer" */
export type v1_Offer_var_samp_order_by = {
  DomainExtensionId?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Offer_variance_fields = {
  __typename?: 'v1_Offer_variance_fields';
  DomainExtensionId?: Maybe<Scalars['Float']['output']>;
  ExternalPrice?: Maybe<Scalars['Float']['output']>;
  LanguageId?: Maybe<Scalars['Float']['output']>;
  MarketPlaceCategoriesId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferPublisherPrice?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['Float']['output']>;
  additional_price_percentage?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_price?: Maybe<Scalars['Float']['output']>;
  links_to_follow?: Maybe<Scalars['Float']['output']>;
  postduration_id?: Maybe<Scalars['Float']['output']>;
  requested_word_count?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  supplier_count?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Offer" */
export type v1_Offer_variance_order_by = {
  DomainExtensionId?: InputMaybe<order_by>;
  ExternalPrice?: InputMaybe<order_by>;
  LanguageId?: InputMaybe<order_by>;
  MarketPlaceCategoriesId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferPublisherPrice?: InputMaybe<order_by>;
  Status?: InputMaybe<order_by>;
  additional_price_percentage?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  link_price?: InputMaybe<order_by>;
  links_to_follow?: InputMaybe<order_by>;
  postduration_id?: InputMaybe<order_by>;
  requested_word_count?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  supplier_count?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.OrderFor" */
export type v1_OrderFor = {
  __typename?: 'v1_OrderFor';
  /** An array relationship */
  SalesOrderBases: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  SalesOrderBases_aggregate: v1_SalesOrderBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.OrderFor" */
export type v1_OrderForSalesOrderBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.OrderFor" */
export type v1_OrderForSalesOrderBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};

/** aggregated selection of "v1.OrderFor" */
export type v1_OrderFor_aggregate = {
  __typename?: 'v1_OrderFor_aggregate';
  aggregate?: Maybe<v1_OrderFor_aggregate_fields>;
  nodes: Array<v1_OrderFor>;
};

/** aggregate fields of "v1.OrderFor" */
export type v1_OrderFor_aggregate_fields = {
  __typename?: 'v1_OrderFor_aggregate_fields';
  avg?: Maybe<v1_OrderFor_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_OrderFor_max_fields>;
  min?: Maybe<v1_OrderFor_min_fields>;
  stddev?: Maybe<v1_OrderFor_stddev_fields>;
  stddev_pop?: Maybe<v1_OrderFor_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_OrderFor_stddev_samp_fields>;
  sum?: Maybe<v1_OrderFor_sum_fields>;
  var_pop?: Maybe<v1_OrderFor_var_pop_fields>;
  var_samp?: Maybe<v1_OrderFor_var_samp_fields>;
  variance?: Maybe<v1_OrderFor_variance_fields>;
};


/** aggregate fields of "v1.OrderFor" */
export type v1_OrderFor_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_OrderFor_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_OrderFor_avg_fields = {
  __typename?: 'v1_OrderFor_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.OrderFor". All fields are combined with a logical 'AND'. */
export type v1_OrderFor_bool_exp = {
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_OrderFor_bool_exp>>;
  _not?: InputMaybe<v1_OrderFor_bool_exp>;
  _or?: InputMaybe<Array<v1_OrderFor_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.OrderFor" */
export enum v1_OrderFor_constraint {
  /** unique or primary key constraint on columns "id" */
  OrderFor_pkey = 'OrderFor_pkey'
}

/** input type for incrementing numeric columns in table "v1.OrderFor" */
export type v1_OrderFor_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.OrderFor" */
export type v1_OrderFor_insert_input = {
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_OrderFor_max_fields = {
  __typename?: 'v1_OrderFor_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_OrderFor_min_fields = {
  __typename?: 'v1_OrderFor_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.OrderFor" */
export type v1_OrderFor_mutation_response = {
  __typename?: 'v1_OrderFor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_OrderFor>;
};

/** input type for inserting object relation for remote table "v1.OrderFor" */
export type v1_OrderFor_obj_rel_insert_input = {
  data: v1_OrderFor_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_OrderFor_on_conflict>;
};

/** on_conflict condition type for table "v1.OrderFor" */
export type v1_OrderFor_on_conflict = {
  constraint: v1_OrderFor_constraint;
  update_columns?: Array<v1_OrderFor_update_column>;
  where?: InputMaybe<v1_OrderFor_bool_exp>;
};

/** Ordering options when selecting data from "v1.OrderFor". */
export type v1_OrderFor_order_by = {
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.OrderFor */
export type v1_OrderFor_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.OrderFor" */
export enum v1_OrderFor_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.OrderFor" */
export type v1_OrderFor_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_OrderFor_stddev_fields = {
  __typename?: 'v1_OrderFor_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_OrderFor_stddev_pop_fields = {
  __typename?: 'v1_OrderFor_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_OrderFor_stddev_samp_fields = {
  __typename?: 'v1_OrderFor_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_OrderFor" */
export type v1_OrderFor_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_OrderFor_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_OrderFor_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_OrderFor_sum_fields = {
  __typename?: 'v1_OrderFor_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.OrderFor" */
export enum v1_OrderFor_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_OrderFor_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_OrderFor_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_OrderFor_set_input>;
  /** filter the rows which have to be updated */
  where: v1_OrderFor_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_OrderFor_var_pop_fields = {
  __typename?: 'v1_OrderFor_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_OrderFor_var_samp_fields = {
  __typename?: 'v1_OrderFor_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_OrderFor_variance_fields = {
  __typename?: 'v1_OrderFor_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.OrderPayTo" */
export type v1_OrderPayTo = {
  __typename?: 'v1_OrderPayTo';
  /** An array relationship */
  SalesOrderBases: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  SalesOrderBases_aggregate: v1_SalesOrderBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.OrderPayTo" */
export type v1_OrderPayToSalesOrderBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.OrderPayTo" */
export type v1_OrderPayToSalesOrderBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};

/** aggregated selection of "v1.OrderPayTo" */
export type v1_OrderPayTo_aggregate = {
  __typename?: 'v1_OrderPayTo_aggregate';
  aggregate?: Maybe<v1_OrderPayTo_aggregate_fields>;
  nodes: Array<v1_OrderPayTo>;
};

/** aggregate fields of "v1.OrderPayTo" */
export type v1_OrderPayTo_aggregate_fields = {
  __typename?: 'v1_OrderPayTo_aggregate_fields';
  avg?: Maybe<v1_OrderPayTo_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_OrderPayTo_max_fields>;
  min?: Maybe<v1_OrderPayTo_min_fields>;
  stddev?: Maybe<v1_OrderPayTo_stddev_fields>;
  stddev_pop?: Maybe<v1_OrderPayTo_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_OrderPayTo_stddev_samp_fields>;
  sum?: Maybe<v1_OrderPayTo_sum_fields>;
  var_pop?: Maybe<v1_OrderPayTo_var_pop_fields>;
  var_samp?: Maybe<v1_OrderPayTo_var_samp_fields>;
  variance?: Maybe<v1_OrderPayTo_variance_fields>;
};


/** aggregate fields of "v1.OrderPayTo" */
export type v1_OrderPayTo_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_OrderPayTo_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_OrderPayTo_avg_fields = {
  __typename?: 'v1_OrderPayTo_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.OrderPayTo". All fields are combined with a logical 'AND'. */
export type v1_OrderPayTo_bool_exp = {
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_OrderPayTo_bool_exp>>;
  _not?: InputMaybe<v1_OrderPayTo_bool_exp>;
  _or?: InputMaybe<Array<v1_OrderPayTo_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.OrderPayTo" */
export enum v1_OrderPayTo_constraint {
  /** unique or primary key constraint on columns "id" */
  OrderPayTo_pkey = 'OrderPayTo_pkey'
}

/** input type for incrementing numeric columns in table "v1.OrderPayTo" */
export type v1_OrderPayTo_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.OrderPayTo" */
export type v1_OrderPayTo_insert_input = {
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_OrderPayTo_max_fields = {
  __typename?: 'v1_OrderPayTo_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_OrderPayTo_min_fields = {
  __typename?: 'v1_OrderPayTo_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.OrderPayTo" */
export type v1_OrderPayTo_mutation_response = {
  __typename?: 'v1_OrderPayTo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_OrderPayTo>;
};

/** on_conflict condition type for table "v1.OrderPayTo" */
export type v1_OrderPayTo_on_conflict = {
  constraint: v1_OrderPayTo_constraint;
  update_columns?: Array<v1_OrderPayTo_update_column>;
  where?: InputMaybe<v1_OrderPayTo_bool_exp>;
};

/** Ordering options when selecting data from "v1.OrderPayTo". */
export type v1_OrderPayTo_order_by = {
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.OrderPayTo */
export type v1_OrderPayTo_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.OrderPayTo" */
export enum v1_OrderPayTo_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.OrderPayTo" */
export type v1_OrderPayTo_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_OrderPayTo_stddev_fields = {
  __typename?: 'v1_OrderPayTo_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_OrderPayTo_stddev_pop_fields = {
  __typename?: 'v1_OrderPayTo_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_OrderPayTo_stddev_samp_fields = {
  __typename?: 'v1_OrderPayTo_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_OrderPayTo" */
export type v1_OrderPayTo_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_OrderPayTo_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_OrderPayTo_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_OrderPayTo_sum_fields = {
  __typename?: 'v1_OrderPayTo_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.OrderPayTo" */
export enum v1_OrderPayTo_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_OrderPayTo_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_OrderPayTo_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_OrderPayTo_set_input>;
  /** filter the rows which have to be updated */
  where: v1_OrderPayTo_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_OrderPayTo_var_pop_fields = {
  __typename?: 'v1_OrderPayTo_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_OrderPayTo_var_samp_fields = {
  __typename?: 'v1_OrderPayTo_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_OrderPayTo_variance_fields = {
  __typename?: 'v1_OrderPayTo_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.OrderPriority" */
export type v1_OrderPriority = {
  __typename?: 'v1_OrderPriority';
  /** An array relationship */
  Orders: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  Orders_aggregate: v1_SalesOrderBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.OrderPriority" */
export type v1_OrderPriorityOrdersArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.OrderPriority" */
export type v1_OrderPriorityOrders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};

/** aggregated selection of "v1.OrderPriority" */
export type v1_OrderPriority_aggregate = {
  __typename?: 'v1_OrderPriority_aggregate';
  aggregate?: Maybe<v1_OrderPriority_aggregate_fields>;
  nodes: Array<v1_OrderPriority>;
};

/** aggregate fields of "v1.OrderPriority" */
export type v1_OrderPriority_aggregate_fields = {
  __typename?: 'v1_OrderPriority_aggregate_fields';
  avg?: Maybe<v1_OrderPriority_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_OrderPriority_max_fields>;
  min?: Maybe<v1_OrderPriority_min_fields>;
  stddev?: Maybe<v1_OrderPriority_stddev_fields>;
  stddev_pop?: Maybe<v1_OrderPriority_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_OrderPriority_stddev_samp_fields>;
  sum?: Maybe<v1_OrderPriority_sum_fields>;
  var_pop?: Maybe<v1_OrderPriority_var_pop_fields>;
  var_samp?: Maybe<v1_OrderPriority_var_samp_fields>;
  variance?: Maybe<v1_OrderPriority_variance_fields>;
};


/** aggregate fields of "v1.OrderPriority" */
export type v1_OrderPriority_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_OrderPriority_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_OrderPriority_avg_fields = {
  __typename?: 'v1_OrderPriority_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.OrderPriority". All fields are combined with a logical 'AND'. */
export type v1_OrderPriority_bool_exp = {
  Orders?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  Orders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_OrderPriority_bool_exp>>;
  _not?: InputMaybe<v1_OrderPriority_bool_exp>;
  _or?: InputMaybe<Array<v1_OrderPriority_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.OrderPriority" */
export enum v1_OrderPriority_constraint {
  /** unique or primary key constraint on columns "id" */
  OrderPriority_pkey = 'OrderPriority_pkey'
}

/** input type for incrementing numeric columns in table "v1.OrderPriority" */
export type v1_OrderPriority_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.OrderPriority" */
export type v1_OrderPriority_insert_input = {
  Orders?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_OrderPriority_max_fields = {
  __typename?: 'v1_OrderPriority_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_OrderPriority_min_fields = {
  __typename?: 'v1_OrderPriority_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.OrderPriority" */
export type v1_OrderPriority_mutation_response = {
  __typename?: 'v1_OrderPriority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_OrderPriority>;
};

/** input type for inserting object relation for remote table "v1.OrderPriority" */
export type v1_OrderPriority_obj_rel_insert_input = {
  data: v1_OrderPriority_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_OrderPriority_on_conflict>;
};

/** on_conflict condition type for table "v1.OrderPriority" */
export type v1_OrderPriority_on_conflict = {
  constraint: v1_OrderPriority_constraint;
  update_columns?: Array<v1_OrderPriority_update_column>;
  where?: InputMaybe<v1_OrderPriority_bool_exp>;
};

/** Ordering options when selecting data from "v1.OrderPriority". */
export type v1_OrderPriority_order_by = {
  Orders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.OrderPriority */
export type v1_OrderPriority_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.OrderPriority" */
export enum v1_OrderPriority_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.OrderPriority" */
export type v1_OrderPriority_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_OrderPriority_stddev_fields = {
  __typename?: 'v1_OrderPriority_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_OrderPriority_stddev_pop_fields = {
  __typename?: 'v1_OrderPriority_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_OrderPriority_stddev_samp_fields = {
  __typename?: 'v1_OrderPriority_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_OrderPriority" */
export type v1_OrderPriority_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_OrderPriority_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_OrderPriority_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_OrderPriority_sum_fields = {
  __typename?: 'v1_OrderPriority_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.OrderPriority" */
export enum v1_OrderPriority_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_OrderPriority_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_OrderPriority_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_OrderPriority_set_input>;
  /** filter the rows which have to be updated */
  where: v1_OrderPriority_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_OrderPriority_var_pop_fields = {
  __typename?: 'v1_OrderPriority_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_OrderPriority_var_samp_fields = {
  __typename?: 'v1_OrderPriority_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_OrderPriority_variance_fields = {
  __typename?: 'v1_OrderPriority_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.OrderType" */
export type v1_OrderType = {
  __typename?: 'v1_OrderType';
  /** An array relationship */
  SalesOrderBases: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  SalesOrderBases_aggregate: v1_SalesOrderBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.OrderType" */
export type v1_OrderTypeSalesOrderBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.OrderType" */
export type v1_OrderTypeSalesOrderBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};

/** aggregated selection of "v1.OrderType" */
export type v1_OrderType_aggregate = {
  __typename?: 'v1_OrderType_aggregate';
  aggregate?: Maybe<v1_OrderType_aggregate_fields>;
  nodes: Array<v1_OrderType>;
};

/** aggregate fields of "v1.OrderType" */
export type v1_OrderType_aggregate_fields = {
  __typename?: 'v1_OrderType_aggregate_fields';
  avg?: Maybe<v1_OrderType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_OrderType_max_fields>;
  min?: Maybe<v1_OrderType_min_fields>;
  stddev?: Maybe<v1_OrderType_stddev_fields>;
  stddev_pop?: Maybe<v1_OrderType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_OrderType_stddev_samp_fields>;
  sum?: Maybe<v1_OrderType_sum_fields>;
  var_pop?: Maybe<v1_OrderType_var_pop_fields>;
  var_samp?: Maybe<v1_OrderType_var_samp_fields>;
  variance?: Maybe<v1_OrderType_variance_fields>;
};


/** aggregate fields of "v1.OrderType" */
export type v1_OrderType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_OrderType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_OrderType_avg_fields = {
  __typename?: 'v1_OrderType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.OrderType". All fields are combined with a logical 'AND'. */
export type v1_OrderType_bool_exp = {
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_OrderType_bool_exp>>;
  _not?: InputMaybe<v1_OrderType_bool_exp>;
  _or?: InputMaybe<Array<v1_OrderType_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.OrderType" */
export enum v1_OrderType_constraint {
  /** unique or primary key constraint on columns "id" */
  OrderType_pkey = 'OrderType_pkey'
}

/** input type for incrementing numeric columns in table "v1.OrderType" */
export type v1_OrderType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.OrderType" */
export type v1_OrderType_insert_input = {
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_OrderType_max_fields = {
  __typename?: 'v1_OrderType_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_OrderType_min_fields = {
  __typename?: 'v1_OrderType_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.OrderType" */
export type v1_OrderType_mutation_response = {
  __typename?: 'v1_OrderType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_OrderType>;
};

/** input type for inserting object relation for remote table "v1.OrderType" */
export type v1_OrderType_obj_rel_insert_input = {
  data: v1_OrderType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_OrderType_on_conflict>;
};

/** on_conflict condition type for table "v1.OrderType" */
export type v1_OrderType_on_conflict = {
  constraint: v1_OrderType_constraint;
  update_columns?: Array<v1_OrderType_update_column>;
  where?: InputMaybe<v1_OrderType_bool_exp>;
};

/** Ordering options when selecting data from "v1.OrderType". */
export type v1_OrderType_order_by = {
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.OrderType */
export type v1_OrderType_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.OrderType" */
export enum v1_OrderType_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.OrderType" */
export type v1_OrderType_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_OrderType_stddev_fields = {
  __typename?: 'v1_OrderType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_OrderType_stddev_pop_fields = {
  __typename?: 'v1_OrderType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_OrderType_stddev_samp_fields = {
  __typename?: 'v1_OrderType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_OrderType" */
export type v1_OrderType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_OrderType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_OrderType_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_OrderType_sum_fields = {
  __typename?: 'v1_OrderType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.OrderType" */
export enum v1_OrderType_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_OrderType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_OrderType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_OrderType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_OrderType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_OrderType_var_pop_fields = {
  __typename?: 'v1_OrderType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_OrderType_var_samp_fields = {
  __typename?: 'v1_OrderType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_OrderType_variance_fields = {
  __typename?: 'v1_OrderType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.OutreachPosts" */
export type v1_OutreachPosts = {
  __typename?: 'v1_OutreachPosts';
  id?: Maybe<Scalars['Int']['output']>;
  new_Anchortext1?: Maybe<Scalars['String']['output']>;
  new_Anchortext2?: Maybe<Scalars['String']['output']>;
  new_Anchortext3?: Maybe<Scalars['String']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Int']['output']>;
  new_OnlineStatusCheckDate?: Maybe<Scalars['timestamp']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Int']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Int']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_PostStatus?: Maybe<Scalars['Int']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "v1.OutreachPosts" */
export type v1_OutreachPosts_aggregate = {
  __typename?: 'v1_OutreachPosts_aggregate';
  aggregate?: Maybe<v1_OutreachPosts_aggregate_fields>;
  nodes: Array<v1_OutreachPosts>;
};

/** aggregate fields of "v1.OutreachPosts" */
export type v1_OutreachPosts_aggregate_fields = {
  __typename?: 'v1_OutreachPosts_aggregate_fields';
  avg?: Maybe<v1_OutreachPosts_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_OutreachPosts_max_fields>;
  min?: Maybe<v1_OutreachPosts_min_fields>;
  stddev?: Maybe<v1_OutreachPosts_stddev_fields>;
  stddev_pop?: Maybe<v1_OutreachPosts_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_OutreachPosts_stddev_samp_fields>;
  sum?: Maybe<v1_OutreachPosts_sum_fields>;
  var_pop?: Maybe<v1_OutreachPosts_var_pop_fields>;
  var_samp?: Maybe<v1_OutreachPosts_var_samp_fields>;
  variance?: Maybe<v1_OutreachPosts_variance_fields>;
};


/** aggregate fields of "v1.OutreachPosts" */
export type v1_OutreachPosts_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_OutreachPosts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_OutreachPosts_avg_fields = {
  __typename?: 'v1_OutreachPosts_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.OutreachPosts". All fields are combined with a logical 'AND'. */
export type v1_OutreachPosts_bool_exp = {
  _and?: InputMaybe<Array<v1_OutreachPosts_bool_exp>>;
  _not?: InputMaybe<v1_OutreachPosts_bool_exp>;
  _or?: InputMaybe<Array<v1_OutreachPosts_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_Anchortext1?: InputMaybe<String_comparison_exp>;
  new_Anchortext2?: InputMaybe<String_comparison_exp>;
  new_Anchortext3?: InputMaybe<String_comparison_exp>;
  new_ContentOrderStatus?: InputMaybe<Int_comparison_exp>;
  new_OnlineStatusCheckDate?: InputMaybe<timestamp_comparison_exp>;
  new_OutreachStatus?: InputMaybe<Int_comparison_exp>;
  new_PostLinksStatus?: InputMaybe<Int_comparison_exp>;
  new_PostOnlineStatus?: InputMaybe<Int_comparison_exp>;
  new_PostStatus?: InputMaybe<Int_comparison_exp>;
  new_TargetURL1?: InputMaybe<String_comparison_exp>;
  new_TargetURL2?: InputMaybe<String_comparison_exp>;
  new_TargetURL3?: InputMaybe<String_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  plc_failed_attempts?: InputMaybe<Int_comparison_exp>;
};

/** aggregate max on columns */
export type v1_OutreachPosts_max_fields = {
  __typename?: 'v1_OutreachPosts_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_Anchortext1?: Maybe<Scalars['String']['output']>;
  new_Anchortext2?: Maybe<Scalars['String']['output']>;
  new_Anchortext3?: Maybe<Scalars['String']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Int']['output']>;
  new_OnlineStatusCheckDate?: Maybe<Scalars['timestamp']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Int']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Int']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_PostStatus?: Maybe<Scalars['Int']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_OutreachPosts_min_fields = {
  __typename?: 'v1_OutreachPosts_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_Anchortext1?: Maybe<Scalars['String']['output']>;
  new_Anchortext2?: Maybe<Scalars['String']['output']>;
  new_Anchortext3?: Maybe<Scalars['String']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Int']['output']>;
  new_OnlineStatusCheckDate?: Maybe<Scalars['timestamp']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Int']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Int']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_PostStatus?: Maybe<Scalars['Int']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "v1.OutreachPosts". */
export type v1_OutreachPosts_order_by = {
  id?: InputMaybe<order_by>;
  new_Anchortext1?: InputMaybe<order_by>;
  new_Anchortext2?: InputMaybe<order_by>;
  new_Anchortext3?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_OnlineStatusCheckDate?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_TargetURL1?: InputMaybe<order_by>;
  new_TargetURL2?: InputMaybe<order_by>;
  new_TargetURL3?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
};

/** select columns of table "v1.OutreachPosts" */
export enum v1_OutreachPosts_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_Anchortext1 = 'new_Anchortext1',
  /** column name */
  new_Anchortext2 = 'new_Anchortext2',
  /** column name */
  new_Anchortext3 = 'new_Anchortext3',
  /** column name */
  new_ContentOrderStatus = 'new_ContentOrderStatus',
  /** column name */
  new_OnlineStatusCheckDate = 'new_OnlineStatusCheckDate',
  /** column name */
  new_OutreachStatus = 'new_OutreachStatus',
  /** column name */
  new_PostLinksStatus = 'new_PostLinksStatus',
  /** column name */
  new_PostOnlineStatus = 'new_PostOnlineStatus',
  /** column name */
  new_PostStatus = 'new_PostStatus',
  /** column name */
  new_TargetURL1 = 'new_TargetURL1',
  /** column name */
  new_TargetURL2 = 'new_TargetURL2',
  /** column name */
  new_TargetURL3 = 'new_TargetURL3',
  /** column name */
  new_name = 'new_name',
  /** column name */
  plc_failed_attempts = 'plc_failed_attempts'
}

/** aggregate stddev on columns */
export type v1_OutreachPosts_stddev_fields = {
  __typename?: 'v1_OutreachPosts_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_OutreachPosts_stddev_pop_fields = {
  __typename?: 'v1_OutreachPosts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_OutreachPosts_stddev_samp_fields = {
  __typename?: 'v1_OutreachPosts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_OutreachPosts" */
export type v1_OutreachPosts_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_OutreachPosts_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_OutreachPosts_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_Anchortext1?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext2?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext3?: InputMaybe<Scalars['String']['input']>;
  new_ContentOrderStatus?: InputMaybe<Scalars['Int']['input']>;
  new_OnlineStatusCheckDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_OutreachStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostLinksStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostOnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostStatus?: InputMaybe<Scalars['Int']['input']>;
  new_TargetURL1?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL2?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL3?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  plc_failed_attempts?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_OutreachPosts_sum_fields = {
  __typename?: 'v1_OutreachPosts_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Int']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Int']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Int']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_PostStatus?: Maybe<Scalars['Int']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type v1_OutreachPosts_var_pop_fields = {
  __typename?: 'v1_OutreachPosts_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_OutreachPosts_var_samp_fields = {
  __typename?: 'v1_OutreachPosts_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_OutreachPosts_variance_fields = {
  __typename?: 'v1_OutreachPosts_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Page" */
export type v1_Page = {
  __typename?: 'v1_Page';
  /** An object relationship */
  Brand?: Maybe<v1_new_brandBase>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  LandinPages: Array<v1_LandingPages>;
  /** An aggregate relationship */
  LandinPages_aggregate: v1_LandingPages_aggregate;
  /** An object relationship */
  Website?: Maybe<v1_Website>;
  alias?: Maybe<Scalars['bpchar']['output']>;
  backlinks_inprogress?: Maybe<Scalars['Int']['output']>;
  backlinks_published?: Maybe<Scalars['Int']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  seo_title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.Page" */
export type v1_PageLandinPagesArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


/** columns and relationships of "v1.Page" */
export type v1_PageLandinPages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};

/** aggregated selection of "v1.Page" */
export type v1_Page_aggregate = {
  __typename?: 'v1_Page_aggregate';
  aggregate?: Maybe<v1_Page_aggregate_fields>;
  nodes: Array<v1_Page>;
};

export type v1_Page_aggregate_bool_exp = {
  count?: InputMaybe<v1_Page_aggregate_bool_exp_count>;
};

export type v1_Page_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Page_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Page_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Page" */
export type v1_Page_aggregate_fields = {
  __typename?: 'v1_Page_aggregate_fields';
  avg?: Maybe<v1_Page_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Page_max_fields>;
  min?: Maybe<v1_Page_min_fields>;
  stddev?: Maybe<v1_Page_stddev_fields>;
  stddev_pop?: Maybe<v1_Page_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Page_stddev_samp_fields>;
  sum?: Maybe<v1_Page_sum_fields>;
  var_pop?: Maybe<v1_Page_var_pop_fields>;
  var_samp?: Maybe<v1_Page_var_samp_fields>;
  variance?: Maybe<v1_Page_variance_fields>;
};


/** aggregate fields of "v1.Page" */
export type v1_Page_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Page_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Page" */
export type v1_Page_aggregate_order_by = {
  avg?: InputMaybe<v1_Page_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Page_max_order_by>;
  min?: InputMaybe<v1_Page_min_order_by>;
  stddev?: InputMaybe<v1_Page_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Page_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Page_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Page_sum_order_by>;
  var_pop?: InputMaybe<v1_Page_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Page_var_samp_order_by>;
  variance?: InputMaybe<v1_Page_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Page" */
export type v1_Page_arr_rel_insert_input = {
  data: Array<v1_Page_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Page_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Page_avg_fields = {
  __typename?: 'v1_Page_avg_fields';
  backlinks_inprogress?: Maybe<Scalars['Float']['output']>;
  backlinks_published?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Page" */
export type v1_Page_avg_order_by = {
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Page". All fields are combined with a logical 'AND'. */
export type v1_Page_bool_exp = {
  Brand?: InputMaybe<v1_new_brandBase_bool_exp>;
  CreatedOn?: InputMaybe<timestamptz_comparison_exp>;
  LandinPages?: InputMaybe<v1_LandingPages_bool_exp>;
  LandinPages_aggregate?: InputMaybe<v1_LandingPages_aggregate_bool_exp>;
  Website?: InputMaybe<v1_Website_bool_exp>;
  _and?: InputMaybe<Array<v1_Page_bool_exp>>;
  _not?: InputMaybe<v1_Page_bool_exp>;
  _or?: InputMaybe<Array<v1_Page_bool_exp>>;
  alias?: InputMaybe<bpchar_comparison_exp>;
  backlinks_inprogress?: InputMaybe<Int_comparison_exp>;
  backlinks_published?: InputMaybe<Int_comparison_exp>;
  body?: InputMaybe<String_comparison_exp>;
  brand_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  seo_title?: InputMaybe<String_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
  website_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Page" */
export enum v1_Page_constraint {
  /** unique or primary key constraint on columns "id" */
  Page_pkey = 'Page_pkey',
  /** unique or primary key constraint on columns "url" */
  Page_url_key = 'Page_url_key'
}

/** input type for incrementing numeric columns in table "v1.Page" */
export type v1_Page_inc_input = {
  backlinks_inprogress?: InputMaybe<Scalars['Int']['input']>;
  backlinks_published?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Page" */
export type v1_Page_insert_input = {
  Brand?: InputMaybe<v1_new_brandBase_obj_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  LandinPages?: InputMaybe<v1_LandingPages_arr_rel_insert_input>;
  Website?: InputMaybe<v1_Website_obj_rel_insert_input>;
  alias?: InputMaybe<Scalars['bpchar']['input']>;
  backlinks_inprogress?: InputMaybe<Scalars['Int']['input']>;
  backlinks_published?: InputMaybe<Scalars['Int']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  seo_title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Page_max_fields = {
  __typename?: 'v1_Page_max_fields';
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  alias?: Maybe<Scalars['bpchar']['output']>;
  backlinks_inprogress?: Maybe<Scalars['Int']['output']>;
  backlinks_published?: Maybe<Scalars['Int']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  seo_title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Page" */
export type v1_Page_max_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  alias?: InputMaybe<order_by>;
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  body?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  seo_title?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Page_min_fields = {
  __typename?: 'v1_Page_min_fields';
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  alias?: Maybe<Scalars['bpchar']['output']>;
  backlinks_inprogress?: Maybe<Scalars['Int']['output']>;
  backlinks_published?: Maybe<Scalars['Int']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  seo_title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Page" */
export type v1_Page_min_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  alias?: InputMaybe<order_by>;
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  body?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  seo_title?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Page" */
export type v1_Page_mutation_response = {
  __typename?: 'v1_Page_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Page>;
};

/** input type for inserting object relation for remote table "v1.Page" */
export type v1_Page_obj_rel_insert_input = {
  data: v1_Page_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Page_on_conflict>;
};

/** on_conflict condition type for table "v1.Page" */
export type v1_Page_on_conflict = {
  constraint: v1_Page_constraint;
  update_columns?: Array<v1_Page_update_column>;
  where?: InputMaybe<v1_Page_bool_exp>;
};

/** Ordering options when selecting data from "v1.Page". */
export type v1_Page_order_by = {
  Brand?: InputMaybe<v1_new_brandBase_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  LandinPages_aggregate?: InputMaybe<v1_LandingPages_aggregate_order_by>;
  Website?: InputMaybe<v1_Website_order_by>;
  alias?: InputMaybe<order_by>;
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  body?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  seo_title?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Page */
export type v1_Page_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Page" */
export enum v1_Page_select_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  alias = 'alias',
  /** column name */
  backlinks_inprogress = 'backlinks_inprogress',
  /** column name */
  backlinks_published = 'backlinks_published',
  /** column name */
  body = 'body',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  id = 'id',
  /** column name */
  seo_title = 'seo_title',
  /** column name */
  url = 'url',
  /** column name */
  website_id = 'website_id'
}

/** input type for updating data in table "v1.Page" */
export type v1_Page_set_input = {
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  alias?: InputMaybe<Scalars['bpchar']['input']>;
  backlinks_inprogress?: InputMaybe<Scalars['Int']['input']>;
  backlinks_published?: InputMaybe<Scalars['Int']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  seo_title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Page_stddev_fields = {
  __typename?: 'v1_Page_stddev_fields';
  backlinks_inprogress?: Maybe<Scalars['Float']['output']>;
  backlinks_published?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Page" */
export type v1_Page_stddev_order_by = {
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Page_stddev_pop_fields = {
  __typename?: 'v1_Page_stddev_pop_fields';
  backlinks_inprogress?: Maybe<Scalars['Float']['output']>;
  backlinks_published?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Page" */
export type v1_Page_stddev_pop_order_by = {
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Page_stddev_samp_fields = {
  __typename?: 'v1_Page_stddev_samp_fields';
  backlinks_inprogress?: Maybe<Scalars['Float']['output']>;
  backlinks_published?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Page" */
export type v1_Page_stddev_samp_order_by = {
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Page" */
export type v1_Page_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Page_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Page_stream_cursor_value_input = {
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  alias?: InputMaybe<Scalars['bpchar']['input']>;
  backlinks_inprogress?: InputMaybe<Scalars['Int']['input']>;
  backlinks_published?: InputMaybe<Scalars['Int']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  seo_title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Page_sum_fields = {
  __typename?: 'v1_Page_sum_fields';
  backlinks_inprogress?: Maybe<Scalars['Int']['output']>;
  backlinks_published?: Maybe<Scalars['Int']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Page" */
export type v1_Page_sum_order_by = {
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Page" */
export enum v1_Page_update_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  alias = 'alias',
  /** column name */
  backlinks_inprogress = 'backlinks_inprogress',
  /** column name */
  backlinks_published = 'backlinks_published',
  /** column name */
  body = 'body',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  id = 'id',
  /** column name */
  seo_title = 'seo_title',
  /** column name */
  url = 'url',
  /** column name */
  website_id = 'website_id'
}

export type v1_Page_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Page_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Page_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Page_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Page_var_pop_fields = {
  __typename?: 'v1_Page_var_pop_fields';
  backlinks_inprogress?: Maybe<Scalars['Float']['output']>;
  backlinks_published?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Page" */
export type v1_Page_var_pop_order_by = {
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Page_var_samp_fields = {
  __typename?: 'v1_Page_var_samp_fields';
  backlinks_inprogress?: Maybe<Scalars['Float']['output']>;
  backlinks_published?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Page" */
export type v1_Page_var_samp_order_by = {
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Page_variance_fields = {
  __typename?: 'v1_Page_variance_fields';
  backlinks_inprogress?: Maybe<Scalars['Float']['output']>;
  backlinks_published?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Page" */
export type v1_Page_variance_order_by = {
  backlinks_inprogress?: InputMaybe<order_by>;
  backlinks_published?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** Payment Companies */
export type v1_PaymentCompanies = {
  __typename?: 'v1_PaymentCompanies';
  /** An array relationship */
  InvoiceBases: Array<v1_InvoiceBase>;
  /** An aggregate relationship */
  InvoiceBases_aggregate: v1_InvoiceBase_aggregate;
  /** An array relationship */
  OrderPayToCompany: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  OrderPayToCompany_aggregate: v1_SalesOrderBase_aggregate;
  /** An array relationship */
  ReceiptPaymentCompany: Array<v1_new_receiptBase>;
  /** An aggregate relationship */
  ReceiptPaymentCompany_aggregate: v1_new_receiptBase_aggregate;
  id: Scalars['Int']['output'];
  is_enabled: Scalars['Boolean']['output'];
  /** An array relationship */
  new_paymentmethodBases: Array<v1_new_paymentmethodBase>;
  /** An aggregate relationship */
  new_paymentmethodBases_aggregate: v1_new_paymentmethodBase_aggregate;
  value: Scalars['String']['output'];
};


/** Payment Companies */
export type v1_PaymentCompaniesInvoiceBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** Payment Companies */
export type v1_PaymentCompaniesInvoiceBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** Payment Companies */
export type v1_PaymentCompaniesOrderPayToCompanyArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** Payment Companies */
export type v1_PaymentCompaniesOrderPayToCompany_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** Payment Companies */
export type v1_PaymentCompaniesReceiptPaymentCompanyArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** Payment Companies */
export type v1_PaymentCompaniesReceiptPaymentCompany_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** Payment Companies */
export type v1_PaymentCompaniesnew_paymentmethodBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_paymentmethodBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_paymentmethodBase_order_by>>;
  where?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
};


/** Payment Companies */
export type v1_PaymentCompaniesnew_paymentmethodBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_paymentmethodBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_paymentmethodBase_order_by>>;
  where?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
};

/** aggregated selection of "v1.PaymentCompanies" */
export type v1_PaymentCompanies_aggregate = {
  __typename?: 'v1_PaymentCompanies_aggregate';
  aggregate?: Maybe<v1_PaymentCompanies_aggregate_fields>;
  nodes: Array<v1_PaymentCompanies>;
};

/** aggregate fields of "v1.PaymentCompanies" */
export type v1_PaymentCompanies_aggregate_fields = {
  __typename?: 'v1_PaymentCompanies_aggregate_fields';
  avg?: Maybe<v1_PaymentCompanies_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PaymentCompanies_max_fields>;
  min?: Maybe<v1_PaymentCompanies_min_fields>;
  stddev?: Maybe<v1_PaymentCompanies_stddev_fields>;
  stddev_pop?: Maybe<v1_PaymentCompanies_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PaymentCompanies_stddev_samp_fields>;
  sum?: Maybe<v1_PaymentCompanies_sum_fields>;
  var_pop?: Maybe<v1_PaymentCompanies_var_pop_fields>;
  var_samp?: Maybe<v1_PaymentCompanies_var_samp_fields>;
  variance?: Maybe<v1_PaymentCompanies_variance_fields>;
};


/** aggregate fields of "v1.PaymentCompanies" */
export type v1_PaymentCompanies_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PaymentCompanies_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PaymentCompanies_avg_fields = {
  __typename?: 'v1_PaymentCompanies_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PaymentCompanies". All fields are combined with a logical 'AND'. */
export type v1_PaymentCompanies_bool_exp = {
  InvoiceBases?: InputMaybe<v1_InvoiceBase_bool_exp>;
  InvoiceBases_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  OrderPayToCompany?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  OrderPayToCompany_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  ReceiptPaymentCompany?: InputMaybe<v1_new_receiptBase_bool_exp>;
  ReceiptPaymentCompany_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PaymentCompanies_bool_exp>>;
  _not?: InputMaybe<v1_PaymentCompanies_bool_exp>;
  _or?: InputMaybe<Array<v1_PaymentCompanies_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  is_enabled?: InputMaybe<Boolean_comparison_exp>;
  new_paymentmethodBases?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  new_paymentmethodBases_aggregate?: InputMaybe<v1_new_paymentmethodBase_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PaymentCompanies" */
export enum v1_PaymentCompanies_constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceFrom_pkey = 'InvoiceFrom_pkey'
}

/** input type for incrementing numeric columns in table "v1.PaymentCompanies" */
export type v1_PaymentCompanies_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PaymentCompanies" */
export type v1_PaymentCompanies_insert_input = {
  InvoiceBases?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  OrderPayToCompany?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  ReceiptPaymentCompany?: InputMaybe<v1_new_receiptBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  new_paymentmethodBases?: InputMaybe<v1_new_paymentmethodBase_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PaymentCompanies_max_fields = {
  __typename?: 'v1_PaymentCompanies_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PaymentCompanies_min_fields = {
  __typename?: 'v1_PaymentCompanies_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PaymentCompanies" */
export type v1_PaymentCompanies_mutation_response = {
  __typename?: 'v1_PaymentCompanies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PaymentCompanies>;
};

/** input type for inserting object relation for remote table "v1.PaymentCompanies" */
export type v1_PaymentCompanies_obj_rel_insert_input = {
  data: v1_PaymentCompanies_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PaymentCompanies_on_conflict>;
};

/** on_conflict condition type for table "v1.PaymentCompanies" */
export type v1_PaymentCompanies_on_conflict = {
  constraint: v1_PaymentCompanies_constraint;
  update_columns?: Array<v1_PaymentCompanies_update_column>;
  where?: InputMaybe<v1_PaymentCompanies_bool_exp>;
};

/** Ordering options when selecting data from "v1.PaymentCompanies". */
export type v1_PaymentCompanies_order_by = {
  InvoiceBases_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  OrderPayToCompany_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  ReceiptPaymentCompany_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  is_enabled?: InputMaybe<order_by>;
  new_paymentmethodBases_aggregate?: InputMaybe<v1_new_paymentmethodBase_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PaymentCompanies */
export type v1_PaymentCompanies_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PaymentCompanies" */
export enum v1_PaymentCompanies_select_column {
  /** column name */
  id = 'id',
  /** column name */
  is_enabled = 'is_enabled',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PaymentCompanies" */
export type v1_PaymentCompanies_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PaymentCompanies_stddev_fields = {
  __typename?: 'v1_PaymentCompanies_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PaymentCompanies_stddev_pop_fields = {
  __typename?: 'v1_PaymentCompanies_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PaymentCompanies_stddev_samp_fields = {
  __typename?: 'v1_PaymentCompanies_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PaymentCompanies" */
export type v1_PaymentCompanies_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PaymentCompanies_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PaymentCompanies_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PaymentCompanies_sum_fields = {
  __typename?: 'v1_PaymentCompanies_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PaymentCompanies" */
export enum v1_PaymentCompanies_update_column {
  /** column name */
  id = 'id',
  /** column name */
  is_enabled = 'is_enabled',
  /** column name */
  value = 'value'
}

export type v1_PaymentCompanies_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PaymentCompanies_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PaymentCompanies_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PaymentCompanies_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PaymentCompanies_var_pop_fields = {
  __typename?: 'v1_PaymentCompanies_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PaymentCompanies_var_samp_fields = {
  __typename?: 'v1_PaymentCompanies_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PaymentCompanies_variance_fields = {
  __typename?: 'v1_PaymentCompanies_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PaymentFrequency" */
export type v1_PaymentFrequency = {
  __typename?: 'v1_PaymentFrequency';
  /** An array relationship */
  RecurringPayments: Array<v1_RecurringPayments>;
  /** An aggregate relationship */
  RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "v1.PaymentFrequency" */
export type v1_PaymentFrequencyRecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** columns and relationships of "v1.PaymentFrequency" */
export type v1_PaymentFrequencyRecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};

/** aggregated selection of "v1.PaymentFrequency" */
export type v1_PaymentFrequency_aggregate = {
  __typename?: 'v1_PaymentFrequency_aggregate';
  aggregate?: Maybe<v1_PaymentFrequency_aggregate_fields>;
  nodes: Array<v1_PaymentFrequency>;
};

/** aggregate fields of "v1.PaymentFrequency" */
export type v1_PaymentFrequency_aggregate_fields = {
  __typename?: 'v1_PaymentFrequency_aggregate_fields';
  avg?: Maybe<v1_PaymentFrequency_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PaymentFrequency_max_fields>;
  min?: Maybe<v1_PaymentFrequency_min_fields>;
  stddev?: Maybe<v1_PaymentFrequency_stddev_fields>;
  stddev_pop?: Maybe<v1_PaymentFrequency_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PaymentFrequency_stddev_samp_fields>;
  sum?: Maybe<v1_PaymentFrequency_sum_fields>;
  var_pop?: Maybe<v1_PaymentFrequency_var_pop_fields>;
  var_samp?: Maybe<v1_PaymentFrequency_var_samp_fields>;
  variance?: Maybe<v1_PaymentFrequency_variance_fields>;
};


/** aggregate fields of "v1.PaymentFrequency" */
export type v1_PaymentFrequency_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PaymentFrequency_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PaymentFrequency_avg_fields = {
  __typename?: 'v1_PaymentFrequency_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PaymentFrequency". All fields are combined with a logical 'AND'. */
export type v1_PaymentFrequency_bool_exp = {
  RecurringPayments?: InputMaybe<v1_RecurringPayments_bool_exp>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PaymentFrequency_bool_exp>>;
  _not?: InputMaybe<v1_PaymentFrequency_bool_exp>;
  _or?: InputMaybe<Array<v1_PaymentFrequency_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PaymentFrequency" */
export enum v1_PaymentFrequency_constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentFrequency_pkey = 'PaymentFrequency_pkey'
}

/** input type for incrementing numeric columns in table "v1.PaymentFrequency" */
export type v1_PaymentFrequency_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PaymentFrequency" */
export type v1_PaymentFrequency_insert_input = {
  RecurringPayments?: InputMaybe<v1_RecurringPayments_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PaymentFrequency_max_fields = {
  __typename?: 'v1_PaymentFrequency_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PaymentFrequency_min_fields = {
  __typename?: 'v1_PaymentFrequency_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PaymentFrequency" */
export type v1_PaymentFrequency_mutation_response = {
  __typename?: 'v1_PaymentFrequency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PaymentFrequency>;
};

/** input type for inserting object relation for remote table "v1.PaymentFrequency" */
export type v1_PaymentFrequency_obj_rel_insert_input = {
  data: v1_PaymentFrequency_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PaymentFrequency_on_conflict>;
};

/** on_conflict condition type for table "v1.PaymentFrequency" */
export type v1_PaymentFrequency_on_conflict = {
  constraint: v1_PaymentFrequency_constraint;
  update_columns?: Array<v1_PaymentFrequency_update_column>;
  where?: InputMaybe<v1_PaymentFrequency_bool_exp>;
};

/** Ordering options when selecting data from "v1.PaymentFrequency". */
export type v1_PaymentFrequency_order_by = {
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PaymentFrequency */
export type v1_PaymentFrequency_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PaymentFrequency" */
export enum v1_PaymentFrequency_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.PaymentFrequency" */
export type v1_PaymentFrequency_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PaymentFrequency_stddev_fields = {
  __typename?: 'v1_PaymentFrequency_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PaymentFrequency_stddev_pop_fields = {
  __typename?: 'v1_PaymentFrequency_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PaymentFrequency_stddev_samp_fields = {
  __typename?: 'v1_PaymentFrequency_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PaymentFrequency" */
export type v1_PaymentFrequency_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PaymentFrequency_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PaymentFrequency_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PaymentFrequency_sum_fields = {
  __typename?: 'v1_PaymentFrequency_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PaymentFrequency" */
export enum v1_PaymentFrequency_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_PaymentFrequency_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PaymentFrequency_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PaymentFrequency_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PaymentFrequency_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PaymentFrequency_var_pop_fields = {
  __typename?: 'v1_PaymentFrequency_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PaymentFrequency_var_samp_fields = {
  __typename?: 'v1_PaymentFrequency_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PaymentFrequency_variance_fields = {
  __typename?: 'v1_PaymentFrequency_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** This table is a many to many table. It will allow us to have more than one purchase order associated with the same post, one for writer, one for supplier. */
export type v1_PoToPosts = {
  __typename?: 'v1_PoToPosts';
  /** An object relationship */
  Post: v1_Posts;
  /** An object relationship */
  PurchaseOrder: v1_new_POBase;
  id: Scalars['Int']['output'];
  po_id: Scalars['Int']['output'];
  post_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.PoToPosts" */
export type v1_PoToPosts_aggregate = {
  __typename?: 'v1_PoToPosts_aggregate';
  aggregate?: Maybe<v1_PoToPosts_aggregate_fields>;
  nodes: Array<v1_PoToPosts>;
};

export type v1_PoToPosts_aggregate_bool_exp = {
  count?: InputMaybe<v1_PoToPosts_aggregate_bool_exp_count>;
};

export type v1_PoToPosts_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_PoToPosts_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.PoToPosts" */
export type v1_PoToPosts_aggregate_fields = {
  __typename?: 'v1_PoToPosts_aggregate_fields';
  avg?: Maybe<v1_PoToPosts_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PoToPosts_max_fields>;
  min?: Maybe<v1_PoToPosts_min_fields>;
  stddev?: Maybe<v1_PoToPosts_stddev_fields>;
  stddev_pop?: Maybe<v1_PoToPosts_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PoToPosts_stddev_samp_fields>;
  sum?: Maybe<v1_PoToPosts_sum_fields>;
  var_pop?: Maybe<v1_PoToPosts_var_pop_fields>;
  var_samp?: Maybe<v1_PoToPosts_var_samp_fields>;
  variance?: Maybe<v1_PoToPosts_variance_fields>;
};


/** aggregate fields of "v1.PoToPosts" */
export type v1_PoToPosts_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.PoToPosts" */
export type v1_PoToPosts_aggregate_order_by = {
  avg?: InputMaybe<v1_PoToPosts_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_PoToPosts_max_order_by>;
  min?: InputMaybe<v1_PoToPosts_min_order_by>;
  stddev?: InputMaybe<v1_PoToPosts_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_PoToPosts_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_PoToPosts_stddev_samp_order_by>;
  sum?: InputMaybe<v1_PoToPosts_sum_order_by>;
  var_pop?: InputMaybe<v1_PoToPosts_var_pop_order_by>;
  var_samp?: InputMaybe<v1_PoToPosts_var_samp_order_by>;
  variance?: InputMaybe<v1_PoToPosts_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.PoToPosts" */
export type v1_PoToPosts_arr_rel_insert_input = {
  data: Array<v1_PoToPosts_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PoToPosts_on_conflict>;
};

/** aggregate avg on columns */
export type v1_PoToPosts_avg_fields = {
  __typename?: 'v1_PoToPosts_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_avg_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.PoToPosts". All fields are combined with a logical 'AND'. */
export type v1_PoToPosts_bool_exp = {
  Post?: InputMaybe<v1_Posts_bool_exp>;
  PurchaseOrder?: InputMaybe<v1_new_POBase_bool_exp>;
  _and?: InputMaybe<Array<v1_PoToPosts_bool_exp>>;
  _not?: InputMaybe<v1_PoToPosts_bool_exp>;
  _or?: InputMaybe<Array<v1_PoToPosts_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  po_id?: InputMaybe<Int_comparison_exp>;
  post_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PoToPosts" */
export enum v1_PoToPosts_constraint {
  /** unique or primary key constraint on columns "id" */
  PoToPosts_pkey = 'PoToPosts_pkey'
}

/** input type for incrementing numeric columns in table "v1.PoToPosts" */
export type v1_PoToPosts_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PoToPosts" */
export type v1_PoToPosts_insert_input = {
  Post?: InputMaybe<v1_Posts_obj_rel_insert_input>;
  PurchaseOrder?: InputMaybe<v1_new_POBase_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_PoToPosts_max_fields = {
  __typename?: 'v1_PoToPosts_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_max_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_PoToPosts_min_fields = {
  __typename?: 'v1_PoToPosts_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_min_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.PoToPosts" */
export type v1_PoToPosts_mutation_response = {
  __typename?: 'v1_PoToPosts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PoToPosts>;
};

/** on_conflict condition type for table "v1.PoToPosts" */
export type v1_PoToPosts_on_conflict = {
  constraint: v1_PoToPosts_constraint;
  update_columns?: Array<v1_PoToPosts_update_column>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};

/** Ordering options when selecting data from "v1.PoToPosts". */
export type v1_PoToPosts_order_by = {
  Post?: InputMaybe<v1_Posts_order_by>;
  PurchaseOrder?: InputMaybe<v1_new_POBase_order_by>;
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PoToPosts */
export type v1_PoToPosts_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PoToPosts" */
export enum v1_PoToPosts_select_column {
  /** column name */
  id = 'id',
  /** column name */
  po_id = 'po_id',
  /** column name */
  post_id = 'post_id'
}

/** input type for updating data in table "v1.PoToPosts" */
export type v1_PoToPosts_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_PoToPosts_stddev_fields = {
  __typename?: 'v1_PoToPosts_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_stddev_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_PoToPosts_stddev_pop_fields = {
  __typename?: 'v1_PoToPosts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_PoToPosts_stddev_samp_fields = {
  __typename?: 'v1_PoToPosts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_PoToPosts" */
export type v1_PoToPosts_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PoToPosts_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PoToPosts_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_PoToPosts_sum_fields = {
  __typename?: 'v1_PoToPosts_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_sum_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.PoToPosts" */
export enum v1_PoToPosts_update_column {
  /** column name */
  id = 'id',
  /** column name */
  po_id = 'po_id',
  /** column name */
  post_id = 'post_id'
}

export type v1_PoToPosts_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PoToPosts_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PoToPosts_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PoToPosts_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PoToPosts_var_pop_fields = {
  __typename?: 'v1_PoToPosts_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_PoToPosts_var_samp_fields = {
  __typename?: 'v1_PoToPosts_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_PoToPosts_variance_fields = {
  __typename?: 'v1_PoToPosts_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.PoToPosts" */
export type v1_PoToPosts_variance_order_by = {
  id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  post_id?: InputMaybe<order_by>;
};

/** Post publication duration  */
export type v1_PostDuration = {
  __typename?: 'v1_PostDuration';
  id: Scalars['Int']['output'];
  value: Scalars['bpchar']['output'];
};

/** aggregated selection of "v1.PostDuration" */
export type v1_PostDuration_aggregate = {
  __typename?: 'v1_PostDuration_aggregate';
  aggregate?: Maybe<v1_PostDuration_aggregate_fields>;
  nodes: Array<v1_PostDuration>;
};

/** aggregate fields of "v1.PostDuration" */
export type v1_PostDuration_aggregate_fields = {
  __typename?: 'v1_PostDuration_aggregate_fields';
  avg?: Maybe<v1_PostDuration_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostDuration_max_fields>;
  min?: Maybe<v1_PostDuration_min_fields>;
  stddev?: Maybe<v1_PostDuration_stddev_fields>;
  stddev_pop?: Maybe<v1_PostDuration_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostDuration_stddev_samp_fields>;
  sum?: Maybe<v1_PostDuration_sum_fields>;
  var_pop?: Maybe<v1_PostDuration_var_pop_fields>;
  var_samp?: Maybe<v1_PostDuration_var_samp_fields>;
  variance?: Maybe<v1_PostDuration_variance_fields>;
};


/** aggregate fields of "v1.PostDuration" */
export type v1_PostDuration_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostDuration_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostDuration_avg_fields = {
  __typename?: 'v1_PostDuration_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostDuration". All fields are combined with a logical 'AND'. */
export type v1_PostDuration_bool_exp = {
  _and?: InputMaybe<Array<v1_PostDuration_bool_exp>>;
  _not?: InputMaybe<v1_PostDuration_bool_exp>;
  _or?: InputMaybe<Array<v1_PostDuration_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<bpchar_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostDuration" */
export enum v1_PostDuration_constraint {
  /** unique or primary key constraint on columns "id" */
  PostDuration_pkey = 'PostDuration_pkey',
  /** unique or primary key constraint on columns "value" */
  PostDuration_value_key = 'PostDuration_value_key'
}

/** input type for incrementing numeric columns in table "v1.PostDuration" */
export type v1_PostDuration_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostDuration" */
export type v1_PostDuration_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type v1_PostDuration_max_fields = {
  __typename?: 'v1_PostDuration_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_PostDuration_min_fields = {
  __typename?: 'v1_PostDuration_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.PostDuration" */
export type v1_PostDuration_mutation_response = {
  __typename?: 'v1_PostDuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostDuration>;
};

/** input type for inserting object relation for remote table "v1.PostDuration" */
export type v1_PostDuration_obj_rel_insert_input = {
  data: v1_PostDuration_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostDuration_on_conflict>;
};

/** on_conflict condition type for table "v1.PostDuration" */
export type v1_PostDuration_on_conflict = {
  constraint: v1_PostDuration_constraint;
  update_columns?: Array<v1_PostDuration_update_column>;
  where?: InputMaybe<v1_PostDuration_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostDuration". */
export type v1_PostDuration_order_by = {
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostDuration */
export type v1_PostDuration_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostDuration" */
export enum v1_PostDuration_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostDuration" */
export type v1_PostDuration_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostDuration_stddev_fields = {
  __typename?: 'v1_PostDuration_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostDuration_stddev_pop_fields = {
  __typename?: 'v1_PostDuration_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostDuration_stddev_samp_fields = {
  __typename?: 'v1_PostDuration_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostDuration" */
export type v1_PostDuration_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostDuration_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostDuration_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type v1_PostDuration_sum_fields = {
  __typename?: 'v1_PostDuration_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostDuration" */
export enum v1_PostDuration_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostDuration_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostDuration_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostDuration_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostDuration_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostDuration_var_pop_fields = {
  __typename?: 'v1_PostDuration_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostDuration_var_samp_fields = {
  __typename?: 'v1_PostDuration_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostDuration_variance_fields = {
  __typename?: 'v1_PostDuration_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostNiche" */
export type v1_PostNiche = {
  __typename?: 'v1_PostNiche';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  RestrictedNichesToOffers: Array<v1_RestrictedNichesToOffer>;
  /** An aggregate relationship */
  RestrictedNichesToOffers_aggregate: v1_RestrictedNichesToOffer_aggregate;
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostNiche" */
export type v1_PostNichePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostNiche" */
export type v1_PostNichePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostNiche" */
export type v1_PostNicheRestrictedNichesToOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RestrictedNichesToOffer_order_by>>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};


/** columns and relationships of "v1.PostNiche" */
export type v1_PostNicheRestrictedNichesToOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RestrictedNichesToOffer_order_by>>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};


/** columns and relationships of "v1.PostNiche" */
export type v1_PostNicheWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** columns and relationships of "v1.PostNiche" */
export type v1_PostNicheWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** aggregated selection of "v1.PostNiche" */
export type v1_PostNiche_aggregate = {
  __typename?: 'v1_PostNiche_aggregate';
  aggregate?: Maybe<v1_PostNiche_aggregate_fields>;
  nodes: Array<v1_PostNiche>;
};

/** aggregate fields of "v1.PostNiche" */
export type v1_PostNiche_aggregate_fields = {
  __typename?: 'v1_PostNiche_aggregate_fields';
  avg?: Maybe<v1_PostNiche_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostNiche_max_fields>;
  min?: Maybe<v1_PostNiche_min_fields>;
  stddev?: Maybe<v1_PostNiche_stddev_fields>;
  stddev_pop?: Maybe<v1_PostNiche_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostNiche_stddev_samp_fields>;
  sum?: Maybe<v1_PostNiche_sum_fields>;
  var_pop?: Maybe<v1_PostNiche_var_pop_fields>;
  var_samp?: Maybe<v1_PostNiche_var_samp_fields>;
  variance?: Maybe<v1_PostNiche_variance_fields>;
};


/** aggregate fields of "v1.PostNiche" */
export type v1_PostNiche_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostNiche_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostNiche_avg_fields = {
  __typename?: 'v1_PostNiche_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostNiche". All fields are combined with a logical 'AND'. */
export type v1_PostNiche_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  RestrictedNichesToOffers?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
  RestrictedNichesToOffers_aggregate?: InputMaybe<v1_RestrictedNichesToOffer_aggregate_bool_exp>;
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostNiche_bool_exp>>;
  _not?: InputMaybe<v1_PostNiche_bool_exp>;
  _or?: InputMaybe<Array<v1_PostNiche_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostNiche" */
export enum v1_PostNiche_constraint {
  /** unique or primary key constraint on columns "id" */
  PostNiche_pkey = 'PostNiche_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostNiche" */
export type v1_PostNiche_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostNiche" */
export type v1_PostNiche_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  RestrictedNichesToOffers?: InputMaybe<v1_RestrictedNichesToOffer_arr_rel_insert_input>;
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostNiche_max_fields = {
  __typename?: 'v1_PostNiche_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostNiche_min_fields = {
  __typename?: 'v1_PostNiche_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostNiche" */
export type v1_PostNiche_mutation_response = {
  __typename?: 'v1_PostNiche_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostNiche>;
};

/** input type for inserting object relation for remote table "v1.PostNiche" */
export type v1_PostNiche_obj_rel_insert_input = {
  data: v1_PostNiche_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostNiche_on_conflict>;
};

/** on_conflict condition type for table "v1.PostNiche" */
export type v1_PostNiche_on_conflict = {
  constraint: v1_PostNiche_constraint;
  update_columns?: Array<v1_PostNiche_update_column>;
  where?: InputMaybe<v1_PostNiche_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostNiche". */
export type v1_PostNiche_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  RestrictedNichesToOffers_aggregate?: InputMaybe<v1_RestrictedNichesToOffer_aggregate_order_by>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostNiche */
export type v1_PostNiche_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostNiche" */
export enum v1_PostNiche_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.PostNiche" */
export type v1_PostNiche_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostNiche_stddev_fields = {
  __typename?: 'v1_PostNiche_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostNiche_stddev_pop_fields = {
  __typename?: 'v1_PostNiche_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostNiche_stddev_samp_fields = {
  __typename?: 'v1_PostNiche_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostNiche" */
export type v1_PostNiche_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostNiche_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostNiche_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostNiche_sum_fields = {
  __typename?: 'v1_PostNiche_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostNiche" */
export enum v1_PostNiche_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_PostNiche_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostNiche_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostNiche_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostNiche_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostNiche_var_pop_fields = {
  __typename?: 'v1_PostNiche_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostNiche_var_samp_fields = {
  __typename?: 'v1_PostNiche_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostNiche_variance_fields = {
  __typename?: 'v1_PostNiche_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Posts" */
export type v1_Posts = {
  __typename?: 'v1_Posts';
  /** An object relationship */
  Advertiser?: Maybe<v1_ContactBase>;
  AdvertiserApprovedOn?: Maybe<Scalars['date']['output']>;
  AdvertiserDeclinedOn?: Maybe<Scalars['date']['output']>;
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  AdvertiserOrderedOn?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  AdvertiserPurchaseOrder?: Maybe<v1_new_POBase>;
  AdvertiserStatus?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  AnchorText1Type?: Maybe<v1_PostsAnchorTextType>;
  /** An object relationship */
  AnchorText2Type?: Maybe<v1_PostsAnchorTextType>;
  /** An object relationship */
  AnchorText3Type?: Maybe<v1_PostsAnchorTextType>;
  /** An object relationship */
  AnchorText4Type?: Maybe<v1_PostsAnchorTextType>;
  /** An object relationship */
  AnchorText5Type?: Maybe<v1_PostsAnchorTextType>;
  /** An object relationship */
  AnchorText6Type?: Maybe<v1_PostsAnchorTextType>;
  /** An object relationship */
  ApprovedBy?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Approver?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  ApproverSM?: Maybe<v1_SystemUserBase>;
  ArticleTone?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Article_Tone?: Maybe<v1_ArticleTones>;
  /** An object relationship */
  Brand?: Maybe<v1_new_brandBase>;
  /** An object relationship */
  Campaign?: Maybe<v1_Campaign>;
  /** An object relationship */
  CampaignStrategy?: Maybe<v1_CampaignStrategy>;
  /** An array relationship */
  CheckoutDetails: Array<v1_CheckoutDetail>;
  /** An aggregate relationship */
  CheckoutDetails_aggregate: v1_CheckoutDetail_aggregate;
  /** An array relationship */
  ContentLinks: Array<v1_ContentLink>;
  /** An aggregate relationship */
  ContentLinks_aggregate: v1_ContentLink_aggregate;
  /** An object relationship */
  ContentStatus: v1_PostsContentStatus;
  /** An object relationship */
  ContentType?: Maybe<v1_ContentTypes>;
  /** An object relationship */
  CopyScapeDuplication?: Maybe<v1_PostsCopyScape>;
  CopyScapeLastCheck?: Maybe<Scalars['timestamptz']['output']>;
  CopyScapeResult?: Maybe<Scalars['String']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  /** An object relationship */
  DeclinedBy?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Language?: Maybe<v1_new_languagesBase>;
  MPOrderId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  MarketPlaceOrder?: Maybe<v1_MarketPlaceOrder>;
  /** An object relationship */
  MarketplaceProject?: Maybe<v1_MarketplaceProjects>;
  MarketplaceProjectId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  MarketplaceStatus?: Maybe<v1_MarketplaceStatus>;
  NicheId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Offer?: Maybe<v1_Offer>;
  OfferId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  OnlineCheckSubmittedBy?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  OnlineStatus?: Maybe<v1_PostsOnlineStatus>;
  OrderedBy?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  Ordered_By?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  OutreachStatus?: Maybe<v1_PostsOutreachStatus>;
  /** An array relationship */
  POsToPosts: Array<v1_PoToPosts>;
  /** An aggregate relationship */
  POsToPosts_aggregate: v1_PoToPosts_aggregate;
  /** An object relationship */
  PostLinksStatus?: Maybe<v1_PostsLinkStatus>;
  /** An object relationship */
  PostNiche?: Maybe<v1_PostNiche>;
  /** An object relationship */
  PostStatus?: Maybe<v1_PostsStatus>;
  PriorityId?: Maybe<Scalars['Int']['output']>;
  PublisherApprovedOn?: Maybe<Scalars['date']['output']>;
  PublisherDeclinedOn?: Maybe<Scalars['date']['output']>;
  PublisherOrderApprovedOn?: Maybe<Scalars['date']['output']>;
  PublisherOrderDeclinedOn?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  PublisherPostStatus?: Maybe<v1_PublisherPostStatus>;
  PublisherStatus?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  PurchaseOrder?: Maybe<v1_new_POBase>;
  RequestedBy?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  RequestedFrom?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Requested_By?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  SEOApproval?: Maybe<v1_PostsSEOApproval>;
  /** An object relationship */
  SEODeclineReason?: Maybe<v1_PostsSEODeclineReason>;
  SalesStatus?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  StatusCode?: Maybe<v1_PostsStatusCode>;
  /** An object relationship */
  Supplier?: Maybe<v1_ContactBase>;
  /** An object relationship */
  SupplierCurrency?: Maybe<v1_TransactionCurrencyBase>;
  /** An object relationship */
  SupplierPurchaseOrder?: Maybe<v1_new_POBase>;
  /** An object relationship */
  SystemUserBase?: Maybe<v1_SystemUserBase>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  UploadedBy?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Uploader?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Website?: Maybe<v1_Website>;
  /** An object relationship */
  Writer?: Maybe<v1_ContactBase>;
  /** A computed field, executes function "v1.post_project" */
  _Project?: Maybe<Scalars['Int']['output']>;
  advertiser_po_id?: Maybe<Scalars['Int']['output']>;
  /** Approved by Sales Manager */
  approved_by_sm: Scalars['Boolean']['output'];
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Int']['output']>;
  /** Date of approving by Sales Manager */
  approved_by_sm_on?: Maybe<Scalars['date']['output']>;
  approvedby_id?: Maybe<Scalars['Int']['output']>;
  approver_id?: Maybe<Scalars['Int']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  campaign_crm_id?: Maybe<Scalars['uuid']['output']>;
  campaign_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.calc_total_price_manager_float" */
  comp_total_price_manager?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "v1.calc_total_price_writer_float" */
  comp_total_price_writer?: Maybe<Scalars['numeric']['output']>;
  contenttype_id?: Maybe<Scalars['Int']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  declinedby_id?: Maybe<Scalars['Int']['output']>;
  failed_index_checks: Scalars['Int']['output'];
  gindex_checked_on?: Maybe<Scalars['date']['output']>;
  id: Scalars['Int']['output'];
  isGoogleIndexed: Scalars['Boolean']['output'];
  is_disabled: Scalars['Boolean']['output'];
  is_marketplace_post: Scalars['Boolean']['output'];
  is_untrackable: Scalars['Boolean']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  lost_date?: Maybe<Scalars['date']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Int']['output']>;
  merged_date?: Maybe<Scalars['timestamp']['output']>;
  merged_from?: Maybe<Scalars['Int']['output']>;
  merged_to?: Maybe<Scalars['Int']['output']>;
  mergedby_id?: Maybe<Scalars['Int']['output']>;
  new_AnchorText1Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText2Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText3Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText4Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText5Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText6Actual?: Maybe<Scalars['String']['output']>;
  new_Anchortext1?: Maybe<Scalars['String']['output']>;
  new_Anchortext2?: Maybe<Scalars['String']['output']>;
  new_Anchortext3?: Maybe<Scalars['String']['output']>;
  new_Anchortext4Requested?: Maybe<Scalars['String']['output']>;
  new_Anchortext5Requested?: Maybe<Scalars['String']['output']>;
  new_Anchortext6Requested?: Maybe<Scalars['String']['output']>;
  new_BrandId?: Maybe<Scalars['uuid']['output']>;
  new_CMSPostID?: Maybe<Scalars['String']['output']>;
  new_ChecklinkStatus?: Maybe<Scalars['Boolean']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Int']['output']>;
  new_CommentToWriter?: Maybe<Scalars['String']['output']>;
  new_ContentApprovedby?: Maybe<Scalars['uuid']['output']>;
  new_ContentCost: Scalars['numeric']['output'];
  new_ContentOrderStatus: Scalars['Int']['output'];
  new_Contentduedate?: Maybe<Scalars['timestamp']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Int']['output']>;
  new_CopyscapePrivateIndexDate?: Maybe<Scalars['timestamp']['output']>;
  new_CurrentFocus?: Maybe<Scalars['Boolean']['output']>;
  new_DateApproved?: Maybe<Scalars['date']['output']>;
  new_DateAssigned?: Maybe<Scalars['timestamp']['output']>;
  new_DeclineDate?: Maybe<Scalars['timestamp']['output']>;
  new_Duplication?: Maybe<Scalars['float8']['output']>;
  new_Keywords?: Maybe<Scalars['String']['output']>;
  new_OnlineSince?: Maybe<Scalars['timestamp']['output']>;
  new_OnlineStatusCheckDate?: Maybe<Scalars['timestamp']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Int']['output']>;
  new_Owner?: Maybe<Scalars['uuid']['output']>;
  new_PostDesciption?: Maybe<Scalars['String']['output']>;
  new_PostID?: Maybe<Scalars['String']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Int']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_PostStatus?: Maybe<Scalars['Int']['output']>;
  /** deprecated */
  new_Postlivesince?: Maybe<Scalars['timestamp']['output']>;
  new_RequestedFrom?: Maybe<Scalars['uuid']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Int']['output']>;
  new_RequestedOn?: Maybe<Scalars['timestamp']['output']>;
  new_SEOApproval?: Maybe<Scalars['Int']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Int']['output']>;
  new_SentToPublisherOn?: Maybe<Scalars['timestamp']['output']>;
  new_SentTorepost?: Maybe<Scalars['timestamp']['output']>;
  new_SenttoApproval?: Maybe<Scalars['timestamp']['output']>;
  new_SeoTitle?: Maybe<Scalars['String']['output']>;
  new_Supplierprice: Scalars['numeric']['output'];
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
  new_TargetURL4?: Maybe<Scalars['String']['output']>;
  new_TargetURL5?: Maybe<Scalars['String']['output']>;
  new_TargetURL6?: Maybe<Scalars['String']['output']>;
  new_TextHTMLbody?: Maybe<Scalars['String']['output']>;
  new_Title?: Maybe<Scalars['String']['output']>;
  new_ToApproveBy?: Maybe<Scalars['uuid']['output']>;
  new_UploadPrice: Scalars['numeric']['output'];
  new_UploadtoSite?: Maybe<Scalars['Boolean']['output']>;
  new_WebsiteContact?: Maybe<Scalars['uuid']['output']>;
  new_WriterAssigned?: Maybe<Scalars['uuid']['output']>;
  new_WritingInstructions?: Maybe<Scalars['String']['output']>;
  new_alias?: Maybe<Scalars['String']['output']>;
  new_anchortext1type?: Maybe<Scalars['Int']['output']>;
  new_anchortext2type?: Maybe<Scalars['Int']['output']>;
  new_anchortext3type?: Maybe<Scalars['Int']['output']>;
  new_anchortext4type?: Maybe<Scalars['Int']['output']>;
  new_anchortext5type?: Maybe<Scalars['Int']['output']>;
  new_anchortext6type?: Maybe<Scalars['Int']['output']>;
  new_baseurl?: Maybe<Scalars['String']['output']>;
  new_contentdeclinereason?: Maybe<Scalars['String']['output']>;
  new_dropeddate?: Maybe<Scalars['timestamp']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_newpostId?: Maybe<Scalars['uuid']['output']>;
  new_onlinechecksubmit?: Maybe<Scalars['timestamp']['output']>;
  new_onlinechecksubmittedby?: Maybe<Scalars['uuid']['output']>;
  new_postcontenttype?: Maybe<Scalars['uuid']['output']>;
  new_postlanguage?: Maybe<Scalars['uuid']['output']>;
  new_postlinktype?: Maybe<Scalars['Int']['output']>;
  new_repostedon?: Maybe<Scalars['timestamp']['output']>;
  new_seoapprovedon?: Maybe<Scalars['timestamp']['output']>;
  new_seodeclinedby?: Maybe<Scalars['uuid']['output']>;
  new_seodeclinedon?: Maybe<Scalars['timestamp']['output']>;
  new_uploadedby?: Maybe<Scalars['uuid']['output']>;
  new_uploader?: Maybe<Scalars['uuid']['output']>;
  new_urlalias?: Maybe<Scalars['String']['output']>;
  new_website?: Maybe<Scalars['uuid']['output']>;
  new_wordcount?: Maybe<Scalars['Int']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Int']['output']>;
  ordered_on?: Maybe<Scalars['date']['output']>;
  orderedby_id?: Maybe<Scalars['Int']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  /** Date of publish post on MP */
  published_on?: Maybe<Scalars['date']['output']>;
  requestDetails?: Maybe<Scalars['String']['output']>;
  requestedby_id?: Maybe<Scalars['Int']['output']>;
  requestedfrom_id?: Maybe<Scalars['Int']['output']>;
  sent_to_pool_date?: Maybe<Scalars['date']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_id?: Maybe<Scalars['Int']['output']>;
  supplier_currency_id?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  supplier_po_id?: Maybe<Scalars['Int']['output']>;
  targetURLStatus1?: Maybe<Scalars['String']['output']>;
  targetURLStatus2?: Maybe<Scalars['String']['output']>;
  targetURLStatus3?: Maybe<Scalars['String']['output']>;
  targetURLType1?: Maybe<Scalars['Boolean']['output']>;
  targetURLType2?: Maybe<Scalars['Boolean']['output']>;
  targetURLType3?: Maybe<Scalars['Boolean']['output']>;
  target_publish_date?: Maybe<Scalars['date']['output']>;
  uploaded_on?: Maybe<Scalars['date']['output']>;
  uploadedby_id?: Maybe<Scalars['Int']['output']>;
  uploader_id?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.Posts" */
export type v1_PostsCheckoutDetailsArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


/** columns and relationships of "v1.Posts" */
export type v1_PostsCheckoutDetails_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CheckoutDetail_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CheckoutDetail_order_by>>;
  where?: InputMaybe<v1_CheckoutDetail_bool_exp>;
};


/** columns and relationships of "v1.Posts" */
export type v1_PostsContentLinksArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentLink_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentLink_order_by>>;
  where?: InputMaybe<v1_ContentLink_bool_exp>;
};


/** columns and relationships of "v1.Posts" */
export type v1_PostsContentLinks_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentLink_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentLink_order_by>>;
  where?: InputMaybe<v1_ContentLink_bool_exp>;
};


/** columns and relationships of "v1.Posts" */
export type v1_PostsPOsToPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PoToPosts_order_by>>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};


/** columns and relationships of "v1.Posts" */
export type v1_PostsPOsToPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PoToPosts_order_by>>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};

/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType = {
  __typename?: 'v1_PostsAnchorTextType';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  postsByNewAnchortext2type: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByNewAnchortext2type_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByNewAnchortext3type: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByNewAnchortext3type_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByNewAnchortext4type: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByNewAnchortext4type_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByNewAnchortext5type: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByNewAnchortext5type_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByNewAnchortext6type: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByNewAnchortext6type_aggregate: v1_Posts_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext2typeArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext2type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext3typeArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext3type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext4typeArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext4type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext5typeArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext5type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext6typeArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextTypepostsByNewAnchortext6type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType_aggregate = {
  __typename?: 'v1_PostsAnchorTextType_aggregate';
  aggregate?: Maybe<v1_PostsAnchorTextType_aggregate_fields>;
  nodes: Array<v1_PostsAnchorTextType>;
};

/** aggregate fields of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType_aggregate_fields = {
  __typename?: 'v1_PostsAnchorTextType_aggregate_fields';
  avg?: Maybe<v1_PostsAnchorTextType_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsAnchorTextType_max_fields>;
  min?: Maybe<v1_PostsAnchorTextType_min_fields>;
  stddev?: Maybe<v1_PostsAnchorTextType_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsAnchorTextType_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsAnchorTextType_stddev_samp_fields>;
  sum?: Maybe<v1_PostsAnchorTextType_sum_fields>;
  var_pop?: Maybe<v1_PostsAnchorTextType_var_pop_fields>;
  var_samp?: Maybe<v1_PostsAnchorTextType_var_samp_fields>;
  variance?: Maybe<v1_PostsAnchorTextType_variance_fields>;
};


/** aggregate fields of "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsAnchorTextType_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsAnchorTextType_avg_fields = {
  __typename?: 'v1_PostsAnchorTextType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsAnchorTextType". All fields are combined with a logical 'AND'. */
export type v1_PostsAnchorTextType_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsAnchorTextType_bool_exp>>;
  _not?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsAnchorTextType_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  postsByNewAnchortext2type?: InputMaybe<v1_Posts_bool_exp>;
  postsByNewAnchortext2type_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByNewAnchortext3type?: InputMaybe<v1_Posts_bool_exp>;
  postsByNewAnchortext3type_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByNewAnchortext4type?: InputMaybe<v1_Posts_bool_exp>;
  postsByNewAnchortext4type_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByNewAnchortext5type?: InputMaybe<v1_Posts_bool_exp>;
  postsByNewAnchortext5type_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByNewAnchortext6type?: InputMaybe<v1_Posts_bool_exp>;
  postsByNewAnchortext6type_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsAnchorTextType" */
export enum v1_PostsAnchorTextType_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsAnchorTextType_pkey = 'PostsAnchorTextType_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  postsByNewAnchortext2type?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByNewAnchortext3type?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByNewAnchortext4type?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByNewAnchortext5type?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByNewAnchortext6type?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsAnchorTextType_max_fields = {
  __typename?: 'v1_PostsAnchorTextType_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsAnchorTextType_min_fields = {
  __typename?: 'v1_PostsAnchorTextType_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType_mutation_response = {
  __typename?: 'v1_PostsAnchorTextType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsAnchorTextType>;
};

/** input type for inserting object relation for remote table "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType_obj_rel_insert_input = {
  data: v1_PostsAnchorTextType_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsAnchorTextType_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType_on_conflict = {
  constraint: v1_PostsAnchorTextType_constraint;
  update_columns?: Array<v1_PostsAnchorTextType_update_column>;
  where?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsAnchorTextType". */
export type v1_PostsAnchorTextType_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  postsByNewAnchortext2type_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByNewAnchortext3type_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByNewAnchortext4type_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByNewAnchortext5type_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByNewAnchortext6type_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsAnchorTextType */
export type v1_PostsAnchorTextType_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsAnchorTextType" */
export enum v1_PostsAnchorTextType_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsAnchorTextType" */
export type v1_PostsAnchorTextType_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsAnchorTextType_stddev_fields = {
  __typename?: 'v1_PostsAnchorTextType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsAnchorTextType_stddev_pop_fields = {
  __typename?: 'v1_PostsAnchorTextType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsAnchorTextType_stddev_samp_fields = {
  __typename?: 'v1_PostsAnchorTextType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsAnchorTextType" */
export type v1_PostsAnchorTextType_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsAnchorTextType_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsAnchorTextType_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsAnchorTextType_sum_fields = {
  __typename?: 'v1_PostsAnchorTextType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsAnchorTextType" */
export enum v1_PostsAnchorTextType_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsAnchorTextType_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsAnchorTextType_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsAnchorTextType_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsAnchorTextType_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsAnchorTextType_var_pop_fields = {
  __typename?: 'v1_PostsAnchorTextType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsAnchorTextType_var_samp_fields = {
  __typename?: 'v1_PostsAnchorTextType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsAnchorTextType_variance_fields = {
  __typename?: 'v1_PostsAnchorTextType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Content order status for post */
export type v1_PostsContentStatus = {
  __typename?: 'v1_PostsContentStatus';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** Content order status for post */
export type v1_PostsContentStatusPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Content order status for post */
export type v1_PostsContentStatusPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PostsContentStatus" */
export type v1_PostsContentStatus_aggregate = {
  __typename?: 'v1_PostsContentStatus_aggregate';
  aggregate?: Maybe<v1_PostsContentStatus_aggregate_fields>;
  nodes: Array<v1_PostsContentStatus>;
};

/** aggregate fields of "v1.PostsContentStatus" */
export type v1_PostsContentStatus_aggregate_fields = {
  __typename?: 'v1_PostsContentStatus_aggregate_fields';
  avg?: Maybe<v1_PostsContentStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsContentStatus_max_fields>;
  min?: Maybe<v1_PostsContentStatus_min_fields>;
  stddev?: Maybe<v1_PostsContentStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsContentStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsContentStatus_stddev_samp_fields>;
  sum?: Maybe<v1_PostsContentStatus_sum_fields>;
  var_pop?: Maybe<v1_PostsContentStatus_var_pop_fields>;
  var_samp?: Maybe<v1_PostsContentStatus_var_samp_fields>;
  variance?: Maybe<v1_PostsContentStatus_variance_fields>;
};


/** aggregate fields of "v1.PostsContentStatus" */
export type v1_PostsContentStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsContentStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsContentStatus_avg_fields = {
  __typename?: 'v1_PostsContentStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsContentStatus". All fields are combined with a logical 'AND'. */
export type v1_PostsContentStatus_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsContentStatus_bool_exp>>;
  _not?: InputMaybe<v1_PostsContentStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsContentStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsContentStatus" */
export enum v1_PostsContentStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsContentStatus_pkey = 'PostsContentStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsContentStatus" */
export type v1_PostsContentStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsContentStatus" */
export type v1_PostsContentStatus_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsContentStatus_max_fields = {
  __typename?: 'v1_PostsContentStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsContentStatus_min_fields = {
  __typename?: 'v1_PostsContentStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsContentStatus" */
export type v1_PostsContentStatus_mutation_response = {
  __typename?: 'v1_PostsContentStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsContentStatus>;
};

/** input type for inserting object relation for remote table "v1.PostsContentStatus" */
export type v1_PostsContentStatus_obj_rel_insert_input = {
  data: v1_PostsContentStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsContentStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsContentStatus" */
export type v1_PostsContentStatus_on_conflict = {
  constraint: v1_PostsContentStatus_constraint;
  update_columns?: Array<v1_PostsContentStatus_update_column>;
  where?: InputMaybe<v1_PostsContentStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsContentStatus". */
export type v1_PostsContentStatus_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsContentStatus */
export type v1_PostsContentStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsContentStatus" */
export enum v1_PostsContentStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsContentStatus" */
export type v1_PostsContentStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsContentStatus_stddev_fields = {
  __typename?: 'v1_PostsContentStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsContentStatus_stddev_pop_fields = {
  __typename?: 'v1_PostsContentStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsContentStatus_stddev_samp_fields = {
  __typename?: 'v1_PostsContentStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsContentStatus" */
export type v1_PostsContentStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsContentStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsContentStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsContentStatus_sum_fields = {
  __typename?: 'v1_PostsContentStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsContentStatus" */
export enum v1_PostsContentStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsContentStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsContentStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsContentStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsContentStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsContentStatus_var_pop_fields = {
  __typename?: 'v1_PostsContentStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsContentStatus_var_samp_fields = {
  __typename?: 'v1_PostsContentStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsContentStatus_variance_fields = {
  __typename?: 'v1_PostsContentStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsCopyScape" */
export type v1_PostsCopyScape = {
  __typename?: 'v1_PostsCopyScape';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostsCopyScape" */
export type v1_PostsCopyScapePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsCopyScape" */
export type v1_PostsCopyScapePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PostsCopyScape" */
export type v1_PostsCopyScape_aggregate = {
  __typename?: 'v1_PostsCopyScape_aggregate';
  aggregate?: Maybe<v1_PostsCopyScape_aggregate_fields>;
  nodes: Array<v1_PostsCopyScape>;
};

/** aggregate fields of "v1.PostsCopyScape" */
export type v1_PostsCopyScape_aggregate_fields = {
  __typename?: 'v1_PostsCopyScape_aggregate_fields';
  avg?: Maybe<v1_PostsCopyScape_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsCopyScape_max_fields>;
  min?: Maybe<v1_PostsCopyScape_min_fields>;
  stddev?: Maybe<v1_PostsCopyScape_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsCopyScape_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsCopyScape_stddev_samp_fields>;
  sum?: Maybe<v1_PostsCopyScape_sum_fields>;
  var_pop?: Maybe<v1_PostsCopyScape_var_pop_fields>;
  var_samp?: Maybe<v1_PostsCopyScape_var_samp_fields>;
  variance?: Maybe<v1_PostsCopyScape_variance_fields>;
};


/** aggregate fields of "v1.PostsCopyScape" */
export type v1_PostsCopyScape_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsCopyScape_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsCopyScape_avg_fields = {
  __typename?: 'v1_PostsCopyScape_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsCopyScape". All fields are combined with a logical 'AND'. */
export type v1_PostsCopyScape_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsCopyScape_bool_exp>>;
  _not?: InputMaybe<v1_PostsCopyScape_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsCopyScape_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsCopyScape" */
export enum v1_PostsCopyScape_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsCopyScape_pkey = 'PostsCopyScape_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsCopyScape" */
export type v1_PostsCopyScape_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsCopyScape" */
export type v1_PostsCopyScape_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsCopyScape_max_fields = {
  __typename?: 'v1_PostsCopyScape_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsCopyScape_min_fields = {
  __typename?: 'v1_PostsCopyScape_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsCopyScape" */
export type v1_PostsCopyScape_mutation_response = {
  __typename?: 'v1_PostsCopyScape_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsCopyScape>;
};

/** input type for inserting object relation for remote table "v1.PostsCopyScape" */
export type v1_PostsCopyScape_obj_rel_insert_input = {
  data: v1_PostsCopyScape_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsCopyScape_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsCopyScape" */
export type v1_PostsCopyScape_on_conflict = {
  constraint: v1_PostsCopyScape_constraint;
  update_columns?: Array<v1_PostsCopyScape_update_column>;
  where?: InputMaybe<v1_PostsCopyScape_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsCopyScape". */
export type v1_PostsCopyScape_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsCopyScape */
export type v1_PostsCopyScape_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsCopyScape" */
export enum v1_PostsCopyScape_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsCopyScape" */
export type v1_PostsCopyScape_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsCopyScape_stddev_fields = {
  __typename?: 'v1_PostsCopyScape_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsCopyScape_stddev_pop_fields = {
  __typename?: 'v1_PostsCopyScape_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsCopyScape_stddev_samp_fields = {
  __typename?: 'v1_PostsCopyScape_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsCopyScape" */
export type v1_PostsCopyScape_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsCopyScape_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsCopyScape_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsCopyScape_sum_fields = {
  __typename?: 'v1_PostsCopyScape_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsCopyScape" */
export enum v1_PostsCopyScape_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsCopyScape_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsCopyScape_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsCopyScape_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsCopyScape_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsCopyScape_var_pop_fields = {
  __typename?: 'v1_PostsCopyScape_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsCopyScape_var_samp_fields = {
  __typename?: 'v1_PostsCopyScape_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsCopyScape_variance_fields = {
  __typename?: 'v1_PostsCopyScape_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus = {
  __typename?: 'v1_PostsLinkStatus';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostsLinkStatus" */
export type v1_PostsLinkStatusPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsLinkStatus" */
export type v1_PostsLinkStatusPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus_aggregate = {
  __typename?: 'v1_PostsLinkStatus_aggregate';
  aggregate?: Maybe<v1_PostsLinkStatus_aggregate_fields>;
  nodes: Array<v1_PostsLinkStatus>;
};

/** aggregate fields of "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus_aggregate_fields = {
  __typename?: 'v1_PostsLinkStatus_aggregate_fields';
  avg?: Maybe<v1_PostsLinkStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsLinkStatus_max_fields>;
  min?: Maybe<v1_PostsLinkStatus_min_fields>;
  stddev?: Maybe<v1_PostsLinkStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsLinkStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsLinkStatus_stddev_samp_fields>;
  sum?: Maybe<v1_PostsLinkStatus_sum_fields>;
  var_pop?: Maybe<v1_PostsLinkStatus_var_pop_fields>;
  var_samp?: Maybe<v1_PostsLinkStatus_var_samp_fields>;
  variance?: Maybe<v1_PostsLinkStatus_variance_fields>;
};


/** aggregate fields of "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsLinkStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsLinkStatus_avg_fields = {
  __typename?: 'v1_PostsLinkStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsLinkStatus". All fields are combined with a logical 'AND'. */
export type v1_PostsLinkStatus_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsLinkStatus_bool_exp>>;
  _not?: InputMaybe<v1_PostsLinkStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsLinkStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsLinkStatus" */
export enum v1_PostsLinkStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsLinkstatus_pkey = 'PostsLinkstatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsLinkStatus_max_fields = {
  __typename?: 'v1_PostsLinkStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsLinkStatus_min_fields = {
  __typename?: 'v1_PostsLinkStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus_mutation_response = {
  __typename?: 'v1_PostsLinkStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsLinkStatus>;
};

/** input type for inserting object relation for remote table "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus_obj_rel_insert_input = {
  data: v1_PostsLinkStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsLinkStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus_on_conflict = {
  constraint: v1_PostsLinkStatus_constraint;
  update_columns?: Array<v1_PostsLinkStatus_update_column>;
  where?: InputMaybe<v1_PostsLinkStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsLinkStatus". */
export type v1_PostsLinkStatus_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsLinkStatus */
export type v1_PostsLinkStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsLinkStatus" */
export enum v1_PostsLinkStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsLinkStatus" */
export type v1_PostsLinkStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsLinkStatus_stddev_fields = {
  __typename?: 'v1_PostsLinkStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsLinkStatus_stddev_pop_fields = {
  __typename?: 'v1_PostsLinkStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsLinkStatus_stddev_samp_fields = {
  __typename?: 'v1_PostsLinkStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsLinkStatus" */
export type v1_PostsLinkStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsLinkStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsLinkStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsLinkStatus_sum_fields = {
  __typename?: 'v1_PostsLinkStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsLinkStatus" */
export enum v1_PostsLinkStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsLinkStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsLinkStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsLinkStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsLinkStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsLinkStatus_var_pop_fields = {
  __typename?: 'v1_PostsLinkStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsLinkStatus_var_samp_fields = {
  __typename?: 'v1_PostsLinkStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsLinkStatus_variance_fields = {
  __typename?: 'v1_PostsLinkStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus = {
  __typename?: 'v1_PostsOnlineStatus';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatusPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatusPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus_aggregate = {
  __typename?: 'v1_PostsOnlineStatus_aggregate';
  aggregate?: Maybe<v1_PostsOnlineStatus_aggregate_fields>;
  nodes: Array<v1_PostsOnlineStatus>;
};

/** aggregate fields of "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus_aggregate_fields = {
  __typename?: 'v1_PostsOnlineStatus_aggregate_fields';
  avg?: Maybe<v1_PostsOnlineStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsOnlineStatus_max_fields>;
  min?: Maybe<v1_PostsOnlineStatus_min_fields>;
  stddev?: Maybe<v1_PostsOnlineStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsOnlineStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsOnlineStatus_stddev_samp_fields>;
  sum?: Maybe<v1_PostsOnlineStatus_sum_fields>;
  var_pop?: Maybe<v1_PostsOnlineStatus_var_pop_fields>;
  var_samp?: Maybe<v1_PostsOnlineStatus_var_samp_fields>;
  variance?: Maybe<v1_PostsOnlineStatus_variance_fields>;
};


/** aggregate fields of "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsOnlineStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsOnlineStatus_avg_fields = {
  __typename?: 'v1_PostsOnlineStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsOnlineStatus". All fields are combined with a logical 'AND'. */
export type v1_PostsOnlineStatus_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsOnlineStatus_bool_exp>>;
  _not?: InputMaybe<v1_PostsOnlineStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsOnlineStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsOnlineStatus" */
export enum v1_PostsOnlineStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsOnlineStatus_pkey = 'PostsOnlineStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsOnlineStatus_max_fields = {
  __typename?: 'v1_PostsOnlineStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsOnlineStatus_min_fields = {
  __typename?: 'v1_PostsOnlineStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus_mutation_response = {
  __typename?: 'v1_PostsOnlineStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsOnlineStatus>;
};

/** input type for inserting object relation for remote table "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus_obj_rel_insert_input = {
  data: v1_PostsOnlineStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsOnlineStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus_on_conflict = {
  constraint: v1_PostsOnlineStatus_constraint;
  update_columns?: Array<v1_PostsOnlineStatus_update_column>;
  where?: InputMaybe<v1_PostsOnlineStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsOnlineStatus". */
export type v1_PostsOnlineStatus_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsOnlineStatus */
export type v1_PostsOnlineStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsOnlineStatus" */
export enum v1_PostsOnlineStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsOnlineStatus" */
export type v1_PostsOnlineStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsOnlineStatus_stddev_fields = {
  __typename?: 'v1_PostsOnlineStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsOnlineStatus_stddev_pop_fields = {
  __typename?: 'v1_PostsOnlineStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsOnlineStatus_stddev_samp_fields = {
  __typename?: 'v1_PostsOnlineStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsOnlineStatus" */
export type v1_PostsOnlineStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsOnlineStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsOnlineStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsOnlineStatus_sum_fields = {
  __typename?: 'v1_PostsOnlineStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsOnlineStatus" */
export enum v1_PostsOnlineStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsOnlineStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsOnlineStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsOnlineStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsOnlineStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsOnlineStatus_var_pop_fields = {
  __typename?: 'v1_PostsOnlineStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsOnlineStatus_var_samp_fields = {
  __typename?: 'v1_PostsOnlineStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsOnlineStatus_variance_fields = {
  __typename?: 'v1_PostsOnlineStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus = {
  __typename?: 'v1_PostsOutreachStatus';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatusPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatusPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus_aggregate = {
  __typename?: 'v1_PostsOutreachStatus_aggregate';
  aggregate?: Maybe<v1_PostsOutreachStatus_aggregate_fields>;
  nodes: Array<v1_PostsOutreachStatus>;
};

/** aggregate fields of "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus_aggregate_fields = {
  __typename?: 'v1_PostsOutreachStatus_aggregate_fields';
  avg?: Maybe<v1_PostsOutreachStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsOutreachStatus_max_fields>;
  min?: Maybe<v1_PostsOutreachStatus_min_fields>;
  stddev?: Maybe<v1_PostsOutreachStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsOutreachStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsOutreachStatus_stddev_samp_fields>;
  sum?: Maybe<v1_PostsOutreachStatus_sum_fields>;
  var_pop?: Maybe<v1_PostsOutreachStatus_var_pop_fields>;
  var_samp?: Maybe<v1_PostsOutreachStatus_var_samp_fields>;
  variance?: Maybe<v1_PostsOutreachStatus_variance_fields>;
};


/** aggregate fields of "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsOutreachStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsOutreachStatus_avg_fields = {
  __typename?: 'v1_PostsOutreachStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsOutreachStatus". All fields are combined with a logical 'AND'. */
export type v1_PostsOutreachStatus_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsOutreachStatus_bool_exp>>;
  _not?: InputMaybe<v1_PostsOutreachStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsOutreachStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsOutreachStatus" */
export enum v1_PostsOutreachStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsOutreachStatus_pkey = 'PostsOutreachStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsOutreachStatus_max_fields = {
  __typename?: 'v1_PostsOutreachStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsOutreachStatus_min_fields = {
  __typename?: 'v1_PostsOutreachStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus_mutation_response = {
  __typename?: 'v1_PostsOutreachStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsOutreachStatus>;
};

/** input type for inserting object relation for remote table "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus_obj_rel_insert_input = {
  data: v1_PostsOutreachStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsOutreachStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus_on_conflict = {
  constraint: v1_PostsOutreachStatus_constraint;
  update_columns?: Array<v1_PostsOutreachStatus_update_column>;
  where?: InputMaybe<v1_PostsOutreachStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsOutreachStatus". */
export type v1_PostsOutreachStatus_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsOutreachStatus */
export type v1_PostsOutreachStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsOutreachStatus" */
export enum v1_PostsOutreachStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsOutreachStatus" */
export type v1_PostsOutreachStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsOutreachStatus_stddev_fields = {
  __typename?: 'v1_PostsOutreachStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsOutreachStatus_stddev_pop_fields = {
  __typename?: 'v1_PostsOutreachStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsOutreachStatus_stddev_samp_fields = {
  __typename?: 'v1_PostsOutreachStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsOutreachStatus" */
export type v1_PostsOutreachStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsOutreachStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsOutreachStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsOutreachStatus_sum_fields = {
  __typename?: 'v1_PostsOutreachStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsOutreachStatus" */
export enum v1_PostsOutreachStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsOutreachStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsOutreachStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsOutreachStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsOutreachStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsOutreachStatus_var_pop_fields = {
  __typename?: 'v1_PostsOutreachStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsOutreachStatus_var_samp_fields = {
  __typename?: 'v1_PostsOutreachStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsOutreachStatus_variance_fields = {
  __typename?: 'v1_PostsOutreachStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated = {
  __typename?: 'v1_PostsPageTypeDeprecated';
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "v1.PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated_aggregate = {
  __typename?: 'v1_PostsPageTypeDeprecated_aggregate';
  aggregate?: Maybe<v1_PostsPageTypeDeprecated_aggregate_fields>;
  nodes: Array<v1_PostsPageTypeDeprecated>;
};

/** aggregate fields of "v1.PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated_aggregate_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_aggregate_fields';
  avg?: Maybe<v1_PostsPageTypeDeprecated_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsPageTypeDeprecated_max_fields>;
  min?: Maybe<v1_PostsPageTypeDeprecated_min_fields>;
  stddev?: Maybe<v1_PostsPageTypeDeprecated_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsPageTypeDeprecated_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsPageTypeDeprecated_stddev_samp_fields>;
  sum?: Maybe<v1_PostsPageTypeDeprecated_sum_fields>;
  var_pop?: Maybe<v1_PostsPageTypeDeprecated_var_pop_fields>;
  var_samp?: Maybe<v1_PostsPageTypeDeprecated_var_samp_fields>;
  variance?: Maybe<v1_PostsPageTypeDeprecated_variance_fields>;
};


/** aggregate fields of "v1.PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsPageTypeDeprecated_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsPageTypeDeprecated_avg_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsPageTypeDeprecated". All fields are combined with a logical 'AND'. */
export type v1_PostsPageTypeDeprecated_bool_exp = {
  _and?: InputMaybe<Array<v1_PostsPageTypeDeprecated_bool_exp>>;
  _not?: InputMaybe<v1_PostsPageTypeDeprecated_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsPageTypeDeprecated_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsPageTypeDeprecated" */
export enum v1_PostsPageTypeDeprecated_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsPageType_pkey = 'PostsPageType_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsPageTypeDeprecated_max_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsPageTypeDeprecated_min_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated_mutation_response = {
  __typename?: 'v1_PostsPageTypeDeprecated_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsPageTypeDeprecated>;
};

/** on_conflict condition type for table "v1.PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated_on_conflict = {
  constraint: v1_PostsPageTypeDeprecated_constraint;
  update_columns?: Array<v1_PostsPageTypeDeprecated_update_column>;
  where?: InputMaybe<v1_PostsPageTypeDeprecated_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsPageTypeDeprecated". */
export type v1_PostsPageTypeDeprecated_order_by = {
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsPageTypeDeprecated */
export type v1_PostsPageTypeDeprecated_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsPageTypeDeprecated" */
export enum v1_PostsPageTypeDeprecated_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsPageTypeDeprecated_stddev_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsPageTypeDeprecated_stddev_pop_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsPageTypeDeprecated_stddev_samp_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsPageTypeDeprecated" */
export type v1_PostsPageTypeDeprecated_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsPageTypeDeprecated_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsPageTypeDeprecated_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsPageTypeDeprecated_sum_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsPageTypeDeprecated" */
export enum v1_PostsPageTypeDeprecated_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsPageTypeDeprecated_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsPageTypeDeprecated_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsPageTypeDeprecated_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsPageTypeDeprecated_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsPageTypeDeprecated_var_pop_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsPageTypeDeprecated_var_samp_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsPageTypeDeprecated_variance_fields = {
  __typename?: 'v1_PostsPageTypeDeprecated_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsRemovalStatus" */
export type v1_PostsRemovalStatus = {
  __typename?: 'v1_PostsRemovalStatus';
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "v1.PostsRemovalStatus" */
export type v1_PostsRemovalStatus_aggregate = {
  __typename?: 'v1_PostsRemovalStatus_aggregate';
  aggregate?: Maybe<v1_PostsRemovalStatus_aggregate_fields>;
  nodes: Array<v1_PostsRemovalStatus>;
};

/** aggregate fields of "v1.PostsRemovalStatus" */
export type v1_PostsRemovalStatus_aggregate_fields = {
  __typename?: 'v1_PostsRemovalStatus_aggregate_fields';
  avg?: Maybe<v1_PostsRemovalStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsRemovalStatus_max_fields>;
  min?: Maybe<v1_PostsRemovalStatus_min_fields>;
  stddev?: Maybe<v1_PostsRemovalStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsRemovalStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsRemovalStatus_stddev_samp_fields>;
  sum?: Maybe<v1_PostsRemovalStatus_sum_fields>;
  var_pop?: Maybe<v1_PostsRemovalStatus_var_pop_fields>;
  var_samp?: Maybe<v1_PostsRemovalStatus_var_samp_fields>;
  variance?: Maybe<v1_PostsRemovalStatus_variance_fields>;
};


/** aggregate fields of "v1.PostsRemovalStatus" */
export type v1_PostsRemovalStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsRemovalStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsRemovalStatus_avg_fields = {
  __typename?: 'v1_PostsRemovalStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsRemovalStatus". All fields are combined with a logical 'AND'. */
export type v1_PostsRemovalStatus_bool_exp = {
  _and?: InputMaybe<Array<v1_PostsRemovalStatus_bool_exp>>;
  _not?: InputMaybe<v1_PostsRemovalStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsRemovalStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsRemovalStatus" */
export enum v1_PostsRemovalStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsRemovalStatus_pkey = 'PostsRemovalStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsRemovalStatus" */
export type v1_PostsRemovalStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsRemovalStatus" */
export type v1_PostsRemovalStatus_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsRemovalStatus_max_fields = {
  __typename?: 'v1_PostsRemovalStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsRemovalStatus_min_fields = {
  __typename?: 'v1_PostsRemovalStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsRemovalStatus" */
export type v1_PostsRemovalStatus_mutation_response = {
  __typename?: 'v1_PostsRemovalStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsRemovalStatus>;
};

/** on_conflict condition type for table "v1.PostsRemovalStatus" */
export type v1_PostsRemovalStatus_on_conflict = {
  constraint: v1_PostsRemovalStatus_constraint;
  update_columns?: Array<v1_PostsRemovalStatus_update_column>;
  where?: InputMaybe<v1_PostsRemovalStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsRemovalStatus". */
export type v1_PostsRemovalStatus_order_by = {
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsRemovalStatus */
export type v1_PostsRemovalStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsRemovalStatus" */
export enum v1_PostsRemovalStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsRemovalStatus" */
export type v1_PostsRemovalStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsRemovalStatus_stddev_fields = {
  __typename?: 'v1_PostsRemovalStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsRemovalStatus_stddev_pop_fields = {
  __typename?: 'v1_PostsRemovalStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsRemovalStatus_stddev_samp_fields = {
  __typename?: 'v1_PostsRemovalStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsRemovalStatus" */
export type v1_PostsRemovalStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsRemovalStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsRemovalStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsRemovalStatus_sum_fields = {
  __typename?: 'v1_PostsRemovalStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsRemovalStatus" */
export enum v1_PostsRemovalStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsRemovalStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsRemovalStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsRemovalStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsRemovalStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsRemovalStatus_var_pop_fields = {
  __typename?: 'v1_PostsRemovalStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsRemovalStatus_var_samp_fields = {
  __typename?: 'v1_PostsRemovalStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsRemovalStatus_variance_fields = {
  __typename?: 'v1_PostsRemovalStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval = {
  __typename?: 'v1_PostsSEOApproval';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostsSEOApproval" */
export type v1_PostsSEOApprovalPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsSEOApproval" */
export type v1_PostsSEOApprovalPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval_aggregate = {
  __typename?: 'v1_PostsSEOApproval_aggregate';
  aggregate?: Maybe<v1_PostsSEOApproval_aggregate_fields>;
  nodes: Array<v1_PostsSEOApproval>;
};

/** aggregate fields of "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval_aggregate_fields = {
  __typename?: 'v1_PostsSEOApproval_aggregate_fields';
  avg?: Maybe<v1_PostsSEOApproval_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsSEOApproval_max_fields>;
  min?: Maybe<v1_PostsSEOApproval_min_fields>;
  stddev?: Maybe<v1_PostsSEOApproval_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsSEOApproval_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsSEOApproval_stddev_samp_fields>;
  sum?: Maybe<v1_PostsSEOApproval_sum_fields>;
  var_pop?: Maybe<v1_PostsSEOApproval_var_pop_fields>;
  var_samp?: Maybe<v1_PostsSEOApproval_var_samp_fields>;
  variance?: Maybe<v1_PostsSEOApproval_variance_fields>;
};


/** aggregate fields of "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsSEOApproval_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsSEOApproval_avg_fields = {
  __typename?: 'v1_PostsSEOApproval_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsSEOApproval". All fields are combined with a logical 'AND'. */
export type v1_PostsSEOApproval_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsSEOApproval_bool_exp>>;
  _not?: InputMaybe<v1_PostsSEOApproval_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsSEOApproval_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsSEOApproval" */
export enum v1_PostsSEOApproval_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsSEOApproval_pkey = 'PostsSEOApproval_pkey',
  /** unique or primary key constraint on columns "value" */
  PostsSEOApproval_value_key = 'PostsSEOApproval_value_key'
}

/** input type for incrementing numeric columns in table "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsSEOApproval_max_fields = {
  __typename?: 'v1_PostsSEOApproval_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsSEOApproval_min_fields = {
  __typename?: 'v1_PostsSEOApproval_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval_mutation_response = {
  __typename?: 'v1_PostsSEOApproval_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsSEOApproval>;
};

/** input type for inserting object relation for remote table "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval_obj_rel_insert_input = {
  data: v1_PostsSEOApproval_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsSEOApproval_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval_on_conflict = {
  constraint: v1_PostsSEOApproval_constraint;
  update_columns?: Array<v1_PostsSEOApproval_update_column>;
  where?: InputMaybe<v1_PostsSEOApproval_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsSEOApproval". */
export type v1_PostsSEOApproval_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsSEOApproval */
export type v1_PostsSEOApproval_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsSEOApproval" */
export enum v1_PostsSEOApproval_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsSEOApproval" */
export type v1_PostsSEOApproval_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsSEOApproval_stddev_fields = {
  __typename?: 'v1_PostsSEOApproval_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsSEOApproval_stddev_pop_fields = {
  __typename?: 'v1_PostsSEOApproval_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsSEOApproval_stddev_samp_fields = {
  __typename?: 'v1_PostsSEOApproval_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsSEOApproval" */
export type v1_PostsSEOApproval_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsSEOApproval_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsSEOApproval_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsSEOApproval_sum_fields = {
  __typename?: 'v1_PostsSEOApproval_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsSEOApproval" */
export enum v1_PostsSEOApproval_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsSEOApproval_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsSEOApproval_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsSEOApproval_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsSEOApproval_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsSEOApproval_var_pop_fields = {
  __typename?: 'v1_PostsSEOApproval_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsSEOApproval_var_samp_fields = {
  __typename?: 'v1_PostsSEOApproval_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsSEOApproval_variance_fields = {
  __typename?: 'v1_PostsSEOApproval_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason = {
  __typename?: 'v1_PostsSEODeclineReason';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReasonPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReasonPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_aggregate = {
  __typename?: 'v1_PostsSEODeclineReason_aggregate';
  aggregate?: Maybe<v1_PostsSEODeclineReason_aggregate_fields>;
  nodes: Array<v1_PostsSEODeclineReason>;
};

/** aggregate fields of "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_aggregate_fields = {
  __typename?: 'v1_PostsSEODeclineReason_aggregate_fields';
  avg?: Maybe<v1_PostsSEODeclineReason_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsSEODeclineReason_max_fields>;
  min?: Maybe<v1_PostsSEODeclineReason_min_fields>;
  stddev?: Maybe<v1_PostsSEODeclineReason_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsSEODeclineReason_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsSEODeclineReason_stddev_samp_fields>;
  sum?: Maybe<v1_PostsSEODeclineReason_sum_fields>;
  var_pop?: Maybe<v1_PostsSEODeclineReason_var_pop_fields>;
  var_samp?: Maybe<v1_PostsSEODeclineReason_var_samp_fields>;
  variance?: Maybe<v1_PostsSEODeclineReason_variance_fields>;
};


/** aggregate fields of "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsSEODeclineReason_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsSEODeclineReason_avg_fields = {
  __typename?: 'v1_PostsSEODeclineReason_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsSEODeclineReason". All fields are combined with a logical 'AND'. */
export type v1_PostsSEODeclineReason_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsSEODeclineReason_bool_exp>>;
  _not?: InputMaybe<v1_PostsSEODeclineReason_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsSEODeclineReason_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsSEODeclineReason" */
export enum v1_PostsSEODeclineReason_constraint {
  /** unique or primary key constraint on columns "name" */
  PostsSEODeclineReason_name_key = 'PostsSEODeclineReason_name_key',
  /** unique or primary key constraint on columns "id" */
  PostsSEODeclineReason_pkey = 'PostsSEODeclineReason_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsSEODeclineReason_max_fields = {
  __typename?: 'v1_PostsSEODeclineReason_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsSEODeclineReason_min_fields = {
  __typename?: 'v1_PostsSEODeclineReason_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_mutation_response = {
  __typename?: 'v1_PostsSEODeclineReason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsSEODeclineReason>;
};

/** input type for inserting object relation for remote table "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_obj_rel_insert_input = {
  data: v1_PostsSEODeclineReason_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsSEODeclineReason_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_on_conflict = {
  constraint: v1_PostsSEODeclineReason_constraint;
  update_columns?: Array<v1_PostsSEODeclineReason_update_column>;
  where?: InputMaybe<v1_PostsSEODeclineReason_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsSEODeclineReason". */
export type v1_PostsSEODeclineReason_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsSEODeclineReason */
export type v1_PostsSEODeclineReason_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsSEODeclineReason" */
export enum v1_PostsSEODeclineReason_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsSEODeclineReason_stddev_fields = {
  __typename?: 'v1_PostsSEODeclineReason_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsSEODeclineReason_stddev_pop_fields = {
  __typename?: 'v1_PostsSEODeclineReason_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsSEODeclineReason_stddev_samp_fields = {
  __typename?: 'v1_PostsSEODeclineReason_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsSEODeclineReason" */
export type v1_PostsSEODeclineReason_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsSEODeclineReason_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsSEODeclineReason_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsSEODeclineReason_sum_fields = {
  __typename?: 'v1_PostsSEODeclineReason_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsSEODeclineReason" */
export enum v1_PostsSEODeclineReason_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_PostsSEODeclineReason_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsSEODeclineReason_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsSEODeclineReason_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsSEODeclineReason_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsSEODeclineReason_var_pop_fields = {
  __typename?: 'v1_PostsSEODeclineReason_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsSEODeclineReason_var_samp_fields = {
  __typename?: 'v1_PostsSEODeclineReason_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsSEODeclineReason_variance_fields = {
  __typename?: 'v1_PostsSEODeclineReason_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsStatus" */
export type v1_PostsStatus = {
  __typename?: 'v1_PostsStatus';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostsStatus" */
export type v1_PostsStatusPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsStatus" */
export type v1_PostsStatusPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** columns and relationships of "v1.PostsStatusCode" */
export type v1_PostsStatusCode = {
  __typename?: 'v1_PostsStatusCode';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


/** columns and relationships of "v1.PostsStatusCode" */
export type v1_PostsStatusCodePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PostsStatusCode" */
export type v1_PostsStatusCodePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PostsStatusCode" */
export type v1_PostsStatusCode_aggregate = {
  __typename?: 'v1_PostsStatusCode_aggregate';
  aggregate?: Maybe<v1_PostsStatusCode_aggregate_fields>;
  nodes: Array<v1_PostsStatusCode>;
};

/** aggregate fields of "v1.PostsStatusCode" */
export type v1_PostsStatusCode_aggregate_fields = {
  __typename?: 'v1_PostsStatusCode_aggregate_fields';
  avg?: Maybe<v1_PostsStatusCode_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsStatusCode_max_fields>;
  min?: Maybe<v1_PostsStatusCode_min_fields>;
  stddev?: Maybe<v1_PostsStatusCode_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsStatusCode_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsStatusCode_stddev_samp_fields>;
  sum?: Maybe<v1_PostsStatusCode_sum_fields>;
  var_pop?: Maybe<v1_PostsStatusCode_var_pop_fields>;
  var_samp?: Maybe<v1_PostsStatusCode_var_samp_fields>;
  variance?: Maybe<v1_PostsStatusCode_variance_fields>;
};


/** aggregate fields of "v1.PostsStatusCode" */
export type v1_PostsStatusCode_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsStatusCode_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsStatusCode_avg_fields = {
  __typename?: 'v1_PostsStatusCode_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsStatusCode". All fields are combined with a logical 'AND'. */
export type v1_PostsStatusCode_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsStatusCode_bool_exp>>;
  _not?: InputMaybe<v1_PostsStatusCode_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsStatusCode_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsStatusCode" */
export enum v1_PostsStatusCode_constraint {
  /** unique or primary key constraint on columns "name" */
  PostsStatusCode_name_key = 'PostsStatusCode_name_key',
  /** unique or primary key constraint on columns "id" */
  PostsStatusCode_pkey = 'PostsStatusCode_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsStatusCode" */
export type v1_PostsStatusCode_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsStatusCode" */
export type v1_PostsStatusCode_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsStatusCode_max_fields = {
  __typename?: 'v1_PostsStatusCode_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsStatusCode_min_fields = {
  __typename?: 'v1_PostsStatusCode_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsStatusCode" */
export type v1_PostsStatusCode_mutation_response = {
  __typename?: 'v1_PostsStatusCode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsStatusCode>;
};

/** input type for inserting object relation for remote table "v1.PostsStatusCode" */
export type v1_PostsStatusCode_obj_rel_insert_input = {
  data: v1_PostsStatusCode_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsStatusCode_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsStatusCode" */
export type v1_PostsStatusCode_on_conflict = {
  constraint: v1_PostsStatusCode_constraint;
  update_columns?: Array<v1_PostsStatusCode_update_column>;
  where?: InputMaybe<v1_PostsStatusCode_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsStatusCode". */
export type v1_PostsStatusCode_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsStatusCode */
export type v1_PostsStatusCode_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsStatusCode" */
export enum v1_PostsStatusCode_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.PostsStatusCode" */
export type v1_PostsStatusCode_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsStatusCode_stddev_fields = {
  __typename?: 'v1_PostsStatusCode_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsStatusCode_stddev_pop_fields = {
  __typename?: 'v1_PostsStatusCode_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsStatusCode_stddev_samp_fields = {
  __typename?: 'v1_PostsStatusCode_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsStatusCode" */
export type v1_PostsStatusCode_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsStatusCode_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsStatusCode_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsStatusCode_sum_fields = {
  __typename?: 'v1_PostsStatusCode_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsStatusCode" */
export enum v1_PostsStatusCode_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_PostsStatusCode_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsStatusCode_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsStatusCode_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsStatusCode_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsStatusCode_var_pop_fields = {
  __typename?: 'v1_PostsStatusCode_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsStatusCode_var_samp_fields = {
  __typename?: 'v1_PostsStatusCode_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsStatusCode_variance_fields = {
  __typename?: 'v1_PostsStatusCode_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.PostsStatus" */
export type v1_PostsStatus_aggregate = {
  __typename?: 'v1_PostsStatus_aggregate';
  aggregate?: Maybe<v1_PostsStatus_aggregate_fields>;
  nodes: Array<v1_PostsStatus>;
};

/** aggregate fields of "v1.PostsStatus" */
export type v1_PostsStatus_aggregate_fields = {
  __typename?: 'v1_PostsStatus_aggregate_fields';
  avg?: Maybe<v1_PostsStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsStatus_max_fields>;
  min?: Maybe<v1_PostsStatus_min_fields>;
  stddev?: Maybe<v1_PostsStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsStatus_stddev_samp_fields>;
  sum?: Maybe<v1_PostsStatus_sum_fields>;
  var_pop?: Maybe<v1_PostsStatus_var_pop_fields>;
  var_samp?: Maybe<v1_PostsStatus_var_samp_fields>;
  variance?: Maybe<v1_PostsStatus_variance_fields>;
};


/** aggregate fields of "v1.PostsStatus" */
export type v1_PostsStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsStatus_avg_fields = {
  __typename?: 'v1_PostsStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsStatus". All fields are combined with a logical 'AND'. */
export type v1_PostsStatus_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PostsStatus_bool_exp>>;
  _not?: InputMaybe<v1_PostsStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PostsStatus" */
export enum v1_PostsStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  PostsStatus_pkey = 'PostsStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.PostsStatus" */
export type v1_PostsStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PostsStatus" */
export type v1_PostsStatus_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PostsStatus_max_fields = {
  __typename?: 'v1_PostsStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsStatus_min_fields = {
  __typename?: 'v1_PostsStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PostsStatus" */
export type v1_PostsStatus_mutation_response = {
  __typename?: 'v1_PostsStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PostsStatus>;
};

/** input type for inserting object relation for remote table "v1.PostsStatus" */
export type v1_PostsStatus_obj_rel_insert_input = {
  data: v1_PostsStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PostsStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.PostsStatus" */
export type v1_PostsStatus_on_conflict = {
  constraint: v1_PostsStatus_constraint;
  update_columns?: Array<v1_PostsStatus_update_column>;
  where?: InputMaybe<v1_PostsStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.PostsStatus". */
export type v1_PostsStatus_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PostsStatus */
export type v1_PostsStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PostsStatus" */
export enum v1_PostsStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PostsStatus" */
export type v1_PostsStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PostsStatus_stddev_fields = {
  __typename?: 'v1_PostsStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsStatus_stddev_pop_fields = {
  __typename?: 'v1_PostsStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsStatus_stddev_samp_fields = {
  __typename?: 'v1_PostsStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsStatus" */
export type v1_PostsStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsStatus_sum_fields = {
  __typename?: 'v1_PostsStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PostsStatus" */
export enum v1_PostsStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PostsStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PostsStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PostsStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PostsStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PostsStatus_var_pop_fields = {
  __typename?: 'v1_PostsStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsStatus_var_samp_fields = {
  __typename?: 'v1_PostsStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsStatus_variance_fields = {
  __typename?: 'v1_PostsStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PostsToIndex" */
export type v1_PostsToIndex = {
  __typename?: 'v1_PostsToIndex';
  failed_index_checks?: Maybe<Scalars['Int']['output']>;
  gindex_checked_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isGoogleIndexed?: Maybe<Scalars['Boolean']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "v1.PostsToIndex" */
export type v1_PostsToIndex_aggregate = {
  __typename?: 'v1_PostsToIndex_aggregate';
  aggregate?: Maybe<v1_PostsToIndex_aggregate_fields>;
  nodes: Array<v1_PostsToIndex>;
};

/** aggregate fields of "v1.PostsToIndex" */
export type v1_PostsToIndex_aggregate_fields = {
  __typename?: 'v1_PostsToIndex_aggregate_fields';
  avg?: Maybe<v1_PostsToIndex_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PostsToIndex_max_fields>;
  min?: Maybe<v1_PostsToIndex_min_fields>;
  stddev?: Maybe<v1_PostsToIndex_stddev_fields>;
  stddev_pop?: Maybe<v1_PostsToIndex_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PostsToIndex_stddev_samp_fields>;
  sum?: Maybe<v1_PostsToIndex_sum_fields>;
  var_pop?: Maybe<v1_PostsToIndex_var_pop_fields>;
  var_samp?: Maybe<v1_PostsToIndex_var_samp_fields>;
  variance?: Maybe<v1_PostsToIndex_variance_fields>;
};


/** aggregate fields of "v1.PostsToIndex" */
export type v1_PostsToIndex_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PostsToIndex_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PostsToIndex_avg_fields = {
  __typename?: 'v1_PostsToIndex_avg_fields';
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PostsToIndex". All fields are combined with a logical 'AND'. */
export type v1_PostsToIndex_bool_exp = {
  _and?: InputMaybe<Array<v1_PostsToIndex_bool_exp>>;
  _not?: InputMaybe<v1_PostsToIndex_bool_exp>;
  _or?: InputMaybe<Array<v1_PostsToIndex_bool_exp>>;
  failed_index_checks?: InputMaybe<Int_comparison_exp>;
  gindex_checked_on?: InputMaybe<date_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  isGoogleIndexed?: InputMaybe<Boolean_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type v1_PostsToIndex_max_fields = {
  __typename?: 'v1_PostsToIndex_max_fields';
  failed_index_checks?: Maybe<Scalars['Int']['output']>;
  gindex_checked_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PostsToIndex_min_fields = {
  __typename?: 'v1_PostsToIndex_min_fields';
  failed_index_checks?: Maybe<Scalars['Int']['output']>;
  gindex_checked_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "v1.PostsToIndex". */
export type v1_PostsToIndex_order_by = {
  failed_index_checks?: InputMaybe<order_by>;
  gindex_checked_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  isGoogleIndexed?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
};

/** select columns of table "v1.PostsToIndex" */
export enum v1_PostsToIndex_select_column {
  /** column name */
  failed_index_checks = 'failed_index_checks',
  /** column name */
  gindex_checked_on = 'gindex_checked_on',
  /** column name */
  id = 'id',
  /** column name */
  isGoogleIndexed = 'isGoogleIndexed',
  /** column name */
  new_name = 'new_name'
}

/** aggregate stddev on columns */
export type v1_PostsToIndex_stddev_fields = {
  __typename?: 'v1_PostsToIndex_stddev_fields';
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PostsToIndex_stddev_pop_fields = {
  __typename?: 'v1_PostsToIndex_stddev_pop_fields';
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PostsToIndex_stddev_samp_fields = {
  __typename?: 'v1_PostsToIndex_stddev_samp_fields';
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PostsToIndex" */
export type v1_PostsToIndex_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PostsToIndex_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PostsToIndex_stream_cursor_value_input = {
  failed_index_checks?: InputMaybe<Scalars['Int']['input']>;
  gindex_checked_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isGoogleIndexed?: InputMaybe<Scalars['Boolean']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PostsToIndex_sum_fields = {
  __typename?: 'v1_PostsToIndex_sum_fields';
  failed_index_checks?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type v1_PostsToIndex_var_pop_fields = {
  __typename?: 'v1_PostsToIndex_var_pop_fields';
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PostsToIndex_var_samp_fields = {
  __typename?: 'v1_PostsToIndex_var_samp_fields';
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PostsToIndex_variance_fields = {
  __typename?: 'v1_PostsToIndex_variance_fields';
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.Posts" */
export type v1_Posts_aggregate = {
  __typename?: 'v1_Posts_aggregate';
  aggregate?: Maybe<v1_Posts_aggregate_fields>;
  nodes: Array<v1_Posts>;
};

export type v1_Posts_aggregate_bool_exp = {
  avg?: InputMaybe<v1_Posts_aggregate_bool_exp_avg>;
  bool_and?: InputMaybe<v1_Posts_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_Posts_aggregate_bool_exp_bool_or>;
  corr?: InputMaybe<v1_Posts_aggregate_bool_exp_corr>;
  count?: InputMaybe<v1_Posts_aggregate_bool_exp_count>;
  covar_samp?: InputMaybe<v1_Posts_aggregate_bool_exp_covar_samp>;
  max?: InputMaybe<v1_Posts_aggregate_bool_exp_max>;
  min?: InputMaybe<v1_Posts_aggregate_bool_exp_min>;
  stddev_samp?: InputMaybe<v1_Posts_aggregate_bool_exp_stddev_samp>;
  sum?: InputMaybe<v1_Posts_aggregate_bool_exp_sum>;
  var_samp?: InputMaybe<v1_Posts_aggregate_bool_exp_var_samp>;
};

export type v1_Posts_aggregate_bool_exp_avg = {
  arguments: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_avg_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_bool_and = {
  arguments: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_bool_or = {
  arguments: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_corr = {
  arguments: v1_Posts_aggregate_bool_exp_corr_arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_corr_arguments = {
  X: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_corr_arguments_columns;
  Y: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_corr_arguments_columns;
};

export type v1_Posts_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Posts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: Int_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_covar_samp = {
  arguments: v1_Posts_aggregate_bool_exp_covar_samp_arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_covar_samp_arguments = {
  X: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_covar_samp_arguments_columns;
};

export type v1_Posts_aggregate_bool_exp_max = {
  arguments: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_max_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_min = {
  arguments: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_min_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_stddev_samp = {
  arguments: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_sum = {
  arguments: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_sum_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Posts_aggregate_bool_exp_var_samp = {
  arguments: v1_Posts_select_column_v1_Posts_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Posts_bool_exp>;
  predicate: float8_comparison_exp;
};

/** aggregate fields of "v1.Posts" */
export type v1_Posts_aggregate_fields = {
  __typename?: 'v1_Posts_aggregate_fields';
  avg?: Maybe<v1_Posts_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Posts_max_fields>;
  min?: Maybe<v1_Posts_min_fields>;
  stddev?: Maybe<v1_Posts_stddev_fields>;
  stddev_pop?: Maybe<v1_Posts_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Posts_stddev_samp_fields>;
  sum?: Maybe<v1_Posts_sum_fields>;
  var_pop?: Maybe<v1_Posts_var_pop_fields>;
  var_samp?: Maybe<v1_Posts_var_samp_fields>;
  variance?: Maybe<v1_Posts_variance_fields>;
};


/** aggregate fields of "v1.Posts" */
export type v1_Posts_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Posts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Posts" */
export type v1_Posts_aggregate_order_by = {
  avg?: InputMaybe<v1_Posts_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Posts_max_order_by>;
  min?: InputMaybe<v1_Posts_min_order_by>;
  stddev?: InputMaybe<v1_Posts_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Posts_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Posts_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Posts_sum_order_by>;
  var_pop?: InputMaybe<v1_Posts_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Posts_var_samp_order_by>;
  variance?: InputMaybe<v1_Posts_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Posts" */
export type v1_Posts_arr_rel_insert_input = {
  data: Array<v1_Posts_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Posts_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Posts_avg_fields = {
  __typename?: 'v1_Posts_avg_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Float']['output']>;
  ArticleTone?: Maybe<Scalars['Float']['output']>;
  MPOrderId?: Maybe<Scalars['Float']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferId?: Maybe<Scalars['Float']['output']>;
  PriorityId?: Maybe<Scalars['Float']['output']>;
  PublisherStatus?: Maybe<Scalars['Float']['output']>;
  SalesStatus?: Maybe<Scalars['Float']['output']>;
  advertiser_po_id?: Maybe<Scalars['Float']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  approver_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  campaign_id?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  declinedby_id?: Maybe<Scalars['Float']['output']>;
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Float']['output']>;
  merged_from?: Maybe<Scalars['Float']['output']>;
  merged_to?: Maybe<Scalars['Float']['output']>;
  mergedby_id?: Maybe<Scalars['Float']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Float']['output']>;
  new_ContentCost?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Float']['output']>;
  new_Duplication?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Float']['output']>;
  new_SEOApproval?: Maybe<Scalars['Float']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Float']['output']>;
  new_Supplierprice?: Maybe<Scalars['Float']['output']>;
  new_UploadPrice?: Maybe<Scalars['Float']['output']>;
  new_anchortext1type?: Maybe<Scalars['Float']['output']>;
  new_anchortext2type?: Maybe<Scalars['Float']['output']>;
  new_anchortext3type?: Maybe<Scalars['Float']['output']>;
  new_anchortext4type?: Maybe<Scalars['Float']['output']>;
  new_anchortext5type?: Maybe<Scalars['Float']['output']>;
  new_anchortext6type?: Maybe<Scalars['Float']['output']>;
  new_postlinktype?: Maybe<Scalars['Float']['output']>;
  new_wordcount?: Maybe<Scalars['Float']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Float']['output']>;
  orderedby_id?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  requestedby_id?: Maybe<Scalars['Float']['output']>;
  requestedfrom_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  supplier_currency_id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  supplier_po_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Posts" */
export type v1_Posts_avg_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Posts". All fields are combined with a logical 'AND'. */
export type v1_Posts_bool_exp = {
  Advertiser?: InputMaybe<v1_ContactBase_bool_exp>;
  AdvertiserApprovedOn?: InputMaybe<date_comparison_exp>;
  AdvertiserDeclinedOn?: InputMaybe<date_comparison_exp>;
  AdvertiserId?: InputMaybe<Int_comparison_exp>;
  AdvertiserOrderedOn?: InputMaybe<date_comparison_exp>;
  AdvertiserPurchaseOrder?: InputMaybe<v1_new_POBase_bool_exp>;
  AdvertiserStatus?: InputMaybe<Int_comparison_exp>;
  AnchorText1Type?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
  AnchorText2Type?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
  AnchorText3Type?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
  AnchorText4Type?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
  AnchorText5Type?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
  AnchorText6Type?: InputMaybe<v1_PostsAnchorTextType_bool_exp>;
  ApprovedBy?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Approver?: InputMaybe<v1_SystemUserBase_bool_exp>;
  ApproverSM?: InputMaybe<v1_SystemUserBase_bool_exp>;
  ArticleTone?: InputMaybe<Int_comparison_exp>;
  Article_Tone?: InputMaybe<v1_ArticleTones_bool_exp>;
  Brand?: InputMaybe<v1_new_brandBase_bool_exp>;
  Campaign?: InputMaybe<v1_Campaign_bool_exp>;
  CampaignStrategy?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  CheckoutDetails?: InputMaybe<v1_CheckoutDetail_bool_exp>;
  CheckoutDetails_aggregate?: InputMaybe<v1_CheckoutDetail_aggregate_bool_exp>;
  ContentLinks?: InputMaybe<v1_ContentLink_bool_exp>;
  ContentLinks_aggregate?: InputMaybe<v1_ContentLink_aggregate_bool_exp>;
  ContentStatus?: InputMaybe<v1_PostsContentStatus_bool_exp>;
  ContentType?: InputMaybe<v1_ContentTypes_bool_exp>;
  CopyScapeDuplication?: InputMaybe<v1_PostsCopyScape_bool_exp>;
  CopyScapeLastCheck?: InputMaybe<timestamptz_comparison_exp>;
  CopyScapeResult?: InputMaybe<String_comparison_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  DeclinedBy?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Language?: InputMaybe<v1_new_languagesBase_bool_exp>;
  MPOrderId?: InputMaybe<Int_comparison_exp>;
  MarketPlaceOrder?: InputMaybe<v1_MarketPlaceOrder_bool_exp>;
  MarketplaceProject?: InputMaybe<v1_MarketplaceProjects_bool_exp>;
  MarketplaceProjectId?: InputMaybe<Int_comparison_exp>;
  MarketplaceStatus?: InputMaybe<v1_MarketplaceStatus_bool_exp>;
  NicheId?: InputMaybe<Int_comparison_exp>;
  Offer?: InputMaybe<v1_Offer_bool_exp>;
  OfferId?: InputMaybe<Int_comparison_exp>;
  OnlineCheckSubmittedBy?: InputMaybe<v1_SystemUserBase_bool_exp>;
  OnlineStatus?: InputMaybe<v1_PostsOnlineStatus_bool_exp>;
  OrderedBy?: InputMaybe<uuid_comparison_exp>;
  Ordered_By?: InputMaybe<v1_SystemUserBase_bool_exp>;
  OutreachStatus?: InputMaybe<v1_PostsOutreachStatus_bool_exp>;
  POsToPosts?: InputMaybe<v1_PoToPosts_bool_exp>;
  POsToPosts_aggregate?: InputMaybe<v1_PoToPosts_aggregate_bool_exp>;
  PostLinksStatus?: InputMaybe<v1_PostsLinkStatus_bool_exp>;
  PostNiche?: InputMaybe<v1_PostNiche_bool_exp>;
  PostStatus?: InputMaybe<v1_PostsStatus_bool_exp>;
  PriorityId?: InputMaybe<Int_comparison_exp>;
  PublisherApprovedOn?: InputMaybe<date_comparison_exp>;
  PublisherDeclinedOn?: InputMaybe<date_comparison_exp>;
  PublisherOrderApprovedOn?: InputMaybe<date_comparison_exp>;
  PublisherOrderDeclinedOn?: InputMaybe<date_comparison_exp>;
  PublisherPostStatus?: InputMaybe<v1_PublisherPostStatus_bool_exp>;
  PublisherStatus?: InputMaybe<Int_comparison_exp>;
  PurchaseOrder?: InputMaybe<v1_new_POBase_bool_exp>;
  RequestedBy?: InputMaybe<uuid_comparison_exp>;
  RequestedFrom?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Requested_By?: InputMaybe<v1_SystemUserBase_bool_exp>;
  SEOApproval?: InputMaybe<v1_PostsSEOApproval_bool_exp>;
  SEODeclineReason?: InputMaybe<v1_PostsSEODeclineReason_bool_exp>;
  SalesStatus?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<v1_PostsStatusCode_bool_exp>;
  Supplier?: InputMaybe<v1_ContactBase_bool_exp>;
  SupplierCurrency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  SupplierPurchaseOrder?: InputMaybe<v1_new_POBase_bool_exp>;
  SystemUserBase?: InputMaybe<v1_SystemUserBase_bool_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  UploadedBy?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Uploader?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Website?: InputMaybe<v1_Website_bool_exp>;
  Writer?: InputMaybe<v1_ContactBase_bool_exp>;
  _Project?: InputMaybe<Int_comparison_exp>;
  _and?: InputMaybe<Array<v1_Posts_bool_exp>>;
  _not?: InputMaybe<v1_Posts_bool_exp>;
  _or?: InputMaybe<Array<v1_Posts_bool_exp>>;
  advertiser_po_id?: InputMaybe<Int_comparison_exp>;
  approved_by_sm?: InputMaybe<Boolean_comparison_exp>;
  approved_by_sm_id?: InputMaybe<Int_comparison_exp>;
  approved_by_sm_on?: InputMaybe<date_comparison_exp>;
  approvedby_id?: InputMaybe<Int_comparison_exp>;
  approver_id?: InputMaybe<Int_comparison_exp>;
  brand_id?: InputMaybe<Int_comparison_exp>;
  campaign_crm_id?: InputMaybe<uuid_comparison_exp>;
  campaign_id?: InputMaybe<Int_comparison_exp>;
  comp_total_price_manager?: InputMaybe<numeric_comparison_exp>;
  comp_total_price_writer?: InputMaybe<numeric_comparison_exp>;
  contenttype_id?: InputMaybe<Int_comparison_exp>;
  copyscape_duplication_percent?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  declinedby_id?: InputMaybe<Int_comparison_exp>;
  failed_index_checks?: InputMaybe<Int_comparison_exp>;
  gindex_checked_on?: InputMaybe<date_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  isGoogleIndexed?: InputMaybe<Boolean_comparison_exp>;
  is_disabled?: InputMaybe<Boolean_comparison_exp>;
  is_marketplace_post?: InputMaybe<Boolean_comparison_exp>;
  is_untrackable?: InputMaybe<Boolean_comparison_exp>;
  keywords?: InputMaybe<String_comparison_exp>;
  language_id?: InputMaybe<Int_comparison_exp>;
  lost_date?: InputMaybe<date_comparison_exp>;
  marketplacestatus_id?: InputMaybe<Int_comparison_exp>;
  merged_date?: InputMaybe<timestamp_comparison_exp>;
  merged_from?: InputMaybe<Int_comparison_exp>;
  merged_to?: InputMaybe<Int_comparison_exp>;
  mergedby_id?: InputMaybe<Int_comparison_exp>;
  new_AnchorText1Actual?: InputMaybe<String_comparison_exp>;
  new_AnchorText2Actual?: InputMaybe<String_comparison_exp>;
  new_AnchorText3Actual?: InputMaybe<String_comparison_exp>;
  new_AnchorText4Actual?: InputMaybe<String_comparison_exp>;
  new_AnchorText5Actual?: InputMaybe<String_comparison_exp>;
  new_AnchorText6Actual?: InputMaybe<String_comparison_exp>;
  new_Anchortext1?: InputMaybe<String_comparison_exp>;
  new_Anchortext2?: InputMaybe<String_comparison_exp>;
  new_Anchortext3?: InputMaybe<String_comparison_exp>;
  new_Anchortext4Requested?: InputMaybe<String_comparison_exp>;
  new_Anchortext5Requested?: InputMaybe<String_comparison_exp>;
  new_Anchortext6Requested?: InputMaybe<String_comparison_exp>;
  new_BrandId?: InputMaybe<uuid_comparison_exp>;
  new_CMSPostID?: InputMaybe<String_comparison_exp>;
  new_ChecklinkStatus?: InputMaybe<Boolean_comparison_exp>;
  new_CmsContentStatus?: InputMaybe<Int_comparison_exp>;
  new_CommentToWriter?: InputMaybe<String_comparison_exp>;
  new_ContentApprovedby?: InputMaybe<uuid_comparison_exp>;
  new_ContentCost?: InputMaybe<numeric_comparison_exp>;
  new_ContentOrderStatus?: InputMaybe<Int_comparison_exp>;
  new_Contentduedate?: InputMaybe<timestamp_comparison_exp>;
  new_CopyScapeDuplicate?: InputMaybe<Int_comparison_exp>;
  new_CopyscapePrivateIndexDate?: InputMaybe<timestamp_comparison_exp>;
  new_CurrentFocus?: InputMaybe<Boolean_comparison_exp>;
  new_DateApproved?: InputMaybe<date_comparison_exp>;
  new_DateAssigned?: InputMaybe<timestamp_comparison_exp>;
  new_DeclineDate?: InputMaybe<timestamp_comparison_exp>;
  new_Duplication?: InputMaybe<float8_comparison_exp>;
  new_Keywords?: InputMaybe<String_comparison_exp>;
  new_OnlineSince?: InputMaybe<timestamp_comparison_exp>;
  new_OnlineStatusCheckDate?: InputMaybe<timestamp_comparison_exp>;
  new_OutreachStatus?: InputMaybe<Int_comparison_exp>;
  new_Owner?: InputMaybe<uuid_comparison_exp>;
  new_PostDesciption?: InputMaybe<String_comparison_exp>;
  new_PostID?: InputMaybe<String_comparison_exp>;
  new_PostLinksStatus?: InputMaybe<Int_comparison_exp>;
  new_PostOnlineStatus?: InputMaybe<Int_comparison_exp>;
  new_PostStatus?: InputMaybe<Int_comparison_exp>;
  new_Postlivesince?: InputMaybe<timestamp_comparison_exp>;
  new_RequestedFrom?: InputMaybe<uuid_comparison_exp>;
  new_RequestedMinWords?: InputMaybe<Int_comparison_exp>;
  new_RequestedOn?: InputMaybe<timestamp_comparison_exp>;
  new_SEOApproval?: InputMaybe<Int_comparison_exp>;
  new_SEODeclineReasonOptions?: InputMaybe<Int_comparison_exp>;
  new_SentToPublisherOn?: InputMaybe<timestamp_comparison_exp>;
  new_SentTorepost?: InputMaybe<timestamp_comparison_exp>;
  new_SenttoApproval?: InputMaybe<timestamp_comparison_exp>;
  new_SeoTitle?: InputMaybe<String_comparison_exp>;
  new_Supplierprice?: InputMaybe<numeric_comparison_exp>;
  new_TargetURL1?: InputMaybe<String_comparison_exp>;
  new_TargetURL2?: InputMaybe<String_comparison_exp>;
  new_TargetURL3?: InputMaybe<String_comparison_exp>;
  new_TargetURL4?: InputMaybe<String_comparison_exp>;
  new_TargetURL5?: InputMaybe<String_comparison_exp>;
  new_TargetURL6?: InputMaybe<String_comparison_exp>;
  new_TextHTMLbody?: InputMaybe<String_comparison_exp>;
  new_Title?: InputMaybe<String_comparison_exp>;
  new_ToApproveBy?: InputMaybe<uuid_comparison_exp>;
  new_UploadPrice?: InputMaybe<numeric_comparison_exp>;
  new_UploadtoSite?: InputMaybe<Boolean_comparison_exp>;
  new_WebsiteContact?: InputMaybe<uuid_comparison_exp>;
  new_WriterAssigned?: InputMaybe<uuid_comparison_exp>;
  new_WritingInstructions?: InputMaybe<String_comparison_exp>;
  new_alias?: InputMaybe<String_comparison_exp>;
  new_anchortext1type?: InputMaybe<Int_comparison_exp>;
  new_anchortext2type?: InputMaybe<Int_comparison_exp>;
  new_anchortext3type?: InputMaybe<Int_comparison_exp>;
  new_anchortext4type?: InputMaybe<Int_comparison_exp>;
  new_anchortext5type?: InputMaybe<Int_comparison_exp>;
  new_anchortext6type?: InputMaybe<Int_comparison_exp>;
  new_baseurl?: InputMaybe<String_comparison_exp>;
  new_contentdeclinereason?: InputMaybe<String_comparison_exp>;
  new_dropeddate?: InputMaybe<timestamp_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_newpostId?: InputMaybe<uuid_comparison_exp>;
  new_onlinechecksubmit?: InputMaybe<timestamp_comparison_exp>;
  new_onlinechecksubmittedby?: InputMaybe<uuid_comparison_exp>;
  new_postcontenttype?: InputMaybe<uuid_comparison_exp>;
  new_postlanguage?: InputMaybe<uuid_comparison_exp>;
  new_postlinktype?: InputMaybe<Int_comparison_exp>;
  new_repostedon?: InputMaybe<timestamp_comparison_exp>;
  new_seoapprovedon?: InputMaybe<timestamp_comparison_exp>;
  new_seodeclinedby?: InputMaybe<uuid_comparison_exp>;
  new_seodeclinedon?: InputMaybe<timestamp_comparison_exp>;
  new_uploadedby?: InputMaybe<uuid_comparison_exp>;
  new_uploader?: InputMaybe<uuid_comparison_exp>;
  new_urlalias?: InputMaybe<String_comparison_exp>;
  new_website?: InputMaybe<uuid_comparison_exp>;
  new_wordcount?: InputMaybe<Int_comparison_exp>;
  onlinechecksubmittedby_id?: InputMaybe<Int_comparison_exp>;
  ordered_on?: InputMaybe<date_comparison_exp>;
  orderedby_id?: InputMaybe<Int_comparison_exp>;
  plc_failed_attempts?: InputMaybe<Int_comparison_exp>;
  po_id?: InputMaybe<Int_comparison_exp>;
  published_on?: InputMaybe<date_comparison_exp>;
  requestDetails?: InputMaybe<String_comparison_exp>;
  requestedby_id?: InputMaybe<Int_comparison_exp>;
  requestedfrom_id?: InputMaybe<Int_comparison_exp>;
  sent_to_pool_date?: InputMaybe<date_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
  strategy_id?: InputMaybe<Int_comparison_exp>;
  supplier_currency_id?: InputMaybe<Int_comparison_exp>;
  supplier_id?: InputMaybe<Int_comparison_exp>;
  supplier_po_id?: InputMaybe<Int_comparison_exp>;
  targetURLStatus1?: InputMaybe<String_comparison_exp>;
  targetURLStatus2?: InputMaybe<String_comparison_exp>;
  targetURLStatus3?: InputMaybe<String_comparison_exp>;
  targetURLType1?: InputMaybe<Boolean_comparison_exp>;
  targetURLType2?: InputMaybe<Boolean_comparison_exp>;
  targetURLType3?: InputMaybe<Boolean_comparison_exp>;
  target_publish_date?: InputMaybe<date_comparison_exp>;
  uploaded_on?: InputMaybe<date_comparison_exp>;
  uploadedby_id?: InputMaybe<Int_comparison_exp>;
  uploader_id?: InputMaybe<Int_comparison_exp>;
  website_id?: InputMaybe<Int_comparison_exp>;
  writer_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Posts" */
export enum v1_Posts_constraint {
  /** unique or primary key constraint on columns "id" */
  Posts_id_key = 'Posts_id_key',
  /** unique or primary key constraint on columns "id" */
  Posts_pkey = 'Posts_pkey'
}

/** input type for incrementing numeric columns in table "v1.Posts" */
export type v1_Posts_inc_input = {
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  AdvertiserStatus?: InputMaybe<Scalars['Int']['input']>;
  ArticleTone?: InputMaybe<Scalars['Int']['input']>;
  MPOrderId?: InputMaybe<Scalars['Int']['input']>;
  MarketplaceProjectId?: InputMaybe<Scalars['Int']['input']>;
  NicheId?: InputMaybe<Scalars['Int']['input']>;
  OfferId?: InputMaybe<Scalars['Int']['input']>;
  PriorityId?: InputMaybe<Scalars['Int']['input']>;
  PublisherStatus?: InputMaybe<Scalars['Int']['input']>;
  SalesStatus?: InputMaybe<Scalars['Int']['input']>;
  advertiser_po_id?: InputMaybe<Scalars['Int']['input']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<Scalars['Int']['input']>;
  approvedby_id?: InputMaybe<Scalars['Int']['input']>;
  approver_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  contenttype_id?: InputMaybe<Scalars['Int']['input']>;
  copyscape_duplication_percent?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  declinedby_id?: InputMaybe<Scalars['Int']['input']>;
  failed_index_checks?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  marketplacestatus_id?: InputMaybe<Scalars['Int']['input']>;
  merged_from?: InputMaybe<Scalars['Int']['input']>;
  merged_to?: InputMaybe<Scalars['Int']['input']>;
  mergedby_id?: InputMaybe<Scalars['Int']['input']>;
  new_CmsContentStatus?: InputMaybe<Scalars['Int']['input']>;
  new_ContentCost?: InputMaybe<Scalars['numeric']['input']>;
  new_ContentOrderStatus?: InputMaybe<Scalars['Int']['input']>;
  new_CopyScapeDuplicate?: InputMaybe<Scalars['Int']['input']>;
  new_Duplication?: InputMaybe<Scalars['float8']['input']>;
  new_OutreachStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostLinksStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostOnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostStatus?: InputMaybe<Scalars['Int']['input']>;
  new_RequestedMinWords?: InputMaybe<Scalars['Int']['input']>;
  new_SEOApproval?: InputMaybe<Scalars['Int']['input']>;
  new_SEODeclineReasonOptions?: InputMaybe<Scalars['Int']['input']>;
  new_Supplierprice?: InputMaybe<Scalars['numeric']['input']>;
  new_UploadPrice?: InputMaybe<Scalars['numeric']['input']>;
  new_anchortext1type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext2type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext3type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext4type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext5type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext6type?: InputMaybe<Scalars['Int']['input']>;
  new_postlinktype?: InputMaybe<Scalars['Int']['input']>;
  new_wordcount?: InputMaybe<Scalars['Int']['input']>;
  onlinechecksubmittedby_id?: InputMaybe<Scalars['Int']['input']>;
  orderedby_id?: InputMaybe<Scalars['Int']['input']>;
  plc_failed_attempts?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  requestedby_id?: InputMaybe<Scalars['Int']['input']>;
  requestedfrom_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_currency_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_po_id?: InputMaybe<Scalars['Int']['input']>;
  uploadedby_id?: InputMaybe<Scalars['Int']['input']>;
  uploader_id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Posts" */
export type v1_Posts_insert_input = {
  Advertiser?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  AdvertiserApprovedOn?: InputMaybe<Scalars['date']['input']>;
  AdvertiserDeclinedOn?: InputMaybe<Scalars['date']['input']>;
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  AdvertiserOrderedOn?: InputMaybe<Scalars['date']['input']>;
  AdvertiserPurchaseOrder?: InputMaybe<v1_new_POBase_obj_rel_insert_input>;
  AdvertiserStatus?: InputMaybe<Scalars['Int']['input']>;
  AnchorText1Type?: InputMaybe<v1_PostsAnchorTextType_obj_rel_insert_input>;
  AnchorText2Type?: InputMaybe<v1_PostsAnchorTextType_obj_rel_insert_input>;
  AnchorText3Type?: InputMaybe<v1_PostsAnchorTextType_obj_rel_insert_input>;
  AnchorText4Type?: InputMaybe<v1_PostsAnchorTextType_obj_rel_insert_input>;
  AnchorText5Type?: InputMaybe<v1_PostsAnchorTextType_obj_rel_insert_input>;
  AnchorText6Type?: InputMaybe<v1_PostsAnchorTextType_obj_rel_insert_input>;
  ApprovedBy?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Approver?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  ApproverSM?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  ArticleTone?: InputMaybe<Scalars['Int']['input']>;
  Article_Tone?: InputMaybe<v1_ArticleTones_obj_rel_insert_input>;
  Brand?: InputMaybe<v1_new_brandBase_obj_rel_insert_input>;
  Campaign?: InputMaybe<v1_Campaign_obj_rel_insert_input>;
  CampaignStrategy?: InputMaybe<v1_CampaignStrategy_obj_rel_insert_input>;
  CheckoutDetails?: InputMaybe<v1_CheckoutDetail_arr_rel_insert_input>;
  ContentLinks?: InputMaybe<v1_ContentLink_arr_rel_insert_input>;
  ContentStatus?: InputMaybe<v1_PostsContentStatus_obj_rel_insert_input>;
  ContentType?: InputMaybe<v1_ContentTypes_obj_rel_insert_input>;
  CopyScapeDuplication?: InputMaybe<v1_PostsCopyScape_obj_rel_insert_input>;
  CopyScapeLastCheck?: InputMaybe<Scalars['timestamptz']['input']>;
  CopyScapeResult?: InputMaybe<Scalars['String']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  DeclinedBy?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Language?: InputMaybe<v1_new_languagesBase_obj_rel_insert_input>;
  MPOrderId?: InputMaybe<Scalars['Int']['input']>;
  MarketPlaceOrder?: InputMaybe<v1_MarketPlaceOrder_obj_rel_insert_input>;
  MarketplaceProject?: InputMaybe<v1_MarketplaceProjects_obj_rel_insert_input>;
  MarketplaceProjectId?: InputMaybe<Scalars['Int']['input']>;
  MarketplaceStatus?: InputMaybe<v1_MarketplaceStatus_obj_rel_insert_input>;
  NicheId?: InputMaybe<Scalars['Int']['input']>;
  Offer?: InputMaybe<v1_Offer_obj_rel_insert_input>;
  OfferId?: InputMaybe<Scalars['Int']['input']>;
  OnlineCheckSubmittedBy?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  OnlineStatus?: InputMaybe<v1_PostsOnlineStatus_obj_rel_insert_input>;
  OrderedBy?: InputMaybe<Scalars['uuid']['input']>;
  Ordered_By?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  OutreachStatus?: InputMaybe<v1_PostsOutreachStatus_obj_rel_insert_input>;
  POsToPosts?: InputMaybe<v1_PoToPosts_arr_rel_insert_input>;
  PostLinksStatus?: InputMaybe<v1_PostsLinkStatus_obj_rel_insert_input>;
  PostNiche?: InputMaybe<v1_PostNiche_obj_rel_insert_input>;
  PostStatus?: InputMaybe<v1_PostsStatus_obj_rel_insert_input>;
  PriorityId?: InputMaybe<Scalars['Int']['input']>;
  PublisherApprovedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherDeclinedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherOrderApprovedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherOrderDeclinedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherPostStatus?: InputMaybe<v1_PublisherPostStatus_obj_rel_insert_input>;
  PublisherStatus?: InputMaybe<Scalars['Int']['input']>;
  PurchaseOrder?: InputMaybe<v1_new_POBase_obj_rel_insert_input>;
  RequestedBy?: InputMaybe<Scalars['uuid']['input']>;
  RequestedFrom?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Requested_By?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  SEOApproval?: InputMaybe<v1_PostsSEOApproval_obj_rel_insert_input>;
  SEODeclineReason?: InputMaybe<v1_PostsSEODeclineReason_obj_rel_insert_input>;
  SalesStatus?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<v1_PostsStatusCode_obj_rel_insert_input>;
  Supplier?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  SupplierCurrency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  SupplierPurchaseOrder?: InputMaybe<v1_new_POBase_obj_rel_insert_input>;
  SystemUserBase?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  UploadedBy?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Uploader?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Website?: InputMaybe<v1_Website_obj_rel_insert_input>;
  Writer?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  advertiser_po_id?: InputMaybe<Scalars['Int']['input']>;
  /** Approved by Sales Manager */
  approved_by_sm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<Scalars['Int']['input']>;
  /** Date of approving by Sales Manager */
  approved_by_sm_on?: InputMaybe<Scalars['date']['input']>;
  approvedby_id?: InputMaybe<Scalars['Int']['input']>;
  approver_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  contenttype_id?: InputMaybe<Scalars['Int']['input']>;
  copyscape_duplication_percent?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  declinedby_id?: InputMaybe<Scalars['Int']['input']>;
  failed_index_checks?: InputMaybe<Scalars['Int']['input']>;
  gindex_checked_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isGoogleIndexed?: InputMaybe<Scalars['Boolean']['input']>;
  is_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_marketplace_post?: InputMaybe<Scalars['Boolean']['input']>;
  is_untrackable?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  lost_date?: InputMaybe<Scalars['date']['input']>;
  marketplacestatus_id?: InputMaybe<Scalars['Int']['input']>;
  merged_date?: InputMaybe<Scalars['timestamp']['input']>;
  merged_from?: InputMaybe<Scalars['Int']['input']>;
  merged_to?: InputMaybe<Scalars['Int']['input']>;
  mergedby_id?: InputMaybe<Scalars['Int']['input']>;
  new_AnchorText1Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText2Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText3Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText4Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText5Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText6Actual?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext1?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext2?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext3?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext4Requested?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext5Requested?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext6Requested?: InputMaybe<Scalars['String']['input']>;
  new_BrandId?: InputMaybe<Scalars['uuid']['input']>;
  new_CMSPostID?: InputMaybe<Scalars['String']['input']>;
  new_ChecklinkStatus?: InputMaybe<Scalars['Boolean']['input']>;
  new_CmsContentStatus?: InputMaybe<Scalars['Int']['input']>;
  new_CommentToWriter?: InputMaybe<Scalars['String']['input']>;
  new_ContentApprovedby?: InputMaybe<Scalars['uuid']['input']>;
  new_ContentCost?: InputMaybe<Scalars['numeric']['input']>;
  new_ContentOrderStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Contentduedate?: InputMaybe<Scalars['timestamp']['input']>;
  new_CopyScapeDuplicate?: InputMaybe<Scalars['Int']['input']>;
  new_CopyscapePrivateIndexDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_CurrentFocus?: InputMaybe<Scalars['Boolean']['input']>;
  new_DateApproved?: InputMaybe<Scalars['date']['input']>;
  new_DateAssigned?: InputMaybe<Scalars['timestamp']['input']>;
  new_DeclineDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_Duplication?: InputMaybe<Scalars['float8']['input']>;
  new_Keywords?: InputMaybe<Scalars['String']['input']>;
  new_OnlineSince?: InputMaybe<Scalars['timestamp']['input']>;
  new_OnlineStatusCheckDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_OutreachStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Owner?: InputMaybe<Scalars['uuid']['input']>;
  new_PostDesciption?: InputMaybe<Scalars['String']['input']>;
  new_PostID?: InputMaybe<Scalars['String']['input']>;
  new_PostLinksStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostOnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostStatus?: InputMaybe<Scalars['Int']['input']>;
  /** deprecated */
  new_Postlivesince?: InputMaybe<Scalars['timestamp']['input']>;
  new_RequestedFrom?: InputMaybe<Scalars['uuid']['input']>;
  new_RequestedMinWords?: InputMaybe<Scalars['Int']['input']>;
  new_RequestedOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_SEOApproval?: InputMaybe<Scalars['Int']['input']>;
  new_SEODeclineReasonOptions?: InputMaybe<Scalars['Int']['input']>;
  new_SentToPublisherOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_SentTorepost?: InputMaybe<Scalars['timestamp']['input']>;
  new_SenttoApproval?: InputMaybe<Scalars['timestamp']['input']>;
  new_SeoTitle?: InputMaybe<Scalars['String']['input']>;
  new_Supplierprice?: InputMaybe<Scalars['numeric']['input']>;
  new_TargetURL1?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL2?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL3?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL4?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL5?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL6?: InputMaybe<Scalars['String']['input']>;
  new_TextHTMLbody?: InputMaybe<Scalars['String']['input']>;
  new_Title?: InputMaybe<Scalars['String']['input']>;
  new_ToApproveBy?: InputMaybe<Scalars['uuid']['input']>;
  new_UploadPrice?: InputMaybe<Scalars['numeric']['input']>;
  new_UploadtoSite?: InputMaybe<Scalars['Boolean']['input']>;
  new_WebsiteContact?: InputMaybe<Scalars['uuid']['input']>;
  new_WriterAssigned?: InputMaybe<Scalars['uuid']['input']>;
  new_WritingInstructions?: InputMaybe<Scalars['String']['input']>;
  new_alias?: InputMaybe<Scalars['String']['input']>;
  new_anchortext1type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext2type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext3type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext4type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext5type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext6type?: InputMaybe<Scalars['Int']['input']>;
  new_baseurl?: InputMaybe<Scalars['String']['input']>;
  new_contentdeclinereason?: InputMaybe<Scalars['String']['input']>;
  new_dropeddate?: InputMaybe<Scalars['timestamp']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_newpostId?: InputMaybe<Scalars['uuid']['input']>;
  new_onlinechecksubmit?: InputMaybe<Scalars['timestamp']['input']>;
  new_onlinechecksubmittedby?: InputMaybe<Scalars['uuid']['input']>;
  new_postcontenttype?: InputMaybe<Scalars['uuid']['input']>;
  new_postlanguage?: InputMaybe<Scalars['uuid']['input']>;
  new_postlinktype?: InputMaybe<Scalars['Int']['input']>;
  new_repostedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_seoapprovedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_seodeclinedby?: InputMaybe<Scalars['uuid']['input']>;
  new_seodeclinedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_uploadedby?: InputMaybe<Scalars['uuid']['input']>;
  new_uploader?: InputMaybe<Scalars['uuid']['input']>;
  new_urlalias?: InputMaybe<Scalars['String']['input']>;
  new_website?: InputMaybe<Scalars['uuid']['input']>;
  new_wordcount?: InputMaybe<Scalars['Int']['input']>;
  onlinechecksubmittedby_id?: InputMaybe<Scalars['Int']['input']>;
  ordered_on?: InputMaybe<Scalars['date']['input']>;
  orderedby_id?: InputMaybe<Scalars['Int']['input']>;
  plc_failed_attempts?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  /** Date of publish post on MP */
  published_on?: InputMaybe<Scalars['date']['input']>;
  requestDetails?: InputMaybe<Scalars['String']['input']>;
  requestedby_id?: InputMaybe<Scalars['Int']['input']>;
  requestedfrom_id?: InputMaybe<Scalars['Int']['input']>;
  sent_to_pool_date?: InputMaybe<Scalars['date']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_currency_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_po_id?: InputMaybe<Scalars['Int']['input']>;
  targetURLStatus1?: InputMaybe<Scalars['String']['input']>;
  targetURLStatus2?: InputMaybe<Scalars['String']['input']>;
  targetURLStatus3?: InputMaybe<Scalars['String']['input']>;
  targetURLType1?: InputMaybe<Scalars['Boolean']['input']>;
  targetURLType2?: InputMaybe<Scalars['Boolean']['input']>;
  targetURLType3?: InputMaybe<Scalars['Boolean']['input']>;
  target_publish_date?: InputMaybe<Scalars['date']['input']>;
  uploaded_on?: InputMaybe<Scalars['date']['input']>;
  uploadedby_id?: InputMaybe<Scalars['Int']['input']>;
  uploader_id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Posts_max_fields = {
  __typename?: 'v1_Posts_max_fields';
  AdvertiserApprovedOn?: Maybe<Scalars['date']['output']>;
  AdvertiserDeclinedOn?: Maybe<Scalars['date']['output']>;
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  AdvertiserOrderedOn?: Maybe<Scalars['date']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Int']['output']>;
  ArticleTone?: Maybe<Scalars['Int']['output']>;
  CopyScapeLastCheck?: Maybe<Scalars['timestamptz']['output']>;
  CopyScapeResult?: Maybe<Scalars['String']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  MPOrderId?: Maybe<Scalars['Int']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Int']['output']>;
  NicheId?: Maybe<Scalars['Int']['output']>;
  OfferId?: Maybe<Scalars['Int']['output']>;
  OrderedBy?: Maybe<Scalars['uuid']['output']>;
  PriorityId?: Maybe<Scalars['Int']['output']>;
  PublisherApprovedOn?: Maybe<Scalars['date']['output']>;
  PublisherDeclinedOn?: Maybe<Scalars['date']['output']>;
  PublisherOrderApprovedOn?: Maybe<Scalars['date']['output']>;
  PublisherOrderDeclinedOn?: Maybe<Scalars['date']['output']>;
  PublisherStatus?: Maybe<Scalars['Int']['output']>;
  RequestedBy?: Maybe<Scalars['uuid']['output']>;
  SalesStatus?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  advertiser_po_id?: Maybe<Scalars['Int']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Int']['output']>;
  /** Date of approving by Sales Manager */
  approved_by_sm_on?: Maybe<Scalars['date']['output']>;
  approvedby_id?: Maybe<Scalars['Int']['output']>;
  approver_id?: Maybe<Scalars['Int']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  campaign_crm_id?: Maybe<Scalars['uuid']['output']>;
  campaign_id?: Maybe<Scalars['Int']['output']>;
  contenttype_id?: Maybe<Scalars['Int']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  declinedby_id?: Maybe<Scalars['Int']['output']>;
  failed_index_checks?: Maybe<Scalars['Int']['output']>;
  gindex_checked_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  lost_date?: Maybe<Scalars['date']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Int']['output']>;
  merged_date?: Maybe<Scalars['timestamp']['output']>;
  merged_from?: Maybe<Scalars['Int']['output']>;
  merged_to?: Maybe<Scalars['Int']['output']>;
  mergedby_id?: Maybe<Scalars['Int']['output']>;
  new_AnchorText1Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText2Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText3Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText4Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText5Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText6Actual?: Maybe<Scalars['String']['output']>;
  new_Anchortext1?: Maybe<Scalars['String']['output']>;
  new_Anchortext2?: Maybe<Scalars['String']['output']>;
  new_Anchortext3?: Maybe<Scalars['String']['output']>;
  new_Anchortext4Requested?: Maybe<Scalars['String']['output']>;
  new_Anchortext5Requested?: Maybe<Scalars['String']['output']>;
  new_Anchortext6Requested?: Maybe<Scalars['String']['output']>;
  new_BrandId?: Maybe<Scalars['uuid']['output']>;
  new_CMSPostID?: Maybe<Scalars['String']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Int']['output']>;
  new_CommentToWriter?: Maybe<Scalars['String']['output']>;
  new_ContentApprovedby?: Maybe<Scalars['uuid']['output']>;
  new_ContentCost?: Maybe<Scalars['numeric']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Int']['output']>;
  new_Contentduedate?: Maybe<Scalars['timestamp']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Int']['output']>;
  new_CopyscapePrivateIndexDate?: Maybe<Scalars['timestamp']['output']>;
  new_DateApproved?: Maybe<Scalars['date']['output']>;
  new_DateAssigned?: Maybe<Scalars['timestamp']['output']>;
  new_DeclineDate?: Maybe<Scalars['timestamp']['output']>;
  new_Duplication?: Maybe<Scalars['float8']['output']>;
  new_Keywords?: Maybe<Scalars['String']['output']>;
  new_OnlineSince?: Maybe<Scalars['timestamp']['output']>;
  new_OnlineStatusCheckDate?: Maybe<Scalars['timestamp']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Int']['output']>;
  new_Owner?: Maybe<Scalars['uuid']['output']>;
  new_PostDesciption?: Maybe<Scalars['String']['output']>;
  new_PostID?: Maybe<Scalars['String']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Int']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_PostStatus?: Maybe<Scalars['Int']['output']>;
  /** deprecated */
  new_Postlivesince?: Maybe<Scalars['timestamp']['output']>;
  new_RequestedFrom?: Maybe<Scalars['uuid']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Int']['output']>;
  new_RequestedOn?: Maybe<Scalars['timestamp']['output']>;
  new_SEOApproval?: Maybe<Scalars['Int']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Int']['output']>;
  new_SentToPublisherOn?: Maybe<Scalars['timestamp']['output']>;
  new_SentTorepost?: Maybe<Scalars['timestamp']['output']>;
  new_SenttoApproval?: Maybe<Scalars['timestamp']['output']>;
  new_SeoTitle?: Maybe<Scalars['String']['output']>;
  new_Supplierprice?: Maybe<Scalars['numeric']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
  new_TargetURL4?: Maybe<Scalars['String']['output']>;
  new_TargetURL5?: Maybe<Scalars['String']['output']>;
  new_TargetURL6?: Maybe<Scalars['String']['output']>;
  new_TextHTMLbody?: Maybe<Scalars['String']['output']>;
  new_Title?: Maybe<Scalars['String']['output']>;
  new_ToApproveBy?: Maybe<Scalars['uuid']['output']>;
  new_UploadPrice?: Maybe<Scalars['numeric']['output']>;
  new_WebsiteContact?: Maybe<Scalars['uuid']['output']>;
  new_WriterAssigned?: Maybe<Scalars['uuid']['output']>;
  new_WritingInstructions?: Maybe<Scalars['String']['output']>;
  new_alias?: Maybe<Scalars['String']['output']>;
  new_anchortext1type?: Maybe<Scalars['Int']['output']>;
  new_anchortext2type?: Maybe<Scalars['Int']['output']>;
  new_anchortext3type?: Maybe<Scalars['Int']['output']>;
  new_anchortext4type?: Maybe<Scalars['Int']['output']>;
  new_anchortext5type?: Maybe<Scalars['Int']['output']>;
  new_anchortext6type?: Maybe<Scalars['Int']['output']>;
  new_baseurl?: Maybe<Scalars['String']['output']>;
  new_contentdeclinereason?: Maybe<Scalars['String']['output']>;
  new_dropeddate?: Maybe<Scalars['timestamp']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_newpostId?: Maybe<Scalars['uuid']['output']>;
  new_onlinechecksubmit?: Maybe<Scalars['timestamp']['output']>;
  new_onlinechecksubmittedby?: Maybe<Scalars['uuid']['output']>;
  new_postcontenttype?: Maybe<Scalars['uuid']['output']>;
  new_postlanguage?: Maybe<Scalars['uuid']['output']>;
  new_postlinktype?: Maybe<Scalars['Int']['output']>;
  new_repostedon?: Maybe<Scalars['timestamp']['output']>;
  new_seoapprovedon?: Maybe<Scalars['timestamp']['output']>;
  new_seodeclinedby?: Maybe<Scalars['uuid']['output']>;
  new_seodeclinedon?: Maybe<Scalars['timestamp']['output']>;
  new_uploadedby?: Maybe<Scalars['uuid']['output']>;
  new_uploader?: Maybe<Scalars['uuid']['output']>;
  new_urlalias?: Maybe<Scalars['String']['output']>;
  new_website?: Maybe<Scalars['uuid']['output']>;
  new_wordcount?: Maybe<Scalars['Int']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Int']['output']>;
  ordered_on?: Maybe<Scalars['date']['output']>;
  orderedby_id?: Maybe<Scalars['Int']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  /** Date of publish post on MP */
  published_on?: Maybe<Scalars['date']['output']>;
  requestDetails?: Maybe<Scalars['String']['output']>;
  requestedby_id?: Maybe<Scalars['Int']['output']>;
  requestedfrom_id?: Maybe<Scalars['Int']['output']>;
  sent_to_pool_date?: Maybe<Scalars['date']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_id?: Maybe<Scalars['Int']['output']>;
  supplier_currency_id?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  supplier_po_id?: Maybe<Scalars['Int']['output']>;
  targetURLStatus1?: Maybe<Scalars['String']['output']>;
  targetURLStatus2?: Maybe<Scalars['String']['output']>;
  targetURLStatus3?: Maybe<Scalars['String']['output']>;
  target_publish_date?: Maybe<Scalars['date']['output']>;
  uploaded_on?: Maybe<Scalars['date']['output']>;
  uploadedby_id?: Maybe<Scalars['Int']['output']>;
  uploader_id?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Posts" */
export type v1_Posts_max_order_by = {
  AdvertiserApprovedOn?: InputMaybe<order_by>;
  AdvertiserDeclinedOn?: InputMaybe<order_by>;
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserOrderedOn?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  CopyScapeLastCheck?: InputMaybe<order_by>;
  CopyScapeResult?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  OrderedBy?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherApprovedOn?: InputMaybe<order_by>;
  PublisherDeclinedOn?: InputMaybe<order_by>;
  PublisherOrderApprovedOn?: InputMaybe<order_by>;
  PublisherOrderDeclinedOn?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  RequestedBy?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  /** Date of approving by Sales Manager */
  approved_by_sm_on?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_crm_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  gindex_checked_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  lost_date?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_date?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_AnchorText1Actual?: InputMaybe<order_by>;
  new_AnchorText2Actual?: InputMaybe<order_by>;
  new_AnchorText3Actual?: InputMaybe<order_by>;
  new_AnchorText4Actual?: InputMaybe<order_by>;
  new_AnchorText5Actual?: InputMaybe<order_by>;
  new_AnchorText6Actual?: InputMaybe<order_by>;
  new_Anchortext1?: InputMaybe<order_by>;
  new_Anchortext2?: InputMaybe<order_by>;
  new_Anchortext3?: InputMaybe<order_by>;
  new_Anchortext4Requested?: InputMaybe<order_by>;
  new_Anchortext5Requested?: InputMaybe<order_by>;
  new_Anchortext6Requested?: InputMaybe<order_by>;
  new_BrandId?: InputMaybe<order_by>;
  new_CMSPostID?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_CommentToWriter?: InputMaybe<order_by>;
  new_ContentApprovedby?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_Contentduedate?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_CopyscapePrivateIndexDate?: InputMaybe<order_by>;
  new_DateApproved?: InputMaybe<order_by>;
  new_DateAssigned?: InputMaybe<order_by>;
  new_DeclineDate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_Keywords?: InputMaybe<order_by>;
  new_OnlineSince?: InputMaybe<order_by>;
  new_OnlineStatusCheckDate?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_Owner?: InputMaybe<order_by>;
  new_PostDesciption?: InputMaybe<order_by>;
  new_PostID?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  /** deprecated */
  new_Postlivesince?: InputMaybe<order_by>;
  new_RequestedFrom?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_RequestedOn?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_SentToPublisherOn?: InputMaybe<order_by>;
  new_SentTorepost?: InputMaybe<order_by>;
  new_SenttoApproval?: InputMaybe<order_by>;
  new_SeoTitle?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_TargetURL1?: InputMaybe<order_by>;
  new_TargetURL2?: InputMaybe<order_by>;
  new_TargetURL3?: InputMaybe<order_by>;
  new_TargetURL4?: InputMaybe<order_by>;
  new_TargetURL5?: InputMaybe<order_by>;
  new_TargetURL6?: InputMaybe<order_by>;
  new_TextHTMLbody?: InputMaybe<order_by>;
  new_Title?: InputMaybe<order_by>;
  new_ToApproveBy?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_WebsiteContact?: InputMaybe<order_by>;
  new_WriterAssigned?: InputMaybe<order_by>;
  new_WritingInstructions?: InputMaybe<order_by>;
  new_alias?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_baseurl?: InputMaybe<order_by>;
  new_contentdeclinereason?: InputMaybe<order_by>;
  new_dropeddate?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_newpostId?: InputMaybe<order_by>;
  new_onlinechecksubmit?: InputMaybe<order_by>;
  new_onlinechecksubmittedby?: InputMaybe<order_by>;
  new_postcontenttype?: InputMaybe<order_by>;
  new_postlanguage?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_repostedon?: InputMaybe<order_by>;
  new_seoapprovedon?: InputMaybe<order_by>;
  new_seodeclinedby?: InputMaybe<order_by>;
  new_seodeclinedon?: InputMaybe<order_by>;
  new_uploadedby?: InputMaybe<order_by>;
  new_uploader?: InputMaybe<order_by>;
  new_urlalias?: InputMaybe<order_by>;
  new_website?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  ordered_on?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  /** Date of publish post on MP */
  published_on?: InputMaybe<order_by>;
  requestDetails?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  sent_to_pool_date?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  targetURLStatus1?: InputMaybe<order_by>;
  targetURLStatus2?: InputMaybe<order_by>;
  targetURLStatus3?: InputMaybe<order_by>;
  target_publish_date?: InputMaybe<order_by>;
  uploaded_on?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Posts_min_fields = {
  __typename?: 'v1_Posts_min_fields';
  AdvertiserApprovedOn?: Maybe<Scalars['date']['output']>;
  AdvertiserDeclinedOn?: Maybe<Scalars['date']['output']>;
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  AdvertiserOrderedOn?: Maybe<Scalars['date']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Int']['output']>;
  ArticleTone?: Maybe<Scalars['Int']['output']>;
  CopyScapeLastCheck?: Maybe<Scalars['timestamptz']['output']>;
  CopyScapeResult?: Maybe<Scalars['String']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  MPOrderId?: Maybe<Scalars['Int']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Int']['output']>;
  NicheId?: Maybe<Scalars['Int']['output']>;
  OfferId?: Maybe<Scalars['Int']['output']>;
  OrderedBy?: Maybe<Scalars['uuid']['output']>;
  PriorityId?: Maybe<Scalars['Int']['output']>;
  PublisherApprovedOn?: Maybe<Scalars['date']['output']>;
  PublisherDeclinedOn?: Maybe<Scalars['date']['output']>;
  PublisherOrderApprovedOn?: Maybe<Scalars['date']['output']>;
  PublisherOrderDeclinedOn?: Maybe<Scalars['date']['output']>;
  PublisherStatus?: Maybe<Scalars['Int']['output']>;
  RequestedBy?: Maybe<Scalars['uuid']['output']>;
  SalesStatus?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  advertiser_po_id?: Maybe<Scalars['Int']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Int']['output']>;
  /** Date of approving by Sales Manager */
  approved_by_sm_on?: Maybe<Scalars['date']['output']>;
  approvedby_id?: Maybe<Scalars['Int']['output']>;
  approver_id?: Maybe<Scalars['Int']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  campaign_crm_id?: Maybe<Scalars['uuid']['output']>;
  campaign_id?: Maybe<Scalars['Int']['output']>;
  contenttype_id?: Maybe<Scalars['Int']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  declinedby_id?: Maybe<Scalars['Int']['output']>;
  failed_index_checks?: Maybe<Scalars['Int']['output']>;
  gindex_checked_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  lost_date?: Maybe<Scalars['date']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Int']['output']>;
  merged_date?: Maybe<Scalars['timestamp']['output']>;
  merged_from?: Maybe<Scalars['Int']['output']>;
  merged_to?: Maybe<Scalars['Int']['output']>;
  mergedby_id?: Maybe<Scalars['Int']['output']>;
  new_AnchorText1Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText2Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText3Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText4Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText5Actual?: Maybe<Scalars['String']['output']>;
  new_AnchorText6Actual?: Maybe<Scalars['String']['output']>;
  new_Anchortext1?: Maybe<Scalars['String']['output']>;
  new_Anchortext2?: Maybe<Scalars['String']['output']>;
  new_Anchortext3?: Maybe<Scalars['String']['output']>;
  new_Anchortext4Requested?: Maybe<Scalars['String']['output']>;
  new_Anchortext5Requested?: Maybe<Scalars['String']['output']>;
  new_Anchortext6Requested?: Maybe<Scalars['String']['output']>;
  new_BrandId?: Maybe<Scalars['uuid']['output']>;
  new_CMSPostID?: Maybe<Scalars['String']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Int']['output']>;
  new_CommentToWriter?: Maybe<Scalars['String']['output']>;
  new_ContentApprovedby?: Maybe<Scalars['uuid']['output']>;
  new_ContentCost?: Maybe<Scalars['numeric']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Int']['output']>;
  new_Contentduedate?: Maybe<Scalars['timestamp']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Int']['output']>;
  new_CopyscapePrivateIndexDate?: Maybe<Scalars['timestamp']['output']>;
  new_DateApproved?: Maybe<Scalars['date']['output']>;
  new_DateAssigned?: Maybe<Scalars['timestamp']['output']>;
  new_DeclineDate?: Maybe<Scalars['timestamp']['output']>;
  new_Duplication?: Maybe<Scalars['float8']['output']>;
  new_Keywords?: Maybe<Scalars['String']['output']>;
  new_OnlineSince?: Maybe<Scalars['timestamp']['output']>;
  new_OnlineStatusCheckDate?: Maybe<Scalars['timestamp']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Int']['output']>;
  new_Owner?: Maybe<Scalars['uuid']['output']>;
  new_PostDesciption?: Maybe<Scalars['String']['output']>;
  new_PostID?: Maybe<Scalars['String']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Int']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_PostStatus?: Maybe<Scalars['Int']['output']>;
  /** deprecated */
  new_Postlivesince?: Maybe<Scalars['timestamp']['output']>;
  new_RequestedFrom?: Maybe<Scalars['uuid']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Int']['output']>;
  new_RequestedOn?: Maybe<Scalars['timestamp']['output']>;
  new_SEOApproval?: Maybe<Scalars['Int']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Int']['output']>;
  new_SentToPublisherOn?: Maybe<Scalars['timestamp']['output']>;
  new_SentTorepost?: Maybe<Scalars['timestamp']['output']>;
  new_SenttoApproval?: Maybe<Scalars['timestamp']['output']>;
  new_SeoTitle?: Maybe<Scalars['String']['output']>;
  new_Supplierprice?: Maybe<Scalars['numeric']['output']>;
  new_TargetURL1?: Maybe<Scalars['String']['output']>;
  new_TargetURL2?: Maybe<Scalars['String']['output']>;
  new_TargetURL3?: Maybe<Scalars['String']['output']>;
  new_TargetURL4?: Maybe<Scalars['String']['output']>;
  new_TargetURL5?: Maybe<Scalars['String']['output']>;
  new_TargetURL6?: Maybe<Scalars['String']['output']>;
  new_TextHTMLbody?: Maybe<Scalars['String']['output']>;
  new_Title?: Maybe<Scalars['String']['output']>;
  new_ToApproveBy?: Maybe<Scalars['uuid']['output']>;
  new_UploadPrice?: Maybe<Scalars['numeric']['output']>;
  new_WebsiteContact?: Maybe<Scalars['uuid']['output']>;
  new_WriterAssigned?: Maybe<Scalars['uuid']['output']>;
  new_WritingInstructions?: Maybe<Scalars['String']['output']>;
  new_alias?: Maybe<Scalars['String']['output']>;
  new_anchortext1type?: Maybe<Scalars['Int']['output']>;
  new_anchortext2type?: Maybe<Scalars['Int']['output']>;
  new_anchortext3type?: Maybe<Scalars['Int']['output']>;
  new_anchortext4type?: Maybe<Scalars['Int']['output']>;
  new_anchortext5type?: Maybe<Scalars['Int']['output']>;
  new_anchortext6type?: Maybe<Scalars['Int']['output']>;
  new_baseurl?: Maybe<Scalars['String']['output']>;
  new_contentdeclinereason?: Maybe<Scalars['String']['output']>;
  new_dropeddate?: Maybe<Scalars['timestamp']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_newpostId?: Maybe<Scalars['uuid']['output']>;
  new_onlinechecksubmit?: Maybe<Scalars['timestamp']['output']>;
  new_onlinechecksubmittedby?: Maybe<Scalars['uuid']['output']>;
  new_postcontenttype?: Maybe<Scalars['uuid']['output']>;
  new_postlanguage?: Maybe<Scalars['uuid']['output']>;
  new_postlinktype?: Maybe<Scalars['Int']['output']>;
  new_repostedon?: Maybe<Scalars['timestamp']['output']>;
  new_seoapprovedon?: Maybe<Scalars['timestamp']['output']>;
  new_seodeclinedby?: Maybe<Scalars['uuid']['output']>;
  new_seodeclinedon?: Maybe<Scalars['timestamp']['output']>;
  new_uploadedby?: Maybe<Scalars['uuid']['output']>;
  new_uploader?: Maybe<Scalars['uuid']['output']>;
  new_urlalias?: Maybe<Scalars['String']['output']>;
  new_website?: Maybe<Scalars['uuid']['output']>;
  new_wordcount?: Maybe<Scalars['Int']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Int']['output']>;
  ordered_on?: Maybe<Scalars['date']['output']>;
  orderedby_id?: Maybe<Scalars['Int']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  /** Date of publish post on MP */
  published_on?: Maybe<Scalars['date']['output']>;
  requestDetails?: Maybe<Scalars['String']['output']>;
  requestedby_id?: Maybe<Scalars['Int']['output']>;
  requestedfrom_id?: Maybe<Scalars['Int']['output']>;
  sent_to_pool_date?: Maybe<Scalars['date']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_id?: Maybe<Scalars['Int']['output']>;
  supplier_currency_id?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  supplier_po_id?: Maybe<Scalars['Int']['output']>;
  targetURLStatus1?: Maybe<Scalars['String']['output']>;
  targetURLStatus2?: Maybe<Scalars['String']['output']>;
  targetURLStatus3?: Maybe<Scalars['String']['output']>;
  target_publish_date?: Maybe<Scalars['date']['output']>;
  uploaded_on?: Maybe<Scalars['date']['output']>;
  uploadedby_id?: Maybe<Scalars['Int']['output']>;
  uploader_id?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Posts" */
export type v1_Posts_min_order_by = {
  AdvertiserApprovedOn?: InputMaybe<order_by>;
  AdvertiserDeclinedOn?: InputMaybe<order_by>;
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserOrderedOn?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  CopyScapeLastCheck?: InputMaybe<order_by>;
  CopyScapeResult?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  OrderedBy?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherApprovedOn?: InputMaybe<order_by>;
  PublisherDeclinedOn?: InputMaybe<order_by>;
  PublisherOrderApprovedOn?: InputMaybe<order_by>;
  PublisherOrderDeclinedOn?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  RequestedBy?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  /** Date of approving by Sales Manager */
  approved_by_sm_on?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_crm_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  gindex_checked_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  lost_date?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_date?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_AnchorText1Actual?: InputMaybe<order_by>;
  new_AnchorText2Actual?: InputMaybe<order_by>;
  new_AnchorText3Actual?: InputMaybe<order_by>;
  new_AnchorText4Actual?: InputMaybe<order_by>;
  new_AnchorText5Actual?: InputMaybe<order_by>;
  new_AnchorText6Actual?: InputMaybe<order_by>;
  new_Anchortext1?: InputMaybe<order_by>;
  new_Anchortext2?: InputMaybe<order_by>;
  new_Anchortext3?: InputMaybe<order_by>;
  new_Anchortext4Requested?: InputMaybe<order_by>;
  new_Anchortext5Requested?: InputMaybe<order_by>;
  new_Anchortext6Requested?: InputMaybe<order_by>;
  new_BrandId?: InputMaybe<order_by>;
  new_CMSPostID?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_CommentToWriter?: InputMaybe<order_by>;
  new_ContentApprovedby?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_Contentduedate?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_CopyscapePrivateIndexDate?: InputMaybe<order_by>;
  new_DateApproved?: InputMaybe<order_by>;
  new_DateAssigned?: InputMaybe<order_by>;
  new_DeclineDate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_Keywords?: InputMaybe<order_by>;
  new_OnlineSince?: InputMaybe<order_by>;
  new_OnlineStatusCheckDate?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_Owner?: InputMaybe<order_by>;
  new_PostDesciption?: InputMaybe<order_by>;
  new_PostID?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  /** deprecated */
  new_Postlivesince?: InputMaybe<order_by>;
  new_RequestedFrom?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_RequestedOn?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_SentToPublisherOn?: InputMaybe<order_by>;
  new_SentTorepost?: InputMaybe<order_by>;
  new_SenttoApproval?: InputMaybe<order_by>;
  new_SeoTitle?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_TargetURL1?: InputMaybe<order_by>;
  new_TargetURL2?: InputMaybe<order_by>;
  new_TargetURL3?: InputMaybe<order_by>;
  new_TargetURL4?: InputMaybe<order_by>;
  new_TargetURL5?: InputMaybe<order_by>;
  new_TargetURL6?: InputMaybe<order_by>;
  new_TextHTMLbody?: InputMaybe<order_by>;
  new_Title?: InputMaybe<order_by>;
  new_ToApproveBy?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_WebsiteContact?: InputMaybe<order_by>;
  new_WriterAssigned?: InputMaybe<order_by>;
  new_WritingInstructions?: InputMaybe<order_by>;
  new_alias?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_baseurl?: InputMaybe<order_by>;
  new_contentdeclinereason?: InputMaybe<order_by>;
  new_dropeddate?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_newpostId?: InputMaybe<order_by>;
  new_onlinechecksubmit?: InputMaybe<order_by>;
  new_onlinechecksubmittedby?: InputMaybe<order_by>;
  new_postcontenttype?: InputMaybe<order_by>;
  new_postlanguage?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_repostedon?: InputMaybe<order_by>;
  new_seoapprovedon?: InputMaybe<order_by>;
  new_seodeclinedby?: InputMaybe<order_by>;
  new_seodeclinedon?: InputMaybe<order_by>;
  new_uploadedby?: InputMaybe<order_by>;
  new_uploader?: InputMaybe<order_by>;
  new_urlalias?: InputMaybe<order_by>;
  new_website?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  ordered_on?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  /** Date of publish post on MP */
  published_on?: InputMaybe<order_by>;
  requestDetails?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  sent_to_pool_date?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  targetURLStatus1?: InputMaybe<order_by>;
  targetURLStatus2?: InputMaybe<order_by>;
  targetURLStatus3?: InputMaybe<order_by>;
  target_publish_date?: InputMaybe<order_by>;
  uploaded_on?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Posts" */
export type v1_Posts_mutation_response = {
  __typename?: 'v1_Posts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Posts>;
};

/** input type for inserting object relation for remote table "v1.Posts" */
export type v1_Posts_obj_rel_insert_input = {
  data: v1_Posts_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Posts_on_conflict>;
};

/** on_conflict condition type for table "v1.Posts" */
export type v1_Posts_on_conflict = {
  constraint: v1_Posts_constraint;
  update_columns?: Array<v1_Posts_update_column>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** Ordering options when selecting data from "v1.Posts". */
export type v1_Posts_order_by = {
  Advertiser?: InputMaybe<v1_ContactBase_order_by>;
  AdvertiserApprovedOn?: InputMaybe<order_by>;
  AdvertiserDeclinedOn?: InputMaybe<order_by>;
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserOrderedOn?: InputMaybe<order_by>;
  AdvertiserPurchaseOrder?: InputMaybe<v1_new_POBase_order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  AnchorText1Type?: InputMaybe<v1_PostsAnchorTextType_order_by>;
  AnchorText2Type?: InputMaybe<v1_PostsAnchorTextType_order_by>;
  AnchorText3Type?: InputMaybe<v1_PostsAnchorTextType_order_by>;
  AnchorText4Type?: InputMaybe<v1_PostsAnchorTextType_order_by>;
  AnchorText5Type?: InputMaybe<v1_PostsAnchorTextType_order_by>;
  AnchorText6Type?: InputMaybe<v1_PostsAnchorTextType_order_by>;
  ApprovedBy?: InputMaybe<v1_SystemUserBase_order_by>;
  Approver?: InputMaybe<v1_SystemUserBase_order_by>;
  ApproverSM?: InputMaybe<v1_SystemUserBase_order_by>;
  ArticleTone?: InputMaybe<order_by>;
  Article_Tone?: InputMaybe<v1_ArticleTones_order_by>;
  Brand?: InputMaybe<v1_new_brandBase_order_by>;
  Campaign?: InputMaybe<v1_Campaign_order_by>;
  CampaignStrategy?: InputMaybe<v1_CampaignStrategy_order_by>;
  CheckoutDetails_aggregate?: InputMaybe<v1_CheckoutDetail_aggregate_order_by>;
  ContentLinks_aggregate?: InputMaybe<v1_ContentLink_aggregate_order_by>;
  ContentStatus?: InputMaybe<v1_PostsContentStatus_order_by>;
  ContentType?: InputMaybe<v1_ContentTypes_order_by>;
  CopyScapeDuplication?: InputMaybe<v1_PostsCopyScape_order_by>;
  CopyScapeLastCheck?: InputMaybe<order_by>;
  CopyScapeResult?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  DeclinedBy?: InputMaybe<v1_SystemUserBase_order_by>;
  Language?: InputMaybe<v1_new_languagesBase_order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketPlaceOrder?: InputMaybe<v1_MarketPlaceOrder_order_by>;
  MarketplaceProject?: InputMaybe<v1_MarketplaceProjects_order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  MarketplaceStatus?: InputMaybe<v1_MarketplaceStatus_order_by>;
  NicheId?: InputMaybe<order_by>;
  Offer?: InputMaybe<v1_Offer_order_by>;
  OfferId?: InputMaybe<order_by>;
  OnlineCheckSubmittedBy?: InputMaybe<v1_SystemUserBase_order_by>;
  OnlineStatus?: InputMaybe<v1_PostsOnlineStatus_order_by>;
  OrderedBy?: InputMaybe<order_by>;
  Ordered_By?: InputMaybe<v1_SystemUserBase_order_by>;
  OutreachStatus?: InputMaybe<v1_PostsOutreachStatus_order_by>;
  POsToPosts_aggregate?: InputMaybe<v1_PoToPosts_aggregate_order_by>;
  PostLinksStatus?: InputMaybe<v1_PostsLinkStatus_order_by>;
  PostNiche?: InputMaybe<v1_PostNiche_order_by>;
  PostStatus?: InputMaybe<v1_PostsStatus_order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherApprovedOn?: InputMaybe<order_by>;
  PublisherDeclinedOn?: InputMaybe<order_by>;
  PublisherOrderApprovedOn?: InputMaybe<order_by>;
  PublisherOrderDeclinedOn?: InputMaybe<order_by>;
  PublisherPostStatus?: InputMaybe<v1_PublisherPostStatus_order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  PurchaseOrder?: InputMaybe<v1_new_POBase_order_by>;
  RequestedBy?: InputMaybe<order_by>;
  RequestedFrom?: InputMaybe<v1_SystemUserBase_order_by>;
  Requested_By?: InputMaybe<v1_SystemUserBase_order_by>;
  SEOApproval?: InputMaybe<v1_PostsSEOApproval_order_by>;
  SEODeclineReason?: InputMaybe<v1_PostsSEODeclineReason_order_by>;
  SalesStatus?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<v1_PostsStatusCode_order_by>;
  Supplier?: InputMaybe<v1_ContactBase_order_by>;
  SupplierCurrency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  SupplierPurchaseOrder?: InputMaybe<v1_new_POBase_order_by>;
  SystemUserBase?: InputMaybe<v1_SystemUserBase_order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  UploadedBy?: InputMaybe<v1_SystemUserBase_order_by>;
  Uploader?: InputMaybe<v1_SystemUserBase_order_by>;
  Website?: InputMaybe<v1_Website_order_by>;
  Writer?: InputMaybe<v1_ContactBase_order_by>;
  _Project?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  approved_by_sm?: InputMaybe<order_by>;
  approved_by_sm_id?: InputMaybe<order_by>;
  approved_by_sm_on?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_crm_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  comp_total_price_manager?: InputMaybe<order_by>;
  comp_total_price_writer?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  gindex_checked_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  isGoogleIndexed?: InputMaybe<order_by>;
  is_disabled?: InputMaybe<order_by>;
  is_marketplace_post?: InputMaybe<order_by>;
  is_untrackable?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  lost_date?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_date?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_AnchorText1Actual?: InputMaybe<order_by>;
  new_AnchorText2Actual?: InputMaybe<order_by>;
  new_AnchorText3Actual?: InputMaybe<order_by>;
  new_AnchorText4Actual?: InputMaybe<order_by>;
  new_AnchorText5Actual?: InputMaybe<order_by>;
  new_AnchorText6Actual?: InputMaybe<order_by>;
  new_Anchortext1?: InputMaybe<order_by>;
  new_Anchortext2?: InputMaybe<order_by>;
  new_Anchortext3?: InputMaybe<order_by>;
  new_Anchortext4Requested?: InputMaybe<order_by>;
  new_Anchortext5Requested?: InputMaybe<order_by>;
  new_Anchortext6Requested?: InputMaybe<order_by>;
  new_BrandId?: InputMaybe<order_by>;
  new_CMSPostID?: InputMaybe<order_by>;
  new_ChecklinkStatus?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_CommentToWriter?: InputMaybe<order_by>;
  new_ContentApprovedby?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_Contentduedate?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_CopyscapePrivateIndexDate?: InputMaybe<order_by>;
  new_CurrentFocus?: InputMaybe<order_by>;
  new_DateApproved?: InputMaybe<order_by>;
  new_DateAssigned?: InputMaybe<order_by>;
  new_DeclineDate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_Keywords?: InputMaybe<order_by>;
  new_OnlineSince?: InputMaybe<order_by>;
  new_OnlineStatusCheckDate?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_Owner?: InputMaybe<order_by>;
  new_PostDesciption?: InputMaybe<order_by>;
  new_PostID?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_Postlivesince?: InputMaybe<order_by>;
  new_RequestedFrom?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_RequestedOn?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_SentToPublisherOn?: InputMaybe<order_by>;
  new_SentTorepost?: InputMaybe<order_by>;
  new_SenttoApproval?: InputMaybe<order_by>;
  new_SeoTitle?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_TargetURL1?: InputMaybe<order_by>;
  new_TargetURL2?: InputMaybe<order_by>;
  new_TargetURL3?: InputMaybe<order_by>;
  new_TargetURL4?: InputMaybe<order_by>;
  new_TargetURL5?: InputMaybe<order_by>;
  new_TargetURL6?: InputMaybe<order_by>;
  new_TextHTMLbody?: InputMaybe<order_by>;
  new_Title?: InputMaybe<order_by>;
  new_ToApproveBy?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_UploadtoSite?: InputMaybe<order_by>;
  new_WebsiteContact?: InputMaybe<order_by>;
  new_WriterAssigned?: InputMaybe<order_by>;
  new_WritingInstructions?: InputMaybe<order_by>;
  new_alias?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_baseurl?: InputMaybe<order_by>;
  new_contentdeclinereason?: InputMaybe<order_by>;
  new_dropeddate?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_newpostId?: InputMaybe<order_by>;
  new_onlinechecksubmit?: InputMaybe<order_by>;
  new_onlinechecksubmittedby?: InputMaybe<order_by>;
  new_postcontenttype?: InputMaybe<order_by>;
  new_postlanguage?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_repostedon?: InputMaybe<order_by>;
  new_seoapprovedon?: InputMaybe<order_by>;
  new_seodeclinedby?: InputMaybe<order_by>;
  new_seodeclinedon?: InputMaybe<order_by>;
  new_uploadedby?: InputMaybe<order_by>;
  new_uploader?: InputMaybe<order_by>;
  new_urlalias?: InputMaybe<order_by>;
  new_website?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  ordered_on?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  published_on?: InputMaybe<order_by>;
  requestDetails?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  sent_to_pool_date?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  targetURLStatus1?: InputMaybe<order_by>;
  targetURLStatus2?: InputMaybe<order_by>;
  targetURLStatus3?: InputMaybe<order_by>;
  targetURLType1?: InputMaybe<order_by>;
  targetURLType2?: InputMaybe<order_by>;
  targetURLType3?: InputMaybe<order_by>;
  target_publish_date?: InputMaybe<order_by>;
  uploaded_on?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Posts */
export type v1_Posts_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Posts" */
export enum v1_Posts_select_column {
  /** column name */
  AdvertiserApprovedOn = 'AdvertiserApprovedOn',
  /** column name */
  AdvertiserDeclinedOn = 'AdvertiserDeclinedOn',
  /** column name */
  AdvertiserId = 'AdvertiserId',
  /** column name */
  AdvertiserOrderedOn = 'AdvertiserOrderedOn',
  /** column name */
  AdvertiserStatus = 'AdvertiserStatus',
  /** column name */
  ArticleTone = 'ArticleTone',
  /** column name */
  CopyScapeLastCheck = 'CopyScapeLastCheck',
  /** column name */
  CopyScapeResult = 'CopyScapeResult',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  MPOrderId = 'MPOrderId',
  /** column name */
  MarketplaceProjectId = 'MarketplaceProjectId',
  /** column name */
  NicheId = 'NicheId',
  /** column name */
  OfferId = 'OfferId',
  /** column name */
  OrderedBy = 'OrderedBy',
  /** column name */
  PriorityId = 'PriorityId',
  /** column name */
  PublisherApprovedOn = 'PublisherApprovedOn',
  /** column name */
  PublisherDeclinedOn = 'PublisherDeclinedOn',
  /** column name */
  PublisherOrderApprovedOn = 'PublisherOrderApprovedOn',
  /** column name */
  PublisherOrderDeclinedOn = 'PublisherOrderDeclinedOn',
  /** column name */
  PublisherStatus = 'PublisherStatus',
  /** column name */
  RequestedBy = 'RequestedBy',
  /** column name */
  SalesStatus = 'SalesStatus',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  advertiser_po_id = 'advertiser_po_id',
  /** column name */
  approved_by_sm = 'approved_by_sm',
  /** column name */
  approved_by_sm_id = 'approved_by_sm_id',
  /** column name */
  approved_by_sm_on = 'approved_by_sm_on',
  /** column name */
  approvedby_id = 'approvedby_id',
  /** column name */
  approver_id = 'approver_id',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  campaign_crm_id = 'campaign_crm_id',
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  contenttype_id = 'contenttype_id',
  /** column name */
  copyscape_duplication_percent = 'copyscape_duplication_percent',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  declinedby_id = 'declinedby_id',
  /** column name */
  failed_index_checks = 'failed_index_checks',
  /** column name */
  gindex_checked_on = 'gindex_checked_on',
  /** column name */
  id = 'id',
  /** column name */
  isGoogleIndexed = 'isGoogleIndexed',
  /** column name */
  is_disabled = 'is_disabled',
  /** column name */
  is_marketplace_post = 'is_marketplace_post',
  /** column name */
  is_untrackable = 'is_untrackable',
  /** column name */
  keywords = 'keywords',
  /** column name */
  language_id = 'language_id',
  /** column name */
  lost_date = 'lost_date',
  /** column name */
  marketplacestatus_id = 'marketplacestatus_id',
  /** column name */
  merged_date = 'merged_date',
  /** column name */
  merged_from = 'merged_from',
  /** column name */
  merged_to = 'merged_to',
  /** column name */
  mergedby_id = 'mergedby_id',
  /** column name */
  new_AnchorText1Actual = 'new_AnchorText1Actual',
  /** column name */
  new_AnchorText2Actual = 'new_AnchorText2Actual',
  /** column name */
  new_AnchorText3Actual = 'new_AnchorText3Actual',
  /** column name */
  new_AnchorText4Actual = 'new_AnchorText4Actual',
  /** column name */
  new_AnchorText5Actual = 'new_AnchorText5Actual',
  /** column name */
  new_AnchorText6Actual = 'new_AnchorText6Actual',
  /** column name */
  new_Anchortext1 = 'new_Anchortext1',
  /** column name */
  new_Anchortext2 = 'new_Anchortext2',
  /** column name */
  new_Anchortext3 = 'new_Anchortext3',
  /** column name */
  new_Anchortext4Requested = 'new_Anchortext4Requested',
  /** column name */
  new_Anchortext5Requested = 'new_Anchortext5Requested',
  /** column name */
  new_Anchortext6Requested = 'new_Anchortext6Requested',
  /** column name */
  new_BrandId = 'new_BrandId',
  /** column name */
  new_CMSPostID = 'new_CMSPostID',
  /** column name */
  new_ChecklinkStatus = 'new_ChecklinkStatus',
  /** column name */
  new_CmsContentStatus = 'new_CmsContentStatus',
  /** column name */
  new_CommentToWriter = 'new_CommentToWriter',
  /** column name */
  new_ContentApprovedby = 'new_ContentApprovedby',
  /** column name */
  new_ContentCost = 'new_ContentCost',
  /** column name */
  new_ContentOrderStatus = 'new_ContentOrderStatus',
  /** column name */
  new_Contentduedate = 'new_Contentduedate',
  /** column name */
  new_CopyScapeDuplicate = 'new_CopyScapeDuplicate',
  /** column name */
  new_CopyscapePrivateIndexDate = 'new_CopyscapePrivateIndexDate',
  /** column name */
  new_CurrentFocus = 'new_CurrentFocus',
  /** column name */
  new_DateApproved = 'new_DateApproved',
  /** column name */
  new_DateAssigned = 'new_DateAssigned',
  /** column name */
  new_DeclineDate = 'new_DeclineDate',
  /** column name */
  new_Duplication = 'new_Duplication',
  /** column name */
  new_Keywords = 'new_Keywords',
  /** column name */
  new_OnlineSince = 'new_OnlineSince',
  /** column name */
  new_OnlineStatusCheckDate = 'new_OnlineStatusCheckDate',
  /** column name */
  new_OutreachStatus = 'new_OutreachStatus',
  /** column name */
  new_Owner = 'new_Owner',
  /** column name */
  new_PostDesciption = 'new_PostDesciption',
  /** column name */
  new_PostID = 'new_PostID',
  /** column name */
  new_PostLinksStatus = 'new_PostLinksStatus',
  /** column name */
  new_PostOnlineStatus = 'new_PostOnlineStatus',
  /** column name */
  new_PostStatus = 'new_PostStatus',
  /** column name */
  new_Postlivesince = 'new_Postlivesince',
  /** column name */
  new_RequestedFrom = 'new_RequestedFrom',
  /** column name */
  new_RequestedMinWords = 'new_RequestedMinWords',
  /** column name */
  new_RequestedOn = 'new_RequestedOn',
  /** column name */
  new_SEOApproval = 'new_SEOApproval',
  /** column name */
  new_SEODeclineReasonOptions = 'new_SEODeclineReasonOptions',
  /** column name */
  new_SentToPublisherOn = 'new_SentToPublisherOn',
  /** column name */
  new_SentTorepost = 'new_SentTorepost',
  /** column name */
  new_SenttoApproval = 'new_SenttoApproval',
  /** column name */
  new_SeoTitle = 'new_SeoTitle',
  /** column name */
  new_Supplierprice = 'new_Supplierprice',
  /** column name */
  new_TargetURL1 = 'new_TargetURL1',
  /** column name */
  new_TargetURL2 = 'new_TargetURL2',
  /** column name */
  new_TargetURL3 = 'new_TargetURL3',
  /** column name */
  new_TargetURL4 = 'new_TargetURL4',
  /** column name */
  new_TargetURL5 = 'new_TargetURL5',
  /** column name */
  new_TargetURL6 = 'new_TargetURL6',
  /** column name */
  new_TextHTMLbody = 'new_TextHTMLbody',
  /** column name */
  new_Title = 'new_Title',
  /** column name */
  new_ToApproveBy = 'new_ToApproveBy',
  /** column name */
  new_UploadPrice = 'new_UploadPrice',
  /** column name */
  new_UploadtoSite = 'new_UploadtoSite',
  /** column name */
  new_WebsiteContact = 'new_WebsiteContact',
  /** column name */
  new_WriterAssigned = 'new_WriterAssigned',
  /** column name */
  new_WritingInstructions = 'new_WritingInstructions',
  /** column name */
  new_alias = 'new_alias',
  /** column name */
  new_anchortext1type = 'new_anchortext1type',
  /** column name */
  new_anchortext2type = 'new_anchortext2type',
  /** column name */
  new_anchortext3type = 'new_anchortext3type',
  /** column name */
  new_anchortext4type = 'new_anchortext4type',
  /** column name */
  new_anchortext5type = 'new_anchortext5type',
  /** column name */
  new_anchortext6type = 'new_anchortext6type',
  /** column name */
  new_baseurl = 'new_baseurl',
  /** column name */
  new_contentdeclinereason = 'new_contentdeclinereason',
  /** column name */
  new_dropeddate = 'new_dropeddate',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_newpostId = 'new_newpostId',
  /** column name */
  new_onlinechecksubmit = 'new_onlinechecksubmit',
  /** column name */
  new_onlinechecksubmittedby = 'new_onlinechecksubmittedby',
  /** column name */
  new_postcontenttype = 'new_postcontenttype',
  /** column name */
  new_postlanguage = 'new_postlanguage',
  /** column name */
  new_postlinktype = 'new_postlinktype',
  /** column name */
  new_repostedon = 'new_repostedon',
  /** column name */
  new_seoapprovedon = 'new_seoapprovedon',
  /** column name */
  new_seodeclinedby = 'new_seodeclinedby',
  /** column name */
  new_seodeclinedon = 'new_seodeclinedon',
  /** column name */
  new_uploadedby = 'new_uploadedby',
  /** column name */
  new_uploader = 'new_uploader',
  /** column name */
  new_urlalias = 'new_urlalias',
  /** column name */
  new_website = 'new_website',
  /** column name */
  new_wordcount = 'new_wordcount',
  /** column name */
  onlinechecksubmittedby_id = 'onlinechecksubmittedby_id',
  /** column name */
  ordered_on = 'ordered_on',
  /** column name */
  orderedby_id = 'orderedby_id',
  /** column name */
  plc_failed_attempts = 'plc_failed_attempts',
  /** column name */
  po_id = 'po_id',
  /** column name */
  published_on = 'published_on',
  /** column name */
  requestDetails = 'requestDetails',
  /** column name */
  requestedby_id = 'requestedby_id',
  /** column name */
  requestedfrom_id = 'requestedfrom_id',
  /** column name */
  sent_to_pool_date = 'sent_to_pool_date',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  strategy_id = 'strategy_id',
  /** column name */
  supplier_currency_id = 'supplier_currency_id',
  /** column name */
  supplier_id = 'supplier_id',
  /** column name */
  supplier_po_id = 'supplier_po_id',
  /** column name */
  targetURLStatus1 = 'targetURLStatus1',
  /** column name */
  targetURLStatus2 = 'targetURLStatus2',
  /** column name */
  targetURLStatus3 = 'targetURLStatus3',
  /** column name */
  targetURLType1 = 'targetURLType1',
  /** column name */
  targetURLType2 = 'targetURLType2',
  /** column name */
  targetURLType3 = 'targetURLType3',
  /** column name */
  target_publish_date = 'target_publish_date',
  /** column name */
  uploaded_on = 'uploaded_on',
  /** column name */
  uploadedby_id = 'uploadedby_id',
  /** column name */
  uploader_id = 'uploader_id',
  /** column name */
  website_id = 'website_id',
  /** column name */
  writer_id = 'writer_id'
}

/** select "v1_Posts_aggregate_bool_exp_avg_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_avg_arguments_columns {
  /** column name */
  new_Duplication = 'new_Duplication'
}

/** select "v1_Posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  approved_by_sm = 'approved_by_sm',
  /** column name */
  isGoogleIndexed = 'isGoogleIndexed',
  /** column name */
  is_disabled = 'is_disabled',
  /** column name */
  is_marketplace_post = 'is_marketplace_post',
  /** column name */
  is_untrackable = 'is_untrackable',
  /** column name */
  new_ChecklinkStatus = 'new_ChecklinkStatus',
  /** column name */
  new_CurrentFocus = 'new_CurrentFocus',
  /** column name */
  new_UploadtoSite = 'new_UploadtoSite',
  /** column name */
  targetURLType1 = 'targetURLType1',
  /** column name */
  targetURLType2 = 'targetURLType2',
  /** column name */
  targetURLType3 = 'targetURLType3'
}

/** select "v1_Posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  approved_by_sm = 'approved_by_sm',
  /** column name */
  isGoogleIndexed = 'isGoogleIndexed',
  /** column name */
  is_disabled = 'is_disabled',
  /** column name */
  is_marketplace_post = 'is_marketplace_post',
  /** column name */
  is_untrackable = 'is_untrackable',
  /** column name */
  new_ChecklinkStatus = 'new_ChecklinkStatus',
  /** column name */
  new_CurrentFocus = 'new_CurrentFocus',
  /** column name */
  new_UploadtoSite = 'new_UploadtoSite',
  /** column name */
  targetURLType1 = 'targetURLType1',
  /** column name */
  targetURLType2 = 'targetURLType2',
  /** column name */
  targetURLType3 = 'targetURLType3'
}

/** select "v1_Posts_aggregate_bool_exp_corr_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_corr_arguments_columns {
  /** column name */
  new_Duplication = 'new_Duplication'
}

/** select "v1_Posts_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_covar_samp_arguments_columns {
  /** column name */
  new_Duplication = 'new_Duplication'
}

/** select "v1_Posts_aggregate_bool_exp_max_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_max_arguments_columns {
  /** column name */
  new_Duplication = 'new_Duplication'
}

/** select "v1_Posts_aggregate_bool_exp_min_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_min_arguments_columns {
  /** column name */
  new_Duplication = 'new_Duplication'
}

/** select "v1_Posts_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_stddev_samp_arguments_columns {
  /** column name */
  new_Duplication = 'new_Duplication'
}

/** select "v1_Posts_aggregate_bool_exp_sum_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_sum_arguments_columns {
  /** column name */
  new_Duplication = 'new_Duplication'
}

/** select "v1_Posts_aggregate_bool_exp_var_samp_arguments_columns" columns of table "v1.Posts" */
export enum v1_Posts_select_column_v1_Posts_aggregate_bool_exp_var_samp_arguments_columns {
  /** column name */
  new_Duplication = 'new_Duplication'
}

/** input type for updating data in table "v1.Posts" */
export type v1_Posts_set_input = {
  AdvertiserApprovedOn?: InputMaybe<Scalars['date']['input']>;
  AdvertiserDeclinedOn?: InputMaybe<Scalars['date']['input']>;
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  AdvertiserOrderedOn?: InputMaybe<Scalars['date']['input']>;
  AdvertiserStatus?: InputMaybe<Scalars['Int']['input']>;
  ArticleTone?: InputMaybe<Scalars['Int']['input']>;
  CopyScapeLastCheck?: InputMaybe<Scalars['timestamptz']['input']>;
  CopyScapeResult?: InputMaybe<Scalars['String']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  MPOrderId?: InputMaybe<Scalars['Int']['input']>;
  MarketplaceProjectId?: InputMaybe<Scalars['Int']['input']>;
  NicheId?: InputMaybe<Scalars['Int']['input']>;
  OfferId?: InputMaybe<Scalars['Int']['input']>;
  OrderedBy?: InputMaybe<Scalars['uuid']['input']>;
  PriorityId?: InputMaybe<Scalars['Int']['input']>;
  PublisherApprovedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherDeclinedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherOrderApprovedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherOrderDeclinedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherStatus?: InputMaybe<Scalars['Int']['input']>;
  RequestedBy?: InputMaybe<Scalars['uuid']['input']>;
  SalesStatus?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  advertiser_po_id?: InputMaybe<Scalars['Int']['input']>;
  /** Approved by Sales Manager */
  approved_by_sm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<Scalars['Int']['input']>;
  /** Date of approving by Sales Manager */
  approved_by_sm_on?: InputMaybe<Scalars['date']['input']>;
  approvedby_id?: InputMaybe<Scalars['Int']['input']>;
  approver_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  contenttype_id?: InputMaybe<Scalars['Int']['input']>;
  copyscape_duplication_percent?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  declinedby_id?: InputMaybe<Scalars['Int']['input']>;
  failed_index_checks?: InputMaybe<Scalars['Int']['input']>;
  gindex_checked_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isGoogleIndexed?: InputMaybe<Scalars['Boolean']['input']>;
  is_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_marketplace_post?: InputMaybe<Scalars['Boolean']['input']>;
  is_untrackable?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  lost_date?: InputMaybe<Scalars['date']['input']>;
  marketplacestatus_id?: InputMaybe<Scalars['Int']['input']>;
  merged_date?: InputMaybe<Scalars['timestamp']['input']>;
  merged_from?: InputMaybe<Scalars['Int']['input']>;
  merged_to?: InputMaybe<Scalars['Int']['input']>;
  mergedby_id?: InputMaybe<Scalars['Int']['input']>;
  new_AnchorText1Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText2Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText3Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText4Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText5Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText6Actual?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext1?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext2?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext3?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext4Requested?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext5Requested?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext6Requested?: InputMaybe<Scalars['String']['input']>;
  new_BrandId?: InputMaybe<Scalars['uuid']['input']>;
  new_CMSPostID?: InputMaybe<Scalars['String']['input']>;
  new_ChecklinkStatus?: InputMaybe<Scalars['Boolean']['input']>;
  new_CmsContentStatus?: InputMaybe<Scalars['Int']['input']>;
  new_CommentToWriter?: InputMaybe<Scalars['String']['input']>;
  new_ContentApprovedby?: InputMaybe<Scalars['uuid']['input']>;
  new_ContentCost?: InputMaybe<Scalars['numeric']['input']>;
  new_ContentOrderStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Contentduedate?: InputMaybe<Scalars['timestamp']['input']>;
  new_CopyScapeDuplicate?: InputMaybe<Scalars['Int']['input']>;
  new_CopyscapePrivateIndexDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_CurrentFocus?: InputMaybe<Scalars['Boolean']['input']>;
  new_DateApproved?: InputMaybe<Scalars['date']['input']>;
  new_DateAssigned?: InputMaybe<Scalars['timestamp']['input']>;
  new_DeclineDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_Duplication?: InputMaybe<Scalars['float8']['input']>;
  new_Keywords?: InputMaybe<Scalars['String']['input']>;
  new_OnlineSince?: InputMaybe<Scalars['timestamp']['input']>;
  new_OnlineStatusCheckDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_OutreachStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Owner?: InputMaybe<Scalars['uuid']['input']>;
  new_PostDesciption?: InputMaybe<Scalars['String']['input']>;
  new_PostID?: InputMaybe<Scalars['String']['input']>;
  new_PostLinksStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostOnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostStatus?: InputMaybe<Scalars['Int']['input']>;
  /** deprecated */
  new_Postlivesince?: InputMaybe<Scalars['timestamp']['input']>;
  new_RequestedFrom?: InputMaybe<Scalars['uuid']['input']>;
  new_RequestedMinWords?: InputMaybe<Scalars['Int']['input']>;
  new_RequestedOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_SEOApproval?: InputMaybe<Scalars['Int']['input']>;
  new_SEODeclineReasonOptions?: InputMaybe<Scalars['Int']['input']>;
  new_SentToPublisherOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_SentTorepost?: InputMaybe<Scalars['timestamp']['input']>;
  new_SenttoApproval?: InputMaybe<Scalars['timestamp']['input']>;
  new_SeoTitle?: InputMaybe<Scalars['String']['input']>;
  new_Supplierprice?: InputMaybe<Scalars['numeric']['input']>;
  new_TargetURL1?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL2?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL3?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL4?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL5?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL6?: InputMaybe<Scalars['String']['input']>;
  new_TextHTMLbody?: InputMaybe<Scalars['String']['input']>;
  new_Title?: InputMaybe<Scalars['String']['input']>;
  new_ToApproveBy?: InputMaybe<Scalars['uuid']['input']>;
  new_UploadPrice?: InputMaybe<Scalars['numeric']['input']>;
  new_UploadtoSite?: InputMaybe<Scalars['Boolean']['input']>;
  new_WebsiteContact?: InputMaybe<Scalars['uuid']['input']>;
  new_WriterAssigned?: InputMaybe<Scalars['uuid']['input']>;
  new_WritingInstructions?: InputMaybe<Scalars['String']['input']>;
  new_alias?: InputMaybe<Scalars['String']['input']>;
  new_anchortext1type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext2type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext3type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext4type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext5type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext6type?: InputMaybe<Scalars['Int']['input']>;
  new_baseurl?: InputMaybe<Scalars['String']['input']>;
  new_contentdeclinereason?: InputMaybe<Scalars['String']['input']>;
  new_dropeddate?: InputMaybe<Scalars['timestamp']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_newpostId?: InputMaybe<Scalars['uuid']['input']>;
  new_onlinechecksubmit?: InputMaybe<Scalars['timestamp']['input']>;
  new_onlinechecksubmittedby?: InputMaybe<Scalars['uuid']['input']>;
  new_postcontenttype?: InputMaybe<Scalars['uuid']['input']>;
  new_postlanguage?: InputMaybe<Scalars['uuid']['input']>;
  new_postlinktype?: InputMaybe<Scalars['Int']['input']>;
  new_repostedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_seoapprovedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_seodeclinedby?: InputMaybe<Scalars['uuid']['input']>;
  new_seodeclinedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_uploadedby?: InputMaybe<Scalars['uuid']['input']>;
  new_uploader?: InputMaybe<Scalars['uuid']['input']>;
  new_urlalias?: InputMaybe<Scalars['String']['input']>;
  new_website?: InputMaybe<Scalars['uuid']['input']>;
  new_wordcount?: InputMaybe<Scalars['Int']['input']>;
  onlinechecksubmittedby_id?: InputMaybe<Scalars['Int']['input']>;
  ordered_on?: InputMaybe<Scalars['date']['input']>;
  orderedby_id?: InputMaybe<Scalars['Int']['input']>;
  plc_failed_attempts?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  /** Date of publish post on MP */
  published_on?: InputMaybe<Scalars['date']['input']>;
  requestDetails?: InputMaybe<Scalars['String']['input']>;
  requestedby_id?: InputMaybe<Scalars['Int']['input']>;
  requestedfrom_id?: InputMaybe<Scalars['Int']['input']>;
  sent_to_pool_date?: InputMaybe<Scalars['date']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_currency_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_po_id?: InputMaybe<Scalars['Int']['input']>;
  targetURLStatus1?: InputMaybe<Scalars['String']['input']>;
  targetURLStatus2?: InputMaybe<Scalars['String']['input']>;
  targetURLStatus3?: InputMaybe<Scalars['String']['input']>;
  targetURLType1?: InputMaybe<Scalars['Boolean']['input']>;
  targetURLType2?: InputMaybe<Scalars['Boolean']['input']>;
  targetURLType3?: InputMaybe<Scalars['Boolean']['input']>;
  target_publish_date?: InputMaybe<Scalars['date']['input']>;
  uploaded_on?: InputMaybe<Scalars['date']['input']>;
  uploadedby_id?: InputMaybe<Scalars['Int']['input']>;
  uploader_id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Posts_stddev_fields = {
  __typename?: 'v1_Posts_stddev_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Float']['output']>;
  ArticleTone?: Maybe<Scalars['Float']['output']>;
  MPOrderId?: Maybe<Scalars['Float']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferId?: Maybe<Scalars['Float']['output']>;
  PriorityId?: Maybe<Scalars['Float']['output']>;
  PublisherStatus?: Maybe<Scalars['Float']['output']>;
  SalesStatus?: Maybe<Scalars['Float']['output']>;
  advertiser_po_id?: Maybe<Scalars['Float']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  approver_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  campaign_id?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  declinedby_id?: Maybe<Scalars['Float']['output']>;
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Float']['output']>;
  merged_from?: Maybe<Scalars['Float']['output']>;
  merged_to?: Maybe<Scalars['Float']['output']>;
  mergedby_id?: Maybe<Scalars['Float']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Float']['output']>;
  new_ContentCost?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Float']['output']>;
  new_Duplication?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Float']['output']>;
  new_SEOApproval?: Maybe<Scalars['Float']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Float']['output']>;
  new_Supplierprice?: Maybe<Scalars['Float']['output']>;
  new_UploadPrice?: Maybe<Scalars['Float']['output']>;
  new_anchortext1type?: Maybe<Scalars['Float']['output']>;
  new_anchortext2type?: Maybe<Scalars['Float']['output']>;
  new_anchortext3type?: Maybe<Scalars['Float']['output']>;
  new_anchortext4type?: Maybe<Scalars['Float']['output']>;
  new_anchortext5type?: Maybe<Scalars['Float']['output']>;
  new_anchortext6type?: Maybe<Scalars['Float']['output']>;
  new_postlinktype?: Maybe<Scalars['Float']['output']>;
  new_wordcount?: Maybe<Scalars['Float']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Float']['output']>;
  orderedby_id?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  requestedby_id?: Maybe<Scalars['Float']['output']>;
  requestedfrom_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  supplier_currency_id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  supplier_po_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Posts" */
export type v1_Posts_stddev_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Posts_stddev_pop_fields = {
  __typename?: 'v1_Posts_stddev_pop_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Float']['output']>;
  ArticleTone?: Maybe<Scalars['Float']['output']>;
  MPOrderId?: Maybe<Scalars['Float']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferId?: Maybe<Scalars['Float']['output']>;
  PriorityId?: Maybe<Scalars['Float']['output']>;
  PublisherStatus?: Maybe<Scalars['Float']['output']>;
  SalesStatus?: Maybe<Scalars['Float']['output']>;
  advertiser_po_id?: Maybe<Scalars['Float']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  approver_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  campaign_id?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  declinedby_id?: Maybe<Scalars['Float']['output']>;
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Float']['output']>;
  merged_from?: Maybe<Scalars['Float']['output']>;
  merged_to?: Maybe<Scalars['Float']['output']>;
  mergedby_id?: Maybe<Scalars['Float']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Float']['output']>;
  new_ContentCost?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Float']['output']>;
  new_Duplication?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Float']['output']>;
  new_SEOApproval?: Maybe<Scalars['Float']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Float']['output']>;
  new_Supplierprice?: Maybe<Scalars['Float']['output']>;
  new_UploadPrice?: Maybe<Scalars['Float']['output']>;
  new_anchortext1type?: Maybe<Scalars['Float']['output']>;
  new_anchortext2type?: Maybe<Scalars['Float']['output']>;
  new_anchortext3type?: Maybe<Scalars['Float']['output']>;
  new_anchortext4type?: Maybe<Scalars['Float']['output']>;
  new_anchortext5type?: Maybe<Scalars['Float']['output']>;
  new_anchortext6type?: Maybe<Scalars['Float']['output']>;
  new_postlinktype?: Maybe<Scalars['Float']['output']>;
  new_wordcount?: Maybe<Scalars['Float']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Float']['output']>;
  orderedby_id?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  requestedby_id?: Maybe<Scalars['Float']['output']>;
  requestedfrom_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  supplier_currency_id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  supplier_po_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Posts" */
export type v1_Posts_stddev_pop_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Posts_stddev_samp_fields = {
  __typename?: 'v1_Posts_stddev_samp_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Float']['output']>;
  ArticleTone?: Maybe<Scalars['Float']['output']>;
  MPOrderId?: Maybe<Scalars['Float']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferId?: Maybe<Scalars['Float']['output']>;
  PriorityId?: Maybe<Scalars['Float']['output']>;
  PublisherStatus?: Maybe<Scalars['Float']['output']>;
  SalesStatus?: Maybe<Scalars['Float']['output']>;
  advertiser_po_id?: Maybe<Scalars['Float']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  approver_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  campaign_id?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  declinedby_id?: Maybe<Scalars['Float']['output']>;
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Float']['output']>;
  merged_from?: Maybe<Scalars['Float']['output']>;
  merged_to?: Maybe<Scalars['Float']['output']>;
  mergedby_id?: Maybe<Scalars['Float']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Float']['output']>;
  new_ContentCost?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Float']['output']>;
  new_Duplication?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Float']['output']>;
  new_SEOApproval?: Maybe<Scalars['Float']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Float']['output']>;
  new_Supplierprice?: Maybe<Scalars['Float']['output']>;
  new_UploadPrice?: Maybe<Scalars['Float']['output']>;
  new_anchortext1type?: Maybe<Scalars['Float']['output']>;
  new_anchortext2type?: Maybe<Scalars['Float']['output']>;
  new_anchortext3type?: Maybe<Scalars['Float']['output']>;
  new_anchortext4type?: Maybe<Scalars['Float']['output']>;
  new_anchortext5type?: Maybe<Scalars['Float']['output']>;
  new_anchortext6type?: Maybe<Scalars['Float']['output']>;
  new_postlinktype?: Maybe<Scalars['Float']['output']>;
  new_wordcount?: Maybe<Scalars['Float']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Float']['output']>;
  orderedby_id?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  requestedby_id?: Maybe<Scalars['Float']['output']>;
  requestedfrom_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  supplier_currency_id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  supplier_po_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Posts" */
export type v1_Posts_stddev_samp_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Posts" */
export type v1_Posts_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Posts_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Posts_stream_cursor_value_input = {
  AdvertiserApprovedOn?: InputMaybe<Scalars['date']['input']>;
  AdvertiserDeclinedOn?: InputMaybe<Scalars['date']['input']>;
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  AdvertiserOrderedOn?: InputMaybe<Scalars['date']['input']>;
  AdvertiserStatus?: InputMaybe<Scalars['Int']['input']>;
  ArticleTone?: InputMaybe<Scalars['Int']['input']>;
  CopyScapeLastCheck?: InputMaybe<Scalars['timestamptz']['input']>;
  CopyScapeResult?: InputMaybe<Scalars['String']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  MPOrderId?: InputMaybe<Scalars['Int']['input']>;
  MarketplaceProjectId?: InputMaybe<Scalars['Int']['input']>;
  NicheId?: InputMaybe<Scalars['Int']['input']>;
  OfferId?: InputMaybe<Scalars['Int']['input']>;
  OrderedBy?: InputMaybe<Scalars['uuid']['input']>;
  PriorityId?: InputMaybe<Scalars['Int']['input']>;
  PublisherApprovedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherDeclinedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherOrderApprovedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherOrderDeclinedOn?: InputMaybe<Scalars['date']['input']>;
  PublisherStatus?: InputMaybe<Scalars['Int']['input']>;
  RequestedBy?: InputMaybe<Scalars['uuid']['input']>;
  SalesStatus?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  advertiser_po_id?: InputMaybe<Scalars['Int']['input']>;
  /** Approved by Sales Manager */
  approved_by_sm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<Scalars['Int']['input']>;
  /** Date of approving by Sales Manager */
  approved_by_sm_on?: InputMaybe<Scalars['date']['input']>;
  approvedby_id?: InputMaybe<Scalars['Int']['input']>;
  approver_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  contenttype_id?: InputMaybe<Scalars['Int']['input']>;
  copyscape_duplication_percent?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  declinedby_id?: InputMaybe<Scalars['Int']['input']>;
  failed_index_checks?: InputMaybe<Scalars['Int']['input']>;
  gindex_checked_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isGoogleIndexed?: InputMaybe<Scalars['Boolean']['input']>;
  is_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_marketplace_post?: InputMaybe<Scalars['Boolean']['input']>;
  is_untrackable?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  lost_date?: InputMaybe<Scalars['date']['input']>;
  marketplacestatus_id?: InputMaybe<Scalars['Int']['input']>;
  merged_date?: InputMaybe<Scalars['timestamp']['input']>;
  merged_from?: InputMaybe<Scalars['Int']['input']>;
  merged_to?: InputMaybe<Scalars['Int']['input']>;
  mergedby_id?: InputMaybe<Scalars['Int']['input']>;
  new_AnchorText1Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText2Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText3Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText4Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText5Actual?: InputMaybe<Scalars['String']['input']>;
  new_AnchorText6Actual?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext1?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext2?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext3?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext4Requested?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext5Requested?: InputMaybe<Scalars['String']['input']>;
  new_Anchortext6Requested?: InputMaybe<Scalars['String']['input']>;
  new_BrandId?: InputMaybe<Scalars['uuid']['input']>;
  new_CMSPostID?: InputMaybe<Scalars['String']['input']>;
  new_ChecklinkStatus?: InputMaybe<Scalars['Boolean']['input']>;
  new_CmsContentStatus?: InputMaybe<Scalars['Int']['input']>;
  new_CommentToWriter?: InputMaybe<Scalars['String']['input']>;
  new_ContentApprovedby?: InputMaybe<Scalars['uuid']['input']>;
  new_ContentCost?: InputMaybe<Scalars['numeric']['input']>;
  new_ContentOrderStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Contentduedate?: InputMaybe<Scalars['timestamp']['input']>;
  new_CopyScapeDuplicate?: InputMaybe<Scalars['Int']['input']>;
  new_CopyscapePrivateIndexDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_CurrentFocus?: InputMaybe<Scalars['Boolean']['input']>;
  new_DateApproved?: InputMaybe<Scalars['date']['input']>;
  new_DateAssigned?: InputMaybe<Scalars['timestamp']['input']>;
  new_DeclineDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_Duplication?: InputMaybe<Scalars['float8']['input']>;
  new_Keywords?: InputMaybe<Scalars['String']['input']>;
  new_OnlineSince?: InputMaybe<Scalars['timestamp']['input']>;
  new_OnlineStatusCheckDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_OutreachStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Owner?: InputMaybe<Scalars['uuid']['input']>;
  new_PostDesciption?: InputMaybe<Scalars['String']['input']>;
  new_PostID?: InputMaybe<Scalars['String']['input']>;
  new_PostLinksStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostOnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_PostStatus?: InputMaybe<Scalars['Int']['input']>;
  /** deprecated */
  new_Postlivesince?: InputMaybe<Scalars['timestamp']['input']>;
  new_RequestedFrom?: InputMaybe<Scalars['uuid']['input']>;
  new_RequestedMinWords?: InputMaybe<Scalars['Int']['input']>;
  new_RequestedOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_SEOApproval?: InputMaybe<Scalars['Int']['input']>;
  new_SEODeclineReasonOptions?: InputMaybe<Scalars['Int']['input']>;
  new_SentToPublisherOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_SentTorepost?: InputMaybe<Scalars['timestamp']['input']>;
  new_SenttoApproval?: InputMaybe<Scalars['timestamp']['input']>;
  new_SeoTitle?: InputMaybe<Scalars['String']['input']>;
  new_Supplierprice?: InputMaybe<Scalars['numeric']['input']>;
  new_TargetURL1?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL2?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL3?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL4?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL5?: InputMaybe<Scalars['String']['input']>;
  new_TargetURL6?: InputMaybe<Scalars['String']['input']>;
  new_TextHTMLbody?: InputMaybe<Scalars['String']['input']>;
  new_Title?: InputMaybe<Scalars['String']['input']>;
  new_ToApproveBy?: InputMaybe<Scalars['uuid']['input']>;
  new_UploadPrice?: InputMaybe<Scalars['numeric']['input']>;
  new_UploadtoSite?: InputMaybe<Scalars['Boolean']['input']>;
  new_WebsiteContact?: InputMaybe<Scalars['uuid']['input']>;
  new_WriterAssigned?: InputMaybe<Scalars['uuid']['input']>;
  new_WritingInstructions?: InputMaybe<Scalars['String']['input']>;
  new_alias?: InputMaybe<Scalars['String']['input']>;
  new_anchortext1type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext2type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext3type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext4type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext5type?: InputMaybe<Scalars['Int']['input']>;
  new_anchortext6type?: InputMaybe<Scalars['Int']['input']>;
  new_baseurl?: InputMaybe<Scalars['String']['input']>;
  new_contentdeclinereason?: InputMaybe<Scalars['String']['input']>;
  new_dropeddate?: InputMaybe<Scalars['timestamp']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_newpostId?: InputMaybe<Scalars['uuid']['input']>;
  new_onlinechecksubmit?: InputMaybe<Scalars['timestamp']['input']>;
  new_onlinechecksubmittedby?: InputMaybe<Scalars['uuid']['input']>;
  new_postcontenttype?: InputMaybe<Scalars['uuid']['input']>;
  new_postlanguage?: InputMaybe<Scalars['uuid']['input']>;
  new_postlinktype?: InputMaybe<Scalars['Int']['input']>;
  new_repostedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_seoapprovedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_seodeclinedby?: InputMaybe<Scalars['uuid']['input']>;
  new_seodeclinedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_uploadedby?: InputMaybe<Scalars['uuid']['input']>;
  new_uploader?: InputMaybe<Scalars['uuid']['input']>;
  new_urlalias?: InputMaybe<Scalars['String']['input']>;
  new_website?: InputMaybe<Scalars['uuid']['input']>;
  new_wordcount?: InputMaybe<Scalars['Int']['input']>;
  onlinechecksubmittedby_id?: InputMaybe<Scalars['Int']['input']>;
  ordered_on?: InputMaybe<Scalars['date']['input']>;
  orderedby_id?: InputMaybe<Scalars['Int']['input']>;
  plc_failed_attempts?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  /** Date of publish post on MP */
  published_on?: InputMaybe<Scalars['date']['input']>;
  requestDetails?: InputMaybe<Scalars['String']['input']>;
  requestedby_id?: InputMaybe<Scalars['Int']['input']>;
  requestedfrom_id?: InputMaybe<Scalars['Int']['input']>;
  sent_to_pool_date?: InputMaybe<Scalars['date']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  strategy_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_currency_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  supplier_po_id?: InputMaybe<Scalars['Int']['input']>;
  targetURLStatus1?: InputMaybe<Scalars['String']['input']>;
  targetURLStatus2?: InputMaybe<Scalars['String']['input']>;
  targetURLStatus3?: InputMaybe<Scalars['String']['input']>;
  targetURLType1?: InputMaybe<Scalars['Boolean']['input']>;
  targetURLType2?: InputMaybe<Scalars['Boolean']['input']>;
  targetURLType3?: InputMaybe<Scalars['Boolean']['input']>;
  target_publish_date?: InputMaybe<Scalars['date']['input']>;
  uploaded_on?: InputMaybe<Scalars['date']['input']>;
  uploadedby_id?: InputMaybe<Scalars['Int']['input']>;
  uploader_id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Posts_sum_fields = {
  __typename?: 'v1_Posts_sum_fields';
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Int']['output']>;
  ArticleTone?: Maybe<Scalars['Int']['output']>;
  MPOrderId?: Maybe<Scalars['Int']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Int']['output']>;
  NicheId?: Maybe<Scalars['Int']['output']>;
  OfferId?: Maybe<Scalars['Int']['output']>;
  PriorityId?: Maybe<Scalars['Int']['output']>;
  PublisherStatus?: Maybe<Scalars['Int']['output']>;
  SalesStatus?: Maybe<Scalars['Int']['output']>;
  advertiser_po_id?: Maybe<Scalars['Int']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Int']['output']>;
  approvedby_id?: Maybe<Scalars['Int']['output']>;
  approver_id?: Maybe<Scalars['Int']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  campaign_id?: Maybe<Scalars['Int']['output']>;
  contenttype_id?: Maybe<Scalars['Int']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  declinedby_id?: Maybe<Scalars['Int']['output']>;
  failed_index_checks?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Int']['output']>;
  merged_from?: Maybe<Scalars['Int']['output']>;
  merged_to?: Maybe<Scalars['Int']['output']>;
  mergedby_id?: Maybe<Scalars['Int']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Int']['output']>;
  new_ContentCost?: Maybe<Scalars['numeric']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Int']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Int']['output']>;
  new_Duplication?: Maybe<Scalars['float8']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Int']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Int']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_PostStatus?: Maybe<Scalars['Int']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Int']['output']>;
  new_SEOApproval?: Maybe<Scalars['Int']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Int']['output']>;
  new_Supplierprice?: Maybe<Scalars['numeric']['output']>;
  new_UploadPrice?: Maybe<Scalars['numeric']['output']>;
  new_anchortext1type?: Maybe<Scalars['Int']['output']>;
  new_anchortext2type?: Maybe<Scalars['Int']['output']>;
  new_anchortext3type?: Maybe<Scalars['Int']['output']>;
  new_anchortext4type?: Maybe<Scalars['Int']['output']>;
  new_anchortext5type?: Maybe<Scalars['Int']['output']>;
  new_anchortext6type?: Maybe<Scalars['Int']['output']>;
  new_postlinktype?: Maybe<Scalars['Int']['output']>;
  new_wordcount?: Maybe<Scalars['Int']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Int']['output']>;
  orderedby_id?: Maybe<Scalars['Int']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  requestedby_id?: Maybe<Scalars['Int']['output']>;
  requestedfrom_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  strategy_id?: Maybe<Scalars['Int']['output']>;
  supplier_currency_id?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  supplier_po_id?: Maybe<Scalars['Int']['output']>;
  uploadedby_id?: Maybe<Scalars['Int']['output']>;
  uploader_id?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Posts" */
export type v1_Posts_sum_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Posts" */
export enum v1_Posts_update_column {
  /** column name */
  AdvertiserApprovedOn = 'AdvertiserApprovedOn',
  /** column name */
  AdvertiserDeclinedOn = 'AdvertiserDeclinedOn',
  /** column name */
  AdvertiserId = 'AdvertiserId',
  /** column name */
  AdvertiserOrderedOn = 'AdvertiserOrderedOn',
  /** column name */
  AdvertiserStatus = 'AdvertiserStatus',
  /** column name */
  ArticleTone = 'ArticleTone',
  /** column name */
  CopyScapeLastCheck = 'CopyScapeLastCheck',
  /** column name */
  CopyScapeResult = 'CopyScapeResult',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  MPOrderId = 'MPOrderId',
  /** column name */
  MarketplaceProjectId = 'MarketplaceProjectId',
  /** column name */
  NicheId = 'NicheId',
  /** column name */
  OfferId = 'OfferId',
  /** column name */
  OrderedBy = 'OrderedBy',
  /** column name */
  PriorityId = 'PriorityId',
  /** column name */
  PublisherApprovedOn = 'PublisherApprovedOn',
  /** column name */
  PublisherDeclinedOn = 'PublisherDeclinedOn',
  /** column name */
  PublisherOrderApprovedOn = 'PublisherOrderApprovedOn',
  /** column name */
  PublisherOrderDeclinedOn = 'PublisherOrderDeclinedOn',
  /** column name */
  PublisherStatus = 'PublisherStatus',
  /** column name */
  RequestedBy = 'RequestedBy',
  /** column name */
  SalesStatus = 'SalesStatus',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  advertiser_po_id = 'advertiser_po_id',
  /** column name */
  approved_by_sm = 'approved_by_sm',
  /** column name */
  approved_by_sm_id = 'approved_by_sm_id',
  /** column name */
  approved_by_sm_on = 'approved_by_sm_on',
  /** column name */
  approvedby_id = 'approvedby_id',
  /** column name */
  approver_id = 'approver_id',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  campaign_crm_id = 'campaign_crm_id',
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  contenttype_id = 'contenttype_id',
  /** column name */
  copyscape_duplication_percent = 'copyscape_duplication_percent',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  declinedby_id = 'declinedby_id',
  /** column name */
  failed_index_checks = 'failed_index_checks',
  /** column name */
  gindex_checked_on = 'gindex_checked_on',
  /** column name */
  id = 'id',
  /** column name */
  isGoogleIndexed = 'isGoogleIndexed',
  /** column name */
  is_disabled = 'is_disabled',
  /** column name */
  is_marketplace_post = 'is_marketplace_post',
  /** column name */
  is_untrackable = 'is_untrackable',
  /** column name */
  keywords = 'keywords',
  /** column name */
  language_id = 'language_id',
  /** column name */
  lost_date = 'lost_date',
  /** column name */
  marketplacestatus_id = 'marketplacestatus_id',
  /** column name */
  merged_date = 'merged_date',
  /** column name */
  merged_from = 'merged_from',
  /** column name */
  merged_to = 'merged_to',
  /** column name */
  mergedby_id = 'mergedby_id',
  /** column name */
  new_AnchorText1Actual = 'new_AnchorText1Actual',
  /** column name */
  new_AnchorText2Actual = 'new_AnchorText2Actual',
  /** column name */
  new_AnchorText3Actual = 'new_AnchorText3Actual',
  /** column name */
  new_AnchorText4Actual = 'new_AnchorText4Actual',
  /** column name */
  new_AnchorText5Actual = 'new_AnchorText5Actual',
  /** column name */
  new_AnchorText6Actual = 'new_AnchorText6Actual',
  /** column name */
  new_Anchortext1 = 'new_Anchortext1',
  /** column name */
  new_Anchortext2 = 'new_Anchortext2',
  /** column name */
  new_Anchortext3 = 'new_Anchortext3',
  /** column name */
  new_Anchortext4Requested = 'new_Anchortext4Requested',
  /** column name */
  new_Anchortext5Requested = 'new_Anchortext5Requested',
  /** column name */
  new_Anchortext6Requested = 'new_Anchortext6Requested',
  /** column name */
  new_BrandId = 'new_BrandId',
  /** column name */
  new_CMSPostID = 'new_CMSPostID',
  /** column name */
  new_ChecklinkStatus = 'new_ChecklinkStatus',
  /** column name */
  new_CmsContentStatus = 'new_CmsContentStatus',
  /** column name */
  new_CommentToWriter = 'new_CommentToWriter',
  /** column name */
  new_ContentApprovedby = 'new_ContentApprovedby',
  /** column name */
  new_ContentCost = 'new_ContentCost',
  /** column name */
  new_ContentOrderStatus = 'new_ContentOrderStatus',
  /** column name */
  new_Contentduedate = 'new_Contentduedate',
  /** column name */
  new_CopyScapeDuplicate = 'new_CopyScapeDuplicate',
  /** column name */
  new_CopyscapePrivateIndexDate = 'new_CopyscapePrivateIndexDate',
  /** column name */
  new_CurrentFocus = 'new_CurrentFocus',
  /** column name */
  new_DateApproved = 'new_DateApproved',
  /** column name */
  new_DateAssigned = 'new_DateAssigned',
  /** column name */
  new_DeclineDate = 'new_DeclineDate',
  /** column name */
  new_Duplication = 'new_Duplication',
  /** column name */
  new_Keywords = 'new_Keywords',
  /** column name */
  new_OnlineSince = 'new_OnlineSince',
  /** column name */
  new_OnlineStatusCheckDate = 'new_OnlineStatusCheckDate',
  /** column name */
  new_OutreachStatus = 'new_OutreachStatus',
  /** column name */
  new_Owner = 'new_Owner',
  /** column name */
  new_PostDesciption = 'new_PostDesciption',
  /** column name */
  new_PostID = 'new_PostID',
  /** column name */
  new_PostLinksStatus = 'new_PostLinksStatus',
  /** column name */
  new_PostOnlineStatus = 'new_PostOnlineStatus',
  /** column name */
  new_PostStatus = 'new_PostStatus',
  /** column name */
  new_Postlivesince = 'new_Postlivesince',
  /** column name */
  new_RequestedFrom = 'new_RequestedFrom',
  /** column name */
  new_RequestedMinWords = 'new_RequestedMinWords',
  /** column name */
  new_RequestedOn = 'new_RequestedOn',
  /** column name */
  new_SEOApproval = 'new_SEOApproval',
  /** column name */
  new_SEODeclineReasonOptions = 'new_SEODeclineReasonOptions',
  /** column name */
  new_SentToPublisherOn = 'new_SentToPublisherOn',
  /** column name */
  new_SentTorepost = 'new_SentTorepost',
  /** column name */
  new_SenttoApproval = 'new_SenttoApproval',
  /** column name */
  new_SeoTitle = 'new_SeoTitle',
  /** column name */
  new_Supplierprice = 'new_Supplierprice',
  /** column name */
  new_TargetURL1 = 'new_TargetURL1',
  /** column name */
  new_TargetURL2 = 'new_TargetURL2',
  /** column name */
  new_TargetURL3 = 'new_TargetURL3',
  /** column name */
  new_TargetURL4 = 'new_TargetURL4',
  /** column name */
  new_TargetURL5 = 'new_TargetURL5',
  /** column name */
  new_TargetURL6 = 'new_TargetURL6',
  /** column name */
  new_TextHTMLbody = 'new_TextHTMLbody',
  /** column name */
  new_Title = 'new_Title',
  /** column name */
  new_ToApproveBy = 'new_ToApproveBy',
  /** column name */
  new_UploadPrice = 'new_UploadPrice',
  /** column name */
  new_UploadtoSite = 'new_UploadtoSite',
  /** column name */
  new_WebsiteContact = 'new_WebsiteContact',
  /** column name */
  new_WriterAssigned = 'new_WriterAssigned',
  /** column name */
  new_WritingInstructions = 'new_WritingInstructions',
  /** column name */
  new_alias = 'new_alias',
  /** column name */
  new_anchortext1type = 'new_anchortext1type',
  /** column name */
  new_anchortext2type = 'new_anchortext2type',
  /** column name */
  new_anchortext3type = 'new_anchortext3type',
  /** column name */
  new_anchortext4type = 'new_anchortext4type',
  /** column name */
  new_anchortext5type = 'new_anchortext5type',
  /** column name */
  new_anchortext6type = 'new_anchortext6type',
  /** column name */
  new_baseurl = 'new_baseurl',
  /** column name */
  new_contentdeclinereason = 'new_contentdeclinereason',
  /** column name */
  new_dropeddate = 'new_dropeddate',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_newpostId = 'new_newpostId',
  /** column name */
  new_onlinechecksubmit = 'new_onlinechecksubmit',
  /** column name */
  new_onlinechecksubmittedby = 'new_onlinechecksubmittedby',
  /** column name */
  new_postcontenttype = 'new_postcontenttype',
  /** column name */
  new_postlanguage = 'new_postlanguage',
  /** column name */
  new_postlinktype = 'new_postlinktype',
  /** column name */
  new_repostedon = 'new_repostedon',
  /** column name */
  new_seoapprovedon = 'new_seoapprovedon',
  /** column name */
  new_seodeclinedby = 'new_seodeclinedby',
  /** column name */
  new_seodeclinedon = 'new_seodeclinedon',
  /** column name */
  new_uploadedby = 'new_uploadedby',
  /** column name */
  new_uploader = 'new_uploader',
  /** column name */
  new_urlalias = 'new_urlalias',
  /** column name */
  new_website = 'new_website',
  /** column name */
  new_wordcount = 'new_wordcount',
  /** column name */
  onlinechecksubmittedby_id = 'onlinechecksubmittedby_id',
  /** column name */
  ordered_on = 'ordered_on',
  /** column name */
  orderedby_id = 'orderedby_id',
  /** column name */
  plc_failed_attempts = 'plc_failed_attempts',
  /** column name */
  po_id = 'po_id',
  /** column name */
  published_on = 'published_on',
  /** column name */
  requestDetails = 'requestDetails',
  /** column name */
  requestedby_id = 'requestedby_id',
  /** column name */
  requestedfrom_id = 'requestedfrom_id',
  /** column name */
  sent_to_pool_date = 'sent_to_pool_date',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  strategy_id = 'strategy_id',
  /** column name */
  supplier_currency_id = 'supplier_currency_id',
  /** column name */
  supplier_id = 'supplier_id',
  /** column name */
  supplier_po_id = 'supplier_po_id',
  /** column name */
  targetURLStatus1 = 'targetURLStatus1',
  /** column name */
  targetURLStatus2 = 'targetURLStatus2',
  /** column name */
  targetURLStatus3 = 'targetURLStatus3',
  /** column name */
  targetURLType1 = 'targetURLType1',
  /** column name */
  targetURLType2 = 'targetURLType2',
  /** column name */
  targetURLType3 = 'targetURLType3',
  /** column name */
  target_publish_date = 'target_publish_date',
  /** column name */
  uploaded_on = 'uploaded_on',
  /** column name */
  uploadedby_id = 'uploadedby_id',
  /** column name */
  uploader_id = 'uploader_id',
  /** column name */
  website_id = 'website_id',
  /** column name */
  writer_id = 'writer_id'
}

export type v1_Posts_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Posts_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Posts_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Posts_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Posts_var_pop_fields = {
  __typename?: 'v1_Posts_var_pop_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Float']['output']>;
  ArticleTone?: Maybe<Scalars['Float']['output']>;
  MPOrderId?: Maybe<Scalars['Float']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferId?: Maybe<Scalars['Float']['output']>;
  PriorityId?: Maybe<Scalars['Float']['output']>;
  PublisherStatus?: Maybe<Scalars['Float']['output']>;
  SalesStatus?: Maybe<Scalars['Float']['output']>;
  advertiser_po_id?: Maybe<Scalars['Float']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  approver_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  campaign_id?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  declinedby_id?: Maybe<Scalars['Float']['output']>;
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Float']['output']>;
  merged_from?: Maybe<Scalars['Float']['output']>;
  merged_to?: Maybe<Scalars['Float']['output']>;
  mergedby_id?: Maybe<Scalars['Float']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Float']['output']>;
  new_ContentCost?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Float']['output']>;
  new_Duplication?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Float']['output']>;
  new_SEOApproval?: Maybe<Scalars['Float']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Float']['output']>;
  new_Supplierprice?: Maybe<Scalars['Float']['output']>;
  new_UploadPrice?: Maybe<Scalars['Float']['output']>;
  new_anchortext1type?: Maybe<Scalars['Float']['output']>;
  new_anchortext2type?: Maybe<Scalars['Float']['output']>;
  new_anchortext3type?: Maybe<Scalars['Float']['output']>;
  new_anchortext4type?: Maybe<Scalars['Float']['output']>;
  new_anchortext5type?: Maybe<Scalars['Float']['output']>;
  new_anchortext6type?: Maybe<Scalars['Float']['output']>;
  new_postlinktype?: Maybe<Scalars['Float']['output']>;
  new_wordcount?: Maybe<Scalars['Float']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Float']['output']>;
  orderedby_id?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  requestedby_id?: Maybe<Scalars['Float']['output']>;
  requestedfrom_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  supplier_currency_id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  supplier_po_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Posts" */
export type v1_Posts_var_pop_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Posts_var_samp_fields = {
  __typename?: 'v1_Posts_var_samp_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Float']['output']>;
  ArticleTone?: Maybe<Scalars['Float']['output']>;
  MPOrderId?: Maybe<Scalars['Float']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferId?: Maybe<Scalars['Float']['output']>;
  PriorityId?: Maybe<Scalars['Float']['output']>;
  PublisherStatus?: Maybe<Scalars['Float']['output']>;
  SalesStatus?: Maybe<Scalars['Float']['output']>;
  advertiser_po_id?: Maybe<Scalars['Float']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  approver_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  campaign_id?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  declinedby_id?: Maybe<Scalars['Float']['output']>;
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Float']['output']>;
  merged_from?: Maybe<Scalars['Float']['output']>;
  merged_to?: Maybe<Scalars['Float']['output']>;
  mergedby_id?: Maybe<Scalars['Float']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Float']['output']>;
  new_ContentCost?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Float']['output']>;
  new_Duplication?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Float']['output']>;
  new_SEOApproval?: Maybe<Scalars['Float']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Float']['output']>;
  new_Supplierprice?: Maybe<Scalars['Float']['output']>;
  new_UploadPrice?: Maybe<Scalars['Float']['output']>;
  new_anchortext1type?: Maybe<Scalars['Float']['output']>;
  new_anchortext2type?: Maybe<Scalars['Float']['output']>;
  new_anchortext3type?: Maybe<Scalars['Float']['output']>;
  new_anchortext4type?: Maybe<Scalars['Float']['output']>;
  new_anchortext5type?: Maybe<Scalars['Float']['output']>;
  new_anchortext6type?: Maybe<Scalars['Float']['output']>;
  new_postlinktype?: Maybe<Scalars['Float']['output']>;
  new_wordcount?: Maybe<Scalars['Float']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Float']['output']>;
  orderedby_id?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  requestedby_id?: Maybe<Scalars['Float']['output']>;
  requestedfrom_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  supplier_currency_id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  supplier_po_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Posts" */
export type v1_Posts_var_samp_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Posts_variance_fields = {
  __typename?: 'v1_Posts_variance_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  AdvertiserStatus?: Maybe<Scalars['Float']['output']>;
  ArticleTone?: Maybe<Scalars['Float']['output']>;
  MPOrderId?: Maybe<Scalars['Float']['output']>;
  MarketplaceProjectId?: Maybe<Scalars['Float']['output']>;
  NicheId?: Maybe<Scalars['Float']['output']>;
  OfferId?: Maybe<Scalars['Float']['output']>;
  PriorityId?: Maybe<Scalars['Float']['output']>;
  PublisherStatus?: Maybe<Scalars['Float']['output']>;
  SalesStatus?: Maybe<Scalars['Float']['output']>;
  advertiser_po_id?: Maybe<Scalars['Float']['output']>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  approver_id?: Maybe<Scalars['Float']['output']>;
  brand_id?: Maybe<Scalars['Float']['output']>;
  campaign_id?: Maybe<Scalars['Float']['output']>;
  contenttype_id?: Maybe<Scalars['Float']['output']>;
  copyscape_duplication_percent?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  declinedby_id?: Maybe<Scalars['Float']['output']>;
  failed_index_checks?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  marketplacestatus_id?: Maybe<Scalars['Float']['output']>;
  merged_from?: Maybe<Scalars['Float']['output']>;
  merged_to?: Maybe<Scalars['Float']['output']>;
  mergedby_id?: Maybe<Scalars['Float']['output']>;
  new_CmsContentStatus?: Maybe<Scalars['Float']['output']>;
  new_ContentCost?: Maybe<Scalars['Float']['output']>;
  new_ContentOrderStatus?: Maybe<Scalars['Float']['output']>;
  new_CopyScapeDuplicate?: Maybe<Scalars['Float']['output']>;
  new_Duplication?: Maybe<Scalars['Float']['output']>;
  new_OutreachStatus?: Maybe<Scalars['Float']['output']>;
  new_PostLinksStatus?: Maybe<Scalars['Float']['output']>;
  new_PostOnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_PostStatus?: Maybe<Scalars['Float']['output']>;
  new_RequestedMinWords?: Maybe<Scalars['Float']['output']>;
  new_SEOApproval?: Maybe<Scalars['Float']['output']>;
  new_SEODeclineReasonOptions?: Maybe<Scalars['Float']['output']>;
  new_Supplierprice?: Maybe<Scalars['Float']['output']>;
  new_UploadPrice?: Maybe<Scalars['Float']['output']>;
  new_anchortext1type?: Maybe<Scalars['Float']['output']>;
  new_anchortext2type?: Maybe<Scalars['Float']['output']>;
  new_anchortext3type?: Maybe<Scalars['Float']['output']>;
  new_anchortext4type?: Maybe<Scalars['Float']['output']>;
  new_anchortext5type?: Maybe<Scalars['Float']['output']>;
  new_anchortext6type?: Maybe<Scalars['Float']['output']>;
  new_postlinktype?: Maybe<Scalars['Float']['output']>;
  new_wordcount?: Maybe<Scalars['Float']['output']>;
  onlinechecksubmittedby_id?: Maybe<Scalars['Float']['output']>;
  orderedby_id?: Maybe<Scalars['Float']['output']>;
  plc_failed_attempts?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  requestedby_id?: Maybe<Scalars['Float']['output']>;
  requestedfrom_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  strategy_id?: Maybe<Scalars['Float']['output']>;
  supplier_currency_id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  supplier_po_id?: Maybe<Scalars['Float']['output']>;
  uploadedby_id?: Maybe<Scalars['Float']['output']>;
  uploader_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Posts" */
export type v1_Posts_variance_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserStatus?: InputMaybe<order_by>;
  ArticleTone?: InputMaybe<order_by>;
  MPOrderId?: InputMaybe<order_by>;
  MarketplaceProjectId?: InputMaybe<order_by>;
  NicheId?: InputMaybe<order_by>;
  OfferId?: InputMaybe<order_by>;
  PriorityId?: InputMaybe<order_by>;
  PublisherStatus?: InputMaybe<order_by>;
  SalesStatus?: InputMaybe<order_by>;
  advertiser_po_id?: InputMaybe<order_by>;
  /** Sales Manager who approved the post */
  approved_by_sm_id?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  approver_id?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  campaign_id?: InputMaybe<order_by>;
  contenttype_id?: InputMaybe<order_by>;
  copyscape_duplication_percent?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  declinedby_id?: InputMaybe<order_by>;
  failed_index_checks?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  marketplacestatus_id?: InputMaybe<order_by>;
  merged_from?: InputMaybe<order_by>;
  merged_to?: InputMaybe<order_by>;
  mergedby_id?: InputMaybe<order_by>;
  new_CmsContentStatus?: InputMaybe<order_by>;
  new_ContentCost?: InputMaybe<order_by>;
  new_ContentOrderStatus?: InputMaybe<order_by>;
  new_CopyScapeDuplicate?: InputMaybe<order_by>;
  new_Duplication?: InputMaybe<order_by>;
  new_OutreachStatus?: InputMaybe<order_by>;
  new_PostLinksStatus?: InputMaybe<order_by>;
  new_PostOnlineStatus?: InputMaybe<order_by>;
  new_PostStatus?: InputMaybe<order_by>;
  new_RequestedMinWords?: InputMaybe<order_by>;
  new_SEOApproval?: InputMaybe<order_by>;
  new_SEODeclineReasonOptions?: InputMaybe<order_by>;
  new_Supplierprice?: InputMaybe<order_by>;
  new_UploadPrice?: InputMaybe<order_by>;
  new_anchortext1type?: InputMaybe<order_by>;
  new_anchortext2type?: InputMaybe<order_by>;
  new_anchortext3type?: InputMaybe<order_by>;
  new_anchortext4type?: InputMaybe<order_by>;
  new_anchortext5type?: InputMaybe<order_by>;
  new_anchortext6type?: InputMaybe<order_by>;
  new_postlinktype?: InputMaybe<order_by>;
  new_wordcount?: InputMaybe<order_by>;
  onlinechecksubmittedby_id?: InputMaybe<order_by>;
  orderedby_id?: InputMaybe<order_by>;
  plc_failed_attempts?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  requestedby_id?: InputMaybe<order_by>;
  requestedfrom_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  strategy_id?: InputMaybe<order_by>;
  supplier_currency_id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  supplier_po_id?: InputMaybe<order_by>;
  uploadedby_id?: InputMaybe<order_by>;
  uploader_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Preferences" */
export type v1_Preferences = {
  __typename?: 'v1_Preferences';
  /** An object relationship */
  CreatedBy: v1_SystemUserBase;
  created_by: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  path: Scalars['bpchar']['output'];
  preference: Scalars['json']['output'];
};


/** columns and relationships of "v1.Preferences" */
export type v1_PreferencespreferenceArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplace = {
  __typename?: 'v1_PreferencesMarketplace';
  created_by: Scalars['bpchar']['output'];
  id: Scalars['Int']['output'];
  path: Scalars['bpchar']['output'];
  preference: Scalars['json']['output'];
};


/** columns and relationships of "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplacepreferenceArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplace_aggregate = {
  __typename?: 'v1_PreferencesMarketplace_aggregate';
  aggregate?: Maybe<v1_PreferencesMarketplace_aggregate_fields>;
  nodes: Array<v1_PreferencesMarketplace>;
};

/** aggregate fields of "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplace_aggregate_fields = {
  __typename?: 'v1_PreferencesMarketplace_aggregate_fields';
  avg?: Maybe<v1_PreferencesMarketplace_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PreferencesMarketplace_max_fields>;
  min?: Maybe<v1_PreferencesMarketplace_min_fields>;
  stddev?: Maybe<v1_PreferencesMarketplace_stddev_fields>;
  stddev_pop?: Maybe<v1_PreferencesMarketplace_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PreferencesMarketplace_stddev_samp_fields>;
  sum?: Maybe<v1_PreferencesMarketplace_sum_fields>;
  var_pop?: Maybe<v1_PreferencesMarketplace_var_pop_fields>;
  var_samp?: Maybe<v1_PreferencesMarketplace_var_samp_fields>;
  variance?: Maybe<v1_PreferencesMarketplace_variance_fields>;
};


/** aggregate fields of "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplace_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PreferencesMarketplace_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PreferencesMarketplace_avg_fields = {
  __typename?: 'v1_PreferencesMarketplace_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PreferencesMarketplace". All fields are combined with a logical 'AND'. */
export type v1_PreferencesMarketplace_bool_exp = {
  _and?: InputMaybe<Array<v1_PreferencesMarketplace_bool_exp>>;
  _not?: InputMaybe<v1_PreferencesMarketplace_bool_exp>;
  _or?: InputMaybe<Array<v1_PreferencesMarketplace_bool_exp>>;
  created_by?: InputMaybe<bpchar_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  path?: InputMaybe<bpchar_comparison_exp>;
  preference?: InputMaybe<json_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PreferencesMarketplace" */
export enum v1_PreferencesMarketplace_constraint {
  /** unique or primary key constraint on columns "id" */
  PreferencesMarketplace_pkey = 'PreferencesMarketplace_pkey'
}

/** input type for incrementing numeric columns in table "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplace_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplace_insert_input = {
  created_by?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  path?: InputMaybe<Scalars['bpchar']['input']>;
  preference?: InputMaybe<Scalars['json']['input']>;
};

/** aggregate max on columns */
export type v1_PreferencesMarketplace_max_fields = {
  __typename?: 'v1_PreferencesMarketplace_max_fields';
  created_by?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  path?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_PreferencesMarketplace_min_fields = {
  __typename?: 'v1_PreferencesMarketplace_min_fields';
  created_by?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  path?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplace_mutation_response = {
  __typename?: 'v1_PreferencesMarketplace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PreferencesMarketplace>;
};

/** on_conflict condition type for table "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplace_on_conflict = {
  constraint: v1_PreferencesMarketplace_constraint;
  update_columns?: Array<v1_PreferencesMarketplace_update_column>;
  where?: InputMaybe<v1_PreferencesMarketplace_bool_exp>;
};

/** Ordering options when selecting data from "v1.PreferencesMarketplace". */
export type v1_PreferencesMarketplace_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  path?: InputMaybe<order_by>;
  preference?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PreferencesMarketplace */
export type v1_PreferencesMarketplace_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PreferencesMarketplace" */
export enum v1_PreferencesMarketplace_select_column {
  /** column name */
  created_by = 'created_by',
  /** column name */
  id = 'id',
  /** column name */
  path = 'path',
  /** column name */
  preference = 'preference'
}

/** input type for updating data in table "v1.PreferencesMarketplace" */
export type v1_PreferencesMarketplace_set_input = {
  created_by?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  path?: InputMaybe<Scalars['bpchar']['input']>;
  preference?: InputMaybe<Scalars['json']['input']>;
};

/** aggregate stddev on columns */
export type v1_PreferencesMarketplace_stddev_fields = {
  __typename?: 'v1_PreferencesMarketplace_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PreferencesMarketplace_stddev_pop_fields = {
  __typename?: 'v1_PreferencesMarketplace_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PreferencesMarketplace_stddev_samp_fields = {
  __typename?: 'v1_PreferencesMarketplace_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PreferencesMarketplace" */
export type v1_PreferencesMarketplace_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PreferencesMarketplace_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PreferencesMarketplace_stream_cursor_value_input = {
  created_by?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  path?: InputMaybe<Scalars['bpchar']['input']>;
  preference?: InputMaybe<Scalars['json']['input']>;
};

/** aggregate sum on columns */
export type v1_PreferencesMarketplace_sum_fields = {
  __typename?: 'v1_PreferencesMarketplace_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PreferencesMarketplace" */
export enum v1_PreferencesMarketplace_update_column {
  /** column name */
  created_by = 'created_by',
  /** column name */
  id = 'id',
  /** column name */
  path = 'path',
  /** column name */
  preference = 'preference'
}

export type v1_PreferencesMarketplace_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PreferencesMarketplace_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PreferencesMarketplace_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PreferencesMarketplace_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PreferencesMarketplace_var_pop_fields = {
  __typename?: 'v1_PreferencesMarketplace_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PreferencesMarketplace_var_samp_fields = {
  __typename?: 'v1_PreferencesMarketplace_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PreferencesMarketplace_variance_fields = {
  __typename?: 'v1_PreferencesMarketplace_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.Preferences" */
export type v1_Preferences_aggregate = {
  __typename?: 'v1_Preferences_aggregate';
  aggregate?: Maybe<v1_Preferences_aggregate_fields>;
  nodes: Array<v1_Preferences>;
};

export type v1_Preferences_aggregate_bool_exp = {
  count?: InputMaybe<v1_Preferences_aggregate_bool_exp_count>;
};

export type v1_Preferences_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Preferences_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Preferences_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Preferences" */
export type v1_Preferences_aggregate_fields = {
  __typename?: 'v1_Preferences_aggregate_fields';
  avg?: Maybe<v1_Preferences_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Preferences_max_fields>;
  min?: Maybe<v1_Preferences_min_fields>;
  stddev?: Maybe<v1_Preferences_stddev_fields>;
  stddev_pop?: Maybe<v1_Preferences_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Preferences_stddev_samp_fields>;
  sum?: Maybe<v1_Preferences_sum_fields>;
  var_pop?: Maybe<v1_Preferences_var_pop_fields>;
  var_samp?: Maybe<v1_Preferences_var_samp_fields>;
  variance?: Maybe<v1_Preferences_variance_fields>;
};


/** aggregate fields of "v1.Preferences" */
export type v1_Preferences_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Preferences_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Preferences" */
export type v1_Preferences_aggregate_order_by = {
  avg?: InputMaybe<v1_Preferences_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Preferences_max_order_by>;
  min?: InputMaybe<v1_Preferences_min_order_by>;
  stddev?: InputMaybe<v1_Preferences_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Preferences_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Preferences_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Preferences_sum_order_by>;
  var_pop?: InputMaybe<v1_Preferences_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Preferences_var_samp_order_by>;
  variance?: InputMaybe<v1_Preferences_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Preferences" */
export type v1_Preferences_arr_rel_insert_input = {
  data: Array<v1_Preferences_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Preferences_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Preferences_avg_fields = {
  __typename?: 'v1_Preferences_avg_fields';
  created_by?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Preferences" */
export type v1_Preferences_avg_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Preferences". All fields are combined with a logical 'AND'. */
export type v1_Preferences_bool_exp = {
  CreatedBy?: InputMaybe<v1_SystemUserBase_bool_exp>;
  _and?: InputMaybe<Array<v1_Preferences_bool_exp>>;
  _not?: InputMaybe<v1_Preferences_bool_exp>;
  _or?: InputMaybe<Array<v1_Preferences_bool_exp>>;
  created_by?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  path?: InputMaybe<bpchar_comparison_exp>;
  preference?: InputMaybe<json_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Preferences" */
export enum v1_Preferences_constraint {
  /** unique or primary key constraint on columns "created_by", "path" */
  Preferences_created_by_path_key = 'Preferences_created_by_path_key',
  /** unique or primary key constraint on columns "id" */
  Preferences_pkey = 'Preferences_pkey'
}

/** input type for incrementing numeric columns in table "v1.Preferences" */
export type v1_Preferences_inc_input = {
  created_by?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Preferences" */
export type v1_Preferences_insert_input = {
  CreatedBy?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  created_by?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  path?: InputMaybe<Scalars['bpchar']['input']>;
  preference?: InputMaybe<Scalars['json']['input']>;
};

/** aggregate max on columns */
export type v1_Preferences_max_fields = {
  __typename?: 'v1_Preferences_max_fields';
  created_by?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  path?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "v1.Preferences" */
export type v1_Preferences_max_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  path?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Preferences_min_fields = {
  __typename?: 'v1_Preferences_min_fields';
  created_by?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  path?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "v1.Preferences" */
export type v1_Preferences_min_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  path?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Preferences" */
export type v1_Preferences_mutation_response = {
  __typename?: 'v1_Preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Preferences>;
};

/** on_conflict condition type for table "v1.Preferences" */
export type v1_Preferences_on_conflict = {
  constraint: v1_Preferences_constraint;
  update_columns?: Array<v1_Preferences_update_column>;
  where?: InputMaybe<v1_Preferences_bool_exp>;
};

/** Ordering options when selecting data from "v1.Preferences". */
export type v1_Preferences_order_by = {
  CreatedBy?: InputMaybe<v1_SystemUserBase_order_by>;
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  path?: InputMaybe<order_by>;
  preference?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Preferences */
export type v1_Preferences_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Preferences" */
export enum v1_Preferences_select_column {
  /** column name */
  created_by = 'created_by',
  /** column name */
  id = 'id',
  /** column name */
  path = 'path',
  /** column name */
  preference = 'preference'
}

/** input type for updating data in table "v1.Preferences" */
export type v1_Preferences_set_input = {
  created_by?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  path?: InputMaybe<Scalars['bpchar']['input']>;
  preference?: InputMaybe<Scalars['json']['input']>;
};

/** aggregate stddev on columns */
export type v1_Preferences_stddev_fields = {
  __typename?: 'v1_Preferences_stddev_fields';
  created_by?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Preferences" */
export type v1_Preferences_stddev_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Preferences_stddev_pop_fields = {
  __typename?: 'v1_Preferences_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Preferences" */
export type v1_Preferences_stddev_pop_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Preferences_stddev_samp_fields = {
  __typename?: 'v1_Preferences_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Preferences" */
export type v1_Preferences_stddev_samp_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Preferences" */
export type v1_Preferences_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Preferences_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Preferences_stream_cursor_value_input = {
  created_by?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  path?: InputMaybe<Scalars['bpchar']['input']>;
  preference?: InputMaybe<Scalars['json']['input']>;
};

/** aggregate sum on columns */
export type v1_Preferences_sum_fields = {
  __typename?: 'v1_Preferences_sum_fields';
  created_by?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Preferences" */
export type v1_Preferences_sum_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Preferences" */
export enum v1_Preferences_update_column {
  /** column name */
  created_by = 'created_by',
  /** column name */
  id = 'id',
  /** column name */
  path = 'path',
  /** column name */
  preference = 'preference'
}

export type v1_Preferences_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Preferences_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Preferences_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Preferences_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Preferences_var_pop_fields = {
  __typename?: 'v1_Preferences_var_pop_fields';
  created_by?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Preferences" */
export type v1_Preferences_var_pop_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Preferences_var_samp_fields = {
  __typename?: 'v1_Preferences_var_samp_fields';
  created_by?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Preferences" */
export type v1_Preferences_var_samp_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Preferences_variance_fields = {
  __typename?: 'v1_Preferences_variance_fields';
  created_by?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Preferences" */
export type v1_Preferences_variance_order_by = {
  created_by?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Project" */
export type v1_Project = {
  __typename?: 'v1_Project';
  /** An array relationship */
  AffilateOrders: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  AffilateOrders_aggregate: v1_SalesOrderBase_aggregate;
  /** An array relationship */
  BonusSubmitters: Array<v1_BonusSubmitter>;
  /** An aggregate relationship */
  BonusSubmitters_aggregate: v1_BonusSubmitter_aggregate;
  /** An array relationship */
  Bonuses: Array<v1_Bonuses>;
  /** An aggregate relationship */
  Bonuses_aggregate: v1_Bonuses_aggregate;
  /** An array relationship */
  Campaigns: Array<v1_Campaign>;
  /** An aggregate relationship */
  Campaigns_aggregate: v1_Campaign_aggregate;
  /** An object relationship */
  Category?: Maybe<v1_Categories>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  DefaultCountry?: Maybe<v1_new_countryBase>;
  /** An object relationship */
  Importance?: Maybe<v1_ProjectImportance>;
  /** An array relationship */
  LandinPages: Array<v1_LandingPages>;
  /** An aggregate relationship */
  LandinPages_aggregate: v1_LandingPages_aggregate;
  /** An object relationship */
  Language?: Maybe<v1_new_languagesBase>;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  ProjectCountries: Array<v1_Project_Countries>;
  /** An aggregate relationship */
  ProjectCountries_aggregate: v1_Project_Countries_aggregate;
  /** An object relationship */
  ProjectStatus?: Maybe<v1_ProjectStatus>;
  /** An array relationship */
  Tracker: Array<v1_new_trackerBase>;
  /** An aggregate relationship */
  Tracker_aggregate: v1_new_trackerBase_aggregate;
  /** An object relationship */
  Website?: Maybe<v1_Website>;
  category_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.linkbuilding_monthly_completion" */
  comp_linkbuilding_monthly_completion?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "v1.onsite_monthly_completion" */
  comp_onsite_monthly_completion?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "v1.social_monthly_completion" */
  comp_social_monthly_completion?: Maybe<Scalars['numeric']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  default_country_id?: Maybe<Scalars['Int']['output']>;
  has_integration: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  language_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.linkbuilding_completion" */
  link_building_completion?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.linkbuilding_monthly_target" */
  link_building_target?: Maybe<Scalars['Int']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Int']['output']>;
  new_Brandterms?: Maybe<Scalars['String']['output']>;
  new_Countagain?: Maybe<Scalars['Boolean']['output']>;
  new_CustomerContact?: Maybe<Scalars['uuid']['output']>;
  new_CustomerOrder?: Maybe<Scalars['uuid']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Int']['output']>;
  new_Followup?: Maybe<Scalars['timestamp']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Int']['output']>;
  new_GooglePlus?: Maybe<Scalars['Int']['output']>;
  new_Importance?: Maybe<Scalars['Int']['output']>;
  new_InsiteContent?: Maybe<Scalars['Int']['output']>;
  new_LandingPages?: Maybe<Scalars['Int']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['numeric']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Int']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Int']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Int']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Int']['output']>;
  new_LastOrdersCreation?: Maybe<Scalars['timestamp']['output']>;
  new_LastStatisticsCalculation?: Maybe<Scalars['timestamp']['output']>;
  new_LastUpdate?: Maybe<Scalars['timestamp']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Int']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Int']['output']>;
  new_LpmcampaingsId?: Maybe<Scalars['uuid']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Int']['output']>;
  new_MonthlyRankCheck?: Maybe<Scalars['Boolean']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Int']['output']>;
  new_Parentcampaign?: Maybe<Scalars['uuid']['output']>;
  new_PenalizedbyGoogle?: Maybe<Scalars['Boolean']['output']>;
  new_PendingPosting?: Maybe<Scalars['Int']['output']>;
  new_PostsPosted?: Maybe<Scalars['Int']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Int']['output']>;
  new_PremiumBL?: Maybe<Scalars['Int']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Int']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Int']['output']>;
  new_SocialPush?: Maybe<Scalars['Int']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Int']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Int']['output']>;
  new_TargetSocial?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Int']['output']>;
  new_Topic?: Maybe<Scalars['uuid']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['numeric']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Int']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Int']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Int']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Int']['output']>;
  new_Type?: Maybe<Scalars['Int']['output']>;
  new_UpdateRate?: Maybe<Scalars['Int']['output']>;
  new_VideoLinks?: Maybe<Scalars['Int']['output']>;
  new_Web20?: Maybe<Scalars['Int']['output']>;
  new_WebsiteId?: Maybe<Scalars['uuid']['output']>;
  new_anytoolspass?: Maybe<Scalars['String']['output']>;
  new_anytoolssite?: Maybe<Scalars['String']['output']>;
  new_anytoolsuser?: Maybe<Scalars['String']['output']>;
  new_db_website?: Maybe<Scalars['String']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Int']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Int']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Int']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Int']['output']>;
  new_linkbuildingprojectstarget_Date?: Maybe<Scalars['timestamp']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_wmtoolspass?: Maybe<Scalars['String']['output']>;
  new_wmtoolssite?: Maybe<Scalars['String']['output']>;
  new_wmtoolsuser?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "v1.onsite_completion" */
  onsite_completion?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.onsite_monthly_target" */
  onsite_target?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  project_crm_id?: Maybe<Scalars['uuid']['output']>;
  project_crm_lang?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "v1.social_completion" */
  social_completion?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.social_monthly_target" */
  social_target?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectAffilateOrdersArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectAffilateOrders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectBonusSubmittersArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSubmitter_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSubmitter_order_by>>;
  where?: InputMaybe<v1_BonusSubmitter_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectBonusSubmitters_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusSubmitter_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusSubmitter_order_by>>;
  where?: InputMaybe<v1_BonusSubmitter_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectBonusesArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectBonuses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectCampaignsArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectCampaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectLandinPagesArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectLandinPages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectProjectCountriesArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_Countries_order_by>>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectProjectCountries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_Countries_order_by>>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectTrackerArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


/** columns and relationships of "v1.Project" */
export type v1_ProjectTracker_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};

/** columns and relationships of "v1.ProjectImportance" */
export type v1_ProjectImportance = {
  __typename?: 'v1_ProjectImportance';
  /** An array relationship */
  Projects: Array<v1_Project>;
  /** An aggregate relationship */
  Projects_aggregate: v1_Project_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.ProjectImportance" */
export type v1_ProjectImportanceProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** columns and relationships of "v1.ProjectImportance" */
export type v1_ProjectImportanceProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};

/** aggregated selection of "v1.ProjectImportance" */
export type v1_ProjectImportance_aggregate = {
  __typename?: 'v1_ProjectImportance_aggregate';
  aggregate?: Maybe<v1_ProjectImportance_aggregate_fields>;
  nodes: Array<v1_ProjectImportance>;
};

/** aggregate fields of "v1.ProjectImportance" */
export type v1_ProjectImportance_aggregate_fields = {
  __typename?: 'v1_ProjectImportance_aggregate_fields';
  avg?: Maybe<v1_ProjectImportance_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ProjectImportance_max_fields>;
  min?: Maybe<v1_ProjectImportance_min_fields>;
  stddev?: Maybe<v1_ProjectImportance_stddev_fields>;
  stddev_pop?: Maybe<v1_ProjectImportance_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ProjectImportance_stddev_samp_fields>;
  sum?: Maybe<v1_ProjectImportance_sum_fields>;
  var_pop?: Maybe<v1_ProjectImportance_var_pop_fields>;
  var_samp?: Maybe<v1_ProjectImportance_var_samp_fields>;
  variance?: Maybe<v1_ProjectImportance_variance_fields>;
};


/** aggregate fields of "v1.ProjectImportance" */
export type v1_ProjectImportance_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ProjectImportance_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ProjectImportance_avg_fields = {
  __typename?: 'v1_ProjectImportance_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ProjectImportance". All fields are combined with a logical 'AND'. */
export type v1_ProjectImportance_bool_exp = {
  Projects?: InputMaybe<v1_Project_bool_exp>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_ProjectImportance_bool_exp>>;
  _not?: InputMaybe<v1_ProjectImportance_bool_exp>;
  _or?: InputMaybe<Array<v1_ProjectImportance_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ProjectImportance" */
export enum v1_ProjectImportance_constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectImportance_pkey = 'ProjectImportance_pkey'
}

/** input type for incrementing numeric columns in table "v1.ProjectImportance" */
export type v1_ProjectImportance_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ProjectImportance" */
export type v1_ProjectImportance_insert_input = {
  Projects?: InputMaybe<v1_Project_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_ProjectImportance_max_fields = {
  __typename?: 'v1_ProjectImportance_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_ProjectImportance_min_fields = {
  __typename?: 'v1_ProjectImportance_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.ProjectImportance" */
export type v1_ProjectImportance_mutation_response = {
  __typename?: 'v1_ProjectImportance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ProjectImportance>;
};

/** input type for inserting object relation for remote table "v1.ProjectImportance" */
export type v1_ProjectImportance_obj_rel_insert_input = {
  data: v1_ProjectImportance_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ProjectImportance_on_conflict>;
};

/** on_conflict condition type for table "v1.ProjectImportance" */
export type v1_ProjectImportance_on_conflict = {
  constraint: v1_ProjectImportance_constraint;
  update_columns?: Array<v1_ProjectImportance_update_column>;
  where?: InputMaybe<v1_ProjectImportance_bool_exp>;
};

/** Ordering options when selecting data from "v1.ProjectImportance". */
export type v1_ProjectImportance_order_by = {
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ProjectImportance */
export type v1_ProjectImportance_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ProjectImportance" */
export enum v1_ProjectImportance_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.ProjectImportance" */
export type v1_ProjectImportance_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_ProjectImportance_stddev_fields = {
  __typename?: 'v1_ProjectImportance_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ProjectImportance_stddev_pop_fields = {
  __typename?: 'v1_ProjectImportance_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ProjectImportance_stddev_samp_fields = {
  __typename?: 'v1_ProjectImportance_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ProjectImportance" */
export type v1_ProjectImportance_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ProjectImportance_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ProjectImportance_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ProjectImportance_sum_fields = {
  __typename?: 'v1_ProjectImportance_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ProjectImportance" */
export enum v1_ProjectImportance_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_ProjectImportance_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ProjectImportance_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ProjectImportance_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ProjectImportance_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ProjectImportance_var_pop_fields = {
  __typename?: 'v1_ProjectImportance_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ProjectImportance_var_samp_fields = {
  __typename?: 'v1_ProjectImportance_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ProjectImportance_variance_fields = {
  __typename?: 'v1_ProjectImportance_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.ProjectManagers" */
export type v1_ProjectManagers = {
  __typename?: 'v1_ProjectManagers';
  pm_id?: Maybe<Scalars['Int']['output']>;
  pm_name?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "v1.ProjectManagers" */
export type v1_ProjectManagers_aggregate = {
  __typename?: 'v1_ProjectManagers_aggregate';
  aggregate?: Maybe<v1_ProjectManagers_aggregate_fields>;
  nodes: Array<v1_ProjectManagers>;
};

/** aggregate fields of "v1.ProjectManagers" */
export type v1_ProjectManagers_aggregate_fields = {
  __typename?: 'v1_ProjectManagers_aggregate_fields';
  avg?: Maybe<v1_ProjectManagers_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ProjectManagers_max_fields>;
  min?: Maybe<v1_ProjectManagers_min_fields>;
  stddev?: Maybe<v1_ProjectManagers_stddev_fields>;
  stddev_pop?: Maybe<v1_ProjectManagers_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ProjectManagers_stddev_samp_fields>;
  sum?: Maybe<v1_ProjectManagers_sum_fields>;
  var_pop?: Maybe<v1_ProjectManagers_var_pop_fields>;
  var_samp?: Maybe<v1_ProjectManagers_var_samp_fields>;
  variance?: Maybe<v1_ProjectManagers_variance_fields>;
};


/** aggregate fields of "v1.ProjectManagers" */
export type v1_ProjectManagers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ProjectManagers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ProjectManagers_avg_fields = {
  __typename?: 'v1_ProjectManagers_avg_fields';
  pm_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ProjectManagers". All fields are combined with a logical 'AND'. */
export type v1_ProjectManagers_bool_exp = {
  _and?: InputMaybe<Array<v1_ProjectManagers_bool_exp>>;
  _not?: InputMaybe<v1_ProjectManagers_bool_exp>;
  _or?: InputMaybe<Array<v1_ProjectManagers_bool_exp>>;
  pm_id?: InputMaybe<Int_comparison_exp>;
  pm_name?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type v1_ProjectManagers_max_fields = {
  __typename?: 'v1_ProjectManagers_max_fields';
  pm_id?: Maybe<Scalars['Int']['output']>;
  pm_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_ProjectManagers_min_fields = {
  __typename?: 'v1_ProjectManagers_min_fields';
  pm_id?: Maybe<Scalars['Int']['output']>;
  pm_name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "v1.ProjectManagers". */
export type v1_ProjectManagers_order_by = {
  pm_id?: InputMaybe<order_by>;
  pm_name?: InputMaybe<order_by>;
};

/** select columns of table "v1.ProjectManagers" */
export enum v1_ProjectManagers_select_column {
  /** column name */
  pm_id = 'pm_id',
  /** column name */
  pm_name = 'pm_name'
}

/** aggregate stddev on columns */
export type v1_ProjectManagers_stddev_fields = {
  __typename?: 'v1_ProjectManagers_stddev_fields';
  pm_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ProjectManagers_stddev_pop_fields = {
  __typename?: 'v1_ProjectManagers_stddev_pop_fields';
  pm_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ProjectManagers_stddev_samp_fields = {
  __typename?: 'v1_ProjectManagers_stddev_samp_fields';
  pm_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ProjectManagers" */
export type v1_ProjectManagers_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ProjectManagers_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ProjectManagers_stream_cursor_value_input = {
  pm_id?: InputMaybe<Scalars['Int']['input']>;
  pm_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ProjectManagers_sum_fields = {
  __typename?: 'v1_ProjectManagers_sum_fields';
  pm_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type v1_ProjectManagers_var_pop_fields = {
  __typename?: 'v1_ProjectManagers_var_pop_fields';
  pm_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ProjectManagers_var_samp_fields = {
  __typename?: 'v1_ProjectManagers_var_samp_fields';
  pm_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ProjectManagers_variance_fields = {
  __typename?: 'v1_ProjectManagers_variance_fields';
  pm_id?: Maybe<Scalars['Float']['output']>;
};

/** Project statuscode */
export type v1_ProjectStatus = {
  __typename?: 'v1_ProjectStatus';
  /** An array relationship */
  Projects: Array<v1_Project>;
  /** An aggregate relationship */
  Projects_aggregate: v1_Project_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** Project statuscode */
export type v1_ProjectStatusProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** Project statuscode */
export type v1_ProjectStatusProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};

/** aggregated selection of "v1.ProjectStatus" */
export type v1_ProjectStatus_aggregate = {
  __typename?: 'v1_ProjectStatus_aggregate';
  aggregate?: Maybe<v1_ProjectStatus_aggregate_fields>;
  nodes: Array<v1_ProjectStatus>;
};

/** aggregate fields of "v1.ProjectStatus" */
export type v1_ProjectStatus_aggregate_fields = {
  __typename?: 'v1_ProjectStatus_aggregate_fields';
  avg?: Maybe<v1_ProjectStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ProjectStatus_max_fields>;
  min?: Maybe<v1_ProjectStatus_min_fields>;
  stddev?: Maybe<v1_ProjectStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_ProjectStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ProjectStatus_stddev_samp_fields>;
  sum?: Maybe<v1_ProjectStatus_sum_fields>;
  var_pop?: Maybe<v1_ProjectStatus_var_pop_fields>;
  var_samp?: Maybe<v1_ProjectStatus_var_samp_fields>;
  variance?: Maybe<v1_ProjectStatus_variance_fields>;
};


/** aggregate fields of "v1.ProjectStatus" */
export type v1_ProjectStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ProjectStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ProjectStatus_avg_fields = {
  __typename?: 'v1_ProjectStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ProjectStatus". All fields are combined with a logical 'AND'. */
export type v1_ProjectStatus_bool_exp = {
  Projects?: InputMaybe<v1_Project_bool_exp>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_ProjectStatus_bool_exp>>;
  _not?: InputMaybe<v1_ProjectStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_ProjectStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ProjectStatus" */
export enum v1_ProjectStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectStatus_pkey = 'ProjectStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.ProjectStatus" */
export type v1_ProjectStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ProjectStatus" */
export type v1_ProjectStatus_insert_input = {
  Projects?: InputMaybe<v1_Project_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_ProjectStatus_max_fields = {
  __typename?: 'v1_ProjectStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_ProjectStatus_min_fields = {
  __typename?: 'v1_ProjectStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.ProjectStatus" */
export type v1_ProjectStatus_mutation_response = {
  __typename?: 'v1_ProjectStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ProjectStatus>;
};

/** input type for inserting object relation for remote table "v1.ProjectStatus" */
export type v1_ProjectStatus_obj_rel_insert_input = {
  data: v1_ProjectStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ProjectStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.ProjectStatus" */
export type v1_ProjectStatus_on_conflict = {
  constraint: v1_ProjectStatus_constraint;
  update_columns?: Array<v1_ProjectStatus_update_column>;
  where?: InputMaybe<v1_ProjectStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.ProjectStatus". */
export type v1_ProjectStatus_order_by = {
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ProjectStatus */
export type v1_ProjectStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ProjectStatus" */
export enum v1_ProjectStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.ProjectStatus" */
export type v1_ProjectStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_ProjectStatus_stddev_fields = {
  __typename?: 'v1_ProjectStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ProjectStatus_stddev_pop_fields = {
  __typename?: 'v1_ProjectStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ProjectStatus_stddev_samp_fields = {
  __typename?: 'v1_ProjectStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ProjectStatus" */
export type v1_ProjectStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ProjectStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ProjectStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ProjectStatus_sum_fields = {
  __typename?: 'v1_ProjectStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ProjectStatus" */
export enum v1_ProjectStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_ProjectStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ProjectStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ProjectStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ProjectStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ProjectStatus_var_pop_fields = {
  __typename?: 'v1_ProjectStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ProjectStatus_var_samp_fields = {
  __typename?: 'v1_ProjectStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ProjectStatus_variance_fields = {
  __typename?: 'v1_ProjectStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.ProjectTopic" */
export type v1_ProjectTopic = {
  __typename?: 'v1_ProjectTopic';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  project_topics: Array<v1_project_topic>;
  /** An aggregate relationship */
  project_topics_aggregate: v1_project_topic_aggregate;
};


/** columns and relationships of "v1.ProjectTopic" */
export type v1_ProjectTopicproject_topicsArgs = {
  distinct_on?: InputMaybe<Array<v1_project_topic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_project_topic_order_by>>;
  where?: InputMaybe<v1_project_topic_bool_exp>;
};


/** columns and relationships of "v1.ProjectTopic" */
export type v1_ProjectTopicproject_topics_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_project_topic_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_project_topic_order_by>>;
  where?: InputMaybe<v1_project_topic_bool_exp>;
};

/** aggregated selection of "v1.ProjectTopic" */
export type v1_ProjectTopic_aggregate = {
  __typename?: 'v1_ProjectTopic_aggregate';
  aggregate?: Maybe<v1_ProjectTopic_aggregate_fields>;
  nodes: Array<v1_ProjectTopic>;
};

/** aggregate fields of "v1.ProjectTopic" */
export type v1_ProjectTopic_aggregate_fields = {
  __typename?: 'v1_ProjectTopic_aggregate_fields';
  avg?: Maybe<v1_ProjectTopic_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ProjectTopic_max_fields>;
  min?: Maybe<v1_ProjectTopic_min_fields>;
  stddev?: Maybe<v1_ProjectTopic_stddev_fields>;
  stddev_pop?: Maybe<v1_ProjectTopic_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ProjectTopic_stddev_samp_fields>;
  sum?: Maybe<v1_ProjectTopic_sum_fields>;
  var_pop?: Maybe<v1_ProjectTopic_var_pop_fields>;
  var_samp?: Maybe<v1_ProjectTopic_var_samp_fields>;
  variance?: Maybe<v1_ProjectTopic_variance_fields>;
};


/** aggregate fields of "v1.ProjectTopic" */
export type v1_ProjectTopic_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ProjectTopic_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ProjectTopic_avg_fields = {
  __typename?: 'v1_ProjectTopic_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ProjectTopic". All fields are combined with a logical 'AND'. */
export type v1_ProjectTopic_bool_exp = {
  _and?: InputMaybe<Array<v1_ProjectTopic_bool_exp>>;
  _not?: InputMaybe<v1_ProjectTopic_bool_exp>;
  _or?: InputMaybe<Array<v1_ProjectTopic_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  project_topics?: InputMaybe<v1_project_topic_bool_exp>;
  project_topics_aggregate?: InputMaybe<v1_project_topic_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "v1.ProjectTopic" */
export enum v1_ProjectTopic_constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectTopic_id_key = 'ProjectTopic_id_key',
  /** unique or primary key constraint on columns "id" */
  ProjectTopic_pkey = 'ProjectTopic_pkey'
}

/** input type for incrementing numeric columns in table "v1.ProjectTopic" */
export type v1_ProjectTopic_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ProjectTopic" */
export type v1_ProjectTopic_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project_topics?: InputMaybe<v1_project_topic_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type v1_ProjectTopic_max_fields = {
  __typename?: 'v1_ProjectTopic_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_ProjectTopic_min_fields = {
  __typename?: 'v1_ProjectTopic_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.ProjectTopic" */
export type v1_ProjectTopic_mutation_response = {
  __typename?: 'v1_ProjectTopic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ProjectTopic>;
};

/** input type for inserting object relation for remote table "v1.ProjectTopic" */
export type v1_ProjectTopic_obj_rel_insert_input = {
  data: v1_ProjectTopic_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ProjectTopic_on_conflict>;
};

/** on_conflict condition type for table "v1.ProjectTopic" */
export type v1_ProjectTopic_on_conflict = {
  constraint: v1_ProjectTopic_constraint;
  update_columns?: Array<v1_ProjectTopic_update_column>;
  where?: InputMaybe<v1_ProjectTopic_bool_exp>;
};

/** Ordering options when selecting data from "v1.ProjectTopic". */
export type v1_ProjectTopic_order_by = {
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  project_topics_aggregate?: InputMaybe<v1_project_topic_aggregate_order_by>;
};

/** primary key columns input for table: v1.ProjectTopic */
export type v1_ProjectTopic_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ProjectTopic" */
export enum v1_ProjectTopic_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.ProjectTopic" */
export type v1_ProjectTopic_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_ProjectTopic_stddev_fields = {
  __typename?: 'v1_ProjectTopic_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ProjectTopic_stddev_pop_fields = {
  __typename?: 'v1_ProjectTopic_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ProjectTopic_stddev_samp_fields = {
  __typename?: 'v1_ProjectTopic_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ProjectTopic" */
export type v1_ProjectTopic_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ProjectTopic_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ProjectTopic_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ProjectTopic_sum_fields = {
  __typename?: 'v1_ProjectTopic_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ProjectTopic" */
export enum v1_ProjectTopic_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_ProjectTopic_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ProjectTopic_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ProjectTopic_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ProjectTopic_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ProjectTopic_var_pop_fields = {
  __typename?: 'v1_ProjectTopic_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ProjectTopic_var_samp_fields = {
  __typename?: 'v1_ProjectTopic_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ProjectTopic_variance_fields = {
  __typename?: 'v1_ProjectTopic_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Project_Countries" */
export type v1_Project_Countries = {
  __typename?: 'v1_Project_Countries';
  /** An object relationship */
  Country: v1_new_countryBase;
  /** An object relationship */
  Project: v1_Project;
  country_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  project_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.Project_Countries" */
export type v1_Project_Countries_aggregate = {
  __typename?: 'v1_Project_Countries_aggregate';
  aggregate?: Maybe<v1_Project_Countries_aggregate_fields>;
  nodes: Array<v1_Project_Countries>;
};

export type v1_Project_Countries_aggregate_bool_exp = {
  count?: InputMaybe<v1_Project_Countries_aggregate_bool_exp_count>;
};

export type v1_Project_Countries_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Project_Countries_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Project_Countries" */
export type v1_Project_Countries_aggregate_fields = {
  __typename?: 'v1_Project_Countries_aggregate_fields';
  avg?: Maybe<v1_Project_Countries_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Project_Countries_max_fields>;
  min?: Maybe<v1_Project_Countries_min_fields>;
  stddev?: Maybe<v1_Project_Countries_stddev_fields>;
  stddev_pop?: Maybe<v1_Project_Countries_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Project_Countries_stddev_samp_fields>;
  sum?: Maybe<v1_Project_Countries_sum_fields>;
  var_pop?: Maybe<v1_Project_Countries_var_pop_fields>;
  var_samp?: Maybe<v1_Project_Countries_var_samp_fields>;
  variance?: Maybe<v1_Project_Countries_variance_fields>;
};


/** aggregate fields of "v1.Project_Countries" */
export type v1_Project_Countries_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Project_Countries" */
export type v1_Project_Countries_aggregate_order_by = {
  avg?: InputMaybe<v1_Project_Countries_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Project_Countries_max_order_by>;
  min?: InputMaybe<v1_Project_Countries_min_order_by>;
  stddev?: InputMaybe<v1_Project_Countries_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Project_Countries_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Project_Countries_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Project_Countries_sum_order_by>;
  var_pop?: InputMaybe<v1_Project_Countries_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Project_Countries_var_samp_order_by>;
  variance?: InputMaybe<v1_Project_Countries_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Project_Countries" */
export type v1_Project_Countries_arr_rel_insert_input = {
  data: Array<v1_Project_Countries_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Project_Countries_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Project_Countries_avg_fields = {
  __typename?: 'v1_Project_Countries_avg_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_avg_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Project_Countries". All fields are combined with a logical 'AND'. */
export type v1_Project_Countries_bool_exp = {
  Country?: InputMaybe<v1_new_countryBase_bool_exp>;
  Project?: InputMaybe<v1_Project_bool_exp>;
  _and?: InputMaybe<Array<v1_Project_Countries_bool_exp>>;
  _not?: InputMaybe<v1_Project_Countries_bool_exp>;
  _or?: InputMaybe<Array<v1_Project_Countries_bool_exp>>;
  country_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  project_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Project_Countries" */
export enum v1_Project_Countries_constraint {
  /** unique or primary key constraint on columns "id" */
  Project_Countries_pkey = 'Project_Countries_pkey'
}

/** input type for incrementing numeric columns in table "v1.Project_Countries" */
export type v1_Project_Countries_inc_input = {
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Project_Countries" */
export type v1_Project_Countries_insert_input = {
  Country?: InputMaybe<v1_new_countryBase_obj_rel_insert_input>;
  Project?: InputMaybe<v1_Project_obj_rel_insert_input>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Project_Countries_max_fields = {
  __typename?: 'v1_Project_Countries_max_fields';
  country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_max_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Project_Countries_min_fields = {
  __typename?: 'v1_Project_Countries_min_fields';
  country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_min_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Project_Countries" */
export type v1_Project_Countries_mutation_response = {
  __typename?: 'v1_Project_Countries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Project_Countries>;
};

/** on_conflict condition type for table "v1.Project_Countries" */
export type v1_Project_Countries_on_conflict = {
  constraint: v1_Project_Countries_constraint;
  update_columns?: Array<v1_Project_Countries_update_column>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};

/** Ordering options when selecting data from "v1.Project_Countries". */
export type v1_Project_Countries_order_by = {
  Country?: InputMaybe<v1_new_countryBase_order_by>;
  Project?: InputMaybe<v1_Project_order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Project_Countries */
export type v1_Project_Countries_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Project_Countries" */
export enum v1_Project_Countries_select_column {
  /** column name */
  country_id = 'country_id',
  /** column name */
  id = 'id',
  /** column name */
  project_id = 'project_id'
}

/** input type for updating data in table "v1.Project_Countries" */
export type v1_Project_Countries_set_input = {
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Project_Countries_stddev_fields = {
  __typename?: 'v1_Project_Countries_stddev_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_stddev_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Project_Countries_stddev_pop_fields = {
  __typename?: 'v1_Project_Countries_stddev_pop_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_stddev_pop_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Project_Countries_stddev_samp_fields = {
  __typename?: 'v1_Project_Countries_stddev_samp_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_stddev_samp_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Project_Countries" */
export type v1_Project_Countries_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Project_Countries_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Project_Countries_stream_cursor_value_input = {
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Project_Countries_sum_fields = {
  __typename?: 'v1_Project_Countries_sum_fields';
  country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_sum_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Project_Countries" */
export enum v1_Project_Countries_update_column {
  /** column name */
  country_id = 'country_id',
  /** column name */
  id = 'id',
  /** column name */
  project_id = 'project_id'
}

export type v1_Project_Countries_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Project_Countries_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Project_Countries_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Project_Countries_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Project_Countries_var_pop_fields = {
  __typename?: 'v1_Project_Countries_var_pop_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_var_pop_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Project_Countries_var_samp_fields = {
  __typename?: 'v1_Project_Countries_var_samp_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_var_samp_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Project_Countries_variance_fields = {
  __typename?: 'v1_Project_Countries_variance_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Project_Countries" */
export type v1_Project_Countries_variance_order_by = {
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
};

/** aggregated selection of "v1.Project" */
export type v1_Project_aggregate = {
  __typename?: 'v1_Project_aggregate';
  aggregate?: Maybe<v1_Project_aggregate_fields>;
  nodes: Array<v1_Project>;
};

export type v1_Project_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_Project_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_Project_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_Project_aggregate_bool_exp_count>;
};

export type v1_Project_aggregate_bool_exp_bool_and = {
  arguments: v1_Project_select_column_v1_Project_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Project_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Project_aggregate_bool_exp_bool_or = {
  arguments: v1_Project_select_column_v1_Project_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Project_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Project_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Project_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Project_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Project" */
export type v1_Project_aggregate_fields = {
  __typename?: 'v1_Project_aggregate_fields';
  avg?: Maybe<v1_Project_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Project_max_fields>;
  min?: Maybe<v1_Project_min_fields>;
  stddev?: Maybe<v1_Project_stddev_fields>;
  stddev_pop?: Maybe<v1_Project_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Project_stddev_samp_fields>;
  sum?: Maybe<v1_Project_sum_fields>;
  var_pop?: Maybe<v1_Project_var_pop_fields>;
  var_samp?: Maybe<v1_Project_var_samp_fields>;
  variance?: Maybe<v1_Project_variance_fields>;
};


/** aggregate fields of "v1.Project" */
export type v1_Project_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Project_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Project" */
export type v1_Project_aggregate_order_by = {
  avg?: InputMaybe<v1_Project_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Project_max_order_by>;
  min?: InputMaybe<v1_Project_min_order_by>;
  stddev?: InputMaybe<v1_Project_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Project_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Project_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Project_sum_order_by>;
  var_pop?: InputMaybe<v1_Project_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Project_var_samp_order_by>;
  variance?: InputMaybe<v1_Project_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Project" */
export type v1_Project_arr_rel_insert_input = {
  data: Array<v1_Project_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Project_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Project_avg_fields = {
  __typename?: 'v1_Project_avg_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  default_country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Float']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Float']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Float']['output']>;
  new_GooglePlus?: Maybe<Scalars['Float']['output']>;
  new_Importance?: Maybe<Scalars['Float']['output']>;
  new_InsiteContent?: Maybe<Scalars['Float']['output']>;
  new_LandingPages?: Maybe<Scalars['Float']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Float']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Float']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Float']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PendingPosting?: Maybe<Scalars['Float']['output']>;
  new_PostsPosted?: Maybe<Scalars['Float']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Float']['output']>;
  new_PremiumBL?: Maybe<Scalars['Float']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Float']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Float']['output']>;
  new_SocialPush?: Maybe<Scalars['Float']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Float']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Float']['output']>;
  new_TargetSocial?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['Float']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Float']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Float']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Float']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Float']['output']>;
  new_Type?: Maybe<Scalars['Float']['output']>;
  new_UpdateRate?: Maybe<Scalars['Float']['output']>;
  new_VideoLinks?: Maybe<Scalars['Float']['output']>;
  new_Web20?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Project" */
export type v1_Project_avg_order_by = {
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Project". All fields are combined with a logical 'AND'. */
export type v1_Project_bool_exp = {
  AffilateOrders?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  AffilateOrders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  BonusSubmitters?: InputMaybe<v1_BonusSubmitter_bool_exp>;
  BonusSubmitters_aggregate?: InputMaybe<v1_BonusSubmitter_aggregate_bool_exp>;
  Bonuses?: InputMaybe<v1_Bonuses_bool_exp>;
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_bool_exp>;
  Campaigns?: InputMaybe<v1_Campaign_bool_exp>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  Category?: InputMaybe<v1_Categories_bool_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  DefaultCountry?: InputMaybe<v1_new_countryBase_bool_exp>;
  Importance?: InputMaybe<v1_ProjectImportance_bool_exp>;
  LandinPages?: InputMaybe<v1_LandingPages_bool_exp>;
  LandinPages_aggregate?: InputMaybe<v1_LandingPages_aggregate_bool_exp>;
  Language?: InputMaybe<v1_new_languagesBase_bool_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  ProjectCountries?: InputMaybe<v1_Project_Countries_bool_exp>;
  ProjectCountries_aggregate?: InputMaybe<v1_Project_Countries_aggregate_bool_exp>;
  ProjectStatus?: InputMaybe<v1_ProjectStatus_bool_exp>;
  Tracker?: InputMaybe<v1_new_trackerBase_bool_exp>;
  Tracker_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_bool_exp>;
  Website?: InputMaybe<v1_Website_bool_exp>;
  _and?: InputMaybe<Array<v1_Project_bool_exp>>;
  _not?: InputMaybe<v1_Project_bool_exp>;
  _or?: InputMaybe<Array<v1_Project_bool_exp>>;
  category_id?: InputMaybe<Int_comparison_exp>;
  comp_linkbuilding_monthly_completion?: InputMaybe<numeric_comparison_exp>;
  comp_onsite_monthly_completion?: InputMaybe<numeric_comparison_exp>;
  comp_social_monthly_completion?: InputMaybe<numeric_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  default_country_id?: InputMaybe<Int_comparison_exp>;
  has_integration?: InputMaybe<Boolean_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  language_id?: InputMaybe<Int_comparison_exp>;
  link_building_completion?: InputMaybe<Int_comparison_exp>;
  link_building_target?: InputMaybe<Int_comparison_exp>;
  new_45daysCompletedSocialPush?: InputMaybe<Int_comparison_exp>;
  new_Brandterms?: InputMaybe<String_comparison_exp>;
  new_Countagain?: InputMaybe<Boolean_comparison_exp>;
  new_CustomerContact?: InputMaybe<uuid_comparison_exp>;
  new_CustomerOrder?: InputMaybe<uuid_comparison_exp>;
  new_Description?: InputMaybe<String_comparison_exp>;
  new_DropDomainsLinks?: InputMaybe<Int_comparison_exp>;
  new_Followup?: InputMaybe<timestamp_comparison_exp>;
  new_GoogleManualSpamAction?: InputMaybe<Int_comparison_exp>;
  new_GooglePlus?: InputMaybe<Int_comparison_exp>;
  new_Importance?: InputMaybe<Int_comparison_exp>;
  new_InsiteContent?: InputMaybe<Int_comparison_exp>;
  new_LandingPages?: InputMaybe<Int_comparison_exp>;
  new_LastMonthIncome?: InputMaybe<numeric_comparison_exp>;
  new_LastMonthOnsite?: InputMaybe<Int_comparison_exp>;
  new_LastMonthOutreach?: InputMaybe<Int_comparison_exp>;
  new_LastMonthSocial?: InputMaybe<Int_comparison_exp>;
  new_LastMonthTraffic?: InputMaybe<Int_comparison_exp>;
  new_LastOrdersCreation?: InputMaybe<timestamp_comparison_exp>;
  new_LastStatisticsCalculation?: InputMaybe<timestamp_comparison_exp>;
  new_LastUpdate?: InputMaybe<timestamp_comparison_exp>;
  new_Lastmonthplayers?: InputMaybe<Int_comparison_exp>;
  new_LinkingRealDomains?: InputMaybe<Int_comparison_exp>;
  new_LpmcampaingsId?: InputMaybe<uuid_comparison_exp>;
  new_ManualForumLinks?: InputMaybe<Int_comparison_exp>;
  new_MonthlyRankCheck?: InputMaybe<Boolean_comparison_exp>;
  new_MonthlyTarget?: InputMaybe<Int_comparison_exp>;
  new_Parentcampaign?: InputMaybe<uuid_comparison_exp>;
  new_PenalizedbyGoogle?: InputMaybe<Boolean_comparison_exp>;
  new_PendingPosting?: InputMaybe<Int_comparison_exp>;
  new_PostsPosted?: InputMaybe<Int_comparison_exp>;
  new_PostsPostedLastXdays?: InputMaybe<Int_comparison_exp>;
  new_PremiumBL?: InputMaybe<Int_comparison_exp>;
  new_ShellyPosts?: InputMaybe<Int_comparison_exp>;
  new_SocialLastMonth?: InputMaybe<Int_comparison_exp>;
  new_SocialPush?: InputMaybe<Int_comparison_exp>;
  new_TargetOnsite?: InputMaybe<Int_comparison_exp>;
  new_TargetOutreach?: InputMaybe<Int_comparison_exp>;
  new_TargetSocial?: InputMaybe<Int_comparison_exp>;
  new_ThisMonthOnsite?: InputMaybe<Int_comparison_exp>;
  new_ThisMonthOutreach?: InputMaybe<Int_comparison_exp>;
  new_ThisMonthSocial?: InputMaybe<Int_comparison_exp>;
  new_Topic?: InputMaybe<uuid_comparison_exp>;
  new_TotalAfiliateIncome?: InputMaybe<numeric_comparison_exp>;
  new_TotalIndexedPosts?: InputMaybe<Int_comparison_exp>;
  new_TotalPlayers?: InputMaybe<Int_comparison_exp>;
  new_TotalSocialPush?: InputMaybe<Int_comparison_exp>;
  new_ToxicPostsActive?: InputMaybe<Int_comparison_exp>;
  new_Type?: InputMaybe<Int_comparison_exp>;
  new_UpdateRate?: InputMaybe<Int_comparison_exp>;
  new_VideoLinks?: InputMaybe<Int_comparison_exp>;
  new_Web20?: InputMaybe<Int_comparison_exp>;
  new_WebsiteId?: InputMaybe<uuid_comparison_exp>;
  new_anytoolspass?: InputMaybe<String_comparison_exp>;
  new_anytoolssite?: InputMaybe<String_comparison_exp>;
  new_anytoolsuser?: InputMaybe<String_comparison_exp>;
  new_db_website?: InputMaybe<String_comparison_exp>;
  new_lastmonthtargetonsite?: InputMaybe<Int_comparison_exp>;
  new_lastmonthtargetoutreach?: InputMaybe<Int_comparison_exp>;
  new_lastmonthtargetsocial?: InputMaybe<Int_comparison_exp>;
  new_linkbuildingprojectstarget?: InputMaybe<Int_comparison_exp>;
  new_linkbuildingprojectstarget_Date?: InputMaybe<timestamp_comparison_exp>;
  new_linkbuildingprojectstarget_State?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_wmtoolspass?: InputMaybe<String_comparison_exp>;
  new_wmtoolssite?: InputMaybe<String_comparison_exp>;
  new_wmtoolsuser?: InputMaybe<String_comparison_exp>;
  onsite_completion?: InputMaybe<Int_comparison_exp>;
  onsite_target?: InputMaybe<Int_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  project_crm_id?: InputMaybe<uuid_comparison_exp>;
  project_crm_lang?: InputMaybe<uuid_comparison_exp>;
  social_completion?: InputMaybe<Int_comparison_exp>;
  social_target?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
  website_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Project" */
export enum v1_Project_constraint {
  /** unique or primary key constraint on columns "id" */
  Project_id_key = 'Project_id_key',
  /** unique or primary key constraint on columns "id" */
  Project_pkey = 'Project_pkey'
}

/** input type for incrementing numeric columns in table "v1.Project" */
export type v1_Project_inc_input = {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  default_country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  new_45daysCompletedSocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_DropDomainsLinks?: InputMaybe<Scalars['Int']['input']>;
  new_GoogleManualSpamAction?: InputMaybe<Scalars['Int']['input']>;
  new_GooglePlus?: InputMaybe<Scalars['Int']['input']>;
  new_Importance?: InputMaybe<Scalars['Int']['input']>;
  new_InsiteContent?: InputMaybe<Scalars['Int']['input']>;
  new_LandingPages?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthIncome?: InputMaybe<Scalars['numeric']['input']>;
  new_LastMonthOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthSocial?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthTraffic?: InputMaybe<Scalars['Int']['input']>;
  new_Lastmonthplayers?: InputMaybe<Scalars['Int']['input']>;
  new_LinkingRealDomains?: InputMaybe<Scalars['Int']['input']>;
  new_ManualForumLinks?: InputMaybe<Scalars['Int']['input']>;
  new_MonthlyTarget?: InputMaybe<Scalars['Int']['input']>;
  new_PendingPosting?: InputMaybe<Scalars['Int']['input']>;
  new_PostsPosted?: InputMaybe<Scalars['Int']['input']>;
  new_PostsPostedLastXdays?: InputMaybe<Scalars['Int']['input']>;
  new_PremiumBL?: InputMaybe<Scalars['Int']['input']>;
  new_ShellyPosts?: InputMaybe<Scalars['Int']['input']>;
  new_SocialLastMonth?: InputMaybe<Scalars['Int']['input']>;
  new_SocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_TargetOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_TargetOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_TargetSocial?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthSocial?: InputMaybe<Scalars['Int']['input']>;
  new_TotalAfiliateIncome?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalIndexedPosts?: InputMaybe<Scalars['Int']['input']>;
  new_TotalPlayers?: InputMaybe<Scalars['Int']['input']>;
  new_TotalSocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_ToxicPostsActive?: InputMaybe<Scalars['Int']['input']>;
  new_Type?: InputMaybe<Scalars['Int']['input']>;
  new_UpdateRate?: InputMaybe<Scalars['Int']['input']>;
  new_VideoLinks?: InputMaybe<Scalars['Int']['input']>;
  new_Web20?: InputMaybe<Scalars['Int']['input']>;
  new_lastmonthtargetonsite?: InputMaybe<Scalars['Int']['input']>;
  new_lastmonthtargetoutreach?: InputMaybe<Scalars['Int']['input']>;
  new_lastmonthtargetsocial?: InputMaybe<Scalars['Int']['input']>;
  new_linkbuildingprojectstarget?: InputMaybe<Scalars['Int']['input']>;
  new_linkbuildingprojectstarget_State?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Project" */
export type v1_Project_insert_input = {
  AffilateOrders?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  BonusSubmitters?: InputMaybe<v1_BonusSubmitter_arr_rel_insert_input>;
  Bonuses?: InputMaybe<v1_Bonuses_arr_rel_insert_input>;
  Campaigns?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  Category?: InputMaybe<v1_Categories_obj_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  DefaultCountry?: InputMaybe<v1_new_countryBase_obj_rel_insert_input>;
  Importance?: InputMaybe<v1_ProjectImportance_obj_rel_insert_input>;
  LandinPages?: InputMaybe<v1_LandingPages_arr_rel_insert_input>;
  Language?: InputMaybe<v1_new_languagesBase_obj_rel_insert_input>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  ProjectCountries?: InputMaybe<v1_Project_Countries_arr_rel_insert_input>;
  ProjectStatus?: InputMaybe<v1_ProjectStatus_obj_rel_insert_input>;
  Tracker?: InputMaybe<v1_new_trackerBase_arr_rel_insert_input>;
  Website?: InputMaybe<v1_Website_obj_rel_insert_input>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  default_country_id?: InputMaybe<Scalars['Int']['input']>;
  has_integration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  new_45daysCompletedSocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_Brandterms?: InputMaybe<Scalars['String']['input']>;
  new_Countagain?: InputMaybe<Scalars['Boolean']['input']>;
  new_CustomerContact?: InputMaybe<Scalars['uuid']['input']>;
  new_CustomerOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DropDomainsLinks?: InputMaybe<Scalars['Int']['input']>;
  new_Followup?: InputMaybe<Scalars['timestamp']['input']>;
  new_GoogleManualSpamAction?: InputMaybe<Scalars['Int']['input']>;
  new_GooglePlus?: InputMaybe<Scalars['Int']['input']>;
  new_Importance?: InputMaybe<Scalars['Int']['input']>;
  new_InsiteContent?: InputMaybe<Scalars['Int']['input']>;
  new_LandingPages?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthIncome?: InputMaybe<Scalars['numeric']['input']>;
  new_LastMonthOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthSocial?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthTraffic?: InputMaybe<Scalars['Int']['input']>;
  new_LastOrdersCreation?: InputMaybe<Scalars['timestamp']['input']>;
  new_LastStatisticsCalculation?: InputMaybe<Scalars['timestamp']['input']>;
  new_LastUpdate?: InputMaybe<Scalars['timestamp']['input']>;
  new_Lastmonthplayers?: InputMaybe<Scalars['Int']['input']>;
  new_LinkingRealDomains?: InputMaybe<Scalars['Int']['input']>;
  new_LpmcampaingsId?: InputMaybe<Scalars['uuid']['input']>;
  new_ManualForumLinks?: InputMaybe<Scalars['Int']['input']>;
  new_MonthlyRankCheck?: InputMaybe<Scalars['Boolean']['input']>;
  new_MonthlyTarget?: InputMaybe<Scalars['Int']['input']>;
  new_Parentcampaign?: InputMaybe<Scalars['uuid']['input']>;
  new_PenalizedbyGoogle?: InputMaybe<Scalars['Boolean']['input']>;
  new_PendingPosting?: InputMaybe<Scalars['Int']['input']>;
  new_PostsPosted?: InputMaybe<Scalars['Int']['input']>;
  new_PostsPostedLastXdays?: InputMaybe<Scalars['Int']['input']>;
  new_PremiumBL?: InputMaybe<Scalars['Int']['input']>;
  new_ShellyPosts?: InputMaybe<Scalars['Int']['input']>;
  new_SocialLastMonth?: InputMaybe<Scalars['Int']['input']>;
  new_SocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_TargetOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_TargetOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_TargetSocial?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthSocial?: InputMaybe<Scalars['Int']['input']>;
  new_Topic?: InputMaybe<Scalars['uuid']['input']>;
  new_TotalAfiliateIncome?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalIndexedPosts?: InputMaybe<Scalars['Int']['input']>;
  new_TotalPlayers?: InputMaybe<Scalars['Int']['input']>;
  new_TotalSocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_ToxicPostsActive?: InputMaybe<Scalars['Int']['input']>;
  new_Type?: InputMaybe<Scalars['Int']['input']>;
  new_UpdateRate?: InputMaybe<Scalars['Int']['input']>;
  new_VideoLinks?: InputMaybe<Scalars['Int']['input']>;
  new_Web20?: InputMaybe<Scalars['Int']['input']>;
  new_WebsiteId?: InputMaybe<Scalars['uuid']['input']>;
  new_anytoolspass?: InputMaybe<Scalars['String']['input']>;
  new_anytoolssite?: InputMaybe<Scalars['String']['input']>;
  new_anytoolsuser?: InputMaybe<Scalars['String']['input']>;
  new_db_website?: InputMaybe<Scalars['String']['input']>;
  new_lastmonthtargetonsite?: InputMaybe<Scalars['Int']['input']>;
  new_lastmonthtargetoutreach?: InputMaybe<Scalars['Int']['input']>;
  new_lastmonthtargetsocial?: InputMaybe<Scalars['Int']['input']>;
  new_linkbuildingprojectstarget?: InputMaybe<Scalars['Int']['input']>;
  new_linkbuildingprojectstarget_Date?: InputMaybe<Scalars['timestamp']['input']>;
  new_linkbuildingprojectstarget_State?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_wmtoolspass?: InputMaybe<Scalars['String']['input']>;
  new_wmtoolssite?: InputMaybe<Scalars['String']['input']>;
  new_wmtoolsuser?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  project_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  project_crm_lang?: InputMaybe<Scalars['uuid']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Project_max_fields = {
  __typename?: 'v1_Project_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  category_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  default_country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Int']['output']>;
  new_Brandterms?: Maybe<Scalars['String']['output']>;
  new_CustomerContact?: Maybe<Scalars['uuid']['output']>;
  new_CustomerOrder?: Maybe<Scalars['uuid']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Int']['output']>;
  new_Followup?: Maybe<Scalars['timestamp']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Int']['output']>;
  new_GooglePlus?: Maybe<Scalars['Int']['output']>;
  new_Importance?: Maybe<Scalars['Int']['output']>;
  new_InsiteContent?: Maybe<Scalars['Int']['output']>;
  new_LandingPages?: Maybe<Scalars['Int']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['numeric']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Int']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Int']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Int']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Int']['output']>;
  new_LastOrdersCreation?: Maybe<Scalars['timestamp']['output']>;
  new_LastStatisticsCalculation?: Maybe<Scalars['timestamp']['output']>;
  new_LastUpdate?: Maybe<Scalars['timestamp']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Int']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Int']['output']>;
  new_LpmcampaingsId?: Maybe<Scalars['uuid']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Int']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Int']['output']>;
  new_Parentcampaign?: Maybe<Scalars['uuid']['output']>;
  new_PendingPosting?: Maybe<Scalars['Int']['output']>;
  new_PostsPosted?: Maybe<Scalars['Int']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Int']['output']>;
  new_PremiumBL?: Maybe<Scalars['Int']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Int']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Int']['output']>;
  new_SocialPush?: Maybe<Scalars['Int']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Int']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Int']['output']>;
  new_TargetSocial?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Int']['output']>;
  new_Topic?: Maybe<Scalars['uuid']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['numeric']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Int']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Int']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Int']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Int']['output']>;
  new_Type?: Maybe<Scalars['Int']['output']>;
  new_UpdateRate?: Maybe<Scalars['Int']['output']>;
  new_VideoLinks?: Maybe<Scalars['Int']['output']>;
  new_Web20?: Maybe<Scalars['Int']['output']>;
  new_WebsiteId?: Maybe<Scalars['uuid']['output']>;
  new_anytoolspass?: Maybe<Scalars['String']['output']>;
  new_anytoolssite?: Maybe<Scalars['String']['output']>;
  new_anytoolsuser?: Maybe<Scalars['String']['output']>;
  new_db_website?: Maybe<Scalars['String']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Int']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Int']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Int']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Int']['output']>;
  new_linkbuildingprojectstarget_Date?: Maybe<Scalars['timestamp']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_wmtoolspass?: Maybe<Scalars['String']['output']>;
  new_wmtoolssite?: Maybe<Scalars['String']['output']>;
  new_wmtoolsuser?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  project_crm_id?: Maybe<Scalars['uuid']['output']>;
  project_crm_lang?: Maybe<Scalars['uuid']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Project" */
export type v1_Project_max_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_Brandterms?: InputMaybe<order_by>;
  new_CustomerContact?: InputMaybe<order_by>;
  new_CustomerOrder?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_Followup?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_LastOrdersCreation?: InputMaybe<order_by>;
  new_LastStatisticsCalculation?: InputMaybe<order_by>;
  new_LastUpdate?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_LpmcampaingsId?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_Parentcampaign?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_Topic?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_WebsiteId?: InputMaybe<order_by>;
  new_anytoolspass?: InputMaybe<order_by>;
  new_anytoolssite?: InputMaybe<order_by>;
  new_anytoolsuser?: InputMaybe<order_by>;
  new_db_website?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_Date?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_wmtoolspass?: InputMaybe<order_by>;
  new_wmtoolssite?: InputMaybe<order_by>;
  new_wmtoolsuser?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_crm_id?: InputMaybe<order_by>;
  project_crm_lang?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Project_min_fields = {
  __typename?: 'v1_Project_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  category_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  default_country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Int']['output']>;
  new_Brandterms?: Maybe<Scalars['String']['output']>;
  new_CustomerContact?: Maybe<Scalars['uuid']['output']>;
  new_CustomerOrder?: Maybe<Scalars['uuid']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Int']['output']>;
  new_Followup?: Maybe<Scalars['timestamp']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Int']['output']>;
  new_GooglePlus?: Maybe<Scalars['Int']['output']>;
  new_Importance?: Maybe<Scalars['Int']['output']>;
  new_InsiteContent?: Maybe<Scalars['Int']['output']>;
  new_LandingPages?: Maybe<Scalars['Int']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['numeric']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Int']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Int']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Int']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Int']['output']>;
  new_LastOrdersCreation?: Maybe<Scalars['timestamp']['output']>;
  new_LastStatisticsCalculation?: Maybe<Scalars['timestamp']['output']>;
  new_LastUpdate?: Maybe<Scalars['timestamp']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Int']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Int']['output']>;
  new_LpmcampaingsId?: Maybe<Scalars['uuid']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Int']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Int']['output']>;
  new_Parentcampaign?: Maybe<Scalars['uuid']['output']>;
  new_PendingPosting?: Maybe<Scalars['Int']['output']>;
  new_PostsPosted?: Maybe<Scalars['Int']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Int']['output']>;
  new_PremiumBL?: Maybe<Scalars['Int']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Int']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Int']['output']>;
  new_SocialPush?: Maybe<Scalars['Int']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Int']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Int']['output']>;
  new_TargetSocial?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Int']['output']>;
  new_Topic?: Maybe<Scalars['uuid']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['numeric']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Int']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Int']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Int']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Int']['output']>;
  new_Type?: Maybe<Scalars['Int']['output']>;
  new_UpdateRate?: Maybe<Scalars['Int']['output']>;
  new_VideoLinks?: Maybe<Scalars['Int']['output']>;
  new_Web20?: Maybe<Scalars['Int']['output']>;
  new_WebsiteId?: Maybe<Scalars['uuid']['output']>;
  new_anytoolspass?: Maybe<Scalars['String']['output']>;
  new_anytoolssite?: Maybe<Scalars['String']['output']>;
  new_anytoolsuser?: Maybe<Scalars['String']['output']>;
  new_db_website?: Maybe<Scalars['String']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Int']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Int']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Int']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Int']['output']>;
  new_linkbuildingprojectstarget_Date?: Maybe<Scalars['timestamp']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_wmtoolspass?: Maybe<Scalars['String']['output']>;
  new_wmtoolssite?: Maybe<Scalars['String']['output']>;
  new_wmtoolsuser?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  project_crm_id?: Maybe<Scalars['uuid']['output']>;
  project_crm_lang?: Maybe<Scalars['uuid']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Project" */
export type v1_Project_min_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_Brandterms?: InputMaybe<order_by>;
  new_CustomerContact?: InputMaybe<order_by>;
  new_CustomerOrder?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_Followup?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_LastOrdersCreation?: InputMaybe<order_by>;
  new_LastStatisticsCalculation?: InputMaybe<order_by>;
  new_LastUpdate?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_LpmcampaingsId?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_Parentcampaign?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_Topic?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_WebsiteId?: InputMaybe<order_by>;
  new_anytoolspass?: InputMaybe<order_by>;
  new_anytoolssite?: InputMaybe<order_by>;
  new_anytoolsuser?: InputMaybe<order_by>;
  new_db_website?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_Date?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_wmtoolspass?: InputMaybe<order_by>;
  new_wmtoolssite?: InputMaybe<order_by>;
  new_wmtoolsuser?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_crm_id?: InputMaybe<order_by>;
  project_crm_lang?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Project" */
export type v1_Project_mutation_response = {
  __typename?: 'v1_Project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Project>;
};

/** input type for inserting object relation for remote table "v1.Project" */
export type v1_Project_obj_rel_insert_input = {
  data: v1_Project_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Project_on_conflict>;
};

/** on_conflict condition type for table "v1.Project" */
export type v1_Project_on_conflict = {
  constraint: v1_Project_constraint;
  update_columns?: Array<v1_Project_update_column>;
  where?: InputMaybe<v1_Project_bool_exp>;
};

/** Ordering options when selecting data from "v1.Project". */
export type v1_Project_order_by = {
  AffilateOrders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  BonusSubmitters_aggregate?: InputMaybe<v1_BonusSubmitter_aggregate_order_by>;
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_order_by>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  Category?: InputMaybe<v1_Categories_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  DefaultCountry?: InputMaybe<v1_new_countryBase_order_by>;
  Importance?: InputMaybe<v1_ProjectImportance_order_by>;
  LandinPages_aggregate?: InputMaybe<v1_LandingPages_aggregate_order_by>;
  Language?: InputMaybe<v1_new_languagesBase_order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  OwnerId?: InputMaybe<order_by>;
  ProjectCountries_aggregate?: InputMaybe<v1_Project_Countries_aggregate_order_by>;
  ProjectStatus?: InputMaybe<v1_ProjectStatus_order_by>;
  Tracker_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_order_by>;
  Website?: InputMaybe<v1_Website_order_by>;
  category_id?: InputMaybe<order_by>;
  comp_linkbuilding_monthly_completion?: InputMaybe<order_by>;
  comp_onsite_monthly_completion?: InputMaybe<order_by>;
  comp_social_monthly_completion?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  has_integration?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  link_building_completion?: InputMaybe<order_by>;
  link_building_target?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_Brandterms?: InputMaybe<order_by>;
  new_Countagain?: InputMaybe<order_by>;
  new_CustomerContact?: InputMaybe<order_by>;
  new_CustomerOrder?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_Followup?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_LastOrdersCreation?: InputMaybe<order_by>;
  new_LastStatisticsCalculation?: InputMaybe<order_by>;
  new_LastUpdate?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_LpmcampaingsId?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyRankCheck?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_Parentcampaign?: InputMaybe<order_by>;
  new_PenalizedbyGoogle?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_Topic?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_WebsiteId?: InputMaybe<order_by>;
  new_anytoolspass?: InputMaybe<order_by>;
  new_anytoolssite?: InputMaybe<order_by>;
  new_anytoolsuser?: InputMaybe<order_by>;
  new_db_website?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_Date?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_wmtoolspass?: InputMaybe<order_by>;
  new_wmtoolssite?: InputMaybe<order_by>;
  new_wmtoolsuser?: InputMaybe<order_by>;
  onsite_completion?: InputMaybe<order_by>;
  onsite_target?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  project_crm_id?: InputMaybe<order_by>;
  project_crm_lang?: InputMaybe<order_by>;
  social_completion?: InputMaybe<order_by>;
  social_target?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Project */
export type v1_Project_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Project" */
export enum v1_Project_select_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  category_id = 'category_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  default_country_id = 'default_country_id',
  /** column name */
  has_integration = 'has_integration',
  /** column name */
  id = 'id',
  /** column name */
  language_id = 'language_id',
  /** column name */
  new_45daysCompletedSocialPush = 'new_45daysCompletedSocialPush',
  /** column name */
  new_Brandterms = 'new_Brandterms',
  /** column name */
  new_Countagain = 'new_Countagain',
  /** column name */
  new_CustomerContact = 'new_CustomerContact',
  /** column name */
  new_CustomerOrder = 'new_CustomerOrder',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_DropDomainsLinks = 'new_DropDomainsLinks',
  /** column name */
  new_Followup = 'new_Followup',
  /** column name */
  new_GoogleManualSpamAction = 'new_GoogleManualSpamAction',
  /** column name */
  new_GooglePlus = 'new_GooglePlus',
  /** column name */
  new_Importance = 'new_Importance',
  /** column name */
  new_InsiteContent = 'new_InsiteContent',
  /** column name */
  new_LandingPages = 'new_LandingPages',
  /** column name */
  new_LastMonthIncome = 'new_LastMonthIncome',
  /** column name */
  new_LastMonthOnsite = 'new_LastMonthOnsite',
  /** column name */
  new_LastMonthOutreach = 'new_LastMonthOutreach',
  /** column name */
  new_LastMonthSocial = 'new_LastMonthSocial',
  /** column name */
  new_LastMonthTraffic = 'new_LastMonthTraffic',
  /** column name */
  new_LastOrdersCreation = 'new_LastOrdersCreation',
  /** column name */
  new_LastStatisticsCalculation = 'new_LastStatisticsCalculation',
  /** column name */
  new_LastUpdate = 'new_LastUpdate',
  /** column name */
  new_Lastmonthplayers = 'new_Lastmonthplayers',
  /** column name */
  new_LinkingRealDomains = 'new_LinkingRealDomains',
  /** column name */
  new_LpmcampaingsId = 'new_LpmcampaingsId',
  /** column name */
  new_ManualForumLinks = 'new_ManualForumLinks',
  /** column name */
  new_MonthlyRankCheck = 'new_MonthlyRankCheck',
  /** column name */
  new_MonthlyTarget = 'new_MonthlyTarget',
  /** column name */
  new_Parentcampaign = 'new_Parentcampaign',
  /** column name */
  new_PenalizedbyGoogle = 'new_PenalizedbyGoogle',
  /** column name */
  new_PendingPosting = 'new_PendingPosting',
  /** column name */
  new_PostsPosted = 'new_PostsPosted',
  /** column name */
  new_PostsPostedLastXdays = 'new_PostsPostedLastXdays',
  /** column name */
  new_PremiumBL = 'new_PremiumBL',
  /** column name */
  new_ShellyPosts = 'new_ShellyPosts',
  /** column name */
  new_SocialLastMonth = 'new_SocialLastMonth',
  /** column name */
  new_SocialPush = 'new_SocialPush',
  /** column name */
  new_TargetOnsite = 'new_TargetOnsite',
  /** column name */
  new_TargetOutreach = 'new_TargetOutreach',
  /** column name */
  new_TargetSocial = 'new_TargetSocial',
  /** column name */
  new_ThisMonthOnsite = 'new_ThisMonthOnsite',
  /** column name */
  new_ThisMonthOutreach = 'new_ThisMonthOutreach',
  /** column name */
  new_ThisMonthSocial = 'new_ThisMonthSocial',
  /** column name */
  new_Topic = 'new_Topic',
  /** column name */
  new_TotalAfiliateIncome = 'new_TotalAfiliateIncome',
  /** column name */
  new_TotalIndexedPosts = 'new_TotalIndexedPosts',
  /** column name */
  new_TotalPlayers = 'new_TotalPlayers',
  /** column name */
  new_TotalSocialPush = 'new_TotalSocialPush',
  /** column name */
  new_ToxicPostsActive = 'new_ToxicPostsActive',
  /** column name */
  new_Type = 'new_Type',
  /** column name */
  new_UpdateRate = 'new_UpdateRate',
  /** column name */
  new_VideoLinks = 'new_VideoLinks',
  /** column name */
  new_Web20 = 'new_Web20',
  /** column name */
  new_WebsiteId = 'new_WebsiteId',
  /** column name */
  new_anytoolspass = 'new_anytoolspass',
  /** column name */
  new_anytoolssite = 'new_anytoolssite',
  /** column name */
  new_anytoolsuser = 'new_anytoolsuser',
  /** column name */
  new_db_website = 'new_db_website',
  /** column name */
  new_lastmonthtargetonsite = 'new_lastmonthtargetonsite',
  /** column name */
  new_lastmonthtargetoutreach = 'new_lastmonthtargetoutreach',
  /** column name */
  new_lastmonthtargetsocial = 'new_lastmonthtargetsocial',
  /** column name */
  new_linkbuildingprojectstarget = 'new_linkbuildingprojectstarget',
  /** column name */
  new_linkbuildingprojectstarget_Date = 'new_linkbuildingprojectstarget_Date',
  /** column name */
  new_linkbuildingprojectstarget_State = 'new_linkbuildingprojectstarget_State',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_wmtoolspass = 'new_wmtoolspass',
  /** column name */
  new_wmtoolssite = 'new_wmtoolssite',
  /** column name */
  new_wmtoolsuser = 'new_wmtoolsuser',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  project_crm_id = 'project_crm_id',
  /** column name */
  project_crm_lang = 'project_crm_lang',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  website_id = 'website_id'
}

/** select "v1_Project_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.Project" */
export enum v1_Project_select_column_v1_Project_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  has_integration = 'has_integration',
  /** column name */
  new_Countagain = 'new_Countagain',
  /** column name */
  new_MonthlyRankCheck = 'new_MonthlyRankCheck',
  /** column name */
  new_PenalizedbyGoogle = 'new_PenalizedbyGoogle'
}

/** select "v1_Project_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.Project" */
export enum v1_Project_select_column_v1_Project_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  has_integration = 'has_integration',
  /** column name */
  new_Countagain = 'new_Countagain',
  /** column name */
  new_MonthlyRankCheck = 'new_MonthlyRankCheck',
  /** column name */
  new_PenalizedbyGoogle = 'new_PenalizedbyGoogle'
}

/** input type for updating data in table "v1.Project" */
export type v1_Project_set_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  default_country_id?: InputMaybe<Scalars['Int']['input']>;
  has_integration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  new_45daysCompletedSocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_Brandterms?: InputMaybe<Scalars['String']['input']>;
  new_Countagain?: InputMaybe<Scalars['Boolean']['input']>;
  new_CustomerContact?: InputMaybe<Scalars['uuid']['input']>;
  new_CustomerOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DropDomainsLinks?: InputMaybe<Scalars['Int']['input']>;
  new_Followup?: InputMaybe<Scalars['timestamp']['input']>;
  new_GoogleManualSpamAction?: InputMaybe<Scalars['Int']['input']>;
  new_GooglePlus?: InputMaybe<Scalars['Int']['input']>;
  new_Importance?: InputMaybe<Scalars['Int']['input']>;
  new_InsiteContent?: InputMaybe<Scalars['Int']['input']>;
  new_LandingPages?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthIncome?: InputMaybe<Scalars['numeric']['input']>;
  new_LastMonthOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthSocial?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthTraffic?: InputMaybe<Scalars['Int']['input']>;
  new_LastOrdersCreation?: InputMaybe<Scalars['timestamp']['input']>;
  new_LastStatisticsCalculation?: InputMaybe<Scalars['timestamp']['input']>;
  new_LastUpdate?: InputMaybe<Scalars['timestamp']['input']>;
  new_Lastmonthplayers?: InputMaybe<Scalars['Int']['input']>;
  new_LinkingRealDomains?: InputMaybe<Scalars['Int']['input']>;
  new_LpmcampaingsId?: InputMaybe<Scalars['uuid']['input']>;
  new_ManualForumLinks?: InputMaybe<Scalars['Int']['input']>;
  new_MonthlyRankCheck?: InputMaybe<Scalars['Boolean']['input']>;
  new_MonthlyTarget?: InputMaybe<Scalars['Int']['input']>;
  new_Parentcampaign?: InputMaybe<Scalars['uuid']['input']>;
  new_PenalizedbyGoogle?: InputMaybe<Scalars['Boolean']['input']>;
  new_PendingPosting?: InputMaybe<Scalars['Int']['input']>;
  new_PostsPosted?: InputMaybe<Scalars['Int']['input']>;
  new_PostsPostedLastXdays?: InputMaybe<Scalars['Int']['input']>;
  new_PremiumBL?: InputMaybe<Scalars['Int']['input']>;
  new_ShellyPosts?: InputMaybe<Scalars['Int']['input']>;
  new_SocialLastMonth?: InputMaybe<Scalars['Int']['input']>;
  new_SocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_TargetOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_TargetOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_TargetSocial?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthSocial?: InputMaybe<Scalars['Int']['input']>;
  new_Topic?: InputMaybe<Scalars['uuid']['input']>;
  new_TotalAfiliateIncome?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalIndexedPosts?: InputMaybe<Scalars['Int']['input']>;
  new_TotalPlayers?: InputMaybe<Scalars['Int']['input']>;
  new_TotalSocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_ToxicPostsActive?: InputMaybe<Scalars['Int']['input']>;
  new_Type?: InputMaybe<Scalars['Int']['input']>;
  new_UpdateRate?: InputMaybe<Scalars['Int']['input']>;
  new_VideoLinks?: InputMaybe<Scalars['Int']['input']>;
  new_Web20?: InputMaybe<Scalars['Int']['input']>;
  new_WebsiteId?: InputMaybe<Scalars['uuid']['input']>;
  new_anytoolspass?: InputMaybe<Scalars['String']['input']>;
  new_anytoolssite?: InputMaybe<Scalars['String']['input']>;
  new_anytoolsuser?: InputMaybe<Scalars['String']['input']>;
  new_db_website?: InputMaybe<Scalars['String']['input']>;
  new_lastmonthtargetonsite?: InputMaybe<Scalars['Int']['input']>;
  new_lastmonthtargetoutreach?: InputMaybe<Scalars['Int']['input']>;
  new_lastmonthtargetsocial?: InputMaybe<Scalars['Int']['input']>;
  new_linkbuildingprojectstarget?: InputMaybe<Scalars['Int']['input']>;
  new_linkbuildingprojectstarget_Date?: InputMaybe<Scalars['timestamp']['input']>;
  new_linkbuildingprojectstarget_State?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_wmtoolspass?: InputMaybe<Scalars['String']['input']>;
  new_wmtoolssite?: InputMaybe<Scalars['String']['input']>;
  new_wmtoolsuser?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  project_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  project_crm_lang?: InputMaybe<Scalars['uuid']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Project_stddev_fields = {
  __typename?: 'v1_Project_stddev_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  default_country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Float']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Float']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Float']['output']>;
  new_GooglePlus?: Maybe<Scalars['Float']['output']>;
  new_Importance?: Maybe<Scalars['Float']['output']>;
  new_InsiteContent?: Maybe<Scalars['Float']['output']>;
  new_LandingPages?: Maybe<Scalars['Float']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Float']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Float']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Float']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PendingPosting?: Maybe<Scalars['Float']['output']>;
  new_PostsPosted?: Maybe<Scalars['Float']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Float']['output']>;
  new_PremiumBL?: Maybe<Scalars['Float']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Float']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Float']['output']>;
  new_SocialPush?: Maybe<Scalars['Float']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Float']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Float']['output']>;
  new_TargetSocial?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['Float']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Float']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Float']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Float']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Float']['output']>;
  new_Type?: Maybe<Scalars['Float']['output']>;
  new_UpdateRate?: Maybe<Scalars['Float']['output']>;
  new_VideoLinks?: Maybe<Scalars['Float']['output']>;
  new_Web20?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Project" */
export type v1_Project_stddev_order_by = {
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Project_stddev_pop_fields = {
  __typename?: 'v1_Project_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  default_country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Float']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Float']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Float']['output']>;
  new_GooglePlus?: Maybe<Scalars['Float']['output']>;
  new_Importance?: Maybe<Scalars['Float']['output']>;
  new_InsiteContent?: Maybe<Scalars['Float']['output']>;
  new_LandingPages?: Maybe<Scalars['Float']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Float']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Float']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Float']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PendingPosting?: Maybe<Scalars['Float']['output']>;
  new_PostsPosted?: Maybe<Scalars['Float']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Float']['output']>;
  new_PremiumBL?: Maybe<Scalars['Float']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Float']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Float']['output']>;
  new_SocialPush?: Maybe<Scalars['Float']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Float']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Float']['output']>;
  new_TargetSocial?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['Float']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Float']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Float']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Float']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Float']['output']>;
  new_Type?: Maybe<Scalars['Float']['output']>;
  new_UpdateRate?: Maybe<Scalars['Float']['output']>;
  new_VideoLinks?: Maybe<Scalars['Float']['output']>;
  new_Web20?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Project" */
export type v1_Project_stddev_pop_order_by = {
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Project_stddev_samp_fields = {
  __typename?: 'v1_Project_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  default_country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Float']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Float']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Float']['output']>;
  new_GooglePlus?: Maybe<Scalars['Float']['output']>;
  new_Importance?: Maybe<Scalars['Float']['output']>;
  new_InsiteContent?: Maybe<Scalars['Float']['output']>;
  new_LandingPages?: Maybe<Scalars['Float']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Float']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Float']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Float']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PendingPosting?: Maybe<Scalars['Float']['output']>;
  new_PostsPosted?: Maybe<Scalars['Float']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Float']['output']>;
  new_PremiumBL?: Maybe<Scalars['Float']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Float']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Float']['output']>;
  new_SocialPush?: Maybe<Scalars['Float']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Float']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Float']['output']>;
  new_TargetSocial?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['Float']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Float']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Float']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Float']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Float']['output']>;
  new_Type?: Maybe<Scalars['Float']['output']>;
  new_UpdateRate?: Maybe<Scalars['Float']['output']>;
  new_VideoLinks?: Maybe<Scalars['Float']['output']>;
  new_Web20?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Project" */
export type v1_Project_stddev_samp_order_by = {
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Project" */
export type v1_Project_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Project_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Project_stream_cursor_value_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  default_country_id?: InputMaybe<Scalars['Int']['input']>;
  has_integration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  new_45daysCompletedSocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_Brandterms?: InputMaybe<Scalars['String']['input']>;
  new_Countagain?: InputMaybe<Scalars['Boolean']['input']>;
  new_CustomerContact?: InputMaybe<Scalars['uuid']['input']>;
  new_CustomerOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DropDomainsLinks?: InputMaybe<Scalars['Int']['input']>;
  new_Followup?: InputMaybe<Scalars['timestamp']['input']>;
  new_GoogleManualSpamAction?: InputMaybe<Scalars['Int']['input']>;
  new_GooglePlus?: InputMaybe<Scalars['Int']['input']>;
  new_Importance?: InputMaybe<Scalars['Int']['input']>;
  new_InsiteContent?: InputMaybe<Scalars['Int']['input']>;
  new_LandingPages?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthIncome?: InputMaybe<Scalars['numeric']['input']>;
  new_LastMonthOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthSocial?: InputMaybe<Scalars['Int']['input']>;
  new_LastMonthTraffic?: InputMaybe<Scalars['Int']['input']>;
  new_LastOrdersCreation?: InputMaybe<Scalars['timestamp']['input']>;
  new_LastStatisticsCalculation?: InputMaybe<Scalars['timestamp']['input']>;
  new_LastUpdate?: InputMaybe<Scalars['timestamp']['input']>;
  new_Lastmonthplayers?: InputMaybe<Scalars['Int']['input']>;
  new_LinkingRealDomains?: InputMaybe<Scalars['Int']['input']>;
  new_LpmcampaingsId?: InputMaybe<Scalars['uuid']['input']>;
  new_ManualForumLinks?: InputMaybe<Scalars['Int']['input']>;
  new_MonthlyRankCheck?: InputMaybe<Scalars['Boolean']['input']>;
  new_MonthlyTarget?: InputMaybe<Scalars['Int']['input']>;
  new_Parentcampaign?: InputMaybe<Scalars['uuid']['input']>;
  new_PenalizedbyGoogle?: InputMaybe<Scalars['Boolean']['input']>;
  new_PendingPosting?: InputMaybe<Scalars['Int']['input']>;
  new_PostsPosted?: InputMaybe<Scalars['Int']['input']>;
  new_PostsPostedLastXdays?: InputMaybe<Scalars['Int']['input']>;
  new_PremiumBL?: InputMaybe<Scalars['Int']['input']>;
  new_ShellyPosts?: InputMaybe<Scalars['Int']['input']>;
  new_SocialLastMonth?: InputMaybe<Scalars['Int']['input']>;
  new_SocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_TargetOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_TargetOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_TargetSocial?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthOnsite?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthOutreach?: InputMaybe<Scalars['Int']['input']>;
  new_ThisMonthSocial?: InputMaybe<Scalars['Int']['input']>;
  new_Topic?: InputMaybe<Scalars['uuid']['input']>;
  new_TotalAfiliateIncome?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalIndexedPosts?: InputMaybe<Scalars['Int']['input']>;
  new_TotalPlayers?: InputMaybe<Scalars['Int']['input']>;
  new_TotalSocialPush?: InputMaybe<Scalars['Int']['input']>;
  new_ToxicPostsActive?: InputMaybe<Scalars['Int']['input']>;
  new_Type?: InputMaybe<Scalars['Int']['input']>;
  new_UpdateRate?: InputMaybe<Scalars['Int']['input']>;
  new_VideoLinks?: InputMaybe<Scalars['Int']['input']>;
  new_Web20?: InputMaybe<Scalars['Int']['input']>;
  new_WebsiteId?: InputMaybe<Scalars['uuid']['input']>;
  new_anytoolspass?: InputMaybe<Scalars['String']['input']>;
  new_anytoolssite?: InputMaybe<Scalars['String']['input']>;
  new_anytoolsuser?: InputMaybe<Scalars['String']['input']>;
  new_db_website?: InputMaybe<Scalars['String']['input']>;
  new_lastmonthtargetonsite?: InputMaybe<Scalars['Int']['input']>;
  new_lastmonthtargetoutreach?: InputMaybe<Scalars['Int']['input']>;
  new_lastmonthtargetsocial?: InputMaybe<Scalars['Int']['input']>;
  new_linkbuildingprojectstarget?: InputMaybe<Scalars['Int']['input']>;
  new_linkbuildingprojectstarget_Date?: InputMaybe<Scalars['timestamp']['input']>;
  new_linkbuildingprojectstarget_State?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_wmtoolspass?: InputMaybe<Scalars['String']['input']>;
  new_wmtoolssite?: InputMaybe<Scalars['String']['input']>;
  new_wmtoolsuser?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  project_crm_id?: InputMaybe<Scalars['uuid']['input']>;
  project_crm_lang?: InputMaybe<Scalars['uuid']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Project_sum_fields = {
  __typename?: 'v1_Project_sum_fields';
  category_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  default_country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Int']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Int']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Int']['output']>;
  new_GooglePlus?: Maybe<Scalars['Int']['output']>;
  new_Importance?: Maybe<Scalars['Int']['output']>;
  new_InsiteContent?: Maybe<Scalars['Int']['output']>;
  new_LandingPages?: Maybe<Scalars['Int']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['numeric']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Int']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Int']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Int']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Int']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Int']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Int']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Int']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Int']['output']>;
  new_PendingPosting?: Maybe<Scalars['Int']['output']>;
  new_PostsPosted?: Maybe<Scalars['Int']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Int']['output']>;
  new_PremiumBL?: Maybe<Scalars['Int']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Int']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Int']['output']>;
  new_SocialPush?: Maybe<Scalars['Int']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Int']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Int']['output']>;
  new_TargetSocial?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Int']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Int']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['numeric']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Int']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Int']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Int']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Int']['output']>;
  new_Type?: Maybe<Scalars['Int']['output']>;
  new_UpdateRate?: Maybe<Scalars['Int']['output']>;
  new_VideoLinks?: Maybe<Scalars['Int']['output']>;
  new_Web20?: Maybe<Scalars['Int']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Int']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Int']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Int']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Int']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Project" */
export type v1_Project_sum_order_by = {
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Project" */
export enum v1_Project_update_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  category_id = 'category_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  default_country_id = 'default_country_id',
  /** column name */
  has_integration = 'has_integration',
  /** column name */
  id = 'id',
  /** column name */
  language_id = 'language_id',
  /** column name */
  new_45daysCompletedSocialPush = 'new_45daysCompletedSocialPush',
  /** column name */
  new_Brandterms = 'new_Brandterms',
  /** column name */
  new_Countagain = 'new_Countagain',
  /** column name */
  new_CustomerContact = 'new_CustomerContact',
  /** column name */
  new_CustomerOrder = 'new_CustomerOrder',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_DropDomainsLinks = 'new_DropDomainsLinks',
  /** column name */
  new_Followup = 'new_Followup',
  /** column name */
  new_GoogleManualSpamAction = 'new_GoogleManualSpamAction',
  /** column name */
  new_GooglePlus = 'new_GooglePlus',
  /** column name */
  new_Importance = 'new_Importance',
  /** column name */
  new_InsiteContent = 'new_InsiteContent',
  /** column name */
  new_LandingPages = 'new_LandingPages',
  /** column name */
  new_LastMonthIncome = 'new_LastMonthIncome',
  /** column name */
  new_LastMonthOnsite = 'new_LastMonthOnsite',
  /** column name */
  new_LastMonthOutreach = 'new_LastMonthOutreach',
  /** column name */
  new_LastMonthSocial = 'new_LastMonthSocial',
  /** column name */
  new_LastMonthTraffic = 'new_LastMonthTraffic',
  /** column name */
  new_LastOrdersCreation = 'new_LastOrdersCreation',
  /** column name */
  new_LastStatisticsCalculation = 'new_LastStatisticsCalculation',
  /** column name */
  new_LastUpdate = 'new_LastUpdate',
  /** column name */
  new_Lastmonthplayers = 'new_Lastmonthplayers',
  /** column name */
  new_LinkingRealDomains = 'new_LinkingRealDomains',
  /** column name */
  new_LpmcampaingsId = 'new_LpmcampaingsId',
  /** column name */
  new_ManualForumLinks = 'new_ManualForumLinks',
  /** column name */
  new_MonthlyRankCheck = 'new_MonthlyRankCheck',
  /** column name */
  new_MonthlyTarget = 'new_MonthlyTarget',
  /** column name */
  new_Parentcampaign = 'new_Parentcampaign',
  /** column name */
  new_PenalizedbyGoogle = 'new_PenalizedbyGoogle',
  /** column name */
  new_PendingPosting = 'new_PendingPosting',
  /** column name */
  new_PostsPosted = 'new_PostsPosted',
  /** column name */
  new_PostsPostedLastXdays = 'new_PostsPostedLastXdays',
  /** column name */
  new_PremiumBL = 'new_PremiumBL',
  /** column name */
  new_ShellyPosts = 'new_ShellyPosts',
  /** column name */
  new_SocialLastMonth = 'new_SocialLastMonth',
  /** column name */
  new_SocialPush = 'new_SocialPush',
  /** column name */
  new_TargetOnsite = 'new_TargetOnsite',
  /** column name */
  new_TargetOutreach = 'new_TargetOutreach',
  /** column name */
  new_TargetSocial = 'new_TargetSocial',
  /** column name */
  new_ThisMonthOnsite = 'new_ThisMonthOnsite',
  /** column name */
  new_ThisMonthOutreach = 'new_ThisMonthOutreach',
  /** column name */
  new_ThisMonthSocial = 'new_ThisMonthSocial',
  /** column name */
  new_Topic = 'new_Topic',
  /** column name */
  new_TotalAfiliateIncome = 'new_TotalAfiliateIncome',
  /** column name */
  new_TotalIndexedPosts = 'new_TotalIndexedPosts',
  /** column name */
  new_TotalPlayers = 'new_TotalPlayers',
  /** column name */
  new_TotalSocialPush = 'new_TotalSocialPush',
  /** column name */
  new_ToxicPostsActive = 'new_ToxicPostsActive',
  /** column name */
  new_Type = 'new_Type',
  /** column name */
  new_UpdateRate = 'new_UpdateRate',
  /** column name */
  new_VideoLinks = 'new_VideoLinks',
  /** column name */
  new_Web20 = 'new_Web20',
  /** column name */
  new_WebsiteId = 'new_WebsiteId',
  /** column name */
  new_anytoolspass = 'new_anytoolspass',
  /** column name */
  new_anytoolssite = 'new_anytoolssite',
  /** column name */
  new_anytoolsuser = 'new_anytoolsuser',
  /** column name */
  new_db_website = 'new_db_website',
  /** column name */
  new_lastmonthtargetonsite = 'new_lastmonthtargetonsite',
  /** column name */
  new_lastmonthtargetoutreach = 'new_lastmonthtargetoutreach',
  /** column name */
  new_lastmonthtargetsocial = 'new_lastmonthtargetsocial',
  /** column name */
  new_linkbuildingprojectstarget = 'new_linkbuildingprojectstarget',
  /** column name */
  new_linkbuildingprojectstarget_Date = 'new_linkbuildingprojectstarget_Date',
  /** column name */
  new_linkbuildingprojectstarget_State = 'new_linkbuildingprojectstarget_State',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_wmtoolspass = 'new_wmtoolspass',
  /** column name */
  new_wmtoolssite = 'new_wmtoolssite',
  /** column name */
  new_wmtoolsuser = 'new_wmtoolsuser',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  project_crm_id = 'project_crm_id',
  /** column name */
  project_crm_lang = 'project_crm_lang',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  website_id = 'website_id'
}

export type v1_Project_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Project_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Project_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Project_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Project_var_pop_fields = {
  __typename?: 'v1_Project_var_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  default_country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Float']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Float']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Float']['output']>;
  new_GooglePlus?: Maybe<Scalars['Float']['output']>;
  new_Importance?: Maybe<Scalars['Float']['output']>;
  new_InsiteContent?: Maybe<Scalars['Float']['output']>;
  new_LandingPages?: Maybe<Scalars['Float']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Float']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Float']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Float']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PendingPosting?: Maybe<Scalars['Float']['output']>;
  new_PostsPosted?: Maybe<Scalars['Float']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Float']['output']>;
  new_PremiumBL?: Maybe<Scalars['Float']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Float']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Float']['output']>;
  new_SocialPush?: Maybe<Scalars['Float']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Float']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Float']['output']>;
  new_TargetSocial?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['Float']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Float']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Float']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Float']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Float']['output']>;
  new_Type?: Maybe<Scalars['Float']['output']>;
  new_UpdateRate?: Maybe<Scalars['Float']['output']>;
  new_VideoLinks?: Maybe<Scalars['Float']['output']>;
  new_Web20?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Project" */
export type v1_Project_var_pop_order_by = {
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Project_var_samp_fields = {
  __typename?: 'v1_Project_var_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  default_country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Float']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Float']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Float']['output']>;
  new_GooglePlus?: Maybe<Scalars['Float']['output']>;
  new_Importance?: Maybe<Scalars['Float']['output']>;
  new_InsiteContent?: Maybe<Scalars['Float']['output']>;
  new_LandingPages?: Maybe<Scalars['Float']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Float']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Float']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Float']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PendingPosting?: Maybe<Scalars['Float']['output']>;
  new_PostsPosted?: Maybe<Scalars['Float']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Float']['output']>;
  new_PremiumBL?: Maybe<Scalars['Float']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Float']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Float']['output']>;
  new_SocialPush?: Maybe<Scalars['Float']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Float']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Float']['output']>;
  new_TargetSocial?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['Float']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Float']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Float']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Float']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Float']['output']>;
  new_Type?: Maybe<Scalars['Float']['output']>;
  new_UpdateRate?: Maybe<Scalars['Float']['output']>;
  new_VideoLinks?: Maybe<Scalars['Float']['output']>;
  new_Web20?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Project" */
export type v1_Project_var_samp_order_by = {
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Project_variance_fields = {
  __typename?: 'v1_Project_variance_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  default_country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  new_45daysCompletedSocialPush?: Maybe<Scalars['Float']['output']>;
  new_DropDomainsLinks?: Maybe<Scalars['Float']['output']>;
  new_GoogleManualSpamAction?: Maybe<Scalars['Float']['output']>;
  new_GooglePlus?: Maybe<Scalars['Float']['output']>;
  new_Importance?: Maybe<Scalars['Float']['output']>;
  new_InsiteContent?: Maybe<Scalars['Float']['output']>;
  new_LandingPages?: Maybe<Scalars['Float']['output']>;
  new_LastMonthIncome?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_LastMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_LastMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_LastMonthTraffic?: Maybe<Scalars['Float']['output']>;
  new_Lastmonthplayers?: Maybe<Scalars['Float']['output']>;
  new_LinkingRealDomains?: Maybe<Scalars['Float']['output']>;
  new_ManualForumLinks?: Maybe<Scalars['Float']['output']>;
  new_MonthlyTarget?: Maybe<Scalars['Float']['output']>;
  new_PendingPosting?: Maybe<Scalars['Float']['output']>;
  new_PostsPosted?: Maybe<Scalars['Float']['output']>;
  new_PostsPostedLastXdays?: Maybe<Scalars['Float']['output']>;
  new_PremiumBL?: Maybe<Scalars['Float']['output']>;
  new_ShellyPosts?: Maybe<Scalars['Float']['output']>;
  new_SocialLastMonth?: Maybe<Scalars['Float']['output']>;
  new_SocialPush?: Maybe<Scalars['Float']['output']>;
  new_TargetOnsite?: Maybe<Scalars['Float']['output']>;
  new_TargetOutreach?: Maybe<Scalars['Float']['output']>;
  new_TargetSocial?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOnsite?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthOutreach?: Maybe<Scalars['Float']['output']>;
  new_ThisMonthSocial?: Maybe<Scalars['Float']['output']>;
  new_TotalAfiliateIncome?: Maybe<Scalars['Float']['output']>;
  new_TotalIndexedPosts?: Maybe<Scalars['Float']['output']>;
  new_TotalPlayers?: Maybe<Scalars['Float']['output']>;
  new_TotalSocialPush?: Maybe<Scalars['Float']['output']>;
  new_ToxicPostsActive?: Maybe<Scalars['Float']['output']>;
  new_Type?: Maybe<Scalars['Float']['output']>;
  new_UpdateRate?: Maybe<Scalars['Float']['output']>;
  new_VideoLinks?: Maybe<Scalars['Float']['output']>;
  new_Web20?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetonsite?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetoutreach?: Maybe<Scalars['Float']['output']>;
  new_lastmonthtargetsocial?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget?: Maybe<Scalars['Float']['output']>;
  new_linkbuildingprojectstarget_State?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Project" */
export type v1_Project_variance_order_by = {
  category_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  default_country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  new_45daysCompletedSocialPush?: InputMaybe<order_by>;
  new_DropDomainsLinks?: InputMaybe<order_by>;
  new_GoogleManualSpamAction?: InputMaybe<order_by>;
  new_GooglePlus?: InputMaybe<order_by>;
  new_Importance?: InputMaybe<order_by>;
  new_InsiteContent?: InputMaybe<order_by>;
  new_LandingPages?: InputMaybe<order_by>;
  new_LastMonthIncome?: InputMaybe<order_by>;
  new_LastMonthOnsite?: InputMaybe<order_by>;
  new_LastMonthOutreach?: InputMaybe<order_by>;
  new_LastMonthSocial?: InputMaybe<order_by>;
  new_LastMonthTraffic?: InputMaybe<order_by>;
  new_Lastmonthplayers?: InputMaybe<order_by>;
  new_LinkingRealDomains?: InputMaybe<order_by>;
  new_ManualForumLinks?: InputMaybe<order_by>;
  new_MonthlyTarget?: InputMaybe<order_by>;
  new_PendingPosting?: InputMaybe<order_by>;
  new_PostsPosted?: InputMaybe<order_by>;
  new_PostsPostedLastXdays?: InputMaybe<order_by>;
  new_PremiumBL?: InputMaybe<order_by>;
  new_ShellyPosts?: InputMaybe<order_by>;
  new_SocialLastMonth?: InputMaybe<order_by>;
  new_SocialPush?: InputMaybe<order_by>;
  new_TargetOnsite?: InputMaybe<order_by>;
  new_TargetOutreach?: InputMaybe<order_by>;
  new_TargetSocial?: InputMaybe<order_by>;
  new_ThisMonthOnsite?: InputMaybe<order_by>;
  new_ThisMonthOutreach?: InputMaybe<order_by>;
  new_ThisMonthSocial?: InputMaybe<order_by>;
  new_TotalAfiliateIncome?: InputMaybe<order_by>;
  new_TotalIndexedPosts?: InputMaybe<order_by>;
  new_TotalPlayers?: InputMaybe<order_by>;
  new_TotalSocialPush?: InputMaybe<order_by>;
  new_ToxicPostsActive?: InputMaybe<order_by>;
  new_Type?: InputMaybe<order_by>;
  new_UpdateRate?: InputMaybe<order_by>;
  new_VideoLinks?: InputMaybe<order_by>;
  new_Web20?: InputMaybe<order_by>;
  new_lastmonthtargetonsite?: InputMaybe<order_by>;
  new_lastmonthtargetoutreach?: InputMaybe<order_by>;
  new_lastmonthtargetsocial?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget?: InputMaybe<order_by>;
  new_linkbuildingprojectstarget_State?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus = {
  __typename?: 'v1_PublisherPostStatus';
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  Status: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};


/** columns and relationships of "v1.PublisherPostStatus" */
export type v1_PublisherPostStatusPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.PublisherPostStatus" */
export type v1_PublisherPostStatusPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus_aggregate = {
  __typename?: 'v1_PublisherPostStatus_aggregate';
  aggregate?: Maybe<v1_PublisherPostStatus_aggregate_fields>;
  nodes: Array<v1_PublisherPostStatus>;
};

/** aggregate fields of "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus_aggregate_fields = {
  __typename?: 'v1_PublisherPostStatus_aggregate_fields';
  avg?: Maybe<v1_PublisherPostStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PublisherPostStatus_max_fields>;
  min?: Maybe<v1_PublisherPostStatus_min_fields>;
  stddev?: Maybe<v1_PublisherPostStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_PublisherPostStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PublisherPostStatus_stddev_samp_fields>;
  sum?: Maybe<v1_PublisherPostStatus_sum_fields>;
  var_pop?: Maybe<v1_PublisherPostStatus_var_pop_fields>;
  var_samp?: Maybe<v1_PublisherPostStatus_var_samp_fields>;
  variance?: Maybe<v1_PublisherPostStatus_variance_fields>;
};


/** aggregate fields of "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PublisherPostStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PublisherPostStatus_avg_fields = {
  __typename?: 'v1_PublisherPostStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PublisherPostStatus". All fields are combined with a logical 'AND'. */
export type v1_PublisherPostStatus_bool_exp = {
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  Status?: InputMaybe<String_comparison_exp>;
  _and?: InputMaybe<Array<v1_PublisherPostStatus_bool_exp>>;
  _not?: InputMaybe<v1_PublisherPostStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_PublisherPostStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PublisherPostStatus" */
export enum v1_PublisherPostStatus_constraint {
  /** unique or primary key constraint on columns "Status" */
  PublisherStatus_Status_key = 'PublisherStatus_Status_key',
  /** unique or primary key constraint on columns "id" */
  PublisherStatus_pkey = 'PublisherStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus_insert_input = {
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  Status?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_PublisherPostStatus_max_fields = {
  __typename?: 'v1_PublisherPostStatus_max_fields';
  Status?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_PublisherPostStatus_min_fields = {
  __typename?: 'v1_PublisherPostStatus_min_fields';
  Status?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus_mutation_response = {
  __typename?: 'v1_PublisherPostStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PublisherPostStatus>;
};

/** input type for inserting object relation for remote table "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus_obj_rel_insert_input = {
  data: v1_PublisherPostStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PublisherPostStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus_on_conflict = {
  constraint: v1_PublisherPostStatus_constraint;
  update_columns?: Array<v1_PublisherPostStatus_update_column>;
  where?: InputMaybe<v1_PublisherPostStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.PublisherPostStatus". */
export type v1_PublisherPostStatus_order_by = {
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  Status?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PublisherPostStatus */
export type v1_PublisherPostStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PublisherPostStatus" */
export enum v1_PublisherPostStatus_select_column {
  /** column name */
  Status = 'Status',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.PublisherPostStatus" */
export type v1_PublisherPostStatus_set_input = {
  Status?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_PublisherPostStatus_stddev_fields = {
  __typename?: 'v1_PublisherPostStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PublisherPostStatus_stddev_pop_fields = {
  __typename?: 'v1_PublisherPostStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PublisherPostStatus_stddev_samp_fields = {
  __typename?: 'v1_PublisherPostStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PublisherPostStatus" */
export type v1_PublisherPostStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PublisherPostStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PublisherPostStatus_stream_cursor_value_input = {
  Status?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_PublisherPostStatus_sum_fields = {
  __typename?: 'v1_PublisherPostStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PublisherPostStatus" */
export enum v1_PublisherPostStatus_update_column {
  /** column name */
  Status = 'Status',
  /** column name */
  id = 'id'
}

export type v1_PublisherPostStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PublisherPostStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PublisherPostStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PublisherPostStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PublisherPostStatus_var_pop_fields = {
  __typename?: 'v1_PublisherPostStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PublisherPostStatus_var_samp_fields = {
  __typename?: 'v1_PublisherPostStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PublisherPostStatus_variance_fields = {
  __typename?: 'v1_PublisherPostStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus = {
  __typename?: 'v1_PurchaseOrderStatus';
  /** An array relationship */
  PurchaseOrderStatus: Array<v1_new_POBase>;
  /** An aggregate relationship */
  PurchaseOrderStatus_aggregate: v1_new_POBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatusPurchaseOrderStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** columns and relationships of "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatusPurchaseOrderStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};

/** aggregated selection of "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_aggregate = {
  __typename?: 'v1_PurchaseOrderStatus_aggregate';
  aggregate?: Maybe<v1_PurchaseOrderStatus_aggregate_fields>;
  nodes: Array<v1_PurchaseOrderStatus>;
};

/** aggregate fields of "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_aggregate_fields = {
  __typename?: 'v1_PurchaseOrderStatus_aggregate_fields';
  avg?: Maybe<v1_PurchaseOrderStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_PurchaseOrderStatus_max_fields>;
  min?: Maybe<v1_PurchaseOrderStatus_min_fields>;
  stddev?: Maybe<v1_PurchaseOrderStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_PurchaseOrderStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_PurchaseOrderStatus_stddev_samp_fields>;
  sum?: Maybe<v1_PurchaseOrderStatus_sum_fields>;
  var_pop?: Maybe<v1_PurchaseOrderStatus_var_pop_fields>;
  var_samp?: Maybe<v1_PurchaseOrderStatus_var_samp_fields>;
  variance?: Maybe<v1_PurchaseOrderStatus_variance_fields>;
};


/** aggregate fields of "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_PurchaseOrderStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_PurchaseOrderStatus_avg_fields = {
  __typename?: 'v1_PurchaseOrderStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.PurchaseOrderStatus". All fields are combined with a logical 'AND'. */
export type v1_PurchaseOrderStatus_bool_exp = {
  PurchaseOrderStatus?: InputMaybe<v1_new_POBase_bool_exp>;
  PurchaseOrderStatus_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_PurchaseOrderStatus_bool_exp>>;
  _not?: InputMaybe<v1_PurchaseOrderStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_PurchaseOrderStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.PurchaseOrderStatus" */
export enum v1_PurchaseOrderStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  PurchaseOrderStatus_pkey = 'PurchaseOrderStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_insert_input = {
  PurchaseOrderStatus?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_PurchaseOrderStatus_max_fields = {
  __typename?: 'v1_PurchaseOrderStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_PurchaseOrderStatus_min_fields = {
  __typename?: 'v1_PurchaseOrderStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_mutation_response = {
  __typename?: 'v1_PurchaseOrderStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_PurchaseOrderStatus>;
};

/** input type for inserting object relation for remote table "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_obj_rel_insert_input = {
  data: v1_PurchaseOrderStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_PurchaseOrderStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_on_conflict = {
  constraint: v1_PurchaseOrderStatus_constraint;
  update_columns?: Array<v1_PurchaseOrderStatus_update_column>;
  where?: InputMaybe<v1_PurchaseOrderStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.PurchaseOrderStatus". */
export type v1_PurchaseOrderStatus_order_by = {
  PurchaseOrderStatus_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.PurchaseOrderStatus */
export type v1_PurchaseOrderStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.PurchaseOrderStatus" */
export enum v1_PurchaseOrderStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_PurchaseOrderStatus_stddev_fields = {
  __typename?: 'v1_PurchaseOrderStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_PurchaseOrderStatus_stddev_pop_fields = {
  __typename?: 'v1_PurchaseOrderStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_PurchaseOrderStatus_stddev_samp_fields = {
  __typename?: 'v1_PurchaseOrderStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_PurchaseOrderStatus" */
export type v1_PurchaseOrderStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_PurchaseOrderStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_PurchaseOrderStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_PurchaseOrderStatus_sum_fields = {
  __typename?: 'v1_PurchaseOrderStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.PurchaseOrderStatus" */
export enum v1_PurchaseOrderStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_PurchaseOrderStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_PurchaseOrderStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_PurchaseOrderStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_PurchaseOrderStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_PurchaseOrderStatus_var_pop_fields = {
  __typename?: 'v1_PurchaseOrderStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_PurchaseOrderStatus_var_samp_fields = {
  __typename?: 'v1_PurchaseOrderStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_PurchaseOrderStatus_variance_fields = {
  __typename?: 'v1_PurchaseOrderStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.ReceiptStatus" */
export type v1_ReceiptStatus = {
  __typename?: 'v1_ReceiptStatus';
  /** An array relationship */
  ReceiptStatus: Array<v1_new_receiptBase>;
  /** An aggregate relationship */
  ReceiptStatus_aggregate: v1_new_receiptBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.ReceiptStatus" */
export type v1_ReceiptStatusReceiptStatusArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** columns and relationships of "v1.ReceiptStatus" */
export type v1_ReceiptStatusReceiptStatus_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};

/** aggregated selection of "v1.ReceiptStatus" */
export type v1_ReceiptStatus_aggregate = {
  __typename?: 'v1_ReceiptStatus_aggregate';
  aggregate?: Maybe<v1_ReceiptStatus_aggregate_fields>;
  nodes: Array<v1_ReceiptStatus>;
};

/** aggregate fields of "v1.ReceiptStatus" */
export type v1_ReceiptStatus_aggregate_fields = {
  __typename?: 'v1_ReceiptStatus_aggregate_fields';
  avg?: Maybe<v1_ReceiptStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ReceiptStatus_max_fields>;
  min?: Maybe<v1_ReceiptStatus_min_fields>;
  stddev?: Maybe<v1_ReceiptStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_ReceiptStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ReceiptStatus_stddev_samp_fields>;
  sum?: Maybe<v1_ReceiptStatus_sum_fields>;
  var_pop?: Maybe<v1_ReceiptStatus_var_pop_fields>;
  var_samp?: Maybe<v1_ReceiptStatus_var_samp_fields>;
  variance?: Maybe<v1_ReceiptStatus_variance_fields>;
};


/** aggregate fields of "v1.ReceiptStatus" */
export type v1_ReceiptStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ReceiptStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ReceiptStatus_avg_fields = {
  __typename?: 'v1_ReceiptStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ReceiptStatus". All fields are combined with a logical 'AND'. */
export type v1_ReceiptStatus_bool_exp = {
  ReceiptStatus?: InputMaybe<v1_new_receiptBase_bool_exp>;
  ReceiptStatus_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_ReceiptStatus_bool_exp>>;
  _not?: InputMaybe<v1_ReceiptStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_ReceiptStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ReceiptStatus" */
export enum v1_ReceiptStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  ReceiptStatus_pkey = 'ReceiptStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.ReceiptStatus" */
export type v1_ReceiptStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ReceiptStatus" */
export type v1_ReceiptStatus_insert_input = {
  ReceiptStatus?: InputMaybe<v1_new_receiptBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_ReceiptStatus_max_fields = {
  __typename?: 'v1_ReceiptStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_ReceiptStatus_min_fields = {
  __typename?: 'v1_ReceiptStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.ReceiptStatus" */
export type v1_ReceiptStatus_mutation_response = {
  __typename?: 'v1_ReceiptStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ReceiptStatus>;
};

/** input type for inserting object relation for remote table "v1.ReceiptStatus" */
export type v1_ReceiptStatus_obj_rel_insert_input = {
  data: v1_ReceiptStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ReceiptStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.ReceiptStatus" */
export type v1_ReceiptStatus_on_conflict = {
  constraint: v1_ReceiptStatus_constraint;
  update_columns?: Array<v1_ReceiptStatus_update_column>;
  where?: InputMaybe<v1_ReceiptStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.ReceiptStatus". */
export type v1_ReceiptStatus_order_by = {
  ReceiptStatus_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ReceiptStatus */
export type v1_ReceiptStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ReceiptStatus" */
export enum v1_ReceiptStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.ReceiptStatus" */
export type v1_ReceiptStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_ReceiptStatus_stddev_fields = {
  __typename?: 'v1_ReceiptStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ReceiptStatus_stddev_pop_fields = {
  __typename?: 'v1_ReceiptStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ReceiptStatus_stddev_samp_fields = {
  __typename?: 'v1_ReceiptStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ReceiptStatus" */
export type v1_ReceiptStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ReceiptStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ReceiptStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ReceiptStatus_sum_fields = {
  __typename?: 'v1_ReceiptStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ReceiptStatus" */
export enum v1_ReceiptStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_ReceiptStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ReceiptStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ReceiptStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ReceiptStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ReceiptStatus_var_pop_fields = {
  __typename?: 'v1_ReceiptStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ReceiptStatus_var_samp_fields = {
  __typename?: 'v1_ReceiptStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ReceiptStatus_variance_fields = {
  __typename?: 'v1_ReceiptStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus = {
  __typename?: 'v1_RecurringPaymentStatus';
  /** An array relationship */
  RecurringPayments: Array<v1_RecurringPayments>;
  /** An aggregate relationship */
  RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatusRecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** columns and relationships of "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatusRecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};

/** aggregated selection of "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_aggregate = {
  __typename?: 'v1_RecurringPaymentStatus_aggregate';
  aggregate?: Maybe<v1_RecurringPaymentStatus_aggregate_fields>;
  nodes: Array<v1_RecurringPaymentStatus>;
};

/** aggregate fields of "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_aggregate_fields = {
  __typename?: 'v1_RecurringPaymentStatus_aggregate_fields';
  avg?: Maybe<v1_RecurringPaymentStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_RecurringPaymentStatus_max_fields>;
  min?: Maybe<v1_RecurringPaymentStatus_min_fields>;
  stddev?: Maybe<v1_RecurringPaymentStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_RecurringPaymentStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_RecurringPaymentStatus_stddev_samp_fields>;
  sum?: Maybe<v1_RecurringPaymentStatus_sum_fields>;
  var_pop?: Maybe<v1_RecurringPaymentStatus_var_pop_fields>;
  var_samp?: Maybe<v1_RecurringPaymentStatus_var_samp_fields>;
  variance?: Maybe<v1_RecurringPaymentStatus_variance_fields>;
};


/** aggregate fields of "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_RecurringPaymentStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_RecurringPaymentStatus_avg_fields = {
  __typename?: 'v1_RecurringPaymentStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.RecurringPaymentStatus". All fields are combined with a logical 'AND'. */
export type v1_RecurringPaymentStatus_bool_exp = {
  RecurringPayments?: InputMaybe<v1_RecurringPayments_bool_exp>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_RecurringPaymentStatus_bool_exp>>;
  _not?: InputMaybe<v1_RecurringPaymentStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_RecurringPaymentStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.RecurringPaymentStatus" */
export enum v1_RecurringPaymentStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  RecurringPaymentStatus_pkey = 'RecurringPaymentStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_insert_input = {
  RecurringPayments?: InputMaybe<v1_RecurringPayments_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_RecurringPaymentStatus_max_fields = {
  __typename?: 'v1_RecurringPaymentStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_RecurringPaymentStatus_min_fields = {
  __typename?: 'v1_RecurringPaymentStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_mutation_response = {
  __typename?: 'v1_RecurringPaymentStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_RecurringPaymentStatus>;
};

/** input type for inserting object relation for remote table "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_obj_rel_insert_input = {
  data: v1_RecurringPaymentStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_RecurringPaymentStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_on_conflict = {
  constraint: v1_RecurringPaymentStatus_constraint;
  update_columns?: Array<v1_RecurringPaymentStatus_update_column>;
  where?: InputMaybe<v1_RecurringPaymentStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.RecurringPaymentStatus". */
export type v1_RecurringPaymentStatus_order_by = {
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.RecurringPaymentStatus */
export type v1_RecurringPaymentStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.RecurringPaymentStatus" */
export enum v1_RecurringPaymentStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_RecurringPaymentStatus_stddev_fields = {
  __typename?: 'v1_RecurringPaymentStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_RecurringPaymentStatus_stddev_pop_fields = {
  __typename?: 'v1_RecurringPaymentStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_RecurringPaymentStatus_stddev_samp_fields = {
  __typename?: 'v1_RecurringPaymentStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_RecurringPaymentStatus" */
export type v1_RecurringPaymentStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_RecurringPaymentStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_RecurringPaymentStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_RecurringPaymentStatus_sum_fields = {
  __typename?: 'v1_RecurringPaymentStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.RecurringPaymentStatus" */
export enum v1_RecurringPaymentStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_RecurringPaymentStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_RecurringPaymentStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_RecurringPaymentStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_RecurringPaymentStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_RecurringPaymentStatus_var_pop_fields = {
  __typename?: 'v1_RecurringPaymentStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_RecurringPaymentStatus_var_samp_fields = {
  __typename?: 'v1_RecurringPaymentStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_RecurringPaymentStatus_variance_fields = {
  __typename?: 'v1_RecurringPaymentStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.RecurringPayments" */
export type v1_RecurringPayments = {
  __typename?: 'v1_RecurringPayments';
  /** An object relationship */
  Company?: Maybe<v1_AccountBase>;
  /** An object relationship */
  Credential?: Maybe<v1_Credentials>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  PaymentCategory?: Maybe<v1_ServiceTypes>;
  /** An object relationship */
  PaymentFrequency?: Maybe<v1_PaymentFrequency>;
  /** An object relationship */
  PaymentMethod?: Maybe<v1_new_paymentmethodBase>;
  /** An array relationship */
  PurchaseOrder: Array<v1_new_POBase>;
  /** An aggregate relationship */
  PurchaseOrder_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  RecurringPayments_Roles: Array<v1_RecurringPayments_Roles>;
  /** An aggregate relationship */
  RecurringPayments_Roles_aggregate: v1_RecurringPayments_Roles_aggregate;
  /** An object relationship */
  Status?: Maybe<v1_RecurringPaymentStatus>;
  amount?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['date']['output']>;
  credential_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_on?: Maybe<Scalars['date']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Int']['output']>;
  payment_address?: Maybe<Scalars['String']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.RecurringPayments" */
export type v1_RecurringPaymentsPurchaseOrderArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** columns and relationships of "v1.RecurringPayments" */
export type v1_RecurringPaymentsPurchaseOrder_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** columns and relationships of "v1.RecurringPayments" */
export type v1_RecurringPaymentsRecurringPayments_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_Roles_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};


/** columns and relationships of "v1.RecurringPayments" */
export type v1_RecurringPaymentsRecurringPayments_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_Roles_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};

/** columns and relationships of "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles = {
  __typename?: 'v1_RecurringPayments_Roles';
  /** An object relationship */
  RecurringPayment?: Maybe<v1_RecurringPayments>;
  /** An object relationship */
  Role?: Maybe<v1_Roles>;
  id: Scalars['Int']['output'];
  recurringPayment_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_aggregate = {
  __typename?: 'v1_RecurringPayments_Roles_aggregate';
  aggregate?: Maybe<v1_RecurringPayments_Roles_aggregate_fields>;
  nodes: Array<v1_RecurringPayments_Roles>;
};

export type v1_RecurringPayments_Roles_aggregate_bool_exp = {
  count?: InputMaybe<v1_RecurringPayments_Roles_aggregate_bool_exp_count>;
};

export type v1_RecurringPayments_Roles_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_aggregate_fields = {
  __typename?: 'v1_RecurringPayments_Roles_aggregate_fields';
  avg?: Maybe<v1_RecurringPayments_Roles_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_RecurringPayments_Roles_max_fields>;
  min?: Maybe<v1_RecurringPayments_Roles_min_fields>;
  stddev?: Maybe<v1_RecurringPayments_Roles_stddev_fields>;
  stddev_pop?: Maybe<v1_RecurringPayments_Roles_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_RecurringPayments_Roles_stddev_samp_fields>;
  sum?: Maybe<v1_RecurringPayments_Roles_sum_fields>;
  var_pop?: Maybe<v1_RecurringPayments_Roles_var_pop_fields>;
  var_samp?: Maybe<v1_RecurringPayments_Roles_var_samp_fields>;
  variance?: Maybe<v1_RecurringPayments_Roles_variance_fields>;
};


/** aggregate fields of "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_aggregate_order_by = {
  avg?: InputMaybe<v1_RecurringPayments_Roles_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_RecurringPayments_Roles_max_order_by>;
  min?: InputMaybe<v1_RecurringPayments_Roles_min_order_by>;
  stddev?: InputMaybe<v1_RecurringPayments_Roles_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_RecurringPayments_Roles_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_RecurringPayments_Roles_stddev_samp_order_by>;
  sum?: InputMaybe<v1_RecurringPayments_Roles_sum_order_by>;
  var_pop?: InputMaybe<v1_RecurringPayments_Roles_var_pop_order_by>;
  var_samp?: InputMaybe<v1_RecurringPayments_Roles_var_samp_order_by>;
  variance?: InputMaybe<v1_RecurringPayments_Roles_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_arr_rel_insert_input = {
  data: Array<v1_RecurringPayments_Roles_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_RecurringPayments_Roles_on_conflict>;
};

/** aggregate avg on columns */
export type v1_RecurringPayments_Roles_avg_fields = {
  __typename?: 'v1_RecurringPayments_Roles_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_avg_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.RecurringPayments_Roles". All fields are combined with a logical 'AND'. */
export type v1_RecurringPayments_Roles_bool_exp = {
  RecurringPayment?: InputMaybe<v1_RecurringPayments_bool_exp>;
  Role?: InputMaybe<v1_Roles_bool_exp>;
  _and?: InputMaybe<Array<v1_RecurringPayments_Roles_bool_exp>>;
  _not?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
  _or?: InputMaybe<Array<v1_RecurringPayments_Roles_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  recurringPayment_id?: InputMaybe<Int_comparison_exp>;
  role_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.RecurringPayments_Roles" */
export enum v1_RecurringPayments_Roles_constraint {
  /** unique or primary key constraint on columns "id" */
  RecurringPayments_Roles_pkey = 'RecurringPayments_Roles_pkey'
}

/** input type for incrementing numeric columns in table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  recurringPayment_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_insert_input = {
  RecurringPayment?: InputMaybe<v1_RecurringPayments_obj_rel_insert_input>;
  Role?: InputMaybe<v1_Roles_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  recurringPayment_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_RecurringPayments_Roles_max_fields = {
  __typename?: 'v1_RecurringPayments_Roles_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  recurringPayment_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_max_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_RecurringPayments_Roles_min_fields = {
  __typename?: 'v1_RecurringPayments_Roles_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  recurringPayment_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_min_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_mutation_response = {
  __typename?: 'v1_RecurringPayments_Roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_RecurringPayments_Roles>;
};

/** on_conflict condition type for table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_on_conflict = {
  constraint: v1_RecurringPayments_Roles_constraint;
  update_columns?: Array<v1_RecurringPayments_Roles_update_column>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};

/** Ordering options when selecting data from "v1.RecurringPayments_Roles". */
export type v1_RecurringPayments_Roles_order_by = {
  RecurringPayment?: InputMaybe<v1_RecurringPayments_order_by>;
  Role?: InputMaybe<v1_Roles_order_by>;
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.RecurringPayments_Roles */
export type v1_RecurringPayments_Roles_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.RecurringPayments_Roles" */
export enum v1_RecurringPayments_Roles_select_column {
  /** column name */
  id = 'id',
  /** column name */
  recurringPayment_id = 'recurringPayment_id',
  /** column name */
  role_id = 'role_id'
}

/** input type for updating data in table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  recurringPayment_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_RecurringPayments_Roles_stddev_fields = {
  __typename?: 'v1_RecurringPayments_Roles_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_stddev_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_RecurringPayments_Roles_stddev_pop_fields = {
  __typename?: 'v1_RecurringPayments_Roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_RecurringPayments_Roles_stddev_samp_fields = {
  __typename?: 'v1_RecurringPayments_Roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_RecurringPayments_Roles_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_RecurringPayments_Roles_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  recurringPayment_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_RecurringPayments_Roles_sum_fields = {
  __typename?: 'v1_RecurringPayments_Roles_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  recurringPayment_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_sum_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.RecurringPayments_Roles" */
export enum v1_RecurringPayments_Roles_update_column {
  /** column name */
  id = 'id',
  /** column name */
  recurringPayment_id = 'recurringPayment_id',
  /** column name */
  role_id = 'role_id'
}

export type v1_RecurringPayments_Roles_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_RecurringPayments_Roles_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_RecurringPayments_Roles_set_input>;
  /** filter the rows which have to be updated */
  where: v1_RecurringPayments_Roles_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_RecurringPayments_Roles_var_pop_fields = {
  __typename?: 'v1_RecurringPayments_Roles_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_RecurringPayments_Roles_var_samp_fields = {
  __typename?: 'v1_RecurringPayments_Roles_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_RecurringPayments_Roles_variance_fields = {
  __typename?: 'v1_RecurringPayments_Roles_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.RecurringPayments_Roles" */
export type v1_RecurringPayments_Roles_variance_order_by = {
  id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregated selection of "v1.RecurringPayments" */
export type v1_RecurringPayments_aggregate = {
  __typename?: 'v1_RecurringPayments_aggregate';
  aggregate?: Maybe<v1_RecurringPayments_aggregate_fields>;
  nodes: Array<v1_RecurringPayments>;
};

export type v1_RecurringPayments_aggregate_bool_exp = {
  count?: InputMaybe<v1_RecurringPayments_aggregate_bool_exp_count>;
};

export type v1_RecurringPayments_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_RecurringPayments_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.RecurringPayments" */
export type v1_RecurringPayments_aggregate_fields = {
  __typename?: 'v1_RecurringPayments_aggregate_fields';
  avg?: Maybe<v1_RecurringPayments_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_RecurringPayments_max_fields>;
  min?: Maybe<v1_RecurringPayments_min_fields>;
  stddev?: Maybe<v1_RecurringPayments_stddev_fields>;
  stddev_pop?: Maybe<v1_RecurringPayments_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_RecurringPayments_stddev_samp_fields>;
  sum?: Maybe<v1_RecurringPayments_sum_fields>;
  var_pop?: Maybe<v1_RecurringPayments_var_pop_fields>;
  var_samp?: Maybe<v1_RecurringPayments_var_samp_fields>;
  variance?: Maybe<v1_RecurringPayments_variance_fields>;
};


/** aggregate fields of "v1.RecurringPayments" */
export type v1_RecurringPayments_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.RecurringPayments" */
export type v1_RecurringPayments_aggregate_order_by = {
  avg?: InputMaybe<v1_RecurringPayments_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_RecurringPayments_max_order_by>;
  min?: InputMaybe<v1_RecurringPayments_min_order_by>;
  stddev?: InputMaybe<v1_RecurringPayments_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_RecurringPayments_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_RecurringPayments_stddev_samp_order_by>;
  sum?: InputMaybe<v1_RecurringPayments_sum_order_by>;
  var_pop?: InputMaybe<v1_RecurringPayments_var_pop_order_by>;
  var_samp?: InputMaybe<v1_RecurringPayments_var_samp_order_by>;
  variance?: InputMaybe<v1_RecurringPayments_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.RecurringPayments" */
export type v1_RecurringPayments_arr_rel_insert_input = {
  data: Array<v1_RecurringPayments_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_RecurringPayments_on_conflict>;
};

/** aggregate avg on columns */
export type v1_RecurringPayments_avg_fields = {
  __typename?: 'v1_RecurringPayments_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_avg_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.RecurringPayments". All fields are combined with a logical 'AND'. */
export type v1_RecurringPayments_bool_exp = {
  Company?: InputMaybe<v1_AccountBase_bool_exp>;
  Credential?: InputMaybe<v1_Credentials_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  PaymentCategory?: InputMaybe<v1_ServiceTypes_bool_exp>;
  PaymentFrequency?: InputMaybe<v1_PaymentFrequency_bool_exp>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  PurchaseOrder?: InputMaybe<v1_new_POBase_bool_exp>;
  PurchaseOrder_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  RecurringPayments_Roles?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
  RecurringPayments_Roles_aggregate?: InputMaybe<v1_RecurringPayments_Roles_aggregate_bool_exp>;
  Status?: InputMaybe<v1_RecurringPaymentStatus_bool_exp>;
  _and?: InputMaybe<Array<v1_RecurringPayments_bool_exp>>;
  _not?: InputMaybe<v1_RecurringPayments_bool_exp>;
  _or?: InputMaybe<Array<v1_RecurringPayments_bool_exp>>;
  amount?: InputMaybe<numeric_comparison_exp>;
  company_id?: InputMaybe<Int_comparison_exp>;
  created_on?: InputMaybe<date_comparison_exp>;
  credential_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  expires_on?: InputMaybe<date_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paymentCategory_id?: InputMaybe<Int_comparison_exp>;
  paymentFrequency_id?: InputMaybe<Int_comparison_exp>;
  payment_address?: InputMaybe<String_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  status_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.RecurringPayments" */
export enum v1_RecurringPayments_constraint {
  /** unique or primary key constraint on columns "id" */
  RecurringPayments_pkey = 'RecurringPayments_pkey'
}

/** input type for incrementing numeric columns in table "v1.RecurringPayments" */
export type v1_RecurringPayments_inc_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  paymentFrequency_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.RecurringPayments" */
export type v1_RecurringPayments_insert_input = {
  Company?: InputMaybe<v1_AccountBase_obj_rel_insert_input>;
  Credential?: InputMaybe<v1_Credentials_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  PaymentCategory?: InputMaybe<v1_ServiceTypes_obj_rel_insert_input>;
  PaymentFrequency?: InputMaybe<v1_PaymentFrequency_obj_rel_insert_input>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_obj_rel_insert_input>;
  PurchaseOrder?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  RecurringPayments_Roles?: InputMaybe<v1_RecurringPayments_Roles_arr_rel_insert_input>;
  Status?: InputMaybe<v1_RecurringPaymentStatus_obj_rel_insert_input>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  paymentFrequency_id?: InputMaybe<Scalars['Int']['input']>;
  payment_address?: InputMaybe<Scalars['String']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_RecurringPayments_max_fields = {
  __typename?: 'v1_RecurringPayments_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['date']['output']>;
  credential_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Int']['output']>;
  payment_address?: Maybe<Scalars['String']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_max_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  expires_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  payment_address?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_RecurringPayments_min_fields = {
  __typename?: 'v1_RecurringPayments_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  created_on?: Maybe<Scalars['date']['output']>;
  credential_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_on?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Int']['output']>;
  payment_address?: Maybe<Scalars['String']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_min_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  expires_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  payment_address?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.RecurringPayments" */
export type v1_RecurringPayments_mutation_response = {
  __typename?: 'v1_RecurringPayments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_RecurringPayments>;
};

/** input type for inserting object relation for remote table "v1.RecurringPayments" */
export type v1_RecurringPayments_obj_rel_insert_input = {
  data: v1_RecurringPayments_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_RecurringPayments_on_conflict>;
};

/** on_conflict condition type for table "v1.RecurringPayments" */
export type v1_RecurringPayments_on_conflict = {
  constraint: v1_RecurringPayments_constraint;
  update_columns?: Array<v1_RecurringPayments_update_column>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};

/** Ordering options when selecting data from "v1.RecurringPayments". */
export type v1_RecurringPayments_order_by = {
  Company?: InputMaybe<v1_AccountBase_order_by>;
  Credential?: InputMaybe<v1_Credentials_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  PaymentCategory?: InputMaybe<v1_ServiceTypes_order_by>;
  PaymentFrequency?: InputMaybe<v1_PaymentFrequency_order_by>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_order_by>;
  PurchaseOrder_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  RecurringPayments_Roles_aggregate?: InputMaybe<v1_RecurringPayments_Roles_aggregate_order_by>;
  Status?: InputMaybe<v1_RecurringPaymentStatus_order_by>;
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  created_on?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  expires_on?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  payment_address?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.RecurringPayments */
export type v1_RecurringPayments_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.RecurringPayments" */
export enum v1_RecurringPayments_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  company_id = 'company_id',
  /** column name */
  created_on = 'created_on',
  /** column name */
  credential_id = 'credential_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  description = 'description',
  /** column name */
  expires_on = 'expires_on',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentCategory_id = 'paymentCategory_id',
  /** column name */
  paymentFrequency_id = 'paymentFrequency_id',
  /** column name */
  payment_address = 'payment_address',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  status_id = 'status_id'
}

/** input type for updating data in table "v1.RecurringPayments" */
export type v1_RecurringPayments_set_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  paymentFrequency_id?: InputMaybe<Scalars['Int']['input']>;
  payment_address?: InputMaybe<Scalars['String']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_RecurringPayments_stddev_fields = {
  __typename?: 'v1_RecurringPayments_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_stddev_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_RecurringPayments_stddev_pop_fields = {
  __typename?: 'v1_RecurringPayments_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_stddev_pop_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_RecurringPayments_stddev_samp_fields = {
  __typename?: 'v1_RecurringPayments_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_stddev_samp_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_RecurringPayments" */
export type v1_RecurringPayments_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_RecurringPayments_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_RecurringPayments_stream_cursor_value_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  created_on?: InputMaybe<Scalars['date']['input']>;
  credential_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires_on?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentCategory_id?: InputMaybe<Scalars['Int']['input']>;
  paymentFrequency_id?: InputMaybe<Scalars['Int']['input']>;
  payment_address?: InputMaybe<Scalars['String']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_RecurringPayments_sum_fields = {
  __typename?: 'v1_RecurringPayments_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  credential_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentCategory_id?: Maybe<Scalars['Int']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_sum_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.RecurringPayments" */
export enum v1_RecurringPayments_update_column {
  /** column name */
  amount = 'amount',
  /** column name */
  company_id = 'company_id',
  /** column name */
  created_on = 'created_on',
  /** column name */
  credential_id = 'credential_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  description = 'description',
  /** column name */
  expires_on = 'expires_on',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentCategory_id = 'paymentCategory_id',
  /** column name */
  paymentFrequency_id = 'paymentFrequency_id',
  /** column name */
  payment_address = 'payment_address',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  status_id = 'status_id'
}

export type v1_RecurringPayments_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_RecurringPayments_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_RecurringPayments_set_input>;
  /** filter the rows which have to be updated */
  where: v1_RecurringPayments_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_RecurringPayments_var_pop_fields = {
  __typename?: 'v1_RecurringPayments_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_var_pop_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_RecurringPayments_var_samp_fields = {
  __typename?: 'v1_RecurringPayments_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_var_samp_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_RecurringPayments_variance_fields = {
  __typename?: 'v1_RecurringPayments_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  credential_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentCategory_id?: Maybe<Scalars['Float']['output']>;
  paymentFrequency_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.RecurringPayments" */
export type v1_RecurringPayments_variance_order_by = {
  amount?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  credential_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentCategory_id?: InputMaybe<order_by>;
  paymentFrequency_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Report" */
export type v1_Report = {
  __typename?: 'v1_Report';
  /** An array relationship */
  Report_Roles: Array<v1_Report_Roles>;
  /** An aggregate relationship */
  Report_Roles_aggregate: v1_Report_Roles_aggregate;
  id: Scalars['Int']['output'];
  metabase_id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
};


/** columns and relationships of "v1.Report" */
export type v1_ReportReport_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_Roles_order_by>>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};


/** columns and relationships of "v1.Report" */
export type v1_ReportReport_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_Roles_order_by>>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};

/** columns and relationships of "v1.Report_Roles" */
export type v1_Report_Roles = {
  __typename?: 'v1_Report_Roles';
  /** An object relationship */
  Report: v1_Report;
  /** An object relationship */
  Roles?: Maybe<v1_Roles>;
  id: Scalars['Int']['output'];
  report_id: Scalars['Int']['output'];
  role_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.Report_Roles" */
export type v1_Report_Roles_aggregate = {
  __typename?: 'v1_Report_Roles_aggregate';
  aggregate?: Maybe<v1_Report_Roles_aggregate_fields>;
  nodes: Array<v1_Report_Roles>;
};

export type v1_Report_Roles_aggregate_bool_exp = {
  count?: InputMaybe<v1_Report_Roles_aggregate_bool_exp_count>;
};

export type v1_Report_Roles_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Report_Roles_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Report_Roles" */
export type v1_Report_Roles_aggregate_fields = {
  __typename?: 'v1_Report_Roles_aggregate_fields';
  avg?: Maybe<v1_Report_Roles_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Report_Roles_max_fields>;
  min?: Maybe<v1_Report_Roles_min_fields>;
  stddev?: Maybe<v1_Report_Roles_stddev_fields>;
  stddev_pop?: Maybe<v1_Report_Roles_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Report_Roles_stddev_samp_fields>;
  sum?: Maybe<v1_Report_Roles_sum_fields>;
  var_pop?: Maybe<v1_Report_Roles_var_pop_fields>;
  var_samp?: Maybe<v1_Report_Roles_var_samp_fields>;
  variance?: Maybe<v1_Report_Roles_variance_fields>;
};


/** aggregate fields of "v1.Report_Roles" */
export type v1_Report_Roles_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Report_Roles" */
export type v1_Report_Roles_aggregate_order_by = {
  avg?: InputMaybe<v1_Report_Roles_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Report_Roles_max_order_by>;
  min?: InputMaybe<v1_Report_Roles_min_order_by>;
  stddev?: InputMaybe<v1_Report_Roles_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Report_Roles_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Report_Roles_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Report_Roles_sum_order_by>;
  var_pop?: InputMaybe<v1_Report_Roles_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Report_Roles_var_samp_order_by>;
  variance?: InputMaybe<v1_Report_Roles_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Report_Roles" */
export type v1_Report_Roles_arr_rel_insert_input = {
  data: Array<v1_Report_Roles_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Report_Roles_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Report_Roles_avg_fields = {
  __typename?: 'v1_Report_Roles_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_avg_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Report_Roles". All fields are combined with a logical 'AND'. */
export type v1_Report_Roles_bool_exp = {
  Report?: InputMaybe<v1_Report_bool_exp>;
  Roles?: InputMaybe<v1_Roles_bool_exp>;
  _and?: InputMaybe<Array<v1_Report_Roles_bool_exp>>;
  _not?: InputMaybe<v1_Report_Roles_bool_exp>;
  _or?: InputMaybe<Array<v1_Report_Roles_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  report_id?: InputMaybe<Int_comparison_exp>;
  role_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Report_Roles" */
export enum v1_Report_Roles_constraint {
  /** unique or primary key constraint on columns "id" */
  Report_Roles_pkey = 'Report_Roles_pkey'
}

/** input type for incrementing numeric columns in table "v1.Report_Roles" */
export type v1_Report_Roles_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  report_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Report_Roles" */
export type v1_Report_Roles_insert_input = {
  Report?: InputMaybe<v1_Report_obj_rel_insert_input>;
  Roles?: InputMaybe<v1_Roles_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  report_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Report_Roles_max_fields = {
  __typename?: 'v1_Report_Roles_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  report_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_max_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Report_Roles_min_fields = {
  __typename?: 'v1_Report_Roles_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  report_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_min_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Report_Roles" */
export type v1_Report_Roles_mutation_response = {
  __typename?: 'v1_Report_Roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Report_Roles>;
};

/** on_conflict condition type for table "v1.Report_Roles" */
export type v1_Report_Roles_on_conflict = {
  constraint: v1_Report_Roles_constraint;
  update_columns?: Array<v1_Report_Roles_update_column>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};

/** Ordering options when selecting data from "v1.Report_Roles". */
export type v1_Report_Roles_order_by = {
  Report?: InputMaybe<v1_Report_order_by>;
  Roles?: InputMaybe<v1_Roles_order_by>;
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Report_Roles */
export type v1_Report_Roles_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Report_Roles" */
export enum v1_Report_Roles_select_column {
  /** column name */
  id = 'id',
  /** column name */
  report_id = 'report_id',
  /** column name */
  role_id = 'role_id'
}

/** input type for updating data in table "v1.Report_Roles" */
export type v1_Report_Roles_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  report_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Report_Roles_stddev_fields = {
  __typename?: 'v1_Report_Roles_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_stddev_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Report_Roles_stddev_pop_fields = {
  __typename?: 'v1_Report_Roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Report_Roles_stddev_samp_fields = {
  __typename?: 'v1_Report_Roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Report_Roles" */
export type v1_Report_Roles_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Report_Roles_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Report_Roles_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  report_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Report_Roles_sum_fields = {
  __typename?: 'v1_Report_Roles_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  report_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_sum_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Report_Roles" */
export enum v1_Report_Roles_update_column {
  /** column name */
  id = 'id',
  /** column name */
  report_id = 'report_id',
  /** column name */
  role_id = 'role_id'
}

export type v1_Report_Roles_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Report_Roles_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Report_Roles_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Report_Roles_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Report_Roles_var_pop_fields = {
  __typename?: 'v1_Report_Roles_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Report_Roles_var_samp_fields = {
  __typename?: 'v1_Report_Roles_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Report_Roles_variance_fields = {
  __typename?: 'v1_Report_Roles_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Report_Roles" */
export type v1_Report_Roles_variance_order_by = {
  id?: InputMaybe<order_by>;
  report_id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
};

/** aggregated selection of "v1.Report" */
export type v1_Report_aggregate = {
  __typename?: 'v1_Report_aggregate';
  aggregate?: Maybe<v1_Report_aggregate_fields>;
  nodes: Array<v1_Report>;
};

/** aggregate fields of "v1.Report" */
export type v1_Report_aggregate_fields = {
  __typename?: 'v1_Report_aggregate_fields';
  avg?: Maybe<v1_Report_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Report_max_fields>;
  min?: Maybe<v1_Report_min_fields>;
  stddev?: Maybe<v1_Report_stddev_fields>;
  stddev_pop?: Maybe<v1_Report_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Report_stddev_samp_fields>;
  sum?: Maybe<v1_Report_sum_fields>;
  var_pop?: Maybe<v1_Report_var_pop_fields>;
  var_samp?: Maybe<v1_Report_var_samp_fields>;
  variance?: Maybe<v1_Report_variance_fields>;
};


/** aggregate fields of "v1.Report" */
export type v1_Report_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Report_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Report_avg_fields = {
  __typename?: 'v1_Report_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metabase_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Report". All fields are combined with a logical 'AND'. */
export type v1_Report_bool_exp = {
  Report_Roles?: InputMaybe<v1_Report_Roles_bool_exp>;
  Report_Roles_aggregate?: InputMaybe<v1_Report_Roles_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_Report_bool_exp>>;
  _not?: InputMaybe<v1_Report_bool_exp>;
  _or?: InputMaybe<Array<v1_Report_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  metabase_id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  published?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Report" */
export enum v1_Report_constraint {
  /** unique or primary key constraint on columns "metabase_id" */
  Report_metabase_id_key = 'Report_metabase_id_key',
  /** unique or primary key constraint on columns "id" */
  Report_pkey = 'Report_pkey'
}

/** input type for incrementing numeric columns in table "v1.Report" */
export type v1_Report_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metabase_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Report" */
export type v1_Report_insert_input = {
  Report_Roles?: InputMaybe<v1_Report_Roles_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metabase_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type v1_Report_max_fields = {
  __typename?: 'v1_Report_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  metabase_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_Report_min_fields = {
  __typename?: 'v1_Report_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  metabase_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.Report" */
export type v1_Report_mutation_response = {
  __typename?: 'v1_Report_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Report>;
};

/** input type for inserting object relation for remote table "v1.Report" */
export type v1_Report_obj_rel_insert_input = {
  data: v1_Report_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Report_on_conflict>;
};

/** on_conflict condition type for table "v1.Report" */
export type v1_Report_on_conflict = {
  constraint: v1_Report_constraint;
  update_columns?: Array<v1_Report_update_column>;
  where?: InputMaybe<v1_Report_bool_exp>;
};

/** Ordering options when selecting data from "v1.Report". */
export type v1_Report_order_by = {
  Report_Roles_aggregate?: InputMaybe<v1_Report_Roles_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  metabase_id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  published?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Report */
export type v1_Report_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Report" */
export enum v1_Report_select_column {
  /** column name */
  id = 'id',
  /** column name */
  metabase_id = 'metabase_id',
  /** column name */
  name = 'name',
  /** column name */
  published = 'published'
}

/** input type for updating data in table "v1.Report" */
export type v1_Report_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metabase_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type v1_Report_stddev_fields = {
  __typename?: 'v1_Report_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metabase_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Report_stddev_pop_fields = {
  __typename?: 'v1_Report_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metabase_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Report_stddev_samp_fields = {
  __typename?: 'v1_Report_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metabase_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Report" */
export type v1_Report_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Report_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Report_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metabase_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type v1_Report_sum_fields = {
  __typename?: 'v1_Report_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  metabase_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Report" */
export enum v1_Report_update_column {
  /** column name */
  id = 'id',
  /** column name */
  metabase_id = 'metabase_id',
  /** column name */
  name = 'name',
  /** column name */
  published = 'published'
}

export type v1_Report_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Report_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Report_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Report_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Report_var_pop_fields = {
  __typename?: 'v1_Report_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metabase_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Report_var_samp_fields = {
  __typename?: 'v1_Report_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metabase_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Report_variance_fields = {
  __typename?: 'v1_Report_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metabase_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer = {
  __typename?: 'v1_RestrictedNichesToOffer';
  /** An object relationship */
  Offers: v1_Offer;
  /** An object relationship */
  PostNiches: v1_PostNiche;
  id: Scalars['Int']['output'];
  niche_id: Scalars['Int']['output'];
  offer_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_aggregate = {
  __typename?: 'v1_RestrictedNichesToOffer_aggregate';
  aggregate?: Maybe<v1_RestrictedNichesToOffer_aggregate_fields>;
  nodes: Array<v1_RestrictedNichesToOffer>;
};

export type v1_RestrictedNichesToOffer_aggregate_bool_exp = {
  count?: InputMaybe<v1_RestrictedNichesToOffer_aggregate_bool_exp_count>;
};

export type v1_RestrictedNichesToOffer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_aggregate_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_aggregate_fields';
  avg?: Maybe<v1_RestrictedNichesToOffer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_RestrictedNichesToOffer_max_fields>;
  min?: Maybe<v1_RestrictedNichesToOffer_min_fields>;
  stddev?: Maybe<v1_RestrictedNichesToOffer_stddev_fields>;
  stddev_pop?: Maybe<v1_RestrictedNichesToOffer_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_RestrictedNichesToOffer_stddev_samp_fields>;
  sum?: Maybe<v1_RestrictedNichesToOffer_sum_fields>;
  var_pop?: Maybe<v1_RestrictedNichesToOffer_var_pop_fields>;
  var_samp?: Maybe<v1_RestrictedNichesToOffer_var_samp_fields>;
  variance?: Maybe<v1_RestrictedNichesToOffer_variance_fields>;
};


/** aggregate fields of "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_RestrictedNichesToOffer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_aggregate_order_by = {
  avg?: InputMaybe<v1_RestrictedNichesToOffer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_RestrictedNichesToOffer_max_order_by>;
  min?: InputMaybe<v1_RestrictedNichesToOffer_min_order_by>;
  stddev?: InputMaybe<v1_RestrictedNichesToOffer_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_RestrictedNichesToOffer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_RestrictedNichesToOffer_stddev_samp_order_by>;
  sum?: InputMaybe<v1_RestrictedNichesToOffer_sum_order_by>;
  var_pop?: InputMaybe<v1_RestrictedNichesToOffer_var_pop_order_by>;
  var_samp?: InputMaybe<v1_RestrictedNichesToOffer_var_samp_order_by>;
  variance?: InputMaybe<v1_RestrictedNichesToOffer_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_arr_rel_insert_input = {
  data: Array<v1_RestrictedNichesToOffer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_RestrictedNichesToOffer_on_conflict>;
};

/** aggregate avg on columns */
export type v1_RestrictedNichesToOffer_avg_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_avg_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.RestrictedNichesToOffer". All fields are combined with a logical 'AND'. */
export type v1_RestrictedNichesToOffer_bool_exp = {
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  PostNiches?: InputMaybe<v1_PostNiche_bool_exp>;
  _and?: InputMaybe<Array<v1_RestrictedNichesToOffer_bool_exp>>;
  _not?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
  _or?: InputMaybe<Array<v1_RestrictedNichesToOffer_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  niche_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.RestrictedNichesToOffer" */
export enum v1_RestrictedNichesToOffer_constraint {
  /** unique or primary key constraint on columns "offer_id", "niche_id" */
  RestrictedNichesToOffer_offer_id_niche_id_key = 'RestrictedNichesToOffer_offer_id_niche_id_key',
  /** unique or primary key constraint on columns "id" */
  RestrictedNichesToOffer_pkey = 'RestrictedNichesToOffer_pkey'
}

/** input type for incrementing numeric columns in table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_insert_input = {
  Offers?: InputMaybe<v1_Offer_obj_rel_insert_input>;
  PostNiches?: InputMaybe<v1_PostNiche_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_RestrictedNichesToOffer_max_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_max_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_RestrictedNichesToOffer_min_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_min_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_mutation_response = {
  __typename?: 'v1_RestrictedNichesToOffer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_RestrictedNichesToOffer>;
};

/** on_conflict condition type for table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_on_conflict = {
  constraint: v1_RestrictedNichesToOffer_constraint;
  update_columns?: Array<v1_RestrictedNichesToOffer_update_column>;
  where?: InputMaybe<v1_RestrictedNichesToOffer_bool_exp>;
};

/** Ordering options when selecting data from "v1.RestrictedNichesToOffer". */
export type v1_RestrictedNichesToOffer_order_by = {
  Offers?: InputMaybe<v1_Offer_order_by>;
  PostNiches?: InputMaybe<v1_PostNiche_order_by>;
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.RestrictedNichesToOffer */
export type v1_RestrictedNichesToOffer_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.RestrictedNichesToOffer" */
export enum v1_RestrictedNichesToOffer_select_column {
  /** column name */
  id = 'id',
  /** column name */
  niche_id = 'niche_id',
  /** column name */
  offer_id = 'offer_id'
}

/** input type for updating data in table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_RestrictedNichesToOffer_stddev_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_stddev_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_RestrictedNichesToOffer_stddev_pop_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_RestrictedNichesToOffer_stddev_samp_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_RestrictedNichesToOffer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_RestrictedNichesToOffer_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_RestrictedNichesToOffer_sum_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_sum_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.RestrictedNichesToOffer" */
export enum v1_RestrictedNichesToOffer_update_column {
  /** column name */
  id = 'id',
  /** column name */
  niche_id = 'niche_id',
  /** column name */
  offer_id = 'offer_id'
}

export type v1_RestrictedNichesToOffer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_RestrictedNichesToOffer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_RestrictedNichesToOffer_set_input>;
  /** filter the rows which have to be updated */
  where: v1_RestrictedNichesToOffer_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_RestrictedNichesToOffer_var_pop_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_RestrictedNichesToOffer_var_samp_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_RestrictedNichesToOffer_variance_fields = {
  __typename?: 'v1_RestrictedNichesToOffer_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.RestrictedNichesToOffer" */
export type v1_RestrictedNichesToOffer_variance_order_by = {
  id?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Roles" */
export type v1_Roles = {
  __typename?: 'v1_Roles';
  /** An array relationship */
  Contacts_Roles: Array<v1_Contacts_Roles>;
  /** An aggregate relationship */
  Contacts_Roles_aggregate: v1_Contacts_Roles_aggregate;
  /** An array relationship */
  Credential_Roles: Array<v1_Credential_Roles>;
  /** An aggregate relationship */
  Credential_Roles_aggregate: v1_Credential_Roles_aggregate;
  /** An array relationship */
  RecurringPayments_Roles: Array<v1_RecurringPayments_Roles>;
  /** An aggregate relationship */
  RecurringPayments_Roles_aggregate: v1_RecurringPayments_Roles_aggregate;
  /** An array relationship */
  Report_Roles: Array<v1_Report_Roles>;
  /** An aggregate relationship */
  Report_Roles_aggregate: v1_Report_Roles_aggregate;
  /** An array relationship */
  Users_Roles: Array<v1_Users_Roles>;
  /** An aggregate relationship */
  Users_Roles_aggregate: v1_Users_Roles_aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesContacts_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Contacts_Roles_order_by>>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesContacts_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Contacts_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Contacts_Roles_order_by>>;
  where?: InputMaybe<v1_Contacts_Roles_bool_exp>;
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesCredential_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credential_Roles_order_by>>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesCredential_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Credential_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credential_Roles_order_by>>;
  where?: InputMaybe<v1_Credential_Roles_bool_exp>;
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesRecurringPayments_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_Roles_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesRecurringPayments_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_Roles_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesReport_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_Roles_order_by>>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesReport_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Report_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Report_Roles_order_by>>;
  where?: InputMaybe<v1_Report_Roles_bool_exp>;
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesUsers_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Roles_order_by>>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};


/** columns and relationships of "v1.Roles" */
export type v1_RolesUsers_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Roles_order_by>>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};

/** aggregated selection of "v1.Roles" */
export type v1_Roles_aggregate = {
  __typename?: 'v1_Roles_aggregate';
  aggregate?: Maybe<v1_Roles_aggregate_fields>;
  nodes: Array<v1_Roles>;
};

/** aggregate fields of "v1.Roles" */
export type v1_Roles_aggregate_fields = {
  __typename?: 'v1_Roles_aggregate_fields';
  avg?: Maybe<v1_Roles_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Roles_max_fields>;
  min?: Maybe<v1_Roles_min_fields>;
  stddev?: Maybe<v1_Roles_stddev_fields>;
  stddev_pop?: Maybe<v1_Roles_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Roles_stddev_samp_fields>;
  sum?: Maybe<v1_Roles_sum_fields>;
  var_pop?: Maybe<v1_Roles_var_pop_fields>;
  var_samp?: Maybe<v1_Roles_var_samp_fields>;
  variance?: Maybe<v1_Roles_variance_fields>;
};


/** aggregate fields of "v1.Roles" */
export type v1_Roles_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Roles_avg_fields = {
  __typename?: 'v1_Roles_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Roles". All fields are combined with a logical 'AND'. */
export type v1_Roles_bool_exp = {
  Contacts_Roles?: InputMaybe<v1_Contacts_Roles_bool_exp>;
  Contacts_Roles_aggregate?: InputMaybe<v1_Contacts_Roles_aggregate_bool_exp>;
  Credential_Roles?: InputMaybe<v1_Credential_Roles_bool_exp>;
  Credential_Roles_aggregate?: InputMaybe<v1_Credential_Roles_aggregate_bool_exp>;
  RecurringPayments_Roles?: InputMaybe<v1_RecurringPayments_Roles_bool_exp>;
  RecurringPayments_Roles_aggregate?: InputMaybe<v1_RecurringPayments_Roles_aggregate_bool_exp>;
  Report_Roles?: InputMaybe<v1_Report_Roles_bool_exp>;
  Report_Roles_aggregate?: InputMaybe<v1_Report_Roles_aggregate_bool_exp>;
  Users_Roles?: InputMaybe<v1_Users_Roles_bool_exp>;
  Users_Roles_aggregate?: InputMaybe<v1_Users_Roles_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_Roles_bool_exp>>;
  _not?: InputMaybe<v1_Roles_bool_exp>;
  _or?: InputMaybe<Array<v1_Roles_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Roles" */
export enum v1_Roles_constraint {
  /** unique or primary key constraint on columns "name" */
  Roles_name_key = 'Roles_name_key',
  /** unique or primary key constraint on columns "id" */
  Roles_pkey = 'Roles_pkey'
}

/** input type for incrementing numeric columns in table "v1.Roles" */
export type v1_Roles_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Roles" */
export type v1_Roles_insert_input = {
  Contacts_Roles?: InputMaybe<v1_Contacts_Roles_arr_rel_insert_input>;
  Credential_Roles?: InputMaybe<v1_Credential_Roles_arr_rel_insert_input>;
  RecurringPayments_Roles?: InputMaybe<v1_RecurringPayments_Roles_arr_rel_insert_input>;
  Report_Roles?: InputMaybe<v1_Report_Roles_arr_rel_insert_input>;
  Users_Roles?: InputMaybe<v1_Users_Roles_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Roles_max_fields = {
  __typename?: 'v1_Roles_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_Roles_min_fields = {
  __typename?: 'v1_Roles_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.Roles" */
export type v1_Roles_mutation_response = {
  __typename?: 'v1_Roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Roles>;
};

/** input type for inserting object relation for remote table "v1.Roles" */
export type v1_Roles_obj_rel_insert_input = {
  data: v1_Roles_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Roles_on_conflict>;
};

/** on_conflict condition type for table "v1.Roles" */
export type v1_Roles_on_conflict = {
  constraint: v1_Roles_constraint;
  update_columns?: Array<v1_Roles_update_column>;
  where?: InputMaybe<v1_Roles_bool_exp>;
};

/** Ordering options when selecting data from "v1.Roles". */
export type v1_Roles_order_by = {
  Contacts_Roles_aggregate?: InputMaybe<v1_Contacts_Roles_aggregate_order_by>;
  Credential_Roles_aggregate?: InputMaybe<v1_Credential_Roles_aggregate_order_by>;
  RecurringPayments_Roles_aggregate?: InputMaybe<v1_RecurringPayments_Roles_aggregate_order_by>;
  Report_Roles_aggregate?: InputMaybe<v1_Report_Roles_aggregate_order_by>;
  Users_Roles_aggregate?: InputMaybe<v1_Users_Roles_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Roles */
export type v1_Roles_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Roles" */
export enum v1_Roles_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.Roles" */
export type v1_Roles_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Roles_stddev_fields = {
  __typename?: 'v1_Roles_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Roles_stddev_pop_fields = {
  __typename?: 'v1_Roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Roles_stddev_samp_fields = {
  __typename?: 'v1_Roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Roles" */
export type v1_Roles_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Roles_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Roles_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Roles_sum_fields = {
  __typename?: 'v1_Roles_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Roles" */
export enum v1_Roles_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_Roles_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Roles_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Roles_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Roles_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Roles_var_pop_fields = {
  __typename?: 'v1_Roles_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Roles_var_samp_fields = {
  __typename?: 'v1_Roles_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Roles_variance_fields = {
  __typename?: 'v1_Roles_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Affiliate Order Entity */
export type v1_SalesOrderBase = {
  __typename?: 'v1_SalesOrderBase';
  /** An object relationship */
  AffDealType?: Maybe<v1_AffDealType>;
  /** An object relationship */
  AffiliateProjectNew?: Maybe<v1_new_affordertypeBase>;
  /** An object relationship */
  CompanyNew?: Maybe<v1_AccountBase>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  Description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  Invoices: Array<v1_InvoiceBase>;
  /** An aggregate relationship */
  Invoices_aggregate: v1_InvoiceBase_aggregate;
  Name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "v1.notpaid_invoices_amount_afford" */
  NotPaidAmount?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "v1.notpaid_invoices_afford" */
  NotPaidInvoices?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  OrderFor?: Maybe<v1_OrderFor>;
  /** An object relationship */
  OrderPayTo?: Maybe<v1_PaymentCompanies>;
  /** An object relationship */
  OrderType?: Maybe<v1_OrderType>;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  /** A computed field, executes function "v1.paid_invoices_afford" */
  PaidInvoices?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  PaymentMethod?: Maybe<v1_new_paymentmethodBase>;
  /** An object relationship */
  Priority: v1_OrderPriority;
  PriorityCode: Scalars['Int']['output'];
  /** An object relationship */
  Project?: Maybe<v1_Project>;
  /** An object relationship */
  RegEmail?: Maybe<v1_new_regemailBase>;
  SalesOrderId?: Maybe<Scalars['uuid']['output']>;
  StateCode: Scalars['Int']['output'];
  StatusCode?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  Trackers: Array<v1_new_trackerBase>;
  /** An aggregate relationship */
  Trackers_aggregate: v1_new_trackerBase_aggregate;
  TraversedPath?: Maybe<Scalars['String']['output']>;
  affiliate_project?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.has_open_invoices" */
  hasOpenInvoices?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  new_1InvoiceNOrdersId?: Maybe<Scalars['uuid']['output']>;
  new_AffOrderType?: Maybe<Scalars['uuid']['output']>;
  new_AffiliateAccountPassword?: Maybe<Scalars['String']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Int']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Int']['output']>;
  new_AffiliateWebsite?: Maybe<Scalars['String']['output']>;
  new_AffiliateaccountUsername?: Maybe<Scalars['String']['output']>;
  new_Calculateposts?: Maybe<Scalars['Boolean']['output']>;
  new_Clicks?: Maybe<Scalars['Int']['output']>;
  new_Country?: Maybe<Scalars['uuid']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_DataStarted?: Maybe<Scalars['timestamp']['output']>;
  new_DateEnded?: Maybe<Scalars['timestamp']['output']>;
  new_For?: Maybe<Scalars['Int']['output']>;
  new_Invoiced?: Maybe<Scalars['Boolean']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Int']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['numeric']['output']>;
  new_LastCheck?: Maybe<Scalars['timestamp']['output']>;
  new_OpenInIframe?: Maybe<Scalars['Boolean']['output']>;
  new_OrdeMode?: Maybe<Scalars['Int']['output']>;
  new_OrderRevenue?: Maybe<Scalars['numeric']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Int']['output']>;
  new_OrderType?: Maybe<Scalars['Int']['output']>;
  new_PayTo?: Maybe<Scalars['Int']['output']>;
  new_PaymentFrom?: Maybe<Scalars['String']['output']>;
  new_PaymentMethod?: Maybe<Scalars['uuid']['output']>;
  new_PostStrategy?: Maybe<Scalars['Int']['output']>;
  new_RegistrationEmail?: Maybe<Scalars['uuid']['output']>;
  new_Renewal?: Maybe<Scalars['Boolean']['output']>;
  new_RunningSince?: Maybe<Scalars['timestamp']['output']>;
  new_TotalOrder?: Maybe<Scalars['numeric']['output']>;
  new_affidnumber?: Maybe<Scalars['String']['output']>;
  new_affiliatebrands?: Maybe<Scalars['String']['output']>;
  new_hastrackers?: Maybe<Scalars['Boolean']['output']>;
  new_nifty?: Maybe<Scalars['Boolean']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['numeric']['output']>;
  new_postnumber?: Maybe<Scalars['Int']['output']>;
  new_reportexclude?: Maybe<Scalars['Boolean']['output']>;
  new_sendemailrenewal?: Maybe<Scalars['String']['output']>;
  new_skypegroup?: Maybe<Scalars['Boolean']['output']>;
  new_totalorder_Base?: Maybe<Scalars['numeric']['output']>;
  new_voonixadvertiser?: Maybe<Scalars['String']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Int']['output']>;
  new_voonixsaved?: Maybe<Scalars['Boolean']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.paid_invoices_amount_afford" */
  paid_amount?: Maybe<Scalars['numeric']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  registrationemail_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.affiliate_order_clicks_30_days" */
  tracker_clicks_last_30_days?: Maybe<Scalars['Int']['output']>;
};


/** Affiliate Order Entity */
export type v1_SalesOrderBaseInvoicesArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** Affiliate Order Entity */
export type v1_SalesOrderBaseInvoices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** Affiliate Order Entity */
export type v1_SalesOrderBaseTrackersArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


/** Affiliate Order Entity */
export type v1_SalesOrderBaseTrackers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};

/** aggregated selection of "v1.SalesOrderBase" */
export type v1_SalesOrderBase_aggregate = {
  __typename?: 'v1_SalesOrderBase_aggregate';
  aggregate?: Maybe<v1_SalesOrderBase_aggregate_fields>;
  nodes: Array<v1_SalesOrderBase>;
};

export type v1_SalesOrderBase_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp_count>;
};

export type v1_SalesOrderBase_aggregate_bool_exp_bool_and = {
  arguments: v1_SalesOrderBase_select_column_v1_SalesOrderBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_SalesOrderBase_aggregate_bool_exp_bool_or = {
  arguments: v1_SalesOrderBase_select_column_v1_SalesOrderBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_SalesOrderBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.SalesOrderBase" */
export type v1_SalesOrderBase_aggregate_fields = {
  __typename?: 'v1_SalesOrderBase_aggregate_fields';
  avg?: Maybe<v1_SalesOrderBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_SalesOrderBase_max_fields>;
  min?: Maybe<v1_SalesOrderBase_min_fields>;
  stddev?: Maybe<v1_SalesOrderBase_stddev_fields>;
  stddev_pop?: Maybe<v1_SalesOrderBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_SalesOrderBase_stddev_samp_fields>;
  sum?: Maybe<v1_SalesOrderBase_sum_fields>;
  var_pop?: Maybe<v1_SalesOrderBase_var_pop_fields>;
  var_samp?: Maybe<v1_SalesOrderBase_var_samp_fields>;
  variance?: Maybe<v1_SalesOrderBase_variance_fields>;
};


/** aggregate fields of "v1.SalesOrderBase" */
export type v1_SalesOrderBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_aggregate_order_by = {
  avg?: InputMaybe<v1_SalesOrderBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_SalesOrderBase_max_order_by>;
  min?: InputMaybe<v1_SalesOrderBase_min_order_by>;
  stddev?: InputMaybe<v1_SalesOrderBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_SalesOrderBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_SalesOrderBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_SalesOrderBase_sum_order_by>;
  var_pop?: InputMaybe<v1_SalesOrderBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_SalesOrderBase_var_samp_order_by>;
  variance?: InputMaybe<v1_SalesOrderBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_arr_rel_insert_input = {
  data: Array<v1_SalesOrderBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_SalesOrderBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_SalesOrderBase_avg_fields = {
  __typename?: 'v1_SalesOrderBase_avg_fields';
  PriorityCode?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_project?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Float']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_For?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['Float']['output']>;
  new_OrdeMode?: Maybe<Scalars['Float']['output']>;
  new_OrderRevenue?: Maybe<Scalars['Float']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PostStrategy?: Maybe<Scalars['Float']['output']>;
  new_TotalOrder?: Maybe<Scalars['Float']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['Float']['output']>;
  new_postnumber?: Maybe<Scalars['Float']['output']>;
  new_totalorder_Base?: Maybe<Scalars['Float']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  registrationemail_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_avg_order_by = {
  PriorityCode?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.SalesOrderBase". All fields are combined with a logical 'AND'. */
export type v1_SalesOrderBase_bool_exp = {
  AffDealType?: InputMaybe<v1_AffDealType_bool_exp>;
  AffiliateProjectNew?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
  CompanyNew?: InputMaybe<v1_AccountBase_bool_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  Invoices?: InputMaybe<v1_InvoiceBase_bool_exp>;
  Invoices_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  Name?: InputMaybe<String_comparison_exp>;
  NotPaidAmount?: InputMaybe<numeric_comparison_exp>;
  NotPaidInvoices?: InputMaybe<Int_comparison_exp>;
  OrderFor?: InputMaybe<v1_OrderFor_bool_exp>;
  OrderPayTo?: InputMaybe<v1_PaymentCompanies_bool_exp>;
  OrderType?: InputMaybe<v1_OrderType_bool_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  PaidInvoices?: InputMaybe<Int_comparison_exp>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  Priority?: InputMaybe<v1_OrderPriority_bool_exp>;
  PriorityCode?: InputMaybe<Int_comparison_exp>;
  Project?: InputMaybe<v1_Project_bool_exp>;
  RegEmail?: InputMaybe<v1_new_regemailBase_bool_exp>;
  SalesOrderId?: InputMaybe<uuid_comparison_exp>;
  StateCode?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<Int_comparison_exp>;
  Trackers?: InputMaybe<v1_new_trackerBase_bool_exp>;
  Trackers_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_bool_exp>;
  TraversedPath?: InputMaybe<String_comparison_exp>;
  _and?: InputMaybe<Array<v1_SalesOrderBase_bool_exp>>;
  _not?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  _or?: InputMaybe<Array<v1_SalesOrderBase_bool_exp>>;
  affiliate_project?: InputMaybe<Int_comparison_exp>;
  company_id?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  hasOpenInvoices?: InputMaybe<Boolean_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_1InvoiceNOrdersId?: InputMaybe<uuid_comparison_exp>;
  new_AffOrderType?: InputMaybe<uuid_comparison_exp>;
  new_AffiliateAccountPassword?: InputMaybe<String_comparison_exp>;
  new_AffiliateDealType?: InputMaybe<Int_comparison_exp>;
  new_AffiliateOrderType?: InputMaybe<Int_comparison_exp>;
  new_AffiliateWebsite?: InputMaybe<String_comparison_exp>;
  new_AffiliateaccountUsername?: InputMaybe<String_comparison_exp>;
  new_Calculateposts?: InputMaybe<Boolean_comparison_exp>;
  new_Clicks?: InputMaybe<Int_comparison_exp>;
  new_Country?: InputMaybe<uuid_comparison_exp>;
  new_CreatedByUser?: InputMaybe<String_comparison_exp>;
  new_DataStarted?: InputMaybe<timestamp_comparison_exp>;
  new_DateEnded?: InputMaybe<timestamp_comparison_exp>;
  new_For?: InputMaybe<Int_comparison_exp>;
  new_Invoiced?: InputMaybe<Boolean_comparison_exp>;
  new_InvoicesNotPaid?: InputMaybe<Int_comparison_exp>;
  new_InvoicesNotPaidAmount?: InputMaybe<numeric_comparison_exp>;
  new_LastCheck?: InputMaybe<timestamp_comparison_exp>;
  new_OpenInIframe?: InputMaybe<Boolean_comparison_exp>;
  new_OrdeMode?: InputMaybe<Int_comparison_exp>;
  new_OrderRevenue?: InputMaybe<numeric_comparison_exp>;
  new_OrderSubscriptionType?: InputMaybe<Int_comparison_exp>;
  new_OrderType?: InputMaybe<Int_comparison_exp>;
  new_PayTo?: InputMaybe<Int_comparison_exp>;
  new_PaymentFrom?: InputMaybe<String_comparison_exp>;
  new_PaymentMethod?: InputMaybe<uuid_comparison_exp>;
  new_PostStrategy?: InputMaybe<Int_comparison_exp>;
  new_RegistrationEmail?: InputMaybe<uuid_comparison_exp>;
  new_Renewal?: InputMaybe<Boolean_comparison_exp>;
  new_RunningSince?: InputMaybe<timestamp_comparison_exp>;
  new_TotalOrder?: InputMaybe<numeric_comparison_exp>;
  new_affidnumber?: InputMaybe<String_comparison_exp>;
  new_affiliatebrands?: InputMaybe<String_comparison_exp>;
  new_hastrackers?: InputMaybe<Boolean_comparison_exp>;
  new_nifty?: InputMaybe<Boolean_comparison_exp>;
  new_orderrevenue_Base?: InputMaybe<numeric_comparison_exp>;
  new_postnumber?: InputMaybe<Int_comparison_exp>;
  new_reportexclude?: InputMaybe<Boolean_comparison_exp>;
  new_sendemailrenewal?: InputMaybe<String_comparison_exp>;
  new_skypegroup?: InputMaybe<Boolean_comparison_exp>;
  new_totalorder_Base?: InputMaybe<numeric_comparison_exp>;
  new_voonixadvertiser?: InputMaybe<String_comparison_exp>;
  new_voonixaffsystem?: InputMaybe<Int_comparison_exp>;
  new_voonixsaved?: InputMaybe<Boolean_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paid_amount?: InputMaybe<numeric_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  project_id?: InputMaybe<Int_comparison_exp>;
  registrationemail_id?: InputMaybe<Int_comparison_exp>;
  tracker_clicks_last_30_days?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.SalesOrderBase" */
export enum v1_SalesOrderBase_constraint {
  /** unique or primary key constraint on columns "id" */
  SalesOrderBase_id_key = 'SalesOrderBase_id_key',
  /** unique or primary key constraint on columns "id" */
  SalesOrderBase_pkey = 'SalesOrderBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_inc_input = {
  PriorityCode?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  affiliate_project?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateDealType?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateOrderType?: InputMaybe<Scalars['Int']['input']>;
  new_Clicks?: InputMaybe<Scalars['Int']['input']>;
  new_For?: InputMaybe<Scalars['Int']['input']>;
  new_InvoicesNotPaid?: InputMaybe<Scalars['Int']['input']>;
  new_InvoicesNotPaidAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_OrdeMode?: InputMaybe<Scalars['Int']['input']>;
  new_OrderRevenue?: InputMaybe<Scalars['numeric']['input']>;
  new_OrderSubscriptionType?: InputMaybe<Scalars['Int']['input']>;
  new_OrderType?: InputMaybe<Scalars['Int']['input']>;
  new_PayTo?: InputMaybe<Scalars['Int']['input']>;
  new_PostStrategy?: InputMaybe<Scalars['Int']['input']>;
  new_TotalOrder?: InputMaybe<Scalars['numeric']['input']>;
  new_orderrevenue_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_postnumber?: InputMaybe<Scalars['Int']['input']>;
  new_totalorder_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_voonixaffsystem?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  registrationemail_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_insert_input = {
  AffDealType?: InputMaybe<v1_AffDealType_obj_rel_insert_input>;
  AffiliateProjectNew?: InputMaybe<v1_new_affordertypeBase_obj_rel_insert_input>;
  CompanyNew?: InputMaybe<v1_AccountBase_obj_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Invoices?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrderFor?: InputMaybe<v1_OrderFor_obj_rel_insert_input>;
  OrderPayTo?: InputMaybe<v1_PaymentCompanies_obj_rel_insert_input>;
  OrderType?: InputMaybe<v1_OrderType_obj_rel_insert_input>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_obj_rel_insert_input>;
  Priority?: InputMaybe<v1_OrderPriority_obj_rel_insert_input>;
  PriorityCode?: InputMaybe<Scalars['Int']['input']>;
  Project?: InputMaybe<v1_Project_obj_rel_insert_input>;
  RegEmail?: InputMaybe<v1_new_regemailBase_obj_rel_insert_input>;
  SalesOrderId?: InputMaybe<Scalars['uuid']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  Trackers?: InputMaybe<v1_new_trackerBase_arr_rel_insert_input>;
  TraversedPath?: InputMaybe<Scalars['String']['input']>;
  affiliate_project?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_1InvoiceNOrdersId?: InputMaybe<Scalars['uuid']['input']>;
  new_AffOrderType?: InputMaybe<Scalars['uuid']['input']>;
  new_AffiliateAccountPassword?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateDealType?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateOrderType?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateWebsite?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateaccountUsername?: InputMaybe<Scalars['String']['input']>;
  new_Calculateposts?: InputMaybe<Scalars['Boolean']['input']>;
  new_Clicks?: InputMaybe<Scalars['Int']['input']>;
  new_Country?: InputMaybe<Scalars['uuid']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_DataStarted?: InputMaybe<Scalars['timestamp']['input']>;
  new_DateEnded?: InputMaybe<Scalars['timestamp']['input']>;
  new_For?: InputMaybe<Scalars['Int']['input']>;
  new_Invoiced?: InputMaybe<Scalars['Boolean']['input']>;
  new_InvoicesNotPaid?: InputMaybe<Scalars['Int']['input']>;
  new_InvoicesNotPaidAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_LastCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_OpenInIframe?: InputMaybe<Scalars['Boolean']['input']>;
  new_OrdeMode?: InputMaybe<Scalars['Int']['input']>;
  new_OrderRevenue?: InputMaybe<Scalars['numeric']['input']>;
  new_OrderSubscriptionType?: InputMaybe<Scalars['Int']['input']>;
  new_OrderType?: InputMaybe<Scalars['Int']['input']>;
  new_PayTo?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentFrom?: InputMaybe<Scalars['String']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['uuid']['input']>;
  new_PostStrategy?: InputMaybe<Scalars['Int']['input']>;
  new_RegistrationEmail?: InputMaybe<Scalars['uuid']['input']>;
  new_Renewal?: InputMaybe<Scalars['Boolean']['input']>;
  new_RunningSince?: InputMaybe<Scalars['timestamp']['input']>;
  new_TotalOrder?: InputMaybe<Scalars['numeric']['input']>;
  new_affidnumber?: InputMaybe<Scalars['String']['input']>;
  new_affiliatebrands?: InputMaybe<Scalars['String']['input']>;
  new_hastrackers?: InputMaybe<Scalars['Boolean']['input']>;
  new_nifty?: InputMaybe<Scalars['Boolean']['input']>;
  new_orderrevenue_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_postnumber?: InputMaybe<Scalars['Int']['input']>;
  new_reportexclude?: InputMaybe<Scalars['Boolean']['input']>;
  new_sendemailrenewal?: InputMaybe<Scalars['String']['input']>;
  new_skypegroup?: InputMaybe<Scalars['Boolean']['input']>;
  new_totalorder_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_voonixadvertiser?: InputMaybe<Scalars['String']['input']>;
  new_voonixaffsystem?: InputMaybe<Scalars['Int']['input']>;
  new_voonixsaved?: InputMaybe<Scalars['Boolean']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  registrationemail_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_SalesOrderBase_max_fields = {
  __typename?: 'v1_SalesOrderBase_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  PriorityCode?: Maybe<Scalars['Int']['output']>;
  SalesOrderId?: Maybe<Scalars['uuid']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TraversedPath?: Maybe<Scalars['String']['output']>;
  affiliate_project?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_1InvoiceNOrdersId?: Maybe<Scalars['uuid']['output']>;
  new_AffOrderType?: Maybe<Scalars['uuid']['output']>;
  new_AffiliateAccountPassword?: Maybe<Scalars['String']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Int']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Int']['output']>;
  new_AffiliateWebsite?: Maybe<Scalars['String']['output']>;
  new_AffiliateaccountUsername?: Maybe<Scalars['String']['output']>;
  new_Clicks?: Maybe<Scalars['Int']['output']>;
  new_Country?: Maybe<Scalars['uuid']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_DataStarted?: Maybe<Scalars['timestamp']['output']>;
  new_DateEnded?: Maybe<Scalars['timestamp']['output']>;
  new_For?: Maybe<Scalars['Int']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Int']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['numeric']['output']>;
  new_LastCheck?: Maybe<Scalars['timestamp']['output']>;
  new_OrdeMode?: Maybe<Scalars['Int']['output']>;
  new_OrderRevenue?: Maybe<Scalars['numeric']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Int']['output']>;
  new_OrderType?: Maybe<Scalars['Int']['output']>;
  new_PayTo?: Maybe<Scalars['Int']['output']>;
  new_PaymentFrom?: Maybe<Scalars['String']['output']>;
  new_PaymentMethod?: Maybe<Scalars['uuid']['output']>;
  new_PostStrategy?: Maybe<Scalars['Int']['output']>;
  new_RegistrationEmail?: Maybe<Scalars['uuid']['output']>;
  new_RunningSince?: Maybe<Scalars['timestamp']['output']>;
  new_TotalOrder?: Maybe<Scalars['numeric']['output']>;
  new_affidnumber?: Maybe<Scalars['String']['output']>;
  new_affiliatebrands?: Maybe<Scalars['String']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['numeric']['output']>;
  new_postnumber?: Maybe<Scalars['Int']['output']>;
  new_sendemailrenewal?: Maybe<Scalars['String']['output']>;
  new_totalorder_Base?: Maybe<Scalars['numeric']['output']>;
  new_voonixadvertiser?: Maybe<Scalars['String']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  registrationemail_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_max_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  Name?: InputMaybe<order_by>;
  PriorityCode?: InputMaybe<order_by>;
  SalesOrderId?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TraversedPath?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_1InvoiceNOrdersId?: InputMaybe<order_by>;
  new_AffOrderType?: InputMaybe<order_by>;
  new_AffiliateAccountPassword?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_AffiliateWebsite?: InputMaybe<order_by>;
  new_AffiliateaccountUsername?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Country?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_DataStarted?: InputMaybe<order_by>;
  new_DateEnded?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_LastCheck?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentFrom?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_RegistrationEmail?: InputMaybe<order_by>;
  new_RunningSince?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_affidnumber?: InputMaybe<order_by>;
  new_affiliatebrands?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_sendemailrenewal?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixadvertiser?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_SalesOrderBase_min_fields = {
  __typename?: 'v1_SalesOrderBase_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  PriorityCode?: Maybe<Scalars['Int']['output']>;
  SalesOrderId?: Maybe<Scalars['uuid']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TraversedPath?: Maybe<Scalars['String']['output']>;
  affiliate_project?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_1InvoiceNOrdersId?: Maybe<Scalars['uuid']['output']>;
  new_AffOrderType?: Maybe<Scalars['uuid']['output']>;
  new_AffiliateAccountPassword?: Maybe<Scalars['String']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Int']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Int']['output']>;
  new_AffiliateWebsite?: Maybe<Scalars['String']['output']>;
  new_AffiliateaccountUsername?: Maybe<Scalars['String']['output']>;
  new_Clicks?: Maybe<Scalars['Int']['output']>;
  new_Country?: Maybe<Scalars['uuid']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_DataStarted?: Maybe<Scalars['timestamp']['output']>;
  new_DateEnded?: Maybe<Scalars['timestamp']['output']>;
  new_For?: Maybe<Scalars['Int']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Int']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['numeric']['output']>;
  new_LastCheck?: Maybe<Scalars['timestamp']['output']>;
  new_OrdeMode?: Maybe<Scalars['Int']['output']>;
  new_OrderRevenue?: Maybe<Scalars['numeric']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Int']['output']>;
  new_OrderType?: Maybe<Scalars['Int']['output']>;
  new_PayTo?: Maybe<Scalars['Int']['output']>;
  new_PaymentFrom?: Maybe<Scalars['String']['output']>;
  new_PaymentMethod?: Maybe<Scalars['uuid']['output']>;
  new_PostStrategy?: Maybe<Scalars['Int']['output']>;
  new_RegistrationEmail?: Maybe<Scalars['uuid']['output']>;
  new_RunningSince?: Maybe<Scalars['timestamp']['output']>;
  new_TotalOrder?: Maybe<Scalars['numeric']['output']>;
  new_affidnumber?: Maybe<Scalars['String']['output']>;
  new_affiliatebrands?: Maybe<Scalars['String']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['numeric']['output']>;
  new_postnumber?: Maybe<Scalars['Int']['output']>;
  new_sendemailrenewal?: Maybe<Scalars['String']['output']>;
  new_totalorder_Base?: Maybe<Scalars['numeric']['output']>;
  new_voonixadvertiser?: Maybe<Scalars['String']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  registrationemail_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_min_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  Name?: InputMaybe<order_by>;
  PriorityCode?: InputMaybe<order_by>;
  SalesOrderId?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  TraversedPath?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_1InvoiceNOrdersId?: InputMaybe<order_by>;
  new_AffOrderType?: InputMaybe<order_by>;
  new_AffiliateAccountPassword?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_AffiliateWebsite?: InputMaybe<order_by>;
  new_AffiliateaccountUsername?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Country?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_DataStarted?: InputMaybe<order_by>;
  new_DateEnded?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_LastCheck?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentFrom?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_RegistrationEmail?: InputMaybe<order_by>;
  new_RunningSince?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_affidnumber?: InputMaybe<order_by>;
  new_affiliatebrands?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_sendemailrenewal?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixadvertiser?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_mutation_response = {
  __typename?: 'v1_SalesOrderBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_SalesOrderBase>;
};

/** input type for inserting object relation for remote table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_obj_rel_insert_input = {
  data: v1_SalesOrderBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_SalesOrderBase_on_conflict>;
};

/** on_conflict condition type for table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_on_conflict = {
  constraint: v1_SalesOrderBase_constraint;
  update_columns?: Array<v1_SalesOrderBase_update_column>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.SalesOrderBase". */
export type v1_SalesOrderBase_order_by = {
  AffDealType?: InputMaybe<v1_AffDealType_order_by>;
  AffiliateProjectNew?: InputMaybe<v1_new_affordertypeBase_order_by>;
  CompanyNew?: InputMaybe<v1_AccountBase_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  Description?: InputMaybe<order_by>;
  Invoices_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  Name?: InputMaybe<order_by>;
  NotPaidAmount?: InputMaybe<order_by>;
  NotPaidInvoices?: InputMaybe<order_by>;
  OrderFor?: InputMaybe<v1_OrderFor_order_by>;
  OrderPayTo?: InputMaybe<v1_PaymentCompanies_order_by>;
  OrderType?: InputMaybe<v1_OrderType_order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  PaidInvoices?: InputMaybe<order_by>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_order_by>;
  Priority?: InputMaybe<v1_OrderPriority_order_by>;
  PriorityCode?: InputMaybe<order_by>;
  Project?: InputMaybe<v1_Project_order_by>;
  RegEmail?: InputMaybe<v1_new_regemailBase_order_by>;
  SalesOrderId?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  Trackers_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_order_by>;
  TraversedPath?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  hasOpenInvoices?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_1InvoiceNOrdersId?: InputMaybe<order_by>;
  new_AffOrderType?: InputMaybe<order_by>;
  new_AffiliateAccountPassword?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_AffiliateWebsite?: InputMaybe<order_by>;
  new_AffiliateaccountUsername?: InputMaybe<order_by>;
  new_Calculateposts?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Country?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_DataStarted?: InputMaybe<order_by>;
  new_DateEnded?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_Invoiced?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_LastCheck?: InputMaybe<order_by>;
  new_OpenInIframe?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PaymentFrom?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_RegistrationEmail?: InputMaybe<order_by>;
  new_Renewal?: InputMaybe<order_by>;
  new_RunningSince?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_affidnumber?: InputMaybe<order_by>;
  new_affiliatebrands?: InputMaybe<order_by>;
  new_hastrackers?: InputMaybe<order_by>;
  new_nifty?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_reportexclude?: InputMaybe<order_by>;
  new_sendemailrenewal?: InputMaybe<order_by>;
  new_skypegroup?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixadvertiser?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  new_voonixsaved?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paid_amount?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
  tracker_clicks_last_30_days?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.SalesOrderBase */
export type v1_SalesOrderBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.SalesOrderBase" */
export enum v1_SalesOrderBase_select_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  Name = 'Name',
  /** column name */
  PriorityCode = 'PriorityCode',
  /** column name */
  SalesOrderId = 'SalesOrderId',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  TraversedPath = 'TraversedPath',
  /** column name */
  affiliate_project = 'affiliate_project',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  new_1InvoiceNOrdersId = 'new_1InvoiceNOrdersId',
  /** column name */
  new_AffOrderType = 'new_AffOrderType',
  /** column name */
  new_AffiliateAccountPassword = 'new_AffiliateAccountPassword',
  /** column name */
  new_AffiliateDealType = 'new_AffiliateDealType',
  /** column name */
  new_AffiliateOrderType = 'new_AffiliateOrderType',
  /** column name */
  new_AffiliateWebsite = 'new_AffiliateWebsite',
  /** column name */
  new_AffiliateaccountUsername = 'new_AffiliateaccountUsername',
  /** column name */
  new_Calculateposts = 'new_Calculateposts',
  /** column name */
  new_Clicks = 'new_Clicks',
  /** column name */
  new_Country = 'new_Country',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_DataStarted = 'new_DataStarted',
  /** column name */
  new_DateEnded = 'new_DateEnded',
  /** column name */
  new_For = 'new_For',
  /** column name */
  new_Invoiced = 'new_Invoiced',
  /** column name */
  new_InvoicesNotPaid = 'new_InvoicesNotPaid',
  /** column name */
  new_InvoicesNotPaidAmount = 'new_InvoicesNotPaidAmount',
  /** column name */
  new_LastCheck = 'new_LastCheck',
  /** column name */
  new_OpenInIframe = 'new_OpenInIframe',
  /** column name */
  new_OrdeMode = 'new_OrdeMode',
  /** column name */
  new_OrderRevenue = 'new_OrderRevenue',
  /** column name */
  new_OrderSubscriptionType = 'new_OrderSubscriptionType',
  /** column name */
  new_OrderType = 'new_OrderType',
  /** column name */
  new_PayTo = 'new_PayTo',
  /** column name */
  new_PaymentFrom = 'new_PaymentFrom',
  /** column name */
  new_PaymentMethod = 'new_PaymentMethod',
  /** column name */
  new_PostStrategy = 'new_PostStrategy',
  /** column name */
  new_RegistrationEmail = 'new_RegistrationEmail',
  /** column name */
  new_Renewal = 'new_Renewal',
  /** column name */
  new_RunningSince = 'new_RunningSince',
  /** column name */
  new_TotalOrder = 'new_TotalOrder',
  /** column name */
  new_affidnumber = 'new_affidnumber',
  /** column name */
  new_affiliatebrands = 'new_affiliatebrands',
  /** column name */
  new_hastrackers = 'new_hastrackers',
  /** column name */
  new_nifty = 'new_nifty',
  /** column name */
  new_orderrevenue_Base = 'new_orderrevenue_Base',
  /** column name */
  new_postnumber = 'new_postnumber',
  /** column name */
  new_reportexclude = 'new_reportexclude',
  /** column name */
  new_sendemailrenewal = 'new_sendemailrenewal',
  /** column name */
  new_skypegroup = 'new_skypegroup',
  /** column name */
  new_totalorder_Base = 'new_totalorder_Base',
  /** column name */
  new_voonixadvertiser = 'new_voonixadvertiser',
  /** column name */
  new_voonixaffsystem = 'new_voonixaffsystem',
  /** column name */
  new_voonixsaved = 'new_voonixsaved',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  registrationemail_id = 'registrationemail_id'
}

/** select "v1_SalesOrderBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.SalesOrderBase" */
export enum v1_SalesOrderBase_select_column_v1_SalesOrderBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  new_Calculateposts = 'new_Calculateposts',
  /** column name */
  new_Invoiced = 'new_Invoiced',
  /** column name */
  new_OpenInIframe = 'new_OpenInIframe',
  /** column name */
  new_Renewal = 'new_Renewal',
  /** column name */
  new_hastrackers = 'new_hastrackers',
  /** column name */
  new_nifty = 'new_nifty',
  /** column name */
  new_reportexclude = 'new_reportexclude',
  /** column name */
  new_skypegroup = 'new_skypegroup',
  /** column name */
  new_voonixsaved = 'new_voonixsaved'
}

/** select "v1_SalesOrderBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.SalesOrderBase" */
export enum v1_SalesOrderBase_select_column_v1_SalesOrderBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  new_Calculateposts = 'new_Calculateposts',
  /** column name */
  new_Invoiced = 'new_Invoiced',
  /** column name */
  new_OpenInIframe = 'new_OpenInIframe',
  /** column name */
  new_Renewal = 'new_Renewal',
  /** column name */
  new_hastrackers = 'new_hastrackers',
  /** column name */
  new_nifty = 'new_nifty',
  /** column name */
  new_reportexclude = 'new_reportexclude',
  /** column name */
  new_skypegroup = 'new_skypegroup',
  /** column name */
  new_voonixsaved = 'new_voonixsaved'
}

/** input type for updating data in table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_set_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  PriorityCode?: InputMaybe<Scalars['Int']['input']>;
  SalesOrderId?: InputMaybe<Scalars['uuid']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  TraversedPath?: InputMaybe<Scalars['String']['input']>;
  affiliate_project?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_1InvoiceNOrdersId?: InputMaybe<Scalars['uuid']['input']>;
  new_AffOrderType?: InputMaybe<Scalars['uuid']['input']>;
  new_AffiliateAccountPassword?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateDealType?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateOrderType?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateWebsite?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateaccountUsername?: InputMaybe<Scalars['String']['input']>;
  new_Calculateposts?: InputMaybe<Scalars['Boolean']['input']>;
  new_Clicks?: InputMaybe<Scalars['Int']['input']>;
  new_Country?: InputMaybe<Scalars['uuid']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_DataStarted?: InputMaybe<Scalars['timestamp']['input']>;
  new_DateEnded?: InputMaybe<Scalars['timestamp']['input']>;
  new_For?: InputMaybe<Scalars['Int']['input']>;
  new_Invoiced?: InputMaybe<Scalars['Boolean']['input']>;
  new_InvoicesNotPaid?: InputMaybe<Scalars['Int']['input']>;
  new_InvoicesNotPaidAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_LastCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_OpenInIframe?: InputMaybe<Scalars['Boolean']['input']>;
  new_OrdeMode?: InputMaybe<Scalars['Int']['input']>;
  new_OrderRevenue?: InputMaybe<Scalars['numeric']['input']>;
  new_OrderSubscriptionType?: InputMaybe<Scalars['Int']['input']>;
  new_OrderType?: InputMaybe<Scalars['Int']['input']>;
  new_PayTo?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentFrom?: InputMaybe<Scalars['String']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['uuid']['input']>;
  new_PostStrategy?: InputMaybe<Scalars['Int']['input']>;
  new_RegistrationEmail?: InputMaybe<Scalars['uuid']['input']>;
  new_Renewal?: InputMaybe<Scalars['Boolean']['input']>;
  new_RunningSince?: InputMaybe<Scalars['timestamp']['input']>;
  new_TotalOrder?: InputMaybe<Scalars['numeric']['input']>;
  new_affidnumber?: InputMaybe<Scalars['String']['input']>;
  new_affiliatebrands?: InputMaybe<Scalars['String']['input']>;
  new_hastrackers?: InputMaybe<Scalars['Boolean']['input']>;
  new_nifty?: InputMaybe<Scalars['Boolean']['input']>;
  new_orderrevenue_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_postnumber?: InputMaybe<Scalars['Int']['input']>;
  new_reportexclude?: InputMaybe<Scalars['Boolean']['input']>;
  new_sendemailrenewal?: InputMaybe<Scalars['String']['input']>;
  new_skypegroup?: InputMaybe<Scalars['Boolean']['input']>;
  new_totalorder_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_voonixadvertiser?: InputMaybe<Scalars['String']['input']>;
  new_voonixaffsystem?: InputMaybe<Scalars['Int']['input']>;
  new_voonixsaved?: InputMaybe<Scalars['Boolean']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  registrationemail_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_SalesOrderBase_stddev_fields = {
  __typename?: 'v1_SalesOrderBase_stddev_fields';
  PriorityCode?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_project?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Float']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_For?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['Float']['output']>;
  new_OrdeMode?: Maybe<Scalars['Float']['output']>;
  new_OrderRevenue?: Maybe<Scalars['Float']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PostStrategy?: Maybe<Scalars['Float']['output']>;
  new_TotalOrder?: Maybe<Scalars['Float']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['Float']['output']>;
  new_postnumber?: Maybe<Scalars['Float']['output']>;
  new_totalorder_Base?: Maybe<Scalars['Float']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  registrationemail_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_stddev_order_by = {
  PriorityCode?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_SalesOrderBase_stddev_pop_fields = {
  __typename?: 'v1_SalesOrderBase_stddev_pop_fields';
  PriorityCode?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_project?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Float']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_For?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['Float']['output']>;
  new_OrdeMode?: Maybe<Scalars['Float']['output']>;
  new_OrderRevenue?: Maybe<Scalars['Float']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PostStrategy?: Maybe<Scalars['Float']['output']>;
  new_TotalOrder?: Maybe<Scalars['Float']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['Float']['output']>;
  new_postnumber?: Maybe<Scalars['Float']['output']>;
  new_totalorder_Base?: Maybe<Scalars['Float']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  registrationemail_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_stddev_pop_order_by = {
  PriorityCode?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_SalesOrderBase_stddev_samp_fields = {
  __typename?: 'v1_SalesOrderBase_stddev_samp_fields';
  PriorityCode?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_project?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Float']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_For?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['Float']['output']>;
  new_OrdeMode?: Maybe<Scalars['Float']['output']>;
  new_OrderRevenue?: Maybe<Scalars['Float']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PostStrategy?: Maybe<Scalars['Float']['output']>;
  new_TotalOrder?: Maybe<Scalars['Float']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['Float']['output']>;
  new_postnumber?: Maybe<Scalars['Float']['output']>;
  new_totalorder_Base?: Maybe<Scalars['Float']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  registrationemail_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_stddev_samp_order_by = {
  PriorityCode?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_SalesOrderBase" */
export type v1_SalesOrderBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_SalesOrderBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_SalesOrderBase_stream_cursor_value_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  PriorityCode?: InputMaybe<Scalars['Int']['input']>;
  SalesOrderId?: InputMaybe<Scalars['uuid']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  TraversedPath?: InputMaybe<Scalars['String']['input']>;
  affiliate_project?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_1InvoiceNOrdersId?: InputMaybe<Scalars['uuid']['input']>;
  new_AffOrderType?: InputMaybe<Scalars['uuid']['input']>;
  new_AffiliateAccountPassword?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateDealType?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateOrderType?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateWebsite?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateaccountUsername?: InputMaybe<Scalars['String']['input']>;
  new_Calculateposts?: InputMaybe<Scalars['Boolean']['input']>;
  new_Clicks?: InputMaybe<Scalars['Int']['input']>;
  new_Country?: InputMaybe<Scalars['uuid']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_DataStarted?: InputMaybe<Scalars['timestamp']['input']>;
  new_DateEnded?: InputMaybe<Scalars['timestamp']['input']>;
  new_For?: InputMaybe<Scalars['Int']['input']>;
  new_Invoiced?: InputMaybe<Scalars['Boolean']['input']>;
  new_InvoicesNotPaid?: InputMaybe<Scalars['Int']['input']>;
  new_InvoicesNotPaidAmount?: InputMaybe<Scalars['numeric']['input']>;
  new_LastCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_OpenInIframe?: InputMaybe<Scalars['Boolean']['input']>;
  new_OrdeMode?: InputMaybe<Scalars['Int']['input']>;
  new_OrderRevenue?: InputMaybe<Scalars['numeric']['input']>;
  new_OrderSubscriptionType?: InputMaybe<Scalars['Int']['input']>;
  new_OrderType?: InputMaybe<Scalars['Int']['input']>;
  new_PayTo?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentFrom?: InputMaybe<Scalars['String']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['uuid']['input']>;
  new_PostStrategy?: InputMaybe<Scalars['Int']['input']>;
  new_RegistrationEmail?: InputMaybe<Scalars['uuid']['input']>;
  new_Renewal?: InputMaybe<Scalars['Boolean']['input']>;
  new_RunningSince?: InputMaybe<Scalars['timestamp']['input']>;
  new_TotalOrder?: InputMaybe<Scalars['numeric']['input']>;
  new_affidnumber?: InputMaybe<Scalars['String']['input']>;
  new_affiliatebrands?: InputMaybe<Scalars['String']['input']>;
  new_hastrackers?: InputMaybe<Scalars['Boolean']['input']>;
  new_nifty?: InputMaybe<Scalars['Boolean']['input']>;
  new_orderrevenue_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_postnumber?: InputMaybe<Scalars['Int']['input']>;
  new_reportexclude?: InputMaybe<Scalars['Boolean']['input']>;
  new_sendemailrenewal?: InputMaybe<Scalars['String']['input']>;
  new_skypegroup?: InputMaybe<Scalars['Boolean']['input']>;
  new_totalorder_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_voonixadvertiser?: InputMaybe<Scalars['String']['input']>;
  new_voonixaffsystem?: InputMaybe<Scalars['Int']['input']>;
  new_voonixsaved?: InputMaybe<Scalars['Boolean']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  registrationemail_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_SalesOrderBase_sum_fields = {
  __typename?: 'v1_SalesOrderBase_sum_fields';
  PriorityCode?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  affiliate_project?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Int']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Int']['output']>;
  new_Clicks?: Maybe<Scalars['Int']['output']>;
  new_For?: Maybe<Scalars['Int']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Int']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['numeric']['output']>;
  new_OrdeMode?: Maybe<Scalars['Int']['output']>;
  new_OrderRevenue?: Maybe<Scalars['numeric']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Int']['output']>;
  new_OrderType?: Maybe<Scalars['Int']['output']>;
  new_PayTo?: Maybe<Scalars['Int']['output']>;
  new_PostStrategy?: Maybe<Scalars['Int']['output']>;
  new_TotalOrder?: Maybe<Scalars['numeric']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['numeric']['output']>;
  new_postnumber?: Maybe<Scalars['Int']['output']>;
  new_totalorder_Base?: Maybe<Scalars['numeric']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  registrationemail_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_sum_order_by = {
  PriorityCode?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.SalesOrderBase" */
export enum v1_SalesOrderBase_update_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  Name = 'Name',
  /** column name */
  PriorityCode = 'PriorityCode',
  /** column name */
  SalesOrderId = 'SalesOrderId',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  TraversedPath = 'TraversedPath',
  /** column name */
  affiliate_project = 'affiliate_project',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  new_1InvoiceNOrdersId = 'new_1InvoiceNOrdersId',
  /** column name */
  new_AffOrderType = 'new_AffOrderType',
  /** column name */
  new_AffiliateAccountPassword = 'new_AffiliateAccountPassword',
  /** column name */
  new_AffiliateDealType = 'new_AffiliateDealType',
  /** column name */
  new_AffiliateOrderType = 'new_AffiliateOrderType',
  /** column name */
  new_AffiliateWebsite = 'new_AffiliateWebsite',
  /** column name */
  new_AffiliateaccountUsername = 'new_AffiliateaccountUsername',
  /** column name */
  new_Calculateposts = 'new_Calculateposts',
  /** column name */
  new_Clicks = 'new_Clicks',
  /** column name */
  new_Country = 'new_Country',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_DataStarted = 'new_DataStarted',
  /** column name */
  new_DateEnded = 'new_DateEnded',
  /** column name */
  new_For = 'new_For',
  /** column name */
  new_Invoiced = 'new_Invoiced',
  /** column name */
  new_InvoicesNotPaid = 'new_InvoicesNotPaid',
  /** column name */
  new_InvoicesNotPaidAmount = 'new_InvoicesNotPaidAmount',
  /** column name */
  new_LastCheck = 'new_LastCheck',
  /** column name */
  new_OpenInIframe = 'new_OpenInIframe',
  /** column name */
  new_OrdeMode = 'new_OrdeMode',
  /** column name */
  new_OrderRevenue = 'new_OrderRevenue',
  /** column name */
  new_OrderSubscriptionType = 'new_OrderSubscriptionType',
  /** column name */
  new_OrderType = 'new_OrderType',
  /** column name */
  new_PayTo = 'new_PayTo',
  /** column name */
  new_PaymentFrom = 'new_PaymentFrom',
  /** column name */
  new_PaymentMethod = 'new_PaymentMethod',
  /** column name */
  new_PostStrategy = 'new_PostStrategy',
  /** column name */
  new_RegistrationEmail = 'new_RegistrationEmail',
  /** column name */
  new_Renewal = 'new_Renewal',
  /** column name */
  new_RunningSince = 'new_RunningSince',
  /** column name */
  new_TotalOrder = 'new_TotalOrder',
  /** column name */
  new_affidnumber = 'new_affidnumber',
  /** column name */
  new_affiliatebrands = 'new_affiliatebrands',
  /** column name */
  new_hastrackers = 'new_hastrackers',
  /** column name */
  new_nifty = 'new_nifty',
  /** column name */
  new_orderrevenue_Base = 'new_orderrevenue_Base',
  /** column name */
  new_postnumber = 'new_postnumber',
  /** column name */
  new_reportexclude = 'new_reportexclude',
  /** column name */
  new_sendemailrenewal = 'new_sendemailrenewal',
  /** column name */
  new_skypegroup = 'new_skypegroup',
  /** column name */
  new_totalorder_Base = 'new_totalorder_Base',
  /** column name */
  new_voonixadvertiser = 'new_voonixadvertiser',
  /** column name */
  new_voonixaffsystem = 'new_voonixaffsystem',
  /** column name */
  new_voonixsaved = 'new_voonixsaved',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  registrationemail_id = 'registrationemail_id'
}

export type v1_SalesOrderBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_SalesOrderBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_SalesOrderBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_SalesOrderBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_SalesOrderBase_var_pop_fields = {
  __typename?: 'v1_SalesOrderBase_var_pop_fields';
  PriorityCode?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_project?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Float']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_For?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['Float']['output']>;
  new_OrdeMode?: Maybe<Scalars['Float']['output']>;
  new_OrderRevenue?: Maybe<Scalars['Float']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PostStrategy?: Maybe<Scalars['Float']['output']>;
  new_TotalOrder?: Maybe<Scalars['Float']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['Float']['output']>;
  new_postnumber?: Maybe<Scalars['Float']['output']>;
  new_totalorder_Base?: Maybe<Scalars['Float']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  registrationemail_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_var_pop_order_by = {
  PriorityCode?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_SalesOrderBase_var_samp_fields = {
  __typename?: 'v1_SalesOrderBase_var_samp_fields';
  PriorityCode?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_project?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Float']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_For?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['Float']['output']>;
  new_OrdeMode?: Maybe<Scalars['Float']['output']>;
  new_OrderRevenue?: Maybe<Scalars['Float']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PostStrategy?: Maybe<Scalars['Float']['output']>;
  new_TotalOrder?: Maybe<Scalars['Float']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['Float']['output']>;
  new_postnumber?: Maybe<Scalars['Float']['output']>;
  new_totalorder_Base?: Maybe<Scalars['Float']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  registrationemail_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_var_samp_order_by = {
  PriorityCode?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_SalesOrderBase_variance_fields = {
  __typename?: 'v1_SalesOrderBase_variance_fields';
  PriorityCode?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  affiliate_project?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_AffiliateDealType?: Maybe<Scalars['Float']['output']>;
  new_AffiliateOrderType?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_For?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaid?: Maybe<Scalars['Float']['output']>;
  new_InvoicesNotPaidAmount?: Maybe<Scalars['Float']['output']>;
  new_OrdeMode?: Maybe<Scalars['Float']['output']>;
  new_OrderRevenue?: Maybe<Scalars['Float']['output']>;
  new_OrderSubscriptionType?: Maybe<Scalars['Float']['output']>;
  new_OrderType?: Maybe<Scalars['Float']['output']>;
  new_PayTo?: Maybe<Scalars['Float']['output']>;
  new_PostStrategy?: Maybe<Scalars['Float']['output']>;
  new_TotalOrder?: Maybe<Scalars['Float']['output']>;
  new_orderrevenue_Base?: Maybe<Scalars['Float']['output']>;
  new_postnumber?: Maybe<Scalars['Float']['output']>;
  new_totalorder_Base?: Maybe<Scalars['Float']['output']>;
  new_voonixaffsystem?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  registrationemail_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.SalesOrderBase" */
export type v1_SalesOrderBase_variance_order_by = {
  PriorityCode?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  affiliate_project?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AffiliateDealType?: InputMaybe<order_by>;
  new_AffiliateOrderType?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_For?: InputMaybe<order_by>;
  new_InvoicesNotPaid?: InputMaybe<order_by>;
  new_InvoicesNotPaidAmount?: InputMaybe<order_by>;
  new_OrdeMode?: InputMaybe<order_by>;
  new_OrderRevenue?: InputMaybe<order_by>;
  new_OrderSubscriptionType?: InputMaybe<order_by>;
  new_OrderType?: InputMaybe<order_by>;
  new_PayTo?: InputMaybe<order_by>;
  new_PostStrategy?: InputMaybe<order_by>;
  new_TotalOrder?: InputMaybe<order_by>;
  new_orderrevenue_Base?: InputMaybe<order_by>;
  new_postnumber?: InputMaybe<order_by>;
  new_totalorder_Base?: InputMaybe<order_by>;
  new_voonixaffsystem?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  registrationemail_id?: InputMaybe<order_by>;
};

/** Services Received */
export type v1_ServiceTypes = {
  __typename?: 'v1_ServiceTypes';
  /** An array relationship */
  AccountBases: Array<v1_AccountBase>;
  /** An aggregate relationship */
  AccountBases_aggregate: v1_AccountBase_aggregate;
  /** An array relationship */
  Credentials: Array<v1_Credentials>;
  /** An aggregate relationship */
  Credentials_aggregate: v1_Credentials_aggregate;
  /** An array relationship */
  PurchaseOrderServiceType: Array<v1_new_POBase>;
  /** An aggregate relationship */
  PurchaseOrderServiceType_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  RecurringPayments: Array<v1_RecurringPayments>;
  /** An aggregate relationship */
  RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


/** Services Received */
export type v1_ServiceTypesAccountBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** Services Received */
export type v1_ServiceTypesAccountBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** Services Received */
export type v1_ServiceTypesCredentialsArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


/** Services Received */
export type v1_ServiceTypesCredentials_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


/** Services Received */
export type v1_ServiceTypesPurchaseOrderServiceTypeArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Services Received */
export type v1_ServiceTypesPurchaseOrderServiceType_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** Services Received */
export type v1_ServiceTypesRecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** Services Received */
export type v1_ServiceTypesRecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};

/** aggregated selection of "v1.ServiceTypes" */
export type v1_ServiceTypes_aggregate = {
  __typename?: 'v1_ServiceTypes_aggregate';
  aggregate?: Maybe<v1_ServiceTypes_aggregate_fields>;
  nodes: Array<v1_ServiceTypes>;
};

/** aggregate fields of "v1.ServiceTypes" */
export type v1_ServiceTypes_aggregate_fields = {
  __typename?: 'v1_ServiceTypes_aggregate_fields';
  avg?: Maybe<v1_ServiceTypes_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_ServiceTypes_max_fields>;
  min?: Maybe<v1_ServiceTypes_min_fields>;
  stddev?: Maybe<v1_ServiceTypes_stddev_fields>;
  stddev_pop?: Maybe<v1_ServiceTypes_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_ServiceTypes_stddev_samp_fields>;
  sum?: Maybe<v1_ServiceTypes_sum_fields>;
  var_pop?: Maybe<v1_ServiceTypes_var_pop_fields>;
  var_samp?: Maybe<v1_ServiceTypes_var_samp_fields>;
  variance?: Maybe<v1_ServiceTypes_variance_fields>;
};


/** aggregate fields of "v1.ServiceTypes" */
export type v1_ServiceTypes_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_ServiceTypes_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_ServiceTypes_avg_fields = {
  __typename?: 'v1_ServiceTypes_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.ServiceTypes". All fields are combined with a logical 'AND'. */
export type v1_ServiceTypes_bool_exp = {
  AccountBases?: InputMaybe<v1_AccountBase_bool_exp>;
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_bool_exp>;
  Credentials?: InputMaybe<v1_Credentials_bool_exp>;
  Credentials_aggregate?: InputMaybe<v1_Credentials_aggregate_bool_exp>;
  PurchaseOrderServiceType?: InputMaybe<v1_new_POBase_bool_exp>;
  PurchaseOrderServiceType_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_bool_exp>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_ServiceTypes_bool_exp>>;
  _not?: InputMaybe<v1_ServiceTypes_bool_exp>;
  _or?: InputMaybe<Array<v1_ServiceTypes_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.ServiceTypes" */
export enum v1_ServiceTypes_constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTypes_pkey = 'ServiceTypes_pkey'
}

/** input type for incrementing numeric columns in table "v1.ServiceTypes" */
export type v1_ServiceTypes_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.ServiceTypes" */
export type v1_ServiceTypes_insert_input = {
  AccountBases?: InputMaybe<v1_AccountBase_arr_rel_insert_input>;
  Credentials?: InputMaybe<v1_Credentials_arr_rel_insert_input>;
  PurchaseOrderServiceType?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_ServiceTypes_max_fields = {
  __typename?: 'v1_ServiceTypes_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_ServiceTypes_min_fields = {
  __typename?: 'v1_ServiceTypes_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.ServiceTypes" */
export type v1_ServiceTypes_mutation_response = {
  __typename?: 'v1_ServiceTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_ServiceTypes>;
};

/** input type for inserting object relation for remote table "v1.ServiceTypes" */
export type v1_ServiceTypes_obj_rel_insert_input = {
  data: v1_ServiceTypes_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_ServiceTypes_on_conflict>;
};

/** on_conflict condition type for table "v1.ServiceTypes" */
export type v1_ServiceTypes_on_conflict = {
  constraint: v1_ServiceTypes_constraint;
  update_columns?: Array<v1_ServiceTypes_update_column>;
  where?: InputMaybe<v1_ServiceTypes_bool_exp>;
};

/** Ordering options when selecting data from "v1.ServiceTypes". */
export type v1_ServiceTypes_order_by = {
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_order_by>;
  Credentials_aggregate?: InputMaybe<v1_Credentials_aggregate_order_by>;
  PurchaseOrderServiceType_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.ServiceTypes */
export type v1_ServiceTypes_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.ServiceTypes" */
export enum v1_ServiceTypes_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.ServiceTypes" */
export type v1_ServiceTypes_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_ServiceTypes_stddev_fields = {
  __typename?: 'v1_ServiceTypes_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_ServiceTypes_stddev_pop_fields = {
  __typename?: 'v1_ServiceTypes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_ServiceTypes_stddev_samp_fields = {
  __typename?: 'v1_ServiceTypes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_ServiceTypes" */
export type v1_ServiceTypes_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_ServiceTypes_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_ServiceTypes_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_ServiceTypes_sum_fields = {
  __typename?: 'v1_ServiceTypes_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.ServiceTypes" */
export enum v1_ServiceTypes_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_ServiceTypes_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_ServiceTypes_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_ServiceTypes_set_input>;
  /** filter the rows which have to be updated */
  where: v1_ServiceTypes_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_ServiceTypes_var_pop_fields = {
  __typename?: 'v1_ServiceTypes_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_ServiceTypes_var_samp_fields = {
  __typename?: 'v1_ServiceTypes_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_ServiceTypes_variance_fields = {
  __typename?: 'v1_ServiceTypes_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** This is for the BMS system. */
export type v1_SoftwareProviders = {
  __typename?: 'v1_SoftwareProviders';
  id: Scalars['Int']['output'];
  new_name: Scalars['String']['output'];
};

/** aggregated selection of "v1.SoftwareProviders" */
export type v1_SoftwareProviders_aggregate = {
  __typename?: 'v1_SoftwareProviders_aggregate';
  aggregate?: Maybe<v1_SoftwareProviders_aggregate_fields>;
  nodes: Array<v1_SoftwareProviders>;
};

/** aggregate fields of "v1.SoftwareProviders" */
export type v1_SoftwareProviders_aggregate_fields = {
  __typename?: 'v1_SoftwareProviders_aggregate_fields';
  avg?: Maybe<v1_SoftwareProviders_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_SoftwareProviders_max_fields>;
  min?: Maybe<v1_SoftwareProviders_min_fields>;
  stddev?: Maybe<v1_SoftwareProviders_stddev_fields>;
  stddev_pop?: Maybe<v1_SoftwareProviders_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_SoftwareProviders_stddev_samp_fields>;
  sum?: Maybe<v1_SoftwareProviders_sum_fields>;
  var_pop?: Maybe<v1_SoftwareProviders_var_pop_fields>;
  var_samp?: Maybe<v1_SoftwareProviders_var_samp_fields>;
  variance?: Maybe<v1_SoftwareProviders_variance_fields>;
};


/** aggregate fields of "v1.SoftwareProviders" */
export type v1_SoftwareProviders_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_SoftwareProviders_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_SoftwareProviders_avg_fields = {
  __typename?: 'v1_SoftwareProviders_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.SoftwareProviders". All fields are combined with a logical 'AND'. */
export type v1_SoftwareProviders_bool_exp = {
  _and?: InputMaybe<Array<v1_SoftwareProviders_bool_exp>>;
  _not?: InputMaybe<v1_SoftwareProviders_bool_exp>;
  _or?: InputMaybe<Array<v1_SoftwareProviders_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.SoftwareProviders" */
export enum v1_SoftwareProviders_constraint {
  /** unique or primary key constraint on columns "new_name" */
  SoftwareProviders_new_name_key = 'SoftwareProviders_new_name_key',
  /** unique or primary key constraint on columns "id" */
  SoftwareProviders_pkey = 'SoftwareProviders_pkey'
}

/** input type for incrementing numeric columns in table "v1.SoftwareProviders" */
export type v1_SoftwareProviders_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.SoftwareProviders" */
export type v1_SoftwareProviders_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_SoftwareProviders_max_fields = {
  __typename?: 'v1_SoftwareProviders_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_SoftwareProviders_min_fields = {
  __typename?: 'v1_SoftwareProviders_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.SoftwareProviders" */
export type v1_SoftwareProviders_mutation_response = {
  __typename?: 'v1_SoftwareProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_SoftwareProviders>;
};

/** on_conflict condition type for table "v1.SoftwareProviders" */
export type v1_SoftwareProviders_on_conflict = {
  constraint: v1_SoftwareProviders_constraint;
  update_columns?: Array<v1_SoftwareProviders_update_column>;
  where?: InputMaybe<v1_SoftwareProviders_bool_exp>;
};

/** Ordering options when selecting data from "v1.SoftwareProviders". */
export type v1_SoftwareProviders_order_by = {
  id?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.SoftwareProviders */
export type v1_SoftwareProviders_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.SoftwareProviders" */
export enum v1_SoftwareProviders_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

/** input type for updating data in table "v1.SoftwareProviders" */
export type v1_SoftwareProviders_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_SoftwareProviders_stddev_fields = {
  __typename?: 'v1_SoftwareProviders_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_SoftwareProviders_stddev_pop_fields = {
  __typename?: 'v1_SoftwareProviders_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_SoftwareProviders_stddev_samp_fields = {
  __typename?: 'v1_SoftwareProviders_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_SoftwareProviders" */
export type v1_SoftwareProviders_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_SoftwareProviders_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_SoftwareProviders_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_SoftwareProviders_sum_fields = {
  __typename?: 'v1_SoftwareProviders_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.SoftwareProviders" */
export enum v1_SoftwareProviders_update_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

export type v1_SoftwareProviders_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_SoftwareProviders_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_SoftwareProviders_set_input>;
  /** filter the rows which have to be updated */
  where: v1_SoftwareProviders_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_SoftwareProviders_var_pop_fields = {
  __typename?: 'v1_SoftwareProviders_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_SoftwareProviders_var_samp_fields = {
  __typename?: 'v1_SoftwareProviders_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_SoftwareProviders_variance_fields = {
  __typename?: 'v1_SoftwareProviders_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** combination of fields for Campign srategy */
export type v1_StrategyFieldsSet = {
  __typename?: 'v1_StrategyFieldsSet';
  /** An array relationship */
  CampaignStrategies: Array<v1_CampaignStrategy>;
  /** An aggregate relationship */
  CampaignStrategies_aggregate: v1_CampaignStrategy_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** combination of fields for Campign srategy */
export type v1_StrategyFieldsSetCampaignStrategiesArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


/** combination of fields for Campign srategy */
export type v1_StrategyFieldsSetCampaignStrategies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};

/** aggregated selection of "v1.StrategyFieldsSet" */
export type v1_StrategyFieldsSet_aggregate = {
  __typename?: 'v1_StrategyFieldsSet_aggregate';
  aggregate?: Maybe<v1_StrategyFieldsSet_aggregate_fields>;
  nodes: Array<v1_StrategyFieldsSet>;
};

/** aggregate fields of "v1.StrategyFieldsSet" */
export type v1_StrategyFieldsSet_aggregate_fields = {
  __typename?: 'v1_StrategyFieldsSet_aggregate_fields';
  avg?: Maybe<v1_StrategyFieldsSet_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_StrategyFieldsSet_max_fields>;
  min?: Maybe<v1_StrategyFieldsSet_min_fields>;
  stddev?: Maybe<v1_StrategyFieldsSet_stddev_fields>;
  stddev_pop?: Maybe<v1_StrategyFieldsSet_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_StrategyFieldsSet_stddev_samp_fields>;
  sum?: Maybe<v1_StrategyFieldsSet_sum_fields>;
  var_pop?: Maybe<v1_StrategyFieldsSet_var_pop_fields>;
  var_samp?: Maybe<v1_StrategyFieldsSet_var_samp_fields>;
  variance?: Maybe<v1_StrategyFieldsSet_variance_fields>;
};


/** aggregate fields of "v1.StrategyFieldsSet" */
export type v1_StrategyFieldsSet_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_StrategyFieldsSet_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_StrategyFieldsSet_avg_fields = {
  __typename?: 'v1_StrategyFieldsSet_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.StrategyFieldsSet". All fields are combined with a logical 'AND'. */
export type v1_StrategyFieldsSet_bool_exp = {
  CampaignStrategies?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  CampaignStrategies_aggregate?: InputMaybe<v1_CampaignStrategy_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_StrategyFieldsSet_bool_exp>>;
  _not?: InputMaybe<v1_StrategyFieldsSet_bool_exp>;
  _or?: InputMaybe<Array<v1_StrategyFieldsSet_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.StrategyFieldsSet" */
export enum v1_StrategyFieldsSet_constraint {
  /** unique or primary key constraint on columns "id" */
  StrategyFieldsSet_id_key = 'StrategyFieldsSet_id_key',
  /** unique or primary key constraint on columns "id" */
  StrategyFieldsSet_pkey = 'StrategyFieldsSet_pkey'
}

/** input type for incrementing numeric columns in table "v1.StrategyFieldsSet" */
export type v1_StrategyFieldsSet_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.StrategyFieldsSet" */
export type v1_StrategyFieldsSet_insert_input = {
  CampaignStrategies?: InputMaybe<v1_CampaignStrategy_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_StrategyFieldsSet_max_fields = {
  __typename?: 'v1_StrategyFieldsSet_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_StrategyFieldsSet_min_fields = {
  __typename?: 'v1_StrategyFieldsSet_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.StrategyFieldsSet" */
export type v1_StrategyFieldsSet_mutation_response = {
  __typename?: 'v1_StrategyFieldsSet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_StrategyFieldsSet>;
};

/** input type for inserting object relation for remote table "v1.StrategyFieldsSet" */
export type v1_StrategyFieldsSet_obj_rel_insert_input = {
  data: v1_StrategyFieldsSet_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_StrategyFieldsSet_on_conflict>;
};

/** on_conflict condition type for table "v1.StrategyFieldsSet" */
export type v1_StrategyFieldsSet_on_conflict = {
  constraint: v1_StrategyFieldsSet_constraint;
  update_columns?: Array<v1_StrategyFieldsSet_update_column>;
  where?: InputMaybe<v1_StrategyFieldsSet_bool_exp>;
};

/** Ordering options when selecting data from "v1.StrategyFieldsSet". */
export type v1_StrategyFieldsSet_order_by = {
  CampaignStrategies_aggregate?: InputMaybe<v1_CampaignStrategy_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.StrategyFieldsSet */
export type v1_StrategyFieldsSet_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.StrategyFieldsSet" */
export enum v1_StrategyFieldsSet_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.StrategyFieldsSet" */
export type v1_StrategyFieldsSet_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_StrategyFieldsSet_stddev_fields = {
  __typename?: 'v1_StrategyFieldsSet_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_StrategyFieldsSet_stddev_pop_fields = {
  __typename?: 'v1_StrategyFieldsSet_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_StrategyFieldsSet_stddev_samp_fields = {
  __typename?: 'v1_StrategyFieldsSet_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_StrategyFieldsSet" */
export type v1_StrategyFieldsSet_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_StrategyFieldsSet_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_StrategyFieldsSet_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_StrategyFieldsSet_sum_fields = {
  __typename?: 'v1_StrategyFieldsSet_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.StrategyFieldsSet" */
export enum v1_StrategyFieldsSet_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_StrategyFieldsSet_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_StrategyFieldsSet_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_StrategyFieldsSet_set_input>;
  /** filter the rows which have to be updated */
  where: v1_StrategyFieldsSet_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_StrategyFieldsSet_var_pop_fields = {
  __typename?: 'v1_StrategyFieldsSet_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_StrategyFieldsSet_var_samp_fields = {
  __typename?: 'v1_StrategyFieldsSet_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_StrategyFieldsSet_variance_fields = {
  __typename?: 'v1_StrategyFieldsSet_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel = {
  __typename?: 'v1_Supplier_Tag_rel';
  /** An object relationship */
  Suppliers?: Maybe<v1_ContactBase>;
  /** An object relationship */
  Tags: v1_Tags;
  id: Scalars['Int']['output'];
  supplier_id?: Maybe<Scalars['Int']['output']>;
  tag_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_aggregate = {
  __typename?: 'v1_Supplier_Tag_rel_aggregate';
  aggregate?: Maybe<v1_Supplier_Tag_rel_aggregate_fields>;
  nodes: Array<v1_Supplier_Tag_rel>;
};

export type v1_Supplier_Tag_rel_aggregate_bool_exp = {
  count?: InputMaybe<v1_Supplier_Tag_rel_aggregate_bool_exp_count>;
};

export type v1_Supplier_Tag_rel_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_aggregate_fields = {
  __typename?: 'v1_Supplier_Tag_rel_aggregate_fields';
  avg?: Maybe<v1_Supplier_Tag_rel_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Supplier_Tag_rel_max_fields>;
  min?: Maybe<v1_Supplier_Tag_rel_min_fields>;
  stddev?: Maybe<v1_Supplier_Tag_rel_stddev_fields>;
  stddev_pop?: Maybe<v1_Supplier_Tag_rel_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Supplier_Tag_rel_stddev_samp_fields>;
  sum?: Maybe<v1_Supplier_Tag_rel_sum_fields>;
  var_pop?: Maybe<v1_Supplier_Tag_rel_var_pop_fields>;
  var_samp?: Maybe<v1_Supplier_Tag_rel_var_samp_fields>;
  variance?: Maybe<v1_Supplier_Tag_rel_variance_fields>;
};


/** aggregate fields of "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_aggregate_order_by = {
  avg?: InputMaybe<v1_Supplier_Tag_rel_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Supplier_Tag_rel_max_order_by>;
  min?: InputMaybe<v1_Supplier_Tag_rel_min_order_by>;
  stddev?: InputMaybe<v1_Supplier_Tag_rel_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Supplier_Tag_rel_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Supplier_Tag_rel_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Supplier_Tag_rel_sum_order_by>;
  var_pop?: InputMaybe<v1_Supplier_Tag_rel_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Supplier_Tag_rel_var_samp_order_by>;
  variance?: InputMaybe<v1_Supplier_Tag_rel_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_arr_rel_insert_input = {
  data: Array<v1_Supplier_Tag_rel_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Supplier_Tag_rel_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Supplier_Tag_rel_avg_fields = {
  __typename?: 'v1_Supplier_Tag_rel_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_avg_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Supplier_Tag_rel". All fields are combined with a logical 'AND'. */
export type v1_Supplier_Tag_rel_bool_exp = {
  Suppliers?: InputMaybe<v1_ContactBase_bool_exp>;
  Tags?: InputMaybe<v1_Tags_bool_exp>;
  _and?: InputMaybe<Array<v1_Supplier_Tag_rel_bool_exp>>;
  _not?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
  _or?: InputMaybe<Array<v1_Supplier_Tag_rel_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  supplier_id?: InputMaybe<Int_comparison_exp>;
  tag_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Supplier_Tag_rel" */
export enum v1_Supplier_Tag_rel_constraint {
  /** unique or primary key constraint on columns "id" */
  PK_Supplier_Tag_rel = 'PK_Supplier_Tag_rel'
}

/** input type for incrementing numeric columns in table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_insert_input = {
  Suppliers?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  Tags?: InputMaybe<v1_Tags_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Supplier_Tag_rel_max_fields = {
  __typename?: 'v1_Supplier_Tag_rel_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_max_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Supplier_Tag_rel_min_fields = {
  __typename?: 'v1_Supplier_Tag_rel_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_min_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_mutation_response = {
  __typename?: 'v1_Supplier_Tag_rel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Supplier_Tag_rel>;
};

/** on_conflict condition type for table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_on_conflict = {
  constraint: v1_Supplier_Tag_rel_constraint;
  update_columns?: Array<v1_Supplier_Tag_rel_update_column>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};

/** Ordering options when selecting data from "v1.Supplier_Tag_rel". */
export type v1_Supplier_Tag_rel_order_by = {
  Suppliers?: InputMaybe<v1_ContactBase_order_by>;
  Tags?: InputMaybe<v1_Tags_order_by>;
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Supplier_Tag_rel */
export type v1_Supplier_Tag_rel_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Supplier_Tag_rel" */
export enum v1_Supplier_Tag_rel_select_column {
  /** column name */
  id = 'id',
  /** column name */
  supplier_id = 'supplier_id',
  /** column name */
  tag_id = 'tag_id'
}

/** input type for updating data in table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Supplier_Tag_rel_stddev_fields = {
  __typename?: 'v1_Supplier_Tag_rel_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_stddev_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Supplier_Tag_rel_stddev_pop_fields = {
  __typename?: 'v1_Supplier_Tag_rel_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Supplier_Tag_rel_stddev_samp_fields = {
  __typename?: 'v1_Supplier_Tag_rel_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Supplier_Tag_rel_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Supplier_Tag_rel_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Supplier_Tag_rel_sum_fields = {
  __typename?: 'v1_Supplier_Tag_rel_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_sum_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Supplier_Tag_rel" */
export enum v1_Supplier_Tag_rel_update_column {
  /** column name */
  id = 'id',
  /** column name */
  supplier_id = 'supplier_id',
  /** column name */
  tag_id = 'tag_id'
}

export type v1_Supplier_Tag_rel_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Supplier_Tag_rel_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Supplier_Tag_rel_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Supplier_Tag_rel_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Supplier_Tag_rel_var_pop_fields = {
  __typename?: 'v1_Supplier_Tag_rel_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Supplier_Tag_rel_var_samp_fields = {
  __typename?: 'v1_Supplier_Tag_rel_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Supplier_Tag_rel_variance_fields = {
  __typename?: 'v1_Supplier_Tag_rel_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Supplier_Tag_rel" */
export type v1_Supplier_Tag_rel_variance_order_by = {
  id?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Suppliers" */
export type v1_Suppliers = {
  __typename?: 'v1_Suppliers';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  Supplier_Tags: Array<v1_Supplier_Tag_rel>;
  /** An aggregate relationship */
  Supplier_Tags_aggregate: v1_Supplier_Tag_rel_aggregate;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  allow_upload_content_portal?: Maybe<Scalars['Boolean']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.suppliers_number_of_content_creation_posts" */
  comp_number_of_content_creation_posts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.suppliers_number_of_offers" */
  comp_number_of_offers?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.suppliers_number_of_posts" */
  comp_number_of_posts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.suppliers_number_of_published_posts" */
  comp_number_of_published_posts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.suppliers_number_of_requires_attention_posts" */
  comp_number_of_requires_attention_posts?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_AccountActive?: Maybe<Scalars['Boolean']['output']>;
  new_Blacklisted?: Maybe<Scalars['Boolean']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_IsCustomer?: Maybe<Scalars['Boolean']['output']>;
  new_Issupplier?: Maybe<Scalars['Boolean']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_Linksexchanger?: Maybe<Scalars['Boolean']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translator?: Maybe<Scalars['Boolean']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_Writer?: Maybe<Scalars['Boolean']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contacetype?: Maybe<Scalars['Boolean']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_inhouse?: Maybe<Scalars['Boolean']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  tag_ids?: Maybe<Scalars['_int4']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};


/** columns and relationships of "v1.Suppliers" */
export type v1_SuppliersSupplier_TagsArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};


/** columns and relationships of "v1.Suppliers" */
export type v1_SuppliersSupplier_Tags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};

/** aggregated selection of "v1.Suppliers" */
export type v1_Suppliers_aggregate = {
  __typename?: 'v1_Suppliers_aggregate';
  aggregate?: Maybe<v1_Suppliers_aggregate_fields>;
  nodes: Array<v1_Suppliers>;
};

/** aggregate fields of "v1.Suppliers" */
export type v1_Suppliers_aggregate_fields = {
  __typename?: 'v1_Suppliers_aggregate_fields';
  avg?: Maybe<v1_Suppliers_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Suppliers_max_fields>;
  min?: Maybe<v1_Suppliers_min_fields>;
  stddev?: Maybe<v1_Suppliers_stddev_fields>;
  stddev_pop?: Maybe<v1_Suppliers_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Suppliers_stddev_samp_fields>;
  sum?: Maybe<v1_Suppliers_sum_fields>;
  var_pop?: Maybe<v1_Suppliers_var_pop_fields>;
  var_samp?: Maybe<v1_Suppliers_var_samp_fields>;
  variance?: Maybe<v1_Suppliers_variance_fields>;
};


/** aggregate fields of "v1.Suppliers" */
export type v1_Suppliers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Suppliers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Suppliers_avg_fields = {
  __typename?: 'v1_Suppliers_avg_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Suppliers". All fields are combined with a logical 'AND'. */
export type v1_Suppliers_bool_exp = {
  ActiveOffersCount?: InputMaybe<Int_comparison_exp>;
  AllPosts?: InputMaybe<Int_comparison_exp>;
  AssistantID?: InputMaybe<Int_comparison_exp>;
  Balance?: InputMaybe<numeric_comparison_exp>;
  BirthDate?: InputMaybe<timestamp_comparison_exp>;
  ContactId?: InputMaybe<uuid_comparison_exp>;
  CountryID?: InputMaybe<Int_comparison_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  EMailAddress1?: InputMaybe<String_comparison_exp>;
  EMailAddress2?: InputMaybe<String_comparison_exp>;
  ExchangeRate?: InputMaybe<numeric_comparison_exp>;
  FinishedOrders?: InputMaybe<Int_comparison_exp>;
  FirstName?: InputMaybe<String_comparison_exp>;
  FullName?: InputMaybe<String_comparison_exp>;
  LastName?: InputMaybe<String_comparison_exp>;
  MobilePhone?: InputMaybe<String_comparison_exp>;
  ModifiedOn?: InputMaybe<timestamp_comparison_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  SoldOffersCount?: InputMaybe<Int_comparison_exp>;
  StateCode?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<Int_comparison_exp>;
  Supplier_Tags?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
  Supplier_Tags_aggregate?: InputMaybe<v1_Supplier_Tag_rel_aggregate_bool_exp>;
  Telephone1?: InputMaybe<String_comparison_exp>;
  TotalOffersCount?: InputMaybe<Int_comparison_exp>;
  TotalOrdersSum?: InputMaybe<Int_comparison_exp>;
  TotalPayoutsSum?: InputMaybe<Int_comparison_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  _and?: InputMaybe<Array<v1_Suppliers_bool_exp>>;
  _not?: InputMaybe<v1_Suppliers_bool_exp>;
  _or?: InputMaybe<Array<v1_Suppliers_bool_exp>>;
  allow_upload_content_portal?: InputMaybe<Boolean_comparison_exp>;
  buzzstream_id?: InputMaybe<Int_comparison_exp>;
  comp_number_of_content_creation_posts?: InputMaybe<Int_comparison_exp>;
  comp_number_of_offers?: InputMaybe<Int_comparison_exp>;
  comp_number_of_posts?: InputMaybe<Int_comparison_exp>;
  comp_number_of_published_posts?: InputMaybe<Int_comparison_exp>;
  comp_number_of_requires_attention_posts?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  language1_id?: InputMaybe<Int_comparison_exp>;
  language_id?: InputMaybe<Int_comparison_exp>;
  monthly_quota?: InputMaybe<numeric_comparison_exp>;
  new_AccountActive?: InputMaybe<Boolean_comparison_exp>;
  new_Blacklisted?: InputMaybe<Boolean_comparison_exp>;
  new_Contacttype?: InputMaybe<Int_comparison_exp>;
  new_CreatedByUser?: InputMaybe<String_comparison_exp>;
  new_Facebook?: InputMaybe<String_comparison_exp>;
  new_FullSeoPage?: InputMaybe<numeric_comparison_exp>;
  new_IsCustomer?: InputMaybe<Boolean_comparison_exp>;
  new_Issupplier?: InputMaybe<Boolean_comparison_exp>;
  new_LastActivity?: InputMaybe<timestamp_comparison_exp>;
  new_LinkedIn?: InputMaybe<String_comparison_exp>;
  new_Linksexchanger?: InputMaybe<Boolean_comparison_exp>;
  new_MainLanguage?: InputMaybe<Int_comparison_exp>;
  new_Offers?: InputMaybe<Int_comparison_exp>;
  new_Passportid?: InputMaybe<String_comparison_exp>;
  new_Password?: InputMaybe<String_comparison_exp>;
  new_PaymentAddress?: InputMaybe<String_comparison_exp>;
  new_Paymentmethod?: InputMaybe<Int_comparison_exp>;
  new_Rating?: InputMaybe<Int_comparison_exp>;
  new_SecondaryLanguage?: InputMaybe<Int_comparison_exp>;
  new_SeoShortPost?: InputMaybe<numeric_comparison_exp>;
  new_Translator?: InputMaybe<Boolean_comparison_exp>;
  new_UserName?: InputMaybe<String_comparison_exp>;
  new_WebsitePassword?: InputMaybe<String_comparison_exp>;
  new_Writer?: InputMaybe<Boolean_comparison_exp>;
  new_bankaddress?: InputMaybe<String_comparison_exp>;
  new_bankibanaccount?: InputMaybe<String_comparison_exp>;
  new_bankname?: InputMaybe<String_comparison_exp>;
  new_bankswift?: InputMaybe<String_comparison_exp>;
  new_beneficiaryaddress?: InputMaybe<String_comparison_exp>;
  new_beneficiaryname?: InputMaybe<String_comparison_exp>;
  new_contacetype?: InputMaybe<Boolean_comparison_exp>;
  new_contactid?: InputMaybe<String_comparison_exp>;
  new_fullseopagebig?: InputMaybe<numeric_comparison_exp>;
  new_fullseopagejambo?: InputMaybe<numeric_comparison_exp>;
  new_fullseopagelarge?: InputMaybe<numeric_comparison_exp>;
  new_inhouse?: InputMaybe<Boolean_comparison_exp>;
  new_language?: InputMaybe<uuid_comparison_exp>;
  new_language1?: InputMaybe<uuid_comparison_exp>;
  new_longsocialpost?: InputMaybe<numeric_comparison_exp>;
  new_shortsocialpost?: InputMaybe<numeric_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paymentOption?: InputMaybe<Int_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  price_per_word?: InputMaybe<float8_comparison_exp>;
  skill_level?: InputMaybe<numeric_comparison_exp>;
  tag_ids?: InputMaybe<_int4_comparison_exp>;
  upload_price?: InputMaybe<numeric_comparison_exp>;
};

/** aggregate max on columns */
export type v1_Suppliers_max_fields = {
  __typename?: 'v1_Suppliers_max_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type v1_Suppliers_min_fields = {
  __typename?: 'v1_Suppliers_min_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** Ordering options when selecting data from "v1.Suppliers". */
export type v1_Suppliers_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  BirthDate?: InputMaybe<order_by>;
  ContactId?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  EMailAddress1?: InputMaybe<order_by>;
  EMailAddress2?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  FirstName?: InputMaybe<order_by>;
  FullName?: InputMaybe<order_by>;
  LastName?: InputMaybe<order_by>;
  MobilePhone?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  Supplier_Tags_aggregate?: InputMaybe<v1_Supplier_Tag_rel_aggregate_order_by>;
  Telephone1?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  allow_upload_content_portal?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  comp_number_of_content_creation_posts?: InputMaybe<order_by>;
  comp_number_of_offers?: InputMaybe<order_by>;
  comp_number_of_posts?: InputMaybe<order_by>;
  comp_number_of_published_posts?: InputMaybe<order_by>;
  comp_number_of_requires_attention_posts?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language1_id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_AccountActive?: InputMaybe<order_by>;
  new_Blacklisted?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_Facebook?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_IsCustomer?: InputMaybe<order_by>;
  new_Issupplier?: InputMaybe<order_by>;
  new_LastActivity?: InputMaybe<order_by>;
  new_LinkedIn?: InputMaybe<order_by>;
  new_Linksexchanger?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Passportid?: InputMaybe<order_by>;
  new_Password?: InputMaybe<order_by>;
  new_PaymentAddress?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translator?: InputMaybe<order_by>;
  new_UserName?: InputMaybe<order_by>;
  new_WebsitePassword?: InputMaybe<order_by>;
  new_Writer?: InputMaybe<order_by>;
  new_bankaddress?: InputMaybe<order_by>;
  new_bankibanaccount?: InputMaybe<order_by>;
  new_bankname?: InputMaybe<order_by>;
  new_bankswift?: InputMaybe<order_by>;
  new_beneficiaryaddress?: InputMaybe<order_by>;
  new_beneficiaryname?: InputMaybe<order_by>;
  new_contacetype?: InputMaybe<order_by>;
  new_contactid?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_inhouse?: InputMaybe<order_by>;
  new_language?: InputMaybe<order_by>;
  new_language1?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  tag_ids?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** select columns of table "v1.Suppliers" */
export enum v1_Suppliers_select_column {
  /** column name */
  ActiveOffersCount = 'ActiveOffersCount',
  /** column name */
  AllPosts = 'AllPosts',
  /** column name */
  AssistantID = 'AssistantID',
  /** column name */
  Balance = 'Balance',
  /** column name */
  BirthDate = 'BirthDate',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CountryID = 'CountryID',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  EMailAddress1 = 'EMailAddress1',
  /** column name */
  EMailAddress2 = 'EMailAddress2',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  FinishedOrders = 'FinishedOrders',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  FullName = 'FullName',
  /** column name */
  LastName = 'LastName',
  /** column name */
  MobilePhone = 'MobilePhone',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  SoldOffersCount = 'SoldOffersCount',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  Telephone1 = 'Telephone1',
  /** column name */
  TotalOffersCount = 'TotalOffersCount',
  /** column name */
  TotalOrdersSum = 'TotalOrdersSum',
  /** column name */
  TotalPayoutsSum = 'TotalPayoutsSum',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  buzzstream_id = 'buzzstream_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  language1_id = 'language1_id',
  /** column name */
  language_id = 'language_id',
  /** column name */
  monthly_quota = 'monthly_quota',
  /** column name */
  new_AccountActive = 'new_AccountActive',
  /** column name */
  new_Blacklisted = 'new_Blacklisted',
  /** column name */
  new_Contacttype = 'new_Contacttype',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_Facebook = 'new_Facebook',
  /** column name */
  new_FullSeoPage = 'new_FullSeoPage',
  /** column name */
  new_IsCustomer = 'new_IsCustomer',
  /** column name */
  new_Issupplier = 'new_Issupplier',
  /** column name */
  new_LastActivity = 'new_LastActivity',
  /** column name */
  new_LinkedIn = 'new_LinkedIn',
  /** column name */
  new_Linksexchanger = 'new_Linksexchanger',
  /** column name */
  new_MainLanguage = 'new_MainLanguage',
  /** column name */
  new_Offers = 'new_Offers',
  /** column name */
  new_Passportid = 'new_Passportid',
  /** column name */
  new_Password = 'new_Password',
  /** column name */
  new_PaymentAddress = 'new_PaymentAddress',
  /** column name */
  new_Paymentmethod = 'new_Paymentmethod',
  /** column name */
  new_Rating = 'new_Rating',
  /** column name */
  new_SecondaryLanguage = 'new_SecondaryLanguage',
  /** column name */
  new_SeoShortPost = 'new_SeoShortPost',
  /** column name */
  new_Translator = 'new_Translator',
  /** column name */
  new_UserName = 'new_UserName',
  /** column name */
  new_WebsitePassword = 'new_WebsitePassword',
  /** column name */
  new_Writer = 'new_Writer',
  /** column name */
  new_bankaddress = 'new_bankaddress',
  /** column name */
  new_bankibanaccount = 'new_bankibanaccount',
  /** column name */
  new_bankname = 'new_bankname',
  /** column name */
  new_bankswift = 'new_bankswift',
  /** column name */
  new_beneficiaryaddress = 'new_beneficiaryaddress',
  /** column name */
  new_beneficiaryname = 'new_beneficiaryname',
  /** column name */
  new_contacetype = 'new_contacetype',
  /** column name */
  new_contactid = 'new_contactid',
  /** column name */
  new_fullseopagebig = 'new_fullseopagebig',
  /** column name */
  new_fullseopagejambo = 'new_fullseopagejambo',
  /** column name */
  new_fullseopagelarge = 'new_fullseopagelarge',
  /** column name */
  new_inhouse = 'new_inhouse',
  /** column name */
  new_language = 'new_language',
  /** column name */
  new_language1 = 'new_language1',
  /** column name */
  new_longsocialpost = 'new_longsocialpost',
  /** column name */
  new_shortsocialpost = 'new_shortsocialpost',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentOption = 'paymentOption',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  price_per_word = 'price_per_word',
  /** column name */
  skill_level = 'skill_level',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  upload_price = 'upload_price'
}

/** aggregate stddev on columns */
export type v1_Suppliers_stddev_fields = {
  __typename?: 'v1_Suppliers_stddev_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Suppliers_stddev_pop_fields = {
  __typename?: 'v1_Suppliers_stddev_pop_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Suppliers_stddev_samp_fields = {
  __typename?: 'v1_Suppliers_stddev_samp_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Suppliers" */
export type v1_Suppliers_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Suppliers_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Suppliers_stream_cursor_value_input = {
  ActiveOffersCount?: InputMaybe<Scalars['Int']['input']>;
  AllPosts?: InputMaybe<Scalars['Int']['input']>;
  AssistantID?: InputMaybe<Scalars['Int']['input']>;
  Balance?: InputMaybe<Scalars['numeric']['input']>;
  BirthDate?: InputMaybe<Scalars['timestamp']['input']>;
  ContactId?: InputMaybe<Scalars['uuid']['input']>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  EMailAddress1?: InputMaybe<Scalars['String']['input']>;
  EMailAddress2?: InputMaybe<Scalars['String']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  FinishedOrders?: InputMaybe<Scalars['Int']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FullName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  SoldOffersCount?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  Telephone1?: InputMaybe<Scalars['String']['input']>;
  TotalOffersCount?: InputMaybe<Scalars['Int']['input']>;
  TotalOrdersSum?: InputMaybe<Scalars['Int']['input']>;
  TotalPayoutsSum?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  allow_upload_content_portal?: InputMaybe<Scalars['Boolean']['input']>;
  buzzstream_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language1_id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  monthly_quota?: InputMaybe<Scalars['numeric']['input']>;
  new_AccountActive?: InputMaybe<Scalars['Boolean']['input']>;
  new_Blacklisted?: InputMaybe<Scalars['Boolean']['input']>;
  new_Contacttype?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_Facebook?: InputMaybe<Scalars['String']['input']>;
  new_FullSeoPage?: InputMaybe<Scalars['numeric']['input']>;
  new_IsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  new_Issupplier?: InputMaybe<Scalars['Boolean']['input']>;
  new_LastActivity?: InputMaybe<Scalars['timestamp']['input']>;
  new_LinkedIn?: InputMaybe<Scalars['String']['input']>;
  new_Linksexchanger?: InputMaybe<Scalars['Boolean']['input']>;
  new_MainLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_Offers?: InputMaybe<Scalars['Int']['input']>;
  new_Passportid?: InputMaybe<Scalars['String']['input']>;
  new_Password?: InputMaybe<Scalars['String']['input']>;
  new_PaymentAddress?: InputMaybe<Scalars['String']['input']>;
  new_Paymentmethod?: InputMaybe<Scalars['Int']['input']>;
  new_Rating?: InputMaybe<Scalars['Int']['input']>;
  new_SecondaryLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_SeoShortPost?: InputMaybe<Scalars['numeric']['input']>;
  new_Translator?: InputMaybe<Scalars['Boolean']['input']>;
  new_UserName?: InputMaybe<Scalars['String']['input']>;
  new_WebsitePassword?: InputMaybe<Scalars['String']['input']>;
  new_Writer?: InputMaybe<Scalars['Boolean']['input']>;
  new_bankaddress?: InputMaybe<Scalars['String']['input']>;
  new_bankibanaccount?: InputMaybe<Scalars['String']['input']>;
  new_bankname?: InputMaybe<Scalars['String']['input']>;
  new_bankswift?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryaddress?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryname?: InputMaybe<Scalars['String']['input']>;
  new_contacetype?: InputMaybe<Scalars['Boolean']['input']>;
  new_contactid?: InputMaybe<Scalars['String']['input']>;
  new_fullseopagebig?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagejambo?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagelarge?: InputMaybe<Scalars['numeric']['input']>;
  new_inhouse?: InputMaybe<Scalars['Boolean']['input']>;
  new_language?: InputMaybe<Scalars['uuid']['input']>;
  new_language1?: InputMaybe<Scalars['uuid']['input']>;
  new_longsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  new_shortsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentOption?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  price_per_word?: InputMaybe<Scalars['float8']['input']>;
  skill_level?: InputMaybe<Scalars['numeric']['input']>;
  tag_ids?: InputMaybe<Scalars['_int4']['input']>;
  upload_price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type v1_Suppliers_sum_fields = {
  __typename?: 'v1_Suppliers_sum_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type v1_Suppliers_var_pop_fields = {
  __typename?: 'v1_Suppliers_var_pop_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Suppliers_var_samp_fields = {
  __typename?: 'v1_Suppliers_var_samp_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Suppliers_variance_fields = {
  __typename?: 'v1_Suppliers_variance_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** User */
export type v1_SystemUserBase = {
  __typename?: 'v1_SystemUserBase';
  /** An array relationship */
  AccountBases: Array<v1_AccountBase>;
  /** An aggregate relationship */
  AccountBases_aggregate: v1_AccountBase_aggregate;
  AvatarPicture?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  Bonuses: Array<v1_Bonuses>;
  /** An aggregate relationship */
  Bonuses_aggregate: v1_Bonuses_aggregate;
  /** An array relationship */
  CampaignStrategies: Array<v1_CampaignStrategy>;
  /** An aggregate relationship */
  CampaignStrategies_aggregate: v1_CampaignStrategy_aggregate;
  /** An array relationship */
  Campaigns: Array<v1_Campaign>;
  /** An aggregate relationship */
  Campaigns_aggregate: v1_Campaign_aggregate;
  /** An array relationship */
  ContactBases: Array<v1_ContactBase>;
  /** An aggregate relationship */
  ContactBases_aggregate: v1_ContactBase_aggregate;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  Credentials: Array<v1_Credentials>;
  /** An aggregate relationship */
  Credentials_aggregate: v1_Credentials_aggregate;
  /** An array relationship */
  Events: Array<v1_Events>;
  /** An aggregate relationship */
  Events_aggregate: v1_Events_aggregate;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  InvoiceBases: Array<v1_InvoiceBase>;
  /** An aggregate relationship */
  InvoiceBases_aggregate: v1_InvoiceBase_aggregate;
  /** An array relationship */
  InvoiceDetailBases: Array<v1_InvoiceDetailBase>;
  /** An aggregate relationship */
  InvoiceDetailBases_aggregate: v1_InvoiceDetailBase_aggregate;
  /** An array relationship */
  InvoicesByCancelledBy: Array<v1_InvoiceBase>;
  /** An aggregate relationship */
  InvoicesByCancelledBy_aggregate: v1_InvoiceBase_aggregate;
  IsDisabled?: Maybe<Scalars['Boolean']['output']>;
  JobTitle?: Maybe<Scalars['bpchar']['output']>;
  /** An array relationship */
  LandingPages: Array<v1_LandingPages>;
  /** An aggregate relationship */
  LandingPages_aggregate: v1_LandingPages_aggregate;
  LastName?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  MergedPosts: Array<v1_Posts>;
  /** An aggregate relationship */
  MergedPosts_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  /** An array relationship */
  PO_Declinedby: Array<v1_new_POBase>;
  /** An aggregate relationship */
  PO_Declinedby_aggregate: v1_new_POBase_aggregate;
  PersonalEMailAddress?: Maybe<Scalars['String']['output']>;
  PhoneNumber?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  Projects: Array<v1_Project>;
  /** An aggregate relationship */
  Projects_aggregate: v1_Project_aggregate;
  /** An array relationship */
  PurchaseOrdersByCancelledby: Array<v1_new_POBase>;
  /** An aggregate relationship */
  PurchaseOrdersByCancelledby_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  ReceiptsByCancellor: Array<v1_new_receiptBase>;
  /** An aggregate relationship */
  ReceiptsByCancellor_aggregate: v1_new_receiptBase_aggregate;
  /** An array relationship */
  RecurringPayments: Array<v1_RecurringPayments>;
  /** An aggregate relationship */
  RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  /** An array relationship */
  SalesOrderBases: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  SalesOrderBases_aggregate: v1_SalesOrderBase_aggregate;
  /** An object relationship */
  Status: v1_UsersStatus;
  SystemUserId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  Users_Roles: Array<v1_Users_Roles>;
  /** An aggregate relationship */
  Users_Roles_aggregate: v1_Users_Roles_aggregate;
  /** An array relationship */
  Views: Array<v1_Preferences>;
  /** An aggregate relationship */
  Views_aggregate: v1_Preferences_aggregate;
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  /** An array relationship */
  accountBasesByOwnerId: Array<v1_AccountBase>;
  /** An aggregate relationship */
  accountBasesByOwnerId_aggregate: v1_AccountBase_aggregate;
  /** An array relationship */
  campaignStrategiesByModifiedBy: Array<v1_CampaignStrategy>;
  /** An aggregate relationship */
  campaignStrategiesByModifiedBy_aggregate: v1_CampaignStrategy_aggregate;
  /** An array relationship */
  campaignsByApproverId: Array<v1_Campaign>;
  /** An aggregate relationship */
  campaignsByApproverId_aggregate: v1_Campaign_aggregate;
  /** An array relationship */
  campaignsByCreatedbyId: Array<v1_Campaign>;
  /** An aggregate relationship */
  campaignsByCreatedbyId_aggregate: v1_Campaign_aggregate;
  /** An array relationship */
  campaignsByOwnerId: Array<v1_Campaign>;
  /** An aggregate relationship */
  campaignsByOwnerId_aggregate: v1_Campaign_aggregate;
  /** An array relationship */
  campaignsByUploaderId: Array<v1_Campaign>;
  /** An aggregate relationship */
  campaignsByUploaderId_aggregate: v1_Campaign_aggregate;
  /** An array relationship */
  contactBasesByAssistantid: Array<v1_ContactBase>;
  /** An aggregate relationship */
  contactBasesByAssistantid_aggregate: v1_ContactBase_aggregate;
  /** An array relationship */
  contactBasesByOwnerId: Array<v1_ContactBase>;
  /** An aggregate relationship */
  contactBasesByOwnerId_aggregate: v1_ContactBase_aggregate;
  /** An array relationship */
  credentialsByCreatedbyId: Array<v1_Credentials>;
  /** An aggregate relationship */
  credentialsByCreatedbyId_aggregate: v1_Credentials_aggregate;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  invoiceBasesByOwnerId: Array<v1_InvoiceBase>;
  /** An aggregate relationship */
  invoiceBasesByOwnerId_aggregate: v1_InvoiceBase_aggregate;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  newPobasesByCreatedbyId: Array<v1_new_POBase>;
  /** An aggregate relationship */
  newPobasesByCreatedbyId_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  newPobasesByOwnerId: Array<v1_new_POBase>;
  /** An aggregate relationship */
  newPobasesByOwnerId_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  newPobasesByPaidbyId: Array<v1_new_POBase>;
  /** An aggregate relationship */
  newPobasesByPaidbyId_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  newReceiptbasesByOwnerId: Array<v1_new_receiptBase>;
  /** An aggregate relationship */
  newReceiptbasesByOwnerId_aggregate: v1_new_receiptBase_aggregate;
  /** An array relationship */
  new_POBases: Array<v1_new_POBase>;
  /** An aggregate relationship */
  new_POBases_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  new_brandBases: Array<v1_new_brandBase>;
  /** An aggregate relationship */
  new_brandBases_aggregate: v1_new_brandBase_aggregate;
  /** An array relationship */
  new_receiptBases: Array<v1_new_receiptBase>;
  /** An aggregate relationship */
  new_receiptBases_aggregate: v1_new_receiptBase_aggregate;
  /** An array relationship */
  new_trackerBases: Array<v1_new_trackerBase>;
  /** An aggregate relationship */
  new_trackerBases_aggregate: v1_new_trackerBase_aggregate;
  /** An array relationship */
  postsByApproverId: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByApproverId_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByCreatedbyId: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByCreatedbyId_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByOnlinechecksubmittedbyId: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByOnlinechecksubmittedbyId_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByOrderedbyId: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByOrderedbyId_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByRequestedbyId: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByRequestedbyId_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByRequestedfromId: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByRequestedfromId_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByUploadedbyId: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByUploadedbyId_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  postsByUploaderId: Array<v1_Posts>;
  /** An aggregate relationship */
  postsByUploaderId_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  posts_declinedby: Array<v1_Posts>;
  /** An aggregate relationship */
  posts_declinedby_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  projectsByOwnerId: Array<v1_Project>;
  /** An aggregate relationship */
  projectsByOwnerId_aggregate: v1_Project_aggregate;
  /** An array relationship */
  salesOrderBasesByOwnerId: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  salesOrderBasesByOwnerId_aggregate: v1_SalesOrderBase_aggregate;
  status: Scalars['Int']['output'];
};


/** User */
export type v1_SystemUserBaseAccountBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseAccountBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseBonusesArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** User */
export type v1_SystemUserBaseBonuses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** User */
export type v1_SystemUserBaseCampaignStrategiesArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


/** User */
export type v1_SystemUserBaseCampaignStrategies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


/** User */
export type v1_SystemUserBaseCampaignsArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBaseCampaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBaseContactBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseContactBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseCredentialsArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


/** User */
export type v1_SystemUserBaseCredentials_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


/** User */
export type v1_SystemUserBaseEventsArgs = {
  distinct_on?: InputMaybe<Array<v1_Events_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Events_order_by>>;
  where?: InputMaybe<v1_Events_bool_exp>;
};


/** User */
export type v1_SystemUserBaseEvents_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Events_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Events_order_by>>;
  where?: InputMaybe<v1_Events_bool_exp>;
};


/** User */
export type v1_SystemUserBaseInvoiceBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseInvoiceBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseInvoiceDetailBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseInvoiceDetailBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseInvoicesByCancelledByArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseInvoicesByCancelledBy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseLandingPagesArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


/** User */
export type v1_SystemUserBaseLandingPages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LandingPages_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LandingPages_order_by>>;
  where?: InputMaybe<v1_LandingPages_bool_exp>;
};


/** User */
export type v1_SystemUserBaseMergedPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBaseMergedPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBaseOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** User */
export type v1_SystemUserBaseOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** User */
export type v1_SystemUserBasePO_DeclinedbyArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasePO_Declinedby_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBaseProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** User */
export type v1_SystemUserBaseProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** User */
export type v1_SystemUserBasePurchaseOrdersByCancelledbyArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasePurchaseOrdersByCancelledby_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseReceiptsByCancellorArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseReceiptsByCancellor_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseRecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** User */
export type v1_SystemUserBaseRecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** User */
export type v1_SystemUserBaseSalesOrderBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseSalesOrderBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseUsers_RolesArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Roles_order_by>>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};


/** User */
export type v1_SystemUserBaseUsers_Roles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Users_Roles_order_by>>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};


/** User */
export type v1_SystemUserBaseViewsArgs = {
  distinct_on?: InputMaybe<Array<v1_Preferences_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Preferences_order_by>>;
  where?: InputMaybe<v1_Preferences_bool_exp>;
};


/** User */
export type v1_SystemUserBaseViews_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Preferences_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Preferences_order_by>>;
  where?: InputMaybe<v1_Preferences_bool_exp>;
};


/** User */
export type v1_SystemUserBaseWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** User */
export type v1_SystemUserBaseWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** User */
export type v1_SystemUserBaseaccountBasesByOwnerIdArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseaccountBasesByOwnerId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignStrategiesByModifiedByArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignStrategiesByModifiedBy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_CampaignStrategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_CampaignStrategy_order_by>>;
  where?: InputMaybe<v1_CampaignStrategy_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignsByApproverIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignsByApproverId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignsByCreatedbyIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignsByCreatedbyId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignsByOwnerIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignsByOwnerId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignsByUploaderIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBasecampaignsByUploaderId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** User */
export type v1_SystemUserBasecontactBasesByAssistantidArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasecontactBasesByAssistantid_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasecontactBasesByOwnerIdArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasecontactBasesByOwnerId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasecredentialsByCreatedbyIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


/** User */
export type v1_SystemUserBasecredentialsByCreatedbyId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Credentials_order_by>>;
  where?: InputMaybe<v1_Credentials_bool_exp>;
};


/** User */
export type v1_SystemUserBaseinvoiceBasesByOwnerIdArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** User */
export type v1_SystemUserBaseinvoiceBasesByOwnerId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenewPobasesByCreatedbyIdArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenewPobasesByCreatedbyId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenewPobasesByOwnerIdArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenewPobasesByOwnerId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenewPobasesByPaidbyIdArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenewPobasesByPaidbyId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenewReceiptbasesByOwnerIdArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenewReceiptbasesByOwnerId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenew_POBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenew_POBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenew_brandBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenew_brandBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_brandBase_order_by>>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenew_receiptBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenew_receiptBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenew_trackerBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasenew_trackerBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByApproverIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByApproverId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByCreatedbyIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByCreatedbyId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByOnlinechecksubmittedbyIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByOnlinechecksubmittedbyId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByOrderedbyIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByOrderedbyId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByRequestedbyIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByRequestedbyId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByRequestedfromIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByRequestedfromId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByUploadedbyIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByUploadedbyId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByUploaderIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBasepostsByUploaderId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBaseposts_declinedbyArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBaseposts_declinedby_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** User */
export type v1_SystemUserBaseprojectsByOwnerIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** User */
export type v1_SystemUserBaseprojectsByOwnerId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** User */
export type v1_SystemUserBasesalesOrderBasesByOwnerIdArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** User */
export type v1_SystemUserBasesalesOrderBasesByOwnerId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};

/** aggregated selection of "v1.SystemUserBase" */
export type v1_SystemUserBase_aggregate = {
  __typename?: 'v1_SystemUserBase_aggregate';
  aggregate?: Maybe<v1_SystemUserBase_aggregate_fields>;
  nodes: Array<v1_SystemUserBase>;
};

export type v1_SystemUserBase_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_SystemUserBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_SystemUserBase_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_SystemUserBase_aggregate_bool_exp_count>;
};

export type v1_SystemUserBase_aggregate_bool_exp_bool_and = {
  arguments: v1_SystemUserBase_select_column_v1_SystemUserBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_SystemUserBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_SystemUserBase_aggregate_bool_exp_bool_or = {
  arguments: v1_SystemUserBase_select_column_v1_SystemUserBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_SystemUserBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_SystemUserBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_SystemUserBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_SystemUserBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.SystemUserBase" */
export type v1_SystemUserBase_aggregate_fields = {
  __typename?: 'v1_SystemUserBase_aggregate_fields';
  avg?: Maybe<v1_SystemUserBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_SystemUserBase_max_fields>;
  min?: Maybe<v1_SystemUserBase_min_fields>;
  stddev?: Maybe<v1_SystemUserBase_stddev_fields>;
  stddev_pop?: Maybe<v1_SystemUserBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_SystemUserBase_stddev_samp_fields>;
  sum?: Maybe<v1_SystemUserBase_sum_fields>;
  var_pop?: Maybe<v1_SystemUserBase_var_pop_fields>;
  var_samp?: Maybe<v1_SystemUserBase_var_samp_fields>;
  variance?: Maybe<v1_SystemUserBase_variance_fields>;
};


/** aggregate fields of "v1.SystemUserBase" */
export type v1_SystemUserBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_SystemUserBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.SystemUserBase" */
export type v1_SystemUserBase_aggregate_order_by = {
  avg?: InputMaybe<v1_SystemUserBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_SystemUserBase_max_order_by>;
  min?: InputMaybe<v1_SystemUserBase_min_order_by>;
  stddev?: InputMaybe<v1_SystemUserBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_SystemUserBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_SystemUserBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_SystemUserBase_sum_order_by>;
  var_pop?: InputMaybe<v1_SystemUserBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_SystemUserBase_var_samp_order_by>;
  variance?: InputMaybe<v1_SystemUserBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.SystemUserBase" */
export type v1_SystemUserBase_arr_rel_insert_input = {
  data: Array<v1_SystemUserBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_SystemUserBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_SystemUserBase_avg_fields = {
  __typename?: 'v1_SystemUserBase_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_avg_order_by = {
  id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.SystemUserBase". All fields are combined with a logical 'AND'. */
export type v1_SystemUserBase_bool_exp = {
  AccountBases?: InputMaybe<v1_AccountBase_bool_exp>;
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_bool_exp>;
  AvatarPicture?: InputMaybe<String_comparison_exp>;
  Bonuses?: InputMaybe<v1_Bonuses_bool_exp>;
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_bool_exp>;
  CampaignStrategies?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  CampaignStrategies_aggregate?: InputMaybe<v1_CampaignStrategy_aggregate_bool_exp>;
  Campaigns?: InputMaybe<v1_Campaign_bool_exp>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  ContactBases?: InputMaybe<v1_ContactBase_bool_exp>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Credentials?: InputMaybe<v1_Credentials_bool_exp>;
  Credentials_aggregate?: InputMaybe<v1_Credentials_aggregate_bool_exp>;
  Events?: InputMaybe<v1_Events_bool_exp>;
  Events_aggregate?: InputMaybe<v1_Events_aggregate_bool_exp>;
  FirstName?: InputMaybe<String_comparison_exp>;
  InvoiceBases?: InputMaybe<v1_InvoiceBase_bool_exp>;
  InvoiceBases_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  InvoiceDetailBases?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
  InvoiceDetailBases_aggregate?: InputMaybe<v1_InvoiceDetailBase_aggregate_bool_exp>;
  InvoicesByCancelledBy?: InputMaybe<v1_InvoiceBase_bool_exp>;
  InvoicesByCancelledBy_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  IsDisabled?: InputMaybe<Boolean_comparison_exp>;
  JobTitle?: InputMaybe<bpchar_comparison_exp>;
  LandingPages?: InputMaybe<v1_LandingPages_bool_exp>;
  LandingPages_aggregate?: InputMaybe<v1_LandingPages_aggregate_bool_exp>;
  LastName?: InputMaybe<String_comparison_exp>;
  MergedPosts?: InputMaybe<v1_Posts_bool_exp>;
  MergedPosts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  PO_Declinedby?: InputMaybe<v1_new_POBase_bool_exp>;
  PO_Declinedby_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  PersonalEMailAddress?: InputMaybe<String_comparison_exp>;
  PhoneNumber?: InputMaybe<String_comparison_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  Projects?: InputMaybe<v1_Project_bool_exp>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_bool_exp>;
  PurchaseOrdersByCancelledby?: InputMaybe<v1_new_POBase_bool_exp>;
  PurchaseOrdersByCancelledby_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  ReceiptsByCancellor?: InputMaybe<v1_new_receiptBase_bool_exp>;
  ReceiptsByCancellor_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_bool_exp>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_bool_exp>;
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  Status?: InputMaybe<v1_UsersStatus_bool_exp>;
  SystemUserId?: InputMaybe<uuid_comparison_exp>;
  Users_Roles?: InputMaybe<v1_Users_Roles_bool_exp>;
  Users_Roles_aggregate?: InputMaybe<v1_Users_Roles_aggregate_bool_exp>;
  Views?: InputMaybe<v1_Preferences_bool_exp>;
  Views_aggregate?: InputMaybe<v1_Preferences_aggregate_bool_exp>;
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_SystemUserBase_bool_exp>>;
  _not?: InputMaybe<v1_SystemUserBase_bool_exp>;
  _or?: InputMaybe<Array<v1_SystemUserBase_bool_exp>>;
  accountBasesByOwnerId?: InputMaybe<v1_AccountBase_bool_exp>;
  accountBasesByOwnerId_aggregate?: InputMaybe<v1_AccountBase_aggregate_bool_exp>;
  campaignStrategiesByModifiedBy?: InputMaybe<v1_CampaignStrategy_bool_exp>;
  campaignStrategiesByModifiedBy_aggregate?: InputMaybe<v1_CampaignStrategy_aggregate_bool_exp>;
  campaignsByApproverId?: InputMaybe<v1_Campaign_bool_exp>;
  campaignsByApproverId_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  campaignsByCreatedbyId?: InputMaybe<v1_Campaign_bool_exp>;
  campaignsByCreatedbyId_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  campaignsByOwnerId?: InputMaybe<v1_Campaign_bool_exp>;
  campaignsByOwnerId_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  campaignsByUploaderId?: InputMaybe<v1_Campaign_bool_exp>;
  campaignsByUploaderId_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  contactBasesByAssistantid?: InputMaybe<v1_ContactBase_bool_exp>;
  contactBasesByAssistantid_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  contactBasesByOwnerId?: InputMaybe<v1_ContactBase_bool_exp>;
  contactBasesByOwnerId_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  credentialsByCreatedbyId?: InputMaybe<v1_Credentials_bool_exp>;
  credentialsByCreatedbyId_aggregate?: InputMaybe<v1_Credentials_aggregate_bool_exp>;
  email?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  invoiceBasesByOwnerId?: InputMaybe<v1_InvoiceBase_bool_exp>;
  invoiceBasesByOwnerId_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  name?: InputMaybe<String_comparison_exp>;
  newPobasesByCreatedbyId?: InputMaybe<v1_new_POBase_bool_exp>;
  newPobasesByCreatedbyId_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  newPobasesByOwnerId?: InputMaybe<v1_new_POBase_bool_exp>;
  newPobasesByOwnerId_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  newPobasesByPaidbyId?: InputMaybe<v1_new_POBase_bool_exp>;
  newPobasesByPaidbyId_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  newReceiptbasesByOwnerId?: InputMaybe<v1_new_receiptBase_bool_exp>;
  newReceiptbasesByOwnerId_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp>;
  new_POBases?: InputMaybe<v1_new_POBase_bool_exp>;
  new_POBases_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  new_brandBases?: InputMaybe<v1_new_brandBase_bool_exp>;
  new_brandBases_aggregate?: InputMaybe<v1_new_brandBase_aggregate_bool_exp>;
  new_receiptBases?: InputMaybe<v1_new_receiptBase_bool_exp>;
  new_receiptBases_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp>;
  new_trackerBases?: InputMaybe<v1_new_trackerBase_bool_exp>;
  new_trackerBases_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_bool_exp>;
  postsByApproverId?: InputMaybe<v1_Posts_bool_exp>;
  postsByApproverId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByCreatedbyId?: InputMaybe<v1_Posts_bool_exp>;
  postsByCreatedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByOnlinechecksubmittedbyId?: InputMaybe<v1_Posts_bool_exp>;
  postsByOnlinechecksubmittedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByOrderedbyId?: InputMaybe<v1_Posts_bool_exp>;
  postsByOrderedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByRequestedbyId?: InputMaybe<v1_Posts_bool_exp>;
  postsByRequestedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByRequestedfromId?: InputMaybe<v1_Posts_bool_exp>;
  postsByRequestedfromId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByUploadedbyId?: InputMaybe<v1_Posts_bool_exp>;
  postsByUploadedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  postsByUploaderId?: InputMaybe<v1_Posts_bool_exp>;
  postsByUploaderId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  posts_declinedby?: InputMaybe<v1_Posts_bool_exp>;
  posts_declinedby_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  projectsByOwnerId?: InputMaybe<v1_Project_bool_exp>;
  projectsByOwnerId_aggregate?: InputMaybe<v1_Project_aggregate_bool_exp>;
  salesOrderBasesByOwnerId?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  salesOrderBasesByOwnerId_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  status?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.SystemUserBase" */
export enum v1_SystemUserBase_constraint {
  /** unique or primary key constraint on columns "id" */
  SystemUserBase_id_key = 'SystemUserBase_id_key',
  /** unique or primary key constraint on columns "id" */
  SystemUserBase_pkey = 'SystemUserBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.SystemUserBase" */
export type v1_SystemUserBase_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.SystemUserBase" */
export type v1_SystemUserBase_insert_input = {
  AccountBases?: InputMaybe<v1_AccountBase_arr_rel_insert_input>;
  AvatarPicture?: InputMaybe<Scalars['String']['input']>;
  Bonuses?: InputMaybe<v1_Bonuses_arr_rel_insert_input>;
  CampaignStrategies?: InputMaybe<v1_CampaignStrategy_arr_rel_insert_input>;
  Campaigns?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  ContactBases?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Credentials?: InputMaybe<v1_Credentials_arr_rel_insert_input>;
  Events?: InputMaybe<v1_Events_arr_rel_insert_input>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  InvoiceBases?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  InvoiceDetailBases?: InputMaybe<v1_InvoiceDetailBase_arr_rel_insert_input>;
  InvoicesByCancelledBy?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  IsDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  JobTitle?: InputMaybe<Scalars['bpchar']['input']>;
  LandingPages?: InputMaybe<v1_LandingPages_arr_rel_insert_input>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  MergedPosts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  PO_Declinedby?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  PersonalEMailAddress?: InputMaybe<Scalars['String']['input']>;
  PhoneNumber?: InputMaybe<Scalars['String']['input']>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  Projects?: InputMaybe<v1_Project_arr_rel_insert_input>;
  PurchaseOrdersByCancelledby?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  ReceiptsByCancellor?: InputMaybe<v1_new_receiptBase_arr_rel_insert_input>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_arr_rel_insert_input>;
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  Status?: InputMaybe<v1_UsersStatus_obj_rel_insert_input>;
  SystemUserId?: InputMaybe<Scalars['uuid']['input']>;
  Users_Roles?: InputMaybe<v1_Users_Roles_arr_rel_insert_input>;
  Views?: InputMaybe<v1_Preferences_arr_rel_insert_input>;
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  accountBasesByOwnerId?: InputMaybe<v1_AccountBase_arr_rel_insert_input>;
  campaignStrategiesByModifiedBy?: InputMaybe<v1_CampaignStrategy_arr_rel_insert_input>;
  campaignsByApproverId?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  campaignsByCreatedbyId?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  campaignsByOwnerId?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  campaignsByUploaderId?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  contactBasesByAssistantid?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  contactBasesByOwnerId?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  credentialsByCreatedbyId?: InputMaybe<v1_Credentials_arr_rel_insert_input>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoiceBasesByOwnerId?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  name?: InputMaybe<Scalars['String']['input']>;
  newPobasesByCreatedbyId?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  newPobasesByOwnerId?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  newPobasesByPaidbyId?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  newReceiptbasesByOwnerId?: InputMaybe<v1_new_receiptBase_arr_rel_insert_input>;
  new_POBases?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  new_brandBases?: InputMaybe<v1_new_brandBase_arr_rel_insert_input>;
  new_receiptBases?: InputMaybe<v1_new_receiptBase_arr_rel_insert_input>;
  new_trackerBases?: InputMaybe<v1_new_trackerBase_arr_rel_insert_input>;
  postsByApproverId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByCreatedbyId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByOnlinechecksubmittedbyId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByOrderedbyId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByRequestedbyId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByRequestedfromId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByUploadedbyId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  postsByUploaderId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  posts_declinedby?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  projectsByOwnerId?: InputMaybe<v1_Project_arr_rel_insert_input>;
  salesOrderBasesByOwnerId?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_SystemUserBase_max_fields = {
  __typename?: 'v1_SystemUserBase_max_fields';
  AvatarPicture?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['bpchar']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  PersonalEMailAddress?: Maybe<Scalars['String']['output']>;
  PhoneNumber?: Maybe<Scalars['String']['output']>;
  SystemUserId?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_max_order_by = {
  AvatarPicture?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  FirstName?: InputMaybe<order_by>;
  JobTitle?: InputMaybe<order_by>;
  LastName?: InputMaybe<order_by>;
  PersonalEMailAddress?: InputMaybe<order_by>;
  PhoneNumber?: InputMaybe<order_by>;
  SystemUserId?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_SystemUserBase_min_fields = {
  __typename?: 'v1_SystemUserBase_min_fields';
  AvatarPicture?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['bpchar']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  PersonalEMailAddress?: Maybe<Scalars['String']['output']>;
  PhoneNumber?: Maybe<Scalars['String']['output']>;
  SystemUserId?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_min_order_by = {
  AvatarPicture?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  FirstName?: InputMaybe<order_by>;
  JobTitle?: InputMaybe<order_by>;
  LastName?: InputMaybe<order_by>;
  PersonalEMailAddress?: InputMaybe<order_by>;
  PhoneNumber?: InputMaybe<order_by>;
  SystemUserId?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.SystemUserBase" */
export type v1_SystemUserBase_mutation_response = {
  __typename?: 'v1_SystemUserBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_SystemUserBase>;
};

/** input type for inserting object relation for remote table "v1.SystemUserBase" */
export type v1_SystemUserBase_obj_rel_insert_input = {
  data: v1_SystemUserBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_SystemUserBase_on_conflict>;
};

/** on_conflict condition type for table "v1.SystemUserBase" */
export type v1_SystemUserBase_on_conflict = {
  constraint: v1_SystemUserBase_constraint;
  update_columns?: Array<v1_SystemUserBase_update_column>;
  where?: InputMaybe<v1_SystemUserBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.SystemUserBase". */
export type v1_SystemUserBase_order_by = {
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_order_by>;
  AvatarPicture?: InputMaybe<order_by>;
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_order_by>;
  CampaignStrategies_aggregate?: InputMaybe<v1_CampaignStrategy_aggregate_order_by>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Credentials_aggregate?: InputMaybe<v1_Credentials_aggregate_order_by>;
  Events_aggregate?: InputMaybe<v1_Events_aggregate_order_by>;
  FirstName?: InputMaybe<order_by>;
  InvoiceBases_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  InvoiceDetailBases_aggregate?: InputMaybe<v1_InvoiceDetailBase_aggregate_order_by>;
  InvoicesByCancelledBy_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  IsDisabled?: InputMaybe<order_by>;
  JobTitle?: InputMaybe<order_by>;
  LandingPages_aggregate?: InputMaybe<v1_LandingPages_aggregate_order_by>;
  LastName?: InputMaybe<order_by>;
  MergedPosts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  PO_Declinedby_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  PersonalEMailAddress?: InputMaybe<order_by>;
  PhoneNumber?: InputMaybe<order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_order_by>;
  PurchaseOrdersByCancelledby_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  ReceiptsByCancellor_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_order_by>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_order_by>;
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  Status?: InputMaybe<v1_UsersStatus_order_by>;
  SystemUserId?: InputMaybe<order_by>;
  Users_Roles_aggregate?: InputMaybe<v1_Users_Roles_aggregate_order_by>;
  Views_aggregate?: InputMaybe<v1_Preferences_aggregate_order_by>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  accountBasesByOwnerId_aggregate?: InputMaybe<v1_AccountBase_aggregate_order_by>;
  campaignStrategiesByModifiedBy_aggregate?: InputMaybe<v1_CampaignStrategy_aggregate_order_by>;
  campaignsByApproverId_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  campaignsByCreatedbyId_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  campaignsByOwnerId_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  campaignsByUploaderId_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  contactBasesByAssistantid_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  contactBasesByOwnerId_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  credentialsByCreatedbyId_aggregate?: InputMaybe<v1_Credentials_aggregate_order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  invoiceBasesByOwnerId_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  name?: InputMaybe<order_by>;
  newPobasesByCreatedbyId_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  newPobasesByOwnerId_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  newPobasesByPaidbyId_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  newReceiptbasesByOwnerId_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_order_by>;
  new_POBases_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  new_brandBases_aggregate?: InputMaybe<v1_new_brandBase_aggregate_order_by>;
  new_receiptBases_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_order_by>;
  new_trackerBases_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_order_by>;
  postsByApproverId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByCreatedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByOnlinechecksubmittedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByOrderedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByRequestedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByRequestedfromId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByUploadedbyId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  postsByUploaderId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  posts_declinedby_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  projectsByOwnerId_aggregate?: InputMaybe<v1_Project_aggregate_order_by>;
  salesOrderBasesByOwnerId_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  status?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.SystemUserBase */
export type v1_SystemUserBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.SystemUserBase" */
export enum v1_SystemUserBase_select_column {
  /** column name */
  AvatarPicture = 'AvatarPicture',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  IsDisabled = 'IsDisabled',
  /** column name */
  JobTitle = 'JobTitle',
  /** column name */
  LastName = 'LastName',
  /** column name */
  PersonalEMailAddress = 'PersonalEMailAddress',
  /** column name */
  PhoneNumber = 'PhoneNumber',
  /** column name */
  SystemUserId = 'SystemUserId',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  status = 'status'
}

/** select "v1_SystemUserBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.SystemUserBase" */
export enum v1_SystemUserBase_select_column_v1_SystemUserBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  IsDisabled = 'IsDisabled'
}

/** select "v1_SystemUserBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.SystemUserBase" */
export enum v1_SystemUserBase_select_column_v1_SystemUserBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  IsDisabled = 'IsDisabled'
}

/** input type for updating data in table "v1.SystemUserBase" */
export type v1_SystemUserBase_set_input = {
  AvatarPicture?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  IsDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  JobTitle?: InputMaybe<Scalars['bpchar']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  PersonalEMailAddress?: InputMaybe<Scalars['String']['input']>;
  PhoneNumber?: InputMaybe<Scalars['String']['input']>;
  SystemUserId?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_SystemUserBase_stddev_fields = {
  __typename?: 'v1_SystemUserBase_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_stddev_order_by = {
  id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_SystemUserBase_stddev_pop_fields = {
  __typename?: 'v1_SystemUserBase_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_SystemUserBase_stddev_samp_fields = {
  __typename?: 'v1_SystemUserBase_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_SystemUserBase" */
export type v1_SystemUserBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_SystemUserBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_SystemUserBase_stream_cursor_value_input = {
  AvatarPicture?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  IsDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  JobTitle?: InputMaybe<Scalars['bpchar']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  PersonalEMailAddress?: InputMaybe<Scalars['String']['input']>;
  PhoneNumber?: InputMaybe<Scalars['String']['input']>;
  SystemUserId?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_SystemUserBase_sum_fields = {
  __typename?: 'v1_SystemUserBase_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_sum_order_by = {
  id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** update columns of table "v1.SystemUserBase" */
export enum v1_SystemUserBase_update_column {
  /** column name */
  AvatarPicture = 'AvatarPicture',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  IsDisabled = 'IsDisabled',
  /** column name */
  JobTitle = 'JobTitle',
  /** column name */
  LastName = 'LastName',
  /** column name */
  PersonalEMailAddress = 'PersonalEMailAddress',
  /** column name */
  PhoneNumber = 'PhoneNumber',
  /** column name */
  SystemUserId = 'SystemUserId',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  status = 'status'
}

export type v1_SystemUserBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_SystemUserBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_SystemUserBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_SystemUserBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_SystemUserBase_var_pop_fields = {
  __typename?: 'v1_SystemUserBase_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_SystemUserBase_var_samp_fields = {
  __typename?: 'v1_SystemUserBase_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_SystemUserBase_variance_fields = {
  __typename?: 'v1_SystemUserBase_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.SystemUserBase" */
export type v1_SystemUserBase_variance_order_by = {
  id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Tags" */
export type v1_Tags = {
  __typename?: 'v1_Tags';
  /** An array relationship */
  Tags: Array<v1_Supplier_Tag_rel>;
  /** An aggregate relationship */
  Tags_aggregate: v1_Supplier_Tag_rel_aggregate;
  id: Scalars['Int']['output'];
  supplier_tag: Scalars['String']['output'];
};


/** columns and relationships of "v1.Tags" */
export type v1_TagsTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};


/** columns and relationships of "v1.Tags" */
export type v1_TagsTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Supplier_Tag_rel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Supplier_Tag_rel_order_by>>;
  where?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
};

/** aggregated selection of "v1.Tags" */
export type v1_Tags_aggregate = {
  __typename?: 'v1_Tags_aggregate';
  aggregate?: Maybe<v1_Tags_aggregate_fields>;
  nodes: Array<v1_Tags>;
};

/** aggregate fields of "v1.Tags" */
export type v1_Tags_aggregate_fields = {
  __typename?: 'v1_Tags_aggregate_fields';
  avg?: Maybe<v1_Tags_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Tags_max_fields>;
  min?: Maybe<v1_Tags_min_fields>;
  stddev?: Maybe<v1_Tags_stddev_fields>;
  stddev_pop?: Maybe<v1_Tags_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Tags_stddev_samp_fields>;
  sum?: Maybe<v1_Tags_sum_fields>;
  var_pop?: Maybe<v1_Tags_var_pop_fields>;
  var_samp?: Maybe<v1_Tags_var_samp_fields>;
  variance?: Maybe<v1_Tags_variance_fields>;
};


/** aggregate fields of "v1.Tags" */
export type v1_Tags_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Tags_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Tags_avg_fields = {
  __typename?: 'v1_Tags_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Tags". All fields are combined with a logical 'AND'. */
export type v1_Tags_bool_exp = {
  Tags?: InputMaybe<v1_Supplier_Tag_rel_bool_exp>;
  Tags_aggregate?: InputMaybe<v1_Supplier_Tag_rel_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_Tags_bool_exp>>;
  _not?: InputMaybe<v1_Tags_bool_exp>;
  _or?: InputMaybe<Array<v1_Tags_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  supplier_tag?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Tags" */
export enum v1_Tags_constraint {
  /** unique or primary key constraint on columns "id" */
  PK_Tags = 'PK_Tags'
}

/** input type for incrementing numeric columns in table "v1.Tags" */
export type v1_Tags_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Tags" */
export type v1_Tags_insert_input = {
  Tags?: InputMaybe<v1_Supplier_Tag_rel_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  supplier_tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Tags_max_fields = {
  __typename?: 'v1_Tags_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  supplier_tag?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_Tags_min_fields = {
  __typename?: 'v1_Tags_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  supplier_tag?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.Tags" */
export type v1_Tags_mutation_response = {
  __typename?: 'v1_Tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Tags>;
};

/** input type for inserting object relation for remote table "v1.Tags" */
export type v1_Tags_obj_rel_insert_input = {
  data: v1_Tags_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Tags_on_conflict>;
};

/** on_conflict condition type for table "v1.Tags" */
export type v1_Tags_on_conflict = {
  constraint: v1_Tags_constraint;
  update_columns?: Array<v1_Tags_update_column>;
  where?: InputMaybe<v1_Tags_bool_exp>;
};

/** Ordering options when selecting data from "v1.Tags". */
export type v1_Tags_order_by = {
  Tags_aggregate?: InputMaybe<v1_Supplier_Tag_rel_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  supplier_tag?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Tags */
export type v1_Tags_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Tags" */
export enum v1_Tags_select_column {
  /** column name */
  id = 'id',
  /** column name */
  supplier_tag = 'supplier_tag'
}

/** input type for updating data in table "v1.Tags" */
export type v1_Tags_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  supplier_tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Tags_stddev_fields = {
  __typename?: 'v1_Tags_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Tags_stddev_pop_fields = {
  __typename?: 'v1_Tags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Tags_stddev_samp_fields = {
  __typename?: 'v1_Tags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Tags" */
export type v1_Tags_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Tags_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Tags_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  supplier_tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Tags_sum_fields = {
  __typename?: 'v1_Tags_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Tags" */
export enum v1_Tags_update_column {
  /** column name */
  id = 'id',
  /** column name */
  supplier_tag = 'supplier_tag'
}

export type v1_Tags_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Tags_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Tags_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Tags_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Tags_var_pop_fields = {
  __typename?: 'v1_Tags_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Tags_var_samp_fields = {
  __typename?: 'v1_Tags_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Tags_variance_fields = {
  __typename?: 'v1_Tags_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Teams contain many Users */
export type v1_Teams = {
  __typename?: 'v1_Teams';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "v1.Teams" */
export type v1_Teams_aggregate = {
  __typename?: 'v1_Teams_aggregate';
  aggregate?: Maybe<v1_Teams_aggregate_fields>;
  nodes: Array<v1_Teams>;
};

/** aggregate fields of "v1.Teams" */
export type v1_Teams_aggregate_fields = {
  __typename?: 'v1_Teams_aggregate_fields';
  avg?: Maybe<v1_Teams_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Teams_max_fields>;
  min?: Maybe<v1_Teams_min_fields>;
  stddev?: Maybe<v1_Teams_stddev_fields>;
  stddev_pop?: Maybe<v1_Teams_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Teams_stddev_samp_fields>;
  sum?: Maybe<v1_Teams_sum_fields>;
  var_pop?: Maybe<v1_Teams_var_pop_fields>;
  var_samp?: Maybe<v1_Teams_var_samp_fields>;
  variance?: Maybe<v1_Teams_variance_fields>;
};


/** aggregate fields of "v1.Teams" */
export type v1_Teams_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Teams_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Teams_avg_fields = {
  __typename?: 'v1_Teams_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Teams". All fields are combined with a logical 'AND'. */
export type v1_Teams_bool_exp = {
  _and?: InputMaybe<Array<v1_Teams_bool_exp>>;
  _not?: InputMaybe<v1_Teams_bool_exp>;
  _or?: InputMaybe<Array<v1_Teams_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Teams" */
export enum v1_Teams_constraint {
  /** unique or primary key constraint on columns "id" */
  Teams_pkey = 'Teams_pkey'
}

/** input type for incrementing numeric columns in table "v1.Teams" */
export type v1_Teams_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Teams" */
export type v1_Teams_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Teams_max_fields = {
  __typename?: 'v1_Teams_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_Teams_min_fields = {
  __typename?: 'v1_Teams_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.Teams" */
export type v1_Teams_mutation_response = {
  __typename?: 'v1_Teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Teams>;
};

/** on_conflict condition type for table "v1.Teams" */
export type v1_Teams_on_conflict = {
  constraint: v1_Teams_constraint;
  update_columns?: Array<v1_Teams_update_column>;
  where?: InputMaybe<v1_Teams_bool_exp>;
};

/** Ordering options when selecting data from "v1.Teams". */
export type v1_Teams_order_by = {
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Teams */
export type v1_Teams_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Teams" */
export enum v1_Teams_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.Teams" */
export type v1_Teams_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Teams_stddev_fields = {
  __typename?: 'v1_Teams_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Teams_stddev_pop_fields = {
  __typename?: 'v1_Teams_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Teams_stddev_samp_fields = {
  __typename?: 'v1_Teams_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Teams" */
export type v1_Teams_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Teams_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Teams_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Teams_sum_fields = {
  __typename?: 'v1_Teams_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Teams" */
export enum v1_Teams_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_Teams_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Teams_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Teams_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Teams_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Teams_var_pop_fields = {
  __typename?: 'v1_Teams_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Teams_var_samp_fields = {
  __typename?: 'v1_Teams_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Teams_variance_fields = {
  __typename?: 'v1_Teams_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.TrackerDeal" */
export type v1_TrackerDeal = {
  __typename?: 'v1_TrackerDeal';
  /** An array relationship */
  Trackers: Array<v1_new_trackerBase>;
  /** An aggregate relationship */
  Trackers_aggregate: v1_new_trackerBase_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.TrackerDeal" */
export type v1_TrackerDealTrackersArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


/** columns and relationships of "v1.TrackerDeal" */
export type v1_TrackerDealTrackers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};

/** aggregated selection of "v1.TrackerDeal" */
export type v1_TrackerDeal_aggregate = {
  __typename?: 'v1_TrackerDeal_aggregate';
  aggregate?: Maybe<v1_TrackerDeal_aggregate_fields>;
  nodes: Array<v1_TrackerDeal>;
};

/** aggregate fields of "v1.TrackerDeal" */
export type v1_TrackerDeal_aggregate_fields = {
  __typename?: 'v1_TrackerDeal_aggregate_fields';
  avg?: Maybe<v1_TrackerDeal_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_TrackerDeal_max_fields>;
  min?: Maybe<v1_TrackerDeal_min_fields>;
  stddev?: Maybe<v1_TrackerDeal_stddev_fields>;
  stddev_pop?: Maybe<v1_TrackerDeal_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_TrackerDeal_stddev_samp_fields>;
  sum?: Maybe<v1_TrackerDeal_sum_fields>;
  var_pop?: Maybe<v1_TrackerDeal_var_pop_fields>;
  var_samp?: Maybe<v1_TrackerDeal_var_samp_fields>;
  variance?: Maybe<v1_TrackerDeal_variance_fields>;
};


/** aggregate fields of "v1.TrackerDeal" */
export type v1_TrackerDeal_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_TrackerDeal_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_TrackerDeal_avg_fields = {
  __typename?: 'v1_TrackerDeal_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.TrackerDeal". All fields are combined with a logical 'AND'. */
export type v1_TrackerDeal_bool_exp = {
  Trackers?: InputMaybe<v1_new_trackerBase_bool_exp>;
  Trackers_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_TrackerDeal_bool_exp>>;
  _not?: InputMaybe<v1_TrackerDeal_bool_exp>;
  _or?: InputMaybe<Array<v1_TrackerDeal_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.TrackerDeal" */
export enum v1_TrackerDeal_constraint {
  /** unique or primary key constraint on columns "id" */
  TrackerDeal_pkey = 'TrackerDeal_pkey'
}

/** input type for incrementing numeric columns in table "v1.TrackerDeal" */
export type v1_TrackerDeal_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.TrackerDeal" */
export type v1_TrackerDeal_insert_input = {
  Trackers?: InputMaybe<v1_new_trackerBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_TrackerDeal_max_fields = {
  __typename?: 'v1_TrackerDeal_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_TrackerDeal_min_fields = {
  __typename?: 'v1_TrackerDeal_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.TrackerDeal" */
export type v1_TrackerDeal_mutation_response = {
  __typename?: 'v1_TrackerDeal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_TrackerDeal>;
};

/** input type for inserting object relation for remote table "v1.TrackerDeal" */
export type v1_TrackerDeal_obj_rel_insert_input = {
  data: v1_TrackerDeal_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_TrackerDeal_on_conflict>;
};

/** on_conflict condition type for table "v1.TrackerDeal" */
export type v1_TrackerDeal_on_conflict = {
  constraint: v1_TrackerDeal_constraint;
  update_columns?: Array<v1_TrackerDeal_update_column>;
  where?: InputMaybe<v1_TrackerDeal_bool_exp>;
};

/** Ordering options when selecting data from "v1.TrackerDeal". */
export type v1_TrackerDeal_order_by = {
  Trackers_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.TrackerDeal */
export type v1_TrackerDeal_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.TrackerDeal" */
export enum v1_TrackerDeal_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.TrackerDeal" */
export type v1_TrackerDeal_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_TrackerDeal_stddev_fields = {
  __typename?: 'v1_TrackerDeal_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_TrackerDeal_stddev_pop_fields = {
  __typename?: 'v1_TrackerDeal_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_TrackerDeal_stddev_samp_fields = {
  __typename?: 'v1_TrackerDeal_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_TrackerDeal" */
export type v1_TrackerDeal_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_TrackerDeal_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_TrackerDeal_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_TrackerDeal_sum_fields = {
  __typename?: 'v1_TrackerDeal_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.TrackerDeal" */
export enum v1_TrackerDeal_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_TrackerDeal_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_TrackerDeal_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_TrackerDeal_set_input>;
  /** filter the rows which have to be updated */
  where: v1_TrackerDeal_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_TrackerDeal_var_pop_fields = {
  __typename?: 'v1_TrackerDeal_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_TrackerDeal_var_samp_fields = {
  __typename?: 'v1_TrackerDeal_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_TrackerDeal_variance_fields = {
  __typename?: 'v1_TrackerDeal_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.TrackerTags" */
export type v1_TrackerTags = {
  __typename?: 'v1_TrackerTags';
  /** An array relationship */
  TrackerToTags: Array<v1_TrackerToTags>;
  /** An aggregate relationship */
  TrackerToTags_aggregate: v1_TrackerToTags_aggregate;
  id: Scalars['Int']['output'];
  tracker_tag?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "v1.TrackerTags" */
export type v1_TrackerTagsTrackerToTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerToTags_order_by>>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};


/** columns and relationships of "v1.TrackerTags" */
export type v1_TrackerTagsTrackerToTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerToTags_order_by>>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};

/** aggregated selection of "v1.TrackerTags" */
export type v1_TrackerTags_aggregate = {
  __typename?: 'v1_TrackerTags_aggregate';
  aggregate?: Maybe<v1_TrackerTags_aggregate_fields>;
  nodes: Array<v1_TrackerTags>;
};

/** aggregate fields of "v1.TrackerTags" */
export type v1_TrackerTags_aggregate_fields = {
  __typename?: 'v1_TrackerTags_aggregate_fields';
  avg?: Maybe<v1_TrackerTags_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_TrackerTags_max_fields>;
  min?: Maybe<v1_TrackerTags_min_fields>;
  stddev?: Maybe<v1_TrackerTags_stddev_fields>;
  stddev_pop?: Maybe<v1_TrackerTags_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_TrackerTags_stddev_samp_fields>;
  sum?: Maybe<v1_TrackerTags_sum_fields>;
  var_pop?: Maybe<v1_TrackerTags_var_pop_fields>;
  var_samp?: Maybe<v1_TrackerTags_var_samp_fields>;
  variance?: Maybe<v1_TrackerTags_variance_fields>;
};


/** aggregate fields of "v1.TrackerTags" */
export type v1_TrackerTags_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_TrackerTags_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_TrackerTags_avg_fields = {
  __typename?: 'v1_TrackerTags_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.TrackerTags". All fields are combined with a logical 'AND'. */
export type v1_TrackerTags_bool_exp = {
  TrackerToTags?: InputMaybe<v1_TrackerToTags_bool_exp>;
  TrackerToTags_aggregate?: InputMaybe<v1_TrackerToTags_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_TrackerTags_bool_exp>>;
  _not?: InputMaybe<v1_TrackerTags_bool_exp>;
  _or?: InputMaybe<Array<v1_TrackerTags_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  tracker_tag?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.TrackerTags" */
export enum v1_TrackerTags_constraint {
  /** unique or primary key constraint on columns "id" */
  TrackerTags_pkey = 'TrackerTags_pkey'
}

/** input type for incrementing numeric columns in table "v1.TrackerTags" */
export type v1_TrackerTags_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.TrackerTags" */
export type v1_TrackerTags_insert_input = {
  TrackerToTags?: InputMaybe<v1_TrackerToTags_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  tracker_tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_TrackerTags_max_fields = {
  __typename?: 'v1_TrackerTags_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  tracker_tag?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_TrackerTags_min_fields = {
  __typename?: 'v1_TrackerTags_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  tracker_tag?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.TrackerTags" */
export type v1_TrackerTags_mutation_response = {
  __typename?: 'v1_TrackerTags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_TrackerTags>;
};

/** input type for inserting object relation for remote table "v1.TrackerTags" */
export type v1_TrackerTags_obj_rel_insert_input = {
  data: v1_TrackerTags_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_TrackerTags_on_conflict>;
};

/** on_conflict condition type for table "v1.TrackerTags" */
export type v1_TrackerTags_on_conflict = {
  constraint: v1_TrackerTags_constraint;
  update_columns?: Array<v1_TrackerTags_update_column>;
  where?: InputMaybe<v1_TrackerTags_bool_exp>;
};

/** Ordering options when selecting data from "v1.TrackerTags". */
export type v1_TrackerTags_order_by = {
  TrackerToTags_aggregate?: InputMaybe<v1_TrackerToTags_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  tracker_tag?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.TrackerTags */
export type v1_TrackerTags_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.TrackerTags" */
export enum v1_TrackerTags_select_column {
  /** column name */
  id = 'id',
  /** column name */
  tracker_tag = 'tracker_tag'
}

/** input type for updating data in table "v1.TrackerTags" */
export type v1_TrackerTags_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tracker_tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_TrackerTags_stddev_fields = {
  __typename?: 'v1_TrackerTags_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_TrackerTags_stddev_pop_fields = {
  __typename?: 'v1_TrackerTags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_TrackerTags_stddev_samp_fields = {
  __typename?: 'v1_TrackerTags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_TrackerTags" */
export type v1_TrackerTags_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_TrackerTags_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_TrackerTags_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tracker_tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_TrackerTags_sum_fields = {
  __typename?: 'v1_TrackerTags_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.TrackerTags" */
export enum v1_TrackerTags_update_column {
  /** column name */
  id = 'id',
  /** column name */
  tracker_tag = 'tracker_tag'
}

export type v1_TrackerTags_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_TrackerTags_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_TrackerTags_set_input>;
  /** filter the rows which have to be updated */
  where: v1_TrackerTags_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_TrackerTags_var_pop_fields = {
  __typename?: 'v1_TrackerTags_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_TrackerTags_var_samp_fields = {
  __typename?: 'v1_TrackerTags_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_TrackerTags_variance_fields = {
  __typename?: 'v1_TrackerTags_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.TrackerToTags" */
export type v1_TrackerToTags = {
  __typename?: 'v1_TrackerToTags';
  /** An object relationship */
  TrackerTag: v1_TrackerTags;
  id: Scalars['Int']['output'];
  /** An object relationship */
  new_trackerBase: v1_new_trackerBase;
  tag_id: Scalars['Int']['output'];
  tracker_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.TrackerToTags" */
export type v1_TrackerToTags_aggregate = {
  __typename?: 'v1_TrackerToTags_aggregate';
  aggregate?: Maybe<v1_TrackerToTags_aggregate_fields>;
  nodes: Array<v1_TrackerToTags>;
};

export type v1_TrackerToTags_aggregate_bool_exp = {
  count?: InputMaybe<v1_TrackerToTags_aggregate_bool_exp_count>;
};

export type v1_TrackerToTags_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_TrackerToTags_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.TrackerToTags" */
export type v1_TrackerToTags_aggregate_fields = {
  __typename?: 'v1_TrackerToTags_aggregate_fields';
  avg?: Maybe<v1_TrackerToTags_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_TrackerToTags_max_fields>;
  min?: Maybe<v1_TrackerToTags_min_fields>;
  stddev?: Maybe<v1_TrackerToTags_stddev_fields>;
  stddev_pop?: Maybe<v1_TrackerToTags_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_TrackerToTags_stddev_samp_fields>;
  sum?: Maybe<v1_TrackerToTags_sum_fields>;
  var_pop?: Maybe<v1_TrackerToTags_var_pop_fields>;
  var_samp?: Maybe<v1_TrackerToTags_var_samp_fields>;
  variance?: Maybe<v1_TrackerToTags_variance_fields>;
};


/** aggregate fields of "v1.TrackerToTags" */
export type v1_TrackerToTags_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.TrackerToTags" */
export type v1_TrackerToTags_aggregate_order_by = {
  avg?: InputMaybe<v1_TrackerToTags_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_TrackerToTags_max_order_by>;
  min?: InputMaybe<v1_TrackerToTags_min_order_by>;
  stddev?: InputMaybe<v1_TrackerToTags_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_TrackerToTags_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_TrackerToTags_stddev_samp_order_by>;
  sum?: InputMaybe<v1_TrackerToTags_sum_order_by>;
  var_pop?: InputMaybe<v1_TrackerToTags_var_pop_order_by>;
  var_samp?: InputMaybe<v1_TrackerToTags_var_samp_order_by>;
  variance?: InputMaybe<v1_TrackerToTags_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.TrackerToTags" */
export type v1_TrackerToTags_arr_rel_insert_input = {
  data: Array<v1_TrackerToTags_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_TrackerToTags_on_conflict>;
};

/** aggregate avg on columns */
export type v1_TrackerToTags_avg_fields = {
  __typename?: 'v1_TrackerToTags_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_avg_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.TrackerToTags". All fields are combined with a logical 'AND'. */
export type v1_TrackerToTags_bool_exp = {
  TrackerTag?: InputMaybe<v1_TrackerTags_bool_exp>;
  _and?: InputMaybe<Array<v1_TrackerToTags_bool_exp>>;
  _not?: InputMaybe<v1_TrackerToTags_bool_exp>;
  _or?: InputMaybe<Array<v1_TrackerToTags_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_trackerBase?: InputMaybe<v1_new_trackerBase_bool_exp>;
  tag_id?: InputMaybe<Int_comparison_exp>;
  tracker_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.TrackerToTags" */
export enum v1_TrackerToTags_constraint {
  /** unique or primary key constraint on columns "id" */
  TrackerToTags_pkey = 'TrackerToTags_pkey',
  /** unique or primary key constraint on columns "tag_id", "tracker_id" */
  unique_tracker_tag = 'unique_tracker_tag'
}

/** input type for incrementing numeric columns in table "v1.TrackerToTags" */
export type v1_TrackerToTags_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  tracker_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.TrackerToTags" */
export type v1_TrackerToTags_insert_input = {
  TrackerTag?: InputMaybe<v1_TrackerTags_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_trackerBase?: InputMaybe<v1_new_trackerBase_obj_rel_insert_input>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  tracker_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_TrackerToTags_max_fields = {
  __typename?: 'v1_TrackerToTags_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  tracker_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_max_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_TrackerToTags_min_fields = {
  __typename?: 'v1_TrackerToTags_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  tracker_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_min_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.TrackerToTags" */
export type v1_TrackerToTags_mutation_response = {
  __typename?: 'v1_TrackerToTags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_TrackerToTags>;
};

/** on_conflict condition type for table "v1.TrackerToTags" */
export type v1_TrackerToTags_on_conflict = {
  constraint: v1_TrackerToTags_constraint;
  update_columns?: Array<v1_TrackerToTags_update_column>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};

/** Ordering options when selecting data from "v1.TrackerToTags". */
export type v1_TrackerToTags_order_by = {
  TrackerTag?: InputMaybe<v1_TrackerTags_order_by>;
  id?: InputMaybe<order_by>;
  new_trackerBase?: InputMaybe<v1_new_trackerBase_order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.TrackerToTags */
export type v1_TrackerToTags_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.TrackerToTags" */
export enum v1_TrackerToTags_select_column {
  /** column name */
  id = 'id',
  /** column name */
  tag_id = 'tag_id',
  /** column name */
  tracker_id = 'tracker_id'
}

/** input type for updating data in table "v1.TrackerToTags" */
export type v1_TrackerToTags_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  tracker_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_TrackerToTags_stddev_fields = {
  __typename?: 'v1_TrackerToTags_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_stddev_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_TrackerToTags_stddev_pop_fields = {
  __typename?: 'v1_TrackerToTags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_TrackerToTags_stddev_samp_fields = {
  __typename?: 'v1_TrackerToTags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_TrackerToTags" */
export type v1_TrackerToTags_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_TrackerToTags_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_TrackerToTags_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
  tracker_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_TrackerToTags_sum_fields = {
  __typename?: 'v1_TrackerToTags_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  tracker_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_sum_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.TrackerToTags" */
export enum v1_TrackerToTags_update_column {
  /** column name */
  id = 'id',
  /** column name */
  tag_id = 'tag_id',
  /** column name */
  tracker_id = 'tracker_id'
}

export type v1_TrackerToTags_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_TrackerToTags_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_TrackerToTags_set_input>;
  /** filter the rows which have to be updated */
  where: v1_TrackerToTags_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_TrackerToTags_var_pop_fields = {
  __typename?: 'v1_TrackerToTags_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_TrackerToTags_var_samp_fields = {
  __typename?: 'v1_TrackerToTags_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_TrackerToTags_variance_fields = {
  __typename?: 'v1_TrackerToTags_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.TrackerToTags" */
export type v1_TrackerToTags_variance_order_by = {
  id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase = {
  __typename?: 'v1_TransactionCurrencyBase';
  /** An array relationship */
  Balances: Array<v1_Balance>;
  /** An aggregate relationship */
  Balances_aggregate: v1_Balance_aggregate;
  /** An array relationship */
  BillingMethods: Array<v1_BillingMethod>;
  /** An aggregate relationship */
  BillingMethods_aggregate: v1_BillingMethod_aggregate;
  /** An array relationship */
  BonusesToCurrencies: Array<v1_BonusesToCurrencies>;
  /** An aggregate relationship */
  BonusesToCurrencies_aggregate: v1_BonusesToCurrencies_aggregate;
  /** An array relationship */
  Campaign: Array<v1_Campaign>;
  /** An aggregate relationship */
  Campaign_aggregate: v1_Campaign_aggregate;
  /** An array relationship */
  ContactBases: Array<v1_ContactBase>;
  /** An aggregate relationship */
  ContactBases_aggregate: v1_ContactBase_aggregate;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  CurrencyName: Scalars['String']['output'];
  CurrencyPrecision: Scalars['Int']['output'];
  CurrencySymbol: Scalars['String']['output'];
  EntityImageId?: Maybe<Scalars['uuid']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  ISOCurrencyCode: Scalars['String']['output'];
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  Invoice: Array<v1_InvoiceBase>;
  /** An array relationship */
  InvoiceDetail: Array<v1_InvoiceDetailBase>;
  /** An aggregate relationship */
  InvoiceDetail_aggregate: v1_InvoiceDetailBase_aggregate;
  /** An aggregate relationship */
  Invoice_aggregate: v1_InvoiceBase_aggregate;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  /** An array relationship */
  Order: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  Order_aggregate: v1_SalesOrderBase_aggregate;
  OrganizationId: Scalars['uuid']['output'];
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  RecurringPayments: Array<v1_RecurringPayments>;
  /** An aggregate relationship */
  RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  StateCode: Scalars['Int']['output'];
  StatusCode?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  SupplierPosts: Array<v1_Posts>;
  /** An aggregate relationship */
  SupplierPosts_aggregate: v1_Posts_aggregate;
  TransactionCurrencyId: Scalars['uuid']['output'];
  UniqueDscId?: Maybe<Scalars['uuid']['output']>;
  VersionNumber: Scalars['bytea']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  new_POBases: Array<v1_new_POBase>;
  /** An aggregate relationship */
  new_POBases_aggregate: v1_new_POBase_aggregate;
  /** An array relationship */
  new_receiptBases: Array<v1_new_receiptBase>;
  /** An aggregate relationship */
  new_receiptBases_aggregate: v1_new_receiptBase_aggregate;
  /** An array relationship */
  new_trackerBases: Array<v1_new_trackerBase>;
  /** An aggregate relationship */
  new_trackerBases_aggregate: v1_new_trackerBase_aggregate;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseBalancesArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseBalances_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseBillingMethodsArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseBillingMethods_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseBonusesToCurrenciesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCurrencies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCurrencies_order_by>>;
  where?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseBonusesToCurrencies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCurrencies_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCurrencies_order_by>>;
  where?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseCampaignArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseCampaign_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseContactBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseContactBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseInvoiceArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseInvoiceDetailArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseInvoiceDetail_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceDetailBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceDetailBase_order_by>>;
  where?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseInvoice_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseOrderArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseOrder_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBasePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBasePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseRecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseRecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseSupplierPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBaseSupplierPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBasenew_POBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBasenew_POBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBasenew_receiptBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBasenew_receiptBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBasenew_trackerBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


/** columns and relationships of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBasenew_trackerBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};

/** aggregated selection of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_aggregate = {
  __typename?: 'v1_TransactionCurrencyBase_aggregate';
  aggregate?: Maybe<v1_TransactionCurrencyBase_aggregate_fields>;
  nodes: Array<v1_TransactionCurrencyBase>;
};

/** aggregate fields of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_aggregate_fields = {
  __typename?: 'v1_TransactionCurrencyBase_aggregate_fields';
  avg?: Maybe<v1_TransactionCurrencyBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_TransactionCurrencyBase_max_fields>;
  min?: Maybe<v1_TransactionCurrencyBase_min_fields>;
  stddev?: Maybe<v1_TransactionCurrencyBase_stddev_fields>;
  stddev_pop?: Maybe<v1_TransactionCurrencyBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_TransactionCurrencyBase_stddev_samp_fields>;
  sum?: Maybe<v1_TransactionCurrencyBase_sum_fields>;
  var_pop?: Maybe<v1_TransactionCurrencyBase_var_pop_fields>;
  var_samp?: Maybe<v1_TransactionCurrencyBase_var_samp_fields>;
  variance?: Maybe<v1_TransactionCurrencyBase_variance_fields>;
};


/** aggregate fields of "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_TransactionCurrencyBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_TransactionCurrencyBase_avg_fields = {
  __typename?: 'v1_TransactionCurrencyBase_avg_fields';
  CurrencyPrecision?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.TransactionCurrencyBase". All fields are combined with a logical 'AND'. */
export type v1_TransactionCurrencyBase_bool_exp = {
  Balances?: InputMaybe<v1_Balance_bool_exp>;
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_bool_exp>;
  BillingMethods?: InputMaybe<v1_BillingMethod_bool_exp>;
  BillingMethods_aggregate?: InputMaybe<v1_BillingMethod_aggregate_bool_exp>;
  BonusesToCurrencies?: InputMaybe<v1_BonusesToCurrencies_bool_exp>;
  BonusesToCurrencies_aggregate?: InputMaybe<v1_BonusesToCurrencies_aggregate_bool_exp>;
  Campaign?: InputMaybe<v1_Campaign_bool_exp>;
  Campaign_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  ContactBases?: InputMaybe<v1_ContactBase_bool_exp>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  CreatedOnBehalfBy?: InputMaybe<uuid_comparison_exp>;
  CurrencyName?: InputMaybe<String_comparison_exp>;
  CurrencyPrecision?: InputMaybe<Int_comparison_exp>;
  CurrencySymbol?: InputMaybe<String_comparison_exp>;
  EntityImageId?: InputMaybe<uuid_comparison_exp>;
  ExchangeRate?: InputMaybe<numeric_comparison_exp>;
  ISOCurrencyCode?: InputMaybe<String_comparison_exp>;
  ImportSequenceNumber?: InputMaybe<Int_comparison_exp>;
  Invoice?: InputMaybe<v1_InvoiceBase_bool_exp>;
  InvoiceDetail?: InputMaybe<v1_InvoiceDetailBase_bool_exp>;
  InvoiceDetail_aggregate?: InputMaybe<v1_InvoiceDetailBase_aggregate_bool_exp>;
  Invoice_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  ModifiedBy?: InputMaybe<uuid_comparison_exp>;
  ModifiedOn?: InputMaybe<timestamp_comparison_exp>;
  ModifiedOnBehalfBy?: InputMaybe<uuid_comparison_exp>;
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  Order?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  Order_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  OrganizationId?: InputMaybe<uuid_comparison_exp>;
  OverriddenCreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_bool_exp>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_bool_exp>;
  StateCode?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<Int_comparison_exp>;
  SupplierPosts?: InputMaybe<v1_Posts_bool_exp>;
  SupplierPosts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  UniqueDscId?: InputMaybe<uuid_comparison_exp>;
  VersionNumber?: InputMaybe<bytea_comparison_exp>;
  _and?: InputMaybe<Array<v1_TransactionCurrencyBase_bool_exp>>;
  _not?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  _or?: InputMaybe<Array<v1_TransactionCurrencyBase_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_POBases?: InputMaybe<v1_new_POBase_bool_exp>;
  new_POBases_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  new_receiptBases?: InputMaybe<v1_new_receiptBase_bool_exp>;
  new_receiptBases_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp>;
  new_trackerBases?: InputMaybe<v1_new_trackerBase_bool_exp>;
  new_trackerBases_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "v1.TransactionCurrencyBase" */
export enum v1_TransactionCurrencyBase_constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionCurrencyBase_id_key = 'TransactionCurrencyBase_id_key',
  /** unique or primary key constraint on columns "id" */
  TransactionCurrencyBase_pkey = 'TransactionCurrencyBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_inc_input = {
  CurrencyPrecision?: InputMaybe<Scalars['Int']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_insert_input = {
  Balances?: InputMaybe<v1_Balance_arr_rel_insert_input>;
  BillingMethods?: InputMaybe<v1_BillingMethod_arr_rel_insert_input>;
  BonusesToCurrencies?: InputMaybe<v1_BonusesToCurrencies_arr_rel_insert_input>;
  Campaign?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  ContactBases?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  CurrencyName?: InputMaybe<Scalars['String']['input']>;
  CurrencyPrecision?: InputMaybe<Scalars['Int']['input']>;
  CurrencySymbol?: InputMaybe<Scalars['String']['input']>;
  EntityImageId?: InputMaybe<Scalars['uuid']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  ISOCurrencyCode?: InputMaybe<Scalars['String']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  Invoice?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  InvoiceDetail?: InputMaybe<v1_InvoiceDetailBase_arr_rel_insert_input>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  Order?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  OrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_arr_rel_insert_input>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  SupplierPosts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  UniqueDscId?: InputMaybe<Scalars['uuid']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_POBases?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  new_receiptBases?: InputMaybe<v1_new_receiptBase_arr_rel_insert_input>;
  new_trackerBases?: InputMaybe<v1_new_trackerBase_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type v1_TransactionCurrencyBase_max_fields = {
  __typename?: 'v1_TransactionCurrencyBase_max_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  CurrencyName?: Maybe<Scalars['String']['output']>;
  CurrencyPrecision?: Maybe<Scalars['Int']['output']>;
  CurrencySymbol?: Maybe<Scalars['String']['output']>;
  EntityImageId?: Maybe<Scalars['uuid']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  ISOCurrencyCode?: Maybe<Scalars['String']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OrganizationId?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  UniqueDscId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_TransactionCurrencyBase_min_fields = {
  __typename?: 'v1_TransactionCurrencyBase_min_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  CurrencyName?: Maybe<Scalars['String']['output']>;
  CurrencyPrecision?: Maybe<Scalars['Int']['output']>;
  CurrencySymbol?: Maybe<Scalars['String']['output']>;
  EntityImageId?: Maybe<Scalars['uuid']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  ISOCurrencyCode?: Maybe<Scalars['String']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OrganizationId?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  UniqueDscId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_mutation_response = {
  __typename?: 'v1_TransactionCurrencyBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_TransactionCurrencyBase>;
};

/** input type for inserting object relation for remote table "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_obj_rel_insert_input = {
  data: v1_TransactionCurrencyBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_TransactionCurrencyBase_on_conflict>;
};

/** on_conflict condition type for table "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_on_conflict = {
  constraint: v1_TransactionCurrencyBase_constraint;
  update_columns?: Array<v1_TransactionCurrencyBase_update_column>;
  where?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.TransactionCurrencyBase". */
export type v1_TransactionCurrencyBase_order_by = {
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_order_by>;
  BillingMethods_aggregate?: InputMaybe<v1_BillingMethod_aggregate_order_by>;
  BonusesToCurrencies_aggregate?: InputMaybe<v1_BonusesToCurrencies_aggregate_order_by>;
  Campaign_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CreatedOnBehalfBy?: InputMaybe<order_by>;
  CurrencyName?: InputMaybe<order_by>;
  CurrencyPrecision?: InputMaybe<order_by>;
  CurrencySymbol?: InputMaybe<order_by>;
  EntityImageId?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  ISOCurrencyCode?: InputMaybe<order_by>;
  ImportSequenceNumber?: InputMaybe<order_by>;
  InvoiceDetail_aggregate?: InputMaybe<v1_InvoiceDetailBase_aggregate_order_by>;
  Invoice_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  ModifiedBy?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  ModifiedOnBehalfBy?: InputMaybe<order_by>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  Order_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  OrganizationId?: InputMaybe<order_by>;
  OverriddenCreatedOn?: InputMaybe<order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  SupplierPosts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  UniqueDscId?: InputMaybe<order_by>;
  VersionNumber?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_POBases_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  new_receiptBases_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_order_by>;
  new_trackerBases_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_order_by>;
};

/** primary key columns input for table: v1.TransactionCurrencyBase */
export type v1_TransactionCurrencyBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.TransactionCurrencyBase" */
export enum v1_TransactionCurrencyBase_select_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CreatedOnBehalfBy = 'CreatedOnBehalfBy',
  /** column name */
  CurrencyName = 'CurrencyName',
  /** column name */
  CurrencyPrecision = 'CurrencyPrecision',
  /** column name */
  CurrencySymbol = 'CurrencySymbol',
  /** column name */
  EntityImageId = 'EntityImageId',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  ISOCurrencyCode = 'ISOCurrencyCode',
  /** column name */
  ImportSequenceNumber = 'ImportSequenceNumber',
  /** column name */
  ModifiedBy = 'ModifiedBy',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  ModifiedOnBehalfBy = 'ModifiedOnBehalfBy',
  /** column name */
  OrganizationId = 'OrganizationId',
  /** column name */
  OverriddenCreatedOn = 'OverriddenCreatedOn',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  UniqueDscId = 'UniqueDscId',
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "v1.TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_set_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  CurrencyName?: InputMaybe<Scalars['String']['input']>;
  CurrencyPrecision?: InputMaybe<Scalars['Int']['input']>;
  CurrencySymbol?: InputMaybe<Scalars['String']['input']>;
  EntityImageId?: InputMaybe<Scalars['uuid']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  ISOCurrencyCode?: InputMaybe<Scalars['String']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  UniqueDscId?: InputMaybe<Scalars['uuid']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_TransactionCurrencyBase_stddev_fields = {
  __typename?: 'v1_TransactionCurrencyBase_stddev_fields';
  CurrencyPrecision?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_TransactionCurrencyBase_stddev_pop_fields = {
  __typename?: 'v1_TransactionCurrencyBase_stddev_pop_fields';
  CurrencyPrecision?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_TransactionCurrencyBase_stddev_samp_fields = {
  __typename?: 'v1_TransactionCurrencyBase_stddev_samp_fields';
  CurrencyPrecision?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_TransactionCurrencyBase" */
export type v1_TransactionCurrencyBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_TransactionCurrencyBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_TransactionCurrencyBase_stream_cursor_value_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  CurrencyName?: InputMaybe<Scalars['String']['input']>;
  CurrencyPrecision?: InputMaybe<Scalars['Int']['input']>;
  CurrencySymbol?: InputMaybe<Scalars['String']['input']>;
  EntityImageId?: InputMaybe<Scalars['uuid']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  ISOCurrencyCode?: InputMaybe<Scalars['String']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  UniqueDscId?: InputMaybe<Scalars['uuid']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_TransactionCurrencyBase_sum_fields = {
  __typename?: 'v1_TransactionCurrencyBase_sum_fields';
  CurrencyPrecision?: Maybe<Scalars['Int']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.TransactionCurrencyBase" */
export enum v1_TransactionCurrencyBase_update_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CreatedOnBehalfBy = 'CreatedOnBehalfBy',
  /** column name */
  CurrencyName = 'CurrencyName',
  /** column name */
  CurrencyPrecision = 'CurrencyPrecision',
  /** column name */
  CurrencySymbol = 'CurrencySymbol',
  /** column name */
  EntityImageId = 'EntityImageId',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  ISOCurrencyCode = 'ISOCurrencyCode',
  /** column name */
  ImportSequenceNumber = 'ImportSequenceNumber',
  /** column name */
  ModifiedBy = 'ModifiedBy',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  ModifiedOnBehalfBy = 'ModifiedOnBehalfBy',
  /** column name */
  OrganizationId = 'OrganizationId',
  /** column name */
  OverriddenCreatedOn = 'OverriddenCreatedOn',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  UniqueDscId = 'UniqueDscId',
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  id = 'id'
}

export type v1_TransactionCurrencyBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_TransactionCurrencyBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_TransactionCurrencyBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_TransactionCurrencyBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_TransactionCurrencyBase_var_pop_fields = {
  __typename?: 'v1_TransactionCurrencyBase_var_pop_fields';
  CurrencyPrecision?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_TransactionCurrencyBase_var_samp_fields = {
  __typename?: 'v1_TransactionCurrencyBase_var_samp_fields';
  CurrencyPrecision?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_TransactionCurrencyBase_variance_fields = {
  __typename?: 'v1_TransactionCurrencyBase_variance_fields';
  CurrencyPrecision?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.UsersStatus" */
export type v1_UsersStatus = {
  __typename?: 'v1_UsersStatus';
  /** An array relationship */
  SystemUserBases: Array<v1_SystemUserBase>;
  /** An aggregate relationship */
  SystemUserBases_aggregate: v1_SystemUserBase_aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


/** columns and relationships of "v1.UsersStatus" */
export type v1_UsersStatusSystemUserBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_SystemUserBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SystemUserBase_order_by>>;
  where?: InputMaybe<v1_SystemUserBase_bool_exp>;
};


/** columns and relationships of "v1.UsersStatus" */
export type v1_UsersStatusSystemUserBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SystemUserBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SystemUserBase_order_by>>;
  where?: InputMaybe<v1_SystemUserBase_bool_exp>;
};

/** aggregated selection of "v1.UsersStatus" */
export type v1_UsersStatus_aggregate = {
  __typename?: 'v1_UsersStatus_aggregate';
  aggregate?: Maybe<v1_UsersStatus_aggregate_fields>;
  nodes: Array<v1_UsersStatus>;
};

/** aggregate fields of "v1.UsersStatus" */
export type v1_UsersStatus_aggregate_fields = {
  __typename?: 'v1_UsersStatus_aggregate_fields';
  avg?: Maybe<v1_UsersStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_UsersStatus_max_fields>;
  min?: Maybe<v1_UsersStatus_min_fields>;
  stddev?: Maybe<v1_UsersStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_UsersStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_UsersStatus_stddev_samp_fields>;
  sum?: Maybe<v1_UsersStatus_sum_fields>;
  var_pop?: Maybe<v1_UsersStatus_var_pop_fields>;
  var_samp?: Maybe<v1_UsersStatus_var_samp_fields>;
  variance?: Maybe<v1_UsersStatus_variance_fields>;
};


/** aggregate fields of "v1.UsersStatus" */
export type v1_UsersStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_UsersStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_UsersStatus_avg_fields = {
  __typename?: 'v1_UsersStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.UsersStatus". All fields are combined with a logical 'AND'. */
export type v1_UsersStatus_bool_exp = {
  SystemUserBases?: InputMaybe<v1_SystemUserBase_bool_exp>;
  SystemUserBases_aggregate?: InputMaybe<v1_SystemUserBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_UsersStatus_bool_exp>>;
  _not?: InputMaybe<v1_UsersStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_UsersStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.UsersStatus" */
export enum v1_UsersStatus_constraint {
  /** unique or primary key constraint on columns "name" */
  UserStatus_name_key = 'UserStatus_name_key',
  /** unique or primary key constraint on columns "id" */
  UserStatus_pkey = 'UserStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.UsersStatus" */
export type v1_UsersStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.UsersStatus" */
export type v1_UsersStatus_insert_input = {
  SystemUserBases?: InputMaybe<v1_SystemUserBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_UsersStatus_max_fields = {
  __typename?: 'v1_UsersStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_UsersStatus_min_fields = {
  __typename?: 'v1_UsersStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.UsersStatus" */
export type v1_UsersStatus_mutation_response = {
  __typename?: 'v1_UsersStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_UsersStatus>;
};

/** input type for inserting object relation for remote table "v1.UsersStatus" */
export type v1_UsersStatus_obj_rel_insert_input = {
  data: v1_UsersStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_UsersStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.UsersStatus" */
export type v1_UsersStatus_on_conflict = {
  constraint: v1_UsersStatus_constraint;
  update_columns?: Array<v1_UsersStatus_update_column>;
  where?: InputMaybe<v1_UsersStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.UsersStatus". */
export type v1_UsersStatus_order_by = {
  SystemUserBases_aggregate?: InputMaybe<v1_SystemUserBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.UsersStatus */
export type v1_UsersStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.UsersStatus" */
export enum v1_UsersStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "v1.UsersStatus" */
export type v1_UsersStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_UsersStatus_stddev_fields = {
  __typename?: 'v1_UsersStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_UsersStatus_stddev_pop_fields = {
  __typename?: 'v1_UsersStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_UsersStatus_stddev_samp_fields = {
  __typename?: 'v1_UsersStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_UsersStatus" */
export type v1_UsersStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_UsersStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_UsersStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_UsersStatus_sum_fields = {
  __typename?: 'v1_UsersStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.UsersStatus" */
export enum v1_UsersStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type v1_UsersStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_UsersStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_UsersStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_UsersStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_UsersStatus_var_pop_fields = {
  __typename?: 'v1_UsersStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_UsersStatus_var_samp_fields = {
  __typename?: 'v1_UsersStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_UsersStatus_variance_fields = {
  __typename?: 'v1_UsersStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.UsersToOffers" */
export type v1_UsersToOffers = {
  __typename?: 'v1_UsersToOffers';
  id: Scalars['Int']['output'];
  offer_id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.UsersToOffers" */
export type v1_UsersToOffers_aggregate = {
  __typename?: 'v1_UsersToOffers_aggregate';
  aggregate?: Maybe<v1_UsersToOffers_aggregate_fields>;
  nodes: Array<v1_UsersToOffers>;
};

export type v1_UsersToOffers_aggregate_bool_exp = {
  count?: InputMaybe<v1_UsersToOffers_aggregate_bool_exp_count>;
};

export type v1_UsersToOffers_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_UsersToOffers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_UsersToOffers_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.UsersToOffers" */
export type v1_UsersToOffers_aggregate_fields = {
  __typename?: 'v1_UsersToOffers_aggregate_fields';
  avg?: Maybe<v1_UsersToOffers_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_UsersToOffers_max_fields>;
  min?: Maybe<v1_UsersToOffers_min_fields>;
  stddev?: Maybe<v1_UsersToOffers_stddev_fields>;
  stddev_pop?: Maybe<v1_UsersToOffers_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_UsersToOffers_stddev_samp_fields>;
  sum?: Maybe<v1_UsersToOffers_sum_fields>;
  var_pop?: Maybe<v1_UsersToOffers_var_pop_fields>;
  var_samp?: Maybe<v1_UsersToOffers_var_samp_fields>;
  variance?: Maybe<v1_UsersToOffers_variance_fields>;
};


/** aggregate fields of "v1.UsersToOffers" */
export type v1_UsersToOffers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_UsersToOffers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.UsersToOffers" */
export type v1_UsersToOffers_aggregate_order_by = {
  avg?: InputMaybe<v1_UsersToOffers_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_UsersToOffers_max_order_by>;
  min?: InputMaybe<v1_UsersToOffers_min_order_by>;
  stddev?: InputMaybe<v1_UsersToOffers_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_UsersToOffers_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_UsersToOffers_stddev_samp_order_by>;
  sum?: InputMaybe<v1_UsersToOffers_sum_order_by>;
  var_pop?: InputMaybe<v1_UsersToOffers_var_pop_order_by>;
  var_samp?: InputMaybe<v1_UsersToOffers_var_samp_order_by>;
  variance?: InputMaybe<v1_UsersToOffers_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.UsersToOffers" */
export type v1_UsersToOffers_arr_rel_insert_input = {
  data: Array<v1_UsersToOffers_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_UsersToOffers_on_conflict>;
};

/** aggregate avg on columns */
export type v1_UsersToOffers_avg_fields = {
  __typename?: 'v1_UsersToOffers_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_avg_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.UsersToOffers". All fields are combined with a logical 'AND'. */
export type v1_UsersToOffers_bool_exp = {
  _and?: InputMaybe<Array<v1_UsersToOffers_bool_exp>>;
  _not?: InputMaybe<v1_UsersToOffers_bool_exp>;
  _or?: InputMaybe<Array<v1_UsersToOffers_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.UsersToOffers" */
export enum v1_UsersToOffers_constraint {
  /** unique or primary key constraint on columns "id" */
  UsersToOffers_pkey = 'UsersToOffers_pkey'
}

/** input type for incrementing numeric columns in table "v1.UsersToOffers" */
export type v1_UsersToOffers_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.UsersToOffers" */
export type v1_UsersToOffers_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_UsersToOffers_max_fields = {
  __typename?: 'v1_UsersToOffers_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_max_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_UsersToOffers_min_fields = {
  __typename?: 'v1_UsersToOffers_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_min_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.UsersToOffers" */
export type v1_UsersToOffers_mutation_response = {
  __typename?: 'v1_UsersToOffers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_UsersToOffers>;
};

/** on_conflict condition type for table "v1.UsersToOffers" */
export type v1_UsersToOffers_on_conflict = {
  constraint: v1_UsersToOffers_constraint;
  update_columns?: Array<v1_UsersToOffers_update_column>;
  where?: InputMaybe<v1_UsersToOffers_bool_exp>;
};

/** Ordering options when selecting data from "v1.UsersToOffers". */
export type v1_UsersToOffers_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.UsersToOffers */
export type v1_UsersToOffers_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.UsersToOffers" */
export enum v1_UsersToOffers_select_column {
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "v1.UsersToOffers" */
export type v1_UsersToOffers_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_UsersToOffers_stddev_fields = {
  __typename?: 'v1_UsersToOffers_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_stddev_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_UsersToOffers_stddev_pop_fields = {
  __typename?: 'v1_UsersToOffers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_UsersToOffers_stddev_samp_fields = {
  __typename?: 'v1_UsersToOffers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_UsersToOffers" */
export type v1_UsersToOffers_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_UsersToOffers_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_UsersToOffers_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_UsersToOffers_sum_fields = {
  __typename?: 'v1_UsersToOffers_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_sum_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.UsersToOffers" */
export enum v1_UsersToOffers_update_column {
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  user_id = 'user_id'
}

export type v1_UsersToOffers_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_UsersToOffers_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_UsersToOffers_set_input>;
  /** filter the rows which have to be updated */
  where: v1_UsersToOffers_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_UsersToOffers_var_pop_fields = {
  __typename?: 'v1_UsersToOffers_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_UsersToOffers_var_samp_fields = {
  __typename?: 'v1_UsersToOffers_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_UsersToOffers_variance_fields = {
  __typename?: 'v1_UsersToOffers_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.UsersToOffers" */
export type v1_UsersToOffers_variance_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Users_Roles" */
export type v1_Users_Roles = {
  __typename?: 'v1_Users_Roles';
  /** An object relationship */
  Roles: v1_Roles;
  /** An object relationship */
  Users: v1_SystemUserBase;
  id: Scalars['Int']['output'];
  role_id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.Users_Roles" */
export type v1_Users_Roles_aggregate = {
  __typename?: 'v1_Users_Roles_aggregate';
  aggregate?: Maybe<v1_Users_Roles_aggregate_fields>;
  nodes: Array<v1_Users_Roles>;
};

export type v1_Users_Roles_aggregate_bool_exp = {
  count?: InputMaybe<v1_Users_Roles_aggregate_bool_exp_count>;
};

export type v1_Users_Roles_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Users_Roles_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.Users_Roles" */
export type v1_Users_Roles_aggregate_fields = {
  __typename?: 'v1_Users_Roles_aggregate_fields';
  avg?: Maybe<v1_Users_Roles_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Users_Roles_max_fields>;
  min?: Maybe<v1_Users_Roles_min_fields>;
  stddev?: Maybe<v1_Users_Roles_stddev_fields>;
  stddev_pop?: Maybe<v1_Users_Roles_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Users_Roles_stddev_samp_fields>;
  sum?: Maybe<v1_Users_Roles_sum_fields>;
  var_pop?: Maybe<v1_Users_Roles_var_pop_fields>;
  var_samp?: Maybe<v1_Users_Roles_var_samp_fields>;
  variance?: Maybe<v1_Users_Roles_variance_fields>;
};


/** aggregate fields of "v1.Users_Roles" */
export type v1_Users_Roles_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Users_Roles_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Users_Roles" */
export type v1_Users_Roles_aggregate_order_by = {
  avg?: InputMaybe<v1_Users_Roles_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Users_Roles_max_order_by>;
  min?: InputMaybe<v1_Users_Roles_min_order_by>;
  stddev?: InputMaybe<v1_Users_Roles_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Users_Roles_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Users_Roles_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Users_Roles_sum_order_by>;
  var_pop?: InputMaybe<v1_Users_Roles_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Users_Roles_var_samp_order_by>;
  variance?: InputMaybe<v1_Users_Roles_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Users_Roles" */
export type v1_Users_Roles_arr_rel_insert_input = {
  data: Array<v1_Users_Roles_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Users_Roles_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Users_Roles_avg_fields = {
  __typename?: 'v1_Users_Roles_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_avg_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Users_Roles". All fields are combined with a logical 'AND'. */
export type v1_Users_Roles_bool_exp = {
  Roles?: InputMaybe<v1_Roles_bool_exp>;
  Users?: InputMaybe<v1_SystemUserBase_bool_exp>;
  _and?: InputMaybe<Array<v1_Users_Roles_bool_exp>>;
  _not?: InputMaybe<v1_Users_Roles_bool_exp>;
  _or?: InputMaybe<Array<v1_Users_Roles_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  role_id?: InputMaybe<Int_comparison_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Users_Roles" */
export enum v1_Users_Roles_constraint {
  /** unique or primary key constraint on columns "id" */
  Users_Roles_id_key = 'Users_Roles_id_key',
  /** unique or primary key constraint on columns "role_id", "user_id" */
  Users_Roles_pkey = 'Users_Roles_pkey'
}

/** input type for incrementing numeric columns in table "v1.Users_Roles" */
export type v1_Users_Roles_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Users_Roles" */
export type v1_Users_Roles_insert_input = {
  Roles?: InputMaybe<v1_Roles_obj_rel_insert_input>;
  Users?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Users_Roles_max_fields = {
  __typename?: 'v1_Users_Roles_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_max_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Users_Roles_min_fields = {
  __typename?: 'v1_Users_Roles_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_min_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Users_Roles" */
export type v1_Users_Roles_mutation_response = {
  __typename?: 'v1_Users_Roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Users_Roles>;
};

/** on_conflict condition type for table "v1.Users_Roles" */
export type v1_Users_Roles_on_conflict = {
  constraint: v1_Users_Roles_constraint;
  update_columns?: Array<v1_Users_Roles_update_column>;
  where?: InputMaybe<v1_Users_Roles_bool_exp>;
};

/** Ordering options when selecting data from "v1.Users_Roles". */
export type v1_Users_Roles_order_by = {
  Roles?: InputMaybe<v1_Roles_order_by>;
  Users?: InputMaybe<v1_SystemUserBase_order_by>;
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Users_Roles */
export type v1_Users_Roles_pk_columns_input = {
  role_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

/** select columns of table "v1.Users_Roles" */
export enum v1_Users_Roles_select_column {
  /** column name */
  id = 'id',
  /** column name */
  role_id = 'role_id',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "v1.Users_Roles" */
export type v1_Users_Roles_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Users_Roles_stddev_fields = {
  __typename?: 'v1_Users_Roles_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_stddev_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Users_Roles_stddev_pop_fields = {
  __typename?: 'v1_Users_Roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Users_Roles_stddev_samp_fields = {
  __typename?: 'v1_Users_Roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Users_Roles" */
export type v1_Users_Roles_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Users_Roles_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Users_Roles_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Users_Roles_sum_fields = {
  __typename?: 'v1_Users_Roles_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_sum_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Users_Roles" */
export enum v1_Users_Roles_update_column {
  /** column name */
  id = 'id',
  /** column name */
  role_id = 'role_id',
  /** column name */
  user_id = 'user_id'
}

export type v1_Users_Roles_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Users_Roles_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Users_Roles_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Users_Roles_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Users_Roles_var_pop_fields = {
  __typename?: 'v1_Users_Roles_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Users_Roles_var_samp_fields = {
  __typename?: 'v1_Users_Roles_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Users_Roles_variance_fields = {
  __typename?: 'v1_Users_Roles_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Users_Roles" */
export type v1_Users_Roles_variance_order_by = {
  id?: InputMaybe<order_by>;
  role_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** Users-Teams N:N Relationship Table */
export type v1_Users_Teams = {
  __typename?: 'v1_Users_Teams';
  id: Scalars['Int']['output'];
  team_id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.Users_Teams" */
export type v1_Users_Teams_aggregate = {
  __typename?: 'v1_Users_Teams_aggregate';
  aggregate?: Maybe<v1_Users_Teams_aggregate_fields>;
  nodes: Array<v1_Users_Teams>;
};

/** aggregate fields of "v1.Users_Teams" */
export type v1_Users_Teams_aggregate_fields = {
  __typename?: 'v1_Users_Teams_aggregate_fields';
  avg?: Maybe<v1_Users_Teams_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Users_Teams_max_fields>;
  min?: Maybe<v1_Users_Teams_min_fields>;
  stddev?: Maybe<v1_Users_Teams_stddev_fields>;
  stddev_pop?: Maybe<v1_Users_Teams_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Users_Teams_stddev_samp_fields>;
  sum?: Maybe<v1_Users_Teams_sum_fields>;
  var_pop?: Maybe<v1_Users_Teams_var_pop_fields>;
  var_samp?: Maybe<v1_Users_Teams_var_samp_fields>;
  variance?: Maybe<v1_Users_Teams_variance_fields>;
};


/** aggregate fields of "v1.Users_Teams" */
export type v1_Users_Teams_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Users_Teams_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Users_Teams_avg_fields = {
  __typename?: 'v1_Users_Teams_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Users_Teams". All fields are combined with a logical 'AND'. */
export type v1_Users_Teams_bool_exp = {
  _and?: InputMaybe<Array<v1_Users_Teams_bool_exp>>;
  _not?: InputMaybe<v1_Users_Teams_bool_exp>;
  _or?: InputMaybe<Array<v1_Users_Teams_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  team_id?: InputMaybe<Int_comparison_exp>;
  user_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Users_Teams" */
export enum v1_Users_Teams_constraint {
  /** unique or primary key constraint on columns "id" */
  Users_Teams_id_key = 'Users_Teams_id_key',
  /** unique or primary key constraint on columns "user_id", "team_id" */
  Users_Teams_pkey = 'Users_Teams_pkey'
}

/** input type for incrementing numeric columns in table "v1.Users_Teams" */
export type v1_Users_Teams_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Users_Teams" */
export type v1_Users_Teams_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Users_Teams_max_fields = {
  __typename?: 'v1_Users_Teams_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_Users_Teams_min_fields = {
  __typename?: 'v1_Users_Teams_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.Users_Teams" */
export type v1_Users_Teams_mutation_response = {
  __typename?: 'v1_Users_Teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Users_Teams>;
};

/** on_conflict condition type for table "v1.Users_Teams" */
export type v1_Users_Teams_on_conflict = {
  constraint: v1_Users_Teams_constraint;
  update_columns?: Array<v1_Users_Teams_update_column>;
  where?: InputMaybe<v1_Users_Teams_bool_exp>;
};

/** Ordering options when selecting data from "v1.Users_Teams". */
export type v1_Users_Teams_order_by = {
  id?: InputMaybe<order_by>;
  team_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Users_Teams */
export type v1_Users_Teams_pk_columns_input = {
  team_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

/** select columns of table "v1.Users_Teams" */
export enum v1_Users_Teams_select_column {
  /** column name */
  id = 'id',
  /** column name */
  team_id = 'team_id',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "v1.Users_Teams" */
export type v1_Users_Teams_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Users_Teams_stddev_fields = {
  __typename?: 'v1_Users_Teams_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Users_Teams_stddev_pop_fields = {
  __typename?: 'v1_Users_Teams_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Users_Teams_stddev_samp_fields = {
  __typename?: 'v1_Users_Teams_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Users_Teams" */
export type v1_Users_Teams_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Users_Teams_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Users_Teams_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Users_Teams_sum_fields = {
  __typename?: 'v1_Users_Teams_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Users_Teams" */
export enum v1_Users_Teams_update_column {
  /** column name */
  id = 'id',
  /** column name */
  team_id = 'team_id',
  /** column name */
  user_id = 'user_id'
}

export type v1_Users_Teams_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Users_Teams_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Users_Teams_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Users_Teams_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Users_Teams_var_pop_fields = {
  __typename?: 'v1_Users_Teams_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Users_Teams_var_samp_fields = {
  __typename?: 'v1_Users_Teams_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Users_Teams_variance_fields = {
  __typename?: 'v1_Users_Teams_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** This is for where the bonus will be wagered on for the BMS. */
export type v1_WageringRequirementsLocation = {
  __typename?: 'v1_WageringRequirementsLocation';
  id: Scalars['Int']['output'];
  new_name: Scalars['bpchar']['output'];
};

/** aggregated selection of "v1.WageringRequirementsLocation" */
export type v1_WageringRequirementsLocation_aggregate = {
  __typename?: 'v1_WageringRequirementsLocation_aggregate';
  aggregate?: Maybe<v1_WageringRequirementsLocation_aggregate_fields>;
  nodes: Array<v1_WageringRequirementsLocation>;
};

/** aggregate fields of "v1.WageringRequirementsLocation" */
export type v1_WageringRequirementsLocation_aggregate_fields = {
  __typename?: 'v1_WageringRequirementsLocation_aggregate_fields';
  avg?: Maybe<v1_WageringRequirementsLocation_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_WageringRequirementsLocation_max_fields>;
  min?: Maybe<v1_WageringRequirementsLocation_min_fields>;
  stddev?: Maybe<v1_WageringRequirementsLocation_stddev_fields>;
  stddev_pop?: Maybe<v1_WageringRequirementsLocation_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_WageringRequirementsLocation_stddev_samp_fields>;
  sum?: Maybe<v1_WageringRequirementsLocation_sum_fields>;
  var_pop?: Maybe<v1_WageringRequirementsLocation_var_pop_fields>;
  var_samp?: Maybe<v1_WageringRequirementsLocation_var_samp_fields>;
  variance?: Maybe<v1_WageringRequirementsLocation_variance_fields>;
};


/** aggregate fields of "v1.WageringRequirementsLocation" */
export type v1_WageringRequirementsLocation_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_WageringRequirementsLocation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_WageringRequirementsLocation_avg_fields = {
  __typename?: 'v1_WageringRequirementsLocation_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.WageringRequirementsLocation". All fields are combined with a logical 'AND'. */
export type v1_WageringRequirementsLocation_bool_exp = {
  _and?: InputMaybe<Array<v1_WageringRequirementsLocation_bool_exp>>;
  _not?: InputMaybe<v1_WageringRequirementsLocation_bool_exp>;
  _or?: InputMaybe<Array<v1_WageringRequirementsLocation_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<bpchar_comparison_exp>;
};

/** unique or primary key constraints on table "v1.WageringRequirementsLocation" */
export enum v1_WageringRequirementsLocation_constraint {
  /** unique or primary key constraint on columns "new_name" */
  WageringRequirementsLocation_new_name_key = 'WageringRequirementsLocation_new_name_key',
  /** unique or primary key constraint on columns "id" */
  WageringRequirementsLocation_pkey = 'WageringRequirementsLocation_pkey'
}

/** input type for incrementing numeric columns in table "v1.WageringRequirementsLocation" */
export type v1_WageringRequirementsLocation_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.WageringRequirementsLocation" */
export type v1_WageringRequirementsLocation_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type v1_WageringRequirementsLocation_max_fields = {
  __typename?: 'v1_WageringRequirementsLocation_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_WageringRequirementsLocation_min_fields = {
  __typename?: 'v1_WageringRequirementsLocation_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.WageringRequirementsLocation" */
export type v1_WageringRequirementsLocation_mutation_response = {
  __typename?: 'v1_WageringRequirementsLocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_WageringRequirementsLocation>;
};

/** on_conflict condition type for table "v1.WageringRequirementsLocation" */
export type v1_WageringRequirementsLocation_on_conflict = {
  constraint: v1_WageringRequirementsLocation_constraint;
  update_columns?: Array<v1_WageringRequirementsLocation_update_column>;
  where?: InputMaybe<v1_WageringRequirementsLocation_bool_exp>;
};

/** Ordering options when selecting data from "v1.WageringRequirementsLocation". */
export type v1_WageringRequirementsLocation_order_by = {
  id?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.WageringRequirementsLocation */
export type v1_WageringRequirementsLocation_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.WageringRequirementsLocation" */
export enum v1_WageringRequirementsLocation_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

/** input type for updating data in table "v1.WageringRequirementsLocation" */
export type v1_WageringRequirementsLocation_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type v1_WageringRequirementsLocation_stddev_fields = {
  __typename?: 'v1_WageringRequirementsLocation_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_WageringRequirementsLocation_stddev_pop_fields = {
  __typename?: 'v1_WageringRequirementsLocation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_WageringRequirementsLocation_stddev_samp_fields = {
  __typename?: 'v1_WageringRequirementsLocation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_WageringRequirementsLocation" */
export type v1_WageringRequirementsLocation_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_WageringRequirementsLocation_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_WageringRequirementsLocation_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type v1_WageringRequirementsLocation_sum_fields = {
  __typename?: 'v1_WageringRequirementsLocation_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.WageringRequirementsLocation" */
export enum v1_WageringRequirementsLocation_update_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

export type v1_WageringRequirementsLocation_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_WageringRequirementsLocation_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_WageringRequirementsLocation_set_input>;
  /** filter the rows which have to be updated */
  where: v1_WageringRequirementsLocation_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_WageringRequirementsLocation_var_pop_fields = {
  __typename?: 'v1_WageringRequirementsLocation_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_WageringRequirementsLocation_var_samp_fields = {
  __typename?: 'v1_WageringRequirementsLocation_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_WageringRequirementsLocation_variance_fields = {
  __typename?: 'v1_WageringRequirementsLocation_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Website" */
export type v1_Website = {
  __typename?: 'v1_Website';
  AhrefsDR?: Maybe<Scalars['numeric']['output']>;
  AhrefsRL?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.all_site_posts" */
  AllSitePosts?: Maybe<Scalars['Int']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.website_approved_posts" */
  ApprovedPosts?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  BlogProbability?: Maybe<v1_WebsiteBlogProbability>;
  /** An object relationship */
  ContentIndicator?: Maybe<v1_WebsiteContentIndicator>;
  /** An array relationship */
  ContentTypes: Array<v1_ContentTypes>;
  /** An aggregate relationship */
  ContentTypes_aggregate: v1_ContentTypes_aggregate;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** Depricated. Pls use domain_creation_date. */
  DomainAge?: Maybe<Scalars['date']['output']>;
  /** A computed field, executes function "v1.website_inprogress_posts" */
  InProgressPosts?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  Language?: Maybe<v1_new_languagesBase>;
  /** A computed field, executes function "v1.website_live_posts" */
  LivePosts?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.website_lost_posts" */
  LostPosts?: Maybe<Scalars['Int']['output']>;
  MOZSpamScore?: Maybe<Scalars['numeric']['output']>;
  NativeLink?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  OrganicTraffic?: Maybe<Scalars['Int']['output']>;
  OutgoingDomains?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  Pages: Array<v1_Page>;
  /** An aggregate relationship */
  Pages_aggregate: v1_Page_aggregate;
  /** An object relationship */
  PostNiche?: Maybe<v1_PostNiche>;
  /** A computed field, executes function "v1.website_posted_posts" */
  PostedPosts?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  Projects: Array<v1_Project>;
  /** An aggregate relationship */
  Projects_aggregate: v1_Project_aggregate;
  PublishingSpeed?: Maybe<Scalars['Int']['output']>;
  ReferringDomains?: Maybe<Scalars['numeric']['output']>;
  Reset?: Maybe<Scalars['Int']['output']>;
  SemrushAS?: Maybe<Scalars['numeric']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  UptimeStatus?: Maybe<v1_WebsiteUptimeStatus>;
  /** An object relationship */
  WebsiteDToxRisk?: Maybe<v1_WebsiteDToxRisk>;
  /** An object relationship */
  WebsiteOwner?: Maybe<v1_ContactBase>;
  /** An object relationship */
  WebsiteQualityScore?: Maybe<v1_WebsiteQualityScore>;
  /** An object relationship */
  WebsiteStatus?: Maybe<v1_WebsiteStatus>;
  /** An object relationship */
  WebsiteTopic?: Maybe<v1_WebsiteTopics>;
  /** An object relationship */
  Website_Platform?: Maybe<v1_Website_Platform>;
  /** An array relationship */
  WebsitesToTopics: Array<v1_WebsitesToTopics>;
  /** An aggregate relationship */
  WebsitesToTopics_aggregate: v1_WebsitesToTopics_aggregate;
  ahrefs_last_updated_on?: Maybe<Scalars['date']['output']>;
  /** A computed field, executes function "v1.website_domain_age" */
  comp_domain_age?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "v1.website_ld_rd_ratio" */
  comp_ld_rd_ratio?: Maybe<Scalars['numeric']['output']>;
  /** A computed field, executes function "v1.website_related_offers" */
  comp_related_offers?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  crm_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domain_creation_date: Scalars['date']['output'];
  id: Scalars['Int']['output'];
  ip_adress?: Maybe<Scalars['String']['output']>;
  is_gambling: Scalars['Boolean']['output'];
  keywords?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  linked_domains?: Maybe<Scalars['Int']['output']>;
  lrt_last_updated_on?: Maybe<Scalars['date']['output']>;
  new_AccessCheck?: Maybe<Scalars['timestamp']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['numeric']['output']>;
  new_AccessNotes?: Maybe<Scalars['String']['output']>;
  new_AdminPassword?: Maybe<Scalars['String']['output']>;
  new_BlogProbability?: Maybe<Scalars['Int']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Int']['output']>;
  new_DoFollowLinks?: Maybe<Scalars['Boolean']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['float8']['output']>;
  new_DropRate?: Maybe<Scalars['float8']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Int']['output']>;
  new_LastSeoCheck?: Maybe<Scalars['timestamp']['output']>;
  new_LastUptimeCheck?: Maybe<Scalars['timestamp']['output']>;
  new_MaxPosts?: Maybe<Scalars['Int']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['numeric']['output']>;
  new_OfflineSince?: Maybe<Scalars['timestamp']['output']>;
  new_Platform?: Maybe<Scalars['String']['output']>;
  new_Powerdomain?: Maybe<Scalars['float8']['output']>;
  new_SubDomain?: Maybe<Scalars['Boolean']['output']>;
  new_TitleHome?: Maybe<Scalars['String']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Int']['output']>;
  new_WebsiteContactOwner?: Maybe<Scalars['uuid']['output']>;
  new_WebsiteIP?: Maybe<Scalars['String']['output']>;
  new_adminuser?: Maybe<Scalars['String']['output']>;
  new_baseurl?: Maybe<Scalars['String']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Int']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_trustdom?: Maybe<Scalars['Int']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['Int']['output']>;
  powerTrustDom?: Maybe<Scalars['Int']['output']>;
  referring_domains_total?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Int']['output']>;
  traffic?: Maybe<Scalars['Int']['output']>;
  url_rating?: Maybe<Scalars['Int']['output']>;
  website_owner_id?: Maybe<Scalars['Int']['output']>;
  website_quality_score_id?: Maybe<Scalars['Int']['output']>;
  website_topic_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsiteContentTypesArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentTypes_order_by>>;
  where?: InputMaybe<v1_ContentTypes_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsiteContentTypes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContentTypes_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContentTypes_order_by>>;
  where?: InputMaybe<v1_ContentTypes_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsiteOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsiteOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsitePagesArgs = {
  distinct_on?: InputMaybe<Array<v1_Page_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Page_order_by>>;
  where?: InputMaybe<v1_Page_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsitePages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Page_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Page_order_by>>;
  where?: InputMaybe<v1_Page_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsitePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsitePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsiteProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsiteProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsiteWebsitesToTopicsArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsitesToTopics_order_by>>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};


/** columns and relationships of "v1.Website" */
export type v1_WebsiteWebsitesToTopics_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsitesToTopics_order_by>>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};

/** Website blog probability option sets */
export type v1_WebsiteBlogProbability = {
  __typename?: 'v1_WebsiteBlogProbability';
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** Website blog probability option sets */
export type v1_WebsiteBlogProbabilityWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** Website blog probability option sets */
export type v1_WebsiteBlogProbabilityWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** aggregated selection of "v1.WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_aggregate = {
  __typename?: 'v1_WebsiteBlogProbability_aggregate';
  aggregate?: Maybe<v1_WebsiteBlogProbability_aggregate_fields>;
  nodes: Array<v1_WebsiteBlogProbability>;
};

/** aggregate fields of "v1.WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_aggregate_fields = {
  __typename?: 'v1_WebsiteBlogProbability_aggregate_fields';
  avg?: Maybe<v1_WebsiteBlogProbability_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_WebsiteBlogProbability_max_fields>;
  min?: Maybe<v1_WebsiteBlogProbability_min_fields>;
  stddev?: Maybe<v1_WebsiteBlogProbability_stddev_fields>;
  stddev_pop?: Maybe<v1_WebsiteBlogProbability_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_WebsiteBlogProbability_stddev_samp_fields>;
  sum?: Maybe<v1_WebsiteBlogProbability_sum_fields>;
  var_pop?: Maybe<v1_WebsiteBlogProbability_var_pop_fields>;
  var_samp?: Maybe<v1_WebsiteBlogProbability_var_samp_fields>;
  variance?: Maybe<v1_WebsiteBlogProbability_variance_fields>;
};


/** aggregate fields of "v1.WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_WebsiteBlogProbability_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_WebsiteBlogProbability_avg_fields = {
  __typename?: 'v1_WebsiteBlogProbability_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.WebsiteBlogProbability". All fields are combined with a logical 'AND'. */
export type v1_WebsiteBlogProbability_bool_exp = {
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_WebsiteBlogProbability_bool_exp>>;
  _not?: InputMaybe<v1_WebsiteBlogProbability_bool_exp>;
  _or?: InputMaybe<Array<v1_WebsiteBlogProbability_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.WebsiteBlogProbability" */
export enum v1_WebsiteBlogProbability_constraint {
  /** unique or primary key constraint on columns "id" */
  WebsiteBlogProbability_pkey = 'WebsiteBlogProbability_pkey'
}

/** input type for incrementing numeric columns in table "v1.WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_insert_input = {
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_WebsiteBlogProbability_max_fields = {
  __typename?: 'v1_WebsiteBlogProbability_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_WebsiteBlogProbability_min_fields = {
  __typename?: 'v1_WebsiteBlogProbability_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_mutation_response = {
  __typename?: 'v1_WebsiteBlogProbability_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_WebsiteBlogProbability>;
};

/** input type for inserting object relation for remote table "v1.WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_obj_rel_insert_input = {
  data: v1_WebsiteBlogProbability_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_WebsiteBlogProbability_on_conflict>;
};

/** on_conflict condition type for table "v1.WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_on_conflict = {
  constraint: v1_WebsiteBlogProbability_constraint;
  update_columns?: Array<v1_WebsiteBlogProbability_update_column>;
  where?: InputMaybe<v1_WebsiteBlogProbability_bool_exp>;
};

/** Ordering options when selecting data from "v1.WebsiteBlogProbability". */
export type v1_WebsiteBlogProbability_order_by = {
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.WebsiteBlogProbability */
export type v1_WebsiteBlogProbability_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.WebsiteBlogProbability" */
export enum v1_WebsiteBlogProbability_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_WebsiteBlogProbability_stddev_fields = {
  __typename?: 'v1_WebsiteBlogProbability_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_WebsiteBlogProbability_stddev_pop_fields = {
  __typename?: 'v1_WebsiteBlogProbability_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_WebsiteBlogProbability_stddev_samp_fields = {
  __typename?: 'v1_WebsiteBlogProbability_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_WebsiteBlogProbability" */
export type v1_WebsiteBlogProbability_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_WebsiteBlogProbability_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_WebsiteBlogProbability_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_WebsiteBlogProbability_sum_fields = {
  __typename?: 'v1_WebsiteBlogProbability_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.WebsiteBlogProbability" */
export enum v1_WebsiteBlogProbability_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_WebsiteBlogProbability_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_WebsiteBlogProbability_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_WebsiteBlogProbability_set_input>;
  /** filter the rows which have to be updated */
  where: v1_WebsiteBlogProbability_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_WebsiteBlogProbability_var_pop_fields = {
  __typename?: 'v1_WebsiteBlogProbability_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_WebsiteBlogProbability_var_samp_fields = {
  __typename?: 'v1_WebsiteBlogProbability_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_WebsiteBlogProbability_variance_fields = {
  __typename?: 'v1_WebsiteBlogProbability_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Website content indicator */
export type v1_WebsiteContentIndicator = {
  __typename?: 'v1_WebsiteContentIndicator';
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** Website content indicator */
export type v1_WebsiteContentIndicatorWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** Website content indicator */
export type v1_WebsiteContentIndicatorWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** aggregated selection of "v1.WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_aggregate = {
  __typename?: 'v1_WebsiteContentIndicator_aggregate';
  aggregate?: Maybe<v1_WebsiteContentIndicator_aggregate_fields>;
  nodes: Array<v1_WebsiteContentIndicator>;
};

/** aggregate fields of "v1.WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_aggregate_fields = {
  __typename?: 'v1_WebsiteContentIndicator_aggregate_fields';
  avg?: Maybe<v1_WebsiteContentIndicator_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_WebsiteContentIndicator_max_fields>;
  min?: Maybe<v1_WebsiteContentIndicator_min_fields>;
  stddev?: Maybe<v1_WebsiteContentIndicator_stddev_fields>;
  stddev_pop?: Maybe<v1_WebsiteContentIndicator_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_WebsiteContentIndicator_stddev_samp_fields>;
  sum?: Maybe<v1_WebsiteContentIndicator_sum_fields>;
  var_pop?: Maybe<v1_WebsiteContentIndicator_var_pop_fields>;
  var_samp?: Maybe<v1_WebsiteContentIndicator_var_samp_fields>;
  variance?: Maybe<v1_WebsiteContentIndicator_variance_fields>;
};


/** aggregate fields of "v1.WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_WebsiteContentIndicator_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_WebsiteContentIndicator_avg_fields = {
  __typename?: 'v1_WebsiteContentIndicator_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.WebsiteContentIndicator". All fields are combined with a logical 'AND'. */
export type v1_WebsiteContentIndicator_bool_exp = {
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_WebsiteContentIndicator_bool_exp>>;
  _not?: InputMaybe<v1_WebsiteContentIndicator_bool_exp>;
  _or?: InputMaybe<Array<v1_WebsiteContentIndicator_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.WebsiteContentIndicator" */
export enum v1_WebsiteContentIndicator_constraint {
  /** unique or primary key constraint on columns "id" */
  WebsiteContentIndicator_pkey = 'WebsiteContentIndicator_pkey'
}

/** input type for incrementing numeric columns in table "v1.WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_insert_input = {
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_WebsiteContentIndicator_max_fields = {
  __typename?: 'v1_WebsiteContentIndicator_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_WebsiteContentIndicator_min_fields = {
  __typename?: 'v1_WebsiteContentIndicator_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_mutation_response = {
  __typename?: 'v1_WebsiteContentIndicator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_WebsiteContentIndicator>;
};

/** input type for inserting object relation for remote table "v1.WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_obj_rel_insert_input = {
  data: v1_WebsiteContentIndicator_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_WebsiteContentIndicator_on_conflict>;
};

/** on_conflict condition type for table "v1.WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_on_conflict = {
  constraint: v1_WebsiteContentIndicator_constraint;
  update_columns?: Array<v1_WebsiteContentIndicator_update_column>;
  where?: InputMaybe<v1_WebsiteContentIndicator_bool_exp>;
};

/** Ordering options when selecting data from "v1.WebsiteContentIndicator". */
export type v1_WebsiteContentIndicator_order_by = {
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.WebsiteContentIndicator */
export type v1_WebsiteContentIndicator_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.WebsiteContentIndicator" */
export enum v1_WebsiteContentIndicator_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_WebsiteContentIndicator_stddev_fields = {
  __typename?: 'v1_WebsiteContentIndicator_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_WebsiteContentIndicator_stddev_pop_fields = {
  __typename?: 'v1_WebsiteContentIndicator_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_WebsiteContentIndicator_stddev_samp_fields = {
  __typename?: 'v1_WebsiteContentIndicator_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_WebsiteContentIndicator" */
export type v1_WebsiteContentIndicator_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_WebsiteContentIndicator_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_WebsiteContentIndicator_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_WebsiteContentIndicator_sum_fields = {
  __typename?: 'v1_WebsiteContentIndicator_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.WebsiteContentIndicator" */
export enum v1_WebsiteContentIndicator_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_WebsiteContentIndicator_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_WebsiteContentIndicator_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_WebsiteContentIndicator_set_input>;
  /** filter the rows which have to be updated */
  where: v1_WebsiteContentIndicator_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_WebsiteContentIndicator_var_pop_fields = {
  __typename?: 'v1_WebsiteContentIndicator_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_WebsiteContentIndicator_var_samp_fields = {
  __typename?: 'v1_WebsiteContentIndicator_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_WebsiteContentIndicator_variance_fields = {
  __typename?: 'v1_WebsiteContentIndicator_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** WebsiteDToxRisk option set */
export type v1_WebsiteDToxRisk = {
  __typename?: 'v1_WebsiteDToxRisk';
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** WebsiteDToxRisk option set */
export type v1_WebsiteDToxRiskWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** WebsiteDToxRisk option set */
export type v1_WebsiteDToxRiskWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** aggregated selection of "v1.WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_aggregate = {
  __typename?: 'v1_WebsiteDToxRisk_aggregate';
  aggregate?: Maybe<v1_WebsiteDToxRisk_aggregate_fields>;
  nodes: Array<v1_WebsiteDToxRisk>;
};

/** aggregate fields of "v1.WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_aggregate_fields = {
  __typename?: 'v1_WebsiteDToxRisk_aggregate_fields';
  avg?: Maybe<v1_WebsiteDToxRisk_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_WebsiteDToxRisk_max_fields>;
  min?: Maybe<v1_WebsiteDToxRisk_min_fields>;
  stddev?: Maybe<v1_WebsiteDToxRisk_stddev_fields>;
  stddev_pop?: Maybe<v1_WebsiteDToxRisk_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_WebsiteDToxRisk_stddev_samp_fields>;
  sum?: Maybe<v1_WebsiteDToxRisk_sum_fields>;
  var_pop?: Maybe<v1_WebsiteDToxRisk_var_pop_fields>;
  var_samp?: Maybe<v1_WebsiteDToxRisk_var_samp_fields>;
  variance?: Maybe<v1_WebsiteDToxRisk_variance_fields>;
};


/** aggregate fields of "v1.WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_WebsiteDToxRisk_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_WebsiteDToxRisk_avg_fields = {
  __typename?: 'v1_WebsiteDToxRisk_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.WebsiteDToxRisk". All fields are combined with a logical 'AND'. */
export type v1_WebsiteDToxRisk_bool_exp = {
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_WebsiteDToxRisk_bool_exp>>;
  _not?: InputMaybe<v1_WebsiteDToxRisk_bool_exp>;
  _or?: InputMaybe<Array<v1_WebsiteDToxRisk_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.WebsiteDToxRisk" */
export enum v1_WebsiteDToxRisk_constraint {
  /** unique or primary key constraint on columns "id" */
  WebsiteDToxRisk_pkey = 'WebsiteDToxRisk_pkey'
}

/** input type for incrementing numeric columns in table "v1.WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_insert_input = {
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_WebsiteDToxRisk_max_fields = {
  __typename?: 'v1_WebsiteDToxRisk_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_WebsiteDToxRisk_min_fields = {
  __typename?: 'v1_WebsiteDToxRisk_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_mutation_response = {
  __typename?: 'v1_WebsiteDToxRisk_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_WebsiteDToxRisk>;
};

/** input type for inserting object relation for remote table "v1.WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_obj_rel_insert_input = {
  data: v1_WebsiteDToxRisk_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_WebsiteDToxRisk_on_conflict>;
};

/** on_conflict condition type for table "v1.WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_on_conflict = {
  constraint: v1_WebsiteDToxRisk_constraint;
  update_columns?: Array<v1_WebsiteDToxRisk_update_column>;
  where?: InputMaybe<v1_WebsiteDToxRisk_bool_exp>;
};

/** Ordering options when selecting data from "v1.WebsiteDToxRisk". */
export type v1_WebsiteDToxRisk_order_by = {
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.WebsiteDToxRisk */
export type v1_WebsiteDToxRisk_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.WebsiteDToxRisk" */
export enum v1_WebsiteDToxRisk_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_WebsiteDToxRisk_stddev_fields = {
  __typename?: 'v1_WebsiteDToxRisk_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_WebsiteDToxRisk_stddev_pop_fields = {
  __typename?: 'v1_WebsiteDToxRisk_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_WebsiteDToxRisk_stddev_samp_fields = {
  __typename?: 'v1_WebsiteDToxRisk_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_WebsiteDToxRisk" */
export type v1_WebsiteDToxRisk_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_WebsiteDToxRisk_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_WebsiteDToxRisk_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_WebsiteDToxRisk_sum_fields = {
  __typename?: 'v1_WebsiteDToxRisk_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.WebsiteDToxRisk" */
export enum v1_WebsiteDToxRisk_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_WebsiteDToxRisk_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_WebsiteDToxRisk_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_WebsiteDToxRisk_set_input>;
  /** filter the rows which have to be updated */
  where: v1_WebsiteDToxRisk_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_WebsiteDToxRisk_var_pop_fields = {
  __typename?: 'v1_WebsiteDToxRisk_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_WebsiteDToxRisk_var_samp_fields = {
  __typename?: 'v1_WebsiteDToxRisk_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_WebsiteDToxRisk_variance_fields = {
  __typename?: 'v1_WebsiteDToxRisk_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** This is about the website quality score for the website entity. */
export type v1_WebsiteQualityScore = {
  __typename?: 'v1_WebsiteQualityScore';
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  id: Scalars['Int']['output'];
  new_name: Scalars['bpchar']['output'];
};


/** This is about the website quality score for the website entity. */
export type v1_WebsiteQualityScoreWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** This is about the website quality score for the website entity. */
export type v1_WebsiteQualityScoreWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** aggregated selection of "v1.WebsiteQualityScore" */
export type v1_WebsiteQualityScore_aggregate = {
  __typename?: 'v1_WebsiteQualityScore_aggregate';
  aggregate?: Maybe<v1_WebsiteQualityScore_aggregate_fields>;
  nodes: Array<v1_WebsiteQualityScore>;
};

/** aggregate fields of "v1.WebsiteQualityScore" */
export type v1_WebsiteQualityScore_aggregate_fields = {
  __typename?: 'v1_WebsiteQualityScore_aggregate_fields';
  avg?: Maybe<v1_WebsiteQualityScore_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_WebsiteQualityScore_max_fields>;
  min?: Maybe<v1_WebsiteQualityScore_min_fields>;
  stddev?: Maybe<v1_WebsiteQualityScore_stddev_fields>;
  stddev_pop?: Maybe<v1_WebsiteQualityScore_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_WebsiteQualityScore_stddev_samp_fields>;
  sum?: Maybe<v1_WebsiteQualityScore_sum_fields>;
  var_pop?: Maybe<v1_WebsiteQualityScore_var_pop_fields>;
  var_samp?: Maybe<v1_WebsiteQualityScore_var_samp_fields>;
  variance?: Maybe<v1_WebsiteQualityScore_variance_fields>;
};


/** aggregate fields of "v1.WebsiteQualityScore" */
export type v1_WebsiteQualityScore_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_WebsiteQualityScore_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_WebsiteQualityScore_avg_fields = {
  __typename?: 'v1_WebsiteQualityScore_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.WebsiteQualityScore". All fields are combined with a logical 'AND'. */
export type v1_WebsiteQualityScore_bool_exp = {
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_WebsiteQualityScore_bool_exp>>;
  _not?: InputMaybe<v1_WebsiteQualityScore_bool_exp>;
  _or?: InputMaybe<Array<v1_WebsiteQualityScore_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<bpchar_comparison_exp>;
};

/** unique or primary key constraints on table "v1.WebsiteQualityScore" */
export enum v1_WebsiteQualityScore_constraint {
  /** unique or primary key constraint on columns "new_name" */
  WebsiteQualityScore_new_name_key = 'WebsiteQualityScore_new_name_key',
  /** unique or primary key constraint on columns "id" */
  WebsiteQualityScore_pkey = 'WebsiteQualityScore_pkey'
}

/** input type for incrementing numeric columns in table "v1.WebsiteQualityScore" */
export type v1_WebsiteQualityScore_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.WebsiteQualityScore" */
export type v1_WebsiteQualityScore_insert_input = {
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type v1_WebsiteQualityScore_max_fields = {
  __typename?: 'v1_WebsiteQualityScore_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type v1_WebsiteQualityScore_min_fields = {
  __typename?: 'v1_WebsiteQualityScore_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "v1.WebsiteQualityScore" */
export type v1_WebsiteQualityScore_mutation_response = {
  __typename?: 'v1_WebsiteQualityScore_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_WebsiteQualityScore>;
};

/** input type for inserting object relation for remote table "v1.WebsiteQualityScore" */
export type v1_WebsiteQualityScore_obj_rel_insert_input = {
  data: v1_WebsiteQualityScore_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_WebsiteQualityScore_on_conflict>;
};

/** on_conflict condition type for table "v1.WebsiteQualityScore" */
export type v1_WebsiteQualityScore_on_conflict = {
  constraint: v1_WebsiteQualityScore_constraint;
  update_columns?: Array<v1_WebsiteQualityScore_update_column>;
  where?: InputMaybe<v1_WebsiteQualityScore_bool_exp>;
};

/** Ordering options when selecting data from "v1.WebsiteQualityScore". */
export type v1_WebsiteQualityScore_order_by = {
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.WebsiteQualityScore */
export type v1_WebsiteQualityScore_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.WebsiteQualityScore" */
export enum v1_WebsiteQualityScore_select_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

/** input type for updating data in table "v1.WebsiteQualityScore" */
export type v1_WebsiteQualityScore_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type v1_WebsiteQualityScore_stddev_fields = {
  __typename?: 'v1_WebsiteQualityScore_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_WebsiteQualityScore_stddev_pop_fields = {
  __typename?: 'v1_WebsiteQualityScore_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_WebsiteQualityScore_stddev_samp_fields = {
  __typename?: 'v1_WebsiteQualityScore_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_WebsiteQualityScore" */
export type v1_WebsiteQualityScore_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_WebsiteQualityScore_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_WebsiteQualityScore_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type v1_WebsiteQualityScore_sum_fields = {
  __typename?: 'v1_WebsiteQualityScore_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.WebsiteQualityScore" */
export enum v1_WebsiteQualityScore_update_column {
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name'
}

export type v1_WebsiteQualityScore_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_WebsiteQualityScore_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_WebsiteQualityScore_set_input>;
  /** filter the rows which have to be updated */
  where: v1_WebsiteQualityScore_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_WebsiteQualityScore_var_pop_fields = {
  __typename?: 'v1_WebsiteQualityScore_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_WebsiteQualityScore_var_samp_fields = {
  __typename?: 'v1_WebsiteQualityScore_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_WebsiteQualityScore_variance_fields = {
  __typename?: 'v1_WebsiteQualityScore_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Website status option set */
export type v1_WebsiteStatus = {
  __typename?: 'v1_WebsiteStatus';
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** Website status option set */
export type v1_WebsiteStatusWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** Website status option set */
export type v1_WebsiteStatusWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** aggregated selection of "v1.WebsiteStatus" */
export type v1_WebsiteStatus_aggregate = {
  __typename?: 'v1_WebsiteStatus_aggregate';
  aggregate?: Maybe<v1_WebsiteStatus_aggregate_fields>;
  nodes: Array<v1_WebsiteStatus>;
};

/** aggregate fields of "v1.WebsiteStatus" */
export type v1_WebsiteStatus_aggregate_fields = {
  __typename?: 'v1_WebsiteStatus_aggregate_fields';
  avg?: Maybe<v1_WebsiteStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_WebsiteStatus_max_fields>;
  min?: Maybe<v1_WebsiteStatus_min_fields>;
  stddev?: Maybe<v1_WebsiteStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_WebsiteStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_WebsiteStatus_stddev_samp_fields>;
  sum?: Maybe<v1_WebsiteStatus_sum_fields>;
  var_pop?: Maybe<v1_WebsiteStatus_var_pop_fields>;
  var_samp?: Maybe<v1_WebsiteStatus_var_samp_fields>;
  variance?: Maybe<v1_WebsiteStatus_variance_fields>;
};


/** aggregate fields of "v1.WebsiteStatus" */
export type v1_WebsiteStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_WebsiteStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_WebsiteStatus_avg_fields = {
  __typename?: 'v1_WebsiteStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.WebsiteStatus". All fields are combined with a logical 'AND'. */
export type v1_WebsiteStatus_bool_exp = {
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_WebsiteStatus_bool_exp>>;
  _not?: InputMaybe<v1_WebsiteStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_WebsiteStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.WebsiteStatus" */
export enum v1_WebsiteStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  WebsiteStatus_pkey = 'WebsiteStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.WebsiteStatus" */
export type v1_WebsiteStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.WebsiteStatus" */
export type v1_WebsiteStatus_insert_input = {
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_WebsiteStatus_max_fields = {
  __typename?: 'v1_WebsiteStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_WebsiteStatus_min_fields = {
  __typename?: 'v1_WebsiteStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.WebsiteStatus" */
export type v1_WebsiteStatus_mutation_response = {
  __typename?: 'v1_WebsiteStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_WebsiteStatus>;
};

/** input type for inserting object relation for remote table "v1.WebsiteStatus" */
export type v1_WebsiteStatus_obj_rel_insert_input = {
  data: v1_WebsiteStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_WebsiteStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.WebsiteStatus" */
export type v1_WebsiteStatus_on_conflict = {
  constraint: v1_WebsiteStatus_constraint;
  update_columns?: Array<v1_WebsiteStatus_update_column>;
  where?: InputMaybe<v1_WebsiteStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.WebsiteStatus". */
export type v1_WebsiteStatus_order_by = {
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.WebsiteStatus */
export type v1_WebsiteStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.WebsiteStatus" */
export enum v1_WebsiteStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.WebsiteStatus" */
export type v1_WebsiteStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_WebsiteStatus_stddev_fields = {
  __typename?: 'v1_WebsiteStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_WebsiteStatus_stddev_pop_fields = {
  __typename?: 'v1_WebsiteStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_WebsiteStatus_stddev_samp_fields = {
  __typename?: 'v1_WebsiteStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_WebsiteStatus" */
export type v1_WebsiteStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_WebsiteStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_WebsiteStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_WebsiteStatus_sum_fields = {
  __typename?: 'v1_WebsiteStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.WebsiteStatus" */
export enum v1_WebsiteStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_WebsiteStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_WebsiteStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_WebsiteStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_WebsiteStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_WebsiteStatus_var_pop_fields = {
  __typename?: 'v1_WebsiteStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_WebsiteStatus_var_samp_fields = {
  __typename?: 'v1_WebsiteStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_WebsiteStatus_variance_fields = {
  __typename?: 'v1_WebsiteStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.WebsiteTopics" */
export type v1_WebsiteTopics = {
  __typename?: 'v1_WebsiteTopics';
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An array relationship */
  WebsitesToTopics: Array<v1_WebsitesToTopics>;
  /** An aggregate relationship */
  WebsitesToTopics_aggregate: v1_WebsitesToTopics_aggregate;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  id: Scalars['Int']['output'];
  is_parent: Scalars['Boolean']['output'];
  parent_id?: Maybe<Scalars['Int']['output']>;
  topic_name: Scalars['String']['output'];
};


/** columns and relationships of "v1.WebsiteTopics" */
export type v1_WebsiteTopicsOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.WebsiteTopics" */
export type v1_WebsiteTopicsOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.WebsiteTopics" */
export type v1_WebsiteTopicsWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** columns and relationships of "v1.WebsiteTopics" */
export type v1_WebsiteTopicsWebsitesToTopicsArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsitesToTopics_order_by>>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};


/** columns and relationships of "v1.WebsiteTopics" */
export type v1_WebsiteTopicsWebsitesToTopics_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_WebsitesToTopics_order_by>>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};


/** columns and relationships of "v1.WebsiteTopics" */
export type v1_WebsiteTopicsWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** aggregated selection of "v1.WebsiteTopics" */
export type v1_WebsiteTopics_aggregate = {
  __typename?: 'v1_WebsiteTopics_aggregate';
  aggregate?: Maybe<v1_WebsiteTopics_aggregate_fields>;
  nodes: Array<v1_WebsiteTopics>;
};

/** aggregate fields of "v1.WebsiteTopics" */
export type v1_WebsiteTopics_aggregate_fields = {
  __typename?: 'v1_WebsiteTopics_aggregate_fields';
  avg?: Maybe<v1_WebsiteTopics_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_WebsiteTopics_max_fields>;
  min?: Maybe<v1_WebsiteTopics_min_fields>;
  stddev?: Maybe<v1_WebsiteTopics_stddev_fields>;
  stddev_pop?: Maybe<v1_WebsiteTopics_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_WebsiteTopics_stddev_samp_fields>;
  sum?: Maybe<v1_WebsiteTopics_sum_fields>;
  var_pop?: Maybe<v1_WebsiteTopics_var_pop_fields>;
  var_samp?: Maybe<v1_WebsiteTopics_var_samp_fields>;
  variance?: Maybe<v1_WebsiteTopics_variance_fields>;
};


/** aggregate fields of "v1.WebsiteTopics" */
export type v1_WebsiteTopics_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_WebsiteTopics_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_WebsiteTopics_avg_fields = {
  __typename?: 'v1_WebsiteTopics_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.WebsiteTopics". All fields are combined with a logical 'AND'. */
export type v1_WebsiteTopics_bool_exp = {
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  Websites?: InputMaybe<v1_Website_bool_exp>;
  WebsitesToTopics?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
  WebsitesToTopics_aggregate?: InputMaybe<v1_WebsitesToTopics_aggregate_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_WebsiteTopics_bool_exp>>;
  _not?: InputMaybe<v1_WebsiteTopics_bool_exp>;
  _or?: InputMaybe<Array<v1_WebsiteTopics_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  is_parent?: InputMaybe<Boolean_comparison_exp>;
  parent_id?: InputMaybe<Int_comparison_exp>;
  topic_name?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.WebsiteTopics" */
export enum v1_WebsiteTopics_constraint {
  /** unique or primary key constraint on columns "id" */
  WebsiteTopics_pkey = 'WebsiteTopics_pkey'
}

/** input type for incrementing numeric columns in table "v1.WebsiteTopics" */
export type v1_WebsiteTopics_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.WebsiteTopics" */
export type v1_WebsiteTopics_insert_input = {
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  WebsitesToTopics?: InputMaybe<v1_WebsitesToTopics_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_parent?: InputMaybe<Scalars['Boolean']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
  topic_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_WebsiteTopics_max_fields = {
  __typename?: 'v1_WebsiteTopics_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
  topic_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_WebsiteTopics_min_fields = {
  __typename?: 'v1_WebsiteTopics_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
  topic_name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.WebsiteTopics" */
export type v1_WebsiteTopics_mutation_response = {
  __typename?: 'v1_WebsiteTopics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_WebsiteTopics>;
};

/** input type for inserting object relation for remote table "v1.WebsiteTopics" */
export type v1_WebsiteTopics_obj_rel_insert_input = {
  data: v1_WebsiteTopics_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_WebsiteTopics_on_conflict>;
};

/** on_conflict condition type for table "v1.WebsiteTopics" */
export type v1_WebsiteTopics_on_conflict = {
  constraint: v1_WebsiteTopics_constraint;
  update_columns?: Array<v1_WebsiteTopics_update_column>;
  where?: InputMaybe<v1_WebsiteTopics_bool_exp>;
};

/** Ordering options when selecting data from "v1.WebsiteTopics". */
export type v1_WebsiteTopics_order_by = {
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  WebsitesToTopics_aggregate?: InputMaybe<v1_WebsitesToTopics_aggregate_order_by>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  is_parent?: InputMaybe<order_by>;
  parent_id?: InputMaybe<order_by>;
  topic_name?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.WebsiteTopics */
export type v1_WebsiteTopics_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.WebsiteTopics" */
export enum v1_WebsiteTopics_select_column {
  /** column name */
  id = 'id',
  /** column name */
  is_parent = 'is_parent',
  /** column name */
  parent_id = 'parent_id',
  /** column name */
  topic_name = 'topic_name'
}

/** input type for updating data in table "v1.WebsiteTopics" */
export type v1_WebsiteTopics_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_parent?: InputMaybe<Scalars['Boolean']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
  topic_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_WebsiteTopics_stddev_fields = {
  __typename?: 'v1_WebsiteTopics_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_WebsiteTopics_stddev_pop_fields = {
  __typename?: 'v1_WebsiteTopics_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_WebsiteTopics_stddev_samp_fields = {
  __typename?: 'v1_WebsiteTopics_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_WebsiteTopics" */
export type v1_WebsiteTopics_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_WebsiteTopics_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_WebsiteTopics_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_parent?: InputMaybe<Scalars['Boolean']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
  topic_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_WebsiteTopics_sum_fields = {
  __typename?: 'v1_WebsiteTopics_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.WebsiteTopics" */
export enum v1_WebsiteTopics_update_column {
  /** column name */
  id = 'id',
  /** column name */
  is_parent = 'is_parent',
  /** column name */
  parent_id = 'parent_id',
  /** column name */
  topic_name = 'topic_name'
}

export type v1_WebsiteTopics_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_WebsiteTopics_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_WebsiteTopics_set_input>;
  /** filter the rows which have to be updated */
  where: v1_WebsiteTopics_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_WebsiteTopics_var_pop_fields = {
  __typename?: 'v1_WebsiteTopics_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_WebsiteTopics_var_samp_fields = {
  __typename?: 'v1_WebsiteTopics_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_WebsiteTopics_variance_fields = {
  __typename?: 'v1_WebsiteTopics_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** WebsiteUptimeStatus option set */
export type v1_WebsiteUptimeStatus = {
  __typename?: 'v1_WebsiteUptimeStatus';
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  id: Scalars['Int']['output'];
  valu: Scalars['String']['output'];
};


/** WebsiteUptimeStatus option set */
export type v1_WebsiteUptimeStatusWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** WebsiteUptimeStatus option set */
export type v1_WebsiteUptimeStatusWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** aggregated selection of "v1.WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_aggregate = {
  __typename?: 'v1_WebsiteUptimeStatus_aggregate';
  aggregate?: Maybe<v1_WebsiteUptimeStatus_aggregate_fields>;
  nodes: Array<v1_WebsiteUptimeStatus>;
};

/** aggregate fields of "v1.WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_aggregate_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_aggregate_fields';
  avg?: Maybe<v1_WebsiteUptimeStatus_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_WebsiteUptimeStatus_max_fields>;
  min?: Maybe<v1_WebsiteUptimeStatus_min_fields>;
  stddev?: Maybe<v1_WebsiteUptimeStatus_stddev_fields>;
  stddev_pop?: Maybe<v1_WebsiteUptimeStatus_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_WebsiteUptimeStatus_stddev_samp_fields>;
  sum?: Maybe<v1_WebsiteUptimeStatus_sum_fields>;
  var_pop?: Maybe<v1_WebsiteUptimeStatus_var_pop_fields>;
  var_samp?: Maybe<v1_WebsiteUptimeStatus_var_samp_fields>;
  variance?: Maybe<v1_WebsiteUptimeStatus_variance_fields>;
};


/** aggregate fields of "v1.WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_WebsiteUptimeStatus_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_WebsiteUptimeStatus_avg_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.WebsiteUptimeStatus". All fields are combined with a logical 'AND'. */
export type v1_WebsiteUptimeStatus_bool_exp = {
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_WebsiteUptimeStatus_bool_exp>>;
  _not?: InputMaybe<v1_WebsiteUptimeStatus_bool_exp>;
  _or?: InputMaybe<Array<v1_WebsiteUptimeStatus_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  valu?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.WebsiteUptimeStatus" */
export enum v1_WebsiteUptimeStatus_constraint {
  /** unique or primary key constraint on columns "id" */
  WebsiteUptimeStatus_pkey = 'WebsiteUptimeStatus_pkey'
}

/** input type for incrementing numeric columns in table "v1.WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_insert_input = {
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  valu?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_WebsiteUptimeStatus_max_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  valu?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_WebsiteUptimeStatus_min_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  valu?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_mutation_response = {
  __typename?: 'v1_WebsiteUptimeStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_WebsiteUptimeStatus>;
};

/** input type for inserting object relation for remote table "v1.WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_obj_rel_insert_input = {
  data: v1_WebsiteUptimeStatus_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_WebsiteUptimeStatus_on_conflict>;
};

/** on_conflict condition type for table "v1.WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_on_conflict = {
  constraint: v1_WebsiteUptimeStatus_constraint;
  update_columns?: Array<v1_WebsiteUptimeStatus_update_column>;
  where?: InputMaybe<v1_WebsiteUptimeStatus_bool_exp>;
};

/** Ordering options when selecting data from "v1.WebsiteUptimeStatus". */
export type v1_WebsiteUptimeStatus_order_by = {
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  valu?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.WebsiteUptimeStatus */
export type v1_WebsiteUptimeStatus_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.WebsiteUptimeStatus" */
export enum v1_WebsiteUptimeStatus_select_column {
  /** column name */
  id = 'id',
  /** column name */
  valu = 'valu'
}

/** input type for updating data in table "v1.WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  valu?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_WebsiteUptimeStatus_stddev_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_WebsiteUptimeStatus_stddev_pop_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_WebsiteUptimeStatus_stddev_samp_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_WebsiteUptimeStatus" */
export type v1_WebsiteUptimeStatus_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_WebsiteUptimeStatus_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_WebsiteUptimeStatus_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  valu?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_WebsiteUptimeStatus_sum_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.WebsiteUptimeStatus" */
export enum v1_WebsiteUptimeStatus_update_column {
  /** column name */
  id = 'id',
  /** column name */
  valu = 'valu'
}

export type v1_WebsiteUptimeStatus_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_WebsiteUptimeStatus_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_WebsiteUptimeStatus_set_input>;
  /** filter the rows which have to be updated */
  where: v1_WebsiteUptimeStatus_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_WebsiteUptimeStatus_var_pop_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_WebsiteUptimeStatus_var_samp_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_WebsiteUptimeStatus_variance_fields = {
  __typename?: 'v1_WebsiteUptimeStatus_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.Website_Platform" */
export type v1_Website_Platform = {
  __typename?: 'v1_Website_Platform';
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.Website_Platform" */
export type v1_Website_PlatformWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** columns and relationships of "v1.Website_Platform" */
export type v1_Website_PlatformWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** aggregated selection of "v1.Website_Platform" */
export type v1_Website_Platform_aggregate = {
  __typename?: 'v1_Website_Platform_aggregate';
  aggregate?: Maybe<v1_Website_Platform_aggregate_fields>;
  nodes: Array<v1_Website_Platform>;
};

/** aggregate fields of "v1.Website_Platform" */
export type v1_Website_Platform_aggregate_fields = {
  __typename?: 'v1_Website_Platform_aggregate_fields';
  avg?: Maybe<v1_Website_Platform_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Website_Platform_max_fields>;
  min?: Maybe<v1_Website_Platform_min_fields>;
  stddev?: Maybe<v1_Website_Platform_stddev_fields>;
  stddev_pop?: Maybe<v1_Website_Platform_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Website_Platform_stddev_samp_fields>;
  sum?: Maybe<v1_Website_Platform_sum_fields>;
  var_pop?: Maybe<v1_Website_Platform_var_pop_fields>;
  var_samp?: Maybe<v1_Website_Platform_var_samp_fields>;
  variance?: Maybe<v1_Website_Platform_variance_fields>;
};


/** aggregate fields of "v1.Website_Platform" */
export type v1_Website_Platform_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Website_Platform_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Website_Platform_avg_fields = {
  __typename?: 'v1_Website_Platform_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Website_Platform". All fields are combined with a logical 'AND'. */
export type v1_Website_Platform_bool_exp = {
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_Website_Platform_bool_exp>>;
  _not?: InputMaybe<v1_Website_Platform_bool_exp>;
  _or?: InputMaybe<Array<v1_Website_Platform_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Website_Platform" */
export enum v1_Website_Platform_constraint {
  /** unique or primary key constraint on columns "id" */
  Website_Platform_pkey = 'Website_Platform_pkey'
}

/** input type for incrementing numeric columns in table "v1.Website_Platform" */
export type v1_Website_Platform_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Website_Platform" */
export type v1_Website_Platform_insert_input = {
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_Website_Platform_max_fields = {
  __typename?: 'v1_Website_Platform_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_Website_Platform_min_fields = {
  __typename?: 'v1_Website_Platform_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.Website_Platform" */
export type v1_Website_Platform_mutation_response = {
  __typename?: 'v1_Website_Platform_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Website_Platform>;
};

/** input type for inserting object relation for remote table "v1.Website_Platform" */
export type v1_Website_Platform_obj_rel_insert_input = {
  data: v1_Website_Platform_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Website_Platform_on_conflict>;
};

/** on_conflict condition type for table "v1.Website_Platform" */
export type v1_Website_Platform_on_conflict = {
  constraint: v1_Website_Platform_constraint;
  update_columns?: Array<v1_Website_Platform_update_column>;
  where?: InputMaybe<v1_Website_Platform_bool_exp>;
};

/** Ordering options when selecting data from "v1.Website_Platform". */
export type v1_Website_Platform_order_by = {
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Website_Platform */
export type v1_Website_Platform_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Website_Platform" */
export enum v1_Website_Platform_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.Website_Platform" */
export type v1_Website_Platform_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_Website_Platform_stddev_fields = {
  __typename?: 'v1_Website_Platform_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Website_Platform_stddev_pop_fields = {
  __typename?: 'v1_Website_Platform_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Website_Platform_stddev_samp_fields = {
  __typename?: 'v1_Website_Platform_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Website_Platform" */
export type v1_Website_Platform_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Website_Platform_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Website_Platform_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_Website_Platform_sum_fields = {
  __typename?: 'v1_Website_Platform_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.Website_Platform" */
export enum v1_Website_Platform_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_Website_Platform_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Website_Platform_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Website_Platform_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Website_Platform_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Website_Platform_var_pop_fields = {
  __typename?: 'v1_Website_Platform_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Website_Platform_var_samp_fields = {
  __typename?: 'v1_Website_Platform_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Website_Platform_variance_fields = {
  __typename?: 'v1_Website_Platform_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "v1.Website" */
export type v1_Website_aggregate = {
  __typename?: 'v1_Website_aggregate';
  aggregate?: Maybe<v1_Website_aggregate_fields>;
  nodes: Array<v1_Website>;
};

export type v1_Website_aggregate_bool_exp = {
  avg?: InputMaybe<v1_Website_aggregate_bool_exp_avg>;
  bool_and?: InputMaybe<v1_Website_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_Website_aggregate_bool_exp_bool_or>;
  corr?: InputMaybe<v1_Website_aggregate_bool_exp_corr>;
  count?: InputMaybe<v1_Website_aggregate_bool_exp_count>;
  covar_samp?: InputMaybe<v1_Website_aggregate_bool_exp_covar_samp>;
  max?: InputMaybe<v1_Website_aggregate_bool_exp_max>;
  min?: InputMaybe<v1_Website_aggregate_bool_exp_min>;
  stddev_samp?: InputMaybe<v1_Website_aggregate_bool_exp_stddev_samp>;
  sum?: InputMaybe<v1_Website_aggregate_bool_exp_sum>;
  var_samp?: InputMaybe<v1_Website_aggregate_bool_exp_var_samp>;
};

export type v1_Website_aggregate_bool_exp_avg = {
  arguments: v1_Website_select_column_v1_Website_aggregate_bool_exp_avg_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_bool_and = {
  arguments: v1_Website_select_column_v1_Website_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_bool_or = {
  arguments: v1_Website_select_column_v1_Website_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_corr = {
  arguments: v1_Website_aggregate_bool_exp_corr_arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_corr_arguments = {
  X: v1_Website_select_column_v1_Website_aggregate_bool_exp_corr_arguments_columns;
  Y: v1_Website_select_column_v1_Website_aggregate_bool_exp_corr_arguments_columns;
};

export type v1_Website_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_Website_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: Int_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_covar_samp = {
  arguments: v1_Website_aggregate_bool_exp_covar_samp_arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_covar_samp_arguments = {
  X: v1_Website_select_column_v1_Website_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: v1_Website_select_column_v1_Website_aggregate_bool_exp_covar_samp_arguments_columns;
};

export type v1_Website_aggregate_bool_exp_max = {
  arguments: v1_Website_select_column_v1_Website_aggregate_bool_exp_max_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_min = {
  arguments: v1_Website_select_column_v1_Website_aggregate_bool_exp_min_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_stddev_samp = {
  arguments: v1_Website_select_column_v1_Website_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_sum = {
  arguments: v1_Website_select_column_v1_Website_aggregate_bool_exp_sum_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: float8_comparison_exp;
};

export type v1_Website_aggregate_bool_exp_var_samp = {
  arguments: v1_Website_select_column_v1_Website_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_Website_bool_exp>;
  predicate: float8_comparison_exp;
};

/** aggregate fields of "v1.Website" */
export type v1_Website_aggregate_fields = {
  __typename?: 'v1_Website_aggregate_fields';
  avg?: Maybe<v1_Website_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Website_max_fields>;
  min?: Maybe<v1_Website_min_fields>;
  stddev?: Maybe<v1_Website_stddev_fields>;
  stddev_pop?: Maybe<v1_Website_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Website_stddev_samp_fields>;
  sum?: Maybe<v1_Website_sum_fields>;
  var_pop?: Maybe<v1_Website_var_pop_fields>;
  var_samp?: Maybe<v1_Website_var_samp_fields>;
  variance?: Maybe<v1_Website_variance_fields>;
};


/** aggregate fields of "v1.Website" */
export type v1_Website_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Website_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.Website" */
export type v1_Website_aggregate_order_by = {
  avg?: InputMaybe<v1_Website_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_Website_max_order_by>;
  min?: InputMaybe<v1_Website_min_order_by>;
  stddev?: InputMaybe<v1_Website_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_Website_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_Website_stddev_samp_order_by>;
  sum?: InputMaybe<v1_Website_sum_order_by>;
  var_pop?: InputMaybe<v1_Website_var_pop_order_by>;
  var_samp?: InputMaybe<v1_Website_var_samp_order_by>;
  variance?: InputMaybe<v1_Website_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.Website" */
export type v1_Website_arr_rel_insert_input = {
  data: Array<v1_Website_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Website_on_conflict>;
};

/** aggregate avg on columns */
export type v1_Website_avg_fields = {
  __typename?: 'v1_Website_avg_fields';
  AhrefsDR?: Maybe<Scalars['Float']['output']>;
  AhrefsRL?: Maybe<Scalars['Float']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Float']['output']>;
  MOZSpamScore?: Maybe<Scalars['Float']['output']>;
  NativeLink?: Maybe<Scalars['Float']['output']>;
  OrganicTraffic?: Maybe<Scalars['Float']['output']>;
  OutgoingDomains?: Maybe<Scalars['Float']['output']>;
  PublishingSpeed?: Maybe<Scalars['Float']['output']>;
  ReferringDomains?: Maybe<Scalars['Float']['output']>;
  Reset?: Maybe<Scalars['Float']['output']>;
  SemrushAS?: Maybe<Scalars['Float']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  keywords?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  linked_domains?: Maybe<Scalars['Float']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['Float']['output']>;
  new_BlogProbability?: Maybe<Scalars['Float']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Float']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['Float']['output']>;
  new_DropRate?: Maybe<Scalars['Float']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Float']['output']>;
  new_MaxPosts?: Maybe<Scalars['Float']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['Float']['output']>;
  new_Powerdomain?: Maybe<Scalars['Float']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Float']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Float']['output']>;
  new_trustdom?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  platform_id?: Maybe<Scalars['Float']['output']>;
  powerTrustDom?: Maybe<Scalars['Float']['output']>;
  referring_domains_total?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Float']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  url_rating?: Maybe<Scalars['Float']['output']>;
  website_owner_id?: Maybe<Scalars['Float']['output']>;
  website_quality_score_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.Website" */
export type v1_Website_avg_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.Website". All fields are combined with a logical 'AND'. */
export type v1_Website_bool_exp = {
  AhrefsDR?: InputMaybe<numeric_comparison_exp>;
  AhrefsRL?: InputMaybe<Int_comparison_exp>;
  AllSitePosts?: InputMaybe<Int_comparison_exp>;
  AmountOfIndexedKeywords?: InputMaybe<Int_comparison_exp>;
  ApprovedPosts?: InputMaybe<Int_comparison_exp>;
  BlogProbability?: InputMaybe<v1_WebsiteBlogProbability_bool_exp>;
  ContentIndicator?: InputMaybe<v1_WebsiteContentIndicator_bool_exp>;
  ContentTypes?: InputMaybe<v1_ContentTypes_bool_exp>;
  ContentTypes_aggregate?: InputMaybe<v1_ContentTypes_aggregate_bool_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  DomainAge?: InputMaybe<date_comparison_exp>;
  InProgressPosts?: InputMaybe<Int_comparison_exp>;
  Language?: InputMaybe<v1_new_languagesBase_bool_exp>;
  LivePosts?: InputMaybe<Int_comparison_exp>;
  LostPosts?: InputMaybe<Int_comparison_exp>;
  MOZSpamScore?: InputMaybe<numeric_comparison_exp>;
  NativeLink?: InputMaybe<Int_comparison_exp>;
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  OrganicTraffic?: InputMaybe<Int_comparison_exp>;
  OutgoingDomains?: InputMaybe<numeric_comparison_exp>;
  Pages?: InputMaybe<v1_Page_bool_exp>;
  Pages_aggregate?: InputMaybe<v1_Page_aggregate_bool_exp>;
  PostNiche?: InputMaybe<v1_PostNiche_bool_exp>;
  PostedPosts?: InputMaybe<Int_comparison_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  Projects?: InputMaybe<v1_Project_bool_exp>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_bool_exp>;
  PublishingSpeed?: InputMaybe<Int_comparison_exp>;
  ReferringDomains?: InputMaybe<numeric_comparison_exp>;
  Reset?: InputMaybe<Int_comparison_exp>;
  SemrushAS?: InputMaybe<numeric_comparison_exp>;
  Sichtbarkeitsindex?: InputMaybe<numeric_comparison_exp>;
  UptimeStatus?: InputMaybe<v1_WebsiteUptimeStatus_bool_exp>;
  WebsiteDToxRisk?: InputMaybe<v1_WebsiteDToxRisk_bool_exp>;
  WebsiteOwner?: InputMaybe<v1_ContactBase_bool_exp>;
  WebsiteQualityScore?: InputMaybe<v1_WebsiteQualityScore_bool_exp>;
  WebsiteStatus?: InputMaybe<v1_WebsiteStatus_bool_exp>;
  WebsiteTopic?: InputMaybe<v1_WebsiteTopics_bool_exp>;
  Website_Platform?: InputMaybe<v1_Website_Platform_bool_exp>;
  WebsitesToTopics?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
  WebsitesToTopics_aggregate?: InputMaybe<v1_WebsitesToTopics_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_Website_bool_exp>>;
  _not?: InputMaybe<v1_Website_bool_exp>;
  _or?: InputMaybe<Array<v1_Website_bool_exp>>;
  ahrefs_last_updated_on?: InputMaybe<date_comparison_exp>;
  comp_domain_age?: InputMaybe<numeric_comparison_exp>;
  comp_ld_rd_ratio?: InputMaybe<numeric_comparison_exp>;
  comp_related_offers?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  crm_id?: InputMaybe<uuid_comparison_exp>;
  description?: InputMaybe<String_comparison_exp>;
  domain_creation_date?: InputMaybe<date_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  ip_adress?: InputMaybe<String_comparison_exp>;
  is_gambling?: InputMaybe<Boolean_comparison_exp>;
  keywords?: InputMaybe<Int_comparison_exp>;
  language_id?: InputMaybe<Int_comparison_exp>;
  linked_domains?: InputMaybe<Int_comparison_exp>;
  lrt_last_updated_on?: InputMaybe<date_comparison_exp>;
  new_AccessCheck?: InputMaybe<timestamp_comparison_exp>;
  new_AccessCheckFailCounter?: InputMaybe<numeric_comparison_exp>;
  new_AccessNotes?: InputMaybe<String_comparison_exp>;
  new_AdminPassword?: InputMaybe<String_comparison_exp>;
  new_BlogProbability?: InputMaybe<Int_comparison_exp>;
  new_ContentIndicator?: InputMaybe<Int_comparison_exp>;
  new_DoFollowLinks?: InputMaybe<Boolean_comparison_exp>;
  new_DomainRatingMOZ?: InputMaybe<float8_comparison_exp>;
  new_DropRate?: InputMaybe<float8_comparison_exp>;
  new_GoogleIndexedPages?: InputMaybe<Int_comparison_exp>;
  new_LastSeoCheck?: InputMaybe<timestamp_comparison_exp>;
  new_LastUptimeCheck?: InputMaybe<timestamp_comparison_exp>;
  new_MaxPosts?: InputMaybe<Int_comparison_exp>;
  new_MozDomainAuthority?: InputMaybe<numeric_comparison_exp>;
  new_OfflineSince?: InputMaybe<timestamp_comparison_exp>;
  new_Platform?: InputMaybe<String_comparison_exp>;
  new_Powerdomain?: InputMaybe<float8_comparison_exp>;
  new_SubDomain?: InputMaybe<Boolean_comparison_exp>;
  new_TitleHome?: InputMaybe<String_comparison_exp>;
  new_UptimeStatus?: InputMaybe<Int_comparison_exp>;
  new_WebsiteContactOwner?: InputMaybe<uuid_comparison_exp>;
  new_WebsiteIP?: InputMaybe<String_comparison_exp>;
  new_adminuser?: InputMaybe<String_comparison_exp>;
  new_baseurl?: InputMaybe<String_comparison_exp>;
  new_dtoxrisk?: InputMaybe<Int_comparison_exp>;
  new_language?: InputMaybe<uuid_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_trustdom?: InputMaybe<Int_comparison_exp>;
  niche_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<Int_comparison_exp>;
  powerTrustDom?: InputMaybe<Int_comparison_exp>;
  referring_domains_total?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
  totalTrafficHREFS?: InputMaybe<Int_comparison_exp>;
  traffic?: InputMaybe<Int_comparison_exp>;
  url_rating?: InputMaybe<Int_comparison_exp>;
  website_owner_id?: InputMaybe<Int_comparison_exp>;
  website_quality_score_id?: InputMaybe<Int_comparison_exp>;
  website_topic_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.Website" */
export enum v1_Website_constraint {
  /** unique or primary key constraint on columns "id" */
  Website_id_key = 'Website_id_key',
  /** unique or primary key constraint on columns "new_baseurl" */
  Website_new_baseurl_key = 'Website_new_baseurl_key',
  /** unique or primary key constraint on columns "new_name" */
  Website_new_name_key = 'Website_new_name_key',
  /** unique or primary key constraint on columns "id" */
  Website_pkey = 'Website_pkey'
}

/** input type for incrementing numeric columns in table "v1.Website" */
export type v1_Website_inc_input = {
  AhrefsDR?: InputMaybe<Scalars['numeric']['input']>;
  AhrefsRL?: InputMaybe<Scalars['Int']['input']>;
  AmountOfIndexedKeywords?: InputMaybe<Scalars['Int']['input']>;
  MOZSpamScore?: InputMaybe<Scalars['numeric']['input']>;
  NativeLink?: InputMaybe<Scalars['Int']['input']>;
  OrganicTraffic?: InputMaybe<Scalars['Int']['input']>;
  OutgoingDomains?: InputMaybe<Scalars['numeric']['input']>;
  PublishingSpeed?: InputMaybe<Scalars['Int']['input']>;
  ReferringDomains?: InputMaybe<Scalars['numeric']['input']>;
  Reset?: InputMaybe<Scalars['Int']['input']>;
  SemrushAS?: InputMaybe<Scalars['numeric']['input']>;
  Sichtbarkeitsindex?: InputMaybe<Scalars['numeric']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  keywords?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  linked_domains?: InputMaybe<Scalars['Int']['input']>;
  new_AccessCheckFailCounter?: InputMaybe<Scalars['numeric']['input']>;
  new_BlogProbability?: InputMaybe<Scalars['Int']['input']>;
  new_ContentIndicator?: InputMaybe<Scalars['Int']['input']>;
  new_DomainRatingMOZ?: InputMaybe<Scalars['float8']['input']>;
  new_DropRate?: InputMaybe<Scalars['float8']['input']>;
  new_GoogleIndexedPages?: InputMaybe<Scalars['Int']['input']>;
  new_MaxPosts?: InputMaybe<Scalars['Int']['input']>;
  new_MozDomainAuthority?: InputMaybe<Scalars['numeric']['input']>;
  new_Powerdomain?: InputMaybe<Scalars['float8']['input']>;
  new_UptimeStatus?: InputMaybe<Scalars['Int']['input']>;
  new_dtoxrisk?: InputMaybe<Scalars['Int']['input']>;
  new_trustdom?: InputMaybe<Scalars['Int']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['Int']['input']>;
  powerTrustDom?: InputMaybe<Scalars['Int']['input']>;
  referring_domains_total?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  totalTrafficHREFS?: InputMaybe<Scalars['Int']['input']>;
  traffic?: InputMaybe<Scalars['Int']['input']>;
  url_rating?: InputMaybe<Scalars['Int']['input']>;
  website_owner_id?: InputMaybe<Scalars['Int']['input']>;
  website_quality_score_id?: InputMaybe<Scalars['Int']['input']>;
  website_topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.Website" */
export type v1_Website_insert_input = {
  AhrefsDR?: InputMaybe<Scalars['numeric']['input']>;
  AhrefsRL?: InputMaybe<Scalars['Int']['input']>;
  AmountOfIndexedKeywords?: InputMaybe<Scalars['Int']['input']>;
  BlogProbability?: InputMaybe<v1_WebsiteBlogProbability_obj_rel_insert_input>;
  ContentIndicator?: InputMaybe<v1_WebsiteContentIndicator_obj_rel_insert_input>;
  ContentTypes?: InputMaybe<v1_ContentTypes_arr_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  /** Depricated. Pls use domain_creation_date. */
  DomainAge?: InputMaybe<Scalars['date']['input']>;
  Language?: InputMaybe<v1_new_languagesBase_obj_rel_insert_input>;
  MOZSpamScore?: InputMaybe<Scalars['numeric']['input']>;
  NativeLink?: InputMaybe<Scalars['Int']['input']>;
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  OrganicTraffic?: InputMaybe<Scalars['Int']['input']>;
  OutgoingDomains?: InputMaybe<Scalars['numeric']['input']>;
  Pages?: InputMaybe<v1_Page_arr_rel_insert_input>;
  PostNiche?: InputMaybe<v1_PostNiche_obj_rel_insert_input>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  Projects?: InputMaybe<v1_Project_arr_rel_insert_input>;
  PublishingSpeed?: InputMaybe<Scalars['Int']['input']>;
  ReferringDomains?: InputMaybe<Scalars['numeric']['input']>;
  Reset?: InputMaybe<Scalars['Int']['input']>;
  SemrushAS?: InputMaybe<Scalars['numeric']['input']>;
  Sichtbarkeitsindex?: InputMaybe<Scalars['numeric']['input']>;
  UptimeStatus?: InputMaybe<v1_WebsiteUptimeStatus_obj_rel_insert_input>;
  WebsiteDToxRisk?: InputMaybe<v1_WebsiteDToxRisk_obj_rel_insert_input>;
  WebsiteOwner?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  WebsiteQualityScore?: InputMaybe<v1_WebsiteQualityScore_obj_rel_insert_input>;
  WebsiteStatus?: InputMaybe<v1_WebsiteStatus_obj_rel_insert_input>;
  WebsiteTopic?: InputMaybe<v1_WebsiteTopics_obj_rel_insert_input>;
  Website_Platform?: InputMaybe<v1_Website_Platform_obj_rel_insert_input>;
  WebsitesToTopics?: InputMaybe<v1_WebsitesToTopics_arr_rel_insert_input>;
  ahrefs_last_updated_on?: InputMaybe<Scalars['date']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  crm_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain_creation_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ip_adress?: InputMaybe<Scalars['String']['input']>;
  is_gambling?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  linked_domains?: InputMaybe<Scalars['Int']['input']>;
  lrt_last_updated_on?: InputMaybe<Scalars['date']['input']>;
  new_AccessCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_AccessCheckFailCounter?: InputMaybe<Scalars['numeric']['input']>;
  new_AccessNotes?: InputMaybe<Scalars['String']['input']>;
  new_AdminPassword?: InputMaybe<Scalars['String']['input']>;
  new_BlogProbability?: InputMaybe<Scalars['Int']['input']>;
  new_ContentIndicator?: InputMaybe<Scalars['Int']['input']>;
  new_DoFollowLinks?: InputMaybe<Scalars['Boolean']['input']>;
  new_DomainRatingMOZ?: InputMaybe<Scalars['float8']['input']>;
  new_DropRate?: InputMaybe<Scalars['float8']['input']>;
  new_GoogleIndexedPages?: InputMaybe<Scalars['Int']['input']>;
  new_LastSeoCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_LastUptimeCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_MaxPosts?: InputMaybe<Scalars['Int']['input']>;
  new_MozDomainAuthority?: InputMaybe<Scalars['numeric']['input']>;
  new_OfflineSince?: InputMaybe<Scalars['timestamp']['input']>;
  new_Platform?: InputMaybe<Scalars['String']['input']>;
  new_Powerdomain?: InputMaybe<Scalars['float8']['input']>;
  new_SubDomain?: InputMaybe<Scalars['Boolean']['input']>;
  new_TitleHome?: InputMaybe<Scalars['String']['input']>;
  new_UptimeStatus?: InputMaybe<Scalars['Int']['input']>;
  new_WebsiteContactOwner?: InputMaybe<Scalars['uuid']['input']>;
  new_WebsiteIP?: InputMaybe<Scalars['String']['input']>;
  new_adminuser?: InputMaybe<Scalars['String']['input']>;
  new_baseurl?: InputMaybe<Scalars['String']['input']>;
  new_dtoxrisk?: InputMaybe<Scalars['Int']['input']>;
  new_language?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_trustdom?: InputMaybe<Scalars['Int']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['Int']['input']>;
  powerTrustDom?: InputMaybe<Scalars['Int']['input']>;
  referring_domains_total?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  totalTrafficHREFS?: InputMaybe<Scalars['Int']['input']>;
  traffic?: InputMaybe<Scalars['Int']['input']>;
  url_rating?: InputMaybe<Scalars['Int']['input']>;
  website_owner_id?: InputMaybe<Scalars['Int']['input']>;
  website_quality_score_id?: InputMaybe<Scalars['Int']['input']>;
  website_topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_Website_max_fields = {
  __typename?: 'v1_Website_max_fields';
  AhrefsDR?: Maybe<Scalars['numeric']['output']>;
  AhrefsRL?: Maybe<Scalars['Int']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** Depricated. Pls use domain_creation_date. */
  DomainAge?: Maybe<Scalars['date']['output']>;
  MOZSpamScore?: Maybe<Scalars['numeric']['output']>;
  NativeLink?: Maybe<Scalars['Int']['output']>;
  OrganicTraffic?: Maybe<Scalars['Int']['output']>;
  OutgoingDomains?: Maybe<Scalars['numeric']['output']>;
  PublishingSpeed?: Maybe<Scalars['Int']['output']>;
  ReferringDomains?: Maybe<Scalars['numeric']['output']>;
  Reset?: Maybe<Scalars['Int']['output']>;
  SemrushAS?: Maybe<Scalars['numeric']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['numeric']['output']>;
  ahrefs_last_updated_on?: Maybe<Scalars['date']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  crm_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domain_creation_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  ip_adress?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  linked_domains?: Maybe<Scalars['Int']['output']>;
  lrt_last_updated_on?: Maybe<Scalars['date']['output']>;
  new_AccessCheck?: Maybe<Scalars['timestamp']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['numeric']['output']>;
  new_AccessNotes?: Maybe<Scalars['String']['output']>;
  new_AdminPassword?: Maybe<Scalars['String']['output']>;
  new_BlogProbability?: Maybe<Scalars['Int']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Int']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['float8']['output']>;
  new_DropRate?: Maybe<Scalars['float8']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Int']['output']>;
  new_LastSeoCheck?: Maybe<Scalars['timestamp']['output']>;
  new_LastUptimeCheck?: Maybe<Scalars['timestamp']['output']>;
  new_MaxPosts?: Maybe<Scalars['Int']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['numeric']['output']>;
  new_OfflineSince?: Maybe<Scalars['timestamp']['output']>;
  new_Platform?: Maybe<Scalars['String']['output']>;
  new_Powerdomain?: Maybe<Scalars['float8']['output']>;
  new_TitleHome?: Maybe<Scalars['String']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Int']['output']>;
  new_WebsiteContactOwner?: Maybe<Scalars['uuid']['output']>;
  new_WebsiteIP?: Maybe<Scalars['String']['output']>;
  new_adminuser?: Maybe<Scalars['String']['output']>;
  new_baseurl?: Maybe<Scalars['String']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Int']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_trustdom?: Maybe<Scalars['Int']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['Int']['output']>;
  powerTrustDom?: Maybe<Scalars['Int']['output']>;
  referring_domains_total?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Int']['output']>;
  traffic?: Maybe<Scalars['Int']['output']>;
  url_rating?: Maybe<Scalars['Int']['output']>;
  website_owner_id?: Maybe<Scalars['Int']['output']>;
  website_quality_score_id?: Maybe<Scalars['Int']['output']>;
  website_topic_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.Website" */
export type v1_Website_max_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  /** Depricated. Pls use domain_creation_date. */
  DomainAge?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  ahrefs_last_updated_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  crm_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  domain_creation_date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  ip_adress?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  lrt_last_updated_on?: InputMaybe<order_by>;
  new_AccessCheck?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_AccessNotes?: InputMaybe<order_by>;
  new_AdminPassword?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_LastSeoCheck?: InputMaybe<order_by>;
  new_LastUptimeCheck?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_OfflineSince?: InputMaybe<order_by>;
  new_Platform?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_TitleHome?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_WebsiteContactOwner?: InputMaybe<order_by>;
  new_WebsiteIP?: InputMaybe<order_by>;
  new_adminuser?: InputMaybe<order_by>;
  new_baseurl?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_language?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_Website_min_fields = {
  __typename?: 'v1_Website_min_fields';
  AhrefsDR?: Maybe<Scalars['numeric']['output']>;
  AhrefsRL?: Maybe<Scalars['Int']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** Depricated. Pls use domain_creation_date. */
  DomainAge?: Maybe<Scalars['date']['output']>;
  MOZSpamScore?: Maybe<Scalars['numeric']['output']>;
  NativeLink?: Maybe<Scalars['Int']['output']>;
  OrganicTraffic?: Maybe<Scalars['Int']['output']>;
  OutgoingDomains?: Maybe<Scalars['numeric']['output']>;
  PublishingSpeed?: Maybe<Scalars['Int']['output']>;
  ReferringDomains?: Maybe<Scalars['numeric']['output']>;
  Reset?: Maybe<Scalars['Int']['output']>;
  SemrushAS?: Maybe<Scalars['numeric']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['numeric']['output']>;
  ahrefs_last_updated_on?: Maybe<Scalars['date']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  crm_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domain_creation_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  ip_adress?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  linked_domains?: Maybe<Scalars['Int']['output']>;
  lrt_last_updated_on?: Maybe<Scalars['date']['output']>;
  new_AccessCheck?: Maybe<Scalars['timestamp']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['numeric']['output']>;
  new_AccessNotes?: Maybe<Scalars['String']['output']>;
  new_AdminPassword?: Maybe<Scalars['String']['output']>;
  new_BlogProbability?: Maybe<Scalars['Int']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Int']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['float8']['output']>;
  new_DropRate?: Maybe<Scalars['float8']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Int']['output']>;
  new_LastSeoCheck?: Maybe<Scalars['timestamp']['output']>;
  new_LastUptimeCheck?: Maybe<Scalars['timestamp']['output']>;
  new_MaxPosts?: Maybe<Scalars['Int']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['numeric']['output']>;
  new_OfflineSince?: Maybe<Scalars['timestamp']['output']>;
  new_Platform?: Maybe<Scalars['String']['output']>;
  new_Powerdomain?: Maybe<Scalars['float8']['output']>;
  new_TitleHome?: Maybe<Scalars['String']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Int']['output']>;
  new_WebsiteContactOwner?: Maybe<Scalars['uuid']['output']>;
  new_WebsiteIP?: Maybe<Scalars['String']['output']>;
  new_adminuser?: Maybe<Scalars['String']['output']>;
  new_baseurl?: Maybe<Scalars['String']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Int']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_trustdom?: Maybe<Scalars['Int']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['Int']['output']>;
  powerTrustDom?: Maybe<Scalars['Int']['output']>;
  referring_domains_total?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Int']['output']>;
  traffic?: Maybe<Scalars['Int']['output']>;
  url_rating?: Maybe<Scalars['Int']['output']>;
  website_owner_id?: Maybe<Scalars['Int']['output']>;
  website_quality_score_id?: Maybe<Scalars['Int']['output']>;
  website_topic_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.Website" */
export type v1_Website_min_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  /** Depricated. Pls use domain_creation_date. */
  DomainAge?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  ahrefs_last_updated_on?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  crm_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  domain_creation_date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  ip_adress?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  lrt_last_updated_on?: InputMaybe<order_by>;
  new_AccessCheck?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_AccessNotes?: InputMaybe<order_by>;
  new_AdminPassword?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_LastSeoCheck?: InputMaybe<order_by>;
  new_LastUptimeCheck?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_OfflineSince?: InputMaybe<order_by>;
  new_Platform?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_TitleHome?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_WebsiteContactOwner?: InputMaybe<order_by>;
  new_WebsiteIP?: InputMaybe<order_by>;
  new_adminuser?: InputMaybe<order_by>;
  new_baseurl?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_language?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.Website" */
export type v1_Website_mutation_response = {
  __typename?: 'v1_Website_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_Website>;
};

/** input type for inserting object relation for remote table "v1.Website" */
export type v1_Website_obj_rel_insert_input = {
  data: v1_Website_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_Website_on_conflict>;
};

/** on_conflict condition type for table "v1.Website" */
export type v1_Website_on_conflict = {
  constraint: v1_Website_constraint;
  update_columns?: Array<v1_Website_update_column>;
  where?: InputMaybe<v1_Website_bool_exp>;
};

/** Ordering options when selecting data from "v1.Website". */
export type v1_Website_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AllSitePosts?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  ApprovedPosts?: InputMaybe<order_by>;
  BlogProbability?: InputMaybe<v1_WebsiteBlogProbability_order_by>;
  ContentIndicator?: InputMaybe<v1_WebsiteContentIndicator_order_by>;
  ContentTypes_aggregate?: InputMaybe<v1_ContentTypes_aggregate_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  DomainAge?: InputMaybe<order_by>;
  InProgressPosts?: InputMaybe<order_by>;
  Language?: InputMaybe<v1_new_languagesBase_order_by>;
  LivePosts?: InputMaybe<order_by>;
  LostPosts?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  Pages_aggregate?: InputMaybe<v1_Page_aggregate_order_by>;
  PostNiche?: InputMaybe<v1_PostNiche_order_by>;
  PostedPosts?: InputMaybe<order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  UptimeStatus?: InputMaybe<v1_WebsiteUptimeStatus_order_by>;
  WebsiteDToxRisk?: InputMaybe<v1_WebsiteDToxRisk_order_by>;
  WebsiteOwner?: InputMaybe<v1_ContactBase_order_by>;
  WebsiteQualityScore?: InputMaybe<v1_WebsiteQualityScore_order_by>;
  WebsiteStatus?: InputMaybe<v1_WebsiteStatus_order_by>;
  WebsiteTopic?: InputMaybe<v1_WebsiteTopics_order_by>;
  Website_Platform?: InputMaybe<v1_Website_Platform_order_by>;
  WebsitesToTopics_aggregate?: InputMaybe<v1_WebsitesToTopics_aggregate_order_by>;
  ahrefs_last_updated_on?: InputMaybe<order_by>;
  comp_domain_age?: InputMaybe<order_by>;
  comp_ld_rd_ratio?: InputMaybe<order_by>;
  comp_related_offers?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  crm_id?: InputMaybe<order_by>;
  description?: InputMaybe<order_by>;
  domain_creation_date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  ip_adress?: InputMaybe<order_by>;
  is_gambling?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  lrt_last_updated_on?: InputMaybe<order_by>;
  new_AccessCheck?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_AccessNotes?: InputMaybe<order_by>;
  new_AdminPassword?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DoFollowLinks?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_LastSeoCheck?: InputMaybe<order_by>;
  new_LastUptimeCheck?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_OfflineSince?: InputMaybe<order_by>;
  new_Platform?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_SubDomain?: InputMaybe<order_by>;
  new_TitleHome?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_WebsiteContactOwner?: InputMaybe<order_by>;
  new_WebsiteIP?: InputMaybe<order_by>;
  new_adminuser?: InputMaybe<order_by>;
  new_baseurl?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_language?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.Website */
export type v1_Website_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.Website" */
export enum v1_Website_select_column {
  /** column name */
  AhrefsDR = 'AhrefsDR',
  /** column name */
  AhrefsRL = 'AhrefsRL',
  /** column name */
  AmountOfIndexedKeywords = 'AmountOfIndexedKeywords',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DomainAge = 'DomainAge',
  /** column name */
  MOZSpamScore = 'MOZSpamScore',
  /** column name */
  NativeLink = 'NativeLink',
  /** column name */
  OrganicTraffic = 'OrganicTraffic',
  /** column name */
  OutgoingDomains = 'OutgoingDomains',
  /** column name */
  PublishingSpeed = 'PublishingSpeed',
  /** column name */
  ReferringDomains = 'ReferringDomains',
  /** column name */
  Reset = 'Reset',
  /** column name */
  SemrushAS = 'SemrushAS',
  /** column name */
  Sichtbarkeitsindex = 'Sichtbarkeitsindex',
  /** column name */
  ahrefs_last_updated_on = 'ahrefs_last_updated_on',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  crm_id = 'crm_id',
  /** column name */
  description = 'description',
  /** column name */
  domain_creation_date = 'domain_creation_date',
  /** column name */
  id = 'id',
  /** column name */
  ip_adress = 'ip_adress',
  /** column name */
  is_gambling = 'is_gambling',
  /** column name */
  keywords = 'keywords',
  /** column name */
  language_id = 'language_id',
  /** column name */
  linked_domains = 'linked_domains',
  /** column name */
  lrt_last_updated_on = 'lrt_last_updated_on',
  /** column name */
  new_AccessCheck = 'new_AccessCheck',
  /** column name */
  new_AccessCheckFailCounter = 'new_AccessCheckFailCounter',
  /** column name */
  new_AccessNotes = 'new_AccessNotes',
  /** column name */
  new_AdminPassword = 'new_AdminPassword',
  /** column name */
  new_BlogProbability = 'new_BlogProbability',
  /** column name */
  new_ContentIndicator = 'new_ContentIndicator',
  /** column name */
  new_DoFollowLinks = 'new_DoFollowLinks',
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_GoogleIndexedPages = 'new_GoogleIndexedPages',
  /** column name */
  new_LastSeoCheck = 'new_LastSeoCheck',
  /** column name */
  new_LastUptimeCheck = 'new_LastUptimeCheck',
  /** column name */
  new_MaxPosts = 'new_MaxPosts',
  /** column name */
  new_MozDomainAuthority = 'new_MozDomainAuthority',
  /** column name */
  new_OfflineSince = 'new_OfflineSince',
  /** column name */
  new_Platform = 'new_Platform',
  /** column name */
  new_Powerdomain = 'new_Powerdomain',
  /** column name */
  new_SubDomain = 'new_SubDomain',
  /** column name */
  new_TitleHome = 'new_TitleHome',
  /** column name */
  new_UptimeStatus = 'new_UptimeStatus',
  /** column name */
  new_WebsiteContactOwner = 'new_WebsiteContactOwner',
  /** column name */
  new_WebsiteIP = 'new_WebsiteIP',
  /** column name */
  new_adminuser = 'new_adminuser',
  /** column name */
  new_baseurl = 'new_baseurl',
  /** column name */
  new_dtoxrisk = 'new_dtoxrisk',
  /** column name */
  new_language = 'new_language',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_trustdom = 'new_trustdom',
  /** column name */
  niche_id = 'niche_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  powerTrustDom = 'powerTrustDom',
  /** column name */
  referring_domains_total = 'referring_domains_total',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  totalTrafficHREFS = 'totalTrafficHREFS',
  /** column name */
  traffic = 'traffic',
  /** column name */
  url_rating = 'url_rating',
  /** column name */
  website_owner_id = 'website_owner_id',
  /** column name */
  website_quality_score_id = 'website_quality_score_id',
  /** column name */
  website_topic_id = 'website_topic_id'
}

/** select "v1_Website_aggregate_bool_exp_avg_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_avg_arguments_columns {
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_Powerdomain = 'new_Powerdomain'
}

/** select "v1_Website_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  is_gambling = 'is_gambling',
  /** column name */
  new_DoFollowLinks = 'new_DoFollowLinks',
  /** column name */
  new_SubDomain = 'new_SubDomain'
}

/** select "v1_Website_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  is_gambling = 'is_gambling',
  /** column name */
  new_DoFollowLinks = 'new_DoFollowLinks',
  /** column name */
  new_SubDomain = 'new_SubDomain'
}

/** select "v1_Website_aggregate_bool_exp_corr_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_corr_arguments_columns {
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_Powerdomain = 'new_Powerdomain'
}

/** select "v1_Website_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_covar_samp_arguments_columns {
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_Powerdomain = 'new_Powerdomain'
}

/** select "v1_Website_aggregate_bool_exp_max_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_max_arguments_columns {
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_Powerdomain = 'new_Powerdomain'
}

/** select "v1_Website_aggregate_bool_exp_min_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_min_arguments_columns {
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_Powerdomain = 'new_Powerdomain'
}

/** select "v1_Website_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_stddev_samp_arguments_columns {
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_Powerdomain = 'new_Powerdomain'
}

/** select "v1_Website_aggregate_bool_exp_sum_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_sum_arguments_columns {
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_Powerdomain = 'new_Powerdomain'
}

/** select "v1_Website_aggregate_bool_exp_var_samp_arguments_columns" columns of table "v1.Website" */
export enum v1_Website_select_column_v1_Website_aggregate_bool_exp_var_samp_arguments_columns {
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_Powerdomain = 'new_Powerdomain'
}

/** input type for updating data in table "v1.Website" */
export type v1_Website_set_input = {
  AhrefsDR?: InputMaybe<Scalars['numeric']['input']>;
  AhrefsRL?: InputMaybe<Scalars['Int']['input']>;
  AmountOfIndexedKeywords?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  /** Depricated. Pls use domain_creation_date. */
  DomainAge?: InputMaybe<Scalars['date']['input']>;
  MOZSpamScore?: InputMaybe<Scalars['numeric']['input']>;
  NativeLink?: InputMaybe<Scalars['Int']['input']>;
  OrganicTraffic?: InputMaybe<Scalars['Int']['input']>;
  OutgoingDomains?: InputMaybe<Scalars['numeric']['input']>;
  PublishingSpeed?: InputMaybe<Scalars['Int']['input']>;
  ReferringDomains?: InputMaybe<Scalars['numeric']['input']>;
  Reset?: InputMaybe<Scalars['Int']['input']>;
  SemrushAS?: InputMaybe<Scalars['numeric']['input']>;
  Sichtbarkeitsindex?: InputMaybe<Scalars['numeric']['input']>;
  ahrefs_last_updated_on?: InputMaybe<Scalars['date']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  crm_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain_creation_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ip_adress?: InputMaybe<Scalars['String']['input']>;
  is_gambling?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  linked_domains?: InputMaybe<Scalars['Int']['input']>;
  lrt_last_updated_on?: InputMaybe<Scalars['date']['input']>;
  new_AccessCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_AccessCheckFailCounter?: InputMaybe<Scalars['numeric']['input']>;
  new_AccessNotes?: InputMaybe<Scalars['String']['input']>;
  new_AdminPassword?: InputMaybe<Scalars['String']['input']>;
  new_BlogProbability?: InputMaybe<Scalars['Int']['input']>;
  new_ContentIndicator?: InputMaybe<Scalars['Int']['input']>;
  new_DoFollowLinks?: InputMaybe<Scalars['Boolean']['input']>;
  new_DomainRatingMOZ?: InputMaybe<Scalars['float8']['input']>;
  new_DropRate?: InputMaybe<Scalars['float8']['input']>;
  new_GoogleIndexedPages?: InputMaybe<Scalars['Int']['input']>;
  new_LastSeoCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_LastUptimeCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_MaxPosts?: InputMaybe<Scalars['Int']['input']>;
  new_MozDomainAuthority?: InputMaybe<Scalars['numeric']['input']>;
  new_OfflineSince?: InputMaybe<Scalars['timestamp']['input']>;
  new_Platform?: InputMaybe<Scalars['String']['input']>;
  new_Powerdomain?: InputMaybe<Scalars['float8']['input']>;
  new_SubDomain?: InputMaybe<Scalars['Boolean']['input']>;
  new_TitleHome?: InputMaybe<Scalars['String']['input']>;
  new_UptimeStatus?: InputMaybe<Scalars['Int']['input']>;
  new_WebsiteContactOwner?: InputMaybe<Scalars['uuid']['input']>;
  new_WebsiteIP?: InputMaybe<Scalars['String']['input']>;
  new_adminuser?: InputMaybe<Scalars['String']['input']>;
  new_baseurl?: InputMaybe<Scalars['String']['input']>;
  new_dtoxrisk?: InputMaybe<Scalars['Int']['input']>;
  new_language?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_trustdom?: InputMaybe<Scalars['Int']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['Int']['input']>;
  powerTrustDom?: InputMaybe<Scalars['Int']['input']>;
  referring_domains_total?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  totalTrafficHREFS?: InputMaybe<Scalars['Int']['input']>;
  traffic?: InputMaybe<Scalars['Int']['input']>;
  url_rating?: InputMaybe<Scalars['Int']['input']>;
  website_owner_id?: InputMaybe<Scalars['Int']['input']>;
  website_quality_score_id?: InputMaybe<Scalars['Int']['input']>;
  website_topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_Website_stddev_fields = {
  __typename?: 'v1_Website_stddev_fields';
  AhrefsDR?: Maybe<Scalars['Float']['output']>;
  AhrefsRL?: Maybe<Scalars['Float']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Float']['output']>;
  MOZSpamScore?: Maybe<Scalars['Float']['output']>;
  NativeLink?: Maybe<Scalars['Float']['output']>;
  OrganicTraffic?: Maybe<Scalars['Float']['output']>;
  OutgoingDomains?: Maybe<Scalars['Float']['output']>;
  PublishingSpeed?: Maybe<Scalars['Float']['output']>;
  ReferringDomains?: Maybe<Scalars['Float']['output']>;
  Reset?: Maybe<Scalars['Float']['output']>;
  SemrushAS?: Maybe<Scalars['Float']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  keywords?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  linked_domains?: Maybe<Scalars['Float']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['Float']['output']>;
  new_BlogProbability?: Maybe<Scalars['Float']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Float']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['Float']['output']>;
  new_DropRate?: Maybe<Scalars['Float']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Float']['output']>;
  new_MaxPosts?: Maybe<Scalars['Float']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['Float']['output']>;
  new_Powerdomain?: Maybe<Scalars['Float']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Float']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Float']['output']>;
  new_trustdom?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  platform_id?: Maybe<Scalars['Float']['output']>;
  powerTrustDom?: Maybe<Scalars['Float']['output']>;
  referring_domains_total?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Float']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  url_rating?: Maybe<Scalars['Float']['output']>;
  website_owner_id?: Maybe<Scalars['Float']['output']>;
  website_quality_score_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.Website" */
export type v1_Website_stddev_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_Website_stddev_pop_fields = {
  __typename?: 'v1_Website_stddev_pop_fields';
  AhrefsDR?: Maybe<Scalars['Float']['output']>;
  AhrefsRL?: Maybe<Scalars['Float']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Float']['output']>;
  MOZSpamScore?: Maybe<Scalars['Float']['output']>;
  NativeLink?: Maybe<Scalars['Float']['output']>;
  OrganicTraffic?: Maybe<Scalars['Float']['output']>;
  OutgoingDomains?: Maybe<Scalars['Float']['output']>;
  PublishingSpeed?: Maybe<Scalars['Float']['output']>;
  ReferringDomains?: Maybe<Scalars['Float']['output']>;
  Reset?: Maybe<Scalars['Float']['output']>;
  SemrushAS?: Maybe<Scalars['Float']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  keywords?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  linked_domains?: Maybe<Scalars['Float']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['Float']['output']>;
  new_BlogProbability?: Maybe<Scalars['Float']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Float']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['Float']['output']>;
  new_DropRate?: Maybe<Scalars['Float']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Float']['output']>;
  new_MaxPosts?: Maybe<Scalars['Float']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['Float']['output']>;
  new_Powerdomain?: Maybe<Scalars['Float']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Float']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Float']['output']>;
  new_trustdom?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  platform_id?: Maybe<Scalars['Float']['output']>;
  powerTrustDom?: Maybe<Scalars['Float']['output']>;
  referring_domains_total?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Float']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  url_rating?: Maybe<Scalars['Float']['output']>;
  website_owner_id?: Maybe<Scalars['Float']['output']>;
  website_quality_score_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.Website" */
export type v1_Website_stddev_pop_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_Website_stddev_samp_fields = {
  __typename?: 'v1_Website_stddev_samp_fields';
  AhrefsDR?: Maybe<Scalars['Float']['output']>;
  AhrefsRL?: Maybe<Scalars['Float']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Float']['output']>;
  MOZSpamScore?: Maybe<Scalars['Float']['output']>;
  NativeLink?: Maybe<Scalars['Float']['output']>;
  OrganicTraffic?: Maybe<Scalars['Float']['output']>;
  OutgoingDomains?: Maybe<Scalars['Float']['output']>;
  PublishingSpeed?: Maybe<Scalars['Float']['output']>;
  ReferringDomains?: Maybe<Scalars['Float']['output']>;
  Reset?: Maybe<Scalars['Float']['output']>;
  SemrushAS?: Maybe<Scalars['Float']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  keywords?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  linked_domains?: Maybe<Scalars['Float']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['Float']['output']>;
  new_BlogProbability?: Maybe<Scalars['Float']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Float']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['Float']['output']>;
  new_DropRate?: Maybe<Scalars['Float']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Float']['output']>;
  new_MaxPosts?: Maybe<Scalars['Float']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['Float']['output']>;
  new_Powerdomain?: Maybe<Scalars['Float']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Float']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Float']['output']>;
  new_trustdom?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  platform_id?: Maybe<Scalars['Float']['output']>;
  powerTrustDom?: Maybe<Scalars['Float']['output']>;
  referring_domains_total?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Float']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  url_rating?: Maybe<Scalars['Float']['output']>;
  website_owner_id?: Maybe<Scalars['Float']['output']>;
  website_quality_score_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.Website" */
export type v1_Website_stddev_samp_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_Website" */
export type v1_Website_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Website_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Website_stream_cursor_value_input = {
  AhrefsDR?: InputMaybe<Scalars['numeric']['input']>;
  AhrefsRL?: InputMaybe<Scalars['Int']['input']>;
  AmountOfIndexedKeywords?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  /** Depricated. Pls use domain_creation_date. */
  DomainAge?: InputMaybe<Scalars['date']['input']>;
  MOZSpamScore?: InputMaybe<Scalars['numeric']['input']>;
  NativeLink?: InputMaybe<Scalars['Int']['input']>;
  OrganicTraffic?: InputMaybe<Scalars['Int']['input']>;
  OutgoingDomains?: InputMaybe<Scalars['numeric']['input']>;
  PublishingSpeed?: InputMaybe<Scalars['Int']['input']>;
  ReferringDomains?: InputMaybe<Scalars['numeric']['input']>;
  Reset?: InputMaybe<Scalars['Int']['input']>;
  SemrushAS?: InputMaybe<Scalars['numeric']['input']>;
  Sichtbarkeitsindex?: InputMaybe<Scalars['numeric']['input']>;
  ahrefs_last_updated_on?: InputMaybe<Scalars['date']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  crm_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain_creation_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ip_adress?: InputMaybe<Scalars['String']['input']>;
  is_gambling?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  linked_domains?: InputMaybe<Scalars['Int']['input']>;
  lrt_last_updated_on?: InputMaybe<Scalars['date']['input']>;
  new_AccessCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_AccessCheckFailCounter?: InputMaybe<Scalars['numeric']['input']>;
  new_AccessNotes?: InputMaybe<Scalars['String']['input']>;
  new_AdminPassword?: InputMaybe<Scalars['String']['input']>;
  new_BlogProbability?: InputMaybe<Scalars['Int']['input']>;
  new_ContentIndicator?: InputMaybe<Scalars['Int']['input']>;
  new_DoFollowLinks?: InputMaybe<Scalars['Boolean']['input']>;
  new_DomainRatingMOZ?: InputMaybe<Scalars['float8']['input']>;
  new_DropRate?: InputMaybe<Scalars['float8']['input']>;
  new_GoogleIndexedPages?: InputMaybe<Scalars['Int']['input']>;
  new_LastSeoCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_LastUptimeCheck?: InputMaybe<Scalars['timestamp']['input']>;
  new_MaxPosts?: InputMaybe<Scalars['Int']['input']>;
  new_MozDomainAuthority?: InputMaybe<Scalars['numeric']['input']>;
  new_OfflineSince?: InputMaybe<Scalars['timestamp']['input']>;
  new_Platform?: InputMaybe<Scalars['String']['input']>;
  new_Powerdomain?: InputMaybe<Scalars['float8']['input']>;
  new_SubDomain?: InputMaybe<Scalars['Boolean']['input']>;
  new_TitleHome?: InputMaybe<Scalars['String']['input']>;
  new_UptimeStatus?: InputMaybe<Scalars['Int']['input']>;
  new_WebsiteContactOwner?: InputMaybe<Scalars['uuid']['input']>;
  new_WebsiteIP?: InputMaybe<Scalars['String']['input']>;
  new_adminuser?: InputMaybe<Scalars['String']['input']>;
  new_baseurl?: InputMaybe<Scalars['String']['input']>;
  new_dtoxrisk?: InputMaybe<Scalars['Int']['input']>;
  new_language?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_trustdom?: InputMaybe<Scalars['Int']['input']>;
  niche_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['Int']['input']>;
  powerTrustDom?: InputMaybe<Scalars['Int']['input']>;
  referring_domains_total?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  totalTrafficHREFS?: InputMaybe<Scalars['Int']['input']>;
  traffic?: InputMaybe<Scalars['Int']['input']>;
  url_rating?: InputMaybe<Scalars['Int']['input']>;
  website_owner_id?: InputMaybe<Scalars['Int']['input']>;
  website_quality_score_id?: InputMaybe<Scalars['Int']['input']>;
  website_topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_Website_sum_fields = {
  __typename?: 'v1_Website_sum_fields';
  AhrefsDR?: Maybe<Scalars['numeric']['output']>;
  AhrefsRL?: Maybe<Scalars['Int']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Int']['output']>;
  MOZSpamScore?: Maybe<Scalars['numeric']['output']>;
  NativeLink?: Maybe<Scalars['Int']['output']>;
  OrganicTraffic?: Maybe<Scalars['Int']['output']>;
  OutgoingDomains?: Maybe<Scalars['numeric']['output']>;
  PublishingSpeed?: Maybe<Scalars['Int']['output']>;
  ReferringDomains?: Maybe<Scalars['numeric']['output']>;
  Reset?: Maybe<Scalars['Int']['output']>;
  SemrushAS?: Maybe<Scalars['numeric']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['numeric']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  keywords?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  linked_domains?: Maybe<Scalars['Int']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['numeric']['output']>;
  new_BlogProbability?: Maybe<Scalars['Int']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Int']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['float8']['output']>;
  new_DropRate?: Maybe<Scalars['float8']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Int']['output']>;
  new_MaxPosts?: Maybe<Scalars['Int']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['numeric']['output']>;
  new_Powerdomain?: Maybe<Scalars['float8']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Int']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Int']['output']>;
  new_trustdom?: Maybe<Scalars['Int']['output']>;
  niche_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['Int']['output']>;
  powerTrustDom?: Maybe<Scalars['Int']['output']>;
  referring_domains_total?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Int']['output']>;
  traffic?: Maybe<Scalars['Int']['output']>;
  url_rating?: Maybe<Scalars['Int']['output']>;
  website_owner_id?: Maybe<Scalars['Int']['output']>;
  website_quality_score_id?: Maybe<Scalars['Int']['output']>;
  website_topic_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.Website" */
export type v1_Website_sum_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.Website" */
export enum v1_Website_update_column {
  /** column name */
  AhrefsDR = 'AhrefsDR',
  /** column name */
  AhrefsRL = 'AhrefsRL',
  /** column name */
  AmountOfIndexedKeywords = 'AmountOfIndexedKeywords',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DomainAge = 'DomainAge',
  /** column name */
  MOZSpamScore = 'MOZSpamScore',
  /** column name */
  NativeLink = 'NativeLink',
  /** column name */
  OrganicTraffic = 'OrganicTraffic',
  /** column name */
  OutgoingDomains = 'OutgoingDomains',
  /** column name */
  PublishingSpeed = 'PublishingSpeed',
  /** column name */
  ReferringDomains = 'ReferringDomains',
  /** column name */
  Reset = 'Reset',
  /** column name */
  SemrushAS = 'SemrushAS',
  /** column name */
  Sichtbarkeitsindex = 'Sichtbarkeitsindex',
  /** column name */
  ahrefs_last_updated_on = 'ahrefs_last_updated_on',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  crm_id = 'crm_id',
  /** column name */
  description = 'description',
  /** column name */
  domain_creation_date = 'domain_creation_date',
  /** column name */
  id = 'id',
  /** column name */
  ip_adress = 'ip_adress',
  /** column name */
  is_gambling = 'is_gambling',
  /** column name */
  keywords = 'keywords',
  /** column name */
  language_id = 'language_id',
  /** column name */
  linked_domains = 'linked_domains',
  /** column name */
  lrt_last_updated_on = 'lrt_last_updated_on',
  /** column name */
  new_AccessCheck = 'new_AccessCheck',
  /** column name */
  new_AccessCheckFailCounter = 'new_AccessCheckFailCounter',
  /** column name */
  new_AccessNotes = 'new_AccessNotes',
  /** column name */
  new_AdminPassword = 'new_AdminPassword',
  /** column name */
  new_BlogProbability = 'new_BlogProbability',
  /** column name */
  new_ContentIndicator = 'new_ContentIndicator',
  /** column name */
  new_DoFollowLinks = 'new_DoFollowLinks',
  /** column name */
  new_DomainRatingMOZ = 'new_DomainRatingMOZ',
  /** column name */
  new_DropRate = 'new_DropRate',
  /** column name */
  new_GoogleIndexedPages = 'new_GoogleIndexedPages',
  /** column name */
  new_LastSeoCheck = 'new_LastSeoCheck',
  /** column name */
  new_LastUptimeCheck = 'new_LastUptimeCheck',
  /** column name */
  new_MaxPosts = 'new_MaxPosts',
  /** column name */
  new_MozDomainAuthority = 'new_MozDomainAuthority',
  /** column name */
  new_OfflineSince = 'new_OfflineSince',
  /** column name */
  new_Platform = 'new_Platform',
  /** column name */
  new_Powerdomain = 'new_Powerdomain',
  /** column name */
  new_SubDomain = 'new_SubDomain',
  /** column name */
  new_TitleHome = 'new_TitleHome',
  /** column name */
  new_UptimeStatus = 'new_UptimeStatus',
  /** column name */
  new_WebsiteContactOwner = 'new_WebsiteContactOwner',
  /** column name */
  new_WebsiteIP = 'new_WebsiteIP',
  /** column name */
  new_adminuser = 'new_adminuser',
  /** column name */
  new_baseurl = 'new_baseurl',
  /** column name */
  new_dtoxrisk = 'new_dtoxrisk',
  /** column name */
  new_language = 'new_language',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_trustdom = 'new_trustdom',
  /** column name */
  niche_id = 'niche_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  powerTrustDom = 'powerTrustDom',
  /** column name */
  referring_domains_total = 'referring_domains_total',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  totalTrafficHREFS = 'totalTrafficHREFS',
  /** column name */
  traffic = 'traffic',
  /** column name */
  url_rating = 'url_rating',
  /** column name */
  website_owner_id = 'website_owner_id',
  /** column name */
  website_quality_score_id = 'website_quality_score_id',
  /** column name */
  website_topic_id = 'website_topic_id'
}

export type v1_Website_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_Website_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_Website_set_input>;
  /** filter the rows which have to be updated */
  where: v1_Website_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_Website_var_pop_fields = {
  __typename?: 'v1_Website_var_pop_fields';
  AhrefsDR?: Maybe<Scalars['Float']['output']>;
  AhrefsRL?: Maybe<Scalars['Float']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Float']['output']>;
  MOZSpamScore?: Maybe<Scalars['Float']['output']>;
  NativeLink?: Maybe<Scalars['Float']['output']>;
  OrganicTraffic?: Maybe<Scalars['Float']['output']>;
  OutgoingDomains?: Maybe<Scalars['Float']['output']>;
  PublishingSpeed?: Maybe<Scalars['Float']['output']>;
  ReferringDomains?: Maybe<Scalars['Float']['output']>;
  Reset?: Maybe<Scalars['Float']['output']>;
  SemrushAS?: Maybe<Scalars['Float']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  keywords?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  linked_domains?: Maybe<Scalars['Float']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['Float']['output']>;
  new_BlogProbability?: Maybe<Scalars['Float']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Float']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['Float']['output']>;
  new_DropRate?: Maybe<Scalars['Float']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Float']['output']>;
  new_MaxPosts?: Maybe<Scalars['Float']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['Float']['output']>;
  new_Powerdomain?: Maybe<Scalars['Float']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Float']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Float']['output']>;
  new_trustdom?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  platform_id?: Maybe<Scalars['Float']['output']>;
  powerTrustDom?: Maybe<Scalars['Float']['output']>;
  referring_domains_total?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Float']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  url_rating?: Maybe<Scalars['Float']['output']>;
  website_owner_id?: Maybe<Scalars['Float']['output']>;
  website_quality_score_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.Website" */
export type v1_Website_var_pop_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_Website_var_samp_fields = {
  __typename?: 'v1_Website_var_samp_fields';
  AhrefsDR?: Maybe<Scalars['Float']['output']>;
  AhrefsRL?: Maybe<Scalars['Float']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Float']['output']>;
  MOZSpamScore?: Maybe<Scalars['Float']['output']>;
  NativeLink?: Maybe<Scalars['Float']['output']>;
  OrganicTraffic?: Maybe<Scalars['Float']['output']>;
  OutgoingDomains?: Maybe<Scalars['Float']['output']>;
  PublishingSpeed?: Maybe<Scalars['Float']['output']>;
  ReferringDomains?: Maybe<Scalars['Float']['output']>;
  Reset?: Maybe<Scalars['Float']['output']>;
  SemrushAS?: Maybe<Scalars['Float']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  keywords?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  linked_domains?: Maybe<Scalars['Float']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['Float']['output']>;
  new_BlogProbability?: Maybe<Scalars['Float']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Float']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['Float']['output']>;
  new_DropRate?: Maybe<Scalars['Float']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Float']['output']>;
  new_MaxPosts?: Maybe<Scalars['Float']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['Float']['output']>;
  new_Powerdomain?: Maybe<Scalars['Float']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Float']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Float']['output']>;
  new_trustdom?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  platform_id?: Maybe<Scalars['Float']['output']>;
  powerTrustDom?: Maybe<Scalars['Float']['output']>;
  referring_domains_total?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Float']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  url_rating?: Maybe<Scalars['Float']['output']>;
  website_owner_id?: Maybe<Scalars['Float']['output']>;
  website_quality_score_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.Website" */
export type v1_Website_var_samp_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_Website_variance_fields = {
  __typename?: 'v1_Website_variance_fields';
  AhrefsDR?: Maybe<Scalars['Float']['output']>;
  AhrefsRL?: Maybe<Scalars['Float']['output']>;
  AmountOfIndexedKeywords?: Maybe<Scalars['Float']['output']>;
  MOZSpamScore?: Maybe<Scalars['Float']['output']>;
  NativeLink?: Maybe<Scalars['Float']['output']>;
  OrganicTraffic?: Maybe<Scalars['Float']['output']>;
  OutgoingDomains?: Maybe<Scalars['Float']['output']>;
  PublishingSpeed?: Maybe<Scalars['Float']['output']>;
  ReferringDomains?: Maybe<Scalars['Float']['output']>;
  Reset?: Maybe<Scalars['Float']['output']>;
  SemrushAS?: Maybe<Scalars['Float']['output']>;
  Sichtbarkeitsindex?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  keywords?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  linked_domains?: Maybe<Scalars['Float']['output']>;
  new_AccessCheckFailCounter?: Maybe<Scalars['Float']['output']>;
  new_BlogProbability?: Maybe<Scalars['Float']['output']>;
  new_ContentIndicator?: Maybe<Scalars['Float']['output']>;
  new_DomainRatingMOZ?: Maybe<Scalars['Float']['output']>;
  new_DropRate?: Maybe<Scalars['Float']['output']>;
  new_GoogleIndexedPages?: Maybe<Scalars['Float']['output']>;
  new_MaxPosts?: Maybe<Scalars['Float']['output']>;
  new_MozDomainAuthority?: Maybe<Scalars['Float']['output']>;
  new_Powerdomain?: Maybe<Scalars['Float']['output']>;
  new_UptimeStatus?: Maybe<Scalars['Float']['output']>;
  new_dtoxrisk?: Maybe<Scalars['Float']['output']>;
  new_trustdom?: Maybe<Scalars['Float']['output']>;
  niche_id?: Maybe<Scalars['Float']['output']>;
  platform_id?: Maybe<Scalars['Float']['output']>;
  powerTrustDom?: Maybe<Scalars['Float']['output']>;
  referring_domains_total?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  totalTrafficHREFS?: Maybe<Scalars['Float']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  url_rating?: Maybe<Scalars['Float']['output']>;
  website_owner_id?: Maybe<Scalars['Float']['output']>;
  website_quality_score_id?: Maybe<Scalars['Float']['output']>;
  website_topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.Website" */
export type v1_Website_variance_order_by = {
  AhrefsDR?: InputMaybe<order_by>;
  AhrefsRL?: InputMaybe<order_by>;
  AmountOfIndexedKeywords?: InputMaybe<order_by>;
  MOZSpamScore?: InputMaybe<order_by>;
  NativeLink?: InputMaybe<order_by>;
  OrganicTraffic?: InputMaybe<order_by>;
  OutgoingDomains?: InputMaybe<order_by>;
  PublishingSpeed?: InputMaybe<order_by>;
  ReferringDomains?: InputMaybe<order_by>;
  Reset?: InputMaybe<order_by>;
  SemrushAS?: InputMaybe<order_by>;
  Sichtbarkeitsindex?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  keywords?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  linked_domains?: InputMaybe<order_by>;
  new_AccessCheckFailCounter?: InputMaybe<order_by>;
  new_BlogProbability?: InputMaybe<order_by>;
  new_ContentIndicator?: InputMaybe<order_by>;
  new_DomainRatingMOZ?: InputMaybe<order_by>;
  new_DropRate?: InputMaybe<order_by>;
  new_GoogleIndexedPages?: InputMaybe<order_by>;
  new_MaxPosts?: InputMaybe<order_by>;
  new_MozDomainAuthority?: InputMaybe<order_by>;
  new_Powerdomain?: InputMaybe<order_by>;
  new_UptimeStatus?: InputMaybe<order_by>;
  new_dtoxrisk?: InputMaybe<order_by>;
  new_trustdom?: InputMaybe<order_by>;
  niche_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  powerTrustDom?: InputMaybe<order_by>;
  referring_domains_total?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  totalTrafficHREFS?: InputMaybe<order_by>;
  traffic?: InputMaybe<order_by>;
  url_rating?: InputMaybe<order_by>;
  website_owner_id?: InputMaybe<order_by>;
  website_quality_score_id?: InputMaybe<order_by>;
  website_topic_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics = {
  __typename?: 'v1_WebsitesToTopics';
  /** An object relationship */
  Website: v1_Website;
  /** An object relationship */
  WebsiteTopic: v1_WebsiteTopics;
  id: Scalars['Int']['output'];
  topic_id: Scalars['Int']['output'];
  website_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_aggregate = {
  __typename?: 'v1_WebsitesToTopics_aggregate';
  aggregate?: Maybe<v1_WebsitesToTopics_aggregate_fields>;
  nodes: Array<v1_WebsitesToTopics>;
};

export type v1_WebsitesToTopics_aggregate_bool_exp = {
  count?: InputMaybe<v1_WebsitesToTopics_aggregate_bool_exp_count>;
};

export type v1_WebsitesToTopics_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_aggregate_fields = {
  __typename?: 'v1_WebsitesToTopics_aggregate_fields';
  avg?: Maybe<v1_WebsitesToTopics_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_WebsitesToTopics_max_fields>;
  min?: Maybe<v1_WebsitesToTopics_min_fields>;
  stddev?: Maybe<v1_WebsitesToTopics_stddev_fields>;
  stddev_pop?: Maybe<v1_WebsitesToTopics_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_WebsitesToTopics_stddev_samp_fields>;
  sum?: Maybe<v1_WebsitesToTopics_sum_fields>;
  var_pop?: Maybe<v1_WebsitesToTopics_var_pop_fields>;
  var_samp?: Maybe<v1_WebsitesToTopics_var_samp_fields>;
  variance?: Maybe<v1_WebsitesToTopics_variance_fields>;
};


/** aggregate fields of "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_WebsitesToTopics_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_aggregate_order_by = {
  avg?: InputMaybe<v1_WebsitesToTopics_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_WebsitesToTopics_max_order_by>;
  min?: InputMaybe<v1_WebsitesToTopics_min_order_by>;
  stddev?: InputMaybe<v1_WebsitesToTopics_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_WebsitesToTopics_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_WebsitesToTopics_stddev_samp_order_by>;
  sum?: InputMaybe<v1_WebsitesToTopics_sum_order_by>;
  var_pop?: InputMaybe<v1_WebsitesToTopics_var_pop_order_by>;
  var_samp?: InputMaybe<v1_WebsitesToTopics_var_samp_order_by>;
  variance?: InputMaybe<v1_WebsitesToTopics_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_arr_rel_insert_input = {
  data: Array<v1_WebsitesToTopics_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_WebsitesToTopics_on_conflict>;
};

/** aggregate avg on columns */
export type v1_WebsitesToTopics_avg_fields = {
  __typename?: 'v1_WebsitesToTopics_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_avg_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.WebsitesToTopics". All fields are combined with a logical 'AND'. */
export type v1_WebsitesToTopics_bool_exp = {
  Website?: InputMaybe<v1_Website_bool_exp>;
  WebsiteTopic?: InputMaybe<v1_WebsiteTopics_bool_exp>;
  _and?: InputMaybe<Array<v1_WebsitesToTopics_bool_exp>>;
  _not?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
  _or?: InputMaybe<Array<v1_WebsitesToTopics_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  topic_id?: InputMaybe<Int_comparison_exp>;
  website_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.WebsitesToTopics" */
export enum v1_WebsitesToTopics_constraint {
  /** unique or primary key constraint on columns "id" */
  WebsitesToTopics_pkey = 'WebsitesToTopics_pkey'
}

/** input type for incrementing numeric columns in table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_insert_input = {
  Website?: InputMaybe<v1_Website_obj_rel_insert_input>;
  WebsiteTopic?: InputMaybe<v1_WebsiteTopics_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_WebsitesToTopics_max_fields = {
  __typename?: 'v1_WebsitesToTopics_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_max_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_WebsitesToTopics_min_fields = {
  __typename?: 'v1_WebsitesToTopics_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_min_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_mutation_response = {
  __typename?: 'v1_WebsitesToTopics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_WebsitesToTopics>;
};

/** on_conflict condition type for table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_on_conflict = {
  constraint: v1_WebsitesToTopics_constraint;
  update_columns?: Array<v1_WebsitesToTopics_update_column>;
  where?: InputMaybe<v1_WebsitesToTopics_bool_exp>;
};

/** Ordering options when selecting data from "v1.WebsitesToTopics". */
export type v1_WebsitesToTopics_order_by = {
  Website?: InputMaybe<v1_Website_order_by>;
  WebsiteTopic?: InputMaybe<v1_WebsiteTopics_order_by>;
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.WebsitesToTopics */
export type v1_WebsitesToTopics_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.WebsitesToTopics" */
export enum v1_WebsitesToTopics_select_column {
  /** column name */
  id = 'id',
  /** column name */
  topic_id = 'topic_id',
  /** column name */
  website_id = 'website_id'
}

/** input type for updating data in table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_WebsitesToTopics_stddev_fields = {
  __typename?: 'v1_WebsitesToTopics_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_stddev_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_WebsitesToTopics_stddev_pop_fields = {
  __typename?: 'v1_WebsitesToTopics_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_WebsitesToTopics_stddev_samp_fields = {
  __typename?: 'v1_WebsitesToTopics_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_WebsitesToTopics" */
export type v1_WebsitesToTopics_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_WebsitesToTopics_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_WebsitesToTopics_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  website_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_WebsitesToTopics_sum_fields = {
  __typename?: 'v1_WebsitesToTopics_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  website_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_sum_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.WebsitesToTopics" */
export enum v1_WebsitesToTopics_update_column {
  /** column name */
  id = 'id',
  /** column name */
  topic_id = 'topic_id',
  /** column name */
  website_id = 'website_id'
}

export type v1_WebsitesToTopics_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_WebsitesToTopics_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_WebsitesToTopics_set_input>;
  /** filter the rows which have to be updated */
  where: v1_WebsitesToTopics_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_WebsitesToTopics_var_pop_fields = {
  __typename?: 'v1_WebsitesToTopics_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_WebsitesToTopics_var_samp_fields = {
  __typename?: 'v1_WebsitesToTopics_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_WebsitesToTopics_variance_fields = {
  __typename?: 'v1_WebsitesToTopics_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  website_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.WebsitesToTopics" */
export type v1_WebsitesToTopics_variance_order_by = {
  id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
  website_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.Writers" */
export type v1_Writers = {
  __typename?: 'v1_Writers';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  allow_upload_content_portal?: Maybe<Scalars['Boolean']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.writers_inprogress_post" */
  inprogress_posts?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.writer_left_quota" */
  left_quota?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_AccountActive?: Maybe<Scalars['Boolean']['output']>;
  new_Blacklisted?: Maybe<Scalars['Boolean']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_IsCustomer?: Maybe<Scalars['Boolean']['output']>;
  new_Issupplier?: Maybe<Scalars['Boolean']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_Linksexchanger?: Maybe<Scalars['Boolean']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_Translator?: Maybe<Scalars['Boolean']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_Writer?: Maybe<Scalars['Boolean']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contacetype?: Maybe<Scalars['Boolean']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_inhouse?: Maybe<Scalars['Boolean']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  tag_ids?: Maybe<Scalars['_int4']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "v1.Writers" */
export type v1_Writers_aggregate = {
  __typename?: 'v1_Writers_aggregate';
  aggregate?: Maybe<v1_Writers_aggregate_fields>;
  nodes: Array<v1_Writers>;
};

/** aggregate fields of "v1.Writers" */
export type v1_Writers_aggregate_fields = {
  __typename?: 'v1_Writers_aggregate_fields';
  avg?: Maybe<v1_Writers_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_Writers_max_fields>;
  min?: Maybe<v1_Writers_min_fields>;
  stddev?: Maybe<v1_Writers_stddev_fields>;
  stddev_pop?: Maybe<v1_Writers_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_Writers_stddev_samp_fields>;
  sum?: Maybe<v1_Writers_sum_fields>;
  var_pop?: Maybe<v1_Writers_var_pop_fields>;
  var_samp?: Maybe<v1_Writers_var_samp_fields>;
  variance?: Maybe<v1_Writers_variance_fields>;
};


/** aggregate fields of "v1.Writers" */
export type v1_Writers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_Writers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_Writers_avg_fields = {
  __typename?: 'v1_Writers_avg_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.Writers". All fields are combined with a logical 'AND'. */
export type v1_Writers_bool_exp = {
  ActiveOffersCount?: InputMaybe<Int_comparison_exp>;
  AllPosts?: InputMaybe<Int_comparison_exp>;
  AssistantID?: InputMaybe<Int_comparison_exp>;
  Balance?: InputMaybe<numeric_comparison_exp>;
  BirthDate?: InputMaybe<timestamp_comparison_exp>;
  ContactId?: InputMaybe<uuid_comparison_exp>;
  CountryID?: InputMaybe<Int_comparison_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Description?: InputMaybe<String_comparison_exp>;
  EMailAddress1?: InputMaybe<String_comparison_exp>;
  EMailAddress2?: InputMaybe<String_comparison_exp>;
  ExchangeRate?: InputMaybe<numeric_comparison_exp>;
  FinishedOrders?: InputMaybe<Int_comparison_exp>;
  FirstName?: InputMaybe<String_comparison_exp>;
  FullName?: InputMaybe<String_comparison_exp>;
  LastName?: InputMaybe<String_comparison_exp>;
  MobilePhone?: InputMaybe<String_comparison_exp>;
  ModifiedOn?: InputMaybe<timestamp_comparison_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  SoldOffersCount?: InputMaybe<Int_comparison_exp>;
  StateCode?: InputMaybe<Int_comparison_exp>;
  StatusCode?: InputMaybe<Int_comparison_exp>;
  Telephone1?: InputMaybe<String_comparison_exp>;
  TotalOffersCount?: InputMaybe<Int_comparison_exp>;
  TotalOrdersSum?: InputMaybe<Int_comparison_exp>;
  TotalPayoutsSum?: InputMaybe<Int_comparison_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  _and?: InputMaybe<Array<v1_Writers_bool_exp>>;
  _not?: InputMaybe<v1_Writers_bool_exp>;
  _or?: InputMaybe<Array<v1_Writers_bool_exp>>;
  allow_upload_content_portal?: InputMaybe<Boolean_comparison_exp>;
  buzzstream_id?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  inprogress_posts?: InputMaybe<Int_comparison_exp>;
  language1_id?: InputMaybe<Int_comparison_exp>;
  language_id?: InputMaybe<Int_comparison_exp>;
  left_quota?: InputMaybe<Int_comparison_exp>;
  monthly_quota?: InputMaybe<numeric_comparison_exp>;
  new_AccountActive?: InputMaybe<Boolean_comparison_exp>;
  new_Blacklisted?: InputMaybe<Boolean_comparison_exp>;
  new_Contacttype?: InputMaybe<Int_comparison_exp>;
  new_CreatedByUser?: InputMaybe<String_comparison_exp>;
  new_Facebook?: InputMaybe<String_comparison_exp>;
  new_FullSeoPage?: InputMaybe<numeric_comparison_exp>;
  new_IsCustomer?: InputMaybe<Boolean_comparison_exp>;
  new_Issupplier?: InputMaybe<Boolean_comparison_exp>;
  new_LastActivity?: InputMaybe<timestamp_comparison_exp>;
  new_LinkedIn?: InputMaybe<String_comparison_exp>;
  new_Linksexchanger?: InputMaybe<Boolean_comparison_exp>;
  new_MainLanguage?: InputMaybe<Int_comparison_exp>;
  new_Offers?: InputMaybe<Int_comparison_exp>;
  new_Passportid?: InputMaybe<String_comparison_exp>;
  new_Password?: InputMaybe<String_comparison_exp>;
  new_PaymentAddress?: InputMaybe<String_comparison_exp>;
  new_Paymentmethod?: InputMaybe<Int_comparison_exp>;
  new_Rating?: InputMaybe<Int_comparison_exp>;
  new_SecondaryLanguage?: InputMaybe<Int_comparison_exp>;
  new_SeoShortPost?: InputMaybe<numeric_comparison_exp>;
  new_Translation?: InputMaybe<numeric_comparison_exp>;
  new_Translator?: InputMaybe<Boolean_comparison_exp>;
  new_UserName?: InputMaybe<String_comparison_exp>;
  new_WebsitePassword?: InputMaybe<String_comparison_exp>;
  new_Writer?: InputMaybe<Boolean_comparison_exp>;
  new_bankaddress?: InputMaybe<String_comparison_exp>;
  new_bankibanaccount?: InputMaybe<String_comparison_exp>;
  new_bankname?: InputMaybe<String_comparison_exp>;
  new_bankswift?: InputMaybe<String_comparison_exp>;
  new_beneficiaryaddress?: InputMaybe<String_comparison_exp>;
  new_beneficiaryname?: InputMaybe<String_comparison_exp>;
  new_contacetype?: InputMaybe<Boolean_comparison_exp>;
  new_contactid?: InputMaybe<String_comparison_exp>;
  new_fullseopagebig?: InputMaybe<numeric_comparison_exp>;
  new_fullseopagejambo?: InputMaybe<numeric_comparison_exp>;
  new_fullseopagelarge?: InputMaybe<numeric_comparison_exp>;
  new_inhouse?: InputMaybe<Boolean_comparison_exp>;
  new_language?: InputMaybe<uuid_comparison_exp>;
  new_language1?: InputMaybe<uuid_comparison_exp>;
  new_longsocialpost?: InputMaybe<numeric_comparison_exp>;
  new_shortsocialpost?: InputMaybe<numeric_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paymentOption?: InputMaybe<Int_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  price_per_word?: InputMaybe<float8_comparison_exp>;
  skill_level?: InputMaybe<numeric_comparison_exp>;
  tag_ids?: InputMaybe<_int4_comparison_exp>;
  upload_price?: InputMaybe<numeric_comparison_exp>;
};

/** aggregate max on columns */
export type v1_Writers_max_fields = {
  __typename?: 'v1_Writers_max_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type v1_Writers_min_fields = {
  __typename?: 'v1_Writers_min_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  BirthDate?: Maybe<Scalars['timestamp']['output']>;
  ContactId?: Maybe<Scalars['uuid']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  EMailAddress1?: Maybe<Scalars['String']['output']>;
  EMailAddress2?: Maybe<Scalars['String']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FullName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  Telephone1?: Maybe<Scalars['String']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Facebook?: Maybe<Scalars['String']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_LastActivity?: Maybe<Scalars['timestamp']['output']>;
  new_LinkedIn?: Maybe<Scalars['String']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Passportid?: Maybe<Scalars['String']['output']>;
  new_Password?: Maybe<Scalars['String']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_UserName?: Maybe<Scalars['String']['output']>;
  new_WebsitePassword?: Maybe<Scalars['String']['output']>;
  new_bankaddress?: Maybe<Scalars['String']['output']>;
  new_bankibanaccount?: Maybe<Scalars['String']['output']>;
  new_bankname?: Maybe<Scalars['String']['output']>;
  new_bankswift?: Maybe<Scalars['String']['output']>;
  new_beneficiaryaddress?: Maybe<Scalars['String']['output']>;
  new_beneficiaryname?: Maybe<Scalars['String']['output']>;
  new_contactid?: Maybe<Scalars['String']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_language?: Maybe<Scalars['uuid']['output']>;
  new_language1?: Maybe<Scalars['uuid']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** Ordering options when selecting data from "v1.Writers". */
export type v1_Writers_order_by = {
  ActiveOffersCount?: InputMaybe<order_by>;
  AllPosts?: InputMaybe<order_by>;
  AssistantID?: InputMaybe<order_by>;
  Balance?: InputMaybe<order_by>;
  BirthDate?: InputMaybe<order_by>;
  ContactId?: InputMaybe<order_by>;
  CountryID?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Description?: InputMaybe<order_by>;
  EMailAddress1?: InputMaybe<order_by>;
  EMailAddress2?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  FinishedOrders?: InputMaybe<order_by>;
  FirstName?: InputMaybe<order_by>;
  FullName?: InputMaybe<order_by>;
  LastName?: InputMaybe<order_by>;
  MobilePhone?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  SoldOffersCount?: InputMaybe<order_by>;
  StateCode?: InputMaybe<order_by>;
  StatusCode?: InputMaybe<order_by>;
  Telephone1?: InputMaybe<order_by>;
  TotalOffersCount?: InputMaybe<order_by>;
  TotalOrdersSum?: InputMaybe<order_by>;
  TotalPayoutsSum?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  allow_upload_content_portal?: InputMaybe<order_by>;
  buzzstream_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inprogress_posts?: InputMaybe<order_by>;
  language1_id?: InputMaybe<order_by>;
  language_id?: InputMaybe<order_by>;
  left_quota?: InputMaybe<order_by>;
  monthly_quota?: InputMaybe<order_by>;
  new_AccountActive?: InputMaybe<order_by>;
  new_Blacklisted?: InputMaybe<order_by>;
  new_Contacttype?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_Facebook?: InputMaybe<order_by>;
  new_FullSeoPage?: InputMaybe<order_by>;
  new_IsCustomer?: InputMaybe<order_by>;
  new_Issupplier?: InputMaybe<order_by>;
  new_LastActivity?: InputMaybe<order_by>;
  new_LinkedIn?: InputMaybe<order_by>;
  new_Linksexchanger?: InputMaybe<order_by>;
  new_MainLanguage?: InputMaybe<order_by>;
  new_Offers?: InputMaybe<order_by>;
  new_Passportid?: InputMaybe<order_by>;
  new_Password?: InputMaybe<order_by>;
  new_PaymentAddress?: InputMaybe<order_by>;
  new_Paymentmethod?: InputMaybe<order_by>;
  new_Rating?: InputMaybe<order_by>;
  new_SecondaryLanguage?: InputMaybe<order_by>;
  new_SeoShortPost?: InputMaybe<order_by>;
  new_Translation?: InputMaybe<order_by>;
  new_Translator?: InputMaybe<order_by>;
  new_UserName?: InputMaybe<order_by>;
  new_WebsitePassword?: InputMaybe<order_by>;
  new_Writer?: InputMaybe<order_by>;
  new_bankaddress?: InputMaybe<order_by>;
  new_bankibanaccount?: InputMaybe<order_by>;
  new_bankname?: InputMaybe<order_by>;
  new_bankswift?: InputMaybe<order_by>;
  new_beneficiaryaddress?: InputMaybe<order_by>;
  new_beneficiaryname?: InputMaybe<order_by>;
  new_contacetype?: InputMaybe<order_by>;
  new_contactid?: InputMaybe<order_by>;
  new_fullseopagebig?: InputMaybe<order_by>;
  new_fullseopagejambo?: InputMaybe<order_by>;
  new_fullseopagelarge?: InputMaybe<order_by>;
  new_inhouse?: InputMaybe<order_by>;
  new_language?: InputMaybe<order_by>;
  new_language1?: InputMaybe<order_by>;
  new_longsocialpost?: InputMaybe<order_by>;
  new_shortsocialpost?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentOption?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  price_per_word?: InputMaybe<order_by>;
  skill_level?: InputMaybe<order_by>;
  tag_ids?: InputMaybe<order_by>;
  upload_price?: InputMaybe<order_by>;
};

/** select columns of table "v1.Writers" */
export enum v1_Writers_select_column {
  /** column name */
  ActiveOffersCount = 'ActiveOffersCount',
  /** column name */
  AllPosts = 'AllPosts',
  /** column name */
  AssistantID = 'AssistantID',
  /** column name */
  Balance = 'Balance',
  /** column name */
  BirthDate = 'BirthDate',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CountryID = 'CountryID',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  EMailAddress1 = 'EMailAddress1',
  /** column name */
  EMailAddress2 = 'EMailAddress2',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  FinishedOrders = 'FinishedOrders',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  FullName = 'FullName',
  /** column name */
  LastName = 'LastName',
  /** column name */
  MobilePhone = 'MobilePhone',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  SoldOffersCount = 'SoldOffersCount',
  /** column name */
  StateCode = 'StateCode',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  Telephone1 = 'Telephone1',
  /** column name */
  TotalOffersCount = 'TotalOffersCount',
  /** column name */
  TotalOrdersSum = 'TotalOrdersSum',
  /** column name */
  TotalPayoutsSum = 'TotalPayoutsSum',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  allow_upload_content_portal = 'allow_upload_content_portal',
  /** column name */
  buzzstream_id = 'buzzstream_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  language1_id = 'language1_id',
  /** column name */
  language_id = 'language_id',
  /** column name */
  monthly_quota = 'monthly_quota',
  /** column name */
  new_AccountActive = 'new_AccountActive',
  /** column name */
  new_Blacklisted = 'new_Blacklisted',
  /** column name */
  new_Contacttype = 'new_Contacttype',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_Facebook = 'new_Facebook',
  /** column name */
  new_FullSeoPage = 'new_FullSeoPage',
  /** column name */
  new_IsCustomer = 'new_IsCustomer',
  /** column name */
  new_Issupplier = 'new_Issupplier',
  /** column name */
  new_LastActivity = 'new_LastActivity',
  /** column name */
  new_LinkedIn = 'new_LinkedIn',
  /** column name */
  new_Linksexchanger = 'new_Linksexchanger',
  /** column name */
  new_MainLanguage = 'new_MainLanguage',
  /** column name */
  new_Offers = 'new_Offers',
  /** column name */
  new_Passportid = 'new_Passportid',
  /** column name */
  new_Password = 'new_Password',
  /** column name */
  new_PaymentAddress = 'new_PaymentAddress',
  /** column name */
  new_Paymentmethod = 'new_Paymentmethod',
  /** column name */
  new_Rating = 'new_Rating',
  /** column name */
  new_SecondaryLanguage = 'new_SecondaryLanguage',
  /** column name */
  new_SeoShortPost = 'new_SeoShortPost',
  /** column name */
  new_Translation = 'new_Translation',
  /** column name */
  new_Translator = 'new_Translator',
  /** column name */
  new_UserName = 'new_UserName',
  /** column name */
  new_WebsitePassword = 'new_WebsitePassword',
  /** column name */
  new_Writer = 'new_Writer',
  /** column name */
  new_bankaddress = 'new_bankaddress',
  /** column name */
  new_bankibanaccount = 'new_bankibanaccount',
  /** column name */
  new_bankname = 'new_bankname',
  /** column name */
  new_bankswift = 'new_bankswift',
  /** column name */
  new_beneficiaryaddress = 'new_beneficiaryaddress',
  /** column name */
  new_beneficiaryname = 'new_beneficiaryname',
  /** column name */
  new_contacetype = 'new_contacetype',
  /** column name */
  new_contactid = 'new_contactid',
  /** column name */
  new_fullseopagebig = 'new_fullseopagebig',
  /** column name */
  new_fullseopagejambo = 'new_fullseopagejambo',
  /** column name */
  new_fullseopagelarge = 'new_fullseopagelarge',
  /** column name */
  new_inhouse = 'new_inhouse',
  /** column name */
  new_language = 'new_language',
  /** column name */
  new_language1 = 'new_language1',
  /** column name */
  new_longsocialpost = 'new_longsocialpost',
  /** column name */
  new_shortsocialpost = 'new_shortsocialpost',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentOption = 'paymentOption',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  price_per_word = 'price_per_word',
  /** column name */
  skill_level = 'skill_level',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  upload_price = 'upload_price'
}

/** aggregate stddev on columns */
export type v1_Writers_stddev_fields = {
  __typename?: 'v1_Writers_stddev_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_Writers_stddev_pop_fields = {
  __typename?: 'v1_Writers_stddev_pop_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_Writers_stddev_samp_fields = {
  __typename?: 'v1_Writers_stddev_samp_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_Writers" */
export type v1_Writers_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_Writers_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_Writers_stream_cursor_value_input = {
  ActiveOffersCount?: InputMaybe<Scalars['Int']['input']>;
  AllPosts?: InputMaybe<Scalars['Int']['input']>;
  AssistantID?: InputMaybe<Scalars['Int']['input']>;
  Balance?: InputMaybe<Scalars['numeric']['input']>;
  BirthDate?: InputMaybe<Scalars['timestamp']['input']>;
  ContactId?: InputMaybe<Scalars['uuid']['input']>;
  CountryID?: InputMaybe<Scalars['Int']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  EMailAddress1?: InputMaybe<Scalars['String']['input']>;
  EMailAddress2?: InputMaybe<Scalars['String']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  FinishedOrders?: InputMaybe<Scalars['Int']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FullName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  SoldOffersCount?: InputMaybe<Scalars['Int']['input']>;
  StateCode?: InputMaybe<Scalars['Int']['input']>;
  StatusCode?: InputMaybe<Scalars['Int']['input']>;
  Telephone1?: InputMaybe<Scalars['String']['input']>;
  TotalOffersCount?: InputMaybe<Scalars['Int']['input']>;
  TotalOrdersSum?: InputMaybe<Scalars['Int']['input']>;
  TotalPayoutsSum?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  allow_upload_content_portal?: InputMaybe<Scalars['Boolean']['input']>;
  buzzstream_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language1_id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  monthly_quota?: InputMaybe<Scalars['numeric']['input']>;
  new_AccountActive?: InputMaybe<Scalars['Boolean']['input']>;
  new_Blacklisted?: InputMaybe<Scalars['Boolean']['input']>;
  new_Contacttype?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_Facebook?: InputMaybe<Scalars['String']['input']>;
  new_FullSeoPage?: InputMaybe<Scalars['numeric']['input']>;
  new_IsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  new_Issupplier?: InputMaybe<Scalars['Boolean']['input']>;
  new_LastActivity?: InputMaybe<Scalars['timestamp']['input']>;
  new_LinkedIn?: InputMaybe<Scalars['String']['input']>;
  new_Linksexchanger?: InputMaybe<Scalars['Boolean']['input']>;
  new_MainLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_Offers?: InputMaybe<Scalars['Int']['input']>;
  new_Passportid?: InputMaybe<Scalars['String']['input']>;
  new_Password?: InputMaybe<Scalars['String']['input']>;
  new_PaymentAddress?: InputMaybe<Scalars['String']['input']>;
  new_Paymentmethod?: InputMaybe<Scalars['Int']['input']>;
  new_Rating?: InputMaybe<Scalars['Int']['input']>;
  new_SecondaryLanguage?: InputMaybe<Scalars['Int']['input']>;
  new_SeoShortPost?: InputMaybe<Scalars['numeric']['input']>;
  new_Translation?: InputMaybe<Scalars['numeric']['input']>;
  new_Translator?: InputMaybe<Scalars['Boolean']['input']>;
  new_UserName?: InputMaybe<Scalars['String']['input']>;
  new_WebsitePassword?: InputMaybe<Scalars['String']['input']>;
  new_Writer?: InputMaybe<Scalars['Boolean']['input']>;
  new_bankaddress?: InputMaybe<Scalars['String']['input']>;
  new_bankibanaccount?: InputMaybe<Scalars['String']['input']>;
  new_bankname?: InputMaybe<Scalars['String']['input']>;
  new_bankswift?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryaddress?: InputMaybe<Scalars['String']['input']>;
  new_beneficiaryname?: InputMaybe<Scalars['String']['input']>;
  new_contacetype?: InputMaybe<Scalars['Boolean']['input']>;
  new_contactid?: InputMaybe<Scalars['String']['input']>;
  new_fullseopagebig?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagejambo?: InputMaybe<Scalars['numeric']['input']>;
  new_fullseopagelarge?: InputMaybe<Scalars['numeric']['input']>;
  new_inhouse?: InputMaybe<Scalars['Boolean']['input']>;
  new_language?: InputMaybe<Scalars['uuid']['input']>;
  new_language1?: InputMaybe<Scalars['uuid']['input']>;
  new_longsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  new_shortsocialpost?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentOption?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  price_per_word?: InputMaybe<Scalars['float8']['input']>;
  skill_level?: InputMaybe<Scalars['numeric']['input']>;
  tag_ids?: InputMaybe<Scalars['_int4']['input']>;
  upload_price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type v1_Writers_sum_fields = {
  __typename?: 'v1_Writers_sum_fields';
  ActiveOffersCount?: Maybe<Scalars['Int']['output']>;
  AllPosts?: Maybe<Scalars['Int']['output']>;
  AssistantID?: Maybe<Scalars['Int']['output']>;
  Balance?: Maybe<Scalars['numeric']['output']>;
  CountryID?: Maybe<Scalars['Int']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  FinishedOrders?: Maybe<Scalars['Int']['output']>;
  SoldOffersCount?: Maybe<Scalars['Int']['output']>;
  StateCode?: Maybe<Scalars['Int']['output']>;
  StatusCode?: Maybe<Scalars['Int']['output']>;
  TotalOffersCount?: Maybe<Scalars['Int']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Int']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Int']['output']>;
  buzzstream_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language1_id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  monthly_quota?: Maybe<Scalars['numeric']['output']>;
  new_Contacttype?: Maybe<Scalars['Int']['output']>;
  new_FullSeoPage?: Maybe<Scalars['numeric']['output']>;
  new_MainLanguage?: Maybe<Scalars['Int']['output']>;
  new_Offers?: Maybe<Scalars['Int']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Int']['output']>;
  new_Rating?: Maybe<Scalars['Int']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Int']['output']>;
  new_SeoShortPost?: Maybe<Scalars['numeric']['output']>;
  new_Translation?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagebig?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['numeric']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['numeric']['output']>;
  new_longsocialpost?: Maybe<Scalars['numeric']['output']>;
  new_shortsocialpost?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  price_per_word?: Maybe<Scalars['float8']['output']>;
  skill_level?: Maybe<Scalars['numeric']['output']>;
  upload_price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type v1_Writers_var_pop_fields = {
  __typename?: 'v1_Writers_var_pop_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_Writers_var_samp_fields = {
  __typename?: 'v1_Writers_var_samp_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_Writers_variance_fields = {
  __typename?: 'v1_Writers_variance_fields';
  ActiveOffersCount?: Maybe<Scalars['Float']['output']>;
  AllPosts?: Maybe<Scalars['Float']['output']>;
  AssistantID?: Maybe<Scalars['Float']['output']>;
  Balance?: Maybe<Scalars['Float']['output']>;
  CountryID?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  FinishedOrders?: Maybe<Scalars['Float']['output']>;
  SoldOffersCount?: Maybe<Scalars['Float']['output']>;
  StateCode?: Maybe<Scalars['Float']['output']>;
  StatusCode?: Maybe<Scalars['Float']['output']>;
  TotalOffersCount?: Maybe<Scalars['Float']['output']>;
  TotalOrdersSum?: Maybe<Scalars['Float']['output']>;
  TotalPayoutsSum?: Maybe<Scalars['Float']['output']>;
  buzzstream_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language1_id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  monthly_quota?: Maybe<Scalars['Float']['output']>;
  new_Contacttype?: Maybe<Scalars['Float']['output']>;
  new_FullSeoPage?: Maybe<Scalars['Float']['output']>;
  new_MainLanguage?: Maybe<Scalars['Float']['output']>;
  new_Offers?: Maybe<Scalars['Float']['output']>;
  new_Paymentmethod?: Maybe<Scalars['Float']['output']>;
  new_Rating?: Maybe<Scalars['Float']['output']>;
  new_SecondaryLanguage?: Maybe<Scalars['Float']['output']>;
  new_SeoShortPost?: Maybe<Scalars['Float']['output']>;
  new_Translation?: Maybe<Scalars['Float']['output']>;
  new_fullseopagebig?: Maybe<Scalars['Float']['output']>;
  new_fullseopagejambo?: Maybe<Scalars['Float']['output']>;
  new_fullseopagelarge?: Maybe<Scalars['Float']['output']>;
  new_longsocialpost?: Maybe<Scalars['Float']['output']>;
  new_shortsocialpost?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentOption?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  price_per_word?: Maybe<Scalars['Float']['output']>;
  skill_level?: Maybe<Scalars['Float']['output']>;
  upload_price?: Maybe<Scalars['Float']['output']>;
};

/** Purchase Orders */
export type v1_new_POBase = {
  __typename?: 'v1_new_POBase';
  /** An object relationship */
  Advertiser?: Maybe<v1_ContactBase>;
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  AdvertiserPoId: Array<v1_Posts>;
  /** An aggregate relationship */
  AdvertiserPoId_aggregate: v1_Posts_aggregate;
  /** An object relationship */
  Approver?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  BonusSubmitter?: Maybe<v1_BonusSubmitter>;
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  CancelledByUser?: Maybe<v1_SystemUserBase>;
  CancelledOn?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  Company?: Maybe<v1_AccountBase>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn: Scalars['timestamp']['output'];
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  DeclinedBy?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  DeclinedByUser?: Maybe<v1_SystemUserBase>;
  DeclinedOn?: Maybe<Scalars['timestamptz']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  PaidBy?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  PaymentMethod?: Maybe<v1_new_paymentmethodBase>;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An array relationship */
  PostsToPo: Array<v1_PoToPosts>;
  /** An aggregate relationship */
  PostsToPo_aggregate: v1_PoToPosts_aggregate;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An object relationship */
  PurchaseOrderStatus?: Maybe<v1_PurchaseOrderStatus>;
  /** An object relationship */
  RecurringPayment?: Maybe<v1_RecurringPayments>;
  /** An object relationship */
  ServiceType?: Maybe<v1_ServiceTypes>;
  /** An object relationship */
  Supplier?: Maybe<v1_ContactBase>;
  /** An array relationship */
  SupplierPosts: Array<v1_Posts>;
  /** An aggregate relationship */
  SupplierPosts_aggregate: v1_Posts_aggregate;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  Writer?: Maybe<v1_ContactBase>;
  approvedby_id?: Maybe<Scalars['Int']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_publisher_total" */
  comp_publisher_total?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_total_publisher_amount" */
  comp_total_publisher_amount?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_total_writer_amount" */
  comp_total_writer_amount?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.comp_writer_total" */
  comp_writer_total?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  euro_equivalent?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['Int']['output'];
  new_ApprovedOn?: Maybe<Scalars['timestamp']['output']>;
  new_BonusIfany?: Maybe<Scalars['numeric']['output']>;
  new_Company?: Maybe<Scalars['uuid']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_DueDate?: Maybe<Scalars['timestamp']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Int']['output']>;
  new_POId?: Maybe<Scalars['uuid']['output']>;
  new_PaidBy?: Maybe<Scalars['uuid']['output']>;
  new_PaidOn?: Maybe<Scalars['date']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Int']['output']>;
  new_PaymentType?: Maybe<Scalars['Int']['output']>;
  new_PostEndDate?: Maybe<Scalars['timestamp']['output']>;
  new_PostStartDate?: Maybe<Scalars['timestamp']['output']>;
  new_ProviderInvoiceid?: Maybe<Scalars['String']['output']>;
  new_RecurringPayment?: Maybe<Scalars['uuid']['output']>;
  new_RelatedOrder?: Maybe<Scalars['uuid']['output']>;
  new_Sum: Scalars['numeric']['output'];
  new_TotalContentCost?: Maybe<Scalars['numeric']['output']>;
  new_checknumber?: Maybe<Scalars['String']['output']>;
  new_customertype?: Maybe<Scalars['Boolean']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_paymentmethoddm?: Maybe<Scalars['uuid']['output']>;
  new_paymentmethodnew?: Maybe<Scalars['uuid']['output']>;
  new_ponumber?: Maybe<Scalars['String']['output']>;
  new_purchaseordermarkedpaidon?: Maybe<Scalars['timestamp']['output']>;
  new_servedon?: Maybe<Scalars['timestamp']['output']>;
  new_total: Scalars['numeric']['output'];
  new_total_Base: Scalars['numeric']['output'];
  owner_id?: Maybe<Scalars['Int']['output']>;
  paidby_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  recurringPayment_id?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  status_change_reason?: Maybe<Scalars['String']['output']>;
  statuscode: Scalars['Int']['output'];
  supplier_id?: Maybe<Scalars['Int']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type_of_contractor?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};


/** Purchase Orders */
export type v1_new_POBaseAdvertiserPoIdArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Purchase Orders */
export type v1_new_POBaseAdvertiserPoId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Purchase Orders */
export type v1_new_POBasePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Purchase Orders */
export type v1_new_POBasePostsToPoArgs = {
  distinct_on?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PoToPosts_order_by>>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};


/** Purchase Orders */
export type v1_new_POBasePostsToPo_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_PoToPosts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_PoToPosts_order_by>>;
  where?: InputMaybe<v1_PoToPosts_bool_exp>;
};


/** Purchase Orders */
export type v1_new_POBasePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Purchase Orders */
export type v1_new_POBaseSupplierPostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Purchase Orders */
export type v1_new_POBaseSupplierPosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};

/** aggregated selection of "v1.new_POBase" */
export type v1_new_POBase_aggregate = {
  __typename?: 'v1_new_POBase_aggregate';
  aggregate?: Maybe<v1_new_POBase_aggregate_fields>;
  nodes: Array<v1_new_POBase>;
};

export type v1_new_POBase_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_new_POBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_new_POBase_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_new_POBase_aggregate_bool_exp_count>;
};

export type v1_new_POBase_aggregate_bool_exp_bool_and = {
  arguments: v1_new_POBase_select_column_v1_new_POBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_POBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_POBase_aggregate_bool_exp_bool_or = {
  arguments: v1_new_POBase_select_column_v1_new_POBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_POBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_POBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_new_POBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_POBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.new_POBase" */
export type v1_new_POBase_aggregate_fields = {
  __typename?: 'v1_new_POBase_aggregate_fields';
  avg?: Maybe<v1_new_POBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_POBase_max_fields>;
  min?: Maybe<v1_new_POBase_min_fields>;
  stddev?: Maybe<v1_new_POBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_POBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_POBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_POBase_sum_fields>;
  var_pop?: Maybe<v1_new_POBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_POBase_var_samp_fields>;
  variance?: Maybe<v1_new_POBase_variance_fields>;
};


/** aggregate fields of "v1.new_POBase" */
export type v1_new_POBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_POBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.new_POBase" */
export type v1_new_POBase_aggregate_order_by = {
  avg?: InputMaybe<v1_new_POBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_new_POBase_max_order_by>;
  min?: InputMaybe<v1_new_POBase_min_order_by>;
  stddev?: InputMaybe<v1_new_POBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_new_POBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_new_POBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_new_POBase_sum_order_by>;
  var_pop?: InputMaybe<v1_new_POBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_new_POBase_var_samp_order_by>;
  variance?: InputMaybe<v1_new_POBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.new_POBase" */
export type v1_new_POBase_arr_rel_insert_input = {
  data: Array<v1_new_POBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_POBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_new_POBase_avg_fields = {
  __typename?: 'v1_new_POBase_avg_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  DeclinedBy?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BonusIfany?: Maybe<Scalars['Float']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Float']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_Sum?: Maybe<Scalars['Float']['output']>;
  new_TotalContentCost?: Maybe<Scalars['Float']['output']>;
  new_total?: Maybe<Scalars['Float']['output']>;
  new_total_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paidby_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  type_of_contractor?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.new_POBase" */
export type v1_new_POBase_avg_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.new_POBase". All fields are combined with a logical 'AND'. */
export type v1_new_POBase_bool_exp = {
  Advertiser?: InputMaybe<v1_ContactBase_bool_exp>;
  AdvertiserId?: InputMaybe<Int_comparison_exp>;
  AdvertiserPoId?: InputMaybe<v1_Posts_bool_exp>;
  AdvertiserPoId_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  Approver?: InputMaybe<v1_SystemUserBase_bool_exp>;
  BonusSubmitter?: InputMaybe<v1_BonusSubmitter_bool_exp>;
  CancelledBy?: InputMaybe<Int_comparison_exp>;
  CancelledByUser?: InputMaybe<v1_SystemUserBase_bool_exp>;
  CancelledOn?: InputMaybe<timestamptz_comparison_exp>;
  Company?: InputMaybe<v1_AccountBase_bool_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  DeclinedBy?: InputMaybe<Int_comparison_exp>;
  DeclinedByUser?: InputMaybe<v1_SystemUserBase_bool_exp>;
  DeclinedOn?: InputMaybe<timestamptz_comparison_exp>;
  ExchangeRate?: InputMaybe<numeric_comparison_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  PaidBy?: InputMaybe<v1_SystemUserBase_bool_exp>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  PostsToPo?: InputMaybe<v1_PoToPosts_bool_exp>;
  PostsToPo_aggregate?: InputMaybe<v1_PoToPosts_aggregate_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  PurchaseOrderStatus?: InputMaybe<v1_PurchaseOrderStatus_bool_exp>;
  RecurringPayment?: InputMaybe<v1_RecurringPayments_bool_exp>;
  ServiceType?: InputMaybe<v1_ServiceTypes_bool_exp>;
  Supplier?: InputMaybe<v1_ContactBase_bool_exp>;
  SupplierPosts?: InputMaybe<v1_Posts_bool_exp>;
  SupplierPosts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  Writer?: InputMaybe<v1_ContactBase_bool_exp>;
  _and?: InputMaybe<Array<v1_new_POBase_bool_exp>>;
  _not?: InputMaybe<v1_new_POBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_POBase_bool_exp>>;
  approvedby_id?: InputMaybe<Int_comparison_exp>;
  bonus_submitter_id?: InputMaybe<Int_comparison_exp>;
  comp_publisher_total?: InputMaybe<Int_comparison_exp>;
  comp_total_publisher_amount?: InputMaybe<Int_comparison_exp>;
  comp_total_writer_amount?: InputMaybe<Int_comparison_exp>;
  comp_writer_total?: InputMaybe<Int_comparison_exp>;
  company_id?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  euro_equivalent?: InputMaybe<numeric_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_ApprovedOn?: InputMaybe<timestamp_comparison_exp>;
  new_BonusIfany?: InputMaybe<numeric_comparison_exp>;
  new_Company?: InputMaybe<uuid_comparison_exp>;
  new_Description?: InputMaybe<String_comparison_exp>;
  new_DueDate?: InputMaybe<timestamp_comparison_exp>;
  new_FromCompanyPayment?: InputMaybe<Int_comparison_exp>;
  new_POId?: InputMaybe<uuid_comparison_exp>;
  new_PaidBy?: InputMaybe<uuid_comparison_exp>;
  new_PaidOn?: InputMaybe<date_comparison_exp>;
  new_PaymentAddress?: InputMaybe<String_comparison_exp>;
  new_PaymentMethod?: InputMaybe<Int_comparison_exp>;
  new_PaymentType?: InputMaybe<Int_comparison_exp>;
  new_PostEndDate?: InputMaybe<timestamp_comparison_exp>;
  new_PostStartDate?: InputMaybe<timestamp_comparison_exp>;
  new_ProviderInvoiceid?: InputMaybe<String_comparison_exp>;
  new_RecurringPayment?: InputMaybe<uuid_comparison_exp>;
  new_RelatedOrder?: InputMaybe<uuid_comparison_exp>;
  new_Sum?: InputMaybe<numeric_comparison_exp>;
  new_TotalContentCost?: InputMaybe<numeric_comparison_exp>;
  new_checknumber?: InputMaybe<String_comparison_exp>;
  new_customertype?: InputMaybe<Boolean_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_paymentmethoddm?: InputMaybe<uuid_comparison_exp>;
  new_paymentmethodnew?: InputMaybe<uuid_comparison_exp>;
  new_ponumber?: InputMaybe<String_comparison_exp>;
  new_purchaseordermarkedpaidon?: InputMaybe<timestamp_comparison_exp>;
  new_servedon?: InputMaybe<timestamp_comparison_exp>;
  new_total?: InputMaybe<numeric_comparison_exp>;
  new_total_Base?: InputMaybe<numeric_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paidby_id?: InputMaybe<Int_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  po_id?: InputMaybe<Int_comparison_exp>;
  recurringPayment_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  status_change_reason?: InputMaybe<String_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
  supplier_id?: InputMaybe<Int_comparison_exp>;
  transactionId?: InputMaybe<String_comparison_exp>;
  type_of_contractor?: InputMaybe<Int_comparison_exp>;
  writer_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_POBase" */
export enum v1_new_POBase_constraint {
  /** unique or primary key constraint on columns "id" */
  new_POBase_id_key = 'new_POBase_id_key',
  /** unique or primary key constraint on columns "id" */
  new_POBase_pkey = 'new_POBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.new_POBase" */
export type v1_new_POBase_inc_input = {
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  DeclinedBy?: InputMaybe<Scalars['Int']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  approvedby_id?: InputMaybe<Scalars['Int']['input']>;
  bonus_submitter_id?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  euro_equivalent?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_BonusIfany?: InputMaybe<Scalars['numeric']['input']>;
  new_FromCompanyPayment?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentType?: InputMaybe<Scalars['Int']['input']>;
  new_Sum?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalContentCost?: InputMaybe<Scalars['numeric']['input']>;
  new_total?: InputMaybe<Scalars['numeric']['input']>;
  new_total_Base?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paidby_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  recurringPayment_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  type_of_contractor?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_POBase" */
export type v1_new_POBase_insert_input = {
  Advertiser?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  AdvertiserPoId?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  Approver?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  BonusSubmitter?: InputMaybe<v1_BonusSubmitter_obj_rel_insert_input>;
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  CancelledByUser?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  CancelledOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Company?: InputMaybe<v1_AccountBase_obj_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  DeclinedBy?: InputMaybe<Scalars['Int']['input']>;
  DeclinedByUser?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  DeclinedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  PaidBy?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_obj_rel_insert_input>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  PostsToPo?: InputMaybe<v1_PoToPosts_arr_rel_insert_input>;
  PurchaseOrderStatus?: InputMaybe<v1_PurchaseOrderStatus_obj_rel_insert_input>;
  RecurringPayment?: InputMaybe<v1_RecurringPayments_obj_rel_insert_input>;
  ServiceType?: InputMaybe<v1_ServiceTypes_obj_rel_insert_input>;
  Supplier?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  SupplierPosts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  Writer?: InputMaybe<v1_ContactBase_obj_rel_insert_input>;
  approvedby_id?: InputMaybe<Scalars['Int']['input']>;
  bonus_submitter_id?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  euro_equivalent?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_ApprovedOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_BonusIfany?: InputMaybe<Scalars['numeric']['input']>;
  new_Company?: InputMaybe<Scalars['uuid']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DueDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_FromCompanyPayment?: InputMaybe<Scalars['Int']['input']>;
  new_POId?: InputMaybe<Scalars['uuid']['input']>;
  new_PaidBy?: InputMaybe<Scalars['uuid']['input']>;
  new_PaidOn?: InputMaybe<Scalars['date']['input']>;
  new_PaymentAddress?: InputMaybe<Scalars['String']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentType?: InputMaybe<Scalars['Int']['input']>;
  new_PostEndDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_PostStartDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_ProviderInvoiceid?: InputMaybe<Scalars['String']['input']>;
  new_RecurringPayment?: InputMaybe<Scalars['uuid']['input']>;
  new_RelatedOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_Sum?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalContentCost?: InputMaybe<Scalars['numeric']['input']>;
  new_checknumber?: InputMaybe<Scalars['String']['input']>;
  new_customertype?: InputMaybe<Scalars['Boolean']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_paymentmethoddm?: InputMaybe<Scalars['uuid']['input']>;
  new_paymentmethodnew?: InputMaybe<Scalars['uuid']['input']>;
  new_ponumber?: InputMaybe<Scalars['String']['input']>;
  new_purchaseordermarkedpaidon?: InputMaybe<Scalars['timestamp']['input']>;
  new_servedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_total?: InputMaybe<Scalars['numeric']['input']>;
  new_total_Base?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paidby_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  recurringPayment_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_change_reason?: InputMaybe<Scalars['String']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type_of_contractor?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_POBase_max_fields = {
  __typename?: 'v1_new_POBase_max_fields';
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  CancelledOn?: Maybe<Scalars['timestamptz']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  DeclinedBy?: Maybe<Scalars['Int']['output']>;
  DeclinedOn?: Maybe<Scalars['timestamptz']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  approvedby_id?: Maybe<Scalars['Int']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  euro_equivalent?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_ApprovedOn?: Maybe<Scalars['timestamp']['output']>;
  new_BonusIfany?: Maybe<Scalars['numeric']['output']>;
  new_Company?: Maybe<Scalars['uuid']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_DueDate?: Maybe<Scalars['timestamp']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Int']['output']>;
  new_POId?: Maybe<Scalars['uuid']['output']>;
  new_PaidBy?: Maybe<Scalars['uuid']['output']>;
  new_PaidOn?: Maybe<Scalars['date']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Int']['output']>;
  new_PaymentType?: Maybe<Scalars['Int']['output']>;
  new_PostEndDate?: Maybe<Scalars['timestamp']['output']>;
  new_PostStartDate?: Maybe<Scalars['timestamp']['output']>;
  new_ProviderInvoiceid?: Maybe<Scalars['String']['output']>;
  new_RecurringPayment?: Maybe<Scalars['uuid']['output']>;
  new_RelatedOrder?: Maybe<Scalars['uuid']['output']>;
  new_Sum?: Maybe<Scalars['numeric']['output']>;
  new_TotalContentCost?: Maybe<Scalars['numeric']['output']>;
  new_checknumber?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_paymentmethoddm?: Maybe<Scalars['uuid']['output']>;
  new_paymentmethodnew?: Maybe<Scalars['uuid']['output']>;
  new_ponumber?: Maybe<Scalars['String']['output']>;
  new_purchaseordermarkedpaidon?: Maybe<Scalars['timestamp']['output']>;
  new_servedon?: Maybe<Scalars['timestamp']['output']>;
  new_total?: Maybe<Scalars['numeric']['output']>;
  new_total_Base?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paidby_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  recurringPayment_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_change_reason?: Maybe<Scalars['String']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type_of_contractor?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.new_POBase" */
export type v1_new_POBase_max_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  CancelledOn?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  DeclinedOn?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_ApprovedOn?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_DueDate?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_POId?: InputMaybe<order_by>;
  new_PaidBy?: InputMaybe<order_by>;
  new_PaidOn?: InputMaybe<order_by>;
  new_PaymentAddress?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_PostEndDate?: InputMaybe<order_by>;
  new_PostStartDate?: InputMaybe<order_by>;
  new_ProviderInvoiceid?: InputMaybe<order_by>;
  new_RecurringPayment?: InputMaybe<order_by>;
  new_RelatedOrder?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_checknumber?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_paymentmethoddm?: InputMaybe<order_by>;
  new_paymentmethodnew?: InputMaybe<order_by>;
  new_ponumber?: InputMaybe<order_by>;
  new_purchaseordermarkedpaidon?: InputMaybe<order_by>;
  new_servedon?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_change_reason?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  transactionId?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_new_POBase_min_fields = {
  __typename?: 'v1_new_POBase_min_fields';
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  CancelledOn?: Maybe<Scalars['timestamptz']['output']>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  DeclinedBy?: Maybe<Scalars['Int']['output']>;
  DeclinedOn?: Maybe<Scalars['timestamptz']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  approvedby_id?: Maybe<Scalars['Int']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  euro_equivalent?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_ApprovedOn?: Maybe<Scalars['timestamp']['output']>;
  new_BonusIfany?: Maybe<Scalars['numeric']['output']>;
  new_Company?: Maybe<Scalars['uuid']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_DueDate?: Maybe<Scalars['timestamp']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Int']['output']>;
  new_POId?: Maybe<Scalars['uuid']['output']>;
  new_PaidBy?: Maybe<Scalars['uuid']['output']>;
  new_PaidOn?: Maybe<Scalars['date']['output']>;
  new_PaymentAddress?: Maybe<Scalars['String']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Int']['output']>;
  new_PaymentType?: Maybe<Scalars['Int']['output']>;
  new_PostEndDate?: Maybe<Scalars['timestamp']['output']>;
  new_PostStartDate?: Maybe<Scalars['timestamp']['output']>;
  new_ProviderInvoiceid?: Maybe<Scalars['String']['output']>;
  new_RecurringPayment?: Maybe<Scalars['uuid']['output']>;
  new_RelatedOrder?: Maybe<Scalars['uuid']['output']>;
  new_Sum?: Maybe<Scalars['numeric']['output']>;
  new_TotalContentCost?: Maybe<Scalars['numeric']['output']>;
  new_checknumber?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_paymentmethoddm?: Maybe<Scalars['uuid']['output']>;
  new_paymentmethodnew?: Maybe<Scalars['uuid']['output']>;
  new_ponumber?: Maybe<Scalars['String']['output']>;
  new_purchaseordermarkedpaidon?: Maybe<Scalars['timestamp']['output']>;
  new_servedon?: Maybe<Scalars['timestamp']['output']>;
  new_total?: Maybe<Scalars['numeric']['output']>;
  new_total_Base?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paidby_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  recurringPayment_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  status_change_reason?: Maybe<Scalars['String']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type_of_contractor?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.new_POBase" */
export type v1_new_POBase_min_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  CancelledOn?: InputMaybe<order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  DeclinedOn?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_ApprovedOn?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_DueDate?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_POId?: InputMaybe<order_by>;
  new_PaidBy?: InputMaybe<order_by>;
  new_PaidOn?: InputMaybe<order_by>;
  new_PaymentAddress?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_PostEndDate?: InputMaybe<order_by>;
  new_PostStartDate?: InputMaybe<order_by>;
  new_ProviderInvoiceid?: InputMaybe<order_by>;
  new_RecurringPayment?: InputMaybe<order_by>;
  new_RelatedOrder?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_checknumber?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_paymentmethoddm?: InputMaybe<order_by>;
  new_paymentmethodnew?: InputMaybe<order_by>;
  new_ponumber?: InputMaybe<order_by>;
  new_purchaseordermarkedpaidon?: InputMaybe<order_by>;
  new_servedon?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_change_reason?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  transactionId?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.new_POBase" */
export type v1_new_POBase_mutation_response = {
  __typename?: 'v1_new_POBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_POBase>;
};

/** input type for inserting object relation for remote table "v1.new_POBase" */
export type v1_new_POBase_obj_rel_insert_input = {
  data: v1_new_POBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_POBase_on_conflict>;
};

/** on_conflict condition type for table "v1.new_POBase" */
export type v1_new_POBase_on_conflict = {
  constraint: v1_new_POBase_constraint;
  update_columns?: Array<v1_new_POBase_update_column>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_POBase". */
export type v1_new_POBase_order_by = {
  Advertiser?: InputMaybe<v1_ContactBase_order_by>;
  AdvertiserId?: InputMaybe<order_by>;
  AdvertiserPoId_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  Approver?: InputMaybe<v1_SystemUserBase_order_by>;
  BonusSubmitter?: InputMaybe<v1_BonusSubmitter_order_by>;
  CancelledBy?: InputMaybe<order_by>;
  CancelledByUser?: InputMaybe<v1_SystemUserBase_order_by>;
  CancelledOn?: InputMaybe<order_by>;
  Company?: InputMaybe<v1_AccountBase_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  DeclinedByUser?: InputMaybe<v1_SystemUserBase_order_by>;
  DeclinedOn?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  OwnerId?: InputMaybe<order_by>;
  PaidBy?: InputMaybe<v1_SystemUserBase_order_by>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_order_by>;
  PostsToPo_aggregate?: InputMaybe<v1_PoToPosts_aggregate_order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  PurchaseOrderStatus?: InputMaybe<v1_PurchaseOrderStatus_order_by>;
  RecurringPayment?: InputMaybe<v1_RecurringPayments_order_by>;
  ServiceType?: InputMaybe<v1_ServiceTypes_order_by>;
  Supplier?: InputMaybe<v1_ContactBase_order_by>;
  SupplierPosts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  Writer?: InputMaybe<v1_ContactBase_order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  comp_publisher_total?: InputMaybe<order_by>;
  comp_total_publisher_amount?: InputMaybe<order_by>;
  comp_total_writer_amount?: InputMaybe<order_by>;
  comp_writer_total?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_ApprovedOn?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_DueDate?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_POId?: InputMaybe<order_by>;
  new_PaidBy?: InputMaybe<order_by>;
  new_PaidOn?: InputMaybe<order_by>;
  new_PaymentAddress?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_PostEndDate?: InputMaybe<order_by>;
  new_PostStartDate?: InputMaybe<order_by>;
  new_ProviderInvoiceid?: InputMaybe<order_by>;
  new_RecurringPayment?: InputMaybe<order_by>;
  new_RelatedOrder?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_checknumber?: InputMaybe<order_by>;
  new_customertype?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_paymentmethoddm?: InputMaybe<order_by>;
  new_paymentmethodnew?: InputMaybe<order_by>;
  new_ponumber?: InputMaybe<order_by>;
  new_purchaseordermarkedpaidon?: InputMaybe<order_by>;
  new_servedon?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  status_change_reason?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  transactionId?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_POBase */
export type v1_new_POBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_POBase" */
export enum v1_new_POBase_select_column {
  /** column name */
  AdvertiserId = 'AdvertiserId',
  /** column name */
  CancelledBy = 'CancelledBy',
  /** column name */
  CancelledOn = 'CancelledOn',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DeclinedBy = 'DeclinedBy',
  /** column name */
  DeclinedOn = 'DeclinedOn',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  approvedby_id = 'approvedby_id',
  /** column name */
  bonus_submitter_id = 'bonus_submitter_id',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  euro_equivalent = 'euro_equivalent',
  /** column name */
  id = 'id',
  /** column name */
  new_ApprovedOn = 'new_ApprovedOn',
  /** column name */
  new_BonusIfany = 'new_BonusIfany',
  /** column name */
  new_Company = 'new_Company',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_DueDate = 'new_DueDate',
  /** column name */
  new_FromCompanyPayment = 'new_FromCompanyPayment',
  /** column name */
  new_POId = 'new_POId',
  /** column name */
  new_PaidBy = 'new_PaidBy',
  /** column name */
  new_PaidOn = 'new_PaidOn',
  /** column name */
  new_PaymentAddress = 'new_PaymentAddress',
  /** column name */
  new_PaymentMethod = 'new_PaymentMethod',
  /** column name */
  new_PaymentType = 'new_PaymentType',
  /** column name */
  new_PostEndDate = 'new_PostEndDate',
  /** column name */
  new_PostStartDate = 'new_PostStartDate',
  /** column name */
  new_ProviderInvoiceid = 'new_ProviderInvoiceid',
  /** column name */
  new_RecurringPayment = 'new_RecurringPayment',
  /** column name */
  new_RelatedOrder = 'new_RelatedOrder',
  /** column name */
  new_Sum = 'new_Sum',
  /** column name */
  new_TotalContentCost = 'new_TotalContentCost',
  /** column name */
  new_checknumber = 'new_checknumber',
  /** column name */
  new_customertype = 'new_customertype',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_paymentmethoddm = 'new_paymentmethoddm',
  /** column name */
  new_paymentmethodnew = 'new_paymentmethodnew',
  /** column name */
  new_ponumber = 'new_ponumber',
  /** column name */
  new_purchaseordermarkedpaidon = 'new_purchaseordermarkedpaidon',
  /** column name */
  new_servedon = 'new_servedon',
  /** column name */
  new_total = 'new_total',
  /** column name */
  new_total_Base = 'new_total_Base',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paidby_id = 'paidby_id',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  po_id = 'po_id',
  /** column name */
  recurringPayment_id = 'recurringPayment_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  status_change_reason = 'status_change_reason',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  supplier_id = 'supplier_id',
  /** column name */
  transactionId = 'transactionId',
  /** column name */
  type_of_contractor = 'type_of_contractor',
  /** column name */
  writer_id = 'writer_id'
}

/** select "v1_new_POBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.new_POBase" */
export enum v1_new_POBase_select_column_v1_new_POBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  new_customertype = 'new_customertype'
}

/** select "v1_new_POBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.new_POBase" */
export enum v1_new_POBase_select_column_v1_new_POBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  new_customertype = 'new_customertype'
}

/** input type for updating data in table "v1.new_POBase" */
export type v1_new_POBase_set_input = {
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  CancelledOn?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  DeclinedBy?: InputMaybe<Scalars['Int']['input']>;
  DeclinedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  approvedby_id?: InputMaybe<Scalars['Int']['input']>;
  bonus_submitter_id?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  euro_equivalent?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_ApprovedOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_BonusIfany?: InputMaybe<Scalars['numeric']['input']>;
  new_Company?: InputMaybe<Scalars['uuid']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DueDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_FromCompanyPayment?: InputMaybe<Scalars['Int']['input']>;
  new_POId?: InputMaybe<Scalars['uuid']['input']>;
  new_PaidBy?: InputMaybe<Scalars['uuid']['input']>;
  new_PaidOn?: InputMaybe<Scalars['date']['input']>;
  new_PaymentAddress?: InputMaybe<Scalars['String']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentType?: InputMaybe<Scalars['Int']['input']>;
  new_PostEndDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_PostStartDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_ProviderInvoiceid?: InputMaybe<Scalars['String']['input']>;
  new_RecurringPayment?: InputMaybe<Scalars['uuid']['input']>;
  new_RelatedOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_Sum?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalContentCost?: InputMaybe<Scalars['numeric']['input']>;
  new_checknumber?: InputMaybe<Scalars['String']['input']>;
  new_customertype?: InputMaybe<Scalars['Boolean']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_paymentmethoddm?: InputMaybe<Scalars['uuid']['input']>;
  new_paymentmethodnew?: InputMaybe<Scalars['uuid']['input']>;
  new_ponumber?: InputMaybe<Scalars['String']['input']>;
  new_purchaseordermarkedpaidon?: InputMaybe<Scalars['timestamp']['input']>;
  new_servedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_total?: InputMaybe<Scalars['numeric']['input']>;
  new_total_Base?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paidby_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  recurringPayment_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_change_reason?: InputMaybe<Scalars['String']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type_of_contractor?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_POBase_stddev_fields = {
  __typename?: 'v1_new_POBase_stddev_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  DeclinedBy?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BonusIfany?: Maybe<Scalars['Float']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Float']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_Sum?: Maybe<Scalars['Float']['output']>;
  new_TotalContentCost?: Maybe<Scalars['Float']['output']>;
  new_total?: Maybe<Scalars['Float']['output']>;
  new_total_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paidby_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  type_of_contractor?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.new_POBase" */
export type v1_new_POBase_stddev_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_new_POBase_stddev_pop_fields = {
  __typename?: 'v1_new_POBase_stddev_pop_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  DeclinedBy?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BonusIfany?: Maybe<Scalars['Float']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Float']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_Sum?: Maybe<Scalars['Float']['output']>;
  new_TotalContentCost?: Maybe<Scalars['Float']['output']>;
  new_total?: Maybe<Scalars['Float']['output']>;
  new_total_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paidby_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  type_of_contractor?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.new_POBase" */
export type v1_new_POBase_stddev_pop_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_new_POBase_stddev_samp_fields = {
  __typename?: 'v1_new_POBase_stddev_samp_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  DeclinedBy?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BonusIfany?: Maybe<Scalars['Float']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Float']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_Sum?: Maybe<Scalars['Float']['output']>;
  new_TotalContentCost?: Maybe<Scalars['Float']['output']>;
  new_total?: Maybe<Scalars['Float']['output']>;
  new_total_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paidby_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  type_of_contractor?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.new_POBase" */
export type v1_new_POBase_stddev_samp_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_new_POBase" */
export type v1_new_POBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_POBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_POBase_stream_cursor_value_input = {
  AdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  CancelledOn?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  DeclinedBy?: InputMaybe<Scalars['Int']['input']>;
  DeclinedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  approvedby_id?: InputMaybe<Scalars['Int']['input']>;
  bonus_submitter_id?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  euro_equivalent?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_ApprovedOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_BonusIfany?: InputMaybe<Scalars['numeric']['input']>;
  new_Company?: InputMaybe<Scalars['uuid']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DueDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_FromCompanyPayment?: InputMaybe<Scalars['Int']['input']>;
  new_POId?: InputMaybe<Scalars['uuid']['input']>;
  new_PaidBy?: InputMaybe<Scalars['uuid']['input']>;
  new_PaidOn?: InputMaybe<Scalars['date']['input']>;
  new_PaymentAddress?: InputMaybe<Scalars['String']['input']>;
  new_PaymentMethod?: InputMaybe<Scalars['Int']['input']>;
  new_PaymentType?: InputMaybe<Scalars['Int']['input']>;
  new_PostEndDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_PostStartDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_ProviderInvoiceid?: InputMaybe<Scalars['String']['input']>;
  new_RecurringPayment?: InputMaybe<Scalars['uuid']['input']>;
  new_RelatedOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_Sum?: InputMaybe<Scalars['numeric']['input']>;
  new_TotalContentCost?: InputMaybe<Scalars['numeric']['input']>;
  new_checknumber?: InputMaybe<Scalars['String']['input']>;
  new_customertype?: InputMaybe<Scalars['Boolean']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_paymentmethoddm?: InputMaybe<Scalars['uuid']['input']>;
  new_paymentmethodnew?: InputMaybe<Scalars['uuid']['input']>;
  new_ponumber?: InputMaybe<Scalars['String']['input']>;
  new_purchaseordermarkedpaidon?: InputMaybe<Scalars['timestamp']['input']>;
  new_servedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_total?: InputMaybe<Scalars['numeric']['input']>;
  new_total_Base?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paidby_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  po_id?: InputMaybe<Scalars['Int']['input']>;
  recurringPayment_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  status_change_reason?: InputMaybe<Scalars['String']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  supplier_id?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type_of_contractor?: InputMaybe<Scalars['Int']['input']>;
  writer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_POBase_sum_fields = {
  __typename?: 'v1_new_POBase_sum_fields';
  AdvertiserId?: Maybe<Scalars['Int']['output']>;
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  DeclinedBy?: Maybe<Scalars['Int']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  approvedby_id?: Maybe<Scalars['Int']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  euro_equivalent?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_BonusIfany?: Maybe<Scalars['numeric']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Int']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Int']['output']>;
  new_PaymentType?: Maybe<Scalars['Int']['output']>;
  new_Sum?: Maybe<Scalars['numeric']['output']>;
  new_TotalContentCost?: Maybe<Scalars['numeric']['output']>;
  new_total?: Maybe<Scalars['numeric']['output']>;
  new_total_Base?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paidby_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  po_id?: Maybe<Scalars['Int']['output']>;
  recurringPayment_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  supplier_id?: Maybe<Scalars['Int']['output']>;
  type_of_contractor?: Maybe<Scalars['Int']['output']>;
  writer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.new_POBase" */
export type v1_new_POBase_sum_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.new_POBase" */
export enum v1_new_POBase_update_column {
  /** column name */
  AdvertiserId = 'AdvertiserId',
  /** column name */
  CancelledBy = 'CancelledBy',
  /** column name */
  CancelledOn = 'CancelledOn',
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DeclinedBy = 'DeclinedBy',
  /** column name */
  DeclinedOn = 'DeclinedOn',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  approvedby_id = 'approvedby_id',
  /** column name */
  bonus_submitter_id = 'bonus_submitter_id',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  euro_equivalent = 'euro_equivalent',
  /** column name */
  id = 'id',
  /** column name */
  new_ApprovedOn = 'new_ApprovedOn',
  /** column name */
  new_BonusIfany = 'new_BonusIfany',
  /** column name */
  new_Company = 'new_Company',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_DueDate = 'new_DueDate',
  /** column name */
  new_FromCompanyPayment = 'new_FromCompanyPayment',
  /** column name */
  new_POId = 'new_POId',
  /** column name */
  new_PaidBy = 'new_PaidBy',
  /** column name */
  new_PaidOn = 'new_PaidOn',
  /** column name */
  new_PaymentAddress = 'new_PaymentAddress',
  /** column name */
  new_PaymentMethod = 'new_PaymentMethod',
  /** column name */
  new_PaymentType = 'new_PaymentType',
  /** column name */
  new_PostEndDate = 'new_PostEndDate',
  /** column name */
  new_PostStartDate = 'new_PostStartDate',
  /** column name */
  new_ProviderInvoiceid = 'new_ProviderInvoiceid',
  /** column name */
  new_RecurringPayment = 'new_RecurringPayment',
  /** column name */
  new_RelatedOrder = 'new_RelatedOrder',
  /** column name */
  new_Sum = 'new_Sum',
  /** column name */
  new_TotalContentCost = 'new_TotalContentCost',
  /** column name */
  new_checknumber = 'new_checknumber',
  /** column name */
  new_customertype = 'new_customertype',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_paymentmethoddm = 'new_paymentmethoddm',
  /** column name */
  new_paymentmethodnew = 'new_paymentmethodnew',
  /** column name */
  new_ponumber = 'new_ponumber',
  /** column name */
  new_purchaseordermarkedpaidon = 'new_purchaseordermarkedpaidon',
  /** column name */
  new_servedon = 'new_servedon',
  /** column name */
  new_total = 'new_total',
  /** column name */
  new_total_Base = 'new_total_Base',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paidby_id = 'paidby_id',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  po_id = 'po_id',
  /** column name */
  recurringPayment_id = 'recurringPayment_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  status_change_reason = 'status_change_reason',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  supplier_id = 'supplier_id',
  /** column name */
  transactionId = 'transactionId',
  /** column name */
  type_of_contractor = 'type_of_contractor',
  /** column name */
  writer_id = 'writer_id'
}

export type v1_new_POBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_POBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_POBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_POBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_POBase_var_pop_fields = {
  __typename?: 'v1_new_POBase_var_pop_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  DeclinedBy?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BonusIfany?: Maybe<Scalars['Float']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Float']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_Sum?: Maybe<Scalars['Float']['output']>;
  new_TotalContentCost?: Maybe<Scalars['Float']['output']>;
  new_total?: Maybe<Scalars['Float']['output']>;
  new_total_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paidby_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  type_of_contractor?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.new_POBase" */
export type v1_new_POBase_var_pop_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_new_POBase_var_samp_fields = {
  __typename?: 'v1_new_POBase_var_samp_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  DeclinedBy?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BonusIfany?: Maybe<Scalars['Float']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Float']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_Sum?: Maybe<Scalars['Float']['output']>;
  new_TotalContentCost?: Maybe<Scalars['Float']['output']>;
  new_total?: Maybe<Scalars['Float']['output']>;
  new_total_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paidby_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  type_of_contractor?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.new_POBase" */
export type v1_new_POBase_var_samp_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_new_POBase_variance_fields = {
  __typename?: 'v1_new_POBase_variance_fields';
  AdvertiserId?: Maybe<Scalars['Float']['output']>;
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  DeclinedBy?: Maybe<Scalars['Float']['output']>;
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  approvedby_id?: Maybe<Scalars['Float']['output']>;
  bonus_submitter_id?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BonusIfany?: Maybe<Scalars['Float']['output']>;
  new_FromCompanyPayment?: Maybe<Scalars['Float']['output']>;
  new_PaymentMethod?: Maybe<Scalars['Float']['output']>;
  new_PaymentType?: Maybe<Scalars['Float']['output']>;
  new_Sum?: Maybe<Scalars['Float']['output']>;
  new_TotalContentCost?: Maybe<Scalars['Float']['output']>;
  new_total?: Maybe<Scalars['Float']['output']>;
  new_total_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paidby_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  po_id?: Maybe<Scalars['Float']['output']>;
  recurringPayment_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  supplier_id?: Maybe<Scalars['Float']['output']>;
  type_of_contractor?: Maybe<Scalars['Float']['output']>;
  writer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.new_POBase" */
export type v1_new_POBase_variance_order_by = {
  AdvertiserId?: InputMaybe<order_by>;
  CancelledBy?: InputMaybe<order_by>;
  DeclinedBy?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  approvedby_id?: InputMaybe<order_by>;
  bonus_submitter_id?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BonusIfany?: InputMaybe<order_by>;
  new_FromCompanyPayment?: InputMaybe<order_by>;
  new_PaymentMethod?: InputMaybe<order_by>;
  new_PaymentType?: InputMaybe<order_by>;
  new_Sum?: InputMaybe<order_by>;
  new_TotalContentCost?: InputMaybe<order_by>;
  new_total?: InputMaybe<order_by>;
  new_total_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paidby_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  po_id?: InputMaybe<order_by>;
  recurringPayment_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  supplier_id?: InputMaybe<order_by>;
  type_of_contractor?: InputMaybe<order_by>;
  writer_id?: InputMaybe<order_by>;
};

/** Affiliate Income Report */
export type v1_new_affiliateincomereportBase = {
  __typename?: 'v1_new_affiliateincomereportBase';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId: Scalars['uuid']['output'];
  OwnerIdType: Scalars['Int']['output'];
  OwningBusinessUnit?: Maybe<Scalars['uuid']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  VersionNumber: Scalars['bytea']['output'];
  id: Scalars['Int']['output'];
  new_AffiliateOrder?: Maybe<Scalars['uuid']['output']>;
  new_AffiliateProgram?: Maybe<Scalars['uuid']['output']>;
  new_Brand?: Maybe<Scalars['uuid']['output']>;
  new_CPC?: Maybe<Scalars['numeric']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['numeric']['output']>;
  new_EndDate?: Maybe<Scalars['timestamp']['output']>;
  new_FTDValue?: Maybe<Scalars['Int']['output']>;
  new_Income?: Maybe<Scalars['numeric']['output']>;
  new_LpmCampaign?: Maybe<Scalars['uuid']['output']>;
  new_Players?: Maybe<Scalars['Int']['output']>;
  new_SignUps?: Maybe<Scalars['Int']['output']>;
  new_StartDate?: Maybe<Scalars['timestamp']['output']>;
  new_Traffic?: Maybe<Scalars['Int']['output']>;
  new_TrafficGa?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_affiliateincomereportId: Scalars['uuid']['output'];
  new_income_Base?: Maybe<Scalars['numeric']['output']>;
  new_month?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "v1.new_affiliateincomereportBase" */
export type v1_new_affiliateincomereportBase_aggregate = {
  __typename?: 'v1_new_affiliateincomereportBase_aggregate';
  aggregate?: Maybe<v1_new_affiliateincomereportBase_aggregate_fields>;
  nodes: Array<v1_new_affiliateincomereportBase>;
};

/** aggregate fields of "v1.new_affiliateincomereportBase" */
export type v1_new_affiliateincomereportBase_aggregate_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_aggregate_fields';
  avg?: Maybe<v1_new_affiliateincomereportBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_affiliateincomereportBase_max_fields>;
  min?: Maybe<v1_new_affiliateincomereportBase_min_fields>;
  stddev?: Maybe<v1_new_affiliateincomereportBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_affiliateincomereportBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_affiliateincomereportBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_affiliateincomereportBase_sum_fields>;
  var_pop?: Maybe<v1_new_affiliateincomereportBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_affiliateincomereportBase_var_samp_fields>;
  variance?: Maybe<v1_new_affiliateincomereportBase_variance_fields>;
};


/** aggregate fields of "v1.new_affiliateincomereportBase" */
export type v1_new_affiliateincomereportBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_affiliateincomereportBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_new_affiliateincomereportBase_avg_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_avg_fields';
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CPC?: Maybe<Scalars['Float']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['Float']['output']>;
  new_FTDValue?: Maybe<Scalars['Float']['output']>;
  new_Income?: Maybe<Scalars['Float']['output']>;
  new_Players?: Maybe<Scalars['Float']['output']>;
  new_SignUps?: Maybe<Scalars['Float']['output']>;
  new_Traffic?: Maybe<Scalars['Float']['output']>;
  new_TrafficGa?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_income_Base?: Maybe<Scalars['Float']['output']>;
  new_month?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.new_affiliateincomereportBase". All fields are combined with a logical 'AND'. */
export type v1_new_affiliateincomereportBase_bool_exp = {
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  CreatedOnBehalfBy?: InputMaybe<uuid_comparison_exp>;
  ExchangeRate?: InputMaybe<numeric_comparison_exp>;
  ImportSequenceNumber?: InputMaybe<Int_comparison_exp>;
  ModifiedBy?: InputMaybe<uuid_comparison_exp>;
  ModifiedOn?: InputMaybe<timestamp_comparison_exp>;
  ModifiedOnBehalfBy?: InputMaybe<uuid_comparison_exp>;
  OverriddenCreatedOn?: InputMaybe<timestamp_comparison_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  OwnerIdType?: InputMaybe<Int_comparison_exp>;
  OwningBusinessUnit?: InputMaybe<uuid_comparison_exp>;
  TimeZoneRuleVersionNumber?: InputMaybe<Int_comparison_exp>;
  TransactionCurrencyId?: InputMaybe<uuid_comparison_exp>;
  UTCConversionTimeZoneCode?: InputMaybe<Int_comparison_exp>;
  VersionNumber?: InputMaybe<bytea_comparison_exp>;
  _and?: InputMaybe<Array<v1_new_affiliateincomereportBase_bool_exp>>;
  _not?: InputMaybe<v1_new_affiliateincomereportBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_affiliateincomereportBase_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_AffiliateOrder?: InputMaybe<uuid_comparison_exp>;
  new_AffiliateProgram?: InputMaybe<uuid_comparison_exp>;
  new_Brand?: InputMaybe<uuid_comparison_exp>;
  new_CPC?: InputMaybe<numeric_comparison_exp>;
  new_ConversionRateClickFtd?: InputMaybe<numeric_comparison_exp>;
  new_EndDate?: InputMaybe<timestamp_comparison_exp>;
  new_FTDValue?: InputMaybe<Int_comparison_exp>;
  new_Income?: InputMaybe<numeric_comparison_exp>;
  new_LpmCampaign?: InputMaybe<uuid_comparison_exp>;
  new_Players?: InputMaybe<Int_comparison_exp>;
  new_SignUps?: InputMaybe<Int_comparison_exp>;
  new_StartDate?: InputMaybe<timestamp_comparison_exp>;
  new_Traffic?: InputMaybe<Int_comparison_exp>;
  new_TrafficGa?: InputMaybe<Int_comparison_exp>;
  new_Year?: InputMaybe<Int_comparison_exp>;
  new_affiliateincomereportId?: InputMaybe<uuid_comparison_exp>;
  new_income_Base?: InputMaybe<numeric_comparison_exp>;
  new_month?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_affiliateincomereportBase" */
export enum v1_new_affiliateincomereportBase_constraint {
  /** unique or primary key constraint on columns "id" */
  PK_new_affiliateincomereportBase = 'PK_new_affiliateincomereportBase',
  /** unique or primary key constraint on columns "id" */
  new_affiliateincomereportBase_id_key = 'new_affiliateincomereportBase_id_key'
}

/** input type for incrementing numeric columns in table "v1.new_affiliateincomereportBase" */
export type v1_new_affiliateincomereportBase_inc_input = {
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_CPC?: InputMaybe<Scalars['numeric']['input']>;
  new_ConversionRateClickFtd?: InputMaybe<Scalars['numeric']['input']>;
  new_FTDValue?: InputMaybe<Scalars['Int']['input']>;
  new_Income?: InputMaybe<Scalars['numeric']['input']>;
  new_Players?: InputMaybe<Scalars['Int']['input']>;
  new_SignUps?: InputMaybe<Scalars['Int']['input']>;
  new_Traffic?: InputMaybe<Scalars['Int']['input']>;
  new_TrafficGa?: InputMaybe<Scalars['Int']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_income_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_month?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_affiliateincomereportBase" */
export type v1_new_affiliateincomereportBase_insert_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  OwningBusinessUnit?: InputMaybe<Scalars['uuid']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_AffiliateProgram?: InputMaybe<Scalars['uuid']['input']>;
  new_Brand?: InputMaybe<Scalars['uuid']['input']>;
  new_CPC?: InputMaybe<Scalars['numeric']['input']>;
  new_ConversionRateClickFtd?: InputMaybe<Scalars['numeric']['input']>;
  new_EndDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_FTDValue?: InputMaybe<Scalars['Int']['input']>;
  new_Income?: InputMaybe<Scalars['numeric']['input']>;
  new_LpmCampaign?: InputMaybe<Scalars['uuid']['input']>;
  new_Players?: InputMaybe<Scalars['Int']['input']>;
  new_SignUps?: InputMaybe<Scalars['Int']['input']>;
  new_StartDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_Traffic?: InputMaybe<Scalars['Int']['input']>;
  new_TrafficGa?: InputMaybe<Scalars['Int']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_affiliateincomereportId?: InputMaybe<Scalars['uuid']['input']>;
  new_income_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_month?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_affiliateincomereportBase_max_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_max_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  OwningBusinessUnit?: Maybe<Scalars['uuid']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_AffiliateOrder?: Maybe<Scalars['uuid']['output']>;
  new_AffiliateProgram?: Maybe<Scalars['uuid']['output']>;
  new_Brand?: Maybe<Scalars['uuid']['output']>;
  new_CPC?: Maybe<Scalars['numeric']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['numeric']['output']>;
  new_EndDate?: Maybe<Scalars['timestamp']['output']>;
  new_FTDValue?: Maybe<Scalars['Int']['output']>;
  new_Income?: Maybe<Scalars['numeric']['output']>;
  new_LpmCampaign?: Maybe<Scalars['uuid']['output']>;
  new_Players?: Maybe<Scalars['Int']['output']>;
  new_SignUps?: Maybe<Scalars['Int']['output']>;
  new_StartDate?: Maybe<Scalars['timestamp']['output']>;
  new_Traffic?: Maybe<Scalars['Int']['output']>;
  new_TrafficGa?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_affiliateincomereportId?: Maybe<Scalars['uuid']['output']>;
  new_income_Base?: Maybe<Scalars['numeric']['output']>;
  new_month?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_new_affiliateincomereportBase_min_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_min_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  OwningBusinessUnit?: Maybe<Scalars['uuid']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  TransactionCurrencyId?: Maybe<Scalars['uuid']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_AffiliateOrder?: Maybe<Scalars['uuid']['output']>;
  new_AffiliateProgram?: Maybe<Scalars['uuid']['output']>;
  new_Brand?: Maybe<Scalars['uuid']['output']>;
  new_CPC?: Maybe<Scalars['numeric']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['numeric']['output']>;
  new_EndDate?: Maybe<Scalars['timestamp']['output']>;
  new_FTDValue?: Maybe<Scalars['Int']['output']>;
  new_Income?: Maybe<Scalars['numeric']['output']>;
  new_LpmCampaign?: Maybe<Scalars['uuid']['output']>;
  new_Players?: Maybe<Scalars['Int']['output']>;
  new_SignUps?: Maybe<Scalars['Int']['output']>;
  new_StartDate?: Maybe<Scalars['timestamp']['output']>;
  new_Traffic?: Maybe<Scalars['Int']['output']>;
  new_TrafficGa?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_affiliateincomereportId?: Maybe<Scalars['uuid']['output']>;
  new_income_Base?: Maybe<Scalars['numeric']['output']>;
  new_month?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.new_affiliateincomereportBase" */
export type v1_new_affiliateincomereportBase_mutation_response = {
  __typename?: 'v1_new_affiliateincomereportBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_affiliateincomereportBase>;
};

/** on_conflict condition type for table "v1.new_affiliateincomereportBase" */
export type v1_new_affiliateincomereportBase_on_conflict = {
  constraint: v1_new_affiliateincomereportBase_constraint;
  update_columns?: Array<v1_new_affiliateincomereportBase_update_column>;
  where?: InputMaybe<v1_new_affiliateincomereportBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_affiliateincomereportBase". */
export type v1_new_affiliateincomereportBase_order_by = {
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CreatedOnBehalfBy?: InputMaybe<order_by>;
  ExchangeRate?: InputMaybe<order_by>;
  ImportSequenceNumber?: InputMaybe<order_by>;
  ModifiedBy?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  ModifiedOnBehalfBy?: InputMaybe<order_by>;
  OverriddenCreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  OwningBusinessUnit?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  TransactionCurrencyId?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  VersionNumber?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AffiliateOrder?: InputMaybe<order_by>;
  new_AffiliateProgram?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_CPC?: InputMaybe<order_by>;
  new_ConversionRateClickFtd?: InputMaybe<order_by>;
  new_EndDate?: InputMaybe<order_by>;
  new_FTDValue?: InputMaybe<order_by>;
  new_Income?: InputMaybe<order_by>;
  new_LpmCampaign?: InputMaybe<order_by>;
  new_Players?: InputMaybe<order_by>;
  new_SignUps?: InputMaybe<order_by>;
  new_StartDate?: InputMaybe<order_by>;
  new_Traffic?: InputMaybe<order_by>;
  new_TrafficGa?: InputMaybe<order_by>;
  new_Year?: InputMaybe<order_by>;
  new_affiliateincomereportId?: InputMaybe<order_by>;
  new_income_Base?: InputMaybe<order_by>;
  new_month?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_affiliateincomereportBase */
export type v1_new_affiliateincomereportBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_affiliateincomereportBase" */
export enum v1_new_affiliateincomereportBase_select_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CreatedOnBehalfBy = 'CreatedOnBehalfBy',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  ImportSequenceNumber = 'ImportSequenceNumber',
  /** column name */
  ModifiedBy = 'ModifiedBy',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  ModifiedOnBehalfBy = 'ModifiedOnBehalfBy',
  /** column name */
  OverriddenCreatedOn = 'OverriddenCreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  OwnerIdType = 'OwnerIdType',
  /** column name */
  OwningBusinessUnit = 'OwningBusinessUnit',
  /** column name */
  TimeZoneRuleVersionNumber = 'TimeZoneRuleVersionNumber',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  UTCConversionTimeZoneCode = 'UTCConversionTimeZoneCode',
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  id = 'id',
  /** column name */
  new_AffiliateOrder = 'new_AffiliateOrder',
  /** column name */
  new_AffiliateProgram = 'new_AffiliateProgram',
  /** column name */
  new_Brand = 'new_Brand',
  /** column name */
  new_CPC = 'new_CPC',
  /** column name */
  new_ConversionRateClickFtd = 'new_ConversionRateClickFtd',
  /** column name */
  new_EndDate = 'new_EndDate',
  /** column name */
  new_FTDValue = 'new_FTDValue',
  /** column name */
  new_Income = 'new_Income',
  /** column name */
  new_LpmCampaign = 'new_LpmCampaign',
  /** column name */
  new_Players = 'new_Players',
  /** column name */
  new_SignUps = 'new_SignUps',
  /** column name */
  new_StartDate = 'new_StartDate',
  /** column name */
  new_Traffic = 'new_Traffic',
  /** column name */
  new_TrafficGa = 'new_TrafficGa',
  /** column name */
  new_Year = 'new_Year',
  /** column name */
  new_affiliateincomereportId = 'new_affiliateincomereportId',
  /** column name */
  new_income_Base = 'new_income_Base',
  /** column name */
  new_month = 'new_month',
  /** column name */
  new_name = 'new_name',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

/** input type for updating data in table "v1.new_affiliateincomereportBase" */
export type v1_new_affiliateincomereportBase_set_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  OwningBusinessUnit?: InputMaybe<Scalars['uuid']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_AffiliateProgram?: InputMaybe<Scalars['uuid']['input']>;
  new_Brand?: InputMaybe<Scalars['uuid']['input']>;
  new_CPC?: InputMaybe<Scalars['numeric']['input']>;
  new_ConversionRateClickFtd?: InputMaybe<Scalars['numeric']['input']>;
  new_EndDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_FTDValue?: InputMaybe<Scalars['Int']['input']>;
  new_Income?: InputMaybe<Scalars['numeric']['input']>;
  new_LpmCampaign?: InputMaybe<Scalars['uuid']['input']>;
  new_Players?: InputMaybe<Scalars['Int']['input']>;
  new_SignUps?: InputMaybe<Scalars['Int']['input']>;
  new_StartDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_Traffic?: InputMaybe<Scalars['Int']['input']>;
  new_TrafficGa?: InputMaybe<Scalars['Int']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_affiliateincomereportId?: InputMaybe<Scalars['uuid']['input']>;
  new_income_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_month?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_affiliateincomereportBase_stddev_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_stddev_fields';
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CPC?: Maybe<Scalars['Float']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['Float']['output']>;
  new_FTDValue?: Maybe<Scalars['Float']['output']>;
  new_Income?: Maybe<Scalars['Float']['output']>;
  new_Players?: Maybe<Scalars['Float']['output']>;
  new_SignUps?: Maybe<Scalars['Float']['output']>;
  new_Traffic?: Maybe<Scalars['Float']['output']>;
  new_TrafficGa?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_income_Base?: Maybe<Scalars['Float']['output']>;
  new_month?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_new_affiliateincomereportBase_stddev_pop_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_stddev_pop_fields';
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CPC?: Maybe<Scalars['Float']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['Float']['output']>;
  new_FTDValue?: Maybe<Scalars['Float']['output']>;
  new_Income?: Maybe<Scalars['Float']['output']>;
  new_Players?: Maybe<Scalars['Float']['output']>;
  new_SignUps?: Maybe<Scalars['Float']['output']>;
  new_Traffic?: Maybe<Scalars['Float']['output']>;
  new_TrafficGa?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_income_Base?: Maybe<Scalars['Float']['output']>;
  new_month?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_new_affiliateincomereportBase_stddev_samp_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_stddev_samp_fields';
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CPC?: Maybe<Scalars['Float']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['Float']['output']>;
  new_FTDValue?: Maybe<Scalars['Float']['output']>;
  new_Income?: Maybe<Scalars['Float']['output']>;
  new_Players?: Maybe<Scalars['Float']['output']>;
  new_SignUps?: Maybe<Scalars['Float']['output']>;
  new_Traffic?: Maybe<Scalars['Float']['output']>;
  new_TrafficGa?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_income_Base?: Maybe<Scalars['Float']['output']>;
  new_month?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_new_affiliateincomereportBase" */
export type v1_new_affiliateincomereportBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_affiliateincomereportBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_affiliateincomereportBase_stream_cursor_value_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  ExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  OwningBusinessUnit?: InputMaybe<Scalars['uuid']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  TransactionCurrencyId?: InputMaybe<Scalars['uuid']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_AffiliateOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_AffiliateProgram?: InputMaybe<Scalars['uuid']['input']>;
  new_Brand?: InputMaybe<Scalars['uuid']['input']>;
  new_CPC?: InputMaybe<Scalars['numeric']['input']>;
  new_ConversionRateClickFtd?: InputMaybe<Scalars['numeric']['input']>;
  new_EndDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_FTDValue?: InputMaybe<Scalars['Int']['input']>;
  new_Income?: InputMaybe<Scalars['numeric']['input']>;
  new_LpmCampaign?: InputMaybe<Scalars['uuid']['input']>;
  new_Players?: InputMaybe<Scalars['Int']['input']>;
  new_SignUps?: InputMaybe<Scalars['Int']['input']>;
  new_StartDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_Traffic?: InputMaybe<Scalars['Int']['input']>;
  new_TrafficGa?: InputMaybe<Scalars['Int']['input']>;
  new_Year?: InputMaybe<Scalars['Int']['input']>;
  new_affiliateincomereportId?: InputMaybe<Scalars['uuid']['input']>;
  new_income_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_month?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_affiliateincomereportBase_sum_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_sum_fields';
  ExchangeRate?: Maybe<Scalars['numeric']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_CPC?: Maybe<Scalars['numeric']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['numeric']['output']>;
  new_FTDValue?: Maybe<Scalars['Int']['output']>;
  new_Income?: Maybe<Scalars['numeric']['output']>;
  new_Players?: Maybe<Scalars['Int']['output']>;
  new_SignUps?: Maybe<Scalars['Int']['output']>;
  new_Traffic?: Maybe<Scalars['Int']['output']>;
  new_TrafficGa?: Maybe<Scalars['Int']['output']>;
  new_Year?: Maybe<Scalars['Int']['output']>;
  new_income_Base?: Maybe<Scalars['numeric']['output']>;
  new_month?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.new_affiliateincomereportBase" */
export enum v1_new_affiliateincomereportBase_update_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CreatedOnBehalfBy = 'CreatedOnBehalfBy',
  /** column name */
  ExchangeRate = 'ExchangeRate',
  /** column name */
  ImportSequenceNumber = 'ImportSequenceNumber',
  /** column name */
  ModifiedBy = 'ModifiedBy',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  ModifiedOnBehalfBy = 'ModifiedOnBehalfBy',
  /** column name */
  OverriddenCreatedOn = 'OverriddenCreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  OwnerIdType = 'OwnerIdType',
  /** column name */
  OwningBusinessUnit = 'OwningBusinessUnit',
  /** column name */
  TimeZoneRuleVersionNumber = 'TimeZoneRuleVersionNumber',
  /** column name */
  TransactionCurrencyId = 'TransactionCurrencyId',
  /** column name */
  UTCConversionTimeZoneCode = 'UTCConversionTimeZoneCode',
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  id = 'id',
  /** column name */
  new_AffiliateOrder = 'new_AffiliateOrder',
  /** column name */
  new_AffiliateProgram = 'new_AffiliateProgram',
  /** column name */
  new_Brand = 'new_Brand',
  /** column name */
  new_CPC = 'new_CPC',
  /** column name */
  new_ConversionRateClickFtd = 'new_ConversionRateClickFtd',
  /** column name */
  new_EndDate = 'new_EndDate',
  /** column name */
  new_FTDValue = 'new_FTDValue',
  /** column name */
  new_Income = 'new_Income',
  /** column name */
  new_LpmCampaign = 'new_LpmCampaign',
  /** column name */
  new_Players = 'new_Players',
  /** column name */
  new_SignUps = 'new_SignUps',
  /** column name */
  new_StartDate = 'new_StartDate',
  /** column name */
  new_Traffic = 'new_Traffic',
  /** column name */
  new_TrafficGa = 'new_TrafficGa',
  /** column name */
  new_Year = 'new_Year',
  /** column name */
  new_affiliateincomereportId = 'new_affiliateincomereportId',
  /** column name */
  new_income_Base = 'new_income_Base',
  /** column name */
  new_month = 'new_month',
  /** column name */
  new_name = 'new_name',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

export type v1_new_affiliateincomereportBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_affiliateincomereportBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_affiliateincomereportBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_affiliateincomereportBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_affiliateincomereportBase_var_pop_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_var_pop_fields';
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CPC?: Maybe<Scalars['Float']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['Float']['output']>;
  new_FTDValue?: Maybe<Scalars['Float']['output']>;
  new_Income?: Maybe<Scalars['Float']['output']>;
  new_Players?: Maybe<Scalars['Float']['output']>;
  new_SignUps?: Maybe<Scalars['Float']['output']>;
  new_Traffic?: Maybe<Scalars['Float']['output']>;
  new_TrafficGa?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_income_Base?: Maybe<Scalars['Float']['output']>;
  new_month?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_new_affiliateincomereportBase_var_samp_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_var_samp_fields';
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CPC?: Maybe<Scalars['Float']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['Float']['output']>;
  new_FTDValue?: Maybe<Scalars['Float']['output']>;
  new_Income?: Maybe<Scalars['Float']['output']>;
  new_Players?: Maybe<Scalars['Float']['output']>;
  new_SignUps?: Maybe<Scalars['Float']['output']>;
  new_Traffic?: Maybe<Scalars['Float']['output']>;
  new_TrafficGa?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_income_Base?: Maybe<Scalars['Float']['output']>;
  new_month?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_new_affiliateincomereportBase_variance_fields = {
  __typename?: 'v1_new_affiliateincomereportBase_variance_fields';
  ExchangeRate?: Maybe<Scalars['Float']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CPC?: Maybe<Scalars['Float']['output']>;
  new_ConversionRateClickFtd?: Maybe<Scalars['Float']['output']>;
  new_FTDValue?: Maybe<Scalars['Float']['output']>;
  new_Income?: Maybe<Scalars['Float']['output']>;
  new_Players?: Maybe<Scalars['Float']['output']>;
  new_SignUps?: Maybe<Scalars['Float']['output']>;
  new_Traffic?: Maybe<Scalars['Float']['output']>;
  new_TrafficGa?: Maybe<Scalars['Float']['output']>;
  new_Year?: Maybe<Scalars['Float']['output']>;
  new_income_Base?: Maybe<Scalars['Float']['output']>;
  new_month?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase = {
  __typename?: 'v1_new_affordertypeBase';
  /** An object relationship */
  AffiliateOrderCategoriesPriority?: Maybe<v1_AffiliateOrderCategoriesPriority>;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  Orders: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  Orders_aggregate: v1_SalesOrderBase_aggregate;
  id: Scalars['Int']['output'];
  new_BrandedKeywords?: Maybe<Scalars['String']['output']>;
  new_FreeBonusKeywords?: Maybe<Scalars['String']['output']>;
  new_ProxyCheck?: Maybe<Scalars['String']['output']>;
  new_affordertypeId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_partnershare?: Maybe<Scalars['Int']['output']>;
  new_salesservice?: Maybe<Scalars['Boolean']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  profit_share?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.new_affordertypeBase" */
export type v1_new_affordertypeBaseOrdersArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** columns and relationships of "v1.new_affordertypeBase" */
export type v1_new_affordertypeBaseOrders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};

/** aggregated selection of "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_aggregate = {
  __typename?: 'v1_new_affordertypeBase_aggregate';
  aggregate?: Maybe<v1_new_affordertypeBase_aggregate_fields>;
  nodes: Array<v1_new_affordertypeBase>;
};

export type v1_new_affordertypeBase_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_new_affordertypeBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_new_affordertypeBase_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_new_affordertypeBase_aggregate_bool_exp_count>;
};

export type v1_new_affordertypeBase_aggregate_bool_exp_bool_and = {
  arguments: v1_new_affordertypeBase_select_column_v1_new_affordertypeBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_affordertypeBase_aggregate_bool_exp_bool_or = {
  arguments: v1_new_affordertypeBase_select_column_v1_new_affordertypeBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_affordertypeBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_new_affordertypeBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_aggregate_fields = {
  __typename?: 'v1_new_affordertypeBase_aggregate_fields';
  avg?: Maybe<v1_new_affordertypeBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_affordertypeBase_max_fields>;
  min?: Maybe<v1_new_affordertypeBase_min_fields>;
  stddev?: Maybe<v1_new_affordertypeBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_affordertypeBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_affordertypeBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_affordertypeBase_sum_fields>;
  var_pop?: Maybe<v1_new_affordertypeBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_affordertypeBase_var_samp_fields>;
  variance?: Maybe<v1_new_affordertypeBase_variance_fields>;
};


/** aggregate fields of "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_affordertypeBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_aggregate_order_by = {
  avg?: InputMaybe<v1_new_affordertypeBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_new_affordertypeBase_max_order_by>;
  min?: InputMaybe<v1_new_affordertypeBase_min_order_by>;
  stddev?: InputMaybe<v1_new_affordertypeBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_new_affordertypeBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_new_affordertypeBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_new_affordertypeBase_sum_order_by>;
  var_pop?: InputMaybe<v1_new_affordertypeBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_new_affordertypeBase_var_samp_order_by>;
  variance?: InputMaybe<v1_new_affordertypeBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_arr_rel_insert_input = {
  data: Array<v1_new_affordertypeBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_affordertypeBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_new_affordertypeBase_avg_fields = {
  __typename?: 'v1_new_affordertypeBase_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_partnershare?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  profit_share?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_avg_order_by = {
  id?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.new_affordertypeBase". All fields are combined with a logical 'AND'. */
export type v1_new_affordertypeBase_bool_exp = {
  AffiliateOrderCategoriesPriority?: InputMaybe<v1_AffiliateOrderCategoriesPriority_bool_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Orders?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  Orders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_new_affordertypeBase_bool_exp>>;
  _not?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_affordertypeBase_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_BrandedKeywords?: InputMaybe<String_comparison_exp>;
  new_FreeBonusKeywords?: InputMaybe<String_comparison_exp>;
  new_ProxyCheck?: InputMaybe<String_comparison_exp>;
  new_affordertypeId?: InputMaybe<uuid_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_partnershare?: InputMaybe<Int_comparison_exp>;
  new_salesservice?: InputMaybe<Boolean_comparison_exp>;
  priority_id?: InputMaybe<Int_comparison_exp>;
  profit_share?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_affordertypeBase" */
export enum v1_new_affordertypeBase_constraint {
  /** unique or primary key constraint on columns "id" */
  PK_new_affordertypeBase = 'PK_new_affordertypeBase',
  /** unique or primary key constraint on columns "id" */
  new_affordertypeBase_id_key = 'new_affordertypeBase_id_key',
  /** unique or primary key constraint on columns "new_name" */
  new_affordertypeBase_new_name_key = 'new_affordertypeBase_new_name_key'
}

/** input type for incrementing numeric columns in table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_partnershare?: InputMaybe<Scalars['Int']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  profit_share?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_insert_input = {
  AffiliateOrderCategoriesPriority?: InputMaybe<v1_AffiliateOrderCategoriesPriority_obj_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Orders?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_BrandedKeywords?: InputMaybe<Scalars['String']['input']>;
  new_FreeBonusKeywords?: InputMaybe<Scalars['String']['input']>;
  new_ProxyCheck?: InputMaybe<Scalars['String']['input']>;
  new_affordertypeId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_partnershare?: InputMaybe<Scalars['Int']['input']>;
  new_salesservice?: InputMaybe<Scalars['Boolean']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  profit_share?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_affordertypeBase_max_fields = {
  __typename?: 'v1_new_affordertypeBase_max_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_BrandedKeywords?: Maybe<Scalars['String']['output']>;
  new_FreeBonusKeywords?: Maybe<Scalars['String']['output']>;
  new_ProxyCheck?: Maybe<Scalars['String']['output']>;
  new_affordertypeId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_partnershare?: Maybe<Scalars['Int']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  profit_share?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_max_order_by = {
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BrandedKeywords?: InputMaybe<order_by>;
  new_FreeBonusKeywords?: InputMaybe<order_by>;
  new_ProxyCheck?: InputMaybe<order_by>;
  new_affordertypeId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_new_affordertypeBase_min_fields = {
  __typename?: 'v1_new_affordertypeBase_min_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_BrandedKeywords?: Maybe<Scalars['String']['output']>;
  new_FreeBonusKeywords?: Maybe<Scalars['String']['output']>;
  new_ProxyCheck?: Maybe<Scalars['String']['output']>;
  new_affordertypeId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_partnershare?: Maybe<Scalars['Int']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  profit_share?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_min_order_by = {
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BrandedKeywords?: InputMaybe<order_by>;
  new_FreeBonusKeywords?: InputMaybe<order_by>;
  new_ProxyCheck?: InputMaybe<order_by>;
  new_affordertypeId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_mutation_response = {
  __typename?: 'v1_new_affordertypeBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_affordertypeBase>;
};

/** input type for inserting object relation for remote table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_obj_rel_insert_input = {
  data: v1_new_affordertypeBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_affordertypeBase_on_conflict>;
};

/** on_conflict condition type for table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_on_conflict = {
  constraint: v1_new_affordertypeBase_constraint;
  update_columns?: Array<v1_new_affordertypeBase_update_column>;
  where?: InputMaybe<v1_new_affordertypeBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_affordertypeBase". */
export type v1_new_affordertypeBase_order_by = {
  AffiliateOrderCategoriesPriority?: InputMaybe<v1_AffiliateOrderCategoriesPriority_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Orders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  new_BrandedKeywords?: InputMaybe<order_by>;
  new_FreeBonusKeywords?: InputMaybe<order_by>;
  new_ProxyCheck?: InputMaybe<order_by>;
  new_affordertypeId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  new_salesservice?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_affordertypeBase */
export type v1_new_affordertypeBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_affordertypeBase" */
export enum v1_new_affordertypeBase_select_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_BrandedKeywords = 'new_BrandedKeywords',
  /** column name */
  new_FreeBonusKeywords = 'new_FreeBonusKeywords',
  /** column name */
  new_ProxyCheck = 'new_ProxyCheck',
  /** column name */
  new_affordertypeId = 'new_affordertypeId',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_partnershare = 'new_partnershare',
  /** column name */
  new_salesservice = 'new_salesservice',
  /** column name */
  priority_id = 'priority_id',
  /** column name */
  profit_share = 'profit_share',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

/** select "v1_new_affordertypeBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.new_affordertypeBase" */
export enum v1_new_affordertypeBase_select_column_v1_new_affordertypeBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  new_salesservice = 'new_salesservice'
}

/** select "v1_new_affordertypeBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.new_affordertypeBase" */
export enum v1_new_affordertypeBase_select_column_v1_new_affordertypeBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  new_salesservice = 'new_salesservice'
}

/** input type for updating data in table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_set_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_BrandedKeywords?: InputMaybe<Scalars['String']['input']>;
  new_FreeBonusKeywords?: InputMaybe<Scalars['String']['input']>;
  new_ProxyCheck?: InputMaybe<Scalars['String']['input']>;
  new_affordertypeId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_partnershare?: InputMaybe<Scalars['Int']['input']>;
  new_salesservice?: InputMaybe<Scalars['Boolean']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  profit_share?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_affordertypeBase_stddev_fields = {
  __typename?: 'v1_new_affordertypeBase_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_partnershare?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  profit_share?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_stddev_order_by = {
  id?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_new_affordertypeBase_stddev_pop_fields = {
  __typename?: 'v1_new_affordertypeBase_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_partnershare?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  profit_share?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_new_affordertypeBase_stddev_samp_fields = {
  __typename?: 'v1_new_affordertypeBase_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_partnershare?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  profit_share?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_new_affordertypeBase" */
export type v1_new_affordertypeBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_affordertypeBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_affordertypeBase_stream_cursor_value_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_BrandedKeywords?: InputMaybe<Scalars['String']['input']>;
  new_FreeBonusKeywords?: InputMaybe<Scalars['String']['input']>;
  new_ProxyCheck?: InputMaybe<Scalars['String']['input']>;
  new_affordertypeId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_partnershare?: InputMaybe<Scalars['Int']['input']>;
  new_salesservice?: InputMaybe<Scalars['Boolean']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  profit_share?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_affordertypeBase_sum_fields = {
  __typename?: 'v1_new_affordertypeBase_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_partnershare?: Maybe<Scalars['Int']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  profit_share?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_sum_order_by = {
  id?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** update columns of table "v1.new_affordertypeBase" */
export enum v1_new_affordertypeBase_update_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_BrandedKeywords = 'new_BrandedKeywords',
  /** column name */
  new_FreeBonusKeywords = 'new_FreeBonusKeywords',
  /** column name */
  new_ProxyCheck = 'new_ProxyCheck',
  /** column name */
  new_affordertypeId = 'new_affordertypeId',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_partnershare = 'new_partnershare',
  /** column name */
  new_salesservice = 'new_salesservice',
  /** column name */
  priority_id = 'priority_id',
  /** column name */
  profit_share = 'profit_share',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

export type v1_new_affordertypeBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_affordertypeBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_affordertypeBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_affordertypeBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_affordertypeBase_var_pop_fields = {
  __typename?: 'v1_new_affordertypeBase_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_partnershare?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  profit_share?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_new_affordertypeBase_var_samp_fields = {
  __typename?: 'v1_new_affordertypeBase_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_partnershare?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  profit_share?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_new_affordertypeBase_variance_fields = {
  __typename?: 'v1_new_affordertypeBase_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_partnershare?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  profit_share?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.new_affordertypeBase" */
export type v1_new_affordertypeBase_variance_order_by = {
  id?: InputMaybe<order_by>;
  new_partnershare?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  profit_share?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Brands */
export type v1_new_brandBase = {
  __typename?: 'v1_new_brandBase';
  /** An array relationship */
  Bonuses: Array<v1_Bonuses>;
  /** An aggregate relationship */
  Bonuses_aggregate: v1_Bonuses_aggregate;
  /** An array relationship */
  BrandAliases: Array<v1_BrandAlias>;
  /** An aggregate relationship */
  BrandAliases_aggregate: v1_BrandAlias_aggregate;
  /** An object relationship */
  BrandPriority?: Maybe<v1_BrandPriority>;
  /** An object relationship */
  Company?: Maybe<v1_AccountBase>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An array relationship */
  Pages: Array<v1_Page>;
  /** An aggregate relationship */
  Pages_aggregate: v1_Page_aggregate;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  Trackers: Array<v1_new_trackerBase>;
  /** An aggregate relationship */
  Trackers_aggregate: v1_new_trackerBase_aggregate;
  affiliate_program?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "v1.brand_related_trackers" */
  comp_related_trackers?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.brand_30_day_clicks" */
  comp_trackers_30_day_clicks?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  new_Affiliateprogram?: Maybe<Scalars['uuid']['output']>;
  new_Company?: Maybe<Scalars['uuid']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_Tracker?: Maybe<Scalars['uuid']['output']>;
  new_aliases?: Maybe<Scalars['String']['output']>;
  new_brandId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  new_new_brand_new_countryBases: Array<v1_new_new_brand_new_countryBase>;
  /** An aggregate relationship */
  new_new_brand_new_countryBases_aggregate: v1_new_new_brand_new_countryBase_aggregate;
  new_url?: Maybe<Scalars['String']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
  tracker_id?: Maybe<Scalars['Int']['output']>;
};


/** Brands */
export type v1_new_brandBaseBonusesArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** Brands */
export type v1_new_brandBaseBonuses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Bonuses_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Bonuses_order_by>>;
  where?: InputMaybe<v1_Bonuses_bool_exp>;
};


/** Brands */
export type v1_new_brandBaseBrandAliasesArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandAlias_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandAlias_order_by>>;
  where?: InputMaybe<v1_BrandAlias_bool_exp>;
};


/** Brands */
export type v1_new_brandBaseBrandAliases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BrandAlias_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BrandAlias_order_by>>;
  where?: InputMaybe<v1_BrandAlias_bool_exp>;
};


/** Brands */
export type v1_new_brandBasePagesArgs = {
  distinct_on?: InputMaybe<Array<v1_Page_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Page_order_by>>;
  where?: InputMaybe<v1_Page_bool_exp>;
};


/** Brands */
export type v1_new_brandBasePages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Page_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Page_order_by>>;
  where?: InputMaybe<v1_Page_bool_exp>;
};


/** Brands */
export type v1_new_brandBasePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Brands */
export type v1_new_brandBasePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** Brands */
export type v1_new_brandBaseTrackersArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


/** Brands */
export type v1_new_brandBaseTrackers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_trackerBase_order_by>>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};


/** Brands */
export type v1_new_brandBasenew_new_brand_new_countryBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_new_brand_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};


/** Brands */
export type v1_new_brandBasenew_new_brand_new_countryBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_new_brand_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};

/** aggregated selection of "v1.new_brandBase" */
export type v1_new_brandBase_aggregate = {
  __typename?: 'v1_new_brandBase_aggregate';
  aggregate?: Maybe<v1_new_brandBase_aggregate_fields>;
  nodes: Array<v1_new_brandBase>;
};

export type v1_new_brandBase_aggregate_bool_exp = {
  count?: InputMaybe<v1_new_brandBase_aggregate_bool_exp_count>;
};

export type v1_new_brandBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_brandBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.new_brandBase" */
export type v1_new_brandBase_aggregate_fields = {
  __typename?: 'v1_new_brandBase_aggregate_fields';
  avg?: Maybe<v1_new_brandBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_brandBase_max_fields>;
  min?: Maybe<v1_new_brandBase_min_fields>;
  stddev?: Maybe<v1_new_brandBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_brandBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_brandBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_brandBase_sum_fields>;
  var_pop?: Maybe<v1_new_brandBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_brandBase_var_samp_fields>;
  variance?: Maybe<v1_new_brandBase_variance_fields>;
};


/** aggregate fields of "v1.new_brandBase" */
export type v1_new_brandBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_brandBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.new_brandBase" */
export type v1_new_brandBase_aggregate_order_by = {
  avg?: InputMaybe<v1_new_brandBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_new_brandBase_max_order_by>;
  min?: InputMaybe<v1_new_brandBase_min_order_by>;
  stddev?: InputMaybe<v1_new_brandBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_new_brandBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_new_brandBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_new_brandBase_sum_order_by>;
  var_pop?: InputMaybe<v1_new_brandBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_new_brandBase_var_samp_order_by>;
  variance?: InputMaybe<v1_new_brandBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.new_brandBase" */
export type v1_new_brandBase_arr_rel_insert_input = {
  data: Array<v1_new_brandBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_brandBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_new_brandBase_avg_fields = {
  __typename?: 'v1_new_brandBase_avg_fields';
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_avg_order_by = {
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.new_brandBase". All fields are combined with a logical 'AND'. */
export type v1_new_brandBase_bool_exp = {
  Bonuses?: InputMaybe<v1_Bonuses_bool_exp>;
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_bool_exp>;
  BrandAliases?: InputMaybe<v1_BrandAlias_bool_exp>;
  BrandAliases_aggregate?: InputMaybe<v1_BrandAlias_aggregate_bool_exp>;
  BrandPriority?: InputMaybe<v1_BrandPriority_bool_exp>;
  Company?: InputMaybe<v1_AccountBase_bool_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Pages?: InputMaybe<v1_Page_bool_exp>;
  Pages_aggregate?: InputMaybe<v1_Page_aggregate_bool_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  Trackers?: InputMaybe<v1_new_trackerBase_bool_exp>;
  Trackers_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_new_brandBase_bool_exp>>;
  _not?: InputMaybe<v1_new_brandBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_brandBase_bool_exp>>;
  affiliate_program?: InputMaybe<String_comparison_exp>;
  comp_related_trackers?: InputMaybe<Int_comparison_exp>;
  comp_trackers_30_day_clicks?: InputMaybe<Int_comparison_exp>;
  company_id?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_Affiliateprogram?: InputMaybe<uuid_comparison_exp>;
  new_Company?: InputMaybe<uuid_comparison_exp>;
  new_Description?: InputMaybe<String_comparison_exp>;
  new_Tracker?: InputMaybe<uuid_comparison_exp>;
  new_aliases?: InputMaybe<String_comparison_exp>;
  new_brandId?: InputMaybe<uuid_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_new_brand_new_countryBases?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
  new_new_brand_new_countryBases_aggregate?: InputMaybe<v1_new_new_brand_new_countryBase_aggregate_bool_exp>;
  new_url?: InputMaybe<String_comparison_exp>;
  priority_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
  tracker_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_brandBase" */
export enum v1_new_brandBase_constraint {
  /** unique or primary key constraint on columns "id" */
  PK_new_brandBase = 'PK_new_brandBase',
  /** unique or primary key constraint on columns "id" */
  new_brandBase_id_key = 'new_brandBase_id_key'
}

/** input type for incrementing numeric columns in table "v1.new_brandBase" */
export type v1_new_brandBase_inc_input = {
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  tracker_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_brandBase" */
export type v1_new_brandBase_insert_input = {
  Bonuses?: InputMaybe<v1_Bonuses_arr_rel_insert_input>;
  BrandAliases?: InputMaybe<v1_BrandAlias_arr_rel_insert_input>;
  BrandPriority?: InputMaybe<v1_BrandPriority_obj_rel_insert_input>;
  Company?: InputMaybe<v1_AccountBase_obj_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Pages?: InputMaybe<v1_Page_arr_rel_insert_input>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  Trackers?: InputMaybe<v1_new_trackerBase_arr_rel_insert_input>;
  affiliate_program?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_Affiliateprogram?: InputMaybe<Scalars['uuid']['input']>;
  new_Company?: InputMaybe<Scalars['uuid']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_Tracker?: InputMaybe<Scalars['uuid']['input']>;
  new_aliases?: InputMaybe<Scalars['String']['input']>;
  new_brandId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_new_brand_new_countryBases?: InputMaybe<v1_new_new_brand_new_countryBase_arr_rel_insert_input>;
  new_url?: InputMaybe<Scalars['String']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  tracker_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_brandBase_max_fields = {
  __typename?: 'v1_new_brandBase_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  affiliate_program?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_Affiliateprogram?: Maybe<Scalars['uuid']['output']>;
  new_Company?: Maybe<Scalars['uuid']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_Tracker?: Maybe<Scalars['uuid']['output']>;
  new_aliases?: Maybe<Scalars['String']['output']>;
  new_brandId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_url?: Maybe<Scalars['String']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  tracker_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_max_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  affiliate_program?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Affiliateprogram?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_Tracker?: InputMaybe<order_by>;
  new_aliases?: InputMaybe<order_by>;
  new_brandId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_url?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_new_brandBase_min_fields = {
  __typename?: 'v1_new_brandBase_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  affiliate_program?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_Affiliateprogram?: Maybe<Scalars['uuid']['output']>;
  new_Company?: Maybe<Scalars['uuid']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_Tracker?: Maybe<Scalars['uuid']['output']>;
  new_aliases?: Maybe<Scalars['String']['output']>;
  new_brandId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_url?: Maybe<Scalars['String']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  tracker_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_min_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  affiliate_program?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Affiliateprogram?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_Tracker?: InputMaybe<order_by>;
  new_aliases?: InputMaybe<order_by>;
  new_brandId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_url?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.new_brandBase" */
export type v1_new_brandBase_mutation_response = {
  __typename?: 'v1_new_brandBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_brandBase>;
};

/** input type for inserting object relation for remote table "v1.new_brandBase" */
export type v1_new_brandBase_obj_rel_insert_input = {
  data: v1_new_brandBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_brandBase_on_conflict>;
};

/** on_conflict condition type for table "v1.new_brandBase" */
export type v1_new_brandBase_on_conflict = {
  constraint: v1_new_brandBase_constraint;
  update_columns?: Array<v1_new_brandBase_update_column>;
  where?: InputMaybe<v1_new_brandBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_brandBase". */
export type v1_new_brandBase_order_by = {
  Bonuses_aggregate?: InputMaybe<v1_Bonuses_aggregate_order_by>;
  BrandAliases_aggregate?: InputMaybe<v1_BrandAlias_aggregate_order_by>;
  BrandPriority?: InputMaybe<v1_BrandPriority_order_by>;
  Company?: InputMaybe<v1_AccountBase_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Pages_aggregate?: InputMaybe<v1_Page_aggregate_order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  Trackers_aggregate?: InputMaybe<v1_new_trackerBase_aggregate_order_by>;
  affiliate_program?: InputMaybe<order_by>;
  comp_related_trackers?: InputMaybe<order_by>;
  comp_trackers_30_day_clicks?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Affiliateprogram?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_Tracker?: InputMaybe<order_by>;
  new_aliases?: InputMaybe<order_by>;
  new_brandId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_new_brand_new_countryBases_aggregate?: InputMaybe<v1_new_new_brand_new_countryBase_aggregate_order_by>;
  new_url?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_brandBase */
export type v1_new_brandBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_brandBase" */
export enum v1_new_brandBase_select_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  affiliate_program = 'affiliate_program',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  id = 'id',
  /** column name */
  new_Affiliateprogram = 'new_Affiliateprogram',
  /** column name */
  new_Company = 'new_Company',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_Tracker = 'new_Tracker',
  /** column name */
  new_aliases = 'new_aliases',
  /** column name */
  new_brandId = 'new_brandId',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_url = 'new_url',
  /** column name */
  priority_id = 'priority_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  tracker_id = 'tracker_id'
}

/** input type for updating data in table "v1.new_brandBase" */
export type v1_new_brandBase_set_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  affiliate_program?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_Affiliateprogram?: InputMaybe<Scalars['uuid']['input']>;
  new_Company?: InputMaybe<Scalars['uuid']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_Tracker?: InputMaybe<Scalars['uuid']['input']>;
  new_aliases?: InputMaybe<Scalars['String']['input']>;
  new_brandId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_url?: InputMaybe<Scalars['String']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  tracker_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_brandBase_stddev_fields = {
  __typename?: 'v1_new_brandBase_stddev_fields';
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_stddev_order_by = {
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_new_brandBase_stddev_pop_fields = {
  __typename?: 'v1_new_brandBase_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_stddev_pop_order_by = {
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_new_brandBase_stddev_samp_fields = {
  __typename?: 'v1_new_brandBase_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_stddev_samp_order_by = {
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_new_brandBase" */
export type v1_new_brandBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_brandBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_brandBase_stream_cursor_value_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  affiliate_program?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_Affiliateprogram?: InputMaybe<Scalars['uuid']['input']>;
  new_Company?: InputMaybe<Scalars['uuid']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_Tracker?: InputMaybe<Scalars['uuid']['input']>;
  new_aliases?: InputMaybe<Scalars['String']['input']>;
  new_brandId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_url?: InputMaybe<Scalars['String']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
  tracker_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_brandBase_sum_fields = {
  __typename?: 'v1_new_brandBase_sum_fields';
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
  tracker_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_sum_order_by = {
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.new_brandBase" */
export enum v1_new_brandBase_update_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  affiliate_program = 'affiliate_program',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  id = 'id',
  /** column name */
  new_Affiliateprogram = 'new_Affiliateprogram',
  /** column name */
  new_Company = 'new_Company',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_Tracker = 'new_Tracker',
  /** column name */
  new_aliases = 'new_aliases',
  /** column name */
  new_brandId = 'new_brandId',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_url = 'new_url',
  /** column name */
  priority_id = 'priority_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode',
  /** column name */
  tracker_id = 'tracker_id'
}

export type v1_new_brandBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_brandBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_brandBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_brandBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_brandBase_var_pop_fields = {
  __typename?: 'v1_new_brandBase_var_pop_fields';
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_var_pop_order_by = {
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_new_brandBase_var_samp_fields = {
  __typename?: 'v1_new_brandBase_var_samp_fields';
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_var_samp_order_by = {
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_new_brandBase_variance_fields = {
  __typename?: 'v1_new_brandBase_variance_fields';
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
  tracker_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.new_brandBase" */
export type v1_new_brandBase_variance_order_by = {
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  priority_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
  tracker_id?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBase = {
  __typename?: 'v1_new_countryBase';
  /** An array relationship */
  AccountBases: Array<v1_AccountBase>;
  /** An aggregate relationship */
  AccountBases_aggregate: v1_AccountBase_aggregate;
  /** An array relationship */
  Balances: Array<v1_Balance>;
  /** An aggregate relationship */
  Balances_aggregate: v1_Balance_aggregate;
  /** An array relationship */
  BillingInformation: Array<v1_BillingInformation>;
  /** An aggregate relationship */
  BillingInformation_aggregate: v1_BillingInformation_aggregate;
  /** An array relationship */
  BillingMethod: Array<v1_BillingMethod>;
  /** An aggregate relationship */
  BillingMethod_aggregate: v1_BillingMethod_aggregate;
  /** An array relationship */
  BonusesToCountries: Array<v1_BonusesToCountries>;
  /** An aggregate relationship */
  BonusesToCountries_aggregate: v1_BonusesToCountries_aggregate;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  Project_Countries: Array<v1_Project_Countries>;
  /** An aggregate relationship */
  Project_Countries_aggregate: v1_Project_Countries_aggregate;
  /** An array relationship */
  Projects: Array<v1_Project>;
  /** An aggregate relationship */
  Projects_aggregate: v1_Project_aggregate;
  /** An array relationship */
  Users: Array<v1_ContactBase>;
  /** An aggregate relationship */
  Users_aggregate: v1_ContactBase_aggregate;
  id: Scalars['Int']['output'];
  new_IPlist?: Maybe<Scalars['String']['output']>;
  new_Offshore?: Maybe<Scalars['Boolean']['output']>;
  new_ShortCountryName?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  new_new_brand_new_countryBases: Array<v1_new_new_brand_new_countryBase>;
  /** An aggregate relationship */
  new_new_brand_new_countryBases_aggregate: v1_new_new_brand_new_countryBase_aggregate;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseAccountBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseAccountBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_AccountBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_AccountBase_order_by>>;
  where?: InputMaybe<v1_AccountBase_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseBalancesArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseBalances_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseBillingInformationArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingInformation_order_by>>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseBillingInformation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingInformation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingInformation_order_by>>;
  where?: InputMaybe<v1_BillingInformation_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseBillingMethodArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseBillingMethod_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BillingMethod_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BillingMethod_order_by>>;
  where?: InputMaybe<v1_BillingMethod_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseBonusesToCountriesArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCountries_order_by>>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseBonusesToCountries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_BonusesToCountries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_BonusesToCountries_order_by>>;
  where?: InputMaybe<v1_BonusesToCountries_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseProject_CountriesArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_Countries_order_by>>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseProject_Countries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_Countries_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_Countries_order_by>>;
  where?: InputMaybe<v1_Project_Countries_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseUsersArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBaseUsers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBasenew_new_brand_new_countryBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_new_brand_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};


/** columns and relationships of "v1.new_countryBase" */
export type v1_new_countryBasenew_new_brand_new_countryBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_new_brand_new_countryBase_order_by>>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};

/** aggregated selection of "v1.new_countryBase" */
export type v1_new_countryBase_aggregate = {
  __typename?: 'v1_new_countryBase_aggregate';
  aggregate?: Maybe<v1_new_countryBase_aggregate_fields>;
  nodes: Array<v1_new_countryBase>;
};

/** aggregate fields of "v1.new_countryBase" */
export type v1_new_countryBase_aggregate_fields = {
  __typename?: 'v1_new_countryBase_aggregate_fields';
  avg?: Maybe<v1_new_countryBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_countryBase_max_fields>;
  min?: Maybe<v1_new_countryBase_min_fields>;
  stddev?: Maybe<v1_new_countryBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_countryBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_countryBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_countryBase_sum_fields>;
  var_pop?: Maybe<v1_new_countryBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_countryBase_var_samp_fields>;
  variance?: Maybe<v1_new_countryBase_variance_fields>;
};


/** aggregate fields of "v1.new_countryBase" */
export type v1_new_countryBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_countryBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_new_countryBase_avg_fields = {
  __typename?: 'v1_new_countryBase_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.new_countryBase". All fields are combined with a logical 'AND'. */
export type v1_new_countryBase_bool_exp = {
  AccountBases?: InputMaybe<v1_AccountBase_bool_exp>;
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_bool_exp>;
  Balances?: InputMaybe<v1_Balance_bool_exp>;
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_bool_exp>;
  BillingInformation?: InputMaybe<v1_BillingInformation_bool_exp>;
  BillingInformation_aggregate?: InputMaybe<v1_BillingInformation_aggregate_bool_exp>;
  BillingMethod?: InputMaybe<v1_BillingMethod_bool_exp>;
  BillingMethod_aggregate?: InputMaybe<v1_BillingMethod_aggregate_bool_exp>;
  BonusesToCountries?: InputMaybe<v1_BonusesToCountries_bool_exp>;
  BonusesToCountries_aggregate?: InputMaybe<v1_BonusesToCountries_aggregate_bool_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Project_Countries?: InputMaybe<v1_Project_Countries_bool_exp>;
  Project_Countries_aggregate?: InputMaybe<v1_Project_Countries_aggregate_bool_exp>;
  Projects?: InputMaybe<v1_Project_bool_exp>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_bool_exp>;
  Users?: InputMaybe<v1_ContactBase_bool_exp>;
  Users_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_new_countryBase_bool_exp>>;
  _not?: InputMaybe<v1_new_countryBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_countryBase_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_IPlist?: InputMaybe<String_comparison_exp>;
  new_Offshore?: InputMaybe<Boolean_comparison_exp>;
  new_ShortCountryName?: InputMaybe<String_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_new_brand_new_countryBases?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
  new_new_brand_new_countryBases_aggregate?: InputMaybe<v1_new_new_brand_new_countryBase_aggregate_bool_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_countryBase" */
export enum v1_new_countryBase_constraint {
  /** unique or primary key constraint on columns "id" */
  new_countryBase_id_key = 'new_countryBase_id_key'
}

/** input type for incrementing numeric columns in table "v1.new_countryBase" */
export type v1_new_countryBase_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_countryBase" */
export type v1_new_countryBase_insert_input = {
  AccountBases?: InputMaybe<v1_AccountBase_arr_rel_insert_input>;
  Balances?: InputMaybe<v1_Balance_arr_rel_insert_input>;
  BillingInformation?: InputMaybe<v1_BillingInformation_arr_rel_insert_input>;
  BillingMethod?: InputMaybe<v1_BillingMethod_arr_rel_insert_input>;
  BonusesToCountries?: InputMaybe<v1_BonusesToCountries_arr_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Project_Countries?: InputMaybe<v1_Project_Countries_arr_rel_insert_input>;
  Projects?: InputMaybe<v1_Project_arr_rel_insert_input>;
  Users?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_IPlist?: InputMaybe<Scalars['String']['input']>;
  new_Offshore?: InputMaybe<Scalars['Boolean']['input']>;
  new_ShortCountryName?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_new_brand_new_countryBases?: InputMaybe<v1_new_new_brand_new_countryBase_arr_rel_insert_input>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_countryBase_max_fields = {
  __typename?: 'v1_new_countryBase_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_IPlist?: Maybe<Scalars['String']['output']>;
  new_ShortCountryName?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_new_countryBase_min_fields = {
  __typename?: 'v1_new_countryBase_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_IPlist?: Maybe<Scalars['String']['output']>;
  new_ShortCountryName?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.new_countryBase" */
export type v1_new_countryBase_mutation_response = {
  __typename?: 'v1_new_countryBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_countryBase>;
};

/** input type for inserting object relation for remote table "v1.new_countryBase" */
export type v1_new_countryBase_obj_rel_insert_input = {
  data: v1_new_countryBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_countryBase_on_conflict>;
};

/** on_conflict condition type for table "v1.new_countryBase" */
export type v1_new_countryBase_on_conflict = {
  constraint: v1_new_countryBase_constraint;
  update_columns?: Array<v1_new_countryBase_update_column>;
  where?: InputMaybe<v1_new_countryBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_countryBase". */
export type v1_new_countryBase_order_by = {
  AccountBases_aggregate?: InputMaybe<v1_AccountBase_aggregate_order_by>;
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_order_by>;
  BillingInformation_aggregate?: InputMaybe<v1_BillingInformation_aggregate_order_by>;
  BillingMethod_aggregate?: InputMaybe<v1_BillingMethod_aggregate_order_by>;
  BonusesToCountries_aggregate?: InputMaybe<v1_BonusesToCountries_aggregate_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Project_Countries_aggregate?: InputMaybe<v1_Project_Countries_aggregate_order_by>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_order_by>;
  Users_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  new_IPlist?: InputMaybe<order_by>;
  new_Offshore?: InputMaybe<order_by>;
  new_ShortCountryName?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_new_brand_new_countryBases_aggregate?: InputMaybe<v1_new_new_brand_new_countryBase_aggregate_order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** select columns of table "v1.new_countryBase" */
export enum v1_new_countryBase_select_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_IPlist = 'new_IPlist',
  /** column name */
  new_Offshore = 'new_Offshore',
  /** column name */
  new_ShortCountryName = 'new_ShortCountryName',
  /** column name */
  new_name = 'new_name',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

/** input type for updating data in table "v1.new_countryBase" */
export type v1_new_countryBase_set_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_IPlist?: InputMaybe<Scalars['String']['input']>;
  new_Offshore?: InputMaybe<Scalars['Boolean']['input']>;
  new_ShortCountryName?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_countryBase_stddev_fields = {
  __typename?: 'v1_new_countryBase_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_new_countryBase_stddev_pop_fields = {
  __typename?: 'v1_new_countryBase_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_new_countryBase_stddev_samp_fields = {
  __typename?: 'v1_new_countryBase_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_new_countryBase" */
export type v1_new_countryBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_countryBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_countryBase_stream_cursor_value_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_IPlist?: InputMaybe<Scalars['String']['input']>;
  new_Offshore?: InputMaybe<Scalars['Boolean']['input']>;
  new_ShortCountryName?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_countryBase_sum_fields = {
  __typename?: 'v1_new_countryBase_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.new_countryBase" */
export enum v1_new_countryBase_update_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_IPlist = 'new_IPlist',
  /** column name */
  new_Offshore = 'new_Offshore',
  /** column name */
  new_ShortCountryName = 'new_ShortCountryName',
  /** column name */
  new_name = 'new_name',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

export type v1_new_countryBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_countryBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_countryBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_countryBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_countryBase_var_pop_fields = {
  __typename?: 'v1_new_countryBase_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_new_countryBase_var_samp_fields = {
  __typename?: 'v1_new_countryBase_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_new_countryBase_variance_fields = {
  __typename?: 'v1_new_countryBase_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBase = {
  __typename?: 'v1_new_languagesBase';
  /** An array relationship */
  Campaigns: Array<v1_Campaign>;
  /** An aggregate relationship */
  Campaigns_aggregate: v1_Campaign_aggregate;
  /** An array relationship */
  ContactBases: Array<v1_ContactBase>;
  /** An aggregate relationship */
  ContactBases_aggregate: v1_ContactBase_aggregate;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  LanguagePriceSteps: Array<v1_LanguagePriceStep>;
  /** An aggregate relationship */
  LanguagePriceSteps_aggregate: v1_LanguagePriceStep_aggregate;
  /** An array relationship */
  MarketPlaceOrderRequests: Array<v1_MarketPlaceOrderRequest>;
  /** An aggregate relationship */
  MarketPlaceOrderRequests_aggregate: v1_MarketPlaceOrderRequest_aggregate;
  /** An array relationship */
  Offers: Array<v1_Offer>;
  /** An aggregate relationship */
  Offers_aggregate: v1_Offer_aggregate;
  /** An array relationship */
  Posts: Array<v1_Posts>;
  /** An aggregate relationship */
  Posts_aggregate: v1_Posts_aggregate;
  /** An array relationship */
  Projects: Array<v1_Project>;
  /** An aggregate relationship */
  Projects_aggregate: v1_Project_aggregate;
  /** An array relationship */
  Websites: Array<v1_Website>;
  /** An aggregate relationship */
  Websites_aggregate: v1_Website_aggregate;
  /** An array relationship */
  contactBasesByLanguageId: Array<v1_ContactBase>;
  /** An aggregate relationship */
  contactBasesByLanguageId_aggregate: v1_ContactBase_aggregate;
  id: Scalars['Int']['output'];
  new_2LettersLanguageCode?: Maybe<Scalars['String']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Int']['output']>;
  new_LanguagesConnectionId?: Maybe<Scalars['uuid']['output']>;
  new_MultiLangCheckboxName?: Maybe<Scalars['String']['output']>;
  new_MultiLangCheckboxNameForKeywords?: Maybe<Scalars['String']['output']>;
  new_RelatedCCTLD?: Maybe<Scalars['String']['output']>;
  new_SemRushDataBase?: Maybe<Scalars['String']['output']>;
  new_drupallangcode?: Maybe<Scalars['String']['output']>;
  new_languagesId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseCampaignsArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseCampaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Campaign_order_by>>;
  where?: InputMaybe<v1_Campaign_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseContactBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseContactBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseLanguagePriceStepsArgs = {
  distinct_on?: InputMaybe<Array<v1_LanguagePriceStep_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LanguagePriceStep_order_by>>;
  where?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseLanguagePriceSteps_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_LanguagePriceStep_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_LanguagePriceStep_order_by>>;
  where?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseMarketPlaceOrderRequestsArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderRequest_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseMarketPlaceOrderRequests_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_MarketPlaceOrderRequest_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_MarketPlaceOrderRequest_order_by>>;
  where?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseOffersArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseOffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Offer_order_by>>;
  where?: InputMaybe<v1_Offer_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBasePostsArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBasePosts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Posts_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Posts_order_by>>;
  where?: InputMaybe<v1_Posts_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseProjectsArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseProjects_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Project_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Project_order_by>>;
  where?: InputMaybe<v1_Project_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseWebsitesArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBaseWebsites_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Website_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Website_order_by>>;
  where?: InputMaybe<v1_Website_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBasecontactBasesByLanguageIdArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** columns and relationships of "v1.new_languagesBase" */
export type v1_new_languagesBasecontactBasesByLanguageId_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};

/** aggregated selection of "v1.new_languagesBase" */
export type v1_new_languagesBase_aggregate = {
  __typename?: 'v1_new_languagesBase_aggregate';
  aggregate?: Maybe<v1_new_languagesBase_aggregate_fields>;
  nodes: Array<v1_new_languagesBase>;
};

/** aggregate fields of "v1.new_languagesBase" */
export type v1_new_languagesBase_aggregate_fields = {
  __typename?: 'v1_new_languagesBase_aggregate_fields';
  avg?: Maybe<v1_new_languagesBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_languagesBase_max_fields>;
  min?: Maybe<v1_new_languagesBase_min_fields>;
  stddev?: Maybe<v1_new_languagesBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_languagesBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_languagesBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_languagesBase_sum_fields>;
  var_pop?: Maybe<v1_new_languagesBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_languagesBase_var_samp_fields>;
  variance?: Maybe<v1_new_languagesBase_variance_fields>;
};


/** aggregate fields of "v1.new_languagesBase" */
export type v1_new_languagesBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_languagesBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_new_languagesBase_avg_fields = {
  __typename?: 'v1_new_languagesBase_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.new_languagesBase". All fields are combined with a logical 'AND'. */
export type v1_new_languagesBase_bool_exp = {
  Campaigns?: InputMaybe<v1_Campaign_bool_exp>;
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_bool_exp>;
  ContactBases?: InputMaybe<v1_ContactBase_bool_exp>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  LanguagePriceSteps?: InputMaybe<v1_LanguagePriceStep_bool_exp>;
  LanguagePriceSteps_aggregate?: InputMaybe<v1_LanguagePriceStep_aggregate_bool_exp>;
  MarketPlaceOrderRequests?: InputMaybe<v1_MarketPlaceOrderRequest_bool_exp>;
  MarketPlaceOrderRequests_aggregate?: InputMaybe<v1_MarketPlaceOrderRequest_aggregate_bool_exp>;
  Offers?: InputMaybe<v1_Offer_bool_exp>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_bool_exp>;
  Posts?: InputMaybe<v1_Posts_bool_exp>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_bool_exp>;
  Projects?: InputMaybe<v1_Project_bool_exp>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_bool_exp>;
  Websites?: InputMaybe<v1_Website_bool_exp>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_new_languagesBase_bool_exp>>;
  _not?: InputMaybe<v1_new_languagesBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_languagesBase_bool_exp>>;
  contactBasesByLanguageId?: InputMaybe<v1_ContactBase_bool_exp>;
  contactBasesByLanguageId_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_2LettersLanguageCode?: InputMaybe<String_comparison_exp>;
  new_InternalLanguageNumber?: InputMaybe<Int_comparison_exp>;
  new_LanguagesConnectionId?: InputMaybe<uuid_comparison_exp>;
  new_MultiLangCheckboxName?: InputMaybe<String_comparison_exp>;
  new_MultiLangCheckboxNameForKeywords?: InputMaybe<String_comparison_exp>;
  new_RelatedCCTLD?: InputMaybe<String_comparison_exp>;
  new_SemRushDataBase?: InputMaybe<String_comparison_exp>;
  new_drupallangcode?: InputMaybe<String_comparison_exp>;
  new_languagesId?: InputMaybe<uuid_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_languagesBase" */
export enum v1_new_languagesBase_constraint {
  /** unique or primary key constraint on columns "id" */
  new_languagesBase_Id_key = 'new_languagesBase_Id_key',
  /** unique or primary key constraint on columns "id" */
  new_languagesBase_pkey = 'new_languagesBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.new_languagesBase" */
export type v1_new_languagesBase_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  new_InternalLanguageNumber?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_languagesBase" */
export type v1_new_languagesBase_insert_input = {
  Campaigns?: InputMaybe<v1_Campaign_arr_rel_insert_input>;
  ContactBases?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  LanguagePriceSteps?: InputMaybe<v1_LanguagePriceStep_arr_rel_insert_input>;
  MarketPlaceOrderRequests?: InputMaybe<v1_MarketPlaceOrderRequest_arr_rel_insert_input>;
  Offers?: InputMaybe<v1_Offer_arr_rel_insert_input>;
  Posts?: InputMaybe<v1_Posts_arr_rel_insert_input>;
  Projects?: InputMaybe<v1_Project_arr_rel_insert_input>;
  Websites?: InputMaybe<v1_Website_arr_rel_insert_input>;
  contactBasesByLanguageId?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_2LettersLanguageCode?: InputMaybe<Scalars['String']['input']>;
  new_InternalLanguageNumber?: InputMaybe<Scalars['Int']['input']>;
  new_LanguagesConnectionId?: InputMaybe<Scalars['uuid']['input']>;
  new_MultiLangCheckboxName?: InputMaybe<Scalars['String']['input']>;
  new_MultiLangCheckboxNameForKeywords?: InputMaybe<Scalars['String']['input']>;
  new_RelatedCCTLD?: InputMaybe<Scalars['String']['input']>;
  new_SemRushDataBase?: InputMaybe<Scalars['String']['input']>;
  new_drupallangcode?: InputMaybe<Scalars['String']['input']>;
  new_languagesId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_languagesBase_max_fields = {
  __typename?: 'v1_new_languagesBase_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_2LettersLanguageCode?: Maybe<Scalars['String']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Int']['output']>;
  new_LanguagesConnectionId?: Maybe<Scalars['uuid']['output']>;
  new_MultiLangCheckboxName?: Maybe<Scalars['String']['output']>;
  new_MultiLangCheckboxNameForKeywords?: Maybe<Scalars['String']['output']>;
  new_RelatedCCTLD?: Maybe<Scalars['String']['output']>;
  new_SemRushDataBase?: Maybe<Scalars['String']['output']>;
  new_drupallangcode?: Maybe<Scalars['String']['output']>;
  new_languagesId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_new_languagesBase_min_fields = {
  __typename?: 'v1_new_languagesBase_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_2LettersLanguageCode?: Maybe<Scalars['String']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Int']['output']>;
  new_LanguagesConnectionId?: Maybe<Scalars['uuid']['output']>;
  new_MultiLangCheckboxName?: Maybe<Scalars['String']['output']>;
  new_MultiLangCheckboxNameForKeywords?: Maybe<Scalars['String']['output']>;
  new_RelatedCCTLD?: Maybe<Scalars['String']['output']>;
  new_SemRushDataBase?: Maybe<Scalars['String']['output']>;
  new_drupallangcode?: Maybe<Scalars['String']['output']>;
  new_languagesId?: Maybe<Scalars['uuid']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.new_languagesBase" */
export type v1_new_languagesBase_mutation_response = {
  __typename?: 'v1_new_languagesBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_languagesBase>;
};

/** input type for inserting object relation for remote table "v1.new_languagesBase" */
export type v1_new_languagesBase_obj_rel_insert_input = {
  data: v1_new_languagesBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_languagesBase_on_conflict>;
};

/** on_conflict condition type for table "v1.new_languagesBase" */
export type v1_new_languagesBase_on_conflict = {
  constraint: v1_new_languagesBase_constraint;
  update_columns?: Array<v1_new_languagesBase_update_column>;
  where?: InputMaybe<v1_new_languagesBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_languagesBase". */
export type v1_new_languagesBase_order_by = {
  Campaigns_aggregate?: InputMaybe<v1_Campaign_aggregate_order_by>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  LanguagePriceSteps_aggregate?: InputMaybe<v1_LanguagePriceStep_aggregate_order_by>;
  MarketPlaceOrderRequests_aggregate?: InputMaybe<v1_MarketPlaceOrderRequest_aggregate_order_by>;
  Offers_aggregate?: InputMaybe<v1_Offer_aggregate_order_by>;
  Posts_aggregate?: InputMaybe<v1_Posts_aggregate_order_by>;
  Projects_aggregate?: InputMaybe<v1_Project_aggregate_order_by>;
  Websites_aggregate?: InputMaybe<v1_Website_aggregate_order_by>;
  contactBasesByLanguageId_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  new_2LettersLanguageCode?: InputMaybe<order_by>;
  new_InternalLanguageNumber?: InputMaybe<order_by>;
  new_LanguagesConnectionId?: InputMaybe<order_by>;
  new_MultiLangCheckboxName?: InputMaybe<order_by>;
  new_MultiLangCheckboxNameForKeywords?: InputMaybe<order_by>;
  new_RelatedCCTLD?: InputMaybe<order_by>;
  new_SemRushDataBase?: InputMaybe<order_by>;
  new_drupallangcode?: InputMaybe<order_by>;
  new_languagesId?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_languagesBase */
export type v1_new_languagesBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_languagesBase" */
export enum v1_new_languagesBase_select_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_2LettersLanguageCode = 'new_2LettersLanguageCode',
  /** column name */
  new_InternalLanguageNumber = 'new_InternalLanguageNumber',
  /** column name */
  new_LanguagesConnectionId = 'new_LanguagesConnectionId',
  /** column name */
  new_MultiLangCheckboxName = 'new_MultiLangCheckboxName',
  /** column name */
  new_MultiLangCheckboxNameForKeywords = 'new_MultiLangCheckboxNameForKeywords',
  /** column name */
  new_RelatedCCTLD = 'new_RelatedCCTLD',
  /** column name */
  new_SemRushDataBase = 'new_SemRushDataBase',
  /** column name */
  new_drupallangcode = 'new_drupallangcode',
  /** column name */
  new_languagesId = 'new_languagesId',
  /** column name */
  new_name = 'new_name',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

/** input type for updating data in table "v1.new_languagesBase" */
export type v1_new_languagesBase_set_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_2LettersLanguageCode?: InputMaybe<Scalars['String']['input']>;
  new_InternalLanguageNumber?: InputMaybe<Scalars['Int']['input']>;
  new_LanguagesConnectionId?: InputMaybe<Scalars['uuid']['input']>;
  new_MultiLangCheckboxName?: InputMaybe<Scalars['String']['input']>;
  new_MultiLangCheckboxNameForKeywords?: InputMaybe<Scalars['String']['input']>;
  new_RelatedCCTLD?: InputMaybe<Scalars['String']['input']>;
  new_SemRushDataBase?: InputMaybe<Scalars['String']['input']>;
  new_drupallangcode?: InputMaybe<Scalars['String']['input']>;
  new_languagesId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_languagesBase_stddev_fields = {
  __typename?: 'v1_new_languagesBase_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_new_languagesBase_stddev_pop_fields = {
  __typename?: 'v1_new_languagesBase_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_new_languagesBase_stddev_samp_fields = {
  __typename?: 'v1_new_languagesBase_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_new_languagesBase" */
export type v1_new_languagesBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_languagesBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_languagesBase_stream_cursor_value_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_2LettersLanguageCode?: InputMaybe<Scalars['String']['input']>;
  new_InternalLanguageNumber?: InputMaybe<Scalars['Int']['input']>;
  new_LanguagesConnectionId?: InputMaybe<Scalars['uuid']['input']>;
  new_MultiLangCheckboxName?: InputMaybe<Scalars['String']['input']>;
  new_MultiLangCheckboxNameForKeywords?: InputMaybe<Scalars['String']['input']>;
  new_RelatedCCTLD?: InputMaybe<Scalars['String']['input']>;
  new_SemRushDataBase?: InputMaybe<Scalars['String']['input']>;
  new_drupallangcode?: InputMaybe<Scalars['String']['input']>;
  new_languagesId?: InputMaybe<Scalars['uuid']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_languagesBase_sum_fields = {
  __typename?: 'v1_new_languagesBase_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.new_languagesBase" */
export enum v1_new_languagesBase_update_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_2LettersLanguageCode = 'new_2LettersLanguageCode',
  /** column name */
  new_InternalLanguageNumber = 'new_InternalLanguageNumber',
  /** column name */
  new_LanguagesConnectionId = 'new_LanguagesConnectionId',
  /** column name */
  new_MultiLangCheckboxName = 'new_MultiLangCheckboxName',
  /** column name */
  new_MultiLangCheckboxNameForKeywords = 'new_MultiLangCheckboxNameForKeywords',
  /** column name */
  new_RelatedCCTLD = 'new_RelatedCCTLD',
  /** column name */
  new_SemRushDataBase = 'new_SemRushDataBase',
  /** column name */
  new_drupallangcode = 'new_drupallangcode',
  /** column name */
  new_languagesId = 'new_languagesId',
  /** column name */
  new_name = 'new_name',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

export type v1_new_languagesBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_languagesBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_languagesBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_languagesBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_languagesBase_var_pop_fields = {
  __typename?: 'v1_new_languagesBase_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_new_languagesBase_var_samp_fields = {
  __typename?: 'v1_new_languagesBase_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_new_languagesBase_variance_fields = {
  __typename?: 'v1_new_languagesBase_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  new_InternalLanguageNumber?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase = {
  __typename?: 'v1_new_new_brand_new_countryBase';
  /** An object relationship */
  Brands?: Maybe<v1_new_brandBase>;
  /** An object relationship */
  Countries?: Maybe<v1_new_countryBase>;
  VersionNumber?: Maybe<Scalars['bytea']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  new_brandid?: Maybe<Scalars['uuid']['output']>;
  new_countryid?: Maybe<Scalars['uuid']['output']>;
  new_new_brand_new_countryId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_aggregate = {
  __typename?: 'v1_new_new_brand_new_countryBase_aggregate';
  aggregate?: Maybe<v1_new_new_brand_new_countryBase_aggregate_fields>;
  nodes: Array<v1_new_new_brand_new_countryBase>;
};

export type v1_new_new_brand_new_countryBase_aggregate_bool_exp = {
  count?: InputMaybe<v1_new_new_brand_new_countryBase_aggregate_bool_exp_count>;
};

export type v1_new_new_brand_new_countryBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_aggregate_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_aggregate_fields';
  avg?: Maybe<v1_new_new_brand_new_countryBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_new_brand_new_countryBase_max_fields>;
  min?: Maybe<v1_new_new_brand_new_countryBase_min_fields>;
  stddev?: Maybe<v1_new_new_brand_new_countryBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_new_brand_new_countryBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_new_brand_new_countryBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_new_brand_new_countryBase_sum_fields>;
  var_pop?: Maybe<v1_new_new_brand_new_countryBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_new_brand_new_countryBase_var_samp_fields>;
  variance?: Maybe<v1_new_new_brand_new_countryBase_variance_fields>;
};


/** aggregate fields of "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_new_brand_new_countryBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_aggregate_order_by = {
  avg?: InputMaybe<v1_new_new_brand_new_countryBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_new_new_brand_new_countryBase_max_order_by>;
  min?: InputMaybe<v1_new_new_brand_new_countryBase_min_order_by>;
  stddev?: InputMaybe<v1_new_new_brand_new_countryBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_new_new_brand_new_countryBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_new_new_brand_new_countryBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_new_new_brand_new_countryBase_sum_order_by>;
  var_pop?: InputMaybe<v1_new_new_brand_new_countryBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_new_new_brand_new_countryBase_var_samp_order_by>;
  variance?: InputMaybe<v1_new_new_brand_new_countryBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_arr_rel_insert_input = {
  data: Array<v1_new_new_brand_new_countryBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_new_brand_new_countryBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_new_new_brand_new_countryBase_avg_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_avg_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_avg_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.new_new_brand_new_countryBase". All fields are combined with a logical 'AND'. */
export type v1_new_new_brand_new_countryBase_bool_exp = {
  Brands?: InputMaybe<v1_new_brandBase_bool_exp>;
  Countries?: InputMaybe<v1_new_countryBase_bool_exp>;
  VersionNumber?: InputMaybe<bytea_comparison_exp>;
  _and?: InputMaybe<Array<v1_new_new_brand_new_countryBase_bool_exp>>;
  _not?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_new_brand_new_countryBase_bool_exp>>;
  brand_id?: InputMaybe<Int_comparison_exp>;
  country_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_brandid?: InputMaybe<uuid_comparison_exp>;
  new_countryid?: InputMaybe<uuid_comparison_exp>;
  new_new_brand_new_countryId?: InputMaybe<uuid_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_new_brand_new_countryBase" */
export enum v1_new_new_brand_new_countryBase_constraint {
  /** unique or primary key constraint on columns "id" */
  new_new_brand_new_countryBase_id_key = 'new_new_brand_new_countryBase_id_key',
  /** unique or primary key constraint on columns "id" */
  new_new_brand_new_countryBase_pkey = 'new_new_brand_new_countryBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_inc_input = {
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_insert_input = {
  Brands?: InputMaybe<v1_new_brandBase_obj_rel_insert_input>;
  Countries?: InputMaybe<v1_new_countryBase_obj_rel_insert_input>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_brandid?: InputMaybe<Scalars['uuid']['input']>;
  new_countryid?: InputMaybe<Scalars['uuid']['input']>;
  new_new_brand_new_countryId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type v1_new_new_brand_new_countryBase_max_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_max_fields';
  brand_id?: Maybe<Scalars['Int']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_brandid?: Maybe<Scalars['uuid']['output']>;
  new_countryid?: Maybe<Scalars['uuid']['output']>;
  new_new_brand_new_countryId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_max_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_brandid?: InputMaybe<order_by>;
  new_countryid?: InputMaybe<order_by>;
  new_new_brand_new_countryId?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_new_new_brand_new_countryBase_min_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_min_fields';
  brand_id?: Maybe<Scalars['Int']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_brandid?: Maybe<Scalars['uuid']['output']>;
  new_countryid?: Maybe<Scalars['uuid']['output']>;
  new_new_brand_new_countryId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_min_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_brandid?: InputMaybe<order_by>;
  new_countryid?: InputMaybe<order_by>;
  new_new_brand_new_countryId?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_mutation_response = {
  __typename?: 'v1_new_new_brand_new_countryBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_new_brand_new_countryBase>;
};

/** on_conflict condition type for table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_on_conflict = {
  constraint: v1_new_new_brand_new_countryBase_constraint;
  update_columns?: Array<v1_new_new_brand_new_countryBase_update_column>;
  where?: InputMaybe<v1_new_new_brand_new_countryBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_new_brand_new_countryBase". */
export type v1_new_new_brand_new_countryBase_order_by = {
  Brands?: InputMaybe<v1_new_brandBase_order_by>;
  Countries?: InputMaybe<v1_new_countryBase_order_by>;
  VersionNumber?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_brandid?: InputMaybe<order_by>;
  new_countryid?: InputMaybe<order_by>;
  new_new_brand_new_countryId?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_new_brand_new_countryBase */
export type v1_new_new_brand_new_countryBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_new_brand_new_countryBase" */
export enum v1_new_new_brand_new_countryBase_select_column {
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  country_id = 'country_id',
  /** column name */
  id = 'id',
  /** column name */
  new_brandid = 'new_brandid',
  /** column name */
  new_countryid = 'new_countryid',
  /** column name */
  new_new_brand_new_countryId = 'new_new_brand_new_countryId'
}

/** input type for updating data in table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_set_input = {
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_brandid?: InputMaybe<Scalars['uuid']['input']>;
  new_countryid?: InputMaybe<Scalars['uuid']['input']>;
  new_new_brand_new_countryId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_new_brand_new_countryBase_stddev_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_stddev_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_stddev_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_new_new_brand_new_countryBase_stddev_pop_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_stddev_pop_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_stddev_pop_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_new_new_brand_new_countryBase_stddev_samp_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_stddev_samp_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_stddev_samp_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_new_brand_new_countryBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_new_brand_new_countryBase_stream_cursor_value_input = {
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_brandid?: InputMaybe<Scalars['uuid']['input']>;
  new_countryid?: InputMaybe<Scalars['uuid']['input']>;
  new_new_brand_new_countryId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type v1_new_new_brand_new_countryBase_sum_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_sum_fields';
  brand_id?: Maybe<Scalars['Int']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_sum_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "v1.new_new_brand_new_countryBase" */
export enum v1_new_new_brand_new_countryBase_update_column {
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  country_id = 'country_id',
  /** column name */
  id = 'id',
  /** column name */
  new_brandid = 'new_brandid',
  /** column name */
  new_countryid = 'new_countryid',
  /** column name */
  new_new_brand_new_countryId = 'new_new_brand_new_countryId'
}

export type v1_new_new_brand_new_countryBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_new_brand_new_countryBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_new_brand_new_countryBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_new_brand_new_countryBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_new_brand_new_countryBase_var_pop_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_var_pop_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_var_pop_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_new_new_brand_new_countryBase_var_samp_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_var_samp_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_var_samp_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_new_new_brand_new_countryBase_variance_fields = {
  __typename?: 'v1_new_new_brand_new_countryBase_variance_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  country_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.new_new_brand_new_countryBase" */
export type v1_new_new_brand_new_countryBase_variance_order_by = {
  brand_id?: InputMaybe<order_by>;
  country_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** PaymentMethod */
export type v1_new_paymentmethodBase = {
  __typename?: 'v1_new_paymentmethodBase';
  /** An array relationship */
  Balances: Array<v1_Balance>;
  /** An aggregate relationship */
  Balances_aggregate: v1_Balance_aggregate;
  /** An array relationship */
  ContactBases: Array<v1_ContactBase>;
  /** An aggregate relationship */
  ContactBases_aggregate: v1_ContactBase_aggregate;
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  InvoiceBases: Array<v1_InvoiceBase>;
  /** An aggregate relationship */
  InvoiceBases_aggregate: v1_InvoiceBase_aggregate;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  OwningBusinessUnit?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  PayingCompany?: Maybe<v1_PaymentCompanies>;
  /** An array relationship */
  RecurringPayments: Array<v1_RecurringPayments>;
  /** An aggregate relationship */
  RecurringPayments_aggregate: v1_RecurringPayments_aggregate;
  /** An array relationship */
  SalesOrderBases: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  SalesOrderBases_aggregate: v1_SalesOrderBase_aggregate;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  VersionNumber?: Maybe<Scalars['bytea']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  new_CVV?: Maybe<Scalars['String']['output']>;
  new_CardNumber?: Maybe<Scalars['String']['output']>;
  new_Company?: Maybe<Scalars['Int']['output']>;
  new_ExpirationDate?: Maybe<Scalars['timestamp']['output']>;
  new_IncomingPayment?: Maybe<Scalars['Boolean']['output']>;
  new_NameOnCard?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  new_POBases: Array<v1_new_POBase>;
  /** An aggregate relationship */
  new_POBases_aggregate: v1_new_POBase_aggregate;
  new_PaymentDetails?: Maybe<Scalars['String']['output']>;
  new_internerid?: Maybe<Scalars['String']['output']>;
  new_internetpass?: Maybe<Scalars['String']['output']>;
  new_internetusername?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_otherdetails?: Maybe<Scalars['String']['output']>;
  new_outgoingPayment?: Maybe<Scalars['Boolean']['output']>;
  new_paymentmethodId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  new_receiptBases: Array<v1_new_receiptBase>;
  /** An aggregate relationship */
  new_receiptBases_aggregate: v1_new_receiptBase_aggregate;
  new_safeweb?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseBalancesArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseBalances_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_Balance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_Balance_order_by>>;
  where?: InputMaybe<v1_Balance_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseContactBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseContactBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_ContactBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_ContactBase_order_by>>;
  where?: InputMaybe<v1_ContactBase_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseInvoiceBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseInvoiceBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseRecurringPaymentsArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseRecurringPayments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_RecurringPayments_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_RecurringPayments_order_by>>;
  where?: InputMaybe<v1_RecurringPayments_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseSalesOrderBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBaseSalesOrderBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBasenew_POBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBasenew_POBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_POBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_POBase_order_by>>;
  where?: InputMaybe<v1_new_POBase_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBasenew_receiptBasesArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};


/** PaymentMethod */
export type v1_new_paymentmethodBasenew_receiptBases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_new_receiptBase_order_by>>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};

/** aggregated selection of "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_aggregate = {
  __typename?: 'v1_new_paymentmethodBase_aggregate';
  aggregate?: Maybe<v1_new_paymentmethodBase_aggregate_fields>;
  nodes: Array<v1_new_paymentmethodBase>;
};

export type v1_new_paymentmethodBase_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_new_paymentmethodBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_new_paymentmethodBase_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_new_paymentmethodBase_aggregate_bool_exp_count>;
};

export type v1_new_paymentmethodBase_aggregate_bool_exp_bool_and = {
  arguments: v1_new_paymentmethodBase_select_column_v1_new_paymentmethodBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_paymentmethodBase_aggregate_bool_exp_bool_or = {
  arguments: v1_new_paymentmethodBase_select_column_v1_new_paymentmethodBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_paymentmethodBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_new_paymentmethodBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_aggregate_fields = {
  __typename?: 'v1_new_paymentmethodBase_aggregate_fields';
  avg?: Maybe<v1_new_paymentmethodBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_paymentmethodBase_max_fields>;
  min?: Maybe<v1_new_paymentmethodBase_min_fields>;
  stddev?: Maybe<v1_new_paymentmethodBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_paymentmethodBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_paymentmethodBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_paymentmethodBase_sum_fields>;
  var_pop?: Maybe<v1_new_paymentmethodBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_paymentmethodBase_var_samp_fields>;
  variance?: Maybe<v1_new_paymentmethodBase_variance_fields>;
};


/** aggregate fields of "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_paymentmethodBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_aggregate_order_by = {
  avg?: InputMaybe<v1_new_paymentmethodBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_new_paymentmethodBase_max_order_by>;
  min?: InputMaybe<v1_new_paymentmethodBase_min_order_by>;
  stddev?: InputMaybe<v1_new_paymentmethodBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_new_paymentmethodBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_new_paymentmethodBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_new_paymentmethodBase_sum_order_by>;
  var_pop?: InputMaybe<v1_new_paymentmethodBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_new_paymentmethodBase_var_samp_order_by>;
  variance?: InputMaybe<v1_new_paymentmethodBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_arr_rel_insert_input = {
  data: Array<v1_new_paymentmethodBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_paymentmethodBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_new_paymentmethodBase_avg_fields = {
  __typename?: 'v1_new_paymentmethodBase_avg_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Company?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_avg_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.new_paymentmethodBase". All fields are combined with a logical 'AND'. */
export type v1_new_paymentmethodBase_bool_exp = {
  Balances?: InputMaybe<v1_Balance_bool_exp>;
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_bool_exp>;
  ContactBases?: InputMaybe<v1_ContactBase_bool_exp>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_bool_exp>;
  CreatedBy?: InputMaybe<uuid_comparison_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  CreatedOnBehalfBy?: InputMaybe<uuid_comparison_exp>;
  ImportSequenceNumber?: InputMaybe<Int_comparison_exp>;
  InvoiceBases?: InputMaybe<v1_InvoiceBase_bool_exp>;
  InvoiceBases_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  ModifiedBy?: InputMaybe<uuid_comparison_exp>;
  ModifiedOn?: InputMaybe<timestamp_comparison_exp>;
  ModifiedOnBehalfBy?: InputMaybe<uuid_comparison_exp>;
  OverriddenCreatedOn?: InputMaybe<timestamp_comparison_exp>;
  OwnerId?: InputMaybe<uuid_comparison_exp>;
  OwnerIdType?: InputMaybe<Int_comparison_exp>;
  OwningBusinessUnit?: InputMaybe<uuid_comparison_exp>;
  PayingCompany?: InputMaybe<v1_PaymentCompanies_bool_exp>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_bool_exp>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_bool_exp>;
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  TimeZoneRuleVersionNumber?: InputMaybe<Int_comparison_exp>;
  UTCConversionTimeZoneCode?: InputMaybe<Int_comparison_exp>;
  VersionNumber?: InputMaybe<bytea_comparison_exp>;
  _and?: InputMaybe<Array<v1_new_paymentmethodBase_bool_exp>>;
  _not?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_paymentmethodBase_bool_exp>>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_CVV?: InputMaybe<String_comparison_exp>;
  new_CardNumber?: InputMaybe<String_comparison_exp>;
  new_Company?: InputMaybe<Int_comparison_exp>;
  new_ExpirationDate?: InputMaybe<timestamp_comparison_exp>;
  new_IncomingPayment?: InputMaybe<Boolean_comparison_exp>;
  new_NameOnCard?: InputMaybe<String_comparison_exp>;
  new_POBases?: InputMaybe<v1_new_POBase_bool_exp>;
  new_POBases_aggregate?: InputMaybe<v1_new_POBase_aggregate_bool_exp>;
  new_PaymentDetails?: InputMaybe<String_comparison_exp>;
  new_internerid?: InputMaybe<String_comparison_exp>;
  new_internetpass?: InputMaybe<String_comparison_exp>;
  new_internetusername?: InputMaybe<String_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_otherdetails?: InputMaybe<String_comparison_exp>;
  new_outgoingPayment?: InputMaybe<Boolean_comparison_exp>;
  new_paymentmethodId?: InputMaybe<uuid_comparison_exp>;
  new_receiptBases?: InputMaybe<v1_new_receiptBase_bool_exp>;
  new_receiptBases_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp>;
  new_safeweb?: InputMaybe<String_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_paymentmethodBase" */
export enum v1_new_paymentmethodBase_constraint {
  /** unique or primary key constraint on columns "id" */
  PK_new_paymentmethodBase = 'PK_new_paymentmethodBase',
  /** unique or primary key constraint on columns "id" */
  new_paymentmethodBase_id_key = 'new_paymentmethodBase_id_key'
}

/** input type for incrementing numeric columns in table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_inc_input = {
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_Company?: InputMaybe<Scalars['Int']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_insert_input = {
  Balances?: InputMaybe<v1_Balance_arr_rel_insert_input>;
  ContactBases?: InputMaybe<v1_ContactBase_arr_rel_insert_input>;
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  InvoiceBases?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  OwningBusinessUnit?: InputMaybe<Scalars['uuid']['input']>;
  PayingCompany?: InputMaybe<v1_PaymentCompanies_obj_rel_insert_input>;
  RecurringPayments?: InputMaybe<v1_RecurringPayments_arr_rel_insert_input>;
  SalesOrderBases?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_CVV?: InputMaybe<Scalars['String']['input']>;
  new_CardNumber?: InputMaybe<Scalars['String']['input']>;
  new_Company?: InputMaybe<Scalars['Int']['input']>;
  new_ExpirationDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_IncomingPayment?: InputMaybe<Scalars['Boolean']['input']>;
  new_NameOnCard?: InputMaybe<Scalars['String']['input']>;
  new_POBases?: InputMaybe<v1_new_POBase_arr_rel_insert_input>;
  new_PaymentDetails?: InputMaybe<Scalars['String']['input']>;
  new_internerid?: InputMaybe<Scalars['String']['input']>;
  new_internetpass?: InputMaybe<Scalars['String']['input']>;
  new_internetusername?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_otherdetails?: InputMaybe<Scalars['String']['input']>;
  new_outgoingPayment?: InputMaybe<Scalars['Boolean']['input']>;
  new_paymentmethodId?: InputMaybe<Scalars['uuid']['input']>;
  new_receiptBases?: InputMaybe<v1_new_receiptBase_arr_rel_insert_input>;
  new_safeweb?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_paymentmethodBase_max_fields = {
  __typename?: 'v1_new_paymentmethodBase_max_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  OwningBusinessUnit?: Maybe<Scalars['uuid']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_CVV?: Maybe<Scalars['String']['output']>;
  new_CardNumber?: Maybe<Scalars['String']['output']>;
  new_Company?: Maybe<Scalars['Int']['output']>;
  new_ExpirationDate?: Maybe<Scalars['timestamp']['output']>;
  new_NameOnCard?: Maybe<Scalars['String']['output']>;
  new_PaymentDetails?: Maybe<Scalars['String']['output']>;
  new_internerid?: Maybe<Scalars['String']['output']>;
  new_internetpass?: Maybe<Scalars['String']['output']>;
  new_internetusername?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_otherdetails?: Maybe<Scalars['String']['output']>;
  new_paymentmethodId?: Maybe<Scalars['uuid']['output']>;
  new_safeweb?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_max_order_by = {
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CreatedOnBehalfBy?: InputMaybe<order_by>;
  ImportSequenceNumber?: InputMaybe<order_by>;
  ModifiedBy?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  ModifiedOnBehalfBy?: InputMaybe<order_by>;
  OverriddenCreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  OwningBusinessUnit?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CVV?: InputMaybe<order_by>;
  new_CardNumber?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  new_ExpirationDate?: InputMaybe<order_by>;
  new_NameOnCard?: InputMaybe<order_by>;
  new_PaymentDetails?: InputMaybe<order_by>;
  new_internerid?: InputMaybe<order_by>;
  new_internetpass?: InputMaybe<order_by>;
  new_internetusername?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_otherdetails?: InputMaybe<order_by>;
  new_paymentmethodId?: InputMaybe<order_by>;
  new_safeweb?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_new_paymentmethodBase_min_fields = {
  __typename?: 'v1_new_paymentmethodBase_min_fields';
  CreatedBy?: Maybe<Scalars['uuid']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  CreatedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  ModifiedBy?: Maybe<Scalars['uuid']['output']>;
  ModifiedOn?: Maybe<Scalars['timestamp']['output']>;
  ModifiedOnBehalfBy?: Maybe<Scalars['uuid']['output']>;
  OverriddenCreatedOn?: Maybe<Scalars['timestamp']['output']>;
  OwnerId?: Maybe<Scalars['uuid']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  OwningBusinessUnit?: Maybe<Scalars['uuid']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_CVV?: Maybe<Scalars['String']['output']>;
  new_CardNumber?: Maybe<Scalars['String']['output']>;
  new_Company?: Maybe<Scalars['Int']['output']>;
  new_ExpirationDate?: Maybe<Scalars['timestamp']['output']>;
  new_NameOnCard?: Maybe<Scalars['String']['output']>;
  new_PaymentDetails?: Maybe<Scalars['String']['output']>;
  new_internerid?: Maybe<Scalars['String']['output']>;
  new_internetpass?: Maybe<Scalars['String']['output']>;
  new_internetusername?: Maybe<Scalars['String']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_otherdetails?: Maybe<Scalars['String']['output']>;
  new_paymentmethodId?: Maybe<Scalars['uuid']['output']>;
  new_safeweb?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_min_order_by = {
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CreatedOnBehalfBy?: InputMaybe<order_by>;
  ImportSequenceNumber?: InputMaybe<order_by>;
  ModifiedBy?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  ModifiedOnBehalfBy?: InputMaybe<order_by>;
  OverriddenCreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  OwningBusinessUnit?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CVV?: InputMaybe<order_by>;
  new_CardNumber?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  new_ExpirationDate?: InputMaybe<order_by>;
  new_NameOnCard?: InputMaybe<order_by>;
  new_PaymentDetails?: InputMaybe<order_by>;
  new_internerid?: InputMaybe<order_by>;
  new_internetpass?: InputMaybe<order_by>;
  new_internetusername?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_otherdetails?: InputMaybe<order_by>;
  new_paymentmethodId?: InputMaybe<order_by>;
  new_safeweb?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_mutation_response = {
  __typename?: 'v1_new_paymentmethodBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_paymentmethodBase>;
};

/** input type for inserting object relation for remote table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_obj_rel_insert_input = {
  data: v1_new_paymentmethodBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_paymentmethodBase_on_conflict>;
};

/** on_conflict condition type for table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_on_conflict = {
  constraint: v1_new_paymentmethodBase_constraint;
  update_columns?: Array<v1_new_paymentmethodBase_update_column>;
  where?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_paymentmethodBase". */
export type v1_new_paymentmethodBase_order_by = {
  Balances_aggregate?: InputMaybe<v1_Balance_aggregate_order_by>;
  ContactBases_aggregate?: InputMaybe<v1_ContactBase_aggregate_order_by>;
  CreatedBy?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  CreatedOnBehalfBy?: InputMaybe<order_by>;
  ImportSequenceNumber?: InputMaybe<order_by>;
  InvoiceBases_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  ModifiedBy?: InputMaybe<order_by>;
  ModifiedOn?: InputMaybe<order_by>;
  ModifiedOnBehalfBy?: InputMaybe<order_by>;
  OverriddenCreatedOn?: InputMaybe<order_by>;
  OwnerId?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  OwningBusinessUnit?: InputMaybe<order_by>;
  PayingCompany?: InputMaybe<v1_PaymentCompanies_order_by>;
  RecurringPayments_aggregate?: InputMaybe<v1_RecurringPayments_aggregate_order_by>;
  SalesOrderBases_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  VersionNumber?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CVV?: InputMaybe<order_by>;
  new_CardNumber?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  new_ExpirationDate?: InputMaybe<order_by>;
  new_IncomingPayment?: InputMaybe<order_by>;
  new_NameOnCard?: InputMaybe<order_by>;
  new_POBases_aggregate?: InputMaybe<v1_new_POBase_aggregate_order_by>;
  new_PaymentDetails?: InputMaybe<order_by>;
  new_internerid?: InputMaybe<order_by>;
  new_internetpass?: InputMaybe<order_by>;
  new_internetusername?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_otherdetails?: InputMaybe<order_by>;
  new_outgoingPayment?: InputMaybe<order_by>;
  new_paymentmethodId?: InputMaybe<order_by>;
  new_receiptBases_aggregate?: InputMaybe<v1_new_receiptBase_aggregate_order_by>;
  new_safeweb?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_paymentmethodBase */
export type v1_new_paymentmethodBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_paymentmethodBase" */
export enum v1_new_paymentmethodBase_select_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CreatedOnBehalfBy = 'CreatedOnBehalfBy',
  /** column name */
  ImportSequenceNumber = 'ImportSequenceNumber',
  /** column name */
  ModifiedBy = 'ModifiedBy',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  ModifiedOnBehalfBy = 'ModifiedOnBehalfBy',
  /** column name */
  OverriddenCreatedOn = 'OverriddenCreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  OwnerIdType = 'OwnerIdType',
  /** column name */
  OwningBusinessUnit = 'OwningBusinessUnit',
  /** column name */
  TimeZoneRuleVersionNumber = 'TimeZoneRuleVersionNumber',
  /** column name */
  UTCConversionTimeZoneCode = 'UTCConversionTimeZoneCode',
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  id = 'id',
  /** column name */
  new_CVV = 'new_CVV',
  /** column name */
  new_CardNumber = 'new_CardNumber',
  /** column name */
  new_Company = 'new_Company',
  /** column name */
  new_ExpirationDate = 'new_ExpirationDate',
  /** column name */
  new_IncomingPayment = 'new_IncomingPayment',
  /** column name */
  new_NameOnCard = 'new_NameOnCard',
  /** column name */
  new_PaymentDetails = 'new_PaymentDetails',
  /** column name */
  new_internerid = 'new_internerid',
  /** column name */
  new_internetpass = 'new_internetpass',
  /** column name */
  new_internetusername = 'new_internetusername',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_otherdetails = 'new_otherdetails',
  /** column name */
  new_outgoingPayment = 'new_outgoingPayment',
  /** column name */
  new_paymentmethodId = 'new_paymentmethodId',
  /** column name */
  new_safeweb = 'new_safeweb',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

/** select "v1_new_paymentmethodBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.new_paymentmethodBase" */
export enum v1_new_paymentmethodBase_select_column_v1_new_paymentmethodBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  new_IncomingPayment = 'new_IncomingPayment',
  /** column name */
  new_outgoingPayment = 'new_outgoingPayment'
}

/** select "v1_new_paymentmethodBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.new_paymentmethodBase" */
export enum v1_new_paymentmethodBase_select_column_v1_new_paymentmethodBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  new_IncomingPayment = 'new_IncomingPayment',
  /** column name */
  new_outgoingPayment = 'new_outgoingPayment'
}

/** input type for updating data in table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_set_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  OwningBusinessUnit?: InputMaybe<Scalars['uuid']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_CVV?: InputMaybe<Scalars['String']['input']>;
  new_CardNumber?: InputMaybe<Scalars['String']['input']>;
  new_Company?: InputMaybe<Scalars['Int']['input']>;
  new_ExpirationDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_IncomingPayment?: InputMaybe<Scalars['Boolean']['input']>;
  new_NameOnCard?: InputMaybe<Scalars['String']['input']>;
  new_PaymentDetails?: InputMaybe<Scalars['String']['input']>;
  new_internerid?: InputMaybe<Scalars['String']['input']>;
  new_internetpass?: InputMaybe<Scalars['String']['input']>;
  new_internetusername?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_otherdetails?: InputMaybe<Scalars['String']['input']>;
  new_outgoingPayment?: InputMaybe<Scalars['Boolean']['input']>;
  new_paymentmethodId?: InputMaybe<Scalars['uuid']['input']>;
  new_safeweb?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_paymentmethodBase_stddev_fields = {
  __typename?: 'v1_new_paymentmethodBase_stddev_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Company?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_stddev_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_new_paymentmethodBase_stddev_pop_fields = {
  __typename?: 'v1_new_paymentmethodBase_stddev_pop_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Company?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_stddev_pop_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_new_paymentmethodBase_stddev_samp_fields = {
  __typename?: 'v1_new_paymentmethodBase_stddev_samp_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Company?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_stddev_samp_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_new_paymentmethodBase" */
export type v1_new_paymentmethodBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_paymentmethodBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_paymentmethodBase_stream_cursor_value_input = {
  CreatedBy?: InputMaybe<Scalars['uuid']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  CreatedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  ImportSequenceNumber?: InputMaybe<Scalars['Int']['input']>;
  ModifiedBy?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ModifiedOnBehalfBy?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenCreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  OwnerId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerIdType?: InputMaybe<Scalars['Int']['input']>;
  OwningBusinessUnit?: InputMaybe<Scalars['uuid']['input']>;
  TimeZoneRuleVersionNumber?: InputMaybe<Scalars['Int']['input']>;
  UTCConversionTimeZoneCode?: InputMaybe<Scalars['Int']['input']>;
  VersionNumber?: InputMaybe<Scalars['bytea']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_CVV?: InputMaybe<Scalars['String']['input']>;
  new_CardNumber?: InputMaybe<Scalars['String']['input']>;
  new_Company?: InputMaybe<Scalars['Int']['input']>;
  new_ExpirationDate?: InputMaybe<Scalars['timestamp']['input']>;
  new_IncomingPayment?: InputMaybe<Scalars['Boolean']['input']>;
  new_NameOnCard?: InputMaybe<Scalars['String']['input']>;
  new_PaymentDetails?: InputMaybe<Scalars['String']['input']>;
  new_internerid?: InputMaybe<Scalars['String']['input']>;
  new_internetpass?: InputMaybe<Scalars['String']['input']>;
  new_internetusername?: InputMaybe<Scalars['String']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_otherdetails?: InputMaybe<Scalars['String']['input']>;
  new_outgoingPayment?: InputMaybe<Scalars['Boolean']['input']>;
  new_paymentmethodId?: InputMaybe<Scalars['uuid']['input']>;
  new_safeweb?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_paymentmethodBase_sum_fields = {
  __typename?: 'v1_new_paymentmethodBase_sum_fields';
  ImportSequenceNumber?: Maybe<Scalars['Int']['output']>;
  OwnerIdType?: Maybe<Scalars['Int']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Int']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_Company?: Maybe<Scalars['Int']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_sum_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** update columns of table "v1.new_paymentmethodBase" */
export enum v1_new_paymentmethodBase_update_column {
  /** column name */
  CreatedBy = 'CreatedBy',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CreatedOnBehalfBy = 'CreatedOnBehalfBy',
  /** column name */
  ImportSequenceNumber = 'ImportSequenceNumber',
  /** column name */
  ModifiedBy = 'ModifiedBy',
  /** column name */
  ModifiedOn = 'ModifiedOn',
  /** column name */
  ModifiedOnBehalfBy = 'ModifiedOnBehalfBy',
  /** column name */
  OverriddenCreatedOn = 'OverriddenCreatedOn',
  /** column name */
  OwnerId = 'OwnerId',
  /** column name */
  OwnerIdType = 'OwnerIdType',
  /** column name */
  OwningBusinessUnit = 'OwningBusinessUnit',
  /** column name */
  TimeZoneRuleVersionNumber = 'TimeZoneRuleVersionNumber',
  /** column name */
  UTCConversionTimeZoneCode = 'UTCConversionTimeZoneCode',
  /** column name */
  VersionNumber = 'VersionNumber',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  id = 'id',
  /** column name */
  new_CVV = 'new_CVV',
  /** column name */
  new_CardNumber = 'new_CardNumber',
  /** column name */
  new_Company = 'new_Company',
  /** column name */
  new_ExpirationDate = 'new_ExpirationDate',
  /** column name */
  new_IncomingPayment = 'new_IncomingPayment',
  /** column name */
  new_NameOnCard = 'new_NameOnCard',
  /** column name */
  new_PaymentDetails = 'new_PaymentDetails',
  /** column name */
  new_internerid = 'new_internerid',
  /** column name */
  new_internetpass = 'new_internetpass',
  /** column name */
  new_internetusername = 'new_internetusername',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_otherdetails = 'new_otherdetails',
  /** column name */
  new_outgoingPayment = 'new_outgoingPayment',
  /** column name */
  new_paymentmethodId = 'new_paymentmethodId',
  /** column name */
  new_safeweb = 'new_safeweb',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

export type v1_new_paymentmethodBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_paymentmethodBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_paymentmethodBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_paymentmethodBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_paymentmethodBase_var_pop_fields = {
  __typename?: 'v1_new_paymentmethodBase_var_pop_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Company?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_var_pop_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_new_paymentmethodBase_var_samp_fields = {
  __typename?: 'v1_new_paymentmethodBase_var_samp_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Company?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_var_samp_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_new_paymentmethodBase_variance_fields = {
  __typename?: 'v1_new_paymentmethodBase_variance_fields';
  ImportSequenceNumber?: Maybe<Scalars['Float']['output']>;
  OwnerIdType?: Maybe<Scalars['Float']['output']>;
  TimeZoneRuleVersionNumber?: Maybe<Scalars['Float']['output']>;
  UTCConversionTimeZoneCode?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_Company?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.new_paymentmethodBase" */
export type v1_new_paymentmethodBase_variance_order_by = {
  ImportSequenceNumber?: InputMaybe<order_by>;
  OwnerIdType?: InputMaybe<order_by>;
  TimeZoneRuleVersionNumber?: InputMaybe<order_by>;
  UTCConversionTimeZoneCode?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_Company?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Receipts */
export type v1_new_receiptBase = {
  __typename?: 'v1_new_receiptBase';
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  CancelledByUser?: Maybe<v1_SystemUserBase>;
  CancelledOn?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  Company?: Maybe<v1_AccountBase>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  /** An array relationship */
  Invoices: Array<v1_InvoiceBase>;
  /** An aggregate relationship */
  Invoices_aggregate: v1_InvoiceBase_aggregate;
  /** An object relationship */
  Owner?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  PaymentCompany?: Maybe<v1_PaymentCompanies>;
  /** An object relationship */
  PaymentMethod?: Maybe<v1_new_paymentmethodBase>;
  ReceiptName: Scalars['String']['output'];
  /** An object relationship */
  ReceiptStatus?: Maybe<v1_ReceiptStatus>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  euro_equivalent?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['Int']['output'];
  new_AdzzPaymentMethod?: Maybe<Scalars['uuid']['output']>;
  new_BankTransferissuedon?: Maybe<Scalars['timestamp']['output']>;
  new_BankTransfertransactionid?: Maybe<Scalars['String']['output']>;
  new_CardCVV?: Maybe<Scalars['String']['output']>;
  new_CardExp?: Maybe<Scalars['timestamp']['output']>;
  new_CardHolder?: Maybe<Scalars['String']['output']>;
  new_CardType?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_FromCompany?: Maybe<Scalars['Int']['output']>;
  new_InvoicesAssigned?: Maybe<Scalars['Boolean']['output']>;
  new_LinktoInvoice?: Maybe<Scalars['uuid']['output']>;
  new_NewCustomer?: Maybe<Scalars['uuid']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Int']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Int']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Int']['output']>;
  new_Totalamount?: Maybe<Scalars['numeric']['output']>;
  new_Withholdingtax?: Maybe<Scalars['Boolean']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['numeric']['output']>;
  new_Withholdingtaxdescription?: Maybe<Scalars['String']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Int']['output']>;
  new_cardnumber?: Maybe<Scalars['Int']['output']>;
  new_checkbanknumber?: Maybe<Scalars['String']['output']>;
  new_checkdescription?: Maybe<Scalars['String']['output']>;
  new_checknumber?: Maybe<Scalars['String']['output']>;
  new_checkstatus?: Maybe<Scalars['String']['output']>;
  new_company?: Maybe<Scalars['uuid']['output']>;
  new_copyPrintUrl?: Maybe<Scalars['String']['output']>;
  new_customertype?: Maybe<Scalars['Boolean']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['numeric']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_paidon?: Maybe<Scalars['timestamp']['output']>;
  new_paymentduedate?: Maybe<Scalars['timestamp']['output']>;
  new_paypaltransactionid?: Maybe<Scalars['String']['output']>;
  new_producedon?: Maybe<Scalars['timestamp']['output']>;
  new_receiptId?: Maybe<Scalars['uuid']['output']>;
  new_receiptnumber?: Maybe<Scalars['String']['output']>;
  new_senttoaccounting?: Maybe<Scalars['timestamp']['output']>;
  new_sourcePrintUrl?: Maybe<Scalars['String']['output']>;
  new_totalamount_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalvatinshekels?: Maybe<Scalars['String']['output']>;
  new_trigger?: Maybe<Scalars['Boolean']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  /** 1 is Draft */
  statuscode: Scalars['Int']['output'];
};


/** Receipts */
export type v1_new_receiptBaseInvoicesArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};


/** Receipts */
export type v1_new_receiptBaseInvoices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_InvoiceBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_InvoiceBase_order_by>>;
  where?: InputMaybe<v1_InvoiceBase_bool_exp>;
};

/** aggregated selection of "v1.new_receiptBase" */
export type v1_new_receiptBase_aggregate = {
  __typename?: 'v1_new_receiptBase_aggregate';
  aggregate?: Maybe<v1_new_receiptBase_aggregate_fields>;
  nodes: Array<v1_new_receiptBase>;
};

export type v1_new_receiptBase_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_new_receiptBase_aggregate_bool_exp_count>;
};

export type v1_new_receiptBase_aggregate_bool_exp_bool_and = {
  arguments: v1_new_receiptBase_select_column_v1_new_receiptBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_receiptBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_receiptBase_aggregate_bool_exp_bool_or = {
  arguments: v1_new_receiptBase_select_column_v1_new_receiptBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_receiptBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_receiptBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_receiptBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.new_receiptBase" */
export type v1_new_receiptBase_aggregate_fields = {
  __typename?: 'v1_new_receiptBase_aggregate_fields';
  avg?: Maybe<v1_new_receiptBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_receiptBase_max_fields>;
  min?: Maybe<v1_new_receiptBase_min_fields>;
  stddev?: Maybe<v1_new_receiptBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_receiptBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_receiptBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_receiptBase_sum_fields>;
  var_pop?: Maybe<v1_new_receiptBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_receiptBase_var_samp_fields>;
  variance?: Maybe<v1_new_receiptBase_variance_fields>;
};


/** aggregate fields of "v1.new_receiptBase" */
export type v1_new_receiptBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_receiptBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.new_receiptBase" */
export type v1_new_receiptBase_aggregate_order_by = {
  avg?: InputMaybe<v1_new_receiptBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_new_receiptBase_max_order_by>;
  min?: InputMaybe<v1_new_receiptBase_min_order_by>;
  stddev?: InputMaybe<v1_new_receiptBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_new_receiptBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_new_receiptBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_new_receiptBase_sum_order_by>;
  var_pop?: InputMaybe<v1_new_receiptBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_new_receiptBase_var_samp_order_by>;
  variance?: InputMaybe<v1_new_receiptBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.new_receiptBase" */
export type v1_new_receiptBase_arr_rel_insert_input = {
  data: Array<v1_new_receiptBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_receiptBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_new_receiptBase_avg_fields = {
  __typename?: 'v1_new_receiptBase_avg_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CardType?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Float']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Float']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Float']['output']>;
  new_Totalamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Float']['output']>;
  new_cardnumber?: Maybe<Scalars['Float']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['Float']['output']>;
  new_totalamount_Base?: Maybe<Scalars['Float']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_avg_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.new_receiptBase". All fields are combined with a logical 'AND'. */
export type v1_new_receiptBase_bool_exp = {
  CancelledBy?: InputMaybe<Int_comparison_exp>;
  CancelledByUser?: InputMaybe<v1_SystemUserBase_bool_exp>;
  CancelledOn?: InputMaybe<timestamptz_comparison_exp>;
  Company?: InputMaybe<v1_AccountBase_bool_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  Invoices?: InputMaybe<v1_InvoiceBase_bool_exp>;
  Invoices_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_bool_exp>;
  Owner?: InputMaybe<v1_SystemUserBase_bool_exp>;
  PaymentCompany?: InputMaybe<v1_PaymentCompanies_bool_exp>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_bool_exp>;
  ReceiptName?: InputMaybe<String_comparison_exp>;
  ReceiptStatus?: InputMaybe<v1_ReceiptStatus_bool_exp>;
  _and?: InputMaybe<Array<v1_new_receiptBase_bool_exp>>;
  _not?: InputMaybe<v1_new_receiptBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_receiptBase_bool_exp>>;
  company_id?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  euro_equivalent?: InputMaybe<numeric_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  new_AdzzPaymentMethod?: InputMaybe<uuid_comparison_exp>;
  new_BankTransferissuedon?: InputMaybe<timestamp_comparison_exp>;
  new_BankTransfertransactionid?: InputMaybe<String_comparison_exp>;
  new_CardCVV?: InputMaybe<String_comparison_exp>;
  new_CardExp?: InputMaybe<timestamp_comparison_exp>;
  new_CardHolder?: InputMaybe<String_comparison_exp>;
  new_CardType?: InputMaybe<Int_comparison_exp>;
  new_CreatedByUser?: InputMaybe<String_comparison_exp>;
  new_Description?: InputMaybe<String_comparison_exp>;
  new_FromCompany?: InputMaybe<Int_comparison_exp>;
  new_InvoicesAssigned?: InputMaybe<Boolean_comparison_exp>;
  new_LinktoInvoice?: InputMaybe<uuid_comparison_exp>;
  new_NewCustomer?: InputMaybe<uuid_comparison_exp>;
  new_Paymentsnumber?: InputMaybe<Int_comparison_exp>;
  new_Taxpercentage?: InputMaybe<Int_comparison_exp>;
  new_TotalTaxAmount?: InputMaybe<Int_comparison_exp>;
  new_Totalamount?: InputMaybe<numeric_comparison_exp>;
  new_Withholdingtax?: InputMaybe<Boolean_comparison_exp>;
  new_Withholdingtaxamount?: InputMaybe<numeric_comparison_exp>;
  new_Withholdingtaxdescription?: InputMaybe<String_comparison_exp>;
  new_Withholdingtaxpercentage?: InputMaybe<Int_comparison_exp>;
  new_cardnumber?: InputMaybe<Int_comparison_exp>;
  new_checkbanknumber?: InputMaybe<String_comparison_exp>;
  new_checkdescription?: InputMaybe<String_comparison_exp>;
  new_checknumber?: InputMaybe<String_comparison_exp>;
  new_checkstatus?: InputMaybe<String_comparison_exp>;
  new_company?: InputMaybe<uuid_comparison_exp>;
  new_copyPrintUrl?: InputMaybe<String_comparison_exp>;
  new_customertype?: InputMaybe<Boolean_comparison_exp>;
  new_exchangeratebynis?: InputMaybe<numeric_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_paidon?: InputMaybe<timestamp_comparison_exp>;
  new_paymentduedate?: InputMaybe<timestamp_comparison_exp>;
  new_paypaltransactionid?: InputMaybe<String_comparison_exp>;
  new_producedon?: InputMaybe<timestamp_comparison_exp>;
  new_receiptId?: InputMaybe<uuid_comparison_exp>;
  new_receiptnumber?: InputMaybe<String_comparison_exp>;
  new_senttoaccounting?: InputMaybe<timestamp_comparison_exp>;
  new_sourcePrintUrl?: InputMaybe<String_comparison_exp>;
  new_totalamount_Base?: InputMaybe<numeric_comparison_exp>;
  new_totalvatinshekels?: InputMaybe<String_comparison_exp>;
  new_trigger?: InputMaybe<Boolean_comparison_exp>;
  new_withholdingtaxamount_Base?: InputMaybe<numeric_comparison_exp>;
  owner_id?: InputMaybe<Int_comparison_exp>;
  paymentmethod_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_receiptBase" */
export enum v1_new_receiptBase_constraint {
  /** unique or primary key constraint on columns "id" */
  new_receiptBase_id_key = 'new_receiptBase_id_key',
  /** unique or primary key constraint on columns "id" */
  new_receiptBase_pkey = 'new_receiptBase_pkey'
}

/** input type for incrementing numeric columns in table "v1.new_receiptBase" */
export type v1_new_receiptBase_inc_input = {
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  euro_equivalent?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_CardType?: InputMaybe<Scalars['Int']['input']>;
  new_FromCompany?: InputMaybe<Scalars['Int']['input']>;
  new_Paymentsnumber?: InputMaybe<Scalars['Int']['input']>;
  new_Taxpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_TotalTaxAmount?: InputMaybe<Scalars['Int']['input']>;
  new_Totalamount?: InputMaybe<Scalars['numeric']['input']>;
  new_Withholdingtaxamount?: InputMaybe<Scalars['numeric']['input']>;
  new_Withholdingtaxpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_cardnumber?: InputMaybe<Scalars['Int']['input']>;
  new_exchangeratebynis?: InputMaybe<Scalars['numeric']['input']>;
  new_totalamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_withholdingtaxamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  /** 1 is Draft */
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_receiptBase" */
export type v1_new_receiptBase_insert_input = {
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  CancelledByUser?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  CancelledOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Company?: InputMaybe<v1_AccountBase_obj_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  Invoices?: InputMaybe<v1_InvoiceBase_arr_rel_insert_input>;
  Owner?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  PaymentCompany?: InputMaybe<v1_PaymentCompanies_obj_rel_insert_input>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_obj_rel_insert_input>;
  ReceiptName?: InputMaybe<Scalars['String']['input']>;
  ReceiptStatus?: InputMaybe<v1_ReceiptStatus_obj_rel_insert_input>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  euro_equivalent?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_AdzzPaymentMethod?: InputMaybe<Scalars['uuid']['input']>;
  new_BankTransferissuedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_BankTransfertransactionid?: InputMaybe<Scalars['String']['input']>;
  new_CardCVV?: InputMaybe<Scalars['String']['input']>;
  new_CardExp?: InputMaybe<Scalars['timestamp']['input']>;
  new_CardHolder?: InputMaybe<Scalars['String']['input']>;
  new_CardType?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_FromCompany?: InputMaybe<Scalars['Int']['input']>;
  new_InvoicesAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  new_LinktoInvoice?: InputMaybe<Scalars['uuid']['input']>;
  new_NewCustomer?: InputMaybe<Scalars['uuid']['input']>;
  new_Paymentsnumber?: InputMaybe<Scalars['Int']['input']>;
  new_Taxpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_TotalTaxAmount?: InputMaybe<Scalars['Int']['input']>;
  new_Totalamount?: InputMaybe<Scalars['numeric']['input']>;
  new_Withholdingtax?: InputMaybe<Scalars['Boolean']['input']>;
  new_Withholdingtaxamount?: InputMaybe<Scalars['numeric']['input']>;
  new_Withholdingtaxdescription?: InputMaybe<Scalars['String']['input']>;
  new_Withholdingtaxpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_cardnumber?: InputMaybe<Scalars['Int']['input']>;
  new_checkbanknumber?: InputMaybe<Scalars['String']['input']>;
  new_checkdescription?: InputMaybe<Scalars['String']['input']>;
  new_checknumber?: InputMaybe<Scalars['String']['input']>;
  new_checkstatus?: InputMaybe<Scalars['String']['input']>;
  new_company?: InputMaybe<Scalars['uuid']['input']>;
  new_copyPrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_customertype?: InputMaybe<Scalars['Boolean']['input']>;
  new_exchangeratebynis?: InputMaybe<Scalars['numeric']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_paidon?: InputMaybe<Scalars['timestamp']['input']>;
  new_paymentduedate?: InputMaybe<Scalars['timestamp']['input']>;
  new_paypaltransactionid?: InputMaybe<Scalars['String']['input']>;
  new_producedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_receiptId?: InputMaybe<Scalars['uuid']['input']>;
  new_receiptnumber?: InputMaybe<Scalars['String']['input']>;
  new_senttoaccounting?: InputMaybe<Scalars['timestamp']['input']>;
  new_sourcePrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_totalamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalvatinshekels?: InputMaybe<Scalars['String']['input']>;
  new_trigger?: InputMaybe<Scalars['Boolean']['input']>;
  new_withholdingtaxamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  /** 1 is Draft */
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_receiptBase_max_fields = {
  __typename?: 'v1_new_receiptBase_max_fields';
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  CancelledOn?: Maybe<Scalars['timestamptz']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  ReceiptName?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  euro_equivalent?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_AdzzPaymentMethod?: Maybe<Scalars['uuid']['output']>;
  new_BankTransferissuedon?: Maybe<Scalars['timestamp']['output']>;
  new_BankTransfertransactionid?: Maybe<Scalars['String']['output']>;
  new_CardCVV?: Maybe<Scalars['String']['output']>;
  new_CardExp?: Maybe<Scalars['timestamp']['output']>;
  new_CardHolder?: Maybe<Scalars['String']['output']>;
  new_CardType?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_FromCompany?: Maybe<Scalars['Int']['output']>;
  new_LinktoInvoice?: Maybe<Scalars['uuid']['output']>;
  new_NewCustomer?: Maybe<Scalars['uuid']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Int']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Int']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Int']['output']>;
  new_Totalamount?: Maybe<Scalars['numeric']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['numeric']['output']>;
  new_Withholdingtaxdescription?: Maybe<Scalars['String']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Int']['output']>;
  new_cardnumber?: Maybe<Scalars['Int']['output']>;
  new_checkbanknumber?: Maybe<Scalars['String']['output']>;
  new_checkdescription?: Maybe<Scalars['String']['output']>;
  new_checknumber?: Maybe<Scalars['String']['output']>;
  new_checkstatus?: Maybe<Scalars['String']['output']>;
  new_company?: Maybe<Scalars['uuid']['output']>;
  new_copyPrintUrl?: Maybe<Scalars['String']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['numeric']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_paidon?: Maybe<Scalars['timestamp']['output']>;
  new_paymentduedate?: Maybe<Scalars['timestamp']['output']>;
  new_paypaltransactionid?: Maybe<Scalars['String']['output']>;
  new_producedon?: Maybe<Scalars['timestamp']['output']>;
  new_receiptId?: Maybe<Scalars['uuid']['output']>;
  new_receiptnumber?: Maybe<Scalars['String']['output']>;
  new_senttoaccounting?: Maybe<Scalars['timestamp']['output']>;
  new_sourcePrintUrl?: Maybe<Scalars['String']['output']>;
  new_totalamount_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalvatinshekels?: Maybe<Scalars['String']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_max_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  CancelledOn?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  ReceiptName?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AdzzPaymentMethod?: InputMaybe<order_by>;
  new_BankTransferissuedon?: InputMaybe<order_by>;
  new_BankTransfertransactionid?: InputMaybe<order_by>;
  new_CardCVV?: InputMaybe<order_by>;
  new_CardExp?: InputMaybe<order_by>;
  new_CardHolder?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_LinktoInvoice?: InputMaybe<order_by>;
  new_NewCustomer?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxdescription?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_checkbanknumber?: InputMaybe<order_by>;
  new_checkdescription?: InputMaybe<order_by>;
  new_checknumber?: InputMaybe<order_by>;
  new_checkstatus?: InputMaybe<order_by>;
  new_company?: InputMaybe<order_by>;
  new_copyPrintUrl?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_paidon?: InputMaybe<order_by>;
  new_paymentduedate?: InputMaybe<order_by>;
  new_paypaltransactionid?: InputMaybe<order_by>;
  new_producedon?: InputMaybe<order_by>;
  new_receiptId?: InputMaybe<order_by>;
  new_receiptnumber?: InputMaybe<order_by>;
  new_senttoaccounting?: InputMaybe<order_by>;
  new_sourcePrintUrl?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_totalvatinshekels?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_new_receiptBase_min_fields = {
  __typename?: 'v1_new_receiptBase_min_fields';
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  CancelledOn?: Maybe<Scalars['timestamptz']['output']>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  ReceiptName?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  euro_equivalent?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_AdzzPaymentMethod?: Maybe<Scalars['uuid']['output']>;
  new_BankTransferissuedon?: Maybe<Scalars['timestamp']['output']>;
  new_BankTransfertransactionid?: Maybe<Scalars['String']['output']>;
  new_CardCVV?: Maybe<Scalars['String']['output']>;
  new_CardExp?: Maybe<Scalars['timestamp']['output']>;
  new_CardHolder?: Maybe<Scalars['String']['output']>;
  new_CardType?: Maybe<Scalars['Int']['output']>;
  new_CreatedByUser?: Maybe<Scalars['String']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_FromCompany?: Maybe<Scalars['Int']['output']>;
  new_LinktoInvoice?: Maybe<Scalars['uuid']['output']>;
  new_NewCustomer?: Maybe<Scalars['uuid']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Int']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Int']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Int']['output']>;
  new_Totalamount?: Maybe<Scalars['numeric']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['numeric']['output']>;
  new_Withholdingtaxdescription?: Maybe<Scalars['String']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Int']['output']>;
  new_cardnumber?: Maybe<Scalars['Int']['output']>;
  new_checkbanknumber?: Maybe<Scalars['String']['output']>;
  new_checkdescription?: Maybe<Scalars['String']['output']>;
  new_checknumber?: Maybe<Scalars['String']['output']>;
  new_checkstatus?: Maybe<Scalars['String']['output']>;
  new_company?: Maybe<Scalars['uuid']['output']>;
  new_copyPrintUrl?: Maybe<Scalars['String']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['numeric']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_paidon?: Maybe<Scalars['timestamp']['output']>;
  new_paymentduedate?: Maybe<Scalars['timestamp']['output']>;
  new_paypaltransactionid?: Maybe<Scalars['String']['output']>;
  new_producedon?: Maybe<Scalars['timestamp']['output']>;
  new_receiptId?: Maybe<Scalars['uuid']['output']>;
  new_receiptnumber?: Maybe<Scalars['String']['output']>;
  new_senttoaccounting?: Maybe<Scalars['timestamp']['output']>;
  new_sourcePrintUrl?: Maybe<Scalars['String']['output']>;
  new_totalamount_Base?: Maybe<Scalars['numeric']['output']>;
  new_totalvatinshekels?: Maybe<Scalars['String']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_min_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  CancelledOn?: InputMaybe<order_by>;
  CreatedOn?: InputMaybe<order_by>;
  ReceiptName?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AdzzPaymentMethod?: InputMaybe<order_by>;
  new_BankTransferissuedon?: InputMaybe<order_by>;
  new_BankTransfertransactionid?: InputMaybe<order_by>;
  new_CardCVV?: InputMaybe<order_by>;
  new_CardExp?: InputMaybe<order_by>;
  new_CardHolder?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_LinktoInvoice?: InputMaybe<order_by>;
  new_NewCustomer?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxdescription?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_checkbanknumber?: InputMaybe<order_by>;
  new_checkdescription?: InputMaybe<order_by>;
  new_checknumber?: InputMaybe<order_by>;
  new_checkstatus?: InputMaybe<order_by>;
  new_company?: InputMaybe<order_by>;
  new_copyPrintUrl?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_paidon?: InputMaybe<order_by>;
  new_paymentduedate?: InputMaybe<order_by>;
  new_paypaltransactionid?: InputMaybe<order_by>;
  new_producedon?: InputMaybe<order_by>;
  new_receiptId?: InputMaybe<order_by>;
  new_receiptnumber?: InputMaybe<order_by>;
  new_senttoaccounting?: InputMaybe<order_by>;
  new_sourcePrintUrl?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_totalvatinshekels?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.new_receiptBase" */
export type v1_new_receiptBase_mutation_response = {
  __typename?: 'v1_new_receiptBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_receiptBase>;
};

/** input type for inserting object relation for remote table "v1.new_receiptBase" */
export type v1_new_receiptBase_obj_rel_insert_input = {
  data: v1_new_receiptBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_receiptBase_on_conflict>;
};

/** on_conflict condition type for table "v1.new_receiptBase" */
export type v1_new_receiptBase_on_conflict = {
  constraint: v1_new_receiptBase_constraint;
  update_columns?: Array<v1_new_receiptBase_update_column>;
  where?: InputMaybe<v1_new_receiptBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_receiptBase". */
export type v1_new_receiptBase_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  CancelledByUser?: InputMaybe<v1_SystemUserBase_order_by>;
  CancelledOn?: InputMaybe<order_by>;
  Company?: InputMaybe<v1_AccountBase_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  Invoices_aggregate?: InputMaybe<v1_InvoiceBase_aggregate_order_by>;
  Owner?: InputMaybe<v1_SystemUserBase_order_by>;
  PaymentCompany?: InputMaybe<v1_PaymentCompanies_order_by>;
  PaymentMethod?: InputMaybe<v1_new_paymentmethodBase_order_by>;
  ReceiptName?: InputMaybe<order_by>;
  ReceiptStatus?: InputMaybe<v1_ReceiptStatus_order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AdzzPaymentMethod?: InputMaybe<order_by>;
  new_BankTransferissuedon?: InputMaybe<order_by>;
  new_BankTransfertransactionid?: InputMaybe<order_by>;
  new_CardCVV?: InputMaybe<order_by>;
  new_CardExp?: InputMaybe<order_by>;
  new_CardHolder?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_CreatedByUser?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_InvoicesAssigned?: InputMaybe<order_by>;
  new_LinktoInvoice?: InputMaybe<order_by>;
  new_NewCustomer?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtax?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxdescription?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_checkbanknumber?: InputMaybe<order_by>;
  new_checkdescription?: InputMaybe<order_by>;
  new_checknumber?: InputMaybe<order_by>;
  new_checkstatus?: InputMaybe<order_by>;
  new_company?: InputMaybe<order_by>;
  new_copyPrintUrl?: InputMaybe<order_by>;
  new_customertype?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_paidon?: InputMaybe<order_by>;
  new_paymentduedate?: InputMaybe<order_by>;
  new_paypaltransactionid?: InputMaybe<order_by>;
  new_producedon?: InputMaybe<order_by>;
  new_receiptId?: InputMaybe<order_by>;
  new_receiptnumber?: InputMaybe<order_by>;
  new_senttoaccounting?: InputMaybe<order_by>;
  new_sourcePrintUrl?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_totalvatinshekels?: InputMaybe<order_by>;
  new_trigger?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_receiptBase */
export type v1_new_receiptBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_receiptBase" */
export enum v1_new_receiptBase_select_column {
  /** column name */
  CancelledBy = 'CancelledBy',
  /** column name */
  CancelledOn = 'CancelledOn',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  ReceiptName = 'ReceiptName',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  euro_equivalent = 'euro_equivalent',
  /** column name */
  id = 'id',
  /** column name */
  new_AdzzPaymentMethod = 'new_AdzzPaymentMethod',
  /** column name */
  new_BankTransferissuedon = 'new_BankTransferissuedon',
  /** column name */
  new_BankTransfertransactionid = 'new_BankTransfertransactionid',
  /** column name */
  new_CardCVV = 'new_CardCVV',
  /** column name */
  new_CardExp = 'new_CardExp',
  /** column name */
  new_CardHolder = 'new_CardHolder',
  /** column name */
  new_CardType = 'new_CardType',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_FromCompany = 'new_FromCompany',
  /** column name */
  new_InvoicesAssigned = 'new_InvoicesAssigned',
  /** column name */
  new_LinktoInvoice = 'new_LinktoInvoice',
  /** column name */
  new_NewCustomer = 'new_NewCustomer',
  /** column name */
  new_Paymentsnumber = 'new_Paymentsnumber',
  /** column name */
  new_Taxpercentage = 'new_Taxpercentage',
  /** column name */
  new_TotalTaxAmount = 'new_TotalTaxAmount',
  /** column name */
  new_Totalamount = 'new_Totalamount',
  /** column name */
  new_Withholdingtax = 'new_Withholdingtax',
  /** column name */
  new_Withholdingtaxamount = 'new_Withholdingtaxamount',
  /** column name */
  new_Withholdingtaxdescription = 'new_Withholdingtaxdescription',
  /** column name */
  new_Withholdingtaxpercentage = 'new_Withholdingtaxpercentage',
  /** column name */
  new_cardnumber = 'new_cardnumber',
  /** column name */
  new_checkbanknumber = 'new_checkbanknumber',
  /** column name */
  new_checkdescription = 'new_checkdescription',
  /** column name */
  new_checknumber = 'new_checknumber',
  /** column name */
  new_checkstatus = 'new_checkstatus',
  /** column name */
  new_company = 'new_company',
  /** column name */
  new_copyPrintUrl = 'new_copyPrintUrl',
  /** column name */
  new_customertype = 'new_customertype',
  /** column name */
  new_exchangeratebynis = 'new_exchangeratebynis',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_paidon = 'new_paidon',
  /** column name */
  new_paymentduedate = 'new_paymentduedate',
  /** column name */
  new_paypaltransactionid = 'new_paypaltransactionid',
  /** column name */
  new_producedon = 'new_producedon',
  /** column name */
  new_receiptId = 'new_receiptId',
  /** column name */
  new_receiptnumber = 'new_receiptnumber',
  /** column name */
  new_senttoaccounting = 'new_senttoaccounting',
  /** column name */
  new_sourcePrintUrl = 'new_sourcePrintUrl',
  /** column name */
  new_totalamount_Base = 'new_totalamount_Base',
  /** column name */
  new_totalvatinshekels = 'new_totalvatinshekels',
  /** column name */
  new_trigger = 'new_trigger',
  /** column name */
  new_withholdingtaxamount_Base = 'new_withholdingtaxamount_Base',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

/** select "v1_new_receiptBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.new_receiptBase" */
export enum v1_new_receiptBase_select_column_v1_new_receiptBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  new_InvoicesAssigned = 'new_InvoicesAssigned',
  /** column name */
  new_Withholdingtax = 'new_Withholdingtax',
  /** column name */
  new_customertype = 'new_customertype',
  /** column name */
  new_trigger = 'new_trigger'
}

/** select "v1_new_receiptBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.new_receiptBase" */
export enum v1_new_receiptBase_select_column_v1_new_receiptBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  new_InvoicesAssigned = 'new_InvoicesAssigned',
  /** column name */
  new_Withholdingtax = 'new_Withholdingtax',
  /** column name */
  new_customertype = 'new_customertype',
  /** column name */
  new_trigger = 'new_trigger'
}

/** input type for updating data in table "v1.new_receiptBase" */
export type v1_new_receiptBase_set_input = {
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  CancelledOn?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ReceiptName?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  euro_equivalent?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_AdzzPaymentMethod?: InputMaybe<Scalars['uuid']['input']>;
  new_BankTransferissuedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_BankTransfertransactionid?: InputMaybe<Scalars['String']['input']>;
  new_CardCVV?: InputMaybe<Scalars['String']['input']>;
  new_CardExp?: InputMaybe<Scalars['timestamp']['input']>;
  new_CardHolder?: InputMaybe<Scalars['String']['input']>;
  new_CardType?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_FromCompany?: InputMaybe<Scalars['Int']['input']>;
  new_InvoicesAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  new_LinktoInvoice?: InputMaybe<Scalars['uuid']['input']>;
  new_NewCustomer?: InputMaybe<Scalars['uuid']['input']>;
  new_Paymentsnumber?: InputMaybe<Scalars['Int']['input']>;
  new_Taxpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_TotalTaxAmount?: InputMaybe<Scalars['Int']['input']>;
  new_Totalamount?: InputMaybe<Scalars['numeric']['input']>;
  new_Withholdingtax?: InputMaybe<Scalars['Boolean']['input']>;
  new_Withholdingtaxamount?: InputMaybe<Scalars['numeric']['input']>;
  new_Withholdingtaxdescription?: InputMaybe<Scalars['String']['input']>;
  new_Withholdingtaxpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_cardnumber?: InputMaybe<Scalars['Int']['input']>;
  new_checkbanknumber?: InputMaybe<Scalars['String']['input']>;
  new_checkdescription?: InputMaybe<Scalars['String']['input']>;
  new_checknumber?: InputMaybe<Scalars['String']['input']>;
  new_checkstatus?: InputMaybe<Scalars['String']['input']>;
  new_company?: InputMaybe<Scalars['uuid']['input']>;
  new_copyPrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_customertype?: InputMaybe<Scalars['Boolean']['input']>;
  new_exchangeratebynis?: InputMaybe<Scalars['numeric']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_paidon?: InputMaybe<Scalars['timestamp']['input']>;
  new_paymentduedate?: InputMaybe<Scalars['timestamp']['input']>;
  new_paypaltransactionid?: InputMaybe<Scalars['String']['input']>;
  new_producedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_receiptId?: InputMaybe<Scalars['uuid']['input']>;
  new_receiptnumber?: InputMaybe<Scalars['String']['input']>;
  new_senttoaccounting?: InputMaybe<Scalars['timestamp']['input']>;
  new_sourcePrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_totalamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalvatinshekels?: InputMaybe<Scalars['String']['input']>;
  new_trigger?: InputMaybe<Scalars['Boolean']['input']>;
  new_withholdingtaxamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  /** 1 is Draft */
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_receiptBase_stddev_fields = {
  __typename?: 'v1_new_receiptBase_stddev_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CardType?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Float']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Float']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Float']['output']>;
  new_Totalamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Float']['output']>;
  new_cardnumber?: Maybe<Scalars['Float']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['Float']['output']>;
  new_totalamount_Base?: Maybe<Scalars['Float']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_stddev_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_new_receiptBase_stddev_pop_fields = {
  __typename?: 'v1_new_receiptBase_stddev_pop_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CardType?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Float']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Float']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Float']['output']>;
  new_Totalamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Float']['output']>;
  new_cardnumber?: Maybe<Scalars['Float']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['Float']['output']>;
  new_totalamount_Base?: Maybe<Scalars['Float']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_stddev_pop_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_new_receiptBase_stddev_samp_fields = {
  __typename?: 'v1_new_receiptBase_stddev_samp_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CardType?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Float']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Float']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Float']['output']>;
  new_Totalamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Float']['output']>;
  new_cardnumber?: Maybe<Scalars['Float']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['Float']['output']>;
  new_totalamount_Base?: Maybe<Scalars['Float']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_stddev_samp_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_new_receiptBase" */
export type v1_new_receiptBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_receiptBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_receiptBase_stream_cursor_value_input = {
  CancelledBy?: InputMaybe<Scalars['Int']['input']>;
  CancelledOn?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  ReceiptName?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  euro_equivalent?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_AdzzPaymentMethod?: InputMaybe<Scalars['uuid']['input']>;
  new_BankTransferissuedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_BankTransfertransactionid?: InputMaybe<Scalars['String']['input']>;
  new_CardCVV?: InputMaybe<Scalars['String']['input']>;
  new_CardExp?: InputMaybe<Scalars['timestamp']['input']>;
  new_CardHolder?: InputMaybe<Scalars['String']['input']>;
  new_CardType?: InputMaybe<Scalars['Int']['input']>;
  new_CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_FromCompany?: InputMaybe<Scalars['Int']['input']>;
  new_InvoicesAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  new_LinktoInvoice?: InputMaybe<Scalars['uuid']['input']>;
  new_NewCustomer?: InputMaybe<Scalars['uuid']['input']>;
  new_Paymentsnumber?: InputMaybe<Scalars['Int']['input']>;
  new_Taxpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_TotalTaxAmount?: InputMaybe<Scalars['Int']['input']>;
  new_Totalamount?: InputMaybe<Scalars['numeric']['input']>;
  new_Withholdingtax?: InputMaybe<Scalars['Boolean']['input']>;
  new_Withholdingtaxamount?: InputMaybe<Scalars['numeric']['input']>;
  new_Withholdingtaxdescription?: InputMaybe<Scalars['String']['input']>;
  new_Withholdingtaxpercentage?: InputMaybe<Scalars['Int']['input']>;
  new_cardnumber?: InputMaybe<Scalars['Int']['input']>;
  new_checkbanknumber?: InputMaybe<Scalars['String']['input']>;
  new_checkdescription?: InputMaybe<Scalars['String']['input']>;
  new_checknumber?: InputMaybe<Scalars['String']['input']>;
  new_checkstatus?: InputMaybe<Scalars['String']['input']>;
  new_company?: InputMaybe<Scalars['uuid']['input']>;
  new_copyPrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_customertype?: InputMaybe<Scalars['Boolean']['input']>;
  new_exchangeratebynis?: InputMaybe<Scalars['numeric']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_paidon?: InputMaybe<Scalars['timestamp']['input']>;
  new_paymentduedate?: InputMaybe<Scalars['timestamp']['input']>;
  new_paypaltransactionid?: InputMaybe<Scalars['String']['input']>;
  new_producedon?: InputMaybe<Scalars['timestamp']['input']>;
  new_receiptId?: InputMaybe<Scalars['uuid']['input']>;
  new_receiptnumber?: InputMaybe<Scalars['String']['input']>;
  new_senttoaccounting?: InputMaybe<Scalars['timestamp']['input']>;
  new_sourcePrintUrl?: InputMaybe<Scalars['String']['input']>;
  new_totalamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  new_totalvatinshekels?: InputMaybe<Scalars['String']['input']>;
  new_trigger?: InputMaybe<Scalars['Boolean']['input']>;
  new_withholdingtaxamount_Base?: InputMaybe<Scalars['numeric']['input']>;
  owner_id?: InputMaybe<Scalars['Int']['input']>;
  paymentmethod_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  /** 1 is Draft */
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_receiptBase_sum_fields = {
  __typename?: 'v1_new_receiptBase_sum_fields';
  CancelledBy?: Maybe<Scalars['Int']['output']>;
  company_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  euro_equivalent?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_CardType?: Maybe<Scalars['Int']['output']>;
  new_FromCompany?: Maybe<Scalars['Int']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Int']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Int']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Int']['output']>;
  new_Totalamount?: Maybe<Scalars['numeric']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['numeric']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Int']['output']>;
  new_cardnumber?: Maybe<Scalars['Int']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['numeric']['output']>;
  new_totalamount_Base?: Maybe<Scalars['numeric']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['numeric']['output']>;
  owner_id?: Maybe<Scalars['Int']['output']>;
  paymentmethod_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_sum_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** update columns of table "v1.new_receiptBase" */
export enum v1_new_receiptBase_update_column {
  /** column name */
  CancelledBy = 'CancelledBy',
  /** column name */
  CancelledOn = 'CancelledOn',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  ReceiptName = 'ReceiptName',
  /** column name */
  company_id = 'company_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  euro_equivalent = 'euro_equivalent',
  /** column name */
  id = 'id',
  /** column name */
  new_AdzzPaymentMethod = 'new_AdzzPaymentMethod',
  /** column name */
  new_BankTransferissuedon = 'new_BankTransferissuedon',
  /** column name */
  new_BankTransfertransactionid = 'new_BankTransfertransactionid',
  /** column name */
  new_CardCVV = 'new_CardCVV',
  /** column name */
  new_CardExp = 'new_CardExp',
  /** column name */
  new_CardHolder = 'new_CardHolder',
  /** column name */
  new_CardType = 'new_CardType',
  /** column name */
  new_CreatedByUser = 'new_CreatedByUser',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_FromCompany = 'new_FromCompany',
  /** column name */
  new_InvoicesAssigned = 'new_InvoicesAssigned',
  /** column name */
  new_LinktoInvoice = 'new_LinktoInvoice',
  /** column name */
  new_NewCustomer = 'new_NewCustomer',
  /** column name */
  new_Paymentsnumber = 'new_Paymentsnumber',
  /** column name */
  new_Taxpercentage = 'new_Taxpercentage',
  /** column name */
  new_TotalTaxAmount = 'new_TotalTaxAmount',
  /** column name */
  new_Totalamount = 'new_Totalamount',
  /** column name */
  new_Withholdingtax = 'new_Withholdingtax',
  /** column name */
  new_Withholdingtaxamount = 'new_Withholdingtaxamount',
  /** column name */
  new_Withholdingtaxdescription = 'new_Withholdingtaxdescription',
  /** column name */
  new_Withholdingtaxpercentage = 'new_Withholdingtaxpercentage',
  /** column name */
  new_cardnumber = 'new_cardnumber',
  /** column name */
  new_checkbanknumber = 'new_checkbanknumber',
  /** column name */
  new_checkdescription = 'new_checkdescription',
  /** column name */
  new_checknumber = 'new_checknumber',
  /** column name */
  new_checkstatus = 'new_checkstatus',
  /** column name */
  new_company = 'new_company',
  /** column name */
  new_copyPrintUrl = 'new_copyPrintUrl',
  /** column name */
  new_customertype = 'new_customertype',
  /** column name */
  new_exchangeratebynis = 'new_exchangeratebynis',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_paidon = 'new_paidon',
  /** column name */
  new_paymentduedate = 'new_paymentduedate',
  /** column name */
  new_paypaltransactionid = 'new_paypaltransactionid',
  /** column name */
  new_producedon = 'new_producedon',
  /** column name */
  new_receiptId = 'new_receiptId',
  /** column name */
  new_receiptnumber = 'new_receiptnumber',
  /** column name */
  new_senttoaccounting = 'new_senttoaccounting',
  /** column name */
  new_sourcePrintUrl = 'new_sourcePrintUrl',
  /** column name */
  new_totalamount_Base = 'new_totalamount_Base',
  /** column name */
  new_totalvatinshekels = 'new_totalvatinshekels',
  /** column name */
  new_trigger = 'new_trigger',
  /** column name */
  new_withholdingtaxamount_Base = 'new_withholdingtaxamount_Base',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  paymentmethod_id = 'paymentmethod_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

export type v1_new_receiptBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_receiptBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_receiptBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_receiptBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_receiptBase_var_pop_fields = {
  __typename?: 'v1_new_receiptBase_var_pop_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CardType?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Float']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Float']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Float']['output']>;
  new_Totalamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Float']['output']>;
  new_cardnumber?: Maybe<Scalars['Float']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['Float']['output']>;
  new_totalamount_Base?: Maybe<Scalars['Float']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_var_pop_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_new_receiptBase_var_samp_fields = {
  __typename?: 'v1_new_receiptBase_var_samp_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CardType?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Float']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Float']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Float']['output']>;
  new_Totalamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Float']['output']>;
  new_cardnumber?: Maybe<Scalars['Float']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['Float']['output']>;
  new_totalamount_Base?: Maybe<Scalars['Float']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_var_samp_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_new_receiptBase_variance_fields = {
  __typename?: 'v1_new_receiptBase_variance_fields';
  CancelledBy?: Maybe<Scalars['Float']['output']>;
  company_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  euro_equivalent?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_CardType?: Maybe<Scalars['Float']['output']>;
  new_FromCompany?: Maybe<Scalars['Float']['output']>;
  new_Paymentsnumber?: Maybe<Scalars['Float']['output']>;
  new_Taxpercentage?: Maybe<Scalars['Float']['output']>;
  new_TotalTaxAmount?: Maybe<Scalars['Float']['output']>;
  new_Totalamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxamount?: Maybe<Scalars['Float']['output']>;
  new_Withholdingtaxpercentage?: Maybe<Scalars['Float']['output']>;
  new_cardnumber?: Maybe<Scalars['Float']['output']>;
  new_exchangeratebynis?: Maybe<Scalars['Float']['output']>;
  new_totalamount_Base?: Maybe<Scalars['Float']['output']>;
  new_withholdingtaxamount_Base?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
  paymentmethod_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  /** 1 is Draft */
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.new_receiptBase" */
export type v1_new_receiptBase_variance_order_by = {
  CancelledBy?: InputMaybe<order_by>;
  company_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  euro_equivalent?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_CardType?: InputMaybe<order_by>;
  new_FromCompany?: InputMaybe<order_by>;
  new_Paymentsnumber?: InputMaybe<order_by>;
  new_Taxpercentage?: InputMaybe<order_by>;
  new_TotalTaxAmount?: InputMaybe<order_by>;
  new_Totalamount?: InputMaybe<order_by>;
  new_Withholdingtaxamount?: InputMaybe<order_by>;
  new_Withholdingtaxpercentage?: InputMaybe<order_by>;
  new_cardnumber?: InputMaybe<order_by>;
  new_exchangeratebynis?: InputMaybe<order_by>;
  new_totalamount_Base?: InputMaybe<order_by>;
  new_withholdingtaxamount_Base?: InputMaybe<order_by>;
  owner_id?: InputMaybe<order_by>;
  paymentmethod_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  /** 1 is Draft */
  statuscode?: InputMaybe<order_by>;
};

/** Registration Emails (for Affiliate Order) */
export type v1_new_regemailBase = {
  __typename?: 'v1_new_regemailBase';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  Orders: Array<v1_SalesOrderBase>;
  /** An aggregate relationship */
  Orders_aggregate: v1_SalesOrderBase_aggregate;
  id: Scalars['Int']['output'];
  new_name?: Maybe<Scalars['String']['output']>;
  new_regemailId: Scalars['uuid']['output'];
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
};


/** Registration Emails (for Affiliate Order) */
export type v1_new_regemailBaseOrdersArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};


/** Registration Emails (for Affiliate Order) */
export type v1_new_regemailBaseOrders_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_SalesOrderBase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_SalesOrderBase_order_by>>;
  where?: InputMaybe<v1_SalesOrderBase_bool_exp>;
};

/** aggregated selection of "v1.new_regemailBase" */
export type v1_new_regemailBase_aggregate = {
  __typename?: 'v1_new_regemailBase_aggregate';
  aggregate?: Maybe<v1_new_regemailBase_aggregate_fields>;
  nodes: Array<v1_new_regemailBase>;
};

/** aggregate fields of "v1.new_regemailBase" */
export type v1_new_regemailBase_aggregate_fields = {
  __typename?: 'v1_new_regemailBase_aggregate_fields';
  avg?: Maybe<v1_new_regemailBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_regemailBase_max_fields>;
  min?: Maybe<v1_new_regemailBase_min_fields>;
  stddev?: Maybe<v1_new_regemailBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_regemailBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_regemailBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_regemailBase_sum_fields>;
  var_pop?: Maybe<v1_new_regemailBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_regemailBase_var_samp_fields>;
  variance?: Maybe<v1_new_regemailBase_variance_fields>;
};


/** aggregate fields of "v1.new_regemailBase" */
export type v1_new_regemailBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_regemailBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_new_regemailBase_avg_fields = {
  __typename?: 'v1_new_regemailBase_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.new_regemailBase". All fields are combined with a logical 'AND'. */
export type v1_new_regemailBase_bool_exp = {
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Orders?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  Orders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_new_regemailBase_bool_exp>>;
  _not?: InputMaybe<v1_new_regemailBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_regemailBase_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_regemailId?: InputMaybe<uuid_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_regemailBase" */
export enum v1_new_regemailBase_constraint {
  /** unique or primary key constraint on columns "id" */
  PK_new_regemailBase = 'PK_new_regemailBase',
  /** unique or primary key constraint on columns "id" */
  new_regemailBase_id_key = 'new_regemailBase_id_key'
}

/** input type for incrementing numeric columns in table "v1.new_regemailBase" */
export type v1_new_regemailBase_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_regemailBase" */
export type v1_new_regemailBase_insert_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Orders?: InputMaybe<v1_SalesOrderBase_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_regemailId?: InputMaybe<Scalars['uuid']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_regemailBase_max_fields = {
  __typename?: 'v1_new_regemailBase_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_regemailId?: Maybe<Scalars['uuid']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type v1_new_regemailBase_min_fields = {
  __typename?: 'v1_new_regemailBase_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_regemailId?: Maybe<Scalars['uuid']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "v1.new_regemailBase" */
export type v1_new_regemailBase_mutation_response = {
  __typename?: 'v1_new_regemailBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_regemailBase>;
};

/** input type for inserting object relation for remote table "v1.new_regemailBase" */
export type v1_new_regemailBase_obj_rel_insert_input = {
  data: v1_new_regemailBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_regemailBase_on_conflict>;
};

/** on_conflict condition type for table "v1.new_regemailBase" */
export type v1_new_regemailBase_on_conflict = {
  constraint: v1_new_regemailBase_constraint;
  update_columns?: Array<v1_new_regemailBase_update_column>;
  where?: InputMaybe<v1_new_regemailBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_regemailBase". */
export type v1_new_regemailBase_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  Orders_aggregate?: InputMaybe<v1_SalesOrderBase_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_regemailId?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_regemailBase */
export type v1_new_regemailBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_regemailBase" */
export enum v1_new_regemailBase_select_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_regemailId = 'new_regemailId',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

/** input type for updating data in table "v1.new_regemailBase" */
export type v1_new_regemailBase_set_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_regemailId?: InputMaybe<Scalars['uuid']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_regemailBase_stddev_fields = {
  __typename?: 'v1_new_regemailBase_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_new_regemailBase_stddev_pop_fields = {
  __typename?: 'v1_new_regemailBase_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_new_regemailBase_stddev_samp_fields = {
  __typename?: 'v1_new_regemailBase_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_new_regemailBase" */
export type v1_new_regemailBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_regemailBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_regemailBase_stream_cursor_value_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_regemailId?: InputMaybe<Scalars['uuid']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_regemailBase_sum_fields = {
  __typename?: 'v1_new_regemailBase_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.new_regemailBase" */
export enum v1_new_regemailBase_update_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  id = 'id',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_regemailId = 'new_regemailId',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

export type v1_new_regemailBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_regemailBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_regemailBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_regemailBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_regemailBase_var_pop_fields = {
  __typename?: 'v1_new_regemailBase_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_new_regemailBase_var_samp_fields = {
  __typename?: 'v1_new_regemailBase_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_new_regemailBase_variance_fields = {
  __typename?: 'v1_new_regemailBase_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** Trackers */
export type v1_new_trackerBase = {
  __typename?: 'v1_new_trackerBase';
  /** An object relationship */
  Brand?: Maybe<v1_new_brandBase>;
  ClicksTracker?: Maybe<Clicks_lines>;
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  Creator?: Maybe<v1_SystemUserBase>;
  /** An object relationship */
  Currency?: Maybe<v1_TransactionCurrencyBase>;
  /** An object relationship */
  Deal?: Maybe<v1_TrackerDeal>;
  /** An object relationship */
  Order?: Maybe<v1_SalesOrderBase>;
  /** An object relationship */
  Project?: Maybe<v1_Project>;
  /** An array relationship */
  TrackerToTags: Array<v1_TrackerToTags>;
  /** An aggregate relationship */
  TrackerToTags_aggregate: v1_TrackerToTags_aggregate;
  baseline?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "v1.trackers_clicks_30_days" */
  clicks_last_30_days?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  is_confirmed?: Maybe<Scalars['Boolean']['output']>;
  new_AdzzTracker?: Maybe<Scalars['String']['output']>;
  new_AffiliateTracker?: Maybe<Scalars['String']['output']>;
  new_AffiliateTrackerExternal?: Maybe<Scalars['String']['output']>;
  new_BannerSize?: Maybe<Scalars['Int']['output']>;
  new_Brand?: Maybe<Scalars['uuid']['output']>;
  new_CPA?: Maybe<Scalars['numeric']['output']>;
  new_ClickCount?: Maybe<Scalars['Int']['output']>;
  new_ClickCountStatistics?: Maybe<Scalars['uuid']['output']>;
  new_Clicks?: Maybe<Scalars['Int']['output']>;
  new_Currency?: Maybe<Scalars['uuid']['output']>;
  new_Deal?: Maybe<Scalars['Int']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_DirectLink?: Maybe<Scalars['Boolean']['output']>;
  new_IsDefaultTracker?: Maybe<Scalars['Boolean']['output']>;
  new_LastClicked?: Maybe<Scalars['timestamp']['output']>;
  new_MediaType?: Maybe<Scalars['Int']['output']>;
  new_OnlineCheckedOn?: Maybe<Scalars['timestamp']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_Promoted?: Maybe<Scalars['Boolean']['output']>;
  new_Revshare?: Maybe<Scalars['Int']['output']>;
  new_TrackerOrder?: Maybe<Scalars['uuid']['output']>;
  new_Website?: Maybe<Scalars['uuid']['output']>;
  new_createdby?: Maybe<Scalars['uuid']['output']>;
  new_createdbyuser?: Maybe<Scalars['String']['output']>;
  new_eCPA?: Maybe<Scalars['numeric']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_trackerId?: Maybe<Scalars['uuid']['output']>;
  order_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode: Scalars['Int']['output'];
  statuscode?: Maybe<Scalars['Int']['output']>;
};


/** Trackers */
export type v1_new_trackerBaseTrackerToTagsArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerToTags_order_by>>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};


/** Trackers */
export type v1_new_trackerBaseTrackerToTags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_TrackerToTags_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_TrackerToTags_order_by>>;
  where?: InputMaybe<v1_TrackerToTags_bool_exp>;
};

/** aggregated selection of "v1.new_trackerBase" */
export type v1_new_trackerBase_aggregate = {
  __typename?: 'v1_new_trackerBase_aggregate';
  aggregate?: Maybe<v1_new_trackerBase_aggregate_fields>;
  nodes: Array<v1_new_trackerBase>;
};

export type v1_new_trackerBase_aggregate_bool_exp = {
  bool_and?: InputMaybe<v1_new_trackerBase_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<v1_new_trackerBase_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<v1_new_trackerBase_aggregate_bool_exp_count>;
};

export type v1_new_trackerBase_aggregate_bool_exp_bool_and = {
  arguments: v1_new_trackerBase_select_column_v1_new_trackerBase_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_trackerBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_trackerBase_aggregate_bool_exp_bool_or = {
  arguments: v1_new_trackerBase_select_column_v1_new_trackerBase_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_trackerBase_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type v1_new_trackerBase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_new_trackerBase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.new_trackerBase" */
export type v1_new_trackerBase_aggregate_fields = {
  __typename?: 'v1_new_trackerBase_aggregate_fields';
  avg?: Maybe<v1_new_trackerBase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_new_trackerBase_max_fields>;
  min?: Maybe<v1_new_trackerBase_min_fields>;
  stddev?: Maybe<v1_new_trackerBase_stddev_fields>;
  stddev_pop?: Maybe<v1_new_trackerBase_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_new_trackerBase_stddev_samp_fields>;
  sum?: Maybe<v1_new_trackerBase_sum_fields>;
  var_pop?: Maybe<v1_new_trackerBase_var_pop_fields>;
  var_samp?: Maybe<v1_new_trackerBase_var_samp_fields>;
  variance?: Maybe<v1_new_trackerBase_variance_fields>;
};


/** aggregate fields of "v1.new_trackerBase" */
export type v1_new_trackerBase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_new_trackerBase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.new_trackerBase" */
export type v1_new_trackerBase_aggregate_order_by = {
  avg?: InputMaybe<v1_new_trackerBase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_new_trackerBase_max_order_by>;
  min?: InputMaybe<v1_new_trackerBase_min_order_by>;
  stddev?: InputMaybe<v1_new_trackerBase_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_new_trackerBase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_new_trackerBase_stddev_samp_order_by>;
  sum?: InputMaybe<v1_new_trackerBase_sum_order_by>;
  var_pop?: InputMaybe<v1_new_trackerBase_var_pop_order_by>;
  var_samp?: InputMaybe<v1_new_trackerBase_var_samp_order_by>;
  variance?: InputMaybe<v1_new_trackerBase_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.new_trackerBase" */
export type v1_new_trackerBase_arr_rel_insert_input = {
  data: Array<v1_new_trackerBase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_trackerBase_on_conflict>;
};

/** aggregate avg on columns */
export type v1_new_trackerBase_avg_fields = {
  __typename?: 'v1_new_trackerBase_avg_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BannerSize?: Maybe<Scalars['Float']['output']>;
  new_CPA?: Maybe<Scalars['Float']['output']>;
  new_ClickCount?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_Deal?: Maybe<Scalars['Float']['output']>;
  new_MediaType?: Maybe<Scalars['Float']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_Revshare?: Maybe<Scalars['Float']['output']>;
  new_eCPA?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_avg_order_by = {
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.new_trackerBase". All fields are combined with a logical 'AND'. */
export type v1_new_trackerBase_bool_exp = {
  Brand?: InputMaybe<v1_new_brandBase_bool_exp>;
  CreatedOn?: InputMaybe<timestamp_comparison_exp>;
  Creator?: InputMaybe<v1_SystemUserBase_bool_exp>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_bool_exp>;
  Deal?: InputMaybe<v1_TrackerDeal_bool_exp>;
  Order?: InputMaybe<v1_SalesOrderBase_bool_exp>;
  Project?: InputMaybe<v1_Project_bool_exp>;
  TrackerToTags?: InputMaybe<v1_TrackerToTags_bool_exp>;
  TrackerToTags_aggregate?: InputMaybe<v1_TrackerToTags_aggregate_bool_exp>;
  _and?: InputMaybe<Array<v1_new_trackerBase_bool_exp>>;
  _not?: InputMaybe<v1_new_trackerBase_bool_exp>;
  _or?: InputMaybe<Array<v1_new_trackerBase_bool_exp>>;
  baseline?: InputMaybe<String_comparison_exp>;
  brand_id?: InputMaybe<Int_comparison_exp>;
  clicks_last_30_days?: InputMaybe<Int_comparison_exp>;
  createdby_id?: InputMaybe<Int_comparison_exp>;
  currency_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  is_confirmed?: InputMaybe<Boolean_comparison_exp>;
  new_AdzzTracker?: InputMaybe<String_comparison_exp>;
  new_AffiliateTracker?: InputMaybe<String_comparison_exp>;
  new_AffiliateTrackerExternal?: InputMaybe<String_comparison_exp>;
  new_BannerSize?: InputMaybe<Int_comparison_exp>;
  new_Brand?: InputMaybe<uuid_comparison_exp>;
  new_CPA?: InputMaybe<numeric_comparison_exp>;
  new_ClickCount?: InputMaybe<Int_comparison_exp>;
  new_ClickCountStatistics?: InputMaybe<uuid_comparison_exp>;
  new_Clicks?: InputMaybe<Int_comparison_exp>;
  new_Currency?: InputMaybe<uuid_comparison_exp>;
  new_Deal?: InputMaybe<Int_comparison_exp>;
  new_Description?: InputMaybe<String_comparison_exp>;
  new_DirectLink?: InputMaybe<Boolean_comparison_exp>;
  new_IsDefaultTracker?: InputMaybe<Boolean_comparison_exp>;
  new_LastClicked?: InputMaybe<timestamp_comparison_exp>;
  new_MediaType?: InputMaybe<Int_comparison_exp>;
  new_OnlineCheckedOn?: InputMaybe<timestamp_comparison_exp>;
  new_OnlineStatus?: InputMaybe<Int_comparison_exp>;
  new_Promoted?: InputMaybe<Boolean_comparison_exp>;
  new_Revshare?: InputMaybe<Int_comparison_exp>;
  new_TrackerOrder?: InputMaybe<uuid_comparison_exp>;
  new_Website?: InputMaybe<uuid_comparison_exp>;
  new_createdby?: InputMaybe<uuid_comparison_exp>;
  new_createdbyuser?: InputMaybe<String_comparison_exp>;
  new_eCPA?: InputMaybe<numeric_comparison_exp>;
  new_name?: InputMaybe<String_comparison_exp>;
  new_trackerId?: InputMaybe<uuid_comparison_exp>;
  order_id?: InputMaybe<Int_comparison_exp>;
  project_id?: InputMaybe<Int_comparison_exp>;
  statecode?: InputMaybe<Int_comparison_exp>;
  statuscode?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.new_trackerBase" */
export enum v1_new_trackerBase_constraint {
  /** unique or primary key constraint on columns "id" */
  new_trackerBase_id_key = 'new_trackerBase_id_key',
  /** unique or primary key constraint on columns "id" */
  new_trackerBase_pkey = 'new_trackerBase_pkey',
  /** unique or primary key constraint on columns "project_id", "new_name" */
  tracker_project_name_key = 'tracker_project_name_key'
}

/** input type for incrementing numeric columns in table "v1.new_trackerBase" */
export type v1_new_trackerBase_inc_input = {
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_BannerSize?: InputMaybe<Scalars['Int']['input']>;
  new_CPA?: InputMaybe<Scalars['numeric']['input']>;
  new_ClickCount?: InputMaybe<Scalars['Int']['input']>;
  new_Clicks?: InputMaybe<Scalars['Int']['input']>;
  new_Deal?: InputMaybe<Scalars['Int']['input']>;
  new_MediaType?: InputMaybe<Scalars['Int']['input']>;
  new_OnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Revshare?: InputMaybe<Scalars['Int']['input']>;
  new_eCPA?: InputMaybe<Scalars['numeric']['input']>;
  order_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.new_trackerBase" */
export type v1_new_trackerBase_insert_input = {
  Brand?: InputMaybe<v1_new_brandBase_obj_rel_insert_input>;
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  Creator?: InputMaybe<v1_SystemUserBase_obj_rel_insert_input>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_obj_rel_insert_input>;
  Deal?: InputMaybe<v1_TrackerDeal_obj_rel_insert_input>;
  Order?: InputMaybe<v1_SalesOrderBase_obj_rel_insert_input>;
  Project?: InputMaybe<v1_Project_obj_rel_insert_input>;
  TrackerToTags?: InputMaybe<v1_TrackerToTags_arr_rel_insert_input>;
  baseline?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  new_AdzzTracker?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateTracker?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateTrackerExternal?: InputMaybe<Scalars['String']['input']>;
  new_BannerSize?: InputMaybe<Scalars['Int']['input']>;
  new_Brand?: InputMaybe<Scalars['uuid']['input']>;
  new_CPA?: InputMaybe<Scalars['numeric']['input']>;
  new_ClickCount?: InputMaybe<Scalars['Int']['input']>;
  new_ClickCountStatistics?: InputMaybe<Scalars['uuid']['input']>;
  new_Clicks?: InputMaybe<Scalars['Int']['input']>;
  new_Currency?: InputMaybe<Scalars['uuid']['input']>;
  new_Deal?: InputMaybe<Scalars['Int']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DirectLink?: InputMaybe<Scalars['Boolean']['input']>;
  new_IsDefaultTracker?: InputMaybe<Scalars['Boolean']['input']>;
  new_LastClicked?: InputMaybe<Scalars['timestamp']['input']>;
  new_MediaType?: InputMaybe<Scalars['Int']['input']>;
  new_OnlineCheckedOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_OnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Promoted?: InputMaybe<Scalars['Boolean']['input']>;
  new_Revshare?: InputMaybe<Scalars['Int']['input']>;
  new_TrackerOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_Website?: InputMaybe<Scalars['uuid']['input']>;
  new_createdby?: InputMaybe<Scalars['uuid']['input']>;
  new_createdbyuser?: InputMaybe<Scalars['String']['input']>;
  new_eCPA?: InputMaybe<Scalars['numeric']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_trackerId?: InputMaybe<Scalars['uuid']['input']>;
  order_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_new_trackerBase_max_fields = {
  __typename?: 'v1_new_trackerBase_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  baseline?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_AdzzTracker?: Maybe<Scalars['String']['output']>;
  new_AffiliateTracker?: Maybe<Scalars['String']['output']>;
  new_AffiliateTrackerExternal?: Maybe<Scalars['String']['output']>;
  new_BannerSize?: Maybe<Scalars['Int']['output']>;
  new_Brand?: Maybe<Scalars['uuid']['output']>;
  new_CPA?: Maybe<Scalars['numeric']['output']>;
  new_ClickCount?: Maybe<Scalars['Int']['output']>;
  new_ClickCountStatistics?: Maybe<Scalars['uuid']['output']>;
  new_Clicks?: Maybe<Scalars['Int']['output']>;
  new_Currency?: Maybe<Scalars['uuid']['output']>;
  new_Deal?: Maybe<Scalars['Int']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_LastClicked?: Maybe<Scalars['timestamp']['output']>;
  new_MediaType?: Maybe<Scalars['Int']['output']>;
  new_OnlineCheckedOn?: Maybe<Scalars['timestamp']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_Revshare?: Maybe<Scalars['Int']['output']>;
  new_TrackerOrder?: Maybe<Scalars['uuid']['output']>;
  new_Website?: Maybe<Scalars['uuid']['output']>;
  new_createdby?: Maybe<Scalars['uuid']['output']>;
  new_createdbyuser?: Maybe<Scalars['String']['output']>;
  new_eCPA?: Maybe<Scalars['numeric']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_trackerId?: Maybe<Scalars['uuid']['output']>;
  order_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_max_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  baseline?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AdzzTracker?: InputMaybe<order_by>;
  new_AffiliateTracker?: InputMaybe<order_by>;
  new_AffiliateTrackerExternal?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_ClickCountStatistics?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Currency?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_LastClicked?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineCheckedOn?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_TrackerOrder?: InputMaybe<order_by>;
  new_Website?: InputMaybe<order_by>;
  new_createdby?: InputMaybe<order_by>;
  new_createdbyuser?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_trackerId?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_new_trackerBase_min_fields = {
  __typename?: 'v1_new_trackerBase_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']['output']>;
  baseline?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_AdzzTracker?: Maybe<Scalars['String']['output']>;
  new_AffiliateTracker?: Maybe<Scalars['String']['output']>;
  new_AffiliateTrackerExternal?: Maybe<Scalars['String']['output']>;
  new_BannerSize?: Maybe<Scalars['Int']['output']>;
  new_Brand?: Maybe<Scalars['uuid']['output']>;
  new_CPA?: Maybe<Scalars['numeric']['output']>;
  new_ClickCount?: Maybe<Scalars['Int']['output']>;
  new_ClickCountStatistics?: Maybe<Scalars['uuid']['output']>;
  new_Clicks?: Maybe<Scalars['Int']['output']>;
  new_Currency?: Maybe<Scalars['uuid']['output']>;
  new_Deal?: Maybe<Scalars['Int']['output']>;
  new_Description?: Maybe<Scalars['String']['output']>;
  new_LastClicked?: Maybe<Scalars['timestamp']['output']>;
  new_MediaType?: Maybe<Scalars['Int']['output']>;
  new_OnlineCheckedOn?: Maybe<Scalars['timestamp']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_Revshare?: Maybe<Scalars['Int']['output']>;
  new_TrackerOrder?: Maybe<Scalars['uuid']['output']>;
  new_Website?: Maybe<Scalars['uuid']['output']>;
  new_createdby?: Maybe<Scalars['uuid']['output']>;
  new_createdbyuser?: Maybe<Scalars['String']['output']>;
  new_eCPA?: Maybe<Scalars['numeric']['output']>;
  new_name?: Maybe<Scalars['String']['output']>;
  new_trackerId?: Maybe<Scalars['uuid']['output']>;
  order_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_min_order_by = {
  CreatedOn?: InputMaybe<order_by>;
  baseline?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_AdzzTracker?: InputMaybe<order_by>;
  new_AffiliateTracker?: InputMaybe<order_by>;
  new_AffiliateTrackerExternal?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_ClickCountStatistics?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Currency?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_LastClicked?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineCheckedOn?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_TrackerOrder?: InputMaybe<order_by>;
  new_Website?: InputMaybe<order_by>;
  new_createdby?: InputMaybe<order_by>;
  new_createdbyuser?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_trackerId?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.new_trackerBase" */
export type v1_new_trackerBase_mutation_response = {
  __typename?: 'v1_new_trackerBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_new_trackerBase>;
};

/** input type for inserting object relation for remote table "v1.new_trackerBase" */
export type v1_new_trackerBase_obj_rel_insert_input = {
  data: v1_new_trackerBase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_new_trackerBase_on_conflict>;
};

/** on_conflict condition type for table "v1.new_trackerBase" */
export type v1_new_trackerBase_on_conflict = {
  constraint: v1_new_trackerBase_constraint;
  update_columns?: Array<v1_new_trackerBase_update_column>;
  where?: InputMaybe<v1_new_trackerBase_bool_exp>;
};

/** Ordering options when selecting data from "v1.new_trackerBase". */
export type v1_new_trackerBase_order_by = {
  Brand?: InputMaybe<v1_new_brandBase_order_by>;
  CreatedOn?: InputMaybe<order_by>;
  Creator?: InputMaybe<v1_SystemUserBase_order_by>;
  Currency?: InputMaybe<v1_TransactionCurrencyBase_order_by>;
  Deal?: InputMaybe<v1_TrackerDeal_order_by>;
  Order?: InputMaybe<v1_SalesOrderBase_order_by>;
  Project?: InputMaybe<v1_Project_order_by>;
  TrackerToTags_aggregate?: InputMaybe<v1_TrackerToTags_aggregate_order_by>;
  baseline?: InputMaybe<order_by>;
  brand_id?: InputMaybe<order_by>;
  clicks_last_30_days?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  is_confirmed?: InputMaybe<order_by>;
  new_AdzzTracker?: InputMaybe<order_by>;
  new_AffiliateTracker?: InputMaybe<order_by>;
  new_AffiliateTrackerExternal?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_Brand?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_ClickCountStatistics?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Currency?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_Description?: InputMaybe<order_by>;
  new_DirectLink?: InputMaybe<order_by>;
  new_IsDefaultTracker?: InputMaybe<order_by>;
  new_LastClicked?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineCheckedOn?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Promoted?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_TrackerOrder?: InputMaybe<order_by>;
  new_Website?: InputMaybe<order_by>;
  new_createdby?: InputMaybe<order_by>;
  new_createdbyuser?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  new_name?: InputMaybe<order_by>;
  new_trackerId?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.new_trackerBase */
export type v1_new_trackerBase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.new_trackerBase" */
export enum v1_new_trackerBase_select_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  baseline = 'baseline',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  is_confirmed = 'is_confirmed',
  /** column name */
  new_AdzzTracker = 'new_AdzzTracker',
  /** column name */
  new_AffiliateTracker = 'new_AffiliateTracker',
  /** column name */
  new_AffiliateTrackerExternal = 'new_AffiliateTrackerExternal',
  /** column name */
  new_BannerSize = 'new_BannerSize',
  /** column name */
  new_Brand = 'new_Brand',
  /** column name */
  new_CPA = 'new_CPA',
  /** column name */
  new_ClickCount = 'new_ClickCount',
  /** column name */
  new_ClickCountStatistics = 'new_ClickCountStatistics',
  /** column name */
  new_Clicks = 'new_Clicks',
  /** column name */
  new_Currency = 'new_Currency',
  /** column name */
  new_Deal = 'new_Deal',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_DirectLink = 'new_DirectLink',
  /** column name */
  new_IsDefaultTracker = 'new_IsDefaultTracker',
  /** column name */
  new_LastClicked = 'new_LastClicked',
  /** column name */
  new_MediaType = 'new_MediaType',
  /** column name */
  new_OnlineCheckedOn = 'new_OnlineCheckedOn',
  /** column name */
  new_OnlineStatus = 'new_OnlineStatus',
  /** column name */
  new_Promoted = 'new_Promoted',
  /** column name */
  new_Revshare = 'new_Revshare',
  /** column name */
  new_TrackerOrder = 'new_TrackerOrder',
  /** column name */
  new_Website = 'new_Website',
  /** column name */
  new_createdby = 'new_createdby',
  /** column name */
  new_createdbyuser = 'new_createdbyuser',
  /** column name */
  new_eCPA = 'new_eCPA',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_trackerId = 'new_trackerId',
  /** column name */
  order_id = 'order_id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

/** select "v1_new_trackerBase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "v1.new_trackerBase" */
export enum v1_new_trackerBase_select_column_v1_new_trackerBase_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  is_confirmed = 'is_confirmed',
  /** column name */
  new_DirectLink = 'new_DirectLink',
  /** column name */
  new_IsDefaultTracker = 'new_IsDefaultTracker',
  /** column name */
  new_Promoted = 'new_Promoted'
}

/** select "v1_new_trackerBase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "v1.new_trackerBase" */
export enum v1_new_trackerBase_select_column_v1_new_trackerBase_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  is_confirmed = 'is_confirmed',
  /** column name */
  new_DirectLink = 'new_DirectLink',
  /** column name */
  new_IsDefaultTracker = 'new_IsDefaultTracker',
  /** column name */
  new_Promoted = 'new_Promoted'
}

/** input type for updating data in table "v1.new_trackerBase" */
export type v1_new_trackerBase_set_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  baseline?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  new_AdzzTracker?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateTracker?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateTrackerExternal?: InputMaybe<Scalars['String']['input']>;
  new_BannerSize?: InputMaybe<Scalars['Int']['input']>;
  new_Brand?: InputMaybe<Scalars['uuid']['input']>;
  new_CPA?: InputMaybe<Scalars['numeric']['input']>;
  new_ClickCount?: InputMaybe<Scalars['Int']['input']>;
  new_ClickCountStatistics?: InputMaybe<Scalars['uuid']['input']>;
  new_Clicks?: InputMaybe<Scalars['Int']['input']>;
  new_Currency?: InputMaybe<Scalars['uuid']['input']>;
  new_Deal?: InputMaybe<Scalars['Int']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DirectLink?: InputMaybe<Scalars['Boolean']['input']>;
  new_IsDefaultTracker?: InputMaybe<Scalars['Boolean']['input']>;
  new_LastClicked?: InputMaybe<Scalars['timestamp']['input']>;
  new_MediaType?: InputMaybe<Scalars['Int']['input']>;
  new_OnlineCheckedOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_OnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Promoted?: InputMaybe<Scalars['Boolean']['input']>;
  new_Revshare?: InputMaybe<Scalars['Int']['input']>;
  new_TrackerOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_Website?: InputMaybe<Scalars['uuid']['input']>;
  new_createdby?: InputMaybe<Scalars['uuid']['input']>;
  new_createdbyuser?: InputMaybe<Scalars['String']['input']>;
  new_eCPA?: InputMaybe<Scalars['numeric']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_trackerId?: InputMaybe<Scalars['uuid']['input']>;
  order_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_new_trackerBase_stddev_fields = {
  __typename?: 'v1_new_trackerBase_stddev_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BannerSize?: Maybe<Scalars['Float']['output']>;
  new_CPA?: Maybe<Scalars['Float']['output']>;
  new_ClickCount?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_Deal?: Maybe<Scalars['Float']['output']>;
  new_MediaType?: Maybe<Scalars['Float']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_Revshare?: Maybe<Scalars['Float']['output']>;
  new_eCPA?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_stddev_order_by = {
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_new_trackerBase_stddev_pop_fields = {
  __typename?: 'v1_new_trackerBase_stddev_pop_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BannerSize?: Maybe<Scalars['Float']['output']>;
  new_CPA?: Maybe<Scalars['Float']['output']>;
  new_ClickCount?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_Deal?: Maybe<Scalars['Float']['output']>;
  new_MediaType?: Maybe<Scalars['Float']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_Revshare?: Maybe<Scalars['Float']['output']>;
  new_eCPA?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_stddev_pop_order_by = {
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_new_trackerBase_stddev_samp_fields = {
  __typename?: 'v1_new_trackerBase_stddev_samp_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BannerSize?: Maybe<Scalars['Float']['output']>;
  new_CPA?: Maybe<Scalars['Float']['output']>;
  new_ClickCount?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_Deal?: Maybe<Scalars['Float']['output']>;
  new_MediaType?: Maybe<Scalars['Float']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_Revshare?: Maybe<Scalars['Float']['output']>;
  new_eCPA?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_stddev_samp_order_by = {
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_new_trackerBase" */
export type v1_new_trackerBase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_new_trackerBase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_new_trackerBase_stream_cursor_value_input = {
  CreatedOn?: InputMaybe<Scalars['timestamp']['input']>;
  baseline?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  createdby_id?: InputMaybe<Scalars['Int']['input']>;
  currency_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  new_AdzzTracker?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateTracker?: InputMaybe<Scalars['String']['input']>;
  new_AffiliateTrackerExternal?: InputMaybe<Scalars['String']['input']>;
  new_BannerSize?: InputMaybe<Scalars['Int']['input']>;
  new_Brand?: InputMaybe<Scalars['uuid']['input']>;
  new_CPA?: InputMaybe<Scalars['numeric']['input']>;
  new_ClickCount?: InputMaybe<Scalars['Int']['input']>;
  new_ClickCountStatistics?: InputMaybe<Scalars['uuid']['input']>;
  new_Clicks?: InputMaybe<Scalars['Int']['input']>;
  new_Currency?: InputMaybe<Scalars['uuid']['input']>;
  new_Deal?: InputMaybe<Scalars['Int']['input']>;
  new_Description?: InputMaybe<Scalars['String']['input']>;
  new_DirectLink?: InputMaybe<Scalars['Boolean']['input']>;
  new_IsDefaultTracker?: InputMaybe<Scalars['Boolean']['input']>;
  new_LastClicked?: InputMaybe<Scalars['timestamp']['input']>;
  new_MediaType?: InputMaybe<Scalars['Int']['input']>;
  new_OnlineCheckedOn?: InputMaybe<Scalars['timestamp']['input']>;
  new_OnlineStatus?: InputMaybe<Scalars['Int']['input']>;
  new_Promoted?: InputMaybe<Scalars['Boolean']['input']>;
  new_Revshare?: InputMaybe<Scalars['Int']['input']>;
  new_TrackerOrder?: InputMaybe<Scalars['uuid']['input']>;
  new_Website?: InputMaybe<Scalars['uuid']['input']>;
  new_createdby?: InputMaybe<Scalars['uuid']['input']>;
  new_createdbyuser?: InputMaybe<Scalars['String']['input']>;
  new_eCPA?: InputMaybe<Scalars['numeric']['input']>;
  new_name?: InputMaybe<Scalars['String']['input']>;
  new_trackerId?: InputMaybe<Scalars['uuid']['input']>;
  order_id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  statecode?: InputMaybe<Scalars['Int']['input']>;
  statuscode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_new_trackerBase_sum_fields = {
  __typename?: 'v1_new_trackerBase_sum_fields';
  brand_id?: Maybe<Scalars['Int']['output']>;
  createdby_id?: Maybe<Scalars['Int']['output']>;
  currency_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_BannerSize?: Maybe<Scalars['Int']['output']>;
  new_CPA?: Maybe<Scalars['numeric']['output']>;
  new_ClickCount?: Maybe<Scalars['Int']['output']>;
  new_Clicks?: Maybe<Scalars['Int']['output']>;
  new_Deal?: Maybe<Scalars['Int']['output']>;
  new_MediaType?: Maybe<Scalars['Int']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Int']['output']>;
  new_Revshare?: Maybe<Scalars['Int']['output']>;
  new_eCPA?: Maybe<Scalars['numeric']['output']>;
  order_id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  statecode?: Maybe<Scalars['Int']['output']>;
  statuscode?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_sum_order_by = {
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** update columns of table "v1.new_trackerBase" */
export enum v1_new_trackerBase_update_column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  baseline = 'baseline',
  /** column name */
  brand_id = 'brand_id',
  /** column name */
  createdby_id = 'createdby_id',
  /** column name */
  currency_id = 'currency_id',
  /** column name */
  id = 'id',
  /** column name */
  is_confirmed = 'is_confirmed',
  /** column name */
  new_AdzzTracker = 'new_AdzzTracker',
  /** column name */
  new_AffiliateTracker = 'new_AffiliateTracker',
  /** column name */
  new_AffiliateTrackerExternal = 'new_AffiliateTrackerExternal',
  /** column name */
  new_BannerSize = 'new_BannerSize',
  /** column name */
  new_Brand = 'new_Brand',
  /** column name */
  new_CPA = 'new_CPA',
  /** column name */
  new_ClickCount = 'new_ClickCount',
  /** column name */
  new_ClickCountStatistics = 'new_ClickCountStatistics',
  /** column name */
  new_Clicks = 'new_Clicks',
  /** column name */
  new_Currency = 'new_Currency',
  /** column name */
  new_Deal = 'new_Deal',
  /** column name */
  new_Description = 'new_Description',
  /** column name */
  new_DirectLink = 'new_DirectLink',
  /** column name */
  new_IsDefaultTracker = 'new_IsDefaultTracker',
  /** column name */
  new_LastClicked = 'new_LastClicked',
  /** column name */
  new_MediaType = 'new_MediaType',
  /** column name */
  new_OnlineCheckedOn = 'new_OnlineCheckedOn',
  /** column name */
  new_OnlineStatus = 'new_OnlineStatus',
  /** column name */
  new_Promoted = 'new_Promoted',
  /** column name */
  new_Revshare = 'new_Revshare',
  /** column name */
  new_TrackerOrder = 'new_TrackerOrder',
  /** column name */
  new_Website = 'new_Website',
  /** column name */
  new_createdby = 'new_createdby',
  /** column name */
  new_createdbyuser = 'new_createdbyuser',
  /** column name */
  new_eCPA = 'new_eCPA',
  /** column name */
  new_name = 'new_name',
  /** column name */
  new_trackerId = 'new_trackerId',
  /** column name */
  order_id = 'order_id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  statecode = 'statecode',
  /** column name */
  statuscode = 'statuscode'
}

export type v1_new_trackerBase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_new_trackerBase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_new_trackerBase_set_input>;
  /** filter the rows which have to be updated */
  where: v1_new_trackerBase_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_new_trackerBase_var_pop_fields = {
  __typename?: 'v1_new_trackerBase_var_pop_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BannerSize?: Maybe<Scalars['Float']['output']>;
  new_CPA?: Maybe<Scalars['Float']['output']>;
  new_ClickCount?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_Deal?: Maybe<Scalars['Float']['output']>;
  new_MediaType?: Maybe<Scalars['Float']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_Revshare?: Maybe<Scalars['Float']['output']>;
  new_eCPA?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_var_pop_order_by = {
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_new_trackerBase_var_samp_fields = {
  __typename?: 'v1_new_trackerBase_var_samp_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BannerSize?: Maybe<Scalars['Float']['output']>;
  new_CPA?: Maybe<Scalars['Float']['output']>;
  new_ClickCount?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_Deal?: Maybe<Scalars['Float']['output']>;
  new_MediaType?: Maybe<Scalars['Float']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_Revshare?: Maybe<Scalars['Float']['output']>;
  new_eCPA?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_var_samp_order_by = {
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_new_trackerBase_variance_fields = {
  __typename?: 'v1_new_trackerBase_variance_fields';
  brand_id?: Maybe<Scalars['Float']['output']>;
  createdby_id?: Maybe<Scalars['Float']['output']>;
  currency_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  new_BannerSize?: Maybe<Scalars['Float']['output']>;
  new_CPA?: Maybe<Scalars['Float']['output']>;
  new_ClickCount?: Maybe<Scalars['Float']['output']>;
  new_Clicks?: Maybe<Scalars['Float']['output']>;
  new_Deal?: Maybe<Scalars['Float']['output']>;
  new_MediaType?: Maybe<Scalars['Float']['output']>;
  new_OnlineStatus?: Maybe<Scalars['Float']['output']>;
  new_Revshare?: Maybe<Scalars['Float']['output']>;
  new_eCPA?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  statecode?: Maybe<Scalars['Float']['output']>;
  statuscode?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.new_trackerBase" */
export type v1_new_trackerBase_variance_order_by = {
  brand_id?: InputMaybe<order_by>;
  createdby_id?: InputMaybe<order_by>;
  currency_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  new_BannerSize?: InputMaybe<order_by>;
  new_CPA?: InputMaybe<order_by>;
  new_ClickCount?: InputMaybe<order_by>;
  new_Clicks?: InputMaybe<order_by>;
  new_Deal?: InputMaybe<order_by>;
  new_MediaType?: InputMaybe<order_by>;
  new_OnlineStatus?: InputMaybe<order_by>;
  new_Revshare?: InputMaybe<order_by>;
  new_eCPA?: InputMaybe<order_by>;
  order_id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  statecode?: InputMaybe<order_by>;
  statuscode?: InputMaybe<order_by>;
};

/** columns and relationships of "v1.process" */
export type v1_process = {
  __typename?: 'v1_process';
  /** An object relationship */
  Rate?: Maybe<v1_process_rate>;
  description: Scalars['bpchar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['bpchar']['output'];
  rate_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "v1.process" */
export type v1_process_aggregate = {
  __typename?: 'v1_process_aggregate';
  aggregate?: Maybe<v1_process_aggregate_fields>;
  nodes: Array<v1_process>;
};

export type v1_process_aggregate_bool_exp = {
  count?: InputMaybe<v1_process_aggregate_bool_exp_count>;
};

export type v1_process_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_process_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_process_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.process" */
export type v1_process_aggregate_fields = {
  __typename?: 'v1_process_aggregate_fields';
  avg?: Maybe<v1_process_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_process_max_fields>;
  min?: Maybe<v1_process_min_fields>;
  stddev?: Maybe<v1_process_stddev_fields>;
  stddev_pop?: Maybe<v1_process_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_process_stddev_samp_fields>;
  sum?: Maybe<v1_process_sum_fields>;
  var_pop?: Maybe<v1_process_var_pop_fields>;
  var_samp?: Maybe<v1_process_var_samp_fields>;
  variance?: Maybe<v1_process_variance_fields>;
};


/** aggregate fields of "v1.process" */
export type v1_process_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_process_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.process" */
export type v1_process_aggregate_order_by = {
  avg?: InputMaybe<v1_process_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_process_max_order_by>;
  min?: InputMaybe<v1_process_min_order_by>;
  stddev?: InputMaybe<v1_process_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_process_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_process_stddev_samp_order_by>;
  sum?: InputMaybe<v1_process_sum_order_by>;
  var_pop?: InputMaybe<v1_process_var_pop_order_by>;
  var_samp?: InputMaybe<v1_process_var_samp_order_by>;
  variance?: InputMaybe<v1_process_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.process" */
export type v1_process_arr_rel_insert_input = {
  data: Array<v1_process_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_process_on_conflict>;
};

/** aggregate avg on columns */
export type v1_process_avg_fields = {
  __typename?: 'v1_process_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.process" */
export type v1_process_avg_order_by = {
  id?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.process". All fields are combined with a logical 'AND'. */
export type v1_process_bool_exp = {
  Rate?: InputMaybe<v1_process_rate_bool_exp>;
  _and?: InputMaybe<Array<v1_process_bool_exp>>;
  _not?: InputMaybe<v1_process_bool_exp>;
  _or?: InputMaybe<Array<v1_process_bool_exp>>;
  description?: InputMaybe<bpchar_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<bpchar_comparison_exp>;
  rate_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.process" */
export enum v1_process_constraint {
  /** unique or primary key constraint on columns "id" */
  process_pkey = 'process_pkey'
}

/** input type for incrementing numeric columns in table "v1.process" */
export type v1_process_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  rate_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.process" */
export type v1_process_insert_input = {
  Rate?: InputMaybe<v1_process_rate_obj_rel_insert_input>;
  description?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['bpchar']['input']>;
  rate_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_process_max_fields = {
  __typename?: 'v1_process_max_fields';
  description?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['bpchar']['output']>;
  rate_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.process" */
export type v1_process_max_order_by = {
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_process_min_fields = {
  __typename?: 'v1_process_min_fields';
  description?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['bpchar']['output']>;
  rate_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.process" */
export type v1_process_min_order_by = {
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.process" */
export type v1_process_mutation_response = {
  __typename?: 'v1_process_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_process>;
};

/** on_conflict condition type for table "v1.process" */
export type v1_process_on_conflict = {
  constraint: v1_process_constraint;
  update_columns?: Array<v1_process_update_column>;
  where?: InputMaybe<v1_process_bool_exp>;
};

/** Ordering options when selecting data from "v1.process". */
export type v1_process_order_by = {
  Rate?: InputMaybe<v1_process_rate_order_by>;
  description?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.process */
export type v1_process_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "v1.process_rate" */
export type v1_process_rate = {
  __typename?: 'v1_process_rate';
  id: Scalars['Int']['output'];
  /** An array relationship */
  processes: Array<v1_process>;
  /** An aggregate relationship */
  processes_aggregate: v1_process_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "v1.process_rate" */
export type v1_process_rateprocessesArgs = {
  distinct_on?: InputMaybe<Array<v1_process_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_order_by>>;
  where?: InputMaybe<v1_process_bool_exp>;
};


/** columns and relationships of "v1.process_rate" */
export type v1_process_rateprocesses_aggregateArgs = {
  distinct_on?: InputMaybe<Array<v1_process_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<v1_process_order_by>>;
  where?: InputMaybe<v1_process_bool_exp>;
};

/** aggregated selection of "v1.process_rate" */
export type v1_process_rate_aggregate = {
  __typename?: 'v1_process_rate_aggregate';
  aggregate?: Maybe<v1_process_rate_aggregate_fields>;
  nodes: Array<v1_process_rate>;
};

/** aggregate fields of "v1.process_rate" */
export type v1_process_rate_aggregate_fields = {
  __typename?: 'v1_process_rate_aggregate_fields';
  avg?: Maybe<v1_process_rate_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_process_rate_max_fields>;
  min?: Maybe<v1_process_rate_min_fields>;
  stddev?: Maybe<v1_process_rate_stddev_fields>;
  stddev_pop?: Maybe<v1_process_rate_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_process_rate_stddev_samp_fields>;
  sum?: Maybe<v1_process_rate_sum_fields>;
  var_pop?: Maybe<v1_process_rate_var_pop_fields>;
  var_samp?: Maybe<v1_process_rate_var_samp_fields>;
  variance?: Maybe<v1_process_rate_variance_fields>;
};


/** aggregate fields of "v1.process_rate" */
export type v1_process_rate_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_process_rate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type v1_process_rate_avg_fields = {
  __typename?: 'v1_process_rate_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "v1.process_rate". All fields are combined with a logical 'AND'. */
export type v1_process_rate_bool_exp = {
  _and?: InputMaybe<Array<v1_process_rate_bool_exp>>;
  _not?: InputMaybe<v1_process_rate_bool_exp>;
  _or?: InputMaybe<Array<v1_process_rate_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  processes?: InputMaybe<v1_process_bool_exp>;
  processes_aggregate?: InputMaybe<v1_process_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "v1.process_rate" */
export enum v1_process_rate_constraint {
  /** unique or primary key constraint on columns "id" */
  process_rate_pkey = 'process_rate_pkey'
}

/** input type for incrementing numeric columns in table "v1.process_rate" */
export type v1_process_rate_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.process_rate" */
export type v1_process_rate_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  processes?: InputMaybe<v1_process_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type v1_process_rate_max_fields = {
  __typename?: 'v1_process_rate_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type v1_process_rate_min_fields = {
  __typename?: 'v1_process_rate_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "v1.process_rate" */
export type v1_process_rate_mutation_response = {
  __typename?: 'v1_process_rate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_process_rate>;
};

/** input type for inserting object relation for remote table "v1.process_rate" */
export type v1_process_rate_obj_rel_insert_input = {
  data: v1_process_rate_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_process_rate_on_conflict>;
};

/** on_conflict condition type for table "v1.process_rate" */
export type v1_process_rate_on_conflict = {
  constraint: v1_process_rate_constraint;
  update_columns?: Array<v1_process_rate_update_column>;
  where?: InputMaybe<v1_process_rate_bool_exp>;
};

/** Ordering options when selecting data from "v1.process_rate". */
export type v1_process_rate_order_by = {
  id?: InputMaybe<order_by>;
  processes_aggregate?: InputMaybe<v1_process_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.process_rate */
export type v1_process_rate_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.process_rate" */
export enum v1_process_rate_select_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "v1.process_rate" */
export type v1_process_rate_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type v1_process_rate_stddev_fields = {
  __typename?: 'v1_process_rate_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type v1_process_rate_stddev_pop_fields = {
  __typename?: 'v1_process_rate_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type v1_process_rate_stddev_samp_fields = {
  __typename?: 'v1_process_rate_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "v1_process_rate" */
export type v1_process_rate_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_process_rate_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_process_rate_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type v1_process_rate_sum_fields = {
  __typename?: 'v1_process_rate_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "v1.process_rate" */
export enum v1_process_rate_update_column {
  /** column name */
  id = 'id',
  /** column name */
  value = 'value'
}

export type v1_process_rate_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_process_rate_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_process_rate_set_input>;
  /** filter the rows which have to be updated */
  where: v1_process_rate_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_process_rate_var_pop_fields = {
  __typename?: 'v1_process_rate_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type v1_process_rate_var_samp_fields = {
  __typename?: 'v1_process_rate_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type v1_process_rate_variance_fields = {
  __typename?: 'v1_process_rate_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "v1.process" */
export enum v1_process_select_column {
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  rate_id = 'rate_id'
}

/** input type for updating data in table "v1.process" */
export type v1_process_set_input = {
  description?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['bpchar']['input']>;
  rate_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_process_stddev_fields = {
  __typename?: 'v1_process_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.process" */
export type v1_process_stddev_order_by = {
  id?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_process_stddev_pop_fields = {
  __typename?: 'v1_process_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.process" */
export type v1_process_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_process_stddev_samp_fields = {
  __typename?: 'v1_process_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.process" */
export type v1_process_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_process" */
export type v1_process_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_process_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_process_stream_cursor_value_input = {
  description?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['bpchar']['input']>;
  rate_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_process_sum_fields = {
  __typename?: 'v1_process_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  rate_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.process" */
export type v1_process_sum_order_by = {
  id?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.process" */
export enum v1_process_update_column {
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  rate_id = 'rate_id'
}

export type v1_process_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_process_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_process_set_input>;
  /** filter the rows which have to be updated */
  where: v1_process_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_process_var_pop_fields = {
  __typename?: 'v1_process_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.process" */
export type v1_process_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_process_var_samp_fields = {
  __typename?: 'v1_process_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.process" */
export type v1_process_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_process_variance_fields = {
  __typename?: 'v1_process_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.process" */
export type v1_process_variance_order_by = {
  id?: InputMaybe<order_by>;
  rate_id?: InputMaybe<order_by>;
};

/** Project to Topic relation */
export type v1_project_topic = {
  __typename?: 'v1_project_topic';
  /** An object relationship */
  ProjectTopic: v1_ProjectTopic;
  campaign_id: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  project_id?: Maybe<Scalars['Int']['output']>;
  topic_id: Scalars['Int']['output'];
};

/** aggregated selection of "v1.project_topic" */
export type v1_project_topic_aggregate = {
  __typename?: 'v1_project_topic_aggregate';
  aggregate?: Maybe<v1_project_topic_aggregate_fields>;
  nodes: Array<v1_project_topic>;
};

export type v1_project_topic_aggregate_bool_exp = {
  count?: InputMaybe<v1_project_topic_aggregate_bool_exp_count>;
};

export type v1_project_topic_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<v1_project_topic_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<v1_project_topic_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "v1.project_topic" */
export type v1_project_topic_aggregate_fields = {
  __typename?: 'v1_project_topic_aggregate_fields';
  avg?: Maybe<v1_project_topic_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<v1_project_topic_max_fields>;
  min?: Maybe<v1_project_topic_min_fields>;
  stddev?: Maybe<v1_project_topic_stddev_fields>;
  stddev_pop?: Maybe<v1_project_topic_stddev_pop_fields>;
  stddev_samp?: Maybe<v1_project_topic_stddev_samp_fields>;
  sum?: Maybe<v1_project_topic_sum_fields>;
  var_pop?: Maybe<v1_project_topic_var_pop_fields>;
  var_samp?: Maybe<v1_project_topic_var_samp_fields>;
  variance?: Maybe<v1_project_topic_variance_fields>;
};


/** aggregate fields of "v1.project_topic" */
export type v1_project_topic_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<v1_project_topic_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "v1.project_topic" */
export type v1_project_topic_aggregate_order_by = {
  avg?: InputMaybe<v1_project_topic_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<v1_project_topic_max_order_by>;
  min?: InputMaybe<v1_project_topic_min_order_by>;
  stddev?: InputMaybe<v1_project_topic_stddev_order_by>;
  stddev_pop?: InputMaybe<v1_project_topic_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<v1_project_topic_stddev_samp_order_by>;
  sum?: InputMaybe<v1_project_topic_sum_order_by>;
  var_pop?: InputMaybe<v1_project_topic_var_pop_order_by>;
  var_samp?: InputMaybe<v1_project_topic_var_samp_order_by>;
  variance?: InputMaybe<v1_project_topic_variance_order_by>;
};

/** input type for inserting array relation for remote table "v1.project_topic" */
export type v1_project_topic_arr_rel_insert_input = {
  data: Array<v1_project_topic_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<v1_project_topic_on_conflict>;
};

/** aggregate avg on columns */
export type v1_project_topic_avg_fields = {
  __typename?: 'v1_project_topic_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "v1.project_topic" */
export type v1_project_topic_avg_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "v1.project_topic". All fields are combined with a logical 'AND'. */
export type v1_project_topic_bool_exp = {
  ProjectTopic?: InputMaybe<v1_ProjectTopic_bool_exp>;
  _and?: InputMaybe<Array<v1_project_topic_bool_exp>>;
  _not?: InputMaybe<v1_project_topic_bool_exp>;
  _or?: InputMaybe<Array<v1_project_topic_bool_exp>>;
  campaign_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  project_id?: InputMaybe<Int_comparison_exp>;
  topic_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "v1.project_topic" */
export enum v1_project_topic_constraint {
  /** unique or primary key constraint on columns "id" */
  project_topic_id_key = 'project_topic_id_key',
  /** unique or primary key constraint on columns "id" */
  project_topic_pkey = 'project_topic_pkey'
}

/** input type for incrementing numeric columns in table "v1.project_topic" */
export type v1_project_topic_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "v1.project_topic" */
export type v1_project_topic_insert_input = {
  ProjectTopic?: InputMaybe<v1_ProjectTopic_obj_rel_insert_input>;
  campaign_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type v1_project_topic_max_fields = {
  __typename?: 'v1_project_topic_max_fields';
  campaign_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "v1.project_topic" */
export type v1_project_topic_max_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type v1_project_topic_min_fields = {
  __typename?: 'v1_project_topic_min_fields';
  campaign_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "v1.project_topic" */
export type v1_project_topic_min_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "v1.project_topic" */
export type v1_project_topic_mutation_response = {
  __typename?: 'v1_project_topic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<v1_project_topic>;
};

/** on_conflict condition type for table "v1.project_topic" */
export type v1_project_topic_on_conflict = {
  constraint: v1_project_topic_constraint;
  update_columns?: Array<v1_project_topic_update_column>;
  where?: InputMaybe<v1_project_topic_bool_exp>;
};

/** Ordering options when selecting data from "v1.project_topic". */
export type v1_project_topic_order_by = {
  ProjectTopic?: InputMaybe<v1_ProjectTopic_order_by>;
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: v1.project_topic */
export type v1_project_topic_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "v1.project_topic" */
export enum v1_project_topic_select_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  id = 'id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  topic_id = 'topic_id'
}

/** input type for updating data in table "v1.project_topic" */
export type v1_project_topic_set_input = {
  campaign_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type v1_project_topic_stddev_fields = {
  __typename?: 'v1_project_topic_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "v1.project_topic" */
export type v1_project_topic_stddev_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type v1_project_topic_stddev_pop_fields = {
  __typename?: 'v1_project_topic_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "v1.project_topic" */
export type v1_project_topic_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type v1_project_topic_stddev_samp_fields = {
  __typename?: 'v1_project_topic_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "v1.project_topic" */
export type v1_project_topic_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "v1_project_topic" */
export type v1_project_topic_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: v1_project_topic_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type v1_project_topic_stream_cursor_value_input = {
  campaign_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type v1_project_topic_sum_fields = {
  __typename?: 'v1_project_topic_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "v1.project_topic" */
export type v1_project_topic_sum_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** update columns of table "v1.project_topic" */
export enum v1_project_topic_update_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  id = 'id',
  /** column name */
  project_id = 'project_id',
  /** column name */
  topic_id = 'topic_id'
}

export type v1_project_topic_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<v1_project_topic_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<v1_project_topic_set_input>;
  /** filter the rows which have to be updated */
  where: v1_project_topic_bool_exp;
};

/** aggregate var_pop on columns */
export type v1_project_topic_var_pop_fields = {
  __typename?: 'v1_project_topic_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "v1.project_topic" */
export type v1_project_topic_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type v1_project_topic_var_samp_fields = {
  __typename?: 'v1_project_topic_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "v1.project_topic" */
export type v1_project_topic_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type v1_project_topic_variance_fields = {
  __typename?: 'v1_project_topic_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  project_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "v1.project_topic" */
export type v1_project_topic_variance_order_by = {
  id?: InputMaybe<order_by>;
  project_id?: InputMaybe<order_by>;
  topic_id?: InputMaybe<order_by>;
};
