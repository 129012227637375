import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import React from 'react';
import {Loading, SORT_DESC, useGetIdentity} from 'react-admin';
import {MARKETPLACE_STATUS, PUBLISHER_POST_STATUS, STATE_CODE} from '../../config/statuses';
import DashboardListWrapper from '../common/dashboardListWrapper';
import DeclinedPostsFields, {declinedPostsSearchFields} from './marketplaceDashboardDeclinedPostsFields';
import Model from '../../models/db/models';
import NewPostsFields, {newPostsSearchFields} from './marketplaceDashboardNewPostsFields';
import PublishedPostsFields, {publishedPostsSearchFields} from './marketplaceDashboardPublishedPostsFields';

const MarketplaceDashboardList = (props: any) => {
    const {data: identity} = useGetIdentity();
    const userId = identity?.id;

    if (!userId) return <Loading />;

    return (
        <Grid container display="flex" flexDirection="column" sx={{mt: '20px'}}>
            <Grid item>
                <Card {...props}>
                    <DashboardListWrapper
                        cardTitle="New Orders"
                        title="Marketplace Dashboard"
                        resource={Model.Post.res}
                        filter={{
                            [Model.Post.marketplacestatus_id]: MARKETPLACE_STATUS.ORDER_PLACED,
                            [Model.Post.statecode]: STATE_CODE.ENABLED,
                        }}
                        searchFields={newPostsSearchFields}
                        sort={{
                            field: Model.Post.CreatedOn,
                            order: SORT_DESC,
                        }}
                    >
                        <NewPostsFields />
                    </DashboardListWrapper>
                </Card>
            </Grid>
            <Grid item>
                <Card {...props}>
                    <DashboardListWrapper
                        cardTitle="Published Posts"
                        title=" "
                        resource={Model.Post.res}
                        filter={{
                            [Model.Post.PublisherStatus]: PUBLISHER_POST_STATUS.PUBLISHED,
                            [Model.Post.statecode]: STATE_CODE.ENABLED,
                        }}
                        searchFields={publishedPostsSearchFields}
                        sort={{
                            field: Model.Post.published_on,
                            order: SORT_DESC,
                        }}
                    >
                        <PublishedPostsFields />
                    </DashboardListWrapper>
                </Card>
            </Grid>
            <Grid item>
                <Card {...props}>
                    <DashboardListWrapper
                        cardTitle="Declined Posts"
                        title=" "
                        resource={Model.Post.res}
                        filter={{
                            [Model.Post.PublisherStatus]: PUBLISHER_POST_STATUS.DECLINED_ORDER,
                            [Model.Post.statecode]: STATE_CODE.ENABLED,
                        }}
                        searchFields={declinedPostsSearchFields}
                        sort={{
                            field: Model.Post.PublisherDeclinedOn,
                            order: SORT_DESC,
                        }}
                    >
                        <DeclinedPostsFields />
                    </DashboardListWrapper>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MarketplaceDashboardList;
