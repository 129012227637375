import {v1_WebsiteTopics_select_column} from '../../generated/types';

const WebsiteTopic = {
    ...v1_WebsiteTopics_select_column,
    res: 'v1_WebsiteTopics',
    comp: {},
    rel: {},
};

export default WebsiteTopic;
