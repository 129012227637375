import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import {IfCanAccess} from '@react-admin/ra-rbac/esm/src/IfCanAccess';
import React from 'react';
import {Loading, SORT_DESC, useGetIdentity, useStore} from 'react-admin';
import {ACTIONS, CONTENT_STATUS, POST_STATUS, STATE_CODE, STRATEGY_TYPE} from '../../config/statuses';
import {useGetActiveUsers} from '../../hooks/useGetActiveUsers';
import AutocompleteWrapper from '../common/autocompleteWrapper';
import DashboardListWrapper from '../common/dashboardListWrapper';
import DashboardCampaigns from '../generic/dashboardCampaigns';
import DeclinedPostsFields, {declinedPostsSearchFields} from './pmDashboardDeclinedPostsFields';
import OrderedPostsFields, {orderedPostsSearchFields} from './pmDashboardOrderedPostsFields';
import PostsToApproveFields, {postsToApproveSearchFields} from './pmDashboardPostsToApproveFields';
import PostsToUploadFields, {postsToUploadSearchFields} from './pmDashboardPostsToUploadFields';
import RequestedContentFields, {requestedContentSearchFields} from './pmDashboardRequestedContentFields';
import Model from '../../models/db/models';
import OPERATOR from '../../models/db/operators';

const PmDashboardList = (props: any) => {
    const {data: identity} = useGetIdentity();
    const userId = identity?.id;

    const [selectedUserId, setSelectedUserId] = useStore('pmDashboardSelectedUser', userId);
    const {data: activeUsers, isLoading} = useGetActiveUsers();

    if (!userId) return <Loading />;

    return (
        <Grid container display="flex" flexDirection="column" sx={{mt: '20px'}}>
            <Grid item alignSelf="flex-end">
                {isLoading ? (
                    <Loading />
                ) : (
                    <IfCanAccess resource={'pm-dashboard'} action={ACTIONS.SELECT_USER}>
                        <AutocompleteWrapper
                            disableClear={true}
                            styles={{width: 300}}
                            selectedOption={selectedUserId}
                            label="User"
                            options={activeUsers}
                            labelProp="name"
                            onOptionSelected={setSelectedUserId}
                            isRequired={false}
                        />
                    </IfCanAccess>
                )}
            </Grid>
            <Grid item>
                <Card {...props}>
                    <DashboardListWrapper
                        cardTitle="Posts to Approve"
                        title=" "
                        resource={Model.Post.res}
                        filter={{
                            [Model.Post.approver_id]: selectedUserId,
                            [Model.Post.is_marketplace_post]: false,
                            [Model.Post.new_ContentOrderStatus]: CONTENT_STATUS.WAITING_APPROVAL,
                            [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [POST_STATUS.POSTED],
                            [Model.Post.statecode]: STATE_CODE.ENABLED,
                        }}
                        searchFields={postsToApproveSearchFields}
                        sort={{
                            field: Model.Post.new_SenttoApproval,
                            order: SORT_DESC,
                        }}
                    >
                        <PostsToApproveFields />
                    </DashboardListWrapper>

                    <DashboardListWrapper
                            cardTitle="Posts to Approve (Marketplace)"
                            title=" "
                            resource={Model.Post.res}
                            filter={{
                                [Model.Post.approver_id]: selectedUserId,
                                [Model.Post.is_marketplace_post]: true,
                                [Model.Post.new_ContentOrderStatus]: CONTENT_STATUS.WAITING_APPROVAL,
                                [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [POST_STATUS.POSTED],
                                [Model.Post.statecode]: STATE_CODE.ENABLED,
                            }}
                            searchFields={postsToApproveSearchFields}
                            sort={{
                                field: Model.Post.new_SenttoApproval,
                                order: SORT_DESC,
                            }}
                    >
                        <PostsToApproveFields />
                    </DashboardListWrapper>

                    <DashboardListWrapper
                        cardTitle="Requested Content"
                        title="PM Dashboard"
                        resource={Model.Post.res}
                        filter={{
                            [Model.Post.new_ContentOrderStatus]: CONTENT_STATUS.CONTENT_REQUEST,
                            [Model.Post.is_marketplace_post]: false,
                            [Model.Post.requestedfrom_id]: selectedUserId,
                            [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [POST_STATUS.POSTED],
                            [Model.Post.statecode]: STATE_CODE.ENABLED,
                        }}
                        searchFields={requestedContentSearchFields}
                        sort={{
                            field: Model.Post.new_RequestedOn,
                            order: SORT_DESC,
                        }}
                    >
                        <RequestedContentFields />
                    </DashboardListWrapper>

                    <DashboardListWrapper
                            cardTitle="Requested Content (Marketplace)"
                            title=" "
                            resource={Model.Post.res}
                            filter={{
                                [Model.Post.new_ContentOrderStatus]: CONTENT_STATUS.CONTENT_REQUEST,
                                [Model.Post.is_marketplace_post]: true,
                                [Model.Post.requestedfrom_id]: selectedUserId,
                                [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [POST_STATUS.POSTED],
                                [Model.Post.statecode]: STATE_CODE.ENABLED,
                            }}
                            searchFields={requestedContentSearchFields}
                            sort={{
                                field: Model.Post.new_RequestedOn,
                                order: SORT_DESC,
                            }}
                    >
                        <RequestedContentFields />
                    </DashboardListWrapper>

                    <DashboardListWrapper
                        cardTitle="Declined Posts"
                        title=" "
                        resource={Model.Post.res}
                        filter={{
                            declinedby_id: selectedUserId,
                            new_ContentOrderStatus: CONTENT_STATUS.DECLINED,
                            [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [POST_STATUS.POSTED],
                            statecode: STATE_CODE.ENABLED,
                        }}
                        searchFields={declinedPostsSearchFields}
                        sort={{
                            field: Model.Post.new_DeclineDate,
                            order: SORT_DESC,
                        }}
                    >
                        <DeclinedPostsFields />
                    </DashboardListWrapper>

                    <DashboardListWrapper
                        cardTitle="Posts to Upload"
                        title=" "
                        resource={Model.Post.res}
                        filter={{
                            new_ContentOrderStatus: CONTENT_STATUS.APPROVED,
                            [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [POST_STATUS.POSTED],
                            [`${Model.Post.rel.Campaign}#${Model.Campaign.rel.Owner}#${Model.User.id}`]: selectedUserId,
                            statecode: STATE_CODE.ENABLED,
                        }}
                        searchFields={postsToUploadSearchFields}
                        sort={{
                            field: Model.Post.new_DateApproved,
                            order: SORT_DESC,
                        }}
                    >
                        <PostsToUploadFields />
                    </DashboardListWrapper>

                    <DashboardListWrapper
                        cardTitle="Ordered Posts"
                        title=" "
                        resource={Model.Post.res}
                        filter={{
                            orderedby_id: selectedUserId,
                            new_ContentOrderStatus: CONTENT_STATUS.ORDERED,
                            [`${Model.Post.new_PostStatus}${OPERATOR.NIN}`]: [POST_STATUS.POSTED],
                            statecode: STATE_CODE.ENABLED,
                        }}
                        searchFields={orderedPostsSearchFields}
                        sort={{
                            field: Model.Post.CreatedOn,
                            order: SORT_DESC,
                        }}
                    >
                        <OrderedPostsFields />
                    </DashboardListWrapper>

                    <DashboardCampaigns
                        cardTitle="On Site Campaigns"
                        userId={Number(selectedUserId)}
                        strategyType={STRATEGY_TYPE.ON_SITE_CONTENT}
                    />

                    <DashboardCampaigns
                        cardTitle="Link Building Campaigns"
                        userId={Number(selectedUserId)}
                        strategyType={STRATEGY_TYPE.LINK_BUILDING}
                    />

                    <DashboardCampaigns
                        cardTitle="Social Campaigns"
                        userId={Number(selectedUserId)}
                        strategyType={STRATEGY_TYPE.SOCIAL}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};

export default PmDashboardList;
