import {RES} from '../config/statuses';

export const crmAppLanguageEnglish = {
    files: {
        upload: {
            message: '%{message}',
            failure: 'Files not uploaded',
        },
    },
    'ra-audit-log': {
        record_event: {
            comment: 'New published comment',
            copyscape: '',
            PostLinkCheck: 'Post Link Check Succeeded',
            PostChangeStatus: 'updated:\u00A0',
            declined_with_comment: 'declined with comment: ', // here doesn't work space and nbsp, why?
            declined: 'declined',
            new_PostStatus: 'updated:\u00A0',
            offer: 'Offer status updated',
            PublisherStatusChange: 'updated:\u00A0',
            MarketplaceStatusChange: 'updated:\u00A0',
            ApproveSM: '',
            Approve: '',
        },
    },
    ra: {
        action: {
            edit: '',
        },
    },
    post: {
        changes_were_not_saved: 'The changes were not saved!',
    },
    offers: {
        potential_campaigns: {
            show_only_without_offer_website: 'Without Offer Website',
        },
        bulk_update_success: 'offers %{ids} updated successfully',
    },
    bonus: {
        changes_were_edited: 'Bonus successfully edited',
        bonus_bulk_update_success: 'Bonuses with ids %{ids} updated successfully',
    },
    brand: {
        brand_bulk_update_success: 'Brands with ids %{ids} updated successfully',
        brand_was_created: 'Brand %{brand} has been successfully created',
        brand_alias_was_created: 'Alias %{brandAlias} for selected brand has been successfully created',
    },
    purchaseOrder: {
        purchase_order_updated: '%{resource} updated with new Purchase Order',
        new_purchase_order_update_failed: '%{resource} update with new Purchase Order failed',
        purchase_order_created: 'Purchase Order Has Been Created',
    },
    adzz: {
        auth: {
            token_expired: 'Token expired. Please log in again.',
        },
        resources: {
            v1_Posts: {
                title: 'post',
                fields: {
                    new_ContentOrderStatus: 'Content Status',
                    new_PostStatus: 'Post Status',
                    new_OutreachStatus: 'Outreach Status',
                    new_TextHTMLbody: 'Post body',
                    writer_id: 'writer',
                    new_name: 'Source URL',
                    approver_id: 'To Be Approved By',
                    requestedfrom_id: 'Request Receiver',
                    supplier_id: 'Publisher',
                    new_Supplierprice: 'Publisher Price',
                    new_ContentCost: 'Content Cost',
                    new_UploadPrice: 'Upload Price',
                    PublisherStatus: 'Publisher status',
                    marketplacestatus_id: 'Marketplace status',
                    AdvertiserStatus: 'Advertiser status',
                    comp_total_price_writer: 'Writer Fee',
                    comp_total_price_manager: 'Total Price',
                    new_TargetURL1: 'Target URL 1',
                    new_TargetURL2: 'Target URL 2',
                    new_TargetURL3: 'Target URL 3',
                    new_Anchortext1: 'Requested Anchor Text 1',
                    new_Anchortext2: 'Requested Anchor Text 2',
                    new_Anchortext3: 'Requested Anchor Text 3',
                },
                insert: {
                    created: '%{affected_rows} posts was created. IDs: %{ids}',
                    errorApollo: '%{err}',
                },
                attachPO: {
                    success: 'Posts attached to PO successfully',
                    error: 'An error occurred while attaching posts to PO',
                },
                copyscapeCheck: {
                    success: `Copyscape was successful with 0% duplicates found`,
                    error: `Copyscape found %{allpercentmatched}% duplicates in %{count} websites`,
                },
                bulkCreatePosts: {
                    error: {
                        unsaved_form: `Before creating posts, you must save form number %{number}`,
                        unfilled_form: 'Before creating posts, you must fill out forms.',
                    },
                },
            },
            v1_Project: {
                title: 'project',
                fields: {
                    new_Importance: 'Priority',
                    owner_id: 'Owner',
                },
            },
            v1_Campaign: {
                title: 'сampaign',
                fields: {
                    approver_id: 'Approver',
                    new_MonthlyTarget: 'Target',
                    owner_id: 'Campaign Owner',
                    new_MinWordsperpost: 'Default Requested Word Count',
                    new_Priority: 'Campaign Priority',
                    project_id: 'Project',
                    strategy_id: 'Strategy',
                    allow_upload_content_portal: 'Allow Upload From Content Portal',
                },
            },
            v1_ContactBase: {
                title: 'writter',
                fields: {
                    owner_id: 'Marketplace Assistant',
                    EMailAddress1: "Writer's Email",
                    new_WebsitePassword: "Writer's password",
                    monthly_quota: 'Monthly Quota of Posts',
                    new_FullSeoPage: 'Paid For 500 - 699 Words',
                    new_fullseopagebig: 'Paid For 700 - 899 Words',
                    new_fullseopagelarge: 'Paid For 900 - 1499 Words',
                    new_fullseopagejambo: 'Paid For 1500 + Words',
                    new_shortsocialpost: 'Social Short Post Cost',
                    new_longsocialpost: 'Social Long Post Cost',
                    new_SeoShortPost: 'Paid For SEO Post',
                    new_Translation: 'Translation Cost',
                },
            },
            v1_AccountBase: {
                title: 'company',
                fields: {
                    owner_id: 'Company Owner',
                    EMailAddress1: 'Email Address',
                },
            },
            v1_new_receiptBase: {
                title: 'receipt',
                fields: {
                    statuscode: 'Status',
                    CancelledBy: 'Cancelled By',
                    company_id: 'Company',
                    currency_id: 'Currency',
                    new_FromCompany: 'From Company',
                    new_Totalamount: 'Amount',
                    CancelledOn: 'Cancelled On',
                },
                markAsPaid: {
                    success: 'Receipt Marked as Paid successfully',
                    error: 'An error occurred updating Receipt',
                },
            },
            v1_SalesOrderBase: {
                title: 'Affiliate order',
                fields: {
                    new_LastCheck: 'Last Checked',
                    CreatedOn: 'Create On',
                    createdby_id: 'Created By',
                },
            },
            v1_InvoiceBase: {
                title: 'invoice',
                fields: {
                    StatusCode: 'Status',
                    new_PayTo: 'From Company',
                    currency_id: 'Currency',
                    new_TotalVATIncluded: 'Amount',
                    new_producedon: 'Produced On',
                    CancelledOn: 'Cancelled On',
                    CreatedOn: 'Created On',
                    CancelledBy: 'Cancelled By',
                },
            },
            v1_new_POBase: {
                title: 'Purchase order',
                fields: {
                    statuscode: 'Status',
                    currency_id: 'Currency',
                    CancelledBy: 'Cancelled By',
                    approvedby_id: 'Approved By',
                    new_Sum: 'Amount',
                    DeclinedBy: 'Declined By',
                    CancelledOn: 'Cancelled On',
                    CreatedOn: 'Created On',
                    new_PaidOn: 'Paid On',
                    new_ApprovedOn: 'Approved On',
                    DeclinedOn: 'Declined On',
                    new_name: 'Name',
                    new_Description: 'Description',
                    new_PaymentAddress: 'Payment Address',
                    paymentmethod_id: 'Payment Method',
                    new_PaymentType: 'Payment Category',
                    recurringPayment_id: 'Recurring Payment',
                    new_DueDate: 'Due Date',
                    paidby_id: 'Paid By',
                },
            },
            v1_Offer: {
                title: 'offer',
                fields: {
                    createdby_id: 'Created By',
                    currency_id: 'Currency',
                    created_on: 'Created On',
                    link_price: 'Publisher Price',
                    name: 'Name',
                },
            },
            v1_new_brandBase: {
                title: 'brand',
            },
            v1_Website: {
                title: 'website',
                response: `%{message}`,
            },
            v1_new_trackerBase: {
                title: 'tracker',
            },
            v1_LandingPages: {
                title: 'landing page',
                fields: {
                    priority: 'Landing Page Priority',
                },
            },
            v1_Contacts_Roles: {
                createError: 'An error occurred while setting Contact Role',
                error: '%{error}',
            },
            v1_Suppliers: {
                createError: 'An error occurred while creating publisher',
            },
            [RES.BONUS]: {
                title: 'bonus',
            },
        },
        livePosts: {
            created: '%{affected_rows} posts were created. IDs: %{ids}',
            errorApollo: '%{err}',
        },
        importWebsitesStatistics: {
            created: '%{affected_rows} websites were created/updated. IDs: %{ids}',
            errorApollo: '%{err}',
        },
        sendToPool: {
            success: 'Post %{ids} have successfully been added to the pool.',
            error: 'Post %{ids} have not been added to the pool.',
            info: 'Post has been Google Indexed and is therefore not allowed to be sent to pool',
        },
        refreshContentTypes: {
            success: 'Content types was refreshed',
            error: 'Content types was not refreshed: %{errorMessage}',
        },
        changeStateCode: {
            success: '%{typeOfOperation} %{entityName} Successfully',
            error: 'An error occurred while %{typeOfOperation} %{entityName}',
        },
        createContact: {
            success: 'The new record was created successfully',
            error: 'An error occurred while creating the new record',
        },
        plc: {
            success: 'The request to check links for the post ID: %{id} was sent',
        },
        cancelRecord: {
            success: 'This %{entityName} has been cancelled',
            error: 'An error occurred while cancelling the %{entityName}',
        },
        declineRecord: {
            success: 'This %{entityName} has been declined',
            error: 'An error occurred while declining the %{entityName}',
        },
    },
    trackers: {
        common_bulk_update_success: 'Trackers updated successfully',
        common_bulk_update_error: 'An error occurred updating trackers',
        brand_bulk_update_success: "Tracker's brand changed to id %{id}",
        ao_bulk_update_success: "Tracker's affiliate order changed to id %{id}",
    },

    errors: {
        duplication_error: 'Duplication error',
    },
};
