import {Box, Tooltip} from '@mui/material';
import Typography, {TypographyProps} from '@mui/material/Typography/Typography';
import purify from 'dompurify';
import get from 'lodash/get';
import React, {Ref} from 'react';
import {ReferenceField, useRecordContext, WrapperField} from 'react-admin';
import {COL, RES} from '../../config/statuses';
import {ReferenceProps} from '../../models/input-field.models';
import {TextFieldTruncate} from './textFieldTruncate';

export const UserField = ({
    source,
    label,
    sortBy,
}: ReferenceProps & {
    sortBy?: string;
}) => {
    return (
        <ReferenceField reference={RES.USER} source={source} label={label} sortBy={sortBy} link="edit">
            <TruncatedTooltipField source={COL[RES.USER].name} limit={20} />
        </ReferenceField>
    );
};

export const TruncatedTooltipField = (props: TypographyProps & TextFieldTruncateProps) => {
    const {source, textValue, label, limit, sortBy} = props;

    const record = useRecordContext(props);
    const value = textValue ? textValue : get(record, source) || '';

    const TooltipTitle = React.forwardRef((props: any, ref: Ref<HTMLDivElement>) => {
        return (
            <Box ref={ref} component="div" {...props} sx={{whiteSpace: 'nowrap'}}>
                <TextFieldTruncate textValue={textValue} limit={limit} sortBy={sortBy} {...props} />
            </Box>
        );
    });

    return (
        <WrapperField label={label}>
            <Tooltip
                title={
                    <Typography component="span" variant="body2">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: purify.sanitize(value),
                            }}
                        />
                    </Typography>
                }
                placement="top-start"
            >
                <TooltipTitle source={source} />
            </Tooltip>
        </WrapperField>
    );
};

export interface TextFieldTruncateProps {
    source: string;
    textValue?: string;
    label?: string;
    limit?: number;
    sortBy?: string;
}
