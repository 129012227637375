import {v1_PostsCopyScape_select_column} from '../../generated/types';

const PostCopyScape = {
    ...v1_PostsCopyScape_select_column,
    res: 'v1_PostsCopyScape',
    comp: {},
    rel: {},
};

export default PostCopyScape;
