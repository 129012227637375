import {v1_TrackerToTags_select_column} from '../../generated/types';

const TrackersToTags = {
    ...v1_TrackerToTags_select_column,
    res: 'v1_TrackerToTags',
    comp: {},
    rel: {},
};

export default TrackersToTags;
