import {v1_RestrictedNichesToOffer_select_column} from '../../generated/types';

const RestrictedNiche = {
    ...v1_RestrictedNichesToOffer_select_column,
    res: 'v1_RestrictedNichesToOffer',
    comp: {},
    rel: {
        PostNiche: 'PostNiches',
    },
};

export default RestrictedNiche;
