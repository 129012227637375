import {v1_Campaign_select_column} from '../../generated/types';

const Campaign = {
    ...v1_Campaign_select_column,
    res: 'v1_Campaign',
    comp: {},
    rel: {
        Project: 'Project',
        CampaignStrategy: 'Strategy',
        Owner: 'Owner'
    },
    label: 'Campaigns',
    entity: 'Campaign',
};

export default Campaign;
