import {v1_PostsContentStatus_select_column} from '../../generated/types';

const PostContentStatus = {
    ...v1_PostsContentStatus_select_column,
    res: 'v1_PostsContentStatus',
    comp: {},
    rel: {},
};

export default PostContentStatus;
