import {v1_new_languagesBase_select_column} from '../../generated/types';

const Language = {
    ...v1_new_languagesBase_select_column,
    res: 'v1_new_languagesBase',
    comp: {},
    rel: {},
    label: 'Languages',
    entity: 'Language',
};

export default Language;
