import {v1_SalesOrderBase_select_column} from '../../generated/types';

const Order = {
    ...v1_SalesOrderBase_select_column,
    res: 'v1_SalesOrderBase',
    comp: {},
    rel: {
        Company: 'CompanyNew',
        AffiliateOrderType: 'AffiliateProjectNew',
    },
};

export default Order;
