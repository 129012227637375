import {
    AccountBalanceWallet,
    AccountCircle,
    AddCard,
    ArrowForward,
    AutoStories,
    Book,
    Business,
    CircleNotifications,
    CreditScore,
    CurrencyExchange,
    Dvr,
    EmojiEvents,
    EmojiObjects,
    FormatBold,
    Groups,
    HdrAuto,
    Hub,
    Language,
    Lock,
    Memory,
    Newspaper,
    PermContactCalendar,
    PointOfSale,
    Public,
    ReceiptLong,
    ReplayCircleFilled,
    RocketLaunch,
    Stars,
    Translate,
    Troubleshoot,
    UploadFile,
    Store
} from '@mui/icons-material';
import Model from '../models/db/models';
import {IMenuItem} from '../models/menu-item.model';
import {STATE_CODE} from './statuses';

export const menuItemsList: IMenuItem[] = [
    {
        name: 'Dashboards',
        resource: '',
        to: '/',
        label: 'Dashboards',

        children: [
            {
                name: '',
                resource: 'pm-dashboard',
                to: '/pm-dashboard',
                label: 'PM',
                icon: <Dvr color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'outreach-dashboard',
                to: '/outreach-dashboard',
                label: 'Outreach',
                icon: <Hub color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'content-manager',
                to: '/content-manager',
                label: 'Content Manager',
                icon: <PermContactCalendar color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'marketplace-dashboard',
                to: '/marketplace-dashboard',
                label: 'Marketplace',
                icon: <Store color="primary" fontSize="small" />,
            },
        ],
    },
    {
        name: 'Content',
        resource: '',
        to: '/',
        label: 'Content',
        children: [
            {
                name: '',
                resource: 'v1_Project',
                to: {
                    pathname: '/v1_Project',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Projects',
                icon: <RocketLaunch color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Campaign',
                to: {
                    pathname: '/v1_Campaign',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Campaigns',
                icon: <Stars color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Posts',
                to: {
                    pathname: '/v1_Posts',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                        'Campaign%23statecode': STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Posts',
                icon: <Book color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Writers',
                to: {
                    pathname: '/v1_Writers',
                    search: `filter=${JSON.stringify({
                        StateCode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Writers',
                icon: <AutoStories color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_LandingPages',
                to: {
                    pathname: '/v1_LandingPages',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Landing Pages',
                icon: <Newspaper color="primary" fontSize="small" />,
            },
        ],
    },
    {
        name: 'Outreach',
        resource: '',
        to: '/',
        label: 'Outreach',
        children: [
            {
                name: '',
                resource: 'v1_Suppliers',
                to: {
                    pathname: '/v1_Suppliers',
                    search: `filter=${JSON.stringify({
                        StateCode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Publisher',
                icon: <EmojiObjects color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Offer',
                to: {
                    pathname: '/v1_Offer',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Offers',
                icon: <AccountBalanceWallet color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Website',
                to: {
                    pathname: '/v1_Website',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Websites',
                icon: <Language color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_CampaignStrategy',
                to: {
                    pathname: '/v1_CampaignStrategy',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Strategies',
                icon: <ArrowForward color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Advertisers',
                to: '/v1_Advertisers',
                label: 'Advertiser',
                icon: <HdrAuto color="primary" fontSize="small" />,
            },
        ],
    },
    {
        name: 'Affiliate',
        resource: '',
        to: '/',
        label: 'Affiliate',
        children: [
            {
                name: '',
                resource: 'v1_AccountBase',
                to: {
                    pathname: '/v1_AccountBase',
                    search: `filter=${JSON.stringify({
                        StateCode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Companies',
                icon: <Business color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_SalesOrderBase',
                to: {
                    pathname: '/v1_SalesOrderBase',
                    search: `filter=${JSON.stringify({
                        StateCode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Affiliate Orders',
                icon: <AddCard color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_trackerBase',
                to: {
                    pathname: '/v1_new_trackerBase',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Trackers',
                icon: <CircleNotifications color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_brandBase',
                to: {
                    pathname: '/v1_new_brandBase',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Brands',
                icon: <EmojiEvents color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: `${Model.Bonus.res}`,
                to: {
                    pathname: `/${Model.Bonus.res}`,
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: Model.Bonus.label,
                icon: <FormatBold color="primary" fontSize="small" />,
            },
        ],
    },
    {
        name: 'Financials',
        resource: '',
        to: '/',
        label: 'Financials',
        children: [
            {
                name: '',
                resource: 'v1_InvoiceBase',
                to: {
                    pathname: '/v1_InvoiceBase',
                    search: `filter=${JSON.stringify({
                        StateCode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Invoices',
                icon: <PointOfSale color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_receiptBase',
                to: {
                    pathname: '/v1_new_receiptBase',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Receipts',
                icon: <ReceiptLong color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_POBase',
                to: {
                    pathname: '/v1_new_POBase',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Purchase Orders',
                icon: <CurrencyExchange color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_RecurringPayments',
                to: {
                    pathname: '/v1_RecurringPayments',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Recurring Payments',
                icon: <ReplayCircleFilled color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_paymentmethodBase',
                to: {
                    pathname: '/v1_new_paymentmethodBase',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Payment Methods',
                icon: <CreditScore color="primary" fontSize="small" />,
            },
        ],
    },
    {
        name: 'Settings',
        resource: '',
        to: '/',
        label: 'Settings',
        children: [
            {
                name: '',
                resource: 'v1_new_languagesBase',
                to: {
                    pathname: '/v1_new_languagesBase',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Languages',
                icon: <Translate color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_countryBase',
                to: '/v1_new_countryBase',
                label: 'Countries',
                icon: <Public color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_process',
                to: '/v1_process',
                label: 'Process',
                icon: <Memory color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_SystemUserBase',
                to: '/v1_SystemUserBase',
                label: 'Users',
                icon: <AccountCircle color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Teams',
                to: '/v1_Teams',
                label: 'Teams',
                icon: <Groups color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Report',
                to: '/v1_Report',
                label: 'Report',
                icon: <Troubleshoot color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Page',
                to: '/v1_Page',
                label: 'Page',
                icon: <UploadFile color="primary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Credentials',
                to: {
                    pathname: '/v1_Credentials',
                    search: `filter=${JSON.stringify({
                        statecode: STATE_CODE.ENABLED,
                    })}`,
                },
                label: 'Credentials',
                icon: <Lock color="primary" fontSize="small" />,
            },
        ],
    },
];
