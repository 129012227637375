import {v1_Bonuses_select_column} from '../../generated/types';

const Bonus = {
    ...v1_Bonuses_select_column,
    res: 'v1_Bonuses',
    comp: {},
    rel: {
        BonusStatus: 'BonusStatus',
        BonusesToCountries: 'BonusesToCountries',
        BonusesToTypes: 'BonusesToTypes',
        Brand: 'new_brandBase',
        Project: 'Project',
    },
    label: 'Bonuses',
    entity: 'Bonus',
    listParam: 'v1_Bonuses.listParams',
};

export default Bonus;
