import React from 'react';
import {Datagrid, DateField, FunctionField, ReferenceField, TextField} from 'react-admin';
import {v1_Posts} from '../../generated/types';
import {TruncatedTooltipField, UserField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';
import {TruncatedLinkField} from '../common/truncatedLinkField';
import {LOCALE, PAGE} from '../../config/statuses';
import Model from '../../models/db/models';
import OPERATOR from '../../models/db/operators';

const PostsToUploadFields = () => (
    <Datagrid bulkActionButtons={false} rowClick={PAGE.EDIT}>
        <LinkToNewTab propertyName={Model.Post.id} resource={Model.Post.res} />
        <LinkToRecordField source={Model.Post.id} label="Post ID" redirectToShow={false} />
        <TruncatedLinkField source={Model.Post.new_name} label="Source URL" showPropertyName={true} />
        <DateField source={Model.Post.new_DeclineDate} label="Declined On" locales={LOCALE.EN_GB} />
        <TruncatedTooltipField source={Model.Post.new_Title} label="H1 Title" limit={80} />
        <ReferenceField
            source={Model.Post.campaign_id}
            reference={Model.Campaign.res}
            label="Campaign"
            link={PAGE.EDIT}
            sortBy={`${Model.Post.rel.Campaign}.${Model.Campaign.new_name}`}
        >
            <TruncatedTooltipField source={Model.Campaign.new_name} limit={50} />
        </ReferenceField>
        <ReferenceField
            source={Model.Post.campaign_id}
            reference={Model.Campaign.res}
            label="Project"
            link={false}
            sortBy={`${Model.Post.rel.Campaign}.${Model.Campaign.rel.Project}.${Model.Campaign.new_name}`}
        >
            <ReferenceField source={Model.Campaign.project_id} reference={Model.Project.res} link={PAGE.EDIT}>
                <TruncatedTooltipField source={Model.Project.new_name} limit={50} />
            </ReferenceField>
        </ReferenceField>
        <UserField
            source={Model.Post.approvedby_id}
            label="Approved By"
            sortBy={`${Model.Post.rel.ApprovedBy}.${Model.User.name}`}
        />
        <ReferenceField
            source={Model.Post.writer_id}
            reference={Model.Writer.res}
            label="Writer Assigned"
            link={PAGE.EDIT}
            sortBy={`${Model.Post.rel.Writer}.${Model.Writer.FullName}`}
        >
            <TextField source={Model.Writer.FullName} />
        </ReferenceField>
        <FunctionField
            label="Uploaded To Site"
            render={(record: v1_Posts) => (record.new_UploadtoSite ? 'Yes' : 'No')}
            sortBy={Model.Post.new_UploadtoSite}
        />
    </Datagrid>
);

export const postsToUploadSearchFields =
    `${Model.Post.new_Title},` +
    `${Model.Post.new_PostID},` +
    `${Model.Post.rel.Writer}#${Model.Writer.FullName}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.Campaign}#${Model.Campaign.new_name}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.Campaign}#${Model.Campaign.rel.Project}#${Model.Project.new_name}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.ApprovedBy}#${Model.User.name}${OPERATOR.ILIKE}`;

export default PostsToUploadFields;
