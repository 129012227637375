import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {useBasename} from 'ra-core/src/routing/useBasename';
import React from 'react';
import {Link} from 'react-admin';
import {v1_Website} from '../../../generated/types';
import Model from '../../../models/db/models';
import {CsvImportResult} from '../../common/importScv/types';

type ImportWebsiteCsvError = {
    line: number;
    errors: Array<string>;
};

type ImportWebsiteCsvResult = {
    created: Array<v1_Website>;
    updated: Array<v1_Website>;
    error: Array<ImportWebsiteCsvError>;
};

type ImportWebsiteCsvSummaryProps = {
    result: ImportWebsiteCsvResult;
    setResult: React.Dispatch<React.SetStateAction<CsvImportResult | null>>;
    toggleDialog: () => void;
};

const WebsitesTable = ({websites, type}: {websites: Array<v1_Website>; type: string}) => {
    const basename = useBasename();

    return (
        <>
            <Typography>{`${type} websites`}</Typography>
            <Table
                sx={{
                    mb: 2,
                    '& .MuiTableCell-root': {
                        padding: '6px',
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Website ID</TableCell>
                        <TableCell>Link to Website</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{
                        overflowY: 'scroll',
                        maxHeight: '500px',
                    }}
                >
                    {websites.map((website: v1_Website) => {
                        return (
                            <TableRow>
                                <TableCell>{website.id}</TableCell>
                                <TableCell>
                                    {'Click '}
                                    <Link to={`${basename}/${Model.Website.res}/${website.id}`} target="_blank">
                                        here
                                    </Link>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export const ImportCSVSummary = ({result, setResult, toggleDialog}: ImportWebsiteCsvSummaryProps) => {
    const {created, updated, error} = result;
    const closeModal = () => {
        toggleDialog();
        setResult(null);
    };
    return (
        <>
            <DialogTitle>Import CSV Summary</DialogTitle>
            <DialogContent>
                {created.length > 0 && <WebsitesTable websites={result.created} type="created" />}
                {updated.length > 0 && <WebsitesTable websites={result.updated} type="updated" />}
                {error.length > 0 && (
                    <>
                        <Typography>Errors</Typography>
                        <Table
                            sx={{
                                mb: 2,
                                '& .MuiTableCell-root': {
                                    padding: '6px',
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Line</TableCell>
                                    <TableCell>Error</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {error.map((currentError) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{currentError.line}</TableCell>
                                            <TableCell>
                                                {currentError.errors.map((lineError) => {
                                                    return <Typography>{lineError}</Typography>;
                                                })}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={closeModal}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
};
