import {v1_Offer_select_column} from '../../generated/types';

const Offer = {
    ...v1_Offer_select_column,
    res: 'v1_Offer',
    comp: {
        RelatedWebsites: 'comp_related_websites',
    },
    rel: {
        Company: 'Company',
        User: 'Creator',
        Currency: 'Currency',
        Language: 'Language',
        OfferToTags: 'OfferToTags',
        Supplier: 'Supplier',
        Website: 'Website',
        WebsiteTopic: 'WebsiteTopic',
        OfferNiche: 'MarketPlaceOfferNiche',
        RestrictedNiche: 'RestrictedNiches',
    },
    label: 'Offers',
    entity: 'Offers',
};

export default Offer;
