import {v1_Advertisers_select_column} from '../../generated/types';

const Advertiser = {
    ...v1_Advertisers_select_column,
    res: 'v1_Advertisers',
    comp: {},
    rel: {},
    label: 'Advertisers',
    entity: 'Advertiser',
};

export default Advertiser;
