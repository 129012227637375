import {v1_InvoiceBase_select_column} from '../../generated/types';

const Invoice = {
    ...v1_InvoiceBase_select_column,
    res: 'v1_InvoiceBase',
    comp: {},
    rel: {
        Company: 'Company',
        Order: 'Order',
    },
    label: 'Invoices',
    entity: 'invoice',
    listParams: 'v1_InvoiceBase.listParams',
};

export default Invoice;
