import {v1_Suppliers_select_column} from '../../generated/types';

const Supplier = {
    ...v1_Suppliers_select_column,
    res: 'v1_Suppliers',
    comp: {},
    rel: {
        User: 'Owner',
    },
    label: 'Suppliers',
    entity: 'Supplier',
};

export default Supplier;
