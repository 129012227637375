import {v1_ProjectImportance_select_column} from '../../generated/types';

const ProjectImportance = {
    ...v1_ProjectImportance_select_column,
    res: 'v1_ProjectImportance',
    comp: {},
    rel: {},
};

export default ProjectImportance;
