import {v1_ContactBase_select_column} from '../../generated/types';

const Contact = {
    ...v1_ContactBase_select_column,
    res: 'v1_ContactBase',
    comp: {},
    rel: {},
};

export default Contact;
