import gql from 'graphql-tag';

export const GET_LIST_USER_ROLES = gql`
    {
        id
        name
        Users_Roles {
            user_id
        }
        Contacts_Roles {
            contact_id
        }
        Report_Roles {
            role_id
        }
    }
`;

export const CREATE_CONTACT_ROLE_MUTATION = gql`
    mutation CreateContactRole($contactId: Int!, $roleId: Int!) {
        insert_v1_Contacts_Roles_one(object: {contact_id: $contactId, role_id: $roleId}) {
            id
        }
    }
`;
