import {Datagrid, DateField, RaRecord, ReferenceField, TextField} from 'react-admin';
import CurrencyAmountWrapper from '../common/currencyAmountWrapper';
import {TruncatedTooltipField, UserField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';

const ReadyToPublishFields = () => (
    <Datagrid bulkActionButtons={false} rowClick="edit">
        <LinkToNewTab propertyName="id" resource="v1_Posts" />
        <LinkToRecordField source="id" label="Post ID" redirectToShow={false} />
        <DateField source="CreatedOn" label="Ordered On" locales="en-GB" />
        <DateField source="new_DateApproved" label="Approved On" locales="en-GB" />
        <TruncatedTooltipField limit={80} source="new_Title" label="H1 Title" />
        <ReferenceField
            label="Campaign"
            source="campaign_id"
            reference="v1_Campaign"
            link="edit"
            sortBy="Campaign.new_name"
        >
            <TruncatedTooltipField limit={50} source="new_name" />
        </ReferenceField>
        <ReferenceField
            label="Project"
            source="campaign_id"
            reference="v1_Campaign"
            link={false}
            sortBy="Campaign.Project.new_name"
        >
            <ReferenceField reference="v1_Project" source="project_id" link="edit">
                <TruncatedTooltipField limit={50} source="new_name" />
            </ReferenceField>
        </ReferenceField>
        <ReferenceField
            source="website_id"
            link="edit"
            label="Website"
            reference="v1_Website"
            sortBy="Website.new_name"
        >
            <TruncatedTooltipField limit={50} source="new_name" />
        </ReferenceField>
        <UserField label="Request Receiver" source="requestedfrom_id" sortBy="RequestedFrom.name" />
        <ReferenceField
            source="supplier_id"
            label="Publisher"
            reference="v1_Suppliers"
            link={(record: RaRecord) => `/${'v1_Suppliers'}/${record?.id}`}
            sortBy="Supplier.FullName"
        >
            <TextField source="FullName" />
        </ReferenceField>
        <CurrencyAmountWrapper
            label="Publisher Price"
            sourceCurrency="supplier_currency_id"
            sourceAmount="new_Supplierprice"
            sortBy="new_Supplierprice"
        />
    </Datagrid>
);

export const readyToPublishSearchFields =
    'new_Title,' +
    'new_PostID,' +
    'Supplier#FullName@_ilike,' +
    'Website#new_name@_ilike,' +
    'Campaign#new_name@_ilike,' +
    'Campaign#Project#new_name@_ilike,' +
    'RequestedFrom#name@_ilike';

export default ReadyToPublishFields;
