import {v1_new_regemailBase_select_column} from '../../generated/types';

const RegistrationEmail = {
    ...v1_new_regemailBase_select_column,
    res: 'v1_new_regemailBase',
    comp: {},
    rel: {},
};

export default RegistrationEmail;
