import {v1_new_affordertypeBase_select_column} from '../../generated/types';

const AffiliateOrderType = {
    ...v1_new_affordertypeBase_select_column,
    res: 'v1_new_affordertypeBase',
    comp: {},
    rel: {},
    label: 'AO Categories',
};

export default AffiliateOrderType;
