import React, {useEffect} from 'react';
import {AutocompleteArrayInput, AutocompleteInput, ReferenceInput, required, SORT_ASC, TextInput} from 'react-admin';
import {useFormContext, useWatch} from 'react-hook-form';
import {COL, RES, STATE_CODE} from '../../../config/statuses';
import {EmailInputWithLink} from '../../common/emailInputWithLink';
import {UserInput} from '../../common/inputWrappers';
import {ReferenceManyToManyInputWithLink} from '../../common/referenceManyToManyInputWithLink';

export const CreatePublisherFormDropdownFields = ({
    getFieldValues,
    userId,
}: {
    getFieldValues: (values: any) => void;
    userId?: number;
}) => {
    const form = useFormContext();
    const formValues = useWatch({control: form.control});

    useEffect(() => {
        if (getFieldValues) {
            getFieldValues(formValues);
        }
    }, [formValues, getFieldValues]);

    return (
        <>
            <TextInput
                source={COL[RES.PUBLISHER].FirstName}
                label="First Name"
                validate={required()}
                variant="outlined"
                fullWidth
                helperText={false}
            />
            <TextInput
                source={COL[RES.PUBLISHER].LastName}
                label="Last Name"
                variant="outlined"
                fullWidth
                helperText={false}
            />
            <EmailInputWithLink
                source={COL[RES.PUBLISHER].EMailAddress1}
                label="Email"
                type="email"
                isRequired={true}
                variant="outlined"
                fullWidth
                helperText={false}
            />
            <ReferenceInput source={COL[RES.PUBLISHER].currency_id} reference={RES.CURRENCY}>
                <AutocompleteInput
                    optionText={COL[RES.CURRENCY].CurrencySymbol}
                    label={'Currency'}
                    filterToQuery={(searchText) => ({
                        CurrencySymbol: searchText,
                    })}
                    parse={(value) => (value === '' ? null : value)}
                    variant="outlined"
                    helperText={false}
                />
            </ReferenceInput>
            <ReferenceInput
                source={COL[RES.PUBLISHER].paymentmethod_id}
                reference={RES.PAYMENT_METHOD}
                filter={{statecode: STATE_CODE.ENABLED}}
                sort={{
                    field: COL[RES.PAYMENT_METHOD].new_name,
                    order: SORT_ASC,
                }}
            >
                <AutocompleteInput
                    optionText={COL[RES.PAYMENT_METHOD].new_name}
                    label={'Payment Method'}
                    filterToQuery={(searchText) => ({
                        new_name: searchText,
                    })}
                    parse={(value) => (value === '' ? null : value)}
                    variant="outlined"
                    helperText={false}
                />
            </ReferenceInput>
            <TextInput
                source={RES.PAYMENT_ADDRESS}
                label="Payment Address"
                variant="outlined"
                fullWidth
                helperText={false}
            />
            <UserInput
                source={COL[RES.PUBLISHER].owner_id}
                label="Owner"
                isRequired={true}
                defaultValue={userId}
                variant="outlined"
                fullWidth
                helperText={false}
            />
            <ReferenceManyToManyInputWithLink
                source={COL[RES.PUBLISHER].id}
                reference={RES.TAG}
                through={RES.PUBLISHER_TAG_REL}
                using={`${COL[RES.PUBLISHER_TAG_REL].supplier_id},${COL[RES.PUBLISHER_TAG_REL].tag_id}`}
                sort={{
                    field: `${COL[RES.PUBLISHER_TAG_REL].tag_id}`,
                    order: SORT_ASC,
                }}
            >
                <AutocompleteArrayInput
                    label="Publisher Tags"
                    optionText={COL[RES.TAG].supplier_tag}
                    optionValue={COL[RES.TAG].id}
                    filterToQuery={(searchText) => ({
                        supplier_tag: searchText,
                    })}
                    parse={(value) => (value === '' ? null : value)}
                    variant="outlined"
                    fullWidth
                    helperText={false}
                />
            </ReferenceManyToManyInputWithLink>
        </>
    );
};
