import {v1_new_trackerBase_select_column} from '../../generated/types';

const Tracker = {
    ...v1_new_trackerBase_select_column,
    res: 'v1_new_trackerBase',
    comp: {
        clicks_last_30_days: 'trackers_clicks_30_days',
    },
    rel: {
        Order: 'SalesOrderBase',
        Deal: 'Deal',
        Brand: 'Brand',
    },
    label: 'Trackers',
    entity: 'Tracker',
};

export default Tracker;
