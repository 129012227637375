import {CardContent, CardHeader} from '@mui/material';
import {List, Pagination, SearchInput} from 'react-admin';
import {DashboardListProps} from '../../types/dashboard-list-props.type';

const ListPagination = () => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />;

const DashboardListWrapper = (props: DashboardListProps) => {
    const {cardTitle, title, resource, filter, children, searchFields, sort} = props;
    const dashboardSearchFilter = [<SearchInput source={searchFields} alwaysOn key="Search" />];
    return (
        <>
            <CardHeader title={cardTitle} />
            <CardContent>
                <List
                    filters={dashboardSearchFilter}
                    hasCreate={false}
                    title={title}
                    resource={resource}
                    filter={filter}
                    empty={false}
                    actions={false}
                    disableSyncWithLocation
                    sort={sort}
                    pagination={<ListPagination />}
                >
                    {children}
                </List>
            </CardContent>
        </>
    );
};

export default DashboardListWrapper;
