import {CellChange, Column, Id, NumberCell, ReactGrid, Row, TextCell} from '@silevis/reactgrid';
import {useState} from 'react';
import '@silevis/reactgrid/styles.css';

export interface LivePostData {
    SourceUrl: string;
    TargetUrl1: string;
    AnchorText1: string;
    TargetUrl2: string;
    AnchorText2: string;
    TargetUrl3: string;
    AnchorText3: string;
    SupplierPrice: number;
    ContentCost: number;
    UploadPrice: number;
    H1Title: string;
}

interface LivePostsSpreadsheetProps {
    livePosts: LivePostData[];
    handleTableDataChanges: any;
}

const formatNumbers = Intl.NumberFormat('en-US', {
    useGrouping: false,
});

const headerRow: Row = {
    rowId: 'header',
    cells: [
        {
            type: 'header',
            text: 'Source URL',
        },
        {
            type: 'header',
            text: 'Target URL 1',
        },
        {
            type: 'header',
            text: 'Anchor Text 1',
        },
        {
            type: 'header',
            text: 'Target URL 2',
        },
        {
            type: 'header',
            text: 'Anchor Text 2',
        },
        {
            type: 'header',
            text: 'Target URL 3',
        },
        {
            type: 'header',
            text: 'Anchor Text 3',
        },
        {
            type: 'header',
            text: 'Supplier Price',
        },
        {
            type: 'header',
            text: 'Content Cost',
        },
        {
            type: 'header',
            text: 'Upload Price',
        },
        {
            type: 'header',
            text: 'H1 Title',
        },
    ],
};

const getRows = (livePosts: LivePostData[]): Row[] => [
    headerRow,
    ...livePosts.map<Row>((livePost, idx) => ({
        rowId: idx,
        cells: [
            {
                type: 'text',
                text: livePost.SourceUrl,
            },
            {
                type: 'text',
                text: livePost.TargetUrl1,
            },
            {
                type: 'text',
                text: livePost.AnchorText1,
            },
            {
                type: 'text',
                text: livePost.TargetUrl2,
            },
            {
                type: 'text',
                text: livePost.AnchorText2,
            },
            {
                type: 'text',
                text: livePost.TargetUrl3,
            },
            {
                type: 'text',
                text: livePost.AnchorText3,
            },
            {
                type: 'number',
                value: livePost.SupplierPrice,
                hideZero: true,
                format: formatNumbers,
            },
            {
                type: 'number',
                value: livePost.ContentCost,
                hideZero: true,
                format: formatNumbers,
            },
            {
                type: 'number',
                value: livePost.UploadPrice,
                hideZero: true,
                format: formatNumbers,
            },
            {
                type: 'text',
                text: livePost.H1Title,
            },
        ],
    })),
];

const getColumns = (): Column[] => [
    {
        columnId: 'SourceUrl',
        resizable: true,
    },
    {
        columnId: 'TargetUrl1',
        resizable: true,
    },
    {
        columnId: 'AnchorText1',
        resizable: true,
    },
    {
        columnId: 'TargetUrl2',
        resizable: true,
    },
    {
        columnId: 'AnchorText2',
        resizable: true,
    },
    {
        columnId: 'TargetUrl3',
        resizable: true,
    },
    {
        columnId: 'AnchorText3',
        resizable: true,
    },
    {
        columnId: 'SupplierPrice',
        resizable: true,
    },
    {
        columnId: 'ContentCost',
        resizable: true,
    },
    {
        columnId: 'UploadPrice',
        resizable: true,
    },
    {
        columnId: 'H1Title',
        resizable: true,
    },
];

export const getLivePosts = (): LivePostData[] => {
    const maxRows = 50;
    const result = [];
    for (let r = 1; r <= maxRows; r++) {
        result.push({
            SourceUrl: '',
            TargetUrl1: '',
            AnchorText1: '',
            TargetUrl2: '',
            AnchorText2: '',
            TargetUrl3: '',
            AnchorText3: '',
            SupplierPrice: 0,
            ContentCost: 0,
            UploadPrice: 0,
            H1Title: '',
        });
    }
    return result;
};

export const applyChangesToLivePosts = (
    changes: CellChange<TextCell | NumberCell>[],
    prevLivePosts: any[]
): LivePostData[] => {
    changes.forEach((change) => {
        const livePostIndex = change.rowId as number;
        const fieldId = change.columnId;

        if (change.type === 'number') {
            prevLivePosts[livePostIndex][fieldId] = change.newCell.value;
        }
        if (change.type === 'text') {
            prevLivePosts[livePostIndex][fieldId] = change.newCell.text;
        }
    });
    return [...prevLivePosts];
};

export const LivePostsSpreadsheet = ({livePosts, handleTableDataChanges}: LivePostsSpreadsheetProps) => {
    const rows = getRows(livePosts);
    const [columns, setColumns] = useState<Column[]>(getColumns());

    const handleColumnResize = (ci: Id, width: number) => {
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = {
                ...resizedColumn,
                width,
            };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    };

    return (
        <ReactGrid
            rows={rows}
            columns={columns}
            enableColumnSelection
            enableRowSelection
            enableRangeSelection
            onColumnResized={handleColumnResize}
            onCellsChanged={handleTableDataChanges}
        />
    );
};
