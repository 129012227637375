import {
    AlignmentButtons,
    ClearButtons,
    ColorButtons,
    FormatButtons,
    LinkButtons,
    ListButtons,
    RichTextInput,
    RichTextInputToolbar,
} from 'ra-input-rich-text';
import React, {useEffect} from 'react';
import {
    AutocompleteInput,
    DateInput,
    ReferenceInput,
    ReferenceInputProps,
    required,
    SORT_ASC,
    TextInput,
    useGetOne,
} from 'react-admin';
import {FieldValues, useFormContext, UseFormReturn} from 'react-hook-form';
import {COL, PAYMENT_CATEGORY, RES, STATE_CODE, TYPE_OF_CONTRACTOR} from '../../../config/statuses';
import {DisplayProps} from '../../../models/input-field.models';
import {parseValue} from '../../../utility/parseValue';
import {autocompleteInputReadOnlyProps} from '../../../utility/readOnly';
import {CurrencyInput} from '../inputWrappers';
import {ReferenceInputWithLink} from '../referenceInputWithLink';
import {POFromDropdownContractor} from './createPOFromDropdown';

const setFormValueIfNotDirty = (
    form: UseFormReturn<FieldValues, any, undefined>,
    fieldName: string,
    newValue: string | number
) => {
    const isDirtyField = form.getFieldState(fieldName)?.isDirty;
    if (!isDirtyField) form.setValue(fieldName, newValue);
};

const RenderReferenceInputWithLink = ({
    source,
    reference,
    label,
    defaultValue,
    validate,
    referenceField,
    readOnly,
}: {
    label: string;
    reference: keyof typeof COL;
    referenceField: string;
    readonly?: boolean;
} & ReferenceInputProps &
    DisplayProps) => (
    <ReferenceInputWithLink
        source={source}
        reference={reference}
        filter={{StateCode: STATE_CODE.ENABLED}}
        sort={{
            field: referenceField,
            order: SORT_ASC,
        }}
        hideLink
    >
        <AutocompleteInput
            disabled
            label={label}
            optionText={referenceField}
            defaultValue={defaultValue}
            parse={(value) => (value === '' ? null : value)}
            filterToQuery={(searchText) => ({
                [referenceField]: searchText,
            })}
            validate={validate}
            variant="outlined"
            helperText={false}
            {...(readOnly ? autocompleteInputReadOnlyProps : {})}
        />
    </ReferenceInputWithLink>
);

export const CreatePOFromDropdownFields = ({
    contractor,
    newPOName,
}: {
    contractor: POFromDropdownContractor;
    newPOName: string;
}) => {
    const form = useFormContext();

    const currentFormContractor = form.watch(
        contractor.type === TYPE_OF_CONTRACTOR.SUPPLIER
            ? COL[RES.PURCHASE_ORDER].supplier_id
            : COL[RES.PURCHASE_ORDER].writer_id
    );

    const {data: contractorData} = useGetOne(
        contractor.type === TYPE_OF_CONTRACTOR.SUPPLIER ? RES.PUBLISHER : RES.WRITER,
        {id: currentFormContractor},
        {enabled: !!contractor.id}
    );

    useEffect(() => {
        if (contractorData) {
            setFormValueIfNotDirty(form, COL[RES.PURCHASE_ORDER].new_PaymentAddress, contractorData.new_PaymentAddress);
            setFormValueIfNotDirty(form, COL[RES.PURCHASE_ORDER].paymentmethod_id, contractorData.paymentmethod_id);
        }
    }, [contractorData, form]);

    return (
        <>
            <TextInput
                label="PO Name"
                fullWidth
                required={true}
                variant="outlined"
                source={COL[RES.PURCHASE_ORDER].new_name}
                defaultValue={newPOName}
            />
            <RichTextInput
                toolbar={
                    <RichTextInputToolbar>
                        <FormatButtons />
                        <ColorButtons />
                        <AlignmentButtons />
                        <ListButtons />
                        <LinkButtons />
                        <ClearButtons />
                    </RichTextInputToolbar>
                }
                fullWidth
                validate={required()}
                source={COL[RES.PURCHASE_ORDER].new_Description}
                label="Description"
                sx={{wordBreak: 'break-word'}}
                helperText={false}
            />
            {contractor.type === TYPE_OF_CONTRACTOR.WRITER ? (
                <RenderReferenceInputWithLink
                    readOnly
                    label="Writer"
                    defaultValue={contractor.id}
                    reference={RES.WRITER}
                    source={COL[RES.PURCHASE_ORDER].writer_id}
                    optionText={COL[RES.WRITER].FullName}
                    referenceField={COL[RES.WRITER].FullName}
                />
            ) : (
                <RenderReferenceInputWithLink
                    readOnly
                    label="Publisher"
                    defaultValue={contractor.id}
                    reference={RES.PUBLISHER}
                    source={COL[RES.PURCHASE_ORDER].supplier_id}
                    optionText={COL[RES.WRITER].FullName}
                    referenceField={COL[RES.PUBLISHER].FullName}
                />
            )}
            <TextInput
                fullWidth
                helperText={false}
                source={COL[RES.PURCHASE_ORDER].new_PaymentAddress}
                label="Payment Address"
                variant="outlined"
            />
            <ReferenceInput
                source={COL[RES.PURCHASE_ORDER].paymentmethod_id}
                reference={RES.PAYMENT_METHOD}
                filter={{statecode: STATE_CODE.ENABLED}}
                sort={{
                    field: COL[RES.PAYMENT_METHOD].new_name,
                    order: SORT_ASC,
                }}
            >
                <AutocompleteInput
                    fullWidth
                    optionText={COL[RES.PAYMENT_METHOD].new_name}
                    label="Payment Method"
                    variant="outlined"
                    defaultValue={null}
                    filterToQuery={(searchText) => ({
                        [COL[RES.PAYMENT_METHOD].new_name]: searchText,
                    })}
                    parse={parseValue}
                    helperText={false}
                />
            </ReferenceInput>
            <CurrencyInput source={COL[RES.PURCHASE_ORDER].currency_id} isRequired variant="outlined" />

            <ReferenceInput
                source={COL[RES.PURCHASE_ORDER].new_PaymentType}
                reference={RES.PAYMENT_CATEGORY}
                sort={{
                    field: COL[RES.PAYMENT_CATEGORY].id,
                    order: SORT_ASC,
                }}
            >
                <AutocompleteInput
                    defaultValue={
                        contractor.type === TYPE_OF_CONTRACTOR.SUPPLIER
                            ? PAYMENT_CATEGORY.POST_DEAL
                            : PAYMENT_CATEGORY.CONTENT
                    }
                    fullWidth
                    optionText={COL[RES.PAYMENT_CATEGORY].name}
                    label="Payment Category"
                    filterToQuery={(searchText) => ({name: searchText})}
                    parse={parseValue}
                    variant="outlined"
                    validate={required()}
                    helperText={false}
                />
            </ReferenceInput>
            <DateInput
                source={COL[RES.PURCHASE_ORDER].new_DueDate}
                label="Due Date"
                validate={required()}
                fullWidth
                variant="outlined"
                helperText={false}
            />
        </>
    );
};
