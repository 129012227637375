import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {authLink, httpLink} from './config/apolloConfig';
import {hasuraGqlAdminSecret, hasuraGqlEndpoint, isLocalHasura} from './environment-variables';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    uri: hasuraGqlEndpoint,
    ...(isLocalHasura() && {
        headers: {
            'x-hasura-admin-secret': hasuraGqlAdminSecret,
        },
    }),
});

root.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
);

reportWebVitals();
