import {v1_WebsiteDToxRisk_select_column} from '../../generated/types';

const WebsiteDtoxRisk = {
    ...v1_WebsiteDToxRisk_select_column,
    res: 'v1_WebsiteDToxRisk',
    comp: {},
    rel: {},
};

export default WebsiteDtoxRisk;
