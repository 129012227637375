import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const theme = createTheme({
    palette: {
        primary: {
            main: '#fc0303',
        },
        secondary: {
            main: '#fc7703',
        },
        success: {
            main: '#128c20',
        },
    },
});

type ProgressBarWithLabelColors = 'primary' | 'secondary' | 'success';

interface ProgressBarWithLabelProps {
    value: number;
    displayValue: string;
}

const ProgressBarWithLabel = (props: ProgressBarWithLabelProps) => {
    const {value, displayValue} = props;

    const getColor = (value: number): ProgressBarWithLabelColors => {
        if (value < 60) {
            return 'primary';
        } else if (value < 85) {
            return 'secondary';
        } else {
            return 'success';
        }
    };

    const color = getColor(value);
    const progressValue = value <= 100 ? value : 100;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    mr: 1,
                }}
            >
                <ThemeProvider theme={theme}>
                    <LinearProgress variant="determinate" value={progressValue} color={color} />
                </ThemeProvider>
            </Box>

            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">
                    {displayValue}
                </Typography>
            </Box>
        </Box>
    );
};

export default ProgressBarWithLabel;
