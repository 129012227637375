import ContentCreate from '@mui/icons-material/Create';
import {Grid, IconButton} from '@mui/material';
import {ReferenceManyToManyInput, ReferenceManyToManyInputProps} from '@react-admin/ra-relationships';
import {useCreatePath} from 'react-admin';
import {Link} from 'react-router-dom';
import {DisplayProps} from '../../models/input-field.models';

export const ReferenceManyToManyInputWithLink = (props: ReferenceManyToManyInputProps & DisplayProps) => {
    const createPath = useCreatePath();

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <ReferenceManyToManyInput {...props} />
            </Grid>
            <Grid item xs="auto">
                <IconButton
                    component={Link}
                    to={createPath({
                        type: 'list',
                        resource: props.reference,
                    })}
                    target="_blank"
                    onClick={stopPropagation}
                    sx={{mt: 1}}
                >
                    <ContentCreate />
                </IconButton>
                {props?.urlLinkIcon}
            </Grid>
        </Grid>
    );
};

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();
