import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import React, {useState} from 'react';
import {Loading, SimpleForm, useCreateSuggestionContext, useGetIdentity, useNotify, useRefresh} from 'react-admin';
import {RES, ROLES, ROUTES} from '../../../config/statuses';
import {v1_ContactBase} from '../../../generated/types';
import {sendPostToApi} from '../../../services/api';
import {TransformDataForContactBase} from '../../common/transformDataForContactBase';
import {CreatePublisherFormDropdownFields} from './createPublisherFromDropdownFields';

const CreatePublisherFromDropdown = () => {
    const {onCancel, onCreate} = useCreateSuggestionContext();
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const notify = useNotify();
    const [fieldValues, setFieldValues] = useState<v1_ContactBase>();
    const refresh = useRefresh();
    if (isIdentityLoading) return <Loading />;

    const handleSubmit = async () => {
        const response = await createPublisher(TransformDataForContactBase(fieldValues as v1_ContactBase));
        onCreate(response);
        refresh();
    };

    const handleChildFormValues = (values: v1_ContactBase) => {
        setFieldValues(values);
    };

    const createPublisher = async (data: v1_ContactBase) => {
        try {
            const contactRoleCreateResponse = await sendPostToApi(ROUTES.PUBLISHER_CREATE, {
                ...data,
                role_id: ROLES.PUBLISHER,
            });
            return contactRoleCreateResponse.data;
        } catch (error: any) {
            notify(`adzz.resources.${RES.PUBLISHER}.createError`, {
                type: 'error',
                messageArgs: {
                    error: error.message,
                },
            });
        }
    };

    return (
        <Dialog open onClose={onCancel}>
            <SimpleForm onSubmit={handleSubmit} toolbar={false}>
                <DialogContent>
                    <CreatePublisherFormDropdownFields
                        getFieldValues={handleChildFormValues}
                        userId={Number(identity?.id)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </SimpleForm>
        </Dialog>
    );
};

export default CreatePublisherFromDropdown;
