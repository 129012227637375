import {ApolloClient, InMemoryCache} from '@apollo/client';
import {authLink, httpLink} from '../config/apolloConfig';
import {hasuraGqlAdminSecret, hasuraGqlEndpoint, isLocalHasura} from '../environment-variables';

export const clientOptions: any = new ApolloClient({
    link: authLink.concat(httpLink),
    uri: hasuraGqlEndpoint,
    cache: new InMemoryCache(),
    ...(isLocalHasura() && {
        headers: {
            'x-hasura-admin-secret': hasuraGqlAdminSecret,
        },
    }),
});
