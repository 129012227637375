import {defaultTheme} from 'react-admin';

export const THEME_COLORS = {
    AQUA: '#039be5',
    BLACK: '#000000',
    BLUE: '#0085E2',
    BLUE_GREEN: '#008080',
    CHESTNUT: '#CD5C5C',
    DARK_GRAY: '#303241',
    DARK_ORANGE: '#FF8C00',
    DARK_YELLOW: '#cc7436',
    DEEP_BROWN: '#541811',
    DEEP_YELLOW: '#b27d00',
    GOLD: '#FFD700',
    GRAY: '#818181',
    HOT_PINK: '#FF69B4',
    LIGHT_BLUE: '#40A3E9',
    LIGHT_DARK_GRAY: '#414354',
    LIGHT_GRAY: '#F4F4F4',
    LIGHT_GREEN: '#90EE90',
    MATE_GRAY: '#EAEAEA',
    MAGENTA: '#8B008B',
    POWDER_BLUE: '#ADD8E6',
    RED: '#FF0000',
    TRANSPARENT_GRAY: '#00000099',
    VIOLET: '#5D4C6F',
    WHITE: '#FFF',
    YELLOW_GREEN: '#4caf50',
    CRAYOLA: '#6699DA',
};

export const customTheme: any = {
    ...defaultTheme,

    palette: {
        mode: 'light',
        primary: {
            main: THEME_COLORS.BLUE,
        },
        secondary: {
            main: THEME_COLORS.LIGHT_BLUE,
        },
        background: {
            highlighted: {primary: '#e2f7fe', secondary: '#fff5e4'},
        },
    },

    sidebar: {
        width: 200,
        closedWidth: 0,
    },

    components: {
        ...defaultTheme.components,

        // React Admin Styles

        RaList: {
            styleOverrides: {
                root: {
                    '& .RaList-main': {
                        paddingLeft: '10px',
                    },
                    '& .MuiPaper-root': {
                        backgroundColor: THEME_COLORS.LIGHT_GRAY,
                    },
                },
            },
        },

        RaShow: {
            styleOverrides: {
                root: {
                    '& .RaShow-card': {paddingBottom: 20},
                    '& .ra-field-new_Description': {
                        overflowX: 'auto',
                        maxWidth: '60vw',
                    },
                },
            },
        },

        RaEdit: {
            styleOverrides: {
                root: {
                    '& .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                    '& .list-page': {
                        '& .MuiCardContent-root': {
                            backgroundColor: THEME_COLORS.LIGHT_GRAY,
                            boxShadow: 'none',
                        },
                    },
                },
            },
        },

        RaSidebar: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-root': {
                        backgroundColor: THEME_COLORS.DARK_GRAY,
                    },
                },
            },
        },

        RaCreate: {
            styleOverrides: {
                root: {
                    '& .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        RaBulkActionsToolbar: {
            styleOverrides: {
                root: {
                    '& .RaBulkActionsToolbar-topToolbar': {
                        position: 'sticky',
                        right: 0,
                    },
                },
            },
        },

        RaDatagrid: {
            styleOverrides: {
                root: {
                    '& .RaDatagrid-headerCell': {
                        backgroundColor: THEME_COLORS.LIGHT_GRAY,
                        fontWeight: 600,
                    },
                    '& .RaDatagrid-thead': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / .2)',
                        clipPath: 'inset(0 0 -4px 0)',
                    },
                },
            },
        },

        RaLayout: {
            styleOverrides: {
                root: {
                    backgroundColor: THEME_COLORS.DARK_GRAY,
                    paddingLeft: '6px',
                    paddingBottom: '20px',
                    '& .css-pofitp-MuiListItem-root': {
                        padding: 0,
                    },
                    '& .RaLayout-content': {
                        backgroundColor: THEME_COLORS.LIGHT_GRAY,
                    },
                },
            },
        },

        RaMenuItem: {
            styleOverrides: {
                root: {
                    color: THEME_COLORS.WHITE,
                    paddingLeft: '2px',
                    '& .RaMenuItem-nestedList': {
                        paddingLeft: 0,
                    },
                    '& .MuiListItemButton-root .MuiListItemText-primary': {
                        fontSize: '1rem !important',
                        lineHeight: '1.5 !important',
                    },
                    '& .RaMenuItem-active': {
                        color: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        RaRichTextInput: {
            styleOverrides: {
                root: {
                    '& .ProseMirror': {
                        minHeight: '140px',
                        backgroundColor: THEME_COLORS.WHITE + ' !important',
                    },
                    '& .RaRichTextInput-editorContent': {
                        maxHeight: '160px',
                        overflowY: 'auto',
                    },
                },
            },
        },

        RaTabbedForm: {
            styleOverrides: {
                root: {
                    '& .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        RaSavedQueriesList: {
            styleOverrides: {
                root: {
                    '& .MuiTypography-root': {
                        textTransform: 'Capitalize',
                        fontSize: '14px',
                        letterSpacing: '0.7px',
                        lineHeight: '18px',
                    },
                },
            },
        },

        RaFilterForm: {
            styleOverrides: {
                root: {
                    '& .MuiTypography-root': {
                        fontSize: '14px',
                        letterSpacing: '0.7px',
                        lineHeight: '18px',
                    },
                },
            },
        },

        // Mui Styles

        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root': {
                        fontSize: '14px',
                        letterSpacing: '0.7px',
                        lineHeight: '18px',
                    },
                },
            },
        },

        MuiList: {
            styleOverrides: {
                root: {
                    '& .MuiListItemText-root': {
                        '& .MuiTypography-root': {
                            fontSize: '14px',
                            letterSpacing: '0.7px',
                            lineHeight: '18px',
                        },
                    },
                },
            },
        },

        MuiCardContent: {
            styleOverrides: {
                root: {
                    backgroundColor: THEME_COLORS.LIGHT_GRAY,
                    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / .2)',
                },
            },
        },

        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: THEME_COLORS.LIGHT_GRAY,
                },
            },
        },

        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    RaFilterListItem: {
                        '& .selected': {
                            backgroundColor: 'rgba(0, 133, 226, 0)',
                        },
                    },
                },
            },
        },

        MuiPaper: {
            styleOverrides: {
                root: {
                    '.RaTabbedForm-root .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                    boxShadow: 'none',
                },
            },
        },

        MuiTableBody: {
            styleOverrides: {
                root: {
                    tr: {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                    'tr:nth-of-type(even)': {
                        backgroundColor: THEME_COLORS.LIGHT_GRAY,
                    },
                },
            },
        },
    },
};

export const darkTheme: any = {
    ...defaultTheme,

    palette: {
        ...customTheme.palette,
        background: {
            highlighted: {primary: '#03445a', secondary: '#5e3b00'},
        },
        mode: 'dark',
    },

    sidebar: {
        ...customTheme.sidebar,
    },

    components: {
        // React Admin Styles

        RaList: {
            ...customTheme.RaList,
        },

        RaShow: {
            ...customTheme.RaShow,
        },

        RaBulkActionsToolbar: {
            ...customTheme.RaBulkActionsToolbar,
        },

        RaDatagrid: {
            styleOverrides: {
                root: {
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 600,
                    },
                    '& .RaDatagrid-thead': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / .2)',
                        clipPath: 'inset(0 0 -4px 0)',
                    },
                },
            },
        },

        RaLayout: {
            styleOverrides: {
                root: {
                    paddingLeft: '6px',
                    paddingBottom: '20px',
                    '& .css-pofitp-MuiListItem-root': {
                        padding: 0,
                    },
                },
            },
        },

        RaMenuItem: {
            styleOverrides: {
                root: {
                    paddingLeft: '2px',
                    '& .RaMenuItem-nestedList': {
                        paddingLeft: 0,
                    },
                    '& .MuiListItemButton-root .MuiListItemText-primary': {
                        fontSize: '1rem !important',
                        lineHeight: '1.5 !important',
                    },
                },
            },
        },

        RaRichTextInput: {
            ...customTheme.RaRichTextInput,
        },

        RaSavedQueriesList: {
            styleOverrides: {
                root: {
                    '& .MuiTypography-root': {
                        textTransform: 'Capitalize',
                        fontSize: '14px',
                        letterSpacing: '0.7px',
                        lineHeight: '18px',
                    },
                },
            },
        },

        RaFilterForm: {
            styleOverrides: {
                root: {
                    '& .MuiTypography-root': {
                        fontSize: '14px',
                        letterSpacing: '0.7px',
                        lineHeight: '18px',
                    },
                },
            },
        },

        // Mui Styles

        MuiFormControl: {
            ...customTheme.components.MuiFormControl,
        },

        MuiList: {
            ...customTheme.components.MuiList,
        },

        MuiCardContent: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / .2)',
                },
            },
        },

        MuiListItem: {
            ...customTheme.components.MuiListItem,
        },

        MuiTableBody: {
            styleOverrides: {
                root: {
                    tr: {
                        backgroundColor: THEME_COLORS.LIGHT_DARK_GRAY,
                    },

                    'tr:nth-of-type(even)': {
                        backgroundColor: THEME_COLORS.DARK_GRAY,
                    },
                },
            },
        },
    },
};

export const themeColors: any = {
    GREEN: '#4caf50',
    ORANGE: 'orange',
    YELLOW: 'yellow',
    BLUE: '#60a2e6',
    RED: 'red',
    BLACK: 'black',
    GRAY: 'grey',
    PINK: '#FF33E3',
    PURPLE: 'purple',
    DARK_GREEN: '#107430',
    BROWN: 'brown',
    WHITE: '#fff',
};

export const datagridStyle = {width: '85vw', overflow: 'auto'};
