import {v1_BrandAlias_select_column} from '../../generated/types';

const BrandAlias = {
    ...v1_BrandAlias_select_column,
    res: 'v1_BrandAlias',
    comp: {},
    rel: {},
    label: 'Brand Aliases',
};

export default BrandAlias;
