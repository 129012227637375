import {v1_BonusStatus_select_column} from '../../generated/types';

const BonusStatus = {
    ...v1_BonusStatus_select_column,
    res: 'v1_BonusStatus',
    comp: {},
    rel: {},
    label: 'Bonus Status',
};

export default BonusStatus;
