import {Box, Grid} from '@mui/material/';
import type {CellChange, TextCell} from '@silevis/reactgrid';
import {useState} from 'react';
import {Button, Form, useGetList, useNotify} from 'react-admin';
import {useForm} from 'react-hook-form';
import {ROUTES} from '../../../config/statuses';
import {sendPostToApi} from '../../../services/api';
import {BulkCreateFormFields} from './bulkCreateFormFields';
import {applyChangesToOffers, OffersSpreadsheet} from './spreadsheet';
import type {CheckedOffersData, OffersData} from './types';

interface CheckDuplicatesFormProps {
    setDisplaySections: React.Dispatch<
        React.SetStateAction<{
            checking: boolean;
            submit: boolean;
        }>
    >;
    offers: OffersData[];
    setOffers: React.Dispatch<React.SetStateAction<OffersData[]>>;
    setCheckedOffers: React.Dispatch<React.SetStateAction<CheckedOffersData[]>>;
}

type FormInputs = {
    supplier_id?: string;
};

const NO_PUBLISHER_ERROR = 'Publisher field should be filled.';

const isLanguageCellValid = (language: string, languageCodes?: Array<string>) => {
    return languageCodes?.some((code) => {
        return code.includes(',') ? code.split(',').includes(language) : code === language;
    });
};

export function CheckDuplicatesForm({
    setDisplaySections,
    offers,
    setOffers,
    setCheckedOffers,
}: CheckDuplicatesFormProps) {
    const [isAxiosLoading, setIsAxiosLoading] = useState(false);
    const notify = useNotify();
    const {reset} = useForm();

    const {data: languageList} = useGetList(
        'v1_new_languagesBase',
        {
            pagination: {
                page: 1,
                perPage: 10000,
            },
        },
        {}
    );
    const languageCodes = languageList
        ?.filter((entity) => entity['new_2LettersLanguageCode'])
        .map((p) => p['new_2LettersLanguageCode']);

    const validateLanguage = (value: FormInputs) => {
        const errors: FormInputs = {};
        const notValidLanguageRows: number[] = getNotValidLanguageRows();

        if (notValidLanguageRows.length > 0) {
            errors.supplier_id = `Language cells have to be one of languages codes, not valid in following rows: ${notValidLanguageRows.join(', ')}. \n`;
        }
        if (!value['supplier_id']) {
            errors.supplier_id = errors?.supplier_id
                ? `${errors.supplier_id}${NO_PUBLISHER_ERROR}`
                : NO_PUBLISHER_ERROR;
        }

        return errors;
    };

    const getNotValidLanguageRows = () => {
        return offers.reduce((accumulator: number[], offer, idx) => {
            if (offer._domain && !isLanguageCellValid(offer._language.trim().toLowerCase(), languageCodes)) {
                accumulator.push(idx + 1);
            }
            return accumulator;
        }, []);
    };

    async function checkDuplicates(formData: Record<string, number>) {
        const createOfferData = (offer: OffersData, formData: Record<string, number>) => ({
            data: {...offer, ...formData},
        });
        const createOfferWithTmpId = (item: OffersData, idx: number) => ({
            ...item,
            _tmp_id: idx,
        });

        setIsAxiosLoading(true);

        const offersToValidate = getTableData().map((offer) => createOfferData(offer as OffersData, formData));

        try {
            const {success, data} = await sendPostToApi(ROUTES.OFFER_CHECK_DUPLICATES, offersToValidate);
            if (!success) return;

            const offersWithTmpId = data.map(createOfferWithTmpId);

            setDisplaySections({
                checking: false,
                submit: true,
            });
            setCheckedOffers(offersWithTmpId);
            reset();
        } catch (error: any) {
            notify(error.message, {type: 'error'});
        } finally {
            setIsAxiosLoading(false);
        }
    }

    function getTableData() {
        return offers.filter((offer) => offer._domain.length > 0).map((offer) => trimObjFields(offer));
    }

    function trimObjFields(obj: Record<any, any>) {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
                return [key, typeof value === 'string' ? value.trim() : value];
            })
        );
    }

    function handleTableDataChanges(changes: CellChange<TextCell>[]) {
        setOffers((prevOffers) => applyChangesToOffers(changes, prevOffers));
    }

    return (
        <Box>
            <Form onSubmit={checkDuplicates} id="checkDuplicateOffers" validate={validateLanguage}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <BulkCreateFormFields />
                    </Grid>
                </Grid>
                <OffersSpreadsheet offers={offers} handleTableDataChanges={handleTableDataChanges} />
            </Form>
            <Grid sx={{mt: 3}}>
                <Button
                    type="submit"
                    variant="contained"
                    label={isAxiosLoading ? 'Checking' : 'Check Duplicates'}
                    form="checkDuplicateOffers"
                    disabled={isAxiosLoading}
                />
            </Grid>
        </Box>
    );
}
