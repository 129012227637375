import {Box} from '@mui/material/';
import {useState} from 'react';
import {Button, Datagrid, Form, TextField, useGetIdentity, useNotify} from 'react-admin';
import {useForm} from 'react-hook-form';
import {ROUTES} from '../../../config/statuses';
import {sendPostToApi} from '../../../services/api';
import {DuplicatesField} from './DuplicatesField';
import {getOffers} from './spreadsheet';
import type {CheckedOffersData, OffersData} from './types';

interface CheckDuplicatesFormProps {
    setDisplaySections: React.Dispatch<
        React.SetStateAction<{
            checking: boolean;
            submit: boolean;
        }>
    >;
    checkedOffers: CheckedOffersData[];
    setOffers: React.Dispatch<React.SetStateAction<OffersData[]>>;
    setCheckedOffers: React.Dispatch<React.SetStateAction<CheckedOffersData[]>>;
}

export function CreateBulkForm({
    setDisplaySections,
    checkedOffers,
    setOffers,
    setCheckedOffers,
}: CheckDuplicatesFormProps) {
    const [isAxiosLoading, setIsAxiosLoading] = useState(false);
    const notify = useNotify();
    const {data: identity} = useGetIdentity();
    const {getValues, register} = useForm();

    async function createOffers() {
        const formValues = getValues();
        const offersToCreate: CheckedOffersData[] = [];
        const offersToUpdate: CheckedOffersData[] = [];

        checkedOffers.forEach((item) => {
            const tmpId = item._tmp_id;
            const formValue = formValues[`offerTmpId_${tmpId}`];

            if (formValue === 'create') {
                offersToCreate.push(item);
            } else if (formValue) {
                offersToUpdate.push({id: parseInt(formValue), ...item});
            }
        });

        const payload = {
            offersToCreate: offersToCreate,
            offersToUpdate: offersToUpdate,
            userId: identity?.id,
        };

        try {
            setIsAxiosLoading(true);
            const data = await sendPostToApi(ROUTES.OFFER_CREATE_BULK, {
                ...payload,
            });
            notify(data['message'], {
                type: data['success'] ? 'success' : 'error',
            });
            setIsAxiosLoading(false);
            if (data.success) {
                setOffers(getOffers());
                setCheckedOffers([]);
                setDisplaySections({
                    checking: true,
                    submit: false,
                });
            }
        } catch (error: any) {
            notify(error.message, {type: 'error'});
            setIsAxiosLoading(false);
        }
    }

    function back() {
        setDisplaySections({
            checking: true,
            submit: false,
        });
    }

    return (
        <Box>
            <Form id="bulkCreateOffers" onSubmit={createOffers}>
                <Datagrid
                    data={checkedOffers}
                    bulkActionButtons={false}
                    sort={{
                        field: '_domain',
                        order: 'DESC',
                    }}
                    sx={{mb: 3}}
                >
                    <TextField source="name" />
                    <TextField source="_domain" />
                    <TextField source="notes" />
                    <TextField source="_language" />
                    <TextField source="_currency_symbol" />
                    <TextField source="link_price" />
                    <DuplicatesField register={register} />
                </Datagrid>
            </Form>
            <Button onClick={back} variant="contained" label="Back" sx={{mr: 3}} />
            <Button
                type="submit"
                variant="contained"
                label={isAxiosLoading ? 'Saving' : 'Save'}
                form="bulkCreateOffers"
                disabled={isAxiosLoading}
            />
        </Box>
    );
}
