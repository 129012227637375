import React from 'react';
import {Datagrid, DateField, NumberField, ReferenceField} from 'react-admin';
import {TruncatedTooltipField, UserField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';
import {TruncatedLinkField} from '../common/truncatedLinkField';
import {LOCALE, PAGE} from '../../config/statuses';
import Model from '../../models/db/models';
import OPERATOR from '../../models/db/operators';

const RequestedContentFields = () => (
    <Datagrid bulkActionButtons={false} rowClick={PAGE.EDIT}>
        <LinkToNewTab propertyName={Model.Post.id} resource={Model.Post.res} />
        <LinkToRecordField source={Model.Post.id} label="Post ID" redirectToShow={false} />
        <TruncatedLinkField source={Model.Post.new_name} label="Source URL" showPropertyName={true} />
        <DateField source={Model.Post.new_RequestedOn} label="Requested On" locales={LOCALE.EN_GB} />
        <ReferenceField
            source={Model.Post.campaign_id}
            reference={Model.Campaign.res}
            label="Campaign"
            link={PAGE.EDIT}
            sortBy={`${Model.Post.rel.Campaign}.${Model.Campaign.new_name}`}
        >
            <TruncatedTooltipField source={Model.Campaign.new_name} limit={50} />
        </ReferenceField>
        <ReferenceField
            source={Model.Post.campaign_id}
            reference={Model.Campaign.res}
            label="Project"
            link={false}
            sortBy={`${Model.Post.rel.Campaign}.${Model.Campaign.rel.Project}.${Model.Project.new_name}`}
        >
            <ReferenceField source={Model.Campaign.project_id} reference={Model.Project.res} link={PAGE.EDIT}>
                <TruncatedTooltipField source={Model.Project.new_name} limit={50} />
            </ReferenceField>
        </ReferenceField>
        <ReferenceField
            source={Model.Post.website_id}
            reference={Model.Website.res}
            label="Website"
            sortBy={`${Model.Post.rel.Website}.${Model.Website.new_name}`}
            link={PAGE.EDIT}
        >
            <TruncatedTooltipField source={Model.Website.new_name} limit={50} />
        </ReferenceField>
        <UserField
            source={Model.Post.requestedby_id}
            label="Request Sent By"
            sortBy={`${Model.Post.rel.RequestedBy}.${Model.Post.new_name}`}
        />
        <UserField
            source={Model.Post.approver_id}
            label="To Be Approved By"
            sortBy={`${Model.Post.rel.Approver}.${Model.User.name}`}
        />
        <TruncatedTooltipField source={Model.Post.requestDetails} label="Request Details" limit={20} />
        <NumberField source={Model.Post.new_RequestedMinWords} label="Words Requested" />
    </Datagrid>
);
export const requestedContentSearchFields =
    `${Model.Post.requestDetails},` +
    `${Model.Post.rel.Campaign}#${Model.Campaign.new_name}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.Campaign}#${Model.Campaign.rel.Project}#${Model.Project.new_name}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.Approver}#${Model.User.name}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.RequestedFrom}#${Model.User.name}${OPERATOR.ILIKE},` +
    `${Model.Post.rel.Website}#${Model.Website.new_name}${OPERATOR.ILIKE}`;
export default RequestedContentFields;
