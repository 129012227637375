import {Datagrid, DateField, RaRecord, ReferenceField, TextField} from 'react-admin';
import CurrencyAmountWrapper from '../common/currencyAmountWrapper';
import {TruncatedTooltipField, UserField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';

const ContentWritingFields = () => {
    return (
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <LinkToNewTab propertyName="id" resource="v1_Posts" />
            <LinkToRecordField source="id" label="Post ID" redirectToShow={false} />
            <DateField source="CreatedOn" label="Created On" locales="en-GB" />
            <DateField source="ordered_on" label="Ordered On" locales="en-GB" />
            <DateField source="new_RequestedOn" label="Requested On" locales="en-GB" />
            <DateField source="new_SenttoApproval" label="Sent to Approval On" locales="en-GB" />
            <TruncatedTooltipField limit={80} source="new_Title" label="H1 Title" />
            <ReferenceField
                label="Campaign"
                source="campaign_id"
                reference="v1_Campaign"
                link="edit"
                sortBy="Campaign.new_name"
            >
                <TruncatedTooltipField limit={50} source="new_name" />
            </ReferenceField>
            <ReferenceField
                label="Project"
                source="campaign_id"
                reference="v1_Campaign"
                link={false}
                sortBy="Campaign.Project.new_name"
            >
                <ReferenceField reference="v1_Project" source="project_id" link="edit">
                    <TruncatedTooltipField limit={50} source="new_name" />
                </ReferenceField>
                <ReferenceField source="website_id" link="edit" label="Website" reference="v1_Website">
                    <TruncatedTooltipField limit={50} source="new_name" />
                </ReferenceField>
            </ReferenceField>
            <UserField label="Request Sent By" source="requestedby_id" sortBy="Requested_By.name" />
            <UserField label="To Be Approved By" source="approver_id" sortBy="Approver.name" />
            <UserField label="Request Receiver" source="requestedfrom_id" sortBy="RequestedFrom.name" />
            <ReferenceField
                source="supplier_id"
                label="Publisher"
                reference="v1_Suppliers"
                link={(record: RaRecord) => `/${'v1_Suppliers'}/${record.id}`}
                sortBy="Supplier.FullName"
            >
                <TextField source="FullName" />
            </ReferenceField>
            <ReferenceField
                label="Content Status"
                source="new_ContentOrderStatus"
                reference="v1_PostsContentStatus"
                link={false}
            >
                <TextField source="value" />
            </ReferenceField>
            <CurrencyAmountWrapper
                label="Publisher Price"
                sourceCurrency="supplier_currency_id"
                sourceAmount="new_Supplierprice"
                sortBy="new_Supplierprice"
            />
        </Datagrid>
    );
};

export const contentWritingSearchFields =
    'new_Title,' +
    'new_PostID,' +
    'Supplier#FullName@_ilike,' +
    'Website#new_name@_ilike,' +
    'Campaign#new_name@_ilike,' +
    'Campaign#Project#new_name@_ilike,' +
    'Approver#name@_ilike,' +
    'Ordered_By#name@_ilike,' +
    'ContentStatus#value@_ilike';

export default ContentWritingFields;
