import {v1_LandingPages_select_column} from '../../generated/types';

const LandingPage = {
    ...v1_LandingPages_select_column,
    res: 'v1_LandingPages',
    comp: {},
    rel: {},
    label: 'Landing Pages',
    entity: 'Landing Page',
};

export default LandingPage;
