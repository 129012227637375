import {v1_Users_Teams_select_column} from '../../generated/types';

const UsersToTeams = {
    ...v1_Users_Teams_select_column,
    res: 'v1_Users_Teams',
    comp: {},
    rel: {},
};

export default UsersToTeams;
