import {v1_PostsStatus_select_column} from '../../generated/types';

const PostStatus = {
    ...v1_PostsStatus_select_column,
    res: 'v1_PostsStatus',
    comp: {},
    rel: {},
};

export default PostStatus;
