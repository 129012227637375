import {v1_Months_select_column} from '../../generated/types';

const Month = {
    ...v1_Months_select_column,
    res: 'v1_Months',
    comp: {},
    rel: {},
    label: 'Months',
    entity: 'Month',
};

export default Month;
