import {Button, Dialog, DialogActions, DialogContent, TextField} from '@mui/material';
import React, {useState} from 'react';
import {
    AutocompleteInput,
    Loading,
    ReferenceInput,
    required,
    SimpleForm,
    useCreate,
    useCreateSuggestionContext,
    useGetIdentity,
} from 'react-admin';
import {v1_AccountBase} from '../../generated/types';
import {parseValue} from '../../utility/parseValue';

type PayloadForCreate = {
    Name?: string;
    new_Accounttype?: number;
    company_contact_name?: string;
    new_companyaddress?: string;
    country_id?: number;
    new_companytype?: boolean;
    new_Description?: string;
    owner_id?: number;
    EMailAddress1?: string;
    Telephone1?: string;
    new_Passportid?: string;
    Description?: string;
};

type TypeOfCompany = {
    newCompanyName: string;
};

export const CreateCompanyFromDropdown = ({newCompanyName}: TypeOfCompany) => {
    const {onCancel, onCreate} = useCreateSuggestionContext();
    const [create] = useCreate<v1_AccountBase>();
    const {data: identity, isLoading: identityIsLoading} = useGetIdentity();
    const [payload, setPayload] = useState<PayloadForCreate>({
        Name: newCompanyName,
    });

    if (identityIsLoading) return <Loading />;

    const handleSubmit = () => {
        create(
            'v1_AccountBase',
            {
                data: {
                    ...payload,
                    createdby_id: Number(identity?.id),
                    CreatedOn: new Date(),
                },
            },
            {
                onSuccess: (data) => {
                    setPayload({});
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <SimpleForm onSubmit={handleSubmit} toolbar={false}>
                <DialogContent>
                    <TextField
                        label="Company"
                        value={payload?.Name}
                        required={true}
                        onChange={(event) =>
                            setPayload({
                                ...payload,
                                Name: event.target.value,
                            })
                        }
                        fullWidth
                        variant="outlined"
                        name="Name"
                    />
                    <ReferenceInput
                        source="new_Accounttype"
                        reference="v1_CompanyType"
                        sort={{
                            field: 'value',
                            order: 'ASC',
                        }}
                        name="new_Accounttype"
                    >
                        <AutocompleteInput
                            optionText="value"
                            label="Company Type"
                            variant="outlined"
                            defaultValue={null}
                            filterToQuery={(searchText) => ({
                                value: searchText,
                            })}
                            parse={parseValue}
                            validate={required()}
                            helperText={false}
                            onChange={(event) =>
                                setPayload({
                                    ...payload,
                                    new_Accounttype: event,
                                })
                            }
                        />
                    </ReferenceInput>
                    <TextField
                        label="Payment Address"
                        multiline
                        rows={3}
                        required={true}
                        onChange={(event) =>
                            setPayload({
                                ...payload,
                                new_companyaddress: event.target.value,
                            })
                        }
                        fullWidth
                        variant="outlined"
                        name="new_companyaddress"
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </SimpleForm>
        </Dialog>
    );
};
