import RefreshIcon from '@mui/icons-material/Refresh';
import {Grid, IconButton} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {AutocompleteInput, ReferenceInput, required, SORT_ASC, useGetOne, useNotify, useRefresh} from 'react-admin';
import {useWatch} from 'react-hook-form';
import {
    COL,
    EMPTY_FILTER_ID,
    PURCHASE_ORDER_STATUS,
    RES,
    ROUTES,
    SERVICE_TYPE,
    TYPE_OF_CONTRACTOR,
} from '../../config/statuses';
import {
    CallbackProps,
    DisplayProps,
    IReferenceInputProps,
    ReferenceProps,
    ValidationProps,
} from '../../models/input-field.models';
import {sendPostToApi} from '../../services/api';
import {parseValue} from '../../utility/parseValue';
import {autocompleteInputReadOnlyProps} from '../../utility/readOnly';
import CreateOfferFromDropdown from '../offer/createOfferFromDropdown';
import CreatePublisherFromDropdown from '../supplier/createPublisherFormDropdown/createPublisherFromDropdown';
import {CreateCompanyFromDropdown} from './createCompanyFromDropdown';
import {CreatePOFromDropdown, POFromDropdownContractor} from './createPOFromDropdown/createPOFromDropdown';
import CreateWebsiteFromDropdown from './createWebsiteFromDropdown';
import {ReferenceInputWithLink} from './referenceInputWithLink';
import Model from '../../models/db/models';

export const UserInput = ({
    source,
    label,
    isRequired,
    disabled,
    placeholder,
    defaultValue,
    variant,
    helperText,
    fullWidth,
    readOnly,
}: ReferenceProps & ValidationProps & DisplayProps) => {
    return (
        <ReferenceInput
            source={source}
            reference={RES.USER}
            filter={{'Status#name': 'Active'}}
            sort={{
                field: 'name',
                order: 'ASC',
            }}
        >
            <AutocompleteInput
                label={label}
                disabled={disabled}
                optionText="name"
                emptyText={placeholder}
                defaultValue={defaultValue}
                filterToQuery={(searchText) => ({name: searchText})}
                parse={parseValue}
                validate={isRequired ? required() : undefined}
                variant={variant}
                helperText={helperText ?? false}
                fullWidth={fullWidth ?? false}
                {...(readOnly ? autocompleteInputReadOnlyProps : {})}
            />
        </ReferenceInput>
    );
};

export const WriterInput = ({
    isRequired,
    variant,
    disabled,
    setStateIdCallback,
    hideLink,
    defaultValue,
    helperText,
    onBlur,
}: ValidationProps &
    DisplayProps &
    CallbackProps &
    IReferenceInputProps & {
        onBlur?: () => void;
    }) => {
    return (
        <ReferenceInputWithLink
            source="writer_id"
            reference={RES.WRITER}
            sort={{
                field: 'FullName',
                order: 'ASC',
            }}
            hideLink={hideLink}
        >
            <AutocompleteInput
                onBlur={onBlur}
                label="Writer"
                optionText="FullName"
                defaultValue={defaultValue || null}
                parse={parseValue}
                filterToQuery={(searchText) => ({FullName: searchText})}
                validate={isRequired ? required() : undefined}
                variant={variant}
                disabled={disabled ?? undefined}
                onChange={setStateIdCallback ? (event) => setStateIdCallback(event) : undefined}
                helperText={helperText ?? false}
                fullWidth
            />
        </ReferenceInputWithLink>
    );
};

export const AdvertiserInput = ({
    isRequired,
    disabled,
    setStateIdCallback,
    hideLink,
    defaultValue,
    helperText,
}: ValidationProps & DisplayProps & CallbackProps & IReferenceInputProps) => {
    return (
        <ReferenceInputWithLink
            source="AdvertiserId"
            reference={RES.ADVERTISER}
            sort={{
                field: 'FullName',
                order: 'ASC',
            }}
            hideLink={hideLink}
        >
            <AutocompleteInput
                label="Advertiser"
                optionText="FullName"
                defaultValue={defaultValue || null}
                parse={parseValue}
                filterToQuery={(searchText) => ({FullName: searchText})}
                validate={isRequired ? required() : undefined}
                variant="outlined"
                disabled={disabled ?? undefined}
                onChange={setStateIdCallback ? (event) => setStateIdCallback(event) : undefined}
                helperText={helperText ?? false}
                fullWidth
            />
        </ReferenceInputWithLink>
    );
};

export const SupplierInput = ({
    disabled,
    isRequired,
    label = 'Publisher',
    variant,
    setStateIdCallback,
    hideLink,
    helperText,
    defaultValue,
    acceptCreate = false,
}: ValidationProps & DisplayProps & CallbackProps & IReferenceInputProps) => {
    return (
        <ReferenceInputWithLink
            source="supplier_id"
            reference={RES.PUBLISHER}
            filter={{StateCode: 0}}
            sort={{
                field: 'FullName',
                order: 'ASC',
            }}
            disableCache
            hideLink={hideLink}
        >
            <AutocompleteInput
                create={acceptCreate ? <CreatePublisherFromDropdown /> : undefined}
                label={label}
                disabled={disabled}
                optionText="FullName"
                defaultValue={defaultValue || null}
                validate={isRequired ? required() : undefined}
                parse={parseValue}
                filterToQuery={(searchText) => ({FullName: searchText})}
                variant={variant}
                onChange={setStateIdCallback ? (event) => setStateIdCallback(event) : undefined}
                helperText={helperText}
            />
        </ReferenceInputWithLink>
    );
};

export const ContactInput = ({disabled, isRequired}: ValidationProps) => {
    return (
        <SupplierInput
            disabled={disabled}
            isRequired={isRequired}
            label="Publisher"
            variant="outlined"
            fullWidth
            helperText={false}
        />
    );
};

export const LanguageInput = ({
    source,
    label,
    isRequired,
    variant,
    defaultValue,
    helperText = false,
}: ReferenceProps & ValidationProps & DisplayProps) => {
    return (
        <ReferenceInput
            source={source}
            reference={RES.LANGUAGE}
            sort={{
                field: 'new_name',
                order: 'ASC',
            }}
        >
            <AutocompleteInput
                label={label}
                optionText="new_name"
                parse={parseValue}
                filterToQuery={(searchText) => ({new_name: searchText})}
                validate={isRequired ? required() : undefined}
                variant={variant}
                fullWidth
                helperText={helperText}
                defaultValue={defaultValue}
            />
        </ReferenceInput>
    );
};

export const ProjectInput = ({isRequired}: ValidationProps) => {
    return (
        <ReferenceInputWithLink
            source="project_id"
            reference={RES.PROJECT}
            filter={{statecode: 0}}
            sort={{
                field: 'new_name',
                order: 'ASC',
            }}
        >
            <AutocompleteInput
                label="Project"
                optionText="new_name"
                parse={parseValue}
                filterToQuery={(searchText) => ({new_name: searchText})}
                validate={isRequired ? required() : undefined}
                variant="outlined"
                helperText={false}
            />
        </ReferenceInputWithLink>
    );
};

export const ContentTypeInput = (
    props: DisplayProps &
        ValidationProps & {
            showRefresh?: boolean;
        }
) => {
    const {defaultValue, label = 'Content type', helperText, variant, isRequired} = props;
    const notify = useNotify();
    const websiteIdForm = useWatch({name: Model.Post.website_id});
    const projectId = useWatch({name: Model.Campaign.project_id});
    const {data: projectData} = useGetOne(RES.PROJECT, {id: projectId}, {enabled: !!projectId});

    const websiteId = websiteIdForm || (projectId ? projectData?.website_id : undefined);
    const websiteFilter = {website_id: websiteId || EMPTY_FILTER_ID};
    const [loaded, setLoaded] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const refresh = useRefresh();

    useEffect(() => {
        setLoaded(true);
    }, []);

    const handleClick = async () => {
        setIsRefreshing(true);
        await handleOnServer({
            website_id: websiteId,
        });
    };

    const handleOnServer = async (payload: {website_id: number}) => {
        try {
            const data = await sendPostToApi(ROUTES.CONTENT_TYPE_REFRESH, {
                ...payload,
            });
            const isSuccessResponse = data['success'];
            if (isSuccessResponse) {
                refresh();
            }
            notify(`adzz.refreshContentTypes.${isSuccessResponse ? 'success' : 'error'}`, {
                type: isSuccessResponse ? 'success' : 'error',
                messageArgs: {errorMessage: data['message']},
            });
        } catch (error: any) {
            notify(error.message, {type: 'error'});
        }
        setIsRefreshing(false);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <ReferenceInput
                    source={Model.Post.contenttype_id}
                    reference={Model.ContentType.res}
                    filter={websiteFilter}
                    sort={{
                        field: Model.ContentType.new_name,
                        order: SORT_ASC,
                    }}
                >
                    <AutocompleteInput
                        defaultValue={loaded ? defaultValue ?? undefined : undefined}
                        filterToQuery={(searchText) => ({
                            new_name: searchText,
                        })}
                        label={label}
                        optionText={Model.ContentType.new_name}
                        parse={parseValue}
                        variant={variant}
                        helperText={helperText}
                        isRequired={!!isRequired}
                        validate={isRequired ? required() : undefined}
                        {...(!websiteId && autocompleteInputReadOnlyProps)}
                    />
                </ReferenceInput>
            </Grid>
            {props.showRefresh && (
                <Grid item xs="auto">
                    <IconButton
                        aria-label="Refresh"
                        onClick={handleClick}
                        disabled={!websiteId || isRefreshing}
                        sx={{mt: 1}}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};

export const WebsiteInput = (props: ValidationProps & DisplayProps & IReferenceInputProps) => {
    const {isRequired, helperText, defaultValue, variant, hideLink, label} = props;

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <ReferenceInputWithLink
            name="website_id"
            perPage={10}
            reference={RES.WEBSITE}
            sort={{
                field: 'new_name',
                order: 'ASC',
            }}
            source="website_id"
            hideLink={hideLink}
            urlLinkIcon={props.urlLinkIcon}
        >
            <AutocompleteInput
                create={<CreateWebsiteFromDropdown />}
                defaultValue={loaded ? defaultValue ?? undefined : undefined}
                filterToQuery={(searchText) => ({new_name: searchText})}
                label={label || 'Website'}
                name="website_id"
                optionText="new_name"
                parse={parseValue}
                validate={isRequired ? required() : undefined}
                variant={variant}
                helperText={helperText ?? false}
                fullWidth
            />
        </ReferenceInputWithLink>
    );
};

export const AffiliateOrderInput = ({isRequired, disabled}: ValidationProps) => {
    return (
        <ReferenceInputWithLink
            source="order_id"
            filter={{StateCode: 0}}
            reference={RES.AFFILIATE_ORDER}
            sort={{
                field: 'Name',
                order: 'ASC',
            }}
        >
            <AutocompleteInput
                optionText="Name"
                label="Affiliate Order"
                disabled={disabled}
                defaultValue={null}
                parse={parseValue}
                filterToQuery={(searchText) => ({Name: searchText})}
                validate={isRequired ? required() : undefined}
                variant="outlined"
                fullWidth
                helperText={false}
            />
        </ReferenceInputWithLink>
    );
};

export const CurrencyInput = ({
    isRequired,
    requiredErrorMessage,
    variant,
    label,
    source,
    helperText,
    defaultValue,
    readOnly,
}: {
    requiredErrorMessage?: string;
} & ValidationProps &
    DisplayProps) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <ReferenceInput source={source ? source : 'currency_id'} reference={RES.CURRENCY}>
            <AutocompleteInput
                optionText="CurrencySymbol"
                defaultValue={loaded ? defaultValue ?? undefined : undefined}
                label={label ? label : 'Currency'}
                filterToQuery={(searchText) => ({CurrencySymbol: searchText})}
                parse={parseValue}
                validate={isRequired ? required(requiredErrorMessage ? requiredErrorMessage : undefined) : undefined}
                variant={variant}
                helperText={helperText ?? false}
                fullWidth
                {...(readOnly ? autocompleteInputReadOnlyProps : {})}
            />
        </ReferenceInput>
    );
};

export const PaymentMethodInput = ({isRequired, disabled}: ValidationProps) => {
    return (
        <ReferenceInputWithLink
            source="paymentmethod_id"
            reference={RES.PAYMENT_METHOD}
            filter={{statecode: 0}}
            sort={{
                field: 'new_name',
                order: 'ASC',
            }}
        >
            <AutocompleteInput
                optionText="new_name"
                label="Payment Method"
                disabled={disabled}
                defaultValue={null}
                filterToQuery={(searchText) => ({new_name: searchText})}
                parse={parseValue}
                validate={isRequired ? required() : undefined}
                variant="outlined"
                fullWidth
                helperText={false}
            />
        </ReferenceInputWithLink>
    );
};

export const CustomerInput = ({isRequired, disabled, acceptCreate}: ValidationProps & IReferenceInputProps) => {
    const [newCompanyName, setNewCompanyName] = useState('');

    return (
        <ReferenceInputWithLink
            source="company_id"
            reference={RES.COMPANY}
            filter={{StateCode: 0}}
            sort={{
                field: 'Name',
                order: 'ASC',
            }}
        >
            <AutocompleteInput
                create={acceptCreate ? <CreateCompanyFromDropdown newCompanyName={newCompanyName} /> : undefined}
                optionText="Name"
                label="Company"
                disabled={disabled}
                defaultValue={null}
                filterToQuery={(searchText) => {
                    setNewCompanyName(searchText);
                    return {Name: searchText};
                }}
                parse={parseValue}
                validate={isRequired ? required() : undefined}
                variant="outlined"
                fullWidth
                helperText={false}
            />
        </ReferenceInputWithLink>
    );
};

export const BrandInput = ({isRequired, variant, helperText}: ValidationProps & DisplayProps) => {
    return (
        <ReferenceInputWithLink
            source="brand_id"
            reference={RES.BRAND}
            filter={{statecode: 0}}
            sort={{
                field: 'new_name',
                order: 'ASC',
            }}
        >
            <AutocompleteInput
                optionText="new_name"
                label="Brand"
                filterToQuery={(searchText) => ({new_name: searchText})}
                validate={isRequired ? required() : undefined}
                variant={variant}
                helperText={helperText}
            />
        </ReferenceInputWithLink>
    );
};

export const OfferInput = ({
    isRequired,
    setStateIdCallback,
    helperText,
    source = 'OfferId',
    acceptCreate = false,
    defaultValue,
}: ValidationProps & DisplayProps & CallbackProps & IReferenceInputProps) => {
    const websiteId = useWatch({name: 'website_id'});

    return (
        <ReferenceInputWithLink
            source={source}
            reference={RES.OFFER}
            filter={{
                website_id: websiteId || EMPTY_FILTER_ID,
            }}
            sort={{
                field: 'id',
                order: 'ASC',
            }}
        >
            <AutocompleteInput
                create={acceptCreate ? <CreateOfferFromDropdown /> : undefined}
                label="Offer"
                name={source}
                defaultValue={defaultValue}
                optionText="name"
                variant="outlined"
                validate={isRequired ? required() : undefined}
                disabled={!websiteId}
                onChange={setStateIdCallback ? (event) => setStateIdCallback(event) : undefined}
                helperText={helperText}
            />
        </ReferenceInputWithLink>
    );
};

export const PurchaseOrderNameInput = ({
    contractor,
    disabled,
}: {
    contractor: POFromDropdownContractor;
    disabled: boolean;
}) => {
    const {type} = contractor;

    const [newPOName, setNewPOName] = useState('');

    const filterWriterId = useWatch({
        name: COL[RES.POST].writer_id,
        defaultValue: undefined,
    });

    const filterSupplierId = useWatch({
        name: COL[RES.POST].supplier_id,
        defaultValue: undefined,
    });

    const filterParams = useMemo(() => {
        const commonFields = {
            [COL[RES.PURCHASE_ORDER].statuscode]: [
                PURCHASE_ORDER_STATUS.APPROVAL_REQUIRED,
                PURCHASE_ORDER_STATUS.DECLINED,
            ],
            [COL[RES.PURCHASE_ORDER].new_PaymentType]: [SERVICE_TYPE.CONTENT, SERVICE_TYPE.POSTS_DEAL],
        };

        if (type === TYPE_OF_CONTRACTOR.WRITER) {
            return {
                ...commonFields,
                [COL[RES.PURCHASE_ORDER].writer_id]: filterWriterId ?? undefined,
            };
        }

        if (type === TYPE_OF_CONTRACTOR.SUPPLIER) {
            return {
                ...commonFields,
                [COL[RES.PURCHASE_ORDER].supplier_id]: filterSupplierId ?? undefined,
            };
        }

        return commonFields;
    }, [type, filterWriterId, filterSupplierId]);

    const getSourceAndLabel = () => ({
        source: contractor.type === TYPE_OF_CONTRACTOR.WRITER ? COL[RES.POST].po_id : COL[RES.POST].supplier_po_id,
        label: contractor.type === TYPE_OF_CONTRACTOR.WRITER ? 'Writer PO' : 'Publisher PO',
    });

    return (
        <ReferenceInputWithLink
            source={getSourceAndLabel().source}
            reference={RES.PURCHASE_ORDER}
            sort={{
                field: COL[RES.PURCHASE_ORDER].new_name,
                order: SORT_ASC,
            }}
            filter={filterParams}
        >
            <AutocompleteInput
                create={<CreatePOFromDropdown contractor={contractor} newPOName={newPOName} />}
                optionText={COL[RES.PURCHASE_ORDER].new_name}
                label={getSourceAndLabel().label}
                filterToQuery={(searchText) => {
                    setNewPOName(searchText);
                    return {new_name: searchText};
                }}
                variant="outlined"
                disabled={disabled}
                helperText={false}
            />
        </ReferenceInputWithLink>
    );
};
