import {CreateDialog} from '@react-admin/ra-form-layout';
import React from 'react';
import {
    Loading,
    SimpleForm,
    useCreate,
    useCreateSuggestionContext,
    useGetIdentity,
    useNotify,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import {PURCHASE_ORDER_STATUS, RES, TYPE_OF_CONTRACTOR} from '../../../config/statuses';
import {v1_new_POBase, v1_Posts} from '../../../generated/types';
import {CreatePOFromDropdownFields} from './createPOFromDropdownFields';

export type POFromDropdownContractor = {
    type: (typeof TYPE_OF_CONTRACTOR)[keyof typeof TYPE_OF_CONTRACTOR];
    id: number;
};

type CreatePOFromDropdownProps = {
    contractor: POFromDropdownContractor;
    newPOName: string;
};
export const CreatePOFromDropdown = ({contractor, newPOName}: CreatePOFromDropdownProps) => {
    const {onCancel, onCreate} = useCreateSuggestionContext();
    const [create] = useCreate<v1_new_POBase>();
    const refresh = useRefresh();
    const {data: identity, isLoading: identityIsLoading} = useGetIdentity();
    const record = useRecordContext<v1_Posts>();
    const notify = useNotify();

    if (identityIsLoading) return <Loading />;

    const contractorDataMap = {
        [TYPE_OF_CONTRACTOR.WRITER]: {
            type_of_contractor: TYPE_OF_CONTRACTOR.WRITER,
            currency_id: record.currency_id,
            new_Sum: record.comp_total_price_writer,
            new_total: record.comp_total_price_writer,
        },
        [TYPE_OF_CONTRACTOR.SUPPLIER]: {
            type_of_contractor: TYPE_OF_CONTRACTOR.SUPPLIER,
            currency_id: record.supplier_currency_id,
            new_Sum: record.new_Supplierprice,
            new_total: record.new_Supplierprice,
        },
    };

    const save = (data: any) => {
        const contractorData = contractorDataMap[contractor.type];
        create(
            RES.PURCHASE_ORDER,
            {
                data: {
                    createdby_id: Number(identity?.id),
                    statuscode: PURCHASE_ORDER_STATUS.APPROVAL_REQUIRED,
                    ...contractorData,
                    ...data,
                },
            },
            {
                onSuccess: (data) => {
                    notify('PO created successfully', {type: 'success'});
                    onCreate(data);
                    refresh();
                },
                onError: () => {
                    notify('An error occurred creating PO', {type: 'error'});
                },
            }
        );
    };

    return (
        <CreateDialog
            isOpen
            close={onCancel}
            resource={RES.PURCHASE_ORDER}
            title={`Create ${contractor.type === TYPE_OF_CONTRACTOR.SUPPLIER ? 'publisher' : 'writer'} PO`}
        >
            <SimpleForm onSubmit={save}>
                <CreatePOFromDropdownFields newPOName={newPOName} contractor={contractor} />
            </SimpleForm>
        </CreateDialog>
    );
};
