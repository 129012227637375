import {v1_new_POBase_select_column} from '../../generated/types';

const PurchaseOrder = {
    ...v1_new_POBase_select_column,
    res: 'v1_new_POBase',
    comp: {
        publisherTotal: 'comp_publisher_total',
        publisherAmount: 'comp_total_publisher_amount',
        writerTotal: 'comp_writer_total',
        writerAmount: 'comp_total_writer_amount',
    },
    rel: {
        Post: 'Posts',
        SupplierPost: 'SupplierPosts',
    },
    label: 'Purchase Orders',
    entity: 'purchase order',
};

export default PurchaseOrder;
