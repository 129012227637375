import {v1_Credential_Roles_select_column} from '../../generated/types';

const CredentialsToRoles = {
    ...v1_Credential_Roles_select_column,
    res: 'v1_Credential_Roles',
    comp: {},
    rel: {},
    label: 'Credential Roles',
};

export default CredentialsToRoles;
