import {v1_CampaignStrategy_select_column} from '../../generated/types';

const CampaignStrategy = {
    ...v1_CampaignStrategy_select_column,
    res: 'v1_CampaignStrategy',
    comp: {},
    rel: {
        CampaignType: 'CampaignType',
    },
    label:'Campaign Strategies'
};

export default CampaignStrategy;
