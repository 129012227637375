import {v1_BonusTypes_select_column} from '../../generated/types';

const BonusType = {
    ...v1_BonusTypes_select_column,
    res: 'v1_BonusTypes',
    comp: {},
    rel: {},
    label: 'Bonus Type',
};

export default BonusType;
