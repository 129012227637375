import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {Button, Dialog, IconButton, Typography} from '@mui/material';
import React, {useState} from 'react';
import {ImportCsvDialogContent} from './importCsvDialogContent';
import {CsvImportResult, ImportCsvProps} from './types';

export const ImportCsv = ({
    children,
    Summary,
    title = 'Import CSV',
    buttonLabel = 'Import CSV',
    apiRoute,
}: ImportCsvProps) => {
    const [isImportDialogOpen, setImportDialogOpen] = useState(false);
    const [result, setResult] = useState<CsvImportResult | null>(null);
    const [isInProcess, setIsInProcess] = useState(false);
    const getIsInProcess = (isInProcess: boolean) => {
        setIsInProcess(isInProcess);
    };
    const resetResult = () => setResult(null);
    const toggleDialog = () => {
        setImportDialogOpen(!isImportDialogOpen);
        resetResult();
    };
    const closeDialog = () => {
        setImportDialogOpen(false);
        resetResult();
    };

    return (
        <>
            <Button
                sx={{
                    lineHeight: 1.5,
                    padding: '4px 5px',
                    boxShadow: 2,
                    gap: 0.5,
                }}
                onClick={toggleDialog}
            >
                <ImportExportIcon />
                <Typography sx={{fontSize: 13}}>{title}</Typography>
            </Button>
            <Dialog
                open={isImportDialogOpen}
                maxWidth="sm"
                fullWidth
                onClose={(_event, reason) => {
                    if (reason && reason === 'backdropClick') return;
                    toggleDialog();
                }}
            >
                {!result && (
                    <ImportCsvDialogContent
                        buttonLabel={buttonLabel}
                        setResult={setResult}
                        getIsInProcess={getIsInProcess}
                        title={title}
                        apiRoute={apiRoute}
                    >
                        {children}
                    </ImportCsvDialogContent>
                )}
                {result &&
                    Summary &&
                    Summary({
                        result,
                        setResult,
                        toggleDialog,
                    })}
                <IconButton
                    disabled={isInProcess}
                    sx={{
                        p: 0.2,
                        position: 'absolute',
                        top: 2,
                        right: 2,
                    }}
                    onClick={closeDialog}
                >
                    <CloseIcon />
                </IconButton>
            </Dialog>
        </>
    );
};
