import {v1_new_receiptBase_select_column} from '../../generated/types';

const Receipt = {
    ...v1_new_receiptBase_select_column,
    res: 'v1_new_receiptBase',
    comp: {},
    rel: {},
    label: 'Receipts',
    entity: 'Receipt',
};

export default Receipt;
