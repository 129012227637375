import {Box, Divider, Tooltip, Typography} from '@mui/material';
import {Loading, useGetList} from 'react-admin';
import {RES} from '../../../config/statuses';
import {v1_Website} from '../../../generated/types';
import {THEME_COLORS} from '../../../layout/theme';

export const DefaultValuesNotification = () => {
    const {data: notGamblingWebsites, isLoading: isNonGamblingWebsites} = useGetList<v1_Website>(RES.WEBSITE, {
        filter: {
            is_gambling: false,
        },
    });
    if (isNonGamblingWebsites) {
        return <Loading />;
    }
    return (
        <Box
            sx={{
                boxShadow: 2,
                p: 1,
                bgcolor: THEME_COLORS.LIGHT_BLUE,
                color: THEME_COLORS.WHITE,
                borderRadius: 1,
                height: 'max-content',
                width: '100%',
            }}
        >
            <Typography sx={{fontSize: 18}}>Default values</Typography>
            <Divider />
            <Typography
                sx={{
                    pt: 1,
                    fontSize: 13,
                    whiteSpace: 'pre',
                }}
            >
                {'Do follow = true\nIs homepage link = true\n'}
            </Typography>
            <Typography sx={{fontSize: 13}}>Restricted niches = gambling except for the following websites</Typography>
            <Tooltip
                sx={{
                    fontSize: 13,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}
                title={
                    <Box>
                        {notGamblingWebsites?.map((site) => <Typography key={site.id}>{site.new_name}</Typography>)}
                    </Box>
                }
            >
                <Typography>list</Typography>
            </Tooltip>
        </Box>
    );
};
