import {v1_Project_select_column} from '../../generated/types';

const Project = {
    ...v1_Project_select_column,
    res: 'v1_Project',
    comp: {
        SocialMonthlyCompletion: 'comp_social_monthly_completion',
        OnsiteMonthlyCompletion: 'comp_onsite_monthly_completion',
        LinkBuildingMonthlyCompletion: 'comp_linkbuilding_monthly_completion',
        LinkBuildingTarget: 'link_building_target',
        SocialTarget: 'social_target',
        OnsiteTarget: 'onsite_target',
    },
    rel: {},
    label: 'Projects',
    entity: 'Project',
};

export default Project;
