import {v1_Credentials_select_column} from '../../generated/types';

const Credential = {
    ...v1_Credentials_select_column,
    res: 'v1_Credentials',
    comp: {},
    rel: {},
    label: 'Credentials',
    entity: 'Credential',
};

export default Credential;
