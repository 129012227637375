import {v1_SalesOrderBase_select_column} from '../../generated/types';

const AffiliateOrder = {
    ...v1_SalesOrderBase_select_column,
    res: 'v1_SalesOrderBase',
    comp: {
        PaidInvoices: 'PaidInvoices',
        NotPaidInvoices: 'NotPaidInvoices',
        NotPaidAmount: 'NotPaidAmount',
        PaidAmount: 'paid_amount',
        TrackerClicksLast30Days: 'tracker_clicks_last_30_days',
        HasOpenInvoices: 'hasOpenInvoices',
    },
    rel: {
        Company: 'CompanyNew',
        Tracker: 'Trackers',
        AffiliateOrderType: 'AffiliateProjectNew',
        AffiliateDealType: 'AffDealType',
    },
    label: 'Affiliate Orders',
    entity: 'Order',
    listParams: 'v1_SalesOrderBase.listParams',
};

export default AffiliateOrder;
