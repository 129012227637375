import {Button, Dialog, DialogActions, DialogContent, TextField} from '@mui/material';
import {useEffect, useState} from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    required,
    SimpleForm,
    useCreate,
    useCreateSuggestionContext,
} from 'react-admin';
import {useWatch} from 'react-hook-form';
import {v1_Offer} from '../../generated/types';
import Models from '../../models/db/models';
import {sendPostToApi} from '../../services/api';
import {updateOfferRestrictedNiche} from '../../utility/helpers';
import {parseValue} from '../../utility/parseValue';

type PayloadForCreate = {
    name?: string;
    website_id?: number;
    supplier_id?: number;
};

const CreateOfferFromDropdown = () => {
    const {filter, onCancel, onCreate} = useCreateSuggestionContext();
    const [payload, setPayload] = useState<PayloadForCreate>({name: filter});
    const [create] = useCreate<v1_Offer>();

    const websiteId = useWatch({name: 'website_id'});
    const publisherId = useWatch({name: 'supplier_id'});

    useEffect(() => {
        setPayload({
            ...payload,
            website_id: websiteId,
            supplier_id: publisherId,
        });
    }, []);

    const handleSubmit = () => {
        create(
            Models.Offer.res,
            {
                data: payload,
            },
            {
                onSuccess: async (data) => {
                    await sendPostToApi(updateOfferRestrictedNiche({id: data.id}), {websiteId});
                    setPayload({});
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <SimpleForm onSubmit={handleSubmit} toolbar={false}>
                <DialogContent sx={{minWidth: '50vh'}}>
                    <TextField
                        label="Offer Name"
                        value={payload?.name}
                        onChange={(event) =>
                            setPayload({
                                ...payload,
                                name: event.target.value,
                            })
                        }
                        fullWidth
                        required={true}
                        variant="outlined"
                        name="name"
                    />
                    <ReferenceInput source="website_id" reference="v1_Website">
                        <AutocompleteInput
                            optionText="new_name"
                            defaultValue={websiteId}
                            label={'Website'}
                            filterToQuery={(searchText) => ({
                                new_name: searchText,
                            })}
                            parse={parseValue}
                            variant="outlined"
                            helperText={false}
                            onChange={(event) =>
                                setPayload({
                                    ...payload,
                                    website_id: event,
                                })
                            }
                            validate={required()}
                        />
                    </ReferenceInput>
                    <ReferenceInput source="supplier_id" reference="v1_Suppliers">
                        <AutocompleteInput
                            optionText="FullName"
                            label={'Publisher'}
                            defaultValue={publisherId}
                            filterToQuery={(searchText) => ({
                                FullName: searchText,
                            })}
                            parse={parseValue}
                            variant="outlined"
                            helperText={false}
                            onChange={(event) =>
                                setPayload({
                                    ...payload,
                                    supplier_id: event,
                                })
                            }
                            validate={required()}
                        />
                    </ReferenceInput>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </SimpleForm>
        </Dialog>
    );
};

export default CreateOfferFromDropdown;
