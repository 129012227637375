import {User} from '@nhost/nhost-js';
import {getPermissionsFromRoles} from '@react-admin/ra-rbac';
import {AuthProvider, localStorageStore} from 'react-admin';
import {CRM_USER, ROUTES} from '../config/statuses';
import {sendPostToApi} from '../services/api';
import {nhost} from './nhost';

const localStore = localStorageStore();

const getNhostUser = () => {
    const user = nhost.auth.getUser();
    if (user === null) throw new Error('User not authenticated!');
    return user;
};

const getRoles = async (nhostUser: User) => {
    const response = await sendPostToApi(ROUTES.GET_ROLES, {
        id: nhostUser.metadata.crm_system_user_id,
    });
    return response.roles;
};

const getPermissions = async (userRoles: string[]) => {
    const response = await sendPostToApi(ROUTES.GET_ROLE_DEFINITIONS, {});
    return getPermissionsFromRoles({
        roleDefinitions: response.definitions,
        userRoles: userRoles,
    });
};

export const authProvider: AuthProvider = {
    nhostLogin: async () => {
        await nhost.auth.signIn({
            provider: 'google',
            options: {
                redirectTo: `${window.location.href}`,
            },
        });
    },

    // Despite the presence of this method here,
    // we don't use it because it doesn't return Promise
    // and an error is displayed on the login page because of it.
    login: async () => {
        return Promise.resolve();
    },

    logout: async () => {
        await nhost.auth.signOut();
        return Promise.resolve();
    },

    checkAuth: async () => {
        const isAuthenticated = await nhost.auth.isAuthenticatedAsync();

        const nhostUser = getNhostUser();
        const isCrmUser = nhostUser.roles.includes(CRM_USER);

        return isAuthenticated && isCrmUser ? Promise.resolve() : Promise.reject();
    },

    checkError: (error: any) => {
        const status = error.status;
        const logoutStatuses = [401, 403];

        if (logoutStatuses.includes(status)) {
            return Promise.reject();
        }

        return Promise.resolve();
    },

    getIdentity: () => {
        try {
            const nhostUser = getNhostUser();

            return Promise.resolve({
                id: Number(nhostUser.metadata.crm_system_user_id),
                fullName: nhostUser.displayName,
                avatar: nhostUser.avatarUrl,
            });
        } catch (error) {
            return Promise.reject();
        }
    },

    getPermissions: async () => {
        try {
            const nhostUser = getNhostUser();
            if (!nhostUser.roles.includes(CRM_USER)) return Promise.reject('Not CRM User');

            let userId: number | undefined = localStore.getItem('userId', undefined);
            if (!userId) {
                userId = Number(nhostUser.metadata.crm_system_user_id);
                if (!userId) return Promise.reject('No id');
                localStore.setItem('userId', userId);
            }

            let userRoles: string[] | undefined = localStore.getItem('userRoles', undefined);
            if (!userRoles) {
                userRoles = await getRoles(nhostUser);
                if (!userRoles) return Promise.reject('No roles');
                localStore.setItem('userRoles', userRoles);
            }

            let permissions: Object | undefined = localStore.getItem('userPermissions', undefined);
            if (!permissions) {
                permissions = await getPermissions(userRoles);
                if (!permissions) return Promise.reject('No permissions');
                localStore.setItem('userPermissions', permissions);
            }

            return Promise.resolve(permissions);
        } catch (error) {
            return Promise.reject();
        }
    },
};
