import {Box, Grid, Tooltip, Typography} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import {AppBar, useSidebarState} from 'react-admin';
import {useLocation} from 'react-router-dom';
import styles from '../components/common/stylesCommon.module.css';
import {menuItemsList} from '../config/menuItemList';
import logo from '../layout/images/adzz-logo.png';
import {NotificationButton} from './notifications/notificationButton';

const findIconByResource = (resource: string) => {
    const foundItem = getResourceItem(menuItemsList, resource);

    if (foundItem) return foundItem.icon;

    for (const item of menuItemsList) {
        if (item.children) {
            const foundChildItem = getResourceItem(item.children, resource);
            if (foundChildItem) return foundChildItem.icon;
        }
    }
};

const getResourceItem = (array: any[], resource: string) => {
    return array.find((item) => item.resource === resource);
};

const CRMAppBar = () => {
    const [open, setOpen] = useSidebarState();
    const location = useLocation();
    const entityIcon = findIconByResource(location.pathname.split('/')[1]);

    const modifiedIcon = !!entityIcon
        ? React.cloneElement(entityIcon as React.ReactElement<any>, {
              ...entityIcon.props,
              color: 'white',
              fontSize: 'medium',
              style: {marginRight: '10px'},
          })
        : null;

    return (
        <AppBar sx={{color: 'white'}}>
            <Toolbar>
                <Tooltip title={open ? 'Close menu' : 'Open menu'}>
                    <Box onClick={() => setOpen(!open)} className={styles.pointer}>
                        <img src={logo} alt="logo" className={styles.logo} />
                    </Box>
                </Tooltip>
            </Toolbar>
            <Box flex="1">
                <Grid container display="flex" alignItems="center">
                    {modifiedIcon}
                    <Typography variant="h6" id="react-admin-title"></Typography>
                </Grid>
            </Box>
            <Box sx={{paddingTop: '7px'}}>
                <NotificationButton />
            </Box>
        </AppBar>
    );
};

export default CRMAppBar;
