import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {useLocation} from 'react-router-dom';
import {SupplierInput} from '../../common/inputWrappers';

export function BulkCreateFormFields() {
    const {state: defaultSupplier} = useLocation();
    const {setValue} = useFormContext();
    const defaultSupplierId = defaultSupplier?.record?.id;

    useEffect(() => {
        if (defaultSupplier) {
            setValue('supplier_id', defaultSupplierId);
        }
    }, [setValue, defaultSupplier, defaultSupplierId]);

    return (
        <SupplierInput
            disabled={!!defaultSupplierId}
            isRequired
            hideLink
            defaultValue={defaultSupplierId || null}
            acceptCreate={true}
        />
    );
}
