import {gql, useMutation} from '@apollo/client';
import {Alert, Button as MUIButton, Card, CardContent, Grid, Typography} from '@mui/material';
import type {CellChange, TextCell} from '@silevis/reactgrid';
import {useEffect, useState} from 'react';
import {Button, Form, SelectInput, useGetIdentity, useNotify, useRedirect} from 'react-admin';
import {Link, useLocation} from 'react-router-dom';
import {CONTENT_STATUS} from '../../../config/statuses';
import {CurrencyInput, SupplierInput, WriterInput} from '../../common/inputWrappers';
import {Errors} from './Errors';
import type {LivePostData} from './LivePostsSpreadsheet';
import {applyChangesToLivePosts, getLivePosts, LivePostsSpreadsheet} from './LivePostsSpreadsheet';

export function AddLivePosts() {
    const {data: identity, isLoading: identityIsLoading} = useGetIdentity();
    const redirect = useRedirect();
    const {state} = useLocation();
    const [livePosts, setLivePosts] = useState<LivePostData[]>(getLivePosts());
    const [errorsToDisplay, setErrorsToDisplay] = useState<string[]>([]);
    const handleTableDataChanges = (changes: CellChange<TextCell>[]) => {
        setLivePosts((prevLivePosts) => applyChangesToLivePosts(changes, prevLivePosts));
    };
    const notify = useNotify();

    const ADD_LIVE_POSTS_MUTATION = gql`
        mutation addLivePostsMutation($posts: [LivePostInput]!, $user: UserInputLivePosts!) {
            addLivePosts(posts: $posts, user: $user) {
                affected_rows
                returning {
                    id
                }
            }
        }
    `;
    const [addLivePostsMutation, {data: dataApollo, loading: loadingApollo, error: errorApollo}] = useMutation(
        ADD_LIVE_POSTS_MUTATION,
        {ignoreResults: false}
    );

    const validatePosts = (posts: any[]) => {
        const errors: string[] = [];
        posts.forEach((post) => {
            try {
                new URL(post.new_name!);
            } catch {
                errors.push(post.new_name!);
            }
        });
        setErrorsToDisplay(errors);
        return errors;
    };

    const createPosts = async (formData: any) => {
        setErrorsToDisplay([]);
        const livePostsToAdd = livePosts.filter((post) => post.SourceUrl.length > 0);
        const livePostsData = [];

        for (const livePost of livePostsToAdd) {
            livePostsData.push({
                // FROM SPREADSHEET
                new_name: livePost.SourceUrl.trim(),
                new_TargetURL1: livePost.TargetUrl1.trim() || null,
                new_Anchortext1: livePost.AnchorText1 || null,
                new_TargetURL2: livePost.TargetUrl2.trim() || null,
                new_Anchortext2: livePost.AnchorText2 || null,
                new_TargetURL3: livePost.TargetUrl3.trim() || null,
                new_Anchortext3: livePost.AnchorText3 || null,
                new_Supplierprice: livePost.SupplierPrice || 0,
                new_ContentCost: livePost.ContentCost || 0,
                new_UploadPrice: livePost.UploadPrice || 0,
                new_Title: livePost.H1Title || null, // FROM FORM DATA
                new_ContentOrderStatus: formData.new_ContentOrderStatus,
                writer_id: formData.writer_id,
                supplier_id: formData.supplier_id,
                supplier_currency_id: formData._supplier_currency_id || null,
                currency_id: formData._writer_currency_id || null, // FROM CONTEXT
                campaign_id: state.record.id,
                createdby_id: identity?.id,
                strategy_id: state.record.strategy_id || null,
            });
        }

        const errors = validatePosts(livePostsData);

        if (errors.length) return;

        if (!livePostsData.length) return;

        const variables = {
            variables: {
                posts: livePostsData,
                user: {id: identity?.id},
            },
        };

        await addLivePostsMutation(variables);
    };

    useEffect(() => {
        if (!dataApollo) return;

        const result = dataApollo.addLivePosts.returning.map((post: any) => post.id);
        const ids = result.length > 10 ? [result[0], result[result.length - 1]].join(' - ') : result;

        const {affected_rows} = dataApollo.addLivePosts;
        notify('adzz.livePosts.created', {
            messageArgs: {
                affected_rows,
                ids,
            },
            type: 'success',
        });
        redirect(`/v1_Campaign/${state.record.id}/2`);
    }, [dataApollo, notify, redirect, state.record.id]);

    useEffect(() => {
        if (!errorApollo) return;

        notify('adzz.livePosts.errorApollo', {
            messageArgs: {err: errorApollo.message},
            type: 'error',
        });
    }, [errorApollo, notify]);

    if (identityIsLoading) return <></>;

    return (
        <>
            <Card>
                <CardContent>
                    <Grid container direction="column" sx={{mt: 3}}>
                        <Typography variant="h3">Add live Posts</Typography>
                        <Grid>
                            <Errors errors={errorsToDisplay} />
                        </Grid>
                        <Form onSubmit={createPosts} id="addLivePostsForm">
                            <Grid
                                container
                                spacing={3}
                                sx={{
                                    mt: 0,
                                    mb: 4,
                                }}
                            >
                                <Grid item xs={4}>
                                    <SelectInput
                                        choices={[
                                            {
                                                id: CONTENT_STATUS.NO_NEED,
                                                name: 'No Need',
                                            },
                                            {
                                                id: CONTENT_STATUS.APPROVED,
                                                name: 'Approved',
                                            },
                                        ]}
                                        fullWidth
                                        name="new_ContentOrderStatus"
                                        source="new_ContentOrderStatus"
                                        label="Content Status"
                                        defaultValue={CONTENT_STATUS.NO_NEED}
                                        variant="outlined"
                                        helperText={false}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <WriterInput
                                        defaultValue={state.record.writer_id}
                                        variant="outlined"
                                        helperText={false}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SupplierInput variant="outlined" helperText={false} />
                                </Grid>
                                <Grid item xs={6}>
                                    <CurrencyInput
                                        source="_supplier_currency_id"
                                        label="Publisher Currency"
                                        variant="outlined"
                                        helperText={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CurrencyInput
                                        source="_writer_currency_id"
                                        label="Writer Currency"
                                        variant="outlined"
                                        helperText={false}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Alert severity="info" variant="filled">
                                        <strong>
                                            The Strategy for each post will be set to the related campaign's Default
                                            Strategy
                                        </strong>
                                    </Alert>
                                </Grid>
                            </Grid>

                            <LivePostsSpreadsheet
                                livePosts={livePosts}
                                handleTableDataChanges={handleTableDataChanges}
                            />
                        </Form>
                        <Grid sx={{mt: 3}}>
                            <Button
                                type="submit"
                                disabled={loadingApollo ? true : false}
                                variant="contained"
                                label={loadingApollo ? 'Saving...' : 'Save'}
                                form="addLivePostsForm"
                                sx={{mr: 3}}
                            />
                            <MUIButton
                                sx={{lineHeight: 1.5}}
                                component={Link}
                                to={`/v1_Campaign/${state.record.id}/2`}
                                size="small"
                                variant="contained"
                            >
                                Back to campaign #{state.record.id}
                            </MUIButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
