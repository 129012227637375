import {v1_InvoiceStatus_select_column} from '../../generated/types';

const InvoiceStatus = {
    ...v1_InvoiceStatus_select_column,
    res: 'v1_InvoiceStatus',
    comp: {},
    rel: {},
};

export default InvoiceStatus;
