import {QueryClient} from 'react-query';

export const appQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnReconnect: false,
        },
    },
});

export default appQueryClient;
