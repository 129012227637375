import {v1_Categories_select_column} from '../../generated/types';

const Categories = {
    ...v1_Categories_select_column,
    res: 'v1_Categories',
    comp: {},
    rel: {},
    label: 'Category',
};

export default Categories;
