import {v1_PostNiche_select_column} from '../../generated/types';

const PostNiche = {
    ...v1_PostNiche_select_column,
    res: 'v1_PostNiche',
    comp: {},
    rel: {},
};

export default PostNiche;
