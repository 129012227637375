export const azureBlobSAS = process.env.REACT_APP_AZURE_BLOB_SAS as string;

export const azureBlobStorageAccount = process.env.REACT_APP_AZURE_BLOB_ACCOUNT as string;

export const azureBlobImageContainer = process.env.REACT_APP_AZURE_BLOB_IMAGE_CONTAINER as string;

export const tinyMCEApiKey = process.env.REACT_APP_TINY_MCE_API_KEY as string;

export const appProxyBase = process.env.REACT_APP_PROXY_BASE as string;

export const isDevEnvironment = process.env.REACT_APP_IS_DEV_ENVIRONMENT || 'false';

export const hasuraGqlAdminSecret = process.env.REACT_APP_HASURA_GRAPHQL_ADMIN_SECRET as string;

export const hasuraGqlEndpoint = process.env.REACT_APP_HASURA_PROXY_ENDPOINT as string;

export const hasuraGqlLocalEndpoint = process.env.REACT_APP_HASURA_LOCAL_ENDPOINT as string;

export const isLocalHasura = () => {
    return hasuraGqlEndpoint === hasuraGqlLocalEndpoint;
};
