import {v1_CompanyType_select_column} from '../../generated/types';

const CompanyType = {
    ...v1_CompanyType_select_column,
    res: 'v1_CompanyType',
    comp: {},
    rel: {},
};

export default CompanyType;
