import {useGetList} from 'react-admin';

// you should check the actual amount of active users, otherwise the list will be incomplete (25 by default)
const MAX_ACTIVE_USER = 150;

export const useGetActiveUsers = (props?: {enabled?: boolean}) => {
    const {data, isLoading} = useGetList(
        'v1_SystemUserBase',
        {
            sort: {field: 'name', order: 'ASC'},
            pagination: {page: 1, perPage: MAX_ACTIVE_USER},
            filter: {status: 1},
        },
        {
            enabled: props?.enabled,
        }
    );
    return {data, isLoading};
};
