import {addEventsForMutations} from '@react-admin/ra-audit-log';
import {useState} from 'react';
import {DataProvider, withLifecycleCallbacks} from 'react-admin';
import {authProvider} from '../authorization/authProvider';
import {AUDITED_FIELDS} from '../config/events';
import {hasChangedField} from '../utility/helpers';
import {eventsLifecycleCallbacks} from './eventsLifecycleCallbacks';
import {postLifecycleCallbacks} from './postLifecycleCallbacks';

import useHasuraDataProvider from './useHasuraDataProvider';

const shouldAudit = (action: string, ...args: any[]): boolean => {
    const [resource, params] = args;
    if (action !== 'update' || !(resource in AUDITED_FIELDS)) return false;

    const {data, previousData} = params;
    return AUDITED_FIELDS[resource as keyof typeof AUDITED_FIELDS].some((auditField) =>
        hasChangedField(auditField.field, data, previousData)
    );
};

export const useAppDataProvider = (): DataProvider | null => {
    const [dataProvider, setDataProvider] = useState(null);

    useHasuraDataProvider(setDataProvider);

    if (!dataProvider) return null;

    // Wraps hasura dataProvider to create events on Posts mutations
    // @see https://marmelab.com/ra-enterprise/modules/ra-audit-log#client-side-tracking
    const dataProviderWithLogs = addEventsForMutations(dataProvider, authProvider, {
        name: 'v1_Events',
        shouldAudit,
    });

    // Wraps dataProvider with `withLifecycleCallbacks` to add business logic, callbacks separated by resource
    // @see https://marmelab.com/react-admin/withLifecycleCallbacks.html
    // @see https://marmelab.com/react-admin/withLifecycleCallbacks.html#limitations
    const dataProviderWithBusinessLogic = withLifecycleCallbacks(dataProviderWithLogs, [
        eventsLifecycleCallbacks,
        postLifecycleCallbacks,
    ]);
    return dataProviderWithBusinessLogic;
};
