const enum OPERATOR {
    IS_NULL = '@_is_null',
    DISTINCT_ON = 'distinct_on',
    ILIKE = '@_ilike',
    EQ = '@_eq',
    NIN = '@_nin',
    IN = '@_in',
    GTE = '@_gte',
    GT = '@_gt',
    LTE = '@_lte',
    LT = '@_lt',
    NOT = '_not',
}

export default OPERATOR;
