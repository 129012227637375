import {DataProvider, UpdateManyParams, UpdateManyResult, UpdateParams, UpdateResult} from 'react-admin';
import {v1_Posts} from '../generated/types';

import appQueryClient from './appQueryClient';

const resource = 'v1_Posts';

export const postLifecycleCallbacks = {
    resource,

    beforeUpdate: async (params: UpdateParams<v1_Posts>, dataProvider: DataProvider) => {
        return params;
    },
    afterUpdate: async (result: UpdateResult, dataProvider: DataProvider) => {
        appQueryClient.invalidateQueries('v1_Events');
        return result;
    },

    beforeUpdateMany: async (params: UpdateManyParams, dataProvider: DataProvider) => {
        return params;
    },
    afterUpdateMany: async (result: UpdateManyResult, dataProvider: DataProvider) => {
        appQueryClient.invalidateQueries('v1_Events');
        return result;
    },
};

export default postLifecycleCallbacks;
