import {v1_CampaignType_select_column} from '../../generated/types';

const CampaignType = {
    ...v1_CampaignType_select_column,
    res: 'v1_CampaignType',
    comp: {},
    rel: {},
};

export default CampaignType;
