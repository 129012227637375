import {v1_PostsStatus_select_column} from '../../generated/types';

const PostsStatus = {
    ...v1_PostsStatus_select_column,
    res: 'v1_PostsStatus',
    comp: {},
    rel: {},
};

export default PostsStatus;
