import {v1_ContentTypes_select_column} from '../../generated/types';

const ContentType = {
    ...v1_ContentTypes_select_column,
    res: 'v1_ContentTypes',
    comp: {},
    rel: {},
};

export default ContentType;
