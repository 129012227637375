import {v1_ServiceTypes_select_column} from '../../generated/types';

const PaymentCategory = {
    ...v1_ServiceTypes_select_column,
    res: 'v1_ServiceTypes',
    comp: {},
    rel: {},
    label:'Payment Categories'
};

export default PaymentCategory;
