import buildHasuraProvider from 'ra-data-hasura';
import {useEffect} from 'react';
import customBuildFields from '../queries/custom-build-queries';
import {clientOptions} from './hasuraConfig';

const useHasuraDataProvider = (setStateProvider: any) => {
    useEffect(() => {
        const buildDataProvider = async () => {
            const originalDataProvider = await buildHasuraProvider(
                {clientOptions},
                {
                    buildFields: customBuildFields,
                }
            );

            const dataProviderHasura = {
                ...originalDataProvider,
                getList: async (resource: any, params: any) => {
                    let sortOrder = params.sort.order === 'ASC' ? 'asc_nulls_last' : 'desc_nulls_last';

                    const newParams = {
                        ...params,
                        sort: {
                            field: params.sort.field,
                            order: sortOrder,
                        },
                    };

                    return originalDataProvider.getList(resource, newParams);
                },
            };

            setStateProvider(dataProviderHasura);
        };
        buildDataProvider();
    }, [setStateProvider]);
};
export default useHasuraDataProvider;
