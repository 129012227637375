import {v1_WebsitesToTopics_select_column} from '../../generated/types';

const WebsitesToTopics = {
    ...v1_WebsitesToTopics_select_column,
    res: 'v1_WebsitesToTopics',
    comp: {},
    rel: {},
};

export default WebsitesToTopics;
