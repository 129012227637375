import {Card, Stack, Typography} from '@mui/material';

interface ErrorsProps {
    errors: any[];
}

export function Errors({errors}: ErrorsProps) {
    if (errors.length === 0) return null;

    return (
        <Card
            sx={{
                mb: 3,
                padding: '15px',
            }}
        >
            <Typography variant="h6" color="red">
                Errors (statistics was not saved):
            </Typography>
            <Stack>
                {errors.map((error: any, idx: number) => {
                    return (
                        <Typography variant="body1" key={idx}>
                            URL {error.new_name} (Line {error.id + 1}):{' '}
                            {error.language_id && `incorrect language "${error.language_id}", `}
                            {error.new_dtoxrisk && `incorrect dtox risk "${error.new_dtoxrisk}".`}
                        </Typography>
                    );
                })}
            </Stack>
        </Card>
    );
}
