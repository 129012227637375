import {AppLocationContext} from '@react-admin/ra-navigation';
import {Layout} from 'react-admin';
import useGetPreferences from '../hooks/useGetPreferences';
import {useScrollToTop} from '../hooks/useScrollToTop';
import CRMAppBar from './CRMAppBar';
import MenuList from './menu';
import {NotificationContextProvider} from '../contexts/notificationContext';

const MyLayout = (props: any) => {
    useScrollToTop();
    useGetPreferences();
    return (
        <AppLocationContext>
            <NotificationContextProvider>
                <Layout
                    sx={{
                        minHeight: '1500px',
                        '& .MuiPaper-root': {overflow: 'visible'},
                        '& .RaLayout-contentWithSidebar': {
                            '& .MuiDrawer-root': {
                                '& .MuiPaper-root': {
                                    '& .RaSidebar-fixed': {
                                        position: 'inherit',
                                        overflow: 'visible',
                                    },
                                },
                            },
                        },
                    }}
                    {...props}
                    appBar={CRMAppBar}
                    menu={MenuList}
                ></Layout>
            </NotificationContextProvider>
        </AppLocationContext>
    );
};

export default MyLayout;
