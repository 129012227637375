import {v1_process_select_column} from '../../generated/types';

const Process = {
    ...v1_process_select_column,
    res: 'v1_process',
    comp: {},
    rel: {},
    label: 'Process Logs',
};

export default Process;
