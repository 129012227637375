import {v1_BrandPriority_select_column} from '../../generated/types';

const BrandPriority = {
    ...v1_BrandPriority_select_column,
    res: 'v1_BrandPriority',
    comp: {},
    rel: {},
};

export default BrandPriority;
