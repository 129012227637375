import {v1_MarketplaceProjects_select_column} from '../../generated/types';

const MarketplaceProject = {
    ...v1_MarketplaceProjects_select_column,
    res: 'v1_MarketplaceProjects',
    label: 'Marketplace Project',
    comp: {},
    rel: {},
};

export default MarketplaceProject;
