import {
    v1_AccountBase_select_column,
    v1_Advertisers_select_column,
    v1_AffDealType_select_column,
    v1_AffiliateOrderCategoriesPriority_select_column,
    v1_AffiliateProgramType_select_column,
    v1_Bonuses_select_column,
    v1_BonusesToCountries_select_column,
    v1_BonusesToTypes_select_column,
    v1_BonusStatus_select_column,
    v1_BonusTypes_select_column,
    v1_BrandAlias_select_column,
    v1_BrandPriority_select_column,
    v1_Campaign_select_column,
    v1_CampaignPriority_select_column,
    v1_CampaignStrategy_select_column,
    v1_CampaignType_select_column,
    v1_Categories_select_column,
    v1_CompanyType_select_column,
    v1_ContactBase_select_column,
    v1_ContentTypes_select_column,
    v1_Credentials_select_column,
    v1_InvoiceBase_select_column,
    v1_InvoiceStatus_select_column,
    v1_LandingPagePriority_select_column,
    v1_LandingPages_select_column,
    v1_LanguagePriceStep_select_column,
    v1_MarketplaceStatus_select_column,
    v1_Months_select_column,
    v1_new_affordertypeBase_select_column,
    v1_new_brandBase_select_column,
    v1_new_countryBase_select_column,
    v1_new_languagesBase_select_column,
    v1_new_new_brand_new_countryBase_select_column,
    v1_new_paymentmethodBase_select_column,
    v1_new_POBase_select_column,
    v1_new_receiptBase_select_column,
    v1_new_regemailBase_select_column,
    v1_new_trackerBase_select_column,
    v1_Offer_select_column,
    v1_OfferTags_select_column,
    v1_OfferToTags_select_column,
    v1_OrderPriority_select_column,
    v1_OrderType_select_column,
    v1_Page_select_column,
    v1_PaymentCompanies_select_column,
    v1_PostDuration_select_column,
    v1_PostNiche_select_column,
    v1_Posts_select_column,
    v1_PostsAnchorTextType_select_column,
    v1_PostsContentStatus_select_column,
    v1_PostsCopyScape_select_column,
    v1_PostsLinkStatus_select_column,
    v1_PostsOnlineStatus_select_column,
    v1_PostsOutreachStatus_select_column,
    v1_PostsStatus_select_column,
    v1_process_select_column,
    v1_Project_Countries_select_column,
    v1_Project_select_column,
    v1_ProjectImportance_select_column,
    v1_PurchaseOrderStatus_select_column,
    v1_ReceiptStatus_select_column,
    v1_RecurringPayments_select_column,
    v1_Report_select_column,
    v1_RestrictedNichesToOffer_select_column,
    v1_SalesOrderBase_select_column,
    v1_ServiceTypes_select_column,
    v1_StrategyFieldsSet_select_column,
    v1_Supplier_Tag_rel_select_column,
    v1_Suppliers_select_column,
    v1_SystemUserBase_select_column,
    v1_Tags_select_column,
    v1_Teams_select_column,
    v1_TrackerDeal_select_column,
    v1_TrackerTags_select_column,
    v1_TrackerToTags_select_column,
    v1_TransactionCurrencyBase_select_column,
    v1_Website_Platform_select_column,
    v1_Website_select_column,
    v1_WebsiteDToxRisk_select_column,
    v1_WebsiteQualityScore_select_column,
    v1_WebsiteTopics_select_column,
    v1_Writers_select_column,
} from '../generated/types';
import Models from '../models/db/models';

export const POST_STATUS = {
    IN_PROGRESS: 100000000,
    POOL: 100000001,
    PLANNED: 100000002,
    POSTED: 100000003,
    UPLOADING_ERROR_OR_OTHER_PROBLEM: 100000004,
    MERGED: 100000005,
    ERROR: 100000004,
};

export const ONLINE_POST_STATUS = {
    LIVE: 100000000,
    OFFLINE: 100000001,
};

export const POSTS_COPY_SCAPE = {
    OK: 100000000,
    DUPLICATE_INTERNET: 100000001,
    DUPLICATE_PRIVATE_INDEX: 100000002,
    DUPLICATE_INTERNET_PRIVATE_INDEX: 100000003,
    NOT_USED: 100000004,
};

export const POSTS_LINK_STATUS = {
    OK: 100000000,
    OK_INCLUDES_NO_FOLLOW: 100000005,
    OK_MANUALLY_CHECKED: 100000006,
    PARTIALY_OK: 100000001,
    LINKS_NOT_FOUND: 100000002,
    ERROR: 100000003,
    SORCE_URL_NOT_EXISTS_404: 100000004,
};

export const enum CONTENT_STATUS {
    PLANNED = 100000008,
    MERGED = 100000007,
    NO_NEED = 100000000,
    ORDERED = 100000002,
    WAITING_APPROVAL = 100000003,
    DECLINED = 100000005,
    APPROVED = 100000004,
    CONTENT_REQUEST = 100000006,
    WAITING_APPROVAL_ADVERTISER = 100000011,
}

export const NO_NEED_CONTENT_STATUS = [
    {
        id: CONTENT_STATUS.NO_NEED,
        value: 'No Need',
    },
];

export const OUTREACH_STATUS = {
    CONTENT_WRITING: 100000000,
    READY_TO_PUBLISH: 100000001,
    SENT_TO_PUBLISHER: 100000002,
    PUBLISHED: 100000003,
    POOL: 100000004,
    PLANNED: 100000005,
    REASSIGN_WEBSITE: 100000006,
};

export const PURCHASE_ORDER_STATUS = {
    APPROVAL_REQUIRED: 1,
    TO_PAY: 100000000,
    PAID: 2,
    DECLINED: 100000001,
    CANCELLED: 3,
};

export const ORDER_TYPE = {
    LINKS_DEAL: 100000000,
    SEO_PACKAGE_DEAL: 100000001,
    AFFILIATES_PROGRAM: 100000002,
    MEDIA_DEAL: 100000003,
    MEDICAL_LEADS: 100000004,
    WEBSITE_BUILDING: 100000005,
    WEBSITE_MAINTENANCE: 100000006,
    PPC_SMM: 100000007,
    LINKS_EXCHANGE: 100000008,
    OTHER: 100000009,
    CUSTOMER_REVENUE_SHARE: 100000010,
    PARTNERSHIP: 100000011,
    AFFILIATE_DEAL: 100000012,
};

export const TRACKERS_ONLINE_STATUS = {
    NOT_AVAILABLE: 100000001,
    ONLINE: 100000000,
};

export const enum TRACKERS_ONLINE_STATUS_VALUE {
    ONLINE = 'online',
    NOT_AVAILABLE = 'Not Available',
}

export const INVOICE_STATUS = {
    CUSTOMER_REFUSED_TO_PAY: 100000000,
    PAID: 100001,
    DRAFT: 1,
    PRODUCED_NOT_PAID: 100000001,
    CANCELLED: 100000002,
};

export const RECEIPTS_STATUS = {
    DRAFT: 1,
    PAID: 100000001,
    INACTIVE: 2,
    CREDIT: 100000002,
};

export const SERVICE_TYPE = {
    OTHER: 100000010,
    CONTENT: 100000000,
    GRAPHICS_DESIGN: 100000001,
    OFFICE_SOFTWARE: 100000002,
    POSTS_DEAL: 100000003,
    OUTSOURCE_SOFTWARE_PROGRAMMING: 100000009,
    IT: 100000004,
    WEBSITE_DEVELOPMENT: 100000005,
    OFFICE_MAINTENANCE: 100000007,
    DOMAINS_HOSTING: 100000008,
    CONTENT_FREELANCERS: 3,
    OUTREACH_FREELANCERS: 4,
    LINK_SUPPLIERS: 2,
    MEDIA_BUYING_PROJECT: 9,
};

export const STRATEGY_TYPE = {
    VIDEO: 100000008,
    ON_SITE_CONTENT: 100000015,
    INITIAL_WEBSITE_BUILDING_CONTENT: 100000016,
    LINK_BUILDING: 100000017,
    SOCIAL: 100000012,
    PPC: 100000018,
    OTHER_SEO: 100000019,
};

export const CONTACT_TYPE = {
    WRITER: 100000000,
    LINK_BUILDER: 100000001,
    SEO_CLIENT: 100000002,
    WEBMASTER: 100000003,
    DESIGNER: 100000004,
    LINK_BUYEr_SUPPLIER: 100000005,
    AFFILIATE_MANAGER: 100000006,
    SERVICE_PROVIDER: 100000007,
};

export const BRAND_PRIORITY = {
    HIGH: 1,
    MEDIUM: 2,
    LOW: 3,
    UNKNOWN: 4,
};

export const ROLES = {
    ADMIN: 1,
    OPERATIONS_MANAGER: 2,
    OUTREACH_MANAGER: 3,
    OUTREACH_USER: 4,
    AFFILIATE_MANAGER: 5,
    AFFILIATE_USER: 6,
    ONSITE_MANAGER: 7,
    ONSITE_USER: 8,
    FINANCIAL_USER: 9,
    WRITER: 10,
    PUBLISHER: 11,
    ADVERTISER: 12,
    SYSTEM_CUSTOMIZER: 13,
    CONTENT_MANAGER: 15,
    SALES_MANAGER: 17,
    FINANCIAL_MANAGER: 18,
};

export const INVOICE_YEARS = [
    {
        id: 2017,
        name: '2017',
    },
    {
        id: 2018,
        name: '2018',
    },
    {
        id: 2019,
        name: '2019',
    },
    {
        id: 2020,
        name: '2020',
    },
    {
        id: 2021,
        name: '2021',
    },
    {
        id: 2022,
        name: '2022',
    },
    {
        id: 2023,
        name: '2023',
    },
];

export const EMPTY_FILTER_ID = -1;

export const STATE_CODE = {
    ENABLED: 0,
    DISABLED: 1,
};

export const enum STATE_CODE_VALUE {
    ENABLED = 'active',
    DISABLED = 'inactive',
}

export const USER_STATUS_CODE = {
    ACTIVE: 1,
    INACTIVE: 2,
};

export const RECURRING_PAYMENT_STATUS = {
    ACTIVE: 1,
    INACTIVE: 2,
    TRIAL: 3,
    TO_DELETE: 4,
};

export const BONUS_STATUS = {
    DRAFT: 1,
    PUBLISHED: 2,
    DISQUALIFIED: 3,
};

export const TYPE_OF_CONTRACTOR = {
    WRITER: 0,
    SUPPLIER: 1,
    COMPANY: 2,
    ADVERTISER: 3,
};

export const COMPLETION_RATE = {
    SIXTY: 60,
    EIGHTY_FIVE: 85,
};

export const RECURRING_PAYMENT_FREQUENCY = {
    MONTHLY: 1,
    YEARLY: 2,
    BY_DEMAND: 3,
};

export const ORDER_PRIORITY = {
    HIGH: 1,
    NO_PRIORITY: 2,
    MEDIUM: 100000000,
    LOW: 100000001,
    NOT_WORKING: 100000002,
};

export const enum CancelButtonActions {
    CANCEL = 'Cancel',
    DECLINE = 'Decline',
}

export const CRM_USER = 'CRM User';

export const MARKETPLACE_STATUSES = {
    PROVIDE_DETAILS: 1,
    ORDER_PLACED: 2,
    CONTENT_CREATION: 3,
    WAITING_APPROVAL: 4,
    SENT_TO_PUBLISHER: 5,
    PUBLISHED: 6,
};

export const enum RES {
    ADVERTISER = 'v1_Advertisers',
    AFFILIATE_ACCOUNT_USERNAME = 'new_AffiliateaccountUsername',
    AFFILIATE_ORDER = 'v1_SalesOrderBase',
    AFFILIATE_ORDER_DEAL_TYPE = 'v1_AffDealType',
    AFFILIATE_ORDER_PRIORITY = 'v1_OrderPriority',
    AFFILIATE_ORDER_CATEGORY_PRIORITY = 'v1_AffiliateOrderCategoriesPriority',
    AFFILIATE_ORDER_TYPE = 'v1_new_affordertypeBase',
    AFFILIATE_ORDER_REG_EMAIL = 'v1_new_regemailBase',
    AFFILIATE_PROGRAM_TYPE = 'v1_AffiliateProgramType',
    BONUS = 'v1_Bonuses',
    BONUSES_TO_COUNTRIES = 'v1_BonusesToCountries',
    BONUS_STATUS = 'v1_BonusStatus',
    BONUS_TYPE = 'v1_BonusTypes',
    BONUSES_TO_TYPES = 'v1_BonusesToTypes',
    BRAND = 'v1_new_brandBase',
    BRAND_ALIAS = 'v1_BrandAlias',
    BRAND_COUNTRY = 'v1_new_new_brand_new_countryBase',
    BRAND_PRIORITY = 'v1_BrandPriority',
    CAMPAIGN = 'v1_Campaign',
    CAMPAIGN_PRIORITY = 'v1_CampaignPriority',
    CAMPAIGN_STRATEGY = 'v1_CampaignStrategy',
    CONTENT_TYPE = 'v1_ContentTypes',
    CAMPAIGN_TYPE = 'v1_CampaignType',
    COMPANY = 'v1_AccountBase',
    COMPANY_TYPE = 'v1_CompanyType',
    COUNTRY = 'v1_new_countryBase',
    CONTRACTOR = 'v1_ContactBase',
    CREDENTIAL = 'v1_Credentials',
    CURRENCY = 'v1_TransactionCurrencyBase',
    INVOICE = 'v1_InvoiceBase',
    INVOICE_STATUS = 'v1_InvoiceStatus',
    LANDING_PAGE = 'v1_LandingPages',
    LANDING_PAGE_PRIORITY = 'v1_LandingPagePriority',
    LANGUAGE = 'v1_new_languagesBase',
    LANGUAGE_PRICE_STEP = 'v1_LanguagePriceStep',
    MARKETPLACE_STATUS = 'v1_MarketplaceStatus',
    MONTH = 'v1_Months',
    OFFER = 'v1_Offer',
    OFFER_STATUS = 'v1_OfferStatus',
    OFFER_TAG = 'v1_OfferTags',
    PUBLISHER_TAG_REL = 'v1_Supplier_Tag_rel',
    OFFERS_TO_TAGS = 'v1_OfferToTags',
    ORDER_TYPE = 'v1_OrderType',
    PAGE = 'v1_Page',
    PAYMENT_CATEGORY = 'v1_ServiceTypes',
    PAYMENT_COMPANY = 'v1_PaymentCompanies',
    PAYMENT_METHOD = 'v1_new_paymentmethodBase',
    PAYMENT_ADDRESS = 'new_PaymentAddress',
    POST = 'v1_Posts',
    POST_COPYSCAPE = 'v1_PostsCopyScape',
    POST_DURATION = 'v1_PostDuration',
    POST_STATUS = 'v1_PostsStatus',
    POST_CONTENT_STATUS = 'v1_PostsContentStatus',
    POST_LINK_STATUS = 'v1_PostsLinkStatus',
    POST_NICHE = 'v1_PostNiche',
    POST_ANCHOR_TEXT_TYPE = 'v1_PostsAnchorTextType',
    POST_ONLINE_STATUS = 'v1_PostsOnlineStatus',
    POST_OUTREACH_STATUS = 'v1_PostsOutreachStatus',
    PROCESS = 'v1_process',
    PROJECT = 'v1_Project',
    PROJECT_IMPORTANCE = 'v1_ProjectImportance',
    PROJECT_COUNTRY = 'v1_Project_Countries',
    PROJECT_CATEGORY = 'v1_Categories',
    PUBLISHER = 'v1_Suppliers',
    PURCHASE_ORDER = 'v1_new_POBase',
    PURCHASE_ORDER_STATUS = 'v1_PurchaseOrderStatus',
    RECEIPT = 'v1_new_receiptBase',
    RECEIPT_STATUS = 'v1_ReceiptStatus',
    RECURRING_PAYMENT = 'v1_RecurringPayments',
    REPORT = 'v1_Report',
    RESTRICTED_NICHES_TO_OFFER = 'v1_RestrictedNichesToOffer',
    SUPPLIER = 'v1_Suppliers',
    STRATEGY_FIELD_SET = 'v1_StrategyFieldsSet',
    TAG = 'v1_Tags',
    TEAM = 'v1_Teams',
    TRACKER = 'v1_new_trackerBase',
    TRACKER_DEAL = 'v1_TrackerDeal',
    TRACKER_TAG = 'v1_TrackerTags',
    TRACKERS_TO_TAGS = 'v1_TrackerToTags',
    USER = 'v1_SystemUserBase',
    WEBSITE = 'v1_Website',
    WEBSITE_TOPIC = 'v1_WebsiteTopics',
    DTOX_RISK = 'v1_WebsiteDToxRisk',
    WEBSITE_PLATFORM = 'v1_Website_Platform',
    WEBSITE_QUALITY_SCORE = 'v1_WebsiteQualityScore',
    WRITER = 'v1_Writers',
}

export const COL = {
    [RES.ADVERTISER]: v1_Advertisers_select_column,
    [RES.AFFILIATE_ORDER]: v1_SalesOrderBase_select_column,
    [RES.AFFILIATE_ORDER_DEAL_TYPE]: v1_AffDealType_select_column,
    [RES.AFFILIATE_ORDER_PRIORITY]: v1_OrderPriority_select_column,
    [RES.AFFILIATE_ORDER_CATEGORY_PRIORITY]: v1_AffiliateOrderCategoriesPriority_select_column,
    [RES.AFFILIATE_ORDER_TYPE]: v1_new_affordertypeBase_select_column,
    [RES.AFFILIATE_ORDER_REG_EMAIL]: v1_new_regemailBase_select_column,
    [RES.AFFILIATE_ORDER_DEAL_TYPE]: v1_AffDealType_select_column,
    [RES.AFFILIATE_PROGRAM_TYPE]: v1_AffiliateProgramType_select_column,
    [RES.BONUS]: v1_Bonuses_select_column,
    [RES.BONUSES_TO_COUNTRIES]: v1_BonusesToCountries_select_column,
    [RES.BONUS_STATUS]: v1_BonusStatus_select_column,
    [RES.BONUS_TYPE]: v1_BonusTypes_select_column,
    [RES.BONUSES_TO_TYPES]: v1_BonusesToTypes_select_column,
    [RES.BRAND]: v1_new_brandBase_select_column,
    [RES.BRAND_ALIAS]: v1_BrandAlias_select_column,
    [RES.BRAND_COUNTRY]: v1_new_new_brand_new_countryBase_select_column,
    [RES.BRAND_PRIORITY]: v1_BrandPriority_select_column,
    [RES.CAMPAIGN]: v1_Campaign_select_column,
    [RES.CAMPAIGN_PRIORITY]: v1_CampaignPriority_select_column,
    [RES.CAMPAIGN_STRATEGY]: v1_CampaignStrategy_select_column,
    [RES.CONTENT_TYPE]: v1_ContentTypes_select_column,
    [RES.CAMPAIGN_TYPE]: v1_CampaignType_select_column,
    [RES.COMPANY]: v1_AccountBase_select_column,
    [RES.COMPANY_TYPE]: v1_CompanyType_select_column,
    [RES.COUNTRY]: v1_new_countryBase_select_column,
    [RES.CONTRACTOR]: v1_ContactBase_select_column,
    [RES.CREDENTIAL]: v1_Credentials_select_column,
    [RES.CURRENCY]: v1_TransactionCurrencyBase_select_column,
    [RES.DTOX_RISK]: v1_WebsiteDToxRisk_select_column,
    [RES.INVOICE]: v1_InvoiceBase_select_column,
    [RES.INVOICE_STATUS]: v1_InvoiceStatus_select_column,
    [RES.LANDING_PAGE]: v1_LandingPages_select_column,
    [RES.LANDING_PAGE_PRIORITY]: v1_LandingPagePriority_select_column,
    [RES.LANGUAGE]: v1_new_languagesBase_select_column,
    [RES.LANGUAGE_PRICE_STEP]: v1_LanguagePriceStep_select_column,
    [RES.MARKETPLACE_STATUS]: v1_MarketplaceStatus_select_column,
    [RES.MONTH]: v1_Months_select_column,
    [RES.OFFER]: v1_Offer_select_column,
    [RES.OFFER_TAG]: v1_OfferTags_select_column,
    [RES.OFFERS_TO_TAGS]: v1_OfferToTags_select_column,
    [RES.ORDER_TYPE]: v1_OrderType_select_column,
    [RES.PAGE]: v1_Page_select_column,
    [RES.PAYMENT_CATEGORY]: v1_ServiceTypes_select_column,
    [RES.PAYMENT_COMPANY]: v1_PaymentCompanies_select_column,
    [RES.PAYMENT_METHOD]: v1_new_paymentmethodBase_select_column,
    [RES.POST]: v1_Posts_select_column,
    [RES.POST_COPYSCAPE]: v1_PostsCopyScape_select_column,
    [RES.POST_DURATION]: v1_PostDuration_select_column,
    [RES.POST_STATUS]: v1_PostsStatus_select_column,
    [RES.POST_CONTENT_STATUS]: v1_PostsContentStatus_select_column,
    [RES.POST_ONLINE_STATUS]: v1_PostsOnlineStatus_select_column,
    [RES.POST_LINK_STATUS]: v1_PostsLinkStatus_select_column,
    [RES.POST_ANCHOR_TEXT_TYPE]: v1_PostsAnchorTextType_select_column,
    [RES.POST_OUTREACH_STATUS]: v1_PostsOutreachStatus_select_column,
    [RES.POST_NICHE]: v1_PostNiche_select_column,
    [RES.PUBLISHER_TAG_REL]: v1_Supplier_Tag_rel_select_column,
    [RES.PROCESS]: v1_process_select_column,
    [RES.PROJECT]: v1_Project_select_column,
    [RES.PROJECT_IMPORTANCE]: v1_ProjectImportance_select_column,
    [RES.PROJECT_COUNTRY]: v1_Project_Countries_select_column,
    [RES.PROJECT_CATEGORY]: v1_Categories_select_column,
    [RES.PROJECT_IMPORTANCE]: v1_ProjectImportance_select_column,
    [RES.PUBLISHER]: v1_Suppliers_select_column,
    [RES.PURCHASE_ORDER]: v1_new_POBase_select_column,
    [RES.PURCHASE_ORDER_STATUS]: v1_PurchaseOrderStatus_select_column,
    [RES.RECEIPT]: v1_new_receiptBase_select_column,
    [RES.RECEIPT_STATUS]: v1_ReceiptStatus_select_column,
    [RES.RECURRING_PAYMENT]: v1_RecurringPayments_select_column,
    [RES.RESTRICTED_NICHES_TO_OFFER]: v1_RestrictedNichesToOffer_select_column,
    [RES.REPORT]: v1_Report_select_column,
    [RES.SUPPLIER]: v1_Suppliers_select_column,
    [RES.STRATEGY_FIELD_SET]: v1_StrategyFieldsSet_select_column,
    [RES.TAG]: v1_Tags_select_column,
    [RES.TEAM]: v1_Teams_select_column,
    [RES.TRACKER]: v1_new_trackerBase_select_column,
    [RES.TRACKER_DEAL]: v1_TrackerDeal_select_column,
    [RES.TRACKER_TAG]: v1_TrackerTags_select_column,
    [RES.TRACKERS_TO_TAGS]: v1_TrackerToTags_select_column,
    [RES.USER]: v1_SystemUserBase_select_column,
    [RES.WEBSITE]: v1_Website_select_column,
    [RES.WEBSITE_PLATFORM]: v1_Website_Platform_select_column,
    [RES.WEBSITE_TOPIC]: v1_WebsiteTopics_select_column,
    [RES.WEBSITE_QUALITY_SCORE]: v1_WebsiteQualityScore_select_column,
    [RES.WRITER]: v1_Writers_select_column,
};

export const enum ROUTES {
    ACTION = 'crm/action',
    BONUS_UPLOAD = 'crm/bonus/upload',
    BONUS_UPDATE = 'crm/bonus/update',
    BONUS_BULK_DUPLICATE = 'crm/bonus/bulk-duplicate',
    CHANGE_STATECODE = 'crm/action/change-statecode',
    FETCH_BONUSES = 'crm/bonus/fetch',
    GET_ROLES = 'auth/get-user-roles',
    GET_ROLE_DEFINITIONS = 'auth/get-role-definitions',
    CONTENT_TYPE_REFRESH = 'crm/content-type/refresh',
    FILE_DOWNLOAD = 'crm/file/download',
    LANDING_PAGE_CREATE = 'crm/landing-page/create',
    OFFER_CHECK_DUPLICATES = 'crm/offer/check-duplicates',
    OFFER_CREATE_BULK = 'crm/offer/create-bulk',
    OFFER_IMPORT_CSV = 'crm/offer/import-csv',
    POST_CREATE_BULK = 'crm/post/create-bulk',
    POST_MERGE = 'crm/post/merge',
    POST_UPLOAD = 'crm/post/upload',
    POST_ATTACH_PO = 'crm/post/attach-po',
    PUBLISHER_CREATE = 'crm/publisher/create',
    CHECK_DUPLICATES = 'check-duplicates',
    BULK_EDIT_BONUSES = 'crm/bonus/bulk-edit',
    BULK_EDIT_BRANDS = 'crm/brand/bulk-edit',
    BULK_EDIT_OFFERS = 'crm/offer/bulk-edit',
    BULK_EDIT_TRACKERS = 'crm/tracker/bulk-edit',
    WEBSITE_STATISTICS_IMPORT_CSV = 'crm/website/import-csv',
    POST_APPROVE_BY_SM = 'crm/post/approve-by-sm',
    AI_GENERATE_ARTICLE = 'ai/article/generate',
    AI_CHANGE_POST_STATUS = 'ai/post/change-status',
    UPDATE_OFFER_RESTRICTED_NICHE = 'crm/offer/%id/update-restricted-niche',
}

export const enum COMP {
    TRACKERS_30_DAY_CLICKS = 'comp_trackers_30_day_clicks',
    TRACKERS_CLICKS_LAST_30_DAYS = 'tracker_clicks_last_30_days',
    NOT_PAID_AMOUNT = 'NotPaidAmount',
    PAID_INVOICES = 'PaidInvoices',
    NOT_PAID_INVOICES = 'NotPaidInvoices',
    HAS_OPEN_INVOICES = 'hasOpenInvoices',
    RELATED_WEBSITE = 'comp_related_websites',
    RELATED_OFFER = 'comp_related_offers',
    LD_RD_RATIO = 'comp_ld_rd_ratio',
    _Project = '_Project',
}

export const REL = {
    [RES.AFFILIATE_ORDER]: {
        COMPANY: 'CompanyNew',
        TRACKER: 'Trackers',
        AFFILIATE_ORDER_TYPE: 'AffiliateProjectNew',
    },
    [RES.TRACKER]: {
        BRAND: 'Brand',
    },
    [RES.OFFER]: {
        COMPANY: 'Company',
        USER: 'Creator',
        CURRENCY: 'Currency',
        LANGUAGE: 'Language',
        OFFER_TO_TAG: 'OfferToTags',
        SUPPLIER: 'Supplier',
        WEBSITE: 'Website',
        WEBSITE_TOPIC: 'WebsiteTopic',
    },
    [RES.COMPANY]: {
        AFFILIATE_ORDER: 'AffOrders',
    },
    [RES.SUPPLIER]: {
        USER: 'Owner',
    },
    [RES.OFFERS_TO_TAGS]: {
        OFFER_TAG: 'OfferTag',
    },
    [RES.WEBSITE]: {
        USER: 'Creator',
        WEBSITE_DTOX_RISK: 'WebsiteDToxRisk',
        POST_NICHE: 'PostNiche',
        WEBSITE_PLATFORM: 'Website_Platform',
        WEBSITE_QUALITY_SCORE: 'WebsiteQualityScore',
        LANGUAGE: 'Language',
    },
};

export const ENGLISH_LANGUAGE_ID = 69;

export const enum CUSTOM_PAGES {
    IMPORT_STATISTICS = '/import-statistics',
    ADD_BULK_OFFERS = '/add-bulk-offers',
}

export const OFFER_STATUS = {
    DECLINED: 1,
    PUBLISHED: 2,
    IN_PROGRESS: 3,
    WAITING_APPROVAL: 4,
    UNPUBLISHED: 5,
};

export const enum PAYMENT_CATEGORY {
    POST_DEAL = 100000003,
    CONTENT = 100000000,
}

export const enum ACTIONS {
    CHANGE_ACTIVE_STATUS_BULK = 'canChangeActiveStatusBulkAction',
    CHANGE_ACTIVE_STATUS = 'changeActiveStatusButton',
    CHANGE_STATUS = 'changeStatuses',
    EDIT_BULK = 'canBulkEdit',
    UPDATE = 'Update',
    CANCEL = 'Cancel',
    DECLINE = 'Decline',
    MODAL_CANCEL_BUTTON = 'modalCancelButton',
    MARK_RECEIPT_AS_PAID = 'markReceiptAsPaid',
    EDIT_CLOSED_RECEIPT = 'editClosedReceipt',
    SET_REPORT_DETAILS = 'setReportDetails',
    SELECT_USER = 'selectUser',
}

export const enum PERMISSIONS {
    EDIT_CLOSED_RECEIPT = 'editClosedReceipt',
    MODAL_CANCEL = 'modalCancelButton',
    SET_REPORT_DETAILS = 'setReportDetails',
    SELECT_USER = 'selectUser',
}

export const enum CURRENCY {
    EURO = 'EUR',
}

export const enum MODE {
    OPTIMISTIC = 'optimistic',
    PESSIMISTIC = 'pessimistic',
    UNDOABLE = 'undoable',
}

export const enum PAGE {
    EDIT = 'edit',
    CREATE = 'create',
    SHOW = 'show',
}

export const enum WRITER_RATES_FIELDS {
    MINIMUM = 'new_FullSeoPage',
    SMALL = 'new_fullseopagebig',
    MEDIUM = 'new_fullseopagelarge',
    MAXIMUM = 'new_fullseopagejambo',
}

export const enum LOCALE {
    EN_GB = 'en-GB',
}

export const PUBLISHER_POST_STATUS = {
    APPROVE_ORDER: 1,
    DECLINED_ORDER: 2,
    CONTENT_CREATION: 3,
    TO_PUBLISH: 4,
    PUBLISHED: 5,
};

export const MARKETPLACE_STATUS = {
    PROVIDE_DETAILS: 1,
    ORDER_PLACED: 2,
    CONTENT_CREATION: 3,
    WAITING_APPROVAL: 4,
    SENT_TO_PUBLISHER: 5,
    PUBLISHED: 6,
};

export const enum POST_NICHE {
    CRYPTO = 1,
    CBD = 2,
    ADULT = 3,
    FINANCE = 4,
    DATING = 5,
    GAMBLING = 6,
}

export const uploadPostRequiredFields = [
    {
        field: Models.Post.comp.Project,
        name: 'Project',
    },
    {
        field: Models.Post.campaign_id,
        name: 'Campaign',
    },
    {
        field: Models.Post.website_id,
        name: 'Website',
    },
    {
        field: Models.Post.new_alias,
        name: 'Alias',
    },
    {
        field: Models.Post.strategy_id,
        name: 'Strategy',
    },
    {
        field: Models.Post.new_Title,
        name: 'H1 title',
    },
    {
        field: Models.Post.new_TextHTMLbody,
        name: 'Post body',
    },
    {
        field: Models.Post.contenttype_id,
        name: 'Content type',
    },
];

export const OFFER_CSV_TEMPLATE_LINK =
    'https://docs.google.com/spreadsheets/d/10cz0qQRPlwTQy8DOeLXD4Oy22e1wqMMt1Wz9D6XWxVI/edit';

export const enum HttpMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch',
}

export const enum NOTIFICATION_SOCKET_EVENTS {
    NEW_NOTIFICATION = 'new_notification',
}
