import {v1_LanguagePriceStep_select_column} from '../../generated/types';

const LanguagePriceStep = {
    ...v1_LanguagePriceStep_select_column,
    res: 'v1_LanguagePriceStep',
    comp: {},
    rel: {},
    label: 'Languages',
    entity: 'Language',
};

export default LanguagePriceStep;
