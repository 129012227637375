import {v1_RecurringPayments_select_column} from '../../generated/types';

const RecurringPayment = {
    ...v1_RecurringPayments_select_column,
    res: 'v1_RecurringPayments',
    comp: {},
    rel: {},
    label: 'Recurring Payments',
    entity: 'Recurring Payment',
};

export default RecurringPayment;
