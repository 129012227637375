import {v1_PaymentCompanies_select_column} from '../../generated/types';

const PaymentCompany = {
    ...v1_PaymentCompanies_select_column,
    res: 'v1_PaymentCompanies',
    comp: {},
    rel: {},
};

export default PaymentCompany;
