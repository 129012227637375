import {v1_OfferTags_select_column} from '../../generated/types';

const OfferTag = {
    ...v1_OfferTags_select_column,
    res: 'v1_OfferTags',
    comp: {},
    rel: {},
};

export default OfferTag;
