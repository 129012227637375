import {v1_Website_Platform_select_column} from '../../generated/types';

const WebsitePlatform = {
    ...v1_Website_Platform_select_column,
    res: 'v1_Website_Platform',
    comp: {},
    rel: {},
};

export default WebsitePlatform;
