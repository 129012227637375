import EmailIcon from '@mui/icons-material/Email';
import {Grid, IconButton} from '@mui/material';
import {TextInput, TextInputProps} from 'react-admin';
import {useWatch} from 'react-hook-form';
import {Link} from 'react-router-dom';

export const EmailInputWithLink = (props: TextInputProps) => {
    const email = useWatch({
        name: props.source,
    });
    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <TextInput fullWidth {...props} />
            </Grid>
            <Grid item xs="auto">
                <IconButton
                    aria-label="Send an email"
                    component={Link}
                    to={`mailto:${email}`}
                    onClick={stopPropagation}
                    disabled={!email}
                    sx={{mt: 1}}
                >
                    <EmailIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();
