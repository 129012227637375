import {v1_MarketPlaceCategoriesTypes_select_column} from '../../generated/types';

const MarketplaceCategoryTypes = {
    ...v1_MarketPlaceCategoriesTypes_select_column,
    res: 'v1_MarketPlaceCategoriesTypes',
    comp: {},
    rel: {},
};

export default MarketplaceCategoryTypes;
