import {v1_Users_Roles_select_column} from '../../generated/types';

const UsersToRoles = {
    ...v1_Users_Roles_select_column,
    res: 'v1_Users_Roles',
    comp: {},
    rel: {},
};

export default UsersToRoles;
