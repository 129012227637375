import {v1_SystemUserBase_select_column} from '../../generated/types';

const User = {
    ...v1_SystemUserBase_select_column,
    res: 'v1_SystemUserBase',
    comp: {},
    rel: {},
    label: 'Users',
};

export default User;
